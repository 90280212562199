import React, { Component, useState, useEffect } from 'react';
import { PagedIndexGrid, ContentCard, StatCard, SplitLayout } from '@rockerbox/styleguide';
import { Table, Label, Icon, Accordion } from 'semantic-ui-react';
import { mock, getValidations, saveValidations, getAllValidations, getValidationQuery } from '../../utils/api';
import GenericSetting from './GenericSetting';


const Validations = (props) => {

  const title = "Enable Account Validations";
  const key_name = "validation_script";
  const [queries, setQueries] = useState([]);
  const [open, setOpen] = useState(0);
  const [closed, setClosed] = useState(0);
  const [activeIndex, setactiveIndex] = useState(undefined);

  useEffect(() => {
    getValidationQuery().then(resp => {
      const orderByDate = resp.sort((a,b) => {
        const current = a.opened_at;
        const next = b.opened_at;

        return ((current == next) ? 0 : ((current < next) ? 1 : -1));
      })
      setQueries(resp);
      const open = resp.filter(c => c.is_open).length;
      const closed = resp.length - open;
      if (open > 0) {
        setactiveIndex(0);
      }

      setOpen(open);
      setClosed(closed);
    })
  }, [])

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index;
    setactiveIndex(newIndex);
  }

  const StatusCell = ({item, col}) => {
    const status = item[col.key];
    return (
      <Table.Cell>
        {status ? <Icon name="warning sign" color="yellow"/> : <Icon name="check" color="green"/>}
      </Table.Cell>
    )
  }

  const cols = [
    {display: "Status", key: "is_open", as: StatusCell},
    {display: "Type", key: "feature"},
    {display: "Error", key: "text"},
    {display: "Opened at", key: "opened_at"},
    {display: "Closed at", key: "closed_at"},
  ]


  // TODO: summary of all open issue vs closed issues (Stat cards with totals)
    // OPEN ISSUES: 5
    // CLOSED ISSUES: 100
  // TODO: if NO open issues, have the table "hidden"
  // TODO: ability to expand / open the table to reveal the relevant client issues
  // TODO: table with all historical (closed) issues and the status clearly communicated to client in chronological order of when the issue was opened


  return(
  <React.Fragment>
    <GenericSetting
      {...{title, key_name, getOptions: getAllValidations, get: getValidations, save: saveValidations}}
    />
    <ContentCard title="Validation issues">
      <SplitLayout
        leftWidth={8}
        rightWidth={8}
        leftContent={<StatCard label="Open" value={open} />}
        rightContent={<StatCard label="Resolved" value={closed} />}
      />
      <Accordion>
        <Accordion.Title index={0} active={activeIndex === 0} onClick={handleAccordionClick}>
          <Icon name='dropdown' />
          View Issues
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <PagedIndexGrid data={queries} cols={cols} itemsPerPage={20}/>
        </Accordion.Content>
    </Accordion>
    </ContentCard>
  </React.Fragment>)
}

export default Validations;
