import React, { Component } from 'react';
import { ColumnLayout } from '@rockerbox/styleguide';
import { Dropdown, Menu, Icon, Form, Button, Container, Image, Header } from 'semantic-ui-react';
import { heroIntegrationsImage } from '@rockerbox/styleguide';
import CacheContext from '../../utils/CacheContext.js'
import { getAllIntegrations } from '../../utils/api.js'

const STYLE = {color:"black",fontWeight:"bold"}

export const IntegrationDisplayName = ({ platform }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const integrations = (Context.getCache(`integrations`, getAllIntegrations) || [])
  const integrationObject = integrations.find(row => row.platform == platform) || {}
  const { display_name } = integrationObject
  return <>{display_name || platform}</>
}

export const IntegrationMenu = ({title, integrations, selectedPlatform, platformType, platformTypes, selectPlatform, platformFeatures, setDeprecationAlert, setShowDeprecationAlert}) => {

  // const platformIntegrations = platformTypes
  //   .find(({key}) => key == platformType)
  //
  // if (!platformIntegrations) return null;
  //
  // const text = <Menu.Header>{title}</Menu.Header>
  // const onChange = (_, { value }) => selectPlatform(value, "setup")
  // const options = platformIntegrations
  //   .values
  //   .map(({key, values}) => {
  //     const validValues = (values|| [])
  //       .filter((row) => !!row.pixel_source_name)
  //
  //     if (validValues.length == 0) return {text: key, value: key, key}
  //     return false
  //   })
  //   .filter(x => x)

  const activeIntegrations = integrations
    .filter(({ category }) => category == platformType)
    .sort((a, b) => a.key < b.key ? -1 : 1)

  const manageClick = (platform, isDeprecated, displayName) => {
    selectPlatform(platform)

    if (isDeprecated) {
      setDeprecationAlert(`Your account is using a deprecated version of the ${displayName} integration. Please contact us for help upgrading to the latest version.`)
      setShowDeprecationAlert(true)
    }
    else {
      setDeprecationAlert(null)
      setShowDeprecationAlert(false)
    }
  }

  const getDeprecationStatus = (values) => {
    return values.some((element) => element.values[0].feature_deprecated == 1)
  }

  if (!activeIntegrations.length) return null

  return (
  <>
    <Menu vertical fluid>
      <Menu.Item header content={title} style={STYLE}/>
      {/* <Dropdown {...{text, options, onChange}} item style={STYLE} size="tiny"  icon="plus" disabled /> */}
      { activeIntegrations.map(row => {
        const active = row.key == selectedPlatform
        const isDeprecated =  getDeprecationStatus(row.values)
        const onClick = () => manageClick(row.key, isDeprecated, row.display_name)
        const issueIcon = isDeprecated && !active && <Icon name="exclamation triangle" style={{float: 'right', color: 'red', margin: 0}}/>
        const chevron = active && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>

        return <Menu.Item {...{active, onClick}}>
          <IntegrationDisplayName platform={row.key}/> {issueIcon} {chevron}
        </Menu.Item>
      })}
    </Menu>
  </>
  )
}

export const EmptyDetails = () => <div />
export const EmptyView = ({ title, subtitle, onClick }) => (
  <Container textAlign='center'>
    <Image
      src={heroIntegrationsImage}
      size='medium'
      centered
      className='m-t-20'
    />
    <Header as='h2'>
      {title || "No Integrations Enabled"}
      <Header.Subheader>
        {subtitle || "Connect external data sources to enrich your data in Rockerbox"}
      </Header.Subheader>
    </Header>
    <Button
      size='big'
      color='blue'
      content="Add Integration"
      onClick={onClick}
    />
  </Container>
)
