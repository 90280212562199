import React, { Component } from 'react'
import { Grid, Sticky } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SplitLayout = props => (
  <Grid fluid="true">
    <Grid.Row stretched={props.stretched}>
      <Grid.Column width={props.leftWidth}>
        {props.leftContent}
      </Grid.Column>
      <Grid.Column width={props.rightWidth} style={props.rightStyle}>
        {props.rightContent}
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

SplitLayout.propTypes = {
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16) */
  leftWidth: PropTypes.number.isRequired,
  /** 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 (sum of all widths cannot exceed 16)*/
  rightWidth: PropTypes.number.isRequired,
  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
  /** stretch contents to take up the entire grid height */
  stretched: PropTypes.bool,

}

export default SplitLayout

export class StickyScroll extends Component {
  state = { active: false }

  handleMainRef = mainRef => this.setState({ mainRef })
  handleSideRef = sideRef => this.setState({ sideRef })

  checkHeights = () => {
    // check if refs exist and if heights have changed
    if ((this.state.mainRef && this.state.sideRef) && ((this.state.mainRef.clientHeight !== this.mainHeight) || (this.state.sideRef.clientHeight !== this.sideHeight))){
      // update stored height values
      this.mainHeight = this.state.mainRef.clientHeight
      this.sideHeight = this.state.sideRef.clientHeight

      // also check for window height to preview weird scroll issues!
      const shouldBeActive = this.mainHeight > this.sideHeight && this.mainHeight > window.innerHeight
      if (shouldBeActive !== this.state.active) {
        this.setState({ active: shouldBeActive })
      }
    }
  }

  componentWillMount = () => window.addEventListener('resize', this.checkHeights, false)

  componentWillUnmount = () => window.removeEventListener('resize', this.checkHeights, false)

  componentDidUpdate = () => this.checkHeights()

  render(){
    const { mainRef, sideRef, active } = this.state
    const props = this.props

    return(
      <SplitLayout
        stretched={props.stretched}
        leftWidth={props.leftWidth}
        leftContent={
          props.stickySide === 'left' ?
            <StickySide
              handleContextRef={this.handleSideRef}
              context={mainRef}
              content={props.leftContent}
              active={active}
              offset={props.stickyOffset}
            />
          :
            <MainSide
              handleContextRef={this.handleMainRef}
              content={props.leftContent}
            />
        }
        rightWidth={props.rightWidth}
        rightContent={
          props.stickySide === 'right' ?
            <StickySide
              handleContextRef={this.handleSideRef}
              context={mainRef}
              content={props.rightContent}
              active={active}
              offset={props.stickyOffset}
            />
          :
            <MainSide
              handleContextRef={this.handleMainRef}
              content={props.rightContent}
            />
        }
      />
    )
  }
}

const StickySide = props => (
  <Sticky context={props.context} active={props.active} offset={props.offset}>
    <div ref={props.handleContextRef}>
      {props.content}
    </div>
  </Sticky>
)

const MainSide = props => (
  <div ref={props.handleContextRef}>
    {props.content}
  </div>
)
