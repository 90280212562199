import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Grid, Header, Icon, Table } from 'semantic-ui-react'
import { ClockIcon } from '@heroicons/react/outline'
import { CursorClickIcon, DocumentTextIcon, FlagIcon } from '@heroicons/react/solid'

import { ContentCard } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getTierColors } from '../../api/account'
import { colorHash } from '../../../components/ViewAttribution/AttributionPercent'
import { timestampFormatter, timestampDiffFormatter } from './helpers'
import { EXCLUDED_DETAIL_FIELDS, USER_ID_FIELDS, DEVICE_FIELDS } from './constants'


const TableRow = ({ label, value }) => !!value && (
  <Table.Row>
    <Table.Cell width={3} style={{ fontWeight: 600 }}>{label}</Table.Cell>
    <Table.Cell width={13} style={{ wordBreak: 'break-word' }} className={label}>{value}</Table.Cell>
  </Table.Row>
)

const DataTable = ({ header, data }) => !!data?.length && (<>
  {!!header &&
    <Header as='h3' content={header} style={{ marginBottom: 0 }} />
  }
  <Table basic='very' style={{ marginTop: 0 }}>
    <Table.Body>
      {data.map(({ key, label, value }) => (
        <TableRow {...{ key: key||label, label, value }} />
      ))}
    </Table.Body>
  </Table>
</>)


const DetailView = ({ item }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const tierColors = Context.getCache(`tierColors`, getTierColors) || null
  const color = (!!tierColors && tierColors[item?.tier_1]) || colorHash(item?.tier_1)

  const { action, is_marketing, tier_1 } = item || {}
  const eventType = useMemo(() => {
    if (action !== 'view') return 'conversion'
    if (!!is_marketing) return 'marketing'
    return 'view'
  }, [item])


  // url and query param data
  const url = (item?.url || '').replace(/&action=.*/, '')
  const urlParts = url.split('/')
  const domain = urlParts.slice(2, 3)
  const [path, query] = urlParts.slice(3).join('/').split('?')
  const referrer = (item?.request_referrer || '').split('?').slice(0, 1)

  const urlData = !!url && [
    { label: 'Domain', value: domain },
    { label: 'Path', value: !!path && `/${path}` },
    { label: 'Referrer', value: referrer },
  ]
  const urlTable = <DataTable data={urlData} />

  const queryParams = !!query && query.split('&').map(x => {
    const [label, value] = x.split('=')
    return {
      label,
      value: decodeURIComponent(value).replaceAll('+', ' '),
    }
  })
  const queryParamKeys = !!queryParams ? queryParams.map(x => x.label) : []
  const queryParamsTable = <DataTable header='Query Parameters' data={queryParams} />


  // event properties data
  const urlDataPart = (item?.url || '').split('&action=').slice(1)[0] || ''
  const dataParams = urlDataPart.split('&').slice(1)
    .map(x => {
      const [label, value] = x.split('=')
      return {
        label,
        value: decodeURIComponent(value).replaceAll('+', ' '),
      }
    })
    .filter(x => 
      !EXCLUDED_DETAIL_FIELDS.includes(x.label) && !queryParamKeys.includes(x.label)
    )
  const dataParamsTable = <DataTable header='Event Properties' data={dataParams} />
  

  // user and device data
  const USER_DEVICE_FIELDS = [...USER_ID_FIELDS, ...DEVICE_FIELDS]
  const userDeviceData = !!item && USER_DEVICE_FIELDS.map(({ key, label, format }) => {
    const originalValue = item[key]
    const value = !!format ? format(originalValue) : originalValue
    return {
      label,
      value: !!value && value,
    }
  })
  const userDeviceTable = <DataTable header='User &amp; Device Properties' data={userDeviceData} />


  // marketing touchpoint tiers
  const tierSeparator = <Icon name='angle right' style={{ color }} />
  const marketingTiers = eventType === 'marketing' && (
    <Header as='h4'>
      <Icon name='circle' style={{ color }} size='large' />
      <Header.Content>
        <span>{tier_1 || 'Direct'}</span>
        {!!item?.tier_2 && item.tier_2 != ' ' && <>{tierSeparator}<span>{item.tier_2}</span></>}
        {!!item?.tier_3 && item.tier_3 != ' ' && <>{tierSeparator}<span>{item.tier_3}</span></>}
        {!!item?.tier_4 && item.tier_4 != ' ' && <>{tierSeparator}<span>{item.tier_4}</span></>}
        {!!item?.tier_5 && item.tier_5 != ' ' && <>{tierSeparator}<span>{item.tier_5}</span></>}
      </Header.Content>
    </Header>
  )

  const header = eventType === 'conversion' ? `Event: ${action}` :
    eventType === 'marketing' ? `Marketing Touchpoint: ${tier_1}` : `Page View: ${path || 'Home Page'}`

  if (!item) return null

  return (
    <div>
      <Header
        as='h2'
        content={header}
        subheader={timestampFormatter(item.timestamp)}
        className='ellipsed'
      />
      {marketingTiers}
      {urlTable}
      {queryParamsTable}
      {dataParamsTable}
      {userDeviceTable}
    </div>
  )
}


const TimelineItem = ({ item, setSelectedItem, selectedItem }) => {
  const isSelected = item == selectedItem

  const [color, EventIcon, displayTitle] = useMemo(() => {
    const { action, is_marketing, tier_1 } = item
    if (action !== 'view') return ['green', FlagIcon, action]
    if (!!is_marketing) return ['orange', CursorClickIcon, tier_1 || 'Direct']
    return ['blue', DocumentTextIcon, 'Page View']
  }, [item])

  return (
    <div
      onClick={() => setSelectedItem(item)}
      className={`timeline-item ${isSelected && 'selected'}`}
    >
      <div className='timestamp'>
        {timestampFormatter(item.timestamp, 'h:mm a', false)}
      </div>
      <div className='line'>
        <div className={`dot ${color}`}>
          <EventIcon className='icon' />
        </div>
      </div>
      <div className='title'>
        {displayTitle}
      </div>
    </div>
  )
}


const EventsTimeline = ({ userSessionsEvents }) => {
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    if (!selectedItem && !!userSessionsEvents?.length && !!userSessionsEvents[0]?.events?.length) {
      setSelectedItem(userSessionsEvents[0]['events'][0])
    }
  }, [userSessionsEvents])

  const columnStyles = {
    height: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
  }

  // hide if no data
  if (userSessionsEvents === false) return null

  return (
    <ContentCard hasTable title='Event Stream' loading={!userSessionsEvents}>
      <Grid celled='internally'>
        <Grid.Row>
          <Grid.Column width={6} style={columnStyles}>
            {!!userSessionsEvents && userSessionsEvents.map((x, i) => (
              <div key={i}>
                <Header
                  as='h4'
                  image={<ClockIcon style={{ display: 'inline-block', height: 25, width: 25, opacity: 0.5, margin: '1px 7px 0 0' }} />}
                  content={timestampFormatter(x.start)}
                  subheader={timestampDiffFormatter(x.start, x.end)}
                  style={{ marginTop: !!i ? 12 : 0, marginBottom: 6 }}
                />
                <div className='timeline'>
                  {x.events.map((item, j) => (
                    <TimelineItem key={j} {...{ item, setSelectedItem, selectedItem }} />
                  ))}
                </div>
              </div>
            ))}
          </Grid.Column>
          <Grid.Column width={10} style={columnStyles}>
            <DetailView item={selectedItem} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ContentCard>
  )
}

export default EventsTimeline
