import React, { useEffect, useState } from 'react';
import { Segment, Dropdown, Button, Form } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import _ from 'lodash';
import Row from './ComparisonRow';

import { getSegments, getAuthorizations, getPlatformComparisonConfigs, updatePlatformComparisonConfigs, postPlatformComparisonConfigs, deletePlatformComparisonConfigs } from '../../utils/api';

const FacebookComparisonForm = (props) => {
  const { platform } = props;

  // Rockerbox Conversion Segment state
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [filter_id, setFilterId] = useState("");

  // Facebook Account state
  const [facebookAuths, setFacebookAuths] = useState([]);

  // List data
  const [comparisonConfigsList, setComparisonConfigsList] = useState([]);
  const [updatedComparisonConfigsList, setUpdatedComparisonConfigsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const newRow = {selected_auth: "", platform_conversion: "", platform, filter_id, deleted: 0};

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    const filtered = comparisonConfigsList.filter(c => c.filter_id == filter_id);
    setUpdatedComparisonConfigsList(filtered.length > 0 ? filtered : [newRow]);
  }, [filter_id])

  const getData = () => {
    getRockerboxSegments();
    getFacebookAccounts();
    getPlatformComparisonData();
  }

  const getPlatformComparisonData = () => {
    getPlatformComparisonConfigs()
    .then(data => {
      if(data.length > 0) {
        const filter_id = data[0].filter_id;
        const filteredData = data.filter(c => c.filter_id == filter_id);
        setFilterId(filter_id);
        setComparisonConfigsList(data);
        setUpdatedComparisonConfigsList(filteredData); 
      } else {
        setComparisonConfigsList([newRow]);
        setUpdatedComparisonConfigsList([newRow]);
      }
    })
  }

  const getRockerboxSegments = () => {
    getSegments()
    .then(data => {
      const segmentOptions = data
      .filter(row => !row['is_child'])
      .map(row => {
        row.text = row.action_name
        row.value = row.action_id
        return row
      })

      setSegmentOptions(segmentOptions);
    })
  }

  const getFacebookAccounts = () => {
    getAuthorizations()
    .then(data => {
      const accounts = data.filter(row => row.platform.indexOf("facebook") > -1)

      if (accounts.length > 0) {
        const facebookAuths = accounts
          .reduce((p,c) => p.concat(c.authorizations), [])
          .map(row => {
            row.text = row.account_name
            row.value = row.id
            return row
          })

        setFacebookAuths(facebookAuths);
      }
    })
  }

  const handleChange = (row, index) => (event, {value}) => {
    const { selected_auth, platform_conversion, account_id } = value;
    const listClone = _.cloneDeep(updatedComparisonConfigsList)
    const updatedRowObj = Object.assign(row, {selected_auth, platform_conversion, platform_account_id: account_id});
    listClone[index] = updatedRowObj;

    setUpdatedComparisonConfigsList(listClone)
  }

  const handleSubmit = () => {
    setLoading(true);
    console.log(updatedComparisonConfigsList)
    const apiCalls = updatedComparisonConfigsList
    .filter(c => c.platform_conversion !== "")
    .map(c => {

      // const apiCall = c.deleted == 1 ? deletePlatformComparisonConfigs(c.id) : 
      // c.id ? updatePlatformComparisonConfigs(c, c.id) : 
      // postPlatformComparisonConfigs(c);

      // return apiCall;
    })

    Promise.all(apiCalls)
      .then(data => {
        setLoading(false);
        getData();
      })
  }

  const handleAddRow = (e,data) => {
    e.preventDefault();
    const listClone = _.cloneDeep(updatedComparisonConfigsList);
    listClone.push(newRow);

    setUpdatedComparisonConfigsList(listClone);
  }

  const removeFromList = (position) => {
    const listClone = _.cloneDeep(updatedComparisonConfigsList);
    listClone[position].deleted = 1

    setUpdatedComparisonConfigsList(listClone);
  }

  return(
    <React.Fragment>
      <ContentCard title={`Comparison`} >
        <Form onSubmit={handleSubmit}>
          <Segment style={{"border":"none","boxShadow":"none"}}>
            <label>Rockerbox</label>
            <Form.Group inline>
              <Form.Field>
                <label>Conversion Segment</label>
                <Dropdown 
                  onChange={(_, {value}) => setFilterId(value)}
                  value={filter_id}
                  options={segmentOptions}
                  selection
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          {
            updatedComparisonConfigsList.map((c,i) => {
              return (
                <Row onChange={handleChange(c, i)}  {...{facebookAuths, rowData: c, position: i, removeFromList}}/>
              )
            })
          }
          <Button icon="plus" onClick={handleAddRow}/>
          <Button primary type="submit" loading={loading}>Save</Button>
        </Form>
      </ContentCard>
    </React.Fragment>
  )
}

export default FacebookComparisonForm;
