// helper functions for PagedIndexGrid
export function recordsFrom(selectedPageNumber, itemsPerPage) {
  const fromRecord = (selectedPageNumber - 1) * itemsPerPage + 1

  if (selectedPageNumber === 1 ) {
    return 1
  } else {
    return new Intl.NumberFormat().format(fromRecord)
  }
}

export function recordsTo(selectedPageNumber, itemsPerPage, totalItems) {
  const toRecord = itemsPerPage * selectedPageNumber

  if (toRecord > totalItems) {
    return new Intl.NumberFormat().format(totalItems)
  } else {
    return new Intl.NumberFormat().format(toRecord)
  }
}
