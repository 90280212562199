import IndexGrid from './IndexGrid'
import { EmptyMessage } from './IndexGrid'
import * as SubParts from './IndexGridCells'

import Search from './SearchHeader'

const arrayToColumns = (arr) => {
  return arr.map(key => { return { key, display: key} })
}


export default Object.assign(IndexGrid, SubParts, { EmptyMessage, arrayToColumns, Search })

