import React, { Component } from 'react'
import { Dropdown, Input } from 'semantic-ui-react';

class SearchableDropdown  extends Component {
  state = {
    options: [],
    filteredOptions: []
  }

  componentDidMount() {
    const { options } = this.props;
    this.setState({ options, filteredOptions: options });
  }

  componentWillMount(){
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = ({ target }) => {
    const { containerRef } = this;
    return this.setState({ opened: containerRef.contains(target) })
  }
  handleTriggerFocus = () => (!this.state.opened) ? this.toggleOpen() : false
  toggleOpen = () => this.setState({ query: '', opened: !this.state.opened }, this.focusInput)

  focusInput = () => this.inputRef.focus()
  setFocusedOption = value => this.focusedOption = value

  handleSearch = (event) => {

    const { target: {value}} = event;
    const { options } = this.props;
    this.setState({ query: value })
    const filtered = options.filter(tag => tag.text.toLowerCase().includes(value.toLowerCase()))
    this.setState({ filteredOptions: filtered })
  }

  handleChange = (event, chosen) => {
    const { value } = chosen;
    const { options } = this.props;
    const selected = options.filter(row => row.value == value)[0]
    if (selected) {
      this.props.onSelect(event, selected)
      this.setState({ opened: false })
    }
  }

  handleEnter = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.handleChange(event, {value: this.focusedOption})
    }
  }

  render(){
    const { opened, query, filteredOptions } = this.state
    const { props: { children, title } } = this;

    const onClick = this.handleChange;
    const onKeyDown = this.handleEnter;

    const defaultElement = (props) => (<span {...props}>{props.children}</span>)
    const wrap = this.props.as || defaultElement;

    return(
      <wrap ref={containerRef => this.containerRef = containerRef} >
        <Dropdown pointing='top right' icon='' className='company-switcher' trigger={children} >
          <Dropdown.Menu open={opened}>
            <Input icon='search' iconPosition='left' ref={inputRef => this.inputRef = inputRef} value={query} onChange={this.handleSearch} />
            <Dropdown.Divider />
            { title && <Dropdown.Header icon='exchange' content={title} /> }
            <Dropdown.Menu scrolling>
              {filteredOptions.map((option, i) =>
                <Dropdown.Item {...option} tabIndex={0} onFocus={() => this.setFocusedOption(option.value)} {...{onClick, onKeyDown}}/>
              )}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      </wrap>
    )
  }
}
export default SearchableDropdown;
