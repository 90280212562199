import React, { Component } from 'react';
import { Kanban } from '@rockerbox/styleguide';
import { getDataset } from '../../utils/api';
import { getConversionDataSummary } from './../../utils/api';
import CacheContext from '../../utils/CacheContext.js'
import { Accordion, Input, Header, Popup, Icon, Label, Form, Button, Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const remapResponses = (summary, surveyResponseKey, responseRules, ruleValues, defaultResponse) => {

  const summaryValues = (summary
    .find(row => row.key == surveyResponseKey) || {raw: []})
    .raw

  responseRules.map(row =>{
    row.responsePatternSet = row.response_values.split("|").map(v => v.toLowerCase())
  })

  const values = [...summaryValues, ...ruleValues] // add to state 
    .filter(row => !!row)
    .map(row => {
      const originalValue = row
      const lowered = (originalValue||"").toLowerCase()
      const patternInValue = (f) => lowered.includes(f)


      for (const pos in responseRules) {
        const rule = responseRules[pos]
        const { responsePatternSet, response_display } = rule

        const hasMatch = responsePatternSet
          .filter(patternInValue)
          .length 

        if (hasMatch) {
          const newValue = response_display
          return { originalValue, newValue }
        }
      }
      return { originalValue, newValue: defaultResponse }
    })
  

  const data = d3.nest()
    .key(row => row.newValue )
    .key(row => row.originalValue )
    .rollup(values => {
      return { values, count: values.length}
    })
    .entries(values)
    .map(row => {
      const { key, values } = row;
      const numRows = values.length

      const sorted = values.sort((p, c) => c.count - p.count)
      const totalCounts = values
        .reduce((p, c) => {
          const { values: { count } } = c
          p.count += count
          return p 
        }, { count: 0, key, numRows, values: sorted})

      return totalCounts
    })
    .sort((p,c) => c.count - p.count )
    .reduce((p, c) => {
      const { key, numRows } = c
      const values = c.values
        .map(row => {
          row.newValue = key
          row.originalValue = row.key
          row.num_rows = numRows
          row.count = row.values.count
          return row
        })
        .sort((p,c) => c.count - p.count)
        .map((row, pos) => { 
          row.pos = pos
          return row
        })

      return p.concat(values)
    }, [])
    .map(row => {
      row.text = row.key
      row.id = row.key
      return row
    })
    
  return data
}

const StyledLabel = styled(Label)`
  margin-top: 0.5em !important;
  background-color: ${props => props.exactMatch ? "#00BB5C !important" : "inherit"};
  & > .icon {
    cursor: pointer;
    margin-right: 0em;
    margin-left: 0.5em !important;
    font-size: 0.91666667em !important;
    opacity: 0.5;
    transition: background 0.1s ease;
  }
`

const IconWrapper = styled.span`
  & > .icon {
    cursor: pointer;
    margin-right: 0em;
    margin-left: 0.5em !important;
    font-size: 0.91666667em !important;
    opacity: 0.5;
    transition: background 0.1s ease;
  }

  & > .icon:hover {
    opacity: 1;
  }
`

const buildCardComponent = (onClick, onRemove, responseDict) => ({ item, snapshot, provided }) => {
  const firstMatch = Object.keys(responseDict).find(row => item.text.toLowerCase().includes(row.toLowerCase()))
  const exactMatch = !!responseDict[item.text.toLowerCase()] || (firstMatch && (firstMatch.toLowerCase() == item.text.toLowerCase()))
  const split = item.text.toLowerCase().split((firstMatch||"").toLowerCase())

  return (
    <StyledLabel {...provided.draggableProps} {...provided.dragHandleProps} {...{exactMatch}}>
      { !firstMatch || exactMatch ? item.text : 
        split.map((v,i) => {
          return <>{v}{ i + 1 < split.length  ? <span style={{color:"#00BB5C"}}>{firstMatch}</span> : ""}</>
        })
      }
      { item.newValue == "Unclassified" && <Icon name='add' onClick={(evt) => onClick(evt, { value: item.text})} /> }
      { item.newValue != "Unclassified" && <Icon name='remove' onClick={(evt) => onRemove(evt, { value: item.text})} /> }
    </StyledLabel>
  )
}

const HeaderComponent = (props) => {
  
  const [showEdit, setShowEdit] = React.useState(false)

  const { col, color, text, icon, onHeaderChange, onRemoveOption } = props;
  const { rule = {} } = col;
  const { response_display, response_values } = rule
  if (showEdit) {
    return <>
      <Input value={response_display} onChange={(_, { value }) => onHeaderChange(col.rule, value) } />
      &nbsp;
      <Icon size="mini" name="check" onClick={() => setShowEdit(false)} style={{fontSize: ".8em"}} />
    </>
    
  }
  
  return !!response_display && <>
    <Header as='h4' {...{color, icon}} style={{marginTop: "10px"}}>
      { response_display }
      <IconWrapper>
        &nbsp;
        <Icon size="mini" name="pencil" onClick={() => setShowEdit(true)} style={{fontSize: ".8em"}} />
        <Icon size="mini" name="delete" onClick={() => onRemoveOption(response_display)} style={{fontSize: ".8em", float:"right"}} />
      </IconWrapper>
    </Header>
  </>
}

const CurrentResponses = ({ options, editEntry, appendEntry, createEntry, removeEntry, removePattern, defaultResponse, survey, responseRules, surveyResponseKey, id, ruleValues}) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const { summary = [] } = survey ? 
    (Context.getCache(`conversionDataSummary${survey}`, getConversionDataSummary, survey) || {}) : {}

  const remapped = React.useMemo(() => {
    
    const hasSummary = Object.keys(summary).length > 0 
    const activeRules = responseRules.filter(row => row.response_values && row.response_values.length > 0 )
    return (hasSummary) ?
      remapResponses(summary, surveyResponseKey, activeRules, ruleValues, "Unclassified") : []
  }, [summary, responseRules, defaultResponse])


  options.map(row => {
    row.columnWidth = 1
    row.rule = responseRules.find(rule => rule.response_display == row.value) || {}
  })


  const allOptions = [
    {color:"blue", columnWidth: 2.5, text: "", value: "Unclassified", fullHeight: true}
  ].concat(
    options.filter(row => row.value != "Unclassified")
  )

  const onChange = (newItems, changedItem) => {
    appendEntry(changedItem.newValue, changedItem.originalValue)
  }
  const onHeaderChange = (rule, value) => {
    editEntry(rule, value)
  }
  const onClick = (evt, { value }) => {
    createEntry(value, value)
  }
  const onRemove = (evt, { value }) => {
    removePattern(value)
  }
  const onRemoveOption = (value) => {
    removeEntry(value)
  }

  const asDict = responseRules.reduce((p,row) => {
    row.response_values.split("|").map(v => v.toLowerCase()).map(v => {
      p[v] = p[v]||[]
      p[v] = [...p[v], row.response_display]
    })
    return p
  },{})

  const allowMaximize = false
  const CardComponent = buildCardComponent(onClick, onRemove, asDict)
  const rightColumnsHeader = <div><span>Response Classifications</span> <Popup content='A response with a solid green background indicates an exact match. Highlighted green text indicates a substring match.' trigger={<Icon name='question circle outline' color='grey' />} /></div>
  const leftColumnsHeader = "Unclassified Responses"

  const previewSection = <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Mapped Response</Table.HeaderCell>
          <Table.HeaderCell>Original Response</Table.HeaderCell>
          <Table.HeaderCell>Count </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { 
          remapped
            .map(({ originalValue, newValue, count, pos, num_rows })=> {
              return <Table.Row>
                { pos == 0 && ( <Table.Cell rowSpan={num_rows} > { newValue } </Table.Cell>) }
                <Table.Cell>
                  { originalValue }
                  { originalValue != newValue && <Button floated='right' compact basic icon="copy outline" as='a' onClick={() => createEntry(originalValue)} /> }
                  <div style={{float:"right"}}>
                  { options && !!options.length && originalValue != newValue && <Form.Dropdown options={options} floated='right' compact basic size='mini' onChange={(evt, { value }) => appendEntry(value, originalValue)} icon='tag' className='icon' button text=" " value={false} mini /> }
                  </div>
                </Table.Cell>
                <Table.Cell>{ count } </Table.Cell>
              </Table.Row>
            })
        }
      </Table.Body>
    </Table>
  </>

  const panels = [{
    key: 'advanced',
    title: 'Advanced',
    content: { content: previewSection }
  }]

  return <>
  <div>
    <Kanban wrap columnWidth={4}
      {...{
        HeaderComponent, CardComponent, 
        options: allOptions, items: remapped,
        leftColumnsHeader, rightColumnsHeader, 
        allowMaximize, asRows: false, classificationKey: "newValue", 
        onChange, onHeaderChange, onRemoveOption
      }}
    />
  </div>
  <Accordion panels={panels} />
  </>
}

export default CurrentResponses;
