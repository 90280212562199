import React from "react"
import { Image, Statistic } from "semantic-ui-react"
import { formatCurrency, secondsToDhms } from "../../../utils/valueFormatter"

const PlatformSummary = ({ platformIcon, platformDisplayName, platformMetricsObj, hasRevenue }) => {
  const convOnly = platformMetricsObj?.total_count  - platformMetricsObj?.overlap_count || 0
  const revOnly = platformMetricsObj?.total_revenue - platformMetricsObj?.overlap_revenue || 0
  const ttcOnly = platformMetricsObj?.total_time_to_conv - platformMetricsObj?.overlap_time_to_conv || 0
  
  let displayMetrics = {
    "CONVERSIONS": convOnly.toLocaleString(),
    "AVG CONVERSION TIME": convOnly === 0 ? '-' : secondsToDhms(ttcOnly / convOnly)
  }

  if (hasRevenue) {
    const revMetrics = {
      "REVENUE": formatCurrency(revOnly),
      "AVG REVENUE": formatCurrency(revOnly / convOnly)
    }
    //Insert keys in the middle of the obj for right display order
    const extendedMetrics = Object.entries(displayMetrics)
    extendedMetrics.splice(1,0, ...Object.entries(revMetrics))
    displayMetrics = Object.fromEntries(extendedMetrics) 
  }

  return (
    <div style={{ padding: '20px 10px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
      <div style={{ flexBasis: '40%', display: 'flex', alignItems: 'center' }}>
        <Image src={platformIcon} style={{ width: 60, height: 60 }} />{' '}
        <p style={{ paddingLeft: '16px' }}>
          {' '}
          Compare these {platformDisplayName}-only conversions to the metrics below, which represent conversions that touched both {platformDisplayName} and the respective channel.
        </p>
      </div>
      <Statistic.Group color="purple" size="tiny" style={{ flexBasis: '60%', justifyContent: 'space-evenly', margin: 0, paddingLeft: '40px' }}>
        {Object.entries(displayMetrics).map((obj, i, arr) => (
          <Statistic key={obj[0]} style={{ marginRight: i == arr.length-1 ? 0 : 'auto', marginLeft: 'auto', marginBottom: 0 }}>
            <h2 style={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'center' }}>{obj[0]}</h2>
            <Statistic.Value style={{ fontSize: '20px !important', fontWeight: '300', textAlign: 'center' }}>
              {obj[1]}
            </Statistic.Value>
          </Statistic>
        ))}
      </Statistic.Group>
    </div>
  );
}

export default PlatformSummary;