import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import TopRightActions from './TopRightActions';

class ReportTypeCard extends Component {
  render() {
    return (
      <ContentCard
        title='Attribution Report'
        disableHeaderEllipse
        noContent
        style={{marginBottom:"-15px"}}
        topRight={<TopRightActions {...this.props}/>}
      />
    )
  }
}

export default ReportTypeCard;
