import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Button, Form, Dropdown } from 'semantic-ui-react';
import Container from '../parts/Container';
import moment from 'moment';

const ScheduleStatus = (props) => (
  <Container center>
    { props.success ?
      <Container center column>
        <h5>Your report has been scheduled! Check the Exports tab to see it and your other reports</h5>
        <Container>
          <Button onClick={props.done}>Done</Button>
        </Container>
      </Container> :
      <Container center column>
        <h5>This is awkward, but there was an error scheduling your report.</h5>
        <Container>
          <Button onClick={props.reset}>Try Again</Button>
          <Button onClick={props.done}>Cancel</Button>
        </Container>
      </Container>
    }
  </Container>
);

const Field = props => {
  return (
    <Form.Group widths="equal">
      <Form.Field>
        <label>{props.label}</label>
        {props.children}
      </Form.Field>
    </Form.Group>
  );
};

const WEEKDAYS = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

const SCHEDULED_CADENCES = [
  { value: WEEKDAYS.join(','), text: 'Daily' },
  { value: 'Mon', text: 'Weekly' }
  // {value: XXXX, text: "Monthly"}
];

const RecipientsField = props => {
  if (props.currentOutbox && props.currentOutbox.outbox_type !== 'do_spaces')
    return null;
  return (
    <Field label="Report Recipients">
      <Input
        value={props.value}
        icon="envelope outline"
        iconPosition="left"
        placeholder="Enter a comma-separated list of emails that should receive the report"
        onChange={props.onChange}
      />
    </Field>
  );
};

const CadenceField = props => {
  return (
    <Field label="When">
      <Dropdown
        placeholder="How often would you like to recieve this report?"
        selection
        name="cadence"
        value={props.days}
        options={SCHEDULED_CADENCES.map(c => {
          return { key: c.value, text: c.text, value: c.value };
        })}
        onChange={props.updateCadence}
      />
    </Field>
  );
};

const NameField = props => {
  return (
    <Field label="Name">
      <Input value={props.name} name="name" placeholder="name" onChange={props.onChange} />
    </Field>
  );
};

const initialState = {
  name: "",
  success: undefined,
  resolved: true,
  recipients: '',
  days: undefined
};

class ScheduleForm extends Component {
  timeout = null;

  state = Object.assign({}, initialState);

  onReset = () => {
    this.setState(Object.assign({}, initialState));
  }

  onSubmit = (evt) => {

    const { exportType, tiers, save, schedule, pixel_source_name, filter_id, reportTypes, outboxes, savedReportId } = this.props;
    const { name, days, recipients } = this.state;

    const obj = {};

    const outbox = outboxes.filter(o => o.outbox_type === 'do_spaces')[0];

    if (savedReportId) {
      Object.assign(obj, {
        saved_report_id: savedReportId,
        outbox_id: outbox.id,
        time: 10,
        days: days,
        destination: recipients.replace(/ /g, '')
      });

      schedule(obj).then(x => {
        this.setState({success: true});
        this.setState({resolved: true});
      }).catch(x => {
        this.setState({success: false});
        this.setState({resolved: true});
      });

    } else {
      const report_type_id = reportTypes.find((t) => t.report_type === exportType).id;

      Object.assign(obj, {
        report_type_id: report_type_id,
        num_days: 30,
        filter_id: filter_id,
        filters: tiers,
        name: name
      });

      save(obj).then(res => {
        let saved_report_id = res.data.response[0].id;
        let schedule_report = {
          saved_report_id: savedReportId,
          outbox_id: outbox.id,
          time: 10,
          days: days,
          destination: recipients.replace(/ /g, ''),
          saved_report_id: saved_report_id
        };
        schedule(schedule_report);
      }).then(x => {
        this.setState({success: true});
        this.setState({resolved: true});
      }).catch(x => {
        this.setState({success: false});
        this.setState({resolved: true});
      })
    }

    this.setState({resolved: false});

    evt.preventDefault()
  }

  updateCadence = (item, e) => {
    this.setState({ days: e.value });
  }

  onFieldChange = (fieldName) => (evt, data) => {
    this.setState({[fieldName] : data.value});
  }

  validate = () => {
    let success = true;
    const {
      recipients,
      days,
      name
    } = this.state;

    const { savedReportId } = this.props;

    if (savedReportId && !(recipients && days && days.split(',').length)) success = false;
    if (!savedReportId && !(recipients && days && days.split(',').length && name.length)) success = false;

    const validate_email = address => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(address);
    };

    recipients.split(',').forEach(e => {
      e = e.trim();
      if (!validate_email(e)) success = false;
    });

    return success;
  };

  render() {

    const { state, props, onSubmit, onFieldChange, onReset, updateCadence, validate } = this;

    const { savedReportId } = this.props;

    if (state.success !== undefined) return (<ScheduleStatus {...props} {...state} reset={onReset} />)

    return (
        <Container center>
          <Form onSubmit={onSubmit}>
            { !savedReportId && <NameField onChange={onFieldChange("name")} {...this.props} {...this.state} /> }
            <CadenceField updateCadence={updateCadence} {...this.props} {...this.state} />
            <RecipientsField onChange={onFieldChange('recipients')} {...this.props} />
            <Button type="submit" disabled={!validate() || !this.state.resolved}>Schedule Report</Button>
          </Form>
        </Container>
      )
  }
}


const SaveReportForm = (props) => {
  return <ScheduleForm {...props} save={props.save} />
}

SaveReportForm.propTypes = {
  save: PropTypes.func.isRequired,
}

export default SaveReportForm;
