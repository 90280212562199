import React from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import {
  ShoppingCartIcon,
  ChartSquareBarIcon,
  MapIcon,
  UserAddIcon,
  FilterIcon,
  ChartPieIcon,
  PresentationChartLineIcon
} from '@heroicons/react/outline'
import * as routes from '../../routes'
import Link from '../../utils/link'
import { BasicViewHeader } from '../../components'
import { buildHash, buildPath } from '../../hooks/urlState'
import { track } from '../../../utils/tracking'

export const routeDisplayMapping = {
  attributionReport: {
    display: 'Attribution Report',
    path: routes.attributionReport,
    icon: <ChartSquareBarIcon style={{ height: 25, margin: 0 }} />,
  },
  conversionsDetail: {
    display: 'Conversions Detail',
    path: routes.conversionsDetail,
    icon: <ShoppingCartIcon style={{ height: 25, margin: 0 }} />,
  },
  funnelPosition: {
    display: 'Funnel Position',
    path: routes.funnelPosition,
    icon: <FilterIcon style={{ height: 25, margin: 0 }} />,
  },
  marketingPaths: {
    display: 'Marketing Paths',
    path: routes.marketingPaths,
    icon: <MapIcon style={{ height: 25, margin: 0 }} />,
  },
  channelOverlap: {
    display: 'Channel Overlap',
    path: routes.channelOverlap,
    icon: <ChartPieIcon style={{ height: 25, margin: 0 }} />,
  },
  newVisitors: {
    display: 'New Visitors',
    path: routes.newVisitors,
    icon: <UserAddIcon style={{ height: 25, margin: 0 }} />,
  },
  spendTrends: {
    display: 'Spend Trends',
    path: routes.spendTrends,
    icon: <PresentationChartLineIcon style={{ height: 25, margin: 0 }} />,
  },
  digitalAdvertising: {
    display: 'Digital Advertising',
    path: routes.digitalAdvertising,
    icon: <PresentationChartLineIcon style={{ height: 25, margin: 0 }} />,
  },
}

export const MainHeader = () => (
  <BasicViewHeader
    header='Saved Reports'
    subheader=''
  />
)

export const TitleCell = ({ item }) => {
  const { name, route, params, id, user, shared } = item
  const rebuiltPath = buildPath(route, params.route)
  const rebuiltHash = params.hash ? buildHash(params.hash) : ''
  const fullPath = rebuiltPath + rebuiltHash
  return (
    <Table.Cell>
      <Link
        to={fullPath}
        onClick={() => {
          track('saved_report.open', { id, name, user, shared, route, params })
        }}
      >
        {name}
      </Link>
    </Table.Cell>
  )
}

export const BasedOnCell = ({ item }) => {
  const { route } = item
  return (
    <Table.Cell>
      <Link to={routeDisplayMapping[route].path}>
        {routeDisplayMapping[route].display}
      </Link>
    </Table.Cell>
  )
}

export const TimeCell = ({ item }) => {
  const { created_at } = item
  const dateString = moment(created_at).format('DD-MMM-YYYY')
  return (
    <Table.Cell>
      <p>{dateString}</p>
    </Table.Cell>
  )
}
