import React from 'react';
import { Table, Icon, Header } from 'semantic-ui-react';

export const ContentCardTableFooter = props => (
  <Table.Footer>
    <Table.Row>
      <Table.HeaderCell colSpan='100'>
        <div className='footer-content'>{props.children}</div>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
)

export const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign='center'
        selectable={false}
        className={props.callToAction ? undefined : 'empty'}
        style={{ borderTop: 'solid 1px #efefef' }}
      >
        <br />
        <Icon
          circular
          disabled
          name={props.emptyIcon || 'info'}
          size='big'
          className='m-b-10' />
        <Header
          as='h3'
          disabled
          content={props.fallbackMsg || props.fallBackMsg || 'No data found'}
          className='m-0' />
        { props.callToAction && <div style={{marginTop:"8px"}}>{ props.callToAction }</div> }
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)
