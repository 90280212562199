import { DefaultCell, NumberCell, CurrencyCell, DiscountCell, DateCell, TimestampCell, TimerangeCell, PathCell } from './helpers'

export const CONVERSION_COLUMNS = [
  // default columns
  {
    display: 'Order Number',
    key: 'order_number',
    as: NumberCell,
    isSearchable: true,
    default: true,
  },
  {
    display: 'Order ID',
    key: 'order_id',
    as: NumberCell,
    isSearchable: true,
    default: true,
  },
  {
    display: 'Email',
    key: 'email',
    as: DefaultCell,
    isSearchable: true,
    default: true,
  },
  {
    display: 'Customer ID',
    key: 'external_id',
    as: NumberCell,
    requirement: 'lookups',
    isSearchable: true,
    default: true,
  },
  {
    display: 'Revenue',
    key: 'revenue',
    as: CurrencyCell,
    isSearchable: true,
    requirement: 'revenue',
    default: true,
  },
  {
    display: 'Conversion Time',
    key: 'timestamp_conv',
    as: TimestampCell,
    default: true,
  },
  {
    display: 'Marketing Touchpoints',
    key: 'path_deduplicated',
    as: PathCell,
    isSearchable: true,
    default: true,
  },

  // optional columns
  {
    display: 'Time to Conversion',
    key: 'time_to_conversion',
    as: TimerangeCell,
    isSearchable: true,
  },
  {
    display: 'Customer Type',
    key: 'customer_type',
    as: DefaultCell,
    requirement: 'new_to_file',
  },
  {
    display: 'Discount',
    key: 'discount',
    isSearchable: true,
    as: DiscountCell,
  },
  {
    display: 'Coupon',
    key: 'coupon',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Date',
    key: 'date',
    as: DateCell,
    isSearchable: true,
  },
  {
    display: 'Device',
    key: 'device',
    as: DefaultCell,
    isSearchable: true,
  },

  // geo attributes
  {
    display: 'City',
    key: 'city',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'State',
    key: 'state',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Province',
    key: 'province',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Zip Code',
    key: 'zip_code',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Country',
    key: 'country',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'DMA ID',
    key: 'dma_id',
    as: NumberCell,
    isSearchable: true,
  },

  // shopify attributes
  {
    display: 'Num Lifetime Orders',
    key: 'orders_count',
    as: NumberCell,
    isSearchable: true,
  },
  {
    display: 'Order Total',
    key: 'total',
    as: CurrencyCell,
    isSearchable: true,
  },
  {
    display: 'Order Source',
    key: 'order_source',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Shopify Shop ID',
    key: 'shop',
    as: DefaultCell,
    isSearchable: true,
  },

  // rockerbox attributes
  {
    display: 'Conversion ID',
    key: 'conversion_hash_id',
    as: DefaultCell,
    isSearchable: true,
  },
  {
    display: 'Rockerbox User ID',
    key: 'uid',
    as: DefaultCell,
    isSearchable: true,
  },
]