import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Link } from 'react-router-dom';
import { Popup, Button } from 'semantic-ui-react';
import CacheContext from '../../../utils/CacheContext';

import { Row, RowItem, Emph, NonEmph } from './parts';

import { LabelSwatch, colorHash } from '../AttributionPercent';
import { trimPercent} from '../AttributionPercent/helpers';
import { urlEncodeTier } from '../helpers';
import DailyChart from '../../DailyChart'

import { getTierColors } from '../../../utils/api'

const TIERS = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"];

const angleStyles = {
  fontSize: 12,
  color: "grey",
  position: "relative",
  top: -4,
  marginRight: 2
}

const numberFormatOptions = {
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  currency: 'USD'
}

const spendFormatOptions = {
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'USD'
}


export const ReportRow = ({index, model, row, match, hasSpend, range}) => {
  const [{currency_code}] = React.useContext(CacheContext);
  const [state, setState, Context] = React.useContext(CacheContext)
  const tierColors = Context.getCache(`tierColors`, getTierColors) || {}

  spendFormatOptions.currency = currency_code || 'USD';
  numberFormatOptions.currency = currency_code || 'USD';
  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const spendFormatter = new Intl.NumberFormat('en-US', spendFormatOptions);
  const { id, startDate, endDate, ntfFilter } = match.params;

  const prestem = match.path.replace(":savedReportId?","0")
  const stem = prestem.split(":id?")[0]
  const values = match.url.replace(stem,"").split("/").slice(0,7)
  const urlBase = stem + values.join("/") + "/"

  const { percent, group, total, rev_total, spend_total, yesterday_spend, count, yesterdayCount, yesterdayRevenue, highlight, cpa, roas, rpa, tiers } = row;

  const tierSelection = tiers
  const tier_1 = tierSelection && tierSelection.length && tierSelection[0]
  const tierColor = tierColors && tier_1 && tierColors[tier_1] || colorHash(tier_1)

  return (
    <Row style={{backgroundColor: highlight ? "#f9f9f9" : undefined}} >
      <RowItem justify="center" flex={.25}>
        <Emph style={{fontSize:"1.3em"}}>
          <LabelSwatch
            style={tierColor && {background: tierColor}}
            percent={percent*100}
            size={percent < .05 ? 6 : Math.sqrt(720*percent)}
            position={index}
          />
        </Emph>
        <NonEmph style={{fontSize:".7em"}}>{trimPercent(percent)}%</NonEmph>
      </RowItem>

      <RowItem flex={2} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", minWidth: 0}}>
        <Emph style={{fontSize:"1.3em"}}>
          {
            tierSelection.map((tier,i) =>
              tier && tier !== "–" &&
              <div>
                <Popup
                  inverted size="small" position="left center"
                  content={decodeURIComponent(tier)}
                  trigger={
                    <Link
                      to={urlBase + TIERS.slice(0,i+2).join(",") + "/" + urlEncodeTier(tierSelection.slice(0,i+1)).join("/")}
                      style={{fontSize: (20-(i*2)),paddingLeft: `${i*5}px`}}>
                      { i > 0 && <span>&#x221F;</span> } {decodeURIComponent(tier)}
                    </Link>
                  }
                />
              </div>
            )
          }
        </Emph>
      </RowItem>

      <RowItem>
        <Emph>{(total == parseInt(total)) ? d3.format(",")(total) : d3.format(",.2f")(total)}</Emph>
      </RowItem>
      <RowItem>
        <Emph>{numberFormatter.format(rev_total)}</Emph>
      </RowItem>
      <RowItem>
        <Emph>{rpa > 0 ? spendFormatter.format(rpa) : "-"}</Emph>
      </RowItem>
      <RowItem>
        <Emph>{(spend_total>0) ? spendFormatter.format(spend_total) : numberFormatter.format(0)}</Emph>
      </RowItem>
      <RowItem>
        <Emph>{cpa == 0 ? numberFormatter.format(cpa) : spendFormatter.format(cpa)}</Emph>
      </RowItem>
      <RowItem>
        <Emph>{roas == 0.00 ? roas : d3.format(",.2f")(roas)}</Emph>
      </RowItem>
      <RowItem flex={1.5}>
        <div style={{width:"150px",height:"30px",marginRight:"50px"}}>
          <DailyChart data={count} refPrefix={index} width={200} height={30} forcedBuckets={range} startDate={startDate}/>
        </div>
      </RowItem>
      <RowItem flex={0.5}>
        { tierSelection.length == 1 && tierSelection[0] !== 'Direct' ?
            <Button
              style={{marginLeft:"15px",maxWidth:"30px"}}
              compact small
              basic
              as={Link}
              icon='chart pie'
              to={`/v2/customers/converters/view/histogram/${id}/${endDate}/${endDate}/${ntfFilter}/${tierSelection[0]}`}/>
            : null
        }
      </RowItem>
    </Row>
  )
}
