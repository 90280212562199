import React from 'react';
import { connect } from 'react-redux';
import { push, withRouter } from 'react-router-dom';

import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

import { SET_REPORT_TYPES } from '../ReportIndex/constants';

const GET_REPORT_TYPES_ENDPOINT = '/reporting/types';

const err = () => console.log("error")

function mapStateToProps (state, props) {
  return {
    reportTypes: state.reports.reportTypes
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    setReportTypes: (reportTypes) => dispatch(rootAction(SET_REPORT_TYPES, reportTypes)),
  }
}

const Component = (props) => [
  <Fetch error={err} endpoint={GET_REPORT_TYPES_ENDPOINT} success={props.setReportTypes} />
]

const ReportTypeData = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component))

export default ReportTypeData;
