import React, { useEffect, useState, useMemo } from "react"
import { useAtom } from 'jotai'
import { columnsAtom, tiersAtom, goalAtom } from "../../../atoms"
import { generateCols } from "./platformConfig/displayHelpers"
import { generateTier1DisplayNames, generateFirstColumn, generateColsComparison, generateSegmentMetrics } from "../Comparison/helpers"
import { usePlatformPerformanceConfig } from "./platformConfig" 
import { prettyDisplayNames, verifyColsUrl, getColumnNames, arrayMatches } from "../helpers"

export const useTreeColumns = (
  view,
  platform, model, integrations, // for comparison cols
  modelOptions, hasRevenue, optional_metrics, // for comparison segment metrics
  currencyCode, tier
) => {

  const [colsUrl, setColsUrl] = useAtom(columnsAtom)
  const [_tiers, setTiers] = useAtom(tiersAtom)
  const [goal] = useAtom(goalAtom)
  const [tiersNameDisplay, setTiersNameDisplay] = useState('')

  // const [_view, setView] = useState(view) // To Do: for switching tabs reset
  const setSelectedColumns = (selected) => {
    const columnNames = getColumnNames(selected)
    setColsUrl(columnNames)
  }

  // platform performance config
  const performanceConfig = usePlatformPerformanceConfig();
  const { platformConfig, hasOptionSelected } = performanceConfig;

  // comparison metrics and config
  const segmentMetrics = generateSegmentMetrics(modelOptions, hasRevenue, optional_metrics)
  const modelDisplayName = useMemo(() => {
    if (modelOptions.length === 0) return ''
    return modelOptions.filter(obj => obj.value == model).pop()?.text || ''
  }, [modelOptions, model])

  const allTiers = useMemo(() => {
    if (view === 'platform') return platformConfig.hierarchy.slice(1)
    if (view === 'comparison') return ["tier_3","tier_4","tier_5"]
  }, [view, platformConfig])

  const allColumns = useMemo(() => {
    if (view === "platform") {
      // hasOptionSelected initially false - not sure what this is dependent on but might cause race condition with saved views
      const platformAllColumns = generateCols({ platformConfig, hasOptionSelected, currencyCode, goal, tier })
      setTiersNameDisplay(platformAllColumns[0].display)
      return platformAllColumns
    }

    if (view === "comparison") {
      if (!integrations || modelOptions.length === 0) return
      const { icon } = integrations[platform] || {}
      if (!icon) return
      
      const tierNames = generateTier1DisplayNames(allTiers)
      setTiersNameDisplay(tierNames)
      const firstColumn = generateFirstColumn(allTiers, tierNames)
      const comparisonCols = generateColsComparison(segmentMetrics, model, modelDisplayName, icon, firstColumn, currencyCode)
      return comparisonCols
    }
  }, [view, hasOptionSelected, integrations, model, modelOptions, platform, goal, colsUrl])

  const { groupBy = [] } = useMemo(() => (
    allColumns?.find(c => c.groupBy) || {}
  ), [allColumns])
  
  const tiers = useMemo(() => {
    // if (_view !== view) return allTiers
    if (!_tiers || _tiers.length === 0) {
      return allTiers
    }

    let found = false

    if (view === 'platform') {
      found = _tiers[0].value ? !!allTiers.find((tier) => tier.value === _tiers[0].value) : false
    }
    if (view === 'comparison') {
      found = !!allTiers.find((tier) => tier === _tiers[0])
    }
    if (!found) {
      return allTiers
    }
    
    return _tiers
  }, [_tiers, allTiers])

  useEffect(() => {
    if (arrayMatches(tiers, _tiers) || !_tiers || _tiers.length === 0) return
    setTiers([]) //  todo: use jotai library reset atom
  }, [tiers, _tiers])

  const updateColsTiersDisplayName = (columns) => { 
    const tier1DisplayName = tiers.map(c => 
        c.text ? c.text.replace(" name", "") : (prettyDisplayNames(c))
    ).join(" / ");
    const tiersColumnIndex = columns.findIndex(col => col.key === 'group')
    setTiersNameDisplay(tier1DisplayName)
    const updatedCols = columns.slice()
    updatedCols[tiersColumnIndex].display = tier1DisplayName;
    return updatedCols;
  }
  
  const selectedColumns = useMemo(() => {
    // TODO: 
    // if tab is switched (but not through saved view modal), reset columns (even when selected columns in platform and comparison are valid) versus saved view is clicked and set to colsUrl
    
    if (!allColumns) return []
    // if (_view !== view) return allColumns

    if (colsUrl.length === 0 || tiers.length === 0 || tiersNameDisplay.length === 0) {
      let selected = allColumns
      if (tiers.length > 0) {
        selected = updateColsTiersDisplayName(allColumns)
      }
      return selected
    }
    
    const verify = verifyColsUrl(colsUrl, allColumns)
    if (!verify) {
      return allColumns
    }

    const findSelectedColumns = colsUrl.map((key) => allColumns.find((col) => key === col.key))
    const updatedCols = findSelectedColumns.length > 0 ? updateColsTiersDisplayName(findSelectedColumns) : findSelectedColumns
    return updatedCols
      
  }, [view, allColumns, colsUrl, tiers, tiersNameDisplay])

  useEffect(() => {
    const columnNames = getColumnNames(selectedColumns)
    if (arrayMatches(colsUrl, columnNames) || colsUrl.length === 0) return
    setColsUrl([])
  }, [colsUrl, selectedColumns])

  return { 
    allTiers,
    tiers, 
    setTiers,
    allColumns,              // used by the drawer (all Columns)
    selectedColumns,         // used by the tree and drawer (selected Columns)
    setSelectedColumns,      // used by the drawer (set selected Columns)
    groupBy,
    modelDisplayName
  }
}
  