import React, { Component, useMemo } from 'react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Input, Button } from 'semantic-ui-react';
import * as Tree from '../TreeParts';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { filterTree } from '../AttributableOverview/tree';
import { testTransformTableData, getTransformTableData } from '../../utils/api';

const cols = [
  "spend_key",
  "transform_tier_1",
  "transform_tier_2",
  "transform_tier_3",
  "transform_tier_4",
  "transform_tier_5",
].map(key => { return { key, display: key} })

const TreeRow = ({row, pos=0}) => {
  const RowComponent = (
    <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd"}}>
      <Tree.Column flex={1}>{ row.key }</Tree.Column>
      <Tree.Column style={{textAlign:"right"}}>{ row.count }</Tree.Column>
      <Tree.Column flex={1} style={{marginRight:15*(1/2)*pos}} />
    </Tree.Row>
  )

  return (
    <Tree.Collapsible open={pos < 2} title={RowComponent} style={{}} useBorder alignOnOpen>
      { row.values && row.values.length > 0 && row.values[0].values ? 
        row.values.map(innerRow => <TreeRow {...{row: innerRow, pos: pos+1}} />) : 
        <IndexGrid {...{cols, data: row.values.map(({row}) => row) }} />
      }
    </Tree.Collapsible>
  )
}

const sumRecursive = (node) => {
  if (!node.count) node.count = node.values.reduce((p,c) => p + sumRecursive(c), 0 )
  return node.count
}

const TableData = ({ title, rawData, searchValue, children, loading }) => {

  const nestedData = React.useMemo(() => {

    if (!rawData || rawData.length == 0) return rawData;

    const values = d3.nest()
      .key(row => row.transform_tier_1)
      .key(row => row.transform_tier_2)
      .key(row => row.transform_tier_3)
      .key(row => row.transform_tier_4)
      .key(row => row.transform_tier_5)
      .rollup(values => {
        return values.map(obj => { return {row: obj, count: 1}} )
      })
      .entries(rawData)

    values.map(sumRecursive)

    return values

  }, [rawData])


  const data = React.useMemo(() => {
    return filterTree({key: "", "values":nestedData || []}, searchValue, (text, node) => {
      return node.key && node.key.includes(text)
    })["values"]
  }, [nestedData, searchValue])


  return (
  <div style={{marginRight:10,flex:1,paddingTop:10}}>
    <h5>{ title }</h5>
    { children }
    <div>{ loading && <ContentCard.CardLoader /> }</div>
    {
      !loading && <ContentCard hasTable >
        { data.map(row => (<TreeRow {...{row}} />)) }
      </ContentCard>
    }
  </div>
  )
}

const TransformTableResults = ({ transformTableId, transform_table, edit }) => {

  const [searchValue, setSearchValue] = React.useState("");
  const [rawData, setRawData] = React.useState(null);
  const [rawTestData, setRawTestData] = React.useState(null);
  const [rawDataLoading, setRawDataLoading] = React.useState(true);
  const [rawTestDataLoading, setRawTestDataLoading] = React.useState(false);

  React.useEffect(() => {
    setRawData(null);
    setRawTestData([]);
    getTransformTableData(transformTableId)
      .then(setRawData)
  }, [transformTableId])

  React.useEffect(() => { setRawTestDataLoading(rawTestData == null) }, [rawTestData])
  React.useEffect(() => { setRawDataLoading(rawData == null) }, [rawData])

  const onChange = (_, evt) => setSearchValue(evt.value)

  const handleTest = () => {
    transform_table.modified = false;
    setRawTestData(null);
    const postObj = transform_table;
    const acetlReportId= postObj.acetl_report_id;

    testTransformTableData(postObj, acetlReportId)
      .then(setRawTestData)
  }

  return (
    <React.Fragment>
      <div>
        <Input icon='search'  type="text" placeholder="Find an event" value={searchValue} {...{onChange}} />
      </div>
      <div style={{display:"flex",alignContent:"stretch"}}>
        <TableData {...{title: "Current", transformTableId, searchValue, rawData, loading: rawDataLoading}} />
        {edit && 
          <TableData {...{title: "Preview Changes", transformTableId, searchValue, rawData: rawTestData, loading: rawTestDataLoading}}>

            <div style={{textAlign:"center"}}>
              { !transform_table.modified && (!rawTestData || !rawTestData.length) ?
                "Modify the transform table settings and preview the updates to the tier structure here." : transform_table.modified ?
                <Button onClick={handleTest} content="Test Changes" /> : ""
              }
            </div>
          </TableData>
        }
      </div>
    </React.Fragment>
  )
}

export default TransformTableResults;
