import React, { Component } from 'react';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { getDatasetSchemaStartEnd} from '../../utils/api';
import { parseLucene } from '../InfluencerResults/luceneFilter';
import DateRangeHooksUrlState from '../helpers/DateRangeHooksUrlState';
import { useRouteDates } from '../helpers/DateRangeHooksUrlState/hooks';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { checkJobSubmissionDate, trackJobV2 } from "../../utils/job_api";
import { getConversionSegments, submitPipelineAgg, submitEventsAgg } from "../../utils/api";
import { useURLState } from "../hooks";
import { Segment, Input, Form, Dropdown } from 'semantic-ui-react'

import BackfillStatus from "./BackfillStatus";
import BackfillSubmission from "./BackfillSubmission";

import moment from "moment";
import { extendMoment } from 'moment-range';
const daysAgo = (days) => moment.utc().utcOffset(-5).subtract(days, "days").format("YYYY-MM-DD")

const TYPE_OPTIONS = [
  {"text": "All", "value": "all"},
  {"text": "Featured", "value": "featured"},
  {"text": "Onsite", "value": "onsite_lookup"},
  {"text": "Batch", "value": "batch_lookup"}
]
const DATATYPE_OPTIONS = [
  {"text": "Conversion Data", "value": "conversion_data"},
  {"text": "Attribution Data", "value": "compiled_mta_tiers"},
]

const RERUN_OPTIONS = [
  {"text": "Full Pipeline", "value": "pipeline"},
  {"text": "Events", "value": "compile"},
]

export const defaultCols = [
  { display: "Name", key: "action_name" },
  { display: "Conversion Key", key: "conversion_key_name" },
];

const DatasetEvaluation = (props) => {

  //const { segments } = props;

  const username =  document.cookie.split("user_name=\"")[1].split("\";")[0];
  const { startDate, endDate } = useRouteDates()

  const [segments, setSegments] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [submit, setSubmit] = React.useState(0)
  const [searchValue, setSearchValue] = React.useState("")
  const [before, setBefore] = React.useState("")
  const [after, setAfter] = React.useState("")
  const [conversionData, setConversionData] = React.useState([])
  const [compiledData, setCompiledData] = React.useState([])
  const [segmentType, _setSegmentType] = useURLState("segmentType","all")
  const [datasetType, _setDatasetType] = useURLState("datasetType","conversion_data")
  const setSegmentType = (_, { value }) => _setSegmentType(value)
  const setDatasetType = (_, { value }) => _setDatasetType(value)


  React.useEffect(() => {
    getConversionSegments()
      .then(data => {
        const conversions = data
          .filter(segment => segment.action_type == "conversion")
          .filter(segment => !segment.is_child)


        setSegments(conversions)
      })
  }, [])

  React.useEffect(() => { setSubmit(submit +1) }, [before, after, searchValue, segmentType])

  React.useEffect(() => {

    if (segments.length == 0) return;

    const ids = segments.map(row => row.action_id)
    const segmentsObject = segments.reduce((p,c) => Object.assign(p, {[c.action_id]: c}), {})

    setIsLoading(true)

    const conversionPromises = ids.map(id => getDatasetSchemaStartEnd(datasetType, id, "attribution_events", startDate, endDate))

    Promise.all(conversionPromises)
      .then((responses) => {
        const all = responses.reduce((p, resp, i) => {
          const { response } = resp
          const { current } = response
          const current_id = `${ids[i]}`

          const allMap = d3.nest()
            .key(row => row.date)
            .map(response['all'])

          current.map(row => Object.assign(row, { 
            fill_date: moment(row.updated_at*1000).format("YYYY-MM-DD"), 
            fill_date_int: parseInt(moment(row.updated_at*1000).format("YYYYMMDD")),
            action_id: current_id, 
            name: segmentsObject[current_id].action_name, 
            all: allMap[row.date] 
          }))

          return [...p, ...current]

        }, [])

        setConversionData(all)
        setIsLoading(false)
      })

  }, [datasetType, segments, startDate, endDate])

  const filteredSegments = React.useMemo(() => {
    if (segmentType == "all") return segments
    if (segmentType == "featured") return segments.filter(row => row.featured)
    return segments.filter(row => segmentType.includes(row.type))
  }, [segments,segmentType])

  const segmentsObject = React.useMemo(() => {
    return filteredSegments.reduce((p,c) => Object.assign(p, {[c.action_id]: c}), {})
  }, [filteredSegments])


  const filteredData = React.useMemo(() => {
      const keys = Object.keys(segmentsObject)
      let parsed = parseLucene(
          conversionData, searchValue, 
          (term, field) => {
            return (!term && ["spend"].includes(field)) ? 
              0 : (term && typeof(term) == "string") ? 
              term.toLowerCase() : term 
          },
          [ "name" ],
          { }
        )
        .filter(row => keys.includes(row.action_id) )

      if (before) parsed = parsed.filter(row => row.fill_date < before)
      if (after) parsed = parsed.filter(row => row.fill_date > after)

      return parsed
    }, 
    [submit, segmentsObject, conversionData]
  )


  const cData = React.useMemo(() => {

    const _filteredData = filteredData
      .map(row => {
        const name = segmentsObject[parseInt(row.action_id)]['action_name']
        return Object.assign({name, date: row.date, fill_date: row.fill_date, action_id: row.action_id})
      })

    const rollup = d3.nest()
      .key(row => row.name)
      .key(row => row.date)
      .rollup(values => values[0].fill_date)
      .map(_filteredData)

    const values = Object.entries(rollup)
      .map(([k,v]) => {
        return Object.assign({name: k}, v)
      })
      
    return values

  }, [filteredData])

  const colObj = cData.reduce((p,c) => Object.assign(p,c), {})
  const _colKeys = Object.keys(colObj)
  const namedKeys = _colKeys.filter(key => !key.includes("202"))
  const dateKeys = _colKeys.filter(key => key.includes("202")).sort()
  const colKeys = [...namedKeys, ...dateKeys]
    
  const dateKeysAll = Array.from(conversionData.reduce((p,row) => p.add(row.fill_date), new Set())).sort()
  const dateOptions = dateKeysAll.map(text => Object.assign({ text, value: text }))

  const cols = cData.length ? IndexGrid.arrayToColumns(colKeys) : []

  const jobType = datasetType == "conversion_data" ? "pipelineAggStatus": "eventsAggStatus"


  return <SplitLayout leftWidth={13} rightWidth={3} 
    leftContent={
    <>
      <ContentCard 
        title="Filter Segments by Fill Status" 
        topRight={<DateRangeHooksUrlState startDate={startDate} endDate={endDate} />}
      >
        <Form>
          <Segment.Group horizontal>
            <Segment secondary>
              <Form.Field>
                <label>Dataset</label>
                <Dropdown value={datasetType} options={DATATYPE_OPTIONS} selection onChange={setDatasetType} />
              </Form.Field>
              <Form.Field>
                <label>Segment Type</label>
                <Dropdown value={segmentType} options={TYPE_OPTIONS} selection onChange={setSegmentType} />
              </Form.Field>
              <Form.Field>
                <label>Name Search</label>
                <Input onChange={(_, { value }) => setSearchValue(value)} />
              </Form.Field>
            </Segment>
            <Segment secondary>
              <Form.Field>
                <label>Filled before</label>
                <Dropdown selection clearable search options={[...dateOptions].reverse()} onChange={(_, { value }) => setBefore(value)} />
              </Form.Field>
              <Form.Field>
                <label>Filled after</label>
                <Dropdown selection clearable search options={dateOptions} onChange={(_, { value }) => setAfter(value)} />
              </Form.Field>
            </Segment>
          </Segment.Group>
        </Form>
      </ContentCard>
      <ContentCard title="By Cache Fill Date">
        { !!cData.length && <IndexGrid data={isLoading ? false : cData} cols={cols} /> }
      </ContentCard>
    </>
    }
    rightContent={<>
      <BackfillSubmission {...{filteredData, datasetType}} />
      <BackfillStatus {...{filteredData, segments: segmentsObject, jobType}} />
    </>
    }
  />
}

export default DatasetEvaluation;
