export const extractAttribution = (query) => {
  const derivedAttribution = {}
  try {
    // set for CORS
    document.domain = 'rockerbox.com'
    const parentPage = window.top
    const parentLocation = parentPage.location

    // extract query params
    const urlSearchParams = new URLSearchParams(parentLocation.search)
    Object.assign(derivedAttribution, Object.fromEntries(urlSearchParams.entries()))

    // add url, path, referrer
    derivedAttribution['url'] = parentLocation.href
    derivedAttribution['path'] = parentLocation.pathname
    derivedAttribution['referrer'] = parentPage.document.referrer
  } catch {}

  const attributionObj = {}
  try {
    const attributionQuery = query.get('attribution')
    const attributionStr = atob(attributionQuery)
    Object.assign(attributionObj, JSON.parse(attributionStr))
  } catch {}

  const attribution = { ...derivedAttribution, ...attributionObj }
  return attribution
}
