import React, { useState, useEffect } from 'react';
import { useStepsDisplay } from '../StepsSectionContext';
import { postSnowflakeTable } from '../../../api/datalake';

import CreateTable from '../components/CreateTable';

const SetupClientTables = () => {
  const [active, setActive] = useState(false)
  const [stepsState] = useStepsDisplay();

  useEffect(() => {
    setActive(stepsState[1])
  }, [stepsState])

  return (
    <CreateTable
      active={active}
      createTable={postSnowflakeTable}
    />
  )
}

export default SetupClientTables
