import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';

export const calcWeekly = (currentDate, data, weeklyNames) => {

  const evalDate = (date) => {

    const thisDate = moment(date).toDate()

    if (thisDate > moment(currentDate).subtract(7, 'days').toDate()) {
      return weeklyNames[0]
    }
    if (thisDate > moment(currentDate).subtract(14, 'days').toDate()) {
      return weeklyNames[1]
    }
    if (thisDate > moment(currentDate).subtract(21, 'days').toDate()) {
      return weeklyNames[2]
    }
    if (thisDate > moment(currentDate).subtract(28, 'days').toDate()) {
      return weeklyNames[3]
    }
  }

  const allKeys = {}

  const weeklyData = d3.nest()
      .key(x => evalDate(x.date))
      .rollup(values => {
        return values.reduce((p,c) => {
          Object.keys(c).map(k => {
            if (k != "date") {
              p[k] = (p[k] || 0) + c[k];
              allKeys[k] = 0
            }
          })
          return p
        },{})
      })
      .map(data)

  return weeklyData

}
