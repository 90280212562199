import React, { useEffect, useMemo } from 'react'
import { init } from 'commandbar'

import CacheContext from '../../../utils/CacheContext'
import { getSegments, getSavedReports, getArtifacts } from '../../api/attribution'
import { getUser, getAccount, getAuthorizations, getAllIntegrations } from '../../api/account'
import { PLATFORMS } from '../AdvertisingPlatforms/constants'
import { buildPath, buildHash } from '../../hooks/urlState'

/* 
To Access Command Bar UI Editor: open command bar (cmd + k) then type in 'open editor' 
Text will turn green and you can access editor there. Username and Password is in Passbolt under command bar.
*/

const COMMAND_BAR_ACCOUNT_ID = '249f86b4'

const CommandBar = ({ history }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const user = Context.getCache(`user`, getUser) || null
  const account = Context.getCache(`account`, getAccount) || null
  const segments = Context.getCache(`segments`, getSegments) || null
  const savedReports = Context.getCache(`savedReports`, getSavedReports) || null
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null
  const allIntegrations = Context.getCache(`allIntegrations`, getAllIntegrations) || null
  const { intercom_surveys } = Context.getCache(`artifacts`, getArtifacts) || {}

  const intercomSurveyId = useMemo(() => {
    if (!intercom_surveys) return null
    const intercomSurveys = JSON.parse(intercom_surveys || '{}')
    const survey = intercomSurveys['general_feedback'] || {}
    const intercomEnv = window.env === 'prod' ? 'prod' : 'dev'
    const surveyId = survey[intercomEnv]
    return surveyId
  }, [intercom_surveys])

  const navigate = (route) => {
    history.push(route)
    window.dispatchEvent(new Event('hashchange'))
  }

  const startIntercomChat = () => !!window.Intercom && window.Intercom('show')

  const submitIntercomFeedback = () => {
    !!window.Intercom && window.Intercom('startSurvey', intercomSurveyId)
  }

  useEffect(() => {
    init(COMMAND_BAR_ACCOUNT_ID)
    window.CommandBar.addRouter(navigate)
    window.CommandBar.addCallback('startChat', startIntercomChat)
  }, [])

  useEffect(() => {
    if (!intercomSurveyId) return
    window.CommandBar.addCallback('submitFeedback', submitIntercomFeedback)
  }, [intercomSurveyId])

  useEffect(() => {
    if (!user || !account) return
    const { username, email } = user
    const { tier, pixel_source_name, advertiser_name } = account || {}
    window.CommandBar.boot(username, {
      email,
      tier,
      accountId: pixel_source_name,
      accountName: advertiser_name,
    })
  }, [user, account])

  useEffect(() => {
    if (!segments) return
    const accountSegments = segments
      .filter(segment => segment.action_type === 'conversion')
      .map(x => ({
        label: x.action_name,
        id: x.action_id,
        action_type: x.action_type,
      }))
    window.CommandBar.addContext("segments", accountSegments)
  }, [segments])

  useEffect(() => {
    if (!savedReports) return
    const buildFullPath = (savedReport) => {
      const rebuiltPath = buildPath(savedReport.route, savedReport.params.route)
      const rebuiltHash = savedReport.params.hash ? buildHash(savedReport.params.hash) : ''
      return rebuiltPath + rebuiltHash
    }

    const savedReportsPaths = savedReports.map(x => ({
      label: x.name,
      id: x.id,
      path: buildFullPath(x),
    }))
    window.CommandBar.addContext("savedReports", savedReportsPaths)
  }, [savedReports])

  useEffect(() => {
    if (!authorizations || !allIntegrations) return
    const authedPlatforms = authorizations
      .filter(x => x.authorizations.some(y => !!y.account_id) && Object.keys(PLATFORMS).includes(x.platform))
      .map(x => x.platform)
    const digitalAdPlatforms = allIntegrations
      .filter(x => authedPlatforms.includes(x.platform) && Object.keys(PLATFORMS).includes(x.platform))
      .map(x => ({
        label: x.display_name,
        id: x.platform,
        icon: `https://images.rockerbox.com/integrations/${x.icon_img}`,
      }))
    window.CommandBar.addContext("digitalAdPlatforms", digitalAdPlatforms)
  }, [authorizations, allIntegrations])

  return null
}

export default CommandBar
