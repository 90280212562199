import React, { Component } from 'react';

import { Message, Dropdown, Segment, Form, Button, Table, Popup, Label, Icon } from 'semantic-ui-react'
import { SplitLayout, IndexGrid, PagedIndexGrid, ContentCard } from '@rockerbox/styleguide';

import MultiselectTwoSides from 'react-multiselect-two-sides';
//import './select.css' in EventTags/form.js
const OPTIONS = [
  {text: "By Action Name", value: "input"}, 
  {text: "By Comma String", value: "parsed_input"}, 
  {text: "Choose Action Name", value: "options"}
]

const ActionSelection = ({ options, value, handleChange }) => {

  const [showOptions, setShowOptions] = React.useState(false)
  React.useEffect(() => {
    if (options && (showOptions == false)) {

      const isMulti = (value.length > 1);
      const inOptions = options.filter(row => value.includes(row.value)).length > 0;

      isMulti || inOptions ? 
        setShowOptions("options") :
        setShowOptions("input")
    }
  },[value, options])


  const dropdownLabel = <Dropdown style={{paddingBottom:"4px"}}
    value={showOptions}
    onChange={(_, {value}) => setShowOptions(value) }
    options={OPTIONS}
  />

  if (showOptions == "options") {

    const allOptions = options.concat(!!value && value.map(value => { return {text: value, value}}))

    return <div style={{width:"100%"}}>
      { dropdownLabel }
      { options.length > 10 ? <MultiselectTwoSides
          options={allOptions}
          value={value}
          onChange={(v) => {
            const value = typeof(v) == "object" ? v : [v]
            handleChange({value}, "events")
          }}
          className="msts_theme_example"
          availableHeader="Actions Available"
          availableFooter={`Available: ${options.length}`}
          selectedHeader="Selected"
          selectedFooter={`Selected: ${value.length}`}
          labelKey="text"
          valueKey="value"
          showControls
          searchable
        /> : 
        <Form.Select
          multiple
          search
          inline
          options={options}
          value={value}
          onChange={(e,data) => handleChange(data, "events")}
          fluid label="Select events"
          placeholder='Choose the event value you would like to associate with your conversion'
        />
      }
    </div>
  }

  
  if (showOptions == "parsed_input") return <Form.Field>
    { dropdownLabel }
    <Form.Input value={value} onChange={(e,{ value }) => handleChange({value: value.split(",").map(v => v.trim())}, "events")} />
    <Message content="This input will not allow commas but will allow multiple patterns to be entered as a commas separated list" />
  </Form.Field>

  return <Form.Field>
    { dropdownLabel }
    <Form.Input value={value} onChange={(e,{ value }) => handleChange({value: [value]}, "events")} />
  </Form.Field>

}

export default ActionSelection;

