import React, { Component, useState, useEffect } from 'react'
import rdmd from '@readme/markdown'
import { Header } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'
import { postFormattedHelpDoc } from '../../api/attribution';
import { MarkdownViewer } from './markdownViewer'

import '@readme/markdown/dist/main.css'

const Help = ({ slug, context, includeTitle }) => {
  const [doc, setDoc] = useState(null)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    postFormattedHelpDoc(slug, context)
    .then(doc => {
      setDoc(doc)
      setLoading(false)
    })
  }, [])
  
  useEffect(() => {
    postFormattedHelpDoc(slug, context)
    .then(doc => {
      setDoc(doc)
      setLoading(false)
    })
  }, [context])
  
  if (!doc || loading) return <ContentCard.CardLoader />

  if (doc) {
    const { title, updatedAt, markdown } = doc    
    return (
      <>
        {includeTitle &&
          <Header
            as='h1'
            content={title}
            subheader={updatedAt}
          />
        }
        <MarkdownViewer className='readme-docs'>
          {rdmd(markdown)}
        </MarkdownViewer>
      </>
    )
  }
}

export default Help
