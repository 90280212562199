import styled from 'styled-components'

export const MarkdownViewer = styled.div`
  p {
    font-size: 1.25em;
  }

  ol li,
  ul li {
    font-size: 1.15em;
    line-height: 1.5em;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 50vw;
  }

  code {
    padding: 5px;
    border-radius: 5px;
    background: #efefef;
    color: #026cac;
    font-size: 1.1em;
    font-weight: 600;
  }

  .lightbox,
  .heading > a.heading-anchor-icon {
    display: none !important;
  }

  table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: separate;
    border-spacing: 0;
  }

  table tfoot th,
  table thead th {
    cursor: auto;
    background: #F9FAFB;
    color: rgba(0,0,0,.87);
    text-transform: none
  }

  table tfoot tr>th:first-child,
  table thead th,
  table thead tr>th:first-child {
    border-left: none
  }

  table:first-child {
    margin-top: 0
  }

  table:last-child {
    margin-bottom: 0;
  }

  table td,
  table th {
    transition: background .1s ease,color .1s ease
  }

  table thead th {
    text-align: inherit;
    padding: .91666667em .75em;
    vertical-align: inherit;
    font-style: none;
    font-weight: 700;
    border-bottom: 1px solid rgba(34,36,38,.1)
  }

  table td,
  table tfoot th {
    padding: .75em;
    text-align: inherit
  }

  table thead tr:first-child>th:first-child {
    border-radius: .33333333rem 0 0
  }

  table thead tr:first-child>th:last-child {
    border-radius: 0 .33333333rem 0 0
  }

  table thead tr:first-child>th:only-child {
    border-radius: .33333333rem .33333333rem 0 0
  }

  table tfoot th {
    border-top: 1px solid rgba(34,36,38,.15);
    vertical-align: middle;
    font-weight: 500;
  }

  table tfoot tr:first-child>th:first-child {
    border-radius: 0 0 0 .33333333rem
  }

  table tfoot tr:first-child>th:last-child {
    border-radius: 0 0 .33333333rem
  }

  table tfoot tr:first-child>th:only-child {
    border-radius: 0 0 .33333333rem .33333333rem
  }

  table tr td {
    border-top: 1px solid rgba(34,36,38,.1)
  }

  table tr:first-child td {
    border-top: none
  }

  table tbody+tbody tr:first-child td {
    border-top: 1px solid rgba(34,36,38,.1)
  }

  .rdmd-code-copy {
    display: none;
  }
`
