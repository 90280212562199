import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Button, Form } from 'semantic-ui-react';
import Container from '../parts/Container';
import { formatTiersValue } from '../ViewAttribution/helpers';
import moment from 'moment';

const SaveStatus = (props) => (
  <Container center>
    { props.success ?
      <Container center column>
        <h5>Your report has been saved! Check the Exports tab to see it and your other reports</h5>
        <Container>
          <Button onClick={props.done}>Done</Button>
        </Container>
      </Container> :
      <Container center column>
        <h5>This is awkward, but there was an error saving your report.</h5>
        <Container>
          <Button onClick={props.reset}>Try Again</Button>
          <Button onClick={props.done}>Cancel</Button>
        </Container>
      </Container>
    }
  </Container>
);

const initialState = {
  name: "",
  success: undefined,
  resolved: true
}

class SaveForm extends Component {

  state = Object.assign({}, initialState)

  onReset = () => {
    this.setState(Object.assign({}, initialState))
  }

  onSubmit = (evt) => {

    const { exportType, tiers, save, pixel_source_name, filter_id, reportTypes } = this.props
    const { name } = this.state

    const report_type_id = reportTypes.find((t) => t.report_type === exportType).id;

    const obj = {
      report_type_id: report_type_id,
      num_days: 1,
      filter_id: filter_id,
      filters: formatTiersValue(tiers),
      name: name
    }

    this.setState({resolved: false});

    save(obj).then(x => {
      this.setState({success: true});
      this.setState({resolved: true});
    }).catch(x => {
      this.setState({success: false});
      this.setState({resolved: true});
    })

    evt.preventDefault()
  }

  onFieldChange = (fieldName) => (evt, data) => {
    this.setState({[fieldName] : data.value});
  }

  render() {

    const { state, props, onSubmit, onFieldChange, onReset } = this;

    if (state.success !== undefined) return (<SaveStatus {...props} {...state} reset={onReset} />)

    return (
        <Container center>
          <Form onSubmit={onSubmit}>
            <Input value={state.name} name="recipient" placeholder="name" onChange={onFieldChange("name")} />
            <Button type="submit" disabled={!this.state.resolved}>Save Report</Button>
          </Form>
        </Container>
      )
  }
}


const SaveReportForm = (props) => {
  return <SaveForm {...props} save={props.save} schedule={props.schedule} />
}

SaveReportForm.propTypes = {
  save: PropTypes.func.isRequired,
}

export default SaveReportForm;
