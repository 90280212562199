import React, {useState} from 'react'
import { Card, Grid, Button, Icon, Popup, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { ArrowCircleRightIcon, XIcon } from '@heroicons/react/solid'
import { track } from '../../../../utils/tracking'

const Container = styled.div`
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
`

const CalloutText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
`
const StyledMessage = styled(Message)`
  box-shadow: none !important;
  background-color: #EEFCFA !important;
  color: #167466 !important;
`

export const CalloutCards = (props) => {
  const { setGoalsDrawerOpen, hasGoal } = props
  
  const [customReportCardState, setCustomReportCardState] = useState(true)
  const [goalCardState, setGoalCardState] = useState(true)

  const onReportShowMeHowClick = () => {
    document.getElementById("save-view-btn").click()
  }

  return (
    <div>
      {hasGoal || (goalCardState || customReportCardState)  && <Grid columns="equal" style={{marginBottom: 10}}>
        <Grid.Row>
          <Grid.Column>
            {customReportCardState && <Card fluid style={{backgroundColor: '#fbfbfe'}}>
              <Card.Content>
                <Container>
                  <h2 style={{fontSize: 21}}>Save frequently used settings as a custom report</h2>
                  <CalloutText style={{lineHeight: 1.5}}>Adjust basic and advanced settings to generate your own data set and save the results for easy retrieval later.</CalloutText>
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
                    <Button primary style={{display: 'flex', alignItems: 'center'}} onClick={() => onReportShowMeHowClick()}>
                      Show me how
                      <ArrowCircleRightIcon style={{ display: 'inline-block', height: 16, marginLeft: 10 }} />
                    </Button>

                    <CalloutText style={{fontSize: '1rem', marginLeft: 20}} className="rb-clickable" onClick={() => setCustomReportCardState(false)}>Dismiss</CalloutText>
                  </div>
                </Container>
              </Card.Content>
            </Card>}
          </Grid.Column>
          <Grid.Column>
            {goalCardState && <Card fluid style={{backgroundColor: '#fbfbfe'}}>
              <Card.Content>
                <Container>
                  <h2 style={{fontSize: 21}}>Set goals for CPA and ROAS</h2>
                  <CalloutText style={{lineHeight: 1.5}}>Track campaigns against a fixed goal or rolling average, then receive recommendations from Rockerbox on how to optimize toward your goal.</CalloutText>
                  <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
                    <Button primary style={{display: 'flex', alignItems: 'center'}} onClick={() => {
                      setGoalsDrawerOpen(true)
                      track(`channels.digitalAdvertising.goals.card.click`)
                      }}>
                      Show me how
                      <ArrowCircleRightIcon style={{ display: 'inline-block', height: 16, marginLeft: 10 }} />
                    </Button>
                    <CalloutText style={{fontSize: '1rem', marginLeft: 20}} className="rb-clickable" onClick={() => setGoalCardState(false)}>Dismiss</CalloutText>
                  </div>
                </Container>
              </Card.Content>
            </Card>}
          </Grid.Column>
        </Grid.Row>
      </Grid>}
    </div>
  )
}

export const RemoveGoal =({resetGoal}) => {
  return (
    <Button 
    className='remove-box-shadow'
    style={{marginLeft: 5, fontSize: 14}} 
    color="purple" 
    inverted 
    onClick={() => resetGoal()}>
      <XIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
      Remove Goal
    </Button>
  )
}


export const NoGoal = ({goal, resetGoal}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span>
        N/A
        <Popup  
          hoverable={true} 
          inverted 
          content="Within the date range selected, there are days of 0 conversions which does not allow a moving average to be calculated. Try using a Fixed Target goal on this filter set." 
          position='top center'  
          trigger={<Icon name='question circle' style={{marginLeft: 5, fontSize: 14, color: '#bfc6f3', cursor: 'help', display: 'inline'}}/>}
        />
      </span>
    </div>
  )
}

export const NoEmaMessage = ({optionalMetrics}) => {
  const hasRoas = optionalMetrics.find(c => c == 'roas')

  return (
    <StyledMessage content={`Within the date range selected, there are days of 0 conversions which does not allow a moving average to be calculated. Try using ${hasRoas ?  "ROAS or switching to" : ""} a Fixed Target goal on this filter set.`}/>
  )
}

export const LowBudgetMessage = () => <Message color="yellow" style={{boxShadow: 'none'}}>
  <p>Your Spend and Budget are close, so there may not be major recommendations available.</p>
</Message>

