import React, { Component, useState, useRef } from "react";
import { Icon, Popup, Button, Table, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

export const Schedule = ({ item, col }) => {
  const little_time = new moment()
    .hours(item.time)
    .minutes(0)
    .format("LT");

  return (
    <Table.Cell>
      {item.days.split(",").length > 6
        ? "Every day"
        : item.days.replace(/,/g, ", ")}{" "}
      {little_time}
    </Table.Cell>
  );
};

export const CelledCheckmark = ({ item, col }) => (
  <Table.Cell>
    <Icon
      color={item[col.key] ? "green" : "yellow"}
      name={item[col.key] ? "checkmark" : "exclamation triangle"}
      size="large"
    />
  </Table.Cell>
);

export const TextOnly = ({ item, col }) => (
  <Table.Cell>
    {typeof item[col.key] == "object"
      ? item[col.key].map(item => item[col.displayValue]).join(", ")
      : item[col.key]}
  </Table.Cell>
);

export const Time = ({ item, col }) => {
  const time = new moment(item[col.key]).format("LLL");
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const LocalFromUTCTime = ({ item, col, UTCOffset }) => {
  const utc = new moment.tz(item[col.key], "UTC");
  const time = new moment(utc + UTCOffset).format("LLL");
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const SendButton = ({ item, script, send, popup }) => (
  <ButtonWithHover
    popupContent={popup || "Send"}
    primary
    icon="paper plane"
    size="tiny"
    onClick={() => send(item, script)}
  />
);

export const HistoryButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="View Status"
    icon="history"
    as={Link}
    to={`/v2/settings/reports/view/inspect/id/${itemId}`}
    onClick={onClick}
  />
);

export const SetupButton = ({ outboxId, onClick }) => (
  <ButtonWithHover
    popupContent="Setup Instruction"
    icon="file alternate outline"
    as={Link}
    to={`/v2/settings/destination/view/outbox/id/${outboxId}`}
    onClick={onClick}
  />
);

export const ViewReportButton = ({ itemId }) => (
  <ButtonWithHover
    popupContent="View Report"
    icon="eye"
    as={Link}
    to={`/v2/settings/conversion/view/${itemId}`}
  />
);

export const DocsButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="View Schema"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/schema/id/${itemId}`}
  />
);

export const CustomizeButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="Customize Columns"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/customize/id/${itemId}`}
  />
);

export const DownloadButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="Download"
    icon="download"
    as={Link}
    to={`/data/delivered/space/download?id=${itemId}`}
    target="_blank"
  />
);

export const ButtonWithHover = props => (
  <Popup
    inverted
    basic={props.noArrow}
    content={props.popupContent}
    trigger={<Button {...props} />}
    position="top center"
    size="mini"
  />
);

export const Spacer = () => (
  <Button icon="paper plane" inverted disabled className="visibility-hidden" />
);

export const OverlayDeleteBtn = ({ onClick }) => {
  const [popupOpen, setPopupOpen] = useState(false)

  const handleClick = (e, data) => {
    onClick(e, data)
    setPopupOpen(false)
  }

  const handleClose = () => {
    setPopupOpen(false)
  }

  return (
    <>
      <ButtonWithHover
        size="tiny"
        negative
        icon="trash alternate"
        onClick={() => setPopupOpen(true)}
        popupContent="Delete"
      />
      {popupOpen && (
        <div className="confirmation-row-overlay">
          <div className="row-delete-confirmation">
            <p>Are you sure?</p>
            <Button size="mini" negative content="Yes, Delete" onClick={handleClick}/>
            <Button size="mini" content="Cancel" onClick={handleClose}/>
          </div>
        </div>
      )}
    </>
  )

}

export class DeleteButton extends Component {
  state = {
    popupOpen: false
  };

  onDeleteClick = (e, data) => {
    this.props.onDelete(e, data);
    this.setState({ popupOpen: false });
  };

  handleClose = e => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { disabled, popupContent } = this.props;
    if (disabled) {
      return (
        <ButtonWithHover
          negative
          noArrow={true}
          popupContent={popupContent}
          icon="trash alternate"
        />
      );
    }
    return (
      <Popup
        onClose={this.handleClose}
        open={this.state.popupOpen}
        on="click"
        trigger={
          <Button
            {...this.props}
            negative
            icon="trash alternate"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ popupOpen: true });
            }}
          />
        }
        content={
          <Popup
            trigger={
              <Button
                negative
                content={this.props.popup || "Delete"}
                onClick={this.onDeleteClick}
                onMouseOut={this.handleClose}
              />
            }
            on="hover"
            content={this.props.popupContent || "Delete"}
          />
        }
      />
    );
  }
}

export class DeactivateButton extends Component {
  state = {
    popupOpen: false
  };

  onActionClick = e => {
    this.props.onChange(e);
    this.setState({ popupOpen: false });
  };

  handleClose = e => {
    this.setState({ popupOpen: false });
  };

  handleClick = e => {
    e.stopPropagation();
    this.setState({ popupOpen: true });
  };

  render() {
    return (
      <Popup
        onClose={this.handleClose}
        open={this.state.popupOpen}
        on="click"
        trigger={
          this.props.active == 1 ? (
            <Button icon="pause" onClick={this.handleClick} />
          ) : (
            <Button icon="play" onClick={this.handleClick} />
          )
        }
        content={
          <Button
            content={
              this.props.popup || this.props.active == 1
                ? "Deactivate"
                : "Activate"
            }
            onClick={this.onActionClick}
            onMouseOut={this.handleClose}
          />
        }
      />
    );
  }
}

export const ViewButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="View"
    onClick={onClick}
    icon="eye"
    as={Link}
    to={url}
  />
);

export const IconButton = ({
  col: { popupContent = "edit", icon = "pencil", onClick }
}) => {
  return (
    <ButtonWithHover
      popupContent={popupContent}
      onClick={onClick}
      icon={icon}
    />
  );
};

export const EditButton = props => (
  <ButtonWithHover
    onClick={props.onClick}
    icon="pencil"
    as={Link}
    to={props.url}
    {...props}
    popupContent={props.popupContent || "Edit"}
  />
);

export const TransformButton = props => (
  <ButtonWithHover
    onClick={props.onClick}
    icon="exchange"
    as={Link}
    to={props.url}
    {...props}
    popupContent={props.popupContent || "Transform"}
  />
);

export const CopyButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="Copy"
    onClick={onClick}
    icon="copy"
    as={Link}
    to={url}
  />
);

export const CopyLinkButtonV3 = ({ item, onClick }) => (
  <ButtonWithHover
    size="tiny"
    popupContent="Copy Link"
    onClick={onClick}
    icon="copy"
    color="purple"
  />
);

export const EditButtonV3 = ({ item, onClick }) => (
  <ButtonWithHover
    size="tiny"
    popupContent="Edit"
    onClick={onClick}
    icon="edit"
    color="purple"
  />
);

export const MatchingUrlsButton = ({ url }) => (
  <ButtonWithHover
    popupContent="View Matching URLs"
    icon="eye"
    as={Link}
    to={url}
    color="black"
  />
);

export const EllipsedCell = ({ item, col, style={} }) => (
  <Table.Cell className="ellipsed" style={style}>
    <Popup
      trigger={<span>{item[col.key]}</span>}
      content={item[col.key]}
      inverted
      hoverable
    />
  </Table.Cell>
);

export const UrlCell = ({ item, col }) => {
  const urlArray = item[col.key].split(/\?|&/);
  const urlDomain = urlArray[0];
  const urlParams = urlArray
    .filter(p => p !== urlDomain)
    .map(p => (
      <Table.Row key={p}>
        <Table.Cell>{p.split("=")[0]}</Table.Cell>
        <Table.Cell>{p.split("=")[1]}</Table.Cell>
      </Table.Row>
    ));

  return (
    <Table.Cell className="ellipsed">
      <Popup
        hoverable
        wide
        position="bottom left"
        trigger={<span>{item[col.key]}</span>}
        content={
          <React.Fragment>
            <Header as="h6" content={urlDomain} className="m-0" disabled />
            <Table celled compact="very" fluid size="small" columns={2}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Parameter</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{urlParams}</Table.Body>
            </Table>
          </React.Fragment>
        }
      />
    </Table.Cell>
  );
};

export const CollapsingCell = ({ item, col }) => (
  <Table.Cell collapsing style={{ minWidth: 70 }}>
    {item[col.key]}
  </Table.Cell>
);

export const ExpandContractCell = ({
  item,
  col,
  idKey,
  handleExpand,
  handleContract,
  expand,
  ownComponent
}) => {
  if (ownComponent) {
    return (
      <Button
        as="a"
        size="mini"
        icon={expand == item[idKey] ? "minus" : "plus"}
        onClick={() => {
          return expand == item[idKey]
            ? handleContract(item[idKey])
            : handleExpand(item[idKey]);
        }}
      />
    );
  } else {
    return (
      <Table.Cell>
        <Button
          as="a"
          size="mini"
          icon={expand == item[idKey] ? "minus" : "plus"}
          onClick={() => {
            return expand == item[idKey]
              ? handleContract(item[idKey])
              : handleExpand(item[idKey]);
          }}
        />
      </Table.Cell>
    );
  }
};

export const DownloadCell = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="Download All Reports"
    icon="download"
    size="mini"
    onClick={onClick}
  />
);

export const NumberCell = ({ item, col }) => {
  const value = item[col.key]
  let formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  })

  return (
    <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
      { value ? formatter.format(value) : ''}
    </Table.Cell>
  )
}

export const CopyCell = ({ item, col }) => {
  const value = item[col.key]
  const btnRef = useRef(null)
  const [clicked, setClicked] = useState(false)
  const handleOnClick = () => {
    navigator.clipboard.writeText(value)
    setClicked(true)

    setTimeout(() => {
      btnRef?.current?.ref?.current?.blur()
      setClicked(false)
    }, 1500)
  }

  return (
    <Table.Cell style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {value}
      {!!value &&
        <Button
          ref={btnRef}
          inverted
          icon={clicked ? 'check' : 'copy'}
          color={clicked ? 'green' : 'purple'}
          size='large'
          onClick={handleOnClick}
          className='remove-box-shadow'
        />
      }
    </Table.Cell>
  )
}
