import React, { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'

import { MiniChartCard, MiniChartCardLoader } from '@rockerbox/styleguide'

const numberFormatter = (numDecimals) => (number) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: numDecimals,
  })
  return numberFormat.format(number)
}

const MiniChartCards = ({ loading, data, hasRevenue, featuredSegmentName, currencyFormatter }) => {
  const [roas, cpa] = useMemo(() => {
    if (!data) return [0, 0]
    const totalRevenue = data.reduce((acc, x) => acc + x.revenue, 0)
    const totalSpend = data.reduce((acc, x) => acc + x.spend_total, 0)
    const totalConversions = data.reduce((acc, x) => acc + x.conversions, 0)
    const roas = totalRevenue / totalSpend
    const cpa = totalSpend / totalConversions
    return [roas, cpa]
  }, [data])

  return (
    <Grid.Row style={{ height: 150, marginBottom: 1 }}>
      <Grid.Column>
      {!!loading ? <MiniChartCardLoader /> :
          <MiniChartCard
            data={data}
            title={!!hasRevenue ? 'Blended ROAS' : 'Blended CPA'}
            formatter={!!hasRevenue ? numberFormatter(2) : currencyFormatter(2)}
            dataKey={!!hasRevenue ? 'roas' : 'cpa'}
            summaryType='custom'
            customTotal={!!hasRevenue ? roas : cpa}
            color={!!hasRevenue ? '#ffa64d' : '#E76D8E'}
          />
        }
      </Grid.Column>
      <Grid.Column>
        {!!loading ? <MiniChartCardLoader /> :
          <MiniChartCard
            data={data}
            title={!!hasRevenue ? 'Blended CPA' : featuredSegmentName}
            formatter={!!hasRevenue ? currencyFormatter(2) : numberFormatter(0)}
            dataKey={!!hasRevenue ? 'cpa' : 'conversions'}
            summaryType={!!hasRevenue ? 'custom' : 'sum'}
            customTotal={!!hasRevenue ? cpa : null}
            color={!!hasRevenue ? '#E76D8E' : '#4987BF'}
          />
        }
      </Grid.Column>
      <Grid.Column>
        {!!loading ? <MiniChartCardLoader /> :
          <MiniChartCard
            data={data}
            title={!!hasRevenue ? `Revenue (${featuredSegmentName})` : `Spend (${featuredSegmentName})`}
            formatter={currencyFormatter(0)}
            dataKey={!!hasRevenue ? 'revenue' : 'spend_total'}
            summaryType='sum'
            color={!!hasRevenue ? '#66D9AE' : '#5B57C8'}
          />
        }
      </Grid.Column>
    </Grid.Row>
  )
}

export default MiniChartCards
