import styled from 'styled-components'

export const Subheading = styled.span`
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: lighter;
`

export const Bold = styled.span`
  font-weight: 500;
  font-family: 'Poppins', 'Helvetica Neue', Arial, Helvetica, sans-serif !important
`
export const iconStyles = {
  height: '1.25em',
  margin: '0px 5px 0px 0px'
}
