import React from 'react';
import PropTypes from 'prop-types'
import { Segment, Statistic, Popup, Icon } from 'semantic-ui-react';

const StatCard = props => {
  let numberFormatOptions = {
    style: props.type ? props.type : 'decimal',
    maximumFractionDigits: props.maxDigits ? props.maxDigits : 0
  }
  if (props.type === 'currency') numberFormatOptions['currency'] = props.currencyCode || 'USD';
  if (props.type === 'percent') numberFormatOptions['style'] = 'percent';

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formattedValue = props.value ? !props.text ? numberFormatter.format(props.value) : "-" : props.value;

  return(
    <Segment color={props.segmentColor} textAlign='center' className='stat' onClick={props.onClick} style={{cursor:props.onClick ? "pointer": undefined, ...props.style}}>
      <Statistic color={props.color ? props.color : 'blue'} size={props.size} >
        <Statistic.Value text={!!props.text}>{formattedValue }</Statistic.Value>
        <Statistic.Label>
          {props.explainer ?
            <Popup hoverable inverted position='right center'
                   trigger={<span>{props.label}</span>}>
              <Popup.Content>
                {props.explainer}
              </Popup.Content>
            </Popup>
          :
            props.label
          }
        </Statistic.Label>
      </Statistic>
    </Segment>
  )
}

StatCard.propTypes = {
  /** decimal is the default, optional: currency, percent  */
  type: PropTypes.string,
  /** Value content inside of stat card*/
  value: PropTypes.number,
  /** mini, tiny, small, large, huge */
  size: PropTypes.string,
  /** Stat value color -- red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black */
  color: PropTypes.string,
  /** Smaller text under value */
  label: PropTypes.string,
  /** Label hover text */
  explainer: PropTypes.string,
  /** top border of the stat card -- red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black */
  segmentColor: PropTypes.string
}

export default StatCard
