import React, { Component } from 'react';
import { Checkbox, Radio, Label, Form, Button, Input, Grid, Icon, Segment, Dropdown, Table } from 'semantic-ui-react';

import styled from "styled-components";

const Description = styled.div`
  font-weight:bold;
  padding-bottom:5px;
`
const RadioWrap = styled.div`
  padding:8px;
  ${({ checked, showBorder }) => checked && showBorder ? "border:1.5px solid rgb(2 108 172 / 75%); border-radius:.5em;" : ""}
  ${({ disabled }) => disabled ? "" : ""}

  & .ui.disabled.checkbox.checked label {
    opacity: 1;
  }
  & .ui.checkbox.disabled input:checked~label:before {
    background: rgb(2 108 172 / 50%);
    border-color: rgb(2 108 172 / 50%);
  }
  & .ui.checkbox.disabled label:before {
    background: inherit;
  }

`

const RadioOption = ({ disabled, showBorder, multiple, label, checked, onClick, ShowSelected }) => {
  const Component = multiple ? Checkbox : Radio
  const parameters = multiple ? {defaultChecked: checked, checked: !!checked} : { checked: !!checked }
  const wrapperClick = !ShowSelected ? onClick : undefined

  return <RadioWrap {...{disabled, showBorder, checked, onClick: wrapperClick}}>
    <Component {...{label, onClick, disabled}} {...parameters} />
    { checked && ShowSelected }
  </RadioWrap>
}

const RadioOptions = (props) => {
  const { disabled, description, options, onChange, multiple, showBorder = true } = props
  const [ isInit, setIsInit ] = React.useState(true)
  const [ selected, setSelected ] = React.useState(props.value)

  React.useEffect(() => setSelected(props.value), [props.value])

  const isSelected = (value) => {
    if (!multiple) return (selected == value) ? "checked" : undefined
    return (selected||[]).find(v => v == value) ? true :  undefined
  }

  const selectRow = (row) => {
    if (!multiple) return setSelected(row.value)

    const isSelected = (selected||[]).find(cur => cur == row.value)
    isSelected ? setSelected(selected.filter(cur => cur != row.value)) : setSelected([...(selected || []), row.value])
  }

  React.useEffect(() => {
    (!isInit) ? onChange(false, { value: selected }) : setIsInit(false)
  }, [selected])

  return <div>
    { description && <Description>{ description }</Description>}
    { 
      options.map(row => {
        const { ShowSelected } = row
        return <RadioOption 
          {...{disabled, showBorder, multiple, label: row.text, checked: isSelected(row.value), ShowSelected}} 
          onClick={() => !disabled ? selectRow(row) : false} 
        />
      })
    }
  </div>
}

export default RadioOptions;
