import React, { Component } from 'react';
import { getDataset, getPinterestSegment } from '../../utils/api';
import { Dropdown, Step, Message, Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import Impact from '../Impact';

const getPinterestSegmentId = () => {
  return getPinterestSegment()
    .then(segments => (segments.length > 0) ? segments[0].filter_id : false)
}

const Pinterest = (props) => {
  return <>
    <Impact title="Pinterest Events" datasets={["pinterest_difference"]} getSegmentId={getPinterestSegmentId} />
  </>

}

export default Pinterest
