import React, { Component } from 'react';
import { Checkbox, Message, Form, Segment } from 'semantic-ui-react';
import { ColumnLayout, ContentCard } from '@rockerbox/styleguide';
import { getConversionDataset } from '../../utils/api';
import SelectConversion from '../SelectSegment';
import { SelectButtons } from '@rockerbox/styleguide';

export const FormDropdown = (props) => <Form.Dropdown {...props} select label={props.label} selection/>

const GeneralStep = ({ state, updateStateField, updateStateArrayRowField, entityType, typeOptions, trackingOptions, showName, showTracking, setIsDisabled }) => {

  const { entity_type, name, segments } = state;
  const { hasPromoCodes, hasAffiliateLinks, hasUTMParams} = state;
  const { costViaSponsorship, costViaPurchase, costViaRevenue } = state;

  const [fieldOptions, setFieldOptions] = React.useState([])

  const [firstSegment, ...otherSegments] = segments;
  const { segment_id } = firstSegment || {};

  React.useEffect(() => {
    segment_id && segment_id.length === 0 ? setIsDisabled(true) : setIsDisabled(false);
    if (segment_id) getConversionDataset(segment_id)
      .then(response => response['response']['conversion_data'])
      .then(data => {
        if (!data || data.length == 0) return;

        const fields = Object.keys(data[0])
          .filter(key => !["1","source_name","ip","request_referrer","rb_source","event_id","hash_ip","raw_message_id","timestamp","date","landing_url","user_agent","conversion_hash_id","origin","url"].includes(key))
          .map(value => { return {text: value, value}})

        setFieldOptions(fields)
      })
  }, [segment_id])

  const showSegments = (hasPromoCodes || costViaPurchase || costViaRevenue)


  return (
    <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4} >
      <ContentCard>
        { false && !entityType &&<>
          <Message
            header={"What type of marketing are you trying to track?"}
            content="We have a few hard to track channels listed here. The goal is to appropriately bucket the entity so we know how to atribute this data as it gets added to the model."
          />
          {/* <Form.Dropdown selection value={entity_type} onChange={updateStateField("entity_type")} options={typeOptions} /> */}
          <SelectButtons value={entity_type} onChange={updateStateField("entity_type")} options={typeOptions} large />
        </>
        }
        {showName && <>
          <Message header={`What is the name of this ${entity_type}?`} content={`How do you refer to this particular ${entity_type}`} />
          <Form.Input value={name} onChange={updateStateField("name")}/>
        </>
        }
        
        
        { 
          showName && segments.map(row => {
            const { segment_id = "", promo_field } = row
            return <>
              <Message header="Associate a segment" content="Since you are using 'promo-codes' or information collected at the time of conversion to attribute marketing, it is necessary to choose a conversion segment from which to capture the marketing information. To do this, we need to know the conversion segment as well as the field which contains the promo-code information." />
              <SelectConversion
                onChange={(value) => updateStateArrayRowField("segments","segment_id")(row)(false, { value }) }
                as={FormDropdown}
                skipDefaultSelection={true}
                selectedSegmentId={segment_id}
                label="Segment"
              />

              <FormDropdown
                value={promo_field}
                onChange={updateStateArrayRowField("segments","promo_field")(row)}
                options={fieldOptions}
                search
                label="Promo-code Field"
              />
              
            </>
          })
        }
        
      </ContentCard>
    </ColumnLayout>
  )
}

export default GeneralStep;
