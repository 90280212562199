import React, { useMemo, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import { CacheContext } from '../../../../utils/CacheContext'
import { getSegments } from '../../../api/attribution'
import { getAccountCurrency } from '../../../api/account'
import { getViaCache } from '../../../../utils/arrow/reportingDataCache'
import { getViaCacheDataReporting } from '../../AttributionReport/hooks/dataReportingEndpoint'
import { useURLState } from '../../../../components/hooks'

import { formatPlatformName } from './helpers'
import SpendBreakdown from './SpendBreakdown'
import MiniChartCards from './MiniChartCards'
import ComboChart from './ComboChart'
import { formatCurrency } from '../../../utils/valueFormatter'
import { track, time } from '../../../../utils/tracking'

const SummaryCharts = ({ startDate, endDate }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const currency = Context.getCache(`currency`, getAccountCurrency) || null
  const segments = Context.getCache(`segments`, getSegments) || null
  const { account } = state
  const { tier } = account || {}
  const isLite = tier === 'lite'

  const [urlStartDate, _setStartDate] = useURLState('startDate')
  const [urlEndDate, _setEndDate] = useURLState('endDate')

  const [loading, setLoading] = useState(true)
  const [conversionSummary, setConversionSummary] = useState(null)
  const [checkCurrentRaw, setCheckCurrentRaw] = useState({})

  const currencyCode = useMemo(() =>
    (!!currency?.length && currency[0]?.currency_code) || 'USD'
  , [currency])
  
  const currencyFormatter = (numDecimals) => (number) => {
    return formatCurrency(number, currencyCode)
  }

  const featuredSegment = useMemo(() => {
    if (!segments) return null
    return segments.find(s => !!s.featured)
  }, [segments])
  const featuredSegmentName = featuredSegment?.action_name || 'Orders'
  const hasRevenue = !!featuredSegment?.include_revenue

  useEffect(() => {
    !!featuredSegment && getDailySummary() && setLoading(true)
  }, [featuredSegment, startDate, endDate])

  useEffect(() => {
    // check if raw data is valid with dates 
    if (checkCurrentRaw.startDate !== urlStartDate || checkCurrentRaw.endDate !== urlEndDate) return
    const dailySummary = calculateDailySummary(checkCurrentRaw.conversionMTATiersDataAq)
    setConversionSummary(dailySummary)
  }, [checkCurrentRaw])

  const getDailySummary = async () => {
    if (!featuredSegment || !account) return
    const { action_id } = featuredSegment
    const hasDataReportingApiFlag = account.features.find(feature => feature == 'data_reporting_api')

    const conversionMTATiersDataAq = hasDataReportingApiFlag ? await getViaCacheDataReporting(startDate, endDate, "compiled_mta_tiers", action_id) : await getViaCache(startDate, endDate, "compiled_mta_tiers", action_id)
    setCheckCurrentRaw({ conversionMTATiersDataAq, startDate, endDate })
  }

  const calculateDailySummary = (conversionMTATiersDataAq) => {
    const conversionMTATiersDataRaw = conversionMTATiersDataAq.objects()
    const conversionMTATiersData = Object.values(conversionMTATiersDataRaw.reduce((obj, row) => {
      obj[row.date] = obj[row.date] || []
      obj[row.date].push(row)
      return obj
    }, {}))
    const dailySummaryData = conversionMTATiersData.map(dailyData =>
      dailyData.reduce((acc, x) => {
        acc.date = x.date
        acc.conversions += x.even || 0
        acc.revenue += x.revenue_even || 0
        acc.spend_total += x.spend || 0
        if (x.spend) {
          const platform = formatPlatformName(x.platform) || 'other'
          acc.spend[platform] = x.spend + (acc.spend[platform] || 0)
        }
        return acc
      }, {
        date: "",
        conversions: 0,
        revenue: 0,
        spend_total: 0,
        spend: {}
      })
    ).sort((a, b) => a.date > b.date ? 1 : -1)

    return dailySummaryData
  }

  const dailyData = useMemo(() => {
    if (!conversionSummary) return
    return conversionSummary.map(x => ({
      date: x.date,
      revenue: x.revenue,
      conversions: x.conversions,
      spend_total: x.spend_total,
      roas: (x.revenue || 0) / (x.spend_total || 1),
      cpa: (x.spend_total || 0) / (x.conversions || 1),
      ...x.spend,
    }))
  }, [conversionSummary])

  const spendBreakdown = useMemo(() => {
    if (!conversionSummary) return
    const spendSummary = conversionSummary.reduce((acc, x) => {
      const platforms = Object.keys(x.spend || {})
      platforms.map(p => {
        acc[p] = x.spend[p] + (acc[p] || 0)
      })
      return acc
    }, {})
    return Object.keys(spendSummary)
      .map(platform => ({
        platform,
        spend: spendSummary[platform]
      }))
      .sort((a, b) => {
        return b.spend - a.spend
      })
  }, [conversionSummary])

  const spendPlatforms = useMemo(() => {
    if (!spendBreakdown) return
    setLoading(false)
    return spendBreakdown.map(x => x.platform)
  }, [spendBreakdown])

  useEffect(() => {
    if (!!loading) {
      time('home.view')
      return
    }
    track('home.view')
  }, [loading])

  return (
    <Grid>
      <Grid.Column stretched width={5}>
        <SpendBreakdown
          {...{ loading, currencyFormatter, data: spendBreakdown }}
        />
      </Grid.Column>
      <Grid.Column width={11}>
        <Grid stretched columns='equal'>
          <MiniChartCards {...{ loading, hasRevenue, featuredSegmentName, currencyFormatter, data: dailyData }} />
          <Grid.Row>
            <Grid.Column>
              <ComboChart
                {...{ loading, hasRevenue, currencyFormatter, data: dailyData, platforms: spendPlatforms }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

export default SummaryCharts
