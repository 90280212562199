import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Container, Divider, Grid, Button, Icon, Form, Dropdown, Header } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import * as routes from '../../routes';
import Steps from './Steps';
import DataSource from './DataSource';
import MarketingChannels from './MarketingChannels';
import DefineMappings from './DefineMappings';
import { postSpend, getSpendAssignments, updateSpend, getSpendPlatform, getSpendPlatformTiers, getSpendFields } from '../../utils/api';
import _ from 'lodash';
import { createMappings, createMarketingChannels, createDataSources } from './helpers';
import { Announcements } from '../Announcements';

class SpendMappings extends Component {
  state = {
    active: {
      data: true,
      marketing: false,
      mappings: false
    },
    dataSources: {
      spendSource: '',
      spendFilters: [
        { field: '', value: '', id: 0, text: '' },
      ]
    },
    marketingChannels: {
      bucket1: '',
      bucket2: '',
      bucket3: '',
      bucket4: '',
      bucket5: ''
    },
    defineMappings: {
      name: '',
      mappings: [
        {spendField: '', tierBucket: '', id: 0},
        {spendField: '', tierBucket: '', id: 1},
        {spendField: '', tierBucket: '', id: 2},
        {spendField: '', tierBucket: '', id: 3},
        {spendField: '', tierBucket: '', id: 4},
      ]
    },
    defineOverrides: {
      tier_1: false, // {"tier_field": "tier_1", "spend_field": "tier_two_alt"}
      tier_2: false,
      tier_3: false,
      tier_4: false,
      tier_5: false
    },
    spendFieldOptions: [],
    spendDataSourceOptions: [],
    spendPlatformTierOptions: {},
    error: undefined
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const copy = this.props.match.path.includes('copy') ? true : false;
    if(id) {
      getSpendAssignments()
        .then(data => {
          const dataObj = data.filter(x => x.id == id)[0];
          const { platform } = dataObj.spend_selection;

          this.getTiers(platform);
          this.getFields(platform)
            .then( x => {
              const stateCopy = _.cloneDeep(this.state);

              const defineMappings = createMappings(dataObj.mapping_rules, dataObj.name);
              const marketingChannels = createMarketingChannels(dataObj.tier_selection);
              const dataSources = createDataSources(dataObj.spend_selection, this.state.spendFieldOptions);

              stateCopy.defineMappings = defineMappings;

              stateCopy.marketingChannels = marketingChannels;
              stateCopy.dataSources = dataSources;

              dataObj.tier_overrides.map(row => {
                stateCopy.defineOverrides[row.tier_field] = row.spend_field
              },)

              if(copy) {
                stateCopy.defineMappings.name = ""
              }

              this.setState(stateCopy)
            })
        })
    }

    getSpendPlatform()
      .then(data => {
        const spendDataSourceOptions = data.map(c => {return {text: c.platform, value: c.platform}})
        this.setState({spendDataSourceOptions});
      })

  }

  updateActiveState = (stateSlice) => {
    const futureState = { [stateSlice]: true }
    const inactiveKeys = Object.keys(this.state).filter( activeStatus => activeStatus !== stateSlice );
    inactiveKeys.forEach( key => futureState[key] = false)

    this.setState({active: futureState})
  }

  addSpendFilter = (event) => {
    event.preventDefault();
    const stateCopy = _.cloneDeep(this.state);
    const { spendFilters } = stateCopy.dataSources;
    const newTableRow = { field: '', value: '', id: spendFilters.length };

    spendFilters.push(newTableRow);

    this.setState(stateCopy);

  }

  handleTextChange = (data, stateSlice, key) => {
    const { value } = data;
    const stateCopy = _.cloneDeep(this.state);
    stateCopy[stateSlice][key] = value;

    this.setState(stateCopy)
  }


  handleOverrideChange = (tier, data) => {
    const { value } = data;
    const stateCopy = _.cloneDeep(this.state);
    stateCopy.defineOverrides[tier] = value;

    this.setState(stateCopy);
  }

  handleSpendDataChange = (data, id, key, stateSlice) => {
    const stateCopy = _.cloneDeep(this.state);
    const { mappings } = stateCopy.defineMappings;
    const { spendFilters } = stateCopy.dataSources;
    const { value, children } = data;

    if(stateSlice === "dataSources") {
      const updatedSpendFilters = spendFilters.map(c => {
        if(c.id === id) {
          c[key] = value;
          // text key used for define mappings summary table
          if(key === "field") {
            c.text = children;
          }
        }
        return c
      })

      stateCopy.dataSources.spendFilters = updatedSpendFilters
    }
    if (stateSlice === "defineMappings"){
      const updatedMappings = mappings.map(c => {
        if(c.id === id) {
          c[key] = value;
          c.text = children;
          if (c.tierBucket && c.tierBucket != "") {
            const tier = c.tierBucket;
            const spend = c.spendField;
            stateCopy.defineOverrides[tier] = spend;
          }
        }
        return c;
      })
    }

    this.setState(stateCopy)
  }

  handleSpendSourceChange = (data, stateSlice, key) => {
    const {value} = data;
    const stateCopy = _.cloneDeep(this.state);

    stateCopy[stateSlice][key] = value;
    this.setState(stateCopy)

    this.getTiers(value);
    this.getFields(value);
  }

  getFields = (platform) => {
    return getSpendFields(platform)
      .then(data => {
        const options = data.map(c => {
          return {text: c.display_name, value: c.cassandra_name};
        })
        this.setState({spendFieldOptions: options})
      })
  }

  getTiers = (platform) => {
    getSpendPlatformTiers(platform)
      .then(data => {
        const spendPlatformTierOptions = Object.keys(data).map( c => {
          const options = data[c].map(item => {
            return {text: item !== null ? item.toLowerCase() : item , value: item}
          })
          data[c] = options;
        })

        this.setState({spendPlatformTierOptions: data});
      })
  }

  createPostObj = () => {
    const copy = this.props.match.path.includes('copy') ? true : false;
    const { dataSources, marketingChannels, defineMappings, defineOverrides } = this.state;
    const { spendFilters } = dataSources;
    const { mappings } = defineMappings;

    const postObj = {
      "name": defineMappings.name,
      "tier_selection": {
          "tier_1": marketingChannels.bucket1,
          "tier_2": marketingChannels.bucket2,
          "tier_3": marketingChannels.bucket3,
          "tier_4": marketingChannels.bucket4,
          "tier_5": marketingChannels.bucket5
        },
    }

    const spendSelection = spendFilters.reduce((obj, item) => {
      obj[item.field] = item.value;
      return obj
    }, {"platform": dataSources.spendSource})

    postObj.spend_selection = spendSelection;

    const filteredMappings = mappings
      .filter((x,i) => {
        return x.tierBucket !== '';
      })

    const formattedMappings = filteredMappings.map((c,i)=> {
        return {"tier_field": c.tierBucket, "spend_field": c.spendField}
      });

    postObj.mapping_rules = formattedMappings;

    const filteredOverrides = Object.keys(defineOverrides)
      .map(tier => {
        return { tier_field: tier, spend_field: defineOverrides[tier]}
      })
      .filter(obj => obj.spend_field !== false);

    postObj.tier_overrides = filteredOverrides;


    if(this.props.match.params.id && !copy) {
      updateSpend(postObj, this.props.match.params.id)
      .then(data => {
        this.props.history.push(routes.spendIndex);
      }).catch((error) => this.setState({ error }));
    } else {
      postSpend(postObj)
      .then(data => {
        this.props.history.push(routes.spendIndex);
      }).catch((error) => this.setState({ error }));
    }
  }

  render() {
    const { active } = this.state;
    const { defineMappings, defineOverrides, dataSources, marketingChannels, spendFieldOptions, error } = this.state;
    return (
      <ContentCard
        title="Create Spend Mapping"
        topRight={
          <Link to={routes.spendIndex}>
            <Icon name="angle left" />
            Back to Spend Mappings
          </Link>
        }
      >
        { (error != undefined) &&
          <Announcements
            announcements={[{"title":error,"major":1}]}
          />
        }
        <Steps active={this.state.active} updateActiveState={this.updateActiveState}/>

        { active.data && (
          <DataSource
            updateActiveState={this.updateActiveState}
            dataSources={this.state.dataSources}
            addSpendFilter={this.addSpendFilter}
            handleTextChange={this.handleTextChange}
            handleSpendDataChange={this.handleSpendDataChange}
            spendFieldOptions={this.state.spendFieldOptions}
            spendDataSourceOptions={this.state.spendDataSourceOptions}
            handleSpendSourceChange={this.handleSpendSourceChange}
            spendPlatformTierOptions={this.state.spendPlatformTierOptions}
          />) }

        { active.marketing && (
          <MarketingChannels
            updateActiveState={this.updateActiveState}
            marketingChannels={this.state.marketingChannels}
            handleTextChange={this.handleTextChange}
          />) }

        { active.mappings && (
          <DefineMappings
            updateActiveState={this.updateActiveState}
            createPostObj={this.createPostObj}
            handleTextChange={this.handleTextChange}
            handleSpendDataChange={this.handleSpendDataChange}
            id={this.props.match.params.id}
            handleOverrideChange={this.handleOverrideChange}
            {...{ defineMappings, defineOverrides, dataSources, marketingChannels, spendFieldOptions }}
          />) }
      </ContentCard>
    );
  }
}

export default withRouter(SpendMappings);
