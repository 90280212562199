import React from 'react';
import { Button }  from 'semantic-ui-react';
import { ArrowCircleRightIcon } from '@heroicons/react/solid'
import { formatSpendText, generateInsightData, firstElementMonthAndYear, groupAndCalculateMean } from './helpers';
import { BUTTON_WIDTH } from './constants';

const Insights = ({ data, platform, filterType, industry, spendLevel, spendLevelOptions}) => {
    if(data.length === 0) {
        return (
            <div className='insights'>
                <div className='text'>
                <p className='first-line'>No data available</p>
                <p className="text-center">Please select a different filter</p>
                </div>
            </div>
        )
    }

    const weeklyData = data ? groupAndCalculateMean(data, platform) : [];
    const formattedPlatform = platform[0].toUpperCase() + platform.slice(1);
    const url = `/v3/channels/digital_advertising/comparison/${platform}`;
    const { month: startMonth = "", year: startYear = "" } = data ? firstElementMonthAndYear(weeklyData) : {};
    const buttonText = `View my ${formattedPlatform} Performance`;
    const formattedSpendLevel = spendLevel !== "All" ? formatSpendText(spendLevelOptions.find(option => option.value === spendLevel).value) : "";
    const insightData = data.length > 1 ? generateInsightData(weeklyData, platform, filterType, industry, formattedSpendLevel, startMonth, startYear, formattedPlatform) : {};
    const maxWidth = BUTTON_WIDTH[platform]

    return (
    <div className='insights'>
        <div className='text'>
            <p className='first-line'>{insightData["firstLine"]}</p>
            <p>{insightData["secondLine"]}</p>
            <p>{insightData["thirdLine"]}</p>
            <Button primary style={{display: 'flex', alignItems: 'center', width: "54%", fontSize: "1.01rem", paddingLeft: '8px', paddingRight: '2px', overflow: "hidden", whiteSpace: "nowrap", maxWidth: BUTTON_WIDTH[platform]}} href={url}> {buttonText} <ArrowCircleRightIcon style={{ display: 'inline-block', height: '25px', marginLeft: '5px' }} /> </Button>
        </div>
    </div>
    );
}
export default Insights;
