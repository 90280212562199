import React from 'react'
import styled from 'styled-components'
import { Image, Header, Button } from 'semantic-ui-react'
import { noDataCat, heroAttributionImage } from '@rockerbox/styleguide'

export const FormStylesWrap = styled.div`
  height: 100%;

  .signup-form-box {
    h2.signup-form-header {
      color: #${props => props.color} !important;
    }

    input:focus {
      border-color: #${props => props.color} !important;
    }

    .ui.checkbox input:checked ~ label:before {
      background-color: #${props => props.color} !important;
      border-color: #${props => props.color} !important;
    }

    a {
      color: #${props => props.color} !important;

      &:hover {
        color: #${props => props.color} !important;
        opacity: 0.8;
      }
    }

    div.checkbox-wrapper {
      float: left;
      padding-top: 3px;
    }

    .signup-form-text {
      padding-left: 5px;
      padding-right: 8px;
    }

    .signup-form-submit,
    .signup-form-error {
      > button.ui.button {
        background-color: #${props => props.buttonColor || props.color} !important;
        color: #${props => props.buttonTextColor} !important;
  
        &:hover {
          background-color: #${props => props.buttonColor || props.color} !important;
          opacity: 0.8;
        }

        &:focus {
          outline:  #${props => props.buttonColor || props.color} 2px solid;
          outline-offset: 2px;
        }
      }
    }
  }
`

export const Error = ({ token, startIntercomChat, accountExists, invalidKey }) => {
  if (accountExists){
    return (
      <div className='signup-form-error'>
        <Image src={heroAttributionImage} centered size="small" />
        <Header
          as='h2'
          content={<>Good news, your company has an<br />existing Rockerbox account 🎉</>}
          subheader='Contact your team member to get invited to the account'
          textAlign='center'
        />
      </div>
    ) 
  }
  else if (invalidKey){
    return (
      <div className='signup-form-error'>
        <Image src={noDataCat} centered size="small" />
        <Header
          as='h2'
          content={<>Your signup key is invalid</>}
          subheader='Contact your Rockerbox team member to get the correct signup key'
          textAlign='center'
        />
      </div>
    )
  }
  else {
    return (
      <div className='signup-form-error'>
    <Image src={noDataCat} centered size="tiny" />
    <Header
      as='h2'
      content='Sorry, we had an issue creating your account'
      subheader='Please contact us for further assistance in getting started'
      textAlign='center'
    />
    <Button
      fluid
      primary
      onClick={() => startIntercomChat(token)}
      content='Contact Us'
    />
  </div>
    )
  }
}
