import React, { Component } from 'react';
import { Statistic, Button, Form, Icon } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getListStyle, getItemStyle } from './dndHelpers';
import { getConversionData, testLookup } from '../../utils/api';
import { StatCard, IndexGrid } from '@rockerbox/styleguide';
import { PaddedLabel } from './form';

import moment from 'moment';

const today = moment.utc().utcOffset(-5).subtract(1,'d').format("YYYY-MM-DD")

const COLS = [
  {display: "Lookup", key: "lookup"},
  {display: "Total Paths", key: "total_paths"},
  {display: "Paths with Issues", key: "problematic_paths"},
  {display: "New UIDs Yielded", key: "new_uids"},
  {display: "", key: ""},
]

const LookupChecks = (props) => {
  const { lookupOptions } = props;
  const [uids, setUids] = React.useState(false)
  const [shouldTest, setShouldTest] = React.useState(false)
  const [badCount, setBadCount] = React.useState(false)
  const [goodCount, setGoodCount] = React.useState(false)
  const [entries, setEntries] = React.useState(false)
  const conversionSegmentId = props.id

  React.useEffect(() => {
    if (conversionSegmentId) getConversionData(conversionSegmentId, today, today)
      .then(data => {
        const uids = data.map(row => row.uid)
        setUids(uids)
      })
  }, [conversionSegmentId])

  React.useEffect(() => {
    if (shouldTest && lookupOptions.length && uids.length) {
      const uidTables = lookupOptions
        .map(row => row.text)
        .filter(table => table.slice(0,3) == "uid")

      const promises = uidTables.map(tableName => testLookup(uids, tableName))
      Promise.all(promises)
        .then(arr => {
          setBadCount(arr.filter(row => row.bad_lookup).length)
          setGoodCount(arr.filter(row => !row.bad_lookup).length)
          setEntries(arr)
        })
    }
  }, [shouldTest, lookupOptions, uids])

  return (
    <React.Fragment>
      <PaddedLabel>Test results for all UID originated lookups</PaddedLabel>
      { (shouldTest == false) ? 
        <div style={{textAlign:"center"}}><Button onClick={() => setShouldTest(true)}>Test UIDs</Button></div> :
        <>
          <Statistic.Group size={"mini"} style={{"justify-content":"space-between", margin: 1}}>
            <StatCard style={{flex:1,margin:5}} value={ uids.length} label="Tested UIDs" />
            <StatCard style={{flex:1,margin:5}} value={goodCount} label="Valid Lookups" />
            <StatCard style={{flex:1,margin:5}} value={badCount} label="Bad Lookups" />
            <div style={{flex:1}} />
          </Statistic.Group>
          <IndexGrid cols={COLS} data={entries} negative={"bad_lookup"} />
        </>
      }
      
    </React.Fragment>
  )
}

export default LookupChecks;

