import React, { useMemo } from 'react';
import { useAtom } from 'jotai'

import { IndexGridTree } from '@rockerbox/styleguide';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { spendFormatter } from '../../../utils/valueFormatter';
import { f, calculateMeanPageviews, calculateMeanBounceRate } from '../helpers';
import { columnsAtom } from '../../../atoms';

export const getTreeGridColumns = (tierColorMap, currencyCode, tooltips) => {
  return [
    {
      display: "Channel",
      key: 'group',
      groupBy: ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"],
      as: IndexGridTree.NameCell(tierColorMap)
    },
    {
      display: "New Visitors",
      key: 'num_new_visitors',
      as: IndexGridTree.NumberCell,
      reducer: (values) => d3.sum(f(values), x => x.num_new_visitors),
      tooltip: tooltips.num_new_visitors,
      summaryLabel: "Total",
      style: { textAlign: 'right' }
    },
    {
      display: "New Visitors %",
      key: 'num_new_visitors_percent_col',
      as: IndexGridTree.PercentageCellAvg,
      reducer: (values) => d3.sum(f(values), x => x.num_new_visitors),
      style: { textAlign: 'right' }
    },
    {
      display: "Spend",
      key: 'spend',
      as: IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      reducer: (values) => d3.sum(f(values), x => x.spend),
      summaryLabel: "Total",
      style: { textAlign: 'right' }
    },
    {
      display: "Cost per Visitor",
      key: 'cpa',
      as: IndexGridTree.VisitorCpaCell(spendFormatter(currencyCode)),
      reducer: (values) => d3.sum(f(values), x => x.spend) ? d3.sum(f(values), x => x.spend) / d3.sum(f(values), x => x.num_new_visitors) : Infinity,
      tooltip: tooltips.cpa,
      summaryLabel: "Avg",
      style: { textAlign: 'right' }
    },
    {
      display: "Average Pageviews",
      key: 'num_pageviews_mean',
      as: IndexGridTree.NumberCell,
      reducer: calculateMeanPageviews,
      tooltip: tooltips.num_pageviews_mean,
      summaryLabel: "Avg",
      style: { textAlign: 'right' }
    },
    {
      display: "Bounce Rate",
      key: 'num_bounces',
      as: IndexGridTree.BounceRateCell,
      reducer: calculateMeanBounceRate,
      tooltip: tooltips.num_bounces,
      summaryLabel: "Avg",
      style: { textAlign: 'right' }
    },
  ];
}

export const useTreeColumns = (tierColorMap, currencyCode, tooltips) => {

  const [colsUrl, setColsUrl] = useAtom(columnsAtom)
  
  const setSelectedColumns = (selected) => {
    const columnNames = selected.map(({ key }) => key)
    setColsUrl(columnNames)
  }

  const allColumns = useMemo(() => {
    return getTreeGridColumns(tierColorMap, currencyCode, tooltips)
  }, [tierColorMap, currencyCode, tooltips]);

  const selectedColumns = useMemo(() => {
    if (!allColumns) return []

    if (colsUrl.length === 0) return allColumns

    const findSelectedColumns = colsUrl.map((key) => allColumns.find((col) => key === col.key))
    return findSelectedColumns
      
  }, [allColumns, colsUrl])

  return {
    allColumns,
    selectedColumns,
    setSelectedColumns,
  }
}

