import React, { useContext, useState, useMemo, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import moment from 'moment'

import { BasicViewHeader } from '../../components'
import { CacheContext } from '../../../utils/CacheContext'
import { getSegments, getLookupTables } from '../../api/attribution'
import { getConversionData } from '../../api/attributionCache'
import { getUserData } from '../../api/hindsightChecks'

import { buildPath } from '../../hooks/urlState'
import * as routes from '../../baseRoutes'
import { track } from '../../../utils/tracking'

import ConversionDetails from './ConversionDetails'
import MarketingTouchpoints from './MarketingTouchpoints'
import EventsTimeline from './EventsTimeline'
import IdentityGraph from './IdentityGraph'


const ConversionsUserProfile = () => {
  const { id, date, convHashId } = useParams()
  const segId = id

  const [state, setState, Context] = useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || []
  const lookupTables = Context.getCache(`lookupTables`, getLookupTables) || null
  const conversionDetails = state?.conversionDetails || {}
  const cachedConvData = conversionDetails[convHashId]

  const [conversionData, setConversionData] = useState(null)
  const [userData, setUserData] = useState(null)

  // header and breadcrumbs
  const conversionDetailPath = useMemo(() => {
    const params = { id: segId }
    return buildPath('conversionsDetail', params)
  }, [segId])

  const header = useMemo(() => {
    if (!conversionData) return 'Loading...'
    if (!!conversionData.order_number) return `Order #${conversionData.order_number}`
    if (!!conversionData.order_id) return `Order ${conversionData.order_id}`
    return `Conversion ${conversionData.conversion_hash_id}`
  }, [conversionData])

  const segmentInfo = useMemo(() => {
    return segments.find(s => s?.action_id === parseInt(segId))
  }, [segments, segId])

  useEffect(() => {
    track(`data.customers.conversions.user.view`, {
      segment_id: segId,
      date,
      conversion_hash_id: convHashId,
    })
  }, [])

  // conversion data
  useEffect(() => {
    if (!!cachedConvData) return setConversionData(cachedConvData)
    getConversionData(segId, date, date)
      .then(data => {
        const convData = data.find(x => x.conversion_hash_id === convHashId)
        setConversionData(convData)
      })
  }, [cachedConvData])

  useEffect(() => {
    if (!conversionData) return
    const { base_id } = conversionData
    getUserData(segId, base_id)
      .then(data => {
        setUserData(data)
      })
      .catch(() => {
        setUserData({ events: [] })
      })
  }, [conversionData])

  const marketingEvents = conversionData?.events

  // events data
  const actionPatterns = useMemo(() => {
    return segments
      .flatMap(x => x.url_pattern)
      .map(x => {
        if (x === '/') return 'view'
        if (x === 'shopify.api.order.web') return 'Completed Order'
        return x.replace(/^action=/, '')
      })
  }, [segments])

  const userSessionsEvents = useMemo(() => {
    if (!actionPatterns || !userData || !conversionData) return null
    const { sessions, events } = userData
    if (!sessions || !events) return false
    const sessionGroups = sessions.map(x => ({
      id: x.sessionId,
      start: x.timestamp,
      end: x.timestamp_finish,
      duration_seconds: x.session_seconds,
      uid: x.uid,
    }))

    return sessionGroups.map(session => {
      const sessionEvents = events
        .filter(x =>
          x.sessionId === session.id
          && actionPatterns.includes(x?.action)
          && moment(conversionData.timestamp_conv * 1000).isAfter(moment.utc(x.timestamp))
        )
        .sort((a,b) => {
          if (a.timestamp === b.timestamp) {
            if (a.action === 'view' && b.action !== 'view') return -1
            if (a.action !== 'view' && b.action === 'view') return 1
            return 0
          }
          return a.timestamp > b.timestamp ? 1 : -1
        })
      return {
        ...session,
        events: sessionEvents,
      }
    }).filter(x => x.events.length > 0)
  }, [actionPatterns, userData, conversionData])

  // identity graph data
  const identityGraph = useMemo(() => {
    if (!userData || !conversionData || !lookupTables) return null
    const { attempted_lookups, lookup } = userData
    const base_key = conversionData?.base_key || 'uid'
    const base_id = conversionData?.base_id || conversionData.uid
    if (!attempted_lookups || !lookup) return false

    const matchedLookups = Object.keys(conversionData)
      .filter(x => x.includes('_from') && x != 'cache_from')
      .map(x => conversionData[x])
      .filter(x => !!x)

    const lookupChain = attempted_lookups.map(x => {
      const table = lookupTables.find(y => y.table_name === x)
      const { from, to, table_name } = table
      const match = matchedLookups.includes(table_name)
      return {
        from,
        to,
        table_name,
        match,
      }
    })

    const lookupPaths = []
    lookupChain.map(x => {
      const { from, to } = x
      let added = false
      lookupPaths.map((path, i) => {
        for (let p = path.length-1; p > 0; p--) {
          const currID = path[p]
          if (currID === from) {
            const newPath = [...path.slice(0, p+1), to]
            lookupPaths.push(newPath)
            added = true
            break
          }
        }
      })
      if (!added) lookupPaths.push([from, to])
    })

    const userIDs = lookup.reduce((acc, x) => {
      Object.entries(x).map(([key, value]) => {
        if (!acc[key]) acc[key] = []
        if (!acc[key].includes(value)) acc[key].push(value)
      })
      return acc
    }, {})

    const graph = []
    Object.entries(userIDs)
      .filter(([key, _value]) => key != base_key && key != 'base_id')
      .map(([key, values]) => {
        const matchingLookups = lookupPaths.filter(x => x.slice(1).includes(key))
        values.map((v, i) => {
          const idx = i % matchingLookups.length
          const path = matchingLookups[idx]
          graph.push({
            path,
            key,
            value: v,
          })
        })
      })
    return {
      baseKey: base_key,
      baseValue: base_id,
      graph,
    }
  }, [userData, conversionData, lookupTables])

  return (
    <>
      <BasicViewHeader
        header={header}
        subheader={<>
          {'Customer Data '}
          <Icon name='angle right' style={{ margin: 0 }} />{' '}
          <Link to={routes.conversionsOverview}>
            Orders &amp; Conversions
          </Link>{' '}
          <Icon name='angle right' style={{ margin: 0 }} />{' '}
          <Link to={conversionDetailPath}>
            {segmentInfo?.action_name}
          </Link>
        </>}
      />
      <ConversionDetails {...{ conversionData }} />
      <MarketingTouchpoints {...{ marketingEvents }} />
      <EventsTimeline {...{ userSessionsEvents }} />
      <IdentityGraph {...{ identityGraph }} />
    </>
  )
}

export default ConversionsUserProfile
