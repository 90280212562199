import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

export const buildGroupsFromRaw = (raw) => {
  const groups = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"];
  const hasWeight = raw.filter(r => !!r.tier_1);

  return groups.reduce((p,c,i,a) => {
    p[c] = d3.nest()
      .key(x => a.slice(0,i+1).map(k => x[k]).join("|||") )
      .rollup(v => v.length)
      .entries(hasWeight)
      .map(x => {
        const value = x.key.split("|||").reverse()[0]
        return {
          key: x.key,
          text: value + " (" + x.values + ")",
          value: value,
          count: x.values,
          parentTiers: a.slice(0,i),
          parentValues: x.key.split("|||").slice(0,-1)
        }
      }).sort((p,c) => c.count - p.count)
    return p
  },{})
}

export const start_date = moment.utc().utcOffset(-5).subtract(30, 'days').format("YYYY-MM-DD")
export const end_date = moment.utc().utcOffset(-5).subtract(1, 'days').format("YYYY-MM-DD")
export const end_date_plus_one = moment.utc().utcOffset(-5).subtract(0, 'days').format("YYYY-MM-DD")

export const buildDates = (start_date, end_date) => d3.time.scale()
  .domain([new Date(moment.utc(start_date)), new Date(moment.utc(end_date).add(1, 'days').format("YYYY-MM-DD"))])
  .ticks(d3.time.days, 1)
  .map(x => moment.utc(x).format("YYYY-MM-DD"))

export const dates = buildDates(start_date, end_date_plus_one)


export const aggregateGroups = (dates, model_types, group, ntfFilter=0, cprefix="", csuffix="", rprefix="", rsuffix="_revenue") => (data) => {

    const keyFunc = typeof(group) == "object" ?
      (x) => group.map(g => x[g]).join(" ||| ").replace(/false/g,"–") :
      (x) => x[group]

    const groupCount = d3.nest()
      .key(keyFunc)
      .rollup(values => {
        return model_types.reduce((p,model_type) => {
            p[model_type] = d3.sum(values.map(x => x[cprefix + model_type + csuffix]))
            return p
          },{})
      })
      .entries(data.attribution)
      .reduce((p,c) => {
        p[c.key] = c.values
        return p
      },{})

    const totals = model_types.reduce((p,c) => {
      p[c] = d3.sum(Object.values(groupCount).map(x => x[c]))
      return p
    }, {})

    const groupPercent = Object.keys(groupCount).reduce((p,c) => {
      p[c] = p[c] || {};
      model_types.map(t =>
        p[c][t] = groupCount[c][t]/totals[t]
      )
      return p
    }, {})


    const groupTimeseries = d3.nest()
      .key(keyFunc)
      .rollup(values => {

        return model_types.reduce((p,model_type) => {
          const byDate = d3.nest()
            .key(x => x.date)
            .rollup(values => {
              return {
                "returning_count":d3.sum(values.map(x => x[cprefix + model_type + csuffix] - x["ntf_" + cprefix + model_type + csuffix])),
                "ntf_count":d3.sum(values.map(x => x["ntf_" + cprefix + model_type + csuffix])),
                "count":d3.sum(values.map(x => x[cprefix + model_type + csuffix])),
                "returning_revenue":d3.sum(values.map(x => x[rprefix + model_type + rsuffix] - x["ntf_" + rprefix + model_type + rsuffix])),
                "ntf_revenue":d3.sum(values.map(x => x["ntf_" + rprefix + model_type + rsuffix])),
                "revenue":d3.sum(values.map(x => x[rprefix + model_type + rsuffix])),
                "spend":d3.sum(values.map(x => x["spend"] || 0))
              }
            })
            .map(values)

          // HACK for repeat users filter
          const countKey = (ntfFilter == 2) ? 'returning_count' : 'count'
          const revenueKey = (ntfFilter == 2) ? 'returning_revenue' : 'revenue'

          p[model_type] = {
            count: dates.map(d => (byDate[d]||{})[countKey] || 0),
            ntf_count: dates.map(d => (byDate[d]||{})['ntf_count'] || 0),
            revenue: dates.map(d => (byDate[d]||{})[revenueKey] || 0),
            ntf_revenue: dates.map(d => (byDate[d]||{})['ntf_revenue'] || 0),
            spend: dates.map(d => (byDate[d]||{})['spend'] || 0)
          }
          return p
        },{})

      })
      .entries(data.attribution)
      .map(groupData => {
        const values = Object.keys(groupData.values).map((key,i) => {

          const x = groupData.values[key];
          const total = d3.sum(x.count);
          const totalSpend = d3.sum(x.spend);
          const totalRev = d3.sum(x.revenue);
          const ntfTotal = d3.sum(x.ntf_count);
          const ntfTotalRev = d3.sum(x.ntf_revenue);
          return Object.assign({
            model_type: key,
            group: groupData.key,
            tiers: groupData.key.replace(/%/g,"%25").split(" ||| "),
            total: total,
            ntf_total: ntfTotal,
            returning_total: total-ntfTotal,
            rev_total: totalRev,
            ntf_rev_total: ntfTotalRev,
            returning_rev_total: totalRev-ntfTotalRev,
            spend_total: totalSpend,
            cpa: totalSpend ? totalSpend/total : 0,
            rpa: totalRev/total,
            roas: totalSpend ? totalRev/totalSpend : 0,
            percent: groupPercent[groupData.key][key]
          },x)
        })
        .sort((p,c) => c.total - p.total)

        return model_types.reduce((p,c,i) => { p[c] = values.find(x => x.model_type == c); return p}, {})
      })


    return {
      groupTimeseries: groupTimeseries,
    }
}

export const aggByDate = (byDate = {}, field, _default) => (date) => {
  return Object.assign({date:date}, (byDate[date] || _default)[field] )
}

export const makeTotal = x => {
  x.total = x.attributed + x.unattributed;
  return x
}


export const calcTotals = (selectedData) => {
  return selectedData.reduce((p,c) => {
    p.count += c.total || 0;
    p.spend += c.spend_total || 0;
    p.revenue += c.rev_total|| 0;
    return p
  },{ count: 0, revenue: 0, spend: 0 })
}

export const calcTimeseries = (selectedData, startDate, endDate, plotVariable) => {

  const dateRange = buildDates(startDate, endDate)
  const dateSummary = selectedData.reduce((p,c) => {
    const values = c[plotVariable];
    values.map((v,i) => {
      p[dateRange[i]] = (p[dateRange[i]] || 0) + v
    })
    return p
  },{})

  return Object.keys(dateSummary)
    .map(k => { return {"date":k, "Total":dateSummary[k]} })
    .sort((p,c) => d3.descending(p.date,c.date));
}

export const getFilterTiers = (params) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = params;
  const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 }

  Object.keys(tiers).map(t => {
    if (tiers[t] == undefined) delete tiers[t]
  })
  return tiers
}


export const urlDecodeTier = tiers => {
  if (!tiers) {
    return tiers
  }
  return Object.entries(tiers).reduce((prev, cur) => {
    const [key, value] = cur;

    if (value) {
      prev[key] = decodeURIComponent(value)
    }

    return prev;
  }, {})
}
export const convertPctURL = (val) => {
  return val.replace(/%(?!\d|[ABCDEF]+)/g, '%25')
}

export const encode = (value) => {
  return encodeURIComponent(value).replaceAll("%25", "_PERCENT_")
}
export const decode = (value) => {
  return decodeURIComponent(value.replaceAll("_PERCENT_","%25"))
}

export const urlDecodeTierStr = val => decode(convertPctURL(val))

export const urlDecodeTierAfterSplit = tiers => {
  if (!tiers) {
    return tiers
  }
  return Object.entries(tiers).reduce((prev, cur) => {
    const [key, value] = cur;

    if (value) {
      prev[key] = value.split(",").map(val => decode(convertPctURL(val)));
    }

    return prev;
  }, {})
}

export const urlEncodeTier = tiers => {
  if (Array.isArray(tiers)) {
    return tiers.map(tier => encode(tier))
  }

  return encode(tiers);
}

export const formatTiersValue = tiers => {
  if (!tiers) {
    return {}
  }

  Object.keys(tiers).forEach(key => {
    const shouldSplit = tiers[key] && tiers[key].indexOf(",") !== -1;
    tiers[key] = shouldSplit ? tiers[key].split(",") : tiers[key];
  })

  return tiers;
}
