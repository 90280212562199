import React from "react"
import { Table, Icon, Divider } from "semantic-ui-react"
import { SplitLayout } from "@rockerbox/styleguide"
import { convertToPercent } from "./Overlap/helpers"
import { formatCurrency, secondsToDhms } from "../../utils/valueFormatter"
import { TreeTableLoader, KeyInsightsLoader, VennDiagramLoader, ChannelOverlapBaseline } from "../../components/loaders.js"
import { LightBulbIcon } from '@heroicons/react/outline'
import styled from "styled-components"

export const ChannelCell = ({ item, col }) => (
  <Table.Cell style={{ textAlign: 'left' }}>
    <Icon name='circle' style={{ color: item.color }} size='large' /> {item[col.key]}
  </Table.Cell>
)

export const CurrencyCell = ({ item, col }) => (
  <Table.Cell>
    <p>{formatCurrency(item[col.key])}</p>
  </Table.Cell>
)

export const CurrencyCellPercent = ({ item, col }) => {
  const percentFormatter = col.key ? `${convertToPercent(item[col.key])} ${item[col.key] > 0 ? 'higher' : 'lower'}` : ''
  return (
    <Table.Cell>
      <p>{item[col.key] === Infinity ? 'N/A' : percentFormatter}</p>
    </Table.Cell>
  )
}


export const Loading = () => (
  <div>
    <div style={{ padding: '50px 20px' }}>
    <SplitLayout
      leftWidth={7}
      leftContent={<KeyInsightsLoader />}
      rightWidth={9}
      rightContent={<VennDiagramLoader />}
      />
    </div>
    <Divider style={{ padding: '20px' }} />
    <Divider />
    <ChannelOverlapBaseline />
    <TreeTableLoader />
  </div>
);

export const OverlapCell = ({ item, col }) => (
  <Table.Cell>
    {/* <SplitLayout
      leftWidth={8}
      leftContent={<VennDiagram id={item.key.replaceAll(' ', '-')} data={item.count_venn} width={50} height={50} noLabel />}
      rightWidth={8}
      rightContent={<div> */}
        <p>{`${item[col.key].toLocaleString('en-US')} (${convertToPercent(item[col.key_percent])})`}</p>
      {/* </div>}
      rightStyle={{display: 'flex', alignItems: 'center'}}
    /> */}
  </Table.Cell>
)

export const OverlapCellPercent = ({ item, col }) => (
  <Table.Cell>
    <p>{`${convertToPercent(item[col.key])}`}</p>
  </Table.Cell>
)

export const TimeCell = ({ item, col }) => {
  const timeFormatter = secondsToDhms(item[col.key]);
  const percentFormatter = col.key_percent ? `(${convertToPercent(item[col.key_percent])} ${item[col.key_percent] < 0 ? 'faster' : 'slower'})` : ''
  return (
    <Table.Cell>
      <p>{timeFormatter}</p>
      {/* <p style={{ lineHeight: '5px' }}>{percentFormatter}</p> */}
    </Table.Cell>
  )
}

export const cols = [
  { display: 'Channel', key: 'channel', as: ChannelCell, headerStyles: { textAlign: 'left' } },
  { display: 'Overlapping Conversions', as: OverlapCell, key: 'count_overlap', key_percent: 'count_overlap_ratio' },
  { display: 'Avg Conversion Time', key: 'avg_ttc_overlap', as: TimeCell },
]

export const revCols = [
  { display: 'Revenue', key: 'revenue_overlap', as: CurrencyCell },
  { display: 'Avg Revenue', key: 'avg_rev_overlap', as: CurrencyCell },
  { display: 'Avg Revenue % \u0394', key: 'avg_rev_change_rate', as: CurrencyCellPercent },
  { display: 'Avg Revenue \u0394', key: 'avg_rev_diff', as: CurrencyCell }
]

const GrayHeader = styled.h2`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0px;`

export const FilterHeader = ({ headerText }) => <>
<div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
  <LightBulbIcon style={{ width: 30, margin: '-2px 7px 0px 0', fill: '#FFDF78', stroke: '#b2b1ac' }} />
  <GrayHeader>{headerText}</GrayHeader>
</div>
</>
