import React, { Component } from 'react';
import Proptypes from 'prop-types';
import * as routes from '../../routes';
import { Grid, Feed, Accordion, Table, Icon } from 'semantic-ui-react';
import { CustomerTimeline, CustomerProfile, ContentCard } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Error } from '../styled';

const DATE_FORMAT = "MMM DD YYYY @ hh:mm a";

const isConversionEvent = (url, pattern) => {
  const urlParamValues = url.split(/[&]/);
  return urlParamValues.includes(pattern);
}

const isAnyConversionEvent = (url, segments) => {
  const patterns = segments.map(segment => segment.url_pattern[0]);
  const urlParamValues = url.split(/[&]/);
  return patterns.findIndex(pattern => urlParamValues.includes(pattern)) > -1;
}

const getConversionDetails = (waypoint) => {
  const exclusions = ['hash_ip','client_source', 'rb_source', 'uid', 'action'];

  let urlParamValues = waypoint.url.split(/[&]/);
  urlParamValues.shift();

  const whiteList = urlParamValues.filter(param => {
    const values = param.split('=');
    return exclusions.indexOf(values[0]) === -1;
  })

  return whiteList.map(param => {
    const values = param.split('=');
    const obj = {
      label: values[0].split('_').join(' '),
      value: values[1]
    }
    return obj;
  })
}

const hasBrowsingEvent = (arr, conversionPattern) => {
  return arr.findIndex(event => isBrowsingEvent(event, conversionPattern) && !event.urls) > -1;
};

const truncateURL = (url) => url.split('&action=')[0]

const groupBrowsingEvents = (path, conversionPattern) => {
  let grouped = path.slice();
  while (hasBrowsingEvent(grouped, conversionPattern)) {
    let browsingStart = grouped.findIndex(event => isBrowsingEvent(event, conversionPattern) && !event.urls);
    let browsingEnd = grouped.findIndex((event, index) => !isBrowsingEvent(event, conversionPattern) && index > browsingStart);
    if (browsingEnd === -1) {
      browsingEnd = grouped.length;
    }
    let browsingEvent = grouped[browsingStart];
    let urlGroup = grouped.splice(browsingStart, browsingEnd - browsingStart).map(event => formatBrowsingObj(event));
    browsingEvent.urls = urlGroup;
    grouped.splice(browsingStart, 0, browsingEvent);
  }
  return grouped;
}

const isBrowsingEvent = (waypoint, conversionPattern) => {
  return !isConversionEvent(waypoint.url, conversionPattern) && !waypoint.tier_1 && !waypoint.url.includes('&utm');
}

const formatBrowsingObj = (waypoint) => {
  return {
    date: moment(waypoint.timestamp).format(DATE_FORMAT),
    url: truncateURL(waypoint.url)
  }
}

const getEventType = (waypoint, conversionPattern) => {
  if (isConversionEvent(waypoint.url, conversionPattern)) {
    return 'conversion'
  }

  return "view"
}

const getEventName = (waypoint, conversionPattern, segments) => {

    const segment = segments.find(seg => seg.action_id === waypoint.conversion_id);
    return segment.action_name;


}

const getEventTiers = (_waypoint, conversionPattern, conversionEventId, conversionEvent) => {

  const waypoint = _waypoint.tier_1 != 0 ? _waypoint : Object.assign(_waypoint,{tier_1: "Direct"})

  if (!conversionEvent.use_model) return [
    { name: waypoint.tier_1 },
    { name: waypoint.tier_2 },
    { name: waypoint.tier_3 },
    { name: waypoint.tier_4 },
    { name: waypoint.tier_5 }
  ];


  return [
    { name: waypoint.tier_1, href: getEventLink(waypoint, conversionPattern, conversionEventId, 2) },
    { name: waypoint.tier_2, href: getEventLink(waypoint, conversionPattern, conversionEventId, 3) },
    { name: waypoint.tier_3, href: getEventLink(waypoint, conversionPattern, conversionEventId, 4) },
    { name: waypoint.tier_4, href: getEventLink(waypoint, conversionPattern, conversionEventId, 5) },
    { name: waypoint.tier_5, href: getEventLink(waypoint, conversionPattern, conversionEventId, 5) }
  ]
}

const TIERS = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"]

const getEventLink = (waypoint, conversionPattern, conversionEventId, num) => {

  const tiers = TIERS.slice(0,Math.min(num,3));
  const values = TIERS.slice(0,num-1).map(t => waypoint[t]).join("/")
  const url = routes.conversionData + "/0/" + conversionEventId + "/report/even/" + tiers.join(",") + "/" + values;
  return url.endsWith('/') ? url.slice(0,-1) : url;
}

const CustomerJourney = (props) => {
  const { match, path, history, events, segments, error } = props;
  const { conversionEvent, conversionPattern, conversionEventId } = props;

  if (error) {
    return <Error>{error}</Error>
  }

  if (!path.length || !conversionPattern)
    return (
      <ContentCard
        title='Customer'
        loading
        topRight={
          <Link to={routes.customerData + '/' + match.params.id}>
            <Icon name="angle left" />
            Back to Customers
          </Link>
        } />
    )

  let sortedPath = path.slice().sort((p,c) => moment(p.timestamp) - moment(c.timestamp));

  const groupedPath = groupBrowsingEvents(sortedPath, conversionPattern);

  const conversion = path.find(waypoint => {
    const urlParamValues = waypoint.url.split(/[&]/);
    return urlParamValues.includes(conversionPattern);
  })

  const conversionTimeFormatted = conversion ? moment(conversion.timestamp).format(DATE_FORMAT) : '';
  return path.length && (
    <ContentCard
      title={conversion && conversion.email ? 'Customer: ' + conversion.email : 'Customer' }
      topRight={
        <Link to={routes.customerData + '/' + match.params.id}>
          <Icon name="angle left" />
          Back to Customers
        </Link>
      }
    >
      <Grid fluid="true" divided>
        <CustomerTimeline width={10}>
          { groupedPath
            .filter((item, pos) => groupedPath.indexOf(item) == pos)
            .map((waypoint) => {
              if (isConversionEvent(waypoint.url, conversionPattern)) {
                return (
                  <CustomerTimeline.ConversionItem
                    date={moment(waypoint.timestamp).format(DATE_FORMAT)}
                    eventName={getEventName(waypoint, conversionPattern, segments)}
                    eventLink={getEventLink(waypoint, conversionPattern, conversionEventId, 1)}
                    conversionDetails={getConversionDetails(waypoint)}
                  />
                )
              } else if (waypoint.conversion_id) {
                return (
                  <CustomerTimeline.ConversionItem
                    date={moment(waypoint.timestamp).format(DATE_FORMAT)}
                    eventName={getEventName(waypoint, conversionPattern, segments)}
                    eventLink={`${routes.conversionData}/${waypoint.conversion_id}/report`}
                    conversionDetails={getConversionDetails(waypoint)}
                    color="blue"
                  />
                );
              } else if (isBrowsingEvent(waypoint)) {
                return <CustomerTimeline.BrowsingItems urls={waypoint.urls} />;
              }

              return (
                <CustomerTimeline.MarketingItem
                  eventTiers={getEventTiers(waypoint, conversionPattern, conversionEventId, conversionEvent)}
                  url={truncateURL(waypoint.url)}
                  date={moment(waypoint.timestamp).format(DATE_FORMAT)} />
              )
            })
          }
        </CustomerTimeline>
        <CustomerProfile width={6}>
          { conversion && conversion.email && (
            <CustomerProfile.Detail
              label="Email"
              value={conversion.email}
            icon="envelope outline"
          />
        )}

        <CustomerProfile.Detail
          label="First Seen On"
          value={moment(path[0].timestamp).format(DATE_FORMAT)}
          icon="clock outline"
        />

        <CustomerProfile.Detail
          label="Converted On"
          value={conversionTimeFormatted}
          icon="check"
        />
        { conversion && conversion.revenue && (
          <CustomerProfile.Detail
            label="Revenue"
            value={conversion.revenue}
            icon="dollar"
          />
        )}
        </CustomerProfile>
      </Grid>
    </ContentCard>
  )
}

CustomerJourney.propTypes = {
  path: Proptypes.array.isRequired
}

export default CustomerJourney;
