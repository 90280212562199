import { colorHash } from "../../../../components/ViewAttribution/AttributionPercent"

// Shared
export const calcDiff = (value, baseline) => {
  const diff = value - baseline
  return [diff, (diff / baseline)]
}

// Index - Data Processing
export const generateVennItem = (keysArr, value, label, colorMap = {}, clickMethod) => {
  const baseObj = { sets: [...keysArr], size: value }
  if (label) baseObj.label = label
  if (clickMethod) baseObj.onClick = clickMethod
  if (keysArr.length == 1) baseObj.color = colorMap?.[keysArr[0]] || colorHash(keysArr[0])
  return baseObj
}

const sortChannelByValue = (overlapChannels, pathsProcessed, customerTypeSuffix, mainMetric) => {
  return overlapChannels.map((channel) => {
    const customerTypeObj = selectCustomerTypeMetrics(pathsProcessed[channel], customerTypeSuffix)
    return {
      key: channel,
      value: customerTypeObj[`overlap_${mainMetric}`]
    }
  })
  .sort((a, b) => b.value - a.value)
}

export const generateTopInsights = (
  overlapChannels, pathsProcessed, customerTypeSuffix, mainMetric, // for sorting channels
  platformObj, platform, colorMap, setBubbleClicked // for Venn data
) => {
  const sortedChannels = sortChannelByValue(overlapChannels, pathsProcessed, customerTypeSuffix, mainMetric)
  setBubbleClicked([sortedChannels[0].key]) // Specify the default bubble
  let totalChannelSize = 0
  let totalOverlapSize = 0
  const vennRatioLimit = 0.05
  const keyInsights = []

  // generate insights
  const highLevelOverlaps = sortedChannels.flatMap((obj, i, channels) => {
    const {key: channelKey} = obj
    const customerTypeObj = selectCustomerTypeMetrics(pathsProcessed[channelKey], customerTypeSuffix)
    const platformSize = platformObj[`total_${mainMetric}`]
    const channelSize = customerTypeObj[`total_${mainMetric}`]
    const overlapSize = customerTypeObj[`overlap_${mainMetric}`]

    if (
      channels.length < 2 ||
      overlapSize > 0 &&
      channelSize > platformSize * vennRatioLimit &&
      (totalChannelSize / platformSize < 3.14 || i < 2) &&
      totalOverlapSize < platformSize
    ) {
      const total_obj = generateVennItem([channelKey], channelSize, channelDisplayName(channelKey), colorMap, setBubbleClicked)
      const overlap_obj = generateVennItem([channelKey, platform], overlapSize)
      totalChannelSize += channelSize
      totalOverlapSize += overlapSize
      keyInsights.push(channelKey)
      return [total_obj, overlap_obj]
    }
    return []

  }).sort((a, b) => a.size - b.size)
  
  return { highLevelOverlaps, keyInsights }
}

export const selectCustomerTypeMetrics = (obj, customerTypeSuffix) => {
  if (!obj) return {};
  return {
    total_count: obj[`total_count${customerTypeSuffix}`],
    total_revenue: obj[`total_revenue${customerTypeSuffix}`],
    total_time_to_conv: obj[`total_time_to_conv${customerTypeSuffix}`],
    overlap_count: obj[`overlap_count${customerTypeSuffix}`],
    overlap_revenue: obj[`overlap_revenue${customerTypeSuffix}`],
    overlap_time_to_conv: obj[`overlap_time_to_conv${customerTypeSuffix}`]
  }
}

export const processMetricsData = (channelKey, platform, obj, platformObj, colorMap) => {
  const [platformOnlyConv, platformOnlyAvgRev] = calcNonOverlapMetrics(platformObj, platformObj)
  const [channelOnlyConv, channelOnlyAvgRev] = calcNonOverlapMetrics(obj, obj)

  const avgRevOverlap = obj.overlap_revenue / obj.overlap_count
  const [avgRevOvrlpDiff, avgRevOvrlpChangeRate] = calcDiff(avgRevOverlap, platformOnlyAvgRev)

  const avgPlatformOnlyTtc = (platformObj.total_time_to_conv - platformObj.overlap_time_to_conv) / platformOnlyConv
  const avgOverlapTtc = obj.overlap_time_to_conv / obj.overlap_count
  const [avgTtcOvrlpDiff, avgTtcOvrlpChangeRate] = calcDiff(avgOverlapTtc, avgPlatformOnlyTtc)

  const platformConvVenn = generateVennItem([platform], platformObj.total_count, undefined, colorMap)
  const channelConvVenn = generateVennItem([channelKey], obj.total_count, undefined, colorMap)
  const overlapConvVenn = generateVennItem([channelKey, platform], obj.overlap_count)

  return {
    key: channelKey,
    channel: channelDisplayName(channelKey),
    color: colorMap[channelKey] || colorHash(channelKey),
    count_channel: channelOnlyConv,
    count_overlap: obj.overlap_count,
    count_platform: platformOnlyConv,
    count_venn: [
      platformConvVenn,
      channelConvVenn,
      overlapConvVenn
    ],
    count_overlap_ratio: obj.overlap_count / platformObj.total_count,
    revenue_overlap: obj.overlap_revenue,
    avg_rev_channel: channelOnlyAvgRev,
    avg_rev_platform: platformOnlyAvgRev,
    avg_rev_overlap: avgRevOverlap,
    avg_rev_diff: avgRevOvrlpDiff,
    avg_rev_change_rate: avgRevOvrlpChangeRate,
    avg_ttc_overlap: avgOverlapTtc,
    avg_ttc_diff: avgTtcOvrlpDiff,
    avg_ttc_change_rate: avgTtcOvrlpChangeRate
  }
}

const calcNonOverlapMetrics = (totalObj, overlapObj) => {
  const nonOverlapConv = totalObj.total_count - (overlapObj?.overlap_count || 0)
  const nonOverlapAvgRev = (totalObj.total_revenue - (overlapObj?.overlap_revenue || 0)) / nonOverlapConv || 0
  return [nonOverlapConv, nonOverlapAvgRev]
}

export const channelDisplayName = (text) => {
  if (!text) return text
  const format = text.replaceAll("_", " ").replaceAll("-", " ").replaceAll(".", " ")
  const spaceCamelCase = format.replace(/([a-z])([A-Z])/g, "$1 $2")
  const words = spaceCamelCase.split(" ")
  const addCaps = words.map((word) => {
    try {
      if (words.length == 1 && word.length < 4) return word.toUpperCase()
      if (word.length < 3) return word.toUpperCase()
      if (word == 'ott' || word == 'gdn') return word.toUpperCase()
      return word[0].toUpperCase() + word.substring(1)
    } catch(e) {
      return word
    }
  }).join(" ")
  if (addCaps == 'Brand') return 'Brand Search'
  if (addCaps == 'Non Brand') return 'Non-Brand Search'
  return addCaps
}

// Hooks
export const addMetrics = (recordObj, path, type = 'total') => {
  recordObj[`${type}_count`] = (recordObj[`${type}_count`] || 0) + path.count
  recordObj[`${type}_revenue`] = (recordObj[`${type}_revenue`] || 0) + path.revenue
  recordObj[`${type}_time_to_conv`] = (recordObj[`${type}_time_to_conv`] || 0) + (path.avg_seconds_til_conversion * path.count)
  recordObj[`${type}_count_new`] = (recordObj[`${type}_count_new`] || 0) + path.count_new_to_file
  recordObj[`${type}_revenue_new`] = (recordObj[`${type}_revenue_new`] || 0) + path.revenue_new_to_file
  recordObj[`${type}_time_to_conv_new`] = (recordObj[`${type}_time_to_conv_new`] || 0) + (path.avg_seconds_til_conversion_new_to_file * path.count_new_to_file)
  recordObj[`${type}_count_repeat`] = (recordObj[`${type}_count`] || 0) - (recordObj[`${type}_count_new`] || 0)
  recordObj[`${type}_revenue_repeat`] = (recordObj[`${type}_revenue`] || 0) - (recordObj[`${type}_revenue_new`] || 0)
  recordObj[`${type}_time_to_conv_repeat`] = (recordObj[`${type}_time_to_conv`] || 0) - (recordObj[`${type}_time_to_conv_new`] || 0)
}

export const generateCompanyRegex = (account) => {
  const { pixel_source_name, advertiser_name } = account || {}
  const pixelSourceName = pixel_source_name.toLowerCase().replaceAll("_", ".").replaceAll("-", ".")
  const advertiserName = advertiser_name.toLowerCase().replaceAll(" ", ".")
  return new RegExp(`${pixelSourceName}|${advertiserName}`)
}

export const detectPlatformTier = (pathsData, platformRegex, organicRegex) => {
  let platformTier = 0
  pathsData
    .flatMap(({ path }) => path)
    .flatMap(tiers => {
      if (!!platformTier) return
      let isOrganic = false
      tiers.map((tier, i) => {
        if (!!platformTier) return
        if (tier.toLowerCase().match(organicRegex)) {
          isOrganic = true
        }
        if (!isOrganic && !!tier.toLowerCase().match(platformRegex)) {
          platformTier = i+1
        }
      })
    })
  return platformTier
}

// Insights
export const blendColors = (colorA, colorB, amount) => {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
  return '#' + r + g + b;
}

// Parts
export const convertToPercent = (value) => {
  return `${Math.ceil((Math.abs(value * 100)))}%`
}