import React, { Component } from 'react';
import { Container, Image, Header, Button } from 'semantic-ui-react';
import { spendPreviewImage } from '@rockerbox/styleguide';

export default ({ onButtonClick }) => (
  <Container textAlign='center'>
    <Image
      src={spendPreviewImage}
      size='medium'
      bordered
      circular
      centered
      className='spend-preview-image m-t-20'
    />
    <Header as='h2'>
      Model Customization
      <Header.Subheader>Get started with model customization by starting a project.</Header.Subheader>
    </Header>
    <Button
      primary
      size='large'
      onClick={onButtonClick}
    >
      Create Project
    </Button>
  </Container>
)
