/**
 * Returns the row in the given `values` array with the spend_input value just below the given `goal` value.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {Object|null} - The row with the spend_input value just below the goal, or null if no such row exists.
 */
export function getLowerRow(values, goal) {
  const index = values.findIndex(row => row.spend_input < goal)
  return index >= 0 ? values[index] : null
}

/**
 * Returns the row in the given `values` array with the spend_input value just above the given `goal` value.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {Object|null} - The row with the spend_input value just above the goal, or null if no such row exists.
 */
export function getUpperRow(values, goal) {
  const index = values.findIndex(row => row.spend_input >= goal)
  return index >= 0 ? values[index] : null
}

/**
 * Returns the coefficients for a simple linear model based on the given `lower` and `actual` rows.
 * @param {Object} lower - The row with the spend_input value just below the goal.
 * @param {Object} actual - The row with the spend_input value just above the goal.
 * @returns {Object} - An object containing the coefficients for the linear model, with `B0` and `respCoef` properties.
 */
export function getLinearModelCoefficients(lower, actual) {
  const B0 = actual.marginal_response
  const spendDelta = actual.spend_input - lower.spend_input
  const respDelta = actual.marginal_response - lower.marginal_response
  const respCoef = respDelta / spendDelta
  return { B0, respCoef }
}

/**
 * Returns the estimated response value for the given `goal` value and response curve represented by the `values` array.
 * @param {Array} values - An array of objects representing the response curve for a feature, each with `spend_input` and `marginal_response` properties.
 * @param {number} goal - The goal value for the feature.
 * @returns {number|null} - The estimated response value for the goal, or null if no such value can be calculated.
 */
export function getEstimatedResponseValue(values, goal) {
  const lower = getLowerRow(values, goal)
  const upper = getUpperRow(values, goal)
  if (!lower || !upper) {
    return null
  }
  const { B0, respCoef } = getLinearModelCoefficients(lower, upper)
  const spendDiff = goal - upper.spend_input
  return B0 + spendDiff * respCoef
}

/**
 * Given an array of response curves and an object of field values, returns an object with estimated response values for each field.
 * If the input array or object is null or empty, or a response curve is missing for a field, the corresponding value will be null.
 * Uses the `getLowerRow`, `getUpperRow`, `getLinearModelCoefficients`, and `getEstimatedResponseValue` helper functions.
 * @param {Array} responseCurves - An array of objects representing response curves for different features, each with a `key` and a `values` array of objects representing the curve, each with `spend_input` and `marginal_response` properties.
 * @param {Object} fieldValues - An object with keys representing field names and values representing goals for each field.
 * @returns {Object} - An object with keys representing field names and values representing estimated response values for each field.
 */
export function forecastData(responseCurves, fieldValues) {
  if (!responseCurves || Object.keys(fieldValues).length === 0) {
    return {};
  }

  return Object.entries(fieldValues).reduce((p, [key, goal]) => {
    const goalValue = goal.value;
    const values = responseCurves.find(curve => curve.key === key)?.values;
    if (!values) {
      p[key] = null;
      return p;
    }

    const lower = getLowerRow(values, goalValue) || { spend_input: 0, marginal_response: 0 };
    const upper = getUpperRow(values, goalValue);
    if (!lower || !upper) {
      p[key] = null;
      return p;
    }

    const { B0, respCoef } = getLinearModelCoefficients(lower, upper);
    const respEst = B0 + (goalValue - upper.spend_input) * respCoef;
    p[key] = respEst;
    return p;
  }, {});
}
