import React from 'react'
import { Progress, Header, Grid } from 'semantic-ui-react'
import { Headers, EmptyMessage } from './parts'

const ProgressBarGrid = ({ data, cols, itemsPerPage=5, color, secondaryColor, total}) => {

  if (data.length == 0) {return <></>};

  const [l1, m1, m2, m3, ...unusedCols] = cols;
  const firstItems = data.slice(0, itemsPerPage)

  return <>
    <Headers label1={ !!m1.display ? m1.display : m2.display } label2={ m3?.display || m2.display } />
    { firstItems.map(row => {
        return <ProgressBar {...{row, cols: [l1, m1, m2, m3], color, secondaryColor, total}} />
      })
    }
  </>
}

const defaultMetricFormatter = (localeOptions) => (x) => x.toLocaleString('en-US', localeOptions);

const ProgressBar = ({ row, cols, color, secondaryColor, total = undefined}) => {

  const [l1, m1, m2, m3] = cols;

  const label = row[l1.key];
  const value1 = m1.as ? m1.as(row, m1) : row[m1.key];
  const value2 = m2.as ? m2.as(row, m2) : row[m2.key];
  const value3 = !!m3 && (m3?.as ? m3.as(row, m3) : row[m3?.key]);
  const f1 = m1.formatter || defaultMetricFormatter(m1.localeOptions);
  const f2 = m2.formatter || defaultMetricFormatter(m2.localeOptions);
  const f3 = !!m3 && (m3?.formatter || defaultMetricFormatter(m3?.localeOptions));
  const defaultColor = color || "purple";

  return <>
    <Grid fluid className="progress-wrapper" style={{marginTop: '.5rem'}}>
      <Grid.Row>
        <Grid.Column width={8} textAlign='left'>
          <Header as='h5' textAlign='left' size='small' style={{ fontWeight: '500' }}>{label}</Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as='h5' textAlign='center' size='small' color={defaultColor} style={{ fontWeight: '500' }}>
            {!!m1.display ? f1(value1) : f2(value2)}
          </Header>
        </Grid.Column>
        <Grid.Column width={4} >
          <Header as='h5' textAlign='center' size='small' color={secondaryColor || defaultColor} style={{ fontWeight: '500' }}>
            {!!f3 ? f3(value3) : f2(value2)}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          { total ?
          <Progress size='small' value={value1} total={total} color={defaultColor} style={{ borderRadius: '2rem !important' }} ></Progress>
          :
          <Progress size='small' value={value1} total={value2} color={defaultColor} style={{ borderRadius: '2rem !important' }} ></Progress>
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
  }

//ProgressBarGrid.propTypes = {
//  /** metric name */
//  data: PropTypes.list,
//  cols: PropTypes.list,
//  itemsPerPage: PropTypes.number,
//}
export default ProgressBarGrid
