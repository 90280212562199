export const trimPercent = (x) => String((Math.round(x*1000)/1000*100).toPrecision(3)).slice(0,4)

export const buildDrawableData = (data,exclude) => {

  if (!data) return []

  const drawableData = Object.keys(data).reduce((p,c) => {
    if (exclude.indexOf(c) == -1) p[c] = data[c]
    return p
  }, {})

  const total = Object.keys(drawableData).reduce((p,x) => p + drawableData[x], 0)
  let running = 0

  const sorted = Object.keys(drawableData).map(x => {
    const percent = drawableData[x]/total
    const current = running
    return {"value":drawableData[x], "name": x, "percent": (drawableData[x]/total) }
  }).sort((p,c) => c.percent - p.percent)
    .map(x => {
      const current = running
      running += x.percent
      x.running = current
      return x
    })

  return sorted
}

export const colorNames = {
  "green": "#66D9AE",
  "orange": "#FFA278",
  "purple": "#7C78DC",
  "red": "#E76D8E",
  "blue": "#0489c1",
  "yellow": "#FFDF78",
  "dark-green": "#42C493",
  "dark-orange": "#DE794C",
  "dark-purple": "#5B57C8",
  "dark-red": "#C94568",
  "dark-blue": "#03638D",
  "dark-yellow": "#DEBC4C",
  "light-green": "#92EBC9",
  "light-orange": "#FFBC9E",
  "light-purple": "#A3A0EC",
  "light-red": "#F095AD",
  "light-blue": "#1298D2",
  "light-yellow": "#FFE89E",
}

const greys = [
  "#9D9C9C","#7D7E7D","#6B6A6A","#555555","#404040","#333333","#292929","#1F1F1F",
]

export const solid_colors = Object.keys(colorNames).map(x => colorNames[x] )
export const colors = solid_colors
const x = []
.concat(
  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, white 2px, white 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, black 4px, black 4px)`)
)
.concat(
  solid_colors.slice(0,-3).map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${solid_colors[i+3]} 2px, ${solid_colors[i+3]} 4px)`)
)
.concat(
  solid_colors.map((c,i) => `repeating-linear-gradient(45deg, ${c}, ${c} 2px, ${greys[i]} 2px, ${greys[i]} 4px)`)
)
.concat(greys)
//.concat(
//  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(0deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
//)
//.concat(
//  solid_colors.slice(0,-1).map((c,i) => `repeating-linear-gradient(135deg, ${c}, ${c} 2px, ${solid_colors[i+1]} 2px, ${solid_colors[i+1]} 4px)`)
//)
