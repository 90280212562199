import React from "react";
import { Button, Progress, Popup, Table, Icon } from "semantic-ui-react";
import { runningPeriod, formatLastingTime } from "./statusCalculation";

const ProgressBarCell = props => {
  const {
    item: { status, started_at, submitted_at, username, job_id }
  } = props;

  const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${job_id}`;
  const isRBUser = username.trim().slice(-14) === "@rockerbox.com";
  const percentage =
    status === "submitted" ? 25 : status === "queued" ? 50 : 75;
  const content =
    status === "submitted"
      ? "Submitted"
      : status === "queued"
      ? "Queued"
      : "Processing";
  const runningTime = started_at
    ? runningPeriod(started_at)
    : runningPeriod(submitted_at);
  const timeContent = formatLastingTime(runningTime);

  const ProgressBar = (
    <Progress
      percent={percentage}
      indicating
      content={
        isRBUser ? (
          <a href={jobUrl} target="_blank">
            view workqueue job
          </a>
        ) : null
      }
    />
  );

  return (
    <Table.Cell textAlign="center">
      <div style={{ width: "200px", marginTop: "15px" }}>
        <Popup content={content + timeContent} trigger={ProgressBar} />
      </div>
    </Table.Cell>
  );
};

export default ProgressBarCell;
