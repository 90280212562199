import React, { useState, useEffect } from 'react';
import { Form } from "semantic-ui-react";
import { useDatalake } from '../DatalakeContext/hooks';
import ErrorMessage from "./ErrorMessage";
import { ROCKERBOX_REPORT_TYPE } from '../constants';
import { compose, filter, map, get } from 'lodash/fp';
import { getSegmentInfo } from '../../../api/datalake';

const DatasetOptions = compose(
  map(dataset => ({ key: dataset.action_id, value: dataset.action_id, text: `${dataset.action_name} (${dataset.action_id})` })),
  filter(dataset => !dataset.action_name.toLowerCase().includes('all pages'))
);

const RockerboxDatasetSyncForm = ({
  disableInputs,
  submitDatasetToSync,
  width,
  styles
}) => {

  const {
    datalakeReports,
    selectDropdownOptionsByReportType,
    segments
  } = useDatalake()

  const [conversionTypeOptions, setConversionTypeOptions] = useState([])
  const [datasetOptions, setDatasetOptions] = useState([])
  const [conversionType, setConversionType] = useState({})
  const [dataset, setDataset] = useState({})
  const [tableName, setTableName] = useState('')
  const [error, setError] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)

  const retrieveDatalakeReportOptions = () => {
    const reportRockerboxOptions = selectDropdownOptionsByReportType(ROCKERBOX_REPORT_TYPE)
    setConversionTypeOptions(reportRockerboxOptions)
    retrieveDatalakeDatasets()
  }

  const retrieveDatalakeDatasets = () => {
    const segmentOptions = DatasetOptions(segments)
    setDatasetOptions(segmentOptions);
  }

  const handleConversionTypeChange = (_, { value }) => setConversionType(value)
  const handleDatasetChange = (_, { value }) => setDataset(value)
  const handleTableNameChange = (_, { value }) => setTableName(value)

  const handleSubmit = () => {
    setError('')
    setDisableSubmit(true)
    return submitDatasetToSync(conversionType.toString(), tableName, dataset.toString())
      .then((e) => {
        setDisableSubmit(false)
      })
      .catch((e) => {
        const errorMessage = get(e, 'response.data.message');
        setError(errorMessage)
        setDisableSubmit(false)
      })
      .finally(() => {
        setDataset({})
        setConversionType({})
        setTableName('')
      })
  }

  useEffect(() => {
    if(datalakeReports.length) retrieveDatalakeReportOptions()
  }, [datalakeReports, conversionType])

  useEffect(() => {
    if(dataset > 0 && conversionType == 'compiled_mta') {
        segments.map((segment) => {
          if(segment['action_id'] === dataset && segment['action_name'].includes('pinterest')) {
            setDisableSubmit(true)
            setError("This dataset is not available for conversions that include attribution data provided by Pinterest.")
          }
        })
    }
  }, [dataset, conversionType])

  return (
    <>
      <Form>
        <Form.Group style={styles}>
        <Form.Field>
          <Form.Select
            fluid
            style={{ width: width }}
            label="Conversion"
            options={conversionTypeOptions}
            value={conversionType}
            onChange={handleConversionTypeChange}
            disabled={disableInputs}
          />
        </Form.Field>
        <Form.Field>
          <Form.Select
            fluid
            style={{ width: width }}
            label="Dataset"
            options={datasetOptions}
            value={dataset}
            onChange={handleDatasetChange}
            disabled={disableInputs}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            fluid
            label="Table Name"
            style={{ width: width }}
            placeholder="Enter a table name"
            value={tableName}
            onChange={handleTableNameChange}
            disabled={disableInputs}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            size="medium"
            content="Sync this dataset"
            type="button"
            onClick={handleSubmit}
            disabled={disableInputs || disableSubmit}
            style={{marginTop: 20}}
          />
        </Form.Field>
        </Form.Group>
      </Form>
      {error && <ErrorMessage message={error} />}
    </>
  )
}

export default RockerboxDatasetSyncForm;
