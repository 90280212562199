import React, { useContext } from 'react'
import shallow from 'zustand/shallow'
import { Icon, Button } from 'semantic-ui-react'

import { SelectMenu, SplitLayout, ContentCard } from '@rockerbox/styleguide'
import CacheContext from '../../utils/CacheContext.js'
import { useURLState, forceViewPath } from '../hooks'
import { entityStore } from './entityStore'

import {NoPrograms, NoSelection} from './NoPrograms';
import Status from './ViewStatus';
import ViewSponsors from './ViewSponsors';
import ViewPrograms from './ViewPrograms';
import ViewProgram from './ViewProgram';
import Impact from '../Impact';
import ViewCampaignSpend from './ViewCampaignSpend';
import CreateEntityProgram from './CreateProgram';

const STEPS = ["Status"].map(text => Object.assign({text, value: text}))



const Steps = ({ current, refresh }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const features = state?.account?.features || []
  const hasLegacySpend = features.includes('sponsorships_legacy_spend')
  const hasLegacyUTMs = features.includes('sponsorships_legacy_utms')

  // forceViewPath()

  const [open, setOpen] = React.useState(false)
  const [activeStep, setActiveStep] = useURLState("activeStep", "No Programs")
  const [showDiagnostics, setShowDiagnostics] = React.useState(false)

  const [ entityConfigs, entityLoading, programOptions, getEntityConfigs, entitySegmentId ] = entityStore(({ 
      entityConfigs,
      entityLoading, 
      programOptions, 
      getEntityConfigs,
      entitySegmentId
  }) => [ entityConfigs, entityLoading, programOptions || [], getEntityConfigs, entitySegmentId ], shallow)

  React.useEffect(() => { getEntityConfigs() }, [])
  React.useEffect(() => {

    if (!!activeStep) return;

    const _activeStep = (programOptions && programOptions.length) ? programOptions[0].value : "No Programs"
    setActiveStep(_activeStep)
  }, [programOptions])

  const { programs, entityData } = React.useMemo(
    () => programOptions.find(row => row.value == activeStep)||{},
    [ programOptions, activeStep ]
  )

  const onClose = () => setOpen(false)

  const selectedView = activeStep == "All Sponsors" ? <ViewSponsors entityData={entityConfigs} {...{getEntityConfigs}}/> :
    activeStep == "All Programs" ? <ViewPrograms entityData={entityConfigs} /> :
    (entityData || programs) ? <SplitLayout
        leftWidth={4}
        rightWidth={12}
        leftContent={<ViewProgram {...{programs, entityConfigs, programOptions, hasLegacySpend}} /> }
        rightContent={<ViewSponsors {...{getEntityConfigs, entityConfigs, entityData: entityData || [], program: programs[0], hasLegacySpend, hasLegacyUTMs}} contentOnly /> }
      /> :
    
    activeStep == "Status" ? <Status /> :
    activeStep == "Impact" ? <Impact title="Entity" datasets={["entity_difference"]} segmentId={entitySegmentId} /> :
    activeStep == "Fixed Costs" ? <ViewCampaignSpend /> :
    entityConfigs ? <NoSelection /> : <NoPrograms {...{setOpen}} />
      

  return entityLoading ? 
    <ContentCard.LoadingView /> :
    <SplitLayout
      leftWidth={2}
      rightWidth={14}
      leftContent={<>
        <SelectMenu
          value={activeStep}
          title="Programs"
          options={programOptions}
          onChange={(_, { value }) => setActiveStep(value)}
          style={{ boxShadow: 'none' }}
        />
        <CreateEntityProgram {...{open, onClose, programOptions}} />
        <Button
          fluid
          primary
          content="Create Program"
          onClick={() => setOpen(true)}
        />
        <SelectMenu
          value={activeStep}
          title="Diagnostics"
          options={STEPS}
          onChange={(_, { value }) => setActiveStep(value)}
          style={{ background: 'transparent', boxShadow: 'none', marginTop: 20 }}
        />
      </>}
      rightContent={selectedView}
    />
}

export default Steps;
