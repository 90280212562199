import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  ContentCard,
  ColumnLayout,
  IndexGrid,
  ExpandableIndexGrid,
  ScrollingPopup
} from "@rockerbox/styleguide";
import { Menu, Icon } from "semantic-ui-react";
import SavedReportIndex from "./SavedReportIndex";
import ScheduledReportIndex from "./ScheduledReportIndex";
import DeliveredReportIndex from "./DeliveredReportIndex";
import DeliveredReportMenu from "./DeliveredReportMenu";
import { _pushPath } from "../pushpath";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const categories = [
  // { name: "outbox", label: "Delivery Options" },
  { name: "saved", label: "Saved Reports" },
  { name: "scheduled", label: "Scheduled Reports" },
  { name: "delivered", label: "Scheduled Delivery Status" }
  // { name: "exported", label: "Ad Hoc Exports" }
];

function constructTableData(messages, outbox) {
  if (!messages || !messages.length) {
    return [];
  }
  const m = new moment.tz(moment.tz.guess());
  const utcOffset = m._offset;

  return messages
    .filter(
      message =>
        message.finished_at &&
        message.finish_type === "success" &&
        message.outbox_id
    )
    .map(message =>
      Object.assign(message, {
        delivered_at: message.finished_at
          ? new moment(moment(message.finished_at) + utcOffset).format("LLL")
          : "None",
        download_report: {
          enable:
            message.finish_type === "success" &&
            outbox.outbox_type === "do_spaces"
              ? true
              : false,
          id: message.id
        }
      })
    );
}

class OutboxReportIndex extends _pushPath(Component) {
  state = {
    category: "saved",
    optionType: "do_spaces",
    currentOutbox: {},
    outbox_name: "Email Delivery"
  };

  componentDidMount() {
    const { match: { params: { category, id } = {} } = {} } = this.props;
    if (category && category !== this.state.category) {
      return this.setState({ category });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      outboxes,
      match: { params: { category, id } = {} } = {}
    } = this.props;
    if (prevProps.outboxes.length !== this.props.outboxes.length) {
      if (category === "delivered" && id) {
        const currentOutbox = outboxes.filter(outbox => {
          const message = outbox.messages;
          const findMessageById = outbox.messages.some(
            message => message.id == id
          );
          return findMessageById;
        });
        if (currentOutbox && currentOutbox.length === 1) {
          return this.setState({ outbox_name: currentOutbox[0].name });
        }
      }
      if (category === "outbox" && id) {
        const currentOutbox = this.props.outboxes.filter(
          outbox => outbox.id == id
        )[0];
        return this.setState({
          currentOutbox: currentOutbox || {},
          outbox_name: (currentOutbox && currentOutbox.name) || "",
          optionType: (currentOutbox && currentOutbox.outbox_type) || "email"
        });
      }
    }
  }

  rightSideContent(currentCategory) {
    const { category, optionType, currentOutbox, outbox_name } = this.state;
    const { outboxes } = this.props;

    const components = {
      scheduled: ScheduledReportIndex,
      saved: SavedReportIndex,
      delivered: DeliveredReportIndex
    };
    const Component = components[currentCategory];

    return (
      <Component
        {...this.props}
        {...this.state}
        currentOutbox={currentOutbox}
        outbox_name={outbox_name}
        updateReportId={this.updateReportId.bind(this)}
        delieveredReports={this.getDeliveredReportByName(outboxes)}
        redirect={this.redirect.bind(this)}
        isRBUser={this.isRBUser.bind(this)}
      />
    );
  }

  handleMenuHeaderClick(category) {
    this.setState({ category });
    this.updatePath({ category, id: null });
  }

  switchCurrentOutbox(outbox) {
    this.setState({ currentOutbox: outbox });
  }

  handleTypeMenuHeaderClick(type) {
    const outboxes = this.props.outboxes.filter(
      outbox => outbox.outbox_type === type
    );
    this.updatePath({ category: this.state.category, id: null });
    this.setState({
      optionType: type,
      currentOutbox: outboxes.length > 0 ? outboxes[0] : {}
    });
  }

  handleOutboxNameClick(name) {
    this.updatePath({ category: this.state.category, id: null });
    this.setState({
      outbox_name: name
    });
  }

  updateReportId({ id }) {
    this.updatePath({ category: this.state.category, id });
  }

  redirect(path) {
    this.props.history.push(path);
  }

  getDeliveredReportByName(allOutbox) {
    return allOutbox.reduce((prev, current) => {
      if (prev[current.name]) {
        prev[current.name] = prev[current.name].concat(current.messages);
      } else {
        prev[current.name] = current.messages;
      }
      prev[current.name] = constructTableData(prev[current.name], current);
      return prev;
    }, {});
  }

  isRBUser() {
    const { username } = this.props;
    return username && username.trim().slice(-14) === "@rockerbox.com";
  }

  render() {
    const self = this;
    const { category, optionType, outbox_name } = this.state;
    const { outboxes, setOutboxes, loading } = this.props;
    const CategoryMenu = (categories, currentCategory) => {
      const items = categories.map(category => {
        return (
          <Menu.Item
            active={currentCategory === category.name}
            onClick={() => this.handleMenuHeaderClick(category.name)}
          >
            <Menu.Header>
              {category.label}
              {currentCategory === category.name && (
                <Icon
                  name="chevron right"
                  style={{ float: "right", margin: 0 }}
                />
              )}
            </Menu.Header>
          </Menu.Item>
        );
      });
      return (
        <React.Fragment>
          <Menu vertical fluid>
            {items}
          </Menu>
        </React.Fragment>
      );
    };

    function renderMiddle() {
      if (category === "delivered") {
        return (
          <DeliveredReportMenu
            delieveredReports={self.getDeliveredReportByName(outboxes)}
            outbox_name={outbox_name}
            handleOutboxNameClick={self.handleOutboxNameClick.bind(self)}
          />
        );
      }

      return null;
    }

    return (
      <React.Fragment>
        <ColumnLayout
          leftWidth={3}
          centerWidth={
            category === "outbox" || category === "delivered" ? 3 : 0
          }
          rightWidth={
            category === "outbox" || category === "delivered" ? 9 : 11
          }
          leftContent={CategoryMenu(categories, category)}
          rightContent={this.rightSideContent(category)}
        >
          {renderMiddle()}
        </ColumnLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const { username } = state.app;

  return { username };
}

export default connect(mapStateToProps)(withRouter(OutboxReportIndex));
