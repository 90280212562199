import React, { Component } from 'react';
import { checkUrl, getAttributableEvents } from '../../utils/api';
import TestInput from './TestInput';
import TestOutput from './TestOutput';
import { ContentCard, ViewHeader, testingDude } from '@rockerbox/styleguide';

const TestMarketingTouchpoint = () => {
  const [marketType, setMarketType] = React.useState('url');
  const [url, setUrl] = React.useState('');
  const [referrer, setReferrer] = React.useState('');
  const [checkUrlData, setCheckUrlData] = React.useState([]);
  const [attributableEvents, setAttributableEvents] = React.useState([]);

  React.useEffect(() => {
    getAttributableEvents().then((attributableEvents) => {
      setAttributableEvents(attributableEvents);
    });
  }, []);

  const handleSetMarketType = (e, data) => {
    setMarketType(data.value);
    setUrl('');
  };

  const handleUrlChange = (e) => {
    if (marketType === 'external' || marketType === 'externalJSON') setUrl(e);
    else setUrl(e.target.value);
  };

  const handleReferrerChange = (e) => {
    setReferrer(e.target.value);
  };

  const handleSubmit = () => {
    const postObj = {
      attributable_events: attributableEvents,
      events: [{ url, referrer }],
    };

    checkUrl(postObj)
      .then((data) => {
        setCheckUrlData([...data, ...checkUrlData]);
      })
      .catch((err) => {
        console.log('Error with Input', err);
        const errObj = {
          marketType,
          original_url: url,
          request_referrer: referrer,
          match_ids: []
        }
        setCheckUrlData([errObj, ...checkUrlData]);
      })
      .then(() => {
        setMarketType('url');
        setUrl('');
        setReferrer('');
      });
  };

  const handleClickClear = () => {
    setCheckUrlData([]);
  }

  const marketTypes = [
    { name: 'URL or Referrer URL', value: 'url'},
    { name: 'Impression Tracking Pixel', value: 'pixel'},
    { name: 'External Marketing', value: 'external' },
    { name: 'External (JSON) Marketing', value: 'externalJSON'},
  ];

  return (
    <ContentCard
      title={
        <ViewHeader
          image={testingDude}
          headerText="Verify your marketing touchpoints are mapped correctly."
          menuOptions={marketTypes}
          setOption={setMarketType}
          active={marketType}
        />
      }
      explainer="Enter a landing page and/or referrer URL, impression-tracking pixel, or external marketing, and see which rules match"
    >
      <TestInput
        advertiser={attributableEvents[0]}
        url={url}
        referrer={referrer}
        marketType={marketType}
        setMarketType={handleSetMarketType}
        handleUrlChange={handleUrlChange}
        handleReferrerChange={handleReferrerChange}
        handleSubmit={handleSubmit}
      />
      {checkUrlData.length > 0 ? (
        <TestOutput
          checkUrlData={checkUrlData}
          attributableEvents={attributableEvents}
          handleClickClear={handleClickClear}
        />
      ) : ''}
    </ContentCard>
  );
};

export default TestMarketingTouchpoint;
