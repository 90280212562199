import React, { Component } from 'react'
import { ContentCard, Checklist, AlertCard } from '@rockerbox/styleguide'
import HelpResources from './helpResources'

const Shopify = () => (<>
  <AlertCard
    success
    dismissable
    as='h3'
    message="Setup complete!"
    description="We're pulling in data from Shopify to populate your Rockerbox dashboard."
  />
  <ContentCard title="Account Setup Status">
    <Checklist
      items={[
        {
          title: "Activating your account",
          completed: true,
        },
        {
          title: "Configuring Rockerbox on your Shopify store",
          description: "We'll be placing a pixel on your store and ingesting order data to help you track customers and their marketing activity.",
          completed: true,
        },
      ]}
    />
  </ContentCard>
  <HelpResources />
</>)

export default Shopify
