import { readParquet } from "../../utils/arrow/parquet";
import { tableFromIPC } from "apache-arrow";
import * as aq from "arquero";
import { isDate, isValidJSONArray, isDecimal, isBigInt } from "./dataValidation";
import { trimEnd } from "lodash";

const MAX_INT32 = 2 ** 31 - 1;
function isNegative(value) {
  return value[value.length - 1] > MAX_INT32;
}

function negate(value) {
  let carry = 1;
  for (let i = 0; i < value.length; i++) {
    const elem = value[i];
    const updated = ~elem + carry;
    value[i] = updated;
    carry &= elem === 0 ? 1 : 0;
  }
}

function uint32ArrayToDecimal(value, scale=10){
  const negative = isNegative(value);
  const sign = negative ? "-" : "";
  if (negative) {
    negate(value);
  }

  const str = value.toString().padStart(scale, "0");
  if (scale === 0) {
    return `${sign}${str}`;
  }

  const wholePart = str.slice(0, -scale) || "0";
  const decimalPart = trimEnd(str.slice(-scale), "0") || "0";
  return `${sign}${wholePart}.${decimalPart}`;
}


const fetchToParquetBuffer = async (promise) => {
  const resp = await promise;
  const raw_buffer = await resp.arrayBuffer()
  return new Uint8Array(raw_buffer)
}

const getAsBuffer = async (url) => {
  const promise = fetch(url, { credentials: "include" })
  const parquetBuffer = await fetchToParquetBuffer(promise);
  const arrowIPC = await readParquet(parquetBuffer);
  return arrowIPC
}

export const getParquetAndReturnJson = async (url) => {
  const _buffer = await getAsBuffer(url)
  const _data = tableFromIPC(_buffer)
  const _arch = aq.fromArrow(_data)
  const columnNames = _arch.columnNames()
  const utcDateColumns = columnNames
    .filter((col) => isDate(_arch.get(col)))
    .reduce((p, col) => {
      p[col] = `d => op.format_utcdate(d['${col}'])`
      return p
    }, {})
  const validJSONColumnsArrays = columnNames
    .filter((col) => isValidJSONArray(_arch.get(col)))
    .reduce((p, col) => {
      p[col] = aq.escape(d => JSON.parse(d[col]))
      return p
    }, {})
  const decimalCols = _data.schema.fields.filter(f => f.type.scale)
  const scaleMap = decimalCols.reduce((p, c) => {
    p[c.name] = c.type.scale;
    return p;
  }, {});
  
  const decimalColumns = columnNames
    .filter((col) => isDecimal(_arch.get(col)))
    .reduce((p, col) => {
      p[col] = aq.escape(d => uint32ArrayToDecimal(d[col], scaleMap[col]))
      return p
    }, {})
  const bigIntColumns = columnNames
    .filter((col) => isBigInt(_arch.get(col)))
    .reduce((p, col) => {
      p[col] = aq.escape(d => Number(d[col]));
      return p
    }, {})

  const arch = _arch
    .derive(utcDateColumns)
    .derive(validJSONColumnsArrays)
    .derive(decimalColumns)
    .derive(bigIntColumns)

  return arch.objects()
}
