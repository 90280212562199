import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Icon, Checkbox, Input, Divider, Button } from 'semantic-ui-react';
import { DEFAULT_TIERS } from '../../../constants/tiers';
import { Drawer } from '../../index'
import styled from 'styled-components'

const TierFilterGroup = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 35px;
`

const TierLabel = styled.span`
  background-color: #475ddc;
  padding: 5px;
  border-radius: 13px;
  color: white; 
  margin: 0px 5px 5px 0px;
  display: inline-flex;
  align-items: center;
`

const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`

const RockerboxStyledDropdown = styled(Dropdown)`
  border: solid 0.5px #bfc6f3 !important; 
  .text {
    color: #333333 !important;
  }
`

const DrawerInnerList = styled.div`
 width: 100%;
 height: calc(100vh - 195px);
 overflow-y: auto;
`

const DrawerFooter = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
`

const FILTER_OPTIONS = [
  { text: 'equals', value: 'equals', key: 'equals' },
  { text: 'does not equal', value: 'not_equal', key: 'not_equal'},
]

const TierFilter = ({ tier, tierList, tierFilters, setTierFilters }) => {

  const [operator, setOperator] = useState('equals')
  const [tierFilterDrawerOpen, setTierFilterDrawerOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [filteredList, setFilteredList] = useState(tierList)
  const [checkedList, setCheckedList] = useState(tierFilters)
  const [pillsList, setPillsList] = useState(tierFilters || [])
  const [closing, setClosing] = useState(false)

  useEffect(() => {
    setCheckedList(tierFilters)
  }, [tierFilters])

  useEffect(() => {
    setFilteredList(tierList)
  }, [tierList])

  useEffect(() => {
    setPillsList(checkedList)
  }, [checkedList])

  useEffect(() => {
    if (closing) {
      setTierFilters(pillsList)
      setClosing(false)
    }
  }, [closing])

  useEffect(() => {
    if (!tierFilterDrawerOpen) {
      setSearchValue('')
      setFilteredList(tierList)
    }
  }, [tierFilterDrawerOpen])

  useEffect(() => {
    if (!searchValue) {
      setFilteredList(tierList)
      return
    }
    const filtered = tierList.filter(x => x.toLowerCase().includes(searchValue))
    setFilteredList(filtered)
  }, [searchValue])

  const handleCheckbox = (e, { checked, label }) => {
    if (checked) {
      setCheckedList([...checkedList, label])
    }
    if (!checked) {
      const removeChecked = checkedList.filter(x => x !== label)
      setCheckedList(removeChecked)
    }
  }

  const handleDrawerClose = () => {
    setTierFilterDrawerOpen(false)
    setClosing(true)
  }

  const removeTier = (value) => {
    const filtered = checkedList.filter(x => x !== value)
    setTierFilters(filtered)
  }

  return (
    <TierFilterGroup>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Label style={{width: 40}}>
          {`${DEFAULT_TIERS[tier]}`}
        </Label>
        <Label>
          CONTAINS
        </Label>
        {/* <RockerboxStyledDropdown selection style={{marginRight: 10}} options={FILTER_OPTIONS} value={operator} onChange={(e, { value }) => setOperator(value)} /> */}
        <div>
          <Icon
            name="ellipsis horizontal"
            circular
            inverted
            color="purple"
            onClick={() => setTierFilterDrawerOpen(true)}
            style={{fontSize: 14, cursor: 'pointer', margin: '0 10px'}}
          />
        </div>
      </div>
      <div>
        {pillsList.map(x => <TierLabel>
          {x} <Icon onClick={() => removeTier(x)} name="close" circular color="purple" size="small" style={{backgroundColor: "#fff", marginLeft: 5, marginRight: 0, cursor: 'pointer'}}/>
          </TierLabel>)}
      </div>
        
      <Drawer
        openDrawer={tierFilterDrawerOpen}
        onDrawerClose={handleDrawerClose}
        id={`${DEFAULT_TIERS[tier]}`}
        noDelay
      >
        <h2>{`${DEFAULT_TIERS[tier]} Filters`}</h2>
        <Input
          fluid
          icon="search"
          iconPosition="left"
          placeholder={`Search ${DEFAULT_TIERS[tier]} filters`}
          onChange={(evt, { value }) => setSearchValue(value.toLowerCase())} 
          style={{ marginBottom: 15 }}
        />
        <DrawerInnerList>
          {filteredList.map((item) => (
            <div>
              <Checkbox
                style={{ padding: '0 10px 10px 0', wordBreak: 'break-all' }}
                label={item}
                checked={checkedList.includes(item)}
                onChange={handleCheckbox}
              />
            </div>
          ))}
        </DrawerInnerList>
        <DrawerFooter>
          <Button
            primary
            content='Done'
            onClick={handleDrawerClose}
          />
          <Button
            inverted
            color='purple'
            content={`Clear ${DEFAULT_TIERS[tier]} filters`}
            onClick={() => {
              setCheckedList([])
            }}
          />
        </DrawerFooter>
      </Drawer>
    </TierFilterGroup>
  );
}

export default TierFilter
