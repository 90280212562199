import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { postRedshiftGrantPermission } from '../../../api/datalake';
import { useStepsState, useStepsDisplay } from '../StepsSectionContext';
import ErrorMessage from '../components/ErrorMessage';

const formStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: 'auto'
}

const RedshiftGrantPermission = () => {
  const [clientRoleArn, setClientRoleArn] = useState('')
  const [error, setError] = useState('');
  const [, setStepsDisplay] = useStepsDisplay();
  const [, setStateData] = useStepsState();

  const handleSubmitArn = () => {
    setError('')
    postRedshiftGrantPermission(clientRoleArn)
      .then((data) => {
        data = data['data']
        setStateData(data)
        if (data['status'] === 'success') {
          setStepsDisplay(2, true);
        }
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }

  const handleInputChange = (_, { value }) => setClientRoleArn(value);

  return (
    <Form>
      <Form.Group style={formStyles}>
        <Form.Field>
          <Form.Input
            label='ARN'
            placeholder='arn:aws:iam::99912...'
            value={clientRoleArn}
            onChange={handleInputChange}
            style={{ width: 500 }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            size='small'
            content='Submit ARN'
            type='button'
            onClick={handleSubmitArn}
            style={{ fontSize: 13 }}
          />
        </Form.Field>
      </Form.Group>
      {error && <ErrorMessage message={error} />}
    </Form>
  )
}

export default RedshiftGrantPermission;
