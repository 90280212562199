import React, { Component } from 'react';
import { Segment, Header, Input, Container, Dropdown, Button, Form, Table, Modal } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as routes from '../../../routes';

import { getSegments, getAuthorizations, getFacebookConversions, updateFacebookSyntheticConfigs, createFacebookSyntheticConfigs } from '../../../utils/api';

const tiers = d3.range(1,6).map(row => `tier_${row}`)

class FacebookComparisonForm extends Component {

  state = {
    id: false,
    account_id: false,
    facebookAuths: [],
    selectedAuth: false,
    facebookSegments: [],
    selectedConversion: false,
    selectedView: false,
    segments: []
  }

  componentDidMount() {
    if (this.props.data) this.deriveStateFromProps();
    this.getFacebookAccounts()
    this.getSegments()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedAuth !== prevState.selectedAuth) this.getFacebookConversions();
    if (this.props.data !== prevProps.data) this.deriveStateFromProps();
  }

  deriveStateFromProps() {
    const { id, facebook_view_pixel, facebook_conversion_pixel, filter_id, account_id } = this.props.data;

    const tierValues = tiers.reduce((p,c) => {
      p[c] = this.props.data[c]
      return p
    },{})

    const [ selectedConversion, selectedView ] = [ facebook_conversion_pixel, facebook_view_pixel ];

    const { facebookAuths } = this.state;
    const selectedAuth = this.getSelectedAuthId(facebookAuths, account_id) 

    this.setState({ filter_id, selectedAuth, selectedConversion, selectedView, account_id, id, ...tierValues })
  }

  getSelectedAuthId(facebookAuths, account_id) {
    const missingData = (!((facebookAuths.length > 0) && account_id))

    if (missingData) return false;
    return facebookAuths.find(row => row.account_id == account_id).id
  }

  async getSegments() {
    const s = await getSegments()
    
    const segments = s
      .filter(row => !row['is_child'])
      .map(row => {
        return {text: row.action_name, value: row.action_id}
      })

    this.setState({ segments })
  }

  async getFacebookAccounts() {
    const resp = await getAuthorizations()
    const accounts = resp
      .filter(row => row.platform.indexOf("facebook") > -1)

    if (accounts.length > 0) {
      const facebookAuths = accounts
        .reduce((p,c) => p.concat(c.authorizations), [])
        .map(row => {
          row.text = row.account_name
          row.value = row.id
          return row
        })
      const { account_id } = this.state;
      const selectedAuth = this.getSelectedAuthId(facebookAuths, account_id);
      this.setState({ facebookAuths, selectedAuth })
    }
  }

  async getFacebookConversions() {
    const { selectedAuth } = this.state;

    if (selectedAuth) {
      const resp = await getFacebookConversions(selectedAuth)
      const facebookSegments = [
          {"text":"View Content","value":"offsite_conversion.fb_pixel_view_content"},
          {"text":"Purchase","value":"offsite_conversion.fb_pixel_purchase"},
          {"text":"Add Payment Info","value":"offsite_conversion.fb_pixel_add_payment_info"},
          {"text":"Add To Cart","value":"offsite_conversion.fb_pixel_add_to_cart"},
          {"text":"Complete Registration","value":"offsite_conversion.fb_pixel_complete_registration"},
          {"text":"Initiate Checkout","value":"offsite_conversion.fb_pixel_initiate_checkout"},
          {"text":"Lead", "value":"offsite_conversion.fb_pixel_lead"}
        ].concat(resp
          .map(row => {
            row.text = row.name
            row.value = `offsite_conversion.custom.${row.id}`

            return row
          })
        )
      const { account_id } = this.state.facebookAuths.find(row => row.id == selectedAuth)
      this.setState({ facebookSegments, account_id })
      
    }
  }

  onSubmit = () => {
    const { id } = this.state

    const send = id ? updateFacebookSyntheticConfigs.bind(false, id) : createFacebookSyntheticConfigs;

    const { selectedView, selectedConversion, selectedAuth, filter_id, account_id } = this.state
    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = this.state

    const data = {
      facebook_view_pixel: selectedView, 
      facebook_conversion_pixel: selectedConversion, 
      id, filter_id, account_id,
      tier_1, tier_2, tier_3, tier_4, tier_5 
    }

    send(data)
      .then(resp => {
        if (this.props.onSuccess) this.props.onSuccess()
        this.props.history.push(routes.dataSources + "/view/fb_data")
      })
  }

  setDropdownField = (name) => (event, { value }) => {
    this.setState({ [name] : value })
  }
 
  

  render() {
    const { onSubmit } = this;
    const { facebookAuths, selectedAuth } = this.state;
    const { segments, filter_id } = this.state;
    const { id, facebookSegments, selectedView, selectedConversion } = this.state;
    const title = id ? "Edit" : "Create"

    return (
      <ContentCard title={`${title} Synthetic Event`} >
        <Form onSubmit={onSubmit}>
          <Segment style={{"border":"none","boxShadow":"none"}}>
            <label>Rockerbox</label>
            <Form.Group inline>
              <Form.Field>
                <label>Conversion Segment</label>
                <Dropdown 
                  onChange={this.setDropdownField("filter_id")}
                  value={filter_id}
                  options={segments}
                  selection
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment secondary>
            <label>Facebook</label>
            <Form.Group inline>
              <Form.Field>
                <label>Account</label>
                <Dropdown 
                  onChange={this.setDropdownField("selectedAuth")}
                  value={selectedAuth}
                  options={facebookAuths}
                  selection
                />
              </Form.Field>
              <Form.Field>
                <label> View Segment </label>
                <Dropdown 
                  onChange={this.setDropdownField("selectedView")}
                  value={selectedView}
                  options={facebookSegments}
                  selection
                />
              </Form.Field>
              <Form.Field>
                <label>Conversion Segment </label>
                <Dropdown 
                  onChange={this.setDropdownField("selectedConversion")}
                  value={selectedConversion}
                  options={facebookSegments}
                  selection
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          {/*
          <Segment style={{"border":"none","boxShadow":"none"}}>
            <label>Tier Mapping</label>
            <Form.Group inline>
              { tiers.map(tier => (
                  <TierInputRow {...{label: tier, value: this.state[tier], onChange : this.setDropdownField(tier)}} />
                )) 
              }
            </Form.Group>
          </Segment>
          */}
          <Button primary type="submit">Save</Button>
        </Form>
      </ContentCard>
    )
  }
}

const TierInputRow = ({label, value, onChange}) => (
  <Form.Field>
    <label>{ label }</label>
    <Input onChange={onChange} value={value} />
  </Form.Field>
)
export default withRouter(FacebookComparisonForm);
