import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Label, Table, Popup, Menu, Icon, Dropdown } from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Aux } from '../stateless';
import * as routes from '../../routes';
import DailyChart from '../DailyChart'
import { Currency, formatPercent } from '../../utils/format_helpers';

const formatter = new Intl.NumberFormat('en-US');

const LinkTo = ({ item: { action_id, action_name } }) => (
  <Table.Cell width={6}>
    <Popup inverted content='View Report'
           position='right center' size='small'
           trigger={
            <Link to={routes.conversionData + "/" + action_id}>{action_name}</Link>
           } />
  </Table.Cell>
);

const NumberComponent = (key, format=formatter.format) => props => (
  <Table.Cell collapsing>
    <span> { props.item[key] ? format(props.item[key]) : "-" } </span>
  </Table.Cell>
)

const RevenueCell = (key) => props => (
  <Table.Cell collapsing>
    <span> { props.item['include_revenue'] === 1 && props.item[key] > 0 && <Currency amount={props.item[key]}/>}</span>
  </Table.Cell>
)

const RevenueChange = (key) => props => (
  <Table.Cell width={3}>
    { Boolean(props.item['include_revenue'] === 1) &&
      <Label circular color={props.item[key] < 0 ? "red" : props.item[key] ? "green" : ""} size="tiny">
        <Icon name={props.item[key] < 0 ? "angle down" : props.item[key] ? "angle up" : ""} />
        {formatPercent(props.item[key])}
      </Label>
    }
  </Table.Cell>
)


const PercentComponent = (key) => props => (
  <Table.Cell width={3}>
    { Boolean(props.item[key]) &&
      <Label circular color={props.item[key] < 0 ? "red" : props.item[key] ? "green" : ""} size="tiny">
        <Icon name={props.item[key] < 0 ? "angle down" : props.item[key] ? "angle up" : ""} />
        {formatPercent(props.item[key])}
      </Label>
    }
  </Table.Cell>
)

export const Daily = props => (
  <Table.Cell width={4}>
    <DailyChart data={props.item.daily && props.item.daily.conversionsByDate} refPrefix={props.item.action_id} width={250} forcedBuckets={props.value}/>
  </Table.Cell>
);

const Weekly = x => <Label circular size="tiny">Weekly Change</Label>


const CheckComponent = (selectedArray, onClick) => props => (
  <Table.Cell collapsing>
    {props.item.is_child ? <Icon name='chevron right' /> :
      <span>
        <Checkbox
          value={props.item.action_id}
          checked={selectedArray.indexOf(props.item.action_id) > -1 }
          onChange={(_,data) => onClick(data) }
        />
      </span>
    }
  </Table.Cell>
)

const Modeled = (props) => {
  const {item} = props;
  return (
    <Table.Cell textAlign='center'>
      {item.use_model !== 0 ?
        <Icon color='green' name='check' />
      :
        '-'
      }
    </Table.Cell>
  )
}

const SelectAll = props => {
  return (
    <Checkbox
      checked={props.selectAll}
      indeterminate={!props.selectAll}
      onChange={(_, data) => props.handleSelectAll(data)}
    />
  )
}


const SummaryTable = ({segments, onCheck, selected, selectAll, handleSelectAll, value, handlePastDaysChange, showChildren, handleShowChildren, hasChildSegments}) => {

  const columns = [
    { display: <SelectAll selectAll={selectAll} handleSelectAll={handleSelectAll}/>, key: 'check', as: CheckComponent(selected, onCheck) },
    { display: 'Conversion', key: 'action_name', as: LinkTo },
    { display: 'Count', key: 'conversions', as: NumberComponent("total_conversions")},
    { display: <Weekly />, key: 'total_wow', as: PercentComponent("total_wow") },
    { display: 'Revenue', key: 'revenue', as: RevenueCell("total_revenue")},
    { display: <Weekly />, key: 'revenue_wow', as: RevenueChange("revenue_wow")},
    { display: 'Modeled', key: 'use_model', as: Modeled },
    { display: 'Daily Activity', key: 'daily', as: (props) => <Daily {...props} value={value}/>},
  ];

  const dateOptions = [
    {value: 30, text: 'Past 30 days'},
    {value: 60, text: 'Past 60 days'},
    {value: 90, text: 'Past 90 days'}
  ]

  return (
    <ContentCard hasTable title="Summary"
      topRight={
        <React.Fragment>
          {hasChildSegments &&
            <Checkbox label="Show Children" checked={showChildren} onChange={handleShowChildren} style={{marginRight: 15}}/>
          }
          <Dropdown
            onChange={handlePastDaysChange}
            options={dateOptions}
            value={value}
            style={{border: 'none'}}
          />
        </React.Fragment>
      }>
      {segments.length ?
        <IndexGrid as={ContentCard.Table} static data={segments} cols={columns} />
      :
      <ContentCard.CardLoader />
      }
    </ContentCard>
  )

}

export default SummaryTable;
