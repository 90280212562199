// index.js
export const arrayToColumns = (arr) => {
  return arr.map(key => { return { key, display: key} })
}

// IndexGrid.js
export const columnsToOptions = (arr) => arr.map(({ key }) => new Object({key, value: key, text: key}))

export const pagerLength = (rows, itemsPerPage) => {
  const numberOfPagers = Math.ceil(rows.length / itemsPerPage)

  return numberOfPagers
};

export const isFalsy = (obj) => {
  return !obj || Object.getOwnPropertyNames(obj).length == 0;
}
