import React, { useState } from "react"
import { CacheContext } from '../../utils/CacheContext'
import { getArtifacts, getTierColors } from "../api/attribution"

const useAccountSettings = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { account } = state
  const { tier } = account || {}
  const isLite = tier === 'lite'
  const isStarter = tier === 'starter'
  const defaultView = isLite ? 'platform' : 'comparison'
  const defaultModel = isStarter ? 'assisted' : 'normalized'

  return {
    tier,
    account,
    isLite,
    defaultView,
    defaultModel
  }
}

const useAppSettings = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { tier, account, isLite, defaultView, defaultModel } = useAccountSettings()
  const artifacts = Context.getCache('artifacts', getArtifacts) || {}
  const tierColors = Context.getCache('tierColors', getTierColors) || {};
  const gSheetsPluginLink = JSON.parse(artifacts?.google_sheets_plugin || '{}')?.link
  
  return {
    artifacts,
    tierColors,
    gSheetsPluginLink,
    tier, account, isLite, defaultView, defaultModel
  }
}

export default useAppSettings
