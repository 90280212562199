import moment from 'moment'
import { openDB } from "idb"
import { handleCacheResponse } from './helpers';

export function getDailySpendTiersData(id, date) {
  const url = `/data/v3/attribution_events?date=${date}&identifier=${id}&keys=spend_tiers`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(handleCacheResponse)
    .then(data => data.map(d => { return { ...d, date } }))
}

export function getSpendTiersData(id, startDate, endDate) {
  const dateRange = Array.from(moment.range(startDate, endDate).by('day')).map(x => x.format('YYYY-MM-DD'));
  const requests = dateRange.map(date => getDailySpendTiersData(id, date));
  return Promise.all(requests)
    .then(r => r.flat());
}

const getDB = () => openDB("api_cache", 3, {upgrade(upgradeDb) {
  if (!upgradeDb.objectStoreNames.contains('url_cache_id')) upgradeDb.createObjectStore('url_cache_id', {keyPath: "url"});
  if (!upgradeDb.objectStoreNames.contains('cache_id_data')) upgradeDb.createObjectStore('cache_id_data', {keyPath: "cache_id"});
}})

export function getConversionSegmentReportingData(id, startDate, endDate) {
  const statsUrl = `/data/v3/stats/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=compiled_mta_tiers`;

  const promise = (url) => fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then((raw, request) => {
      const { details: { keys, cache_id } = {}, response } = raw;

      if (keys.length === 1) {
        const key = keys[0];
        const _cache_id = Object.values(cache_id[key])[0]
        const data = response[key];

        Object.entries(cache_id[key]).map(([date, id]) => {
          getDB().then(function(db) {
            const tx = db.transaction(['url_cache_id', 'cache_id_data'], 'readwrite');
            const url_cache = tx.objectStore('url_cache_id');
            const cache_data = tx.objectStore('cache_id_data');
            url_cache.put({ url, cache_id: _cache_id })
            cache_data.put({ cache_id: _cache_id, data })
            return tx.complete;
          })
        })

        return data;
      }
      return raw["response"];
    })

  return getDB()
    .then(db => {
      return new Promise((resolve, reject) => {


        fetch(statsUrl, { credentials: "include"})
          .then(response => response.json())
          .then(({ response: { current }}) => current.reduce((p, { date, id }) => Object.assign(p, {[date]: id}), {}) )
          .then(dateObj => {
            //console.log(new Date())
            return Object.entries(dateObj).map(([k,v]) => {
              const url = `/cache/v3/attribution_events?start_date=${k}&end_date=${k}&identifier=${id}&keys=compiled_mta_tiers`

              return new Promise((resolve, reject) => {

                getDB()
                  .then(function(db) {
                    const tx = db.transaction(['url_cache_id','cache_id_data'], 'readwrite');
                    const url_cache = tx.objectStore('url_cache_id');
                    const cache_data = tx.objectStore('cache_id_data');
                    url_cache.get(url)
                      .then(response => {
                        const { cache_id = false } = response || {}

                        if (cache_id == v) {
                          cache_data.get(cache_id)
                            .then(({ data }) => resolve(data))
                          return true
                        }
                        if (cache_id && cache_id != v) {
                          cache_data.delete(cache_id)
                          url_cache.delete(url)
                        }
                        promise(url)
                          .then(resolve)
                      })
                  })
              })
            })
          })
          .then(promises => {
            //console.log("created all promises", new Date())
            Promise.all(promises)
              .then(dates => {
                //console.log("resolved all indexdb/gets", new Date())
                resolve(Object.values(dates))
              })
          })
      })
  })
}

export function getOnsiteDataset(filterId, dataset, startDate, endDate) {
  const url = `/data/v3/onsite_events?identifier=${filterId}&keys=${dataset}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
  .then(response => response.json())
  .then(({ response }) => response)
}
