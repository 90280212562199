import React, { Component } from 'react';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { get, currencyFormat, currency, percent, number, defaultNumber } from './helpers';

const Spend = ({tag, values, months, accessor, formatter}) => {
  const format = formatter || defaultNumber;

  const cumulativeRepeatConverters = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["converters"] + (values['repeat Existing'][date]||{})["converters"]
    p.push(cum)
    return p
  },[((values['Existing']||{})["cohort"]||{})["converters"] + (get(values,'NTF',{})["cohort"]||{})["converters"]])

  const cumulativeRepeatNTFConverters = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["converters"] 
    p.push(cum)
    return p
  },[(get(values,'NTF',{})["cohort"]||{})["converters"]])


  const cumulativeRepeatConversions = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["conversions"] + (values['repeat Existing'][date]||{})["conversions"]
    p.push(cum)
    return p
  },[((values['Existing']||{})["cohort"]||{})["conversions"] + (get(values,'NTF',{})["cohort"]||{})["conversions"]])

  const cumulativeRepeatNTFConversions = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["conversions"] 
    p.push(cum)
    return p
  },[(get(values,'NTF',{})["cohort"]||{})["conversions"]])


  const cumulativeRepeatRevenue = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["revenue"] + (values['repeat Existing'][date]||{})["revenue"]
    p.push(cum)
    return p
  },[((values['Existing']||{})["cohort"]||{})["revenue"] + (get(values,'NTF',{})["cohort"]||{})["revenue"]]).slice(1)

  const cumulativeRepeatNTFRevenue = months.reduce((p,date) =>  {
    const last = p.length > 0 ? p.slice(-1)[0] : 0
    const cum = last + (values['repeat NTF'][date]||{})["revenue"] 
    p.push(cum)
    return p
  },[(get(values,'NTF',{})["cohort"]||{})["revenue"]]).slice(1)

  const x = (get(values,'NTF',{})["cohort"]||{})[accessor]
  //console.log(x, tag)

  if (x == undefined) return null

  return (
    <React.Fragment>
      <Row hover>
        <Column padded="5">Total Spend</Column>
        <Column>{ format((get(values,'NTF',{})["cohort"]||{})[accessor]) }</Column>
        { months.map(date => (<Column></Column>) ) }
        <Column bold>{ format((get(values,'NTF',{})["cohort"]||{})[accessor]) }</Column>
      </Row>

      <Row>
        <Column padded="5">All Purchases</Column>
      </Row>
      <Row hover>
        <Column padded="20">ROAS</Column>
        <Column>{ number(((get(values,'NTF',{})["cohort"]||{})["revenue"]+ (get(values,'Existing',{})["cohort"]||{})["revenue"])/((get(values,'NTF',{})["cohort"]||{})[accessor] )) }</Column>
        { 
          cumulativeRepeatRevenue.map(value => (
            <Column>
              { number(value/(get(values,'NTF',{})["cohort"]||{})[accessor]) }
            </Column>
          )) 
        }
        <Column></Column>
      </Row>
      <Row hover>
        <Column padded="20">Cost per Purchase</Column>
        { 
          cumulativeRepeatConversions.map((value,i) => (
            <Column> { format((get(values,'NTF',{})["cohort"]||{})[accessor]/value) } </Column>
          )) 
        }
        <Column bold></Column>
      </Row>
      <Row hover light>
        <Column padded="20">% Incremental (All Purchases)</Column>
        { 
          cumulativeRepeatConversions.map((value,i) => (
            <Column> { 
              percent(
                ((get(values,'NTF',{})["cohort"]||{})[accessor]/cumulativeRepeatConversions[0] - (get(values,'NTF',{})["cohort"]||{})[accessor]/value) / ((get(values,'NTF',{})["cohort"]||{})[accessor]/cumulativeRepeatConversions[0])
              ) } </Column>
          )) 
        }
        <Column bold></Column>
      </Row>

      <Row>
        <Column padded="5">New Customer Purchases</Column>
      </Row>
      <Row hover>
        <Column padded="20">NTF ROAS</Column>
        <Column>{ number((get(values,'NTF',{})["cohort"]||{})["revenue"]/(get(values,'NTF',{})["cohort"]||{})[accessor]) }</Column>
        { 
          cumulativeRepeatNTFRevenue.map(value => (
            <Column>
              { number(value/(get(values,'NTF',{})["cohort"]||{})[accessor]) }
            </Column>
          )) 
        }
        <Column></Column>
      </Row>
      <Row hover>
        <Column padded="20">NTF Cost per Purchase</Column>
        { 
          cumulativeRepeatNTFConversions.map((value,i) => (
            <Column> { format((get(values,'NTF',{})["cohort"]||{})[accessor]/value) } </Column>
          )) 
        }
        <Column bold></Column>
      </Row>
      <Row hover light>
        <Column padded="20">% Incremental (NTF Purchases)</Column>
        { 
          cumulativeRepeatNTFConversions.map((value,i) => (
            <Column> { 
              percent(
                ((get(values,'NTF',{})["cohort"]||{})[accessor]/cumulativeRepeatNTFConversions[0] - (get(values,'NTF',{})["cohort"]||{})[accessor]/value) / ((get(values,'NTF',{})["cohort"]||{})[accessor]/cumulativeRepeatNTFConversions[0])
              ) } </Column>
          )) 
        }
        <Column bold></Column>
      </Row>
    </React.Fragment>
  )
}

export default Spend;
