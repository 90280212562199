import React, { useMemo, useEffect, useState, useContext } from 'react';
import { SplitLayout } from '@rockerbox/styleguide';
import { Tab, Table, Dropdown } from 'semantic-ui-react';

import { ComposedChart, AreaChart, LineChart } from 'recharts';
import { ReferenceLine, Area, Line, Scatter } from 'recharts';
import { Label, Tooltip, Legend, YAxis, XAxis } from 'recharts';

const Channel = ({ data, response, selectedData, selectedFeature, setSelectedFeature }) => {

  const averageROI = !selectedData ? [] : 
    (selectedData.raw.roi_mean
      .find(row => row.rn == selectedFeature) || {})?.roi_mean

  return <>
      <div>
        <h4 style={{display: "inline"}}> Choose a Channel: </h4>
        { selectedData && <Dropdown 
            selection
            options={selectedData.features} 
            onChange={(evt, { value }) => setSelectedFeature(value)}
          />
        }
      </div>
      <h4><b>Independent data</b></h4>
      { data.length > 0 && <>
          <h5>Historical Spend</h5>
          <AreaChart data={data} width={600} height={150} >
            <YAxis />
            <YAxis orientation="right" hidden />
            <XAxis dataKey="DATE" hide />
            <Tooltip />
            <Area dataKey="media_value" stroke="blue" fill="blue" dot={<></>}/>
          </AreaChart> 
        </>
      }
      <h4><b>Predicted Results</b></h4>
      <SplitLayout
        rightWidth={8}
        leftWidth={8}
        leftContent={<>
          <h4></h4>
          { data.length > 0 && <>
              <h5>Predicted Revenue</h5>
              <ComposedChart data={data} width={600} height={200} >
                <YAxis yAxisId="left" type="number" dataKey="decomp_value" name="spend" unit="$" stroke="green" />
                <XAxis dataKey="DATE" />
                <Tooltip />
                <Area name="Pred. Revenue" yAxisId="left" dataKey="decomp_value" stroke="green" fill="green" dot={<></>}/>
              </ComposedChart> 
            </>
          }
          { data.length > 0 && <>
              <h5>ROAS</h5>
              <ComposedChart data={data} width={600} height={200} >
                <YAxis  type="number" dataKey="roi" name="roi" unit="x" stroke="blue" />
                <XAxis dataKey="DATE" />
                <Tooltip />
                <Line name="Return on ad Spend" dataKey="roi" stroke="blue" dot={<></>} />
              </ComposedChart> 
            </>
          }
        </>}
        rightContent={<>
          <h4></h4>
          { response.length > 0 && <>
              <h5>Predicted Revenue by Spend Level</h5>
              <ComposedChart data={response} width={600} height={200} paddingBottom={20} >
                <YAxis />
                <XAxis dataKey="spend_input" domain={[0, 'dataMax']} type="number">
                  <Label offset={-10} position="bottom" >Spend</Label>
                </XAxis>
                <Tooltip />
                <Line dataKey="marginal_response" stroke="#000" />}/>
                {/* <Scatter dataKey="decomp_value" dot={<></>}/> */}
              </ComposedChart> 
            </>
          }

          { response.length > 0 && <>
              <h5>Predicted ROAS by Spend Level</h5>
              <ComposedChart data={response} width={600} height={200} paddingBottom={20} >
                <YAxis />
                <XAxis dataKey="spend_input" domain={[0, 'dataMax']} type="number">
                  <Label offset={-10} position="bottom" >Spend</Label>
                </XAxis>
                <Tooltip />
                <Area dataKey="predicted_roas_efficient" fill="green" stroke="#000" />}/>
                <ReferenceLine y={averageROI} label="Average ROI" stroke="red" strokeDasharray="3 3" />

              </ComposedChart> 
            </>
          }
        </>}
      />
  </>
}

export default Channel;
