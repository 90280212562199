import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatCurrency, secondsToDhms } from '../../utils/valueFormatter'
import { useCurrency } from '../../hooks/currency'

import { percentFormatter } from '../ConversionsOverview/helpers'

const Subheading = styled.h3`
  font-weight: lighter !important;
  margin-top: 0px;
`

const HeadingCol = styled(Grid.Column)`
  text-transform: uppercase;
`

const RowLabelCell = styled(HeadingCol)`
  min-width: 140px;
`

const ColorCol = styled(Grid.Column)`
  text-transform: uppercase;
  color: #475ddc;
  text-align: right;
`

const Row = styled(Grid.Row)`
  padding: 0px !important;
`

const ConversionTimeDelta = ({ value, display }) => {
  return (
    <div>
      {
        value == 0 ? '—' : (
          <div style={{backgroundColor: value > 0 ? '#00ad39' : value < 0 ? '#e00000' : 'transparent', borderRadius: 4, padding: '3px 5px', display: 'inline-block'}}>
            <span style={{color: `${value > 0 || value < 0 ? '#ffffff' : '#475ddc'}`}}>
              {value < 0 ? '+' : '-'}
              {secondsToDhms(Math.abs(value))}
            </span>
        </div>
        )
      }
    </div>
  )
}

const RevenueDelta = ({value, overallAvgRev, filteredAvgRev, currencyCode}) => {
  // went to 2 decimal places because numbers can be slightly off in double digital decimal places
  const even = parseFloat(overallAvgRev).toFixed(2) == parseFloat(filteredAvgRev).toFixed(2)
  const greaterOverall = overallAvgRev > filteredAvgRev
  const absValue = Math.abs(value)

  return (
    <div>
      {even && '—'}
      {!even && <span style={{backgroundColor: even ? 'transparent' : greaterOverall ? '#e00000' : '#00ad39', borderRadius: 4, padding: '3px 5px', color: `${(!even) ? '#ffffff' : '#475ddc'}`}}>
        {even ? '' : greaterOverall ? '-' : '+'}
        {formatCurrency(absValue, currencyCode)}
      </span>}
    </div>
  )
}

const AvgTouchpointsSymbol = ({overallAvgTouchpoint, filteredAvgTouchpoint}) => {
  const even = parseFloat(overallAvgTouchpoint).toFixed(2) == parseFloat(filteredAvgTouchpoint).toFixed(2)
  const greaterOverall = overallAvgTouchpoint > filteredAvgTouchpoint
  return even ? '' : greaterOverall ? '-' : '+'
}

export const SummaryStatsCard = ({ overallSummary, filteredSummary, percentOfTotal }) => {
  const { currencyCode } = useCurrency()
  const overallAvgRev = formatCurrency(overallSummary.averageRevenue, currencyCode)
  const filteredAvgRev = formatCurrency(filteredSummary.averageRevenue, currencyCode)
  return(
    <Segment style={{padding: 30}}>
      <h1 style={{fontWeight: 'lighter'}}>Summary Stats</h1>
      <Subheading>Use the settings above to compare between total amounts and filtered subsets of your data.</Subheading>
      <Grid columns="equal" divided="vertically" style={{marginTop: '1em', padding: '1em 1em 1em 0'}}>
        <Row>
          <HeadingCol width={5}> </HeadingCol>
          <HeadingCol style={{textAlign: 'right'}}> All </HeadingCol>
          <HeadingCol style={{textAlign: 'right'}}> Subset </HeadingCol>
          <HeadingCol style={{textAlign: 'right'}}> %/𝚫 </HeadingCol>
        </Row>
        <Row>
          <RowLabelCell width={5}>Conversions</RowLabelCell>
          <ColorCol>
            {overallSummary.conversions}
          </ColorCol>
          <ColorCol>
            {filteredSummary.conversions == overallSummary.conversions ? '—' : filteredSummary.conversions}
          </ColorCol>
          <ColorCol>
            {percentOfTotal.conversions == 100 ? '—' : percentFormatter(percentOfTotal.conversions/100)}
          </ColorCol>
        </Row>
        <Row>
          <RowLabelCell width={5}>Avg Touchpoints</RowLabelCell>
          <ColorCol>
            {parseFloat(overallSummary.averageLength).toFixed(2)}
          </ColorCol>
          <ColorCol>
            {parseFloat(overallSummary.averageLength).toFixed(2) == parseFloat(filteredSummary.averageLength).toFixed(2) ? '—' : parseFloat(filteredSummary.averageLength).toFixed(2)}
          </ColorCol>
          <ColorCol>
            <AvgTouchpointsSymbol overallAvgTouchpoint={overallSummary.averageLength} filteredAvgTouchpoint={filteredSummary.averageLength}/>
            {percentOfTotal.touchpoints == 0 ? '—' :parseFloat(Math.abs(percentOfTotal.touchpoints)).toFixed(2)}
          </ColorCol>
        </Row>
        <Row>
          <RowLabelCell width={5}>Avg Revenue</RowLabelCell>
          <ColorCol>
            {overallAvgRev}
          </ColorCol>
          <ColorCol>
            {overallAvgRev == filteredAvgRev ? '—' : filteredAvgRev}
          </ColorCol>
          <ColorCol>
            <RevenueDelta
              value={percentOfTotal.revenue}
              overallAvgRev={overallSummary.averageRevenue}
              filteredAvgRev={filteredSummary.averageRevenue}
              {...{ currencyCode }}
            />
          </ColorCol>
        </Row>
        <Row>
          <RowLabelCell width={5}>Avg Conv Time</RowLabelCell>
          <ColorCol>
            {secondsToDhms(overallSummary.averageTime)}
          </ColorCol>
          <ColorCol>
            {secondsToDhms(overallSummary.averageTime) == secondsToDhms(filteredSummary.averageTime) 
              ? '—' 
              : secondsToDhms(filteredSummary.averageTime)
            }
          </ColorCol>
          <ColorCol>
            <ConversionTimeDelta value={percentOfTotal.time}/>
          </ColorCol>
        </Row>
      </Grid>
    </Segment>
  )
}
