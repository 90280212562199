import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';

const CopyClipboardInlineText = ({ value, width }) => {
  const [clicked, setClicked] = useState(false);
  const handleOnClick = () => {
    setClicked(true);
    navigator.clipboard.writeText(value)
    setTimeout(() => setClicked(false), 1500);
  }

  return (
    <Input
      action={{
        color: 'purple',
        icon: clicked ? 'check circle' : 'copy',
        onClick: handleOnClick,
      }}
      actionPosition='left'
      value={value}
      style={{
        width: width,
      }}
      readonly
    />
  )
}

export default CopyClipboardInlineText;
