import React, { Component } from "react";
import { IndexGrid, ContentCard } from "../../../";
import { Checkbox, Table } from "semantic-ui-react";

class ReportSchemaTable extends Component {
  render() {
    const {
      schema,
      reportKey,
      selectedColumns,
      updateSelectedColumns,
      defaultSchema,
      selectAllOrEmpty
    } = this.props;

    const ColumnCheckbox = ({ item, col }) => (
      <Table.Cell collapsing>
        <span>
          <Checkbox
            checked={
              selectedColumns && selectedColumns.includes(item.manage.name)
            }
            onChange={() => updateSelectedColumns(item.manage.name)}
          />
        </span>
      </Table.Cell>
    );
    const ColumnSelectAll = () => (
      <Checkbox
        checked={
          selectedColumns &&
          selectedColumns.length === Object.keys(defaultSchema).length
        }
        onChange={() => selectAllOrEmpty()}
      />
    );
    const tableCols = defaultSchema
      ? [
          { display: <ColumnSelectAll />, key: "manage", as: ColumnCheckbox },
          { display: "Column Number", key: "column_number" },
          { display: "Column Name", key: "name" },
          { display: "Data Type", key: "type" },
          { display: "Data Format", key: "format" },
          { display: "Description", key: "description" }
        ]
      : [
          { display: "Column Number", key: "column_number" },
          { display: "Column Name", key: "name" },
          { display: "Data Type", key: "type" },
          { display: "Data Format", key: "format" },
          { display: "Description", key: "description" }
        ];

    const reportName = reportKey || Object.keys(schema.definitions)[0];

    const reportSchema = schema["definitions"][reportName]["properties"];

    const reportCols = Object.keys(reportSchema);

    const buildFormat = col_schema => {
      const format = col_schema["format"];
      const pattern = col_schema["pattern"];
      if (!format) return "";
      if (!pattern) return format;
      return format + " (" + pattern + ")";
    };

    const transformed = reportCols
      .map(col =>
        Object({
          name: col,
          column_number: reportSchema[col]["column_number"],
          type: reportSchema[col]["type"],
          format: buildFormat(reportSchema[col]),
          description: reportSchema[col]["description"],
          manage: {
            checked: selectedColumns && selectedColumns.includes(col),
            name: col
          }
        })
      )
      .sort((a, b) => (a.column_number > b.column_number ? 0 : -1));

    return (
      <div>
        {schema && (
          <IndexGrid
            as={ContentCard.Table}
            fallBackMsg="No reports currently saved"
            cols={tableCols}
            data={transformed}
          />
        )}
      </div>
    );
  }
}

export default ReportSchemaTable;
