import React, { useMemo, useState } from 'react'
import { Card, Table, Icon, Popup, Button, Image, Header } from 'semantic-ui-react'
import { TrashIcon } from '@heroicons/react/outline'
import { PuzzleIcon } from '@heroicons/react/solid'
import moment from 'moment'

import PlatformCard from './PlatformCard'


export const PanelsGroup = ({ data, platformsDist, integrations, colorMap }) => {
  const platforms = useMemo(() => {
    if (!integrations) return
    const platformKeys = integrations.map(x => x.platform_key)
    const uniquePlatformKeys = [...new Set(platformKeys)]
    return  uniquePlatformKeys
  }, [integrations, data])

  return (
    <Card.Group itemsPerRow={4} style={{padding: '1em 0 2em'}}>
      {platforms.map(platform => (
        <PlatformCard {...{ key: platform, platform, data, platformsDist, integrations, colorMap }} />  
      ))}
    </Card.Group>
  )
}

export const StatusCell = (handleDelete) => ({item, col}) => {
  const [open, setOpen] = useState(false)
  const last_sync = item[col.key]
  const { auth_status } = item

  // hack for the status cell for batch integrations
  if (auth_status === null) {
    return (
      <Table.Cell>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: '.5em'}}>
              <Icon name="check circle" color="green" />
            </div>
            <span>
              Using Batch Files
            </span>
          </div>
        </div>
      </Table.Cell>
    )
  }

  return (
    <Table.Cell>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: '.5em'}}>
            { !auth_status
              ? <Icon name="warning sign" color="orange" />
              : <Icon name="check circle" color="green" />
            }
          </div>
          <span>
            { !auth_status
              ? <span>
                  Unable to Connect
                  <Button 
                    primary 
                    content="Reconnect" 
                    size="mini" 
                    onClick={() => window.location = `/auth/${item.platform_key}?account_id=${item.account_id}`}
                    style={{padding: '5px 5px 4px 5px', marginLeft: '1em', position: 'relative', top: -1}}
                  />
                </span>
              : !!last_sync
                ? `Last Synced ${moment.utc(last_sync).fromNow()}`
                : `Connected`
            }
          </span>
        </div>

        <Popup
          on='click'
          size='small'
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          position='top right'
          trigger={
            <TrashIcon
              style={{
                height: 16,
                marginRight: 0,
                color: '#E76D8E',
                cursor: 'pointer',
              }}
              className='hover-opacity-75'
            />
          }
          content={<>
            <Header
              as='h4'
              content={`Are you sure you want to delete this connection to ${item.platform} account ${item.account_name}?`}
              subheader='This action cannot be undone and may disrupt scheduled reporting or other data syncs.'
            />
            <Button.Group fluid>
            <Button
              content="Nevermind"
              onClick={() => setOpen(false)}
            />
            <Button.Or />
            <Button
              negative
              content="Confirm Delete"
              onClick={() => handleDelete(item.platform_key, item.id)}
            />
            </Button.Group>
          </>}
        />
        
      </div>
    </Table.Cell>
  )
}

export const IconCell = (integrations) => ({item, col}) => {
  const integration = integrations.find(c => c.platform == item.platform);
  
  return (
    <Table.Cell className='account-cell'>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {!!integration?.icon_path
          ? <Image src={integration.icon_path} style={{height: 20, marginRight: '1em'}} />
          : <PuzzleIcon style={{height: 20, marginRight: '1em', marginLeft: 0, color: '#bbb'}} />
        }
        <div className='account-name'>
          {item[col.key]}
        </div>
      </div>
    </Table.Cell>
  )
}

export const CurrencyCell = ({item, col}) => {
  const amount = item[col.key]
  let dollars = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: col.currency || "USD",
  });

  return (
    <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
      { amount ? dollars.format(amount) : ''}
    </Table.Cell>
  )
}
