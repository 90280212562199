import React, {Component} from 'react';
import { Table, Menu } from 'semantic-ui-react';
import { getOnsitePixel } from '../../utils/api';
import { IndexGrid } from '@rockerbox/styleguide';
import moment from 'moment';

const exclusionLists = {
  address: ['first_name', 'last_name', 'id', 'phone', 'company'],
  discount: ['reason']
}

class ConverterDetails extends Component {
  state = {
    active: 'marketing'
  }

  toggleActive = (state) => {
    this.setState({active: state})
  }

  render() {
    const { item } = this.props;
    const { active } = this.state;

    return (
      <React.Fragment>
        <Menu
          fluid
          widths={2}
          attached="top"
          secondary
          pointing
        >
          <Menu.Item
            name="Marketing Path to Conversion"
            active={active === 'marketing'}
            onClick={() => this.toggleActive('marketing')}
          />
          <Menu.Item
            name="Conversion Details"
            active={active === 'customerDetail'}
            onClick={() => this.toggleActive('customerDetail')}
          />
        </Menu>
        {active === 'marketing' && <MarketingPathTable item={item} />}
        {active === 'customerDetail' && <CustomerDetailTable item={item} />}
      </React.Fragment>
    )
  }
}

class MarketingPathTable extends Component {

  state = {
    marketingTableData: []
  }

  componentDidMount() {
    const {events} = this.props.item;
    const updatedEvents = events.map(c => {
      let copy = Object.assign({},c);
      delete copy['even'];
      return copy;
    });

    this.setState({marketingTableData: updatedEvents});
  }

  render() {
    const { marketingTableData } = this.state;
    let cols = [
      {display: 'Timestamp', key: 'timestamp', as: TimestampCell},
      {display: 'Tier 1', key: 'tier_1'},
      {display: 'Tier 2', key: 'tier_2'},
      {display: 'Tier 3', key: 'tier_3'},
      {display: 'Tier 4', key: 'tier_4'},
      {display: 'Tier 5', key: 'tier_5'},
      {display: 'Modeled', key: 'modeled', as: Percentage}
    ];

    return(
      <IndexGrid attached data={marketingTableData} cols={cols} />
    )
  }
}

const TimestampCell = ({item, col}) => {
  const time = moment(item[col.key] * 1000).format('MMM D, YYYY - h:mm a');
  return (
    <Table.Cell>{time}</Table.Cell>
  )
};

const Percentage = ({item, col}) => {
  const percent = (new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })).format(item[col.key])

  return (
    <Table.Cell>
      {item[col.key] ? percent : '-'}
    </Table.Cell>
  )
}

const parseData = (dataObj) => {
  try {
    return JSON.parse(dataObj);
  }
  catch (e) {
    const stringified = `"${dataObj}"`;
    const firstParse = JSON.parse(stringified);
    return JSON.parse(firstParse);
  }
}

const InnerTable = ({dataObj, exclusionsList}) => {
  const data = parseData(dataObj);

  const keys = Object.keys(data)
    .filter(c => !exclusionsList.includes(c));

  if(keys.length > 0) {
    return (
      <Table celled compact="very" style={{border: 'none'}}>
        <Table.Body>
          {
            keys.map(key => {
              if(typeof data[key] === 'object') {
                return null;
              } else {
                return (
                  <Table.Row>
                    <Table.Cell className="ignored">{key}</Table.Cell>
                    <Table.Cell>{data[key]}</Table.Cell>
                  </Table.Row>
                )
              }
            })
          }
        </Table.Body>
      </Table>
    )
  } else {
    return null;
  }

}

const hasObjectFormat = (item, key) => {
  return typeof item[key] === 'string' && item[key].charAt(0) === '{'
}
const checkExclusionsList = (exclusionLists, key) => {
  return exclusionLists.hasOwnProperty(key) ? exclusionLists[key] : []
}

const CustomerDetailTable = ({item}) => {

  const exclusions = [
    'email', 'conversion_hash_id', 'customer_id', 'timestamp_conv', 'revenue_normalized',
    'path_deduplicated', 'action', 'cache_from', 'count', 'hashed_path',
    'tier_one', 'tier_two', 'tier_three', 'tier_four', 'tier_five',
    'external_id', 'uid', 'url', 'is_valid', 'new_to_file', 'events', 'intra_device_uid',
    'rb_timestamp', 'messageId', 'serverside_pageReferrer', 'serverside_url', 'base_id',
    'base_key', '1', 'cache_id', 'cache_id_conv', 'cache_id_events', 'event_tier_combined',
    'is_first_touch_in_session_group',  'time_btwn_prior_touch', 'timestamp_10', 'timestamp_right',
    'spend_key', 'source', 'rb_source', 'session_id', 'session_group', 'raw_message_id', 'level_0',
    'uid_from', 'session_timestamp', 'dma_id', 'revenue_in_order_currency', 'script_version',
  ]

  const keys = Object.keys(item).filter(key => {
    return !exclusions.includes(key);
  }).sort();

  return (
    <Table celled compact attached definition>
      <Table.Body>
        {keys.map(key => {
          return (
            <Table.Row>
              <Table.Cell>
                {key}
              </Table.Cell>
                {
                  hasObjectFormat(item, key) ?
                    <Table.Cell style={{padding: 0}}>
                      <InnerTable dataObj={item[key]} exclusionsList={checkExclusionsList(exclusionLists, key)} />
                    </Table.Cell> :
                    <Table.Cell>{item[key]}</Table.Cell>
                }
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}


export default ConverterDetails;
