import React, { Component } from 'react';
import { IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Sticky, Step } from 'semantic-ui-react';
import { Checkbox, Table, Message, Form, Button, Accordion, Segment, Icon, Grid } from 'semantic-ui-react';
import SelectConversion from '../SelectSegment';
import { getDataset, getSurveyConfigs, postSurveyData, updateSurveyData } from '../../utils/api';
import _ from 'lodash';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as routes from '../../routes';
import { withRouter } from 'react-router-dom';
import { _pushPath } from '../pushpath';

import { 
  SetupMessage, ResponseMessage, DefaultResponseMessage, ExclusionResponseMessage, 
  RemappedResponseMessage, EventMessage, ViewEventsMessage 
} from './Messages';

import CurrentEvents  from './CurrentEvents';
import GeneralSetup  from './SetupGeneral';
import ResponseSetup from './SetupResponses';
import ConversionSetup from './SetupConversion';
import EventSetup from './SetupEvents';

const STEPS = [
  {value: "general", text: "General Setup", validationFields: ["displayName", "survey", "surveyResponseKey"]}, 
  {value: "responses", text: "Organize Responses", validationFields: ["responses","defaultResponse"]}, 
  {value: "events", text: "Marketing Events", validationIf: ["conversion"], validationFields: ["events"]},
//  {value: "conversion", text: "Associate Conversion", validationIf: ["conversion"], validationFields: ["conversion","conversionKey","surveyKey"]},
]

export const FormDropdown = (props) => <Form.Dropdown {...props} select label={props.label} selection/>

class CreateSurvey extends _pushPath(Component) {
  state = {
    activeStep: "general",
    surveyKey: '',
    conversionKey: '',
    questionKey: '',
    displayName: '',
    conversion: '',
    survey: '',
    surveyResponseKey: '',
    id: '',
    events: [],
    excluded: '',
    defaultResponse: 'Other',
    responses: [ { response_display: "", response_values: "", } ],
    fieldErrors: {disable: true},
    ruleValues: []
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if(id) {
      this.setState({ fieldErrors: {} })
      getSurveyConfigs()
      .then(surveyData => {
        const selected = surveyData.find(c => c.id == id);

        const ruleValues = selected.responses.reduce((p,c) => {
          return p.concat(c.response_values.split("|"))
        }, [])

        this.setState({
          displayName: selected.display_name,
          conversion: selected.conversion_id,
          conversionKey: selected.conversion_key,
          questionKey: selected.question_key,
          survey: selected.survey_id,
          surveyKey: selected.survey_key,
          surveyResponseKey: selected.survey_response_key,
          id: selected.id, events: selected.events,
          excluded: selected.no_response_values,
          defaultResponse: selected.default_response_value || "Other",
          responses: selected.responses,
          ruleValues});
      })
    }
  }

  setResponseValues = (responses) => {
    this.setState({ responses })
  }

  handleChange = (val, key) => {
    this.setState({[key]: val}, () => this.validateSteps(this.state.activeStep))
  }

  updateStateArrayRowField = (stateSlice, field, idKey = "id", newIdKey = "new_id") => (item) => (e, {value}) => {
    const stateCopy = _.cloneDeep(this.state);
    const selectedObj = item.new_id ?
        stateCopy[stateSlice].find(c => c[newIdKey] == item[newIdKey]) :
        stateCopy[stateSlice].find(c => c[idKey] == item[idKey]);
    selectedObj[field] = value;

    this.setState(stateCopy, () => this.validateSteps(this.state.activeStep));
  }

  handleSubmit = () => {
    const { displayName, surveyKey, questionKey, conversionKey, survey, conversion, edit, surveyResponseKey, events, excluded, defaultResponse, responses } = this.state;
    const { id } = this.props.match.params;

    const postObj = {
      display_name: displayName,
      survey_key: surveyKey,
      survey_id: survey,
      conversion_key: conversionKey,
      question_key: questionKey,
      conversion_id: conversion,
      survey_response_key: surveyResponseKey,
      events: events.length > 0 ? events.filter(c => c.response) : [],
      no_response_values: excluded,
      default_response_value: defaultResponse,
      responses: responses.length > 0 ? responses.filter(c => c.response_display && c.response_values) : []
    }

    const send = id ? updateSurveyData : postSurveyData;

    send(postObj, id)
      .then(data => {
        this.props.history.push('/v3/data/marketing/surveys/main')
      })
  }

  removeAdvanced = (key) => (pos) => {
    const stateCopy = _.cloneDeep(this.state);

    (key == "events") ?
      stateCopy.events.splice(pos, 1) :
      stateCopy.responses.splice(pos,1)

    this.setState(stateCopy);
  }

  addAdvanced = (key, value) => {
    const stateCopy = _.cloneDeep(this.state);

    if(key == "events") {
      const new_id = `new_${stateCopy.events.length}_${parseInt(Math.random(1,100)*10000000)}`;
      const eventsObj = {
        response: '',
        mta_tier_1: '',
        mta_tier_2: '',
        mta_tier_3: '',
        mta_tier_4: '',
        mta_tier_5: '',
        tier_1: '',
        tier_2: '',
        tier_3: '',
        tier_4: '',
        tier_5: '',
        new_id
      }

      stateCopy.events.push(eventsObj)
    } else {
      const new_id = `new_${stateCopy.responses.length}_${parseInt(Math.random(1,100)*10000000)}`;
      const responseObj = value ? 
        Object.assign(value, { new_id }) : 
        {
          response_display: "",
          response_values: "",
          new_id
        }

      stateCopy.responses = stateCopy.responses.filter(row => !(row.response_display == "" && row.response_values == ""))
      stateCopy.responses.push(responseObj)
      stateCopy.responses = stateCopy.responses
    }

    this.setState(stateCopy);
  }

  validateSteps = (step) => {
    const nextStepPosition = STEPS.map(({ value }) => value).indexOf(step) + 1;
    
    const fieldErrors = {}
    STEPS.slice(0, nextStepPosition).map(({ validationFields }) => {
      validationFields.map(field => {
        const value = this.state[field]
        if (value.length == 0) {
          fieldErrors[field] = "Value is missing"
        }
      })
    })

    this.setState({ fieldErrors })
  }

  setActiveStep = (activeStep) => {
    this.setState({ activeStep }, this.validateSteps(activeStep) )
  }
  setNextStep = () => {
    const nextStepPosition = STEPS.map(({ value }) => value).indexOf(this.state.activeStep) + 1;
    const activeStep = STEPS[nextStepPosition].value;
    this.setState({ activeStep }, this.validateSteps(activeStep) )
  }

  getActiveComponent = (activeStep) => {

    const { updateStateArrayRowField, handleChange, addAdvanced, removeAdvanced, setResponseValues } = this
    const methods = { updateStateArrayRowField, handleChange, addAdvanced, removeAdvanced, setResponseValues }


    if (activeStep == "general") return <GeneralSetup {...this.state} handleChange={this.handleChange} />
    if (activeStep == "responses") return <ResponseSetup {...this.state} {...methods} />
    if (activeStep == "conversion") return <ConversionSetup {...this.state} {...methods} />
    if (activeStep == "events") return <EventSetup {...this.state} {...methods} />
  }

  render() {

    const { activeStep } = this.state;
    const { setActiveStep } = this;
    const { getActiveComponent } = this;

    const activeComponent = getActiveComponent(activeStep)

    const showSubmit = (STEPS.map(({ value }) => value).indexOf(activeStep) + 1) == STEPS.length
    const isDisabled = Object.keys(this.state.fieldErrors).length > 0
    return(
      <Form onSubmit={this.handleSubmit}>
      <Step.Group widths={STEPS.length} size="mini">

        { STEPS.map(({ value, text, icon}) => {
            return <Step active={activeStep == value} onClick={() => setActiveStep(value)}>
              <Step.Content>
                <Step.Title>{ text }</Step.Title>
              </Step.Content>
            </Step>
          })
        }
      </Step.Group>
      { activeComponent }
      <br />
      <div style={{position: "fixed", bottom: "20px", right: "20px" }}>
        { showSubmit ? 
            <Button size='big' color='green' disabled={isDisabled} type="submit" content="Submit" icon='right check' labelPosition='right' /> : 
            <Button size='big' disabled={isDisabled} as='a' primary content="Next" onClick={this.setNextStep} icon='right arrow' labelPosition='right' /> 
        }
      </div>
      </Form>
    )
  }
}

export default withRouter(CreateSurvey);
