import React, { useEffect } from 'react';
import { FilterWrapper, DateRangeHooks, StyledDropdown, RollupFilter, DebouncedSearch, SaveReportButton, ResetFilterButton } from '../../components';
import { NTF_OPTIONS, methodOptions } from '../../constants/options';
import { segmentOptions } from '../../utils/options';

const Filter = ({
  loading, tiersData, // data
  segments, id, setId, // conversion segments
  startDate, endDate, onDateChange, firstReportingDate, // dates
  hasNtf, ntfFilter, setNtfFilter, // customer type
  attributionMethod, setAttributionMethod, hasModel, noData, // model
  filters, resetFilters, search, setSearch, // jotai
}) => {
  const segmentOptionsDropdown = segmentOptions(segments);

  useEffect(() => {
    if (tiersData.length && !hasNtf) {
      setNtfFilter('all');
    }
  }, [hasNtf]);

  return (
    <FilterWrapper
      {...{ loading }}
      other={(
        <>
          <StyledDropdown
            label="Conversion Type"
            options={segmentOptionsDropdown}
            value={parseInt(id)}
            onChange={(e, { value }) => setId(value)}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf || noData}
            options={NTF_OPTIONS}
            value={ntfFilter}
            onChange={(e, { value }) => setNtfFilter(value)}
          />
          <StyledDropdown
            label="Attribution Model"
            options={hasModel ? methodOptions : methodOptions.filter(o => o.value !== 'normalized')}
            value={attributionMethod}
            onChange={(e, { value }) => setAttributionMethod(value)}
            disabled={noData}
          />
          <div style={{ paddingRight: 12 }}>
            <DebouncedSearch
              onChange={(evt, { value }) => {
                setSearch(value.toLowerCase());
                resetFilters();
              }}
              value={search}
              disabled={filters.tier_1.length > 0}
              resetFilters={resetFilters}
            />
          </div>
        </>
      )}
      date={
        <DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />
      }
      stickyRight={(
        <>
          <SaveReportButton
            {...{ loading }}
            options={{
              'Attribution Model': attributionMethod,
            }}
          />
          <ResetFilterButton />
        </>
)}
      advancedFilters={(
        <>
          <RollupFilter rawData={tiersData} />
          {/* <AdvancedFilter rawData={tiersData} {...{ filters, filterSetters }} /> */}
        </>
)}
    />
  );
};

export default Filter;
