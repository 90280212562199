/*
  NOTE: needs id key equivalent to index# for proper positioning in UI
*/

export function createMappings(mappingsArr, name) {
  const newMappingFormat = mappingsArr.map((c, i) => {
    return { spendField: c.spend_field, tierBucket: c.tier_field, id: i }
  })

  const mappingsObj = {
    name: name,
    mappings: newMappingFormat
  }

  while (newMappingFormat.length < 5) {
    newMappingFormat.push(
      {spendField: '', tierBucket: '', id: newMappingFormat.length}
    )
  }

  return mappingsObj
}

export function createMarketingChannels(tierSelection) {
  const buckets = {
    bucket1: tierSelection.tier_1,
    bucket2: tierSelection.tier_2,
    bucket3: tierSelection.tier_3,
    bucket4: tierSelection.tier_4,
    bucket5: tierSelection.tier_5,
  }

  return buckets;
}

export function createDataSources(spendSelection,spendFieldOptions) {

  const spendFieldOptionsObj = spendFieldOptions.reduce((obj, c) => {
    obj[c.value] = c.text
    return obj
  }, {});

  const dataSourcesObj = {
    spendSource: spendSelection.platform
  };

  const tierKeys = Object.keys(spendSelection).filter(x => x.includes('tier'));

  const spendFiltersArr = tierKeys
    .map((c, i) => {
      return {field: c, value: spendSelection[c], text: spendFieldOptionsObj[c]}
    })
    .filter(x => x.value !== null)
    .map((c, i) => {
      c['id'] = i
      return c
    })

  dataSourcesObj.spendFilters = spendFiltersArr.length > 0 ? spendFiltersArr : [{ field: '', value: '', id: 0, text: '' }];

  return dataSourcesObj;
}
