import React from 'react'
import { PropTypes } from 'prop-types'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip } from 'recharts'
import { sortData } from '../hooks/sortData'
import { formatXAxisTickLabel, toolTipLabelFormatter } from '../helpers'
import { GroupByDropdown } from '../parts';
import ChartTooltip from '../ChartTooltip';

const AreaChartViz = ({
  data, groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  XAxisKey, YAxisStyle, width = '100%', height = 200, showXAxis, strokeWidth = 2, solidChart = false, 
  fillOpacity = 1,
  nameMap = {}, currencyOverride, tooltipComponent, formatter }) => {
  const colors = YAxisStyle.map( obj => obj.color || '#026CAC')
  const CTooltip = tooltipComponent || ChartTooltip;

  const { renderData } = sortData(data, groupDatesBy, defaultGroupBy)

  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width={width} height={height}>
        <AreaChart data={renderData}>
          {/* { !solidChart && <defs>
            {colors.map(color => (
              <linearGradient key={color+'gradient'} id={color+'gradient'} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                <stop offset="100%" stopColor={color} stopOpacity={0.1} />
              </linearGradient>
            ))}
          </defs>
          } */}
          <XAxis
            hide={!showXAxis}
            dataKey={XAxisKey}
            tickFormatter={(date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={12}
            padding={{left: 10, right: 10}}
          />
          <YAxis hide yAxisId='Left' />
          <YAxis hide yAxisId='Right' />
          { YAxisStyle.map( areaStyle => (
              <Area
              key = {`area_${areaStyle.keyName}`}
              yAxisId = {areaStyle.side || 'Left'}
              dataKey={areaStyle.keyName}
              type="monotone"
              stroke={areaStyle.color || '#026CAC'}
              fillOpacity={fillOpacity}
              fill={solidChart ? `${areaStyle.color}` : `url(#${areaStyle.color || '#026CAC'}gradient)`}
              strokeWidth={strokeWidth}
              />
          )) }
          <Tooltip
            cursor={{
              stroke: '#efefef',
              strokeWidth: 2,
              strokeDasharray: '5 5',
            }}
            labelFormatter={(v) => toolTipLabelFormatter(v, renderData)}
            content={<CTooltip {...{ nameMap, currencyOverride, formatter }} />}
            position={{ y: 0 }}
            wrapperStyle={{ zIndex: 3 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}

export default AreaChartViz

AreaChartViz.propTypes = {
  as: PropTypes.element,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  XAxisKey: PropTypes.string.isRequired,
  YAxisStyle: PropTypes.arrayOf(
    PropTypes.shape({ 
      keyName: PropTypes.string.isRequired, 
      color: PropTypes.string,
      side: PropTypes.string }) //objects
  ).isRequired
}
