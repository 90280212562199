import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, RowItem, StyledHeader } from './parts';
import { headers, headerOrder, comparisonHeaders, comparisonHeaderOrder } from './constants';
import { ContentCard } from '@rockerbox/styleguide';

export class DefaultHeader extends Component {


  render () {
    const { headers, headerOrder, sortBy, direction, onClick, hasSpend } = this.props
    const name = direction == "ascending" ? "sort-down": "sort-up"
    const D = (sortBy == "percent") ? null: <FontAwesome style={{marginLeft:"-13px",marginRight:"5px"}} name={name} />

    return (
      <Row style={{borderColor:"#ccc",backgroundColor:"#F9FAFB"}}>
        {
          headerOrder.map(key => (
            <RowItem
              style={{cursor:headers[key].sortable ? "pointer" : "inherit"}}
              onClick={() => headers[key].sortable ? onClick(key) : null}
              flex={headers[key].flex}
            >
              <StyledHeader size="big" {...headers[key]} >
                {key == sortBy ? (D) : null}
                {headers[key].big}
                {headers[key].explainer &&
                  <ContentCard.TableExplainer content={headers[key].explainer} />
                }
              </StyledHeader>
            </RowItem>
          ))
        }
      </Row>
    )
  }
}


export const ReportHeader = (props) => <DefaultHeader headers={headers} headerOrder={headerOrder} {...props} />
export const ComparisonHeader = (props) => {

  const modelColumns = props.models.map(x => x.value + "_total").concat(props.models.map(x => x.value + "_rev_total"))
  const order = comparisonHeaderOrder.filter(x => modelColumns.indexOf(x) > -1 || x == "group")

  return <DefaultHeader headers={comparisonHeaders} headerOrder={order} {...props} />

}
