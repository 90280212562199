import React, { Component } from 'react';
import shallow from 'zustand/shallow'
import { Grid, Header } from 'semantic-ui-react'
import { updateEntityData, deleteEntityData, getEntityConfigs, getEntityProgramConfigs } from '../../utils/api';
import { SplitLayout, IndexGrid, IndexGridNew, ContentCard } from '@rockerbox/styleguide';
import { Loader, Label, Icon, Message, Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreateViaGrid from './BulkCreateViaPromoCodes';
import CreateViaEvents from './BulkCreateViaEvents';
import BulkCreateCampaigns from './BulkCreateCampaigns';
import EntityProgram from './EntityProgram';
import * as routes from '../../routes';
import { entityStore } from './entityStore';
import { encode } from '../ViewAttribution/helpers';


const ManageCell = ({item,col}) => {
  return (<Table.Cell>
    <IndexGridNew.EditButton size="tiny" url={`/v3/data/marketing/promo_codes/edit/${item.id}`}/>
    <IndexGridNew.DeleteButton 
      size="tiny"
      onDelete={() => {
        deleteEntityData(item.id)
          .then(() => {
            window.location.reload();
          })
      }}
    />
  </Table.Cell>)
}

const RemoveCell = ({item,col}) => {
  return (<Table.Cell>
  </Table.Cell>)
}

const PromoCodeCell = ({item, col}) => {
  return (
    <Table.Cell>
      {item.promo_codes.length > 0 && item.promo_codes.map(c => {
        return c.name;
      }).join(", ")}
    </Table.Cell>
  )
}

const HasCell = ({item, col}) => {
  return (
    <Table.Cell> { item[col.key].length > 0 ? <Icon name="check" /> : "" } </Table.Cell>
  )
}

const DisplayCell = ({item, col}) => {

  const [loading, setLoading] = React.useState(false)
  const [ set ] = entityStore(({ set, }) => [ set ], shallow)

  const onClick = () => {

    const { program } = col
    const entity = _.cloneDeep(item)
    const { id } = entity
    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = program
    const display = { tier_1, tier_2, tier_3, tier_4, tier_5 }
    entity['display'] = [display]

    setLoading(true)
    updateEntityData(entity, id)
      .then(data => {
        set({ entityConfigs: data })
        setLoading(false)
      })
  }

  return (
    <Table.Cell> 
      { loading ? <div style={{width:"14px", height:"14px", display:"inline-block", position:"relative"}}><Loader active size='mini'/></div> : 
        item[col.key] ?  <Icon name="check" /> : 
          <Label {...{onClick}} style={{cursor:"pointer"}} size="tiny" > Apply Program Display Rule </Label> 
      } 
    </Table.Cell>
  )
}

const StringifyCell = ({item, col}) => {
  return (
    <Table.Cell> { JSON.stringify(item[col.key]) } </Table.Cell>
  )
}

const defaultString = (s) => s ? s : ""
const objectWithDefault = (obj) => Object.fromEntries(Object.entries(obj).map(([key,value]) => [key, defaultString(value)]))

class EntityIndex extends Component {
  state = {
    entityData: false,
    showCreateMultiple: false
  }

  componentDidMount() {
    // this.getData();
  }

  setShowCreateEvents = (showCreateEvents = true) => {
    this.setState({ showCreateEvents, showCreateMultiple: false, showCreateCampaign: false })
  }
  setShowCreateMultiple = (showCreateMultiple = true) => {
    this.setState({ showCreateMultiple, showCreateEvents: false, showCreateCampaign: false })
  }
  setShowCreateCampaign = (showCreateCampaign = true) => {
    this.setState({ showCreateCampaign, showCreateMultiple: false, showCreateEvents: false })
  }

  resetView = () => {
    this.setState({ showCreateMultiple: false, showCreateEvents: false, showCreateCampaign: false })
    this.props.getEntityConfigs(true)
  }

  render() {
    const { contentOnly, entityConfigs, entityData, program, hasLegacySpend, hasLegacyUTMs } = this.props;
    const { showCreateEvents, showCreateMultiple, showCreateCampaign } = this.state;

    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = program || {}
    const display = objectWithDefault({ tier_1, tier_2, tier_3, tier_4, tier_5 })
    const jdisplay = JSON.stringify(display)

    !!entityData && entityData.map(row => {
      if (row.display.length) {
        const { tier_1, tier_2, tier_3, tier_4, tier_5 } = row.display[0]
        const currentDisplay = objectWithDefault({ tier_1, tier_2, tier_3, tier_4, tier_5 })

        row.display_match = JSON.stringify(currentDisplay) == jdisplay
        row.display_current = JSON.stringify(currentDisplay)
        row.display_plan = jdisplay
      }
    })

    const cols = (!contentOnly ? [
      {display: 'Name', key: 'name', isFilterable: true, isSearchable: true},
      {display: 'Sponsorship Category', key: 'entity_type', isFilterable: true},
      {display: 'Promo codes', key: 'promo_codes', as: PromoCodeCell, isFilterable: true},
      !!hasLegacySpend && {display: 'Has Cost?', key: 'costs', as: HasCell, isFilterable: true},
      {display: 'Manage', as: ManageCell},
      {display: 'Remove', as: RemoveCell}
    ] : [
      {display: 'Name', key: 'name', isFilterable: true, isSearchable: true},
      {display: 'Has Tracking?', key: 'promo_codes', as: HasCell, isFilterable: true},
      !!hasLegacySpend && {display: 'Has Cost?', key: 'costs', as: HasCell, isFilterable: true},
      {display: 'Display Matches Program?', key: 'display_match', as: DisplayCell, program, isFilterable: true, onClick: this.updateEntity},
      {display: 'Manage', as: ManageCell},
      //{display: 'Remove', as: RemoveCell}
    ]).filter(Boolean)


    const { setShowCreateEvents, setShowCreateMultiple, setShowCreateCampaign, resetView } = this


    const tableContent = <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as='h3' content='Promo Codes' />
          </Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            <Button primary compact size="mini" icon="plus" content="Promo Codes" onClick={setShowCreateMultiple} />
            {!!hasLegacyUTMs && <>
              <Button primary compact size="mini" icon="plus" content="Click-Based Events (UTMs)" onClick={setShowCreateEvents} />
            </>}
            {!!hasLegacySpend && <>
              <Button primary compact size="mini" icon="plus" content="Campaign Costs" onClick={setShowCreateCampaign} />
              <Button primary compact size="mini" icon="plus" content="Manual Setup" as={Link} to={`/v3/data/marketing/promo_codes/create/${encode(program.entity_type)}`} />
            </>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ContentCard hasTable>
        { !showCreateMultiple && !showCreateCampaign && !showCreateEvents && 
            <IndexGridNew key={program.entity_type} sortable searchable paginate itemsPerPage={15} data={entityData} cols={cols}/> 
        }
      </ContentCard>
      { showCreateMultiple && <CreateViaGrid {...{entityConfigs, entityData, program}} onReset={() => resetView() }/> }
      { showCreateEvents && <CreateViaEvents {...{entityData, program}} onReset={() => resetView() }/> }
      { showCreateCampaign && <BulkCreateCampaigns {...{entityData}} onReset={() => setShowCreateCampaign(false) }/> }
    </>

    return contentOnly ? tableContent :
      <SplitLayout
        leftWidth={13}
        rightWidth={3}
        leftContent={tableContent}
        rightContent={
          <Message
            header="About Sponsorships"
            content={<>
  <p>Sponsorships are tracking parameters associated with specific marketing channels that fall outside of the standard marketing platforms.</p>
  <p>Sponsorships allow you to understand the importance of marketing in these hard to track channels and track the costs associated with running on these channels as well.</p>
            </>}
          />
        }
      />
  }
}

export default EntityIndex;
