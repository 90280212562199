/**
 * reportCacheDataRangeStatsToDateMap
 * The return value is an object where:
 * - keys are dates 
 * - values are arrays of identifier, id combinations associated the most "current" file
 * @param {array} current - array of objects with { date, id, identifier }
 */
const reportCacheDataRangeStatsToDateMap = (current) => {

  return current.reduce((p, { date, id, identifier }) => {
    p[date] = p[date] || []
    p[date].push({
      identifier,
      id
    })
    p[date].sort((a, b) => a.identifier > b.identifier ? 1 : -1)
    return p
  }, {})
}

/**
 * dateMapObjectToDateMapCacheStrings
 * The return value is an object where:
 * - keys are dates 
 * - values are "cacheStrings"
 * @param {object} dateReports - {"DATE": [{id, identifier}] }
 */
const dateMapObjectToDateMapCacheStrings = (dateReports) => {

  const dates = Object.keys(dateReports)
  dates.map(date => {
    dateReports[date] = detailsToCacheString(dateReports[date])
  })
  return dateReports
}


/**
 * detailsToCacheString
 * The return value is a string of "identifier:id,identifier:id
 * - keys are dates 
 * - values are "cacheStrings"
 * @param {array[object]} details - [{id, identifier}]
 */
export const detailsToCacheString = (details) => {
  return details.map(({ identifier, id }) => `${identifier}:${id}`).join(',')
}


/**
 * detailsToCacheString
 * The return value is an array of objects containing
 * - date
 * - identifier
 * - id
 * @param {array[object]} details - [{id, identifier}]
 */
export const getMetaData = (startDate, endDate, dataset) => {
  const statsBase = `/report_cache/stats/${dataset}`;
  const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}`;

  return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())
    .then(({ current }) => current)
    //.then(({ current }) => reportCacheDataRangeStatsToDateMap(current))
    //.then(dateMapObjectToDateMapCacheStrings)
}
