import {
  sponsorshipsFractionalIcon,
  sponsorshipsSiloedIcon,
  sponsorshipsCompareIcon,
  sponsorshipsCPAIcon,
  sponsorshipsTimeseriesIcon,
  sponsorshipsCostsIcon,
  sponsorshipsMissingSpendIcon,
  sponsorshipsMissingConversionsIcon,
} from '@rockerbox/styleguide'


const presetOptions = [
  // siloed to multi-touch
  {
     icon:sponsorshipsSiloedIcon,
     value:"performance_siloed",
     text:"How does each sponsor perform on a siloed basis?",
     description: "View performance by giving full credit to sponsorships, similar to what you would see in a vendor's non-multi-touch report",
     search: "",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum","assisted:sum", "cpaAssisted", "revenue:sum", "roasAssisted"]
     },
     category: "Moving from Siloed to Multi-Touch Attribution",
  },
  {
     icon:sponsorshipsCompareIcon,
     value:"credit_comparison",
     text:"How does multi-touch credit compare to siloed credit?",
     description: "Compare your Rockerbox multi-touch numbers with sponsor-only reporting and see how factoring in other channels affects attributed conversions",
     search: "",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["assisted:sum", "even:sum", "attributedAssistedRatio"]
     },
     category: "Moving from Siloed to Multi-Touch Attribution",
  },
  {
     icon:sponsorshipsCPAIcon,
     value:"cpa_comparison",
     text:"How is CPA impacted by multi-touch vs. siloed credit?",
     description: "Understand your all-in cost to a acquire a customer by comparing just the sponsorship cost with a blended CPA that factors in other paid channels",
     search: "spend:>0",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["cpaAssisted", "cpaAttributed", "attributedAssistedCPARatio"]
     },
     category: "Moving from Siloed to Multi-Touch Attribution",
  },
  {
     icon:sponsorshipsFractionalIcon,
     value:"performance_fractional",
     text:"How does each sponsor perform on a multi-touch basis?",
     description: "Understand the true impact of sponsorships by measuring performance based on multi-touch credit that factors in all of your marketing",
     search: "",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum", "even:sum", "cpaAttributed", "revenue_even:sum", "roasAttributed"]
     },
     category: "Moving from Siloed to Multi-Touch Attribution",
  },

  // general analysis
  {
     icon:sponsorshipsTimeseriesIcon,
     value:"weekly_performance",
     text:"How do sponsorships perform week over week?",
     description: "View top-level metrics across your sponsors and see how many conversions sponsors are driving for every dollar spent",
     search: "incremental_cost:>0",
     defaults: {
       isPreset: true,
       defaultCols: ["date"],
       defaultFormats: ["weekly"],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum","assisted:sum", "cpaAssisted"]
     },
     category: "Sponsorships Performance",
  },
  {
     icon:sponsorshipsCostsIcon,
     value:"affiliate_sponsorship",
     text:"How effective are sponsors paid out by performance?",
     description: "Filter to sponsors who are paid based on a percentage of revenue or the number of orders they helped generate",
     search: "incremental_cost:>0",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum","incremental_cost:sum","assisted:sum", "cpaAssisted"]
     },
     category: "Sponsorships Performance",
  },

  // debug views
  {
     icon:sponsorshipsMissingSpendIcon,
     value:"missing_spend",
     text:"Which sponsorships are missing spend?",
     description: "Debug issues where spend hasn't been provided for a sponsor, which may be skewing your CPA and ROAS metrics",
     search: "spend:=0",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum","assisted:sum", "cpaAssisted", "revenue:sum", "roasAssisted"]
     },
     category: "Diagnostics",
  },
  {
     icon:sponsorshipsMissingConversionsIcon,
     value:"missing_assisted",
     text:"Which sponsorships have no tracked conversions?",
     description: "Debug issues with sponsors that don't have proper tracking setup and aren't getting any credit for conversions",
     search: "assisted:=0 AND spend:>0",
     defaults: {
       isPreset: true,
       defaultCols: [],
       defaultMetricDirection: "column",
       defaultRows: ["entity_name"],
       defaultSummary: ["spend:sum","assisted:sum", "cpaAssisted", "revenue:sum", "roasAssisted"]
     },
     category: "Diagnostics",
  },
]

export default presetOptions;
