import React, { Component } from 'react';
import moment from 'moment';
import { PagedIndexGrid, ExpandableIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Segment, Checkbox, Grid, Dropdown, Message, Table, Search, Input, Statistic, Button, Form, Icon, Divider } from 'semantic-ui-react';
import { getConversionDataSummary } from './../../utils/api';
import CacheContext from '../../utils/CacheContext.js'
import SelectConversion from '../SelectSegment';

import { SetupMessage } from './Messages';
import SetupConversion from './SetupConversion';
import { FormDropdown } from './SurveyForm';
import { sumRecursive, TreeRow } from '../TransformTables/TreeTableData';
import styled from 'styled-components';

const DefaultRow = styled.div`
  margin-left: 12px;
  padding: 12px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
`

const sortJSONArrayString = (d) => JSON.stringify(JSON.parse(d).sort())

const responseMappings = (surveyResponseKey, questionKey, data) => {
  if (!surveyResponseKey || !questionKey || data.length == 0) return false

  const first = data[0]
  const firstQuestion = questionKey in first ? first[questionKey] : ""
  const valueIsJSON = firstQuestion.length > 0 ? firstQuestion[0] == "[" : false

  if (valueIsJSON) data.map(row => row.questionKey = sortJSONArrayString(row[questionKey]) )

  const nested = d3.nest()
    .key(row => row.questionKey || row[questionKey])
    .entries(data)
    .sort((p,c) => c.values.length - p.values.length)

  const primaryQuestion = nested[0].key

  const byBaseId = d3.nest()
    .key(({ base_id }) => base_id)
    .entries(data)
    .filter(({values}) => values.length > 1)

  const responseMap = byBaseId.reduce((responseMap, { values }) => {
    const firstResponse = values.find(row => {
      const key = row.questionKey || row[questionKey]
      return key == primaryQuestion
    })
    const secondResponse = values.find(row => {
      const key = row.questionKey || row[questionKey]
      return key != primaryQuestion
    })
    if (!firstResponse || !secondResponse) return responseMap
    responseMap[firstResponse[surveyResponseKey]] = secondResponse[questionKey]
    return responseMap
  }, {})

  return Object.keys(responseMap)
    .reduce((arr, text) => {
      const values = JSON.parse(responseMap[text])
        .map(value => { return { text: value, value, key: value, values: [{row: false, count: 1}] } })
      return [{key: text, text, value: values, values }, ...arr]
    }, [])

}

const GeneralSetup = (props) => {

  const { conversion, conversion_key, survey, displayName, questionKey, surveyResponseKey, handleChange } = props;

  const [showMulti, setShowMulti] = React.useState(!!questionKey)
  const [state, setState, Context] = React.useContext(CacheContext);
  const { conversion_data_original = [], conversion_data = [], summary = [] } = survey ? 
    (Context.getCache(`conversionDataSummary${survey}`, getConversionDataSummary, survey) || {}) : {}

  const options = React.useMemo(() => summary
    .map(({ key }) => {
      return { key, text: key, value: key }
    })
    .filter(({ key }) => !key.includes("_followup"))
    .sort((p,c) => d3.ascending(p.key, c.key))
  , [summary])

  const optionsLoading = !!survey && options.length == 0

  const handleKeyChange = (e,d) => handleChange(d.value, "surveyResponseKey")

  React.useEffect(() => {
    if (!!questionKey) setShowMulti(true)
  }, [questionKey])

  const responseScaffold = React.useMemo(
    () => responseMappings(surveyResponseKey, questionKey, conversion_data_original),
    [surveyResponseKey, questionKey, conversion_data_original]
  )

  const responseToFirstResponse = React.useMemo(
    () => {
      if (!responseScaffold) return false
      return responseScaffold.reduce((obj, { key, values }) => { 
        values.map(row => {
          const { text } = row
          obj[text] = obj[text] || []
          obj[text].push(key)
        })

        return obj
      }, {})
    },
    [responseScaffold]
  )

  const hasMultipleResponses = React.useMemo(() => {

    if (questionKey) return true
    if (!surveyResponseKey || !conversion_data_original.length) return false

    const responses = d3.nest()
      .key(({ base_id }) => base_id)
      .key(row => row[surveyResponseKey])
      .entries(conversion_data_original)
      .filter(({values}) => values.length > 1).length 

    return (responses / conversion_data_original.length) > .1

  }, [questionKey, surveyResponseKey, conversion_data_original])


  return (
    <Grid columns="equal">
      <Grid.Column style={{backgroundColor: '#F3F3F5'}}>
        <ContentCard title="Survey Setup">
          <SetupMessage />
          <Form.Input label="Name" value={displayName} onChange={(e,d) => handleChange(d.value, "displayName")}/>
          <SelectConversion
            onChange={(id) => handleChange(id, "survey") }
            as={FormDropdown}
            skipDefaultSelection={true}
            selectedSegmentId={survey}
            label="Survey Segment"
          />

          { 
            optionsLoading ? 
              <Form.Input label="Survey Response Field" loading /> : options.length > 0 ? 
              <FormDropdown label="Survey Response Field" {...{options, value: surveyResponseKey}} onChange={handleKeyChange} /> :
              <Form.Input label="Survey Response Field" value={surveyResponseKey} onChange={handleKeyChange} />
          }
          {
            hasMultipleResponses && <>
              <Message  
                icon="question"
                header="It looks like users have submitted multiple responses..."
                content="Is this a multi-response survey? If so, you will need to identify the questions asked or the answers presented so we know how to display your data." 
              />
              <Checkbox label="Yes, this is a multi-question survey" checked={showMulti} onClick={() => setShowMulti(!showMulti)} />
              <br />
              <br />
            </>
          }
          { showMulti && <div>
              <FormDropdown label="Available Responses" {...{options, value: questionKey}} onChange={(_, {value}) => handleChange(value, "questionKey")} /> 
              <br />
              { !questionKey && "Must include available answers to use multi-question survey" }
              { questionKey && <>
                  <b><label>Primary Answers -> Follow-up Choices</label></b>
                  <Segment secondary>
                    <Segment>
                  { responseScaffold && responseScaffold
                      .sort((p,c) => c.values.length - p.values.length)
                      .map(row => <TreeRow {...{defaultOpen: 0, row } } />) 
                  }
                    </Segment>
                  </Segment>
                </>
              }
              
            </div>
          }
          <SetupConversion {...props} setupOnly />
        </ContentCard>
      </Grid.Column>
      <Grid.Column>
        {
          surveyResponseKey && (
            <>
              <ContentCard title="Survey Responses" hasTable>
              { !!questionKey ? <NestedFieldPreview
                  data={conversion_data} fields={[surveyResponseKey, `${surveyResponseKey}_followup`]} 
                  {...{ responseScaffold, responseToFirstResponse }} 
                /> :
                <FieldPreview
                  data={conversion_data_original} field={surveyResponseKey} {...{ responseScaffold, responseToFirstResponse }} 
                />
              }
              </ContentCard>
              { !!questionKey && 
                <ContentCard.ShowMore maxHeight={400} title="Other Responses" hasTable>
                  <FieldPreview
                    data={conversion_data_original} field={surveyResponseKey} {...{ responseScaffold, responseToFirstResponse }} 
                  />
                </ContentCard.ShowMore>
              }
            </>
          )
        }
      </Grid.Column>
    </Grid>
  )
}

export const FieldPreview = ({ data, field, responseScaffold, ButtonComponent = false, RowComponent = false }) => {

  const responseParents = !responseScaffold ? false :
    responseScaffold.reduce((obj, c) => {
      const { key } = c
      obj[key] = c
      return obj
    }, {})
   
  const values = d3.nest()
    .key(row => row.base_id )
    .rollup(values => {
      const parent = values.find(row => responseParents[row[field]])
      const child = values.find(row => !responseParents[row[field]])
      return { response : parent, followup: child, count: values.length }
    })
    .entries(data.sort((p,c) => moment(c.timestamp) - moment(p.timestamp)))
    .filter(row => {
      return !row.values.response && !!row.values.followup
    })
    .reduce((obj,c) => {
      if (c.values.followup[field]) obj[c.values.followup[field]] = (obj[c.values.followup[field]] || 0) + 1
      return obj
    },{})

  const listValues = Object.entries(values)
    .sort((p,c) => c[1] - p[1])

  const Row = RowComponent || DefaultRow

  return <>
    { 
      listValues
        .map(([key, value]) => {
          return <Row>
            <div style={{flex:2}}>{ key }</div>
            <div style={{flex:1}}>{ value }</div>
            { 
              ButtonComponent && <div style={{flex:1}}><ButtonComponent value={key} /></div> 
            }
          </Row>
        }) 
    }
  </>
}


export const NestedFieldPreview = ({ data, fields, responseScaffold, rollup, controlColumn, defaultOpen = 0}) => {

  const rollupFunc = rollup || ((values) => values.map(obj => { return {row: obj, count: 1 }} ))

  const responseParents = !responseScaffold ? false :
    responseScaffold.reduce((obj, c) => {
      const { key } = c
      obj[key] = c
      return obj
    }, {})
   
  const values = React.useMemo(() => {
    const values = fields.reduce((p,c) => p.key(row => row[c]), d3.nest())
      .rollup(rollupFunc)
      .entries(data.sort((p,c) => moment(c.timestamp) - moment(p.timestamp)))
      .filter(row => responseParents ? responseParents[row.key] : true )

    values.map(sumRecursive)
    return values.sort((p,c) => c.count - p.count)
  }, [fields, data.length])

  return values.map(row => (<TreeRow {...{defaultOpen, row, controlColumn}} />))
}


export default GeneralSetup;
