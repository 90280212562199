import React, { Component } from 'react';
import { Dropdown, Button, Form, Input, Label } from 'semantic-ui-react';

import {
  postCriteoCredentials,
  putCriteoCredentials,
  postOutbrainCredentials,
  putOutbrainCredentials,
  postTaboolaCredentials,
  putTaboolaCredentials,
  postCommissionJunctionCredentials,
  postDBMCredentials,
  getPlatformAccounts,
  postPlatformAccount
} from '../../../utils/api';

import { ContentCard } from '@rockerbox/styleguide';

import { Loader, Dimmer, Message, Grid, Segment, Image, Header } from 'semantic-ui-react';

import OAUTH_PLATFORMS from '../../Integrations/Authorizations/OAuthPlatforms'

const ErrorMessage = ({ message }) => {
  return (
    <Message negative>
      <Message.Header>There was an error syncing your account</Message.Header>
      <p>{ message }</p>
    </Message>
  )
}


export class DBMCredentialsForm extends Component {

  state = {
    advertiser_id: '',
    advertiser_name: '',
    loading: false,
    errorMsg: ''
  }

  onFieldChange = (fieldName) => (evt, data) => {
    this.setState({[fieldName] : data.value});
  }

  validate = () => {
    return (
      this.state.advertiser_id !== '' &&
      this.state.advertiser_name !== ''
    )
  }

  onSubmit = () => {
    const { id } = this.props;
    const { advertiser_id, advertiser_name } = this.state;
    const obj = { advertiser_id, advertiser_name };

    this.setState({ loading: true });

    postDBMCredentials(id, obj)
      .then((r) => r.response)
      .then((resp) => {
        if (resp.success === false) throw resp.message;
        this.props.onSubmit();
      })
      .catch((e) => this.setState({ errorMsg: e, loading: false }));
  }

  render() {
    const { advertiser_id, advertiser_name, loading, errorMsg } = this.state;
    const { platform } = this.props;
    return (
      <React.Fragment>
        { loading &&
          <div>
            <Dimmer active inverted>
              <Loader active inline='centered' />
            </Dimmer>
          </div>
        }
        <Grid centered style={{height: '100%'}}>
          <Grid.Row columns={4}>
              <Grid.Column
                key={platform.key}
                textAlign='center'
                className='m-b-30'
                verticalAlign='middle'
              >
                <Segment
                  basic
                  style={{padding: 30}}
                >
                  <Image
                    centered
                    size='medium'
                    src={platform.logo_url}
                  />
                  <Form
                    align='left'
                    error={errorMsg != ''}
                    onSubmit={() => this.onSubmit()}
                  >
                    <Message
                      error
                      header='Authorization Error'
                      content={errorMsg}
                    />
                    <Form.Field>
                      <label>Advertiser ID</label>
                      <Input value={advertiser_id} name="advertiser_id" onChange={this.onFieldChange("advertiser_id")} />
                    </Form.Field>
                    <Form.Field>
                      <label>Account Name</label>
                      <Input value={advertiser_name} name="advertiser_name" onChange={this.onFieldChange("advertiser_name")} />
                    </Form.Field>
                    <Button
                      fluid
                      color='teal'
                      className='m-t-25'
                      type="submit"
                      disabled={!this.validate()}
                    >
                      Connect Account
                    </Button>
                  </Form>
                </Segment>
              </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
}


export class CredentialsForm extends Component {

  state = {
    errorMsg: '',
    loading: false,
    rowId: undefined
  }

  componentDidMount() {
    const { fields, rowId } = this.props;
    const fieldNames = fields.map(f => f.name);
    const obj = fieldNames.reduce((p,c) => { p[c] = ''; return p }, {});

    this.setState({ ...obj, fields, fieldNames, rowId });
  }

  onFieldChange = (fieldName) => (evt, data) => {
    this.setState({[fieldName] : data.value});
  }

  validate = () => {
    return this.state.fieldNames.reduce((p,c) => p && (this.state[c] !== ''), true)
  }

  onSubmit = () => {
    this.setState({ loading: true });
    const { fieldNames, rowId } = this.state;
    const { postFunction, putFunction } = this.props;
    const obj = fieldNames.reduce((p,c) => { p[c] = this.state[c]; return p }, {});

    if (rowId != undefined) obj['id'] = rowId;

    const httpFunction = rowId != undefined ? putFunction : postFunction

    httpFunction(obj)
      .then((r) => r.response)
      .then((resp) => {
        if (resp.success === false) throw resp.message;
        this.props.onSubmit();
      })
      .catch((e) => this.setState({ errorMsg: e, loading: false }));
  }

  render() {
    const { fields, errorMsg, loading } = this.state;
    const { platform } = this.props;

    if (!fields) return null

    return (
      <React.Fragment>
      { loading &&
        <div>
          <Dimmer active inverted>
            <Loader active inline='centered' />
          </Dimmer>
        </div>
      }
        <Grid centered style={{height: '100%'}}>
          <Grid.Row columns={4}>
              <Grid.Column
                key={platform.key}
                textAlign='center'
                className='m-b-30'
                verticalAlign='middle'
              >
                <Segment
                  basic
                  style={{padding: 30}}
                >
                  <Image
                    centered
                    size='medium'
                    src={platform.logo_url}
                  />
                  <Form
                    align='left'
                    error={errorMsg != ''}
                    onSubmit={() => this.onSubmit()}
                  >
                    <Message
                      error
                      header='Authorization Error'
                      content={errorMsg}
                    />
                    {
                      fields.map(f => { return (
                        <Form.Field>
                          <label>{f.displayName}</label>
                          <Input value={this.state[f.name]} name={f.name} onChange={this.onFieldChange(f.name)} />
                        </Form.Field>
                      )})
                    }
                    <Button
                      fluid
                      color='teal'
                      className='m-t-25'
                      type="submit"
                      disabled={!this.validate()}
                    >
                      Connect Account
                    </Button>
                  </Form>
                </Segment>
              </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
}


class OAuthPlatformsForm extends Component {

  onClick = (platform) => {
    if(OAUTH_PLATFORMS.includes(platform)){
      window.location = `/auth/${platform}`;
    }

    this.props.onChange(platform);
  }

  render() {
    const { options, authorizedPlatforms } = this.props;
    const { onClick } = this;

    return (
      <Grid centered>
        <Grid.Row columns={5}>
          {options.map((platform) => {
            if (platform.deprecated) return null
            return (
              <Grid.Column
                key={platform.key}
                textAlign='center'
                className='m-b-30'
              >
                <Segment
                  style={{padding: 30}}
                >
                  { authorizedPlatforms.includes(platform.value) &&
                    <Label
                      attached='top'
                      color='green'
                      icon='check'
                      content='Connected'
                      className='opacity-75'
                    />
                  }
                  <Image
                    centered
                    size='medium'
                    src={platform.logo_url}
                    className={authorizedPlatforms.includes(platform.value) ? 'attached' : ''}
                  />
                  <Header
                    as='h4'
                    content={platform.text}
                    align='center'
                  />
                  <Button
                    fluid
                    color='teal'
                    style={authorizedPlatforms.includes(platform.value) ? {fontSize: 13, lineHeight: '16px'} : {}}
                    onClick={() => onClick(platform.value)}
                  >
                    Connect {authorizedPlatforms.includes(platform.value) && 'Another Account'}
                  </Button>
                </Segment>
              </Grid.Column>
            )}
          )}
        </Grid.Row>
      </Grid>
    )
  }
}


export class AccountsForm extends Component {

  state = {
    accounts: [],
    options: [],
    selectedAccountId: null,
    loading: false,
    errorMsg: '',
    disabled: false
  }

  onChange = (e, data) => {
    const selectedAccountId = data.value;
    const account = data.options.find(d => d.value == selectedAccountId);
    if (account.is_manager) {
      const errorMsg = "It appears you are trying to authorize an Adwords Manager Account. Please read our documentation first at: http://bit.ly/AdWordsManagerAccounts"
      this.setState({ selectedAccountId, errorMsg, disabled: true })
    } else this.setState({ selectedAccountId, disabled: false });
  }

  getAccounts = (platform) => {
    const { id } = this.props;
    this.setState({ loading: true })
    getPlatformAccounts(platform.platform,id)
      .then(accounts => {
        const options = accounts.map((a, i) => {
          return {
            text: a.account_name,
            value: a.account_id || a.customer_id,
            is_manager: a.is_manager || false,
            currency_code: a.currency_code || "USD",
            key: i}
        });
        const errorMsg = accounts.length > 0 ? '' : 'There are no ad accounts associated with this user';
        this.setState({ accounts, options, errorMsg, loading: false });
      });
  }

  onSubmit = () => {
    this.setState({ loading: true })
    const { platform, platforms, id } = this.props;
    const { accounts, selectedAccountId } = this.state;

    const { id_column } = platform;
    const account = accounts.find(acct => acct[id_column] == selectedAccountId);
    account['version'] = platform.default_version;

    postPlatformAccount(platform.platform, id, account)
      .then(() => {
        this.setState({ loading: false });
        this.props.onSubmit();
      });
  }

  componentDidMount() {
    this.getAccounts(this.props.platform);
  }

  render() {
    const { platform } = this.props;
    const { selectedAccountId, accounts, options, loading, errorMsg, disabled } = this.state;
    const { onChange, onSubmit } = this;

    return (
      <React.Fragment>
      { loading &&
        <div>
          <Dimmer active inverted>
            <Loader active inline='centered' />
          </Dimmer>
        </div>
      }
        <Grid centered style={{height: '100%'}}>
          <Grid.Row columns={4}>
              <Grid.Column
                key={platform.id}
                textAlign='center'
                className='m-b-30'
                verticalAlign='middle'
              >
                <Segment
                  basic
                  style={{padding: 30}}
                >
                  <Image
                    centered
                    size='medium'
                    src={platform.logo_url}
                  />
                  <Form align='left' onSubmit={() => onSubmit()} warning={errorMsg != ''}>
                    <Message
                      warning
                      header=''
                      content={errorMsg}
                    />
                    <Form.Dropdown
                      placeholder={`Select ${platform.display_name} Account`}
                      fluid
                      search
                      selection
                      options={options}
                      value={selectedAccountId}
                      onChange={onChange}
                      disabled={!accounts.length}
                    />
                    <Button
                      fluid
                      color='teal'
                      className='m-t-25'
                      type='submit'
                      disabled={disabled}
                    >
                      Connect Account
                    </Button>
                  </Form>
                </Segment>
              </Grid.Column>
          </Grid.Row>
        </Grid>
        {/*<Form onSubmit={() => onSubmit()}>
          <Form.Field>
            <strong>Account</strong>
            <Dropdown
              placeholder='Select Account'
              selection
              options={options}
              value={selectedAccountId}
              onChange={onChange}
              disabled={!accounts.length}
              style={{marginLeft: 15, marginRight: 15}}
            />
          </Form.Field>
          <Button type="submit" disabled={!selectedAccountId}>Save</Button>
        </Form>*/}
      </React.Fragment>
    )
  }
}


export const PLATFORM_DATA = {
  "criteo": {
    fields: [
      {name: "client_id", displayName: "Client ID"},
      {name: "client_secret", displayName: "Client Secret"}
    ],
    postFunction: postCriteoCredentials,
    putFunction: putCriteoCredentials
  },
  "outbrain": {
    fields: [
      {name: "account_id", displayName: "Account ID"},
      {name: "username", displayName: "Username"},
      {name: "password", displayName: "Password"}
    ],
    postFunction: postOutbrainCredentials,
    putFunction: putOutbrainCredentials
  },
  "taboola": {
    fields: [
      {name: "account_id", displayName: "Account ID"},
      {name: "client_id", displayName: "Client ID"},
      {name: "client_secret", displayName: "Client Secret"}
    ],
    postFunction: postTaboolaCredentials,
    putFunction: putTaboolaCredentials
  },
  "commissionjunction": {
    fields: [
      {name: "account_id", displayName: "Account ID"},
      {name: "access_token", displayName: "Access Token"}
    ],
    postFunction: postCommissionJunctionCredentials
  }
}

export class PlatformForm extends Component {

  render() {
    const { platforms, selectedPlatform, activeAccountId, onSubmit, onChange, options } = this.props;
    const { activePlatform, activeAccountRowId } = this.props;

    if (platforms.length == 0) {
      return (
        <div>
          <Dimmer active inverted>
            <Loader active inline='centered' />
          </Dimmer>
        </div>
      )
    }

    if (!selectedPlatform && activePlatform && activeAccountRowId && !activeAccountId) {
      return (activePlatform == 'dbm') ?
              <DBMCredentialsForm onSubmit={onSubmit} id={activeAccountRowId} platform={options.find(p => p.value == 'dbm')} />
              :
              <AccountsForm platform={platforms.find(p => p.platform == activePlatform)} platforms={platforms} onSubmit={onSubmit} id={activeAccountRowId} />;
    }
    if (selectedPlatform && !OAUTH_PLATFORMS.includes(selectedPlatform)) {
      const platform = options.find(p => p.value == selectedPlatform);
      const { fields, postFunction } = PLATFORM_DATA[selectedPlatform];
      return <CredentialsForm onSubmit={onSubmit} platform={platform} fields={fields} postFunction={postFunction} />;
    }

    return <OAuthPlatformsForm {...this.props} />;

  }
}
