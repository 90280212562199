import React from 'react'
import { Progress, Header, Grid } from 'semantic-ui-react'


const ProgressBar = ({obj, total, color, currency}) => {
  const displayValue = !currency
    ? obj.value.toLocaleString('en-US')
    : new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 2 }).format(obj.value)

  return <>
    <Grid fluid className="progress-wrapper">
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={8} textAlign='left'>
          <Header as='h5' textAlign='left' size='small' style={{ fontWeight: '500' }}>{obj.label}</Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as='h5' textAlign='center' size='small' color={color} style={{ fontWeight: '500' }}>{displayValue}</Header>
        </Grid.Column>
        <Grid.Column width={4} >
          <Header as='h5' textAlign='center' size='small' color={color} style={{ fontWeight: '500' }}>{Math.round(obj.value / total * 100) + '%'}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Progress size='small' value={obj.value} total={total} color={color} style={{ borderRadius: '2rem !important' }} ></Progress>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}

export default ProgressBar