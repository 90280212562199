import axios from 'axios';

export function getAccount() {
  const url = `/account`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function putAdvertiserShopifyHosted(isHosted) {
  const url = `/account`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ is_shopify_hosted: isHosted })
  }).then(data => {
    return data;
  });
}

export function getAuthorizations() {
  const url = `/account/authorizations`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getAccountCurrency() {
  const url = "/account/currency/reporting";
  return fetch(url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getAllIntegrations() {
  const url = `/account/integrations/all`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function switchAccount(value) {
  const url = `/account/switch`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ advertiser_name: value })
  }).then(data => {
    return data;
  });
}

export function getAccountUsers() {
  const url = `/account/users`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getPlatformAccounts(platform, id) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.accounts;
    });
}

export function postPlatformAccount(platform, id, obj) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response =>response.json())
    .then(data => data.response)
}

export function deletePlatformAccount(platform, id) {
  const url = `/auth/${platform}/account/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE"
  })
    .then(response => response.json())
    .then(data => {
      return data.success;
    });
}

export function getUser() {
  const url = `/user`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function putUser(obj) {
  const url = `/user`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function changePassword(obj) {
  const url = `/user/password/change`
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(({ response }) => response)
}

export function getUserPermissions() {
  const url = `/user/permissions`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getOnboardingSteps() {
  const url = `/account/onboarding_step`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveOnboardingStep(obj) {
  const url = `/account/onboarding_step`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getOnboardingSurvey() {
  const url = `/account/onboarding_survey`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveOnboardingSurvey(obj) {
  const url = `/account/onboarding_survey`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postRequiresSetup(obj) {
  const url = `/account/requires_setup`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getIntegrations() {
  const url = `/account/integrations`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function getIntegrationFeatures() {
  const url = `/account/integrations/features`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function forgotPassword(obj) {
  const url = `/user/password/forgot`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function deleteUser(obj) {
  const url = `/user/delete`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  }).then(data => {
    return data;
  });
}

export function addNewUser(obj) {
  const url = `/user/signup`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  })
    .then((response) => response.json())
    .then((data) => data.response);
}

export function postDomains(obj) {
  return axios.post("/account/domains", obj)
    .then(resp => resp);
}

export function getDomains() {
  const url = `/account/domains`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postExcludedDomains(obj) {
  return axios.post("/account/excluded/domains", obj)
    .then(resp => resp);
}

export function getExcludedDomains() {
  const url = `/account/excluded/domains`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => {
      return data.response;
    });
}

export function postFeatureWaitlist(obj) {
  const url = `/account/feature_waitlist`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getFeatureWaitlist(feature) {
  const url = `/account/feature_waitlist?feature=${feature}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function saveMappingTemplate(obj) {
  const url = `/account/settings/mapping_template`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getTierColors() {
  const url = `/account/settings/tier_colors`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(({ response }) =>
      response.reduce((acc, { tier_1, color }) => {
        acc[tier_1] = color;
        return acc;
      }, {})
    );
}

export function saveTierColor(obj) {
  const url = `/account/settings/tier_colors`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function deleteTierColor(obj) {
  const url = `/account/settings/tier_colors`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getCustomerID() {
  const url = `/account/billing/stripe_customer`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
  })
    .then(response => response.json())
}

export function postSignup(obj) {
  const url = `/account/signup`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(response => response.response)
}

export function postUserLoginToken(token) {
  const url = `/user/login/token`;

  const postObj = { token }
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(postObj)
  })
  .then(response => response.json())
  .then(response => response.response)
}

export function postSetupIntent(customerId, paymentMethods) {
  const obj = { customer_id: customerId, payment_methods: paymentMethods }
    const url = `/account/billing/setup_payment`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function getShopifySetupStatus() {
  const url = `/auth/shopify/setup`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export async function getShopifyInfo() {
  const res = await getShopifySetupStatus()
  const data = !!res && !!res.length ? res[0] : {}
  const { segments, historical_orders } = data
  data['segments'] = !!segments ? JSON.parse(segments) : {}
  data['view_product'] = data['segments']['view_product']
  data['add_to_cart'] = data['segments']['add_to_cart']
  data['purchase'] = data['segments']['purchase_batch'] || data['segments']['purchase_pixel']
  data['historical_orders'] = !!historical_orders
    ? JSON.parse(historical_orders)
    : {}
  return data
}

export function getStripePricingTiers() {
  const url = `/account/billing/stripe_pricing`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function postPaymentIntent(customerId, paymentMethods, amount) {
  const obj = { customer_id: customerId, payment_methods: paymentMethods, payment_amount: amount }
    const url = `/account/billing/stripe_pricing`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function postStripeSubscription(customerId, priceId, amount, offset, discountCode, type) {
  const obj = { customer_id: customerId, price_id: priceId, amount: amount, offset: offset, discount_code: discountCode, type: type }
    const url = `/account/billing/stripe_subscription`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function deleteStripeSubscription(subscriptionId) {
  const obj = { subscription_id: subscriptionId }
    const url = `/account/billing/stripe_starter`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function getStripeSubscription(customer_id) {
  const url = `/account/billing/stripe_starter?customer_id=${customer_id}`;
  return fetch(url, { 
    credentials: "include",
    method: "GET"
  })
  .then(response => response.json())
}

export function getActiveStripeCoupons(){
  const url = `/account/billing/stripe_coupon`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function getActiveStripePromoCodes(){
  const url = `/account/billing/stripe_promo_code`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function getDiscountCodeMetadata(code, type){
  const url = `/account/billing/stripe_discount_code_metadata?code=${code}&type=${type}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function getStripeInvoice(invoice_id){
  const url = `/account/billing/stripe_invoice?invoice_id=${invoice_id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function getStripePaymentMethod(payment_method_id){
  const url = `/account/billing/stripe_payment_method?payment_method_id=${payment_method_id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function modifyStripePaymentMethod(subscriptionId, paymentMethodId) {
  const obj = { subscription_id: subscriptionId, payment_method_id: paymentMethodId }
    const url = `/account/billing/stripe_payment_method`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function retrieveStripeSetupIntent(setupIntentId){
  const url = `/account/billing/setup_payment?setup_intent=${setupIntentId}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function modifyStripeSubscription(newSubQuantity, subId, subItemId, promoCode) {
  const obj = {new_sub_quantity: newSubQuantity, sub_id: subId, sub_item_id: subItemId, promo_code: promoCode}
  const url = `/account/billing/stripe_subscription`;
    return fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

export function retrieveStripeInvoiceHistory(subscription){
  const url = `/account/billing/stripe_invoice_history?subscription=${subscription}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function retrieveStripePaymentIntent(paymentIntent){
  const url = `/account/billing/stripe_payment_intent?payment_intent=${paymentIntent}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function retrieveStripeCustomerDetails(customer){
  const url = `/account/billing/stripe_customer_details?customer=${customer}`;
  return fetch(url, {
    credentials: "include",
    method: "GET"
  })
    .then(function(response) {
      return response.json();
    })
}

export function getMessages() {
  const url = `/account/help/messages`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}