import React from 'react'
import { useHistory } from 'react-router-dom'
import { Popup, Segment, Image, Header, Icon } from 'semantic-ui-react'
import { Funnel } from '@rockerbox/styleguide'
import { IntegrationDisplayName } from '../Integrations/parts'


export const DataSourceCard = ({ platform }) => (
  <Popup
    basic
    inverted
    size='tiny'
    position='right center'
    content={platform == 'rockerbox'
      ? "Rockerbox On-Site Pixel"
      : <IntegrationDisplayName {...{platform}} />}
    style={{backgroundColor: '#034566'}}
    trigger={
      <ClickableCard
        circular
        link={platform == 'rockerbox'
          ? `/v2/pixel/live`
          : `/v2/settings/integrations/view/${platform}/Accounts`}
      >
        <Image
          ui={false}
          className={`logo-${platform}`}
          src={`https://images.rockerbox.com/integrations/integrations-icon-${platform}.svg`}
        />
      </ClickableCard>
    }
  />
)


export const DataTypeCard = ({ image, title, link, params }) => (
  <ClickableCard {...{link, params}}>
    <Image
      ui={false}
      src={image}
    />
    <Header
      as='h4'
      textAlign='center'
      content={title}
    />
  </ClickableCard>
)


export const DataProcessingCard = ({ image, title, link, params }) => {
  return (
    <div className='node data-processing'>
      <ClickableCard circular {...{link, params}}>
        <Image
          ui={false}
          src={image}
        />
      </ClickableCard>
      <div className='label'>
        <Header
          as='h4'
          textAlign='center'
          content={title}
        />
      </div>
    </div>
  )
}


export const MarketingAnalysisCard = ({ image, title, link, params }) => (
  <ClickableCard {...{link, params}}>
    <Header
      as='h5'
      textAlign='center'
      image={image}
      content={title}
    />
  </ClickableCard>
)


export const MarketingFunnelStep = ({ id, image, text, link, params }) => (
  <ClickableCard as={Funnel.Step} {...{link, params}}>
    <Image
      ui={false}
      className='funnel-image'
      src={image}
    />
    <Header
      as='h5'
      content={text}
    />
  </ClickableCard>
)

export const ViewReportsButton = ({ setActivePane }) => (
  <Segment onClick={() => setActivePane('report-map')} className='view-reports'>
    <Header as='h5' color='blue' textAlign='center'>
      View All Reports
    </Header>
  </Segment>
)


export const ClickableCard = ({ link, children, params, ...props }) => {
  const history = useHistory()
  const formattedLink = evalTemplate(link, params)

  return (
    <Segment {...props} onClick={() => history.push(formattedLink)}>
      {children}
    </Segment>
  )
}


export const evalTemplate = (template, params) => {
  String.prototype.evalTemplate = function(params={}) {
    const keys = Object.keys(params)
    const values = Object.values(params)
    return new Function(...keys, `return \`${this}\``)(...values)
  }

  return template.evalTemplate(params)
}
