import React, { useState, useEffect } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react'
// import DateRangeHooks from '../DatePicker/index';
import { PopupCard, LocalStateDatePicker } from '../../components';
import { track } from '../../../utils/tracking';

import { useGlobalState } from '../../hooks/global';
import { postExportRequest } from '../../api/datalakeAdhocExports';
import {
  selectSegmentOptions,
  selectCustomerTypeOptions,
  selectAttributionModelOptions,
  selectFeaturedSegment,
} from './selectors';
import { createDefaultReportName } from './utils';
import { PRESET_ADHOC_EXPORTS_DATE_RANGES } from '../../utils/time';

import { TIERS } from '../../constants/tiers';
import { DATE_GROUPINGS, METRIC_COLUMNS } from './constants';
import { validateEmail, validateNoSpecialCharacters } from '../../utils/formValidators';

import { find, get, compose, mapValues } from 'lodash/fp';

const ErrorMessage = ({header, message}) => {
  return (
    <div className="ui negative message">
      {header && <div className="header">{header}</div>}
      <p>{message}</p>
    </div>
  )
}

const AdhocExportsForm = ({ onFormClose, open }) => {
  const { user, account, segments } = useGlobalState();

  const [requestMessage, setRequestMessage] = useState("");

  const [currentSegment, setCurrentSegment] = useState({})
  const [customerTypeOptions, setCustomerTypeOptions] = useState([])
  const [attributionModelOptions, setAttributionModelOptions] = useState([])

  // FormState
  const [conversionId, setConversionId] = useState(currentSegment?.action_id)
  const [customerType, setCustomerType] = useState('')
  const [attributionModel, setAttributionModel] = useState('')
  const [reportName, setReportName] = useState("")
  const [formDateRange, setFormDateRange] = useState({ startDate: '', endDate: '' })
  const [aggMetrics, setAggMetrics] = useState([])
  const [dateGroup, setDateGroup] = useState("none")
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState('')
  const [emailDeliveryInputList, setEmailDeliveryInputList] = useState([{ deliveryEmail: user.email }]);

  const multipleEmailFields = emailDeliveryInputList.length > 1

  useEffect(() => {
    // Sets initial segment onLoad
    if (segments.length) {
      const featuredSegment = selectFeaturedSegment(segments);
      setCurrentSegment(featuredSegment)
      setConversionId(featuredSegment.action_id)
    }
  }, [segments])

  useEffect(() => {
    // Updates CustomerType and AttributionModel options based on the selected segment
    // Updates the default report name based on selections
    const updatedCustomerTypeOptions = selectCustomerTypeOptions(currentSegment);
    setCustomerTypeOptions(updatedCustomerTypeOptions);
    setCustomerType(updatedCustomerTypeOptions[0].value);

    const updatedAttributionModelOptions = selectAttributionModelOptions(currentSegment);
    setAttributionModelOptions(updatedAttributionModelOptions)
    setAttributionModel(updatedAttributionModelOptions[0].value)

    const newDefaultReportName = createDefaultReportName(
      currentSegment.action_name,
      updatedCustomerTypeOptions[0].text,
      updatedAttributionModelOptions[0].text,
    );
    setReportName(newDefaultReportName)
  }, [currentSegment]);

  useEffect(() => {
    // Updates default report name on select changes
    const conversionName = currentSegment.action_name;
    const updatedCustomerTypeText = compose(
      get('text'),
      find(({ value }) => value === customerType)
    )(customerTypeOptions);
    const updatedAttributionModelText = compose(
      get('text'),
      find(({ value }) => value === attributionModel)
    )(attributionModelOptions);

    const newDefaultReportName = createDefaultReportName(
      conversionName,
      updatedCustomerTypeText,
      updatedAttributionModelText,
    );
    setReportName(newDefaultReportName)
  }, [customerType, attributionModel])

  // Handlers
  const handleConversionSelect = (_, { value }) => {
    setConversionId(value)
    const updatedCurrentSegment = segments.find(({ action_id }) => action_id === value)
    setCurrentSegment(updatedCurrentSegment)
  }
  const handleCustomerTypeSelect = (_, { value }) => setCustomerType(value)
  const handleAttributionModelSelect = (_, { value }) => setAttributionModel(value)

  const handleReportName = (_, { value }) => setReportName(value)
  const handleDateChange = setFormDateRange
  const handleAggMetrics = setAggMetrics;
  const handleDateGroup = (_, { value }) => setDateGroup(value)

  const handleDeliveryEmailInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailDeliveryInputList];
    list[index][name] = value;
    setEmailDeliveryInputList(list);
  };

  const handleDeliveryEmailRemoveClick = index => {
    const list = [...emailDeliveryInputList];
    list.splice(index, 1);
    setEmailDeliveryInputList(list);
  };

  const handleDeliveryEmailAddClick = () => {
    setEmailDeliveryInputList([...emailDeliveryInputList, { deliveryEmail: "" }]);
  };

  const validateForm = (formObject) => {
    const fields = {
      reportName: {
        key: 'report_name',
        isValid: validateNoSpecialCharacters,
        message: 'Please remove special characters: <> ? [] | * \ / !'
      }
    }

    const validations = mapValues((field) => {
      if(field.isValid(get(field.key)(formObject))){
        return ''
      }
      return field.message
    })(fields)

    formObject?.delivery_options?.email_delivery_location.forEach((value, index) => {
      const keyName = `email${index}`
      if(!validateEmail(value.deliveryEmail)) {
        validations[keyName] = 'Please enter a valid email address'
      }
    })

    const noErrors = Object.keys(validations).every(x => validations[x].length === 0)
    if (!noErrors) {
      setFormErrors(validations)
      return false
    }
    return true;
  }

  const handleSubmit = () => {
    const params = {
      report_request: {
        metadata: aggMetrics,
        metadata_filters: [], // No filters for now. This will be refactored.
        metrics: {
          ntf_filter: customerType,
          attr_method: attributionModel,
          columns: METRIC_COLUMNS.map(metric => metric.value), // Hardcoded for now
        },
        group_by_date: dateGroup,
        advertiser: account.pixel_source_name,
        identifier: conversionId,
        start_date: formDateRange?.startDate,
        end_date: formDateRange?.endDate,
      },
      report_options: {
        file_type: 'csv',
      },
      delivery_options: {
        email_delivery_location: emailDeliveryInputList,
        type: emailDeliveryInputList.length >= 1 ? "email" : "none"
      },
      report_name: reportName,
      reporter: user.email,
      report_type: 'compiled_mta_tiers',
      report_request_origin: "ui"
    }

    if(!validateForm(params)) return;
    setSubmitting(true);

    postExportRequest(params)
      .then((response) => {
        setRequestMessage(JSON.stringify(response.data))

        const trackingMetaData = {
          "pixel_source_name": account.pixel_source_name,
          "report_type": "compiled_mta_tiers",
          "conversion": conversionId,
          "customer_type": customerType,
          "attribution_model": attributionModel,
          "tiers_group_by": aggMetrics,
          "time_period_group_by": dateGroup,
          "start_date": formDateRange?.startDate,
          "end_date": formDateRange?.endDate,
          "name": reportName,
          "email_count": 1, //hardcoded for now
        }

        track('data.exports.ad_hoc_exports.create', trackingMetaData)
        onFormClose(true);
      })
      .catch((error) => {
        setSubmitting(false)
        const inProgressReportsError = "Can't make another request right now"
        if(error?.response?.data?.message.includes(inProgressReportsError)){
          setErrorMsg("You have 3 exports in-progress. Please wait for one of your in-progress exports to complete before requesting another export.")

          const trackingMetaData = {
            "pixel_source_name": account.pixel_source_name
          }

          trackEvent('data.exports.ad_hoc_exports.export_limit', trackingMetaData)
        }
      })
  }

  return (
    <>
      <h2>Generate Export</h2>
      <Form
        onSubmit={handleSubmit}
        loading={submitting}
      >
        <Form.Field>
          <label>Conversion</label>
          <Form.Select
            value={conversionId}
            options={selectSegmentOptions(segments)}
            onChange={handleConversionSelect}
          />
        </Form.Field>
        <Form.Field>
          <label>Customer Type</label>
          <Form.Select
            value={customerType}
            options={customerTypeOptions}
            onChange={handleCustomerTypeSelect}
          />
        </Form.Field>
        <Form.Field>
          <label>Attribution Model</label>
          <Form.Select
            value={attributionModel}
            options={attributionModelOptions}
            onChange={handleAttributionModelSelect}
          />
        </Form.Field>
        <Form.Field>
          <label>Date Range</label>
          <LocalStateDatePicker
            onDateChange={handleDateChange}
            firstReportingDate={currentSegment.first_reporting_date}
            presetDates={PRESET_ADHOC_EXPORTS_DATE_RANGES}
          />
        </Form.Field>
        <Form.Field>
          <label>Group By Time Period</label>
          <Form.Select
            placeholder="Week"
            value={dateGroup}
            options={DATE_GROUPINGS}
            onChange={handleDateGroup}
          />
        </Form.Field>
        <Form.Field>
          <label>Group By Dimensions</label>
          <PopupCard
            list={TIERS.slice(0, 3)}
            originalList={TIERS}
            setOrder={handleAggMetrics}
            applyOnDrop={true}
            open={open}
          />
        </Form.Field>
        <Form.Field>
          <label>Metrics</label>
          {METRIC_COLUMNS.map(metric => metric.text).join(', ')}
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            error={formErrors.reportName || null}
            name="reportName"
            label="Export Name"
            maxLength="200"
            value={reportName}
            onChange={handleReportName}
          />
        </Form.Field>
        <Form.Field>
          <label>Email Recipient</label>
          {emailDeliveryInputList.map((x, i) => (
            <div style={{display: 'flex'}}>
              <div style={{flex: 1}}>
                <Form.Input
                  required
                  error={formErrors[`email${i}`] || null }
                  name="deliveryEmail"
                  placeholder="Email address to deliver the report"
                  value={x.deliveryEmail}
                  onChange={e => handleDeliveryEmailInputChange(e,i)}
                />
              </div>
              <div style={{height: 50, position: 'relative', top: '50%', transform: 'translateY(-15%)', display: 'flex', alignItems: 'bottom'}}>
                {multipleEmailFields && (
                  <Button icon inverted onClick={() => handleDeliveryEmailRemoveClick(i)}>
                    <Icon color='violet' size='mid' name='cancel'/>
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div style={{color: 'blue', fontSize: 14, fontWeight: 'bold'}}>
            <Button icon inverted onClick={handleDeliveryEmailAddClick} style={{color: 'blue', marginRight: -5}}>
              <Icon color='violet' size='mid' name='plus circle' />
              Add Recipient
            </Button>
          </div>
        </Form.Field>
        <Button
          type="submit"
          primary
        >
          Start data export
        </Button>
        <Button type="button" onClick={() => onFormClose()}>Cancel</Button>
        {requestMessage}
        {errorMsg && <ErrorMessage message={errorMsg} />}
      </Form>
    </>
  )
}

export default AdhocExportsForm;
