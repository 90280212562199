import React, { useState, useEffect } from 'react';
import { Form, Message, Button, Icon, Grid, Popup } from 'semantic-ui-react';
import { DataTable} from '@rockerbox/styleguide';
import FindMarketingEvent from '../Entities/FindMarketingEvent';
import { updateChannelGroup, postChannelGroup} from '../../utils/api';

const columns = [
  { display: "Tier 1", key: "tier_1", type: "text" },
  { display: "Tier 2", key: "tier_2", type: "text" },
  { display: "Tier 3", key: "tier_3", type: "text" },
  { display: "Tier 4", key: "tier_4", type: "text" },
  { display: "Tier 5", key: "tier_5", type: "text" },
]

const ChannelGroupsForm = (props) => {
	const { featuredSegment, viewState, selectedGroup, setViewState, setSaveStatus } = props;
	const [name, setName] = useState("");
	const [data, setData] = useState([]);

	const [tiersError, setTiersError] = useState("");
  const [nameError, setNameError] = useState("");
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if(Object.keys(selectedGroup).length > 0) {
			setName(selectedGroup.name);
			setData(selectedGroup.tiers);
		} else {
			setName("");
			setData([])
		}
	}, [selectedGroup])

	const checkName = (value) => {
    const checkCharAndLen = value != "" ? /^[A-Za-z][A-Za-z\s]*$/.test(value) && value.length <=16 : true;
    checkCharAndLen ? setNameError("") : setNameError("Please use only letters and spaces to create your channel group name. (16 character max)");

    setName(value)
  }

	const handleEventAdd = (event, {value}) => {
		const row = value
    const tiers = [...row.parents, row.key]
    const TIERS = ["tier_1","tier_2","tier_3","tier_4","tier_5"]

    const newData = TIERS
      .reduce((p, tier, i) => Object.assign(p, {[tier]: tiers[i]}), {})
    
    const filtered = data.filter(row => !TIERS.reduce((p,c) => p && (row[c] == newData[c]), true))
		setData([...filtered, newData])
  }

	const onRemove = (row) => setData(data.filter(r => r != row))

  const onUpdate = (d) => {
    setData(d)
  }

	const handleSubmit = () => {
		setLoader(true);
		setTiersError("");
		const send = viewState == "edit" ? updateChannelGroup : postChannelGroup;
		const postObj = {
			name,
			tiers: data
		};

		if (postObj.tiers.length == 0) {
			setTiersError("Please select marketing tiers before submitting");
			setLoader(false);
		} else {
			send(postObj, selectedGroup.id)
			.then(data => {
				if(data.status == 500) {
					setSaveStatus("error");
					setViewState("index");
				} 
				else {
					setLoader(false);
					setSaveStatus("saved");
					setViewState("index");
				}
			})
		}
	}		

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit}>
				<Grid columns={2}>
				<Grid.Row>
					<Grid.Column>
						<h3>Display Name</h3>
						<Form.Input value={name} onChange={(e,{value}) => checkName(value)} required/>
					</Grid.Column>
					<Grid.Column>
						{ viewState == "edit" && <Message info style={{marginTop: 24}}><Icon name="info circle" size="large"/>Editing the Display Name will not change the column prefix for this group: <code style={{background: "whitesmoke", color: "#999999", fontWeight: 600, padding: 3, borderRadius: 4}}>{selectedGroup.prefix}</code></Message> }
					</Grid.Column>
				</Grid.Row>
				</Grid>
				{ nameError.length > 0 && <Message negative>{nameError}</Message> }
				<h3>Associated Channels <Popup flowing content={<p>Select the broadest set of tiers that you would like to be associated with your channel group.  <br/> Add multiple rows to select as many sets of tiers as you need.</p>} trigger={<Icon name="question circle" style={{color: "#CACBCD"}}/>} /></h3>
				<DataTable {...{ columns, data, onRemove, onUpdate }}/>
				{ tiersError.length > 0 && <Message negative>{tiersError}</Message> }
				
				<div style={{marginTop: 30}}><FindMarketingEvent segment={featuredSegment} onChange={handleEventAdd}/></div>
				<div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
					<Button content="cancel" onClick={() => setViewState("index")}/>
					<Button primary loading={loader} disabled={nameError || loader} content={viewState == "edit" ? "Update" : "Save"} type="submit"/>
				</div>
			</Form>
		</React.Fragment>
	)
}

export default ChannelGroupsForm;