import styled from 'styled-components';

export const Row = styled.div.attrs({"className":"rowContainer"})`
  padding-left: ${props => props.paddingLeft || '10px' };
  padding-right: ${props => props.paddingRight || '10px' };
  padding-top:${props => props.paddingTop || '15px' };
  padding-bottom:${props => props.marginBottom || '10px' };
  margin-top:${props => props.marginTop || '0px' };
  margin-bottom:${props => props.paddingBottom || '0px' };
  border-bottom:${props => props.borderBottom || '1px solid #eee' };
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-right:0px;
  padding-top: ${props => props.paddingTop || 0 };
  flex: ${props => props.flex || 1 };;
  justify-content: ${props => props.justify|| 'inherit' };
  text-align: ${props => props.justify|| 'inherit' };
`

export const Emph = styled.div`
  font-size:1.1em;
  font-weight:bold;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledHeader = styled.div`
  font-size: ${props => props.size == "big" ? "1em" : ".9em"};
  font-weight:bold;
  color: ${props => props.size == "big" ? "black" : "grey"};
  opacity:.8;
  text-transform:${props => props.size == "big" ? "uppercase" : "inherit"};
  justify-content: ${props => props.justify|| 'inherit' };
  text-align: ${props => props.justify|| 'inherit' };
  min-width:1px;
  flex-direction:row;
`

export const NonEmph = styled.div`
  color:gray;
  font-size:.9em;
  font-weight: ${props => props.bold ? "bold" : "inherit"};
`

