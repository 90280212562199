import React from 'react'
import { ArrowCircleUpIcon, ArrowCircleDownIcon, BadgeCheckIcon, BanIcon } from '@heroicons/react/outline'
import { getRawPercentDiffFromGoal } from './budgetHelpers';

// currency formatter
const spendFormatter = (currencyCode, maxDigits) => {
  const maximumFractionDigits = maxDigits || maxDigits === 0 ? maxDigits : 2
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: maximumFractionDigits,
    currency: currencyCode || 'USD'
  })
};

// currencyOverride & maxDigits params are optional
const formatCurrency = (number, currencyOverride, maxDigits) => {
  const currencyFormat = spendFormatter(currencyOverride, maxDigits)
  const formatted = isNaN(number) ? '-' : typeof number !== 'number' ? currencyFormat.format(0) : currencyFormat.format(number);

  return formatted
}

const numberToTwoDecimals = (number) => {
  const num = typeof number == "number" ? number : 0
  const formattedNumber = num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})
  return formattedNumber
}

const numberFormatter = new Intl.NumberFormat('en-US', { style : 'percent' })
const formatPercent = (x) => numberFormatter.format(x)

const goalDiffDict = {
  increase: {
    type: "increase",
    text: "Increase spend by ",
    icon: ArrowCircleUpIcon,
    tooltip: (metric) => `Your ${metric} on this campaign is considerably lower than your goal, so it’s possible that increasing spend will result in a meaningful increase in revenue.`
  },
  decrease: {
    type: "decrease",
    text: "Decrease spend by ",
    icon: ArrowCircleDownIcon,
    tooltip: (metric, extreme) => extreme ? `Your ${metric} on this campaign is considerably lower than your goal, but you are still Over budget. Consider reducing spend slightly here to help get you closer to your budget.` : `Your ${metric} on this campaign is considerably higher than your goal, so it’s possible you’re overspending. Consider reducing spend and reallocating those funds to campaigns where you may not be spending enough.`
  },
  onTarget: {
    type: "onTarget",
    text: "On target!",
    icon: BadgeCheckIcon,
    tooltip: (metric) => `Your ${metric} on this campaign is in line with your goal, so your spend is probably close to optimal. Great work!`
  },
  insufficientData: {
    type: "insufficientData",
    text: "Insufficient Data",
    icon: BanIcon,
    tooltip: () => <>
      <p>
        We don’t have enough data to provide a recommendation on this campaign. There are several possible reasons why this is the case; please see our help documentation for details.
      </p>

      <a href="https://help.rockerbox.com/article/wi5m1awn1v-optimizing-against-target-cpa-or-roas-goals#insufficient_data" target="_blank">Learn more...</a>
    </>
  }
}

const cpaPercentFromGoal = (target, item) => {

  const { spend, totalSpend, recommendedSpend, jotai_goal={} } = item
  const { budget } = jotai_goal
  const threshold = budget ? 100 : 25
  const isLessThan5Percent = (spend/totalSpend) * 100 < 5
  const percentDiff = getRawPercentDiffFromGoal(target, item.cpa) * -1
  const adjustedPercentDiff = percentDiff * 100
  const absoluteAdjustedDiff = Math.abs(adjustedPercentDiff)

  let increaseOrDecrease = ""
  let formattedPercentage = ""
  
  if(recommendedSpend) {
    const percent = (recommendedSpend/spend)

    increaseOrDecrease = isLessThan5Percent ? "insufficientData" : 
    (absoluteAdjustedDiff <= 10) ? "onTarget" :
    recommendedSpend < 0 ? "decrease" :
    "increase"
    formattedPercentage = Math.abs(percent) > 1 ? formatPercent(1) : formatPercent(Math.abs(percent))

  } else {
    increaseOrDecrease = isLessThan5Percent ? "insufficientData" : 
    (absoluteAdjustedDiff <= 10) ? "onTarget" :
    adjustedPercentDiff > 10 ? "increase" :
    "decrease"

    formattedPercentage =  absoluteAdjustedDiff >= 100 ? formatPercent(1) : formatPercent(Math.abs(percentDiff))
  }

  return {formattedPercentage, increaseOrDecrease: goalDiffDict[increaseOrDecrease], absoluteAdjustedDiff, percentDiff }
}

const roasPercentFromGoal = (target, item) => {
  const { spend, totalSpend, recommendedSpend, jotai_goal=[] } = item
  const { budget } = jotai_goal
  const threshold = budget ? 100 : 25
  const isLessThan5Percent = (spend/totalSpend) * 100 < 5
  const percentDiff = getRawPercentDiffFromGoal(target, item.roas)
  const adjustedPercentDiff = percentDiff*100
  const absoluteAdjustedDiff = Math.abs(adjustedPercentDiff)
  let increaseOrDecrease = ""
  let formattedPercentage = ""
  

  if(recommendedSpend) {
    const percent = (recommendedSpend/spend)

    increaseOrDecrease = isLessThan5Percent ? "insufficientData" : 
    (absoluteAdjustedDiff <= 10) ? "onTarget" :
    recommendedSpend < 0 ? "decrease" :
    "increase"

    formattedPercentage = Math.abs(percent) > 1 ? formatPercent(1) : formatPercent(Math.abs(percent))
  } else {
    increaseOrDecrease = isLessThan5Percent ? "insufficientData" :
    (absoluteAdjustedDiff <= 10) ? "onTarget" :
    adjustedPercentDiff > 10 ? "increase" :
    "decrease"

    formattedPercentage = absoluteAdjustedDiff >= 100 ? formatPercent(1) : formatPercent(Math.abs(percentDiff))
  }

    return {formattedPercentage, increaseOrDecrease: goalDiffDict[increaseOrDecrease], absoluteAdjustedDiff, percentDiff }
}

export const metricMapper = {
  cpa: {
    name: "cpa",
    display_name: "CPA",
    format: formatCurrency,
    trendReversed: true,
    percentageFromGoal: cpaPercentFromGoal,
    calculateImpactNumber: (cpa, spend) => spend/cpa
  },
  roas: {
    name: "roas",
    display_name: "ROAS",
    format: numberToTwoDecimals,
    trendReversed: false,
    percentageFromGoal: roasPercentFromGoal,
    calculateImpactNumber: (roas, spend) => roas * spend
  },
}

export const setStyles = (onTarget, delta, trendReversed, diffObj, ignoreInsufficientData=false) => {
  const { type } = diffObj
  const red = '#e00000'
  const green = '#00ad39'
  const gray = '#ebebeb'
  const hasInsufficientData = type =="insufficientData"

  const redOrGreen = onTarget ? 'transparent' : delta > 0 ? green : red
  const inverseRedOrGreen = onTarget ? 'transparent' : delta < 0 ? green : red

  // line chart needs to ignore insufficientData styles in tooltip
  if(ignoreInsufficientData) {
    return {
      backgroundColor: trendReversed ? inverseRedOrGreen : redOrGreen,
      color: onTarget ? 'black' : 'white'
    }
  }

  const bgColor = onTarget ? 'transparent' : 
    hasInsufficientData ? gray :
    delta > 0 ? green : red

  const bgColorInverse = onTarget ? 'transparent' : 
    hasInsufficientData ? gray :
    delta < 0 ? green : red

  const textColor = (onTarget || hasInsufficientData) ? 'black' : 'white'

  const styles = {
    backgroundColor: trendReversed ? bgColorInverse : bgColor,
    color: textColor
  }

  return styles
}
