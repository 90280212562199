import React, { useEffect } from "react";
import { ContentCard } from "@rockerbox/styleguide";
import { Header, Image } from "semantic-ui-react";
import { experimentStore } from "./store";
import { experimentCard } from "@rockerbox/styleguide";
import { CacheContext } from '../../utils/CacheContext.js'
import { getArtifacts } from '../../utils/api'

const selector = state => [
  state.experimentMessage,
  state.getExperimentMessages
];
const InfoCard = props => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { intercom_product_tours, experiments_help_docs } = Context.getCache(`artifacts`, getArtifacts) || {}
  const { experiments_setup } = !!intercom_product_tours ? JSON.parse(intercom_product_tours) : {}
  const { experiments_help_docs_text, experiments_help_docs_link } = !!experiments_help_docs ? JSON.parse(experiments_help_docs) : {}

  const [experimentMessage, getExperimentMessages] = experimentStore(selector);
  useEffect(() => {
    getExperimentMessages();
  }, []);

  const startSetupTour = () => {
    const SETUP_PATH = "/v2/reporting/experiments/create"
    if (window.location.pathname === SETUP_PATH && !!window.Intercom) return window.Intercom('startTour', experiments_setup)
    window.location.href = `${SETUP_PATH}?product_tour_id=${experiments_setup}`
  }

  return (<>
    <ContentCard>
      <Header as="h3" style={{ fontWeight: "bold" }}>
        <Image src={experimentCard} size="mini" centered />

        {experimentMessage.header}
      </Header>
      <div dangerouslySetInnerHTML={{ __html: experimentMessage.text }}></div>
    </ContentCard>
    <div style={{padding: 20}}>
      {!!experiments_setup && 
        <Header
          as='h4'
          icon='play circle'
          color='blue'
          content='Take a tour of how to set up your Experiments'
          className='cursor-pointer'
          onClick={startSetupTour}
        />
      }
      <Header
        as='h4'
        icon='question circle'
        color='purple'
        content='Have questions or feedback? Contact us'
        className='cursor-pointer'
        onClick={() => !!window.Intercom && window.Intercom('showNewMessage', 'Hi! I have questions/feedback about Experiments.')}
      />
      {!!experiments_help_docs_text && !!experiments_help_docs_link && 
        <Header
          as='h4'
          icon='book'
          color='grey'
          content={experiments_help_docs_text}
          className='cursor-pointer'
          onClick={() => window.open(experiments_help_docs_link, '_blank')}
        />
      }
    </div>
  </>);
};

export default InfoCard;
