import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ScheduleReportForm from './component';

function mapStateToProps (state, props) {
  return {
    pixel_source_name: state.app.user.pixel_source_name,
    filter_id: props.match.params.id,
    reportTypes: state.reports.reportTypes,
    outboxes: state.reports.outboxes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    save: (data) => {
      return axios.post("/reporting/saved",data)
    },
    schedule: (data) => {
      return axios.post("/reporting/scheduled",data)
    }
  }
  
}

const ScheduleReportFormWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleReportForm))

export default ScheduleReportFormWrapper;
