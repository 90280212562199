import React, { Component } from 'react';
import moment from 'moment';
import { Menu, Label, Icon, Select, Checkbox, Table, Modal, Button, Form, Header } from 'semantic-ui-react';
import { ContentCard, SplitLayout, BucketsPath } from '@rockerbox/styleguide';
import { FormWrap, FieldWrap, DateModal } from '../stateless';
import CustomerEventsTable from './CustomerEventsTable';
import CustomerHistogram from './CustomerHistogram';
import { getCustomerPath, getConversionData, sendReporting, getTierColors, getUser } from '../../utils/api';
import * as routes from '../../routes';
import { InlineDropdown, DeprecationBanner } from '../stateless';
import { withRouter, Link } from 'react-router-dom';
import momentTimezone from 'moment-timezone';
import { connect } from 'react-redux';
import Container from '../parts/Container';
import { _timeRange, yesterday, week, month } from '../timerange';
import { _pushPath } from '../pushpath';
import SelectConversion from '../SelectSegment';
import { track } from '../../utils/tracking';
import { Currency } from '../../utils/format_helpers';
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";

const NTF_OPTIONS = [
  {
    key: "All Customers",
    text: "All Customers",
    value: 0,
  },
  {
    key: "New Customers",
    text: "New Customers",
    value: 1,
  },
  {
    key: "Repeat Customers",
    text: "Repeat Customers",
    value: 2,
  },
]

const TimestampCell = ({item, col}) => {
  const timezone = momentTimezone.tz.guess();
  const zoneAbbr = momentTimezone.tz.zone(timezone).abbr(item[col.key]*1000);
  const time = moment(item[col.key] * 1000).format(`MMM D, YYYY - h:mm a`);
  return (
    <Table.Cell>{`${time} (${zoneAbbr})`}</Table.Cell>
  )
};

const EmailCell = ({item, col}) => {
  return (
    <Table.Cell style={{maxWidth: 200, wordWrap: 'break-word'}} className='data-pii'>
      {item[col.key]}
    </Table.Cell>
  )
}

const PathCell = ({item, col}) => {
  let newArr = [];
  item.path_deduplicated.map(path => {
    newArr.push({buckets: [path]})
  })
  return (
    <Table.Cell>
      <BucketsPath steps={newArr} colorMap={col.colorMap} count="200" condensed/>
    </Table.Cell>
  )
}

const RevenueCell = ({item, col}) => {
  return (
    <Table.Cell>
      <Currency amount={item[col.key]}/>
    </Table.Cell>
  )
}

const InspectCell = ({item, col, linkBase}) => {
  let newArr = [];
  item.path_deduplicated.map(path => {
    newArr.push({buckets: [path]})
  })
  return (
    <Table.Cell>
      <Button as={Link} icon='eye' to={`${linkBase}/${item[col.key]}`} />
    </Table.Cell>
  )
}

const NewToFileCell = ({item, col}) => (
  item[col.key] === 1
    ? <Table.Cell style={{textAlign: 'center'}}><Icon name='checkmark'/></Table.Cell>
    : <Table.Cell></Table.Cell>
)

const SendStatus = (props) => (
  <React.Fragment>
    { props.success ?
      <Container center column>
        <h5>Your report is now processing! You should receive an email to download shortly.</h5>
        <Container>
          <Button onClick={() => props.toggleModal(false)}>Done</Button>
        </Container>
      </Container> :
      <Container center column>
        <h5>This is awkward, but there was an error sending your report.</h5>
        <Container>
          <Button onClick={props.handleReset}>Try Again</Button>
          <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
        </Container>
      </Container>
    }
  </React.Fragment>
);

class CustomersTableWrapper extends _pushPath(_timeRange(Component)) {
  state = {
    customerData: [],
    filteredData: [],
    tiers: [],
    resolved: false,
    modal: false,
    cols: [],
    modalOpen: false,
    email: '',
    fileType: 'xlsx',
    success: undefined,
    tierColors: false,
    hasNtf: false,
    start_date: moment(this.props.match.params.startDate),
    end_date: moment(this.props.match.params.endDate),
    focusedInput: null,
    username: ""
  };

  componentDidMount() {
    this.pushToHistory()
    const { id, startDate, endDate, viewType } = this.props.match.params;
    if (id && startDate && endDate) {
      this.getData(startDate, endDate);
    }
    getTierColors().then(tierColors =>
      this.setState({ tierColors })
    )

    getUser()
    .then(user => this.setState({email: user.username, username: user.username}))

    track('view.converters.'+(viewType || 'customers'))
  }

  pushToHistory(sdate, edate, ntf, t1, vtype) {
    const { viewType, id, startDate, endDate, ntfFilter, tier1 } = this.props.match.params;
    const view_type = vtype || viewType || "customers";
    const start_date = sdate || startDate || yesterday;
    const end_date = edate || endDate || yesterday;
    const ntf_filter = ntf || ntfFilter || '0'; // set 0 as a string to prevent infinite loop
    const tier_one = typeof t1 === 'string' ? t1 : tier1 || undefined;
    if(id) {
      this.updatePath({viewType: view_type, id, startDate: start_date, endDate: end_date, ntfFilter: ntf_filter, tier1: tier_one})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected} = this.props;
    const { id, ntfFilter, tier1, startDate, endDate } = this.props.match.params;
    const { customerData } = this.state;
    const ntf = prevProps.match.params.ntfFilter !== ntfFilter;
    const tierOne = prevProps.match.params.tier1 !== tier1;
    const start = prevProps.match.params.startDate !== startDate;
    const end = prevProps.match.params.endDate !== endDate;
    const idVal = prevProps.match.params.id !== id;

    if (prevState.customerData !== this.state.customerData) {
      this.setState({customerData: this.state.customerData, filteredData: this.state.customerData});
    }

    const undefinedParams = ["id", "startDate", "endDate", "ntfFilter"].filter(x => this.props.match.params[x] === undefined)

    if (undefinedParams.length > 0) {
      return this.pushToHistory()
    }
    if (idVal || start || end) {
      this.setState({start_date: moment(startDate), end_date: moment(endDate)})
      return this.getData(startDate, endDate);
    }
    if (ntf || tierOne) {
      return this.filterData()
    }

    const prevViewType = prevProps.match.params.viewType
    const { viewType } = this.props.match.params
    if (viewType != prevViewType) track('view.converters.'+viewType)
  }

  getData = (startDate, endDate) => {
    const { id, ntfFilter } = this.props.match.params;
    this.setState({resolved: false});

    getConversionData(id, startDate, endDate)
      .then(data => {
        // account for edge case where date selection has changed on the frontend
        // since the API call was first made
        const currStartSelection = this.props.match.params.startDate
        const currEndSelection = this.props.match.params.endDate
        if (currStartSelection != startDate || currEndSelection != endDate) {
          console.log("data for", startDate, "to", endDate, "doesn't match current selection of", currStartSelection, "to", currEndSelection)
          return
        }

        let cols = [
          { display: 'New Customer?', key: 'new_to_file', as: NewToFileCell},
          { display: 'Customer ID', key: 'customer_id' },
          { display: 'Email', key: 'email', as: EmailCell},
          { display: 'Conversion Hash ID', key: 'conversion_hash_id' },
          { display: 'Order ID', key: 'orderId'},
          { display: 'Converted On', key: 'timestamp_conv', as: TimestampCell},
          { display: 'Revenue', key: 'revenue', as: RevenueCell },
          { display: 'Path to Conversion', key: 'path_deduplicated', as: PathCell },
          { display: 'Inspect', key: 'base_id', as: InspectCell }
        ];

        if (data === undefined || Object.keys(data).length == 0) {
          this.setState({resolved: true, tiers: []})
          return
        }

        const tiers = {};
        data.map(c => {
          c.hashed_path = c.path_deduplicated.join('|')
          c.path_deduplicated.map(item => {
            tiers[item] = 1
          })
        });

        const tiersOptions = Object.keys(tiers).map(t => {
          return {value: t, text: t}
        })

        if(data.length > 0) {
          const allKeys = Object.keys(data[0]);
          cols = cols.filter(c => allKeys.includes(c.key));
        }

        const hasNtf = !!cols.find(({ key }) => key == "new_to_file")

        this.setState({customerData: data, resolved: true, tiers: tiersOptions, cols, hasNtf}, () => this.filterData())
      })
  }

  filterData = () => {
    const { customerData } = this.state;
    const { tier1, ntfFilter } = this.props.match.params;
    const bucket = tier1 == undefined || tier1 == '';
    let filteredData = customerData;

    if (!bucket) {
      filteredData = filteredData
        .filter(row => {
          return row.path_deduplicated.includes(tier1);
        })
    }

    // new customers
    if (ntfFilter == 1) {
      filteredData = filteredData.filter(row => row.new_to_file === 1)
    }
    // repeat custoemrs
    if (ntfFilter == 2) {
      filteredData = filteredData.filter(row => row.new_to_file === 0)
    }

    this.setState({filteredData})
  }

  initializeTiers = (data) => {
    const tiers = data.reduce((p,c) => {
      c.path.map(item => {return p[item] = 1})
      return p
    },{})

    const tiersOptions = Object.keys(tiers).map(t => {
      return {value: t, text: t}
    })

    return tiersOptions
  }

  handleSelect = (e, {value}) => {
    this.pushToHistory(false, false, false, value)
  }

  handleViewTypeSelect = (e, {value}) => {
    this.pushToHistory(false, false, false, false, value)
  }

  handleNtfChange = (e, {value}) => {
    const ntf_filter = value || '0'
    this.pushToHistory(false, false, ntf_filter)
  }

  handleChange = (e, stateSlice) => {
    this.setState({[stateSlice]: moment(e).format('YYYY-MM-DD')});
  }

  toggleModal = (val) => {
    this.setState({modalOpen: val})
    if(!val) {
      this.handleReset();
    }
  }

  handleTextChange = (value) => {
    this.setState({email: value})
  }

  handleReportSubmit = (evt) => {
    const { exportType, send, advertiser, filter_id } = this.props;
    const { id } = this.props.match.params;
    const { email, tiers, fileType, start_date, end_date, username } = this.state;
    // const { startDate, endDate, id } = this.props.match.params;

    const obj = {
      report_type_id: 33, // conversion report id
      destination: email,
      end_date: end_date.format('YYYY-MM-DD'),
      start_date: start_date.format('YYYY-MM-DD'),
      file_type: fileType,
      filter_id: id,
      advertiser,
    }

    sendReporting(obj).then(x => {
      this.setState({success: true, email: username, fileType: 'xlsx'})
    }).catch(x => {
      this.setState({success: false})
    })

    // track in rockerbox & intercom
    const trackObj = {
      // type: exportType,
      conversion_id: filter_id,
      end_date: end_date.format('YYYY-MM-DD'),
      start_date: start_date.format('YYYY-MM-DD'),
      pixel_source_name: advertiser,
      file_type: fileType
    }
    track('download-report', trackObj);

    evt.preventDefault()
  }

  handleFileSelect = (val) => {
    this.setState({fileType: val});
  }

  handleReset = () => {
    const { username } = this.state;

    this.setState({success: undefined, email: username, fileType: 'xlsx'})
  }

  handleOutsideRange = (day) => {
    const { start_date, end_date } = this.state;
    const maxDays = 120;
    const yesterday = moment.utc().utcOffset(-5)
      .subtract(1, "days")
      .endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(end_date.format());
    const startDateCopy = moment(start_date.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return beforeMin || afterMax || afterSixty;
  };

  onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    if (newStartDate) {
      this.setState({start_date: newStartDate})
    }
    if (newEndDate) {
      this.setState({end_date: newEndDate})
    }
  };

  validate = () => {
    let success = true;
    const {
      email
    } = this.state;

    const validate_email = address => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(address);
    };

    email.split(',').forEach(e => {
      e = e.trim();
      if (!validate_email(e)) success = false;
    });

    return success;
  };

  render() {
    const { segments, artifacts } = this.props;
    const { tiers, filteredData, modal, cols, success, resolved, tierColors, hasNtf, start_date, end_date, focusedInput, email } = this.state;
    const { viewType, id, tier1, ntfFilter } = this.props.match.params;

    const selectedNtfOption = (NTF_OPTIONS.find(x => x.value == ntfFilter) || {})

    const isPinterestSegment = !!segments.find(({ action_id, action_name }) => action_id == id && action_name.includes("Pinterest"))

    return (
      <React.Fragment>
        <DeprecationBanner viewName='Converters' newViewName='Orders & Conversions' newViewPath='/v3/data/customers/conversions/main' />
        <ContentCard disableHeaderEllipse noContent
          title={<React.Fragment>
            Conversion:{" "}
            <SelectConversion
              endpoint={id => `${routes.customerTablesView}/customers/${id}`}
              {...this.props}
              as={InlineDropdown}
            />
          </React.Fragment>}
          topRight={this.renderDateSelector()}
        />
        {/* HACK TO HIDE PINTEREST CONVERSIONS */}
        {isPinterestSegment
        ? <ContentCard.EmptyView
            fallBackMsg="Data Unavailable"
            description={artifacts['pinterest_data_hidden']}
          />
        :
        <SplitLayout
          leftWidth={3}
          leftContent={
            <ContentCard title='Filter' borderless collapsing>
              <FormWrap>
                <FieldWrap>
                  <label>Show Customers that Engaged with</label>
                    <Select
                      fluid
                      clearable
                      value={!!tier1 ? tier1 : ''}
                      placeholder={"Bucket 1"}
                      onChange={this.handleSelect}
                      options={this.state.tiers}
                    />
                </FieldWrap>
                {hasNtf &&
                  <FieldWrap className='m-0'>
                    <label>Customer Type</label>
                    <Select
                      fluid
                      text={selectedNtfOption.text}
                      onChange={this.handleNtfChange}
                      options={NTF_OPTIONS} />
                  </FieldWrap>
                }
              </FormWrap>
            </ContentCard>
          }
          rightWidth={13}
          rightContent={
            <React.Fragment>
            <ContentCard
              borderless
              disableHeaderEllipse
              title={
                <React.Fragment>
                  <InlineDropdown
                    value={viewType}
                    options={[
                      {"value":"customers","text":"Matching Customers"},
                      {"value":"histogram","text":"Customer Distribution"}
                    ]}
                    onChange={this.handleViewTypeSelect}
                  />
                </React.Fragment>
              }
              hasTable
              topRight={<Menu text size="small"><Menu.Item onClick={() => this.toggleModal(true)}><Icon name='download'/>Export</Menu.Item></Menu>}
            >
              { this.renderDateModal() }
            </ContentCard>
            { viewType == "customers" && <CustomerEventsTable
                id={id}
                filteredData={filteredData}
                cols={cols}
                resolved={resolved}
                linkBase={routes.customerJourney + "/" + id}
                tierColors={tierColors}
              />
            }
            { viewType == "histogram" && <CustomerHistogram
                id={id}
                filteredData={filteredData}
                cols={cols}
                resolved={resolved}
              />
            }
            </React.Fragment>
          }
        />
        }
        <Modal size="small" open={this.state.modalOpen} onClose={() => this.toggleModal(false)}>
          <Modal.Content>
            <Modal.Description>
              {success == undefined && <Form onSubmit={this.handleReportSubmit}>
                <Form.Input label="Email address:" value={email} onChange={(e,d) => this.handleTextChange(d.value)}/>
                <Form.Group key={"dates"}>
                <Form.Field>
                  <label>{"Date Range"}</label>
                  <DateRangePicker
                    onDatesChange={this.onDatesChange}
                    onFocusChange={data => this.setState({focusedInput: data})}
                    focusedInput={focusedInput}
                    startDate={start_date}
                    endDate={end_date}
                    noBorder={true}
                    isOutsideRange={d => this.handleOutsideRange(d)}
                    hideKeyboardShortcutsPanel
                    startDateId="deliveryStartDate"
                    endDateId="deliveryEndDate"
                    minimumNights={0}
                  />
                </Form.Field>
              </Form.Group>
                <Form.Select options={[{text: 'xlsx', value: 'xlsx'}, {text: 'tsv', value: 'tsv'}]} value={this.state.fileType} onChange={(e,d) => this.handleFileSelect(d.value)} label="File type:"/>
                <Button content="Send Report" type="submit" disabled={!this.validate()}/>
              </Form>}
              {success !== undefined && (
                <SendStatus success={success} toggleModal={this.toggleModal} handleReset={this.handleReset}/>
              )}
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    advertiser: state.app.user.pixel_source_name,
    segments: state.segment.segments,
    artifacts: state.app.artifacts,
  };
}

export default connect(mapStateToProps, null)(withRouter(CustomersTableWrapper));
