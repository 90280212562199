import React from "react";
import { Table } from "semantic-ui-react";
import { PagedIndexGrid, ContentCard } from "@rockerbox/styleguide";

const AppliedSchemaTable = (props) => {
  const { schemas, appliedData } = props;
  const cols = schemas.map((schema) => ({
    key: schema.name,
    display: schema.name,
  }));

  return (
    <PagedIndexGrid
      cols={cols}
      data={appliedData}
      as={ContentCard.Table}
      itemsPerPage={10}
      fallBackMsg="Click to run report."
      collapsed
      idKey="id"
    />
  );
};

export default AppliedSchemaTable;
