export const formatDimensions = (dimensionsArray) => {
  const groupByMetadata = []

  dimensionsArray.map((tier) => {
      groupByMetadata.push(`${tier[0].toUpperCase()}${tier.slice(1)}`)
  })

  const dimensionsString = groupByMetadata.join(", ")
  return dimensionsString;
}
    
export const formatReportSize = (reportSize) => {
  let formattedReportSize;
  if(reportSize === 0) {
    formattedReportSize = "0 MB"
    return formattedReportSize
  }

  if(reportSize < 1000000) {
    formattedReportSize = `${(reportSize / 1000).toFixed(1)} KB`
  } else {
    formattedReportSize = `${(reportSize / 1000000).toFixed(1)} MB`
  }

  return formattedReportSize
}