import React from 'react'
import styled from 'styled-components'

export const LineWrap = ({ children }) => (
  <svg className='line-wrap'>
    {children}
  </svg>
)

export const Line = ({ x1, y1, x2, y2, startColor, endColor, opacity, width, animation, type }) => {
  if (!x1 || !y1 || !x2 || !y2) return null

  const [length, setLength] = React.useState(false)
  const getLength = (element) => {
    if (!element) return null
    setLength(element.getTotalLength())
  }

  const gradientCoordinates = React.useMemo(() => {
    if (type == 'vertical') return { x1, y1, x2: x1, y2 }
    if (x2 > x1) return { x1, y1, x2, y2: y1 }
    return { x1, y1, x2: x1, y2 }
  }, [x1, y1, x2, y2])

  const lineId = `line-${Math.round(x1)}-${Math.round(y1)}-${Math.round(x2)}-${Math.round(y2)}`

  const startPoint = `${x1} ${y1}`
  const endPoint = `${x2} ${y2}`

  const horizontalMidpoint = (x1+x2)/2
  const verticalMidpoint = (y1+y2)/2

  const generateCurveHandles = (type) => {
    switch(type) {
      case 'vertical':
        return [`${x1} ${verticalMidpoint}`, `${x2} ${verticalMidpoint}`]
      case 'horizontal':
        return [`${horizontalMidpoint} ${y1}`, `${horizontalMidpoint} ${y2}`]
      default:
        return [`${horizontalMidpoint} ${y1}`, `${x2} ${verticalMidpoint}`]
    }
  }
  const [startPointCurveHandle, endPointCurveHandle] = generateCurveHandles(type)

  const { speed, numDots, dotWidth, dotOpacity, dotColor, fade } = animation || {}
  const animated = !!speed && !!numDots

  return (<>
    <defs>
      <linearGradient id={lineId} gradientUnits="userSpaceOnUse" {...gradientCoordinates}>
        <stop offset="0%" stopColor={startColor || "#F3F3F5"} stopOpacity={`${opacity || 100}%`} />
        <stop offset="100%" stopColor={endColor || "#66D9AE"} stopOpacity={`${opacity || 100}%`} />
      </linearGradient>
      <linearGradient id={`${lineId}-dots`} gradientUnits="userSpaceOnUse" {...gradientCoordinates}>
        <stop offset="0%" stopColor={dotColor || startColor || "#F3F3F5"} stopOpacity={`${dotOpacity || 100}%`} />
        <stop offset="100%" stopColor={dotColor || endColor || "#66D9AE"} stopOpacity={`${dotOpacity || 100}%`} />
      </linearGradient>
    </defs>
    <path
      ref={getLength}
      d={`M ${startPoint} C ${startPointCurveHandle}, ${endPointCurveHandle}, ${endPoint}`}
      stroke={`url(#${lineId})`}
      strokeWidth={width || 10}
      fill="transparent"
    />
    {!!animated && !!length &&
      <AnimatedPathStyle
        {...{lineId, length, speed, fade, dotWidth}}
        d={`M ${startPoint} C ${startPointCurveHandle}, ${endPointCurveHandle}, ${endPoint}`}
        stroke={`url(#${lineId}-dots)`}
        strokeWidth={dotWidth || 5}
        strokeDasharray={`0 ${length/numDots}`}
        strokeLinecap="round"
        fill="transparent"
      />
    }
  </>)
}

const AnimatedPathStyle = styled.path`
  animation: path-animation-${props => props.lineId} ${props => props.speed}s infinite linear;

  @keyframes path-animation-${props => props.lineId} {
    0% {
      stroke-dashoffset: 0;
      opacity: ${props => !!props.fade ? 0 : 1};
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -${props => props.length};
      opacity: ${props => !!props.fade ? 0 : 1};
    }
  }
`
