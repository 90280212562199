 const COLORS = [
  '#4f64de',
  '#47aadc',
  '#60e1d0',
  '#3eda7f',
  '#ffa64d',
  '#dc4749',
  '#b847dc',
  '#6847dc',
  '#477cdc',
  '#47d0dc',
  '#36f29b',
  '#f0df4d',
  '#de5f31',
  '#dc47cb',
  '#9247dc',
]

export const generateColors = (numColors) => {
  return [...Array(numColors)].map((_,i) => COLORS[i % COLORS.length])
}

export const formatPlatformName = (platform) => {
  return platform
    .replace('_v1', '')
    .replace('_v2', '')
    .replace('_v3', '')
    .replace('_uac', '')
    .replace('_batch', '')
    .replace('_spend', '')
    .replace('_standard', '')
    .replace('_custom', '')
    .replace('_site_served', '')
    .replace('_dcm', '')
    .replace('_dv360', '')
    .replace('-dsp', '')
    .replace('_dsp', '')
    .replace('_dfp', '')
    .replace('-core', '')
    .replace('-custom', '')
    .replace('-national', '')
    .replace('_postlog', '_linear_tv')
    .replace('_linear_linear_', '_linear_')
    .replace('entity', 'sponsorships')
}
