import React, { Component } from 'react';
import { SplitLayout, ContentCard, IndexGrid, PagedIndexGrid, SortableIndexGrid} from '@rockerbox/styleguide'
import { Label, Icon, Menu, Input, Button, Table, Popup } from 'semantic-ui-react'
import { checkUrl, getSavedBucketUrls } from '../../utils/api';
import _ from 'lodash';

const MatchedCell = ({item, col}) => {
  return (
    <td>
      <Button size='mini' compact circular style={{overflow:"hidden",textOverflow: "ellipsis", whiteSpace: "nowrap", width:"120px"}} >
        { item[col.key] }
      </Button>
    </td>
  )
}

const MatchedHoverCell = ({item, col}) => {
  return (
    <td>
      <Popup
        trigger={ <span>{item[col.key].length} <Icon name='eye' /></span> }
        header="Rules Matched"
        content={ item[col.key].map(row => <div>{ row }</div>) }
      />
    </td>
  )
}

class ExampleEvents extends Component {

  state = {
    exampleEvents: [],
    processedEvents: [],
    unmatchedEvents: [],
    nomatchedEvents: [],
    matchedEvents: [],
    partialMatchedEvents: [],
    activeItem: 'matched events'
  }

  handleClick = (e, { name }) => this.setState({ activeItem: name })

  urlFromLocalStorage = (urlId, cb) => {
    getSavedBucketUrls(urlId)
      .then(exampleEvents => this.setState({ exampleEvents }, cb) )
  }

  getProcessedEvents = (data) => {
    const { events, attributable_events } = data;
    const lastMapper = attributable_events.slice(-1)[0];

    if (events.length && attributable_events.length) checkUrl(data)
      .then(processedEvents => {
        processedEvents.map(row => row.match = (row.matches||[""]).slice(-1)[0])

        const hasMatch = processedEvents.filter(({matches}) => matches.length > 0)
        const noMatch = processedEvents.filter(({matches}) => matches.length == 0)

        const matchedEvents = hasMatch.filter(({match}) => match == lastMapper['name'])
        const partialMatchedEvents = hasMatch.filter(({matches}) => matches.indexOf(lastMapper['name']) > -1)
        const unmatchedEvents = hasMatch.filter(({matches}) => matches.indexOf(lastMapper['name']) == -1)
        const nomatchedEvents = noMatch
        this.setState({ processedEvents, matchedEvents, partialMatchedEvents, unmatchedEvents, nomatchedEvents })
      })
  }

  componentDidMount() {
    const { urlId, attributable_events } = this.props
    if ( urlId && urlId != 0 ) this.urlFromLocalStorage(urlId, () => {
      this.componentDidUpdate({})
    })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.state) != JSON.stringify(this.props.state)) {
      const attributable_events = this.props.attributable_events.filter(event => event.id !== this.props.state.id);
      const mapping = _.cloneDeep(this.props.state)

      if (!!mapping['group']) {
        mapping['id'] = mapping['id'] || mapping['name'] + "TEMP"
        mapping['pixel_source_name'] = mapping['pixel_source_name'] || "TEMP"
        mapping['group'].map((g,i) => {
          g['id'] = mapping['name'] + i;
          g.logic.map((l,j) => { l['id'] = mapping['name'] + j })
        })
        attributable_events.push(mapping);

        const { exampleEvents } = this.state;
        const data = { events: exampleEvents, attributable_events };

        this.getProcessedEvents(data)
      }
    }
  }

  render() {

    if (!this.props.urlId || this.props.urlId == 0) return null

    const { exampleEvents, matchedEvents, partialMatchedEvents, unmatchedEvents, nomatchedEvents, processedEvents, activeItem } = this.state;

    const dataCols = exampleEvents.length ? [
      { display: "Url", key: "original_url", as: IndexGrid.EllipsedCell, headerWidth: 7},
      { display: "Referrer", key: "request_referrer", as: IndexGrid.EllipsedCell, headerWidth: 7},
      { display: "Prio. Match", key: "match", as: MatchedCell, headerWidth: 1},
      { display: "All Matches", key: "matches", as: MatchedHoverCell, headerWidth: 1},
    ] : []


    return (
      <React.Fragment>
        <ContentCard title={`Bucketing Rule Examples (${processedEvents.length})`} borderless noContent />
        <SplitLayout
          leftWidth={3}
          rightWidth={13}
          leftContent={
            <Menu pointing vertical style={{"width":"100%"}}>
              <Menu.Item name='matched events' active={activeItem === 'matched events'} onClick={this.handleClick}>
                <Label>{ matchedEvents.length }</Label>
                <span><Icon name='sitemap' /> True Match </span>
              </Menu.Item>
              <Menu.Item name='partial match' active={activeItem === 'partial match'} onClick={this.handleClick}>
                <Label>{ partialMatchedEvents.length }</Label>
                <span><Icon name='clone outline' /> Unprioritized Match</span>
              </Menu.Item>
              <Menu.Item name='unmatched events' active={activeItem === 'unmatched events'} onClick={this.handleClick}>
                <Label>{ unmatchedEvents.length }</Label>
                <span><Icon name='question circle outline' /> Unmatched </span>
              </Menu.Item>
              <Menu.Item name='nomatched events' active={activeItem === 'nomatched events'} onClick={this.handleClick}>
                <Label>{ nomatchedEvents.length }</Label>
                <span><Icon name='warning sign' /> No matches </span>
              </Menu.Item>
            </Menu>
          }
          rightContent={
            <React.Fragment>
              {
                activeItem == "matched events" &&
                <ContentCard title={"Matched Events"} hasTable >
                  <PagedIndexGrid as={ContentCard.Table} fallBackMsg="No example events" cols={dataCols}
                    data={matchedEvents}
                    itemsPerPage={10}
                    singleLine
                  />
                </ContentCard>
              }
              {
                activeItem == "partial match" &&
                <ContentCard title={"Partially Matched Events"} hasTable >
                  <PagedIndexGrid as={ContentCard.Table} fallBackMsg="No example events" cols={dataCols}
                    data={partialMatchedEvents}
                    itemsPerPage={10}
                    singleLine
                  />
                </ContentCard>
              }
              {
                activeItem == "unmatched events" &&
                <ContentCard title={"Unmatched Events"} hasTable >
                  <PagedIndexGrid as={ContentCard.Table} fallBackMsg="No example events" cols={dataCols}
                    data={unmatchedEvents}
                    itemsPerPage={10}
                    singleLine
                  />
                </ContentCard>
              }
              {
                activeItem == "nomatched events" &&
                <ContentCard title={"No matched Events"} hasTable >
                  <PagedIndexGrid as={ContentCard.Table} fallBackMsg="No example events" cols={dataCols}
                    data={nomatchedEvents}
                    itemsPerPage={10}
                    singleLine
                  />
                </ContentCard>
              }
            </React.Fragment>
          } />
      </React.Fragment>
    )
  }
}
export default ExampleEvents;
