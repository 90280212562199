import React, { Component } from 'react';
import shallow from 'zustand/shallow'
import { Segment, Form, Message, Popup, Icon, Button } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { getDataset } from '../../utils/api';
import { postEntityData, updateEntityData } from '../../utils/api';
import { entityStore } from './entityStore';
import SelectConversion from '../SelectSegment';
import FindMarketingEvent from './FindMarketingEvent';

export const FormDropdown = (props) => <Form.Dropdown {...props} select label={props.label} selection/>

const DEFAULT_CREATE = {
  display: [{}],
  segments: [{segment_id: "", promo_field: ""}],
  events: [],
}

const states = [
  {
    key: "Approved",
    content: "This will create a new entity and promo-code",
    icon: "check circle",
    color: "green"
  },
  {
    key: "Same Name",
    content: "This will add a new promo code to the existing entity",
    icon: "warning circle",
    color: "green"
  },
  {
    key: "Same Tier",
    content: "This exact tier structure is associated with another entity",
    icon: "warning sign",
    color: "orange"
  },
  {
    key: "Missing Tier",
    content: "You must include a tier structure you want to associated with this entity",
    icon: "warning sign",
    color: "red"
  },
  {
    key: "Missing Name",
    content: "You must enter a name to create a new entry",
    icon: "warning sign",
    color: "red"
  },
]


export const COLUMNS = [
  { 
    style: {width:"1.9em"},
    display: "", key: "state", type: "text", 
    asEdit: ({ value }) => <>{ value}</>, 
    asView: DataTable.StatusCell({ states })
  },
  { display: "Name", key: "name", type: "text" },
  { display: "Tier 1", key: "tier_1", type: "text" },
  { display: "Tier 2", key: "tier_2", type: "text" },
  { display: "Tier 3", key: "tier_3", type: "text" },
  { display: "Tier 4", key: "tier_4", type: "text" },
  { display: "Tier 5", key: "tier_5", type: "text" },
]

const READ_ONLY_DISPLAY = [
  "tier_1",
  "tier_2",
  "tier_3",
  "tier_4",
  "tier_5",
]

const makeReadOnly = (readOnlyColumns) => (row) => {
  const readOnly = readOnlyColumns.includes(row.key)
  return Object.assign({ readOnly }, row)
}

const CreateViaEvent = ({ program, entityData, onReset}) => {
  
  const { tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent } = program||{}
  const [data, setData] = React.useState([])
  const [segment_id, setSegment] = React.useState(false)
  const [totalCount, setTotalCount] = React.useState(false)
  const [currentCount, setCurrentCount] = React.useState(false)
  const [promo_field, setEventField] = React.useState("coupon")
  const [loading, setLoading] = React.useState(undefined)


  const [showAddType, setShowAddType] = React.useState(undefined)

  const [ featuredSegmentId, segments, segmentOptions, getSegments ] = entityStore(
    ({ featuredSegmentId, segments, segmentOptions, getSegments, }) => [ 
      featuredSegmentId, 
      segments, 
      segmentOptions, 
      getSegments
    ], 
    shallow
  )

  const hasDisplay =  !!(tier_1 || tier_2 || tier_3 || tier_4 || tier_5)
  const hasCosts = !!(fixed_unit_cost || rev_share_percent)
  const display = { tier_1, tier_2, tier_3, tier_4, tier_5 }
  const costs = { fixed_unit_cost, rev_share_percent }

  const _columns = React.useMemo(() => {
    const options = entityData.map(({ name }) => Object.assign({ name, text: name, value: name }))
    const columns = COLUMNS
    const entityColumn = columns.find(row => row.key == "name")
    entityColumn.asEdit = DataTable.SearchCell(options)

    return columns
  }, [entityData])


  React.useEffect(() => { getSegments() }, [])
  React.useEffect(() => { 
    const hasEntityData = (!!entityData && entityData.length > 0) 
    const _segmentId = hasEntityData ? entityData[0].segments[0].segment_id : 
      featuredSegmentId ? featuredSegmentId : 
      null;

    !segment_id && _segmentId ? setSegment(_segmentId) : null 
  }, [featuredSegmentId])
  React.useEffect(() => { (loading === false) ? onReset() : false }, [loading])


  const { existingTier, existingName } = React.useMemo(() => {
    const existingTier = entityData.reduce((p,c) => {
      c.promo_codes.map(code => {
        p[code.conversion_field_value] = c
      })
      return p
    }, {})
    const existingName = d3.nest()
      .key(row => row.name)
      .map(entityData)
    return { existingTier, existingName }
  }, [entityData])


  const onUpdate = (d) => {
    d.map(row => {
      const sameName = !!existingName[row.name]
      const sameTier = !!existingTier[row.conversion_field_value]
      row.state = sameTier ? "Same Tier" :
        sameName ? "Same Name" :  
        !row.tier_1 ? "Missing Tier" :
        !row.name ? "Missing Name" : "Approved"
    })
    setData(d)
  }

  const addMarketingRow = (row) => {
    const tiers = [...row.parents, row.key]
    const TIERS = ["tier_1","tier_2","tier_3","tier_4","tier_5"]

    const newData = TIERS
      .reduce((p, tier, i) => Object.assign(p, {[tier]: tiers[i]}), {state: "Missing Name"})
    
    const filtered = data.filter(row => !TIERS.reduce((p,c) => p && (row[c] == newData[c]), true))

    setShowAddType(undefined)

    if (filtered.length == data.length) setData(data.concat([newData]))
  }
  const onRemove = (row) => setData(data.filter(r => r != row))
  const addRow = () => setData(data.concat([{}]))
  const onSave = () => {
    setLoading(true)

    const newEntities = data.filter(row => row.state == "Approved")

    const newToPost = newEntities.reduce((p,c) => {

      const eventTiers = Object.fromEntries(READ_ONLY_DISPLAY.map(k => [k, c[k]]))

      p[c.name] = p[c.name] || _.cloneDeep(DEFAULT_CREATE)
      p[c.name]['name'] = c.name
      p[c.name]['entity_type'] = program.entity_type
      p[c.name]['events'].push(eventTiers)


      const display = p[c.name].display[0];
      const segments = p[c.name].segments[0];

      segments.segment_id = segment_id;

      READ_ONLY_DISPLAY.map(tier => {
        display[tier] = program[tier]
      })
      
      return p
    },{})


    const existingEntites = data.filter(row => row.state == "Same Name")
    const toUpdate = existingEntites.reduce((p,c) => {
      const eventTiers = Object.fromEntries(READ_ONLY_DISPLAY.map(k => [k, c[k]]))
      const { name } = c;

      p[name] = p[name] || _.cloneDeep(existingName[name])[0];

      p[c.name]['events'].push(eventTiers)

      return p
    },{})


    const updatePromises = Object.entries(toUpdate).map(([k, obj])=> {
      const { id } = obj
      const send = updateEntityData;
      return send(obj, id)
    })

    const createPromises = Object.entries(newToPost).map(([k, obj])=> {
      const send = postEntityData;
      return send(obj)
    })

    Promise.all([...updatePromises, ...createPromises])
      .then(() => onReset())

  }

  const removeErrors = () => {
    const valid = data.filter(row => (row.state == "Approved") || (row.state == "Same Name"))
    setData(valid)
  }

  const toRemove = data.filter(row => (row.state != "Approved") && (row.state != "Same Name"))

  const readOnlyColumns = READ_ONLY_DISPLAY;
  const skipColumns = program && !hasCosts ? ["entity_type"] : []

  const columns = program ? _columns
    .filter(row => !skipColumns.includes(row.key))
    .map(makeReadOnly(readOnlyColumns)) : _columns
  
  return <ContentCard title="Create Multiple Sponsorships via Marketing Events">
    <ColumnLayout
      leftWidth={12}
      rightWidth={4}
      leftContent={<Form>
        <FormDropdown required onChange={(x, {value}) => setSegment(value) } as={FormDropdown} options={segmentOptions} value={segment_id} label="Segment" />
        <br />
      </Form>
      }
      rightContent={ <Message header="Associate a segment" content="Choose a conversion segment to associate with this sponsorship" />}
    />
    <Segment {...{loading}} >
      { toRemove.length > 0 && <Message content={<>
          The records below either have insufficent data or are already associated with other sponsorships. Either remove or correct the errors before submitting.
          <Button style={{marginLeft:"5px"}} color="orange" compact size="tiny" onClick={removeErrors}>Remove Errors</Button>
        </>} /> 
      }
      
      { data.length > 0  && <DataTable {...{ columns, data, onUpdate, onRemove } } />}
      { !showAddType && <div style={{textAlign:"center",marginTop:"15px"}}>
            <Button basic size='huge' content="Find Marketing Event to Associate" icon="search" onClick={() => setShowAddType("find")} color="blue" />
          </div>
      }
      <br />
      <br />
      { showAddType == "find" && <FindMarketingEvent segment={{segment_id}} onChange={(evt, { value }) => addMarketingRow(value) } /> }
      { totalCount && <>Saving {totalCount-currentCount}/{totalCount}...</> }
      <br />
      <Button disabled={loading || data.length == 0 || !segment_id} loading={loading} floated="right" color="green" onClick={onSave} submit>
        Add Entities & Event Codes
      </Button>
      <br />
      <br />
    </Segment>
  </ContentCard>
}

export default CreateViaEvent;
