import React, { Component } from 'react';
import { Input, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';
import { useKeyPress } from '../../hooks';

const SearchField = ({ placeholder="Find your marketing event", onChange }) => {

  const [filter, setFilter] = React.useState(false)
  const [proposedFilter, setProposedFilter] = React.useState(false)
  const [shouldSearch, setShouldSearch] = React.useState(0)
  const submit = useKeyPress("Enter")

  React.useEffect(() => { setFilter(proposedFilter) }, [shouldSearch, submit]) 
  React.useEffect(() => { if (filter !== false) onChange(false, { value: filter }) }, [filter])

  return <div style={{display:"flex"}}>
    <div style={{flex:7}}>
      <Input fluid {...{placeholder}} onChange={(_, {value}) => setProposedFilter(value)} />
    </div>
    <div style={{flex:1}}>
      <Button color={"green"} 
        disabled={filter == proposedFilter} 
        onClick={(_, {value}) => setShouldSearch(shouldSearch + 1)} 
        content={proposedFilter || proposedFilter == filter? "Search" : "Reset"} 
        as='a' 
      />
    </div>
  </div>

}

export default SearchField;
