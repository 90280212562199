import React from 'react'
import { Ref, Grid, Header, Segment, Icon, Image, Popup } from 'semantic-ui-react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import KanbanCard from './Card';

const KanbanColumn = ({ 
  HeaderComponent, CardComponent = KanbanCard, Component = Grid.Column, 
  col, text, value, color, icon, description, columnWidth, 
  items, 
  onViewDetails, onMaximize, onMinimize, onHeaderChange, onRemoveOption,
  viewDetailsText, allowMaximize 
}) => {
  return (
    <Droppable droppableId={value || Math.random()} text={text}>
      {(provided, snapshot) => (
        <Ref innerRef={provided.innerRef}>
          <Component stretched width={columnWidth}>
            <div className='kanban-column-title'>
              <HeaderComponent {...{col, color, text, icon, onHeaderChange, onRemoveOption}} />
              {description &&
                <Popup
                  inverted
                  position='top right'
                  content={description}
                  trigger={
                    <Icon
                      name="question circle"
                      className='kanban-column-description'
                    />
                  }
                />
              }
              {allowMaximize && onMaximize && <Icon link name='expand' onClick={onMaximize} />}
              {allowMaximize && onMinimize && <Icon link name='compress' onClick={onMinimize} />}
            </div>
            <Segment color={color} className={`kanban-column ${snapshot.isDraggingOver && 'focused'}`} style={{ border: '1px solid #dde1f9' }}>
              {items.map((item, index) =>
               <Draggable draggableId={item.id} index={index} text={item.value}>
                 {(provided, snapshot) => (
                    <Ref innerRef={provided.innerRef}>
                      <CardComponent {...{ item, provided, snapshot, viewDetailsText, onViewDetails: () => onViewDetails(item) }} />
                    </Ref>
                  )}
                </Draggable>
              )}
            </Segment>
          </Component>
        </Ref>
      )}
    </Droppable>
  )
}

export default KanbanColumn;
