import React, { Component } from 'react';
import { Statistic, Button, Form, Icon, Divider } from 'semantic-ui-react';
import { getLookupData } from './../../utils/api';
import { StatCard, IndexGrid } from '@rockerbox/styleguide';
import { PaddedLabel } from './form';

const COLS = [
  {display: "From", key: "input"},
  {display: "count", key: "num_inputs"},
  {display: "To", key: "output"},
  {display: "count", key: "num_outputs"},
  {display: "links", key: "num_links"},
]

const LookupResults = ({conversionSegmentId}) => {
  const [data, setData] = React.useState([]);
  const [baseId, setBaseId] = React.useState(false);
  const [sequenceResults, setSequenceResults] = React.useState([]);

  React.useEffect(() => {
    getLookupData(conversionSegmentId)
      .then(data => {
        if (data.length == 0) return false;
        setSequenceResults(data[0]['from_sequence'].split(","))
        setBaseId(data[0]['base_id'])
        setData(data)
      })
  }, [conversionSegmentId])

  if (data.length == 0) return null

  const first = data[0]
  const last = data.slice(-1)[0]

  return (
    <div>
      <PaddedLabel>Current Identity Resolution (from { baseId } to { last['output'] })</PaddedLabel>
      <Statistic.Group size={"mini"} style={{"justify-content":"space-between", margin: 1}}>
        <StatCard style={{flex:1,margin:5}} value={ first['total_identifiers'] - last['num_outputs'] } label="Before Lookup" />
        <StatCard style={{flex:1,margin:5}} value={ last['num_outputs'] } label="From Lookup" />
        <StatCard style={{flex:1,margin:5}} value={first['total_identifiers'] } label="Total" />
        <StatCard style={{flex:1,margin:5}} value={ (first['total_identifiers']/(first['total_identifiers'] - last['num_outputs']) ) } label="Increase" type="percent" />
      </Statistic.Group>
      <IndexGrid cols={COLS} data={data} />
    </div>
  )
}

export default LookupResults;
