import React, { useMemo, useEffect, useState, useRef } from "react"

import { useUrlStateWithDefaults } from './urlParamHooks'
import useAppSettings from '../../hooks/useAppSettings'

import GatedByPlatformSelection from './GatedByPlatformSelection'

const GatedByGlobalSetup = () => {
  const settings = useAppSettings();
  const { defaultView, defaultModel } = settings;

  const urlState = useUrlStateWithDefaults({ defaultView, defaultModel});

  // set an active view
  const { activeMenuItem } = urlState;
  const noActiveView = !activeMenuItem || activeMenuItem == 0

  useEffect(() => {
    if (noActiveView) urlState.setActiveMenuItem(defaultView)
  },[noActiveView])

  if (noActiveView) return 

  return <GatedByPlatformSelection 
    {...{settings, urlState}} 
  />
}

export default GatedByGlobalSetup;
