import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import { sortData } from '../hooks/sortData'
import { defaultChartDimensions as defaults } from '../constants';
import { formatXAxisTickLabel, toolTipLabelFormatter } from '../helpers'
import { GroupByDropdown } from '../parts';
import ChartTooltip from '../ChartTooltip';

const StackedBarChart = ({
  data, tierColorMap,
  groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  height, showTooltip, hideKeys=[], formatter, showXAxis, showTotalInToolTip
}) => {
  const toolTipValueFormatter = formatter?.format ? formatter.format :
    formatter ? formatter : (v) => v

  const { renderData } = sortData(data, groupDatesBy, defaultGroupBy)
  
  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width ='100%' height={height || defaults['height']}>
        <BarChart data={renderData} barCategoryGap='20%'>
          <XAxis
            xAxisId='0'
            hide={!showXAxis}
            dataKey="date"
            tickFormatter={(date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={12}
            padding={{left: 5, right: 5}}
          />
          <YAxis
            hide
            domain={[0, dataMax => dataMax]}
            yAxisId='bar'
          />
          {Object.keys(renderData[0])
            .filter(k => k != "date")
            .map(k => (
              <Bar key={k} dataKey={k} stackId="a" fill={tierColorMap[k]} yAxisId='bar' />
          ))}
          {showTooltip &&
            <Tooltip
              cursor={false}
              formatter={toolTipValueFormatter}
              labelFormatter={(v) => toolTipLabelFormatter(v, renderData)}
              content={<ChartTooltip {...{ showTotalInToolTip, hideKeys }} />}
              wrapperStyle={{ zIndex: 3 }}
            />
          }
          <CartesianGrid horizontal={false} vertical={false} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default StackedBarChart;
