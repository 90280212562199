import React from 'react';
import { ContentCard, IndexGridNew, BucketsPath } from '@rockerbox/styleguide';
import { Table, Dropdown, Form } from 'semantic-ui-react';
import { Currency, formatWithCommas } from '../../../utils/format_helpers';

const PathCell = ({item, col}) => {
  let newArr = [];
  item.path.map(path => {
    newArr.push({buckets: path})
  })
  return (
    <Table.Cell>
      <BucketsPath steps={newArr} colorMap={col.colorMap} count="200"/>
    </Table.Cell>
  )
}

const NumberCell = ({ item, col }) => (
  <Table.Cell style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
    {formatWithCommas(item[col.key])}
  </Table.Cell>
)

const RevenueCell = ({ item, col }) => (
  <Table.Cell style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
    <Currency amount={item[col.key]}/>
  </Table.Cell>
)

const bucketOptions = [
  {
    key: "t1",
    text: "Bucket 1",
    value: "t1",
    content: "Bucket 1"
  },
  {
    key: "t2",
    text: "Buckets 1 - 2",
    value: "t2",
    content: "Buckets 1 - 2"
  },
  {
    key: "t3",
    text: "Buckets 1 - 3",
    value: "t3",
    content: "Buckets 1 - 3"
  },
  {
    key: "t4",
    text: "Buckets 1 - 4",
    value: "t4",
    content: "Buckets 1 - 4"
  },
  {
    key: "t5",
    text: "Buckets 1 - 5",
    value: "t5",
    content: "Buckets 1 - 5"
  }
];

export const GroupByHeading = ({handleChange, bucketVal, selection = true}) => {
  return (
    <Form.Field>
      <label>Group by &nbsp;
        <ContentCard.TableExplainer content="The marketing channels that your customers engaged with before they converted"/>
      </label>
      <Dropdown
        selection={selection}
        options={bucketOptions}
        value={bucketVal}
        onChange={handleChange}
      />
    </Form.Field>
  )
}

const PathToConversion = ({ resolved, customerPath, tierColors }) => {

  const headerStyles = {textAlign: 'right'}

  const cols = [
    {
      display: "Path to Conversion", 
      key: 'path', 
      as: PathCell, 
      headerWidth: 8,
      colorMap: tierColors
    },
    {
      display: 'No. of Conversions', 
      key: 'count', 
      as: NumberCell, 
      headerWidth: 2, 
      sortable: true, 
      headerStyles,
    },
    {
      display: 'Avg. Revenue', 
      key: 'avg_revenue', 
      as: RevenueCell, 
      headerWidth: 2, 
      sortable: true, 
      headerStyles,
    },
    {
      display: 'Total Revenue',
      key: 'total_revenue', 
      as: RevenueCell,
      headerWidth: 2, 
      sortable: true, 
      headerStyles,
    },
  ];

  if(!resolved && !tierColors) return <ContentCard.CardLoader />

  return (
    <ContentCard hasTable style={{boxShadow: 'none'}}>
      <IndexGridNew
        title="Paths Breakdown"
        data={customerPath}
        cols={cols}
        itemsPerPage={15}
        as={ContentCard.Table}
        paginate
        sortable
        defaultSortCol='count'
      />
    </ContentCard>
  )
}

export default PathToConversion
