import React from 'react';
import { Table } from 'semantic-ui-react'
import { IndexGridNew } from '@rockerbox/styleguide';
import toast from 'react-hot-toast'
import { buildHash, buildPath } from '../../hooks/urlState';
import { track } from '../../../utils/tracking'

const ManageRow = ({ item, col }) => {
  const { id, name, user, shared, route, params } = item
  const { deleteSavedReport, handleGetSavedReports, openEditDrawer, onEditButtonClick } = col.key
  
  const handleDelete = () => {
    deleteSavedReport(id)
      .then((x) => {
        toast.success('Deleted Saved Report')
        track('saved_report.delete', { id, name, user, shared, route, params })
      })
      .catch(err => {
        console.log('Delete saved report error:', err)
        toast.error('Error deleting saved report')
      })
      .finally(() => handleGetSavedReports())
  }

  const handleEdit = () => {
    openEditDrawer()
    onEditButtonClick(item)
  }

  const handleCopy = () => {
    const { route, params } = item
    const rebuiltPath = buildPath(route, params.route)
    const rebuiltHash = params.hash ? buildHash(params.hash) : ''
    const fullLink = `${window.location.origin}${rebuiltPath}${rebuiltHash}`
    navigator.clipboard.writeText(fullLink)
    toast('Link copied to clipboard', { icon: '📋' })
  }

  return (
    <Table.Cell textAlign={"right"} style={{ position: 'relative', height: 47 }}> 
      <IndexGridNew.EditButtonV3 item={item} onClick={handleEdit} />
      <IndexGridNew.CopyLinkButtonV3 item={item} onClick={handleCopy} />
      <IndexGridNew.OverlayDeleteBtn onClick={handleDelete} />
    </Table.Cell>
  )
}

export default ManageRow
