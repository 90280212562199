import React from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Icon, Loader, Popup, Header } from 'semantic-ui-react'
import { CardLoader } from '../ContentCard/ContentCard'

const Wrap = props => props.children

const writeCell = (item, col, otherProps) => {
  const DefaultCell = ({ item, col }) => {
    const content = typeof(item[col.key]) == 'object' ? new String(item[col.key]) : item[col.key]
    return(
      <Table.Cell width={item[col.cellWidth]}>
        <span>{item[col.key]}</span>
      </Table.Cell>
    )
  }
  const CellComponent = col.as || DefaultCell

  return(
    <CellComponent {...{ col, item, ...otherProps }} />
  )
}

const GridRow = props => {
  const style = (props.isTotal || props.item.isTotal) ? {fontWeight: "bold"} : {}
  const warning = props.item[props.warning] 
  return (
    <Table.Row
      onClick={props.onRowClick ? () => props.onRowClick(props.item) : undefined}
      positive={props.positive || props.item.positive}
      style={{backgroundColor: (props.selectedRow == props.item) ? "#f2f2f2": undefined, cursor: props.onRowClick ? 'pointer' : undefined}}
      {...{warning}}
      negative={props.negative == true || props.item[props.negative]}
      {...{style}}
    >
      { props.cols.map((col, index) => writeCell(props.item, col, props)) }
    </Table.Row>
  )
}

const Grid = props => {
  const { sortedHeaders: headers, customHeader=false } = props;
  const TableHeader = (
    <Table.Header>
      { props.headerRows }
      <Table.Row>
        { headers && headers.length > 0 ? headers : props.cols.map(col => (
          col.headerAs ?
            col.headerAs(col) :
            <Table.HeaderCell width={col.headerWidth} style={col.headerStyles}>{col.display}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  )

  return (
    <React.Fragment>
      { customHeader ? customHeader : TableHeader }
      <Table.Body>
        {props.data.map(item => (
          <GridRow {...props} item={item} />
        ))}
      </Table.Body>
      <Wrap>
        {props.footer || null}
      </Wrap>
    </React.Fragment>
  )
}

export const IndexGrid = (props) => {
  const { cols, data, fallBackMsg, ...tableProps } = props;

  return(
    <Table {...tableProps}>
      { !data && <CardLoader /> }
      { data && data.length > 0 && <Grid cols={cols} {...props} /> }
      { data && data.length == 0 && <EmptyMessage fallBackMsg={fallBackMsg} />}
    </Table>
  )
}

export const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign='center'
        selectable={false}
        className='empty'
      >
        <br />
        <Icon
          circular
          disabled
          name='info'
          size='big'
          className='m-b-10' />
        <Header
          as='h3'
          disabled
          content={props.fallBackMsg || 'No data found'}
          className='m-0' />
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)

IndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element
   })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
  onRowClick: PropTypes.func
}

export default IndexGrid
