import React, { Component } from 'react';
import { getDataset, getSurveyConfigs } from '../../utils/api';
import { SplitLayout, SortableIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import ViewAll from './ViewAll';
import Impact from '../Impact';
import SetupStatus, { defaultFunc } from '../DataStatus';

const getSegmentIdFromSurvey = () => {
  return getSurveyConfigs()
    .then(impactData => (impactData.length > 0) ? impactData[0].conversion_id : false)
}

const Status = (props) => {
  const [entityData, setEntityData] = React.useState([])
  const [segmentId, setSegmentId] = React.useState(false)

  React.useEffect(() => {
    getSegmentIdFromSurvey()
      .then(setSegmentId)
  }, [])

  const datasets = [
    { dataset: "survey_difference", display: "Has Survey Data?"}
  ]

  const funcs = [
    { func: defaultFunc, text: "Backfill", display: "", url: "/run/agg/survey" }
  ]


  if (segmentId) return <SetupStatus filter_id={segmentId} {...{datasets, funcs}} />
  return null
}

const Steps = ({ current, refresh }) => {


  const steps = ["Setup", "Status", "Impact"]
  const [activeStep, setActiveStep] = React.useState(steps[0])

  return <>
    <Menu pointing secondary widths={3}>
      {steps.map(value => (
        <Menu.Item
          name={value}
          active={activeStep == value}
          onClick={() => setActiveStep(value)}
        />
      ))}
    </Menu>

    { activeStep == "Setup" && <ViewAll /> }
    { activeStep == "Status" && <Status /> }
    { activeStep == "Impact" && <Impact title="Survey" datasets={["survey_difference"]} getSegmentId={getSegmentIdFromSurvey}/> }
  </>

}

export default Steps;
