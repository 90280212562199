import moment from "moment";
import React from "react";
import { Popup } from "semantic-ui-react";

const asView = ({ cell, row, rowProps }) => {
  if (cell.value && cell.value.length <= 25) {
    return <div style={{ textAlign: "left" }}>{cell.value}</div>;
  }
  return (
    <Popup
      trigger={
        <div style={{ textAlign: "left" }}>{`${cell.value.substring(
          0,
          25
        )}...`}</div>
      }
      content={cell.value}
      inverted
      hoverable
    />
  );
};

export const dataSheetColumns = [
  {
    key: "control_tier_1",
    display: "Tier 1",
    type: "text",
    style: { textAlign: "center" },
    asView,
    readOnly: true
  },
  {
    key: "control_tier_2",
    display: "Tier 2",
    type: "text",
    style: { textAlign: "center" },
    readOnly: true,
    asView
  },
  {
    key: "control_tier_3",
    display: "Tier 3",
    type: "text",
    style: { textAlign: "center" },
    readOnly: true,
    asView
  },
  {
    key: "control_tier_4",
    display: "Tier 4",
    type: "text",
    style: { textAlign: "center" },
    readOnly: true,
    asView
  },
  {
    key: "control_tier_5",
    display: "Tier 5",
    type: "text",
    style: { textAlign: "center" },
    readOnly: true,
    asView
  }
];

export const requiredSetupFields = [
  "name",
  "start_date",
  "filter_id",
  "platform",
  "control_tier_1"
];

export const defaultStart = moment().subtract(14, "days");
export const defaultEnd = moment().subtract(7, "days");

export const searchSplitKey = ">";

export const controlTiers = [
  "control_tier_1",
  "control_tier_2",
  "control_tier_3",
  "control_tier_4",
  "control_tier_5"
];

export const firstAvailableDate = "2021-08-01";
