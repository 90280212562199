export const selectData = (data, itemsPerPage, selectedPageNumber) => {
  return (data||[]).slice(
    (selectedPageNumber - 1) * itemsPerPage,
    selectedPageNumber * itemsPerPage,
  )
}

export const filterData = (data, searchValue, filters) => {

  const hasSearchValue = !!searchValue
  const hasFilterValue = !!filters
  const hasSearchField = data.length ? data[0].searchField : false
  const shouldFilter = hasSearchValue || hasFilterValue

  if (!shouldFilter || !hasSearchField) return data

  return data
    .filter((row,i) => {
      const lowercasedSearchValue = searchValue.toLowerCase()
      const foundSearchValue = (row.searchField || '').indexOf(lowercasedSearchValue) > -1
      const foundFilterValue = filters
        .reduce((p,c) => {
          return p && (c.value ? row[c.field] == c.value : true)
        }, true)

      return foundSearchValue && foundFilterValue
    })
}

export const sortData = (filtered, column, sortKey, subKey, colDirection) => {
  const sortBy = subKey ? o => o[column][subKey] : o => o[sortKey || column];
  const undefinedData = filtered.filter(row => sortBy(row) == undefined);
  const definedData = filtered.filter(row => sortBy(row) != undefined);
  const sortedData = _.sortBy(definedData, [sortBy]);
  if (colDirection == "descending") sortedData.reverse();

  return [...sortedData, ...undefinedData];
}
