import React, {useState} from 'react';
import {useStepsDisplay, useStepsState} from "../StepsSectionContext";
import Button from "../../../../components/parts/Button";
import {Input, Message} from "semantic-ui-react";

const SetupClientShare = () => {
  const [stateData, setStateData] = useStepsState();
  const [stepsData, setStepsData] = useStepsDisplay()
  const [currentCopiedText, setCurrentCopiedText] = useState('');
  const onCopy = (text) => {
    setCurrentCopiedText(text);
    navigator.clipboard.writeText(text)
  }
  const CopyBlock = (props) => {
    return (
      <div>
        <Button primary disabled={stepsData['1'] === false} style={{width: '157px'}} onClick={() => props.onCopyCallback(props.text)}>
          { currentCopiedText === '' ? 'Copy To Clipboard' : 'Copied!' }
        </Button>
        <Message size={'small'} content={props.text}/>
      </div>
    )
  }
  const renderDatabaseCreateStatment = () => {
    const text = Object.keys(stateData).length > 0 ?
      `CREATE DATABASE rockerbox FROM SHARE IDENTIFIER('${stateData['details']['rockerbox_account_id'].toUpperCase()}."${stateData['details']['share'].toUpperCase()}"')` :
      `CREATE DATABASE rockerbox FROM SHARE IDENTIFIER('rbx_account."share_name"')`
    return <CopyBlock text={text} onCopyCallback={onCopy}/>
  }
  return (
    <div>
      <div>
        {renderDatabaseCreateStatment()}
      </div>
    </div>
  )
}
export default SetupClientShare;