import React from 'react'
import ContentLoader from 'react-content-loader'

const PromoCardLoader = () => (
  <ContentLoader
    viewBox="0 0 371 251"
    preserveAspectRatio="none" 
    foregroundColor="#e5e5e5"
    height={251}
    width={'100%'}
  >
    <path d="M287.65,152.75H88.3c-3.87,0-7-3.13-7-7V41.57c0-3.87,3.13-7,7-7h199.35c3.87,0,7,3.13,7,7v104.18
      C294.65,149.62,291.52,152.75,287.65,152.75z"/>
    <path d="M339.59,202H36.36c-2.21,0-4-1.79-4-4v-5.17c0-2.21,1.79-4,4-4h303.22c2.21,0,4,1.79,4,4V198
      C343.59,200.21,341.8,202,339.59,202z"/>
    <path d="M293.83,216.42H82.12c-2.21,0-4-1.79-4-4v0c0-2.21,1.79-4,4-4h211.72c2.21,0,4,1.79,4,4v0
      C297.83,214.63,296.04,216.42,293.83,216.42z"/>
    <path d="M246.24,233.42H129.71c-2.21,0-4-1.79-4-4l0,0c0-2.21,1.79-4,4-4h116.52c2.21,0,4,1.79,4,4l0,0
      C250.24,231.63,248.45,233.42,246.24,233.42z"/>
  </ContentLoader>
)

export default PromoCardLoader
