import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { metricMapper, setStyles } from '../../layouts/IndexGridTree/goalCellHelpers'

// keys to not include in rollups
const skip = ['date', 'target', 'absoluteAdjustedDiff', 'formattedValue', 'backgroundColor', 'onTarget', 'delta']

const addFixedTargetGoalKeys = (goal, c, sorted) => {
  const {target, metric} = goal
  const metricConfig = metricMapper[metric]
  const { trendReversed, percentageFromGoal, format } = metricConfig

  const delta = c[metric] - target
  const formattedValue = format(delta)
  const { absoluteAdjustedDiff, increaseOrDecrease } = percentageFromGoal(target, c, sorted)
  const onTarget = absoluteAdjustedDiff <= 10

  c.absoluteAdjustedDiff = absoluteAdjustedDiff,
  c.formattedValue = formattedValue,
  c.colorStyles = setStyles(onTarget, delta, trendReversed, increaseOrDecrease, true),
  c.onTarget = onTarget,
  c.delta = delta
  c.target = target

  return c
}

const addRollingTargetGoalKeys = (goal, c, i, a, sorted) => {
  const { metric, type } = goal
  const prevDay = a[i-1]
  const metricConfig = metricMapper[metric]
  const { trendReversed, percentageFromGoal, format } = metricConfig

  if(i == 0) {
    c.target = c[metric]
  } else {
    c.target = prevDay.target + ((2/8) * (c[metric] - prevDay.target))
  }
  
  const delta = c[metric] - c.target
  const formattedValue = format(delta)
  const { absoluteAdjustedDiff, increaseOrDecrease } = percentageFromGoal(c.target, c, sorted)
  const onTarget = absoluteAdjustedDiff <= 10

  c.absoluteAdjustedDiff = absoluteAdjustedDiff
  c.formattedValue = formattedValue
  c.colorStyles = setStyles(onTarget, delta, trendReversed, increaseOrDecrease, true)
  c.onTarget = onTarget
  c.delta = delta


  return c
}

const findAllUniqueKeys = (data) => {
  const dataMapped = data.flatMap((x) => Object.keys(x))
  const uniqueMapped = [...new Set(dataMapped)]
  return uniqueMapped
}

const findMonths = (data) => {
  const dataMapped = data.map(({ date }) => moment(date).month())
  return [...new Set(dataMapped)]
}

const aggregateData = (acc, item, chunkIndex, keys) => {
  if (!acc[chunkIndex]) {
    acc[chunkIndex] = {date: item.date, target: item.target}
  }
  keys.forEach(key => {
    const itemValue = item[key]
    if (!acc[chunkIndex][key]) {
      acc[chunkIndex][key] = itemValue
    } else {
      acc[chunkIndex][key] += itemValue
    }
  })
  return acc
}

const groupDataWeekly = (data, keys) => {
  const perChunk = 7

  const result = data.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)
    return aggregateData(acc, item, chunkIndex, keys)
  }, [])

  return result
}

const groupDataMonthly = (data, keys) => {
  const months = findMonths(data)

  const result = data.reduce((acc, item) => {
    const chunkIndex = months.findIndex(month => month === moment(item.date).month())
    return aggregateData(acc, item, chunkIndex, keys)
  }, [])

  return result
}

export const sortData = (data, groupDatesBy = 'daily', defaultGroupBy = false) => {
  const [renderData, setRenderData] = useState(data);

  const sortedData = useMemo(() => {
    return data.sort((a, b) => {
      const x = a['date'];
      const y = b['date'];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }, [data])

  useEffect(() => {
    if (defaultGroupBy) {
      const keys = findAllUniqueKeys(data).filter(x => x !== 'date')
      if (groupDatesBy === 'weekly') {
        const weeklyData = groupDataWeekly(sortedData, keys)
        setRenderData(weeklyData)
        return
      }
      if (groupDatesBy === 'monthly') {
        const monthlyData = groupDataMonthly(sortedData, keys)
        setRenderData(monthlyData)
        return
      }
    }
    setRenderData(sortedData)
    return
  }, [groupDatesBy, data])

  return {
    renderData
  }
}

export const sortGoalData = (data, groupDatesBy = 'daily', defaultGroupBy = false, goal, setGoal) => {
  const { type, metric, target } = goal
  const [renderData, setRenderData] = useState(data)

  const sortedData = useMemo(() => {
    const sorted = data.sort((a, b) => {
      const x = a['date'];
      const y = b['date'];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })

    if(type == "fixed") {
      sorted.map(c => {
        return addFixedTargetGoalKeys(goal, c, sorted)
      })
    }

    if(type == "rolling") {
      sorted.map((c,i,a) => {
        return addRollingTargetGoalKeys(goal, c, i, a, sorted)
      })
      const lastItem = sorted.slice(-1)
      const updatedGoal = Object.assign(goal, {target: lastItem[0].target})
      setGoal(updatedGoal)
    }

    return sorted
  }, [data, goal])

  useEffect(() => {
    const keys = findAllUniqueKeys(data).filter(x => !skip.includes(x))

    if (defaultGroupBy) {
      if (groupDatesBy === 'weekly') {
        const weeklyData = groupDataWeekly(sortedData, keys)
        const addGoalInfo = type == "fixed" ? weeklyData.map(c => addFixedTargetGoalKeys(goal, c)) : weeklyData.map((c,i,a) => addRollingTargetGoalKeys(goal,c,i,a,weeklyData))
  
        setRenderData(addGoalInfo)
        return
      }
      if (groupDatesBy === 'monthly') {
        const monthlyData = groupDataMonthly(sortedData, keys)
        const addGoalInfo = type == "fixed" ? monthlyData.map(c => addFixedTargetGoalKeys(goal, c)) : monthlyData.map((c,i,a) => addRollingTargetGoalKeys(goal,c,i,a,monthlyData))
  
        setRenderData(addGoalInfo)
        return
      }
    }
    setRenderData(sortedData)
    return
  }, [groupDatesBy, data])

  return {
    renderData
  }
}
