import React, { useState, useEffect } from "react";
import moment from "moment";
import { sendReporting, getReportTypeLimitBySegment } from "../../utils/api";
import Container from "../parts/Container";
import {
  Button,
  Modal,
  Form,
  Dropdown,
  Message,
  Popup,
  Icon,
} from "semantic-ui-react";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";
import { findMatchedReportTypeOptions } from "../ReportIndex/helpers";
import { CloseButtonStyles } from "../styled";

const typeOptions = [
  { key: "csv", value: "csv", text: "csv" },
  { key: "tsv", value: "tsv", text: "tsv" },
  { key: "xlsx", value: "xlsx", text: "xlsx" },
];

const SendNewReportModal = (props) => {
  const {
    username,
    pixel_source_name,
    reportTypes,
    segments,
    isRBUser,
    triggerReload,
    resetTimerCount
  } = props;

  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(moment.utc().utcOffset(-5).subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment.utc().utcOffset(-5).subtract(1, "days"));
  const [focusedInput, setFocusedInput] = useState(null);
  const [filetype, setFiletyle] = useState("tsv");
  const [segment, setSegment] = useState(null);
  const [reportTypeId, setReportTypeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successPage, setSuccessPage] = useState(false);
  const [reportTypeLimits, setReportTypeLimit] = useState([])

  useEffect(() => {
    if (segment) {
      getReportTypeLimitBySegment(segment).then((data) => setReportTypeLimit(data['response']))
    }
  }, [segment])

  useEffect(() => {
    setEmail(username);
  }, []);

  const validate = () => {
    return startDate && endDate && email && filetype && segment && reportTypeId;
  };

  const onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    if (newStartDate) {
      setStartDate(newStartDate);
    }
    if (newEndDate) {
      setEndDate(newEndDate);
    }
  };

  const handleOutsideRange = (day) => {
    const maxDays = 60;
    const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(endDate.format());
    const startDateCopy = moment(startDate.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return beforeMin || afterMax || afterSixty;
  };

  const onFormSubmit = () => {
    const parseDate = (str) => moment(str).format("YYYY-MM-DD");

    const requestBody = {
      destination: email,
      advertiser: pixel_source_name,
      file_type: filetype,
      filter_id: segment.toString(),
      report_type_id: reportTypeId,
      filter: {},
      start_date: parseDate(startDate),
      end_date: parseDate(endDate),
    };

    setLoading(true);
    setError(false);

    return sendReporting(requestBody)
      .then(() => {
        setLoading(false);
        setSuccessPage(true);
        setTimeout(triggerReload, 1000);
        resetTimerCount();
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setTimeout(triggerReload, 1000);
        resetTimerCount();
      });
  };

  const segmentOptions =
    segments &&
    segments.map((type) => {
      return {
        key: type.action_id,
        value: type.action_id,
        text: type.action_name,
      };
    });

  let matchedReportTypes = findMatchedReportTypeOptions(
    segment,
    segments,
    reportTypes,
    reportTypeLimits
  );

  const RBUserReportTypeOptions = matchedReportTypes
    ? matchedReportTypes.map((o, i) => {
        return { text: o.name, value: o.id, key: i };
      })
    : [];
  const NonRBUserReportTypeOptions = matchedReportTypes
    ? matchedReportTypes
        .filter((type) => type.is_public === 1)

        .map((o, i) => {
          return { text: o.name, value: o.id, key: i };
        })
    : [];

  const reportTypeOptions = isRBUser
    ? RBUserReportTypeOptions
    : NonRBUserReportTypeOptions;

  const formFieldsAndButton = (
    <React.Fragment>
      <Form.Group key={"segment"}>
        <Form.Field width={9}>
          <label>{"Conversion"}</label>
          <Form.Dropdown
            selection
            options={segmentOptions}
            value={segment}
            onChange={(e, data) => {
              setSegment(data.value);
              setReportTypeId(null);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"reportType"}>
        <Form.Field width={9}>
          <label>{"Report Type"}</label>
          <Form.Dropdown
            selection
            options={reportTypeOptions}
            value={reportTypeId}
            onChange={(e, data) => setReportTypeId(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"destination"}>
        <Form.Field>
          <label>{"Email"}</label>
          <Form.Input
            value={email}
            onChange={(e, data) => setEmail(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"dates"}>
        <Form.Field>
          <label>{"Date Range"}</label>
          <DateRangePicker
            onDatesChange={onDatesChange}
            onFocusChange={(data) => setFocusedInput(data)}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            noBorder={true}
            isOutsideRange={(d) => handleOutsideRange(d)}
            hideKeyboardShortcutsPanel
            startDateId="deliveryStartDate"
            endDateId="deliveryEndDate"
            minimumNights={0}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"filetype"}>
        <Form.Field>
          <label>{"File Type"}</label>
          <Form.Dropdown
            selection
            options={typeOptions}
            value={filetype}
            onChange={(e, data) => setFiletyle(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Button type="submit" disabled={!validate()}>
        Send Report
      </Button>
    </React.Fragment>
  );

  return (
    <Form onSubmit={() => onFormSubmit()} loading={loading} error={error}>
      <Message
        error
        header="Failed to send this saved report"
        content="Please try with a different date or contact us."
      />
      {successPage ? (
        <Container center column>
          <h5>
            Your report is now processing! You can view the status shortly.
          </h5>
          <Container>
            <Button onClick={props.done}>Done</Button>
          </Container>
        </Container>
      ) : (
        formFieldsAndButton
      )}
    </Form>
  );
};

export default (props) => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
      {props.title}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <SendNewReportModal done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
