import React, { Component } from 'react';
import { getDataset, getEntityConfigs } from '../../utils/api';
import { SplitLayout, SortableIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Grid, Dropdown, Step, Message, Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import styled from 'styled-components';

const Mini = styled.div`
  display: inline-block;
  font-size: .9em
`

const ChangeCell = ({ item, col }) => {
  const value = item[col.key]
  return <Table.Cell>
    <ContentCard.PercentChangeLabel value={value} />
  </Table.Cell>
}

const RoundCell = ({ item, col }) => {
  const value = Math.round(item[col.key]*100, 2)/100
  return <Table.Cell>
    { value }
  </Table.Cell>
}

const tiers = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"]

const _getSegmentId = () => {
  return getEntityConfigs()
    .then(impactData => (impactData.length > 0) ? impactData[0].segments[0].segment_id : false)
}

const Impact = ({ 
  title = "Entity", numDays = 30, 
  datasets = ["entity_difference", "entity_summary"] ,
  getSegmentId = _getSegmentId,
  segmentId = false
}) => {

  const dataCols = [
    { key: "even_without", display: `Before ${title} Tracking`, as: RoundCell, headerWidth: 1 },
    { key: "even", display: `Including ${title} Tracking`, as: RoundCell, headerWidth: 1 },
    { key: "change", display: "% Change", as: ChangeCell, headerWidth: 1 }
  ]

  const [impactData, setImpactData] = React.useState([])

  const [impactDifference, setImpactDifference] = React.useState([])
  const [impactDifferenceSummary, setImpactDifferenceSummary] = React.useState([])

  const [segment_id, setSegmentId] = React.useState(segmentId)
  const [tier, setTier] = React.useState("tier_1")
  const [summaryData, setSummaryData] = React.useState([])

  React.useEffect(() => {
    if (!segment_id) getSegmentId()
      .then(setSegmentId)
  },[])

  React.useEffect(() => {
    if (segment_id) {
      const datasetString = datasets.join(",");
      getDataset(datasetString, segment_id, numDays)
        .then(({ response }) => {
          const data = response[datasets[0]]
          setImpactDifference(data)
        })
        
    }
  }, [segment_id])

  React.useEffect(() => rollupImpact(tier), [tier, impactDifference])

  const rollupImpact = (tier) => {

    const allowedTiers = tiers.slice(0, tiers.indexOf(tier)+1)

    const impactDifferenceSummary = d3.nest()
      .key(row => allowedTiers.map(tier => row[tier]).join("||"))
      .rollup(values => {
        const row = values.reduce((p,c) => {
            Object.keys(p).map(key => p[key] += c[key] )
            return p
          }, {"even":0, "revenue_even":0, "even_without":0, "revenue_even_without":0})

        row.change = (row.even - row.even_without) * 2/ (row.even + row.even_without)
        row.revenue_change = (row.revenue_even - row.revenue_even_without) * 2/ (row.revenue_even + row.revenue_even_without)

        return row
      })
      .entries(impactDifference)
      .map(row => {
        return row.key.split("||").reduce((p,c,i) => {
          const key = allowedTiers[i]
          p[key] = c
          return p
        }, row.values)
      })
      .sort((p,c) => p.change - c.change)

    setImpactDifferenceSummary(impactDifferenceSummary)
  }

  const chooseTier = (evt, { value }) => setTier(value)

  const cols = React.useMemo(() => {
    const allowedTiers = tiers.slice(0, tiers.indexOf(tier)+1)

    if (impactDifferenceSummary.length) {
      const cols = IndexGrid.arrayToColumns(allowedTiers)
        .map(row => {
          row.headerWidth = 2
          return row
        })
      return cols.concat(dataCols)
    }
    return []
  }, [impactDifferenceSummary])
  
  return <ContentCard title="Impact" >

    <Grid columns="equal">
      <Grid.Row style={{fontWeight:"bold", backgroundColor: '#F9FAFB', paddingLeft: "15px", lineHeight:"30px"}}>
        View Impact by: &nbsp;&nbsp; <Mini>
          <Dropdown value={tier} selection options={tiers.map(value => new Object({ value, text: value }))} onChange={chooseTier} />
        </Mini>
      </Grid.Row>
    </Grid>
    <SortableIndexGrid cols={cols} data={cols.length > 0 ? impactDifferenceSummary : false} />
  </ContentCard>
}

export default Impact;
