import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button } from 'semantic-ui-react';

const SetupForm = ({promoCode, discountType}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [setupPending, setSetupPending] = useState(false)

  const enterLoading = () => {
    setSetupPending(true)
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // use qs params to indicate that the user has added a payment method
        return_url: `${window.location.href}?confirm=true&code=${promoCode}&type=${discountType}`,
      },
      // redirect: 'if_required'
    });
    
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setPaymentPending(false)
    } else {
      <Redirect to='/onboarding/payment' />
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div className='onboarding-setup-error-message'>{errorMessage}</div>}
      <PaymentElement />
      <Button disabled={!stripe} color='purple' className='onboarding-setup-submit_button' loading={setupPending} onClick={enterLoading}>Submit</Button>
    </form>
  )
};

export default SetupForm;
