import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { SplitLayout, ContentCard, IndexGrid, CustomerTimeline, IndexGridNew } from '@rockerbox/styleguide';
import { Form, Button, Icon, Card, Table, Checkbox } from 'semantic-ui-react';
import { getUserData } from '../../utils/api';
import styled from 'styled-components';
import moment from 'moment'
import _ from 'lodash'
import { SessionsCard, LookupsTable, Tier1Item, ActionItem } from './lookupParts';

const SuccessCell = ({item, col}) => item[col.key] == true ? 
  <Table.Cell style={{textAlign: 'center'}}><Icon name='checkmark'/></Table.Cell> : 
  <Table.Cell></Table.Cell>;


const CheckboxCell = (props) => {
  const { item, col, onCheckboxUpdate } = props;
  return <Table.Cell><Checkbox as='a' onChange={onCheckboxUpdate(item[col.key])} checked={props[col.isSelectedObj][item[col.key]]} /></Table.Cell>;
}
const CheckboxHeader = ({item, col}) => <Table.Cell><Checkbox /></Table.Cell>;

const COLUMNS = [
  {
    "display": CheckboxHeader,
    "key": "sessionId",
    "collapsing": true,
    "isSelectedObj": "selectedSessions",
    "as": CheckboxCell
  },
  {
    "display": "Session Start",
    "key": "timestamp"
  },
  {
    "display": "Duration",
    "key": "duration"
  },
  {
    "display": "Events",
    "key": "events"
  },
  {
    "display": "Marketing?",
    "key": "has_marketing",
    "as": SuccessCell
  },
  {
    "display": "Conversion?",
    "key": "has_conversion",
    "as": SuccessCell
  },
  {
    "display": "",
    "key": "expand",
    "as": IndexGrid.ExpandContractCell
  }
]
const EVENT_TYPES = {
  "showActions": "Actions", 
  "showViews": "Views", 
  "showMarketing": "Marketing Events", 
  "showConversion": "Conversions"
}

const BoldSpan = styled.span`
  font-weight: bold
`;


class UserLookup extends Component {

  state = {
    actions: [],
    selectedActions: {},
    selectedSessions: {},
    showMarketing: true,
    showViews: true,
    showActions: true,
    showConversion: true,
    sessions: false,
    lookup: false,
    attempted_lookups: false,
    events: false
  }

  getData() {
    const selectedSessions = _.cloneDeep(this.state.selectedSessions);
    const { id, uid } = this.props.match.params;
    getUserData(id, uid)
      .then(data => {
        const { actions, attempted_lookups, sessions, lookup, events } = data;
        const lookupCols = Object.keys(lookup[0]);
        events.map(c => {
          c.eventTiers = [{name: c.tier_1, href: '#'}, {name: c.tier_2, href: '#'}, {name: c.tier_3, href: '#'}, {name: c.tier_4, href: '#'}, {name: c.tier_5, href: '#'}];
          c.actionName = c.url.split('action=')[1].split('&')[0];
          c.baseUrl = c.url.split('&action')[0];
        })

        const selectedActions = actions.reduce((p,action) => {
          p[action.action] = true;
          return p
        },{})
        sessions.map(session => {
          selectedSessions[session.sessionId] = true;
          session.duration = moment.duration(session.session_seconds, "seconds").format("h [hrs], m [min]");
        })

        this.setState({ actions, attempted_lookups, lookup, events, sessions, lookupCols, selectedSessions, selectedActions })
      })
  }

  componentDidMount() {
    this.getData()
  }

  onCheckboxUpdate = (key) => () => {
    const selectedSessions = _.cloneDeep(this.state.selectedSessions);
    selectedSessions[key] = !selectedSessions[key]
    this.setState({ selectedSessions })
    return false
  }

  showEventType = (key) => () => {
    this.setState({ [key]: !this.state[key] })
    return false
  }

  selectActionType = (key) => () => {
    const selectedActions = _.cloneDeep(this.state.selectedActions);
    selectedActions[key] = !selectedActions[key]
    this.setState({ selectedActions })
    return false
  }

  render() {
    const { selectedActions, selectedSessions, attempted_lookups, lookup, sessions, events, lookupCols } = this.state;
    const { actions, showMarketing, showViews, showActions, showConversion } = this.state;
    const actionCols = [{display: 'action', key: 'action', headerWidth: 10}, {display: 'count', key: 'count', headerWidth: 6}];

    let isOnlyView = false;
    const feedEvents = (!events || events.length == 0) ? [] : events
      .map(c => {
        if (!selectedSessions[c.sessionId]) return null;

        const objs = []
        const date = c.timestamp
        const referrer = c.request_referrer
        const eventLink = "#"
        const baseUrl = c.url
        const eventName = c.actionName

        const { eventTiers } = c
        
        if (c.is_conversion && showConversion) {
          isOnlyView && objs.push(<CustomerTimeline.SmallItem />)
          isOnlyView = false
          objs.push(
            <ActionItem color='green' {...{date, eventLink, eventName, baseUrl}} />
          )
        }
        else if (c.is_marketing && c.is_view && showMarketing){
          console.log("ismarketing",c.is_marketing)
          isOnlyView && objs.push(<CustomerTimeline.SmallItem />)
          isOnlyView = false
          objs.push(<Tier1Item {...{date, baseUrl, eventTiers, referrer}} />)
        }
        else if (!c.is_view && showActions && selectedActions[c.action]) {
          isOnlyView && objs.push(<CustomerTimeline.SmallItem />)
          isOnlyView = false
          objs.push(
            <ActionItem {...{date, eventLink, eventName, baseUrl}} />
          )
        }
        else if(c.is_view && showViews){
          (!isOnlyView) && objs.push(<CustomerTimeline.Item icon={<Icon circular name="mouse pointer" color='blue' />} title={"Page View"} date={ date } />);
          isOnlyView = true;
          objs.push(
            <CustomerTimeline.SmallItem>
              <span style={{"fontSize":".9em"}}>{ date }</span> - {baseUrl.split("&action=")[0]}
            </CustomerTimeline.SmallItem>
          )
        }
        return objs;
      })

    return (
      <SplitLayout
        leftWidth={7}
        rightWidth={9}
        leftContent={
          <React.Fragment>

            <ContentCard title={"Events"}>
              <div style={{"display":"flex"}}>
                <div style={{"flex":1}}>
                  <h5>Activity Type</h5>
                  { 
                    ["showActions", "showViews", "showMarketing", "showConversion"].map(key => {
                      return <Form.Group>
                        <Checkbox label={EVENT_TYPES[key]} checked={this.state[key]} onClick={this.showEventType(key)} />
                      </Form.Group>
                    })
                  }
                </div>
                { this.state['showActions'] && <div style={{"flex":1}}>
                  <h5>Action Types</h5>
                  { actions
                      .map(row => {
                        if (row.action == "view") return null;
                        return <Form.Group>
                          <Checkbox label={row.action} checked={selectedActions[row.action]} onClick={this.selectActionType(row.action)} />
                        </Form.Group>
                      })
                  }
                </div>
                }
              </div>
            </ContentCard>
            <ContentCard hasTable title={"Sessions"} topRight={<span>Total: {sessions.length}</span>}>
              <IndexGridNew
                {...{selectedSessions}}
                as={ContentCard.Table}
                onCheckboxUpdate={this.onCheckboxUpdate}
                cols={COLUMNS}
                expandCell={true}
                expandable={true}
                data={sessions}
                itemsPerPage={50}
                idKey="sessionId"
                expandedComponent={SessionsCard(actionCols)}
                actionCols={actionCols}
              />
            </ContentCard>
            <ContentCard title={"Lookups"} className='data-pii'>
              <ol>
                {attempted_lookups && attempted_lookups.length > 0 &&
                  attempted_lookups.map(c => <li>{c}</li>)
                }
              </ol>
              { lookup && lookup.length > 0 &&
                <LookupsTable lookupCols={lookupCols} lookup={lookup}/>
              }
            </ContentCard>
          </React.Fragment>
        }
        rightContent={
          <ContentCard title={"Events"}>
            <CustomerTimeline>
              {events && events.length > 0 && feedEvents}
            </CustomerTimeline>
          </ContentCard>
        }
      />
    )
  }
}

export default withRouter(UserLookup);
