import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SendReportForm from './component';


function mapStateToProps (state, props) {
  return {
    pixel_source_name: state.app.user.pixel_source_name,
    username: state.app.username
  }
}

const SendReportFormWrapper = withRouter(connect(mapStateToProps)(SendReportForm))

export default SendReportFormWrapper;
