import React, { useEffect, useState } from "react";
import { Table, Icon, Loader, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { IndexGrid } from "@rockerbox/styleguide";
import { getDeliveredFile, getErrorMessage } from "../../utils/api";

export const SegmentNameCell = (props) => {
  const {
    item: { segment_name: segment_name, identifier },
  } = props;
  const content =
    segment_name && segment_name !== "All Pages" ? (
      <Link to={`/v2/reporting/conversion/view/${identifier}`}>
        {segment_name}
      </Link>
    ) : (
      segment_name || "No Available"
    );
  return <Table.Cell>{content}</Table.Cell>;
};

export const ManageCell = (props) => {
  const {
    item: { id },
  } = props;

  function openMultipleTabs(urlsArray) {
    urlsArray.forEach(function (url) {
      let link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    });
  }

  const ClickEvent = (e, data) => {
    getDeliveredFile(id).then((data) => {
      const urls = data.map(
        (file) => `/data/delivered/space/download?file=${file.filename}`
      );
      openMultipleTabs(urls);
    });
  };
  return (
    <Table.Cell collapsing style={{ width: "150px" }}>
      <IndexGrid.DownloadCell {...props} onClick={ClickEvent} />
      <IndexGrid.ExpandContractCell {...props} ownComponent={true} />
    </Table.Cell>
  );
};

export const DebugCell = (props) => {
  const {
    item: { workqueue_job_id },
  } = props;

  return (
    <Table.Cell collapsing style={{ width: "150px" }}>
      <a
        href={`http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${workqueue_job_id}`}
        target="_blank"
      >
        <Icon name="warning sign" />
      </a>
    </Table.Cell>
  );
};

export const ErrorCell = (props) => {
  const {
    item: { workqueue_job_id},
  } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getErrorMessage(workqueue_job_id).then(data => {
      setError(data['response']);
      setLoading(false);
    }).catch(e => setLoading(false))
  }, [workqueue_job_id])

  return <Table.Cell className="ellipsed" style={{width: "15%"}}>
    <Popup
      trigger={<span>{error ? error : "..."}</span>}
      content={error ? error : "..."}
      inverted
      hoverable
    />
  </Table.Cell>

}
