import React, { useState, useEffect } from "react";
import { getDeliveredFile } from "../../utils/api";
import { PagedIndexGrid, ContentCard, IndexGrid } from "@rockerbox/styleguide";
import { Icon, Popup, Button, Table, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const DownloadButton = ({ item: { filename }, onClick }) => (
  <Table.Cell textAlign="center">
    <ButtonWithHover
      popupContent="Download"
      icon="download"
      as={Link}
      to={`/data/delivered/space/download?file=${filename}`}
      target="_blank"
    />
  </Table.Cell>
);

export const ButtonWithHover = props => (
  <Popup
    inverted
    content={props.popupContent}
    trigger={<Button {...props} />}
  />
);

const cols = [
  { display: "Start Date", key: "start_date" },
  { display: "End Date", key: "end_date" },
  { display: "Finish Type", key: "finish_type" },
  {
    display: "Download",
    key: "download_report",
    as: DownloadButton,
    headerStyles: { textAlign: "center" }
  }
];

const fetchStartEndDate = files => {
  return files.map(item => {
    const { filename } = item;
    const dateRange = filename
      .split("/")
      .filter(str => str.match(/([0-9]){4}-([0-9]){2}-([0-9]){2}/));

    if (dateRange.length !== 1) {
      return item;
    }
    const dateList = dateRange[0].split("_to_");
    return Object.assign({}, item, {
      start_date: dateList[0],
      end_date: dateList[1]
    });
  });
};

const ExpandableTable = ({ item }) => {
  const { id } = item;
  const [deliveredFiles, setDeliveredFiles] = useState([]);
  useEffect(() => {
    getDeliveredFile(id).then(d => setDeliveredFiles(fetchStartEndDate(d)));
  }, []);

  return <IndexGrid data={deliveredFiles} cols={cols} key={id} />;
};

export default ExpandableTable;
