import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Link } from 'react-router-dom';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Progress, Button, Table, Icon } from 'semantic-ui-react';
import ConverterDetails from './ConverterDetails';

const Percentage = ({item, col}) => {
  const percent = (new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })).format(item[col.key])

  return (
    <Table.Cell>
      {item[col.key] ? percent : '-'}
    </Table.Cell>
  )
}

const ProgressCell = ({item, col}) => {

  return (
    <Table.Cell>
      <Progress percent={item[col.key]*100} style={{margin:"0px"}} success/>
    </Table.Cell>
  )
}

const cols = [
  { display: 'Path Length', key: 'Path Length'},
  { display: 'Num Converters', key: 'Num Converters' },
  { display: 'Percentage', key: 'percent', as: Percentage },
  { display: '', key: 'percent', as: ProgressCell},
]

const colsTouches = [
  { display: 'Overlapping Touches', key: 'touch_type'},
  { display: 'First touch', key: 'first_touch' },
  { display: 'Last touch', key: 'last_touch' },
  { display: 'Total', key: 'total' },
  { display: 'Percent', key: 'percent', as: Percentage },
]




class CustomerHistogram extends Component {
  render() {
    const { customerData, resolved, filteredData, exclusions, id } = this.props;
    const linkBase = `/v2/customers/path/view/${id}/`;
    const ProfileButton = ({item}) => <Table.Cell><Button as={Link} to={linkBase + item.uid} icon="user"/></Table.Cell>


    if (!resolved) return <ContentCard.CardLoader />

    const customersByPathLength = d3.nest()
      .key(row => row.path_deduplicated.length)
      .rollup(values => values.length)
      .entries(filteredData)
      .map( row => {
        return {"Path Length": row.key, "Num Converters": row.values, "percent": row.values/filteredData.length }
      })

    // TODO: get from props instead...
    const deduplicatedData = filteredData.filter(row => row.path_deduplicated.length == 1);
    const selected = deduplicatedData.length ? deduplicatedData[0]['path_deduplicated'] : false

    const touchRelated = Object.values(filteredData
      .filter(row => row.path_deduplicated.length > 0)
      .reduce((p,c) => {

        const isInList = [];
        c.path_deduplicated.map((x,i) => {
          if (x != selected) {

            p[x] = (p[x] || {})
            p[x]['touch_type'] = x
            if (isInList.indexOf(x) == -1) p[x]['total'] = (p[x]['total'] || 0) + 1
            if (i == 0) p[x]['first_touch'] = (p[x]['first_touch'] || 0) + 1
            if (i == c.path_deduplicated.length -1) p[x]['last_touch'] = (p[x]['last_touch'] || 0) + 1
            isInList.push(x)
          }
        })
        return p
      },{}))

    touchRelated.map(row => {
      row.percent = row.total/filteredData.length
    })


    return (
      <SplitLayout
        leftWidth={8}
        leftContent={
          <IndexGrid
            expandable={true}
            idKey="conversion_hash_id"
            data={customersByPathLength}
            cols={cols}
            itemsPerPage={50}
            as={ContentCard.Table}
          />
        }
        rightWidth={8}
        rightContent={
          <IndexGrid
            expandable={true}
            idKey="conversion_hash_id"
            data={touchRelated}
            cols={colsTouches}
            itemsPerPage={50}
            as={ContentCard.Table}
          />
        }
      />
    );
  }
}

export default CustomerHistogram;
