import { useContext } from 'react'
import { DatalakeContext } from './context';

import { selectDatasetsByReportType, selectDropdownOptionsByReportType } from './selectors';

export const useDatalake = () => {
  const {
    connectionLoaded,
    datalakeLoaded,
    datalakeConnection,
    transformedTables,
    datalakeReports,
    segments,
    refreshDatalakeTables,
  } = useContext(DatalakeContext);
  return {
    connectionLoaded,
    datalakeLoaded,
    datalakeConnection,
    datalakeReports,
    transformedTables,
    segments,
    selectDatasetsByReportType: selectDatasetsByReportType(transformedTables, datalakeReports),
    selectDropdownOptionsByReportType: selectDropdownOptionsByReportType(transformedTables, datalakeReports),
    refreshDatalakeTables,
  }
}
