import React, { useEffect, useState } from 'react';
import { Input, Button, Form, Message } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import Container from '../parts/Container';
import { getReportTypes, sendReportingData } from '../../utils/api';
import { formatTiersValue, urlDecodeTierStr } from '../ViewAttribution/helpers';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { track } from '../../utils/tracking';
import * as routes from '../../routes';
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";

const fileTypeOptions = [
  {text: 'xlsx', value: 'xlsx'},
  {text: 'csv', value: 'csv'},
  {text: 'tsv', value: 'tsv'}
];

const SendStatus = (props) => (
  <Container center>
    { props.success ?
      <Container center column>
        <h5>Your report is now processing! You should receive an email to download shortly.</h5>
        <Container>
          <Button onClick={props.done}>Done</Button>
          <Button primary as={Link} to={routes.reportsDownload}>View Report Status</Button>
        </Container>
      </Container> :
      <Container center column>
        <h5>This is awkward, but there was an error sending your report.</h5>
        <Container>
          <Button onClick={props.reset}>Try Again</Button>
          <Button onClick={props.done}>Cancel</Button>
        </Container>
      </Container>
    }
  </Container>
);

const SendForm = (props) => {
  const [email, setEmail] = useState(props.username || "");
  const [success, setSuccess] = useState(undefined);
  const [startDate, setStartDate] = useState(moment(props.startDate));
  const [endDate, setEndDate] = useState(moment(props.endDate));
  const [fileType, setFileType] = useState('xlsx');
  const [filter_id, setFilterId] = useState(props.match.params.id || "");
  const [reportTypes, setReportTypes] = useState([]);
  const [overThirtyDays, setOverThirtyDays] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);

  const onReset = () => {
    setEmail("");
    setSuccess(undefined);
    setStartDate(moment(props.startDate));
    setEndDate(moment(props.endDate));
    setFileType('xlsx')
  }

  useEffect(() => {
    getReportTypes()
    .then(reportTypes => setReportTypes(reportTypes))
  }, [])

  useEffect(() => {
    endDate.diff(startDate, 'days') > 61 ? setOverThirtyDays(true) : setOverThirtyDays(false);
  }, [startDate, endDate])

  const onSubmit = (evt) => {
    const { exportType, tiers, pixel_source_name } = props;
    const reportType = reportTypes.find((r) => r.report_type === exportType);

    const obj = {
      report_type_id: reportType.id,
      destination: email,
      end_date: endDate.format('YYYY-MM-DD'),
      start_date: startDate.format('YYYY-MM-DD'),
      advertiser: pixel_source_name,
      filter_id,
      filters: formatTiersValue(tiers),
      file_type: fileType
    }

    for (let key of Object.keys(obj['filters'])) {
      if (!obj['filters'][key]) {
        continue
      }
      obj['filters'][key] = Array.isArray(obj['filters'][key]) ? obj['filters'][key].map(tier => urlDecodeTierStr(tier)) : urlDecodeTierStr(obj['filters'][key])
    }

    sendReportingData(obj).then(x => {
      setSuccess(true);
    }).catch(x => {
      setSuccess(false);
    })

    // // track in rockerbox & intercom
    const trackObj = {
      type: exportType,
      conversion_id: filter_id,
      end_date: endDate.format('YYYY-MM-DD'),
      start_date: startDate.format('YYYY-MM-DD'),
      pixel_source_name: pixel_source_name,
      file_type: fileType
    }
    track('download-report', trackObj);

    evt.preventDefault()
  }

  const validate = () => {
    let success = true;

    const validate_email = address => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(address);
    };

    email.split(',').forEach(e => {
      e = e.trim();
      if (!validate_email(e)) success = false;
    });

    if(endDate.diff(startDate, 'days') > 61) {
      success = false
    }

    return success
  };

  const onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    if (newStartDate) {
      setStartDate(newStartDate);
    }
    if (newEndDate) {
      setEndDate(newEndDate);
    }
  };

  const handleOutsideRange = day => {
    const maxDays = 61;
    const yesterday = moment.utc().utcOffset(-5)
      .subtract(1, "days")
      .endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(endDate.format());
    const startDateCopy = moment(startDate.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return beforeMin || afterMax || afterSixty;
  };

  if (success !== undefined) return (<SendStatus {...props} success={success} reset={onReset} />)

  return (
    <Container>
      <Form onSubmit={onSubmit} warning={overThirtyDays}>
        <Form.Field>
          <label>Email</label>
          <Input value={email} name="recipient" placeholder="email" onChange={(e,data) => setEmail(data.value)} icon="envelope outline"
          iconPosition="left"/>
        </Form.Field>
        <Form.Group key={"dates"}>
          <Form.Field>
            <label>{"Date Range"}</label>
            <DateRangePicker
              onDatesChange={onDatesChange}
              onFocusChange={data => setFocusedInput(data)}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              noBorder={true}
              isOutsideRange={d => handleOutsideRange(d)}
              hideKeyboardShortcutsPanel
              startDateId="deliveryStartDate"
              endDateId="deliveryEndDate"
              minimumNights={0}
            />
          </Form.Field>
        </Form.Group>
        <Form.Select options={fileTypeOptions} value={fileType} onChange={(e,data) => setFileType(data.value)} label="File type"/>
        <Button type="submit" disabled={!validate()}>Send Report</Button>
        <Message warning>
          <p>Report exports are limited to a range of 60 days. If you need reports for a longer range, you can submit multiple reports for 60 days each.</p>
        </Message>
      </Form>
    </Container>
  )
}

const SendReportForm = (props) => {
  return <SendForm {...props} send={props.send} />
}

SendReportForm.propTypes = {
  // reset: PropTypes.func.isRequired,
}

export default SendReportForm;
