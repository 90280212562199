import React, { Component, useEffect } from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';
import _ from 'lodash';

import CacheContext from '../../utils/CacheContext';
import { updateBatchTrigger } from '../../utils/api';
import { Form, Icon, Menu, Button } from 'semantic-ui-react';
import { IndexGrid, ContentCard, SplitLayout } from '@rockerbox/styleguide';

const JOB_COLUMNS = [
  {display: "Name", key: "name"},
  {display: "Job", key: "job"},
  {display: "Segments", key: "filter_id"}
]
const Jobs = ({ groupId, jobs }) => {

  // TODO: upgrade this to support multiple jobs

  const [showForm, setShowForm] = React.useState(false)
  const [backfillConfig, setBackfillConfig] = React.useState("")
  const [state, setState, Context] = React.useContext(CacheContext);

  useEffect(()=>{
    const config = jobs && jobs[0] && jobs[0]['backfill_config'] || null;
    if (config) {
      setBackfillConfig(JSON.stringify(config, null, 2));
    }
  }, [groupId]);

  const submitForm = () => {
    const newJobs = _.cloneDeep(jobs);
    newJobs[0]['backfill_config'] = JSON.parse(backfillConfig);
    updateBatchTrigger(groupId, { jobs: newJobs })
      .then(() => {
        setBackfillConfig("")
        setShowForm(false)
        setState({triggers: undefined})
      })
  }

  jobs.map(job => {
    try {
      if (job['backfill_config'] && job['backfill_config']['work']) {
        job['job'] = job['backfill_config']['work']['pipeline_name'] || job['backfill_config']['work']['func_name'];
        job['filter_id'] = job['backfill_config']['work']['filter_id'];
      }
    } catch(e) {}
  })

  const editColumns = {display: "", key: "edit", as: IndexGrid.IconButton, onClick: (data) => {
    setBackfillConfig(JSON.stringify(jobs[0]['backfill_config'] || {}, false, 2))
    setShowForm(true)
  }}

  return <React.Fragment>
    <ContentCard title={"Then run the following re-aggregations:"} hasTable>
      { !showForm && <IndexGrid data={jobs} cols={[...JOB_COLUMNS, editColumns]} /> }
      { showForm && <Form>
          <AceEditor
            mode="json"
            theme="tomorrow"
            setOptions={{
              highlightGutterLine: false,
              showPrintMargin: false,
              showGutter: true,
              highlightActiveLine: false,
              fontSize: 14,
              wrapEnabled: true
            }}
            width={'100%'}
            height={'530px'}
            value={backfillConfig}
            style={{marginTop: 15}}
            onChange={setBackfillConfig}
          />
          <Button onClick={submitForm}>Save</Button>
        </Form>
      }
    </ContentCard>

  </React.Fragment>
}

export default Jobs;
