import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Dropdown, Button, Checkbox } from 'semantic-ui-react';

const Settings = ({ wrapper, title, key_name, get, getOptions, save}) => {

  const [settings, setSettings] = React.useState([])
  const [options, setOptions] = React.useState([])
  const [hasLoaded, setLoaded ] = React.useState(false)

  React.useEffect(() => {
    get().then(resp => {
      setSettings(resp.response.map(row => row[key_name]));
      setLoaded(true);
    })
  }, [])

  React.useEffect(() => {
    getOptions().then(resp => setOptions(resp.response))
  }, [])

  React.useEffect(() => {
    if(hasLoaded) { submit()}
  }, [settings])

  const prepare = () => settings.map(item => { return { [key_name]: item }});
  const submit= () => save(prepare());
  const addOrRemove = (value) => {
    const newSettings = (settings.includes(value)) ?
      settings.filter(v => v != value) :
      settings.concat([value])

    console.log(newSettings)
    setSettings(newSettings)
  }
  const Wrapper = wrapper ? wrapper : ContentCard

  return <Wrapper title={title}>
    <React.Fragment>
      {options.map(c => {
        return <div style={{paddingTop: 10, paddingBottom: 10}}>
          <Checkbox toggle label={c.text} checked={!!settings.find(item => item == c.value)} onChange={() => addOrRemove(c.value)}/>
        </div>
      })}
    </React.Fragment>
  </Wrapper>
}

export default Settings;
