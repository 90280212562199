import { useEffect } from 'react'
import { getPlatformPerformanceSyncStatus } from '../api/platformPerformanceApi'
import { yesterday, monthAgo } from '../utils/time'

const PlatformPerformanceSync = ({ platform, setPercent, onComplete }) => {

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPlatformPerformanceSyncStatus(platform, monthAgo, yesterday)
      .then(data => {
        const checkedData = data.filter(c => {
          // how to evaluate no_data? 
          if(c.load_status == "success") return true
          if(c.total_count === 0) return true
          if(c.extract_status == "error" || c.transform_status == "error" || c.load_status == "error") return true
        })
      
        setPercent((checkedData.length/30)*100)

        if (checkedData.length == 30) onComplete()
      })
    }, 20000)
  
    return () => clearInterval(intervalId)
  }, [])

  return null
}

export default PlatformPerformanceSync
