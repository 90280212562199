import { useContext, useMemo, useEffect } from 'react'
import CacheContext from '../../../utils/CacheContext'
import { getArtifacts } from '../../api/attribution'

const HelpDocsSuggestions = ({ location }) => {
  const { pathname } = location
  const [state, setState, Context] = useContext(CacheContext)
  const { helpdocs_suggestions } = Context.getCache(`artifacts`, getArtifacts) || {}

  const helpDocsSuggestions = useMemo(() => {
    if (!helpdocs_suggestions) return null
    return JSON.parse(helpdocs_suggestions || '{}')
  }, [helpdocs_suggestions])

  const helpDocsSuggestionsForPath = useMemo(() => {
    if (!helpDocsSuggestions || !pathname) return null
    return helpDocsSuggestions.find(({ route }) => pathname.includes(route))?.suggestions
  }, [helpDocsSuggestions, pathname])

  useEffect(() => {
    if (!window.hdlh) return
    window.hdlh = {
      ...window.hdlh,
      suggestions: helpDocsSuggestionsForPath,
    }
    !!window.Lighthouse && window.Lighthouse.reload()
  }, [helpDocsSuggestionsForPath])

  return null
}

export default HelpDocsSuggestions
