import React, { useMemo, useEffect, useState, useContext } from 'react';
import { SplitLayout } from '@rockerbox/styleguide';
import { Tab, Table, Dropdown } from 'semantic-ui-react';

import { ComposedChart, AreaChart, LineChart } from 'recharts';
import { ReferenceLine, Area, Line, Scatter } from 'recharts';
import { Label, Tooltip, Legend, YAxis, XAxis } from 'recharts';

const Overview = ({ selectedData }) => {

  return <>
      <SplitLayout
        leftWidth={6}
        rightWidth={10}
        rightContent={<>
          <h5>Predicted Revenue by Channel</h5>

          <AreaChart data={selectedData.stackable_decomp} width={600} height={150} >
            <YAxis />
            <XAxis dataKey="DATE" hide />
            <Tooltip />
            { selectedData.features.map(({ value }) => 
                <Area stackId="1" dataKey={value} stroke="blue" fill="blue" dot={<></>}/>
              )
            }
          </AreaChart> 
        </>}
        leftContent={<>
          <h4>Summary of results</h4>
          Based on the performance of your marketing channels and historical budget allocation, 
          we have calculated the Predicted ROAS baseed on the average amount of spend per day in
          a channel.
          
          <Table>
            <tr>
              <td><th>Channel / Strategy</th></td>
              <td><th>Predicted ROAS (at Avg. Spend)</th></td>
              <td><th>Average Spend</th></td>
            </tr>
            { selectedData.raw.roi_mean
                .map(row => (
                  <tr>
                    <td>{ row.rn }</td>
                    <td>{ row.roi_mean }</td>
                    <td>{ selectedData.training_stats[row.rn].mean }</td>
                  </tr>
                )) 
             }
          </Table>
        </>
      }
      />
  </>
}

export default Overview;
