// Potential tier names for supported platforms
export const PLATFORM_NAMES = {
  "facebook": ["facebook", "instagram", "fb\/ig", "fb-ig"],
  "adwords": ["google", "gdn", "youtube", "dcm", "adwords"],
  "pinterest": ["pinterest"],
  "snapchat": ["snapchat"],
  "tiktok": ["tiktok"],
  "bing": ["bing"],
  "linkedin": ["linkedin"],
}

export const EXCLUDED_TIERS = [
  'unmapped.events',
  'referring.domain',
  'www',
  '\.com',
  'referral',
  'organic',
  'email',
  'sms',
  'newsletter',
  'influencer',
  'customer experience',
  'cx',
  'survey',
  'promo.code',
  'coupon',
  'direct',
]
