import React from "react"
import { Header, Button, Container, Image } from 'semantic-ui-react'
import { adAutoParamsImage } from '@rockerbox/styleguide'

export const NoComparisonSetup = ({ onClick, platform }) => (
  <Container textAlign="center">
    <div style={{ display: 'flex', padding: '60px 0', alignItems: 'center', justifyContent: 'center' }}>
      <div>
        <Image src={adAutoParamsImage} centered style={{ height: '160px' }} />
      </div>
      <div style={{ padding: '0 40px', textAlign: 'left' }}>
        <Header as='h1' style={{ fontWeight: 'normal' }}>
          Conversion Comparisons
        </Header>
        <p style={{ width: '400px', fontSize: '16px'}}>
          Compare how your {platform.charAt(0).toUpperCase() + platform.slice(1)}  data compares to Rockerbox’s.
          Create a comparison between your Rockerbox and {platform.charAt(0).toUpperCase() + platform.slice(1)} conversions  by clicking the button below. 
        </p>
        <Button primary onClick={() => onClick(true)}>Create a comparison...</Button>
      </div>
    </div>
  </Container>
)
