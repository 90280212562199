import React, { useEffect, useState } from "react"
import { Header } from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { SplitLayout, IndexGridNew } from '@rockerbox/styleguide';
import { VennDiagram, NoDataCat, SectionHeader } from "../../../components";
import KeyInsights from "./KeyInsights";
import PlatformSummary from "./PlatformSummary";
import { Loading, cols, revCols, FilterHeader } from "../parts";
import { generateTopInsights, generateVennItem, processMetricsData, selectCustomerTypeMetrics } from "./helpers";
import Filter from './Filter'
import { usePathOverlaps } from "./hooks";
import { track, time } from '../../../../utils/tracking'

const Overlap = (props) => {
  const { platform, headerText } = props // Basic params
  const { startDate, endDate, onDateChange, firstReportingDate } = props // Dates
  const { segOptions, segId, setSegId } = props // // Conversions params
  const { ntfFilter, setNtfFilter, hasNtf } = props // NTF
  const { integrations, tierColors, hasRevenue } = props // Other
  const {display_name:platformDisplayName, icon:platformIcon, color:platformColor} = integrations?.[platform] || {}

  const customerTypeSuffix = ntfFilter == 'all' ? "" : `_${ntfFilter}`
  const mainMetric = hasRevenue ? 'revenue' : 'count'
  const colorMap = Object.keys(tierColors).reduce((acc, key) => {
    acc[key.toLowerCase()] = tierColors[key];
    return acc;
  }, []);

  //DATA
  const { pathsProcessed, overlapChannels } = usePathOverlaps(platform, startDate, endDate, segId)
  const [highLevel, setHighLevel] = useState(undefined)
  const [overlapMetricsData, setOverlapMetricsData] = useState(undefined)
  const [keyInsightsMetricsData, setKeyInsightsMetricsData] = useState(undefined)

  const [bubbleClicked, setBubbleClicked] = useState(undefined)
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [carouselChange, setCarouselChange] = useState(false)
  const [loading, setLoading] = useState(true)
  const [displayCols, setDisplayCols] = useState([...cols])

  useEffect(() => {
    setLoading(true)
  }, [startDate, endDate, segId, platform])

  useEffect(() => {
    if (hasRevenue) {
      const updateCols = [... displayCols.slice(0, 2), ...revCols, displayCols[displayCols.length - 1]]
      setDisplayCols(updateCols)
      return
    }
    setDisplayCols([...cols])
  }, [hasRevenue])

  //PROCESS DISPLAY DATA
  useEffect(() => {
    if (!overlapChannels || !pathsProcessed || !platformDisplayName || !ntfFilter) return;
    if (overlapChannels.length == 0) {
      setLoading(false);
      return;
    }

    //Platform data
    colorMap[platform] = platformColor;
    const platformObj = selectCustomerTypeMetrics(pathsProcessed.platform, customerTypeSuffix);
    const platformVennObj = generateVennItem([platform], platformObj[`total_${mainMetric}`], `${platformDisplayName}`, colorMap);
    platformVennObj.size *= 2 // adjust platformVennObj to scale 100% larger to avoid unintentional overlap amongst channels

    //Overlap data
    const { highLevelOverlaps, keyInsights } = generateTopInsights(
      overlapChannels, pathsProcessed, customerTypeSuffix, mainMetric, // for sorting channels
      platformObj, platform, colorMap, setBubbleClicked // for Venn data
    );

    const metricsData = overlapChannels.flatMap((channelKey) => {
      const customerTypeObj = selectCustomerTypeMetrics(pathsProcessed[channelKey], customerTypeSuffix);
      if (customerTypeObj?.overlap_count == 0 || !customerTypeObj?.overlap_count) return []; // Exclude 0 overlaps by customer types
      return processMetricsData(channelKey, platform, customerTypeObj, platformObj, colorMap);
    });

    const keyInsightsMetrics = keyInsights.map((channel) => metricsData.find((x) => x.key === channel));

    setOverlapMetricsData(metricsData);
    setHighLevel([platformVennObj, ...highLevelOverlaps]);
    setKeyInsightsMetricsData(keyInsightsMetrics);
    setLoading(false);
  }, [pathsProcessed, overlapChannels, ntfFilter, platformDisplayName])

  useEffect(() => {
    if (!keyInsightsMetricsData) return;
    const clickedIndex = keyInsightsMetricsData.findIndex((element) => element.key === bubbleClicked[0])
    if (clickedIndex !== carouselIndex) {
      setCarouselIndex(clickedIndex)
    }
  }, [bubbleClicked, keyInsightsMetricsData])
  
  useEffect(() => {
    if (!keyInsightsMetricsData) return;
    if (carouselChange) {
      setBubbleClicked([keyInsightsMetricsData[carouselIndex].key])
      setCarouselChange(false)
    }
  }, [carouselIndex, carouselChange, keyInsightsMetricsData])

  useEffect(() => {
    if (!platform || !startDate || !endDate) return
    if (!!loading) {
      time(`channels.digital_advertising.overlap.view`)
      return
    }
    track(`channels.digital_advertising.overlap.view`, {
      platform,
      start_date: startDate,
      end_date: endDate,
    })
  }, [platform, startDate, endDate, loading])

  return (
    <div>
      <Filter {...{
        loading, platform, // data
        startDate, endDate, onDateChange, firstReportingDate, // dates
        ntfFilter, setNtfFilter, hasNtf, // ntf
        segOptions, segId, setSegId // segments
      }} />
      <FilterHeader {...{ headerText }} />
      <div>
        {loading && <Loading />}
        {
          ((overlapChannels && overlapChannels.length === 0) ||
          (overlapMetricsData && overlapMetricsData.length === 0) &&
          !loading) &&
          <NoDataCat message={<>No Data<p style={{ fontSize: '16px' }}>No overlap was found between the platform and other marketing channels</p></>}/>
        }
        {overlapChannels && overlapChannels.length != 0 && overlapMetricsData && overlapMetricsData.length > 0 && highLevel && !loading && (
          <React.Fragment>
            <SplitLayout
              leftWidth={7}
              leftContent={
                <div style={{ height: '100%', padding: 18 }}>
                  <Header as="h1" style={{ fontWeight: 300, margin: 0 }}>
                    {' '}
                    Key Insights{' '}
                  </Header>
                  <Carousel
                    selectedItem={carouselIndex}
                    showArrows={false}
                    showIndicators={keyInsightsMetricsData.length > 1 ? true : false}
                    onChange={(index) => {
                      setCarouselIndex(index);
                      setCarouselChange(true);
                    }}
                    renderIndicator={(onClickHandler, isSelected, index, label) =>
                      <li className={`dot ${isSelected ? 'selected' : ''}`} onClick={onClickHandler}>
                        {index+1}
                      </li>
                    }
                  >
                    {keyInsightsMetricsData.map((data) => (
                      <KeyInsights {...{ platformDisplayName, platformColor, data, hasRevenue }} />
                    ))}
                  </Carousel>
                </div>
              }
              rightWidth={9}
              rightContent={
                <VennDiagram
                  id={platform}
                  data={highLevel}
                  clicked={bubbleClicked}
                  height={340}
                />
              }
            />
            <SectionHeader content='Channel Overlap Breakdown' style={{ marginTop: 10, marginBottom: 0 }} />
            <PlatformSummary {...{ platformIcon, platformDisplayName, platformMetricsObj: selectCustomerTypeMetrics(pathsProcessed?.platform, customerTypeSuffix), hasRevenue }} />
            {overlapMetricsData && <IndexGridNew sortable cols={displayCols} data={overlapMetricsData} topRight style={{ marginTop: 0 }} textAlign='right' defaultSortCol='count_overlap' />}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Overlap;
