import axios from 'axios';

const NAMESPACE = 'exports';

export const postExportRequest = (params) => {
  return axios.post(`/${NAMESPACE}/report`, params)
}

/**
 *
 * @returns {PromiseLike<any> | Promise<any>}
 */
 export function getExportRequestsForAdvertiser() {
  const url = `/${NAMESPACE}/report?request_origin=ui`;
  return axios.get(url)
    .then(resp => resp.data);
}

/**
 *
 * @returns {PromiseLike<any> | Promise<any>}
 */
 export function getExportRequestStatus(report_request_id) {
  const url = `/${NAMESPACE}/report/status/${report_request_id}`;
  return axios.get(url)
    .then(resp => resp);
}


