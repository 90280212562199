export const mostCommon = (filteredCustomerPaths) => {
  let channelArr = []
  const channelCount = filteredCustomerPaths.reduce((p,c) => {
    c.path.map(channel => {
      const channelsJoined = channel.join('| ')
      const keyExists = p[channelsJoined] 

      if(keyExists) {
        p[channelsJoined] += c.count
      } else {
         p[channelsJoined] = c.count
      }
    })
    return p
  }, {})
  
  for (const key in channelCount) {
    channelArr.push({
      path: [key.split('|')],
      count: channelCount[key]
    })
  }

  return topThreeSort(channelArr, 'count', false)
}

export const highestEarners = (filteredCustomerPaths) => topThreeSort(filteredCustomerPaths, 'avg_revenue', false)

export const fastestMovers = (filteredCustomerPaths) => topThreeSort(filteredCustomerPaths, 'avg_seconds', true)

export const highRevenueLowVolume= (filteredCustomerPaths) => {
  const filteredPaths = filteredCustomerPaths.filter(c => c.deltaRevenue > 0 && c.deltaConversions < 0)
  return topThreeSort(filteredPaths, 'deltaRevenue', false)
}

export const highVolumeLowRevenue = (filteredCustomerPaths) => {
  const filteredPaths = filteredCustomerPaths.filter(c => c.deltaRevenue < 0 && c.deltaConversions > 0)
  return topThreeSort(filteredPaths, 'deltaRevenue', true)
}

export const mostPopular = (newCustomers) => {
  const filteredPaths = newCustomers
    .filter((c => {
      const tier_1 = c.path.map(channel => channel[0])
      return !(c.path.length > 15 && c.count < 2) && !tier_1.includes('Direct')
    }))
  return topThreeSort(filteredPaths, 'count', false)
}

const topThreeSort = (data, sortBy, ascending) => {
  const topThree = data
    .sort((a, b) => {
      if (ascending) return a[sortBy] - b[sortBy]
      return b[sortBy] - a[sortBy]
    })
    .slice(0, 3)
  return topThree
}
