import React, { Component } from "react";
import { IndexGrid, ContentCard } from "@rockerbox/styleguide";
import { Button, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as routes from "../../routes";
import { crossDeviceDelete } from "../../utils/api";

const tableNameMapping = {
  served_source_hash_ip_urls: "Live Pixel",
  hash_ip_events_source_tiers: "Batch Data"
};

const NameMap = ({ item, col }) => {
  return <Table.Cell>{tableNameMapping[item.event_table_name]}</Table.Cell>;
};

const CrossDeviceMainTable = props => {
  const { events, loading, loadCrossDeviceData } = props;

  const ManageCell = ({ item, col }) => {
    const onDelete = () => {
      crossDeviceDelete(item.id).then(data => loadCrossDeviceData());
    };

    return (
      <Table.Cell>
        <IndexGrid.EditButton
          url={`${routes.ottIndex}/event/edit/${item.id}`}
        />
        <IndexGrid.DeleteButton onDelete={onDelete} />
      </Table.Cell>
    );
  };

  const cols = [
    { display: "Vendor", key: "event_source" },
    { display: "Event Type", as: NameMap },
    { display: "Manage", as: ManageCell }
  ];

  return (
    <React.Fragment>
      <ContentCard
        hasTable
        title="OTT Event Settings"
        loading={loading}
        style={{ marginBottom: "10px" }}
        topRight={
          <React.Fragment>
            <Button
              primary
              compact
              size="mini"
              icon="plus"
              content="OTT Event Settings"
              as={Link}
              style={{ marginRight: "10px" }}
              to={routes.ottIndex + "/event/create"}
            />
          </React.Fragment>
        }>
        <IndexGrid data={events} cols={cols} />
      </ContentCard>
    </React.Fragment>
  );
};

export default CrossDeviceMainTable;
