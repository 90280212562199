import React from 'react'
import ContentLoader from 'react-content-loader'
import { List } from 'semantic-ui-react'

const ListLoader = ({ numItems, showBorder=true, animate=true }) => (
  <List
    divided={!!showBorder}
    relaxed
    className='stat-list'
    style={{
      height: 191,
      marginRight: 5,
      marginLeft: 5,
    }}
  >
    {[...Array(numItems)].map((_, i) => (
      <List.Item
        key={i}
        className='list-loader'
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <ContentLoader
            viewBox="0 0 93 26"
            foregroundColor="#e5e5e5"
            height={26}
            width={'100%'}
            {...{ animate }}
          >
            <circle cx="11.56" cy="12.31" r="11.56"/>
            <path d="M87.4,17.18H38.41c-2.21,0-4-1.79-4-4v-1.74c0-2.21,1.79-4,4-4H87.4c2.21,0,4,1.79,4,4v1.74 C91.4,15.39,89.61,17.18,87.4,17.18z"/>
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            viewBox="0 0 60 26"
            foregroundColor="#e5e5e5"
            height={26}
            width={'100%'}
            {...{ animate }}
          >
            <path d="M53.73,18.22H6.2c-2.21,0-4-1.79-4-4V10.4c0-2.21,1.79-4,4-4h47.53c2.21,0,4,1.79,4,4v3.81 C57.73,16.43,55.94,18.22,53.73,18.22z"/>
          </ContentLoader>
        </div>
      </List.Item>
    ))}
  </List>
)

export default ListLoader


