import React, { useContext, useState, useMemo } from 'react'
import { Header, Button } from 'semantic-ui-react'
import { DownloadIcon } from '@heroicons/react/outline'
import json2csv from 'json2csv'

import { CacheContext } from '../../../../utils/CacheContext'
import { track } from '../../../../utils/tracking'
import { TreeTableLoader } from '../../../components/loaders'
import { ModelSectionHeader } from '../stateless'
import { roundNumber } from '../helpers'
import DateDropdown from './dateDropdown'
import Forecast from './Forecast'


const BudgetForecast = ({ features, responseCurves, featureStats, seasonality }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const { account } = state
  const { advertiser_name } = account || {}

  const [data, setData] = useState()
  const [dateRange, setDateRange] = useState()

  const responseCurvesArr = useMemo(() => {
    if (!responseCurves) return
    return Object.entries(responseCurves).map(([k, v]) => ({
      key: k,
      values: v,
    }))
  }, [responseCurves])

  const featureRecommendations = useMemo(() => {
    if (!featureStats) return
    return Object.entries(featureStats).map(([k, v]) => ({
      key: k,
      recommended: v?.recommendedSpend,
      current: v?.currentSpend,
    }))
  }, [featureStats])

  const featureNameMap = useMemo(() => (
    features.reduce((acc, x) => Object.assign(acc, {[x.value]: x.name}), {})
  ), [features])

  const loading = useMemo(() => (
    !responseCurvesArr?.length || !featureRecommendations?.length
  ), [responseCurvesArr, featureRecommendations])

  const formatDataForExport = (data) => {
    if (!data) return []
    const { forecastRows, baseline, summary } = data
    const { numDays } = dateRange
    const formattedData = forecastRows.map((row) => {
      const { key, targetValue, revenueAtTargetValue, revenueAtCurrentValue } = row
      const totalSpend = targetValue*numDays
      const predictedRevenue = revenueAtTargetValue*numDays
      const currentRevenue = revenueAtCurrentValue*numDays
      const predictedRoas = predictedRevenue / totalSpend
      const currentRoas = currentRevenue / totalSpend
      return {
        'Channel / Tactic': featureNameMap[key],
        'Current Daily Spend': roundNumber(featureStats[key].currentSpend),
        'Target Daily Spend': roundNumber(targetValue),
        'Total Spend': roundNumber(totalSpend),
        'Predicted Revenue': roundNumber(predictedRevenue),
        'Revenue vs Current': roundNumber(predictedRevenue - currentRevenue),
        'Predicted ROAS': isNaN(predictedRoas) ? 0 : roundNumber(predictedRoas, 2),
        'ROAS vs Current': isNaN(currentRoas) ? 0 : isNaN(predictedRoas) ? 0 : roundNumber(predictedRoas - currentRoas, 2),
      }
    })
    formattedData.push({
      'Channel / Tactic': 'Marketing Summary',
      'Current Daily Spend': roundNumber(summary.dailySpend),
      'Target Daily Spend': roundNumber(summary.targetDailySpend),
      'Total Spend': roundNumber(summary.totalSpend),
      'Predicted Revenue': roundNumber(summary.predictedRevenue),
      'Revenue vs Current': roundNumber(summary.revenueDiff),
      'Predicted ROAS': roundNumber(summary.predictedRoas, 2),
      'ROAS vs Current': roundNumber(summary.roasDiff, 2),
    })
    if (baseline > 0) {
      formattedData.push({
        'Channel / Tactic': 'Seasonality Adjustment',
        'Predicted Revenue': roundNumber(baseline),
      })
      formattedData.push({
        'Channel / Tactic': 'Total Summary',
        'Predicted Revenue': roundNumber(summary.predictedRevenue + baseline),
      })
    }
    return formattedData
  }

  const exportCSV = () => {
    const parser = new json2csv.Parser()
    const formattedData = formatDataForExport(data)
    const csv = parser.parse(formattedData)

    const csvContent = `data:text/csv;charset=utf-8,${csv}`
    const fileName = `${advertiser_name} Budget Worksheet (${dateRange?.start} - ${dateRange?.end}).csv`

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    track('modeling.mmm.budget_worksheet.download')
  }

  return (<>
    <ModelSectionHeader
      text={<>
        Marketing Budget Worksheet
        {!loading && <DateDropdown {...{ dateRange, setDateRange }} />}
      </>}
    >
      {!loading &&
        <Button
          inverted
          size='large'
          color='purple'
          content={<>
            <DownloadIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
            {` Download Worksheet (CSV)`}
          </>}
          onClick={exportCSV}
          className='remove-box-shadow'
          style={{
            margin: 0,
            marginLeft: 'auto',
          }}
        />
      }
    </ModelSectionHeader>
    <Header
      as='h4'
      content="For each Channel / Tactic, adjust your Planned Daily Spend and we'll recalculate Predicted Revenue and ROAS. When you're done, you can export the worksheet to use in your budget planning."
      style={{ marginBottom: 22, fontWeight: 400 }}
    />
    {!loading
      ? <Forecast {...{ seasonality, responseCurves: responseCurvesArr, featureRecommendations, features, featureStats, dateRange, setData }} />
      : <TreeTableLoader />
    }
  </>)
}

export default BudgetForecast
