import * as aq from "arquero";

import { TIERS } from '../../../constants/tiers'
import { fillDailyPerformanceMissingDates } from '../helpers'

export const validateDates = (mainArray, ...comparedArrays) => {
  const results = comparedArrays.flatMap(datesArr => {
    const evalSet = new Set(datesArr)
    return [...mainArray].filter(date => !evalSet.has(date))
  })
  const missingDates = [...new Set(results)].sort()
  return missingDates
}

export const buildComparison = (platformData, platformDates, tiersData, tiersDates, assisted, assistedWindow, ttcDates, expectedDateRange) => {

  const hasData = platformData?.size && tiersData?.size && assisted?.size && assistedWindow?.size
  const hasDates = platformDates && tiersDates && ttcDates
  
  if (!expectedDateRange || expectedDateRange.length == 0) return {}
  const missingDates = hasDates && validateDates(expectedDateRange) // historically was validating against platformDates, tiersDates, ttcDates

  if (!hasData || !hasDates) return {}
  if (hasData && hasDates && missingDates.length > 0) return { missingDates }

  const JOIN_KEYS = [...TIERS, "date"]

  const simpleTiersDataRollup = {
    "conversions_even_rb": `(d) => op.sum(d.conversions_even_rb || 0)`,
    "conversions_first_touch_rb": `(d) => op.sum(d.conversions_first_touch_rb || 0)`,
    "conversions_last_touch_rb": `(d) => op.sum(d.conversions_last_touch_rb || 0)`,
    "revenue_even_rb": `(d) => op.sum(d.revenue_even_rb || 0)`,
    "revenue_first_touch_rb": `(d) => op.sum(d.revenue_first_touch_rb || 0)`,
    "revenue_last_touch_rb": `(d) => op.sum(d.revenue_last_touch_rb || 0)`,
  }

  if (tiersData.columnNames().includes("conversions_normalized_rb")) {
    Object.assign(simpleTiersDataRollup, {
      "conversions_normalized_rb": `(d) => op.sum(d.conversions_normalized_rb || 0)`,
      "revenue_normalized_rb": `(d) => op.sum(d.revenue_normalized_rb || 0)`,
    })
  }

  // tiersData needs to be initially grouped to avoid overcounting platform Data
  const groupedTiersData = tiersData
    .groupby(...JOIN_KEYS)
    .rollup(simpleTiersDataRollup)

  const joined = platformData
    .join_left(groupedTiersData, [JOIN_KEYS, JOIN_KEYS])
    .join_left(assisted, [JOIN_KEYS, JOIN_KEYS])
    .join_left(assistedWindow, [JOIN_KEYS, JOIN_KEYS])

  const rollupInit = {
    "cpa": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions)`,
    "cpa_assisted_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_assisted_rb)`,
    "roas_assisted_rb": `(d) => op.sum(d.revenue_assisted_rb || 0)/op.sum(d.spend || 0)`,
    "cpa_assisted_att_win_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_assisted_att_win_rb)`,
    "roas_assisted_att_win_rb": `(d) => op.sum(d.revenue_assisted_att_win_rb || 0)/op.sum(d.spend || 0)`,
    "cpa_even_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_even_rb)`,
    "roas_even_rb": `(d) => op.sum(d.revenue_even_rb || 0)/op.sum(d.spend || 0)`,
    "cpa_first_touch_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_first_touch_rb)`,
    "roas_first_touch_rb": `(d) => op.sum(d.revenue_first_touch_rb || 0)/op.sum(d.spend || 0)`,
    "cpa_last_touch_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_last_touch_rb)`,
    "roas_last_touch_rb": `(d) => op.sum(d.revenue_last_touch_rb || 0)/op.sum(d.spend || 0)`,
  }

  
  if (joined.columnNames().includes("revenue")) {
    Object.assign(rollupInit, {
      "roas": `(d) => op.sum(d.revenue || 0)/op.sum(d.spend || 0)`,
    })
  }

  if (joined.columnNames().includes("conversions_normalized_rb")) {
    Object.assign(rollupInit, {
      "cpa_normalized_rb": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions_normalized_rb)`,
      "roas_normalized_rb": `(d) => op.sum(d.revenue_normalized_rb || 0)/op.sum(d.spend || 0)`,
    })
  }

  const rollup = joined
    .select(aq.not(JOIN_KEYS))
    .columnNames()
    .reduce((obj, name) => {
      if (["cpa","roas"].includes(name)) return obj
      obj[name] = `(d) => op.sum(d['${name}'] || 0)`
      return obj
    }, rollupInit)

  const daily = joined.select(aq.not(TIERS))
    .groupby("date")
    .rollup(rollup)
  
  const dailyComparisonData = fillDailyPerformanceMissingDates(expectedDateRange, daily.objects())
  
  return {
    missingDates,
    comparisonData: joined.objects(),
    dailyComparisonData
  }

}


