import React from 'react'
import { Button, Icon, Grid, GridRow, Input, Label, GridColumn } from 'semantic-ui-react'

const InputFormMonthlySpend = ({isEdit, promoCode, monthlyAdSpendFormatted, setIsEdit, data, setData, onSubmit}) => {

    const EditButton = props => (
        <Button
          onClick={() => {
            setIsEdit((prev) => !prev);
            }}
          {...props}
          style={{color: '#475ddc'}}
        >
          <Icon name='pencil' />
          Edit
        </Button>
      );
  
    if (!isEdit && !promoCode || (promoCode && !isEdit) ) {
        return (
            <>
              <p className='monthly-spend'> How much do you spend on advertising each month? </p>
              <p className='monthly-spend-value'> ${monthlyAdSpendFormatted}<EditButton className='monthly-spend-value-edit-button'/></p>
            </>
          );
    } else {
        return (
      <div className='input-form-edit-mode'>
        <Grid>
            <GridColumn width={16}>
                <GridRow>
                    <p className='how-much-do-you-spend'> How much do you spend on advertising each month? </p>
                </GridRow>
                <GridRow>
                    <form style={{marginTop: '15px', marginLeft: '-145px'}}>
                    <Input 
                        placeholder='$500,000'
                        onChange={e => setData(e.target.value)}
                        value={data}
                        type="number" 
                        max={25000000}
                        min={1}
                        className='monthly-spend-input'
                        labelPosition='left'
                    >
                        <Label className='monthly-spend-input-label' basic style={{backgroundColor: '#bfc6f3', color: '#475ddc'}}>$</Label>
                        <input />
                    </Input>
                        <Button
                        type="submit"
                        size='large'
                        color='purple'
                        content='Submit'
                        disabled={data < 1 || data > 25000000}
                        onClick={onSubmit}
                        style={{verticalAlign: 'middle', height: '38px'}}
                        />
                    </form>
                </GridRow>
            </GridColumn>
        </Grid>
      </div>
  )}
    
  };
  export default InputFormMonthlySpend;
  