import { useState, useEffect } from 'react';

export const useKeyPress = (targetKey) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler(e) {
    const { key, preventDefault } = e
    if (key === targetKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setKeyPressed(true);
      return false
    }
  }

  // If released key is our target key then set to false
  const upHandler = (e) => {
    const { key, preventDefault } = e
    if (key === targetKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setKeyPressed(false);
      return false
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
