import { tableFromIPC } from "apache-arrow";
import * as aq from "arquero";

const requestKey = (date, dataset, identifier) => `${date}${dataset}${identifier}`

export const ipcBufferToArrow = (ipcBuffer) => {
  return aq.fromArrow(ipcBuffer); 
}

export const getDataValidInCache = (db) => async (date, dataset, identifier, id) => {
  const tx = db.transaction(['request_cache_id','cache_id_data'], 'readwrite');
  const request_cache = tx.objectStore('request_cache_id');

  const request = requestKey(date, dataset, identifier)
  const result = await request_cache.get(request)
  const { cache_id = false } = result || {}

  const hasCache = cache_id == id
  return { date, dataset, identifier, cache_id, id, hasCache }

}

export const getDataFromCache = (db) => async ({ date, dataset, identifier, cache_id }) => {
  const tx = db.transaction(['cache_id_data'], 'readonly');
  const cache_data = tx.objectStore('cache_id_data');

  const cached = await cache_data.get(cache_id)
  const { buffer } = cached
  const data = tableFromIPC(buffer)
  return { buffer, data, date, dataset, identifier, cache_id }
}

export const clearInvalidCache = (db) => ({ date, dataset, identifier, cache_id }) => {
  const tx = db.transaction(['request_cache_id','cache_id_data'], 'readwrite');
  const request_cache = tx.objectStore('request_cache_id');
  const cache_data = tx.objectStore('cache_id_data');

  const request = requestKey(date, dataset, identifier)

  return Promise.all([
    cache_data.delete(cache_id),
    request_cache.delete(request)
  ])
}

export const setCacheData = (db) => ({ buffer, date, dataset, identifier, id }) => {
  const tx = db.transaction(['request_cache_id','cache_id_data'], 'readwrite');
  const request_cache = tx.objectStore('request_cache_id');
  const cache_data = tx.objectStore('cache_id_data');

  const request = requestKey(date, dataset, identifier)

  return Promise.all([
    request_cache.put({ request, cache_id: id }),
    cache_data.put({ cache_id: id, buffer })
  ])

}
