import React from 'react'
import { Popup, Grid, Segment, Header, Image, Dropdown } from 'semantic-ui-react'
import { sponsorshipsMainIcon } from '@rockerbox/styleguide'
import styled from "styled-components";

const Scale = styled.div`
  cursor: pointer;

  & h4.ui.header {
    font-size:1em;
    & img.image {
      max-height: 30px;
      width:auto;
    }
  }
  & .ui.segment {
    margin-bottom:5px;
  }
`

const OptionButtons = ({ options, defaultValue, onChange }) => {
  const [selected, setSelected] = React.useState(defaultValue)
  const [selectedCategory, setSelectedCategory] = React.useState(false)
  const [init, setInit] = React.useState(true)

  React.useEffect(() => {
    setSelected(defaultValue)
    setSelectedCategory(
      (options.find(({ value }) => value == defaultValue) || {}).category
    )
  }, [defaultValue])

  React.useEffect(() => {
    if (init) {
      setInit(false)
      return
    }

    onChange(false, { value: selected })
  }, [selected])

  const filteredOptions = React.useMemo(() => {
    if (!selectedCategory) return options
    return options.filter(({ category }) => category == selectedCategory)
  }, [selectedCategory])

  const categories = React.useMemo(() => {
    const cats = [...new Set(options.map(x => x.category))]
    return cats.map(x => Object({key: x, text: x, value: x}))
  }, [options])

  return (
    <Scale scale={.9}>
    <Header as='h3' style={{ fontSize: 14, margin: '0 2px 0 2px' }}>
      Analysis:{" "}
      <Dropdown
         inline
         options={categories}
         value={selectedCategory}
         onChange={(_, { value }) => setSelectedCategory(value)}
       />
     </Header>
    <Grid columns={4} className='sponsorships-preset-queries'>
      <Grid.Row stretched>
        {filteredOptions.map(x => (
          <Grid.Column>
            <Popup
              inverted
              position="bottom center"
              content={ x.description }
              trigger={
                <Segment
                  color={selected == x.value && 'blue'}
                  onClick={() => setSelected(x.value)}
                  className={selected == x.value && 'selected'}
                >
                  <Header as='h4' content={x.text} image={x.icon} />
                </Segment>
              } />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
    </Scale>
  )
}

export default OptionButtons
