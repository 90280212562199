import React, { Component } from 'react';
import { Statistic, Button, Form, Icon, Divider } from 'semantic-ui-react';
import { getEventTags } from './../../utils/api';
import { StatCard, IndexGrid } from '@rockerbox/styleguide';
import { PaddedLabel } from './form';

const STYLE = {marginBottom: 10}
const rowStyle = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0px',
  paddingBottom: '0px'
}

const COLS = [
  {display: "From", key: "input"},
  {display: "count", key: "num_inputs"},
  {display: "To", key: "output"},
  {display: "count", key: "num_outputs"},
  {display: "links", key: "num_links"},
]

const EventExclusions  = ({ conversionSegmentId, eventExclusionTags, handleSetEventExclusion, handleAddEventExclusion, handleRemoveEventExclusion }) => {
  const [eventTags, setEventTags] = React.useState([]);

  React.useEffect(() => {
    getEventTags()
      .then((data) => {
        const tags = data.reduce((p,c) => {
          p[c.name] = {key: c.name, value: c.name, text: c.name}
          return p
        }, {})
        
        setEventTags(Object.values(tags))
      })
  }, [])


  return (
    <div>
      <PaddedLabel>Exclude Marketing Events</PaddedLabel>
      <Button size="small" content="add new exclusions" icon="plus" type="button" onClick={handleAddEventExclusion} style={STYLE}/>
      { eventExclusionTags.map((tag, i) => {
          return <div style={rowStyle}>
            <Form.Select options={eventTags} value={tag.name} onChange={handleSetEventExclusion(i)} />
            <Button as='a' icon='remove' size='small' onClick={handleRemoveEventExclusion(i)} />
          </div>
        }) 
      }
    </div>
  )
}

export default EventExclusions;
