import React, { useState, useEffect } from "react";
import moment from "moment";
import { sendSavedReport } from "../../utils/api";
import Container from "../parts/Container";
import {
  Button,
  Modal,
  Form,
  Dropdown,
  Message,
  Popup,
  Icon
} from "semantic-ui-react";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";
import { CloseButtonStyles } from "../styled";

const typeOptions = [
  { key: "csv", value: "csv", text: "csv" },
  { key: "tsv", value: "tsv", text: "tsv" },
  { key: "xlsx", value: "xlsx", text: "xlsx" }
];

const SendSavedReportModal = props => {
  const { savedReports, username, outboxes, triggerReload, resetTimerCount, segmentIdNameMap, reportTypes } = props;

  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(moment.utc().utcOffset(-5).subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment.utc().utcOffset(-5).subtract(1, "days"));
  const [focusedInput, setFocusedInput] = useState(null);
  const [filetype, setFiletyle] = useState("tsv");
  const [savedReportId, setSavedReportId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successPage, setSuccessPage] = useState(false);

  const reportTypeIdNameMap = reportTypes ? reportTypes.reduce((prev, cur) =>{
    prev[cur.id] = cur.name;
    return prev;
  }, {}) : {}

  const savedReportOptions = savedReports.map(report => {
    return { key: report.id, text: `${report.name} || ${segmentIdNameMap[report.filter_id] || "None"} || ${reportTypeIdNameMap[report.report_type_id] || "None"}`, value: report.id };
  });

  useEffect(() => {
    setEmail(username);
  }, []);

  const validate = () => {
    return startDate && endDate && email && filetype;
  };

  const onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    if (newStartDate) {
      setStartDate(newStartDate);
    }
    if (newEndDate) {
      setEndDate(newEndDate);
    }
  };

  const handleOutsideRange = day => {
    const maxDays = 60;
    const yesterday = moment.utc().utcOffset(-5)
      .subtract(1, "days")
      .endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(endDate.format());
    const startDateCopy = moment(startDate.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return beforeMin || afterMax || afterSixty;
  };

  const onFormSubmit = () => {
    const spaceOutbox = outboxes.filter(
      outbox => outbox.outbox_type === "do_spaces"
    )[0];

    const parseDate = str => moment(str).format("YYYY-MM-DD");

    const requestBody = {
      email: email,
      file_type: filetype,
      outbox_id: spaceOutbox ? spaceOutbox.id : null,
      saved_report_id: savedReportId,
      start_date: parseDate(startDate),
      end_date: parseDate(endDate)
    };

    setLoading(true);
    setError(false);

    return sendSavedReport(requestBody)
      .then(() => {
        setLoading(false);
        setSuccessPage(true);
        setTimeout(triggerReload, 1000);
        resetTimerCount();
      })
      .catch(err => {
        setLoading(false);
        setError(true);
        setTimeout(triggerReload, 1000);
        resetTimerCount();
      });
  };

  const formFieldsAndButton = (
    <React.Fragment>
      <Form.Group key={"savedReport"}>
        <Form.Field width={9}>
          <label>{"Saved Report"}</label>
          <Form.Dropdown
            selection
            options={savedReportOptions}
            value={savedReportId}
            onChange={(e, data) => setSavedReportId(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"destination"}>
        <Form.Field>
          <label>{"Email"}</label>
          <Form.Input
            value={email}
            onChange={(e, data) => setEmail(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"dates"}>
        <Form.Field>
          <label>{"Date Range"}</label>
          <DateRangePicker
            onDatesChange={onDatesChange}
            onFocusChange={data => setFocusedInput(data)}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            noBorder={true}
            isOutsideRange={d => handleOutsideRange(d)}
            hideKeyboardShortcutsPanel
            startDateId="deliveryStartDate"
            endDateId="deliveryEndDate"
            minimumNights={0}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"filetype"}>
        <Form.Field>
          <label>{"File Type"}</label>
          <Form.Dropdown
            selection
            options={typeOptions}
            value={filetype}
            onChange={(e, data) => setFiletyle(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Button type="submit" disabled={!validate()}>
        Send Saved Report
      </Button>
    </React.Fragment>
  );

  return (
    <Form onSubmit={() => onFormSubmit()} loading={loading} error={error}>
      <Message
        error
        header="Failed to send this saved report"
        content="Please try with a different date or contact us."
      />
      {successPage ? (
        <Container center column>
          <h5>
            Your report is now processing! You can view the status shortly.
          </h5>
          <Container>
            <Button onClick={props.done}>Done</Button>
          </Container>
        </Container>
      ) : (
        formFieldsAndButton
      )}
    </Form>
  );
};

export default props => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
      {props.title}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <SendSavedReportModal done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
