import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ContentWrap } from '@rockerbox/styleguide';

import GlobalApplicationData from '../../../components/SceneApp/globalApplicationData';
import GlobalErrorBoundary from '../../../components/GlobalErrorBoundary';
import TrackPageChange from '../../../components/TrackPageChange';
import * as routes from '../../routes';
import * as baseRoutes from '../../baseRoutes';

import { Navbar } from '../../components';
import Home from '../Home';
import Settings from '../Settings';
import CommandBar from './CommandBar';
import HelpDocsSuggestions from './HelpDocsSuggestions';

/* Data -> Marketing */
import AdvertisingPlatforms from '../AdvertisingPlatforms';
import PlatformConnectGallery from '../AdvertisingPlatforms/PlatformConnectGallery';
import AdvertisingPlatformLastStep from '../AdvertisingPlatforms/FinalStep';
import AdvertisingTrackingParams from '../AdvertisingTrackingParams';

/* Data -> Promo Codes */
import PromoCodes from '../PromoCodes';
import PromoCodeCreate from '../PromoCodes/CreatePromo';
import PromoCodeEdit from '../PromoCodes/EditPromo';

/* Data -> Surveys */
import Surveys from '../Surveys';
import SurveyCreate from '../Surveys/CreateSurvey';
import SurveyEdit from '../Surveys/EditSurvey';

/* Data -> Customers */
import ConversionsOverview from '../ConversionsOverview';
import ConversionsDetail from '../ConversionsDetail';
import ConversionsUserProfile from '../ConversionsUserProfile';

/* Data -> Warehouse */
import DataWarehouse from '../DataWarehouse';
import SetupConnection from '../DataWarehouse/SetupConnection';

/* Data -> Ad Hoc Exports */
import AdhocExportsHistory from '../AdhocExports';

/* Saved Reports */
import SavedReports from '../SavedReports';

/* Channels */
import DigitalAdvertising from '../DigitalAdvertising';
import ChannelOverlap from '../ChannelOverlap';

/* Attribution */
import AttributionReportIndex from '../AttributionReport';

/* Funnel */
import NewVisitors from '../NewVisitors';
import FunnelPosition from '../FunnelPosition';
import MarketingPaths from '../MarketingPaths';

/* Modeling */
import MarketingMixModeling from '../MarketingMixModeling';

/* Benchmarks */
import SpendTrends from '../SpendTrends';
import PlatformBenchmarks from '../PlatformBenchmarks';

const V3 = ({ location }) => (
  <div className="ui-v3">
    <GlobalApplicationData />
    <TrackPageChange path={location.pathname} />
    <Route path="/" component={CommandBar} />
    <Route path="/" component={HelpDocsSuggestions} />
    <Route path="/" component={Navbar} />
    <ContentWrap>
      <GlobalErrorBoundary>
        <Switch>
          <Route exact path={routes.home} component={Home} />

          {/* Saved Reports */}
          <Route exact path={routes.savedReports} component={SavedReports} />

          {/* Marketing Data */}
          <Route exact path={routes.advertisingPlatforms} component={AdvertisingPlatforms} />
          <Route exact path={routes.connectAdvertisingPlatform} component={PlatformConnectGallery} />
          <Route path={routes.advertisingPlatformLastStep} component={AdvertisingPlatformLastStep} />
          <Route path={routes.advertisingTrackingParameters} component={AdvertisingTrackingParams} />

          {/* Promo Codes */}
          <Route exact path={routes.promoCodes} component={PromoCodes} />
          <Route exact path={routes.promoCodesCreate} component={PromoCodeCreate} />
          <Route exact path={routes.promoCodesEdit} component={PromoCodeEdit} />

          {/* Surveys */}
          <Route exact path={routes.surveys} component={Surveys} />
          <Route exact path={routes.surveyCreate} component={SurveyCreate} />
          <Route exact path={routes.surveyEdit} component={SurveyEdit} />

          {/* Customer Data */}
          <Route path={routes.conversionsOverview} component={ConversionsOverview} />
          <Route path={routes.conversionsDetail} component={ConversionsDetail} />
          <Route path={routes.conversionsUserProfile} component={ConversionsUserProfile} />
          {/* <Route path={routes.conversionsConnect} component={AdvertisingPlatformLastStep} /> */}

          {/* Data Warehouse */}
          <Route exact path={routes.datawarehouse} component={DataWarehouse} />
          <Route path={routes.datawarehouseSetup} component={SetupConnection} />

          {/* Ad Hoc Exports */}
          <Route exact path={routes.adHocExportsHistory} component={AdhocExportsHistory} />

          {/* Channels */}
          <Route exact path={routes.digitalAdvertising} component={DigitalAdvertising} />
          <Route exact path={routes.channelOverlap} component={ChannelOverlap} />

          {/* Attribution */}
          <Route exact path={routes.attributionReport} component={AttributionReportIndex} />

          {/* Funnel */}
          <Route exact path={routes.funnelPosition} component={FunnelPosition} />
          <Route exact path={routes.newVisitors} component={NewVisitors} />
          <Route exact path={routes.marketingPaths} component={MarketingPaths} />

          {/* Modeling */}
          <Route exact path={routes.marketingMixModeling} component={MarketingMixModeling} />

          {/* Benchmarks */}
          <Route path={routes.spendTrends} component={SpendTrends} />
          <Route path={routes.platformBenchmarks} component={PlatformBenchmarks} />

          {/* Settings */}
          <Route path={routes.settingsIndex} component={Settings} />

          {/* Default */}
          <Route>
            <Redirect to={baseRoutes.home} />
          </Route>
        </Switch>
      </GlobalErrorBoundary>
    </ContentWrap>
    <Toaster position="top-center" reverseOrder={false} />
  </div>
);

export default V3;
