import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Card, Header, Divider, Grid } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Bar, Line, Tooltip } from 'recharts'
import { ChevronDoubleRightIcon } from '@heroicons/react/outline'

import { ChartTooltip } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getAccountCurrency, getShopifyInfo } from '../../api/account'
import { getSummaryData } from '../../api/attributionCache'
import { buildPath } from '../../hooks/urlState'
import { numberFormatter, decimalFormatter } from './helpers'
import { spendFormatter } from '../../utils/valueFormatter'
import { SummaryStatsLoader, SummaryChartLoader } from './loaders'
import ConversionStat from './ConversionStat'

const ConversionSummary = ({ segment, startDate, endDate, featured }) => {
  const { action_id, action_name } = segment

  const [state, setState, Context] = useContext(CacheContext)
  const currency = Context.getCache(`currency`, getAccountCurrency) || null
  const shopifyInfo = Context.getCache(`shopifyInfo`, getShopifyInfo) || null

  const [loading, setLoading] = useState(true)
  const [summaryData, setSummaryData] = useState(null)

  useEffect(() => {
    setLoading(true)
    getSummaryData(action_id, startDate, endDate)
      .then(data => {
        const dailySummary = data
          .map(({ date, count, revenue }) => {
            const conversions = Object.values(count).reduce((a,b) => a+b, 0)
            const revenueTotal = Object.values(revenue).reduce((a,b) => a+b, 0)
            const averageRevenue = (!!revenueTotal && !!conversions) ? revenueTotal / conversions : 0
            return {
              date,
              conversions,
              revenue: revenueTotal,
              averageRevenue,
            }
          })
          .sort((a,b) => new Date(a.date) - new Date(b.date))
        setSummaryData(dailySummary)
        setLoading(false)
      })
  }, [segment, startDate, endDate])

  const currencyFormatter = useMemo(() => {
    const { currency_code = 'USD' } = currency || {}
    return (x) => spendFormatter(currency_code).format(x)
  }, [currency])

  const isShopifySegment = useMemo(() => {
    if (!action_id || !shopifyInfo) return
    const shopifySegments = Object.values(shopifyInfo.segments)
    return !!shopifySegments.find(x => x === action_id)
  }, [action_id, shopifyInfo])

  const conversionsLabel = useMemo(() => {
    if (!action_name || !isShopifySegment) return 'Conversions'
    if (action_name.includes('Purchase')) return 'Orders'
    return 'Conversions'
  }, [action_name, isShopifySegment])

  const conversionDetailLink = useMemo(() => {
    return buildPath('conversionsDetail', { id: `${action_id}` })
  }, [action_id])

  const [totalConversions, totalRevenue] = useMemo(() => {
    if (!summaryData) return [null, null]
    return [
      summaryData.reduce((a,b) => a+b.conversions, 0),
      summaryData.reduce((a,b) => a+b.revenue, 0)
    ]
  }, [summaryData])

  const averageRevenuePerConversion = useMemo(() => {
    if (!totalRevenue || !totalConversions) return
    return totalRevenue / totalConversions
  }, [totalRevenue, totalConversions])

  const [conversionsTrend, revenueTrend, averageRevenueTrend] = useMemo(() => {
    if (!summaryData) return [{}, {}, {}]
    const numDays = summaryData.length
    const range = numDays < 14 ? 1 : numDays < 60 ? 7 : 30

    const currPeriod = summaryData.slice(-1*range)
    const prevPeriod = summaryData.slice(-1*range - range, -1*range)

    const currConversions = currPeriod.reduce((a,b) => a+b.conversions, 0)
    const prevConversions = prevPeriod.reduce((a,b) => a+b.conversions, 0)
    const currRevenue = currPeriod.reduce((a,b) => a+b.revenue, 0)
    const prevRevenue = prevPeriod.reduce((a,b) => a+b.revenue, 0)
    const currAvgRevenue = !!currConversions ? currRevenue / currConversions : 0
    const prevAvgRevenue = !!prevConversions ? prevRevenue / prevConversions : 0

    return [
      {
        pctChange: (currConversions - prevConversions) / prevConversions,
        range,
      },
      {
        pctChange: !!currRevenue && !!prevRevenue ? (currRevenue - prevRevenue) / prevRevenue : 0,
        range,
      },
      {
        pctChange: !!currAvgRevenue && !!prevAvgRevenue ? (currAvgRevenue - prevAvgRevenue) / prevAvgRevenue : 0,
        range,
      },
    ]
  }, [summaryData])

  return (
    <Card
      fluid
      link
      as={Link}
      to={conversionDetailLink}
    >
      <Card.Content style={{ padding: 0 }}>
        <div style={{ padding: 10 }}>
          <Header as='h2' className='ellipsed'>
            {!!isShopifySegment &&
              <img
                src={`https://images.rockerbox.com/integrations/integrations-icon-shopify.svg`}
                style={{
                  height: 20,
                  width: 20,
                  marginTop: -3,
                  marginRight: 7,
                }}
              />
            }
            {action_name}
          </Header>
        </div>
        <div style={{ padding: '5px 12px' }}>
          {!!loading ? <SummaryStatsLoader {...{ featured }} /> :
            <Grid columns='equal'>
              <ConversionStat
                label={conversionsLabel}
                value={totalConversions}
                formatter={numberFormatter}
                trend={conversionsTrend}
              />
              {!!totalRevenue &&
                <ConversionStat
                  label='Revenue'
                  value={totalRevenue}
                  formatter={currencyFormatter}
                  color='#52d1a1'
                  trend={revenueTrend}
                />
              }
              {!!featured && !!averageRevenuePerConversion &&
                <ConversionStat
                  label={`Average Revenue Per ${conversionsLabel.replace(/s$/, '')}`}
                  value={averageRevenuePerConversion}
                  formatter={currencyFormatter}
                  color='#ffa278'
                  trend={averageRevenueTrend}
                />
              }
            </Grid>
          }
        </div>
        <div style={{ padding: '0 10px 10px' }}>
          {!!loading ? <SummaryChartLoader height={!!featured ? 200 : 100} /> :
            <ResponsiveContainer width='100%' height={!!featured ? 200 : 100}>
              <ComposedChart data={summaryData}>
                <XAxis hide dataKey='date' />
                <YAxis hide yAxisId='conversions' />
                <YAxis hide yAxisId='revenue' />
                <YAxis hide yAxisId='avgRevenue' />

                <Bar
                  dataKey='conversions'
                  yAxisId='conversions'
                  fill='#477cdc'
                />

                {!!totalRevenue &&
                  <Line
                    dataKey='revenue'
                    yAxisId='revenue'
                    type='monotone'
                    stroke='#52d1a1'
                    strokeWidth={3}
                    dot={false}
                  />
                }

                {!!averageRevenuePerConversion &&
                  <Line
                    dataKey='averageRevenue'
                    yAxisId='avgRevenue'
                    type='monotone'
                    stroke='#ffa278'
                    strokeWidth={3}
                    strokeDasharray="3 3"
                    dot={false}
                  />
                }

                <Tooltip
                  cursor={{
                    stroke: '#efefef',
                    strokeWidth: 2,
                    strokeDasharray: '5 5',
                  }}
                  formatter={!!totalRevenue ? decimalFormatter : numberFormatter}
                  content={
                    <ChartTooltip nameMap={{
                      conversions: conversionsLabel,
                      revenue: 'Revenue',
                      averageRevenue: `Average Revenue per ${conversionsLabel.replace(/s$/, '')}`
                    }} />
                  }
                  position={{ y: 0 }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          }
        </div>
        <Divider style={{ margin: 0 }} />
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}  >
          <Link to={conversionDetailLink} >
            <h3 className='hover-opacity-75'>
              {`View ${conversionsLabel} `}
              <ChevronDoubleRightIcon
                style={{ display: 'inline', height: 14, marginBottom: -2 }}
              />
            </h3>
          </Link>
        </div>
      </Card.Content>
    </Card>
  )
}

export default ConversionSummary
