import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Icon } from 'semantic-ui-react';
import { Grid, Statistic, Popup } from 'semantic-ui-react';
import { SplitLayout, ContentCard, StatCard } from '@rockerbox/styleguide';
import { Aux } from '../stateless';
import SummaryTable from './table';
import SummaryBar from '../ViewAttribution/SummaryBar';
import CacheContext from '../../utils/CacheContext';


const formatter = new Intl.NumberFormat('en-US');
const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const Left = ({ segments, selected }) => {
  const filteredSegments = segments.filter(({ action_id }) => selected.includes(action_id))

  const dailyTs = filteredSegments.filter(s => s.daily ).map(s => {
    return { name: s.action_name, ts: s.daily.conversionsByDate || [] }
  }).reduce((p,c) => {
    c.ts.map(v => {
      p[v.date] = p[v.date] || {}
      p[v.date]['date'] = v.date
      p[v.date][c.name] = v.total
    })
    return p
  }, {})

  const values = Object.values(dailyTs).sort((p,c) => d3.descending(p.date,c.date) )

  return (
    <ContentCard
      title="Conversions"
      borderless
      fullHeight
      textAlign="center"
    >
      {
        filteredSegments.filter(s => s.daily).length == filteredSegments.length && values.length ?
          <SummaryBar data={values} exclude={[]} stacked={true} />
        :
          !filteredSegments || !filteredSegments.length || filteredSegments.filter(s => s.daily).length < filteredSegments.length ?
            <ContentCard.CardLoader />
          :
            <ContentCard.EmptyView
              fallBackMsg="No data available yet"
              description="Please allow 24 hours after setup for data to appear in your dashboard"
            />
      }
    </ContentCard>
  )
}
const Right = ({ segments, selected }) => {
  const { count, revenue } = segments
    .filter(x => x.exclude_summary ? false : !!x.daily )
    .filter(({ action_id }) => selected.includes(action_id))
    .reduce((p,c) => {
      p.count += c.total_conversions || 0;
      if (c.include_revenue) p.revenue += c.total_revenue || 0;

      return p
    }, { count: 0, revenue: 0})

  const [ {currency_code} ] = React.useContext(CacheContext);

  return (
    <Aux>
      <Grid fluid='true'>
        <Grid.Row stretched>
          <Grid.Column>
          <StatCard value={count} label='Conversions' explainer='Past 30 Days' />
          <StatCard value={revenue} label='Revenue' type='currency' currencyCode={currency_code} explainer='Past 30 Days' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Aux>
  )
}



class C extends Component {

  state = {
    selectedSegments: [],
    selectAll: true
  }

  componentDidMount() {
    const { segments } = this.props;
    if (segments.length > 0) {
      const selectedSegments = segments.map(x => x.action_id)
      this.setState({ selectedSegments })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.segments.length > 0 || prevProps.segments == this.props.segments) return;

    const { segments } = this.props;
    const selectedSegments = segments.map(x => x.action_id)
    this.setState({ selectedSegments })

  }

  onCheck = (data) => {

    const originalSelectedSegments = this.state.selectedSegments

    const selectedSegments = data.checked ?
      originalSelectedSegments.concat([data.value]) :
      originalSelectedSegments.filter(x => x != data.value)

    this.setState({ selectedSegments }, () => this.checkSelectAllStatus())
  }

  checkSelectAllStatus = () => {
    const segments = this.props.segments.length;
    const selectedSegments = this.state.selectedSegments.length;
    if(segments > selectedSegments) {
      this.setState({selectAll: false})
    } else {
      this.setState({selectAll: true})
    }
  }

  handleSelectAll = (data) => {
    const originalSelectedSegments = this.state.selectedSegments;

    if(data.checked) {
      const all_segments = this.props.segments
      const { selectedSegments } = this.state
      const sameSegments = all_segments.map(x => x.action_id);

      this.setState({selectAll: true, selectedSegments: sameSegments})
    } else {
      this.setState({selectAll: false, selectedSegments: []})
    }
  }

  render() {
    const {nonChildrenSegments, filteredSegments, hasChildSegments } = this.props
    const all_segments = this.props.segments
    const { selectedSegments } = this.state
    const segments = all_segments.filter(x => selectedSegments.indexOf(x.action_id) > -1)

    return (
      <Aux>
        <SplitLayout
          stretched
          leftWidth={10}
          leftContent={<Left {...{segments: nonChildrenSegments}} selected={this.state.selectedSegments} />}
          rightWidth={6}
          rightContent={<Right {...{segments: nonChildrenSegments}} selected={this.state.selectedSegments} />}
        />
        <SummaryTable onCheck={this.onCheck} segments={filteredSegments} selected={this.state.selectedSegments} selectAll={this.state.selectAll} handleSelectAll={this.handleSelectAll} value={this.props.value} handlePastDaysChange={this.props.handlePastDaysChange} showChildren={this.props.showChildren}
        handleShowChildren={this.props.handleShowChildren} hasChildSegments={hasChildSegments}/>

      </Aux>
    )
  }
}

export default C;
