import React, { useMemo } from 'react';
import { ContentCard, ProgressBarGrid } from '@rockerbox/styleguide';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { useParams } from 'react-router-dom';
import { HorizontalBarLoader } from '../../components/loaders'
import { NoDataCat } from '../../components';

const localeOptions = {'minimumFractionDigits': 0, 'maximumFractionDigits': 2}

const groupByAgg = (data, by, agg) => {
  const keyFunc = (row) => by.map(key => row[key]).join(" → ")
  const rolledMap = d3.nest()
    .key(keyFunc)
    .rollup(agg)
    .map(data)

  return Object.entries(rolledMap).map(([key,obj]) => {
    return Object.assign({ key },obj)
  })
}

const agg = (values) => {
  const spend = d3.sum(values, row => row.spend);
  const num_new_visitors = d3.sum(values, row => row.num_new_visitors);
  const total_page_views = values.reduce((sum,row) => sum + Math.round(row.num_pageviews_mean*row.num_new_visitors), 0);
  
  return {
    spend,
    num_new_visitors,
    num_pageviews_mean: total_page_views/num_new_visitors,
    cpa: spend/num_new_visitors,
    reciprocal_cpa: 1/(spend/num_new_visitors)
  }
}

const ProgressCard = ({data, reportType, spendFormatter, loading}) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = useParams();

  const groupby = useMemo(() => {
    const TIERS = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"]
    const tiersLength = [tier_1, tier_2, tier_3, tier_4, tier_5].filter(row => row).length 
    
    return TIERS.slice(0, tiersLength + 1)
  }, [tier_1, tier_2, tier_3, tier_4, tier_5])

  const sortBy = {
    cpa: (p,c) => (p.cpa||Infinity) - (c.cpa||Infinity),
    engagement: (p,c) => c.num_pageviews_mean - p.num_pageviews_mean,
  }

  const valueCol = {
    'cpa': 'cpa',
    'engagement': 'num_pageviews_mean'
  }

  const cols = {
    cpa: [
      {display: "tier_1", key: "key"},
      {display: false, key: "reciprocal_cpa"},
      {display: "Cost per Visitor", key: "cpa", formatter: spendFormatter.format},
      {display: "Spend", key: "spend", formatter: spendFormatter.format},
    ],
    engagement: [
      {display: "tier_1", key: "key"},
      {display: "Average Pageviews", key: "num_pageviews_mean", localeOptions},
      {display: "Visitors", key: "num_new_visitors"},
    ]
  }

  const rolledData = groupByAgg(data, groupby, agg)
    .sort(sortBy[reportType])
    .filter(d => d[valueCol[reportType]] > 0);

  const totalKey = reportType == 'cpa' ? 'reciprocal_cpa' : 'num_pageviews_mean';
  const total = d3.max(d3.keys(rolledData).map(d => rolledData[d][totalKey] != Infinity ? rolledData[d][totalKey] : 0));

  return (
    <ContentCard title="Top Performers" scrollY borderless hasTable>
      <div style={{height: 349}}>
        {loading 
          ? <HorizontalBarLoader />
          : !!rolledData.length
            ? <ProgressBarGrid cols={cols[reportType]} total={total} data={rolledData} secondaryColor="lightGrey"/>
            : <NoDataCat message={<>Selected Channels<br />have no spend data</>}/>
        }
      </div>
    </ContentCard>
  )
}

export default  ProgressCard
