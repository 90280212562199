import { eventTypesMap, eventsTypeCols } from './constants'
import * as d3 from 'rockerbox_d3_legacy_clone'

const notEnhanced = ['Direct', 'Links and Clicks']

const aggEventsSummary = (eventTypesData, totalConvs, totalRevs, currency, tooltips) => {
    const eventTypeCols = eventsTypeCols(totalRevs, tooltips)
    const eventTypeData = d3.nest()
        .key(row => (eventTypesMap[row['event_type']] || 'Other'))
        .entries(eventTypesData)
        .flatMap(({ key, values }) => {
            const eventType = key
            const positive = !notEnhanced.includes(key)
            const conversionsSum = values.reduce((p, row) => p + row.total_orders, 0)
            const conversions = conversionsSum.toLocaleString('en-US')
            const convRatio = (conversionsSum / totalConvs).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })
            if (conversionsSum === 0) {
                return []
            } 
            if (totalRevs != 0) {
                const revenueSum = values.reduce((p, row) => p + row.total_revenue, 0)
                const revenue = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, maximumFractionDigits: 2 }).format(revenueSum)
                const revRatio = (revenueSum / totalRevs).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })
                return [{ eventType, conversions, convRatio, revenue, revRatio, positive }]
            }
            return [{ eventType, conversions, convRatio, positive }]
        })
    return { cols: eventTypeCols, values: eventTypeData }
}

export default aggEventsSummary