import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Segment, List, Image } from 'semantic-ui-react'
import { CursorClickIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { ShareIcon, DatabaseIcon } from '@heroicons/react/solid'

import { PieChartLoader, ListLoader } from '@rockerbox/styleguide'
import { CacheContext } from '../../../../utils/CacheContext'
import { getAllIntegrations } from '../../../api/account'
import { buildPath } from '../../../hooks/urlState'
import { PLATFORMS } from '../../AdvertisingPlatforms/constants'
import { generateColors } from './helpers'
import { DonutChart } from '../../../components'

const platformPerformancePlatforms = Object.keys(PLATFORMS)

const SpendBreakdown = ({ loading, data, currencyFormatter }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const allIntegrations = Context.getCache(`allIntegrations`, getAllIntegrations) || []

  const formatCurrency = currencyFormatter(2)

  const totalSpend = useMemo(() => 
    !!data && data.reduce((acc, x) => acc + x.spend, 0)
  , [data])

  const colors = useMemo(() =>
    !!data && generateColors(data.length)
  , [data])

  const platforms = useMemo(() => {
    if (!data || !allIntegrations) return null
    return data.map(x => {
      const platformInfo = allIntegrations.find(y => y.platform === x.platform) || {}
      return {
        ...x,
        ...platformInfo,
        title: platformInfo.display_name || x.platform.replaceAll('_', ' ').replaceAll('-', ' '),
        icon: !!platformInfo.icon_white_img && `https://images.rockerbox.com/integrations/${platformInfo.icon_white_img}`,
        defaultIcon: platformInfo.category == 'advertising.digital'
        ? CursorClickIcon
        : platformInfo.category == 'advertising.offline'
        ? SpeakerphoneIcon
        : platformInfo.category == 'advertising.affiliate'
        ? ShareIcon
        : DatabaseIcon
      }
    })
  }, [data, allIntegrations])

  const colorMap = platforms?.reduce((acc, plat, i) => {
    const { platform } = plat
    const color = colors[i]
    return {...acc, [platform]: color}
  }, {})

  if (!!loading) {
    return (
      <Segment style={{ height: 575 }}>
        <PieChartLoader />
        <ListLoader numItems={5} />
      </Segment>
    )
  }

  return (
    <Segment style={{ height: 575 }}>
      <DonutChart 
        height={340}
        innerRadius='80%'
        outerRadius='95%'
        paddingAngle={1}
        data={platforms}
        dataKey="spend"
        nameKey="platform"
        colors={colorMap}
        showTooltip
        formatter={formatCurrency}
        showTotal
        labelForTotal='Total Spend'
      />
      <List
        divided
        relaxed
        className='stat-list'
        style={{
          overflowY: !!platforms && platforms.length > 5 ? 'scroll' : 'hidden',
          height: 191,
          marginRight: 5,
          marginLeft: 5,
        }}
      >
        {!!platforms && platforms.map(({ title, platform, spend, icon, defaultIcon }, i) => 
          <List.Item
            as={Link}
            to={platformPerformancePlatforms.includes(platform)
              ? buildPath('digitalAdvertising', { view: 'comparison', platformName: platform })
              : buildPath('attributionReport')
            }
          >
            <List.Icon
              name='circle'
              size='large'
              verticalAlign='middle'
              style={{
                color: colors[i]
              }}
            />
            {!!icon ?
              <Image
                avatar
                src={icon}
                className='platform-icon'
              />
              :
              <Image
                avatar
                as={defaultIcon}
                className='platform-icon'
              />
            }
            <List.Content>
              <List.Header as='a'>
                {title}
              </List.Header>
              <List.Description as='a'>{formatCurrency(spend)}</List.Description>
            </List.Content>
          </List.Item>
        )}
      </List>
    </Segment>
  )
}

export default SpendBreakdown
