import React from 'react';
import { connect } from 'react-redux';
import { push, withRouter } from 'react-router-dom';

import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

import { SET_OUTBOXES } from '../../constants';

const GET_OUTBOXES_ENDPOINT = '/outbox';

const err = () => console.log("error")

function mapStateToProps (state, props) {
  return {
    outboxes: state.reports.outboxes
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    setOutboxes: (outboxes) => dispatch(rootAction(SET_OUTBOXES, outboxes)),
  }
}

const Component = (props) => [
  <Fetch error={err} endpoint={GET_OUTBOXES_ENDPOINT} success={props.setOutboxes} />
]

const OutboxData = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component))

export default OutboxData;
