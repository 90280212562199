import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

import { getMessages } from './../../utils/api';
import CacheContext from '../../utils/CacheContext.js'

const GenericMessage = (props) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const messages = Context.getCache(`messages`, getMessages) || []

  const { style, message, as } = props
  const activeMessage = messages.find(({ name }) => name == message) || {}
  const { header, text, link } = activeMessage;

  if (!text) return null;

  const content = () => (
    <>
      <div dangerouslySetInnerHTML={ { __html: text }} />
      { link && <><br/><a href={link} target="_blank">Learn More ⤏</a></> }
    </>
  )
  
  const Component = as || Message
  
  return <Component {...{ key: message, header, content, style}} />
}

export default GenericMessage;
