import React, { useState, useEffect} from 'react'
import { getLegacyPaths } from '../../api/attributionCache'
import { getTierColors } from '../../api/account'
import { buildNestedData, filterCustomerPath, buildTiers, summarizePaths } from './helpers'

export const useCustomerPaths = (filtersAndDates, filterTouchpoints, filterTouchpointsMethods) => {
  const { id, startDate, endDate, customerType, tier } = filtersAndDates
  const { minPathLen, any, first, last } = filterTouchpoints
  const { setAny, setFirst, setLast } = filterTouchpointsMethods

  const [pathsData, setPathsData] = useState(undefined)
  const [allNested, setAllNested] = useState({})
  const [ntfNested, setNtfNested] = useState({})
  const [returnNested, setReturnNested] = useState({})
  const [resolved, setResolved] = useState(false)
  const [error, setError] = useState(false)
  const [customerPaths, setCustomerPaths] = useState([])
  const [tiers, setTiers] = useState({})
  const [firstTiers, setFirstTiers] = useState({})
  const [lastTiers, setLastTiers] = useState({})
  const [summaryStats, setSummaryStats] = useState({overallSummary: [], filteredSummary: [], percentOfTotal: {}})
  const [newCustomers, setNewCustomers] = useState([])
  const [hasData, setHasData] = useState(false)
  

  const customerTypeMapping = {
    all: { key: '', nestedObj: allNested },
    new: { key: '_new_to_file', nestedObj: ntfNested },
    repeat: { key: '_returning', nestedObj: returnNested }
  }

  useEffect(() => {
    if((!id || id == 0) || !tier || !startDate || !endDate) return;
    setResolved(false)
    setHasData(false)
    getLegacyPaths(id, tier, startDate, endDate)   
      .then(data => { 
        data.map(row => {
          row.count_returning = row.count - row.count_new_to_file
          row.revenue_returning = row.revenue - row.revenue_new_to_file

          const totalSeconds = row.count*row.avg_seconds_til_conversion
          const ntfSeconds = row.count_new_to_file*row.avg_seconds_til_conversion_new_to_file

          row.avg_seconds_til_conversion_returning = (totalSeconds - ntfSeconds)/row.count_returning

        })

        const ntfKeys = (customerType) => ({
          count: `count${customerTypeMapping[customerType].key}`,
          revenue: `revenue${customerTypeMapping[customerType].key}`,
          avg_seconds_til_conversion: `avg_seconds_til_conversion${customerTypeMapping[customerType].key}`
        })

        const allNested = buildNestedData(any, data, ntfKeys('all'))
        const ntfNested = buildNestedData(any, data, ntfKeys('new'))
        const returnNested = buildNestedData(any, data, ntfKeys('repeat'))

        setAllNested(allNested)
        setNtfNested(ntfNested)
        setReturnNested(returnNested)
        setPathsData(data)
        setHasData(true)
        return allNested

      })
      .catch((err) => {
        setError(true)
        setResolved(true)
      })

  }, [id, tier, startDate, endDate])

  useEffect(() => {
    const { originalCustomerPath, originalTiers } = allNested
  
    if (!originalCustomerPath || !ntfNested.originalCustomerPath || !returnNested.originalCustomerPath) return
    if (Object.keys(originalTiers).length !== Number(tier)) return
    const customerPath = filterCustomerPath(any, first, last, minPathLen, customerTypeMapping[customerType].nestedObj)
    const newCustomers = filterCustomerPath(any, first, last, minPathLen, customerTypeMapping['new'].nestedObj)
    const [tiers, updateAny] = buildTiers(any, customerTypeMapping[customerType].nestedObj, 'any')
    const [firstTiers, updateFirst] = buildTiers(first, customerTypeMapping[customerType].nestedObj, 'first')
    const [lastTiers, updateLast] = buildTiers(last, customerTypeMapping[customerType].nestedObj, 'last')
    setTiers(tiers)
    setFirstTiers(firstTiers)
    setLastTiers(lastTiers)
    setAny(updateAny)
    setFirst(updateFirst)
    setLast(updateLast)
    setCustomerPaths(customerPath)
    setNewCustomers(newCustomers)
  }, [first, last, any, customerType, minPathLen, pathsData, allNested, ntfNested, returnNested])

  useEffect(() => {
    const { originalCustomerPath } = allNested
  
    if (!originalCustomerPath || !customerPaths) return
    const overallSummary = summarizePaths(originalCustomerPath || [])
    const filteredSummary = summarizePaths(customerPaths || [])
    const percentOfTotal = {
      paths: filteredSummary.totalPaths/overallSummary.totalPaths*100,
      conversions: filteredSummary.conversions/overallSummary.conversions*100,
      revenue: overallSummary.averageRevenue - filteredSummary.averageRevenue,
      touchpoints: overallSummary.averageLength - filteredSummary.averageLength,
      time: overallSummary.averageTime - filteredSummary.averageTime
    }
    setSummaryStats({overallSummary, filteredSummary, percentOfTotal})
  }, [allNested, customerPaths])

  return {
    pathsData,
    allNested,
    resolved,
    error,
    customerPaths,
    tiers,
    firstTiers,
    lastTiers, 
    summaryStats,
    newCustomers,
    hasData
  }

}

export const useTierColors = () => {
  const [tierColors, setTierColors] = useState(false)

  useEffect(() => {
    getTierColors()
      .then(data => setTierColors(data))
  }, [])

  return {
    tierColors
  }
}


