export function getPlatformPerformanceMeta(platform) {
  const url = `/report_cache/meta/platform_performance_${platform}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .catch(error => console.error(error.message));
}

export function getPlatformPerformanceSummary(platform, startDate, endDate) {
  const url = `/report_cache/summary/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.data)
    .catch(error => console.error(error.message));
}

export function getPlatformPerformanceSyncStatus(platform, startDate, endDate) {
  const url = `/report_cache/sync_status/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.response)
    .catch(error => console.error(error.message));
}

export function getPlatformPerformanceStats(platform, startDate, endDate) {
  const url = `/report_cache/stats/platform_performance_${platform}?start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => response.current)
    .catch(error => console.error(error.message));
}
