import React, { useMemo, useEffect, useState, useContext } from 'react';
import { Tab, Table, Dropdown } from 'semantic-ui-react';
import { SplitLayout } from '@rockerbox/styleguide';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { ComposedChart, AreaChart, LineChart } from 'recharts';
import { ReferenceLine, Area, Line, Scatter } from 'recharts';
import { Label, Tooltip, Legend, YAxis, XAxis } from 'recharts';

import Overview from './Overview';
import Channel from './Channel';
import Optimize from './Optimize';
import Forecast from './Forecast';

const tierColorMap = {}

const SERIES = [
  "pred_decomp","pred_decomp_vs_actual","metrics",
  "response_curves","training_data","roi_mean"
]

export function getModelRuns() {
  let url = `/data_model_run/mmm`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ data }) => data);
}

export function getModelRun(id) {
  let url = `/data_model_run/mmm/${id}`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ data }) => {

      const features = Object.keys(data.pred_decomp
        .reduce(
          (p,c) => Object.assign(p, {[c.media]: 1}),
          {}
        ))
        .map(text => Object.assign({}, {text, value: text}))

      const training_stats = d3.nest()
        .key(x => x.media)
        .rollup(values => {
          const num_spend_days = values.filter(row => row.media_value > 0).length;
          const total_spend = d3.sum(values.map(row => row.media_value));
          const mean = total_spend/num_spend_days;
          return {
            total_spend,
            mean
          }
        })
        .map(data.training_data)

      const stackable_decomp = d3.nest()
        .key(x => x.DATE)
        .rollup(values => values.reduce(
            (row, current) => Object.assign(row, { [current.media]: current.decomp_value}),
            { DATE: values[0].DATE }
          )
        )
        .entries(data.pred_decomp)
        .map(row => row.values)

      return { 
        training_stats,
        features,
        stackable_decomp,
        raw: data
      }
    });
}


const MediaMixModel = () => {

  const [runs, setRuns] = useState();
  const [selectedRun, setSelectedRun] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedFeature, setSelectedFeature] = useState();

  useEffect(() => {
    getModelRuns()
      .then(data => {
        const { model_run } = data
        const [first, ...rest] = model_run
        setRuns(model_run)
        setSelectedRun(first)
      })
  }, [])

  useEffect(() => {
    if (selectedRun) getModelRun(selectedRun)
      .then(data => setSelectedData(data))
  }, [selectedRun])

  const data = useMemo(
    () => {
      if (!selectedData) return [];

      const { raw } = selectedData
      const pred = raw.pred_decomp.filter(row => row.media == selectedFeature)
      const training = raw.training_data.filter(row => row.media == selectedFeature)

      const allData = [...pred, ...training]

      const combined = d3.nest()
        .key(row => row.DATE)
        .rollup(values => values.reduce((p,c) => Object.assign(p,c), {}))
        .entries(allData)
        .map(row => row.values)
        .map(row => Object.assign( row, {
              roi: row.media_value > 10 ? row.decomp_value/row.media_value : 0,
              spend_input: row.media_value,
              decomp_value: row.decomp_value > .01 ? row.decomp_value : 0,

            }
          )
        )

      return combined
    },
    [selectedFeature]
  )

  const response = useMemo(
    () => {
      if (!selectedData) return [];

      const { raw } = selectedData
      const maxMediaValue = d3.max(data.map(({ media_value }) => media_value))

      const pred = raw.response_curves
        .filter(row => row.feature_name == selectedFeature)
        .filter(row => row.spend_input < maxMediaValue)
        .map(row => Object.assign(
            {
              "predicted_roas": row.marginal_response/row.spend_input,
              "predicted_roas_efficient": row.marginal_response/row.spend_input > 1 ? row.marginal_response/row.spend_input : 0
            },
            row
          )
        )

      return [...pred, ...data]
    },
    [selectedFeature]
  )

  const { raw } = selectedData || {}
  const { response_curves } = raw || {}

  const responseCurves = useMemo(
    () => {
      if (!selectedData) return [];

      const { raw: { response_curves }} = selectedData;

      const grouped = d3.nest()
        .key(row => row.feature_name)
        .rollup(values => {
          return values
            .map(row => {
              row.predicted_roas = row.marginal_response/row.spend_input
              return row
            })
            .sort((p,c) => c.spend_input - p.spend_input)
        })
        .entries(response_curves)
      
      return grouped;
    },
    [selectedData]
  )


  const featureRecommendations = useMemo(
    () => responseCurves.map(({ key }) => Object.assign({ key, recommended: 30, current: 200 }) ),
    [responseCurves]
  )

  if (!runs) return <>loading model runs...</>
  if (!selectedRun) return <>getting selected run...</>
  if (!selectedData) return <>getting selected data...</>

  return <>
    <h3>I want to...</h3>
    <Tab 
      menu={{ fluid: true, vertical: true, tabular: true }} 
      panes={[
        {  
           menuItem: "See an overview of my results", 
           render: () => <Overview {...{selectedData}} /> 
        },
        {  
           menuItem: "Better understanding of a specific channel", 
           render: () => <Channel {...{data, response, selectedData, selectedFeature, setSelectedFeature}} /> 
        },
        {  menuItem: "Optimize budget to ROAS goal", 
           render: () => <Optimize {...{selectedData, responseCurves}} />
        },
        {  
           menuItem: "Forecast revenue at budget levels", 
           render: () => <Forecast {...{selectedData, responseCurves, featureRecommendations}} />
        }
      ]} 
    />




    <br />

    {/* SERIES.map(key => {
        if (!selectedData) return <>no data</>

        const data = selectedData[key]
          .map(row => {
            row['date'] = row['DATE']
            delete row['DATE']
            return row
          })

        return <>
          <h5>{ key }</h5>
          { JSON.stringify(data[0]) }
          <br />
        </>
      })
    */}
  </>
}

export default MediaMixModel;

