import React, { Component } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Form, Grid, Input, Button, Container, Message, Table } from 'semantic-ui-react';
import axios from 'axios';
import { getDomains, postDomains } from '../../utils/api';

class AllowedDomains extends Component {
  state = {
    domain: '',
    loading: false,
    error: false,
    domains: []
  }

  componentDidMount() {
    getDomains().then(data => this.setState({domains: data}));
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.domains !== prevState.domains) {
      this.setState({domains: this.state.domains})
    }
  }

  handleChange = (event, data) => {
    const { value } = data;
    this.setState({ domain: value})
  }

  handleSubmit = () => {
    const postObj = {
      domain: this.state.domain.trim(),
    };

    this.setState({loading: true})
    postDomains(postObj)
      .then(response => {
        this.setState({loading: false, domain: '', error: false })
        getDomains().then(data => this.setState({domains: data}))
      })
      .catch(error => {
        this.setState({error: true, loading: false })
      })

  }

  render() {
    const { domain, loading, error, domains } = this.state;
    const cols = [
      {display: 'Domains', key: 'domain'},
    ];

    return (
      <ContentCard title="Allowed Domains">
        <div style={{marginBottom: 14}}>
          <Input
            placeholder="yourDomain.com"
            value={domain}
            action
            onChange={this.handleChange}
            style={{width: 300}}
          >
            <input />
            <Button
              primary
              type="submit"
              onClick={this.handleSubmit}
              disabled={domain === ''}
              loading={loading}
            >Add</Button>
          </Input>
          { error && (
            <Message negative>
              <p>This domain may have already been added</p>
            </Message>
          )}
        </div>
        <PagedIndexGrid data={domains} cols={cols} itemsPerPage={10} collapsed/>
      </ContentCard>
    )
  }
}

export default AllowedDomains;
