import React, { useState } from "react";
import { PagedIndexGrid, ContentCard, IndexGrid } from "@rockerbox/styleguide";
import { Loader, Table } from "semantic-ui-react";
import ProcessBarCell from "./ProgressBarCell";
import { tz, utcOffset } from "./tz";
import { SegmentNameCell } from "./TableCells";

const ProcessingTable = (props) => {
  const { runningJobs, loading, reloading, username, segmentIdNameMap } = props;
  const [activeRow, setActiveRow] = useState({});

  const cols = [
    { display: "Report Type Name", key: "report_type_name" },
    { display: "Segment Name", key: "filter_id", as: SegmentNameCell },
    { display: "Start Date", key: "start_date" },
    { display: "End Date", key: "end_date" },
    {
      display: `Created Time (${tz})`,
      key: "started_at",
      as: IndexGrid.LocalFromUTCTime,
    },
    { display: "Status", key: "status", as: ProcessBarCell },
    {
      display: "Requested For",
      key: "destination",
      as: IndexGrid.EllipsedCell,
    },
  ];

  if (runningJobs.length === 0) {
    return null;
  } else {
    runningJobs.forEach((item) => {
      const filter_id =
        item["params"] &&
        item["params"]["parameters"] &&
        item["params"]["parameters"]["filter_id"];
      item["username"] = username;

      item["segment_name"] = filter_id ? segmentIdNameMap[filter_id] : null;
      item['identifier'] = filter_id;

      if (item["id"] === activeRow["id"]) {
        item["positive"] = true;
      } else {
        item["positive"] = false;
      }
    });
  }

  return (
    <ContentCard
      title={
        <div>
          Processing Reports{" "}
          <Loader
            active={reloading}
            inline
            size="small"
            style={{ marginLeft: "5px" }}
          />
        </div>
      }
      hasTable
      loading={loading}
    >
      <PagedIndexGrid
        data={runningJobs}
        onRowClick={(e) => setActiveRow(e)}
        cols={cols}
        as={ContentCard.Table}
        itemsPerPage={10}
        fallBackMsg="No reports are processing"
        collapsed
        UTCOffset={utcOffset}
      ></PagedIndexGrid>
    </ContentCard>
  );
};

export default ProcessingTable;
