import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Dropdown, Button, Modal, Form, Input } from 'semantic-ui-react';
import { SplitLayout, ContentCard, ColumnLayout, PagedIndexGrid, IndexGrid } from '@rockerbox/styleguide'
import moment from 'moment';
import SummaryBar from '../ViewAttribution/SummaryBar';
import GroupedBarChart from '../Charts/GroupedBarChart';
import { getGAConversionData, getConversionTimeseries, getSegmentCompare, getConversionSegments, postSegmentCompare } from '../../utils/api';
import { connect } from 'react-redux';
import { rootAction } from '../../rootAction';
import { SET_CONVERSION_SEGMENTS } from '../SelectSegment/index';

import TimeRange, { yesterday, week } from '../timerange';

const HEADERS = [
  {display: "Date", key: "date", as: PagedIndexGrid.EllipsedCell, headerWidth: 2},
  {display: "RB Conversions", key: "rb_conversions", as: PagedIndexGrid.EllipsedCell, headerWidth: 2},
  {display: "GA Conversions", key: "ga_conversions", as: PagedIndexGrid.EllipsedCell, headerWidth: 2},
  {display: "Percent Diff", key: "pct_diff_display", as: PagedIndexGrid.EllipsedCell, headerWidth: 2}
]

const helperSort = (a,b,key, order) => {
  if(order == "descending") {
    return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
  } else {
    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  }
}

class GoogleAnalyticsComparison extends TimeRange {
  state = {
    modal: false,
    startDate: week,
    endDate: yesterday,
    loading: false,
    colMap: [],
    mergedData: [],
    dropDownValue: null,
    modalOpen: false,
    segmentOptions: [],
    gaOptions: [],
    gaData: [],
    conversionVal: '',
    gaVal: '',
    name: ''
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.getData();
  }

  onChange({ value }) {
    this.setState({ loading:true, dropDownValue: value });
  }

  changeFocus(value) {
    const { colMap } = this.state
    const selectedItem = colMap.find(r => value == r.name) || {};
    const { filter_id, touchpoints_filter_id, ga_column_name } = selectedItem;
    this.doApiCalls(filter_id, touchpoints_filter_id, ga_column_name);
  }


  componentDidUpdate(prevProps, prevState) {

    const { gaData, startDate, endDate, colMap, touchPointsFilterId, dropDownValue, segmentOptions } = this.state

    const newStart = startDate != prevState.startDate;
    const newEnd = endDate != prevState.endDate;
    const newFocus = (dropDownValue != prevState.dropDownValue || gaData != prevState.gaData)
    const newFilterId = touchPointsFilterId != prevState.touchPointsFilterId;

    if (newFilterId) return this.getColumnMap();
    if (newStart || newEnd) return this.getGAData(segmentOptions, touchPointsFilterId)
    if (newFocus) return this.changeFocus(dropDownValue)

  }


  doApiCalls = (filterId, touchPointsFilterId, gaColumnName) => {

    const { gaData, startDate, endDate } = this.state;

    getConversionTimeseries(filterId, startDate, endDate)
      .then( rbConversionData => {

        const gaDataFiltered = gaData.map( x => {
          return {
            date: x['cache_from'],
            ga_conversions: x[gaColumnName]
          }
        });


        const rbTimeseries = rbConversionData.map( x => {
          return {
            date: x['date'],
            rb_conversions: (x.count['unattributed'] || 0) + (x.count['attributed'] || 0)
          }
        });

        const groupedColData = [
          gaDataFiltered.map( x => {
            return {date: x.date, data: x.ga_conversions, name: 'ga_conversions' }
          })
          .sort((a,b) => helperSort(a,b,"date")),
          rbTimeseries.map( x => {
            return { date: x.date, data: x.rb_conversions, name: 'rb_conversions' }
          })
          .sort((a,b) => helperSort(a,b,"date"))
        ];

        const format = d3.format(".1%f");
        const mergedGaRockerboxData = rbTimeseries.concat(gaDataFiltered);
        const mergedData = d3.nest()
          .key(d => d.date)
          .rollup( (values) => {
            return { ga_conversions: d3.sum(values, (x) => x.ga_conversions || 0), rb_conversions: d3.sum(values, (x) => x.rb_conversions || 0)}
          })
          .entries(mergedGaRockerboxData)
          .map( x => {
            return {
              date: x.key,
              ga_conversions: x.values.ga_conversions,
              rb_conversions: x.values.rb_conversions,
              pct_diff: Math.abs((x.values.rb_conversions-x.values.ga_conversions)/x.values.rb_conversions*100),
              pct_diff_display: format((x.values.rb_conversions-x.values.ga_conversions)/x.values.rb_conversions)}
          })
          .sort((a,b) => helperSort(a,b,"date"));

        this.setState({ mergedData, loading: false});
      });
  }

  getColumnMap = () => {

    getSegmentCompare()
      .then( colMap => {

        if (colMap.length == 0) {
          this.setState({ colMap: false, loading: false })
          return
        }

        colMap.map( x => { return Object.assign(x,{ text: x.name, value: x.name }) });

        const firstElement = colMap[0];
        const dropDownValue = firstElement.name;
        const loading = true;

        this.setState({ colMap, dropDownValue, loading });

      })
  }

  getGAData = (segmentOptions, touchPointsFilterId) => {

    const { startDate, endDate } = this.state;

    getGAConversionData(startDate, endDate, touchPointsFilterId)
      .then( _gaData => {
        if(_gaData) {
          const gaData = _gaData.sort((a,b) => helperSort(a,b,"cache_from", "descending"));
          const gaOptions = Object.keys(gaData[0])
            .filter(c => c !== "cache_from")
            .map(c => { return {key: c, value: c, text: c}});

          this.setState({ gaData, gaOptions, segmentOptions, touchPointsFilterId });
        }
      })
  }

  getData = () => {

    const { startDate, endDate } = this.state;

    getConversionSegments()
      .then(data => {
        const segmentOptions = data
        .filter(segment => segment.action_type == "conversion")
        .map(seg => {
          return{
            key: seg.action_name,
            text: seg.action_name,
            value: seg.action_id
          }
        })
        const touchPointsFilterId = data
        .filter(segment => segment.touchpoints == 1)
        .map(seg => {
          return {
            id: seg.action_id
          }
        })[0]['id']

        this.getGAData(segmentOptions, touchPointsFilterId)

      })
  }

  handleModal = (state) => {
    state === 'open' ?
      this.setState({modalOpen: true}) :
      this.setState({modalOpen: false, conversionVal: '', gaVal: '', name: ''});
  }

  handleFormChange = ({value}, stateSlice) => {
    this.setState({[stateSlice]: value});
  }

  handleSubmit = () => {
    const { gaVal, conversionVal, touchPointsFilterId, name } = this.state;

    const postObj = {
      name,
      filter_id: conversionVal,
      ga_column_name: gaVal,
      touchpoints_filter_id: touchPointsFilterId
    };

    postSegmentCompare(postObj)
      .then(data => {
        this.setState({modalOpen: false, conversionVal: '', gaVal: '', name: ''}, () => this.getColumnMap());
      })
  }

  render() {
    const { dropDownValue, colMap, mergedData, loading, modalOpen, segmentOptions, conversionVal, gaOptions, gaVal, name } = this.state;
    const mergedDataCols = mergedData.length ? HEADERS : [];
    const boundChange = (e,data) => this.onChange(data);
    debugger

    return (
      <React.Fragment>
        <ContentCard
          title={
          <React.Fragment>
            Comparison: &nbsp;
            <Dropdown value={dropDownValue} options={colMap} inline prevntFluid={true} onChange={boundChange} />
            <Button primary compact size="mini" icon="plus" content={'Create'} onClick={() => this.handleModal("open")} />
          </React.Fragment>
          }
          topRight={ this.renderDateSelector() }
          borderless
          disableHeaderEllipse
          noContent
        />
        { colMap === false && (
            <ContentCard> 
              <table style={{width:"100%",textAlign:"center"}}>
                <IndexGrid.EmptyMessage fallBackMsg="No comparisons created" />
              </table>
            </ContentCard>
          )
        }
        { colMap !== false &&
          <React.Fragment>
            <SplitLayout
              leftWidth={8}
              rightWidth={8}
              rightContent={
                <ContentCard
                  title="Percent Difference"
                  borderless
                  textAlign="center"
                >
                  {
                    !loading ?
                    <SummaryBar data={mergedData} exclude={['ga_conversions', 'rb_conversions']} customRange={[0,50]} />
                    :
                    <ContentCard.CardLoader />
                  }
                </ContentCard>
              }
              leftContent={
                <ContentCard
                  title="Conversions"
                  borderless
                  textAlign="center"
                >
                  {
                    !loading ?
                    <SummaryBar data={mergedData} exclude={['pct_diff']} />
                    :
                    <ContentCard.CardLoader />
                  }
                </ContentCard>
              }
            />
            <ContentCard title={`Conversions`} hasTable {...{loading}}>
              <PagedIndexGrid as={ContentCard.Table} fallBackMsg="Loading" cols={mergedDataCols} data={mergedData} idKey="date" itemsPerPage={10} singleLine />
            </ContentCard>
          </React.Fragment>
        }

        <Modal open={modalOpen} onClose={() => this.handleModal("closed")}>
          <Modal.Header>Create GA Comparison</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form onSubmit={this.handleSubmit}>
                <Form.Field>
                  <label>Name</label>
                  <Input value={name} onChange={(e,d) => this.handleFormChange(d, "name")} required/>
                </Form.Field>
                <Form.Field>
                  <label>Conversion</label>
                  <Form.Dropdown
                    options={segmentOptions}
                    value={conversionVal}
                    onChange={(e,d) => this.handleFormChange(d, "conversionVal")}
                    placeholder="Select Conversion"
                  />
                </Form.Field>
                <Form.Field>
                  <label>GA column</label>
                    <Form.Dropdown
                      options={gaOptions}
                      value={gaVal}
                      onChange={(e,d) => this.handleFormChange(d, "gaVal")}
                      placeholder="Select GA value"
                    />
                </Form.Field>
                <Button content="Cancel" onClick={() => this.handleModal("closed")}/>
                <Button content="Submit" type="sumbmit" primary/>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        { this.renderDateModal() }
      </React.Fragment>
    )
  }
}

function mapStateToProps (state, props) {
  return {
    segments: state.segment.segments,
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    setConversionSegments: (data) => {
      const conversions = data.response
        .filter(segment => segment.action_type == "conversion")
      dispatch(rootAction(SET_CONVERSION_SEGMENTS, conversions))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalyticsComparison);
