import React, { Component, useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { testTransformTableData, getTransformTableData } from '../../utils/api';
import TreeTableData from './TreeTableData';

const DataButton = ({ pullData, loading }) => {
  return <div style={{marginBottom: '40px'}}><Button primary disabled={loading} style={{float: 'right'}} onClick={pullData}>Check Results</Button></div>
}

const TransformTableCurrent = ({ transformTableId, searchValue }) => {
  const [rawData, setRawData] = React.useState(null);
  const [rawDataLoading, setRawDataLoading] = React.useState(false);

  React.useEffect(() => { setRawData(null) }, [transformTableId])

  const pullData = () => {
    setRawData(null);
    setRawDataLoading(true)
    getTransformTableData(transformTableId)
      .then(d => {
        setRawData(d)
        setRawDataLoading(false)
      })
  }

  return <TreeTableData {...{title: "", children: <DataButton {...{pullData, loading: rawDataLoading}} />, transformTableId, searchValue, rawData, loading: rawDataLoading, onButtonClick: pullData}} />
}

export default TransformTableCurrent;
