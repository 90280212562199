import React, { useMemo, useState } from 'react'
import { Segment, Dropdown, Icon } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Bar, Line, Tooltip } from 'recharts'
import { ChartBarIcon }  from '@heroicons/react/solid'
import { TrendingUpIcon } from '@heroicons/react/outline'

import { ComboChartLoader } from '@rockerbox/styleguide'
import { generateColors } from './helpers'
import { reformatDateMMDDYYYY } from '../../../utils/time'


const BAR_CHART_OPTIONS = [
  {
    text: 'Spend',
    value: 'spend',
    key: 'spend'
  },
  {
    text: 'Revenue',
    value: 'revenue',
    key: 'revenue'
  },
  {
    text: 'Conversions',
    value: 'conversions',
    key: 'conversions'
  },
]

const LINE_CHART_OPTIONS = [
  {
    text: 'Blended ROAS',
    value: 'roas',
    key: 'roas'
  },
  {
    text: 'Blended CPA',
    value: 'cpa',
    key: 'cpa'
  },
]


const formatNumber = (number) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  })
  return numberFormat.format(number)
}

const ToolTipRow = ({ name, value, color, payload, currencyFormatter, barChartMetric, i, showTotal }) => {
  const { spend_total } = payload[i].payload
  const nonCurrencyStats = ['conversions', 'roas']
  const formatter = nonCurrencyStats.includes(name) ? formatNumber : currencyFormatter(2)
  const displayName = name.replaceAll('_', ' ').replaceAll('-', ' ')
    .replace('cpa', 'CPA').replace('roas', 'ROAS')
  const suffix = barChartMetric == 'spend' && !['roas', 'cpa'].includes(name) ? ' Spend' : ''

  return (
    <>
      <p
        key={name}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: i < payload.length-1 ? 10 : 0,
          textTransform: 'capitalize',
        }}
        >
        <span style={{ marginRight: 20 }}>
          <Icon name='square' style={{ color }} />
          {`${displayName}${suffix}: `}
        </span>
        <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
          {formatter(value)}
        </span>
      </p>
      {showTotal && (
        <p
          key="total"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '10px',
            borderTop: '1px solid grey',
            fontWeight: 'bold'
          }}
        >
          <span style={{ marginRight: 20 }}>Total</span>
          <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{formatter(spend_total)}</span>
        </p>
      )}
    </>
  )
}


const ComboChart = ({ loading, data, platforms, hasRevenue, currencyFormatter }) => {
  const [barChartMetric, setBarChartMetric] = useState('spend')
  const [lineChartMetric, setLineChartMetric] = useState('roas')

  const [barChartOptions, lineChartOptions] = useMemo(() => {
    if (!hasRevenue) {
      setLineChartMetric('cpa')
      return [
        BAR_CHART_OPTIONS.filter(x => x.value !== 'revenue'),
        LINE_CHART_OPTIONS.filter(x => x.value !== 'roas'),
      ]
    }
    setLineChartMetric('roas')
    return [BAR_CHART_OPTIONS, LINE_CHART_OPTIONS]
  }, [hasRevenue])

  const colors = useMemo(() =>
    !!platforms && generateColors(platforms.length)
    , [platforms])
  
  const toolTipLabelFormatter = (v) => {
    const hasDate = Object.keys(data[0]).includes('date')
    if (hasDate) {
      return reformatDateMMDDYYYY(v)
    }
    return v
  }

  const ChartTooltip = ({ active, payload, label, showSpendTotal }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          padding: 10,
          borderRadius: 4,
          background: '#fff',
          color: '#000',
          boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
        }}>
          <p
            style={{
              fontSize: '14px',
              color: '#4d4d4d'
            }}
          >
            {toolTipLabelFormatter(label)}
          </p>
          {/* {showSpendTotal ? <></>: */}
          {payload.map(({ name, value, color }, i) => (
            <ToolTipRow {...{ name, value, color, payload, currencyFormatter, barChartMetric, i, showTotal: i === payload.length - 2 && showSpendTotal }} />
            ))
          }
        </div>
      )
    }
    return null
  }

  if (!!loading) return <ComboChartLoader />

  return (
    <Segment>
      <div className='card-metric-selectors-wrap'>
        <div className={`card-metric-selector ${barChartMetric == 'spend' ? 'purple' : barChartMetric == 'revenue' ? 'green' : 'blue'}`}>
          <ChartBarIcon className='icon' />
          <Dropdown
            inline
            selection
            selectOnBlur={false}
            options={barChartOptions}
            value={barChartMetric}
            onChange={(_, { value }) => setBarChartMetric(value)}
          />
        </div>
        <div className={`card-metric-selector ${lineChartMetric == 'roas' ? 'orange' : 'pink'}`}>
          <TrendingUpIcon className='icon' />
          <Dropdown
            inline
            selection
            selectOnBlur={false}
            options={lineChartOptions}
            value={lineChartMetric}
            onChange={(_, { value }) => setLineChartMetric(value)}
          />
        </div>
      </div>
      <ResponsiveContainer
        width='100%' 
        height={360}
      >
        <ComposedChart
          data={data}
          barCategoryGap={2}
        >
          <XAxis hide dataKey='date' />
          <YAxis hide yAxisId='bar' />
          <YAxis hide yAxisId='line' />

          {barChartMetric === 'spend' &&
            platforms
              .map((k, i) =>
                <Bar
                  key={k}
                  dataKey={k}
                  yAxisId='bar'
                  stackId='spend'
                  fill={colors[i]}
                  stroke='#fff'
                  strokeWidth={3}
                />
              )
          }

          {barChartMetric !== 'spend' &&
            <Bar
              key={barChartMetric}
              dataKey={barChartMetric}
              yAxisId='bar'
              fill={barChartMetric == 'revenue' ? '#66D9AE' : '#4987BF'}
            />
          }

          <Line
            key={lineChartMetric}
            dataKey={lineChartMetric}
            yAxisId='line'
            type='monotone'
            stroke={lineChartMetric == 'roas' ? '#ffa64d' : '#E76D8E'}
            strokeWidth={3}
            dot={false}
          />

          <Tooltip
            cursor={{
              stroke: '#efefef',
              strokeWidth: 2,
              strokeDasharray: '5 5',
            }}
            content={<ChartTooltip />}
            position={{ y: 0 }}
            showSpendTotal={barChartMetric === 'spend'}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Segment>
  )
}

export default ComboChart
