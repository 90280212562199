import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';

const InboxMenu = ({ keys, inboxByInboxId, handleInboxSwitch, selectedInboxId }) => {
  return <Menu vertical fluid>
    <Menu.Item header>Inboxes</Menu.Item>
    {keys && keys.length > 0 && (
      keys.map(c => {
        return (
          <Menu.Item onClick={() => handleInboxSwitch(c)} active={selectedInboxId === c}>
            {inboxByInboxId[c][0].name}
            {selectedInboxId === c && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
          </Menu.Item>
        )
      })
    )}
  </Menu>
}

export default InboxMenu;
