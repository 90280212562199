import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PlusCircleIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import { ContentCard, IndexGridNew, ChartCardLoader } from '@rockerbox/styleguide';

import * as routes from '../../baseRoutes';
import { deletePlatformAccount } from '../../api/account';
import { getPlatformPerformanceSummary, getPlatformPerformanceSyncStatus } from '../../api/platformPerformanceApi';
import { getSpendByPlatform } from '../../api/attribution';
import { track } from '../../../utils/tracking';
import { useGlobalState } from '../../hooks/global';

import SyncingData from './SyncingData';
import { TreeTableLoader } from '../../components/loaders';
import { yesterday, monthAgo, twoMonthsAgo } from '../../utils/time';
import { PanelsGroup, StatusCell, IconCell, CurrencyCell } from './helpers';
import { BasicViewHeader, NoConnections } from '../../components';
import { PLATFORMS } from './constants';

const AdvertisingPlatforms = () => {
  const { authorizations, integrations: allIntegrations } = useGlobalState();

  const [platformsSummary, setPlatformsSummary] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [accountSummary, setAccountSummary] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [showSync, setShowSync] = useState(false);
  const [platformsToSync, setPlatformsToSync] = useState([]);

  const handleDelete = async (key, id) => {
    setLoading(true);
    await deletePlatformAccount(key, id);
    window.location.reload();
  };

  const colorMap = useMemo(() => {
    const colors = {};
    // TODO: colors for all platforms
    Object.keys(PLATFORMS).forEach(platform => {
      colors[platform] = PLATFORMS[platform].color;
    });
    return colors;
  }, []);

  const advertisingIntegrations = useMemo(() => {
    if (!allIntegrations?.length) return [];
    const _list = allIntegrations
      .filter(integration => integration?.category?.includes('advertising'))
      .map(integration => integration.platform);
    return [...new Set(_list)];
  }, [allIntegrations]);

  // create a flat list of all auths with integration info included
  const connectedAdAccounts = useMemo(() => {
    if (!allIntegrations?.length || !advertisingIntegrations?.length || !authorizations?.length) return undefined;

    return authorizations.flatMap(obj => {
      const { platform } = obj;
      const integrationObj = allIntegrations.find(platformObj => platformObj.platform === platform);
      const accounts = obj.authorizations.flatMap(account => {
        if (!advertisingIntegrations.includes(platform)) return [];

        return {
          platform_key: platform,
          platform: integrationObj.display_name,
          account_name: (!account.batch_platform && !!account.account_name) ? account.account_name : integrationObj.display_name,
          account_id: account.account_id,
          auth_status: !account.batch_platform ? !!account.status : null,
          batch_platform: !!account.batch_platform,
          has_platform_performance: Object.keys(PLATFORMS).includes(platform),
          logo_path: `https://images.rockerbox.com/integrations/${integrationObj.logo_img}`,
          icon_path: integrationObj.icon_img && `https://images.rockerbox.com/integrations/${integrationObj.icon_img}`,
          id: account.id,
        };
      });

      return [...accounts];
    });
  }, [allIntegrations, advertisingIntegrations, authorizations]);

  const authedAdAccounts = useMemo(() => {
    if (!advertisingIntegrations?.length || !authorizations) return [];
    return authorizations
      .filter(c => (
        advertisingIntegrations.includes(c.platform) && c.authorizations.some(a => !a.batch_platform && !!a.account_id)
      ));
  }, [advertisingIntegrations, authorizations]);

  const authedAdPlatforms = useMemo(() => {
    if (!authedAdAccounts?.length) return [];
    return authedAdAccounts.map(c => c.platform);
  }, [authedAdAccounts]);

  const authedPlatPerfAccounts = useMemo(() => {
    if (!authedAdAccounts?.length) return [];
    return authedAdAccounts.filter(c => Object.keys(PLATFORMS).includes(c.platform_key));
  }, [authedAdAccounts]);

  // summary stats for platform performance platforms
  useEffect(() => {
    if (!connectedAdAccounts || !authedAdPlatforms) return;
    const tableData = _.cloneDeep(connectedAdAccounts);

    const dataByDate = {};
    const platformsDist = {};

    // get spend summary from platform performance if available, otherwise use hs checks api
    const summaries = connectedAdAccounts.map(account => {
      if (Object.keys(PLATFORMS).includes(account.platform_key)) {
        return getPlatformPerformanceSummary(account.platform_key, monthAgo, yesterday)
          .then(data => data.filter(d => d.identifier === account.account_id))
          .catch(err => {
            console.log('error:', account.platform, err); // eslint-disable-line
            return [{ platform: account.platform_key, error: true }];
          });
      }
      return getSpendByPlatform(account.platform_key, account.account_id, monthAgo, yesterday)
        .catch(err => {
          console.log('error:', account.platform, account.account_id, err); // eslint-disable-line
          return [{ platform: account.platform_key, error: true }];
        });
    }).filter(s => !!s);

    Promise.all(summaries).then(data => {
      data.forEach(platformData => {
        platformData?.forEach(accountData => {
          const { identifier, metrics, platform, values, error } = accountData;
          if (error) {
            platformsDist[platform] = 'error';
            return;
          }
          const dailyMetrics = metrics || values;
          const platformKey = platform || dailyMetrics?.[0]?.platform;

          const accountObj = tableData.find(obj => obj.account_id === identifier) || {};
          const totalAccountSpend = dailyMetrics.reduce((a, b) => a + (b?.summary?.spend || b.spend), 0);
          const lastSync = metrics?.length > 1
            ? metrics.reduce((a, b) => (a.last_sync > b.last_sync ? a.last_sync : b.last_sync))
            : metrics?.length === 1 ? metrics[0].last_sync : null;

          dailyMetrics.forEach(obj => {
            const daySpend = obj?.summary?.spend || obj.spend || 0;
            platformsDist[platformKey] = (daySpend || 0) + (platformsDist[platformKey] || 0);

            const selectedObj = dataByDate[obj.date];
            if (!selectedObj) {
              dataByDate[obj.date] = { date: obj.date, [platformKey]: daySpend };
              return;
            }
            selectedObj[platformKey] = daySpend + (selectedObj[platformKey] || 0);
          });

          Object.assign(accountObj, { spend: Number(totalAccountSpend.toFixed(2)), last_sync: lastSync });
        });
      });

      // fill in 0s for dates with no data for a platform
      Object.keys(dataByDate).forEach(date => {
        authedAdPlatforms.forEach(platform => {
          if (!!dataByDate[date][platform]) return;
          dataByDate[date][platform] = 0;
        });
      });
      setPlatformsSummary(platformsDist);
      setChartData(Object.values(dataByDate));
      setAccountSummary(tableData);
      setLoading(false);
    })
      .catch(err => {
        console.log('Error:', err.message); // eslint-disable-line
      });
  }, [authedAdPlatforms, connectedAdAccounts]);

  // combine account info with spend & auth status
  const tableData = useMemo(() => {
    if (!connectedAdAccounts?.length || !accountSummary?.length) return [];
    return accountSummary.map(obj => {
      const selectedObj = connectedAdAccounts.find(c => Number(c.account_id) === Number(obj.account_id));
      return { ...obj, ...selectedObj };
    });
  }, [connectedAdAccounts, accountSummary]);

  const tableColumns = useMemo(() => [
    { display: 'Account', key: 'account_name', isSearchable: true, as: connectedAdAccounts && IconCell(connectedAdAccounts) },
    { display: 'Account ID', key: 'account_id', isSearchable: true },
    { display: 'Platform', key: 'platform', isSearchable: true },
    { display: 'Spend', key: 'spend', as: CurrencyCell },
    { display: 'Status', key: 'last_sync', as: StatusCell(handleDelete), headerWidth: 4 },
  ], [connectedAdAccounts]);

  // sync status checks
  useEffect(() => {
    if (!authedPlatPerfAccounts?.length) return;

    const selectedPlatformsByUpdatedKey = authedPlatPerfAccounts.reduce((p, curr) => {
      const filteredAuthsList = curr.authorizations.filter(c => {
        const updated = moment(c.updated);
        const isValid = updated.isValid();
        const diffInMinutes = moment().diff(updated, 'minutes');

        return !!(isValid && diffInMinutes < 60);
      });

      if (filteredAuthsList.length > 0) {
        p.push(curr.platform);
      }

      return p;
    }, []);

    Promise.all(selectedPlatformsByUpdatedKey.map(platform => getPlatformPerformanceSyncStatus(platform, twoMonthsAgo, yesterday)))
      .then(responses => {
        const addPlatformAsKeys = selectedPlatformsByUpdatedKey.reduce((p, c, i) => {
          p[c] = responses[i];
          return p;
        }, {});

        const syncingPlatformsArr = [];

        Object.entries(addPlatformAsKeys).forEach((k, v) => {
          if (addPlatformAsKeys[k].length < 30) {
            syncingPlatformsArr.push({ text: k, value: v });
          }
        });
        setShowSync(true);
        setPlatformsToSync(syncingPlatformsArr);
      });
  }, [authedPlatPerfAccounts]);

  useEffect(() => {
    track('data.marketing.advertising_platforms.main.view');
  }, []);

  if (!!authorizations && !authedAdPlatforms?.length) return <NoConnections />;

  return (
    <>
      <BasicViewHeader
        header="Advertising Platforms"
        subheader="Connect your advertising platforms and centralize your spend"
        topRight={(
          <Button
            primary
            as={Link}
            to={routes.connectAdvertisingPlatform}
          >
            Connect New Account
          </Button>
          )}
      />

      {/* TODO: Enable charts once summary data hooked and compiled */}
      {showSync && <SyncingData {...{ integrations: connectedAdAccounts, platformsToSync }} /> }

      {!!loading && <ChartCardLoader itemsPerRow={4} padding="1em 0" />}
      {!loading && !!chartData?.length
          && (
          <PanelsGroup
            data={chartData}
            platformsDist={platformsSummary}
            integrations={connectedAdAccounts}
            colorMap={colorMap}
          />
          )}

      {loading
        ? (
          <ContentCard hasTable>
            <TreeTableLoader />
          </ContentCard>
        )
        : (
          <ContentCard hasTable>
            <IndexGridNew
              title="Connected Accounts"
              celled
              data={tableData}
              cols={tableColumns}
              sortable
              defaultSortCol="platform"
              defaultSortOrder="ascending"
              placeholder="Search Accounts"
              searchable
              alignSearchLeft
              topRight={(
                <Button
                  inverted
                  color="purple"
                  content={(
                    <>
                      <PlusCircleIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
                      {' Connect Account'}
                    </>
                  )}
                  as={Link}
                  to={routes.connectAdvertisingPlatform}
                  style={{ margin: 0 }}
                  className="remove-box-shadow"
                />
                )}
              fallBackMsg="No Matching Accounts Found"
            />
          </ContentCard>
        )}
    </>
  );
};

export default AdvertisingPlatforms;
