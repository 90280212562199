import { combineReducers } from 'redux';

import segment from '../components/SelectSegment/reducer';
import attribution from '../components/ViewAttribution/reducer';
import app from '../components/SceneApp/reducer';
import reports from '../components/ReportIndex/reducer';
import reportDeliveryStatus from '../components/ReportDeliveryStatus/reducer';
import { reducer as journey } from '../components/CustomerJourney';

const rootReducer = combineReducers({
  segment,
  attribution,
  app,
  reports,
  reportDeliveryStatus,
  journey
});

export default rootReducer;
