import React from 'react';
import { Form } from 'semantic-ui-react';

const FieldWrap = props => (
  <Form.Group widths="equal">
    <Form.Field {...{style: props.style}}>{props.children}</Form.Field>
  </Form.Group>
);

export default FieldWrap;
