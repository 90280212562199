import React, { Component, useEffect } from 'react';
import { mock, getEventTables, saveEventTables } from '../../utils/api';
import GenericSetting from './GenericSetting';
import EventDataSources from '../DataSources/EventDataSources';
import GenericToggle from './GenericToggle';

const options = [
  {text:"Segment", value:"segment_anonymous_id"},
  {text:"Tatari", value:"tatari_session_id"},
]

const getOptions = mock(options)

const EventTables = (props) => {
  const title = "Enable Generic Event Sources";
  const key_name = "event_table_name";

  return (
    <React.Fragment>
      <EventDataSources />
      <GenericToggle {...{title, key_name, getOptions, get: getEventTables, save: saveEventTables}}/>
    </React.Fragment>
  )
}

export default EventTables;
