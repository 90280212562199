import React, { Component } from 'react';
import { Input, Checkbox, Accordion, Grid, Select, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';
import { Aux, FormWrap } from '../stateless';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { ColumnWrap, GRow } from './SelectCohort';
import { metricSettings } from './metrics';

const metricOptions = metricSettings.map(row => {
  return { text: row.title, value: row.title, key: row.value }
})

class ReportSettings extends Component {

  state = {
    active: "metrics",
    activeIndex: 0
  }

  toggleActive = (field) => () => {
    this.setState({active: field})
  }

  render() {
    const { toggleActive } = this;
    const { metrics, paid, margin, ntf, updateField } = this.props;
    const { active } = this.state;

    return (

        <ColumnWrap width={5}>
          <Header as={'h5'}>Display Options</Header>
          <GRow>
            <label>Show Metrics</label>
            <Select multiple value={metrics} options={metricOptions} onChange={updateField("metrics")} size="mini"/>
          </GRow>
          <GRow>
            <label>Profit Margin</label>
            <Input value={margin} onChange={updateField("margin")} style={{paddingLeft:"0px"}}/>
          </GRow>
          <GRow>
            <label>Paid Channels Only</label>
            <div style={{flex:2,paddingTop:"10px"}}>
              <Checkbox
                onChange={() => updateField("paid")(false, {value: !paid})}
                value={paid} checked={paid} />
            </div>
          </GRow>
          <GRow>
            <label>NTF Only</label>
            <div style={{flex:2,paddingTop:"10px"}}>
              <Checkbox
                onChange={() => updateField("ntf")(false, {value: !ntf})}
                value={ntf} checked={ntf} />
            </div>
          </GRow>

        </ColumnWrap>
    )
  }
}

export default ReportSettings;
