import React, { useMemo, useCallback } from "react";
import { Table, Button, Dropdown, Icon } from "semantic-ui-react";
import { ExperimentStateIcon } from "./ExperimentCard";
import { experimentStore } from "./store";
import { customHistory } from "../pushpath";
import { ContentCard } from "@rockerbox/styleguide";

const select = state => [
  state.updateExperimentStatus,
  state.activeExperimentId,
  state.getExperiments,
  state.activeTreatments,
  state.platforms
];

const ResultCard = props => {
  const [
    updateExperimentStatus,
    activeExperimentId,
    getExperiments,
    activeTreatments,
    platforms
  ] = experimentStore(select);
  const { updatePath } = customHistory(props);

  const {
    segment_name,
    platform,
    data_tier,
    tableData,
    state = "running"
  } = props;
  // state
  // runnig can change to running/completed/archived
  // completed can only change to archived
  // archived can't be changed anymore

  const platformText = React.useMemo(() => {
    const platformToUse = platforms.filter(item => item.key === platform)[0];
    return platformToUse ? platformToUse["text"] : "NULL";
  }, [platform, platforms]);

  const stateOptions = useMemo(() => {
    if (state === "running") {
      return [
        { text: "Running", key: "running", value: "running" },
        { text: "Completed", key: "completed", value: "completed" },
        { text: "Archived", key: "archived", value: "archived" }
      ];
    } else if (state === "completed") {
      return [
        { text: "Completed", key: "completed", value: "completed" },
        { text: "Archived", key: "archived", value: "archived" }
      ];
    } else {
      return [{ text: "Archived", key: "archived", value: "archived" }];
    }
  }, [state]);

  const topPerformer = useMemo(() => {
    let topPerfName = "";
    let topPerfValue = -Number.MAX_SAFE_INTEGER;
    const filteredTableData = tableData.filter(
      item => item.percentile !== undefined && item.percentile >= 95
    );

    for (let i = 0; i < filteredTableData.length; i++) {
      if (
        filteredTableData[i]["lift"] > topPerfValue &&
        filteredTableData[i]["lift"] > 0
      ) {
        topPerfValue = filteredTableData[i]["lift"];
        topPerfName = filteredTableData[i][data_tier];
      }
    }

    if (topPerfValue === -Number.MAX_SAFE_INTEGER) {
      topPerfValue = "";
    }

    return { name: topPerfName, value: topPerfValue };
  }, [tableData]);

  const onSelect = useCallback(
    (e, data) => {
      if (data.value !== state) {
        updateExperimentStatus(
          data.value,
          activeTreatments,
          activeExperimentId
        ).then(() => getExperiments());
      }
    },
    [state, activeTreatments, activeExperimentId]
  );

  return (
    <ContentCard
      title="Experiment Details"
      hasTable
      topRight={
        state === "running" ? (
          <Button
            size="tiny"
            onClick={() => updatePath({ view: "edit", id: activeExperimentId })}
            style={{ position: "relative", left: 9 }}>
            <Icon name="edit" />
            Edit
          </Button>
        ) : null
      }>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={6}>Conversion</Table.Cell>
            <Table.Cell width={10}>{segment_name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Platform</Table.Cell>
            <Table.Cell>{platformText}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Baseline</Table.Cell>
            <Table.Cell>{props[`control_${data_tier}`]}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>
              <ExperimentStateIcon state={state} />
              {state === "archived" ? (
                <span>{"Archived"}</span>
              ) : (
                <Dropdown
                  options={stateOptions}
                  value={state}
                  onChange={onSelect}
                />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Top Performer</Table.Cell>
            <Table.Cell>{topPerformer["name"] || "-"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Top Performer Lift</Table.Cell>
            <Table.Cell>
              {topPerformer["value"] ? topPerformer["value"].toFixed(3) : "-"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </ContentCard>
  );
};

export default ResultCard;
