import React from 'react';
import SetupShare from './SetupShare';
import SetupClientShare from "./SetupClientShare";
import SetupClientTables from "./SetupTables";

export default {
  name: 'Snowflake',
  logo: 'snowflake-logo.png',
  steps: [
    {
      order: 1,
      description: 'Enter your Snowflake URL into the field below so we can verify your account information and set up your share',
      component: SetupShare,
    },
    {
      order: 2,
      description: 'Run the following query in the Snowflake SQL Editor using an ACCOUNTADMIN role to create the Rockerbox database:',
      component: SetupClientShare,
      requires: [1]
    },
    {
      order: 3,
      description: 'After creating the database, you can configure your Rockerbox shares. Some of your data will be available almost immediately after creating your shares, but it may take up to 24 hours to sync your complete dataset.',
      component: SetupClientTables,
      requires: [1]
    },
  ]
}
