import React from 'react'
import { Statistic, Card } from 'semantic-ui-react'
import { Tooltip } from './stateless'



const CardObj = ({ value, subText, tooltip }) => (
  <Card textAlign='center' style={{ padding: '1rem', alignItems: 'self-end', flexDirection: 'row', justifyContent: 'center' }} >
    <Statistic style={{ margin: '0'}}>
      <Statistic.Value style={{ color: '#026CAC'}} >
        {value.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })}
      </Statistic.Value>
      <Statistic.Label style={{ color: '#4A4A4A' }} >{subText}</Statistic.Label>
    </Statistic>
    <Tooltip tooltip={tooltip} />
  </Card>
)

const SummaryCards = ({ data, tooltips }) => {
  const trackingSum = data.order_tracking_summary

  const urlOnlyObj = trackingSum.find(o => o.category === 'url_tracking_only')
  const urlOnly = urlOnlyObj ? urlOnlyObj.total_orders : 0
  const enhOnlyObj = trackingSum.find(o => o.category === 'enhanced_tracking_only')
  const enhOnly = enhOnlyObj ? enhOnlyObj.total_orders : 0
  const mixObj = trackingSum.find(o => o.category === 'url_tracking_enhanced')
  const mix = mixObj ? mixObj.total_orders : 0
  const directObj = trackingSum.find(o => o.category === 'no_info')
  const direct = directObj ? directObj.total_orders : 0

  const redDirect = (enhOnly / (direct + enhOnly))
  const IncAtt = (enhOnly / (urlOnly + mix))
  const addTcp = ((enhOnly + mix) / (urlOnly + mix + enhOnly))

  return <>
    <Card.Group itemsPerRow={3} centered>
      <CardObj value={(redDirect != 0 && redDirect < 1) ? redDirect : 0} subText={'Reduction in Direct'} tooltip={tooltips?.direct_reduction} />
      <CardObj value={(IncAtt != 0) ? IncAtt : 0} subText={'Increase in Attributed Conversions'} tooltip={tooltips?.att_conv_increase} />
      <CardObj value={(addTcp != 0 && addTcp < 1) ? addTcp : 0} subText={'Conversions with Additional Touchpoints'} tooltip={tooltips?.additional_touchpoints} />
    </Card.Group>
  </>
}

export default SummaryCards