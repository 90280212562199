import React, { Component } from "react";
import * as Router from "react-router-dom";
import * as routes from "../../routes";

import CacheContext from "../../utils/CacheContext";
import {
  updateConversion,
  getSegments,
  getEventTags,
  createEventTag,
  updateEventTag,
  getTiersAsOptions,
} from "../../utils/api";

import {
  Segment,
  Form,
  Button,
  Table,
  Popup,
  Label,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import {
  SplitLayout,
  IndexGrid,
  PagedIndexGrid,
  ContentCard,
} from "@rockerbox/styleguide";

import MultiselectTwoSides from "react-multiselect-two-sides";
import { EventSelectionRow } from "../Entities/SetupTracking";
import "./select.css";

const FormInput = (props) => {
  const { label, data, field, onUpdate, disabled } = props;
  const value = data[field];
  const onChange = onUpdate(field);
  return <Form.Input {...{ label, value, onChange, disabled }} />;
};

const EMPTY_SEGMENTS = [];
const EMPTY_TAGS = [];
const DEFAULT_TIER = {
  tier_1: null,
  tier_2: null,
  tier_3: null,
  tier_4: null,
  tier_5: null,
};

const EventTagForm = (props) => {
  const [state, setState, Context] = React.useContext(CacheContext);

  const [eventTag, setEventTag] = React.useState(DEFAULT_TIER);
  const [originalEventExclusions, setOriginalEventExclusions] = React.useState(
    []
  );
  const [eventExclusions, setEventExclusions] = React.useState([]);

  const [originalSpendExclusions, setOriginalSpendExclusions] = React.useState(
    []
  );
  const [spendExclusions, setSpendExclusions] = React.useState([]);

  const [selectedTiersOptions, setSelectedTiersOptions] = React.useState([]);
  const [isManual, setIsManual] = React.useState(false);

  const history = Router.useHistory();
  const routedData =
    Router.useRouteMatch(`${routes.eventTagsIndex}/view/:selectedEventTag?`) ||
    {};
  const { params: { selectedEventTag } = {} } = routedData;

  const segments = Context.getCache(`segments`, getSegments) || EMPTY_SEGMENTS;
  const eventTags = Context.getCache(`eventTags`, getEventTags) || EMPTY_TAGS;
  const title = selectedEventTag ? "Edit Event Tag" : "Create Event Tag";

  const selectedSegemntIds = new Set(
    eventExclusions.concat(spendExclusions).map((item) => item.toString())
  );

  React.useEffect(() => {
    const featuredSegment = segments.filter(
      (segment) => segment.featured === 1
    )[0];

    if (featuredSegment && featuredSegment["action_id"]) {
      getTiersAsOptions(featuredSegment["action_id"], 3).then((result) =>
        setSelectedTiersOptions(result)
      );
    }
  }, [segments]);

  React.useEffect(() => {
    if (selectedEventTag) {
      const eventTag = eventTags.find((tag) => tag.id == selectedEventTag);
      setEventTag(eventTag);
      if (eventTag) {
        setIsManual(eventTag["from_transform_table"] === 1 ? false : true);
      }
    }
  }, [eventTags, selectedEventTag]);

  React.useEffect(() => {
    if (!eventTag || !segments || !selectedEventTag) return;

    const eventExclusions = segments
      .filter((seg) => seg.event_tags.find((tag) => tag.name == eventTag.name))
      .map((row) => row.action_id);

    const spendExclusions = segments
      .filter((seg) => seg.spend_tags.find((tag) => tag.name == eventTag.name))
      .map((row) => row.action_id);

    setOriginalEventExclusions(eventExclusions);
    setEventExclusions(eventExclusions);

    setOriginalSpendExclusions(spendExclusions);
    setSpendExclusions(spendExclusions);
  }, [eventTag, segments]);

  if (selectedEventTag && !eventTag) return <ContentCard loading />;

  const onUpdate = (field) => (__, evt) => {
    setEventTag(Object.assign({}, eventTag, { [field]: evt.value }));
  };

  const submitAttachment = (name, key, original, current) => {
    const fullSet = [...original, ...current];

    const { added, removed } = fullSet.reduce(
      (p, id) => {
        if (current.includes(id) && !original.includes(id))
          p["added"] = [...p["added"], id];
        if (!current.includes(id) && original.includes(id))
          p["removed"] = [...p["removed"], id];
        return p;
      },
      { added: [], removed: [] }
    );

    const addPromises = added.map((segmentId) => {
      const currentSegment = segments.find((row) => row.action_id == segmentId);
      const { action_id } = currentSegment;
      const currentTags = currentSegment[key];
      const update = {
        action_id,
        identifier: action_id,
        [key]: [...currentTags, { name }],
      };

      return updateConversion(update);
    });

    const removePromises = removed.map((segmentId) => {
      const currentSegment = segments.find((row) => row.action_id == segmentId);
      const { action_id } = currentSegment;
      const currentTags = currentSegment[key];
      const update = {
        action_id,
        identifier: currentSegment.action_id,
        [key]: currentTags.filter((row) => row.name != name),
      };

      return updateConversion(update);
    });

    return [...addPromises, ...removePromises];
  };

  const submit = () => {
    const send = selectedEventTag ? updateEventTag : createEventTag;

    const newEventTag = Object.assign({}, eventTag, {
      from_transform_table: isManual ? 0 : 1,
    });

    Object.keys(newEventTag).forEach((key) => {
      if (newEventTag[key] === "") {
        newEventTag[key] = null;
      }
    });

    send(newEventTag).then((resp) => {
      const [{ name }] = resp;

      const eventTagPromises = submitAttachment(
        name,
        "event_tags",
        originalEventExclusions,
        eventExclusions
      );
      const spendTagPromises = submitAttachment(
        name,
        "spend_tags",
        originalSpendExclusions,
        spendExclusions
      );

      Promise.all([...eventTagPromises, ...spendTagPromises]).then((resp) => {
        setState({ segments: undefined });
        return;
      });

      setState({ eventTags: undefined });
      !selectedEventTag ? history.push(routes.eventTagsIndex) : null;
    });
  };

  const TierSelection = isManual ? (
    <Segment secondary>
      <b>Event Selection Criteria</b>
      <br />
      <br />
      <Form.Group>
        <FormInput
          label="Tier 1"
          data={eventTag}
          field={"tier_1"}
          onUpdate={onUpdate}
        />
        <FormInput
          label="Tier 2"
          data={eventTag}
          field={"tier_2"}
          onUpdate={onUpdate}
        />
        <FormInput
          label="Tier 3"
          data={eventTag}
          field={"tier_3"}
          onUpdate={onUpdate}
        />
        <FormInput
          label="Tier 4"
          data={eventTag}
          field={"tier_4"}
          onUpdate={onUpdate}
        />
        <FormInput
          label="Tier 5"
          data={eventTag}
          field={"tier_5"}
          onUpdate={onUpdate}
        />
      </Form.Group>
    </Segment>
  ) : (
    <Segment secondary>
      <b>Event Selection Criteria</b>
      <br />
      <br />
      <EventSelectionRow
        tiersOptions={selectedTiersOptions}
        eventRow={eventTag}
        onUpdate={(d) => setEventTag(Object.assign({}, eventTag, d))}
        onRemove={(d) => setEventTag(Object.assign({}, eventTag, DEFAULT_TIER))}
      />
    </Segment>
  );

  return (
    <SplitLayout
      leftWidth={12}
      rightWidth={4}
      leftContent={
        <React.Fragment>
          <ContentCard {...{ title }}>
            <Form>
              <Form.Field>
                <FormInput
                  label="Tag Name"
                  data={eventTag}
                  field={"name"}
                  onUpdate={onUpdate}
                  disabled={selectedEventTag}
                />
                <FormInput
                  label="Attribution Window"
                  data={eventTag}
                  field={"num_days"}
                  onUpdate={onUpdate}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  slider
                  label="Apply on platform data / Apply on input data"
                  checked={isManual}
                  onChange={(e, data) => setIsManual(data.checked)}
                />
              </Form.Field>
              {TierSelection}
              <Button 
                as="a" 
                primary onClick={submit} 
                disabled={!eventTag.name}
              >
                Save
              </Button>
            </Form>
          </ContentCard>
          <ContentCard title="Attach Event Tag">
            <Form.Field>
              <SplitLayout
                leftWidth={8}
                rightWidth={8}
                leftContent={
                  <>
                    <b>Exclude events that match tag from</b>
                    <br />
                    <br />
                    <MultiselectTwoSides
                      options={segments}
                      value={eventExclusions}
                      className="msts_theme_example"
                      onChange={setEventExclusions}
                      availableHeader="Segments Available"
                      availableFooter={`Available: ${segments.length}`}
                      selectedHeader="Excluded"
                      selectedFooter={`Selected: ${eventExclusions.length}`}
                      labelKey="action_name"
                      valueKey="action_id"
                      showControls
                      searchable
                    />
                  </>
                }
                rightContent={
                  <>
                    <b>Exclude spend that match tag from</b>
                    <br />
                    <br />
                    <MultiselectTwoSides
                      options={segments}
                      value={spendExclusions}
                      className="msts_theme_example"
                      onChange={setSpendExclusions}
                      availableHeader="Segments Available"
                      availableFooter={`Available: ${segments.length}`}
                      selectedHeader="Excluded"
                      selectedFooter={`Selected: ${spendExclusions.length}`}
                      labelKey="action_name"
                      valueKey="action_id"
                      showControls
                      searchable
                    />
                  </>
                }
              />
              <Button
                as="a"
                primary
                onClick={submit}
                disabled={!eventTag.name}
                style={{ marginTop: "10px" }}
              >
                Save
              </Button>
            </Form.Field>
          </ContentCard>
        </React.Fragment>
      }
      rightContent={<div />}
    />
  );
};

export default EventTagForm;
