import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';

import * as aq from 'arquero';
import { attributionMethodAtom } from '../../../atoms';
import { generateDateRange } from '../helpers';
import { getViaCache } from '../../../../utils/arrow/reportingDataCache';
import { getViaCacheDataReporting } from './dataReportingEndpoint';
import { cleanData } from './helpers';

const findCurrentSegment = (segments, id) => segments.find(c => c.filter_id === Number(id)) || false;

const getFirstReportingDate = currentSegment => {
  const { first_reporting_date } = currentSegment;
  const firstReportingDate = moment(first_reporting_date);
  return firstReportingDate.format('MM/DD/YYYY');
};

const hasColumn = (table, column) => table
  .columnNames()
  .includes(column);

const DEFAULT_COLUMNS = ['even', 'first_touch', 'last_touch'];
const MODEL_COLUMNS = ['normalized'];

const deriveRepeat = table => {
  const hasNTF = hasColumn(table, 'ntf_even');
  const hasModel = hasColumn(table, 'normalized');
  const hasSpend = hasColumn(table, 'spend');

  // front end check on missing values for numeric columns until API makes update to do check
  const cleanedData = cleanData(table, hasNTF, hasModel, hasSpend);

  if (!hasNTF) return cleanedData;

  const columns = hasModel ? [...DEFAULT_COLUMNS, ...MODEL_COLUMNS] : DEFAULT_COLUMNS;

  const repeat = columns.reduce((p, col) => {
    p[`repeat_${col}`] = aq.escape(d => Number(d[col]) - Number(d[`ntf_${col}`]));
    p[`repeat_revenue_${col}`] = aq.escape(d => Number(d[`revenue_${col}`]) - Number(d[`ntf_revenue_${col}`]));
    return p;
  }, {});

  return cleanedData.derive(repeat);
};

const checkFirstReportingDate = first => ({ date }) => !moment(date).isBefore(first);

export const useDatasets = (segments, startDate, endDate, id, account) => {
  const dataset = 'compiled_mta_tiers';
  const [_attributionMethod, setAttributionMethod] = useAtom(attributionMethodAtom);
  const [firstReportingDate, setFirstReportingDate] = useState(undefined);
  const [tiersData, setTiersData] = useState([]);
  const [tiersDataRaw, setTiersDataRaw] = useState(false);
  const [dates, setDates] = useState([]);
  const [currentRequest, setCurrentRequest] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(false);

  const invalidId = (!id || id === 0);
  const badDate = !startDate || !endDate;
  const missingSegments = !segments || (segments.length < 1);
  const currentSegment = !missingSegments && findCurrentSegment(segments, id);
  const hasMissingParameters = (invalidId || badDate || missingSegments || !currentSegment || !account);
  const hasModel = useMemo(() => !!currentSegment.use_model, [currentSegment]);
  const hasNtf = useMemo(() => !!currentSegment.has_new_to_file, [currentSegment]);

  useEffect(() => {
    // Check assertions - Don't attempt to run anything if we dont have everything
    if (hasMissingParameters) return;

    const requestHash = `${id}${startDate}${endDate}`;
    setCurrentRequest(requestHash);

    const firstReportingDateFromSegment = getFirstReportingDate(currentSegment);

    if (moment(endDate).isBefore(moment(firstReportingDateFromSegment))) {
      // no need to execute request if date range selection is bad
      setCurrentResponse(requestHash);
      setFirstReportingDate(firstReportingDateFromSegment);
      setTiersData([]);
      setTiersDataRaw(false);
      setDates([]);
      return;
    }
    
    const hasDataReportingApiFlag = account.features.find(c => c === 'data_reporting_api');

    if (hasDataReportingApiFlag) {
      getViaCacheDataReporting(startDate, endDate, dataset, id)
        .then(_data => {
          const data = _data.objects();
          console.time('raw repeat');
          const raw = deriveRepeat(_data);
          console.timeEnd('raw repeat');

          const filtered = data.filter(checkFirstReportingDate(firstReportingDateFromSegment));
          setCurrentResponse(requestHash);
          setFirstReportingDate(firstReportingDateFromSegment);
          setTiersDataRaw(raw);
          setTiersData(filtered);
          setDates(generateDateRange(startDate, endDate));
        });
    } else {
      getViaCache(startDate, endDate, dataset, id)
        .then(_data => {
          const data = _data.objects();
          console.time('raw repeat');
          const raw = deriveRepeat(_data);
          console.timeEnd('raw repeat');
          const filtered = data.filter(checkFirstReportingDate(firstReportingDateFromSegment));
          setCurrentResponse(requestHash);
          setFirstReportingDate(firstReportingDateFromSegment);
          setTiersDataRaw(raw);
          setTiersData(filtered);
          setDates(generateDateRange(startDate, endDate));
        });
    }
  }, [id, startDate, endDate, segments]);

  const dataIsCurrent = (currentRequest == currentResponse); // eslint-disable-line
  const loading = !currentRequest || (!hasMissingParameters && !dataIsCurrent);
  const noData = currentRequest && dataIsCurrent && tiersData.length === 0;

  const attributionMethod = useMemo(() => {
    const defaultMethod = hasModel ? 'normalized' : 'even';
    const noMethod = !_attributionMethod || _attributionMethod === '';
    const invalidMethod = _attributionMethod === 'normalized' && !hasModel;

    return (noMethod || invalidMethod) ? defaultMethod : _attributionMethod;
  }, [_attributionMethod, hasModel]);

  return {
    loading,
    noData,
    hasModel, hasNtf,
    attributionMethod, setAttributionMethod,
    tiersData,
    tiersDataRaw,
    dates,
    firstReportingDate,
  };
};
