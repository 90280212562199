import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown, Button } from 'semantic-ui-react';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/tomorrow';
import 'brace/theme/monokai';
import SplitEditor from './SplitEditor';
import { modelTransforms, interactiveLaunch, interactiveStatus, interactiveLogin, interactiveGetKernel } from '../../utils/api';

import { changed, rand, buildMsg } from './helpers.js';

class Kernel extends Component {

  state = {
    sessionId: rand(),
    token: null,
    status: null,
    authenticated: false,
    kernelId: false,
    kernel: false,
    wsConnected: false,
    buffer: ""
  }

  connectWebsocket() {
    const protocol = window.location.protocol.indexOf("s") > -1 ? "wss://" : "ws://";
    const host = window.location.host;
    const { kernelId, sessionId } = this.state;
    const path = `${protocol}${host}/interactive/kernel/api/kernels/${kernelId}/channels?session_id=${sessionId}`
    this.ws = new WebSocket(path);
    this.ws.onopen = () => {
      const msg = buildMsg(this.state.sessionId, "kernel_info_request")
      this.ws.send(JSON.stringify(msg))
      this.setState({
        wsConnected: true
      })
      this.runCode({"code":"from helpers.default_imports import *", "target":"Initialize"})
    }
    this.ws.onmessage = (msg) => {
      this.props.receiveMessage(msg)
    }
  }

  runCode = (execute) => {
    const { code, target } = execute

    const content = {"code":code,"silent":false,"store_history":true,"user_expressions":{},"allow_stdin":true,"stop_on_error":true}
    const msg = buildMsg(this.state.sessionId, "execute_request", `${target}Receive`, {}, content)
    if (this.state.wsConnected) this.ws.send(JSON.stringify(msg))
  }

  launchInteractive = () => {
    interactiveLaunch()
      .then( resp => {
        console.log("LAUNCH", resp.response)
        this.getStatus()
      })
  }

  getStatus = () => {

    interactiveStatus()
      .then( resp => {
        const { state } = resp.response
        console.log("STATUS", resp.response)
        switch(state) {
          case false:
            this.props.setProjectState("requesting_launch");
            return this.launchInteractive()
          case "TASK_RUNNING":
            if (resp.response.token) this.setState({ token: resp.response.token, status: state})
            if (!resp.response.token) setTimeout(this.getStatus, 5000, this)
            return this.props.setProjectState("provisioned");
          case "TASK_LAUNCHING":
            setTimeout(this.getStatus, 3000, this)
            return this.props.setProjectState("launching");
          case "TASK_STAGING":
            setTimeout(this.getStatus, 5000, this)
            return this.props.setProjectState("provisioning");
        }
      })
  }
  loginKernel = () => {
    interactiveLogin(this.state.token)
      .then(resp => {
        this.setState({authenticated: true})
        this.props.setProjectState("ready");
      })
      .catch(resp => {
        this.setState({token: false})
        setTimeout(this.getStatus, 5000, this)
      })
  }

  componentDidMount() {
    this.getStatus()
  }
  componentDidUpdate(prevProps, prevState) {
    if (changed(prevState, this.state, "token") && !!this.state.token) {
      this.loginKernel()
    }
    if (changed(prevState, this.state, "authenticated")) {
      interactiveGetKernel("main.ipynb")
        .then( resp => resp.json() )
        .then( resp => {
          const { kernel } = resp;
          const { id } = kernel;
          this.setState({ kernelId: id, kernel: kernel })
        })
    }
    if (changed(prevState, this.state, "kernelId")) {
      this.connectWebsocket()
    }
    if (changed(prevProps, this.props, "execute")) {
      this.runCode(this.props.execute)
    }
  }

  render() {
    return null
  }
}

Kernel.propTypes = {
  "execute": PropTypes.string,
  "receiveMessage": PropTypes.func,
  "setProjectState": PropTypes.func
}

export default Kernel;
