import React, { Component } from 'react';
import { Progress, Container, Image, Header, Button } from 'semantic-ui-react';
import { spendPreviewImage } from '@rockerbox/styleguide';

export default ({ percent, message }) => (
  <Container textAlign='center'>
    <Image
      src={spendPreviewImage}
      size='medium'
      bordered
      circular
      centered
      className='spend-preview-image m-t-20'
    />
    <Header as='h2'>
      Loading Project...
      <Progress percent={percent} indicating />
      <Header.Subheader>{ message }</Header.Subheader>
    </Header>
  </Container>
)
