import React, { useContext, useMemo } from 'react'

import { IndexGridNew } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { TreeTableLoader } from '../../components/loaders'
import { OpenAdsManagerCell } from './helpers'
import SyncAds from './SyncAds'


const AutoParamsIssues = ({ platform, platformDisplayName }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const { trackingParamAlerts } = state

  const fbAutoParamsIssues = useMemo(() => {
    if (!trackingParamAlerts) return
    const fbIssues = trackingParamAlerts?.facebook?.errors || []
    return fbIssues.map(x => ({
      ...x,
      url: `https://business.facebook.com/adsmanager/manage/ads/edit?act=${x.account_id}&selected_campaign_ids=${x.campaign_id}&selected_ad_ids=${x.ad_id}`,
    }))
  }, [trackingParamAlerts])

  const columns = useMemo(() => ([
    { display: 'Account ID', key: 'account_id', isSearchable: true },
    { display: 'Campaign ID', key: 'campaign_id', isSearchable: true },
    { display: 'Ad ID', key: 'ad_id', isSearchable: true },
    { display: 'Error', key: 'error', isSearchable: true, tooltip: `The error that we encountered when attempting to update your ads automatically via the ${platformDisplayName} API` },
    { display: 'Update', key: 'url', sortable: false, as: OpenAdsManagerCell(platformDisplayName) },
  ]), [platformDisplayName])

  if (!fbAutoParamsIssues) return <TreeTableLoader />

  return (
    <IndexGridNew
      celled
      data={fbAutoParamsIssues}
      cols={columns}
      sortable
      searchable
      alignSearchLeft
      placeholder='Search'
      emptyIcon='check'
      fallBackMsg='No Issues Found'
      topRight={!!fbAutoParamsIssues?.length &&
        <SyncAds {...{ platform, platformDisplayName }} />
      }
    />
  )
}

export default AutoParamsIssues
