import React, { Component } from 'react';
import shallow from 'zustand/shallow'

import CacheContext from '../../utils/CacheContext.js'
import { Message, Segment, Modal, Button, Form } from 'semantic-ui-react';
import { updateEntityData, postEntityProgram, getEntityProgramConfigs } from '../../utils/api';
import { SetupDisplaySelector } from '../Entities/SetupDisplay';
import { entityStore } from './entityStore';
import CreateEntityProgram from './CreateProgram';

import { SelectCheckbox, SplitLayout, IndexGrid, ContentCard } from '@rockerbox/styleguide';

const defaultString = (s) => s ? s : ""
const objectWithDefault = (obj) => Object.fromEntries(Object.entries(obj).map(([key,value]) => [key, defaultString(value)]))

const getDisplayString = (obj) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = obj || {}
  const display = objectWithDefault({ tier_1, tier_2, tier_3, tier_4, tier_5 })
  return JSON.stringify(display)
}


const ViewProgram = (props) => {
  const { programs, entityConfigs, programOptions, hasLegacySpend } = props
  const [ program, ...notImportant ] = programs
  const { id, tier_1, tier_2, tier_3, tier_4, tier_5 } = program
  const [ open, setOpen ] = React.useState(false)
  const [ entityType, setEntityType] = React.useState(program.entity_type)
  const [ description, setDescription] = React.useState(program.description)
  const [ fixedCost, setFixedCost] = React.useState(program.fixed_unit_cost)
  const [ percentCost, setPercentCost] = React.useState(program.rev_share_percent)
  const [ shouldHaveSponsorshipCost, setShouldHaveSponsorshipCost] = React.useState(program.has_campaign_costs) 
  const [ display, setDisplay] = React.useState({ tier_1, tier_2, tier_3, tier_4, tier_5 })
  const [ loading, setLoading ] = React.useState(false)


  const [ getEntityConfigs ] = entityStore(({ getEntityConfigs }) => [ getEntityConfigs ], shallow)

  React.useEffect(() => {
    setEntityType(program.entity_type)
    setDescription(program.description)
    setFixedCost(program.fixed_unit_cost)
    setPercentCost(program.rev_share_percent)
    setShouldHaveSponsorshipCost(program.has_campaign_costs) 
    setDisplay({ tier_1, tier_2, tier_3, tier_4, tier_5 })
  }, [program])

  const paymentValues = []
  fixedCost ? paymentValues.push("fixed_payout") : null
  percentCost ? paymentValues.push("percent_payout") : null
  shouldHaveSponsorshipCost ? paymentValues.push("sponsorship") : null

  const PAYMENT_TERMS = [
    {text: "A fixed amount per purchase" + (fixedCost ? `: $${fixedCost}`: ""), value: "fixed_payout"}, 
    {text: "A percent of revenue driven" + (percentCost ? `: ${percentCost}%`: ""), value: "percent_payout"},
    {text: "A fee for a sponsorship / campaign run", value: "sponsorship"}
  ]

  const onClose = () => setOpen(false)

  const onSubmit = ({ value }) => {

    const { entity_type } = value;
    const entityNameChanged = entity_type != program.entity_type
    const entitiesToUpdate = entityConfigs.filter(row => row.entity_type == program.entity_type)

    const promises = entitiesToUpdate
      .map(row => Object.assign({}, row, { entity_type }))
      .map(entity => {
        const { id } = entity;
        return updateEntityData(entity, id)
      })

    Promise.all(promises)
      .then(arr => {
        getEntityConfigs(true)
          .then(resp => setLoading(false))
      })
    
    onClose()
  }

  const programDisplayString = React.useMemo(() => getDisplayString(program), [program])

  const mismatchedEntities = React.useMemo(() => {
    return entityConfigs.filter(row => {
        const isEntity = row.entity_type == program.entity_type
        const display = [...row.display, {}][0]
        const displayString = getDisplayString(display)
        const matchDisplay = displayString == programDisplayString

        return isEntity && !matchDisplay
      })
  }, [entityConfigs, programDisplayString])

  const conformSponsorships = () => {
    setLoading(true)
    const promises = mismatchedEntities
      .map(row => Object.assign({}, row, {display: [JSON.parse(programDisplayString)]}))
      .map(entity => {
        const { id } = entity;
        return updateEntityData(entity, id)
      })

    Promise.all(promises)
      .then(arr => {
        getEntityConfigs(true)
          .then(resp => setLoading(false))
      })
  }

  return <>
    <Segment style={{ border: '1px solid #f2f4fd' }}>
      <h3>{entityType} Program</h3>
      <Form>
        <Message content={ description } color="white" />
        {!!hasLegacySpend &&
          <Segment secondary>
            <SelectCheckbox 
              disabled
              description="Participants in this program are paid:" 
              value={paymentValues}
              showBorder={false}
              multiple options={PAYMENT_TERMS} onChange={() => {}} 
              />
          </Segment>
        }
        <Segment secondary>
          <div style={{fontWeight:"bold",paddingBottom:"10px"}}>Setup the standard structure underwhich you want marketing associated with this program to appear:</div>
          <div style={{padding:"10px"}}>
            <SetupDisplaySelector showExampleOnly value={display} onChange={(evt, { value }) => setDisplay(value)} />
          </div>
        </Segment>
        { open && <CreateEntityProgram 
          {...{programOptions}}
          {...{open, onClose, onSubmit}} 
          {...{ id, entityType, description, fixedCost, percentCost, shouldHaveSponsorshipCost, display, hasLegacySpend }} 
        />}
        <Button icon="pencil" onClick={() => setOpen(true)}>Edit</Button>
      </Form>
    </Segment>
    { mismatchedEntities.length > 0 && <Segment {...{loading}} style={{ border: '1px solid #f2f4fd' }}>
        <Message content="Sponsorships have been detected that do not conform to the program. Would you like to have all sponsorships conform to the display rules you specified as part of program setup?" icon="warning sign" color="white" />
        <div style={{textAlign:"center"}}>
          <Button icon="magic" onClick={conformSponsorships}>Yes, Update All Sponsorships to Conform</Button>
        </div>
      </Segment>
    }
  </>
}


export default ViewProgram;
