import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { postRedshiftInit } from '../../../api/datalake';
import { useStepsDisplay } from '../StepsSectionContext';
import { map } from 'lodash/fp';
import { REDSHIFT_REGIONS } from '../constants';
import ErrorMessage from '../components/ErrorMessage';

const getRegionOptions = map(region => ({ key: region, value: region, text: region }));
const formStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  height: 'auto'
}

const RedshiftInitialize = () => {
  const [clientRegion, setClientRegion] = useState('')
  const [error, setError] = useState('');
  const [, setStepsDisplay] = useStepsDisplay();
  const AwsRegionOptions = getRegionOptions(REDSHIFT_REGIONS);

  const handleSubmitRegion = () => {
    setError('')
    postRedshiftInit(clientRegion)
      .then((data) => {
        data = data['data']
        if (data['status'] === 'success') {
          setStepsDisplay(1, true);
        }
      })
      .catch((e) => {
        setError(e.response.data.message)
      })
  }

  const handleDropdownChange = (_, { value }) => setClientRegion(value)

  return (
    <Form>
      <Form.Group style={formStyles}>
        <Form.Field>
          <Form.Dropdown
            label='AWS Region'
            placeholder='Select AWS Region'
            fluid
            search
            selection
            options={AwsRegionOptions}
            onChange={handleDropdownChange}
            value={clientRegion}
            style={{ width: 200 }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            size='small'
            content='Submit Region'
            type='button'
            onClick={handleSubmitRegion}
            style={{ fontSize: 13 }}
          />
        </Form.Field>
      </Form.Group>
      {error && <ErrorMessage message={error} />}
    </Form>
  )
}

export default RedshiftInitialize;
