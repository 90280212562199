import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

/* 
    <StyledDropdown
      label="Customer Type"
      disabled={!hasNtf || noData}
      selection
      options={NTF_OPTIONS}
      value={ntfFilter}
      onChange={handleNtfChange}
    />
*/

const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`
const RockerboxStyledDropdown = styled(Dropdown)`
  border: solid 0.5px #bfc6f3 !important; 
  .text {
    color: #333333 !important;
  }
`
const StyledDropdown = ({label, disabled, options, value, onChange, advancedDropdown, text, defaultOpen = false }) => (
  <div style={{ paddingRight: '12px'}}>
    <Label>{label}</Label>
    <RockerboxStyledDropdown 
      selection
      disabled={disabled}
      options={options}
      value={value}
      onChange={onChange}
      text={text}
      defaultOpen={defaultOpen}
    >
      {advancedDropdown && advancedDropdown}
    </RockerboxStyledDropdown>
  </div>
)

export default StyledDropdown
