import React, { useReducer, useMemo, useEffect } from 'react'
import { Input, Table, Dropdown, Label } from 'semantic-ui-react'
import toast from 'react-hot-toast'

import { forecastData } from './forecastData'
import { calculateBaselineFromSeasonality, summarizeForecastRows, setInitialFieldValues, buildUpdateFieldValues } from './forecastHelpers'
import { buildForecastRows } from './forecastTableRows'

import { Tooltip, SummaryBox } from '../../../components'
import { formatCurrency } from '../../../utils/valueFormatter'
import { CalendarIcon } from '../stateless'
import { roundNumber } from '../helpers'


const options = [
  { text: 'Recommended', value: 'recommended' },
  { text: 'Current', value: 'current' },
  { text: 'Custom', value: 'custom' },
]

const headerStyles = {
  fontSize: 12.5,
  fontWeight: 500,
}

const numberCellStyles = {
  textAlign: 'right',
  fontFeatureSettings: '"kern" 1, "tnum" 1',
}

const labelStyles = {
  fontSize: 12,
  fontWeight: 500,
  padding: 5,
}


const Forecast = ({ seasonality, responseCurves, featureRecommendations, features, featureStats, dateRange, setData }) => {
  const [fieldValues, setFieldValues] = useReducer((state, newState) => ({ ...state, ...newState }), {})

  const updateFieldValues = buildUpdateFieldValues(fieldValues, setFieldValues)

  useEffect(() => (
    setInitialFieldValues(featureRecommendations, setFieldValues)
  ), [responseCurves])

  const featureNameMap = useMemo(() => (
    features?.reduce((acc, x) => Object.assign(acc, {[x.value]: x.name}), {})
  ), [features])

  const forecastedData = useMemo(() => (
    forecastData(responseCurves, fieldValues)
  ), [responseCurves, fieldValues])

  const currentSpendValues = useMemo(() => (
    Object.entries(fieldValues).reduce((p, [k, v]) => {
      return Object.assign(p, {[k]: { value: v.current}})
    }, {})
  ), [responseCurves, fieldValues])

  const currentForecastedData = useMemo(() => (
    forecastData(responseCurves, currentSpendValues)
  ), [responseCurves, currentSpendValues])

  const forecastRows = useMemo(() => {
    const { numDays } = dateRange || {}
    return buildForecastRows(fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData, numDays)
  }, [fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData, dateRange])

  const baseline = useMemo(() => {
    const { startDaysFromNow, endDaysFromNow } = dateRange || {}
    return calculateBaselineFromSeasonality(seasonality, startDaysFromNow, endDaysFromNow)
  }, [seasonality, dateRange])

  const summary = useMemo(() => {
    const { numDays } = dateRange || {}
    return summarizeForecastRows(forecastRows, featureStats, baseline, numDays)
  }, [forecastRows, dateRange])

  useEffect(() => {
    if (!summary) return
    setData({ forecastRows, baseline, summary })
  }, [summary])

  const handleInput = (input, max) => {
    if (input < 0) return 0
    if (input > max) {
      toast(`The spend you entered is outside the bounds of our predictive model`, { icon: 'ℹ️', id: 'budget-forecast-input' })
      return roundNumber(max)
    }
    return input
  }

  const { numDays } = dateRange || {}

  return (<>
    <Table className='index-grid-new'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            collapsing
            content='Channel / Tactic'
            style={headerStyles}
          />
          <Table.HeaderCell
            content='Current Daily Spend'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            colSpan={2}
            content={<>
              Planned Daily Spend&nbsp;
              <Tooltip content='Our recommended Daily Spend values are based on our predictions to reach optimal ROAS efficiency, but you can also keep your current spend or enter a custom value that achieves your goals.' />
            </>}
            style={{ ...headerStyles, paddingRight: 65, textAlign: 'right' }}
          />
          <Table.HeaderCell
            content='Total Spend'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content='Predicted Revenue'
            style={{ ...headerStyles, ...numberCellStyles, paddingRight: 40 }}
          />
          <Table.HeaderCell
            colSpan={2}
            content='Revenue vs Current'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content='Predicted ROAS'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content='ROAS vs Current'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content='Spend Share'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content='Effect Share'
            style={{ ...headerStyles, ...numberCellStyles }}
          />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {forecastRows.map(obj => (
          <Table.Row>
            <Table.Cell
              collapsing
              content={featureNameMap?.[obj.key]}
              style={{ paddingLeft: 14 }}
            />
            <Table.Cell
              content={formatCurrency(featureStats?.[obj.key].currentSpend, 'USD', 0)}
              style={{ ...numberCellStyles }}
            />
            <Table.Cell style={{ paddingLeft: 40, paddingRight: 0, textAlign: 'right' }}>
              <Dropdown
                compact
                selection {...{options}} value={obj.currentSelection}
                onChange={(e, { value }) => updateFieldValues(obj['key'], "selection", value)}
                style={{ width: 130 }}
              />
            </Table.Cell>
            <Table.Cell collapsing style={{ paddingLeft: 4, minWidth: 121, fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
              <Input
                compact
                type='number'
                step={50}
                min={0}
                max={featureStats?.[obj.key].historicalSpendMax}
                icon='dollar'
                iconPosition='left'
                disabled={!obj.allowCustomInput}
                value={obj.targetValue}
                onChange={(e, { value }) => value >=0 && updateFieldValues(obj['key'], "value" , handleInput(value, featureStats?.[obj.key].maxSpend))}
                className={`align-right slim-label ${!obj.allowCustomInput && 'disabled-simple'}`}
                style={{ width: 87 }}
              />
            </Table.Cell>
            <Table.Cell
              content={formatCurrency(obj.estimatedTotalValue, 'USD', 0)}
              style={numberCellStyles}
            />
            <Table.Cell
              content={formatCurrency(obj.revenueAtTargetValue*numDays, 'USD', 0)}
              style={{ ...numberCellStyles, paddingRight: 40 }}
            />
            <Table.Cell
              content={`${obj.revenueAtTargetValue > obj.revenueAtCurrentValue ? '+' : ''}${roundNumber(obj.revenuePercentDiff*100, 1)}%`}
              style={{ ...numberCellStyles, paddingRight: 7 }}
            />
            <Table.Cell
              collapsing
              style={{ ...numberCellStyles, paddingLeft: 0 }}
            >
              <Label
                content={`${obj.revenueAtTargetValue > obj.revenueAtCurrentValue ? '+' : ''}${formatCurrency(obj.revenueAtTargetValue*numDays - obj.revenueAtCurrentValue*numDays, 'USD', 0)}`}
                color={obj.revenueAtTargetValue > obj.revenueAtCurrentValue ? 'green' : obj.revenueAtTargetValue < obj.revenueAtCurrentValue ? 'red' : ''}
                style={labelStyles}
              />
            </Table.Cell>
            <Table.Cell
              content={roundNumber(obj.ROIAtTargetValue, 2)}
              style={numberCellStyles}
            />
            <Table.Cell style={numberCellStyles}>
              <Label
                content={`${obj.ROIAtTargetValue > obj.ROIAtCurrentValue ? '+' : ''}${roundNumber(obj.ROIAtTargetValue - obj.ROIAtCurrentValue, 2)}`}
                color={obj.ROIAtTargetValue > obj.ROIAtCurrentValue ? 'green' : obj.ROIAtTargetValue < obj.ROIAtCurrentValue ? 'red' : ''}
                style={labelStyles}
              />
            </Table.Cell>
            <Table.Cell
              content={`${parseInt(roundNumber(obj.spendShare, 3)*1000)/10}%`}
              style={numberCellStyles}
            />
            <Table.Cell
              content={`${parseInt(roundNumber(obj.effectShare, 3)*1000)/10}%`}
              style={numberCellStyles}
            />
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell
            collapsing
            content='Marketing Summary'
            style={headerStyles}
          />
          <Table.HeaderCell
            content={formatCurrency(summary.dailySpend, 'USD', 0)}
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell />
          <Table.HeaderCell
            content={formatCurrency(summary.targetDailySpend, 'USD', 0)}
            style={{ ...headerStyles, textAlign: 'right', paddingRight: 49 }}
          />
          <Table.HeaderCell
            content={formatCurrency(summary.totalSpend, 'USD', 0)}
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell
            content={formatCurrency(summary.predictedRevenue, 'USD', 0)}
            style={{ ...headerStyles, ...numberCellStyles, paddingRight: 40 }}
          />
          <Table.HeaderCell
            content={`${summary.predictedRevenue > summary.currentRevenue ? '+' : ''}${roundNumber(summary.revenuePercentDiff*100, 1)}%`}
            style={{ ...headerStyles, ...numberCellStyles, paddingRight: 7 }}
          />
          <Table.HeaderCell
            collapsing
            style={{ ...headerStyles, ...numberCellStyles, paddingLeft: 0 }}
          >
            <Label
              content={`${summary.revenueDiff > 0 ? '+' : ''}${formatCurrency(summary.revenueDiff, 'USD', 0)}`}
              color={summary.predictedRevenue > summary.currentRevenue ? 'green' : summary.predictedRevenue < summary.currentRevenue ? 'red' : ''}
              style={labelStyles}
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            content={roundNumber(summary.predictedRoas, 2)}
            style={{ ...headerStyles, ...numberCellStyles }}
          />
          <Table.HeaderCell style={{ ...headerStyles, ...numberCellStyles }}>
            <Label
              content={`${summary.roasDiff > 0 ? '+' : ''}${roundNumber(summary.roasDiff, 2)}`}
              color={summary.roasDiff > 0 ? 'green' : summary.roasDiff < 0 ? 'red' : ''}
              style={labelStyles}
            />
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Footer>
    </Table>

    {baseline > 0 &&
      <SummaryBox
        icon={<CalendarIcon/>}
        headline="Seasonal effects on revenue"
        text="We estimate your baseline revenue for this time period based on historical data and add that to your predicted marketing revenue to calculate your total predicted revenue."
        statCards={[
          {label: "Baseline Revenue", value: formatCurrency(baseline, 'USD', 0)},
          {label: null, value: "+"},
          {label: "Predicted Marketing Revenue", value: formatCurrency(summary.predictedRevenue, 'USD', 0)},
          {label: null, value: "="},
          {label: "Total Predicted Revenue" , value: formatCurrency(baseline + summary.predictedRevenue, 'USD', 0)} ]}
      />
    }
  </>)
}

export default Forecast
