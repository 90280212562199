import { IndexGridTree } from '@rockerbox/styleguide';
import { spendFormatter } from '../../utils/valueFormatter';

const f = (values) => values.filter(x => !x.depth)

export const buildCardMetricArray = (revenueKey, conversionKey, currencyCode) => {
  return {
    conversions: {
      "name": "conversions",
      "column": conversionKey,
      "is_default": true,
      "display_name": "Conversions",
      "format": "decimal",
      "as":  IndexGridTree.TextOnly, // TODO: fix to IndexGridTree.NumberCell,
      "trendReversed": false
    },
    revenue: {
      "name": "revenue",
      "column": revenueKey,
      "is_default": true,
      "display_name": "Revenue",
      "format": "currency",
      "as": IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      "trendReversed": false
    },
    spend: {
      "name": "spend",
      "is_default": true,
      "display_name": "Spend",
      "format": "currency",
      "reducer": (values) => d3sum(f(values), x => x.spend),
      "as": IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      "trendReversed": false
    },
    cpa: {
      "name": "cpa",
      "is_default": true,
      "display_name": "CPA",
      "format": "currency",
      "reducer": (values) => d3sum(f(values), x => x[conversionKey]) ? d3sum(f(values), x => x.spend) / d3sum(f(values), x => x[conversionKey]) : Infinity,
      "as": IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      "trendReversed": true,
      "formula": {
        "type": "devide",
        "params": ["spend", conversionKey],
        "func": (x, y) => x / y
      }
    },
    roas: {
      "name": "roas",
      "is_default": true,
      "display_name": "ROAS",
      "format": "decimal",
      "reducer":  (values) => { d3.sum(f(values), x => x.spend) ? d3.sum(f(values), x => x[revenueKey])/d3.sum(f(values), x => x.spend) : Infinity },
      "as": IndexGridTree.NumberCell,
      "trendReversed": false,
      "formula": {
        "type": "devide",
        "params": [revenueKey, "spend"],
        "func": (x, y) => x / y
      }
    },
    rpc: {
      "name": "rpc",
      "is_default": true,
      "display_name": "Avg. Rev per Conv",
      "format": "currency",
      "reducer":  (values) => { d3.sum(f(values), x => x[conversionKey]) ? d3.sum(f(values), x => x[revenueKey])/d3.sum(f(values), x => x[conversionKey]) : Infinity },
      "as": IndexGridTree.NumberCell,
      "trendReversed": false,
      "formula": {
        "type": "devide",
        "params": [revenueKey, conversionKey],
        "func": (x, y) => x / y
      }
    }
  }
}
