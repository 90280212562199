import React, { Component } from "react";
import moment from "moment";
import TimeRange, { yesterday, week, month } from "../timerange";
import { _pushPath } from "../pushpath";
import Layout from "./Layout";

class CreativeData extends _pushPath(TimeRange) {
  state = {
    startDate:
      (this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.startDate) ||
      moment.utc()
        .utcOffset(-5)
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
    endDate: moment.utc()
      .utcOffset(-5)
      .subtract(1, "days")
      .format("YYYY-MM-DD")
  };

  render() {
    const { startDate, endDate } = this.state;
    return (
      <Layout
        renderDateSelector={this.renderAirbnbDatepicker.bind(this)}
        startDate={startDate}
        endDate={endDate}
        timePicker={this.renderAirbnbDatepicker()}
        updatePath={this.updatePath}
        setDate={date => this.setState(date)}
        {...this.props}
      ></Layout>
    );
  }
}

export default CreativeData;
