import React, { useMemo, useCallback, useEffect, useState } from "react";
import { SplitLayout } from "@rockerbox/styleguide";
import {
  Table,
  Button,
  Icon,
  Header,
  Checkbox,
  Grid,
  Divider
} from "semantic-ui-react";
import { customHistory } from "../pushpath";
import ExperimentCard from "./ExperimentCard";
import CacheContext from "../../utils/CacheContext.js";
import { getSegments } from "../../utils/api";
import { findNumDays, parseExperimentState, sliceIntoChunks } from "./helper";
import { experimentStore } from "./store";
import { track } from "../../utils/tracking";
import _ from "lodash";

const experimentsSelector = state => [
  state.experimentList,
  state.getExperiments,
  state.showArchivedExperiment,
  state.setShowArchivedExperiment
];

const ExperimentList = props => {
  const [
    experiments,
    getExperiments,
    showArchivedExperiment,
    setShowArchivedExperiment
  ] = experimentStore(experimentsSelector);
  const { updatePath } = customHistory(props);
  const [state, setState, Context] = React.useContext(CacheContext);
  const [windowWidth, setWindowWidth] = useState();

  const segments = Context.getCache("segments", getSegments) || [];
  const segmentNameMap = segments.reduce((prev, cur) => {
    prev[cur["action_id"]] = cur["action_name"];
    return prev;
  }, {});

  useEffect(() => {
    getExperiments().then(data => track("experiment.viewed", data[0] || {}));
    const onWidthChange = _.debounce(
      () =>
        setWindowWidth(
          window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        ),
      500
    );

    window.addEventListener("resize", onWidthChange);
    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);

    return () => {
      window.removeEventListener("resize", onWidthChange);
    };
  }, []);

  const cardNum = React.useMemo(() => (windowWidth > 1600 ? 3 : 2), [
    windowWidth
  ]);

  const onCreateExperiment = useCallback(() => {
    updatePath({
      view: "create"
    });
    track("experiment.create.start");
  });

  const experimentsData = useMemo(() => {
    const formattedExperiments = experiments.map(experiment =>
      Object.assign({}, experiment, {
        segment_name: segmentNameMap[experiment.filter_id] || "N/A",
        num_days: findNumDays(experiment),
        end_date: experiment.end_date || "n/a",
        state: parseExperimentState(experiment),
        treamentsCount:
          (Array.isArray(experiment.treatments) &&
            experiment.treatments.length) ||
          0,
        baselineCampaignName: experiment[`control_${experiment.data_tier}`]
      })
    );

    const filterArchivedExperiments = showArchivedExperiment
      ? formattedExperiments
      : formattedExperiments.filter(exp => exp.state !== "archived");
    return filterArchivedExperiments;
  }, [experiments, showArchivedExperiment]);

  const cardList = React.useMemo(
    () => sliceIntoChunks(experimentsData, cardNum),
    [cardNum, experimentsData]
  );

  return (
    <>
      <Grid columns={3} textAlign="left" verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={10}>
            <h2>My Experiments</h2>
          </Grid.Column>

          <Grid.Column width={6} textAlign="right">
            <Checkbox
              toggle
              label={<label style={{paddingTop: 0}}>Show Archived</label>}
              checked={showArchivedExperiment}
              style={{ marginRight: 25 }}
              onChange={(e, data) => {
                setShowArchivedExperiment(data.checked);
              }}
            />
            <Button primary small onClick={onCreateExperiment} size="tiny">
              <Icon name="plus" />
              Create New Experiment
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid columns={cardNum}>
        {cardList.map((list, i) => {
          return (
            <Grid.Row key={i}>
              {list.map(item => (
                <Grid.Column key={item.id} stretched>
                  <ExperimentCard {...item} />
                </Grid.Column>
              ))}
            </Grid.Row>
          );
        })}
      </Grid>
    </>
  );
};

export default ExperimentList;
