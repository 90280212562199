import React from 'react'
import { metrics } from "../platformMetrics"
import { Image } from 'semantic-ui-react'
import { logoIconColor, logoIconWhite } from "@rockerbox/styleguide"
import { prettyDisplayNames } from '../helpers'
import { IndexGridTree } from '@rockerbox/styleguide'
import { formatCurrency } from "../../../utils/valueFormatter"

const updateMetricsGroup = (metrics_group, hasRevenue) => {
  const hasRevMetrics = metrics_group.includes('revenue') && metrics_group.includes('roas')
  const filtered = metrics_group.filter(c => c != 'revenue' && c != 'roas')

  return !hasRevenue ? filtered : hasRevMetrics ? metrics_group : filtered
}

const calcMainMetricByTotals = (model, selectedMetricObj, summedDailyComparisonObj, hasRevenue) => {
  const { name, formula } = selectedMetricObj
  const rbMetric = `${name}_${model}_rb`

  const formulaMapper = {
    roas: [`revenue_${model}_rb`, "spend"],
    cpa: ["spend", `conversions_${model}_rb`]
  }

  if(!formula) {
    return [summedDailyComparisonObj[rbMetric], summedDailyComparisonObj[name]]
  } 
  const [rb1, rb2] = [...formulaMapper[name]]
  const [p1, p2]= formula.params

  return [formula.func(summedDailyComparisonObj[rb1], summedDailyComparisonObj[rb2]), formula.func(summedDailyComparisonObj[p1], summedDailyComparisonObj[p2])]
}

export const formatNumber = (item, number, currency) => {
  const { format, maxDigits } = item;

  if (format == 'currency') return formatCurrency(number, currency, maxDigits ? maxDigits : 0)

  let numberFormatOptions = {
    style: format ? format : 'decimal',
    maximumFractionDigits: maxDigits ? maxDigits : 0
  }

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formatted = isNaN(number) ? '-' : typeof number !== 'number' ? numberFormatter.format(0) : numberFormatter.format(number);
  return formatted
}

const ComparisonStats = ({mainNumbers, item, integrations, platform, name, selectedCardMetric, accountCurrency}) => {
  const formatted = mainNumbers.map(c => formatNumber(item, c, accountCurrency))
  const platformConfig = !!integrations ? integrations[platform] : {}
  const { icon, alt } = platformConfig
  const active = selectedCardMetric == name

  return (
    <div style={{textAlign: 'left'}}>
      <div style={{marginBottom: 5, fontFamily: 'Poppins'}}>
       <Image size='tiny' src={logoIconColor} style={{ margin: '-4px 5px 0 0', width: 20, height: 20 }} /> 
        {formatted[0]}
      </div>
      <div className='secondary-metric' style={{fontFamily: 'Poppins'}}>
       <Image size='tiny' src={icon} style={{ margin: '-4px 5px 0 0', width: 20, height: 20 }} /> 
        {formatted[1]}
      </div>
    </div>
  )
}

export const generateComparisonCards = (model, dailyComparisonData, metrics_group, hasRevenue, platformConfig, integrations, platform, selectedCardMetric, accountCurrency) => {
  //reduce by model (spend, conversion, revenue -- if there), rb_[model]
  const summedDailyComparisonObj = dailyComparisonData.reduce((p,c) => {
    Object.keys(c).forEach(key => {
      if(key !== 'date') {
        p[key] = (p[key] || 0) + c[key]
      }
    })
    return p
  }, {})

    // update metrics_group -- include/exclude revenue+roas
  const updatedMetrics = updateMetricsGroup(metrics_group, hasRevenue)
  return updatedMetrics.flatMap(metric => {
    const selectedMetricObj = metrics[metric]
    if (!selectedMetricObj) return [];

    // calc 
    const { name, display_name, formula, format, trendReversed } = selectedMetricObj
    const mainNumbers = calcMainMetricByTotals(model, selectedMetricObj, summedDailyComparisonObj, hasRevenue)
    const [rbNumber, platformNumber] = mainNumbers
    // const rounded = mainNumbers.map(num => num > 100000 ? Math.trunc(num) : num)
    const isCurrencyOrDecimal = ['currency', 'decimal'].includes(format);
    const maxDigits = isCurrencyOrDecimal && (rbNumber > 100000 || platformNumber > 100000) ? 0 : isCurrencyOrDecimal ? 2 : (format == 'percent' && name == 'ctr') ? 4 : 0 // shows cents for currency cards
    const trendNumber = (rbNumber - platformNumber)/platformNumber

    const returnObj = {
      text: display_name,
      value: name,
      heading: display_name,
      maxDigits,
      trendReversed,
      statistic: <ComparisonStats {...{mainNumbers, item: {format, maxDigits}, integrations, platform, name, selectedCardMetric, accountCurrency}}/>,
      trendNumber,
      baseline: rbNumber == platformNumber ? `No change` : `${trendNumber > 0 ? 'more' : 'less'} vs. ${platformConfig.display_platform_name}`
    }
    return returnObj
  })
}

export const generateSegmentMetrics = (modelOptions, segHasRevenue, optional_metrics) => {
  // determine if caclulated metrics are present AND if platform / segmennt contain revenue data to avoid calculation and display in the view
  const updatedCardValues = updateMetricsGroup(optional_metrics, segHasRevenue)
  const formulaMetrics = ["cpa", "roas", "ctr", "cpc", "cpm"]
  const findFormulaMetrics = formulaMetrics.filter(value => updatedCardValues.includes(value))

  const metricCols = ["spend", "conversions", "conversions_even_rb", "conversions_first_touch_rb", "conversions_last_touch_rb", "conversions_assisted_rb", "conversions_assisted_att_win_rb"]
  const revenueCols = ["revenue", "revenue_even_rb", "revenue_first_touch_rb", "revenue_last_touch_rb", "revenue_assisted_rb", "revenue_assisted_att_win_rb"]
  const hasModel = modelOptions.find( obj => (obj.value == 'normalized'))
  const platformHasRevenue = optional_metrics.includes('revenue')

  if (segHasRevenue && platformHasRevenue) metricCols.push(...revenueCols);
  if (hasModel) metricCols.push(...['conversions_normalized_rb']);
  if (hasModel && segHasRevenue && platformHasRevenue) metricCols.push(...['revenue_normalized_rb'])
  if (findFormulaMetrics.length > 0) {
    const attributedFormulas = findFormulaMetrics.map(metric => `attributed_${metric}`)
    metricCols.push(...findFormulaMetrics, ...attributedFormulas)
  }

  return metricCols
}

export const generateTier1DisplayNames = (tiers) => {
  const tier1DisplayName = tiers.map(prettyDisplayNames).join(" / ")
  return tier1DisplayName
}

export const generateFirstColumn = (hierarchy, displayNames) => {
  const cols = [
    { display: displayNames, key: 'group', groupBy: hierarchy, as: IndexGridTree.NameCell({}) }
  ]
  return cols
}

export const generateColsComparison = (metricCols, model, modelDisplayName, platformIcon, firstColumn, currencyCode, platformText = false) => {
  const cols = firstColumn
  const orderedMetrics = Object.keys(metrics).filter( metric =>  metricCols.includes(metric) )
  orderedMetrics.forEach(metric => {
    if (metric.includes(`_rb`) && !metric.includes(`_${model}_rb`)) return; // Remove all RB metrics not for the selected model
    const attributes = metrics[metric]
    const customName = ` ${prettyDisplayNames(modelDisplayName)} ${attributes.custom_name}`
    const formulaMapper = {
      attributed_roas: [`revenue_${model}_rb`, "spend"],
      attributed_cpa: ["spend", `conversions_${model}_rb`]
    }
    const formulaValues = attributes.buildReducer ? formulaMapper[attributes.name] : undefined;
    const isRockerboxMetric = metric.includes('_rb') || metric.includes('attributed_')
    const icon = isRockerboxMetric ? logoIconColor : platformIcon

    const generateDisplayName = () => {
      if (platformText) {
        const prefix = isRockerboxMetric ? 'RB ' : `${platformText} `
        return prefix + attributes.display_name || customName
      }
      return (<><img src={icon} style={{ height: 11, marginBottom: -1 }} /> {attributes.display_name || customName}</>)
    }

    cols.push({
      currency: currencyCode,
      display: generateDisplayName(),
      key: attributes.name,
      isSearchable: true,
      as: attributes.as,
      reducer: attributes.reducer || attributes.buildReducer(formulaValues[0], formulaValues[1]), 
      style: {textAlign: 'right'}
    })
  })
  return cols
}
