import React, { Component } from 'react';
import { getDataset, getEntityConfigs } from '../../utils/api';
import { Bar, SplitLayout, SortableIndexGrid, IndexGrid, ContentCard, PivotGrid } from '@rockerbox/styleguide';
import { Dropdown, Step, Message, Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import Impact from '../Impact';
import SetupStatus, { defaultFunc } from '../DataStatus';
import SummaryBar from '../ViewAttribution/SummaryBar';
import CacheContext from '../../utils/CacheContext';

const numberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}

const ViewCampaignSpend = ({ current, refresh }) => {

  const [{currency_code}] = React.useContext(CacheContext);
  const [allData, setAllData] = React.useState(false)
  const [allMonths, setAllMonths] = React.useState([])
  const [monthly, setMonthly] = React.useState([])
  const [dataByDate, setDataByDate] = React.useState(false)
  const [dataByMonth, setDataByMonth] = React.useState(false)

  const numberFormatter = React.useMemo(() => {
    numberFormatOptions['currency'] = currency_code
    return new Intl.NumberFormat('en-US', numberFormatOptions);
  }, [currency_code])

  const FormattedCell = ({item, col}) => <Table.Cell>{ item[col.key] ? numberFormatter.format(item[col.key]) : "-" }</Table.Cell>

  React.useEffect(() => {
    getEntityConfigs()
      .then(resp => {
        const withCosts = resp.filter(row => row.costs.length)
        const allCosts = withCosts.reduce((p,c) => {
          const allCosts = c.costs.reduce((all,cost) => {
            const entityCosts = cost.cost_range.map(day => Object.assign(day, {"entity_name": c.name}))
            return [...all, ...entityCosts]
          }, [])
          return [...p, ...allCosts]
        }, [])

        console.log(allCosts)

        const costsByDate = d3.nest()
          .key(row => row.date)
          .rollup(values => {
            return d3.sum(values, value => value.cost)
          })
          .entries(allCosts)
          .map(row => Object.assign(row, {"date": row.key.split(" ")[0]}))

        const allMonths = {}

        const costsByMonthEntity= d3.nest()
          .key(row => row.entity_name)
          .key(row => row.date.split(" ")[0].split("-").slice(0,-1).join("-") + "-01")
          .rollup(values => {
            return d3.sum(values, value => value.cost)
          })
          .entries(allCosts)
          .map(row => {
            const entityName = row.key
            const months = row.values.reduce((p,c) => Object.assign(p, {[c.key] : c.values}), {})
            Object.assign(allMonths, months)
            return {entityName, ...months}
          })

        const costsByMonth = d3.nest()
          .key(row => row.date.split(" ")[0].split("-").slice(0,-1).join("-") + "-01")
          .rollup(values => {
            return d3.sum(values, value => value.cost)
          })
          .map(allCosts)

        setAllData(allCosts)
        setAllMonths(Object.keys(allMonths).sort())
        setDataByMonth(costsByMonthEntity)
        setMonthly(costsByMonth)
        setDataByDate(costsByDate)
      })
  }, [])

  const cols = React.useMemo(() => {
    return [
      {display: "Entity", key: "entityName"},
      ...allMonths.map(display => {
        return {display, key: display, as: FormattedCell }
      }),
      { display: "Total", key: "total", 
        as: ({item, col}) => <Table.Cell style={{backgroundColor:"#F9FAFB", borderLeft:"1px solid #eee"}}>{numberFormatter.format(allMonths.reduce((p,c) => p + (item[c]||0), 0))}</Table.Cell> 
      }
    ]
  }, [allMonths])

  const columnNames = {
    "cost:sum": "Cost", 
    "entity_name":"Entity", 
    "cost:percent-row":"Cost (% of row)", 
    "cost:percent-col":"Cost (% of column)",
    "date":"Date",
    "name":"Campaign Name"
  }

  return <>
    <ContentCard title="Entity Campaign Spend" hasTable>
      <PivotGrid 
        showOptions
        columnNames={columnNames}
        hasRawData={allData.length > 0} 
        data={allData} 
        formatTypesAs={{"sum": FormattedCell}} 
        defaultCols={["date"]} 
        defaultRows={["entity_name"]} 
        defaultSummary={["cost:sum"]} 
      />
    </ContentCard>
  </>

}

export default ViewCampaignSpend;
