import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Error } from '../styled';

const EVENT_ACTIVITY = '/cache/something?id='

class EventActivity extends Component {
  render() {
    return (
      <div>
        <ContentCard title="Event Activity"></ContentCard>
        <Error>There is currently no event activity or bucketing rules setup for your account.</Error>
        <ContentCard title="Unmapped Events"></ContentCard>
        <Error>No unbucketed events were detected for your account.</Error>
      </div>
    );
  }
}


class AttributableEventData extends Component {
  render() {
    const { setEventData } = this.props;
    return [
      //<Fetch  endpoint={CONVERSION_EVENT_DATA} success={(data) => setSegmentFields(data.response)} />,
      <EventActivity {...this.props} />
    ]
  }
}

function mapStateToProps(state, props) {
  return {

  };
}

function mapDispatchToProps(dispatch, props) {
  return {

  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(EventActivity);
export default EventActivity;
