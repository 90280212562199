import React, { Component } from 'react';
import shallow from 'zustand/shallow'
import CacheContext from '../../utils/CacheContext.js'
import { Message, Segment, Modal, Button, Form, Input } from 'semantic-ui-react';
import { postEntityProgram, updateEntityProgram, getEntityProgramConfigs } from '../../utils/api';
import { SetupDisplaySelector } from '../Entities/SetupDisplay';
import { entityStore } from './entityStore';

import { SelectCheckbox, SplitLayout, IndexGrid, ContentCard } from '@rockerbox/styleguide';

const CreateEntityProgram = (props) => {
  const [ set ] = entityStore(({ set, }) => [ set ], shallow)

  const { programOptions, onClose, onSubmit, open, id, hasLegacySpend } = props
  const [ entityType, setEntityType] = React.useState(props.entityType || '')
  const [ description, setDescription] = React.useState(props.description || false)
  const [ fixedCost, setFixedCost] = React.useState(props.fixedCost || false)
  const [ percentCost, setPercentCost] = React.useState(props.percentCost || false)
  const [ shouldHaveSponsorshipCost, setShouldHaveSponsorshipCost] = React.useState(props.shouldHaveSponsorshipCost || false)
  const [ display, setDisplay] = React.useState(props.display || false)
  const [loading, setLoading] = React.useState(false)
  const [ nameError, setNameError] = React.useState(false)

  const saveChanges = () => {
    const entity_type = entityType
    const entity_description = description || entity_type
    const fixed_unit_cost = fixedCost
    const rev_share_percent = percentCost
    const has_campaign_costs = shouldHaveSponsorshipCost

    const postObj = Object.assign({ entity_type, description: entity_description, fixed_unit_cost, rev_share_percent, has_campaign_costs }, display)

    const send = id ? updateEntityProgram : postEntityProgram

    setLoading(true)
    send(postObj, id)
      .then(data => {
        set({ entityProgramConfigs: data.response })
        !!onSubmit && onSubmit({ value: postObj })
        setLoading(false)
        !!onClose && onClose()
        resetForm()
      })
  }

  React.useEffect(() => {
    const foundValue = id ? programOptions.find(row => (row.text === entityType && row.text !== props.entityType) ) : programOptions.find(row => row.text === entityType)
    if (!foundValue) {
      setNameError(false)
    } else {
      setNameError({
        content: 'Sponsorship Program Name already exists. Please enter unique sponsorship name.',
        pointing: 'below',
      })
    }
  }, [entityType])

  const resetForm = () => {
    setEntityType('')
    setDescription(false)
    setFixedCost(false)
    setPercentCost(false)
    setShouldHaveSponsorshipCost(false)
    setDisplay(false)
  }

  const FixedPayout = <Segment>
    <Form.Field>
      <label>Amount paid to sponsor per purchase</label>
      <Input 
        label="USD $"
        value={fixedCost||""}
        placeholder="" 
        onChange={(evt, { value }) => setFixedCost(value) }
      />
    </Form.Field>
  </Segment>

  const PercentPayout = <Segment>
    <Form.Field>
      <label>Percent of revenue paid to sponsor per purchase</label>
      <Input 
        label="%"
        labelPosition="right"
        value={percentCost||""}
        placeholder="" 
        onChange={(evt, { value }) => setPercentCost(value) }
      />
    </Form.Field>
  </Segment>

  const SponsorshipPayment = <Message>
    You will be asked to enter this information for each sponsor that is added to the program to account for the appropriate costs
  </Message>

  const paymentValues = []
  fixedCost ? paymentValues.push("fixed_payout") : null
  percentCost ? paymentValues.push("percent_payout") : null
  shouldHaveSponsorshipCost ? paymentValues.push("sponsorship") : null

  const PAYMENT_TERMS = [
    {text: "A fixed amount per purchase", value: "fixed_payout", ShowSelected: FixedPayout}, 
    {text: "A percent of revenue driven", value: "percent_payout", ShowSelected: PercentPayout},
    {text: "A fee for a sponsorship / campaign run", value: "sponsorship", ShowSelected: SponsorshipPayment}
  ]

  const onPaymentSelect = (evt, { value }) => {
    (value.length && value.find("sponsorship")) ? 
      setShouldHaveSponsorshipCost(true) : setShouldHaveSponsorshipCost(false)
  }

  const updateSponsorshipCosts = (evt, { value }) => value.includes("sponsorship") ? 
    setShouldHaveSponsorshipCost(true) :
    setShouldHaveSponsorshipCost(false)

  return <Modal {...{onClose, open}}>
    <Modal.Header>
      { id ? "Edit" : "Create"} Sponsorship Program
    </Modal.Header>
    <Modal.Content>
      { !id && <Message 
          active
          header="What is a sponsorship program?"
          content="A program is a set of rules that define how promo codes are respresented in your attribution data." 
        />
      }
      <Form>
        <Form.Input 
          label='Program Name'
          value={entityType}
          placeholder="ex: Influencer, Podcast, Site Sponsorship" 
          onChange={(evt, { value }) => {
            setEntityType(value) 
          }}
          error={nameError}
        />
        <Form.Field>
          <label>Description</label>
          <Form.Input 
            value={description != "0" ? description || "" : ""}
            placeholder="Brief description about the program for you to reference" 
            onChange={(evt, { value }) => setDescription(value) }
          />
        </Form.Field>
        {!!hasLegacySpend && <>
          <h5>Payment Structure</h5>
          <Segment secondary>
            <SelectCheckbox 
              disabled={!!id}
              showBorder={false}
              value={paymentValues}
              description="Participants in this program are paid:" 
              multiple options={PAYMENT_TERMS} onChange={updateSponsorshipCosts}
              />
          </Segment>
        </>}
        <h5>Reporting Structure</h5>
        <Segment secondary>
          <div style={{fontWeight:"bold",paddingBottom:"10px"}}>Setup the standard structure underwhich you want marketing associated with this program to appear:</div>
          <div style={{padding:"10px"}}>
            <SetupDisplaySelector value={display} onChange={(evt, { value }) => setDisplay(value)} />
          </div>
        </Segment>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={saveChanges} type="submit" primary disabled={nameError} {...{loading}}>Save</Button>
    </Modal.Actions>
  </Modal>
}


export default CreateEntityProgram;
