export function getPixelCnameDomain(force_run_test) {
  const url = `/pixels/cname${force_run_test ? "?force_run_test=true" : ""}`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postPixelCnameDomain(obj) {
  const url = `/pixels/cname`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getPixelValidationStatus(platform) {
  const url = `/pixels/validate?platform=${platform}`;
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getPixelCode() {
  const url = `/pixels/code`;
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getPixelTrackingResolutions(platform) {
  const url = `/pixels/tracking_resolution?platform=${platform}`;
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function postPixelTrackingResolution(obj) {
  const url = `/pixels/tracking_resolution`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => data.response)
}
