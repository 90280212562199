import React from 'react'
import { Button } from 'semantic-ui-react'
import { ViewBoardsIcon } from '@heroicons/react/solid'

const BreakdownsButton = ({onClick}) => (
  <Button
    inverted
    color="purple"
    onClick={onClick}
    style={{ padding: '11px 13px', marginRight: 10, fontSize: 14 }}
    className='remove-box-shadow'
  >
    <ViewBoardsIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px', transform: 'rotate(90deg)' }} />
    Edit Breakdowns
  </Button>
)

export default BreakdownsButton
