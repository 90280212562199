import { atomWithHash } from "jotai/utils";

export const customerTypeAtom = atomWithHash("customerType", "all")
export const attributionMethodAtom = atomWithHash("model", "")

export const modelAtom = atomWithHash('model', "")
export const segIdAtom = atomWithHash("segId", "0")
export const convValueAtom = atomWithHash("platformConversion", "")
export const attributionWindowAtom = atomWithHash("attributionWindow", "")

// position in path
export const anyAtom = atomWithHash("any", "any")
export const firstAtom = atomWithHash("first", "first")
export const midAtom = atomWithHash("mid", "mid")
export const lastAtom = atomWithHash("last", "last")

// tiers
export const tier1Atom = atomWithHash("tier_1", [])
export const tier2Atom = atomWithHash("tier_2", [])
export const tier3Atom = atomWithHash("tier_3", [])
export const tier4Atom = atomWithHash("tier_4", [])
export const tier5Atom = atomWithHash("tier_5", [])

// goal
export const goalAtom = atomWithHash("goal", {})

// saved_report
export const saveIDAtom = atomWithHash("saveID", 0)

// search bar
export const searchAtom = atomWithHash("search", "")

// edit breakdowns
export const tiersAtom = atomWithHash("tiers", [])

// customize columns
export const columnsAtom = atomWithHash("columns", [])

// benchmarks view
export const categoryAtom = atomWithHash("category", "")
export const categoryValueAtom = atomWithHash("categoryValue", "")
export const selectedChannelAtom = atomWithHash("channel", "facebook")
export const platformAtom = atomWithHash("platform", "facebook")

// tab above chart
export const topTabAtom = atomWithHash("topTab", "conversions")

// new visitors, funnel position, paths
export const reportTypeAtom = atomWithHash("reportType", "")
export const minPathLenAtom = atomWithHash("minPathLen", "2")
