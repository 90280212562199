import React, { useState, useCallback } from 'react';
import { Input, Message, Form } from 'semantic-ui-react';
import { addNewUser } from '../../api/account';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';

const InviteUsersInput = ({handleManageUpdate}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ defaultValues: { email: '' } });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = useCallback(({ email }) => {
    const postObj = {
      email: email.trim()
    };
    setLoading(true);
    addNewUser(postObj)
      .then(({ error, error_message }) => {
        if (error) {
          toast.error('User already exists')
          setLoading(false);
          return;
        }
        reset({ email: '' })
        setLoading(false);
        setError(false);
        toast.success("Successfully invited new user")
        handleManageUpdate();
      })
      .catch((err) => {
        console.log('error posting inviting user', err.response);
        toast.error('User already exists')
        setLoading(false);
      });
  });

  return (
      <div style={{ marginBottom: 14 }}>
        {errors.email?.message && (
          <div style={{ backgroundColor: 'rgb(224, 180, 180)' }} className="ui pointing below prompt label">
            {errors.email.message}
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => 
              <Input
                {...field}
                // style={{ width: 275 }}
                placeholder="user@email.com"
                error={errors.email?.message}
                action={{
                  primary: true,
                  type: 'submit',
                  content: 'Invite',
                  loading,
                }}
              />
            }
            rules={{
              required: 'Missing email',
              pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please use a valid email',
              },
            }}
          />
        </Form>
        {error && (
          <Message negative>
            <p>{errorMsg}</p>
          </Message>
        )}
      </div>
  );
};

export default InviteUsersInput;
