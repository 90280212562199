import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from "styled-components";
import * as d3 from 'rockerbox_d3_legacy_clone';


const FlexOption = styled.div`
  display: flex;
  margin-top:3px;
  margin-left:20px;
  margin-right:20px;
  padding:15px;
  ${({ itemsPerRow }) => {
    if (!itemsPerRow) return ""
    const percent = 100/itemsPerRow - 5
    return "flex: 1 0 " + percent + "%; max-width: " + (percent+1.2) + "%; min-width: " + (percent+1.2) + "%;"
  }}

  border:1.5px solid ${({active}) => active ? "rgb(81 173 139 / 85%)" : "rgb(81 173 139 / 25%)"};
  ${({ isPlaceholder }) => isPlaceholder ? "border:0px;" : ""}
  ${({active}) => active ? "background:rgb(81 173 139 / 5%);" : ""}
  border-radius:.5em;
  cursor: pointer;
  ${({ active }) => active ? "font-weight:bold;" : ""}
  & > .icon {
    margin-right:10px;
    margin-top:4px;
    ${({ active }) => active ? "color: #51AD8B;" : ""}
  }
  & .text {
    ${({ large }) => large ? "font-size: 1.2em; line-height: 1.6em; text-align:center; width:100%;" : ""}
  }
`

const ButtonOptions = ({ defaultValue, large, options, onChange }) => {

  const [selected, setSelected] = React.useState(defaultValue)
  const [init, setInit] = React.useState(true)

  React.useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  React.useEffect(() => {
    if (init) {
      setInit(false)
      return
    }

    onChange(false, { value: selected })
    
  }, [selected])

  return <div style={{display:"flex",flexDirection:"columns",justifyContent:"space-around",flexWrap:"wrap"}}>
    { options.map(row => {
        
        return <FlexOption active={selected == row.value} onClick={() => setSelected(row.value)} itemsPerRow={4} large={large}>
          { row.icon && <Icon size="big" name={row.icon} /> }
          <div className="text">{ row.text }</div>
        </FlexOption>
      })
    }
    { d3.range(0,(options.length % 4)).map(item => <FlexOption isPlaceholder style={{minWidth:"21.2%"}} />) }
  </div>
}

export default ButtonOptions;
