import React, { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';
import { Tooltip as InfoTooltip } from '../../../components'
import styled from 'styled-components';
import { tooltipStyles, PLATFORM_COLORS } from './chartHelpers';
import tinycolor from 'tinycolor2'

const HeaderCell = styled(Table.HeaderCell)`
  font-weight:bold;
`

const TableCell = styled(Table.Cell)`
  ${({color}) => {
    if (color < 0) {
      const negativeColor = "#E61939"
      const bgColor = tinycolor(negativeColor).lighten(10).darken(-color*30).toHexString()
      return "background-color:" + bgColor
    } else {
      const positiveColor = "#5fa8f6"
      const bgColor = tinycolor(positiveColor).lighten(10).darken(color*30).toHexString()
      return "background-color:" + bgColor
    }
  }}
`

function roundToTwoDecimalPlaces(number) {
  return parseFloat(parseFloat(number).toFixed(2));
}

const getColorClass = (strValue) => {
  const value = roundToTwoDecimalPlaces(strValue);

  if(value <= -0.5) return 'very-negative';
  if(value <= -0.2) return 'negative';
  if(value <= 0.2) return 'neutral';
  if(value <= 0.5) return 'positive';
  return 'very-positive';
};

const CorrelationChart = ({ correlationData = [], features }) => {

  const columns = Array.from(new Set(correlationData.map(item => item.media_col_to).concat(correlationData.map(item => item.media_col_from))));

  // Create a map for easy access to the values
  const correlation_map = correlationData.reduce((acc, {media_col_from, media_col_to, value}) => {
    acc[`${media_col_from}-${media_col_to}`] = value;
    return acc;
  }, {});

  return (
    <>
      <Header
        as='h4'
        content={<>
          Feature Correlation
          <InfoTooltip
            position='right center'
            content='See how your features are correlated with eachother and revenue.'
            style={{ fontSize: 14, marginLeft: 5, marginBottom: 5 }}
          />
        </>}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <HeaderCell />
            {features.map((column, index) => (
              <HeaderCell key={index}>{index+1}</HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {features.map((row, i) => (
            <Table.Row key={i}>
              <Table.HeaderCell style={{paddingLeft:"5px"}}>{i+1}. {row.name == "target" ? "Revenue" : row.name}</Table.HeaderCell>
              {features.map((column, j) => {
                const cellValue = correlation_map[`${row.value}-${column.value}`] || correlation_map[`${column.value}-${row.value}`] || 0;
                return (
                  <TableCell key={j} color={cellValue}>
                    {roundToTwoDecimalPlaces(cellValue)}
                  </TableCell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default CorrelationChart;
