import React from 'react'
import { Popup } from 'semantic-ui-react';

const ScrollingPopup = props => {
  const {content, ...restOfProps} = props;
  return(
    <Popup
      className='scrolling-popup'
      {...restOfProps}
      content={
        <div className='content'>
          {content}
        </div>
      }
    />
  )
}

export default ScrollingPopup
