import React from 'react'
import { UserCircleIcon, UserGroupIcon, GlobeAltIcon, ColorSwatchIcon, CreditCardIcon } from '@heroicons/react/solid'

import * as routes from '../../baseRoutes'

import MyAccount from './MyAccount'
import ManageUsers from './ManageUsers'
import Domains from './Domains'
import TierColors from './TierColors'
import Billing from './Billing'

export const SETTINGS_MENU = [
  {
    name: 'My Account',
    icon: <UserCircleIcon />,
    route: routes.myAccount,
    component: MyAccount,
  },
  {
    name: 'Team Users',
    icon: <UserGroupIcon />,
    route: routes.manageUsers,
    component: ManageUsers,
  },
  {
    name: 'Domains',
    icon: <GlobeAltIcon />,
    route: routes.domains,
    component: Domains,
    lockedTiers: ['lite'],
  },
  {
    name: 'Colors',
    icon: <ColorSwatchIcon />,
    route: routes.colors,
    component: TierColors,
    lockedTiers: ['lite'],
  },
  {
    name: 'Billing',
    icon: <CreditCardIcon />,
    route: routes.billing,
    component: Billing,
    lockedTiers: ['lite', 'scale'],
  },
]
