import React, { Component, useMemo, useState, useEffect } from 'react';
import { IndexGrid, IndexGridNew, ContentCard } from '@rockerbox/styleguide';

import { getSegments, getDataFeatures } from '../../utils/api';

const NAMES = {
  // TODO:
  // - add external_events (generic events for batch / webhook)
  // - add lookups check

  has_clicks: "Click Tracker",
  has_served: "Creative Pixel",
  has_direct_mail_generic: "Direct Mail",
  has_events_from_conversion: "Events from Conversion",
  has_facebook_synthetic: "Facebook Synthetic",
  has_ott: "OTT",
  has_ott_generic: "Generic OTT",
  has_postlog: "Postlog",
  has_spend: "Marketing",
  has_survey: "Survey",
  has_transform_table: "Uses Transform Tables",
  shopify_type: "Shopify"
}
const nameEntries = Object.entries(NAMES)
const nameValues = Object.values(NAMES)

const COLS = [
  ...IndexGrid.arrayToColumns(["name"]),
  ...(IndexGrid.arrayToColumns(nameValues)
    .map(col => Object.assign(col, {as: IndexGridNew.CelledCheckmark}))
  )
]

const renameFeatureRow = (row, name) => nameEntries
  .reduce((p, [key, col]) => {
    p[col] = row[key]
    return p
  }, { name })


const DataFeatures = (props) => {
  const [dataFeaturesRaw, setDataFeaturesRaw] = React.useState([])
  const [segments, setSegments] = React.useState([])

  useEffect(() => getDataFeatures().then((resp) => setDataFeaturesRaw(resp)), [])
  useEffect(() => getSegments().then((resp) => setSegments(resp)), [])

  const dataFeatures = useMemo(() => {
    if ((segments.length == 0) || (dataFeaturesRaw.length == 0)) return []

    const segObj = segments.reduce((p,c) => {
      p[c.action_id] = c.action_name;
      return p
    },{})

    return dataFeaturesRaw.map(row => renameFeatureRow(row, segObj[row.identifier] ))

  }, [dataFeaturesRaw, segments])


  return <IndexGridNew cols={COLS} data={dataFeatures} />
}

export default DataFeatures;
