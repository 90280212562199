import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react';
import fuzzysort from 'fuzzysort';

let init = true
const SearchCell = (options) => (props) => {

  const [ search, setSearch ] = React.useState(false)
  const { onKeyDown, onCommit, onChange, value } = props

  const upHandler = (e) => {
    if (init && e.metaKey == false && search !== false) {
      init = false
      setSearch(e.key)
    }
  }

  React.useEffect(() => {
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [])

  const searchLev = (options, query) => {
    return fuzzysort.go(query, options, {key: "text"})
      .map(row => row.obj)
  }

  return <div style={{fontSize:".7em"}}>
    <Form.Dropdown {...{value}} 
      search 
      open allowAdditions fluid selection 
      closeOnChange={false}
      selectOnNavigation
      search={searchLev}
      searchQuery={search !== false ? search : value || ""}
      searchInput={{ autoFocus: true }}
      onSearchChange={(e, o) => {
        init = false
        setSearch(o.searchQuery) 
      }}
      options={options}  
      onChange={(e, { value }) => {
        e.preventDefault()
        if (e.type == "keydown" && e.key != "Enter") return
        console.log(value)
        onCommit(value) 
        init = true
      }} 
    />
  </div>
}

SearchCell.propTypes = {
  /** Object of states */
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
   })).isRequired,
  /** */
}

export default SearchCell;
