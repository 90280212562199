import React, { Component } from 'react'
import { Button, Table, Checkbox, Icon, Header, Grid, Modal, Dropdown, Loader } from 'semantic-ui-react';
import { ContentCard, IndexGrid, ExpandableIndexGrid, StatCard } from '@rockerbox/styleguide'

export const AccountStats = ({ adsData, stats }) => {

  const fields = [
    {'name':'Impressions','field':'imps'},
    {'name':'Clicks','field':'clicks'},
    {'name':'Cost','field':'spend'},
    {'name':'Conversions','field':'conversions'},
    {'name':'Conversions (all)','field':'all_conversions'}
  ]

  return (
    <Grid fluid='true'>
      <Grid.Row stretched>
        { fields.map((f) => {
           return (
              <Grid.Column style={{width: "20%"}}>
                <StatCard
                  value={stats[f.field]}
                  label={f.name}
                  size='small'
                />
              </Grid.Column>
            )
          })
        }
      </Grid.Row>
    </Grid>
  )
}

export const AdGroupTable = (props) => {

  const tableData = props.item.adGroups;

  const cols = [
    {display: 'Ad Group ID', key: 'ad_group_id'},
    {display: 'Ad Group', key: 'ad_group', headerWidth: 2},
    {display: 'Device', key: 'device'},
    {display: 'Network', key: 'network'},
    {display: 'Impressions', key: 'imps', headerWidth: 1},
    {display: 'Clicks', key: 'clicks', headerWidth: 1},
    {display: 'Cost', key: 'spend', headerWidth: 1},
    {display: 'Conversions', key: 'conversions', headerWidth: 1},
    {display: 'Conversions (all)', key: 'all_conversions', headerWidth: 2}
  ];

  return(
    <IndexGrid attached data={tableData} cols={cols} />
  )
}
