import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParamsWithDefaults } from '../../urlParamHooks';

import { CacheContext } from '../../../../../utils/CacheContext'
import { getAuthorizations } from '../../../../api/account'
import { getFacebookConversions } from "../../../../api/attribution.js"
import { FACEBOOK_STANDARD_CONVERSIONS } from '../../platformMetrics'


export const usePlatformNames = () => {
  const { platform } = useParamsWithDefaults();
  const selectedPlatform = platform;

  const [state, setState, Context] = React.useContext(CacheContext)
  const authorizations = Context.getCache(`authorizations`, getAuthorizations)
  const [fbNameMap, setFbNameMap] = useState({})

  useEffect(() => {
    if (!authorizations) return
    // get facebook auths
    const fbAuth = authorizations.find(c => c.platform == "facebook") || {}
    const fbAuthz = fbAuth.authorizations || []
    if (!fbAuthz.length) return
    // get facebook names
    const reqs = fbAuthz.map(auth => getFacebookConversions(auth.id))
    Promise.all(reqs).then(responses => {
      const customConversions = responses.flat()
      const nameMap = FACEBOOK_STANDARD_CONVERSIONS
      customConversions.map(x => nameMap[`offsite_conversion.custom.${x.id}`] = `${x.name} (${x.id})`)
      setFbNameMap(nameMap)
    })
  }, [authorizations])

  const allPlatformNames = {
    facebook: fbNameMap
  }

  const conversionNames = useMemo(
    () => allPlatformNames[selectedPlatform] || {}, 
    [fbNameMap, selectedPlatform]
  )

  return {
    platformNameMap: allPlatformNames,
    selectedPlatform,
    conversionNames
  }
}

