import React, { useState, useRef }  from 'react';
import moment from 'moment';

import { getMetaData, get } from '../api_cache';
import { getCacheState, clearExpired, createCacheTable, loadCache } from './cache_meta';
import { initBackend } from './absurd/indexeddb-main-thread';
import worker from 'workerize-loader?name=static/js/[hash].sql!./sql_worker'
import initSqlJs from '@jlongster/sql.js';

const FETCH_LIMIT = 2

export const useSQLite = () => {
  const [database, setDatabase] = useState()

  React.useEffect(() => {
    const webWorker = worker()
    const backend = initBackend(webWorker)
    setDatabase(webWorker)
  }, [])

  return [database, (database||{}).select, (database||{}).create]
}

const useStateRef = (initial) => {
  const [value, setValue] = useState(initial);
  const ref = useRef();

  ref.current = value;

  return [value, setValue, ref]
}

export const useSQLiteCache = (onChange) => {
  const [database, select, create] = useSQLite();

  const [dataset, setDataset] = useState("compiled_mta");
  const [filterId, setFilterId] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const [cacheState, setCacheState] = useState({});
  const [meta, setMeta] = useState(false);
  const [toFetch, setToFetch] = useState(false);
  const [fetching, setFetching] = useState({});
  const [resolved, setResolved, resolvedRef] = useStateRef({});


  const setCacheSettings = (settings) => {
    const { dataset, filterId, startDate, endDate } = settings;

    setDataset(dataset)
    setStartDate(startDate)
    setEndDate(endDate)
    setFilterId(filterId)
  }

  React.useEffect(() => {
    if (database && dataset && filterId && startDate && endDate) {
      setMeta(false)
      setToFetch(false)
      setCacheState({})
      getMetaData(filterId, startDate, endDate, dataset)
        .then(setMeta)
    }
  }, [database, dataset, filterId, startDate, endDate])

  React.useEffect(() => {
    if (!meta) return;

    const hasMeta = Object.values(meta).length > 0;
    if (!hasMeta) return;

    console.log("requested to cache")
    console.log(meta)
    console.log("getting cache state...")
    getCacheState(database, dataset, filterId, meta)
      .then(setCacheState)
    
  }, [meta])

  React.useEffect(() => {
    const { missing, expired } = cacheState;
    if (!missing) return;

    console.log("cache state:")
    console.log(cacheState)

    clearExpired(database, dataset, filterId, expired)
      .then((resp) => console.log(`cleared cache: ${resp}`))

    createCacheTable(database, dataset, filterId)
      .then((resp) => setToFetch(Object.assign({}, expired, missing)) )
    
  }, [cacheState])


  const fetchStatus = Object.assign({}, fetching, resolved)
  const activeFetch = Object.entries(fetchStatus)
    .reduce((p, [k,v]) => {
      if (v === true) return p
      return Object.assign(p, {[k]: v})
    },{})


  React.useEffect(() => {
    const shouldFetch = Object.values(toFetch).length > 0
    const atFetchingLimit = Object.values(activeFetch).length > FETCH_LIMIT

    if (!shouldFetch || atFetchingLimit) return;

    const [first, ...remaining] = Object.entries(toFetch)
    const [date, _] = first

    const newFetching = Object.assign({}, fetching)

    newFetching[date] = get(filterId, date, dataset)
      .then((resp) => {
        const cacheId = resp['details']['cache_id'][dataset]
        const data = resp['response'][dataset]

        data.map(row => Object.assign(row, {
          conversion_date: moment.utc(row.timestamp_conv*1000).format("YYYY-MM-DD"),
          event_date: moment.utc(row.timestamp_events*1000).format("YYYY-MM-DD")
        }))

        loadCache(database, dataset, filterId, date, cacheId, data)
          .then(loaded => {
            const resolved = Object.assign({ [date]: true }, resolvedRef.current)
            setResolved(resolved)
          })
      })
    
    setFetching(newFetching)
    setToFetch(Object.fromEntries(remaining))
  }, [toFetch, fetching, activeFetch])


  React.useEffect(() => {


    const hasMeta = meta && Object.keys(meta).length > 0
    const noPlannedFetch = toFetch && Object.keys(toFetch).length == 0
    const noActiveFetch =  Object.keys(activeFetch).length == 0
    const cacheReady = hasMeta && noPlannedFetch && noActiveFetch

    if (onChange) onChange(cacheReady, meta, toFetch, activeFetch, resolved, cacheState)
  }, [meta, toFetch, fetching, resolved, cacheState])


  return [database, setCacheSettings]
}
