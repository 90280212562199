import React, { Component } from 'react';
import { Icon, Dropdown, Button } from 'semantic-ui-react';
import Split from 'react-split';
import SplitEditor from './SplitEditor';
import Kernel from './Kernel';
import DescriptionSVG from './description';
import Progress from './Progress';
import { modelTransforms } from '../../utils/api';
import { getProjectObject, createProjectObject } from '../../utils/api';
import { changed, rand, buildMsg } from './helpers.js';

const menuStyle = {display:"block", lineHeight:"1.5em", fontSize:"16px"}
const progressStates = ["requesting_launch","launching", "provisioning", "provisioned", "ready"]

class ModelInteractive extends Component {

  stderr = []
  stdout = []

  state = {
    projectState: false,
    selected: "explore",
    outputBuffer: "Results",
    buffer: {}
  }

  receiveMessage = (msg) => {
    const m = JSON.parse(msg.data)
    if (m.msg_type == "stream") {
      const buffer = m.content.text
      const streamName = m.content.name
      const parentMsg = m.parent_header.msg_id.split("-")[0]
      const state = {
        buffer: { body: buffer }
      }

      this[streamName].push(buffer)
      this.setState(state);
    }

    if (m.msg_type == "status") {
      this.props.setBusy(m.content.execution_state == "busy")
    }

    if (m.msg_type == "error") {
      const buffer = `ERROR - ${m.content.ename} - ${m.content.evalue}\n`

      this.stderr.push(buffer)
      this.setState({ outputBuffer: "stderr"});
    }

    if (m.msg_type == "execute_result") {
      const buffer = m.content.data['text/html'] || m.content.data['text/plain']
      const parentMsg = m.parent_header.msg_id.split("-")[0]
      this.setState({ buffer: {body: buffer }, outputBuffer: "Results"});
    }


  }

  setOutputBuffer = (value) => {
    this.setState({ outputBuffer: value })
  }
  getCell = (target) => () => {
    const { project_id } = this.props
    return getProjectObject(project_id, target)
  }
  saveCell = (target) => (body) => {
    const { project_id } = this.props
    return createProjectObject(project_id, target, body)
  }

  runCell = (target) => (_code) => {

    let code = _code
    if (target == "transform") code += `
X, y, labels, uid_labels, num_datapoints, num_excluded, user_summary, touchpoints = run_transform(users, touchpoints, conversions)
print "Output availabe in variables:\\n- X\\n- y\\n- labels\\n- uid_labels"
`
    if (target == "cohort") code += `
users, touchpoints, conversions = run_cohort(users_raw, touchpoints_raw, conversions_raw)
resp = "Output availabe in variables:\\n- users\\n- touchpoints\\n- conversions\\n\\n"
resp += "Summary:\\n" 
resp += " - Users in cohort: %s\\n" % len(users)
resp += " - Touchpoints in cohort: %s\\n" % len(touchpoints)
resp += " - Conversions in cohort: %s\\n" % len(conversions)
print resp
`
    if (target == "model") code += "\nrun_model(X, y)"

    this.setState({ execute: { code, target }})
  }
  setProjectState = (projectState) => {
    this.setState({ projectState })
  }

  componentDidMount() { }
  componentDidUpdate(prevProps, prevState) { }

  render() {
    const { projectState, outputBuffer, selected, execute } = this.state;
    const { stderr, stdout, setOutputBuffer } = this;
    const { setProjectState, receiveMessage } = this;
    return (
      <React.Fragment>
        <Kernel {...{execute, receiveMessage, setProjectState}} />
        { projectState != "ready" && <Progress message={projectState} percent={parseInt(progressStates.indexOf(projectState)/progressStates.length*100)} /> }
        { projectState == "ready" &&
          <Split 
            sizes={[10, 90]}
            minSize={250}
            expandToMin={false}
            gutterSize={5}
            gutterAlign="center"
            snapOffset={30}
            dragInterval={1}
            direction="horizontal"
            cursor="col-resize"
            style={{"display":"flex"}}
          >
            <div style={{"background":"white", "paddingLeft":"2em","paddingTop":"1em"}}>
              { 
                order.map( key => {
                  const title = buffers[key]['title']
                  const fontWeight = selected == key ? "bold": undefined
                  const style = Object.assign({ fontWeight }, menuStyle)
                  const onClick = () => this.setState({"selected": key})
                  return <a href="#" {...{style, onClick}}>{ title } </a>
                })
              }
            </div>
            <div>
              {
                order
                  .filter( key => key == selected )
                  .map( type => {
                    if (type == "about") return <DescriptionSVG />;
                    const { defaultInput, title }= buffers[type]
                    const runBuffer = this.runCell(type)
                    const rightBuffer = this.state.buffer.body
                    const saveBuffer = this.saveCell(type)
                    const getBuffer = buffers[type]['fixed_buffer'] || this.getCell(type)
                    const isFixedInput = !!buffers[type]['fixed_buffer']
                    const beforeAce = buffers[type]['beforeAce']
                    const autoRun = buffers[type]['autoRun']

                    return <SplitEditor
                      {...{ autoRun, beforeAce, isFixedInput, runBuffer, getBuffer, saveBuffer}}
                      {...{ leftTitle: title, defaultInput, rightBuffer, type }}
                      {...{ stderr, stdout, outputBuffer, setOutputBuffer }}
                    />
                  })
              }
            </div>
          </Split>
        }
      </React.Fragment>
    )
  }
}
const order = [
  "about",
  "about_data",
  "explore",
  "cohort",
  "transform",
  "model",
  "apply"
]
const PYTHON_SUMMARY =`summary = pd.DataFrame({
    "users": users_raw.groupby("cache_from").base_id.nunique(),
    "touchpoints": touchpoints_raw.groupby("cache_from").size(),
    "conversions": conversions_raw.groupby("cache_from").base_id.nunique()
})
total = pandas.DataFrame({"count":summary.sum()}).T

sample_percent = (touchpoints_raw.bucket.max()+1)*1.0/100
resp = "<p/><h5>Sample Percent: %s</h5>" % sample_percent
resp += pretty_frames([
  {"name":"Overview", "df":total},
  {"name":"By Date", "df":summary}
])
print resp
`

const buffers = {
  "about": {
    "title": "About Modeling"
  },
  "about_data": {
    "title": "Data Overview",
    "autoRun": true,
    "beforeAce": <div style={{background:"white",padding:".5em",height:"150px"}}>
      <h5>There are three main datasets available for modeling: </h5>
      <ul>
        <li>touchpoints_raw - contains all marketing activity</li>
        <li>users_raw - contains all user sessions within date range</li>
        <li>conversions_raw - contains all conversions within date range</li>
      </ul>
      <h5>Below is an example manipulation summarizing data.</h5>
    </div>,
    "fixed_buffer": () => {
      return new Promise((resolve, reject) => {
        resolve({"response":[{"body":PYTHON_SUMMARY}]})
      })
    }
  },
  "explore": {
    "title": "Explore Data",
    "defaultInput": `
# all session data
users_raw.head()

# all conversion data
conversions_raw.head()

# all touchpoint data
touchpoints_raw.head()
`
  },
  "cohort": {
    "title": "Build Cohorts",
    "defaultInput": `def run_cohort(users_raw, touchpoints_raw, conversions_raw):
    """
    Performs cohort construction for the data that will be passed into the model
      - take in raw datasets
      - determine which data is relevant for the model
      - return subsets of the original datasets that conform to cohorting rules

    Parameters:
      - users_raw (DataFrame): describing user sessions
      - touchpoints_raw (DataFrame):  describing touchpoints
      - conversions_raw (DataFrame):  describing conversions

    Returns:
      - tuple(users, touchpoints, conversions): subsets of the parameters
    """
    
    from attribution.model import cohort
    users, touchpoints, conversions = cohort.build_cohorts(users_raw, touchpoints_raw, conversions_raw, 10)
    del touchpoints['timestamp_conv']
    return users, touchpoints, conversions
`
  },
  "transform": {
    "title": "Transform Features",
    "defaultInput": `def run_transform(users, touchpoints, conversions):
    """
    Takes the cohorted datasets and turns them into an X, y for use in building the model.

    Parameters:
      - users (DataFrame): cohorted user sessions
      - touchpoints (DataFrame):  cohorted touchpoints
      - conversions (DataFrame):  cohorted conversions

    Returns:
      - X
      - y
      - labels
      - uid_labels
      - num_datapoints
      - num_excluded
      - user_summary
      - touchpoints 
    """
    from attribution.model.transform.vector_transforms import VectorTransforms2
    from attribution.model.transform.contract import ContractAndTransform
    tp_transformer = VectorTransforms2(touchpoints, users)
    touchpoints = tp_transformer.from_config({})
    contract = ContractAndTransform()
    data_tuple = contract.from_config({"attr":"multi"}, touchpoints, conversions)

    X, y, labels, uid_labels, num_datapoints, num_excluded, user_summary, touchpoints = data_tuple

    return X, y, labels, uid_labels, num_datapoints, num_excluded, user_summary, touchpoints 
`
  },
  "model": {
    "title": "Build Model",
    "defaultInput": `def run_model(X,y):
    """
    Takes in independent and dependent variables and produces a model.

    Parameters:
      - X (scipy.sparseMatrix): independent variables
      - y (numpy.array): dependent variable

    Returns:
      - ?
    """
    import sklearn.linear_model
    model = sklearn.linear_model.LogisticRegression()
    model.fit(X, y)
    probabilities = model.predict_proba(X)
    labeled_coefficients = dict(zip(labels,model.coef_[0]))


    TIERS = ["tier_1","tier_2","tier_3","tier_4","tier_5"]
    df = pandas.DataFrame({"weight":pandas.Series(labeled_coefficients)}).reset_index()
    pretty_results = pandas.DataFrame(list(df['index'].str.split("|").map(lambda x: x[:5]).values),columns=TIERS).join(df[["weight"]])
    return pretty_results
`
  },
  "apply": {
    "title": "Apply Model",
    "defaultInput": `# Coming soon... will allow one to write custom model application rules`
  }
}

export default ModelInteractive;
