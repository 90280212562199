import React, { Component, useState } from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { ContentCard, SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import { Message, Menu, Icon, Form, Button } from 'semantic-ui-react';
import CacheContext from '../../utils/CacheContext';
import { updateTransformTables, getAllowTransformTableTemplates, getTransformTableTemplates, getTransformTables, getIntegrationFeatures } from '../../utils/api';

import TransformTableDetails from './TransformTableDetails';
import TransformTableResults from './TransformTableResults';
import PreviewAll from './PreviewAll';

const TransformTableTemplates = () => {

  const history = Router.useHistory();
  const [strategy, setStrategy] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [templates, setTemplates] = React.useState([]);
  const [state, setState, Context] = React.useContext(CacheContext);
  const transformTables = Context.getCache(`transformTables`, getTransformTables) || [];
  //const templates = Context.getCache(`transformTablesTemplatesEnabled`, getAllowTransformTableTemplates) || {};

  React.useEffect(() => {
    getTransformTableTemplates()
      .then((templates) => {

        const nested = d3.nest()
          .key(({ strategy }) => strategy)
          .entries(templates)
          .map(({ key, values }) => {
            return { key, value: values, text: key}
          })

        setTemplates(nested)
      })
  }, [])

  const platforms = !strategy ? {} :
    strategy
      .reduce((p, c) => {

        const { platform, version } = c
        const key = (version) ? `${platform}_${version}` : platform
        p[key] = c
        return p
      }, {}) 

  const impacted = transformTables
    .filter(({ platform }) => platforms[platform] !== undefined)
    .map(( { name, platform, id }) => { return { name, platform, id}})

  const onSubmit = () => {

    const impacted = transformTables
      .filter(({ platform }) => platforms[platform] !== undefined)
      .map(( { name, platform, version, id }) => { 
        const key = (version) ? `${platform}_${version}` : platform
        const body = JSON.parse(platforms[key].rule)
        Object.assign(body, { id, name })
        
        return body
      })

    setLoading(true)

    const promises = impacted.map(body => {
      return updateTransformTables(body, body['id'])

    })

    Promise.all(promises)
      .then(responses => {
        history.push(`${routes.transformTablesIndex}/view/all`)
        setLoading(false)
      })
  }
    
  return <ContentCard title={"Apply Templates for Transform Tables"}>
    <Message content="Use the dropdown below to quickly apply templates for an advertiser. Note that if you choose to use a template, it will override the contents and the existing values in a transform table will be lost." />
    <Form.Dropdown 
      options={templates} select label={"Organize Marketing by "} selection 
      onChange={(_, {value}) => setStrategy(value)}
    />
    { loading ?
        <ContentCard.CardLoader /> :
        <IndexGrid 
          fallBackMsg="No changes will be made with selected template" 
          cols={IndexGrid.arrayToColumns(["platform","name","id"])} 
          data={impacted} 
        />
    }
    <div style={{height:35}}>
      { !!strategy && <Button floated='right' content='Apply' as='a' onClick={onSubmit} /> }
    </div>
  </ContentCard> 
}

const TransformTablesIndex = (props) => {
  const history = Router.useHistory();
  const [state, setState, Context] = React.useContext(CacheContext);
  const transformTables = Context.getCache(`transformTables`, getTransformTables) || [];
  const templates = Context.getCache(`transformTablesTemplatesEnabled`, getAllowTransformTableTemplates) || {};
  const allowTemplates = templates.response && templates.response.length > 0
  const routedData = Router.useRouteMatch(`${routes.transformTablesIndex}/view/:selected?`) || {};
  const { params: { selected } = {} } = routedData
  const setSelected = (value) => history.push(`${routes.transformTablesIndex}/view/${value}`)
  const [integrations, setIntegrations] = useState([])
  const [deprecated, setDeprecated] = useState(false)
  const [deprecationAlert, setDeprecationAlert] = useState("")
  
  React.useEffect(() => {
    getIntegrationFeatures()
      .then(data => {
        setIntegrations(data['integrations'])
      })
  }, []) 

  React.useEffect(() => {
    const transformTable = (selected == "new") ? {} : _.cloneDeep(transformTables.find(row => row.id == selected))
    setState({ transformTable })

    if (selected == undefined) setSelected("all");

  }, [selected, transformTables.length]) 

  const { transformTable } = state;
  const selectTable = (name, isDeprecated) => {
    setSelected(name)
    setDeprecated(isDeprecated)
    setDeprecationAlert(`Your account is using a deprecated version of this integration. Please contact us for help upgrading to the latest version.`)
  }

  const getDeprecationStatus = (platform) => {
    const platformName = platform?.replace(/_v\d+$/, '')

    return integrations.filter(function (i,n){
      return i.values.some(function(item) {
        if (item.values[0].platform === platformName) {
          return item.values[0].feature_deprecated == 1
        }

      })
    }).length > 0
  }

  const VerticalMenu = () => {
    return <Menu vertical fluid>
          <Menu.Item>
            <Menu.Header style={{color: 'black'}}>Edit Transform Tables</Menu.Header>
          </Menu.Item>
          {
            transformTables.map(row => {
              const isDeprecated = getDeprecationStatus(row.platform)
              const modifiedRowName = row.name?.replace("Platform: ", "").replace(" transform","").replace("_v2","")
          
              return <Menu.Item active={row.name == selected} onClick={() => selectTable(row.id, isDeprecated)}>
                {row.name && 
                  modifiedRowName} 
                {!(row.id == selected) && 
                   isDeprecated &&
                  <Icon name="exclamation triangle" style={{float: 'right', color: 'red', margin: 0}}/>}
                {row.id == selected && 
                  <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
              </Menu.Item>
            })
          }
        </Menu>
  }
  
  return <SplitLayout 
    leftWidth={2} 
    rightWidth={14}
    leftContent={
      <React.Fragment>
        <Menu vertical fluid>
          <Menu.Item>
            <Menu.Header style={{color: 'black'}}>
              Explore Tables
            </Menu.Header>
          </Menu.Item>
          <Menu.Item active={"all" == selected} onClick={() => selectTable("all")}>
            View All {"all" == selected && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
          </Menu.Item>
          { 
            allowTemplates &&
            <Menu.Item active={"templates" == selected} onClick={() => selectTable("templates")}>
              Apply Templates {"templates" == selected && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
            </Menu.Item>
          }
        </Menu>
        <VerticalMenu/>
        <Button onClick={() => selectTable("new")}>New</Button>
      </React.Fragment>
    } 
    rightContent={
      <React.Fragment>
        {deprecated &&
          <div class="ui negative message">{deprecationAlert}</div>}
        { selected == "all" && <PreviewAll /> }
        { selected == "templates" && <TransformTableTemplates /> }
        { transformTable && selected != "all" && 
          <React.Fragment>
            <TransformTableDetails {...{transformTable}} /> 
            <h3 style={{fontWeight:"500"}}>Explore Raw Data and Resulting Transform Table</h3>
            <TransformTableResults {...{ transformTable }} />
          </React.Fragment>
        }
      </React.Fragment>
    } 
  />
}

export default TransformTablesIndex;
