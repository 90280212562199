import React, { Component } from 'react';
import { Popup, Icon } from 'semantic-ui-react';

class ChangeBar extends Component {
  constructor(props){
    super(props)
    this.state = {
      barWidth: 0,
      fromPosition: (props.from / (props.min + props.max)) * 100,
      toPosition: (props.to / (props.min + props.max)) * 100,
      reverse: Math.min(props.from, props.to) === props.to,
    }
  }

  componentDidMount = () => {
    setTimeout(this.setWidth, 100)
  }

  setWidth = () => {
    this.setState({
      barWidth: Math.abs(this.state.fromPosition - this.state.toPosition)
    })
  }

  render(){
    const props = this.props
    const { fromPosition, toPosition, barWidth, reverse } = this.state

    const barStart = Math.min(fromPosition, toPosition)

    let barStyles = {
      width: barWidth+'%'
    }
    if (reverse) {
      barStyles['marginRight'] = ((barWidth ? 100 : 101)-(barStart+Math.abs(fromPosition - toPosition)))+'%'
      barStyles['marginLeft'] = 'auto'
    } else {
      barStyles['marginLeft'] = barStart+'%'
    }

    return(
      <div className={`ui progress changebar ${reverse && 'reverse'}`}>
        <div
          className='bar'
          style={barStyles}
        ></div>
        <div
          className='from'
          style={{
            marginLeft: fromPosition+'%'
          }}
        >
          <Popup
            hoverable inverted position='top center' size='tiny'
            content={
              <div>
                <strong>{props.fromLabel}</strong>
                <br />
                {props.from} {props.units}
              </div>
            }
            trigger={
              <Icon name='dot circle' color='grey' inverted />
            }
           />
        </div>
        <div
          className='to'
          style={{
            marginLeft: toPosition+'%'
          }}
        >
          <Popup
            hoverable inverted position='top center' size='tiny'
            content={
              <div>
                <strong>{props.toLabel}</strong>
                <br />
                {props.to} {props.units}
              </div>
            }
            trigger={
              <Icon name='bullseye' />
            }
           />
        </div>
      </div>
    )
  }
}

export default ChangeBar
