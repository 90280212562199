import React, { Component } from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import { SplitLayout } from '@rockerbox/styleguide';

import EventTagGrid from './grid';
import EventTagForm from './form';

const EventTags = (props) => {

  const routedData = Router.useRouteMatch(`${routes.eventTagsIndex}/:type/:selectedEventTag?`) || {};
  const { params: { type, selectedEventTag } = {} } = routedData

  if (!type && !selectedEventTag) return <SplitLayout
    leftWidth={13}
    rightWidth={3}
    leftContent={<EventTagGrid />}
    rightContent={<>
      <h4 style={{fontWeight:"bold"}}>About Event Tags</h4>
      <p>
        Event Tags are groups of marketing events that can be excluded from a conversion event. 
      </p>

      <p>
        Event Tags can exclude events in two ways: by excluding the event from the path to conversion and/or excluding spend from being included in the reporting for a specific conversion.
      </p>

      <p>
        Event Tags "tag" marketing events based on the contents in their marketing structure and apply exclusions to specific conversion events.
      </p>
    </>}
  />

  return <EventTagForm />
}

export default EventTags;
