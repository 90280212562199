import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { colors } from '../AttributionPercent/helpers';
import { calcWeekly } from './helper';
import { CenteredRowItem } from '../parts';

class StackedBar extends Component {
  componentDidMount() {
    if (this.props.data && this.props.data.length) this.renderBar()
  }
  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data.length) this.renderBar()
  }

  bottomData(variables) {

    const { data, weeklyNames, dataColumns } = variables

    const currentDate = data[0].date;

    const weeklyData = calcWeekly(currentDate, data, weeklyNames)

    let maxBottomData = 0

    const bottomData = dataColumns.map(key => {
      const currentData = weeklyNames.map(name => (weeklyData[name] || {})[key] || 0)
      maxBottomData = Math.max(maxBottomData,d3.max(currentData))
      return {"name":key, "values": currentData}
    })

    return {
      bottomData, maxBottomData
    }

  }

  setupVariables() {

    const weeklyNames = [
      "THIS WEEK",
      "LAST WEEK",
      "2 WEEKS AGO",
      "3 WEEKS AGO"
    ]

    const { data, exclude, barColors={}, customRange } = this.props
    const stacked = this.props.stacked || false

    const columns = data.reduce((p,c) => {
      Object.keys(c).map((key) => { if (p.indexOf(key) == -1) p.push(key) })
      return p
    }, ["date"])

    const dataColumns = columns.slice(1).filter(x => (exclude.indexOf(x) == -1) )

    const columnColors = (dataColumns.length === 1 && !Object.keys(barColors).length) ? {[dataColumns[0]]: '#0489c1'} : dataColumns.reduce((p,c,i) => {
      p[c] = barColors[c] || colors[i]
      return p
    }, {})

    const totals = data.map((row) => d3.sum(dataColumns.map(col => row[col])))
    const max = d3.max(totals)

    const height = this.props.height || 240 //+ dataColumns.length*20
    const paddingTop = this.props.hideXAxis ? 20 : 50
    const paddingBottom = this.props.hideXAxis ? 0 : 30 //+ dataColumns.length*20

    const drawableHeight = height - paddingTop - paddingBottom
    const parentWidth = this.props.width

    const paddingLeft = 15
    const paddingRight = 15

    const minNumItems = this.props.minNumItems || 30
    const numItems = Math.max(minNumItems, data.length)

    const usableWidth = parentWidth - paddingLeft - paddingRight

    const minWidth = 2
    const maxBarWidth = 40

    const width = Math.min(Math.max(usableWidth/numItems,minWidth), maxBarWidth)
    const space = 2

    let vScale;

    if (!!customRange) {
      vScale = d3.scale.linear().range([0,drawableHeight]).domain(customRange);
    } else {
      vScale = d3.scale.linear().range([0,drawableHeight]).domain([0,max]);
    }

    const markerSize = 6
    const fromTop = 30

    const { bottomData, maxBottomData } = this.bottomData({data, weeklyNames, dataColumns})

    return {
      drawableHeight,
      bottomData, maxBottomData,
      weeklyNames,
      dataColumns,
      columnColors,
      totals,
      height,
      paddingBottom,
      paddingLeft,
      paddingRight,
      usableWidth,
      width,
      space,
      vScale,
      data,
      fromTop,
      markerSize,
      stacked,
      numItems
    }

  }

  renderWrapper(variables) {
    const { tickType, tickFormat } = this.props;
    const target = ReactDOM.findDOMNode(this.refs.bar);

    const {
      width, paddingLeft, paddingRight, height,
      data, numItems
    } = variables

    const minDate = d3.min(data, x => x.date)
    const maxDate = d3.max(data, x => x.date)

    const momentMinDate = moment.utc(minDate)
    const momentMaxDate = moment.utc(maxDate)

    const type = tickType || "day";
    const dateFormat = tickFormat || "YYYY-MM-DD";

    // we have to add 1 day to end of range for daily charts
    // hourly charts do not have this issue
    const adjustedMaxDate = type == "day" ? momentMaxDate.add(1,'days') : momentMaxDate

    const dateRange = d3.time.scale()
      .domain([momentMinDate, adjustedMaxDate])
      .ticks(d3.time[type], 1)
      .map(date => moment.utc(date).set({minute:0,second:0,millisecond:0}).format(dateFormat))
      .reverse()

    const nested = d3.nest()
      .key(x => x.date)
      .map(data)

    const newData = dateRange
      .map(date => (nested[date]|| [{}])[0] )


    const svg = d3.select(target.parentNode)
      .selectAll("svg").data([newData]);

    svg
      .enter()
        .append("svg")

    svg.selectAll('g').remove();

    svg
      .attr("width",numItems*(width)+paddingLeft+paddingRight)
      .attr("height",height)

    const main = svg.selectAll("g.main")
      .data(x => [x]);

    main.enter()
      .append("g")
      .attr("class","main")

    main
      .attr("transform","translate(" + paddingLeft + ",0)")
      .attr("width", numItems*(width))

    return { svg, main }
  }

  renderBottomAxis(main, variables) {

    const {
      width,
      fromTop,
      markerSize,
      vScale,
      totals,
      height,
      paddingBottom,
      numItems
    } = variables

    const axis = main.selectAll("g.top.axis")
      .data(x => [x]);

    axis.enter()
      .append("g").attr("class","top axis")

    const lineAxis = axis.selectAll("line.baseline")
      .data(x => [x])
      .enter()
        .append("line")
        .attr("class","baseline")
        .attr("stroke-dasharray", "5,5")
        .attr("y1",fromTop)
        .attr("y2",fromTop)
        .attr("x0",0)
        .attr("x1",(width)*numItems)
        .attr("style","stroke:#ccc;stroke-width:2")


    const buildTick = (pos, label) => {

      const tick = axis.selectAll("g." + label)
        .data(x => [x])
        .enter()
          .append("g")
          .attr("class",label)
          .attr("transform","translate("+ ((width)*(numItems-1-pos) + (width)/2 ) + ","+ (height-markerSize/2) +")")

      tick
        .append("rect")
        .attr("width",markerSize)
        .attr("height",markerSize)
        .attr("transform","translate(-"+(markerSize/2)+",-"+ (20+markerSize/2) + ")")
        .attr("fill","#333")

      tick
        .append("line")
        .attr("style","stroke:#ccc;stroke-width:1")
        .attr("y1",markerSize + 2)
        .attr("size", vScale(totals[pos]))
        .attr("y2",Math.max(height - paddingBottom - fromTop - (vScale(totals[pos]) || 0), markerSize + 2) )

      tick
        .append("text")
        .text(label.toUpperCase())
        .attr("transform","translate(0,-5)")
        .style("text-anchor","middle")
        .style("font-weight","900")
        .style("font-size","1.1em")


    }

    const datum = main.datum()

    if (datum.length > 0 && !this.props.hideXAxis) {
      const lastDate = datum[0]['date']
      const yest = moment.utc().utcOffset(-5).subtract(1, 'days').format("YYYY-MM-DD")
      const lastMoment = moment(datum[0]['date'])

      const STEP_SIZE = (datum.length / 7 < 11) ? 7 : parseInt(datum.length / 11);
      d3.range(0,datum.length,STEP_SIZE).map((num) => {
        if (num == 0) buildTick(0, (lastDate == yest) ? "YESTERDAY" : lastMoment.format("MMM-Do"))
        else buildTick(num, lastMoment.subtract(STEP_SIZE, 'days').format("MMM-Do"))
      })
    }

  }

  renderMainBar(main, variables) {
    const {
      width, dataColumns, space, height, paddingBottom, columnColors, vScale, numItems,
      drawableHeight, stacked
    } = variables

    const { onClick, tooltipKey, tooltipDirection } = this.props;
    const tipDirection = tooltipDirection || "left"

    const tooltipCls = stacked ? "summary_tooltip_stacked" : "summary_tooltip"

    const container = d3.select("body").selectAll(`div.${tooltipCls}`)
      .data([0]);

    container.enter().append('div').attr('class', tooltipCls).style('opacity', 0);

    const bar = main.selectAll("g.bar")
      .data(x => x);

    bar.enter()
      .append("g")
      .attr("class","bar")
      .attr("transform", (x,i) => "translate("+ ((numItems-1-i)*(width)) + ",0)")
      .style("cursor",onClick ? "pointer" : undefined)
      .on("click",onClick)

    bar.exit().remove()

    const section = bar
      .selectAll("rect")
      .data(function(x) {
        let prev = 0
        const total = d3.sum(dataColumns.map(col => x[col]));
        const datum = dataColumns.map(col => {
          const p = prev
          const curr = vScale(x[col] || 0)
          prev += curr
          const y1 = Math.max(2,curr)
          const date = moment(x['date'], 'YYYY-MM-DD').format("MMMM DD, YYYY")
          const hour = moment(x['date']).format("HH:mm UTC")

          let obj = {"y0":p,"y1":y1, "column":col, "count":x[col], date: date, hour}

          if (!x.total) obj['total'] = total;

          return obj
        })

        return datum
      },(x,i) => i)


    section.exit().remove()

    section.enter()
      .append("rect")

   const self = this;

   section
      .attr("width",width-space)
      .attr("y", d => height - d.y0 - d.y1 - paddingBottom)
      .attr("height", d => d.y1 )
      .attr("rx",0)
      .attr("ry",0)
      .attr("fill", (x,i) => columnColors[x.column])
      .on("mousemove", function(d) {
        container.transition()
          .duration(50)
          .style("opacity", .9);
        
        container.html(self.generateTooltip(d, variables))
          .style("left", (d3.event.pageX - (tipDirection == "right" ? 150 : 0)) + "px")
          .style("top", (d3.event.pageY - 28) + "px")
          .style("width", "150px")
          .style("border-width", 0);
        })
      .on("mouseout", function(d) {
        container.transition()
          .duration(50)
          .style("opacity", 0);
      });

  }

  generateTooltip(d, variables) {
    const { columnColors, stacked } = variables;
    const { tooltipKey } = this.props;

    const key = tooltipKey || "date";
    return `
      <div>
        <div style="padding-bottom: 3px;">
          ${d[key]}
        </div>
        <div style="padding-bottom: 3px;">
          <span class="summary_dot" style="background-color:${columnColors[d.column]}"></span>
          <strong>${d.column}:</strong> ${d3.round(d.count || 0)}
        </div>
        ${ stacked ?
        `<div>
          <span class="summary_dot" style="background-color:#ddd"></span>
          <strong>Total:</strong> ${d.total}
        </div>` : ''}
      </div>
    `
  }

  renderBar() {

    const variables = this.setupVariables()

    const { main } = this.renderWrapper(variables)

    this.renderBottomAxis(main, variables)
    this.renderMainBar(main, variables)
  }

  render() {
    return (
    <svg ref="bar"></svg>
    )
  }

}

class AttributionBar extends Component {

  constructor() {
    super()
    this.state = {
      width: false
    }
  }

  componentDidMount() {
    if (this.props.data && this.props.data.length) this.getDimensions();
    window.addEventListener("resize", this.getDimensions.bind(this));
  }
  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data.length) this.getDimensions()
  }

  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.attributionBar);
    if (target) {
      const parent = target.parentNode.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getDimensions.bind(this));
  }


  render() {

    return (
      <CenteredRowItem>
        <div ref="attributionBar" >
          { this.state.width ? <StackedBar width={this.state.width} {...this.props} /> : null }
        </div>
      </CenteredRowItem>
    )
  }
}

export default AttributionBar;
