import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';

import { SearchField } from '@rockerbox/styleguide';
import { NestedFieldPreview } from '../../../../components/Survey/SetupGeneral';
// import { NestedFieldPreview } from '../../Surveys/SurveyForm/SetupGeneral'; // doesn't work for promo codes need to fix
import { yesterday, daysAgo as daysAgoCalc } from '../../../utils/time';
import { getViaCache } from '../../../../utils/arrow/reportingDataCache';
import { getViaCacheDataReporting } from '../../AttributionReport/hooks/dataReportingEndpoint';
import { useGlobalState } from '../../../hooks/global';

const DEFAULT_TIER_COUNTS = { tier_1: 0, tier_2: 0, tier_3: 0, tier_4: 0, tier_5: 0 };

const filterRowBy = (filter, tierCounts) => row => {
  const hasValue = Object.values(row).includes(filter);
  if (!hasValue) return hasValue;

  Object.keys(tierCounts).forEach(tier => {
    tierCounts[tier] += row[tier].toLowerCase().includes(filter) ? 1 : 0;
  });

  return hasValue;
};

const ControlColumnButton = ({ row, controlColumnName, onChange }) => (
  <Button
    size="mini"
    icon="plus"
    as="a"
    style={{ margin: -6, maxWidth: 70 }}
    content={controlColumnName}
    color="blue"
    compact
    onClick={event => {
      onChange(event, { value: row });
      event.stopPropagation();
    }}
  />
);

const FindMarketingEvent = ({ segment, onChange, controlColumnName = 'Event', platformFilter = false, daysAgo = false }) => {
  const { state } = useGlobalState();
  const { account } = state;
  const { segment_id } = segment;

  const [compiledData, setCompiledData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredTierMatches, setFilteredTierMatches] = useState({ tier_1: 0 });
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    if (segment_id) {
      const [endDate, startDate] = [daysAgoCalc(daysAgo), yesterday];
      const hasDataReportingApiFlag = account.features.find(feature => feature == 'data_reporting_api');

      const dataReportingAPI = hasDataReportingApiFlag ? getViaCacheDataReporting : getViaCache;

      dataReportingAPI(startDate, endDate, 'compiled_mta_tiers', segment_id)
        .then(resp => {
          setCompiledData(resp.objects());
        });
    }
  }, [segment_id, daysAgo]);

  useEffect(() => {
    const tierCounts = _.cloneDeep(DEFAULT_TIER_COUNTS);
    const hasFilter = !!filter;
    const platformFiltered = platformFilter ? compiledData.filter(platformFilter) : compiledData;
    const filtered = hasFilter ? platformFiltered.filter(filterRowBy(filter, tierCounts)) : platformFiltered;

    setFilteredTierMatches(tierCounts);
    setFilteredData(filtered);
  }, [compiledData, filter, platformFilter]);

  const defaultOpen = useMemo(() => {
    const first = Object.entries(filteredTierMatches).sort(([_, p], [, c]) => c - p)[0][0];

    const number = first.split('_')[1];
    return number - 1;
  }, [filteredTierMatches]);

  const controller = row => (
    <ControlColumnButton {...{ row, controlColumnName, onChange }} />
  );

  return (
    <>
      <SearchField onChange={(evt, { value }) => setFilter(value)} />
      {filteredData.length === 0 && (
        <div style={{ margin: '10px', textAlign: 'center' }}>
          No Matching Events Found
        </div>
      )}
      <NestedFieldPreview
        {...{
          data: filteredData,
          defaultOpen,
          fields: ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'],
          rollup: () => [{ count: 1, row: {} }],
          controlColumn: row => controller(row),
        }}
      />
    </>
  );
};

export default FindMarketingEvent;
