import React from 'react'
import StepView from '../helpers/Step'
import { Grid, Button, Segment, Header } from 'semantic-ui-react';
import { InviteUsers } from '../../../V3/components'
import InviteUsersInput from '../../../V3/components/InviteUsers/inviteUsersInput';

export const InviteUsersWidget = ({ header, subheader, advertiser }) => {


  return (
    <Segment
      basic
      className='invite-users-widget'
      textAlign='left'
    >
      <Header as='h5'>
        {header}
        { subheader &&
          <Header.Subheader>
            {subheader}
          </Header.Subheader>
        }
      </Header>
      <InviteUsersInput {...{ handleManageUpdate: () => {}, advertiser}} /> 
    </Segment>
  )

}


const InviteUsersView = ({ completeStep, advertiser }) => (
  <div className='onboarding-invite-view'>
    <Grid
      columns={1}
    >
      <Grid.Row>
        <Grid.Column>
          <Segment
            basic
            textAlign='center'
          >
            <Header as='h1'>
              Invite Users
              <Header.Subheader>
                Add other members of your team to Rockerbox.<br/>You'll be able to share dashboards and collaborate on marketing insights.
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column></Grid.Column>
        <Grid.Column>
          <InviteUsers hideTitle />
          <div className='button-group'>
            <Button
              size='large'
              fluid
              inverted
              color='purple'
              className='m-t-25 m-b-30'
              onClick={completeStep}
            >
              Skip
            </Button>
            <div style={{width:'50px'}}></div>
            <Button
              size='large'
              fluid
              color='purple'
              className='m-t-25 m-b-30'
              onClick={completeStep}
            >
              Next
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>
      <Grid.Row>
      </Grid.Row>
    </Grid>
  </div>
);

export default InviteUsersView
