import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import { handleCacheResponse } from './helpers';
import { yesterday, daysAgo as daysAgoCalc } from '../utils/time';
import { getParquetAndReturnJson } from '../utils/parquetToJson';

export function getSummaryData(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=summary`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(handleCacheResponse);
  // MORE STUFF HERE
}

export function getTimeToConversion(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=time_to_conversion`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(handleCacheResponse);
}

// DELETE
export function getPathsByTier(id, tier_level, startDate, endDate) {
  const url = `/cache/v3/attribution_events?&keys=path_t${tier_level}&start_date=${startDate}&end_date=${endDate}&identifier=${id}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getConversionData(id, startDate, endDate) {
  const url = `/cache/v3/attribution_events?keys=conversion_data_path&identifier=${id}&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(handleCacheResponse);
}

export function getDataset(
  datasetName,
  id,
  daysAgo = false,
  host = 'cache',
  start = false,
  end = false,
  udf = 'attribution_events',
) {
  const [startDate, endDate] = daysAgo
    ? [daysAgoCalc(daysAgo), yesterday]
    : start && end
      ? [start, end]
      : [yesterday, yesterday];

  let url = `/${host}/v3/${udf}?keys=${datasetName}&identifier=${id}`;
  url += daysAgo || (start && end)
    ? `&start_date=${startDate}&end_date=${endDate}`
    : `&date=${yesterday}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
}

export function getTiersAsOptions(id, days = false) {
  return getDataset('compiled_mta_tiers', id, days)
    .then(({ response: { compiled_mta_tiers } }) => compiled_mta_tiers)
    .then(tiers => {
      const tt = ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];
      const rolled = tt
        .reduce((nest, c) => nest.key(row => row[c]), d3.nest())
        .rollup(values => values.length)
        .entries(tiers);

      const toOptions = ({ key, values }) => {
        if (typeof values === 'number') return { key, text: key, value: key };
        return { key, text: key, value: key, values: values.map(toOptions) };
      };

      return rolled.map(toOptions);
    });
}

export function getFirstVisitsTiers(filterId, startDate, endDate) {
  const url = `/cache/v3/onsite_events?identifier=${filterId}&keys=first_visits_tiers&start_date=${startDate}&end_date=${endDate}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

// DELETE
export function getPathsWDetails(id, tier, start, end) {
  const path_key = `path_t${tier}`;
  const url = `/cache/v3/attribution_events?identifier=${id}&start_date=${start}&end_date=${end}&keys=${path_key}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(response => ({
      data: response.response[path_key],
      details: response.details.cache_id[path_key],
    }))
    .catch(error => console.error(error.message)); // eslint-disable-line
}

export function getLegacyPaths(id, tier, start, end) {
  const url = `/data-reporting/legacy/paths/tier/${tier}/cache?start_date=${start}&end_date=${end}&identifier=${id}`;
  return getParquetAndReturnJson(url)
    .then(data => data)
    .catch(err => console.log(err)); // eslint-disable-line
}

export function getLegacyPathsDetails(id, tier, start, end) {
  const path_key = `path_t${tier}`;
  const url = `/data-reporting/legacy/paths/tier/${tier}/cache/details?start_date=${start}&end_date=${end}&identifier=${id}`;
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(({ details }) => details.cache_id[path_key])
    .catch(error => console.error(error.message)); // eslint-disable-line
}

export const getConversionDataset = async (id, daysAgo = false) => {
  const data = await getDataset(
    'conversion_data_excluded,conversion_data_ntf,conversion_data',
    id,
    daysAgo,
  );

  const { response } = data;
  const {
    conversion_data_excluded,
    conversion_data_ntf,
    conversion_data,
  } = response;
  const conversion_dataset = !!conversion_data_excluded && !!conversion_data_excluded.length
    ? conversion_data_excluded
    : !!conversion_data_ntf && !!conversion_data_ntf.length
      ? conversion_data_ntf
      : conversion_data;

  const use_filter_for_original = conversion_dataset.length > 0 && Object.keys(conversion_dataset[0]).indexOf('is_valid') !== -1;
  const conversion_data_original = use_filter_for_original ? conversion_dataset.filter(({ is_valid }) => !!is_valid) : conversion_dataset;

  const ordered = conversion_data_original
    .map(row => ({ ...row, mtimestamp: moment(row.timestamp) }))
    .sort((p, c) => p.mtimestamp - c.timestamp)
    .reduce((p, c) => {
      const current = p[c.base_id];
      if (!current) {
        p[c.base_id] = c;
        return p;
      }
      const rekeyedC = Object.keys(c)
        .filter(key => key.includes('answer') || key.includes('response'))
        .reduce((prev, key) => {
          prev[`${key}_followup`] = c[key];
          return prev;
        }, {});

      Object.assign(p[c.base_id], rekeyedC);

      return p;
    }, {});

  return {
    response: {
      conversion_data: Object.values(ordered),
      conversion_data_original,
    },
  };
};

export const getConversionDataSummary = (id, daysAgo = 3, skipped = false) => {
  const SKIPPED = skipped || [
    'date',
    'conversion_hash_id',
    'timestamp',
    'sessionId',
    'base_id',
    'user_agent',
    'script_version',
    'rb_source',
    'hash_ip',
    'segment_anonymous_id',
    'segmentmessageId',
  ];

  return getConversionDataset(id, daysAgo).then(data => {
    const { response } = data;
    const { conversion_data, conversion_data_original } = response;

    const transposed = conversion_data.reduce((obj, row) => {
      Object.keys(row).forEach(k => {
        obj[k] = obj[k] || [];
        obj[k].push(row[k]);
      });
      return obj;
    }, {});

    const summary = Object.keys(transposed)
      .map(key => {
        const raw = transposed[key];
        const set = new Set(raw);
        const { size } = set;
        return { key, raw, set, size };
      })
      .sort((p, c) => c.size - p.size)
      .filter(({ key }) => !SKIPPED.includes(key));

    return {
      conversion_data_original,
      conversion_data,
      summary,
    };
  });
};
