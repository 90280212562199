import React, { Component } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Button, Header, Label, Form, Dropdown, Field } from 'semantic-ui-react';
import Container from '../parts/Container';
import * as identity from '../../identity';

const FieldRowStyles = styled(Container)`
  margin-top: ${props => props.isSub ? '0px' : '15px'};
  padding-left: ${props => props.isSub ? '60px' : 0};
`;

const InnerFieldRowStyles = styled(Container)`
  border-radius: 4px;
  border-top-right-radius: ${props => props.isSub ? 0 : '4px'};
  border-top-left-radius: ${props => props.isSub ? 0 : '4px'};
  border-bottom-right-radius: ${props => props.index < props.count ? 0 : '4px'};
  border-bottom-left-radius: ${props => props.index < props.count && props.isSub ? 0 : '4px'};
  padding: 15px;
  padding-left: 0px;
  background-color: ${identity.GREY2};
`;

const LabelStyles = styled.label`
  width: ${props => props.first && !props.isSub ? '40px' : 'auto'};
  margin-left: ${props => props.first ? '15px' : '5px'};
  margin-right: 5px;
  color: ${identity.BLUE};
`;

const SelectStyles = styled(Form.Select)`
  margin: ${props => props.first ? '0 5px 0 40px !important' : '0 5px 0px 5px !important'};
`;

const InputStyles = styled(Form.Input)`
  flex: 1;
  margin: 0 !important;
`;

const OrButtonStyles = styled(Button)`
  width: 70px;
  background-color: ${identity.BLUE} !important;
  color: white !important;
  margin-left: 5px !important;
`;


export default class AttributableEventsRow extends Component {

    onCategorySelect = (evt, data) => {
      const { onParamChange } = this.props;
      const values = data.options.find(option => option.value === data.value)
      console.log(values);
      onParamChange(evt, values);
    }

    onParamSelect = (evt, data) => {
      const { onParamChange } = this.props;
      const values = data.options.find(option => option.value === data.value)
      onParamChange(evt, values);
    }

    onCustomParamChange = (evt, data) => {
      const { onCustomChange } = this.props;
      onCustomChange(evt, data);
    }

    render() {
      const { logic, exemplar, isSub, index, segmentFields, operator, onParamChange, onPatternChange, addLogic, removeLogic, onCustomChange } = this.props;
      const { category, param } = logic;
      let reqsSatisfied;
      const needsParam = category !== 'referrer' && category !== 'url';

      const whereOptions = Object.keys(segmentFields).map((field) => {
        return { text: field.toUpperCase(), value: field, category: field }
      })

      whereOptions.push({text: "CUSTOM_TIER", value: "custom_tier", category: "custom_tier"})

      if (needsParam) {
        reqsSatisfied = category !== undefined &&  param !== undefined;
      } else {
        reqsSatisfied = category !== undefined;
      }
       return (
         <FieldRowStyles fill {...this.props}>
           <InnerFieldRowStyles fill align="center" {...this.props} >
             <LabelStyles first={true} isSub={isSub}>{operator}</LabelStyles>
             <SelectStyles
               value={logic.category}
               search
               required
               first={!isSub}
               placeholder="Select field type"
               onChange={this.onCategorySelect}
               options={whereOptions}
               />
             { category && category != "custom_tier" && needsParam && (
               <SelectStyles
                 value={logic.param}
                 search
                 required
                 onChange={this.onParamSelect}
                 placeholder="Select Query Param Name"
                 options={segmentFields[category].map((seg) => {
                   return { text: seg, value: seg, param: seg }
                 })}
               />
             )}

             { category && category == "custom_tier" && (
               <InputStyles
                 value={logic.field}
                 onChange={this.onCustomParamChange}
                 placeholder=""
               />
             )}

             { (reqsSatisfied || category == "custom_tier") && ([
               <LabelStyles>contains</LabelStyles>,
               <InputStyles required value={logic.value}
                 onChange={onPatternChange}
                 onKeyPress={(evt) => {
                   if (evt.which === 13 || evt.which === 32) {
                     evt.preventDefault();
                   }
                 }}
               />
             ])}
             <OrButtonStyles as="a" onClick={addLogic} disabled={!reqsSatisfied}>+ Or</OrButtonStyles>
             { !exemplar && <Button basic negative as="a" onClick={removeLogic}>x</Button> }
           </InnerFieldRowStyles>
         </FieldRowStyles>
       )
    }
 }

 AttributableEventsRow.propTypes = {
   onParamChange: Proptypes.func.isRequired,
   onParamChange: Proptypes.func.isRequired,
   onPatternChange: Proptypes.func.isRequired,
   addLogic: Proptypes.func.isRequired,
   removeLogic: Proptypes.func.isRequired,
   logic: Proptypes.object.isRequired,
   isSub: Proptypes.bool,
   index: Proptypes.number.isRequired,
   segmentFields: Proptypes.object,
   operator: Proptypes.string
 }
