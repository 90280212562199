export const eventTypesMap = {
  'has_onsite': 'Links and Clicks',
  'has_creative': 'Digital Views',
  'has_address_clean_hash': 'Direct Mail Matchback',
  'has_postlog': 'Linear Views',
  'has_ott': 'Streaming Views',
  'has_synthetic_click': 'Synthetic',
  'has_synthetic_view': 'Synthetic',
  'has_survey': 'Survey Response',
  'has_conversion_attribute': 'Promo Codes',
  'has_external_marketing': 'External Marketing',
  'has_conv_only': 'Direct',
  'has_other': 'Other',
  'is_direct': 'Direct'
}

export const trackingMap = {
  'url_tracking_only': 'Links/Clicks Only',
  'url_tracking_enhanced': 'Both Links/Clicks and Enhanced Tracking',
  'enhanced_tracking_only': 'Enhanced Tracking Only',
  'no_info': 'Direct'
}

export const trackingSummOrder = [
  'enhanced_tracking_only',
  'url_tracking_enhanced',
  'url_tracking_only',
  'no_info'
]

export const noDataMsgs = {
  'no_data': {
    'header': 'No Data Found',
    'details': 'If you recently signed up or enabled this feature, please allow up to 24 hours for data to appear.'
  },
  'no_featured_seg': {
    'header': 'No Featured Segment',
    'details': 'Featured segment must be defined for this feature to work'
  }
}

export const eventsTypeCols = (totalRevs, tooltips) => {
  const baseCols = [
  { display: 'Marketing Source', key: 'eventType' , tooltip: tooltips?.source_types },
  { display: 'Conversions', key: 'conversions' , tooltip: tooltips?.source_convs},
  { display: '% of Conversions', key: 'convRatio' , tooltip: tooltips?.source_conv_ratio}
  ]

  if (totalRevs == 0) return baseCols;

  const eventsRevCols = [
    { display: 'Revenue', key: 'revenue' , tooltip: tooltips?.source_rev},
    { display: '% of Revenue', key: 'revRatio' , tooltip: tooltips?.source_rev_ratio}
  ]

  return [...baseCols, ...eventsRevCols]
}