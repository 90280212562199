import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { ChartBarIcon, CursorClickIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { ShareIcon, DatabaseIcon } from '@heroicons/react/solid'

import * as aq from "arquero";

import * as routes from '../../baseRoutes'
import { track, time } from '../../../utils/tracking'
import { DateRangeHooks, SavedReportsGrid, PlatformPerformanceSync, DividingHeader } from '../../components';
import { FREE_PLATFORMS } from './constants'

import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations, getAccountCurrency, getAllIntegrations } from '../../api/account'
import { getSavedReports } from '../../api/attribution';
import { getCurrencyRate } from '../../api/currencyRate'

import { convertCurrencyByDate } from '../../utils/currency'

import { getPlatformSpendDataByPlatform } from './helpers'

import { colorHash } from '../../../components/ViewAttribution/AttributionPercent'

import PlatformMetrics from './PlatformMetrics'
import PlatformSpendChart from './PlatformSpendChart'
import HelpMessage from './HelpMessage';
import VideoModal from './VideoModal';


const StarterDashboard = ({ startDate, endDate, onDateChange, currencyCode }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const integrations = Context.getCache(`integrations`, getAllIntegrations) || null
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null
  const currency = Context.getCache(`currency`, getAccountCurrency) || null

  const [modalOpen, setModalOpen] = useState(false)
  const [savedReports, setSavedReports] = useState(null)
  const [loadingSpend, setLoadingSpend] = useState(true)
  const [loadingConversions, setLoadingConversions] = useState(true)
  const [stillBackfilling, setStillBackfilling] = useState(false)
  const [stillBackfillingShopify, setStillBackfillingShopify] = useState(false)
  const [hasConversionData, setHasConversionData] = React.useState(true)
  const [hasSpendData, setHasSpendData] = React.useState(true)
  const [spendData, setSpendData] = React.useState(undefined)
  const [conversionData, setConversionData] = React.useState(undefined)

  const connectedPlatforms = useMemo(() =>
    !!authorizations ? authorizations.filter(a => a.authorizations.some(a => a.account_id != '')).map(({ platform }) => platform) : []
  , [authorizations])

  const platforms = useMemo(() => {
    if (!authorizations || !integrations) return [];
    return authorizations.map(x => {
      const platformInfo = integrations.find(y => y.platform === x.platform) || {}
      return {
        ...x,
        ...platformInfo,
        title: platformInfo.display_name || x.platform.replaceAll('_', ' ').replaceAll('-', ' '),
        icon: !!platformInfo.icon_white_img && `https://images.rockerbox.com/integrations/${platformInfo.icon_white_img}`,
        defaultIcon: platformInfo.category == 'advertising.digital'
        ? CursorClickIcon
        : platformInfo.category == 'advertising.offline'
        ? SpeakerphoneIcon
        : platformInfo.category == 'advertising.affiliate'
        ? ShareIcon
        : DatabaseIcon
      }
    })
  }, [integrations, authorizations]);

  const colorMap = useMemo(() => {
    if (!authorizations) return {};
    const colors = {}
    authorizations.forEach(x => colors[x.platform] = FREE_PLATFORMS[x.platform]?.color || colorHash(x.platform))
    return colors
  }, [authorizations])

  useEffect(() => {
    if (!authorizations) return
    if (currencyCode == undefined) return
    if (!authorizations.length) return setSpendData([]);
    setLoadingSpend(true);
    getPlatformSpendDataByPlatform(authorizations, startDate, endDate)
      .then(data => {
        const columns = Object.keys(Object.values(data)[0]).filter(x => x != 'date')
        getCurrencyRate(currencyCode, startDate, endDate).then(response => {
          const rates = response['data']
          const table = aq.from(Object.values(data));
          const updated = convertCurrencyByDate(table, rates, columns)
          columns.push('date')
          setSpendData(updated.objects({'columns': columns}))
          setLoadingSpend(false)
        })
      })
  }, [authorizations, startDate, endDate, currencyCode])

  const currencyFormatOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD'
  }
  currencyFormatOptions.currency = currencyCode || 'USD';
  const currencyFormatter = new Intl.NumberFormat('en-US', currencyFormatOptions);

  // spend data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return
    if (!connectedPlatforms.length) return setHasSpendData(false)
    if (!(connectedPlatforms.filter(x => x != 'shopify').length > 0)) return setHasSpendData(false)
    setHasSpendData(true)
  }, [connectedPlatforms])

  // conversion data for metrics charts
  useEffect(() => {
    if (!connectedPlatforms) return
    if (!connectedPlatforms.includes('shopify')) return setHasConversionData(false)
    setHasConversionData(true)
  }, [connectedPlatforms])

  useEffect(() => {
    getSavedReports()
    .then(savedReports => {
      setSavedReports(savedReports)
    })
  }, [])

  useEffect(() => {
    if (!!loadingSpend || !!loadingConversions) {
      time('home.view')
      return
    }
    track('home.view')
  }, [loadingSpend, loadingConversions])

  return (
    <div className='starter-dashboard'>
      <VideoModal {...{ open: modalOpen, onClose: () => setModalOpen(false) }} />
      <HelpMessage {...{ setModalOpen }} />
      {stillBackfilling && connectedPlatforms.map(p =>
        <PlatformPerformanceSync {...{ platform: p, setPercent: () => {}, onComplete: () => window.location.reload() }}/>
      )}
      {stillBackfillingShopify &&
        <PlatformPerformanceSync {...{ platform: 'shopify', setPercent: () => {}, onComplete: () => window.location.reload() }} />
      }
      <div className="datepicker">
        <DateRangeHooks transparent {...{
          startDate,
          endDate,
          firstReportingDate: false,
          onDateChange
        }} />
      </div>

      <PlatformMetrics {...{
        loading: loadingConversions || loadingSpend,
        setLoading: setLoadingConversions,
        stillBackfilling: stillBackfilling || stillBackfillingShopify,
        setStillBackfillingShopify,
        hasConversionData,
        currencyFormatter,
        startDate,
        endDate,
        currencyCode,
        spendData
      }} />

      <PlatformSpendChart {...{
        loading: loadingConversions || loadingSpend,
        setLoading: setLoadingSpend,
        stillBackfilling,
        setStillBackfilling,
        hasSpendData,
        platforms,
        startDate,
        endDate,
        colorMap,
        currencyFormatter,
        data: spendData
      }} />

      <DividingHeader
        text='Saved Reports'
        icon={<ChartBarIcon />}
      />
      {(savedReports && savedReports.length > 0) ? <SavedReportsGrid columns={3} hideTitle /> :
        <Grid centered style={{ marginTop: -2, marginBottom: 0 }}>
          <Grid.Column width={8}>
            <p style={{ fontSize: '18px', textAlign: 'center'}}>
              After <Link to={routes.connectAdvertisingPlatform}>connecting your advertising platforms</Link>, you can adjust the filters within each report and save them for quick access later. Your Saved Reports are available here on your dashboard and within each report.
            </p>
          </Grid.Column>
        </Grid>
      }
    </div>
  )
}


export default StarterDashboard
