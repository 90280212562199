import create from "zustand";
import moment from "moment";
import _ from "lodash";
import { defaultStart, defaultEnd } from "./constants";
import {
  getEnabledExperimentPlatforms,
  getConversionSegmentReportingData,
  getExperiments,
  postExperiment,
  updateExperiment,
  getMessages,
  getArtifacts
} from "../../utils/api";

export const experimentStore = create((set, get) => ({
  getExperiments: async () => {
    set({ apiRequestLoading: true });
    const experiments = await getExperiments();
    set({ experimentList: experiments, apiRequestLoading: false });
    return experiments;
  },
  experimentList: [],
  activeExperimentId: null,
  setActiveExperimentId: id => set({ activeExperimentId: id }),
  newExperiment: {},
  setNewExperiment: (field, value) => {
    set({
      newExperiment: Object.assign({}, get().newExperiment, { [field]: value })
    });
  },
  saveNewExperiment: async body => {
    set({ apiRequestLoading: true });
    const resp = await postExperiment(body);
    const experimentList = resp["response"];
    const experimentsFilteredByName = experimentList.filter(
      exp => exp.name === body.name
    );
    const newExperiment = experimentsFilteredByName.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })[0];

    set({
      apiRequestLoading: false,
      experimentList,
      activeExperimentId: newExperiment["id"]
    });
    return newExperiment["id"];
  },
  resetNewExperiment: keys => {
    if (keys && Array.isArray(keys)) {
      const newState = Object.assign({}, get().newExperiment);
      keys.forEach(key => {
        delete newState[key];
      });
      set({ newExperiment: newState });
    } else {
      set({ newExperiment: {} });
    }
  },
  showArchivedExperiment: false,
  setShowArchivedExperiment: bool => set({ showArchivedExperiment: bool }),
  updateExperimentStatus: async (status, treatments, id) => {
    set({ apiRequestLoading: true });
    const body =
      status === "completed"
        ? {
            end_date: moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          }
        : {
            active: 0,
            end_date: moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          };
    body["treatments"] = treatments;
    const resp = await updateExperiment(body, id);
    set({ apiRequestLoading: false });
  },
  updateExperimentObj: async (obj, id) => {
    set({ apiRequestLoading: true });
    const resp = await updateExperiment(obj, id);
    set({ apiRequestLoading: false });
  },
  activeTreatments: [],
  selectedTreatments: [],
  setSelectedTreatments: treatments => set({ selectedTreatments: treatments }),
  setActiveTreatments: treatments => set({ activeTreatments: treatments }),
  saveTreatment: id => async obj => {
    await updateExperiment(obj, id);
  },
  newTreaments: {},
  setTreament: index => (field, value) => {
    const treaments = _.cloneDeep(get().newTreaments);
    if (treaments[index]) {
      treaments[index] = Object.assign(treaments[index], { [field]: value });
    }
    set({ newTreaments: treaments });
  },
  platforms: [],
  getPlatforms: async () => {
    const platforms = await getEnabledExperimentPlatforms();
    set({ platforms, apiRequestLoading: false });
  },
  selectTreeOpen: "pending",
  setSelectTreeOpen: state => set({ selectTreeOpen: state }),
  setupError: "",
  setSetupError: err => set({ setupError: err }),
  rawData: [],
  getRawData: async (id, startDate, endDate) => {
    set({ apiRequestLoading: true });
    const data = await getConversionSegmentReportingData(
      id,
      startDate,
      endDate
    );
    // restrcit to only required columns
    set({ rawData: data.flat(), apiRequestLoading: false });
  },
  treatmentStartDate: defaultStart,
  setTreatmentStartDate: date => set({ treatmentStartDate: date }),
  treatmentEndDate: defaultEnd,
  setTreatmentEndDate: date => set({ treatmentEndDate: date }),
  result: {},
  initialLoading: true,
  setInitialLoading: bool => set({ initialLoading: bool }),
  apiRequestLoading: false,
  experimentMessage: {},
  getExperimentMessages: async () => {
    const resp = await getMessages();
    set({
      experimentMessage: resp.filter(
        item => item.name === "experiment_index"
      )[0]
    });
  },
  fullTableData: [],
  setFullData: data => set({ fullTableData: data }),
  groupedData: {},
  setGroupedData: data => set({ groupedData: data }),
  computingLoader: false,
  setComputingLoader: bool => set({ computingLoader: bool }),
  chartType: "cpa",
  setChartType: type => set({ chartType: type }),
  chartScale: "linear",
  setChartScale: scale => set({ chartScale: scale }),
  artifacts: {},
  getExperimentArtifacts: async () => {
    const result = await getArtifacts();
    set({ artifacts: JSON.parse(result["experiment_tooltips"]) });
  }
}));

if (process.env.NODE_ENV === "development") {
  experimentStore.subscribe(console.log);
}
