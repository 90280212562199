import { metrics } from '../platformMetrics'

const calcMainMetricByDateRange = ({ formula, testObj, baselineObj, baselineDays }) => {
  const [x, y]  = formula.params
  const xMetricSum = testObj[x] + (baselineDays && baselineObj[x])
  const yMetricSum = testObj[y] + (baselineDays && baselineObj[y])

  const result = formula.func(xMetricSum, yMetricSum)
  return isNaN(result) ? 0 : result
}

const calcMainMetricByMath = (testObj, baselineObj, baselineDays, metricColumn) => {
  return (testObj[metricColumn] + (baselineDays && baselineObj[metricColumn])) // Single date have empty baselineObj
}

export const generateTrendCards = ({ testObj, baselineObj, testDays, baselineDays, metrics_group, metricsObj, currencyCode }) => {
  if (!metricsObj) metricsObj = metrics
  return metrics_group.flatMap(metric => {

    const selectedMetricObj = metricsObj[metric]

    

    if (!selectedMetricObj) return [];

    // CALC MAIN METRIC BY FORMULA
    const { name, column, display_name, formula, format, trendReversed } = selectedMetricObj
    const metricColumn = column || name;
    const mainNumber = !formula 
      ? calcMainMetricByMath(testObj, baselineObj, baselineDays, metricColumn)
      : calcMainMetricByDateRange({ formula, testObj, baselineObj, baselineDays })

    const defaultComputeAverage = (obj, metric, days) => obj[metric]/days

    const avgFunc = formula ? (obj) => {
      const [x, y]  = formula.params
      return formula.func(obj[x], obj[y])
      return 
    } : defaultComputeAverage

    // CALC DAILY AVGs TREND
    const testAvg = avgFunc(testObj, metricColumn, testDays) // use formula to compute avg over the range
    const baselineAvg = avgFunc(baselineObj, metricColumn, baselineDays)
    const trend = (testAvg - baselineAvg) / baselineAvg

    const isCurrencyOrDecimal = ['currency', 'decimal'].includes(format)
    const isVeryLarge = mainNumber > 100000
    const isPercent = format == "percent"
    const isCTR = format == "ctr"

    const maxDigits = isCurrencyOrDecimal && isVeryLarge ? 0 : 
      isCurrencyOrDecimal ? 2 : 
      isPercent ? 4 : 
      isCTR ? 4 : 0;

    return {
      currency: currencyCode,
      text: display_name,
      value: name,
      mainNumber: Number(mainNumber),
      trendNumber: Number(trend),
      baseline: testDays == 1 ? `over the past day` : `over past ${testDays} days`,
      format: format,
      maxDigits,
      trendReversed: trendReversed
    }
  })
}

const sumByPeriod = ({ dailyPerformance, aggObj, start, end }) => {
  dailyPerformance.slice(start, end).forEach(obj => {
    Object.keys(obj)
      .forEach(metric => { 
        if (metric != 'date') aggObj[metric] = obj[metric] + (aggObj[metric] || 0) 
      })
  })
}

export const splitData = (dates, dailyPerformance) => {
  const testDays = dates.length > 14 ? 7 : 1
  const baselineDays = dates.length - testDays
  const testObj = {}
  const baselineObj = {}

  sumByPeriod({ dailyPerformance, aggObj: testObj, start: baselineDays })
  sumByPeriod({ dailyPerformance, aggObj: baselineObj, end: baselineDays })
  return [testObj, baselineObj, testDays, baselineDays]
}
