import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react'
import CacheContext from '../../../utils/CacheContext.js'
import { getPixelCode, getTransformTables, getBatchConfigData, getBatchInbox, getMessages } from '../../../utils/api.js'
import InboxSetup from '../../BatchConfig/setup/InboxSetup';

const HELP_MESSAGE = "batch_integration_setup"

const BatchInbox = ({ platform }) => {
  const [transformTable, setTransformTable] = useState(false)
  const [inboxId, setInboxId] = useState(false)
  const [selectedInbox, setInbox] = useState(false)

  const [state, setState, Context] = React.useContext(CacheContext)
  const messages = Context.getCache(`messages`, getMessages) || []
  const batchMessage = messages.find(({ name }) => name == HELP_MESSAGE)

  React.useEffect(() => {
    getTransformTables()
      .then(resp => {
        const transformTable = resp.find(row => row.platform == platform);
        setTransformTable(transformTable);
      })
  }, [platform])

  React.useEffect(() => {
    if (transformTable) getBatchConfigData()
      .then(resp => {
        const { acetl_report_id } = transformTable;
        const inbox = resp.filter(row => row.report_id == acetl_report_id);
        setInboxId(inbox[0].inbox_id);
      })
  }, [transformTable])

  React.useEffect(() => {
    if (inboxId) getBatchInbox()
      .then(resp => {
        const inbox = resp.find(row => row.inbox_id == inboxId);
        inbox.mapper = inbox.mapper[0];
        setInbox(inbox);
      })
  }, [inboxId])

  const { name, inbox_type, mapper } = selectedInbox;
  const toggleUploadId = false

  return <>
    {!!batchMessage &&
      <Message
        info
        icon='info circle'
        header={batchMessage.header}
        content={batchMessage.text}
      />
    }
    <h3 style={{fontWeight:"bold"}}>Batch Delivery Instructions</h3>
    <InboxSetup {...{ inbox_type, mapper, name, toggleUploadId }} />
  </>
}

export default BatchInbox;
