import React, { useMemo } from 'react';
import { useAtom } from 'jotai'

import { IndexGridTree } from '@rockerbox/styleguide';

import { spendFormatter } from '../../../utils/valueFormatter';
import { tiersAtom, columnsAtom } from '../../../atoms';

const f = (values) => values.filter(x => !x.depth)

export const getTreeGridColumns = (tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals = {}) => {
  return [
    {
      id: "group",
      display: 'Channel',
      key: 'group',
      groupBy: ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'],
      as: IndexGridTree.NameCell(tierColorMap),
    },
    {
      id: "conversion_key",
      display: 'Conversions',
      key: conversionKey,
      as: IndexGridTree.NumberCellTwoDecimals,
      reducer: (values) => d3.sum(f(values), (x) => x[conversionKey]),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: "conversion_percent_key",
      display: '% of Conversions',
      key: conversionKey,
      as: IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals[conversionKey]),
      reducer: (values) => d3.sum(f(values), (x) => x[conversionKey]),
      style: { textAlign: 'right' },
    },
    {
      id: "revenue_key",
      display: 'Revenue',
      key: revenueKey,
      as: IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      reducer: (values) => d3.sum(f(values), (x) => x[revenueKey]),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: "revenue_percent_key",
      display: '% of Revenue',
      key: revenueKey,
      as: IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals[revenueKey]),
      reducer: (values) => d3.sum(f(values), (x) => x[revenueKey]),
      style: { textAlign: 'right' },
    },
    {
      id: "spend",
      display: 'Spend',
      key: 'spend',
      as: IndexGridTree.SpendCell(spendFormatter(currencyCode)),
      reducer: (values) => d3.sum(f(values), (x) => x.spend),
      summaryLabel: 'Total',
      style: { textAlign: 'right' },
    },
    {
      id: "spend_percent",
      display: '% of Spend',
      key: 'spend',
      as: IndexGridTree.PercentageCellOfTotal(tiersSummaryRawTotals.spend),
      reducer: (values) => d3.sum(f(values), (x) => x.spend),
      style: { textAlign: 'right' },
    },
    {
      id: "cpa",
      display: 'CPA',
      key: 'cpa',
      as: IndexGridTree.CpaCell(conversionKey, spendFormatter(currencyCode)),
      reducer: (values) => (d3.sum(f(values), (x) => x.spend) ? d3.sum(f(values), (x) => x.spend) / d3.sum(f(values), (x) => x[conversionKey]) : 0),
      style: { textAlign: 'right' },
    },
    {
      id: "roas",
      display: 'ROAS',
      key: 'roas',
      as: IndexGridTree.RoasCell(revenueKey, spendFormatter(currencyCode)),
      reducer: (values) => (d3.sum(f(values), (x) => x.spend) ? d3.sum(f(values), (x) => x[revenueKey]) / d3.sum(f(values), (x) => x.spend) : 0),
      style: { textAlign: 'right' },
    },
  ]
}

export const useTreeColumns = (tiersData, tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals) => {

  const [savedColumnIds, setSavedColumnIds] = useAtom(columnsAtom);
  const [tiers, setTiers] = useAtom(tiersAtom);
  
  const allColumns = useMemo(
    () => getTreeGridColumns(tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals),
    [tierColorMap, conversionKey, currencyCode, revenueKey]
  );

  const selectedColumns = useMemo(
    () => { 
      if (!tiersData || tiersData.length == 0) return []; // if no data, return empty array
      if (savedColumnIds && savedColumnIds.length == 0) return allColumns;

      // since order matters... use the ordered that is saved
      const selectedColumns = savedColumnIds
        .flatMap((id) => allColumns.find((c) => c.id == id) || []);

      // if there are no selected columns, return all columns
      if (selectedColumns.length == 0) return allColumns;

      return selectedColumns;
    },
    [savedColumnIds, allColumns, conversionKey, tiersData]
  );

  const setColumns = (selectedCols) => {
    const keys = selectedCols.map(({ id }) => id)
    setSavedColumnIds(keys)
  }

  return {
    tiers: tiers.length ? tiers : ["tier_1","tier_2","tier_3","tier_4","tier_5"],
    setTiers,
    allColumns,
    selectedColumns,
    setColumns,
  }
}

