import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { StatCard, SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Checkbox, Icon, Segment, Dropdown, Table } from 'semantic-ui-react';
import { getSurveyConfigs, getSurveyResults } from '../../utils/api';
import { _timeRange, yesterday, week, month } from '../timerange';
import { _pushPath } from '../pushpath';
import EmptyFeature from '../Empty';
import { ATTRIBUTABLE_MODELS } from '../ViewAttribution';
import _ from 'lodash'
import SummaryBar from '../ViewAttribution/SummaryBar';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { formatPercent, buildCurrency } from '../../utils/format_helpers';


class SurveyResults extends _pushPath(_timeRange(Component)) {

  state = {
    model: "last_touch",
    level: "tier_1",
    stats: [],
    configs: false,
    results: [],
    selectedRow: undefined,
    selectedValues: [],
    loading: false
  }

  getData() {
    const { results, configs, loading } = this.state;
    const { id, startDate, endDate } = this.props.match.params;

    if (!configs) {
      getSurveyConfigs()
        .then(configs => {
          configs.map( row => {
            row.text = row.display_name
            row.key = row.id
            row.value = row.id
          })

          if (configs.length > 0) {
            const { id } = configs[0];
            const _startDate = startDate || month
            const _endDate = endDate || yesterday
            this.updatePath({ view: "view", id, startDate: _startDate, endDate: _endDate });
            this.setState({ configs });
          }
        })
    }

  }

  componentDidMount() {
    this.getData()
  }

  buildAgg = (survey_response_key) => {
    const { results, stats } = this.state;
    const summaryStats = stats.reduce((p,c) => {
      p['total_conversions'] += c['total_conversions']
      p['total_responses'] += c['total_responses']
      return p
    },{"total_conversions":0,"total_responses":0})

    const agg = d3.nest()
      .key(row => row[survey_response_key])
      .entries(results)
      .map(({key, values}) => {
        const count = Math.round(values.reduce((p,row) => p + row.even, 0));
        const percent_survey = count / summaryStats.total_responses;
        const percent_conv = count / summaryStats.total_conversions;
        return { key, values, count, percent_survey, percent_conv }
      })
      .sort((p,c) => c.count - p.count)
    return agg
  }

  componentDidUpdate(prevProps, prevState) {

    const { results, configs, loading } = this.state;
    const { id, startDate, endDate } = this.props.match.params;

    const sameParams = (
      (prevProps.match.params.id == id) &&
      (prevProps.match.params.startDate == startDate) &&
      (prevProps.match.params.endDate == endDate)
    )

    if ((id && !loading && configs && configs.length > 0 && results.length == 0) || (configs && !sameParams)) {

      const { conversion_id, survey_response_key } = configs.find(row => row.id == id)
      this.setState({ loading: true, selectedRow: undefined, selectedValues: [] })
      getSurveyResults(conversion_id, startDate, endDate)
        .then(response => {

          const results = response.survey_results
          const stats = response.survey_stats
          const summaryStats = stats.reduce((p,c) => {
            p['total_conversions'] += c['total_conversions']
            p['total_responses'] += c['total_responses']
            return p
          },{"total_conversions":0,"total_responses":0})

          const agg = d3.nest()
            .key(row => row[survey_response_key])
            .entries(results)
            .map(({key, values}) => {
              const count = Math.round(values.reduce((p,row) => p + row.even, 0));
              const percent_survey = count / summaryStats.total_responses;
              const percent_conv = count / summaryStats.total_conversions;
              return { key, values, count, percent_survey, percent_conv }
            })
            .sort((p,c) => c.count - p.count)
          this.setState({ summaryStats, stats, results, agg, loading: false, survey_response_key })
        })
    }
  }


  setResponseKey = (_, event) => {
    const { survey_response_key } = this.state;
    const key = event['checked'] ? "original_" + survey_response_key : survey_response_key;
    const agg = this.buildAgg(key)
    this.setState({ agg })
  }

  chooseValues = (selectedRow) => {
    this.setState({ selectedRow, selectedValues: selectedRow.values })
  }

  resetSelection = () => {
    this.setState({ selectedRow: undefined, selectedValues: [] })
  }

  render() {
    const { level, model, loading, configs, results, agg, selectedValues, survey_response_key } = this.state
    const { summaryStats, selectedRow } = this.state
    const { id } = this.props.match.params

    if (configs == [] && loading == false) return <EmptyFeature message="Your account is not setup to collect survey data. Reach out to Rockerbox customer success to learn more." />


    const aggCols = [
      { display:"Response", key:"key", headerWidth: 3 },
      { display:"Count", key:"count", headerWidth: 1 },
      { display:"% (of survey)", key:"percent_survey", headerWidth: 1, as: PercentCell },
      { display:"% (of conversions)", key:"percent_conv", headerWidth: 1, as: PercentCell }
    ]


    const tierHeader = (col) => (
      <Table.HeaderCell width={2}>
        {col.display} grouped by &nbsp;
          <Dropdown placeholder="Level" options={COLS} defaultValue={level}
            onChange={this.updateStateField("level")}
          />

      </Table.HeaderCell>
    )

    const countHeader = (col) => (
      <Table.HeaderCell width={1}>
        {col.display} by &nbsp;
          <Dropdown placeholder="Model" options={ATTRIBUTABLE_MODELS} defaultValue={model}
            onChange={this.updateStateField("model")}
          />

      </Table.HeaderCell>
    )

    const detailCols = [
      { display:"Marketing", key:"key", headerAs: tierHeader },
      { display:"Conversions", key:"count", headerAs: countHeader },
      { display:"Revenue", key:"revenue", as: CurrencyCell, headerWidth: 1 },
      { display:"Percent", key:"percent", as: PercentCell, headerWidth: 1 },
      { display:"vs Survey Responders", key:"baseline_diff", as: DirectionPercentCell, headerWidth: 1 }
    ]

    const allDetailCols = [
      { display:"Marketing", key:"key", headerAs: tierHeader },
      { display:"Conversions", key:"count", headerAs: countHeader },
      { display:"Revenue", key:"revenue", as: CurrencyCell, headerWidth: 1 },
      { display:"Percent", key:"percent", as: PercentCell, headerWidth: 1 }
    ]

    const selectedCols = COLS.concat([{key:model,display:"Conversions"}])

    const allKeys = COLS.map(row => row.key)
    const keyIndex = allKeys.indexOf(level)
    const keys = allKeys.slice(0, keyIndex + 1)

    const allTotal = results.reduce((p,c) => p + c[model],0)
    const total = selectedValues.reduce((p,c) => p + c[model],0)

    const allData = calculateRollup(results, keys, model, allTotal)
    const allDataMap = allData
      .reduce((p,c) => {
        p[c.key] = c.percent
        return p
      },{})
    const selectedData = calculateRollup(selectedValues, keys, model, total, allDataMap)

    const dailyResponses = d3.nest()
      .key(row => row.date)
      .entries(selectedValues)
      .map(row => {
        row['date'] = row['key']
        row['count'] = row.values.reduce((p,c) => p + c[model], 0)
        return row
      })
    const allDailyResponses = d3.nest()
      .key(row => row.date)
      .entries(results)
      .map(row => {
        row['date'] = row['key']
        row['count'] = row.values.reduce((p,c) => p + c[model], 0)
        return row
      })

    return <React.Fragment>
      <ContentCard
        disableHeaderEllipse noContent
        title={<Dropdown options={configs} defaultValue={parseInt(id)} />}
        topRight={this.renderDateSelector()}
      />
      <SplitLayout
        leftWidth={8}
        rightWidth={8}
        leftContent={
          <React.Fragment>
            <SurveyStats {...{summaryStats}} />
            <ContentCard hasTable>
              <IndexGrid
                cols={aggCols} data={agg} selectedRow={this.state.selectedRow}
                onRowClick={item => this.chooseValues(item)}
              />
            </ContentCard>
            { results && !!results.length && results[0]["original_" + survey_response_key] &&
              <React.Fragment>
                <Checkbox onClick={this.setResponseKey} /> &nbsp; show raw responses
              </React.Fragment>
            }
          </React.Fragment>
        }
        rightContent={
          <React.Fragment>
            {selectedRow && <React.Fragment>
              <ContentCard title={
                <React.Fragment>
                  <Dropdown clearable options={[{"text":selectedRow.key, "value": selectedRow.key}]} value={selectedRow.key} onChange={this.resetSelection} />
                </React.Fragment>
              } >
                <SummaryBar tooltipDirection={"right"} data={dailyResponses} exclude={["values","key"]} height={80} hideXAxis={true} />
              </ContentCard>
              <ContentCard title={`Associated Marketing Events`} hasTable>
                <IndexGrid
                  cols={detailCols}
                  data={selectedData}
                />
              </ContentCard>
            </React.Fragment>
            }
            { !selectedRow && <React.Fragment>
                <ContentCard title={"All Survey Responses"} >
                  <SummaryBar tooltipDirection={"right"} data={allDailyResponses} exclude={["values","key"]} height={80} hideXAxis={true} />
                </ContentCard>
                <ContentCard title={`Associated Marketing Events`} hasTable>
                  <IndexGrid
                    cols={allDetailCols}
                    data={allData}
                  />
                </ContentCard>
              </React.Fragment>
            }
          </React.Fragment>
        }
      />
      { this.renderDateModal() }
    </React.Fragment>
  }
}
const calculateRollup = (values, by, model, total, baselineMap) => {
  return d3.nest()
    .key(row => by.map(k => row[k]).join(" / "))
    .entries(values)
    .map(({key, values}) => {
      const count = Math.round(values.reduce((p,row) => p + row[model], 0)*100)/100
      const revenue = Math.round(values.reduce((p,row) => p + row[`revenue_${model}`], 0)*100)/100
      const percent = Math.round(values.reduce((p,row) => p + row[model], 0)*100)/100/total
      const baseline_percent = baselineMap && baselineMap[key]
      const direction = percent > baseline_percent ? 1 : -1
      const baseline_diff = baseline_percent ?
        direction * Math.abs(baseline_percent - percent)/((percent + baseline_percent)/2) : 0

      return {
        key, count, revenue, percent, baseline_percent, baseline_diff, values
      }
    })
    .sort((p,c) => c.count - p.count)
}

const PercentCell = ({item, col}) => {
  return <Table.Cell>{ formatPercent(item[col.key]) }</Table.Cell>
};
const DirectionPercentCell = ({item, col}) => {
  const abs = Math.abs(item[col.key])
  const direction = Math.abs(item[col.key]) == item[col.key] ? "up" : "down"
  const color = direction == "up" ? "green" : "red"
  const percentFormatter = new Intl.NumberFormat(
    'en-US',
    {style: 'percent',minimumFractionDigits: 2}
  )
  return <Table.Cell>
    <Icon name={`arrow alternate circle ${direction}`} color={color} />
    { percentFormatter.format(Math.abs(item[col.key])) }
  </Table.Cell>
};
const CurrencyCell = ({item, col}) => {
  return <Table.Cell>{ buildCurrency(item[col.key]) }</Table.Cell>
};
const SurveyStats = ({summaryStats}) => {
  return <Segment.Group horizontal style={{marginTop:0}} >
    <StatCard
      label="Conversions"
      value={summaryStats && summaryStats.total_conversions}
    />
    <StatCard
      label="Responses"
      value={summaryStats && summaryStats.total_responses}
    />
    <StatCard
      label="Response Rate"
      value={summaryStats && summaryStats.total_responses/summaryStats.total_conversions}
      type="percent"
    />
  </Segment.Group>
};

const COLS = [
  {key: "tier_1", display:"tier_1", value:"tier_1", text:"tier_1"},
  {key: "tier_2", display:"tier_2", value:"tier_2", text:"tier_2"},
  {key: "tier_3", display:"tier_3", value:"tier_3", text:"tier_3"},
  {key: "tier_4", display:"tier_4", value:"tier_4", text:"tier_4"},
  {key: "tier_5", display:"tier_5", value:"tier_5", text:"tier_5"}
]

export default withRouter(SurveyResults);
