import React from 'react'
import { Modal, Divider } from 'semantic-ui-react'
import { ShoppingBagIcon } from '@heroicons/react/outline'

import { Section, prepareConversionData } from './ConversionDetails'
import { PRODUCT_FIELDS } from './constants'


export const productsFormatter = (x) => {
  const unescaped = x.replace(/\\\\\\/g, '&%&%#@').replace(/\\/g, '').replaceAll('&%&%#@', '\\')
  const products = Array.isArray(JSON.parse(unescaped)) ? JSON.parse(unescaped) : [JSON.parse(unescaped)]
  const productsInfo = products.map(product => prepareConversionData(PRODUCT_FIELDS, product))
  const numProducts = products.reduce((acc, product) => acc + product.quantity, 0) || products.length

  const modalContent = (
    <div style={{ padding: '1em' }}>
      {productsInfo.map((product, i) => (<>
        <Section
          key={i}
          Icon={ShoppingBagIcon}
          items={product}
        />
        {i < productsInfo.length - 1 && <Divider />}
      </>))}
    </div>
  )

  return (
    <div>
      {numProducts}&nbsp;
      <Modal
        closeIcon
        dimmer='inverted'
        className='modal-v3'
        trigger={<a>(View)</a>}
        header='Products'
        content={modalContent}
      />
    </div>
  )
}
