import React, {Component} from 'react';
import { connect } from 'react-redux';
import {ContentCard, ColumnLayout} from '@rockerbox/styleguide';
import {Button} from 'semantic-ui-react';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import {getPixelCode} from '../../utils/api';

class Pixel extends Component {

  state = {
    pixels: [],
    creativePixels: [],
    showCreative: false
  }

  componentDidMount() {
    getPixelCode()
      .then(data => {
        this.setState({pixels: data.pixels, creativePixels: data.creative_pixels});
      })
  }

  setShowCreative = (evt, value) => {
    const showCreative = !this.state.showCreative
    this.setState({ showCreative })
  }

  handleCopy = (event,compiled) => {
    const el = document.createElement('textarea');
    el.value = compiled

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  render() {
    const { setShowCreative } = this;
    const {pixels, creativePixels, showCreative} = this.state;
    return (
      <ColumnLayout stretched leftWidth={3} rightWidth={3} centerWidth={10}
        leftContent={<div></div>}
        rightContent={<div></div>}
        >
        <ContentCard title="Pixel Implementation">
          <p>{this.props.artifacts.pixel_implementation}</p>
            {pixels.length > 0 && pixels.map(c => {
              return (
                <React.Fragment>
                  <AceEditor
                    mode="html"
                    theme="tomorrow"
                    readOnly={true}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={false}
                    highlightActiveLine={false}
                    value={c.compiled}
                    setOptions={{
                      showLineNumbers: true,
                      highlightGutterLine: false
                    }}
                    wrapEnabled={true}
                    width={'100%'}
                    height={'210px'}
                  />
                  <Button
                    primary
                    style={{marginTop: 10}}
                    onClick={(event) => this.handleCopy(event, c.compiled)}
                    >
                      Copy
                  </Button>
                </React.Fragment>
              )
            })}
        </ContentCard>
            <Button onClick={setShowCreative}>{showCreative ? "Hide" : "Show"} Creative Pixels</Button>
            {showCreative && creativePixels.length > 0 && creativePixels.map(c => {
              return (
                <ContentCard title={c.name}>
                  <p>{c.notes}</p>
                  <AceEditor
                    mode="html"
                    theme="tomorrow"
                    readOnly={true}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={false}
                    highlightActiveLine={false}
                    value={c.compiled}
                    setOptions={{
                      showLineNumbers: true,
                      highlightGutterLine: false
                    }}
                    wrapEnabled={true}
                    width={'100%'}
                    height={'100px'}
                  />
                  <Button
                    primary
                    style={{marginTop: 10}}
                    onClick={(event) => this.handleCopy(event, c.compiled)}
                    >
                      Copy
                  </Button>
              </ContentCard>
              )
            })}
      </ColumnLayout>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    artifacts: state.app.artifacts,
  };
}

export default connect(mapStateToProps)(Pixel);
