import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { Header, Dropdown, Divider } from 'semantic-ui-react'
import moment from 'moment'
import { SplitLayout, ContentCard, IndexGridNew, HorizontalBar } from '@rockerbox/styleguide'
import { getSegments, getEventTypeResults, getMessages, getAccountCurrency, getArtifacts } from '../../utils/api'
import CacheContext from '../../utils/CacheContext'
import DateRangeHooksUrlState from '../helpers/DateRangeHooksUrlState'
import { useURLState } from '../hooks'
import { Tooltip, EventTypeExplainer, LoadingSpinner, NoData } from './stateless'
import { noDataMsgs } from './constants'
import SummaryCards from './summaryCards'
import aggData from './aggData'
import _ from 'lodash';
import { track } from '../../utils/tracking'

const HELP_MESSAGE = "event_type_analysis"

const metricOption = [
  { text: "Conversions", value: "conversions" },
  { text: "Revenue", value: "revenue" }
]

const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD")
const monthAgo = moment.utc().utcOffset(-5).subtract(30, "days").format("YYYY-MM-DD")
const firstReportingDate = moment.utc("2021 08 1").utcOffset(-5)

const EventTypeDist = (props) => {
  const { data } = props || {}
  const { cols, values } = data
  return <IndexGridNew
    idKey="key"
    cols={cols}
    data={values}
  />
}


const EventTypes = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const messages = Context.getCache(`messages`, getMessages) || []
  const eventTypeMessage = messages.find(({ name }) => name == HELP_MESSAGE)
  const currency = Context.getCache(`currency_code`, getAccountCurrency) || "USD"
  const segsInfo = (Context.getCache(`segments`, getSegments) || undefined)
  const [segName, setSegName] = useState(undefined)
  const [segId, setSegId] = useState(0)
  const [includeRevenue, setIncludeRevenue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setDataObj] = useState(undefined)
  const [startDate, setStartDate] = useURLState('startDate', monthAgo)
  const [endDate, setEndDate] = useURLState('endDate', yesterday)
  const [metricLabel, setMetricLabel] = useState("Conversions")
  const [metricSummary, setMetricSummary] = useState(undefined)
  const [eventTypesDist, setEventTypeDist] = useState(undefined)

  //Tooltips
  const { marketing_source_tooltips, marketing_source_help_docs, intercom_product_tours } = Context.getCache(`artifacts`, getArtifacts) || {};
  const tooltips = !!marketing_source_tooltips ? JSON.parse(marketing_source_tooltips) : {};
  const helpDocs = !!marketing_source_help_docs ? JSON.parse(marketing_source_help_docs) : {}
  const helpDocLink = helpDocs?.marketing_source_help_docs_link
  const intercomTours = !!intercom_product_tours ? JSON.parse(intercom_product_tours) : {}
  const intercomTourId = intercomTours?.marketing_source
  // console.log(tooltips)



  useEffect(() => {
    const segObj = ((!segsInfo) ? 0 : segsInfo.find(s => s.featured === 1))
    setSegName(!segObj ? segObj : segObj.action_name)
    setSegId(!segObj ? segObj : segObj.action_id)
    setIncludeRevenue(!segObj ? segObj : segObj.include_revenue)
  }, [segsInfo])

  useEffect(() => {
    setLoading(true)
    if (!segId) return;
    getEventTypeResults(segId, startDate, endDate)
      .then(data => {
        setDataObj(data.response)
      }).finally(() => setLoading(false))
  }, [endDate, startDate, segId])

  const isDataMissing = (!data || !data['order_tracking_summary'] || !data['event_type_summary'] || data['order_tracking_summary'].length === 0 || data['event_type_summary'].length === 0)

  useEffect(() => {
    const [eventTypeSummary, trackingSummary] = aggData(isDataMissing, data, currency, tooltips)
    setMetricSummary(trackingSummary)
    setEventTypeDist(eventTypeSummary)
  }, [data, isDataMissing, currency])

  const onDateChange = ({ startDate, endDate }) => {
    const formatStartDate = moment(startDate).format("YYYY-MM-DD")
    const formatEndDate = moment(endDate).format("YYYY-MM-DD")
    setStartDate(formatStartDate)
    setEndDate(formatEndDate)
  }

  const onMetricChange = useCallback((e, { value }) => {
    if (!metricSummary) return;
    const data = _.cloneDeep(metricSummary)
    data.map(obj => obj.value = obj[value])
    setMetricLabel(metricOption.filter(obj => obj.value == value)[0].text)
    setMetricSummary(data)
  }, [metricSummary])

  useEffect(() => {
    if (!!startDate && !!endDate) {
      track(`marketing_sources.view`, {
        start_date: startDate,
        end_date: endDate,
      })
    }
  }, [startDate, endDate])


  return <>
    {(!eventTypeMessage) ? null : <EventTypeExplainer header={eventTypeMessage.header} text={eventTypeMessage.text} intercomTourId={intercomTourId} helpDocLink={helpDocLink} />}
    <Divider />
    <ContentCard
      title={<Header as="h3" textAlign='center'>
        <div className='conv-header'>
          {"Marketing Sources: "}
          <b>{(segName || '')}</b>
          <Tooltip tooltip={tooltips.featured_conv} />
        </div>
      </Header>}
      topRight={
        <DateRangeHooksUrlState {...{ startDate, endDate, onDateChange, firstReportingDate, fluid: true, marginTop: 3, marginBottom: 3 }} />
      }
      noContent
    />
    {(loading && segId !== undefined) ? <LoadingSpinner loading={loading} /> :
      (!segId) ? <NoData header={noDataMsgs.no_featured_seg.header} details={noDataMsgs.no_featured_seg.details} /> :
        (isDataMissing) ? <NoData header={noDataMsgs.no_data.header} details={noDataMsgs.no_data.details} /> :
          (<>
            <SummaryCards data={data} tooltips={tooltips} />
            <SplitLayout
              leftWidth={7}
              leftContent={
                <ContentCard >
                  <Header as='h3' textAlign='center' style={{ fontWeight: 500 }}>
                    Distribution of Tracked {' '}
                    {includeRevenue
                      ? <Dropdown
                        inline
                        defaultValue={metricOption[0].value}
                        options={metricOption}
                        onChange={onMetricChange}
                      />
                      : metricLabel}
                  </Header>
                  {(data && metricSummary)
                    ? <HorizontalBar
                      data={metricSummary}
                      color='blue'
                      metricLabel={metricLabel}
                      currency={metricLabel == "Revenue" ? currency : undefined}
                      tooltips={metricLabel == "Revenue" ? {"metric":tooltips?.dist_rev} : {"metric":tooltips?.dist_conv}}
                    />
                    : null}
                </ContentCard>}
              rightWidth={9}
              rightContent={
                <ContentCard >
                  {(data && eventTypesDist) ? <EventTypeDist data={eventTypesDist} /> : null}
                </ContentCard>}
            />
          </>)
    }
  </>
}

export default withRouter(EventTypes);