import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { SplitLayout } from "@rockerbox/styleguide";
import DeliveryOptionIndex, { optionConfig } from "./DeliveryOptionIndex";
import DeliveryOptionMenu from "./DeliveryOptionMenu";
import { _pushPath } from "../pushpath";
import { withRouter } from "react-router-dom";

class OutboxReportIndex extends _pushPath(Component) {
  state = {
    optionType: "do_spaces",
    currentOutbox: {},
    outbox_name: "Email Delivery"
  };

  componentDidUpdate(prevProps) {
    const { outboxes, match: { params: { id } = {} } = {} } = this.props;
    if (prevProps.outboxes.length !== this.props.outboxes.length) {
      if (id) {
        const currentOutbox = this.props.outboxes.filter(
          outbox => outbox.id == id
        )[0];
        return this.setState({
          currentOutbox: currentOutbox || {},
          outbox_name: (currentOutbox && currentOutbox.name) || "",
          optionType: (currentOutbox && currentOutbox.outbox_type) || "email"
        });
      }
    }
  }

  rightSideContent() {
    const { optionType, currentOutbox, outbox_name } = this.state;
    const { outboxes } = this.props;

    const OutboxContent = props => {
      const { outbox_type } = props.currentOutbox;
      return (
        <React.Fragment>
          <DeliveryOptionIndex
            switchCurrentOutbox={this.switchCurrentOutbox.bind(this)}
            updateReportId={this.updateReportId.bind(this)}
            {...props}
          />
        </React.Fragment>
      );
    };

    return (
      <OutboxContent
        {...this.props}
        {...this.state}
        currentOutbox={currentOutbox}
        outbox_name={outbox_name}
        updateReportId={this.updateReportId.bind(this)}
      />
    );
  }

  switchCurrentOutbox(outbox) {
    this.setState({ currentOutbox: outbox });
  }

  handleTypeMenuHeaderClick(type) {
    const outboxes = this.props.outboxes.filter(
      outbox => outbox.outbox_type === type
    );
    this.updatePath({ id: null });
    this.setState({
      optionType: type,
      currentOutbox: {}
    });
  }

  handleOutboxNameClick(name) {
    this.updatePath({ id: null });
    this.setState({
      outbox_name: name
    });
  }

  updateReportId({ id }) {
    this.updatePath({ id });
  }

  render() {
    const self = this;
    const { optionType, outbox_name } = this.state;
    const { outboxes, setOutboxes, loading } = this.props;

    function renderMenu() {
      return (
        <DeliveryOptionMenu
          handleTypeMenuHeaderClick={self.handleTypeMenuHeaderClick.bind(self)}
          setOutboxes={setOutboxes}
          optionType={optionType}
          outboxes={outboxes}
        />
      );
    }

    return (
      <SplitLayout
        leftWidth={3}
        rightWidth={13}
        leftContent={renderMenu()}
        rightContent={this.rightSideContent()}
      />
    );
  }
}

export default withRouter(OutboxReportIndex);
