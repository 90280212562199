import React from 'react'
import { useHistory } from 'react-router-dom'


const Link = (props) => {
  const { push } = useHistory()
  const { to, onClick } = props

  return <a
    {...props}
    href={to}
    onClick={(e) => {
      push(to)
      window.dispatchEvent(new Event('hashchange'))
      !!onClick && onClick()
      !!e && e.preventDefault()
      return false
    }}
  />
}

export default Link
