import React, { Component, useMemo } from 'react';
import { MultiInput, SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { getStandardDatasets, getSpendFields, getTransformTables, updateTransformTables, createTransformTable, testTransformTableSyntax } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';
import { Divider, Input, Icon, Form, Button } from 'semantic-ui-react';

const PlatformMultiInput = (props) => {

  const { platform } = props;
  const [state, setState, Context] = React.useContext(CacheContext);
  const selectOptions = Context.getCache(`${platform}Options`, getSpendFields, platform);
  return <MultiInput {...{selectOptions}} {...props} />
}

const StandardDatasetsSelect = React.memo((props) => {

  const [state, setState, Context] = React.useContext(CacheContext);
  const options = (Context.getCache(`standardDatasets`, getStandardDatasets) || [])
    .filter(({report_type_name}) => report_type_name.includes("transform"))

  return <Form.Select selection {...{options}} {...props} />
})

const CONTENT = [
  "A transform table is a set of rules that takes in a dataset and reformats it in a way that can be used throughout the platform.",
  "The dataset underpinning a transform table is typically raw metadata from an ad buying platform.",
  "The Transform Table Rule that can be setup in this form specifies how the underlying metadata will be transformed into a table that can be used throughout the platform."
]

const TransformTableForm = (props) => {
  const [funcCache, setFuncCache] = React.useState({});
  const [state, setState, Context] = React.useContext(CacheContext);
  const [loading, setLoading] = React.useState(false);
  const { transformTable } = state;
  const { id } = transformTable;

  React.useEffect(() => {
    setFuncCache({})
  }, [transformTable])

  const handleFormUpdate = (key) => {
    if (funcCache[key]) {
       if (funcCache[key]['onChange']) return funcCache[key]['onChange']
    }
    else {
      funcCache[key] = {}
    }

    funcCache[key]['onChange'] = (e,d) => {
      if (transformTable[key] != d.value) {
        transformTable[key] = d.value;
        transformTable.modified = true;
        setState({ transformTable });
      }
    }
    return funcCache[key]['onChange']
  }

  const testFormUpdate = (key) => {
    if (funcCache[key]) {
       if (funcCache[key]['onBlur']) return funcCache[key]['onBlur']
    }
    else {
      funcCache[key] = {}
    }

    funcCache[key]['onBlur'] = (e,d) => {
      testTransformTableSyntax({template:transformTable[key]})
        .then( data => {
          if (data['success'] != 1) {
            transformTable[key + '_invalid'] = true;
            transformTable['submitted'] = false;
            setState({ transformTable });
          }
          else{
            transformTable[key + '_invalid'] = false;
            transformTable['submitted'] = false;
            setState({ transformTable });
          }
        })
    }
    return funcCache[key]['onBlur']
  }

  const handleFormSubmit = () => {
    setLoading(true);
    const postObj = transformTable;
    const transformTableId = postObj.id;

    const validationCalls = [1,2,3,4,5].map( (x) => {
      const postObject = {
        template: transformTable['transform_tier_' + x]
      }
      return testTransformTableSyntax(postObject)
        .then( data => data['success']) ;
    })

    Promise.all(validationCalls)
      .then( (x) => {
        const checks = x.reduce( (a,b) => a + b, 0);
        if (checks < 5) {
          setLoading(false);
          transformTable['submitted'] = false;
          setState({ transformTable });
          return
        }
        if (transformTableId) {
          updateTransformTables(postObj, transformTableId)
            .then(data => {
              handleFormUpdate("submitted")(null, {'value': true});
              //setState({ transformTables: undefined, transformTable: undefined })
            })
        } else {
          createTransformTable(postObj)
            .then(data => {
              handleFormUpdate("submitted")(null, {'value': true});
              //setState({ transformTables: undefined, transformTable: undefined })
            })
        }
        setLoading(false);
      });
    
    //const invalid= [1,2,3,4,5].map( (x) => transformTable['transform_tier_' + x + '_invalid']).some(x => !!x)
    
    /*if (invalid) {
      return
    }*/

  }
  const { name, spend_key, report_name, report_type_display_name, platform } = transformTable;
  const invalid_table = [1,2,3,4,5].map( (x) => transformTable['transform_tier_' + x + '_invalid']).some(x => !!x)
  return <React.Fragment>
    <SplitLayout
      rightWidth={3}
      leftWidth={13}
      leftContent={
        <ContentCard title={"Transform Table Rule"} >
          <Form onSubmit={handleFormSubmit}>
            <Form.Input label="Name" value={name} onChange={handleFormUpdate("name")}/>
            <h5 style={{fontWeight:500}}> Dataset </h5>
            <Divider />
            <Form.Group widths="equal" >
              { !id && <StandardDatasetsSelect label="Dataset" onChange={handleFormUpdate("acetl_report_id")} /> }
              { !id && <Form.Input label="Type" value={report_type_display_name} onChange={handleFormUpdate("report_type_display_name")} disabled /> }
              { id && <Form.Input label="Dataset" value={report_name.replace("Platform: ","").replace(" transform"," Metadata") } disabled /> }
              { id && <Form.Input label="Type" value={report_type_display_name.replace(" Transform"," Metadata")} disabled /> }
            </Form.Group>
            <h5 style={{fontWeight:500}}> Table Specification </h5>
            <Divider />
            <Form.Group widths="equal" style={{flexWrap:"wrap"}}>
              { 
                [1,2,3,4,5].map(num => {
                  const label = `Tier ${num}`;
                  const k = `transform_tier_${num}`;
                  const value = transformTable[k];
                  const invalid = transformTable[k + '_invalid'];
                  const key = `${id}:${k}`
                  const onChange = handleFormUpdate(k)
                  const onBlur = testFormUpdate(k)
                  return <PlatformMultiInput {...{key, id, platform, label, value, invalid, onChange, onBlur}} />
                })
              }
            </Form.Group>
            <PlatformMultiInput {...{id, platform}} label="Spend Key" value={spend_key} onChange={handleFormUpdate("spend_key")} />
            <Button primary content="Save" type="submit" disabled={ invalid_table } loading={ loading } /> 
            {invalid_table && "Please fix syntax errors before saving."}
            {transformTable.submitted && <Icon name="checkmark" color="green" size="large"/>}
          </Form>
        </ContentCard>
      }
      rightContent={
        <div>
          <h4 style={{fontWeight:"bold"}}>About Transform Tables</h4>
          { CONTENT.map(str => <p>{ str }</p>) }
        </div>
      }
    />
  </React.Fragment>

}

export default TransformTableForm;

