import moment from "moment"

export const formatXAxisTickLabel = (date, i, renderData) => {
  if (!renderData) return
  const [year, month, day] = date.split('-')

  const monthString = moment().month(Number(month) - 1).format("MMM")
  const dayString = Number(day).toString()

  if (renderData.length > 45) {
    const lastMonth = i > 1 ? renderData[i - 2].date.split('-')[1] : ''
    if (i % 2 !== 0) return ''
    if (i === 0 || (month !== lastMonth && month !== '')) {
      return monthString
    }
    return dayString
  }

  if (renderData.length > 5) {
    const lastMonth = i > 0 ? renderData[i - 1].date.split('-')[1] : ''
    if (i === 0 || (month !== lastMonth && month !== '')) {
      return monthString
    }
    return dayString
  }

  return `${monthString} ${dayString}`
}

const reformatDateMMDDYYYY = (date) => moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY')

export const toolTipLabelFormatter = (v, renderData) => {
  const hasDate = Object.keys(renderData[0]).includes('date')
  if (hasDate) {
    return reformatDateMMDDYYYY(v)
  }
  return v
}
