import React from 'react';
import PlatformDatasetSyncForm from './PlatformDatasetSyncForm'

const PlatformDataSync = ({ submitDatasetToSync, isSidebar, disableInputs, open }) => {
  
  if (!open) return <></>

  const formStyles = {
    'display': 'flex',
    'flexDirection': isSidebar ? 'column' : 'row',
    'height': 'auto',
    margin: 0,
    ...isSidebar ? { justifyContent: 'center' } : { alignItems: 'flex-end' }
  }

  return (
    <>
      <h2>Add a new share - Platform data</h2>
      <PlatformDatasetSyncForm
        styles={formStyles}
        submitDatasetToSync={submitDatasetToSync}
        disableInputs={disableInputs}
        width={isSidebar ? 400 : 250}
      />
    </>
  )
}

export default PlatformDataSync;
