import React, { useContext, useMemo } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import { ContentCard, IndexGridNew, GridLoader } from '@rockerbox/styleguide'

import { CacheContext } from '../../../utils/CacheContext'
import { getTierColors } from '../../api/account'
import { TimestampCell } from '../ConversionsDetail/helpers'
import { colorHash } from '../../../components/ViewAttribution/AttributionPercent'


const ChannelCell = ({ item, col }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const tierColors = Context.getCache(`tierColors`, getTierColors) || null
  const color = (!!tierColors && tierColors[item[col.key]]) || colorHash(item[col.key])

  return (
    <Table.Cell style={{ textAlign: 'left' }}>
      <Icon name='circle' style={{ color }} size='large' />
    </Table.Cell>
  )
}

const ModelWeightCell = ({ item, col }) => {
  const percentFormatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const value = item[col.key] > 1 ? item[col.key] / 100 : item[col.key]
  const formattedValue = percentFormatter.format(value)

  const bgOpacity =
    !value ? 0 :
    value === 1 && item.even === 1 ? 0.2 :
    value === 1 ? item.even : value

  const borderBottomRightRadius = !!item.last_touch && (
    Object.keys(item).includes('modeled')
      ? (col.key == 'modeled' ? 3.25 : 0)
      : (col.key == 'even' ? 3.25 : 0)
  )

  return (
    <Table.Cell
      collapsing
      style={{
        fontFeatureSettings: '"kern" 1, "tnum" 1',
        textAlign: 'right',
        backgroundColor: `rgba(71, 93, 220, ${bgOpacity})`,
        borderBottomRightRadius,
      }}
    >
      {formattedValue}
    </Table.Cell>
  )
}

const DEFAULT_COLUMNS = [
  {
    display: '',
    key: 'tier_1',
    as: ChannelCell,
  },
  {
    display: 'Tier 1',
    key: 'tier_1',
  },
  {
    display: 'Tier 2',
    key: 'tier_2',
  },
  {
    display: 'Tier 3',
    key: 'tier_3',
  },
  {
    display: 'Tier 4',
    key: 'tier_4',
  },
  {
    display: 'Tier 5',
    key: 'tier_5',
  },
  {
    display: 'Time',
    key: 'timestamp',
    as: TimestampCell,
  },
  {
    display: <>First&nbsp;Touch&nbsp;Credit</>,
    key: 'first_touch',
    as: ModelWeightCell,
  },
  {
    display: <>Last&nbsp;Touch&nbsp;Credit</>,
    key: 'last_touch',
    as: ModelWeightCell,
  },
  {
    display: <>Even&nbsp;Weight&nbsp;Credit</>,
    key: 'even',
    as: ModelWeightCell,
  },
  {
    display: <>Modeled&nbsp;Credit</>,
    key: 'modeled',
    as: ModelWeightCell,
  },
]

const MarketingTouchpoints = ({ marketingEvents }) => {
  const data = useMemo(() => {
    if (!marketingEvents) return null
    return marketingEvents.map((item, i) => ({
      ...item,
      first_touch: i === 0 ? 1 : 0,
      last_touch: i === marketingEvents.length-1 ? 1 : 0,
    }))
  }, [marketingEvents])

  const cols = useMemo(() => {
    if (!data) return null
    const firstRow = data[0]
    return DEFAULT_COLUMNS.filter(col => col.key in firstRow)
  }, [data])

  return (
    <ContentCard
      hasTable={!!marketingEvents}
    >
      {!marketingEvents ? <GridLoader /> :
        <IndexGridNew
          title="Marketing Touchpoints"
          selectable
          {...{ data, cols }}
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        />
      }
    </ContentCard>
  )
}

export default MarketingTouchpoints
