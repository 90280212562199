import React from 'react';
import { Modal } from 'semantic-ui-react';
import ScheduleReportForm from '../../ScheduleReportForm';
import { CloseButtonStyles } from '../../styled';

export default (props) => (
  <Modal size="small" open={props.open} onClose={props.close}>
    <Modal.Header><CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>Schedule Your Report</Modal.Header>
    <Modal.Content>
      <Modal.Description>
         <ScheduleReportForm done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
)
