import React from 'react';

export const calculateMeans = (data) => {
    const initialSums = {
      cpa: 0,
      roas: 0,
      cpm: 0,
      cpc: 0,
      ctr: 0
    };
  
    const totalSums = data.reduce((sums, item) => {
      return {
        cpa: sums.cpa + item.cpa,
        roas: sums.roas + item.roas,
        cpm: sums.cpm + item.cpm,
        cpc: sums.cpc + item.cpc,
        ctr: sums.ctr + item.ctr
      };
    }, initialSums);
  
    return {
      cpa: totalSums.cpa / data.length,
      roas: totalSums.roas / data.length,
      cpm: totalSums.cpm / data.length,
      cpc: totalSums.cpc / data.length,
      ctr: totalSums.ctr / data.length
    };
}

export const formatMetrics = (metrics) => {
    const formattedMetrics = {};
  
    for (const key in metrics) {
      if (key === 'roas') {
        formattedMetrics[key] = parseFloat(metrics[key].toFixed(2));
      } 
      else if (key === 'ctr') {
        formattedMetrics[key] = `${(metrics[key] * 100).toFixed(2)}%`;
      }
      else {
        formattedMetrics[key] = `$${metrics[key].toFixed(2)}`;
      }
    }
  
    return formattedMetrics;
};
  
export const CustomLegend = ({ activeIndex, onClick, data, activeMetrics }) => {
      const means = formatMetrics(calculateMeans(data));
      const tabs = Object.keys(means).filter((key) => activeMetrics.includes(key));
      return (
        <div className="legend">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${index === activeIndex ? 'active' : ''}`}
              onClick={() => onClick(index)}
              style={{
                left: `${
                  index === activeIndex
                    ? `${index * 190}px`
                    : index > activeIndex
                    ? '188px'
                    : index < activeIndex
                    ? '-2px' 
                    : '0px'
                }`
              }}
            >
              <p className="tab-name">{tab.toUpperCase()}</p>
              <p className="tab-value">{means[tab]}</p>
            </div>
          ))}
        </div>
      );
};
