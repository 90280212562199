import React, { useMemo } from 'react'
import { Menu, Popup } from 'semantic-ui-react'
import { QuestionMarkCircleIcon, ExternalLinkIcon } from '@heroicons/react/solid'

import { HELP_MENU } from './constants'

const HelpMenu = ({ artifacts }) => {
  const [opened, setOpened] = React.useState(false)

  const intercomSurveyId = useMemo(() => {
    if (!artifacts) return null
    const { intercom_surveys } = artifacts || {}
    const survey = intercom_surveys?.general_feedback || {}
    const intercomEnv = window.env === 'prod' ? 'prod' : 'dev'
    const surveyId = survey[intercomEnv]
    return surveyId
  }, [artifacts])

  return (
    <Popup
      transition={{ animation: 'fade down', duration: 500 }}
      trigger={
        <Menu.Item
          className='icon-button'
          content={<QuestionMarkCircleIcon />}
          active={!!opened}
        />
      }
      basic
      on='click'
      position='bottom right'
      open={!!opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className='nav-dropdown-menu'
      content={HELP_MENU.map(({ icon, name, description, external, onClick }) => (
        <div
          key={name}
          className='item'
          onClick={() => {
            onClick({ intercomSurveyId })
            setOpened(false) 
          }}
        >
          <div className='icon help'>{icon}</div>
          <div className='text'>
            <div className='name'>
              {name}
              {!!external && <ExternalLinkIcon className='link-icon' />}
            </div>
            <div className='description'>{description}</div>
          </div>
        </div>
      ))}
    />
  )
}

export default HelpMenu
