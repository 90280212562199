import React, { useState, useEffect } from 'react';
import { Form, Icon, Table, Button } from 'semantic-ui-react'
import { ContentCard, IndexGrid, FullscreenModal } from '@rockerbox/styleguide'
import { getAuthorizations, getSpendPlatforms, deletePlatformAccount } from '../../../utils/api.js'
import CacheContext from '../../../utils/CacheContext.js'
import EmptyFeature from '../../Empty';
import { ConfirmDeleteModal } from './parts'
import NonOAuthForm from './NonOAuth';
import { AccountCell } from './OAuth';
import OAUTH_PLATFORMS from './OAuthPlatforms'
import RedditAuth from './RedditAuth.js';

const StatusCell = ({ item, col }) => {
  const contents = item[col.key] == 1 ?
    <Icon color="green" name='circle check' />:
    <Icon color="orange" name='warning sign' />;

  return (<Table.Cell>{contents}</Table.Cell>)
}

const ReauthCell = (reauthAccount) => ({ item, col }) => {
  const contents = item[col.key] != 1 ?
    <Button as='a' size="small" icon="redo" color="green" onClick={() => reauthAccount(item)} />
    : null;
  return (<Table.Cell>{contents}</Table.Cell>)
}


const AuthorizationsIndex = ({ authorizations, activeFeature, showPlatform }) => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const [ platforms, setPlatforms ] = useState([]);
  const [ platform, setPlatform ] = useState(null);
  const [ rowId, setRowId ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ showModal, setShowModal ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ showForm, setShowForm] = React.useState(false);

  useEffect(() => {
    getSpendPlatforms()
      .then(data => {
        const platformData = data.reduce((list, p,i) => {
          if (p.type != 'spend') return list;
          list.push({
            key: i,
            value: p.platform,
            text: p.display_name,
            logo_url: p.logo_url,
            deprecated: p.deprecated
          })
          return list
        },[])
        setPlatforms(platformData);
      });
  }, []);

  useEffect(() => {
    if ((authorizations !== false) && (platforms.length > 0)) {
      setLoading(false)
    }
  }, [authorizations && authorizations.length, platforms.length])

  const reauthAccount = (i) => {
    if (i.platform === 'shopify') {
      return window.location = `/auth/shopify?shop=${i.account_id}.myshopify.com`;
    }
    if (OAUTH_PLATFORMS.includes(i.platform)) {
      return window.location = `/auth/${i.platform}?account_id=${i.account_id}`;
    }
    setPlatform(i['platform']);
    setRowId(i['id'])
    setShowModal(true);
  }

  const deleteAccount = () => {
    setModalLoading(true);
    deletePlatformAccount(platform, rowId).then(success => {
      setLoading(true);
      setShowDeleteModal(false);
      setModalLoading(false);
      setRowId(null);
      setState({"authorizations": undefined});
    })
  }

  const openDeleteModal = (platform, rowId) => {
    setPlatform(platform);
    setRowId(rowId);
    setShowDeleteModal(true);
  }

  const onSubmit = () => {
    setShowModal(false);
    setState({"authorizations": undefined});
  }

  const DeleteCell = ({ item, col }) => {
    const contents = item[col.key] != 1 ?
      <Button as='a' size="small" icon="trash alternate outline" color="red" onClick={() => openDeleteModal(item['platform'], item['id'])} />
      : null;

    return (<Table.Cell>{contents}</Table.Cell>)
  }

  // HACK for shopify for now
  const cols = [
    {display: '', key: 'status', headerWidth: 0, as: StatusCell, excludePlatforms: []},
    {display: 'Account Name', headerWidth: 8, key: 'account_name'},
    {display: 'Account ID', key: 'account_id', headerWidth: 4, as: AccountCell},
    {display: 'Re-Authenticate', key: 'status', headerWidth: 2, as: ReauthCell(reauthAccount), excludePlatforms: []},
    {display: 'Delete Account', key: 'account_id', headerWidth: 2, as: DeleteCell, excludePlatforms: ["shopify"]}
  ].filter(({ excludePlatforms }) => {
    return !(excludePlatforms||[]).includes(showPlatform)
  })


  if (loading) return <ContentCard.CardLoader />;

  const eligibleAuths = showPlatform ?
    authorizations.filter(({ platform }) => platform == showPlatform) :
    [];

  const { path } = activeFeature;

  const AddAuth = React.memo(() => {
    if (path) return <Button as='a' href={ path } >Add Authorization</Button>
    return <>
      { !showForm && <Button as='a' onClick={() => setShowForm(true)} >Add Authorization</Button> }
      { showForm && <NonOAuthForm {...{ platform: showPlatform }} /> }
    </>
  }, [path])

  const isEmpty = !showForm && showPlatform && !eligibleAuths.length
  if (isEmpty && !path) setShowForm(true) // show form by default when opening integrations that require form input + auth
  const emptyMessage = `You currently have no authorization for ${showPlatform}`
  const canAddAuth = (showForm || (showPlatform && !!eligibleAuths.length))

  return (
    <React.Fragment>
      { isEmpty && <EmptyFeature message={emptyMessage} callToAction={<AddAuth />} /> }
      { !isEmpty && !showForm &&
        <ContentCard hasTable>
          { eligibleAuths.map((a) => { return <IndexGrid data={a.authorizations} cols={cols} /> }) }
        </ContentCard>
      }
      { canAddAuth && <AddAuth />}
      {/* HACK for reddit -- comment out for now */}
      {/* { activeFeature.platform === "reddit" && <RedditAuth eligibleAuths={eligibleAuths} /> } */}
      <FullscreenModal
        onClose={() => setShowModal(false)}
        open={showModal}
        headerContent="Re-enter your account information"
      >
        <NonOAuthForm {...{ platform, rowId }} />
      </FullscreenModal>
      <ConfirmDeleteModal
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        title="Are you sure?"
        {...{deleteAccount, loading: modalLoading, activePlatform: platform, activeAccountRowId: rowId}}
      />
    </React.Fragment>
  )
}

export default AuthorizationsIndex;
