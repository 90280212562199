export function getUserData(filter_id, base_id) {
  const url = `/has/user?base_id=${base_id}&filter_id=${filter_id}`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
}

export function getCreativeData(filter_id, start_date, end_date) {
  const url = `/has/creative/data?keys=response&identifier=${filter_id}&start_date=${start_date}&end_date=${end_date}`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getCreativeDataByTiers(tier_one, tier_two, tier_three, tier_four, tier_five, start_date, end_date) {
  const url = `/has/creative/data?keys=response&tier_one=${tier_one}&tier_two=${tier_two}&tier_three=${tier_three}&tier_four=${tier_four}&tier_five=${tier_five}&start_date=${start_date}&end_date=${end_date}`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}
