import * as aq from "arquero";

export const convertCurrencyByDate = (dataTable, rates, columns) => {
    if (!columns) columns = ['spend']
    const rateTable = aq.table({'date': rates.map(d => d['date']), 'exchange_rate': rates.map(d => d['exchange_rate'])});
    const joined = dataTable.join_left(rateTable, ['date'])
    const aggs = {}
    const renames = {}
    columns.forEach(col => {
        aggs[`${col}ConvertedSpend`] = aq.escape(d => d[`${col}`] / d['exchange_rate'])
        renames[col] = `${col}UnconvertedSpend`
        renames[`${col}ConvertedSpend`] = col
    })
    const derived = joined.derive(aggs)
    const updated = derived.rename(renames)
    return updated
}
