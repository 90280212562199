import React from "react"
import styled from "styled-components"
import { Grid, Progress } from "semantic-ui-react"
import { blendColors, calcDiff } from "./helpers"
import tinycolor from 'tinycolor2';
import { formatCurrency } from "../../../utils/valueFormatter";

const StyledProgressBar = styled(Progress)`
  > .bar {
    background-color: ${props => props.color || 'gray'} !important;
    height: 35px !important;
    opacity: 0.3 !important;
  }

  > .label {
    color: ${props => tinycolor(props.color).darken(15).toString() || '#000'} !important;
    position: absolute;
    top: 0 !important;
    left: 13px !important;
    margin-top: 6px !important;
    background: transparent;
    text-align: left !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: 'Poppins', sans-serif;
  }
`

const DescriptionText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;

  strong {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
`

const KeyInsightsChart = ({value, total, color, display, hasRevenue}) => {
  return (
    <Grid.Row style={{ paddingTop: 0, paddingLeft: 1 }}>
      <Grid.Column width={13}>
        <StyledProgressBar
          style={{ margin: '2px 0px', background: 'rgba(0,0,0,0)' }}
          value={value}
          total={total}
          color={color}
          label={display}
        />
      </Grid.Column>
      <Grid.Column width={3} style={{ paddingTop: 8, fontSize: 15 }} textAlign='right'>
        { !!hasRevenue && formatCurrency(Math.abs(value)) }
        { !hasRevenue && value.toLocaleString() }
      </Grid.Column>
    </Grid.Row>
  )
}

const KeyInsights = ({ platformDisplayName, platformColor, data, hasRevenue }) => {

  const {avg_rev_overlap, avg_rev_channel, avg_rev_platform, avg_rev_change_rate } = data // revenue metrics
  const {count_channel, count_overlap, count_platform} = data // conversion metrics
  const {channel, color} = data // other
  
  const changeRateFormatter = (value) => (`${Math.ceil(Math.abs(value * 100))}% ${value > 0 ? 'more' : 'less'}`)
  const total = hasRevenue ? Math.max(avg_rev_channel, avg_rev_overlap, avg_rev_platform) : Math.max(count_channel, count_overlap, count_platform)
  const revPlatformChange = changeRateFormatter(avg_rev_change_rate)
  const revChannelChange = changeRateFormatter(calcDiff(avg_rev_overlap, avg_rev_channel)[1])
  const channelChange = changeRateFormatter(calcDiff(count_overlap, count_channel)[1])
  const platformChange = changeRateFormatter(calcDiff(count_overlap, count_platform)[1])
  
  return (
    <div style={{ paddingTop: 20, fontSize: '16px', lineHeight: '24px', textAlign: 'left' }}>
      {!!hasRevenue && avg_rev_change_rate !== Infinity && <DescriptionText>On average, users who interacted with both <strong>{platformDisplayName} Ads</strong> and <strong>{channel}</strong> generated <strong>{revPlatformChange} revenue</strong> than {platformDisplayName} Ads only.</DescriptionText>}
      {!!hasRevenue && avg_rev_change_rate === Infinity && <DescriptionText>On average, users who interacted with {platformDisplayName} Ads only generated <strong>{revChannelChange} revenue</strong> than {channel} only.</DescriptionText> }
      { !hasRevenue && <DescriptionText>On average, users who interacted with both <strong>{platformDisplayName} Ads</strong> and <strong>{channel}</strong> generated <strong>{platformChange} conversions</strong> than {platformDisplayName} Ads only.</DescriptionText> }
      <Grid style={{ paddingTop: 25, marginTop: 0 }}>
        <KeyInsightsChart value={hasRevenue ? avg_rev_platform : count_platform} total={total} color={platformColor} display={platformDisplayName} hasRevenue={hasRevenue} />
        <KeyInsightsChart value={hasRevenue ? avg_rev_channel : count_channel } total={total} color={color} display={channel} hasRevenue={hasRevenue} />
        <KeyInsightsChart value={hasRevenue ? avg_rev_overlap : count_overlap} total={total} color={blendColors(platformColor, color, 0.5)} display={`${platformDisplayName} + ${channel}`} hasRevenue={hasRevenue} />
      </Grid>
    </div>
  )
}

export default KeyInsights
