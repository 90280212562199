import React, { useCallback, useEffect } from "react";
import moment from "moment";
import CacheContext from "../../utils/CacheContext.js";
import FindMarketingEvent from "../Entities/FindMarketingEvent";
import { Form, Button, Icon, Popup, Message } from "semantic-ui-react";
import { ContentCard, DataTable, SplitLayout } from "@rockerbox/styleguide";
import { Link } from "react-router-dom";
import { experimentStore } from "./store";
import { getSegments } from "../../utils/api";
import { customHistory } from "../pushpath";
import { parseValueToTiers } from "./helper";
import { dataSheetColumns, controlTiers } from "./constants";
import SingleDatePicker from "./SingleDatePicker";

const experimentsSelector = state => [
  state.getExperiments,
  state.newExperiment,
  state.setNewExperiment,
  state.updateExperimentObj,
  state.selectTreeOpen,
  state.setSelectTreeOpen,
  state.resetNewExperiment,
  state.setupError,
  state.setSetupError,
  state.apiRequestLoading
];

const ExperimentEditForm = props => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const { activeExperiment } = props;
  const [
    getExperiments,
    newExperiment,
    setNewExperiment,
    updateExperimentObj,
    selectTreeOpen,
    setSelectTreeOpen,
    resetNewExperiment,
    setupError,
    setSetupError,
    apiRequestLoading
  ] = experimentStore(experimentsSelector);

  const updateExperiment = Object.assign({}, activeExperiment, newExperiment);

  const segments = Context.getCache("segments", getSegments) || [];
  const { updatePath } = customHistory(props);

  const defaultSegmentId = segments.filter(segment => segment.featured).length
    ? segments.filter(segment => segment.featured)[0]["filter_id"]
    : null;
  const segment = React.useMemo(
    () => ({ segment_id: updateExperiment["filter_id"] }),
    [updateExperiment["filter_id"]]
  );

  useEffect(() => {
    setSetupError("");
    setSelectTreeOpen("finished");
    setNewExperiment("start_date", moment(activeExperiment["start_date"]));
  }, []);

  const nameCallback = useCallback((e, data) =>
    setNewExperiment("name", data.value)
  );
  const startDateCallback = useCallback(date =>
    setNewExperiment("start_date", date)
  );
  const onSelectorClick = useCallback((e, data) => {
    setSelectTreeOpen("open");
  });

  const onEventSelection = useCallback((e, data) => {
    const tiers = parseValueToTiers(data);
    const maxTier = Object.keys(tiers)[Object.keys(tiers).length - 1];

    if (maxTier.indexOf(activeExperiment["data_tier"]) === -1) {
      return setSetupError("Baseline max tier must remain same.");
    } else {
      setSetupError("");
    }

    const resetFields = controlTiers;

    resetFields.forEach(field => {
      setNewExperiment(field, null);
    });

    Object.entries(tiers).forEach(([key, value]) =>
      setNewExperiment(key, value)
    );
    setNewExperiment(
      "data_tier",
      maxTier
        .split("_")
        .slice(1)
        .join("_")
    );
    setSelectTreeOpen("finished");
    e.stopPropagation();
  });

  const onCancel = useCallback(() => {
    setSelectTreeOpen("pending");
    resetNewExperiment();
    updatePath({ view: "view" });
  });

  const onSave = useCallback(() => {
    const requestBody = Object.assign({}, activeExperiment, newExperiment);
    requestBody["start_date"] = requestBody["start_date"].format("YYYY-MM-DD");
    updateExperimentObj(requestBody, activeExperiment["id"])
      .then(() => getExperiments())
      .finally(() => updatePath({ view: "index" }));
  });

  const onRemove = useCallback(() => {
    resetNewExperiment(controlTiers);
    setSelectTreeOpen("pending");
  });

  const emptyHandler = useCallback(() => {});

  return (
    <ContentCard
      title="Edit Experiment"
      loading={apiRequestLoading}
      topRight={<Link onClick={onCancel}>{`< back`}</Link>}>
      {setupError && <Message error header={setupError} />}
      <Form>
        <Form.Group>
          <Form.Field width={8}>
            <Form.Input
              value={updateExperiment["name"]}
              label="Experiment Name"
              onChange={nameCallback}
              id="experimentName"
            />
          </Form.Field>
          <Form.Field width={8} className="custom-react-dates">
            <label>Start Date</label>
            <SingleDatePicker
              startDate={
                moment.isMoment(updateExperiment["start_date"])
                  ? updateExperiment["start_date"]
                  : moment(updateExperiment["start_date"])
              }
              startDateCallback={startDateCallback}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Form.Dropdown
              value={updateExperiment["filter_id"]}
              options={[
                {
                  text: updateExperiment["filter_id"],
                  value: updateExperiment["filter_id"],
                  key: updateExperiment["filter_id"]
                }
              ]}
              disabled
              label="Conversion Segment"
              onChange={emptyHandler}
              fluid
              selection
              id="experimentSegment"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Form.Dropdown
              value={updateExperiment["platform"]}
              options={[
                {
                  text: updateExperiment["platform"],
                  value: updateExperiment["platform"],
                  key: updateExperiment["platform"]
                }
              ]}
              disabled
              label="Platform"
              onChange={emptyHandler}
              fluid
              selection
              id="experimentPlatform"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <label>
              Set Baseline{" "}
              <Popup
                position="right center"
                content="Pick a baseline marketing campaign/ad that you know performs well. In the next step, you will choose other marketing that will then be compared to this baseline."
                trigger={
                  <Icon name="question circle" style={{ color: "#CACBCD" }} />
                }
              />
            </label>
            {selectTreeOpen === "open" ? (
              <FindMarketingEvent
                segment={segment}
                onChange={onEventSelection}
                controlColumnName={"Select"}
                platformFilter={row =>
                  typeof row.platform === 'string' && row.platform.includes(updateExperiment["platform"])
                }
                daysAgo={7}
              />
            ) : selectTreeOpen === "finished" ? (
              <SplitLayout
                leftWidth={14}
                rightWidth={2}
                leftContent={
                  <DataTable
                    columns={dataSheetColumns}
                    data={[updateExperiment]}
                    onUpdate={emptyHandler}
                    onRemove={false}
                  />
                }
                rightContent={<Button onClick={onSelectorClick}>Change</Button>}
              />
            ) : (
              <Button
                basic
                fluid
                onClick={onSelectorClick}
                style={{ color: "!#026CAC", backgroundColor: "white" }}>
                <Icon name="search" color="blue" />
                Select Marketing Event
              </Button>
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16}></Form.Field>
        </Form.Group>
      </Form>
      <Button onClick={onCancel}>Cancel</Button>
      <Button
        primary
        style={{ float: "right" }}
        onClick={onSave}
        disabled={newExperiment["name"] === ""}
        id="experimentSave"
      >
        Save
      </Button>
    </ContentCard>
  );
};

export default ExperimentEditForm;
