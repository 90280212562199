import _ from 'lodash'

export function getMMMRuns() {
  const url = `/data_model_run/mmm`
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => data?.data)
}

export function getMMMData(id) {
  const url = `/data_model_run/mmm/${id}`
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(({ data }) => {
    const features = _.uniq(data.roi_mean.filter(x => x.roi_mean > 0).map(x => x.rn)).sort()

    const predVsActual = _.uniqBy(data.pred_decomp_vs_actual, 'DATE')
      .map(x => ({
        date: x.DATE,
        pred: x.depVarHat,
        actual: x.dep_var,
        delta: Math.abs(x.dep_var - x.depVarHat),
      }))

    const metrics = data.metrics.slice(-1)[0]

    const trainingDataObj = data.training_data.reduce((acc, cur) => {
      const { DATE, media, media_value } = cur
      if (!acc[DATE]) acc[DATE] = { date: DATE }
      acc[DATE][media] = media_value
      return acc
    }, {})
    const trainingDates = Object.keys(trainingDataObj)
    const trainingData = Object.values(trainingDataObj)

    const trainingMinMax = trainingData.reduce((acc, row) => {
      Object.keys(row).forEach(media => {
        if (media === 'date' || !row[media]) return
        if (!acc[media]) acc[media] = { min: Infinity, max: 0 }
        if (row[media] < acc[media].min) acc[media].min = row[media]
        if (row[media] > acc[media].max) acc[media].max = row[media]
      })
      return acc
    }, {})
    const trainingTotals = trainingData.slice(-30).reduce((acc, row) => {
      Object.keys(row).forEach(media => {
        if (media === 'date') return
        if (!acc[media]) acc[media] = 0
        acc[media] += row[media]
      })
      return acc
    }, {})
    const trainingStats = Object.keys(trainingTotals).map(media => {
      const spend = trainingTotals[media]
      const num_spend_days = trainingData.filter(x => x[media] > 0).length
      const mean = !!num_spend_days ? spend/30 : 0
      const { min, max } = trainingMinMax[media] || {}
      return {
        feature: media,
        spend_30d: spend || 0,
        spend_mean: mean || 0,
        spend_min: min || 0,
        spend_max: max || 0,
      }
    })

    const seasonality = data.seasonality_holidays.map(x => {
      const day = {
        date: x.DATE,
        sum: 0,
      }
      Object.entries(x).forEach(([key, value]) => {
        if (key === 'DATE') return
        day[key] = value
        day.sum += value
      })
      return day
    })

    const correlationData = data.correlation_matrix;

    return {
      features,
      correlationData,
      predVsActual,
      metrics,
      trainingDates,
      trainingData,
      trainingStats,
      seasonality,
      raw: data,
    }
  })
}
