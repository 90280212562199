export function submitAttributionRunJob(path, data) {
  const url = `/run/${path}`;

  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response)
}

export function checkJobStatus(submissionId) {
  const url = `/run/check/job?job_id=${submissionId}`
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(data => data.response)
}

export function checkJobWithToken(token) {
  const url = `/run/check/job/token?token=${token}`
  return fetch(url)
    .then(response => response.json())
    .then(data => data.response)
}
