import React, {Component} from 'react';
import { connect } from 'react-redux';
import ButterToast from 'butter-toast';
import { ToastStyles, CloseButtonStyles } from '../Toast';
import styled from 'styled-components';
import { ContentCard } from '@rockerbox/styleguide';
import { Grid, Icon, Message, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import ConversionForm from './form';
import { getSegmentNames, createConversion, updateConversion, getConversionSegments, getLookupTables } from '../../utils/api';
import moment from 'moment';
import _ from 'lodash';
import { reorder } from './dndHelpers';
import { track } from '../../utils/tracking';

const ConversionFormWrapper = props =>  {
  return (
    <ContentCard
      title={props.id ? 'Edit conversion event' : 'Create new conversion event'}
      topRight={(
      <Link to={routes.conversionEventIndex}>
        <Icon name='angle left' />
        Back to Conversions
      </Link>
      )}
    >
    <Grid fluid='true' columns='equal'>
      <Grid.Row>
        <Grid.Column width={12}>
          {props.children }
        </Grid.Column>
        <Grid.Column width={4}>
          <Message
            header={'What\'s a conversion event?'}
            content='A Conversion Event identifies when your customer takes an important action. You can specify one (e.g. Purchased Product) or many (e.g. Request Demo, Created Account, Signed Contract).' />

        </Grid.Column>
      </Grid.Row>
    </Grid>
  </ContentCard>
  );
}

class ConversionSegment extends Component {
  state = {
    conversionName: '',
    methodOption: '',
    event: '',
    events: '',
    conversionKeyName: 'order_id',
    pixelEventOptions: '',
    apiEventOptions: '',
    newToFile: 0,
    segmentNewToFile: 0,
    expectedFrequency: 1,
    minThreshold: 0,
    revenue: 0,
    displayOrder: 0,
    ntfKeyword: '',
    featured: 0,
    hasLtv: 0,
    crossDayDedupe: 0,
    firstReportingDate: '',
    lookupSelect: '',
    lookupItems: [],
    eventExclusionTags: [],
    spendExclusionTags: [],
    lookupOptions: [],
    childSegments: [],
    transformation: "",
    lookupObj: [],
    adminOnly: 0,
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id != prevProps.match.params.id) {
      this.componentDidMount() // HACK: for child segments
    }
  }

  refreshEdit = () => {
    const { id } = this.props.match.params;
    getConversionSegments()
      .then(data => {
        const segment = data.filter(c => c.action_id == id)[0];
        const conversionSourceId = segment.conversion_data_source_id
        const methodOption = (conversionSourceId === 1 || conversionSourceId === 6) ? 'pixel' : conversionSourceId === 5 ? 'server' : 'batch';


        let lookupItems = [];
        if(segment.lookup_sequence.length > 0) {
          lookupItems = segment.lookup_sequence.map((c, i) => {
            c.drag_id = `${i}`;
            return c;
          });
        }
        const { children } = segment;
        const childSegments = children.map(id => data.find(seg => seg.action_id == id))

        this.setState({
          childSegments,
          conversionName: segment.action_name,
          methodOption,
          event: segment.url_pattern[0],
          events: segment.url_pattern,
          isChild: segment.is_child,
          conversionKeyName: segment.conversion_key_name,
          transformation: JSON.stringify(segment.transformation,false,2),
          newToFile: segment.has_new_to_file,
          segmentNewToFile: segment.segment_ntf,
          expectedFrequency: segment.expected_days_until_data,
          minThreshold: segment.expected_threshold,
          revenue: segment.include_revenue,
          ntfKeyword: segment.new_to_file_key,
          displayOrder: segment.display_order,
          crossDayDedupe: segment.cross_day_dedupe,
          eventExclusionTags: segment.event_tags || [],
          spendExclusionTags: segment.spend_tags || [],
          hasLtv: segment.has_ltv,
          firstReportingDate: segment.first_reporting_date == 0 ? '' : moment(segment.first_reporting_date),
          lookupItems,
          adminOnly: segment.admin_only,
        });
      })

  }

  componentDidMount() {
    const { id } = this.props.match.params;
    getLookupTables()
      .then(data => {
        const lookupOptions = _.sortBy(data.map(c => {return {text: c.table_name, value: c.table_name}}), ["text"]);

        const lookupObj = data.reduce((obj, item) => {
          obj[item.table_name] = {id: item.id}
          return obj
        }, {})

        this.setState({lookupOptions, lookupObj})
      })


    getSegmentNames("v2")
      .then(data => {
        const apiEventOptions= data.map(event => {
            return { key: event.action, text: event.action, value: event.action}
          });

        this.setState({ apiEventOptions })
      })

    getSegmentNames("v1")
      .then(data => {
        const pixelEventOptions = data.map(event => {
            return { key: event.segment, text: event.segment, value: event.pattern}
          });

        this.setState({pixelEventOptions})
      })

    if (id) this.refreshEdit()

  }

  handleLookupRemove = (dragId) => {
    const { lookupItems } = this.state;
    const itemsClone = _.cloneDeep(lookupItems)
    const updatedItems = itemsClone.filter(c => c.drag_id != dragId)
    this.setState({lookupItems: updatedItems});
  }

  handleLookupSelect = (value, dragId) => {
    const { lookupItems } = this.state;
    const itemsClone = _.cloneDeep(lookupItems)
    const updatedItems = itemsClone.map(c => {
      if (c.drag_id == dragId) c.table_name = value
      return c;
    })
    this.setState({lookupItems: updatedItems});
  }

  handleSetSpendExclusion = (pos) => (bleh, evt) => {
    const { spendExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(spendExclusionTags)
    exclusionClone[pos]['name'] = evt.value

    this.setState({spendExclusionTags: exclusionClone})
  }

  handleRemoveSpendExclusion = (pos) => () => {
    const { spendExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(spendExclusionTags)
    exclusionClone.splice(pos,1)

    this.setState({spendExclusionTags: exclusionClone})
  }

  handleAddSpendExclusion = () => {
    const { spendExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(spendExclusionTags)
    exclusionClone.push({name: ''})

    this.setState({spendExclusionTags: exclusionClone})
  }

  handleSetEventExclusion = (pos) => (bleh, evt) => {
    const { eventExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(eventExclusionTags)
    exclusionClone[pos]['name'] = evt.value

    this.setState({eventExclusionTags: exclusionClone})
  }

  handleRemoveEventExclusion = (pos) => () => {
    const { eventExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(eventExclusionTags)
    exclusionClone.splice(pos,1)

    this.setState({eventExclusionTags: exclusionClone})
  }

  handleAddEventExclusion = () => {
    const { eventExclusionTags } = this.state;
    const exclusionClone = _.cloneDeep(eventExclusionTags)
    exclusionClone.push({name: ''})

    this.setState({eventExclusionTags: exclusionClone})
  }

  handleAddLookup = () => {
    const { lookupItems } = this.state;
    const lookupClone = _.cloneDeep(lookupItems)
    lookupClone.push({table_name: '', drag_id: `${lookupItems.length}` })

    this.setState({lookupItems: lookupClone})
  }

  handleChange = ({value}, key) => {
    if(key === 'pixel' || key === 'server' || key === 'batch') {
      this.setState({methodOption: key, event: ''})
    } else {
      this.setState({[key]: value});
    }
  }

  handleDateChange = (e, key) => {
    this.setState({[key]: e})
  }

  handleCheckbox = (stateSlice) => {
    this.setState({[stateSlice]: this.state[stateSlice] === 1 ? 0 : 1})
  }

  prepareSubmit = () => {
    const { advertiser } = this.props;
    const { id } = this.props.match.params;
    const { methodOption, firstReportingDate, lookupItems, lookupObj } = this.state;
    const today = moment.utc().utcOffset(-5).format('YYYY-MM-DD');
    const reportingDate = (id === undefined) ?
       today : (id && firstReportingDate !== '') ?
       moment(firstReportingDate).format('YYYY-MM-DD') : '';

    const lookup_sequence = lookupItems.map(c => {return lookupObj[c.table_name]}).filter(c => c)

    return {
      advertiser,
      action_name: this.state.conversionName,
      url_pattern: this.state.events,
      conversion_key_name: this.state.conversionKeyName,
      conversion_data_source_id: methodOption === 'server' ? 5 : methodOption ==='batch' ? 8 : 6,
      has_new_to_file: this.state.newToFile,
      segment_ntf: this.state.segmentNewToFile,
      expected_days_until_data: this.state.expectedFrequency,
      expected_threshold: this.state.minThreshold,
      include_revenue: this.state.revenue,
      new_to_file_key: this.state.ntfKeyword,
      has_ltv: this.state.hasLtv,
      display_order: this.state.displayOrder,
      cross_day_dedupe: this.state.crossDayDedupe,
      event_tags: this.state.eventExclusionTags,
      spend_tags: this.state.spendExclusionTags,
      first_reporting_date: reportingDate,
      transformation: this.state.transformation.length > 2 ? JSON.parse(this.state.transformation) : undefined,
      lookup_sequence,
      admin_only: this.state.adminOnly,
    }

  }

  handleSubmit = () => {
    const { id } = this.props.match.params;
    const data = this.prepareSubmit()

    if (id) {
      data.action_id = id;
      data.identifier = id;
    }

    const send = id ? updateConversion : createConversion;
    const trackingEventName = id ? 'conversion-edit' : 'conversion-create';

    send(data)
      .then((resp) => resp.response)
      .then((resp) => {
        if (resp.error) {
          throw Error(resp.error);
        }
        return true;
      })
      .then((isSuccess) => {
        if (isSuccess) {
          ButterToast.raise({
            dismissOnClick: true,
            content: ({ toastId, dismiss }) => (
              <ToastStyles>
                <CloseButtonStyles>x</CloseButtonStyles>
                Your changes were successfully saved.  Nice!
              </ToastStyles>
            ),
            toastTimeout: 5000
          })
          track(trackingEventName, data);
          setTimeout(() => this.props.history.push(routes.conversionEventIndex), 0);
          return;
        }
      })
      .catch((err) => {
        console.log('error! ', err);
        ButterToast.raise({
          dismissOnClick: true,
          content: ({ toastId, dismiss }) => (
            <ToastStyles>
              <CloseButtonStyles>x</CloseButtonStyles>
              {`There was an error with your submission. ${err}`}
            </ToastStyles>
          ),
          toastTimeout: 5000,
        });
      });
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const lookupItems = reorder(
      this.state.lookupItems,
      result.source.index,
      result.destination.index
    );

    this.setState({
      lookupItems
    });
  }

  render() {
    const { id } = this.props.match.params;
    const { artifacts } = this.props;

    const { handleAddLookup, onDragEnd, handleLookupSelect, handleDateChange,
            handleAddEventExclusion, handleRemoveEventExclusion, handleSetEventExclusion,
            handleAddSpendExclusion, handleRemoveSpendExclusion, handleSetSpendExclusion,
            handleSubmit, handleCheckbox, handleChange, handleLookupRemove,
            prepareSubmit, refreshEdit } = this;

    return(
      <ConversionFormWrapper id={id}>
        <ConversionForm {...this.state}
        {...{
          id, artifacts,
          handleAddEventExclusion, handleRemoveEventExclusion, handleSetEventExclusion,
          handleAddSpendExclusion, handleRemoveSpendExclusion, handleSetSpendExclusion,
          handleAddLookup, onDragEnd, handleLookupSelect, handleDateChange,
          handleSubmit, handleCheckbox, handleChange, handleLookupRemove,
          prepareSubmit, refreshEdit }
        }
      />
      </ConversionFormWrapper>
    )
  }
}

function mapStateToProps(state, props) {
   return {
     advertiser: state.app.user.pixel_source_name,
     artifacts: state.app.artifacts,
   }
}

export default connect(mapStateToProps, null)(ConversionSegment);
