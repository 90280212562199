import React, { useMemo, useCallback } from "react";
import { IndexGridNew, ContentCard } from "@rockerbox/styleguide";
import {
  warehouseStore,
  syncCardStore,
  datasetAccountingStore
} from "./zustandStore";
import { Button } from "semantic-ui-react";
import CacheContext from "../../utils/CacheContext.js";
import { getSegments } from "../../utils/api";
import {
  tableTypeOptions,
  syncTitleMap,
  sycnComponentMap,
  SYNC_CARD_DEFAULT,
  SYNC_CARD_EDIT,
  SYNC_CARD_ADD,
  SYNC_CARD_STATUS,
  SYNC_CARD_SNAPSHOT,
  SYNC_CARD_SNAPSHOT_STATUS,
  SYNC_CARD_SNAPSHOT_CREATION,
  SYNC_CARD_SNAPSHOT_SCHEDULE
} from "./constants";
import SyncCardExpandableDatasets from "./SyncCardExpandableDatasets";

const datasetAccountingSelector = state => [
  state.createNewDataset,
  state.setDatasetId,
  state.deleteDataset,
  state.setSnapshotId
];
const warehouseSelector = state => [
  state.warehouses,
  state.activeWarehouseId,
  state.fetchWarehouse
];
const syncSelector = state => [
  state.activeSegment,
  state.selectSegment,
  state.cardStatus,
  state.updateCardStatus,
  state.newDataset,
  state.setNewDataset,
  state.removeNewDataset,
  state.cardLoading,
  state.setCardLoading,
  state.cardStatusBack
];

// Sync is business object based on segment
const SyncTable = props => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const [warehouses, activeWarehouseId, fetchWarehouse] = warehouseStore(
    warehouseSelector
  );
  const [
    activeSegment,
    selectSegment,
    cardStatus,
    updateCardStatus,
    newDataset,
    setNewDataset,
    removeNewDataset,
    cardLoading,
    setCardLoading,
    cardStatusBack
  ] = syncCardStore(syncSelector);
  const [
    createNewDataset,
    setDatasetId,
    deleteDataset,
    setSnapshotId
  ] = datasetAccountingStore(datasetAccountingSelector);

  const onEditChange = useCallback((e, data) => {
    selectSegment(data.filter_id);
    updateCardStatus(SYNC_CARD_EDIT);
  });
  const onStatusChange = useCallback((e, data) => {
    selectSegment(data.filter_id);
    setDatasetId(data.dataset_id);
    setSnapshotId(null);
    updateCardStatus(SYNC_CARD_STATUS);
  });
  const onSnapshotChange = useCallback((e, data) => {
    selectSegment(data.filter_id);
    setDatasetId(data.dataset_id);
    updateCardStatus(SYNC_CARD_SNAPSHOT);
  });
  const onSnapshotScheduleChange = useCallback((e, data) => {
    selectSegment(data.filter_id);
    setDatasetId(data.dataset_id);
    updateCardStatus(SYNC_CARD_SNAPSHOT_SCHEDULE);
  });
  const onShowChange = useCallback(() => {
    removeNewDataset();
    cardStatusBack();
  });
  const onAddChange = useCallback(() => {
    setNewDataset("dataset", "compiled_mta");
    updateCardStatus(SYNC_CARD_ADD);
  });
  const onAddNew = useCallback(() => setNewDataset("dataset", "compiled_mta"));
  const onDatasetDelete = useCallback((e, data) => {
    setCardLoading(true);
    deleteDataset(data.datasetId)
      .then(() => {
        return fetchWarehouse();
      })
      .finally(() => setCardLoading(false));
  });

  const StatusContent = cardStatus ? sycnComponentMap[cardStatus] : null;

  const keys = [
    { key: "filter_id", display: "Action Id" },
    { key: "action_name", display: "Segment Name" },
    { key: "expand", display: "", as: IndexGridNew.ExpandContractCell }
  ];

  const BackButton = props => {
    return <Button onClick={onShowChange}>Back</Button>;
  };

  const { filteredDatasets = [], filteredSegments = [] } = useMemo(() => {
    const warehouse = warehouses.filter(
      warehouse => warehouse.id === activeWarehouseId
    )[0];
    const datasets =
      warehouse && warehouse["datasets"]
        ? warehouse["datasets"].map(item =>
            Object.assign({}, item, {
              warehouse_table_name: item["warehouse_table_name"] + "_live"
            })
          )
        : [];
    const uniqueDatasetSegment = new Set(
      datasets.map(dataset => dataset.filter_id)
    );
    const filteredSegments = segments
      .filter(segment => uniqueDatasetSegment.has(segment.filter_id))
      .map((segment, i) =>
        Object.assign(segment, {
          sync_id: i,
          datasets: datasets.filter(
            dataset => dataset.filter_id === segment.filter_id
          ),
          onDatasetDelete: onDatasetDelete,
          onStatusChange: onStatusChange,
          onSnapshotChange: onSnapshotChange,
          onSnapshotScheduleChange: onSnapshotScheduleChange
        })
      );
    const filteredDatasets = datasets.filter(
      dataset => dataset.filter_id === activeSegment
    );

    return { filteredDatasets, filteredSegments };
  }, [activeWarehouseId, activeSegment, warehouses]);

  const availableSegments = segments.filter(
    segment => segment.action_type !== "segment"
  );

  return (
    <ContentCard
      loading={cardLoading}
      title={syncTitleMap[cardStatus] || "Sync Management"}
      topRight={cardStatus !== SYNC_CARD_DEFAULT && <BackButton />}>
      {cardStatus === SYNC_CARD_DEFAULT ? (
        <React.Fragment>
          <IndexGridNew
            data={filteredSegments}
            cols={keys}
            itemsPerPage={10}
            fallBackMsg="No Syncs Available"
            collapsed
            paginate
            expandable={true}
            expandedComponent={SyncCardExpandableDatasets}
            idKey="action_name"
          />
          <Button
            icon="plus"
            primary
            style={{ marginTop: "10px" }}
            onClick={onAddChange}>
            Add
          </Button>
        </React.Fragment>
      ) : !!StatusContent ? (
        <StatusContent
          datasets={filteredDatasets}
          segments={availableSegments}
          segment={activeSegment}
          action={cardStatus}
          newDataset={newDataset}
          setNewDataset={setNewDataset}
          onAddNew={onAddNew}
        />
      ) : null}
    </ContentCard>
  );
};

export default SyncTable;
