import { useContext, useEffect } from 'react'
import { CacheContext } from '../../utils/CacheContext'
import { getIntegrations, getAuthorizations } from '../api/account'
import { getPixelValidationStatus } from '../api/attributionPixels'
import { getFacebookAutoParamsErrors } from '../api/attribution'


export const useTrackingParamAlerts = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const advertiserIntegrations = Context.getCache(`advertiserIntegrations`, getIntegrations)
  const authorizations = Context.getCache(`authorizations`, getAuthorizations)
  const { trackingParamAlerts } = state

  useEffect(() => {
    if (!!trackingParamAlerts || !advertiserIntegrations || !authorizations) return
    let allAlerts = {}

    // pixel validation status
    const validateTrackingPlatforms = advertiserIntegrations
      .filter(x => x.feature_type === 'validate_tracking' && x.platform !== 'facebook') // exclude fb, use auto params errors
      .map(x => x.platform)

    const allPixelParamsAlerts = {}
    const promises = validateTrackingPlatforms.map(platform => getPixelValidationStatus(platform))
    Promise.all(promises)
      .then(responses => {
        responses.forEach((res, i) => {
          const { alerts } = res
          const errors = alerts?.errors || []
          const platform = validateTrackingPlatforms[i]

          const mainError = errors.find(x => x?.subplatform === 'all')
          const hasIssues = errors.some(x => x?.severity === 'error')
          const subPlatforms = errors.map(x => x?.subplatform)

          // regex to extract percentage from text description
          const percentMissing = (mainError?.description.match(/(\d+.\d+)%/) || []).slice(1)
          const percentMissingFloat = !!percentMissing.length ? parseFloat(percentMissing[0]) : 0

          const statusObj = {
            hasIssues,
            subPlatforms,
            percentMissing: percentMissingFloat,
          }

          if (hasIssues) allPixelParamsAlerts[platform] = statusObj
        })
      })
      .then(() => {
        allAlerts = {...allAlerts, ...allPixelParamsAlerts}
        setState({ trackingParamAlerts: {...allAlerts} })
      })

    // fb auto params errors
    if (!authorizations?.length) return
    const fbAuths = authorizations.find(x => x.platform === 'facebook')?.authorizations
    if (!fbAuths) return

    const fbAccountIds = fbAuths.map(x => x.account_id)
    const fbPromises = fbAccountIds.map(accountId => getFacebookAutoParamsErrors(accountId))
    Promise.all(fbPromises)
      .then(responses => {
        const allFbErrors = responses.flat().map(x => ({
          error: x.error_title,
          account_id: x.account_id,
          campaign_id: x.campaign_id,
          ad_id: x.ad_id,
        }))
        return allFbErrors
      })
      .then(allFbErrors => {
        if (!allFbErrors.length) return
        allAlerts['facebook'] = {
          hasIssues: true,
          subPlatforms: ['all'],
          numMissing: allFbErrors.length,
          errors: allFbErrors,
        }
        setState({ trackingParamAlerts: {...allAlerts} })
      })
  }, [advertiserIntegrations, authorizations])

  return trackingParamAlerts
}
