export const headers = {
  "percent": {
    "justify": "center",
    "big":" ",
    "small":"%",
    "flex":0.25,
    "sortable":true
  },
  "group": {
    "rowStyle": {fontSize:"1.3em"},
    "big":"Buckets",
    "small":"",
    "flex":2,
    "sortable":true
  },
  "total": {
    "big":"Conversion",
    "small":"yesterday",
    "sortable":true
  },
  "rev_total": {
    "big":"REVENUE",
    "small":"yesterday",
    "sortable":true
  },
  "rpa": {
    "big":"RPA",
    "small":"yesterday",
    "explainer": "Average Revenue Per Conversion",
    "sortable": true
  },
  "spend_total": {
    "big":"Spend",
    "small":"yesterday",
    "sortable": true
  },
  "cpa": {
    "big":"CPA",
    "small":"yesterday",
    "explainer": "Average Spend per Conversion",
    "sortable": true
  },
  "roas": {
    "big":"ROAS",
    "small":"yesterday",
    "explainer": "Return on Ad Spend",
    "sortable": true
  },
  "timeseries": {
    "big":"Daily Activity",
    "small":"",
    "flex":1.5
  },
  "link": {
    "big":"",
    "small":".",
    "flex":0.5
  }
}
export const headerOrder = [
  "percent",
  "group",
  "total",
  "rev_total",
  "rpa",
  "spend_total",
  "cpa",
  "roas",
  "timeseries",
  "link"
]

export const comparisonHeaders = {
  "percent": {
    "justify": "center",
    "big":" ",
    "small":"%",
    "flex":0.25,
    "sortable":true
  },
  "group": {
    "rowStyle": {fontSize:"1.3em"},
    "big":"Buckets",
    "small":"",
    "flex":2,
    "sortable":true
  },
  "first_touch_total": {
    "big":"FIRST-TOUCH",
    "small":"yesterday",
    "sortable":true
  },
  "last_touch_total": {
    "big":"LAST-TOUCH",
    "small":"yesterday",
    "sortable":true
  },
  "even_total": {
    "big":"EVEN-TOUCH",
    "small":"yesterday",
    "sortable":true
  },
  "normalized_total": {
    "big":"MULTI-TOUCH",
    "small":"yesterday",
    "sortable":true
  },
  "first_touch_rev_total": {
    "big":"FIRST-TOUCH REVENUE",
    "small":"",
    "sortable":true
  },
  "last_touch_rev_total": {
    "big":"LAST-TOUCH REVENUE",
    "small":"yesterday",
    "sortable":true
  },
  "even_rev_total": {
    "big":"EVEN-TOUCH REVENUE",
    "small":"yesterday",
    "sortable":true
  },
  "normalized_rev_total": {
    "big":"MULTI-TOUCH REVENUE",
    "small":"yesterday",
    "sortable":true
  }
}
export const comparisonHeaderOrder = [
  "group",
  "first_touch_total",
  "last_touch_total",
  "even_total",
  "normalized_total",
  "first_touch_rev_total",
  "last_touch_rev_total",
  "even_rev_total",
  "normalized_rev_total"
]
