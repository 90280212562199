import {
  SET_ATTRIBUTION_DATA,
  SET_ATTRIBUTION_DATA_GROUPS,
  SET_CHANNEL_GROUPS,
  SET_SUMMARY_DATA_BY_ID,
  SET_HAS_SPEND
} from './index';

const initialState = {
  summary: {},
  data: [],
  dataGroups: {},
  channelGroups: undefined,
  hasSpend: false,
}

export default function AttributionContentReducer (state = initialState, action) {
  switch (action.type) {
    case SET_SUMMARY_DATA_BY_ID:
      return {
        ...state,
        summary: Object.assign({}, state.summary, action.data)
      }
    case SET_ATTRIBUTION_DATA:
      return {
        ...state,
        data: action.data,
        hasResponse: true
      }
    case SET_ATTRIBUTION_DATA_GROUPS:
      return {
        ...state,
        dataGroups: action.data
      }
    case SET_CHANNEL_GROUPS:
      return {
        ...state,
        channelGroups: action.data
      }
    case SET_HAS_SPEND:
      return {
        ...state,
        hasSpend: action.data,
      }
    default:
      return state;
  }
}
