import React, { useCallback } from 'react';
import { Segment, Form, Grid, Button, Message, Select } from 'semantic-ui-react';
import { DEFAULT_TIERS, TIERS as tierKeys } from '../../constants/tiers';
import { createSelectedTiers, updateSelectedTiersforURL } from './helpers';


const TierSelection = ({ selectedTiers, tiers, selectTier, title = "Filter by Marketing Tiers" }) => {
  selectedTiers = createSelectedTiers(selectedTiers)

  return <Form.Field>
    <label>{ title }</label>
    {
      tierKeys.map((key, i) => {
        const prevKey = tierKeys[i-1];
        if(i == 0) {
          return (
            <Select
              fluid
              value={selectedTiers[key] && selectedTiers[key]}
              placeholder={DEFAULT_TIERS[key] + " contains"}
              search
              multiple
              onChange={(_, x) => selectTier(key)(x) }
              options={(tiers[key]||[])}
              style={{ marginBottom: 10 }}
            />
          )
        }
        if(i > 0 && tiers[key] !== undefined) {
          return (
            <Select
              fluid
              value={selectedTiers[key] && selectedTiers[key]}
              placeholder={DEFAULT_TIERS[key] + " contains"}
              search
              multiple
              onChange={(_, x) => selectTier(key)(x) }
              options={(tiers[key]||[])}
              style={{marginBottom: 10}}
              disabled={!selectedTiers[prevKey] || selectedTiers[prevKey].length == 0}
            />
          )
        }
        return null
      })
    }
  </Form.Field>
}

const genericSelectTier = (touchPointPosition, setTouchPointPosition, resetUrl) => (tier) => (value) => {
  const selectedTiers = createSelectedTiers(touchPointPosition)
  selectedTiers[tier] = value.value

  if (value.value.length == 0) {
    const tierIndex = tierKeys.indexOf(tier)
    const afterTiers = tierKeys.slice(tierIndex)
    afterTiers.map(_tier =>
      selectedTiers[_tier] = []
    )
  }
  const updatedTiers = updateSelectedTiersforURL(selectedTiers, resetUrl)
  setTouchPointPosition(updatedTiers)
}

const FilterTouchPoint = ({ tiers, firstTiers, lastTiers, filterTouchpoints, filterTouchpointsMethods }) => {
  const { minPathLen, any, first, last} = filterTouchpoints
  const { setMinPathLen, setAny, setFirst, setLast } = filterTouchpointsMethods

  const clearFilters = useCallback(() => {
    setAny('any');
    setFirst('first')
    setLast('last')
    setMinPathLen(1)
  })

  const handleMinSteps = useCallback((evt) => {
    const selectedSteps = evt.target.value;
    setMinPathLen(selectedSteps)
  })

  return (
    <Segment style={{ backgroundColor: 'transparent' }}>
      <Grid columns='equal' style={{ marginTop: '0px', paddingTop: '10px', paddingBottom: '10px' }} relaxed>
        <Grid.Column>
          <TierSelection {...{ tiers, selectedTiers: any, selectTier: genericSelectTier(any, setAny, 'any'), title: "Anywhere in Path"}} />
        </Grid.Column>
        <Grid.Column>
          <TierSelection {...{ tiers: firstTiers, selectedTiers: first, selectTier: genericSelectTier(first, setFirst, 'first'), title: "First Touch" }} />
        </Grid.Column>
        <Grid.Column>
          <TierSelection {...{ tiers: lastTiers, selectedTiers: last, selectTier: genericSelectTier(last, setLast, 'last'), title: "Last Touch" }} />
        </Grid.Column>
        <Grid.Column width={3} textAlign='center'>
          <div><b>{`Minimum Number of Steps: ${minPathLen}`}</b></div>
          <br />
          <input
            type='range'
            min={1}
            max={5}
            value={minPathLen}
            onChange={handleMinSteps}
          />
        </Grid.Column>
      </Grid>
      <Form.Field style={{ minHeight: "30px" }}>
        <Message compact info>
          <p>
          Each path must meet the conditions of <b>any</b> of the tiers within a selected filter. It must also meet the condition of <b>all</b> populated filters. 
          </p>
        </Message>
        <Button style={{ float: "right", cursor: "pointer" }} onClick={() => clearFilters()}>Clear All Filters</Button>
      </Form.Field>
    </Segment>
  )
}

export default FilterTouchPoint;
