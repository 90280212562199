import React, { useContext, useMemo } from 'react'
import { Modal, Loader, Header, Table, Message, Icon } from 'semantic-ui-react'

import { IndexGridNew } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getBatchInboxes } from '../../api/attributionETL'
import { NoDataCat } from '../../components'


const FieldNameCell = ({ item, col }) => (
  <Table.Cell collapsing style={{ minWidth: 70 }}>
    <strong>
      {item[col.key]}
    </strong>
  </Table.Cell>
)

const BatchInboxInstructions = ({ platform, display, onClose }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const batchInboxes = Context.getCache(`batchInboxes`, getBatchInboxes)

  const loading = useMemo(() => !batchInboxes, [batchInboxes])

  const batchInbox = useMemo(() => {
    if (!batchInboxes) return
    const inbox = batchInboxes.find(x => !!x?.mapper?.find(y => y.vendor === platform) || x.name.includes(display))
    if (!inbox) return
    return { ...inbox, ...inbox.mapper[0] }
  }, [batchInboxes, platform])

  const inboxType = useMemo(() => {
    if (!batchInbox) return
    switch (batchInbox.inbox_type) {
      case 'amazon_ses':
        return 'Email'
      case 'amazon_s3':
        return 'Amazon S3 Bucket'
      case 'sftp':
        return 'SFTP Server'
      default:
        return 'Unknown'
    }
  }, [batchInbox])

  const tableData = useMemo(() => {
    if (!batchInbox) return
    const { inbox_type } = batchInbox
    return [
      inbox_type === 'amazon_ses' && [
        { name: 'Email', value: `${batchInbox.email_key}@report-inbox.getrockerbox.com` },
      ],
      inbox_type === 'amazon_s3' && [
        { name: 'Bucket Name', value: batchInbox.bucket_name },
        { name: 'Folder', value: batchInbox.folder },
        { name: 'Full Path', value: `s3://${batchInbox.bucket_name}/${batchInbox.folder}` },
        { name: 'Authorized ARN', value: batchInbox.external_user_arn },
      ],
      inbox_type === 'sftp' && [
        { name: 'SFTP Host', value: `sftp://sftp.getrockerbox.com` },
        { name: 'Port', value: `22` },
        { name: 'Folder', value: `/files` },
        { name: 'Full Path', value: `sftp://sftp.getrockerbox.com:22/files/` },
        { name: 'Username', value: batchInbox.username },
        { name: 'Password', value: batchInbox.sftp_key },
      ],
    ].flat(1).filter(Boolean)
  }, [batchInbox])

  return (
    <Modal
      open
      closeIcon
      dimmer='inverted'
      className='modal-v3'
      {...{ onClose }}
    >
      <Modal.Header>
        <div className='title'>
          {`${display} Batch File Delivery`}
        </div>
      </Modal.Header>
      <Modal.Content>
        {loading &&
          <Loader active inline='centered' />
        }
        {!loading && !batchInbox &&
          <NoDataCat message={'Inbox not found. Please contact us for assistance.'} />
        }
        {!loading && !!batchInbox && <>
          <Header
            as='h4'
            content={`Inbox Type: ${inboxType}`}
          />
          <IndexGridNew
            celled
            data={tableData}
            cols={[
              { display: 'Field', key: 'name', as: FieldNameCell },
              { display: 'Value', key: 'value', as: IndexGridNew.CopyCell },
            ]}
          />
          <Header
            as='h4'
            content={`Instructions`}
          />
          {inboxType === 'Email' && <>
            <p>
              Schedule your files to be delivered as attachments to the email above (one file per email).
            </p>
            <p>
              Attachments of up to 30 MB (megabytes) are supported. For larger files, please contact support to switch your delivery method to S3 or SFTP.
            </p>
          </>}
          {inboxType === 'Amazon S3 Bucket' && <>
            <Message
              header="Custom ACL Header Required"
              content={<>
                {'Upload files with the '}
                <code>{'bucket-owner-full-control'}</code>{' '}
                <a href="https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObjectAcl.html" target="_blank" rel="noopener noreferrer">
                  {'ACL header '}
                  <Icon name='external' />
                </a>
                {' to ensure that Rockerbox can access the files.'}
              </>}
            />
            <p>
              Please add the following statement to the inline or managed permissions policy associated with your AWS user. This statement will give your user permissions to upload files into the S3 bucket.
            </p>
            <p>
              <pre style={{ padding: '20px 0', background: '#f8f8f9', borderRadius: 4 }}>{`
                {
                  "Action": [
                      "s3:GetObject",
                      "s3:GetObjectAcl",
                      "s3:PutObject",
                      "s3:PutObjectAcl",
                      "s3:DeleteObject",
                      "s3:AbortMultipartUpload",
                      "s3:ListMultipartUploadParts"
                  ],
                  "Resource": [
                      "arn:aws:s3:::${batchInbox.bucket_name}/${batchInbox.folder}/*"
                  ],
                  "Effect": "Allow",
                  "Sid": "${batchInbox.name.replace(/[\W_]+/g,'')}"
                }`.replace('\n', '')}
              </pre>
            </p>
          </>}
          {inboxType === 'SFTP Server' && <>
            <p>
              Authenticate to the Rockerbox SFTP server:
              <br />
              <code>
                {`sftp ${batchInbox.username}@sftp.getrockerbox.com -p 22`}
              </code>
            </p>
            <p>
              Upload data to the <code>/files</code> directory on the server.
            </p>
          </>}
          <Header
            as='h4'
            content={`Supported File Types`}
          />
          <p>
            File extensions are required and must match the file type.
          </p>
          <ul>
            <li>CSV</li>
            <li>TSV</li>
            <li>XLSX</li>
          </ul>
        </>}
      </Modal.Content>
    </Modal>
  )
}

export default BatchInboxInstructions
