import React from 'react';
import {DatasetDocumentationCell, DatasetLastSyncedCell} from "../ReportSync/ReportSyncPlatformTable";
import { ContentCard, IndexGridNew } from '@rockerbox/styleguide'
import { useDatalake } from '../DatalakeContext/hooks';
import { PLATFORM_REPORT_TYPE } from '../constants';
import { Button } from 'semantic-ui-react'

const PlatformDataTable = ({showLastSyncd, loading, emptyGridMessage, callbackFn}) => {

  const { selectDatasetsByReportType } = useDatalake();
  const platformDatasets = selectDatasetsByReportType(PLATFORM_REPORT_TYPE)

  platformDatasets.map((dataset) => ({
    ...dataset,
    platform_name: dataset['platform_name'] = dataset['platform'][0].toUpperCase() + dataset['platform'].substring(1)
  }))

  const tableColumns = [
    { display: 'Platform', key: 'platform_name'},
    { display: 'Dataset', key: '', as: platformDatasets && DatasetDocumentationCell(platformDatasets) },
    { display: 'Table Name', key: 'table_name' }
  ]

  if (!!showLastSyncd) { tableColumns.push({ display: 'Last Synced', key: 'last_sync', as: platformDatasets && DatasetLastSyncedCell(platformDatasets) }) }

  return (
    <>
      {!loading ? 
        <IndexGridNew
          title="Platform Data"
          topRight={
            <Button
              inverted
              color="purple"
              onClick={() => callbackFn()}
              disabled={loading}
              loading={loading}
              content={"Add a platform share"}
              className="remove-box-shadow"
            />
          }
          celled
          data={platformDatasets}
          cols={tableColumns}
          placeholder="Platform Datasets"
          fallBackMsg={emptyGridMessage || "No Matching Datasets Found"}
        /> :
        <ContentCard.CardLoader />
      }
    </>
  )
}

export default PlatformDataTable;
