import React, { useState, useCallback, createRef } from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Legend
} from "recharts";
import { IndexGridNew, ContentCard } from "@rockerbox/styleguide";
import { Table, Dropdown, Header } from "semantic-ui-react";
import { experimentStore } from "./store";
import { useRefDimensions } from "./useRefDimensions";
import { hashCode } from "./helper";
import ColorHash from 'color-hash';

const HASH = new ColorHash({
  hue: {min: 50, max: 355},
  saturation: 0.55,
  lightness: [0.4, 0.75, 0.5]
})

export const colorHash = (text) => HASH.hex(text)

const selector = state => [
  state.chartType,
  state.setChartType,
  state.selectedTreatments,
  state.computingLoader,
  state.chartScale,
  state.setChartScale
];

const ExperimentChart = props => {
  const { data, baselineKey, treatmentKeys, data_tier } = props;
  const containerRef = createRef();
  const dimensions = useRefDimensions(containerRef);
  const [
    chartType,
    setChartType,
    selectedTreatments,
    computingLoader,
    chartScale,
    setChartScale
  ] = experimentStore(selector);

  const onTypeChange = useCallback((e, data) => setChartType(data.value));
  const onScaleChange = useCallback((e, data) => setChartScale(data.value));

  const chartDataDomain = React.useMemo(() => {
    if (chartScale === "log") {
      const minData = data
        .map(item =>
          Object.values(item).filter(
            value => typeof value !== "string" && value > 0
          )
        )
        .flat()
        .sort((a, b) => a - b)[0];
      return [min => 0.8 * minData, dataMax => dataMax * 1.2];
    } else {
      return [0, dataMax => dataMax * 1.2];
    }
  }, [data, chartScale]);

  if (selectedTreatments.length < 1) {
    return (
      <Table style={{ height: "100%" }}>
        <IndexGridNew.EmptyMessage fallbackMsg="Add a Test Campaign below to get started" />
      </Table>
    );
  }

  const typeOptions = [
    { text: "CPA", key: "cpa", value: "cpa" },
    { text: "ROAS", key: "roas", value: "roas" }
  ];
  const scaleOptions = [
    { text: "Linear", key: "linear", value: "linear" },
    { text: "Log", key: "log", value: "log" }
  ];

  return (
    <div ref={containerRef}>
      <ContentCard loading={computingLoader}>
        <Header as="h3">
          <Header.Content>
            {"Cumulative "}
            <Dropdown
              inline
              options={typeOptions}
              value={chartType}
              onChange={onTypeChange}
            />
          </Header.Content>
          <div style={{ float: "right" }}>
            {"Scale: "}
            <Dropdown
              inline
              options={scaleOptions}
              value={chartScale}
              onChange={onScaleChange}
            />
          </div>
        </Header>
        <LineChart
          width={dimensions["width"]}
          height={dimensions["height"]}
          data={data}
          margin={{ top: 5, right: 80, left: -20, bottom: 5 }}>
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={value =>
              new Intl.NumberFormat("en", {
                notation: "compact",
                compactDisplay: "short"
              }).format(value)
            }
            axisLine={false}
            tickLine={false}
            scale={chartScale}
            domain={chartDataDomain}
          />
          <Tooltip />

          <Line
            type="monotone"
            strokeWidth={3}
            dot={false}
            dataKey={baselineKey}
            stroke={colorHash(hashCode(baselineKey + "baseline"))}
          />
          {treatmentKeys.map(({ searchKey, treatment_id }) => (
            <Line
              key={searchKey}
              type="monotone"
              strokeWidth={3}
              dot={false}
              dataKey={searchKey}
              stroke={colorHash(hashCode(`${searchKey}${treatment_id}`))}
            />
          ))}
        </LineChart>
      </ContentCard>
    </div>
  );
};

export default ExperimentChart;
