import React, { useEffect, useState, useMemo } from "react"
import { useAtom } from 'jotai'

import { platformConfigViewToColumns, tierFiltersToColumnNameMap } from '../helpers'
import { tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom } from '../../../atoms'

export const useTiers = (view, platformConfig) => {
  const [currentView, setCurrentView] = useState(view)
  const [tier_1, set_tier_1] = useAtom(tier1Atom)
  const [tier_2, set_tier_2] = useAtom(tier2Atom)
  const [tier_3, set_tier_3] = useAtom(tier3Atom)
  const [tier_4, set_tier_4] = useAtom(tier4Atom)
  const [tier_5, set_tier_5] = useAtom(tier5Atom)

  const resetTiers = () => {
    set_tier_1([])
    set_tier_2([])
    set_tier_3([])
    set_tier_4([])
    set_tier_5([])  
  }

  const tierFilters = currentView == view ? 
    { tier_1, tier_2, tier_3, tier_4, tier_5 } :
    { tier_1: [], tier_2: [], tier_3: [], tier_4: [], tier_5: [] }

  const columnsInView = platformConfigViewToColumns(view, platformConfig)
  const tierFilterMap = useMemo(
    () => tierFiltersToColumnNameMap(tierFilters, columnsInView), 
    [ tier_1, tier_2, tier_3, tier_4, tier_5, view, platformConfig ]
  )

  useEffect(
    () => {
      if (view == currentView) return;
      resetTiers()
      setCurrentView(view)
    },
    [view]
  )


  return { 
    ...tierFilters,
    resetTiers,
    tierFilterMap
  }
}


