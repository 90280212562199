import React from "react"
import { adAutoParamsImage } from '@rockerbox/styleguide'
import { Image, Header } from 'semantic-ui-react'
import NoConnections from "./NoConnections"

const SelectAPlatform = ({dropdownOptionsLoading, dropdownOptions}) => {
  const hasData = dropdownOptions && dropdownOptions.length > 0;

  if (dropdownOptionsLoading) return null
  if (!hasData) return <NoConnections />

  return (
    <div style={{textAlign: 'center'}}>
      <Image
        src={adAutoParamsImage}
        size='medium'
        centered
        className='m-t-75'
      />
      <Header as='h1' style={{color: 'rgba(71, 93, 220, 0.5)', marginTop: 35}}>
        Select a platform above to see <br/> 
        your digital advertising performance
      </Header>
    </div>
  )
}

export default SelectAPlatform
