import React, { useState, useEffect } from 'react'
import { BasicViewHeader } from '../../components';
import { Grid, GridColumn } from 'semantic-ui-react';
import { useAtom } from 'jotai'
import { platformAtom } from '../../atoms'
import { getTrendCategories } from '../../api/attribution';
import LineChartWithLegend from './Chart';
import Insights from './Insights';
import Filter from './Filter';
import Table from './Table';
import CallToAction from './CallToAction';

const PlatformBenchmarks = ({}) => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [platform, setPlatform] = useAtom(platformAtom);
  const [filterType, setFilterType] = useState('Industry');
  const [industry, setIndustry] = useState('All');
  const [spendLevelOptions, setSpendLevelOptions] = useState([]);
  const [spendLevel, setSpendLevel] = useState('All');
  const [adType, setAdType] = useState('All');
  const [trendCategories, setTrendCategories] = useState(false);

  useEffect(() => {
    getTrendCategories().then((data) => {
      setTrendCategories(data)
    })
}, [])

  return (
    <div className="benchmarks">
    <BasicViewHeader
      header='Advertising Performance Benchmarks'
      subheader='View trends and benchmarks for advertising KPIs on popular digital platforms'
    />
    <Filter {...{platform, setPlatform, filterType, setFilterType, setLoading, setData,
                 industry, setIndustry, spendLevel, setSpendLevel, spendLevelOptions, setSpendLevelOptions,
                 adType, setAdType, data, trendCategories}}/>
    <Grid>
      <GridColumn width={5} className="insights">
        <Insights {...{data, platform, filterType, industry, spendLevel, adType, spendLevelOptions}}/>
      </GridColumn>
      <GridColumn width={11} className="chart">
        <LineChartWithLegend {...{data, loading, platform}}/>
      </GridColumn>
    </Grid>
    <Grid>
      <GridColumn width={11} className="table">
        <Table {...{data, platform}}/>
      </GridColumn>
      <GridColumn width={5} className="cta">
        {data.length > 0 && <CallToAction {...{platform}}/>}     
      </GridColumn>
    </Grid>
    </div>
  );
};
export default PlatformBenchmarks;
