import React from 'react'
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { ChartTooltip } from '@rockerbox/styleguide';

const TotalLabel = ({ total, labelForTotal, height }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: height,
      top: '12px',
      left: 0,
    }}
    >
    <div
      style={{
        fontFamily: 'Poppins',
        fontSize: 16,
        marginBottom: 10,
        opacity: 0.6,
        fontWeight: 500,
      }}
    >
      {labelForTotal}
    </div>
    <div
      style={{
        fontSize: 22,
        fontWeight: 500,
        color: '#475ddc',
      }}
    >
      {total}
    </div>
  </div>
);

const DonutChart = ({
  data, dataKey, nameKey, colors, //data 
  height = 360, size, innerRadius, outerRadius, paddingAngle = 0, //dimensions
  showTooltip, formatter, showTotalInToolTip = false,  hideKeys=[], //tooltip
  showTotal, labelForTotal = 'Total' //total
}) => {
  const chartData = dataKey ? data : Object.keys(data).map(key => ({ name: key, value: data[key] }))
  const dKey = dataKey ? dataKey : 'value'
  const nKey = nameKey ? nameKey : 'name'

  const toolTipValueFormatter = formatter?.format ? formatter.format :
    formatter ? formatter : (v) => v
  
  const total = dataKey ? data.reduce((acc, c) => acc += c[dataKey], 0) : Object.values(data).reduce((acc, c) => acc += c, 0)
  const totalFormatted = toolTipValueFormatter(total)

  const outerR = outerRadius ?
    outerRadius : size === 'mini' ?
      height / 2 : '95%'
  
  const innerR = innerRadius ?
    innerRadius : size === 'mini' ?
      height / 2 - 5 : '80%'
  
  return (
    <>
      {showTotal && <TotalLabel {...{total: totalFormatted, labelForTotal, height}} />}
      <ResponsiveContainer width='100%' height={height}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey={dKey}
            nameKey={nKey}
            outerRadius={outerR}
            innerRadius={innerR}
            startAngle={90}
            endAngle={-270}
            paddingAngle={paddingAngle}
          >
            {chartData.map(d => {
              const name = d[nKey]
              return (
                <Cell key={`cell-${name}`} fill={colors[name]} />
              )})
            }
          </Pie>
          {showTooltip &&
            <Tooltip
              cursor={false}
              formatter={toolTipValueFormatter}
              content={<ChartTooltip {...{ showTotalInToolTip, hideKeys }} />}
            />
          } 
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}
DonutChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
  colors: PropTypes.object, // required to have same keys as data
}

export default DonutChart;
