import React, { useContext, useMemo } from 'react'

import CacheContext from '../../../utils/CacheContext'
import { getAccount } from '../../api/account'
import { BasicViewHeader } from '../../components'
import UpgradePromo from './UpgradePromo'
import MMMView from './component'


const MarketingMixModeling = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const account = Context.getCache(`account`, getAccount)

  const hasAccess = useMemo(() => {
    if (!account?.features) return
    return account?.features?.includes('mmm')
  }, [account])

  if (hasAccess === false) return <UpgradePromo />

  return (<>
    <BasicViewHeader
      header='Marketing Mix Modeling'
      subheader='Plan budgets and predict future revenue using historical marketing performance'
    />
    <MMMView />
  </>)
}

export default MarketingMixModeling
