import React from "react"

import { generateComparisonCards } from "./helpers"
import { TrendCard } from "../../../components"

export const TrendCardsFromComparisonData = (props) => {
  const { model } = props
  const { dailyComparisonData, optional_metrics, hasRevenue, onCardClick, optionalMetric, platformConfig, integrations, platform, selectedCardMetric, currencyCode } = props
  const cards = !dailyComparisonData ? [{}, {}, {}, {}, {}, {}] 
    : generateComparisonCards(model, dailyComparisonData, optional_metrics, hasRevenue, platformConfig, integrations, platform, selectedCardMetric, currencyCode)

  return (
    <TrendCard 
      loading={!dailyComparisonData}
      defaultCard={TrendCard.TrendingCard} 
      options={cards} 
      onChange={onCardClick}
      value={optionalMetric}
      point="down"
      cardGroupStyle={{paddingTop: '1em', marginBottom: '-2.05rem', zIndex: 10 }}
      altBaseline
      {...props} 
    />
  )
}

export default TrendCardsFromComparisonData;
