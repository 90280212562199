import React, { Component } from 'react'
import { Menu, Icon, Pagination, Table } from 'semantic-ui-react'
import { default as IndexGrid } from '../IndexGrid'
import ExpandableIndexGrid from '../ExpandableIndexGrid'
import { recordsFrom, recordsTo } from './helpers'
import PropTypes from 'prop-types'

const ContentCardTableFooter = props => (
  <Table.Footer>
    <Table.Row>
      <Table.HeaderCell colSpan='100'>
        <div className='footer-content'>{props.children}</div>
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
)

class PagedIndexGrid extends Component {
  state = {
    pageNumbers: undefined,
    selectedPageNumber: 1,
    data: []
  }

  componentDidMount() {
    const { data, showFooter } = this.props;

    const footer = showFooter == undefined ? true : showFooter;

    this.setState({
      pageNumbers: this.pagerLength(data),
      data: data,
      showFooter: footer
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const { data } = this.props
      this.setState({
        pageNumbers: this.pagerLength(data),
        data: data,
      })
    }

    const isFalsy = (obj) => {
      return !obj || Object.getOwnPropertyNames(obj).length == 0;
    }

    if (prevProps.selectedRow !== this.props.selectedRow && isFalsy(prevProps.selectedRow)) {
      const { data, selectedRow, itemsPerPage } = this.props;
      const index = data.findIndex(item => item == selectedRow);
      this.setState({
        selectedPageNumber: Math.ceil(index / itemsPerPage) || 1
      })
    }

    if (prevProps.selectedRow !== this.props.selectedRow && !isFalsy(prevProps.selectedRow) && isFalsy(this.props.selectedRow)) {
      const { data } = this.props;
      this.setState({
        pageNumbers: this.pagerLength(data),
        data: data,
        selectedPageNumber: 1
      });
    }
  }

  pagerLength = rows => {
    const { itemsPerPage } = this.props
    const numberOfPagers = Math.ceil(rows.length / itemsPerPage)

    return numberOfPagers
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ selectedPageNumber: activePage })
  }

  paginate = data => {
    const { selectedPageNumber } = this.state
    const { itemsPerPage } = this.props

    return data.slice(
      (selectedPageNumber - 1) * itemsPerPage,
      selectedPageNumber * itemsPerPage,
    )
  }

  render() {
    const { pageNumbers, selectedPageNumber, showFooter } = this.state
    const { data, itemsPerPage, pagination } = this.props
    const selectedData = data ? this.paginate(data) : data
    const GridType = this.props.expandable ? ExpandableIndexGrid : IndexGrid;

    if (pagination == "left") {
      return (
        <div>
          <GridType
            {...this.props}
            allData={this.props.data}
            static
            data={selectedData}
            cols={this.props.cols}
            footer={ showFooter &&
              <ContentCardTableFooter>
                {data.length > itemsPerPage &&
                  <Pagination
                    activePage={selectedPageNumber}
                    onPageChange={this.handlePaginationChange}
                    totalPages={pageNumbers}
                    firstItem={false}
                    lastItem={false}
                    ellipsisItem={pageNumbers > 7 ? undefined : null}
                  />
                }
                <div>
                  Showing{' '}
                  {recordsFrom(selectedPageNumber, itemsPerPage)}-{recordsTo(selectedPageNumber, itemsPerPage, data.length)}{' '}
                  out of {new Intl.NumberFormat().format(data.length)} records
                </div>
              </ContentCardTableFooter>
            }
          />
        </div>
      )
    }
    if (!pagination || pagination == "right") {
      return (
        <div>
          <GridType
            {...this.props}
            allData={this.props.data}
            static
            data={selectedData}
            cols={this.props.cols}
            footer={ showFooter &&
              <ContentCardTableFooter>
                <div>
                  Showing{' '}
                  {recordsFrom(selectedPageNumber, itemsPerPage)}-{recordsTo(selectedPageNumber, itemsPerPage, data.length)}{' '}
                  out of {new Intl.NumberFormat().format(data.length)} records
                </div>
                {data.length > itemsPerPage &&
                  <Pagination
                    activePage={selectedPageNumber}
                    onPageChange={this.handlePaginationChange}
                    totalPages={pageNumbers}
                    firstItem={false}
                    lastItem={false}
                    ellipsisItem={pageNumbers > 7 ? undefined : null}
                  />
                }
              </ContentCardTableFooter>
            }
          />
        </div>
      )
    }
  }
}

PagedIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element
   })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
  /** Number of rows per page */
  itemsPerPage: PropTypes.number.isRequired,
  expandable: PropTypes.bool,
  /** Component that will display when table row is clicked */
  expandedComponent: PropTypes.element,
  /** Key from a data object used to determine which row has been clicked and should be expanded. They selected key must be unique across all objects (ideally some sort of id) */
  idKey: PropTypes.string,
  pagination: PropTypes.string
  /** Controls the location of the page selector on the footer--"left" or "right". Defaults to "right". */
}

export default PagedIndexGrid
