import React, { useContext} from 'react'
import CacheContext from '../../../utils/CacheContext'

import { Header } from 'semantic-ui-react'
import { InviteUsers } from '../../components'


const ManageUsers = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const username = state?.user?.username

  return (<>
    <Header
      as='h1'
      content='Manage Team Users'
    />
    <InviteUsers hideTitle showFooter {...{ username }} />
  </>)
}

export default ManageUsers
