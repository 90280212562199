import React, { useMemo } from 'react'
import { Header, Table, Divider } from 'semantic-ui-react'
import { UserIcon } from '@heroicons/react/solid'

import { ContentCard } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getUser } from '../../api/account'
import ChangePassword from './ChangePassword'
import ChangeDisplayName from './ChangeDisplayName'
import BetaEnrollment from './BetaEnrollment'
import ClearCache from './ClearCache'

const MyAccount = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { account } = state
  const { advertiser_name, tier, pixel_source_name, timezone } = account || {}

  const user = (Context.getCache(`user`, getUser) || {})
  const { username } = user

  const tierDisplayName = useMemo(() => {
    switch(tier) {
      case 'lite':
        return 'Free'
      case 'starter':
        return 'Starter'
      default:
        return 'Scale'
    }
  }, [tier])

  return (
    <>
      <Header
        as='h1'
        content='My Account'
      />
      <Header
        as='h3'
        content='Account Details'
      />
      <ContentCard hasTable>
        <Table definition columns={2}>
          <Table.Row>
            <Table.Cell>Account Name</Table.Cell>
            <Table.Cell>{advertiser_name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account Type</Table.Cell>
            <Table.Cell>{tierDisplayName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account ID</Table.Cell>
            <Table.Cell>{pixel_source_name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Timezone</Table.Cell>
            <Table.Cell>{timezone || "UTC"}</Table.Cell>
          </Table.Row>
        </Table>
      </ContentCard>
      <Header
        as='h3'
        content='Username'
      />
      <UserIcon style={{ display: 'inline-block', height: 14, marginBottom: -3, marginRight: 8 }} />
      {username}
      <ChangeDisplayName />
      <ChangePassword />
      <BetaEnrollment />
      <Divider style={{ marginTop: 24 }} />
      <ClearCache />
    </>
  )
}

export default MyAccount
