import React, { Component } from 'react';
import { Input, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import { SearchField } from '@rockerbox/styleguide';
import { NestedFieldPreview } from '../Survey/SetupGeneral';
import { getDataset, getTiersAsOptions } from './../../utils/api';

const buildSearchField = (row) => `${row.tier_1.toLowerCase()} -> ${row.tier_2.toLowerCase()} -> ${row.tier_3.toLowerCase()} -> ${row.tier_4.toLowerCase()} -> ${row.tier_5.toLowerCase()}`
const DEFAULT_TIER_COUNTS = { tier_1: 0, tier_2: 0, tier_3: 0, tier_4: 0, tier_5: 0 }

const filterRowBy = (filter, tierCounts) => (row) => {
  const hasValue = row.searchField.includes(filter)
  if (!hasValue) return hasValue

  Object.keys(tierCounts)
    .map(tier => {
      tierCounts[tier] += row[tier].toLowerCase().includes(filter) ? 1 : 0
    })

  return hasValue
}

const FindMarketingEvent = ({ segment, onChange, controlColumnName="Event", platformFilter = false, daysAgo = false }) => {

  const { segment_id } = segment;

  const [compiledData, setCompiledData] = React.useState([])
  const [filteredData, setFilteredData] = React.useState([])
  const [filteredTierMatches, setFilteredTierMatches] = React.useState({tier_1: 0})
  const [filter, setFilter] = React.useState(false)

  React.useEffect(() => {
    if (segment_id) getDataset("compiled_mta_tiers", segment_id, daysAgo)
      .then(response => response['response']['compiled_mta_tiers'])
      .then(data => data.map(row => Object.assign( row, {searchField: buildSearchField(row)} )) )
      .then(setCompiledData)
  }, [segment_id, daysAgo])

  React.useEffect(() => {
    const tierCounts = _.cloneDeep(DEFAULT_TIER_COUNTS);
    const hasFilter = !!filter;
    const platformFiltered = platformFilter ? compiledData.filter(platformFilter) : compiledData
    const filtered = hasFilter ? platformFiltered.filter(filterRowBy(filter, tierCounts)) : platformFiltered

    setFilteredTierMatches(tierCounts)
    setFilteredData(filtered)
  }, [compiledData, filter, platformFilter])

  const defaultOpen = React.useMemo(() => {
    const [first, value] = Object.entries(filteredTierMatches)
      .sort(([_,p],[,c]) => c - p)[0]

    const [_, number] = first.split("_")
    return number - 1
  }, [filteredTierMatches])


  return <>
    <SearchField onChange={(evt, { value }) => setFilter(value)} />
    { filteredData.length == 0 && <div style={{margin:"10px", textAlign:"center"}}>No Matching Events Found</div> }

    <NestedFieldPreview
      {...{
          data: filteredData,
          defaultOpen,
          fields: ["tier_1","tier_2","tier_3","tier_4","tier_5"],
          rollup: () => { return [{count: 1, row: {}}] },
          controlColumn: (row) => <Button
            size='mini' icon='plus' as='a' style={{margin:-6, maxWidth:70}}
            content={controlColumnName}
            color="blue"
            compact
            onClick={(event) => {
              onChange(event, { value: row })
              event.stopPropagation()
            }}
          />
        }
      }
    />
  </>
}

export default FindMarketingEvent;
