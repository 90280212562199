import React, { useContext, useEffect, useMemo } from 'react'
import { Grid, Segment, Divider } from 'semantic-ui-react'
import { getAccountCurrency } from '../../api/account'

import { CacheContext } from '../../../utils/CacheContext'
import { getSegments } from '../../api/attribution'
import { SavedReportsGrid } from '../../components'
import { useURLStateAndSetDefault } from '../../hooks/urlState'

import { getTimeOfDay } from './helpers'
import { monthAgo as defaultStartDate, yesterday as defaultEndDate } from '../../utils/time'
import Announcements from './Announcements'
import MetricsDashboard from './MetricsDashboard'
import FreeDashboard from './FreeDashboard'
import StarterDashboard from './StarterDashboard'
import ProductUpdates from './ProductUpdates'
import Promo from './Promo'
import { GenericHeader } from './parts'

const Home = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || null
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;
  const { account, user } = state
  const { advertiser_name, tier } = account || {}

  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', defaultStartDate, true)
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', defaultEndDate, true)

  const featuredSegment = useMemo(() => {
    if (!segments) return null
    return segments.find((s) => !!s.featured)
  }, [segments])

  const firstReportingDate = featuredSegment?.first_reporting_date
  const firstName = !!user ? user.first_name : false
  const name = firstName === false ? "" : firstName || advertiser_name
  const heading = `Good ${getTimeOfDay()}, ${name}`
  const isLite = tier === 'lite'
  const isStarter = tier === 'starter'
  const greeting = "Let's dive into your marketing."
  const onDateChange = (input) => {
    setStartDate(input.startDate)
    setEndDate(input.endDate)
  }
  
  useEffect(() => {
    if (!isLite && !isStarter && !!firstReportingDate && startDate < firstReportingDate) {
      setStartDate(firstReportingDate)
    }
  }, [startDate, firstReportingDate])

  return (
    currencyCode === undefined ? null
    :
    isLite ?
    <FreeDashboard {...{ heading, startDate, endDate, onDateChange, currencyCode }} />
    :
    isStarter ?
    <StarterDashboard {...{ heading, startDate, endDate, onDateChange, currencyCode }} />
    :
    <>
      <div className='hero-top'>
        <GenericHeader
          header={heading}
          subheader={`Welcome to Rockerbox! ${greeting}`}
          paddingBottom="1em"
          datePicker={!!startDate && !!endDate && {
            startDate,
            endDate,
            firstReportingDate: !isLite && firstReportingDate,
            onDateChange,
            marginTop: 2,
          }}
        />
        <Announcements />
        <MetricsDashboard {...{ startDate, endDate }} />
      </div>

      <Segment>
        <SavedReportsGrid columns={3} />
      </Segment>
      <Divider style={{ margin: '23px 0' }} />

      <Grid stretched>
        <Grid.Column width={11} className='grid-column-two-thirds'>
          <ProductUpdates />
        </Grid.Column>
        <Grid.Column width={5} className='grid-column-one-third'>
          <Promo />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default Home
