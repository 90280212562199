import React, { useState } from 'react';
import { Button, Message } from 'semantic-ui-react';
import _ from 'lodash';
import { formatDatesIso } from './helpers';

const ValidateSubmitBtn = ({content, fieldsObj, required, submit, dateFields, style}) => {
  const [error, setError] = useState("");
  const [errorList, setErrorList] = useState([]);
  const fields = _.cloneDeep(fieldsObj);
  const checkedFields = required ? required.filter(c => (fields[c] == null) || (fields[c] == undefined) || (fields[c] === "")) : [];

  const handleClick = () => {
    if(checkedFields.length > 0) {
      setError(true);
      setErrorList(checkedFields);
    } else {
      setError(false);
      const postObj = (dateFields && dateFields.length > 0) ? formatDatesIso(dateFields, fields) : fields;
      submit(postObj);
    }
  }

  return (
    <React.Fragment>
      <Button 
        type="submit"
        primary
        content={content}
        disabled={fields.loading}
        loading={fields.loading}
        onClick={handleClick} 
        style={style}
      />
      { error && <Message negative>Fix errors {errorList.length == 1 ? errorList[0] : errorList.join(", ")}</Message>}
    </React.Fragment> 
  )
}

export default ValidateSubmitBtn;