import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Container, Divider, Grid, Button, Form, Header, Icon, Table, Popup, Dropdown, Input } from 'semantic-ui-react';
import _ from 'lodash';

const SummaryTable = ({dataSources, marketingChannels}) => {
  const {bucket1, bucket2, bucket3, bucket4, bucket5} = marketingChannels;
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={7} textAlign="center">
            Selected Spend Data
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={7} textAlign="center">
            Selected Marketing Channels
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell textAlign="center">
            <strong>{dataSources.spendSource}</strong>
            {dataSources.spendFilters.map((c,i) => {
              if(c.field || c.value) {
                return (
                  <div>
                  {c.text} = {c.value}
                  <br />
                  </div>
                )
              }
            })}
          </Table.Cell>
          <Table.HeaderCell width={1} />
          <Table.Cell textAlign="center">
            {bucket1 && <div>Bucket 1: {bucket1}</div>}
            {bucket2 && <div>Bucket 2: {bucket2}</div>}
            {bucket3 && <div>Bucket 3: {bucket3}</div>}
            {bucket4 && <div>Bucket 4: {bucket4}</div>}
            {bucket5 && <div>Bucket 5: {bucket5}</div>}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

const MappingName = ({nameVal, handleTextChange}) => {
  return (
    <React.Fragment>
      <Header
        as="h4"
        content="Mapping Name"
        subheader="Finally, give your mapping a name to make it easy to remember (e.g. Paid Social - Facebook)"
      />
      <Form.Input
        fluid
        placeholder="Your Mapping Name"
        type="text"
        value={nameVal}
        onChange={(e,data) => handleTextChange(data, "defineMappings", "name")}
      />
    </React.Fragment>
  )
}

class MappingsTable extends Component {
  render() {
    const { defineMappings, marketingChannels, spendFieldOptions, handleSpendDataChange, dataSources } = this.props;

    const items = Object.keys(marketingChannels)
      .filter(k => ((marketingChannels[k] != '') && (marketingChannels[k] != null)) )
      .map(k => {
        return { "key": k }
      })

    _.range(0,5-items.length)
    .map(i => items.push(defineMappings.mappings[i]))

    const optionSet = _.cloneDeep(items);

    const options = optionSet.filter((c,i) => {
      c.value = `tier_${i+1}`;
      c.text = `Bucket ${i+1}`;
      delete c.spendField;
      delete c.tierBucket;
      delete c.id;
      return c.key === undefined;
    })

    return (
      <React.Fragment>
        <Header
          as="h4"
          content="Mappings"
          subheader="Using Channel Bucket names & Spend Data Source fields, map the fields that should be tied together."
        />
        <Table>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell width={7} textAlign="center">
              Spend Data Fields
            </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Icon
                  name="arrows alternate horizontal"
                  color="grey"
                  inverted
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={7} textAlign="center">
                Marketing Channel Buckets
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((row, i) => {
              const bucketUppercase = row.key ? row.key.charAt(0).toUpperCase() + row.key.slice(1) : '';
              const bucket = [bucketUppercase.slice(0,6), bucketUppercase.slice(6)].join(' ');

              if(marketingChannels[row.key]) {
                return (
                  <Table.Row>
                    <Table.Cell textAlign="center">-</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon
                        name="arrows alternate horizontal"
                        color="grey"
                        inverted
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {bucket} ({marketingChannels[row.key]})
                    </Table.Cell>
                  </Table.Row>
                )
              } else {
                return (<Table.Row>
                  <Table.Cell textAlign="center">
                    <Dropdown
                      selection
                      fluid
                      required
                      placeholder="Select a Spend Data Field"
                      options={spendFieldOptions}
                      value={row[`spendField`]}
                      disabled={!dataSources.spendSource}
                    >
                      <Dropdown.Menu >
                        {spendFieldOptions.map((current,j) => (
                          <Dropdown.Item
                            key={current.value}
                            value={current.value}
                            onClick={(e,data) => handleSpendDataChange(data, row.id, `spendField`, "defineMappings")}
                          >
                            {current.text}
                          </Dropdown.Item>))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Icon
                      name="arrows alternate horizontal"
                      color="grey"
                      inverted
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Form.Dropdown
                      fluid
                      placeholder="Bucket value"
                      type="text"
                      value={row[`tierBucket`]}
                      options={options}
                      onChange={(e,data) => handleSpendDataChange(data, row.id, `tierBucket`, "defineMappings")}
                      disabled={!row['spendField']}
                    />
                  </Table.Cell>
                </Table.Row>)
              }
            })}

          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }
}

class OverridesTable extends Component {
  render() {
    const { defineMappings, defineOverrides, marketingChannels, spendFieldOptions, handleOverrideChange } = this.props;

    let ii = 0;
    const items = Object.keys(marketingChannels)
      .filter(k => ((marketingChannels[k] != '') && (marketingChannels[k] != null)) )
      .reduce((p,k,i) => {
        p[i] = { "key": k, tierBucket: k.replace("bucket","tier_") }
        ii += 1;
        return p
      },{})


    const orderedMappings = defineMappings.mappings
      .reduce((p,row) => {

        if (row.tierBucket != "") {
          row.pos = parseInt(row.tierBucket.replace("tier_",""));
        } else {
          ii += 1;
          row.pos = ii;
        }

        if (!p[row.pos-1]) p[row.pos-1] = row
        return p
      },{})


    _.range(0,5)
      .map(i => {
        if (items[i]) return;
        items[i] = orderedMappings[i];
      })


    return (
      <React.Fragment>
        <Header
          as="h4"
          content="Overrides"
          subheader="Optionally: Define how you want to override your buckets from the spend report."
        />
        <Table>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell width={7} textAlign="center">
              Marketing Field
            </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Icon
                  name="arrows alternate horizontal"
                  color="grey"
                  inverted
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={7} textAlign="center">
                Override with Spend
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.range(0,5).map((i) => {
              const row = items[i];
              const bucketUppercase = row.key ? row.key.charAt(0).toUpperCase() + row.key.slice(1) : '';
              const bucket = [bucketUppercase.slice(0,6), bucketUppercase.slice(6)].join(' ');

              if(marketingChannels[row.key]) {
                return (
                  <Table.Row>
                    <Table.Cell textAlign="center">
                      {bucket} ({marketingChannels[row.key]})
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon
                        name="arrows alternate horizontal"
                        color="grey"
                        inverted
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">-</Table.Cell>
                  </Table.Row>
                )
              } else {
                return (<Table.Row>
                  <Table.Cell textAlign="center">
                    Bucket {i + 1}
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    <Icon
                      name="arrows alternate horizontal"
                      color="grey"
                      inverted
                    />
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                  <Dropdown
                    selection
                    fluid
                    required
                    placeholder="Select a Spend Data Field"
                    options={spendFieldOptions}
                    value={row[`spendField`] || defineOverrides[`tier_${i+1}`]}
                    disabled={row[`spendField`]}
                  >

                    <Dropdown.Menu >
                      {spendFieldOptions.map((current,j) => (
                        <Dropdown.Item
                          key={current.value}
                          value={current.value}
                          onClick={(e,data) => {
                            handleOverrideChange(`tier_${i+1}`, data)
                          }}
                        >
                          {current.text}
                        </Dropdown.Item>))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
                </Table.Row> )
              }
            })}

          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }
}

const DefineMappings = ({defineMappings, defineOverrides, marketingChannels, dataSources, handleTextChange, updateActiveState, createPostObj, spendFieldOptions, handleSpendDataChange, id, handleOverrideChange}) => {
  return (
    <Form>
      <Container fluid>
        <SummaryTable
          dataSources={dataSources}
          marketingChannels={marketingChannels}
        />
        <MappingsTable
          defineMappings={defineMappings}
          marketingChannels={marketingChannels}
          spendFieldOptions={spendFieldOptions}
          handleSpendDataChange={handleSpendDataChange}
          dataSources={dataSources}
        />
        <OverridesTable
          defineMappings={defineMappings}
          defineOverrides={defineOverrides}
          marketingChannels={marketingChannels}
          spendFieldOptions={spendFieldOptions}
          handleSpendDataChange={handleSpendDataChange}
          dataSources={dataSources}
          handleOverrideChange={handleOverrideChange}
        />
        <MappingName nameVal={defineMappings.name} handleTextChange={handleTextChange} />
      </Container>
      <Divider section />
      <div className="m-t-20">
        <Grid fluid columns={2}>
          <Grid.Column>
            <Button secondary type="button" onClick={() => updateActiveState('marketing')} >Back</Button>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button primary type="button" textAlign="right" onClick={createPostObj} disabled={defineMappings.name === ''}>
              { id ? 'Update' : 'Create'} Mapping
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </Form>
);

}

export default DefineMappings;
