import React, { Component } from 'react';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { getViaKey, get, currencyFormat, currency, percent, number, defaultNumber } from './helpers';

const COGS = ({multiplier, tag, values, months, accessor, formatter}) => {
  const format = formatter || defaultNumber;

  return (
    <React.Fragment>

      <Row hover>
        <Column padded="5">Existing COGS</Column>
        <Column>{ format(multiplier*(getViaKey(values,`Existing.cohort.revenue`,0))) }</Column>
        { months.map(date => (<Column>{ format(multiplier*(getViaKey(values,`repeat Existing.${date}.revenue`,0))) }</Column>) ) }
        { months.map(date => (<Column bold>{ format(multiplier*(getViaKey(values,`Existing.${date}.cumulative.revenue`,0))) }</Column>) ).slice(-1) }
      </Row>

      <Row hover light>
        <Column padded="20">Existing Cumulative</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(multiplier*(getViaKey(values,`Existing.${date}.cumulative.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>

      <Row hover>
        <Column padded="5">New Customer COGS</Column>
        <Column>{ format(multiplier*(getViaKey(values,`NTF.cohort.revenue`,0))) }</Column>
        { months.map(date => (<Column>{ format(multiplier*(getViaKey(values,`repeat NTF.${date}.revenue`,0))) }</Column>) ) }
        { months.map(date => (<Column bold>{ format(multiplier*(getViaKey(values,`NTF.${date}.cumulative.revenue`,0))) }</Column>) ).slice(-1) }
      </Row>

      <Row hover light>
        <Column padded="20">NTF Cumulative</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(multiplier*(getViaKey(values,`NTF.${date}.cumulative.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>


      {/* TODO: Move these to total row 
      <Row hover light>
        <Column padded="20">Cumulative COGS</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(multiplier*(getViaKey(values, `NTF.${date}.cumulative.revenue`,0) + getViaKey(values,`Existing.${date}.cumulative.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>
      */}

    </React.Fragment>
  )
}

export default COGS;
