import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Button, Table, Icon } from 'semantic-ui-react';
import ConverterDetails from './ConverterDetails';

const ExpandedRow = ({item}) => <ConverterDetails item={item} />

const NewToFileCell = ({item, col}) => item[col.key] === 1 ? <Table.Cell style={{textAlign: 'center'}}><Icon name='checkmark'/></Table.Cell> : <Table.Cell></Table.Cell>;

class CustomerEventsTable extends Component {
  render() {
    const { linkBase, customerData, resolved, filteredData, exclusions, id, cols, tierColors } = this.props;
    //const linkBase = `/v2/customers/path/view/${id}/`;
    const ProfileButton = ({item}) => <Table.Cell><Button as={Link} to={linkBase + item.uid} icon="user"/></Table.Cell>

    if (!resolved || !tierColors) return <ContentCard.CardLoader />

    const colsWithColorMap = cols.map(x => {
      if (x.key === 'path_deduplicated'){
        x['colorMap'] = tierColors
      }
      return x
    })

    return (
      <PagedIndexGrid
        expandable={true}
        idKey="conversion_hash_id"
        expandedComponent={ExpandedRow}
        data={filteredData}
        cols={cols}
        itemsPerPage={50}
        as={ContentCard.Table}
        linkBase={linkBase}
      />
    );
  }
}

export default CustomerEventsTable;
