import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import { ContentCard } from '@rockerbox/styleguide';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { Button, Header, Label, Form, Dropdown } from 'semantic-ui-react';
import Container from '../parts/Container';
import * as identity from '../../identity';

export const Flex = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  flex-direction: column;
  ${props => props.grey ? "border-radius:5px;padding:10px;margin:10px;background-color: " + identity.GREY2 : undefined }
`; 
export const SourceLabel = styled.label`
  color: ${identity.GREY3} !important;
`; 
export const ControlButton = styled(Button)`
  background-color: ${identity.BLUE} !important;
  color: white !important;
  ${props => props.withMargin ? "margin-top: 20px !important;" : undefined }
  ${props => props.withMargin ? "margin-bottom: 20px !important" : undefined }
  ${props => props.inline ? "display: inline-block" : undefined }
`; 
export const FormControls = styled(Container)`
  margin-top: 20px;
`; 
export const SourceInput = styled(Form.Input)`
  .ui.input input {
    color: ${props => props.boundValue ? `${identity.BLUE2}` + '!important' : 'black'};
  }
`; 
export const Field = styled(Form.Field)`
  flex: ${props => props.width ? props.width : 1};
  margin-right: 10px !important;
`; 
export const ProTip = styled.span`
  margin-left: 5px;
  color: ${identity.BLUE2};
  opacity: .5;
`; 
export const LightBulb = styled(FontAwesome)`
  margin-right: 5px;
  color: ${identity.BLUE2};
`; 
export const Line = styled(Container)`
  border-bottom: 1px solid #9B9B9B;
  margin-top: 20px;
  margin-bottom: 20px;
`; 
export const FieldRow = styled(Form.Field)`
  width: 100%;
`; 
export const Connector = styled(Container)`
  top: 20px;
  left: 20px;
  z-index: -1;
  position: absolute;
  border: 1px dashed ${identity.BLUE};
`;
export const Wrapper = ({title, linkTitle, linkTo, children}) => (
  <ContentCard title={title}
    topRight={
      <Link to={linkTo}>
        <Icon name="angle left" /> { linkTitle }
      </Link>
    }>
    { children }
  </ContentCard>
)
