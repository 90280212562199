
export const getMetaDataReporting = (identifier, startDate, endDate, dataset) => {

  if(dataset == "time_to_conversion") {
    const statsBase = `/data-reporting/time-to-conversion/cache/hash`
    const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`

    return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())

  } else {
    const statsBase = "/data/v3/stats/attribution_events"
    const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}&keys=${dataset}`;

    return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())
    .then(({ response: { current }}) => current)
  }

//  const reportName = "spend-mta" 
//  const statsBase = `/data-reporting/${reportName}/warehouse/hash`
//  const statsUrl = `${statsBase}?start_date=${"2023-03-08"}&end_date=${"2023-03-08"}&identifier=${identifier}` 


}

export const getMetaData = (identifier, startDate, endDate, dataset) => {
  const statsBase = `/report_cache/stats/${dataset}`;
  const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`;

  return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())
    .then(({ current }) => current)
    //.then(({ current }) => reportCacheDataRangeStatsToDateMap(current))
    //.then(dateMapObjectToDateMapCacheStrings)
}

// export const getMetaData = (identifier, startDate, endDate, dataset) => {
//   const reportName = "spend-mta" 
//   const statsBase = `/data-reporting/${reportName}/cache/hash`
//   const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`

//   return fetch(statsUrl, { credentials: "include"})
//     .then(response => response.json())
//     .then(data => data)
// }
