import React, { Component } from 'react';
import { ContentCard, PagedIndexGrid, IndexGrid } from '@rockerbox/styleguide';
import { Form, Grid, Input, Button, Message, Table } from 'semantic-ui-react';
import { getExcludedIdentifiers, postExcludeIdentifier, deleteExcludeIdentifier } from '../../utils/api';


class ExcludeUsers extends Component{
  state = {
    identifier: '', // identifier value to be created
    loading: false,
    error: false,
    excludeIdentifiers: [], //identifiers list
  }

  componentDidMount() {
    getExcludedIdentifiers()
      .then(data => {
        const excludeIdentifiers = data.response;
        this.setState({ excludeIdentifiers })
      });
  }

  handleChange = (event, data) => {
    const { value } = data;
    this.setState({ identifier: value})
  }

  handlePostSubmit = () => {
    const requestBody = {
      identifier: this.state.identifier && this.state.identifier.trim()
    };

    this.setState({loading: true});
    return postExcludeIdentifier(requestBody)
      .then(response => this.setState({loading: false, identifier: '', error: false, excludeIdentifiers: response}))
      .catch(err => this.setState({error: true, loading:false}))
  }

  // item is the record in the list, col is index
  handleDelete = (item, col) => {
    this.setState({loading: true});
    deleteExcludeIdentifier(item.id)
      .then(response => {
        this.setState({loading: false, identifier: '', error: false, excludeIdentifiers: response})})
      .catch(err => this.setState({error: true, loading:false}))
  }

  render() {
    const { identifier, loading, error, excludeIdentifiers } = this.state;
    const DeleteIdentifier = ({ item, col }) => {
      return (
        <Table.Cell collapsing>
           <IndexGrid.DeleteButton onDelete={() => this.handleDelete(item, col)}></IndexGrid.DeleteButton>
        </Table.Cell>
      )
    }
    const cols = [
      {display: 'Identifier', key: 'identifier'},
      {display: '', key: 'key', as: DeleteIdentifier}
    ];

    return (
      <ContentCard title="Excluded Customer Identifiers">
        <div style={{marginBottom: 14}}>
          <Input
            type="identifier"
            placeholder="rbox-12345678abcdedfgh"
            value={identifier}
            action
            onChange={this.handleChange}
            style={{width: 300}}
          >
            <input />
            <Button
              primary
              type="submit"
              onClick={this.handlePostSubmit.bind(this)}
              disabled={identifier === ''}
              loading={loading}
            >Add</Button>
          </Input>
          { error && (
            <Message negative>
              <p>This user can't be excluded</p>
            </Message>
          )}
        </div>
        <PagedIndexGrid data={excludeIdentifiers} cols={cols} itemsPerPage={10} collapsed/>
      </ContentCard>
    )
  }
}

export default ExcludeUsers
