import React from 'react'
import { Button } from 'semantic-ui-react'
import RocketIcon from '../../assets/RocketIcon.js'


const SetAGoalButton = ({onClick}) => (
  <Button
    inverted
    color="purple"
    onClick={onClick}
    style={{ padding: '11px 13px', marginRight: 10, fontSize: 14 }}
    className='remove-box-shadow'
  >
    <RocketIcon style={{display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }}/>
    Set a Goal
  </Button>
)

export default SetAGoalButton
