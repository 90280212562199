import React, { Component, useState } from "react";
import Proptypes from "prop-types";
import {
  ContentCard,
  IndexGrid,
  PagedIndexGrid,
  SortableIndexGrid,
  SearchableDropdown
} from "@rockerbox/styleguide";
import { Icon, Input, Button, Table, Popup, Form } from "semantic-ui-react";
import {
  setSavedBucketUrls,
  getAttributableEvents,
  getSegments,
  getMissingBuckets,
  getMissingBucketsTimeseries,
  getCreativeMissingBuckets,
  getMissingTransformBuckets
} from "../../utils/api";
import { withRouter } from "react-router-dom";
import * as Tree from "../TreeParts";
import * as routes from "../../routes";

// Rewrite with react hooks
const UnmappedEvent = props => {
  let [isLoading, setLoading] = useState(true);
  let [unmappedEvents, setUnmappedEvents] = useState([]);
  return <React.Fragment>Test</React.Fragment>;
};

// This will be deprecated when proccessed unmapped events is in onsite_events
const groupUnmappedEvents = events => {
  const result = [];
  const eventMap = events.reduce((prev, curr) => {
    const { original_url, request_referrer } = curr;
    const key = `${original_url},${request_referrer}`;
    if (prev[key] && Array.isArray(prev[key])) {
      prev[key].push(curr);
    } else {
      prev[key] = [curr];
    }
    return prev;
  }, {});

  return Object.keys(eventMap)
    .map(key => {
      const [url, referrer] = key.split(",");
      return {
        original_url: url,
        url,
        request_referrer: referrer,
        referrer,
        count: eventMap[key].length
      };
    })
    .sort((p, c) => c.count - p.count);
};

class UnmappedEvents extends Component {
  state = {
    loading: false,
    searchValue: "",
    attributable_events: [],
    missingData: [],
    data: [],
    missingTransformData: [],
    transformData: []
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    const check = ["id", "date"];

    if (check.filter(k => prevProps[k] != this.props[k]).length > 0) {
      this.getData();
    }
  }

  getData = () => {
    const { date } = this.props;
    this.setState({ loading: true });
    const getMissingTransformApiCalls = [
      getCreativeMissingBuckets(this.props.id, date),
      getMissingTransformBuckets(this.props.id, date),
      getSegments(),
      getMissingBuckets(this.props.id, date),
      getAttributableEvents()
    ];
    Promise.all(getMissingTransformApiCalls)
      .then(allResponse => {
        const missingTransformData = allResponse[0]
          .concat(allResponse[1])
          .sort((p, c) => c.count - p.count);
        const transformData = missingTransformData.map(row =>
          Object.assign(row, {
            tier_one: row.tier_one,
            tier_two: row.tier_two,
            tier_three: row.tier_three,
            tier_four: row.tier_four,
            tier_five: row.tier_five
          })
        );

        console.log("transformData");
        const _missingData = allResponse[3];
        const attributable_events = allResponse[4];
        const missingData = groupUnmappedEvents(_missingData);
        console.log("missingData");

        this.setState({
          missingTransformData,
          transformData,
          missingData,
          data: missingData,
          attributable_events,
          loading: false
        });
      })
      .then(() => this.setState({ loading: false }))
      .catch(e => console.log(e));
  };

  handleEditAction = (e, { name, value, rule_type }) => {
    const { data } = this.state;
    const { push } = this.props.history;

    const editRouteMapping = {
      mapping: routes.editMappedEvent,
      exclude: routes.editExcludedEvent
    };
    setSavedBucketUrls(data).then(_id => {
      if (editRouteMapping[rule_type])
        push(editRouteMapping[rule_type] + `/${value}/${_id}`);
    });
  };
  handleAction = (e, { name, value, type }) => {
    const { data } = this.state;
    const { push } = this.props.history;

    const routeMapping = {
      mapping: routes.createMappedEvent,
      exclude: routes.createExcludedEvent
    };

    setSavedBucketUrls(data).then(_id => {
      if (routeMapping[name]) push(routeMapping[name] + `/${_id}`);
    });
  };

  filterData = (evt, objdata) => {
    const { missingData, missingTransformData } = this.state;
    const { value } = objdata;
    const searchValue = value;
    if (value == "") {
      return this.setState({
        data: missingData,
        searchValue,
        transformData: missingTransformData
      });
    }

    const data = missingData.filter(row => {
      const hasUrl = row.original_url.includes(value);
      const hasReferrer = row.request_referrer.includes(value);
      return hasUrl || hasReferrer;
    });
    const transformData = missingTransformData.filter(row => {
      const hasUrl = row.original_url.includes(value);
      const hasReferrer = row.request_referrer.includes(value);
      return hasUrl || hasReferrer;
    });

    return this.setState({ data, searchValue, transformData });
  };

  render() {
    const { date } = this.props;
    const {
      loading,
      attributable_events,
      data,
      searchValue,
      transformData
    } = this.state;
    const forceLoading = loading;

    const editOptions = attributable_events.map(_event => {
      const rule_type = _event.rewrite_tier_1 == "" ? "exclude" : "mapping";
      return { key: _event.id, text: _event.name, value: _event.id, rule_type };
    });

    const dataCols = data.length
      ? [
          {
            display: "Url",
            key: "original_url",
            as: IndexGrid.EllipsedCell,
            headerWidth: 7
          },
          {
            display: "Referrer",
            key: "request_referrer",
            as: IndexGrid.EllipsedCell,
            headerWidth: 7
          },
          { display: "Count", key: "count", headerWidth: 2 }
        ]
      : [];

    const transformDataCols = transformData.length
      ? [
          {
            display: "Tier One",
            key: "tier_one",
            as: IndexGrid.EllipsedCell,
            headerWidth: 2
          },
          {
            display: "Tier Two",
            key: "tier_two",
            as: IndexGrid.EllipsedCell,
            headerWidth: 2
          },
          {
            display: "Tier Three",
            key: "tier_three",
            as: IndexGrid.EllipsedCell,
            headerWidth: 2
          },
          {
            display: "Tier Four",
            key: "tier_four",
            as: IndexGrid.EllipsedCell,
            headerWidth: 2
          },
          {
            display: "Tier Five",
            key: "tier_five",
            as: IndexGrid.EllipsedCell,
            headerWidth: 2
          },
          { display: "Count", key: "count", headerWidth: 2 }
        ]
      : [];

    return (
      <React.Fragment>
        <Tree.Row>
          <Tree.Column>
            <Input
              icon="search"
              type="text"
              placeholder="Find an event"
              value={searchValue}
              onChange={this.filterData}
            />
          </Tree.Column>
          <Tree.Column flex={10}>
            {searchValue.length > 0 && (
              <Form.Group style={{ marginLeft: "10px" }}>
                <Button name="mapping" onClick={this.handleAction}>
                  Create New Rule
                </Button>
                <Button name="exclude" onClick={this.handleAction}>
                  Create Exclusion
                </Button>
                <SearchableDropdown
                  options={editOptions}
                  onSelect={this.handleEditAction}
                  title="Choose Rule"
                >
                  <Button
                    icon="caret down"
                    labelPosition="right"
                    content="Edit Existing"
                  />
                </SearchableDropdown>
              </Form.Group>
            )}
          </Tree.Column>
          <Tree.Column />
        </Tree.Row>
        <ContentCard
          title={`Unmapped Events (${date})`}
          hasTable
          {...{ forceLoading }}
        >
          <PagedIndexGrid
            as={ContentCard.Table}
            fallBackMsg="No unmapped events"
            cols={dataCols}
            data={data}
            itemsPerPage={20}
            collapsed
          />
        </ContentCard>
        <ContentCard
          title={`Unmapped Tier Transform Events (${date})`}
          hasTable
          {...{ forceLoading }}
        >
          <PagedIndexGrid
            as={ContentCard.Table}
            fallBackMsg="No unmapped events"
            cols={transformDataCols}
            data={transformData}
            itemsPerPage={20}
            collapsed
          />
        </ContentCard>
      </React.Fragment>
    );
  }
}
export default withRouter(UnmappedEvents);
