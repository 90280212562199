import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, Message } from 'semantic-ui-react';
import { Aux, FormWrap, FieldWrap} from '../stateless';
import { ContentCard } from '@rockerbox/styleguide';
import { urlDecodeTierAfterSplit} from './helpers';
import { openIntercomMessenger } from '../Navigation/helpSection';

const ATTRIBUTABLE_NAMES = {
  "tier_1": "Bucket 1",
  "tier_2": "Bucket 2",
  "tier_3": "Bucket 3",
  "tier_4": "Bucket 4",
  "tier_5": "Bucket 5"
}

const NTF_OPTIONS = [
  {
    key: "All Customers",
    text: "All Customers",
    value: 0,
  },
  {
    key: "New Customers",
    text: "New Customers",
    value: 1,
  },
  {
    key: "Repeat Customers",
    text: "Repeat Customers",
    value: 2,
  },
]

class HeaderDetails extends Component {
  render() {
    const { props } = this
    const { selectedTiers, reportType, selected } = props
    const frd = selected.first_reporting_date;
    const fieldNameObject = ATTRIBUTABLE_NAMES;
    const modelSelection = (props.models.find(x => x.value == props.model ) || {})
    const groupSelection = (props.groups.find(x => x.value == props.group)  || {})
    const channelGroupSelection = props.channelGroups ? (props.channelGroups.find(x => x.value == props.channelGroup) || {}) : {};
    const selectedNtfOption = (NTF_OPTIONS.find(x => x.value == props.ntfFilter) || {})

    const tierKeys = Object.keys(props.tiers);
    const decodedSelectTiers = urlDecodeTierAfterSplit(selectedTiers);
    const tierLength = tierKeys.reduce((p,k) => {
      p[k] = (decodedSelectTiers[k] || []).length
      return p
    }, {});

    return (
      <Aux>
        <ContentCard title='View' borderless collapsing>
          <FormWrap>
            <FieldWrap>
              <label>Attribution Type</label>
              <Select
                text={modelSelection.text} onChange={(_, x) => props.selectModel(x) } options={props.models} fluid />
            </FieldWrap>
            <FieldWrap className='m-0'>
              <label>Group Data By</label>
              <Select
                fluid
                text={groupSelection.text}
                onChange={(_, x) => props.selectGroup(x) }
                options={props.groups} />
            </FieldWrap>

            { this.props.show_ntf && "ntf" != reportType &&
              <FieldWrap className='m-0'>
                <label>Customer Type</label>
                <Select
                  fluid
                  text={selectedNtfOption.text}
                  onChange={(_, x) => props.handleNtfChange(x)}
                  options={NTF_OPTIONS} />
              </FieldWrap>
            }

            { this.props.channelGroups && this.props.channelGroups.length > 0 &&
              <FieldWrap className='m-0'>
                <label>Channel Groups</label>
                <Select
                  clearable
                  fluid
                  defaultValue={channelGroupSelection.value}
                  onChange={(_, x) => props.selectChannelGroup(x) }
                  options={props.channelGroups} />
              </FieldWrap>
            }
            { (this.props.channelGroups && this.props.channelGroups.length == 0) && 
              <Message info>
                <p>Looking to filter by Paid Channels? <br /> <span style={{fontWeight: 'bold', cursor: 'pointer'}} onClick={openIntercomMessenger}>Contact us</span> to set up this feature</p>
              </Message>
            }
            </FormWrap>
        </ContentCard>
        <ContentCard title='Filters' borderless collapsing>
          <FormWrap>
            <FieldWrap>
              <label>Filter by Marketing Buckets</label>
              {
                tierKeys.map((key, i) => {
                  if (i != 0 && tierLength[tierKeys[i-1]] == 0) return null;

                  return (
                    <Select
                      fluid
                      value={decodedSelectTiers[key] || []}
                      placeholder={fieldNameObject[key] + " contains"}
                      search
                      multiple
                      onChange={(_, x) => props.selectTier(key)(x) }
                      options={props.tiers[key].filter(x =>
                        x.parentValues.reduce((p,v,i) => {
                            const t = x.parentTiers[i]
                          return (decodedSelectTiers[t].indexOf(v) > -1) ? p + 1 : p
                        },0) == x.parentValues.length
                      )}
                    />
                  )
                })
              }
            </FieldWrap>
          </FormWrap>
        </ContentCard>
      </Aux>
    )
  }
}
export default withRouter(HeaderDetails);
