import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Form, Icon } from 'semantic-ui-react';
import DataSheet, { DataEditor } from 'react-datasheet';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datasheet/lib/react-datasheet.css';
import './datasheet.css';
import styled from 'styled-components';
import StatusCell from './StatusCell';
import SearchCell from './SearchCell';

const EditorWrapper = styled.div`
  & > .data-editor {
    height: 2em !important;
  }
`

const numberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}
const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);

const COMPONENTS = {
  default: (props) => <EditorWrapper><DataEditor  {...props} /></EditorWrapper>,
  date: (props) => {
    const { onCommit, value } = props
    return <DatePicker utcOffset={0} open onChange={(value) => onCommit(moment.utc(value).format("YYYY-MM-DD"))} />
  },
  select: (props) => {
    const { onCommit, value, column } = props
    const { options, editProps } = column
    return <Form.Dropdown search options={options} {...props} {...editProps} value={false} onChange={(_, { value }) => { onCommit(value) } } />
  },
  currency: (props) => {
    const { onChange } = props
    return <DataEditor style={{width:"100%"}} {...props} onChange={(value) => onChange(value.replace("$",""))} />
  },
  percent: (props) => {
    const { onChange } = props
    return <DataEditor style={{width:"100%"}} {...props} onChange={(value) => onChange(value.replace("%",""))} />
  }
}

const HoverWrapper = styled.div`
  opacity: .5;
  width: 110%;
  text-align: center;

  &:hover {
    opacity:1
  }
`

const DataTable = ({ columns, data, onUpdate, onRemove, rowProps, readOnly }) => {

  const [selected, setSelected] = React.useState(false)
  const gridValues = data.map(row => columns.map(({ key, readOnly })=> { return {value: row[key], readOnly }}) )
  const grid = gridValues
  const cols = columns.map(({ key, display, style }) => { return { key, display, style }}) 

  const onChange = (cloned) => ({cell, row, col, value}) => {
    cloned[row][cols[col].key] = value
  }

  const onCellsChanged = (array, additions) => {
    const cloned = _.cloneDeep(data)
    const update = onChange(cloned)
    array.forEach(valueUpdate => {
      update(valueUpdate)
    })
    if (additions && additions.length > 0) {
      const newValues = additions.map(row => { return {} })
      additions.forEach((valueUpdate, i) => {
        cloned.push(newValues[i])
        update(valueUpdate)
      })
    }

    // Race condition with keyboard events
    setTimeout(() => onUpdate(cloned.filter(row => Object.keys(row).length > 0)), 1)
  }

  const onValueRender = ((cell, row, col) => {
    const { type, asView } = columns[col]
    if (!cell.value || String(cell.value).trim().length == 0) return "";

    if (!!asView) {
      const Comp = asView
      return <Comp {...{cell, row: data[row], rowProps}} />
    }

    const value = String(cell.value)

    return type == "currency" ? `$${numberFormatter.format(value.replace("$",""))}` : 
      type == "percent" ? `${value.replace("%","")}%` : 
      type == "date" ? value : 
      type == "number" ? numberFormatter.format(value) : value
  })

  const onReadOnly = ((cell, row, col) => {
    cell.readOnly = true;
    return onValueRender(cell, row, col);;
  })

  return <DataSheet 
    readOnly='true'
    onCellsChanged={onCellsChanged}
    data={grid} 
    sheetRenderer={props => (
      <table className={props.className + ' my-awesome-extra-class'} style={{width:"100%"}} >
          <thead>
              <tr style={{height:"1.9em",lineHeight:"1.9em"}}> 
                { onRemove && <th className='cell read-only' style={{height:"1.9em",width:"1.9em"}} ></th> }
                { cols.map(({ style, display }) => (
                    <th {...{style}} className='cell read-only'>
                      {display}
                    </th>
                  ))
                } 
              </tr>
          </thead>
          <tbody> {props.children} </tbody>
      </table>
    )}
    rowRenderer={(props) => <tr style={{height:"2.0em",lineHeight:"2.0em"}}> 
      { onRemove && <td><HoverWrapper onClick={() => onRemove(data[props.row])}><Icon name='remove' /></HoverWrapper></td> }
      {props.children} 
    </tr> }
    valueRenderer={readOnly ? onReadOnly : onValueRender}
    dataEditor={props => { 
      const { col } = props
      const column = columns[col]
      const { type, asEdit } = column
      const Component = asEdit || COMPONENTS[type] || COMPONENTS.default

      return <Component {...props} {...{column}} />
    }}
  />
}

DataTable.propTypes = {
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    type: PropTypes.string,
    asCell: PropTypes.element,
    asEdit: PropTypes.element
   })).isRequired,
  /** */
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool
}

export default Object.assign(DataTable, { StatusCell, SearchCell });
