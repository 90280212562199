import React, { Component } from 'react';
import SpendDataAccount from './SpendDataAccount'

class SpendIndex extends Component {

  render() {
    return (
      <React.Fragment>
        <SpendDataAccount />
      </React.Fragment>
    )
  }
}

export default SpendIndex;
