import React, { Component, useEffect } from "react";
import { Route } from "react-router-dom";
import ButterToast from "butter-toast";

import * as routes from "../../routes";
import { Aux } from "../stateless";
import { ContentWrap } from "@rockerbox/styleguide";
import ConnectedData from "./data";
import GlobalApplicationData from "./globalApplicationData";
import GlobalErrorBoundary from "../GlobalErrorBoundary";
import TrackPageChange from "../TrackPageChange";
import MediaMixModel from "../MediaMixModel";

import Account from "../Account";
import AddressDataSource from "../DataSources/AddressDataSource";
import Announcements from "../Announcements";
import AttributableEvent from "../AttributableEvent";
import AuthorizationsIndex from "../Integrations/Authorizations";
import Attribution from "../ViewAttribution";
import BatchConfigEdit from "../BatchConfig/BatchEdit";
import BatchConfigIndex from "../BatchConfig";
import BatchConfigProcessedView from "../BatchConfig/ProcessedView";
import BatchInboxView from "../BatchConfig/InboxMessages";
import ChannelGroups from '../ChannelGroups';
import ClickDataSource from "../DataSources/ClickDataSource";
import CohortLTV from "../CohortLTV";
import CohortSummary from "../CohortSummary";
import Communications from "../Communications";
import ConversionEventIndex from "../ConversionIndex";
import ConversionBackfill from "../ConversionIndex/DatasetEvaluation";
import TriggerIndex from "../Triggers";
import ConversionSegment from "../ConversionSegment";
import CrossDeviceCreationIndex from "../CrossDevice/CrossDeviceCreation";
import CrossDeviceEditIndex from "../CrossDevice/CrossDeviceEdit";
import CrossDeviceIndex from "../CrossDevice";
import CreativeDataSource from "../CreativeDataSource/index";
import CustomerDataSource from "../DataSources/CustomerDataSource";
import { CustomerJourney } from "../CustomerJourney";
import CustomersTable from "../CustomersTable";
import Dashboard from "../Dashboard";
import Datasets from "../Datasets";
import DataSources from "../DataSources";
import DestinationIndex from "../Destination";
import Diagnostics from '../Diagnostics';
import EditorForm from "../ReportIndex/EditorForm";
import EntitiesCreate from "../Entities/Create";
import EntitiesIndex from "../Entities";
import EventActivity from "../AttributableActivity";
import EventTags from "../EventTags";
import EventTypes from '../EventTypes';
import ExcludeEvents from "../AttributableIndex/exclude";
import ExternalDataSource from "../DataSources/ExternalDataSource";
import Facebook from "../Facebook";
import FacebookAds from "../Facebook/Ads";
import FacebookComparison from "../Facebook/Comparison";
import GoogleAnalyticsComparison from "../GoogleAnalyticsComparison";
import IncrementalityAnalysisIndex from '../IncrementalityAnalysis';
import InfluencerResults from "../InfluencerResults";
import IntegrationsIndex from "../Integrations";
import MappedEvents from "../AttributableIndex";
import MappedOverview from "../AttributableOverview";
import MappingChecker from "../MappingChecker";
import Model from "../Model";
import ModelInteractive from "../ModelInteractive";
import ModelValidation from "../ModelValidation";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import OutboxSetup from "../Destination/OutboxSetup";
import OTTView from '../OTT';
import PixelDataSource from "../DataSources/PixelDataSource";
import PixelLive from "../PixelLive";
import PixelView from "../Pixels";
import PostlogLift from "../PostlogLift";
import PromoResults from "../PromoCodes";
import ReportCustomize from "../ReportCustomize";
import ReportDownload from "../AnalyticDownloads";
import ReportIndex from "../ReportIndex";
import ReportInteractive from "../ReportInteractive/index";
import ReportSchema from "../ReportSchema";
import ReportStatus from "../ReportDeliveryStatus";
import Shopify from "../Shopify";
import SpendIndex from "../SpendIndex";
import SpendMappings from "../SpendMappings";
import SurveyForm from "../Survey/SurveyForm";
import SurveyIndex from "../Survey";
import SurveyResults from "../SurveyResults";
import TemporalDataSource from "../DataSources/TemporalDataSource";
import TransformTables from "../TransformTables";
import UserLookup from "../UserLookup";
import ViewConversionSegment from "../ConversionSegment/view";
import WebhooksIndex from "../Webhooks";
import WebhooksConfig from "../Webhooks/WebhooksConfig";

import V3UIButton from '../parts/V3UIButton';
import { funnelPosition, marketingPaths, newVisitors } from '../../V3/baseRoutes'

const routeConfig = [
  // { name: "reporting", displayName: "Analytics", iconName: "chart pie", route: "/v2/reporting", lite: true },
  // { name: "customers", displayName: "Customers", iconName: "users", route: "/v2/customers", lite: true },
  { name: "settings", displayName: "Settings", iconName: "cogs", route: "/v2/settings", lite: true },
  { name: "channels", displayName: "Channels", iconName: "sitemap", route: "/v2/channels", lite: true },
  { name: "model", displayName: "Model", iconName: "flask", route: "/v2/models" },
  { name: "data", displayName: "Data", iconName: "database", route: "/v2/admin", lite: true },

  // { name: "Dashboard", icon: false, route: routes.dashboard, parent: "/v2/reporting", lite: true },
  // { name: "Reports", icon: false, route: routes.conversionData, parent: "/v2/reporting", lite: true },
  // { name: "New Visitors", icon: false, route: routes.acquisitionIndex, parent: "/v2/reporting", lite: true },
  // { name: "Downloads", icon: false, route: routes.reportsDownload, parent: "/v2/reporting", lite: true },
  // { name: "Survey", icon: false, route: routes.surveyResults, parent: "/v2/reporting" },
  // { name: "Sponsorships", icon: false, route: routes.sponsorshipsResults, parent: "/v2/reporting" },
  // { name: "Experiments", icon: false, route: routes.incrementalityAnalysis, parent: "/v2/reporting"},
  // { name: "Marketing Sources", icon: false, route: routes.marketingSources, parent: "/v2/reporting", lite: true},
  
  // { name: "Paths", icon: false, route: routes.customerData, parent: "/v2/customers", lite: true },
  // { name: "Funnel", icon: false, route: routes.customerFunnel, parent: "/v2/customers", lite: true },
  // { name: "Converters", icon: false, route: routes.customerTablesView, parent: "/v2/customers", lite: true },
  // { name: "Inspect", icon: false, route: routes.customerJourney, parent: "/v2/customers", lite: true },
  // { name: "LTV Cohorts", icon: false, route: routes.cohortLTV, parent: "/v2/customers", feature: 'ltv' },
  //{ name: 'Monthly Cohorts', icon: false, route: routes.cohortSummary, parent: '/v2/customers' },
  
  { name: "Results", icon: false, route: routes.modelsView, parent: "/v2/models" },
  // { name: "Interactive", icon: false, route: routes.modelsInspect, parent: "/v2/models" },
  { name: "Datasets", icon: false, route: routes.modelsValidation, parent: "/v2/models" },
  // { name: "MMM", icon: false, route: routes.modelsMMM, parent: "/v2/models" },
  
  // { name: "Overview", icon: false, route: routes.mappedEventOverview, parent: "/v2/channels", lite: true },
  { name: "Rules", icon: false, route: routes.mappedEventIndex, parent: "/v2/channels", lite: true },
  { name: "Exclusions", icon: false, route: routes.excludedEventIndex, parent: "/v2/channels", lite: true },
  { name: "Transform Tables", icon: false, route: routes.transformTablesIndex, parent: "/v2/channels", lite: true },
  { name: "Groups", icon: false, route: routes.channelGroups, parent: "/v2/channels", lite: true },
  { name: "Tags", icon: false, route: routes.eventTagsIndex, parent: "/v2/channels" },
  { name: "Promo Codes", icon: false, route: routes.promoResults, parent: "/v2/channels" },
  { name: "Mapping Checker", icon: false, route: routes.mappingChecker, parent: "/v2/channels" },

  { name: "Account", icon: false, route: routes.account, parent: "/v2/settings", lite: true },
  { name: "Integrations", icon: false, route: routes.integrationsIndex, parent: "/v2/settings", lite: true },
  { name: "Conversions", icon: false, route: routes.conversionEventIndex, parent: "/v2/settings", lite: true },
  // { name: "Survey", icon: false, route: routes.surveyIndex, parent: "/v2/settings" },
  // { name: "Sponsorships", icon: false, route: routes.entitiesIndex, parent: "/v2/settings" },
  { name: "OTT", icon: false, route: routes.ottIndex, parent: "/v2/settings" },
  { name: "Postlog", icon: false, route: routes.postlog, parent: "/v2/settings" },
  { name: "Datasets", icon: false, route: routes.datasetsIndex, parent: "/v2/settings", lite: true },
  { name: "Exports", icon: false, route: routes.reports, parent: "/v2/settings", lite: true },
  { name: "Destinations", icon: false, route: routes.destinationIndex, parent: "/v2/settings" },
  
  { name: "Pixels", icon: false, route: routes.pixelView, parent: "/v2/admin", lite: true },
  { name: "Pixel Status", icon: false, route: routes.pixelLive, parent: "/v2/admin", lite: true },
  { name: "Webhooks", icon: false, route: routes.webhooksIndex, parent: "/v2/admin", lite: true },
  { name: "Batch", icon: false, route: routes.batchConfigIndex, parent: "/v2/admin", lite: true },
  { name: "Triggers", icon: false, route: routes.triggerIndex, parent: "/v2/admin", lite: true },
  { name: "Data Sources", icon: false, route: routes.dataSources, parent: "/v2/admin" },
  // { name: "Spend", icon: false, route: routes.spendIndex, parent: "/v2/admin" },
  // { name: "Diagnostics", icon: false, route: routes.diagnosticsIndex, parent: "/v2/admin" },
  //{ name: 'Authorizations', icon: false, route: routes.authorizationsIndex, parent: '/v2/settings'},
];

const Navbar = () => <Navigation allRoutes={routeConfig} />

const V2 = props => {
  useEffect(() => {
    document.body.style.backgroundColor = '#F3F3F5'
  }, [])

  return (
    <Aux className="App rockerbox">
      <GlobalApplicationData />
      <ConnectedData />
      <ButterToast trayPosition="bottom-left" />
      <TrackPageChange path={props.location.pathname} />
      <Route path="/" component={Navbar} />
      <ContentWrap>
        <Announcements />
        <Communications />
        <Notifications />
        <GlobalErrorBoundary>
          {/********** REPORTING *************/}
          <Route exact path={routes.conversionData + "/:id?/:startDate?/:endDate?/:reportType?/:model?/:channelGroup?/:ntfFilter?/:group?/:tier_1?/:tier_2?/:tier_3?/:tier_4?/:tier_5?"} component={Attribution} />
          <Route exact path={routes.dashboard} component={Dashboard} />
          <Route exact path={routes.postlog + "/:id?/:startDate?/:endDate?"} component={PostlogLift} />
          
          {/********** CUSTOMERS *************/}
          <Route exact path={routes.acquisitionIndex + "/:startDate?/:endDate?/:reportType?/:group?/:tier_1?/:tier_2?/:tier_3?/:tier_4?/:tier_5?"} render={() => <V3UIButton url={newVisitors} />} />
          {/*<Route exact path={routes.cohortLTV + '/:id?'} component={CohortLTV} />*/}
          <Route exact path={routes.cohortLTV + "/:id?/:how?/:startCohort?/:endCohort?/:tier_1?/:bucketBy?/:numPeriods?/:tier_group?"} component={CohortLTV} />
          <Route exact path={routes.cohortSummary + "/:id?"} component={CohortSummary} />
          <Route exact path={routes.customerData + "/:id?/:startDate?/:endDate?"} render={() => <V3UIButton url={marketingPaths} />} />
          <Route exact path={routes.customerFunnel + "/:id?/:startDate?/:endDate?/:reportType?/:customerType?/:minPathLen?/:any?/:first?/:mid?/:last?"} render={() => <V3UIButton url={funnelPosition} />} />
          <Route exact path={routes.customerJourney} component={CustomersTable} />
          <Route exact path={routes.customerJourney + "/:id/:uid"} component={UserLookup} />
          <Route exact path={routes.customerTablesView + "/:viewType?/:id?/:startDate?/:endDate?/:ntfFilter?/:tier1?"} component={CustomersTable} />
          <Route exact path={routes.marketingSources + "/:startDate?/:endDate?"} component={EventTypes} />
          <Route path={routes.incrementalityAnalysis + "/:view?/:id?"} component={IncrementalityAnalysisIndex} />
          <Route exact path={routes.reportsDownload + "/:category?/:id?"} component={ReportDownload} />
          <Route exact path={routes.sponsorshipsResults + "/:view?/:id?/:startDate?/:endDate?" } component={InfluencerResults} />
          <Route exact path={routes.surveyResults + "/:view?/:id?/:startDate?/:endDate?"} component={SurveyResults} />
        
          {/********** CHANNELS *************/}
          <Route path={routes.channelGroups} component={ChannelGroups} />
          <Route exact path={routes.copyMappedEvent + "/:id/:urlId?"} component={AttributableEvent} />
          <Route exact path={routes.createExcludedEvent + "/:urlId?"} component={AttributableEvent} />
          <Route exact path={routes.createMappedEvent + "/:urlId?/:t1?/:t2?"} component={AttributableEvent} />
          <Route exact path={routes.editExcludedEvent + "/:id/:urlId?"} component={AttributableEvent} />
          <Route exact path={routes.editMappedEvent + "/:id/:urlId?"} component={AttributableEvent} />
          <Route exact path={routes.excludedEventIndex} component={ExcludeEvents} />
          <Route path={routes.eventTagsIndex} component={EventTags} />
          <Route exact path={routes.mappedEventIndex} component={MappedEvents} />
          <Route exact path={routes.mappedEventOverview + `/:id?`} component={MappedOverview} />
          <Route exact path={routes.mappingChecker} component={MappingChecker} />
          <Route exact path={routes.promoResults} component={PromoResults} />
          <Route path={routes.transformTablesIndex} component={TransformTables} />

          {/********** MODELS *************/}
          <Route exact path={routes.modelsInspect} component={ModelInteractive} />
          <Route exact path={routes.modelsValidation + "/:featuredId?/:touchpointId?/:datasetName?/:startDate?/:endDate?/:viewDate?"} component={ModelValidation} />
          <Route exact path={routes.modelsView + "/:id?/:run_id?/:view?/:comparisonId?"} component={Model} />
          <Route exact path={routes.modelsMMM} component={MediaMixModel} />
        
          {/********** SETTINGS *************/}
          <Route exact path={routes.account + "/:index?/:menuItem?"} component={Account} />
          <Route exact path={routes.authorizationsIndex} component={AuthorizationsIndex} />
          <Route exact path={routes.conversionEventBackfill + "/:startDate?/:endDate?/:datasetType?/:segmentType?"} component={ConversionBackfill} />
          <Route exact path={routes.conversionEventIndex} component={ConversionEventIndex} />
          <Route exact path={routes.createConversionEvent} component={ConversionSegment} />
          <Route path={routes.datasetsIndex} component={Datasets} />
          <Route exact path={routes.destinationIndex + "/:id?"} component={DestinationIndex} />
          <Route exact path={routes.destinationIndex + "/id/:id"} component={OutboxSetup} />
          <Route exact path={routes.editConversionEvent + "/:id"} component={ConversionSegment} />
          <Route exact path={routes.entitiesCreate + "/:entityType?"} component={EntitiesCreate} />
          <Route exact path={routes.entitiesEdit + "/:id"} component={EntitiesCreate} />
          <Route exact path={routes.entitiesIndex} component={EntitiesIndex} />
          <Route exact path={routes.entitiesIndex + "/view/:activeStep?/:showCreate?" } component={EntitiesIndex} />
          <Route exact path={routes.integrationsIndex + "/:platform?/:feature?/:startDate?/:endDate?"} component={IntegrationsIndex} />
          <Route exact path={routes.reportCustomize + "/:id"} component={ReportCustomize} />
          <Route exact path={routes.reportInteractive + "/:id"} component={ReportInteractive}  />
          <Route exact path={routes.reports + "/:category?/:id?"} component={ReportIndex} />
          <Route exact path={routes.reportSchema + "/:id"} component={ReportSchema} />
          <Route exact path={routes.reportStatus + "/:id"} component={ReportStatus} />
          <Route exact path={routes.savedReportEditor + "/:id"} component={EditorForm} />
          <Route exact path={routes.surveyCreate} component={SurveyForm} />
          <Route exact path={routes.surveyEdit + "/:id"} component={SurveyForm} />
          <Route exact path={routes.surveyIndex} component={SurveyIndex} />
          <Route exact path={routes.ottIndex + "/event/create"} component={CrossDeviceCreationIndex} />
          <Route exact path={routes.ottIndex + "/event/edit/:id?"} component={CrossDeviceEditIndex} />
          <Route exact path={routes.ottIndex} component={OTTView} />
          <Route exact path={routes.viewConversionEvent + "/:id"} component={ViewConversionSegment} />        
        
          {/********** ADMIN *************/}
          <Route exact path={routes.batchConfigCreate + "/:inboxId?"} component={BatchConfigEdit} />
          <Route exact path={routes.batchConfigEdit + "/:id/:results?"} component={BatchConfigEdit} />
          <Route exact path={routes.batchConfigIndex} component={BatchConfigIndex} />
          <Route exact path={routes.batchConfigIndex + "/index/:inboxId?"} component={BatchConfigIndex} />
          <Route exact path={routes.batchConfigView + "/:id"} component={BatchConfigProcessedView} />
          <Route exact path={routes.batchInboxView + "/:inboxId"} component={BatchInboxView} />
          <Route exact path={routes.copySpendMapping + "/:id"} component={SpendMappings} />
          <Route exact path={routes.createSpendMapping} component={SpendMappings} />
          <Route exact path={routes.editSpendMapping + "/:id"} component={SpendMappings} />
          <Route exact path={routes.dataSources} component={DataSources} />
          <Route exact path={routes.dataSources + "/view/pixel_data"} component={PixelDataSource} />
          <Route exact path={routes.dataSources + "/view/external_data"} component={ExternalDataSource} />
          <Route exact path={routes.dataSources + "/view/creative_data/:firstMenu?/:secondMenu?/:startDate?/:endDate?"} component={CreativeDataSource} />
          <Route exact path={routes.dataSources + "/view/click_data"} component={ClickDataSource} />
          <Route exact path={routes.dataSources + "/view/customer_data"} component={CustomerDataSource} />
          <Route exact path={routes.dataSources + "/view/address_data"} component={AddressDataSource} />
          <Route exact path={routes.dataSources + "/view/temporal_data/:startDate?/:endDate?"} component={TemporalDataSource} />
          <Route exact path={routes.dataSources + "/view/shopify"} component={Shopify} />
          <Route exact path={routes.dataSources + "/view/fb_data"} component={Facebook} />
          <Route exact path={routes.dataSources + "/view/fb_data/synthetic/:type/:id?/:agg?"} component={FacebookComparison} />
          <Route exact path={routes.dataSources + "/view/fb_data/ads_data/:type/:id?"} component={FacebookAds} />
          <Route exact path={routes.diagnosticsIndex + "/:startDate?/:endDate?"} component={Diagnostics} />
          <Route exact path={routes.pixelLive} component={PixelLive} />
          <Route exact path={routes.pixelView} component={PixelView} />
          <Route exact path={routes.spendIndex} component={SpendIndex} />
          <Route path={routes.triggerIndex} component={TriggerIndex} />
          <Route exact path={routes.viewSpend + "/:activePlatform?/:activeAccountRowId?/:activeAssignmentId?"} component={SpendIndex} />
          <Route exact path={routes.webhooksCreate + "/:id"} component={WebhooksConfig} />
          <Route exact path={routes.webhooksEdit + "/:id/:messageId?/:results?"} component={WebhooksConfig} />
          <Route exact path={routes.webhooksIndex} component={WebhooksIndex} />
        
          {/********** OTHER *************/}
          <Route exact path={routes.activity} component={EventActivity} />
          <Route exact path={routes.dataSources + "/view/ga_data"} component={GoogleAnalyticsComparison} />
        </GlobalErrorBoundary>
      </ContentWrap>
    </Aux>
  )
};

export default V2;
