import React from 'react'
import {
  CashIcon, TagIcon, ClipboardListIcon,
  ShoppingCartIcon, CursorClickIcon, FingerPrintIcon,
  DocumentDownloadIcon, TableIcon, DatabaseIcon,
  ChartSquareBarIcon, PresentationChartLineIcon, PresentationChartBarIcon,
  MapIcon, FilterIcon, UserAddIcon, 
  CalculatorIcon, BeakerIcon,
  GlobeIcon, ScaleIcon,
  ChartPieIcon, TrendingUpIcon,
  BookOpenIcon, ChatAlt2Icon, AnnotationIcon, LightBulbIcon } from '@heroicons/react/outline'

export const LOGOUT = '/user/logout'
export const LOGIN = '/login'

import * as routes from '../../baseRoutes'
import * as v2Routes from '../../../routes'

export const HOME = routes.home
export const SETTINGS = routes.settingsIndex

export const NAV_MENU = [
  {
    name: 'Saved Reports',
    custom: true,
    children: [
      {
        name: 'Saved Reports',
        route: routes.savedReports
      }
    ]
  },
  {
    name: 'Data',
    multiSection: true,
    children: [
      {
        name: 'Collect Marketing Data',
        children: [
          {
            name: 'Advertising Platforms',
            description: 'Connect your advertising platforms and centralize your spend',
            icon: <CashIcon />,
            route: routes.advertisingPlatforms,
          },
          {
            name: 'Promo Codes',
            description: 'View promo code usage and associate them with marketing efforts',
            icon: <TagIcon />,
            route: routes.promoCodes,
          },
          {
            name: 'Surveys',
            description: 'Use survey data about how customers heard about your brand',
            icon: <ClipboardListIcon />,
            route: routes.surveys,
          },
        ]
      },
      {
        name: 'Track Customer Data',
        children: [
          {
            name: 'Orders & Conversions',
            description: 'Track the important customer actions that your marketing is driving towards',
            icon: <ShoppingCartIcon />,
            route: routes.conversionsOverview,
            lockedTiers: ['lite'],
          },
          // {
          //   name: 'Website Visitors',
          //   description: 'Collect insights on your website to identify visitors and their behaviors',
          //   icon: <CursorClickIcon />,
          //   route: v2Routes.pixelLive,
          //   lockedTiers: ['lite', 'starter'],
          //   external: true,
          // },
          // {
          //   name: 'Identity Resolution',
          //   description: 'Configure your first-party identity graph for your customers',
          //   icon: <FingerPrintIcon />,
          //   route: v2Routes.marketingSources,
          //   lockedTiers: ['lite', 'starter'],
          //   external: true,
          // },
        ],
      },
      {
        name: 'Data Exports',
        children: [
          {
            name: 'Ad Hoc File Exports',
            description: 'Run ad hoc exports of your Rockerbox data as CSV or Excel spreadsheets',
            icon: <DocumentDownloadIcon />,
            route: routes.adHocExportsHistory,
            lockedTiers: ['lite']
          },
          {
            name: 'Google Sheets Plugin',
            description: 'Use our Google Sheets plugin to export and sync your data in a spreadsheet',
            icon: <TableIcon />,
            artifactLink: 'google_sheets_plugin.link',
            external: true,
          },
          {
            name: 'Data Warehouse Sync',
            description: 'Configure live syncs of your data to data warehouses like Snowflake, AWS Redshift, and Google BigQuery',
            icon: <DatabaseIcon />,
            route: routes.datawarehouse,
          },
        ],
      },
    ]
  },
  {
    name: 'Attribution',
    children: [
      {
        name: 'Cross-Channel Report',
        description: 'View your overall marketing performance using multi-touch attribution',
        icon: <ChartSquareBarIcon />,
        route: routes.attributionReport,
        lockedTiers: ['lite'],
      },
      {
        name: 'Channel Performance',
        description: 'View your performance by looking at platform-reported and first-party metrics',
        icon: <PresentationChartLineIcon />,
        route: routes.digitalAdvertising,
      },
    ]
  },
  {
    name: 'Journeys',
    children: [
      {
        name: 'Marketing Paths',
        description: 'View the sequences of marketing touchpoints leading customers to convert',
        icon: <MapIcon />,
        route: routes.marketingPaths,
        lockedTiers: ['lite'],
      },
      {
        name: 'Funnel Position',
        description: 'Understand the role of each channel and make up of your overall marketing funnel',
        icon: <FilterIcon />,
        route: routes.funnelPosition,
        lockedTiers: ['lite'],
      },
      {
        name: 'Channel Overlap',
        description: 'View how different marketing channels intersect with each other to affect performance',
        icon: <ChartPieIcon />,
        route: routes.channelOverlap,
      },
      {
        name: 'New Visitors',
        description: 'Identify the top-of-funnel marketing driving net-new visitors to your website',
        icon: <UserAddIcon />,
        route: routes.newVisitors,
        lockedTiers: ['lite'],
      },
    ]
  },
  {
    name: 'Forecast',
    children: [
      {
        name: 'Marketing Mix Modeling',
        description: 'Plan budgets and predict future revenue using historical marketing performance',
        icon: <CalculatorIcon />,
        route: routes.marketingMixModeling,
      },
      // {
      //   name: 'Modeled Incrementality',
      //   description: 'Measure the incremental impact of each marketing channel',
      //   icon: <BeakerIcon />,
      //   lockedTiers: 'all',
      // },
    ]
  },
  // {
  //   name: 'Experiments',
  //   children: [
  //     {
  //       name: 'Geo Lift Testing',
  //       description: 'Design and analyze tests by varying budgets across different geographies',
  //       icon: <GlobeIcon />,
  //       lockedTiers: 'all',
  //     },
  //     {
  //       name: 'A/B Testing',
  //       description: 'Run an experiment by comparing a baseline against a set of ads',
  //       icon: <ScaleIcon />,
  //       route: v2Routes.incrementalityAnalysis,
  //       lockedTiers: ['lite', 'starter'],
  //       external: true,
  //     },
  //   ]
  // },
  {
    name: 'Benchmarks',
    children: [
      {
        name: 'Spend Trends',
        description: 'See how marketers are adjusting their budgets on different channels',
        icon: <PresentationChartBarIcon />,
        route: routes.spendTrends,
      },
      {
        name: 'Performance Benchmarks',
        description: 'View trends and benchmarks for advertising KPIs on popular digital platforms',
        icon: <TrendingUpIcon />, 
        route: routes.platformBenchmarks,
      },
    ]
  },
]

export const HELP_MENU = [
  {
    name: 'Help Center',
    description: 'How-to guides and documentation about the Rockerbox platform',
    icon: <BookOpenIcon />,
    external: true,
    onClick: () => window.open('https://help.rockerbox.com', '_blank'),
  },
  {
    name: 'Contact Support',
    description: 'Need help or experiencing issues? Get in touch with our team',
    icon: <ChatAlt2Icon />,
    onClick: () => !!window.Intercom && window.Intercom('show'),
  },
  {
    name: 'Submit Feedback',
    description: 'Have feedback? We\'d love to hear about your experience and how we can improve',
    icon: <AnnotationIcon />,
    onClick: ({ intercomSurveyId }) => {
      !!window.Intercom && window.Intercom('startSurvey', intercomSurveyId)
    },
  },
  {
    name: 'Product Roadmap',
    description: 'View what we\'re working on, vote on features, and submit ideas you\'d like to see',
    icon: <LightBulbIcon />,
    external: true,
    onClick: () => window.open('https://roadmap.rockerbox.com', '_blank'),
  },
]
