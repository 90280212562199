import React, { Component } from 'react';
import { ContentCard, ColumnLayout, IndexGrid, ExpandableIndexGrid, ScrollingPopup } from '@rockerbox/styleguide';
import { Menu, Icon, Table, Button, Popup } from 'semantic-ui-react';
import { getAttributableEvents, deleteAttributableEvent, getBucketsRuleTree, getSegments, getTierPreviews, setSavedBucketUrls } from '../../utils/api';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as routes from '../../routes';
import { withRouter, Link} from 'react-router-dom';
import moment from 'moment';
import { filterTree, leaves } from '../AttributableOverview/tree';

const StatusCell = ({item}) => {
  return (
    <Table.Cell>
      {item.matches.length > 0 ? <Icon name="check" color="green"/> : "None"}
    </Table.Cell>
  )
}

const Plus = (props) => (
  <Table.Cell>
    <Icon name={props.expand == props.item.id ? "minus":"plus"}/>
  </Table.Cell>
)

const ReferrerPopup = ({item}) => {
  const RefPopup = item.referrers.length > 3 ? ScrollingPopup : Popup;
  return (
    <Table.Cell>
      { item.referrers.length ?
          <RefPopup
            hoverable
            header="Referrers"
            position="left center"
            trigger={<div><Icon name="eye" /> { item.num_referrers }</div>}
            content={<div style={{maxWidth:"600px",wordWrap:"break-word"}}><ol>{item.referrers.map(v => <li style={{marginBottom: 10}}>{v}</li>)}</ol></div>}
          /> :
          item.num_referrers
      }
    </Table.Cell>
  )
}

const UrlPopup = ({item}) => {
  const UPopup = item.urls.length > 3 ? ScrollingPopup : Popup;
  return (
    <Table.Cell>
      { item.urls.length ?
          <UPopup
            hoverable
            header="Urls"
            position="left center"
            trigger={<div><Icon name="eye" /> { item.num_urls }</div>}
            content={<div style={{maxWidth:"600px",wordWrap:"break-word"}}><ol>{item.urls.map(v => <li style={{marginBottom: 10}}>{v}</li>)}</ol></div>}
          /> :
          item.num_urls
      }
    </Table.Cell>
  )
}

const MatchTable = ({matchArr, item}) => {
  const cols = [
    {display: "Bucket 3", key: "tier_3"},
    {display: "Bucket 4", key: "tier_4"},
    {display: "Bucket 5", key: "tier_5"},
    {display: "Urls", key: "urls", as: UrlPopup},
    {display: "Referrers", key: "referrers", as: ReferrerPopup}
  ];

  return (
    <React.Fragment>
      <IndexGrid cols={cols} data={matchArr}/>
    </React.Fragment>
  )
}

class AttributableIndex extends Component {
  state = {
    attributableEvents: [],
    transformAttributableEvents: [],
    tier1: '',
    tier2: '',
    tier2Values: [],
    tableData: [],
    tierPreviews: [],
    match: {},
    filteredTreeData: [],
    matchLookupTable: {},
    selectionType: "query_string"
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.match !== this.state.match) {
      const treeData = this.state.treeData;

      if (this.state.match) {
        const results = filterTree({"values":treeData}, this.state.match.referrer_pattern)
        const flatLeaves = leaves(results, [])
        this.setState({filteredTreeData: flatLeaves})
      }
    }
  }

  getData = () => {
    const apiCalls = [getAttributableEvents(), getTierPreviews(), getSegments()];

    Promise.all(apiCalls)
      .then( response => {
        const [ events, tierPreviews, segments] = response;

        const date = moment.utc().utcOffset(-5).subtract(1, 'days').format("YYYY-MM-DD");
        const segment = segments.find(c => c.touchpoints == 1);
        const all_pages = segment ? segment.filter_id : segments[0].filter_id;

        const transformEvents = events.filter(row => row.transform != false)
        const regularEvents = events.filter(row => row.transform == false)

        const attributableEvents = d3.nest()
        .key(x => x.rewrite_tier_1)
        .key(x => x.rewrite_tier_2)
        .entries(regularEvents)
        .filter(x => x.key !== "")

        const transformAttributableEvents = d3.nest()
          .key(x => x.rewrite_tier_1)
          .key(x => x.rewrite_tier_2)
          .entries(transformEvents)
          .filter(x => x.key !== "")

        const tier1 = attributableEvents[0].key;
        const tier2 = attributableEvents[0].values[0].key;
        const selectedEvent = attributableEvents.filter(c => c.key === tier1)[0];
        const tableData = selectedEvent.values.filter(c => c.key === tier2)[0].values;

        // TODO: reenable this at some point...

        //getBucketsRuleTree(all_pages, date)
        //.then(treeData => {
        //  this.runTableDataMatches(treeData, tableData)
        //})
        tableData.map(row => row.matches = [])
        const treeData = [];
        const matchLookupTable = {};
        
        // HACK: for the disable stuff above
        const state = {transformAttributableEvents, attributableEvents, tier1, tier2, tier2Values: selectedEvent.values, tierPreviews}
        Object.assign(state, {treeData, tableData, matchLookupTable})
        

        this.setState(state, () => this.findTierPreviewMatch())
      })
  }
  runTableDataMatches = (treeData, tableData) => {
    const matchLookupTable = treeData.reduce((p,c) => { p[c.match] = c; return p }, {});
    const updatedData = tableData.map(row => {
      const match = matchLookupTable[row.name];
      row.matches = this.addFlatLeaves(match);
    })

    this.setState({matchLookupTable, treeData, tableData});
  }

  handleTier1ColClick = (selectionType) => (tier1) => {

    const { transformAttributableEvents, attributableEvents, treeData } = this.state;

    const events = selectionType == "table" ? 
      transformAttributableEvents: 
      attributableEvents;

    const selectedEvent = events.filter(c => c.key === tier1)[0];
    const tier2 = selectedEvent.values[0].key;
    const tableData = selectedEvent.values.filter(c => c.key === tier2)[0].values;

    this.runTableDataMatches(treeData, tableData);

    this.setState({selectionType, tier1, tier2, tier2Values : selectedEvent.values}, () => {
      this.findTierPreviewMatch();
    });
  }

  handleTier2ColClick = (tier2) => {
    const { tier2Values, treeData } = this.state;
    const selectedData = tier2Values.filter(c => c.key === tier2)[0];
    const tableData = selectedData.values;

    this.runTableDataMatches(treeData, tableData);

    this.setState({tier2}, () => this.findTierPreviewMatch())
  }

  findTierPreviewMatch = () => {
    const { tier1, tier2, tierPreviews } = this.state;
    const match = tierPreviews.find(c => c.tier_1 == tier1 && c.tier_2 == tier2);

    this.setState({match});
  }

  addFlatLeaves = (match) => {
    const expandLeaves = (tree, leafValues) => {
      if (tree.values && tree.values.length > 0) {
        return tree.values.reduce((values, subtree) => {
          return expandLeaves(subtree, values)
        }, leafValues)
      }
      return leafValues.concat([tree])
    }

    const flatLeaves = match ? expandLeaves(match, []) : [];

    return flatLeaves;
  }

  expandedSection = ({item}) => {
    return <MatchTable matchArr={item.matches} item={item}/>
  }

  handleCreate = () => {
    const { filteredTreeData, tier1, tier2 } = this.state;
    const { push } = this.props.history;
    const urls = filteredTreeData.urls && filteredTreeData.urls.length > 0;
    const referrers = filteredTreeData.referrers && filteredTreeData.referrers.length > 0;

    if ( urls && referrers){
      setSavedBucketUrls(filteredTreeData)
      .then(_id => {
        push(`${routes.createMappedEvent}/${_id}/${tier1}/${tier2}`);
      })
    } else {
      push(`${routes.createMappedEvent}/0/${tier1}/${tier2}`);
    }
  }

  render() {
    const { selectionType, transformAttributableEvents, attributableEvents, tier2Values, tier1, tier2, tableData, matchLookupTable } = this.state;
    const ManageCell = ({ item }) => (
      <Table.Cell collapsing>
        <IndexGrid.EditButton url={`${routes.editMappedEvent}/${item.id}`}/>
        <IndexGrid.CopyButton url={`${routes.copyMappedEvent}/${item.id}`}/>
      </Table.Cell>
    )

    const cols = [
      {display: '', as: Plus},
      {display: 'Bucket 3', key: 'rewrite_tier_3'},
      {display: 'Bucket 4', key: 'rewrite_tier_4'},
      {display: 'Bucket 5', key: 'rewrite_tier_5'},
      {display: 'Priority', key: 'order'},
      {display: 'Rule Name', key: 'name'},
      {display: 'Matches', as: StatusCell},
      {display: 'Manage', as: ManageCell}
    ];

    return (
      <React.Fragment>
        <ContentCard
          title="Manage Channel Mapping Rules" noContent borderless noBackground
        />
        <ColumnLayout leftWidth={3} centerWidth={3} rightWidth={10}
          leftContent={
            <React.Fragment>
              <Menu vertical fluid>
                {attributableEvents.map(event => (
                  <Menu.Item onClick={() => this.handleTier1ColClick("query_string")(event.key)} active={tier1 === event.key && selectionType == "query_string"}>
                    <Menu.Header>
                      {event.key}
                      {tier1 === event.key && selectionType == "query_string" && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
                    </Menu.Header>
                  </Menu.Item>
                ))}
              </Menu>
              <h5>Using Platform Data</h5>
              <Menu vertical fluid>
                {transformAttributableEvents.map(event => (
                  <Menu.Item onClick={() => this.handleTier1ColClick("table")(event.key)} active={tier1 === event.key && selectionType == "table"}>
                    <Menu.Header>
                      {event.key}
                      {tier1 === event.key && selectionType == "table" && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
                    </Menu.Header>
                  </Menu.Item>
                ))}
              </Menu>
            </React.Fragment>
          }
          rightContent={
            <React.Fragment>
              <ContentCard hasTable>
                <ExpandableIndexGrid data={tableData} cols={cols} as={ContentCard.Table} fallbackMsg="No conversions" idKey="id" expandedComponent={this.expandedSection}/>
              </ContentCard>
              <Button
                primary
                compact
                content={'Create Rule'}
                onClick={this.handleCreate}
              />
            </React.Fragment>
          }
        >
          <React.Fragment>
            <Menu vertical fluid>
              {tier2Values.map(event => (
                <Menu.Item onClick={() => this.handleTier2ColClick(event.key)} active={tier2 === event.key}>
                  <Menu.Header>
                    {event.key}
                    {tier2 === event.key && <Icon name="chevron right" style={{float: 'right', margin: 0}}/>}
                  </Menu.Header>
                </Menu.Item>
              ))}
            </Menu>
          </React.Fragment>
        </ColumnLayout>
      </React.Fragment>
    )
  }
}

export default withRouter(AttributableIndex);
