export function getAnnouncements() {
  const url = `/announcements`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response);
}

export function getArtifacts() {
  const url = `/artifacts`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function getTierColors() {
  const url = `/account/settings/tier_colors`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(({ response }) =>
      response.reduce((acc, { tier_1, color }) => {
        acc[tier_1] = color;
        return acc;
      }, {})
    );
}

export function getPlatformComparisonConfigs() {
  const url = `/platform_comparison`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(({ response }) => response)
}

export function postPlatformComparisonConfigs(obj) {
  const url = '/platform_comparison';
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
  .then(data => {
    return data;
  });
}

export function deletePlatformComparisonConfigs(id) {
  const url = `/platform_comparison?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE",
  })
  .then(data => data.response);
}

export function getSegments() {
  let url = `/segments`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => (data.response));
}

export function getFeaturedSegment() {
  const url = `/segments`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(({ response }) => response.find(segments => !!segments.featured))
    .then(({ action_id }) => action_id);
}

export function getTrendCategories() {
  const url = `/trends/categories`
  return fetch(url, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(data => data.response)
}

export function getChannelTrends(category, categoryValue) {
  const params = !!category && !!categoryValue ? `?category=${category}&category_value=${categoryValue}` : ''
  const url = `/trends/channels${params}`
  return fetch(url, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(data => data.response)
}

export function postChannelRecommendations(obj) {
  const url = `/trends/recommendations`
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response)
}

export function postFormattedHelpDoc(slug, context) {
  const url = `/help_docs?slug=${slug}`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(context),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getSavedReports() {
  const url = `/saved_reports`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function postSavedReport(obj) {
  const url = `/saved_reports`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function putSavedReport(obj, id) {
  const url = `/saved_reports?id=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function deleteSavedReport(id) {
  const url = `/saved_reports?id=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getChannelRedistributions() {
  const url = `/channel_redistribution`
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function postChannelRedistribution(obj) {
  const url = `/channel_redistribution`;
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function putChannelRedistribution(obj, id) {
  const url = `/channel_redistribution?id=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function deleteChannelRedistribution(id) {
  const url = `/channel_redistribution?id=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getFacebookConversions(id) {
  const url = `/access/facebook/${id}/customconversions?fields=name`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response)
}

export function getLookupTables() {
  const url = `/uid_lookup_tables`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response)
}

export function getFacebookAutoParamsErrors(accountId) {
  const url = `/ads_data/facebook/accounting/errors?account_id=${accountId}`;
  return fetch(url, {
    credentials: 'include'
  })
  .then(response => response.json())
  .then(data => data.response)
}

export function getSpendByPlatform(
  platform,
  accountId,
  startDate,
  endDate
) {
  let url = `/has/spend?platform=${platform}&start_date=${startDate}&end_date=${endDate}`;
  if (accountId) url += `&account_id=${accountId}`;

  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response)
}

export function getSurveyConfigs() {
  const url = `/segments/survey`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postSurveyData(obj) {
  const url = `/segments/survey`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  })
    .then(response => response.json());
}

export function updateSurveyData(data, id) {
  let url = `/segments/survey?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function getEntityConfigs() {
  const url = `/entity`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function updateEntityData(data, id) {
  let url = `/entity?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function postEntityData(obj) {
  const url = `/entity`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .then(data => data.response);
}

export function deleteEntityData(id) {
  const url = `/entity?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "DELETE"
  }).then(response => response.json());
}

export function getEntityProgramConfigs() {
  const url = `/entity/program`;
  return fetch(url, { credentials: "include" })
    .then(response => response.json())
    .then(data => data.response);
}

export function postEntityProgram(obj) {
  const url = `/entity/program`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(obj)
  })
    .then(response => response.json())
    .catch(err => console.log(`Error posting entity: ${err}`));
}

export function updateEntityProgram(data, id) {
  let url = `/entity/program?id=${id}`;
  return fetch(url, {
    credentials: "include",
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(response => response.json());
}