import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

import SelectConversion from '../SelectSegment';
import { Aux, FormWrap, FieldWrap} from '../stateless';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Button, Segment, Input, Grid, Select, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';
import { getLTV, getTiers } from '../../utils/api';
import * as routes from '../../routes';
import styled from 'styled-components';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { numMonthOptions } from '../CohortLTV/SelectCohort';
import _ from 'lodash';
import { Currency, formatPercent } from '../../utils/format_helpers';


const percent = (x) => formatPercent(x);

const number = (new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})).format;

const defaultNumber = (x) => number(x);

const ColumnWrap = ({width, children}) => (
  <Grid.Column width={width}>
    <ContentCard borderless noBackground>
      <FormWrap>
        <FieldWrap>{ children }</FieldWrap>
      </FormWrap>
    </ContentCard>
  </Grid.Column>
)

const NAMES = {
  "0|0|1": "Existing",
  "0|0|0": "repeat Existing",
  "1|1|0": "NTF",
  "0|1|0": "repeat NTF"
}
const MONTHS = ["2018-09","2018-10","2018-11", "2018-12","2019-01","2019-02"];


const initialState = {
    loading: false,
    startingMonth: "2018-11",
    numMonths: 3,
    how: "even",
    tiers: [],
    tier_1: "",
    tier_group: "",
    currentData: {}
  }

class CohortLTV extends Component {

  state = _.clone(initialState)

  reset = () => {
    const { tiers } = this.state;
    this.setState(Object.assign(initialState,{tiers}))
  }

  getData(id) {
    const { selectedSegment } = this.props;
    if (id && selectedSegment) {
      const { startingMonth, numMonths, how } = this.state;
      const tier_1 = ""
      const tier_group = ""
      this.setState({ loading: true })
      const months = MONTHS.slice(MONTHS.indexOf(startingMonth))
        .filter((_,i) => numMonths - i > 0)


      Promise.all(months.map((month,i) => {
          return getLTV(id, how, month, 1+numMonths-i, tier_1, tier_group, true, selectedSegment.new_to_file_key)
            .then(data => this.processData(month, data) )
            .catch(e => console.log(e))
        })
      ).then(_ => {
        this.setState({loading: false})
      })
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getData(id)
    getTiers()
      .then(tiers => this.setState({ tiers }))
  }

  componentDidUpdate(prevProps, prevState) {
    const fields = ["startingMonth", "numMonths", "how", "tier_1", "tier_group"]
    const num = fields.filter(field => this.state[field] != prevState[field]).length

    const { id } = this.props.match.params;

    if (num > 0 || prevProps.match.params.id != id || prevProps.selectedSegment != this.props.selectedSegment) {
      this.getData(id)
    }
  }

  updateField = (field) => (evt, value) => {
    if (this.state[field] != value.value) this.setState({[field] : value.value, currentData: {}})
  }

  processData = (date, data) => {
    const { currentData, tier_1 } = this.state

    const transformedData = d3.nest()
      .key(x => x.month)
      .rollup(v => {
        const ntf = v.filter(x => x.ntf == "1")
        const was_ntf = v.filter(x => x.was_ntf == "1" && x.ntf == "0")
        return {
          ntf_converters: d3.sum(ntf,x => x.converters),
          ntf_conversions: d3.sum(ntf,x => x.conversions),
          ntf_revenue: d3.sum(ntf,x => x.revenue),
          ntf_repeat_conversions: d3.sum(was_ntf,x => x.conversions),
          ntf_repeat_revenue: d3.sum(was_ntf,x => x.revenue),
          total_conversions: d3.sum(v,x => x.conversions)
        }
      })
      .entries(tier_1 == "" ? data : data.filter(row => row.tier == tier_1))


    const mapTransformed = transformedData
      .reduce((p,v) => {
        const x = v.values
        const last = p.length == 0 ? 0 : p.slice(-1)[0].values;
        x.ntf_original_converters = last ? last.ntf_original_converters : x.ntf_converters;
        x.ntf_original_count = last ? last.ntf_original_count : x.ntf_conversions;
        x.ntf_original_revenue = last ? last.ntf_original_revenue : x.ntf_revenue;
        x.ntf_repeat_cumulative = (last.ntf_repeat_cumulative||0) + x.ntf_repeat_conversions;
        x.ntf_repeat_cumulative_revenue = (last.ntf_repeat_cumulative_revenue||0) + x.ntf_repeat_revenue;

        console.log(x.ntf_repeat_cumulative_revenue, x.ntf_original_count);
        x.ntf_repeat_cumulative_ltv = (x.ntf_repeat_cumulative_revenue + x.ntf_original_revenue)/x.ntf_original_converters;
        x.ntf_percent = x.ntf_conversions / x.total_conversions;
        x.ntf_repeat_cumulative_percent = x.ntf_repeat_cumulative/x.ntf_original_count;
        p.push(v)
        return p
      },[])
      .reduce((p,c) => {
        p[c.key] = c.values;
        return p
      },{})


    currentData[date] = mapTransformed;
    this.setState({ currentData })
  }


  render() {
    const { currentData, numMonths, startingMonth, how, tiers, tier_1, tier_group, loading } = this.state;

    const months = MONTHS.slice(MONTHS.indexOf(startingMonth))

    const monthOptions = ["2018-09","2018-10","2018-11"].map(month => { return {key: month, value: month, text: moment(month+"-01","YYYY-MM-DD").format("MMM YYYY")} })
    //const numMonthOptions = [1,2,3,4,5,6].map(month => { return {key: month, value: month, text: month} })
    const modelNames = {
      "first_touch": "First Touch",
      "last_touch": "Last Touch",
      "even": "Even Weight",
      "normalized": "Modeled Multi-touch"
    }
    const modelOptions = ["first_touch","last_touch","even","normalized"].map(x => { return {key: x, value:x, text:modelNames[x]} })


    const monthName = moment(startingMonth+"-01").format("MMMM")
    const shortMonthName = moment(startingMonth+"-01").format("MMM")
    const hasData = months.filter(m => Object.keys(currentData).indexOf(m) > -1)

    const showData = hasData.map((m,i) => months[i] == m).reduce((p,c) => {
      if (c == true) p += c
      return p
    }, 0)


    const tier1Options = tiers.map(r => { return {key: r.key, value: r.key, text: r.key} })
    const selectedOptions = tiers.filter(r => r.key == tier_1);

    const tier2Options = [{key:"tier_2", text:"Tier 2", value:"tier_2"},{key:"tier_3", text:"Tier 3", value:"tier_3"},]


    return (
      <div style={{padding:"5px"}}>

        <Grid fluid="true">
          <Grid.Row style={{paddingBottom:"0px"}}>
            <ColumnWrap width={1} />
            <ColumnWrap width={3}>
              <label>Conversion</label>
              <SelectConversion
                filter={(x) => x.has_new_to_file}
                endpoint={id => routes.cohortSummary + '/' + id}
                {...this.props}
                as={Select}
              />
            </ColumnWrap>
            <ColumnWrap width={2}>
              <label>Starting Month</label>
              <Select options={monthOptions} value={startingMonth} onChange={this.updateField("startingMonth")} />
            </ColumnWrap>
            <ColumnWrap width={2}>
              <label>Ending Month</label>
              <Select options={numMonthOptions(startingMonth)} value={numMonths} onChange={this.updateField("numMonths")} />
            </ColumnWrap>
            <ColumnWrap width={2}>
              <label>Attribution Model</label>
              <Select options={modelOptions} value={how} onChange={this.updateField("how")} />
            </ColumnWrap>
            <ColumnWrap width={2}>
              <label>Tier 1</label>
              <Select value={tier_1} options={tier1Options} onChange={this.updateField("tier_1")} />
            </ColumnWrap>
            {/* tier_1 && <FieldWrap>
              <label>Group By</label>
              <Select value={tier_group} options={tier2Options} onChange={this.updateField("tier_group")} />
            </FieldWrap>
            */}
            <ColumnWrap width={2}>
              <label>&nbsp;</label>
              <Button onClick={this.reset}>Reset</Button>
            </ColumnWrap>
          </Grid.Row>
        </Grid>
        <SplitLayout.StickyScroll
          stickySide='left'
          stickyOffset={15}
          leftWidth={1}
          leftContent={<div /> }
          rightWidth={14}
          rightContent={
          <React.Fragment>
            <ContentCard title={`Repeat Purchase Analysis - New Customer (Repeat Rate)`} borderless centeredTitle>
              { loading ?
                <ContentCard.CardLoader /> :
                <Wrapper>
                    <Column style={{textAlign:"right"}}>
                        <Row style={{paddingBottom:"5px", fontWeight:"bold"}}>
                          <Column flex={5} style={{paddingLeft:"15px",textAlign:"center"}}>First Purchase</Column>
                          <Column
                            flex={months.length}
                            style={{marginLeft:`${10+months.length}px`,textAlign:"center", borderLeft:"1px solid #ccc"}}
                          >
                            Cumulative NTF Repeat Purchase %
                          </Column>
                        </Row>
                        <Row style={{borderBottom:"1px solid #ccc",paddingBottom:"5px",marginBottom:"5px"}}>
                          <Column style={{paddingLeft:"15px"}}>Cohort Month</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Total Purchases</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>NTF Purchases</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>NTF %</Column>
                          <Column style={{marginLeft:"11px"}} />
                        {
                          months.map((date,i) => (
                            i ? (<Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Month {i}</Column>) :
                              (<Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Current Month</Column>)
                          ) )
                        }
                        </Row>
                    </Column>
                { months.slice(0,showData).map(rowDate => (
                    <Row style={{textAlign:"right"}}>
                      <Column>{ moment(rowDate + "-01").format("MMM YYYY")  }</Column>
                      <Column>{ number(currentData[rowDate][rowDate].total_conversions) }</Column>
                      <Column>{ number(currentData[rowDate][rowDate].ntf_conversions) }</Column>
                      <Column>{ percent(currentData[rowDate][rowDate].ntf_percent) }</Column>
                      <Column />
                      {
                        months.slice(months.indexOf(rowDate)).map(date =>
                          <Column>{ percent((currentData[rowDate][date]||{}).ntf_repeat_cumulative_percent) }</Column>
                        )
                      }
                      {
                        d3.range(0,months.indexOf(rowDate)).map(col => <Column></Column>)
                      }
                    </Row>
                  ))
                }
                </Wrapper>
              }
            </ContentCard>
            <ContentCard title={`Repeat Purchase Analysis - New Customer (Lifetime Value)`} borderless centeredTitle>
              { loading ?
                <ContentCard.CardLoader /> :
                <Wrapper>
                    <Column style={{textAlign:"right"}}>
                        <Row style={{paddingBottom:"5px", fontWeight:"bold"}}>
                          <Column flex={5} style={{paddingLeft:"15px",textAlign:"center"}}>First Purchase</Column>
                          <Column
                            flex={months.length}
                            style={{marginLeft:`${10+months.length}px`,textAlign:"center", borderLeft:"1px solid #ccc"}}
                          >
                            Cumulative NTF Customer Value
                          </Column>
                        </Row>
                        <Row style={{borderBottom:"1px solid #ccc",paddingBottom:"5px",marginBottom:"5px"}}>
                          <Column style={{paddingLeft:"15px"}}>Cohort Month</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Total Purchases</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>NTF Purchases</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>NTF %</Column>
                          <Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>NTF (AOV)</Column>
                        {
                          months.map((date,i) => (
                            i ? (<Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Month {i}</Column>) :
                              (<Column style={{marginLeft:"10px",borderLeft:"1px solid #ccc"}}>Current Month</Column>)
                          ) )
                        }
                        </Row>
                    </Column>
                { months.slice(0,showData).map(rowDate => (
                    <Row style={{textAlign:"right"}}>
                      <Column>{ moment(rowDate + "-01").format("MMM YYYY")  }</Column>
                      <Column>{ number(currentData[rowDate][rowDate].total_conversions) }</Column>
                      <Column>{ number(currentData[rowDate][rowDate].ntf_conversions) }</Column>
                      <Column>{ percent(currentData[rowDate][rowDate].ntf_percent) }</Column>
                      <Column><Currency amount={currentData[rowDate][rowDate].ntf_revenue/currentData[rowDate][rowDate].ntf_original_converters}/></Column>
                      {
                        months.slice(months.indexOf(rowDate)).map(date =>
                          <Column>
                            <Currency amount={(currentData[rowDate][date]||{}).ntf_repeat_cumulative_ltv}/></Column>
                        )
                      }
                      {
                        d3.range(0,months.indexOf(rowDate)).map(col => <Column></Column>)
                      }
                    </Row>
                  ))
                }
                </Wrapper>
              }
            </ContentCard>
          </React.Fragment>
          }
        />
      </div>
    );
  }
}


function mapStateToProps (state, props) {
  return {
    selectedSegment: state.segment.segments.find(x => x.action_id == props.match.params.id)
  }
}


export default connect(mapStateToProps)(withRouter(CohortLTV));
