import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Container, Divider, Grid, Button, Form, Header } from 'semantic-ui-react';


const MarketingChannels = ({marketingChannels, handleTextChange, updateActiveState}) => {

  return (
    <Form>
      <Container fluid>
        <Header
          as="h4"
          content="Marketing Channels"
          subheader={`Select the marketing channels that you'd like to tie the above spend data to.`}
        />
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="Bucket 1"
            required
            placeholder="Select a Bucket"
            value={marketingChannels.bucket1}
            onChange={(e, data) => handleTextChange(data, "marketingChannels", "bucket1")}
          />
          <Form.Input
            fluid
            label="Bucket 2"
            placeholder="ALL"
            value={marketingChannels.bucket2}
            onChange={(e, data) => handleTextChange(data, "marketingChannels", "bucket2")}
          />
          <Form.Input
            fluid
            label="Bucket 3"
            placeholder="ALL"
            value={marketingChannels.bucket3}
            onChange={(e, data) => handleTextChange(data, "marketingChannels", "bucket3")}
          />
          <Form.Input
            fluid
            label="Bucket 4"
            placeholder="ALL"
            value={marketingChannels.bucket4}
            onChange={(e, data) => handleTextChange(data, "marketingChannels", "bucket4")}
          />
          <Form.Input
            fluid
            label="Bucket 5"
            placeholder="ALL"
            value={marketingChannels.bucket5}
            onChange={(e, data) => handleTextChange(data, "marketingChannels", "bucket5")}
          />
        </Form.Group>
      </Container>
      <Divider section />
      <div className="m-t-20">
        <Grid fluid columns={2}>
          <Grid.Column>
            <Button secondary type="button" onClick={() => updateActiveState('data')}>Back</Button>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button primary type="button" textAlign="right" onClick={() => updateActiveState('mappings')}>
              Next
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </Form>
  );
}

export default MarketingChannels;
