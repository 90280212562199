import React, { useState, useMemo, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Link from '../utils/link';
import { Button } from 'semantic-ui-react';

import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import * as atoms from '../atoms'
import * as routes from '../routes'

import { useURLState } from '../../components/hooks'
import { monthAgo, weekAgo, yesterday } from '../utils/time';
import { useGlobalState } from '../hooks/global';
import { useSavedReports } from '../views/SavedReports/_hooks/savedReports';
import { buildPath, buildHash } from '../hooks/urlState';
import styled from 'styled-components';

const ResetButton = styled(Button)`
  color: #475ddc !important;
  background: transparent !important;

  &:hover {
    border-color: transparent !important;
    background-color: #475ddc !important;
    color: white !important;
  }
`

const defaultStartEndDate = {
  attributionReport: [monthAgo, yesterday],
  conversionsDetail: [yesterday, yesterday],
  funnelPosition: [weekAgo, yesterday], 
  marketingPaths: [weekAgo, yesterday], 
  newVisitors: [monthAgo, yesterday], 
  spendTrends: [], 
  channelOverlap: [monthAgo, yesterday], 
  digitalAdvertising: {
    platform: [monthAgo, yesterday],
    comparison: [monthAgo, yesterday]
  }
}

const resetParams = () => {
  const [id, setId] = useURLState("id");
  const [startDate, setStartDate] = useURLState('startDate');
  const [endDate, setEndDate] = useURLState('endDate');

  return { setStartDate, setEndDate, setId }
} 

const ResetFilterButton = ({ }) => {
  const match = useRouteMatch()
  const { path, params } = match
  const [routeName, _routePath] = Object.entries(routes).find(x => x[1] === path)
  const { view } = params

  const { setStartDate, setEndDate, setId } = resetParams()
  const { segments } = useGlobalState()
  const { savedReports, handleGetSavedReports } = useSavedReports()
  const defaultSegId = segments.find(({ featured }) => featured === 1)?.filter_id

  const [startDate, endDate] = routeName === 'digitalAdvertising' ? defaultStartEndDate[routeName][view] : defaultStartEndDate[routeName]
  
  // jotai
  const [saveId] = useAtom(atoms.saveIDAtom)

  // jotai reset
  const resetCustomerType = useResetAtom(atoms.customerTypeAtom)
  const resetAttributionMethod = useResetAtom(atoms.attributionMethodAtom)
  const resetModel = useResetAtom(atoms.modelAtom)
  const resetSegId = useResetAtom(atoms.segIdAtom)
  const resetConvValue = useResetAtom(atoms.convValueAtom)
  const resetAttributionWindow = useResetAtom(atoms.attributionWindowAtom)
  const resetAny = useResetAtom(atoms.anyAtom)
  const resetFirst = useResetAtom(atoms.firstAtom)
  const resetMid = useResetAtom(atoms.midAtom)
  const resetLast = useResetAtom(atoms.lastAtom)
  const resetTier1 = useResetAtom(atoms.tier1Atom)
  const resetTier2 = useResetAtom(atoms.tier2Atom)
  const resetTier3 = useResetAtom(atoms.tier3Atom)
  const resetTier4 = useResetAtom(atoms.tier4Atom)
  const resetTier5 = useResetAtom(atoms.tier5Atom)
  const resetGoal = useResetAtom(atoms.goalAtom)
  const resetSearch = useResetAtom(atoms.searchAtom)
  const resetTiers = useResetAtom(atoms.tiersAtom)
  const resetColumns = useResetAtom(atoms.columnsAtom)
  const resetCategory = useResetAtom(atoms.categoryAtom)
  const resetCategoryValue = useResetAtom(atoms.categoryValueAtom)
  const resetSelectedChannel = useResetAtom(atoms.selectedChannelAtom)
  const resetTopTab = useResetAtom(atoms.topTabAtom)
  const resetReportType = useResetAtom(atoms.reportTypeAtom)
  const resetMinPathLen = useResetAtom(atoms.minPathLenAtom)

  useEffect(() => {
    handleGetSavedReports()
  }, [saveId])

  const savedReport = useMemo(() => {
    if (!savedReports || !saveId) return null
    return savedReports.find(({ id }) => id === saveId)
  }, [savedReports, saveId])

  const fullPath = useMemo(() => {
    if (!savedReport) return '/'
    const { route, params } = savedReport
    const rebuiltPath = buildPath(route, params.route)
    const rebuiltHash = params.hash ? buildHash(params.hash) : ''
    return rebuiltPath + rebuiltHash
  }, [savedReport])

  const resetFilters = () => {
    resetCustomerType()
    resetAttributionMethod()
    resetModel()
    resetSegId()
    resetConvValue()
    resetAttributionWindow()
    resetAny()
    resetFirst()
    resetMid()
    resetLast()
    resetTier1()
    resetTier2()
    resetTier3()
    resetTier4()
    resetTier5()
    resetGoal()
    resetSearch()
    resetTiers()
    resetColumns()
    resetCategory()
    resetCategoryValue()
    resetSelectedChannel()
    resetTopTab()
    resetReportType()
    resetMinPathLen()
    setStartDate(startDate)
    setEndDate(endDate)
    setId(defaultSegId)
  }

  return (
    savedReport ? (
      <Link to={fullPath}>
        <ResetButton
          icon='sync'
        />
      </Link >
    ) : (
      <ResetButton
        icon='sync'
        onClick={resetFilters}
      />
    )
  );
}

export default ResetFilterButton
