import React from 'react'
import { default as IndexGrid } from './IndexGrid'
import PropTypes from 'prop-types'
import { Table, Icon, Header } from 'semantic-ui-react';

export const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign='center'
        selectable={false}
        className='empty'
      >
        <br />
        <Icon
          circular
          disabled
          name={props.emptyIcon || 'info'}
          size='big'
          className='m-b-10' />
        <Header
          as='h3'
          disabled
          content={props.fallBackMsg || 'No data found'}
          className='m-0' />
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)

const IndexGridV2 = (props) => {

  return (
    <IndexGrid
      {...props}
      allData={props.data}
      static
      cols={props.cols}
    />
  )
}

IndexGridV2.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element
   })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  emptyIcon: PropTypes.string,
  UTCOffset: PropTypes.number,
  /** Number of rows per page */
  itemsPerPage: PropTypes.number.isRequired,
  expandable: PropTypes.bool,
  /** Component that will display when table row is clicked */
  expandedComponent: PropTypes.element,
  /** Key from a data object used to determine which row has been clicked and should be expanded. They selected key must be unique across all objects (ideally some sort of id) */
  idKey: PropTypes.string,
}

export default IndexGridV2