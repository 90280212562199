import React, { useState } from 'react'
import { Header, Form, Input, Dimmer, Loader } from 'semantic-ui-react'
import { useForm, Controller } from 'react-hook-form' 

import * as routes from '../../baseRoutes'
import * as Sentry from '@sentry/react';
import { track, time } from '../../../utils/tracking'
import { postSignup, postUserLoginToken } from '../../api/account'
import { checkJobWithToken } from '../../api/attributionRun'
import { FormStylesWrap, Error } from './stateless'
import { validateTier } from '../../utils/formValidators'
import CheckboxKeyboardAccessible from '../CheckboxKeyboardAccessible'


const SignupForm = ({ as, signupSource, tier, header, subheader, color='475ddc', buttonColor, buttonTextColor='ffffff', buttonText='Sign Up', email, firstName, lastName, company, website, key, attribution, showValidationMessages=true }) => {
  const accountTier = validateTier(tier) ? tier : 'lite'

  const { control, handleSubmit, formState: {errors}, setValue, trigger, resetField } = useForm({
    defaultValues: {
      first_name: firstName || "",
      last_name: lastName || "",
      email: email || "",
      company_name: company || "",
      company_website: website || "",
      scale_signup_key: key || "",
      termsCheckbox: false,
      tier: accountTier
    }
  })
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)

  // errorMessage(true) accountExists(false) -- shows error screen with intercom button (something went wrong with wq job)
  // errorMessage(true) accountExists(true)-- shows error screen with option to login to platform (account already exists)
  const [errorMessage, setErrorMessage] = useState(false)
  const [accountExists, setAccountExists] = useState(false)
  const [invalidKey, setInvalidKey] = useState(false)
  
  const handleError = () => {
    setLoading(false)
    setErrorMessage(true)
  }

  const getJobStatus = (token) => {
    checkJobWithToken(token)
      .then(resp => {
        if (resp.finished_at > 0) return resp.finish_type
        if (resp.started_at > 0) return 'started'
        return 'queued'
      })
      .then(jobStatus => {
        switch (jobStatus) {
          case 'success':
            postUserLoginToken(token)
              .then(response => {
                if (response.error) {
                  handleError() 
                  return
                }
                const redirectOnSuccess = routes.home
                window.top.location = redirectOnSuccess
              })
            break;
          case 'error':
            handleError()
            break;
          default:
            setTimeout(() => getJobStatus(token), 1000)
        }
      })
      .catch((err) => {
        handleError()
        Sentry.captureException(err)
        console.log(`error checking job with token ${token}`)
      })
  }

  const onSubmit = data => { 
    setLoading(true)
    time('signup')
    const postObj = data
    postObj.signup_source = signupSource
    postObj.attribution = attribution

    postSignup(postObj)
      .then(response => {
        const { error, token } = response
        if (error) {
          setLoading(false)
          setErrorMessage(true)
          switch (error) {
            case 'Invalid signup key':
              setInvalidKey(true)
              break;
            case 'Email already exists':
              setAccountExists(true)
              break;
            case 'Account with this domain already exists':
              setAccountExists(true)
              break;
            case 'Pixel source name already exists':
              setAccountExists(true)
              break;
          }
          return
        }
        setToken(token)
        getJobStatus(token)
        track('signup', postObj, {transport: 'sendBeacon', send_immediately: true})
      })
      .catch((err) => {
        Sentry.captureException(err)
        console.log(err)
      })
  }

  const startIntercomChat = (token) => {
    const chatMessage = `Help! There was an error setting up my account. Error Code: Token generated from signup ${token}` 
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage)
  }
  
  return (
    <FormStylesWrap {...{ color, buttonColor, buttonTextColor }}>
      <Dimmer.Dimmable
        {...{ as }}
        dimmed
        className='signup-form-box'
        style={errorMessage ? {display: 'flex', alignItems: 'center', justifyContent: 'center'} : null}
      >
        <Dimmer active={loading} inverted>
          <Loader
            size='big'
            content={
              <Header
                as='h2'
                content="We're creating your account!"
                subheader="Please don't close or refresh the page. This may take a few minutes."
                textAlign='center'
              />
            }
          />
        </Dimmer>
        {errorMessage ? <Error {...{token, startIntercomChat, accountExists, invalidKey}}/> : 
        <React.Fragment>
          {(!!header || !!subheader) &&
            <Header
              as='h2'
              content={header}
              subheader={subheader}
              textAlign='center'
              className='signup-form-header'
            />
          }
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group unstackable widths={2} style={{ marginBottom: '1em' }}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => 
                  <Form.Input
                    {...field}
                    fluid
                    label='First name'
                    placeholder='Johnny'
                    error={showValidationMessages ? errors.first_name?.message : !!errors.first_name?.message}
                  />
                }
                rules={{required: "Missing first name"}}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) =>
                  <Form.Input
                    {...field}
                    fluid
                    label='Last name'
                    placeholder='Appleseed'
                    error={showValidationMessages ? errors.last_name?.message : !!errors.last_name?.message}
                  />
                }
                rules={{required: "Missing last name"}}
              />
            </Form.Group>
            <Controller
              name="email"
              control={control}
              render={({ field }) =>
                <Form.Input
                  {...field}
                  fluid
                  label='Email address'
                  placeholder='johnny@example.com'
                  error={showValidationMessages ? errors.email?.message : !!errors.email?.message}
                />
              }
              rules={{
                required: "Missing email", 
                pattern: {value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Please use a valid email'}}
              }
            />
            <Controller
              name="company_name"
              control={control}
              render={({ field }) =>
                <Form.Input
                  {...field}
                  fluid
                  label='Company name'
                  placeholder='My Example Co'
                  error={showValidationMessages ? errors.company_name?.message : !!errors.company_name?.message}
                />
              }
              rules={{required: "Missing company name"}}
            />
            <Form.Field error={errors.company_website?.message}>
              <label>Website domain</label>
              <Controller
                name="company_website"
                control={control}
                render={({ field }) => <Input {...field} label='https://' placeholder='example.com'/>}
                rules={{
                  required: "Missing company website", 
                  pattern: {value: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/, message: 'Please use a valid domain'}}
                }
              />
              {showValidationMessages && errors.company_website?.message && <div className="ui pointing above prompt label">{errors.company_website.message}</div>}
            </Form.Field>
            {tier == 'scale' &&
            <Form.Field error={errors.scale_signup_key?.message}>
              <label>Signup Key</label>
              <Controller
                name="scale_signup_key"
                control={control}
                render={({ field }) => <Input {...field} placeholder='Reach out to support@rockerbox.com if you need this'/>}
                rules={{
                  required: "Missing or invalid signup key", 
                  pattern: {value: /^.{6,}$/, message: 'Invalid signup key'}}
                }
              />
              {showValidationMessages && errors.scale_signup_key?.message && <div className="ui pointing above prompt label">{errors.scale_signup_key.message}</div>}
            </Form.Field>}
            <Form.Field error={errors.termsCheckbox}>
              <Controller
                name='termsCheckbox'
                control={control}
                rules={{required: 'Please accept terms'}}
                // using the custom Checkbox component because checkboxes are not keyboard-accessible using Safari
                render={({ field }) => <CheckboxKeyboardAccessible {...field} {...{trigger, setValue, resetField, name:'termsCheckbox', error:errors.termsCheckbox}}
                label={<label className='signup-form-text'> 
                   I agree to the <a href='https://www.rockerbox.com/tc-starter-12-22' target='_blank'>Rockerbox Terms and Conditions</a>
                </label>}
                className='signup-form-terms'
                />}
              />
              {showValidationMessages && errors.termsCheckbox && <p className="ui left pointing prompt label" style={{ display: "flex"}}>{errors.termsCheckbox.message}</p>}
            </Form.Field>
            <Form.Button 
              type='submit'
              size='huge'
              fluid
              primary
              className='signup-form-submit'
            >
              {buttonText}
            </Form.Button>
          </Form>
        </React.Fragment> }
      </Dimmer.Dimmable>
    </FormStylesWrap>
  )
}

export default SignupForm
