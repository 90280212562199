import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

const DeprecationBanner = ({ viewName, newViewName, newViewPath }) => (
  <Segment style={{ background: '#ec3d3b', color: '#fff', padding: 9 }}>
    <Icon name='warning sign' />&nbsp;
    The Classic {viewName} view is deprecated and will be <strong>shut down on June 15, 2023</strong>. Please <a href={newViewPath} style={{ color: '#fff', textDecoration: 'underline' }}>switch to the New {newViewName} view</a>.
  </Segment>
)

export default DeprecationBanner
