export const parsedOutboxes = outboxes => {
  const types = [
    "amazon_s3",
    "sftp",
    "ftp",
    "do_spaces",
    "googlecloud_storage"
  ];

  if (!outboxes) {
    return [];
  }

  return outboxes
    .filter(outbox => outbox.name && types.includes(outbox.outbox_type))
    .map(outbox => {
      const identifier =
        (outbox.config && outbox.config.bucket_name) ||
        (outbox.config && outbox.config.hostname) ||
        "";

      return {
        ...outbox,
        name:
          outbox.name === "Spaces Delivery" ? "Email Delivery" : outbox.name,
        outbox_type: outbox.outbox_type,
        identifier
      };
    });
};

export const formatRunningJobs = jobs => {
  jobs.forEach(job => {
    if (Object.keys(job["params"]["parameters"]).indexOf("start_date") !== -1) {
      job["start_date"] = job["params"]["parameters"]["start_date"];
      job["end_date"] = job["params"]["parameters"]["end_date"];
    } else {
      job["start_date"] = job["params"]["parameters"]["date"];
      job["end_date"] = job["params"]["parameters"]["date"];
    }
  });

  return jobs;
};

export const formatDeliveredJobs = jobs => {
  jobs.forEach(job => {
    const filename = job["filename"];
    const reg = /[0-9]{4}\-[0-9]{2}\-[0-9]{2}/g;
    const matches = (filename && filename.match(reg)) || [];

    if (matches.length == 2) {
      job["start_date"] = matches[0];
      job["end_date"] = matches[1];
    } else {
      job["start_date"] = job["date"];
      job["end_date"] = job["date"];
    }
  });

  return jobs;
};
