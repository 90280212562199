/**
 * Builds an array of objects that contains data for each response curve in the forecast.
 * 
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} responseCurves - An array of objects that contains the response curve data for each forecasted field.
 * @param {Object} currentSpendValues - An object that contains the current spend values for each forecasted field.
 * @param {Object} currentForecastedData - An object that contains the current forecasted data for each forecasted field.
 * @param {Object} forecastedData - An object that contains the forecasted data for each forecasted field.
 * 
 * @returns {Array} An array of objects that contains data for each response curve in the forecast. Each object includes the
 * current selection, the current and target values, the estimated monthly value, the revenue at the current and target values,
 * the revenue percentage difference, the ROI at the current and target values, and the ROI percentage difference.
 */
export const buildForecastRows = (fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData) => {
  return responseCurves.map(obj => buildResponseData(obj, fieldValues, currentSpendValues, currentForecastedData, forecastedData))
}

/**
 * Builds an object that contains the response data for a single response curve.
 * 
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} currentSpendValues - An object that contains the current spend values for each forecasted field.
 * @param {Object} currentForecastedData - An object that contains the current forecasted data for each forecasted field.
 * @param {Object} forecastedData - An object that contains the forecasted data for each forecasted field.
 * 
 * @returns {Object} An object that includes the current selection, the current and target values, the estimated monthly value,
 * the revenue at the current and target values, the revenue percentage difference, the ROI at the current and target values,
 * and the ROI percentage difference.
 */
export const buildResponseData = (obj, fieldValues, currentSpendValues, currentForecastedData, forecastedData) => {
  const response = {}
  
  response.key = obj.key
  response.currentSelection = getCurrentSelection(fieldValues, obj)
  response.allowCustomInput = allowCustomInput(fieldValues, obj)
  response.currentValue = getCurrentValue(fieldValues, obj)
  response.targetValue = getTargetValue(fieldValues, obj)
  response.estimatedMonthlyValue = getEstimatedMonthlyValue(response.targetValue)
  response.revenueAtCurrentValue = getRevenueViaForecast(obj, currentForecastedData)
  response.revenueAtTargetValue = getRevenueViaForecast(obj, forecastedData)
  response.revenuePercentDiff = calcPercentDiff(response.revenueAtCurrentValue, response.revenueAtTargetValue)
  response.ROIAtCurrentValue = getROIViaForecast(obj, currentSpendValues, currentForecastedData)
  response.ROIAtTargetValue = getROIViaForecast(obj, currentSpendValues, forecastedData)
  response.ROIPercentDiff = calcPercentDiff(response.ROIAtCurrentValue, response.ROIAtTargetValue)
  
  return response
}

/**
 * Returns the current selection for a response curve.
 * 
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * 
 * @returns {string} The current selection for the response curve.
 */
export const getCurrentSelection = (fieldValues, obj) => {
  return fieldValues[obj.key]?.selection
}

/**
 * Determines whether custom input is allowed for a response curve.
 * 
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * 
 * @returns {boolean} True if custom input is allowed, false otherwise.
 */
export const allowCustomInput = (fieldValues, obj) => {
  return fieldValues[obj.key]?.selection != "custom"
}

/**
 * Returns the current value for a response curve.
 * 
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * 
 * @returns {number} The current value for the response curve.
 */
export const getCurrentValue = (fieldValues, obj) => {
  return fieldValues[obj.key]?.value
}

/**
 * Returns the target value for a response curve.
 * 
 * @param {Object} fieldValues - An object that contains the target values for each forecasted field.
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * 
 * @returns {number} The target value for the response curve.
 */
export const getTargetValue = (fieldValues, obj) => {
  return fieldValues[obj.key]?.value
}

/**
 * Returns the estimated monthly value for a response curve.
 * 
 * @param {number} targetValue - The target value for the response curve.
 * 
 * @returns {number} The estimated monthly value for the response curve.
 */
export const getEstimatedMonthlyValue = (targetValue) => {
  return targetValue * 30
}

/**
 * Returns the revenue at the target value for a response curve.
 * 
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * @param {Object} forecastedData - An object that contains the forecasted data for each forecasted field.
 * 
 * @returns {number} The revenue at the target value for the response curve.
 */
export const getRevenueViaForecast = (obj, forecastedData) => {
  return forecastedData[obj.key]
}

/**
 * Returns the ROI at the current value for a response curve.
 * 
 * @param {Object} obj - An object that contains the response curve data for a single forecasted field.
 * @param {Object} spendValues - An object that contains the current spend values for each forecasted field.
 * @param {Object} forecastedData - An object that contains the current forecasted data for each forecasted field.
 * 
 * @returns {number} The ROI at the current value for the response curve.
 */
export const getROIViaForecast = (obj, spendValues, forecastedData) => {
  return forecastedData[obj.key] / spendValues[obj.key]?.value
}

/**
 * Returns the revenue percentage difference for a response curve.
 * 
 * @param {number} currentValue 
 * @param {number} newValue 
 * 
 * @returns {number} The revenue percentage difference for the response curve.
 */
export const calcPercentDiff = (currentValue, newValue) => {
  return newValue / currentValue
}

