import React, { useState, useEffect } from 'react';
import { useParamsWithDefaults } from '../urlParamHooks';
import { getViaCache } from '../../../../utils/arrow/reportingDataCache'
import { getViaCacheDataReporting } from '../../AttributionReport/hooks/dataReportingEndpoint';
import { getConversionSegmentReportingData } from "../../../api/attributionReporting"
import { getTimeToConversion } from '../../../api/attributionCache' 
import { TIERS } from '../../../constants/tiers'

import * as aq from "arquero";

export const arrowColumnUnique = (arrow, column) => new Array(...new Set([...arrow.column(column)]))

export const tiersDataToArrow = (data) => {

  const first = data[0];
  const hasNormalized = !!first.normalized;

  const serialized = data.map(obj => {
    const formatted = {
      tier_1: obj.tier_1,
      tier_2: obj.tier_2,
      tier_3: obj.tier_3,
      tier_4: obj.tier_4,
      tier_5: obj.tier_5,
      date: obj.date,
      platform: ( obj.platform || 0 ), 
      even: obj.even,
      last_touch: obj.last_touch,
      first_touch: obj.first_touch,
      revenue_even: obj.revenue_even_usd || obj.revenue_even,
      revenue_last_touch: obj.revenue_last_touch_usd || obj.revenue_last_touch,
      revenue_first_touch: obj.revenue_first_touch_usd || obj.revenue_first_touch,
      normalized: obj.normalized,
      revenue_normalized: obj.revenue_normalized_usd || obj.revenue_normalized,
    }

    if (hasNormalized) {
      formatted['normalized'] = obj.normalized
      formatted['revenue_normalized'] = obj.revenue_normalized_usd || obj.revenue_normalized
    }
 
    return formatted
  })

  return aq.from(serialized)
}

export const ttcDataToArrow = (data) => {
  const seriealized = data.map(obj =>
  ({
    tier_1: obj.tier_1,
    tier_2: obj.tier_2,
    tier_3: obj.tier_3,
    tier_4: obj.tier_4,
    tier_5: obj.tier_5,
    date: obj.date,
    revenue: obj.revenue_usd || obj.revenue || 0,
    timediff_bins: obj.timediff_bins, 
    timediff_count: obj.timediff_count, 
    timediff_ord: obj.timediff_ord, 
  })
  )
  return aq.from(seriealized)
}

const filterByArrow = (data, filterMap) => {

  const toFilter = data
    .params(filterMap)

  const filtered = Object.entries(filterMap)
    .reduce((data, [key, values]) => {
      if (values.length == 0) return data
      return data
        .filter(`(d, $) => op.includes($['${key}'], d['${key}'])`)
    }, toFilter)

  return filtered

}

const filterByPlatform = (tiersData, platform) => {
  const v2 = `${platform}_v2`
  const v3 = `${platform}_v3`
  return filterByArrow(tiersData, { "platform": [platform, v2, v3] })
}

const processPlatformTiersArrow = (tiersData, groupby) => {

  const simpleRollup = {
    "conversions_even_rb": `(d) => op.sum(d.even)`,
    "conversions_last_touch_rb": `(d) => op.sum(d.last_touch)`,
    "conversions_first_touch_rb": `(d) => op.sum(d.first_touch)`,
    "revenue_even_rb": `(d) => op.sum(d.revenue_even)`,
    "revenue_last_touch_rb": `(d) => op.sum(d.revenue_last_touch)`,
    "revenue_first_touch_rb": `(d) => op.sum(d.revenue_first_touch)`,
    "conversions_normalized_rb": `(d) => op.sum(d.normalized)`,
    "revenue_normalized_rb": `(d) => op.sum(d.revenue_normalized)`,
  }

  const columns = tiersData.columnNames()
  if (!columns.includes("normalized")) {
    delete simpleRollup['conversions_normalized_rb']
    delete simpleRollup['revenue_normalized_rb']
  }

  return tiersData.groupby(groupby)
    .rollup(simpleRollup)
}

const processPlatformTTCArrow = (timeToConvData, groupby) => {

  const CONV_WINDOW = { '1_day': 3, '7_day': 4, '14_day': 5, '28_day': 6, 'greater_than_90': 10 }
  const windowed = timeToConvData
    .params(CONV_WINDOW)
    .filter((d, $) => d.timediff_ord == $['7_day'])

  const assistedWindow = windowed
    .groupby(groupby)
    .rollup({
      "conversions_assisted_att_win_rb": (d) => op.sum(d.timediff_count || 0),
      "revenue_assisted_att_win_rb": (d) => op.sum(d.revenue || 0),
    })

  const windowed2 = timeToConvData
  .params(CONV_WINDOW)
  .filter((d, $) => d.timediff_ord == $['greater_than_90'])

  const assisted = windowed2
    .groupby(groupby)
    .rollup({
      "conversions_assisted_rb": (d) => op.sum(d.timediff_count || 0),
      "revenue_assisted_rb": (d) => op.sum(d.revenue || 0),
    })

  return {
    assisted, assistedWindow
  }
}


export const useReportingData = (account) => {
  const { startDate, endDate, platformName, segId } = useParamsWithDefaults();

  const [tiersData, setTiersData] = useState(undefined)
  const [platformTiersData, setPlatformTiersData] = useState(undefined)
  const [tiersDates, setTiersDates] = useState(undefined)
  const [timeToConvData, setTimeToConvData] = useState(undefined)
  const [ttcDates, setTtcDates] = useState(undefined)
  const [assisted, setAssisted] = useState(undefined)
  const [assistedWindow, setAssistedWindow] = useState(undefined)
  const hasDataReportingApiFlag = account.features.find(c => c == 'data_reporting_api')

  useEffect(() => {
    if (!segId && segId != 0) return;
    setTiersData(undefined)
    setTimeToConvData(undefined)
    if(hasDataReportingApiFlag) {
      getViaCacheDataReporting(startDate, endDate, "compiled_mta_tiers", segId)
      .then(arrow => arrow.select(aq.not("spend")))
      .then(arrow => {
        if (arrow.size == 0) {
          setTiersData([])
          setTiersDates([])
          return
        };
        const result = processPlatformTiersArrow(arrow, [...TIERS, "date", "platform"])
        const filtered = filterByPlatform(result, platformName)

        setPlatformTiersData(filtered)
        setTiersData(result)
        setTiersDates(arrowColumnUnique(arrow, "date"))
      })
    } else {
      getViaCache(startDate, endDate, "compiled_mta_tiers", segId)
      .then(arrow => arrow.select(aq.not("spend")))
      .then(arrow => {
        if (arrow.size == 0) {
          setTiersData([])
          setTiersDates([])
          return
        };
        const result = processPlatformTiersArrow(arrow, [...TIERS, "date", "platform"])
        const filtered = filterByPlatform(result, platformName)

        setPlatformTiersData(filtered)
        setTiersData(result)
        setTiersDates(arrowColumnUnique(arrow, "date"))
      })
    }


    getViaCache(startDate, endDate, "time_to_conversion", segId)
      .then(arrow => arrow
        .select(aq.not("spend"))
        .rename({conversions: 'timediff_count', revenue_usd: 'revenue'}
      ))
      .then(arrow => {
        if (arrow.size == 0) {
          setTimeToConvData([])
          setTtcDates([])
          return
        };

        const { assisted, assistedWindow } = processPlatformTTCArrow(arrow, [...TIERS, "date"])
        setAssisted(assisted)
        setAssistedWindow(assistedWindow)
        setTimeToConvData(arrow)
        setTtcDates(arrowColumnUnique(arrow, "date"))
      })

  }, [segId, startDate, endDate])

  return {
    tiersData, 
    tiersDates,
    platformTiersData,
    timeToConvData,
    ttcDates,
    assisted,
    assistedWindow
  }

}
