import React, { useMemo } from 'react'
import Tree from 'react-d3-tree'
import { AtSymbolIcon, UserIcon, PhoneIcon, DeviceMobileIcon, FingerPrintIcon, LinkIcon, LocationMarkerIcon, DatabaseIcon } from '@heroicons/react/solid'

import { ContentCard } from '@rockerbox/styleguide'

const FIELD_MAP = {
  email: {
    name: 'Email',
    icon: AtSymbolIcon,
  },
  external_id: {
    name: 'Customer ID',
    icon: UserIcon,
  },
  phone_number: {
    name: 'Phone Number',
    icon: PhoneIcon,
  },
  mobile_device_id: {
    name: 'Mobile Device ID',
    icon: DeviceMobileIcon,
  },
  uid: {
    name: 'Rockerbox User ID',
    icon: FingerPrintIcon,
  },
  segment_anonymous_id: {
    name: 'Segment User ID',
    icon: LinkIcon,
  },
  address_clean_hash: {
    name: 'Address',
    icon: LocationMarkerIcon,
  },
}


const TreeNode = ({ nodeDatum }) => {
  const { name, attributes } = nodeDatum
  const { value } = attributes

  const Icon = FIELD_MAP[name]?.icon || DatabaseIcon

  return (<>
    <circle r={15} />
    <Icon className='icon' width={20} height={19} x={-10} y={-9} />
    <g className='label'>
      <text className='title' textAnchor='start'>
        {FIELD_MAP[name]?.name || name}
      </text>
      <text className='description'>
        <tspan dy={16}>
          {value}{name.includes('hash') && ' (hashed)'}
        </tspan>
      </text>
    </g>
  </>)
}


const IdentityGraph = ({ identityGraph }) => {
  const wrapRef = document.getElementById('treeWrapper')
  const { width, height } = wrapRef?.getBoundingClientRect() || {}

  const treeData = useMemo(() => {
    if (!identityGraph) return null
    const { baseKey, baseValue, graph } = identityGraph
    const obj = {
      name: baseKey,
      attributes: {
        value: baseValue,
      },
      children: [],
    }

    graph.map(({ path, value }) => {
      let node = obj
      path.slice(1).map((p, pathPosition) => {
        // always push new values to the end
        const end = pathPosition === path.length-2
        if (end) {
          node.children.push({
            name: p,
            attributes: {
              value,
            },
            children: [],
          })
          return
        }
        
        // go deeper into the graph
        const matchingNode = node.children.find(x => x.name === p)
        if (matchingNode) {
          node = matchingNode
          return
        }

        // create intermediate nodes if they don't exist
        node.children.push({
          name: p,
          attributes: {
            value,
          },
          children: [],
        })
        node = node.children[node.children.length-1]
      })
    })
    return obj
  }, [identityGraph])

  const maxDepth = useMemo(() => {
    if (!treeData) return null
    const { graph } = identityGraph
    return Math.max(...graph.map(x=> x.path.length))
  }, [identityGraph])

  // hide if no data
  if (identityGraph === false) return null

  return (
    <ContentCard hasTable title='Identity Graph' loading={!treeData}>
      <div id='treeWrapper' className='tree-graph'>
        {!!treeData &&
          <Tree
            data={treeData}
            collapsible={false}
            orientation='horizontal'
            separation={{ nonSiblings: 1, siblings: 1 }}
            translate={{ x: 50, y: height/2 - 20 }}
            rootNodeClassName='tree-node'
            branchNodeClassName='tree-node'
            leafNodeClassName='tree-node'
            pathClassFunc={() => 'tree-path'}
            nodeSize={{ x: 100, y: 100 }}
            depthFactor={width / maxDepth}
            renderCustomNodeElement={TreeNode}
          />
        }
      </div>
    </ContentCard>
  )
}

export default IdentityGraph
