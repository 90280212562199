import { DEFAULT_TIERS } from './IndexGridTree';
import _ from 'lodash';

// index.js
export const arrayToColumns = (arr) => {
  return arr.map(key => { return { key, display: key} })
}

// IndexGridTree.js
export const pagerLength = (rows, itemsPerPage) => {
  const numberOfPagers = Math.ceil(rows.length / itemsPerPage)

  return numberOfPagers
};

export const selectData = (data, itemsPerPage, selectedPageNumber) => {
  return (data||[]).slice(
    (selectedPageNumber - 1) * itemsPerPage,
    selectedPageNumber * itemsPerPage,
  )
}

export const filterUsingGroupBy = (groupBy, obj, searchVal) => {
  const loweredSearchVal = searchVal.toLowerCase();
  const objCopy = groupBy.reduce((p,key) => {
    p[key] = obj[key].toLowerCase()
    return p
  }, {})

  const hasVal = groupBy.some(c => objCopy[c].includes(loweredSearchVal));

  return hasVal;
}

/* 
  function needed due to bad data (sola wood flowers) passing a single space as a tier value
  results in empty table rows.  
*/
export const filterEmptyTiers = (data) => {
  const clone = _.cloneDeep(data);
  
  const correctedData = clone.map(c => {
    DEFAULT_TIERS.map(tier => c[tier] == " " ? c[tier] = "" : c[tier]);
    return c
  })

  return correctedData;
}

export const addValuesToAllRows = (obj, additionalDataObj, totalSpend) => {
  if("goal" in additionalDataObj) {
    obj.goal = obj[additionalDataObj.goal.metric] - additionalDataObj.goal.target // needed for sorting
    obj.totalSpend = totalSpend
    obj.jotai_goal = additionalDataObj.goal
  }

  return obj
}

