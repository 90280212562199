import React, { useEffect } from 'react'
import { Card, Image } from 'semantic-ui-react'

import { adAutoParamsImage, heroTeamImage, googleSheetsImage, PromoCardLoader } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getArtifacts } from '../../api/attribution'
import { getAccountUsers, getAuthorizations } from '../../api/account'
import * as routes from '../../baseRoutes'


const FREE_PROMO = {
  title: 'State of Spend Dashboard',
  description: `Get the latest data on where top brands like yours\nare putting their advertising dollars`,
  image: adAutoParamsImage,
  link: routes.spendTrends,
}


const PROMOS = {
  connectAdPlatforms: {
    title: 'Connect your Ad Platforms',
    description: `Centralize your advertising spend and\nperformance reporting in a few clicks`,
    image: adAutoParamsImage,
    link: routes.connectAdvertisingPlatform,
  },
  inviteUsers: {
    title: 'Invite Users',
    description: `Add your team to Rockerbox and\nshare your dashboards with them`,
    image: heroTeamImage,
    link: routes.manageUsers,
  },
  googleSheetsPlugin: {
    title: 'Get the Rockerbox Google Sheets Plugin',
    description: `Sync your marketing data to a spreadsheet and\nuse one of our pre-built dashboard templates`,
    image: googleSheetsImage,
    external: true,
  },
}


const Promo = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null
  const users = Context.getCache(`users`, getAccountUsers) || null
  const artifacts = Context.getCache('artifacts', getArtifacts) || {}

  const { account } = state
  const { tier } = account || {}
  const isLite = tier === 'lite'

  const [promo, setPromo] = React.useState(null)

  const gSheetsPluginLink = JSON.parse(artifacts?.google_sheets_plugin || '{}')?.link

  useEffect(() => {
    if (!authorizations || !users) return
    if (!!isLite)
      return setPromo(FREE_PROMO)
    if (authorizations.length < 2)
      return setPromo(PROMOS.connectAdPlatforms)
    if (users.length < 3)
      return setPromo(PROMOS.inviteUsers)
    const gSheet = {
      ...PROMOS.googleSheetsPlugin,
      link: gSheetsPluginLink,
    }
    return setPromo(gSheet)
  }, [authorizations, users])

  return (
    <Card
      fluid
      link
      style={{ overflow: 'hidden' }}
      onClick={() => window.open(promo.link, promo.link.includes('http') ? '_blank' : '_self')}
    >
      {!promo && <PromoCardLoader />}
      {!!promo && <>
        <Image
          centered
          size='small'
          src={promo.image}
          style={{
            background: 'transparent',
            padding: '20px 58px 10px',
            width: 'auto',
            height: 175,
            maxWidth: 'none',
          }}
        />
        <Card.Content
          textAlign='center'
          style={{ borderTop: 'none' }}
        >
          <Card.Header
            content={promo.title}
            style={{ color: '#475ddc'}}
          />
          <Card.Meta
            content={promo.description}
            style={{ whiteSpace: 'pre-wrap' }}
          />
        </Card.Content>
      </>}
    </Card>
  )
}

export default Promo
