import React, { Component } from "react";
import moment from "moment";
import {
  PagedIndexGrid,
  ContentCard,
  IndexGrid,
  ExpandableIndexGrid,
} from "@rockerbox/styleguide";
import { Table, Button, Menu, Loader, Icon } from "semantic-ui-react";
import ExpandableTable from "./ExpandableTable";
import { SegmentNameCell, ManageCell, DebugCell, ErrorCell } from "./TableCells";
import { tz, utcOffset } from "./tz";
import { DELIVERED, MYDELIVERED, FAILED } from "./constants";

class FinishedTable extends Component {
  render() {
    const {
      exportedReports,
      segmentIdNameMap,
      updateReportId,
      loading,
      displayType,
      reloading,
      isRBUser,
      username,
      match: { params: { category, id } = {} } = {},
    } = this.props;

    const finish_type = displayType === FAILED ? "failed" : "success";

    const deliveredData = exportedReports
      .filter((report) => report.regression == 0)
      .filter((report) => report.scheduled_report_id == null)
      .filter((report) => report.finish_type == finish_type)
      .map((report) => {
        report["delivered_at"] = new moment(
          moment(report.finished_at) + utcOffset
        ).format("LLL");
        report["segment_name"] = segmentIdNameMap[report["identifier"]] || null;
        return report;
      });

    const cols = [
      { display: "Report Type Name", key: "scheduled_report_name" },
      { display: "Segment Name", key: "segment_name", as: SegmentNameCell },
      { display: "Start Date", key: "start_date" },
      { display: "End Date", key: "end_date" },
      { display: `Delivered Time (${tz})`, key: "delivered_at" },
      {
        display: "Requested For",
        key: "full_path",
        as: IndexGrid.EllipsedCell,
      },
    ];

    if (finish_type === "success") {
      cols.push({ display: "Manage", key: "expand", as: ManageCell });
    } else {
      cols.push({ display: "Failure", key: "errorMsg",as: ErrorCell });
      if (isRBUser) {
        cols.push({ display: "WQ Link", key: "id", as: DebugCell });
      }
    }

    const deliveredReport =
      displayType === MYDELIVERED
        ? deliveredData.filter(
            (item) =>
              item.destination &&
              item.destination.toLowerCase() === username.toLowerCase()
          )
        : deliveredData;

    return (
      <ContentCard hasTable loading={loading}>
        <PagedIndexGrid
          data={deliveredReport}
          cols={cols}
          fallBackMsg="No reports currently delivered"
          expandCell={true}
          expandable={true}
          expandedComponent={ExpandableTable}
          itemsPerPage={10}
          idKey="id"
        />
      </ContentCard>
    );
  }
}

export default FinishedTable;
