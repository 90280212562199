import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import TimeRange from "../timerange";
import { ContentCard, SplitLayout } from "@rockerbox/styleguide";
import TreatmentCampaignTable from "./TreatmentCampaignTable";
import ResultCard from "./ResultCard";
import ExperimentChart from "./ExperimentChart";
import { Link } from "react-router-dom";
import { Grid, Header, Icon } from "semantic-ui-react";
import { experimentStore } from "./store";
import {
  getTiers,
  combineRow,
  groupBySearchKeyAndDate,
  createTableData,
  zTestCalculation,
  calcLiftAndRecommendation,
  getAllDates,
  constructChartData,
  calculateCumulativeValue,
  getCumulativeResult
} from "./helper";
import { searchSplitKey, defaultStart, defaultEnd } from "./constants";
import { proportionalZTest } from "./statistic";
import { CacheContext } from '../../utils/CacheContext.js'
import { getArtifacts } from '../../utils/api'

const selector = state => [
  state.rawData,
  state.getRawData,
  state.treatmentStartDate,
  state.treatmentEndDate,
  state.setTreatmentStartDate,
  state.setTreatmentEndDate,
  state.activeTreatments,
  state.selectedTreatments,
  state.setSelectedTreatments,
  state.fullTableData,
  state.setFullData,
  state.groupedData,
  state.setGroupedData,
  state.setComputingLoader,
  state.chartType
];

const ExperimentResultView = props => {
  const { name, platform, filter_id, data_tier, id, activeExperiment } = props;
  const [
    rawData,
    getRawData,
    treatmentStartDate,
    treatmentEndDate,
    setTreatmentStartDate,
    setTreatmentEndDate,
    activeTreatments,
    selectedTreatments,
    setSelectedTreatments,
    fullTableData,
    setFullData,
    groupedData,
    setGroupedData,
    setComputingLoader,
    chartType
  ] = experimentStore(selector);

  useEffect(() => {
    if (filter_id) {
      setTreatmentStartDate(moment(activeExperiment["start_date"]));
      setTreatmentEndDate(
        activeExperiment["end_date"]
          ? moment(activeExperiment["end_date"])
          : moment().subtract(1, "days")
      );
      getRawData(
        filter_id,
        activeExperiment["start_date"],
        activeExperiment["end_date"] ||
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
      );
    }
  }, [filter_id, activeExperiment["start_date"], activeExperiment["end_date"]]);

  useEffect(() => {
    setSelectedTreatments([
      "baseline",
      ...activeTreatments.map(treat => treat.id)
    ]);
  }, [activeTreatments]);

  const platformData = React.useMemo(
    () => rawData.filter(data => typeof data.platform === 'string' && data.platform.includes(platform)),
    [rawData, platform]
  );

  const testTiers = React.useMemo(() => getTiers(data_tier), [data_tier]);

  useEffect(() => {
    setComputingLoader(true);
    // add a computing loader if necessary
    if (platformData.length === 0) {
      setFullData([]);
      setGroupedData({});
      return;
    }

    const dataWithSearchKey = platformData.map(data => {
      return Object.assign({}, data, {
        searchKey: testTiers.map(tier => data[tier]).join(searchSplitKey),
        cpa: data["spend"] / data[`${data_tier}_assisted`],
        roas: data[`${data_tier}_assisted_revenue`] / data["spend"]
      });
    });

    const groupBySearchKey = groupBySearchKeyAndDate(dataWithSearchKey);

    const baselineData = activeExperiment
      ? [activeExperiment].map(
          createTableData("control", testTiers, groupBySearchKey)
        )
      : [];

    const treamentsData = activeTreatments
      .map(createTableData("treatment", testTiers, groupBySearchKey))
      .map(zTestCalculation(baselineData[0], data_tier));

    const fullData = baselineData
      .concat(treamentsData)
      .map(calcLiftAndRecommendation(data_tier));

    setFullData(fullData);
    setGroupedData(groupBySearchKey);
    setComputingLoader(false);
  }, [platformData, data_tier, activeExperiment, activeTreatments]);

  const selectedData = React.useMemo(() => {
    const selectedGroups = [];
    const selectedTreatmentKeys = activeTreatments
      .filter(treatment => selectedTreatments.indexOf(treatment.id) !== -1)
      .map(treatment =>
        testTiers
          .map(tier => treatment[`treatment_${tier}`])
          .join(searchSplitKey)
      );
    const baselineKey = testTiers
      .map(tier => activeExperiment[`control_${tier}`])
      .join(searchSplitKey);
    selectedTreatmentKeys.push(baselineKey);

    for (let key in groupedData) {
      if (selectedTreatmentKeys.indexOf(key) !== -1) {
        selectedGroups.push(groupedData[key]);
      }
    }

    return selectedGroups;
  }, [groupedData, selectedTreatments, activeTreatments]);

  const chartData = React.useMemo(() => {
    const dates = getAllDates(selectedData);
    const chartData = dates.map(constructChartData(selectedData, data_tier));
    calculateCumulativeValue(chartData);
    return getCumulativeResult(chartData, chartType);
  }, [selectedData, data_tier, chartType]);

  return (
    <>
      <Grid columns={2}>
        <Grid.Column floated='left'>
          <Link to={"/v2/reporting/experiments/view"}>
            <Icon name="angle left" />
            {"Back to All Experiments"}
          </Link>
        </Grid.Column>
        <Grid.Column className="flex-center-right">
          <TopRightLinks />
        </Grid.Column>
      </Grid>
      <ContentCard
        title={<>Experiment: <strong>{name}</strong></>}
        noContent
        topRight={
          <>
            <Icon name="clock" style={{fontSize: '1.25em', color: '#9c9c9c', marginRight: 5}} />
            <span style={{ fontSize: "16px" }}>{`${treatmentStartDate &&
              treatmentStartDate.format("YYYY-MM-DD")}`}</span>
            <Icon name="arrow right" style={{fontSize: '1.25em', color: '#9c9c9c', marginLeft: 6, marginRight: 5}} />
            <span style={{ fontSize: "16px" }}>
              {`${treatmentEndDate && treatmentEndDate.format("YYYY-MM-DD")}`}
            </span>
          </>
        }></ContentCard>
      <SplitLayout
        leftWidth={5}
        rightWidth={11}
        leftContent={<ResultCard {...props} tableData={fullTableData} />}
        rightContent={
          <ExperimentChart
            data={chartData}
            groupBySearchKey={groupedData}
            baselineKey={fullTableData[0] && fullTableData[0][`searchKey`]}
            treatmentKeys={fullTableData.slice(1).map(t => ({
              searchKey: t["searchKey"],
              treatment_id: t["treatment_id"]
            }))}
            data_tier={data_tier}
            style={{ height: "100%" }}
          />
        }
      />

      <TreatmentCampaignTable
        {...props}
        tableData={fullTableData}
        searchKeys={Object.keys(groupedData)}
      />
    </>
  );
};

const TopRightLinks = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { intercom_product_tours, experiments_help_docs } = Context.getCache(`artifacts`, getArtifacts) || {}
  const { experiments_analysis } = !!intercom_product_tours ? JSON.parse(intercom_product_tours) : {}
  const { experiments_help_docs_link } = !!experiments_help_docs ? JSON.parse(experiments_help_docs) : {}

  return (<>
    {!!experiments_analysis &&
      <Header
        as='h5'
        color='blue'
        className='cursor-pointer opacity-75-hover-100'
        onClick={() => !!window.Intercom && window.Intercom('startTour', experiments_analysis)}
        style={{margin: 0}}
      >
        <Icon name='play circle' style={{marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em'}} />
        {"Take a Tour"}
      </Header>
    }
    <Header
      as='h5'
      color='purple'
      className='cursor-pointer opacity-75-hover-100'
      onClick={() => !!window.Intercom && window.Intercom('showNewMessage', 'Hi! I have questions/feedback about Experiments.')}
      style={{margin: 0, paddingLeft: 15}}
    >
      <Icon name='question circle' style={{marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em'}} />
      {"Questions/Feedback"}
    </Header>
    {!!experiments_help_docs_link &&
      <Header
        as='h5'
        color='grey'
        className='cursor-pointer opacity-75-hover-100'
        onClick={() => window.open(experiments_help_docs_link, '_blank')}
        style={{margin: 0, paddingLeft: 15}}
      >
        <Icon name='book' style={{marginRight: '0.5rem', marginTop: -2, fontSize: '1.25em'}} />
        {"Documentation"}
      </Header>
    }
  </>)
}

export default ExperimentResultView;
