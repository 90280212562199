import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button } from 'semantic-ui-react';

const CheckoutForm = ({}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentPending, setPaymentPending] = useState(false)

  const enterLoading = () => {
    setPaymentPending(true)
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      // redirect: 'if_required'
    })
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setPaymentPending(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log("successful payment");
      <Redirect to='/onboarding/payment' />
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div className='onboarding-payment-error-message'>{errorMessage}</div>}
      <PaymentElement />
      <Button disabled={!stripe} color='purple' className='onboarding-payment-submit_button' loading={paymentPending} onClick={enterLoading}>Submit</Button>
    </form>
  )
};

export default CheckoutForm;
