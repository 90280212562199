import * as d3 from 'rockerbox_d3_legacy_clone';
import { generateDateRange } from '../AttributionReport/helpers';

const fillInBlankDates = (values, startDate, endDate) => {
  const valueKeys = Object.keys(values[0]).filter(key => key !== 'date')
  const blankObj = {}
  valueKeys.forEach(key => blankObj[key] = 0)

  const dateRange = generateDateRange(startDate, endDate).map(date => Object.assign({date}, blankObj))
  const dateObj = {}
  dateRange.map(d => dateObj[d.date] = d);

  const filledArray = dateRange.map(obj1 => {
    const obj2 = values.find(obj2 => obj2.date === obj1.date);
    return { ...obj1, ...obj2 };
  });

  return filledArray
}

export const filterFirstVisits = (raw, tier_1, tier_2, tier_3, tier_4, tier_5) => {
  const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 }
  Object.keys(tiers).map(t => {
    if (tiers[t] == undefined) delete tiers[t]
  })
  const filtered = Object.keys(tiers).reduce((p,c) => {
    if (tiers[c].length) {
      const splitTiers = typeof(tiers[c]) == "string" ? tiers[c].split(",") : tiers[c];
      return p.filter(row => splitTiers.indexOf(row[c]) > -1 || tiers[c].length == 0)
    }
    else return p
  }, raw)
  return filtered
}

const rollupDonutChartData = (_data, rollupCol) => {
  const d = d3.nest()
    .key(g => g[rollupCol])
    .rollup(v => d3.sum(v, x => x.num_new_visitors))
    .entries(_data)
    .map(row => {
      return {
        key: row.key,
        value: row.values
      }
    });
  const values = Object.values(d).sort((p,c) => d3.descending(p.value,c.value))
  const x = values.reduce((p,c) => {
    p[c.key] = c['value']
    return p
  }, {});
  return x;
}

export const rollupCPAChartData = (data) => {
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1)
    .rollup(v => d3.sum(v, x => x.spend)/d3.sum(v, x => x.num_new_visitors))
    .entries(data)
    .reduce((p,c) => {
      c.values.map(v => {
          p[c.key] = p[c.key] || {}
          p[c.key]['date'] = c.key
          p[c.key][v.key] = v.values === Infinity ? 0 : v.values
      })
      return p
    }, {});

  const values = Object.values(d).sort((p,c) => d3.descending(p.date,c.date))

  return values
}

export const rollupEngagementChartData = (data, startDate, endDate) => {
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1)
    .rollup(v => v.reduce((p,c) => p + Math.round(c.num_pageviews_mean*c.num_new_visitors), 0)/d3.sum(v, x => x.num_new_visitors))
    .entries(data)
    .reduce((p,c) => {
      c.values.map(v => {
          p[c.key] = p[c.key] || {}
          p[c.key]['date'] = c.key
          p[c.key][v.key] = v.values ? v.values : 0
      })
      return p
    }, {});

  const values = Object.values(d).sort((p,c) => d3.descending(p.date,c.date))
  const filledArray = fillInBlankDates(values, startDate, endDate)

  return filledArray
}

export const rollupChartData = (data, startDate, endDate) => {
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1)
    .rollup(v => d3.sum(v, x => x.num_new_visitors))
    .entries(data)
    .reduce((p,c) => {
    c.values.map(v => {
        p[c.key] = p[c.key] || {}
        p[c.key]['date'] = c.key
        p[c.key][v.key] = v.values
    })
    return p
  }, {});

  const values = Object.values(d).sort((p,c) => d3.descending(p.date,c.date) )
  const filledArray = fillInBlankDates(values, startDate, endDate)

  return filledArray
}


// For columns calcs
export const f = (values) => values.filter(x => !x.depth)

export const rollupDonutChartDataColors = (filtered, colors) => {
  const d = rollupDonutChartData(filtered, 'tier_1');
  return Object.keys(colors).sort()
    .reduce((obj, c) => Object.assign(obj, {[c]: d[c] || 0}), {});
}

export const calculateMeanPageviews = (values) => {
  const num_new_visitors = d3.sum(f(values), row => row.num_new_visitors);
  const total_page_views = f(values).reduce((sum,row) => sum + Math.round(row.num_pageviews_mean*row.num_new_visitors), 0);
  return total_page_views/num_new_visitors;
}

export const calculateMeanBounceRate = (values) => {
  const num_new_visitors = d3.sum(f(values), row => row.num_new_visitors);
  const num_bounces = d3.sum(f(values), row => row.num_bounces);

  // HACK TO HANDLE SUMMARY ROW WEIGHTED AVG
  if (!Number.isInteger(num_bounces)) {
    return d3.sum(f(values), row => row.num_bounces * (row.num_new_visitors / num_new_visitors)) 
  }

  return num_bounces/num_new_visitors;
}
