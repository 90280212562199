import React from "react"
import { LightBulbIcon } from '@heroicons/react/outline'
import { GrayHeader } from "./stateless"

const FilterHeader = ({ headerText }) => <>
  <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
    <LightBulbIcon style={{ width: 30, margin: '-2px 7px 0px 0', fill: '#FFDF78', stroke: '#b2b1ac' }} />
    <GrayHeader>{headerText}</GrayHeader>
  </div>
</> 

export default FilterHeader;
