import React, { Component } from 'react';
import { Dropdown, Button, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

const Scale = styled.div`
  ${props => props.inline ? "display: inline;" :"" }
  font-size:${props => props.scale}em;
`

const CustomButtonFormat = styled.div`
  & > .ui.basic.active.button, .ui.basic.buttons .active.button {
   color:rgb(2 108 172 / 85%) !important;
   background: rgba(0,0,0,.02)!important;
   font-weight:900;
  }
  & > .ui.basic.button, .ui.basic.buttons .button {
   color:rgb(2 108 172 / 75%) !important;
  }
  & > .ui.basic.buttons {
    border:none
  }
  & > .ui.basic.buttons .button {
    border-left:none
  }
`

const FormatSelector = ({ style, columnNameFormatter, fields, columns, formats, setFormat, rowName = "row", colName = "column" }) => {
  return fields
    .filter(row => (columns.find(col => col.value == row)||{type:"string"}).type != "string" )
    .map(column => {
      const { type } = columns.find(col => col.value == column)
      const options = type == "datetime" ? [
        {text:"Monthly", value: "monthly"},
        {text:"Weekly", value: "weekly"},
        {text:"Daily", value: "daily"}
      ] : [
        {text:"Sum", value: "sum"},
        {text:`Percent of ${rowName}`, value: "percent-row"},
        {text:`Percent of ${colName}`, value: "percent-col"},
        {text:"Percent of all", value: "percent-all"}
      ]

      const value = formats[column] || (type == "datetime" ? "monthly" : "sum")
      
      return <div {...{style}}>
        <label>{ columnNameFormatter(column) } format </label>
        <Scale scale={0.7} inline>
          <Dropdown selection value={value} options={options} onChange={setFormat(column)}/>
        </Scale>
      </div>
    })
}

export const ChooseFormat = ({ column, type, formats, setFormat }) => {
  // TODO: add in other things
  if (type != "datetime") return null

  const options = [
    {text:"D", value: "daily", popout: "Daily"},
    {text:"W", value: "weekly", popout: "Weekly"},
    {text:"M", value: "monthly", popout: "Monthly"},
  ] 
  const value = formats[column] || (type == "datetime" ? "monthly" : "sum")
  
  return <CustomButtonFormat style={{display:"inline-block",verticalAlign:"middle",marginLeft:"8px"}}>
    <Button.Group size="mini" floated="right" compact basic style={{marginTop:"-3px"}}>
      { options.map(row => 
          <Popup content={row.popout} trigger={
            <Button active={value == row.value} onClick={() => setFormat(column)(false, {value: row.value})}> 
              { row.text }
            </Button > 
            }
          />
        )
      }
    </Button.Group>
  </CustomButtonFormat>
}

export default FormatSelector;
