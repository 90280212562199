import React, { useEffect, useMemo, useState } from 'react'

import { Modal } from 'semantic-ui-react';

const VideoModal = ({ open, onClose }) => {
    return (
        <Modal
            {...{ open, onClose }}
            size='large'
            style={{height: "700px"}}
            // closeIcon
            className='modal-v3 video-tutorial-modal'
        >
            <div>
                <iframe
                    src="https://www.loom.com/embed/3163cab78cd1455aa814343d61a727f2"
                    frameBorder="0"
                    allowFullScreen
                    style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} />
            </div>
        </Modal>
    )
}

export default VideoModal;
