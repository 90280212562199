export const encode = (value) => encodeURIComponent(value).replaceAll("%25", "_PERCENT_")
export const decode = (value) => (value || value == 0) && decodeURIComponent(value.replaceAll("_PERCENT_","%25"))
export const convertPctURL = val => val.replace(/%(?!\d|[ABCDEF]+)/g, '%25')
export const urlDecodeTierStr = val => decode(convertPctURL(val))

export const urlDecodeTier = tiers => {
  if (!tiers) return tiers;
  
  return Object.entries(tiers).reduce((prev, cur) => {
    const [key, value] = cur;
    if (value) prev[key] = decodeURIComponent(value)
    return prev;
  }, {})
}

export const urlDecodeTierAfterSplit = tiers => {
  if (!tiers) return tiers

  return Object.entries(tiers).reduce((prev, cur) => {
    const [key, value] = cur;
    if (value) prev[key] = value.split(",").map(val => decode(convertPctURL(val)));
    return prev;
  }, {})
}

export const urlEncodeTier = tiers => {
  if (Array.isArray(tiers)) return tiers.map(tier => encode(tier))
  return encode(tiers);
}

export const formatTiersValue = tiers => {
  if (!tiers) return {}

  Object.keys(tiers).forEach(key => {
    const shouldSplit = tiers[key] && tiers[key].indexOf(",") !== -1;
    tiers[key] = shouldSplit ? tiers[key].split(",") : tiers[key];
  })
  return tiers;
}
