import React, { Component } from 'react';
import { StatCard, SplitLayout, PagedIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Label, Checkbox, Segment, Grid, Dropdown, Message, Table, Search, Input, Statistic, Button, Form, Icon, Divider } from 'semantic-ui-react';

import { getSegments, getTiersAsOptions, getConversionDataSummary } from './../../utils/api';
import CacheContext from '../../utils/CacheContext.js'
import SelectConversion from '../SelectSegment';
import CreateEvents  from './CreateEvents';

import { JoinMessage, EventMessage } from './Messages';
import { FormDropdown } from './SurveyForm';
import { remapResponses } from './CurrentResponses';
import { sumRecursive, TreeRow } from '../TransformTables/TreeTableData';

const buildOptions = (data) => data ? data.map(({ key }) => { return { key, text: key, value: key } }) : []

const getFeaturedSegment = () => {
  return getSegments()
    .then(segments => {
      const { action_id } = segments.find(seg => seg.featured)
      return action_id
    })
}

const EventSetup = (props) => {

  const { handleChange, updateStateArrayRowField, addAdvanced, removeAdvanced } = props
  const { survey, surveyKey, conversion, conversionKey, events, surveyResponseKey, responses, defaultResponse } = props;

  const [showDetails, setShowDetails] = React.useState(false)
  const [keyJoin, setKeyJoin] = React.useState([])
  const [joinSummary, setJoinSummary] = React.useState({})
  const [state, setState, Context] = React.useContext(CacheContext);
  const surveyData = survey ? 
    (Context.getCache(`conversionDataSummary${survey}`, getConversionDataSummary, survey) || false) : false

  const conversionData = conversion ? 
    (Context.getCache(`conversionDataSummary${conversion}`, getConversionDataSummary, conversion) || false) : false

  const featuredSegment = Context.getCache(`featuredSegmentId`, getFeaturedSegment) || false
  const tierOptions = featuredSegment ? (Context.getCache(`tiers${featuredSegment}`, getTiersAsOptions, featuredSegment) || false) : false

  const summaryOptions = React.useMemo(() => buildOptions(surveyData.summary), [surveyData.summary])
  const conversionOptions = React.useMemo(() => buildOptions(conversionData.summary), [conversionData.summary])
  const responseOptions = React.useMemo(
    () => buildOptions(responses.map(row => { return {key: row.response_display }})), 
    [responses.map(({ response_display }) => response_display).join()]
  )

  React.useEffect(() => {
    if (surveyData.conversion_data && conversionData.conversion_data) {
      const right = surveyData.conversion_data.reduce((p,c) => {
        p[c[surveyKey]] = c
        return p
      }, {})

      const surveySet = new Set(Object.keys(right))
      const conversionSet = new Set()

      const keyJoin = conversionData.conversion_data.map(row => {
        const conversion_key = row[conversionKey]
        conversionSet.add(conversion_key)
        const survey_key = (right[conversion_key] || {})[surveyKey]
        return { conversion_key, survey_key }
      })

      const overlap = keyJoin.filter(({ conversion_key, survey_key}) => conversion_key == survey_key)

      const joinSummary = {
        surveys: surveySet.size,
        conversions: conversionSet.size,
        overlap: overlap.length
      }
      
      setKeyJoin(keyJoin)
      setJoinSummary(joinSummary)
    }
  }, [surveyData, conversionData, conversionKey, surveyKey])




  const remove = removeAdvanced("events")

  return (
    <>
      <Grid columns="equal">
        <Grid.Column>
          <ContentCard maxHeight={events.length > 0 ? 400 : 0} title={"Create Events from Response Value"}>
            {
              responseOptions && <>
                <EventMessage />
                {events && events.length > 0 && events.map((event,pos) => {
                  return (
                    <CreateEvents 
                      tierOptions={tierOptions} 
                      options={responseOptions} 
                      {...{event, remove, updateStateArrayRowField, pos}}
                    />
                  )
                })}
                <Button primary onClick={() => addAdvanced("events")} content="event" icon="plus" size="mini" as="span"/>
              </>
            }
            { !responseOptions && "You must map event responses before you can create events" }
          </ContentCard>
            
        </Grid.Column>
        <Grid.Column>
          <ContentCard title="Preview of Events Added from Survey Responses" noContent />
          {/* 
          <Statistic.Group size={"mini"} style={{"justify-content":"space-between", margin: 1}}>
            <StatCard style={{flex:1,margin:5}} value={mappedEventSummary['totalResponses']} label="Responses" />
            <StatCard style={{flex:1,margin:5}} value={mappedEventSummary['skippedResponses']} label="Skipped" />
            <StatCard style={{flex:1,margin:5}} value={mappedEventSummary['addedEvents']} label="Events" />
            <StatCard style={{flex:1,margin:5}} value={mappedEventSummary['addedEvents']/mappedEventSummary['totalResponses']} label="Added Events" type="percent" />
          </Statistic.Group>
          */}
          <br/>
          <ResponseEvents {...{events, surveyData, responses, surveyResponseKey, defaultResponse}} />
        </Grid.Column>
      </Grid>
    </>
  )
}

const ResponseEvents = React.memo(({ events, surveyData, responses, surveyResponseKey, defaultResponse }) => {

  const [fields, setFields] = React.useState(["tier_1"])

  const eventsHash = events.map(row => { return fields.map(k => row[k]).join(",")}).join(",")
  console.log(eventsHash)

  const data = React.useMemo(() => {
    const { summary, conversion_data } = surveyData;
    const responseRules = responses;

    if (summary && Object.keys(summary).length > 0 && responseRules.length > 0) {
      const remapped = remapResponses(summary, surveyResponseKey, responseRules, defaultResponse)
        .reduce((obj, entry) => {
          obj[entry.originalValue] = entry.newValue
          return obj
        }, {})
      
      const valuesByKey = d3.nest()
        .key(row => remapped[row[surveyResponseKey]])
        .rollup(values => {
          const response = [...new Set(values.map(row => row[surveyResponseKey]))]
          const response_followup = [...new Set(values.map(row => row[surveyResponseKey + "_followup"]))]

          return { response, response_followup }

        })
        .map(conversion_data)

      return events
        .reduce((p, row) => {
          const { response, tier_1, tier_2, tier_3, tier_4, tier_5 } = row;
          const count = remapped[response]
          const TIERS = { response, tier_1, tier_2, tier_3, tier_4, tier_5 }

          const tiers = Object.keys(TIERS)
            .filter(key => !!TIERS[key] && TIERS[key].includes("}") )

          const items = tiers.reduce((events, tier) => {

            const values = valuesByKey[response] || {}

            return events.reduce((p,row) => {
              const macro = row[tier].slice(1,-1)
              const items = macro == 'response' ? [response] : values[macro] || []
              const newEvents = items.map(item => {
                return Object.assign({},row, {[tier]: item})
              })
              return [...p, ...newEvents]
            },[])

          },[TIERS])

          return [...p, ...items]
        }, [])
    }

    return []

  }, [eventsHash])

  const values = fields.reduce((p, field) => p.key(row => row[field]), d3.nest())
    .rollup(values => {
      return values.map(obj => { return {row: obj, count: 1 }} )
    })
    .entries(data)

  values.map(sumRecursive)

  const defaultOpen = fields.length -1
  const cols = ["response","tier_1", "tier_2", "tier_3", "tier_4", "tier_5"]
  const columns = IndexGrid.arrayToColumns(cols)
  const options = cols.map(value => { return { value, text: value } })

  return <>
    <Form.Group>
      <Label style={{lineHeight:1.8}}>
        Preview By
      </Label>
      <Form.Dropdown 
        value={fields}
        selection multiple options={options} 
        onChange={(_, {value}) => {
          setFields(value)
        }}
      />
    </Form.Group>
    <ContentCard hasTable>
      { fields.length && values.sort((p,c) => c.count - p.count).map(row => (<TreeRow {...{columns, defaultOpen, row}} />)) }
    </ContentCard>
  </>
})

export default EventSetup;
