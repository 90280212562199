import * as baseRoutes from './baseRoutes';

export * from './baseRoutes';

export const home = `${baseRoutes.home}/:startDate?/:endDate?`;

/* DATA */
// advertising platforms
export const advertisingPlatforms = `${baseRoutes.advertisingPlatforms}/:startDate?/:endDate?`;
export const advertisingPlatformLastStep = `${baseRoutes.advertisingPlatformLastStep}/:platformName?`;
export const advertisingTrackingParameters = `${baseRoutes.advertisingTrackingParameters}/:platformName`;

// promo codes
export const promoCodes = `${baseRoutes.promoCodes}/:activeStep?/:showCreate?`;
export const promoCodesCreate = `${baseRoutes.promoCodesCreate}/:entityType?`;
export const promoCodesEdit = `${baseRoutes.promoCodesEdit}/:id`;

// surveys
export const surveyEdit = `${baseRoutes.surveyEdit}/:id`;

// customers
export const conversionsOverview = `${baseRoutes.conversionsOverview}/:startDate?/:endDate?`;
export const conversionsDetail = `${baseRoutes.conversionsDetail}/:id?/:startDate?/:endDate?`;
export const conversionsUserProfile = `${baseRoutes.conversionsUserProfile}/:id/:date/:convHashId`;
export const conversionsConnect = `${baseRoutes.conversionsConnect}/:platform`;

// data warehouse
export const datawarehouseSetup = `${baseRoutes.datawarehouseSetup}/:platformName`;

/* SAVED REPORTS */
export const savedReports = `${baseRoutes.savedReports}`;

/* CHANNELS */
export const digitalAdvertising = `${baseRoutes.digitalAdvertising}/:view?/:platformName?/:startDate?/:endDate?`;
export const channelOverlap = `${baseRoutes.channelOverlap}/:platformName?/:startDate?/:endDate?/:id?`;

/* ATTRIBUTION */
export const attributionReport = `${baseRoutes.attributionReport}/:id?/:startDate?/:endDate?`;

/* FUNNEL */
export const funnelPosition = `${baseRoutes.funnelPosition}/:id?/:startDate?/:endDate?`;
export const newVisitors = `${baseRoutes.newVisitors}/:startDate?/:endDate?`;
export const marketingPaths = `${baseRoutes.marketingPaths}/:id?/:startDate?/:endDate?/:tier?`;

/* BENCHMARKS */
export const spendTrends = `${baseRoutes.spendTrends}`;
export const platformBenchmarks = `${baseRoutes.platformBenchmarks}`;
