import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Image, Segment } from 'semantic-ui-react'

import { chartBackground, logoWhiteVector } from '@rockerbox/styleguide'
import { useQuery } from '../../V3/hooks/urlState'
import { SignupForm } from '../../V3/components'


const Signup = () => {
  const { tier } = useParams()
  const [header, subheader] = useMemo(() => [
    `Get Started ${tier == 'starter' ? '' : tier == 'scale' ? '': 'for Free'}`,
    `Create a Rockerbox ${tier == 'starter' ? 'Starter' : tier == 'scale' ? 'Scale' : 'Free'} account`
  ], [tier])

  const query = useQuery()
  const source = query.get('source')
  const params = {
    signupSource: !!source ? `ui.${source}` : 'ui',
    email: query.get('email'),
    firstName: query.get('fname'),
    lastName: query.get('lname'),
    company: query.get('company'),
    website: query.get('website'),
  }

  return (
    <div className='ui-signup' style={{ backgroundImage: `url(${chartBackground})` }}>
      <div className='signup-wrap'>
        <div>
          <Image
            src={logoWhiteVector}
            size='small'
            as='a'
            href='https://www.rockerbox.com'
            target='_blank'
            className='logo'
          />
        </div>
        <SignupForm
          as={Segment}
          {...params}
          {...{ tier, header, subheader }}
        />
      </div>
    </div>
  )
}

export default Signup
