import React, { useState, useEffect } from 'react';
import SetupStatus, { defaultFunc } from './SetupStatus';

const Status = ({ getSegmentId, datasets = [], funcs = []}) => {
  const [segmentId, setSegmentId] = useState(false);

  useEffect(() => {
    getSegmentId()
      .then(id => setSegmentId(id));
  }, []);

  funcs.forEach((f) => {
    f.func = defaultFunc
  })

  return segmentId ? (
    <SetupStatus
      filter_id={segmentId}
      {...{ datasets, funcs }}
    />
  ) : null;
};

export default Status;
