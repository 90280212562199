import { colorHash } from '../../../components/ViewAttribution/AttributionPercent';

export const arrowColumnUnique = (arrow, column) => new Array(...new Set([...arrow.column(column)]))

export const getColorsFromHash = (tiersDataRaw, tierColors) => {

  if (!tiersDataRaw) return {}

  return arrowColumnUnique(tiersDataRaw, "tier_1")
    .reduce((p,c) => {
      p[c] = tierColors[c] || colorHash(c);
      return p;
    },{});

  //return d3.nest()
  //  .key(g => g.tier_1)
  //  .entries(tiersData)
  //  .reduce((p,c) => {
  //    p[c['key']] = tierColors[c['key']] || colorHash(c['key']);
  //    return p;
  //  },{});
}
