import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Aux } from '../stateless';
import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

import { SET_CONVERSION_SEGMENTS, CONV_ENDPOINT } from '../SelectSegment';
import { ATTRIBUTION_ENDPOINT, SET_SUMMARY_DATA_BY_ID } from '../ViewAttribution';
import { buildDates, end_date, aggByDate, makeTotal } from '../ViewAttribution/helpers';

import C from './component';
import moment from 'moment';

const err = (err) => console.log(`error ${err} `);

function mapStateToProps(state, props) {
  const segments = state.segment.segments;
  const summary = state.attribution.summary;

  return {
    segments,
    summary
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    setConversionSegments: data => {
      const conversions = data.response
        .filter( segment => segment.action_type == 'conversion')
        .sort((a,b) => a.display_order - b.display_order)

      dispatch(rootAction(SET_CONVERSION_SEGMENTS, conversions));
    },
    setNoData: (segments, id) => data => {

      segments.map(x => {
        if (x.action_id == id) {
          x.daily = {};
          x.total_conversions = 0;
          x.attributed_revenue = 0;
        }
      })
      dispatch(rootAction(SET_CONVERSION_SEGMENTS, segments));
    },
    setSummaryData: (segments, id, dates) => data => {
      const {response, details: {keys}} = data;
      data = {"response": data['response'][keys[0]]}
      const _default = { attributed: 0, unattributed: 0, total: 0 };

      const byDate = data.response.reduce((p, c) => {
        p[c.date] = c;
        p[c.date].count = Object.assign({},_default,c.count);
        p[c.date].revenue = Object.assign({},_default,c.revenue);
        return p;
      }, {});
      const count_default = { count: _default };
      const revenue_default = { revenue: _default };

      const conversionsByDate = dates
        .map(aggByDate(byDate, 'count', count_default))
        .map(makeTotal);
      const conversions = conversionsByDate.reduce(
        (p, c) => {
          const a = c['attributed'] || 0;
          const u = c['unattributed'] || 0;
          p['attributed'] += a
          p['total'] += u + a;
          return p;
        },
        { attributed: 0, total: 0 }
      );
      const revenueByDate = dates
        .map(aggByDate(byDate, 'revenue', revenue_default))
        .map(makeTotal);
      const revenue = revenueByDate.reduce(
        (p, c) => {
          const a = c['attributed'] || 0;
          const u = c['unattributed'] || 0;
          p['attributed'] += a;
          p['total'] += u + a;
          return p;
        },
        { attributed: 0, total: 0 }
      );


      segments.map(x => {
        if (x.action_id == id) {
          x.daily = { conversionsByDate, revenueByDate };
          const total_this_week = d3.sum(x.daily.conversionsByDate.slice(-7),x => x.total)
          const total_last_week = d3.sum(x.daily.conversionsByDate.slice(-14,-7),x => x.total)

          const revenue_this_week = d3.sum(x.daily.revenueByDate.slice(-7),x => x.total)
          const revenue_last_week = d3.sum(x.daily.revenueByDate.slice(-14,-7),x => x.total)

          x.total_wow = (total_this_week-total_last_week)/total_last_week
          x.revenue_wow = (revenue_this_week-revenue_last_week)/revenue_last_week

          x.summary = { conversions, revenue };
          x.total_conversions = conversions.total;
          x.attributed_conversions = conversions.attributed;
          x.total_revenue = revenue.total;
          x.attributed_revenue = revenue.attributed;
        }
      });

      dispatch(rootAction(SET_CONVERSION_SEGMENTS, segments));
      dispatch(
        rootAction(SET_SUMMARY_DATA_BY_ID, {
          [id]: { conversionsByDate, revenueByDate }
        })
      );
    }
  };
}


class ConnectedComponent extends Component {
  state = {
    value: 30,
    showChildren: false
  }

  handlePastDaysChange = (event, data) => {
    const {value} = data;
    this.setState({value});
  }

  handleShowChildren = (event, {checked}) => {
    checked ? this.setState({showChildren: true}) : this.setState({showChildren: false})
  }

  render() {
    const { segments, setConversionSegments, setSummaryData, setNoData } = this.props;
    const { value, showChildren } = this.state;
    const _startValue = moment.utc().utcOffset(-5).subtract(value, 'days')
    const startValue = _startValue.format('YYYY-MM-DD');
    const monthAgo = moment.utc().utcOffset(-5).subtract(30,'days');
    const endDate = moment.utc().utcOffset(-5).subtract(1,'days').format('YYYY-MM-DD');

    const nonChildrenSegments = segments.filter(segment => !segment.is_child || segment.featured);
    const filteredSegments = showChildren ? segments : nonChildrenSegments;
    const hasChildSegments = segments.length > nonChildrenSegments.length

    return (
      <Aux>
        <Fetch
          preventFetch={!!segments.length}
          endpoint={CONV_ENDPOINT}
          error={err}
          success={setConversionSegments}
        />
        {filteredSegments.map(segment => {
          const segFirstDate = segment.first_reporting_date || startValue; // string
          const firstDate = moment.max(moment(segFirstDate),_startValue).format("YYYY-MM-DD");  // string
          const endpoint = `${ATTRIBUTION_ENDPOINT}?start_date=${firstDate}&end_date=${endDate}&keys=summary&identifier=`;
          return (
            <Fetch
              endpoint={endpoint + segment.action_id}
              error={setNoData(segments, segment.action_id)}
              success={setSummaryData(segments, segment.action_id, buildDates(firstDate, endDate))}
            />
          )
        })}
        <C
          {...this.props} value={this.state.value}
          handlePastDaysChange={this.handlePastDaysChange}
          hasChildSegments={hasChildSegments}
          nonChildrenSegments={nonChildrenSegments}
          handleShowChildren={this.handleShowChildren}
          showChildren={this.state.showChildren}
          filteredSegments={filteredSegments}
        />
      </Aux>
    );
  }
};

const AttributionContentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConnectedComponent));

export default AttributionContentWrapper;
