import React from 'react';
import { Container, Image, Header, Button } from 'semantic-ui-react';

import { track } from '../../utils/tracking';

const UpgradePromo = ({
  trackItem, chatMessage, imageSrc, imageSize = 'large',
  content, subheader,
}) => {
  const onClick = () => {
    track(trackItem);
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
  };

  return (
    <Container textAlign="center">
      <Image
        src={imageSrc}
        centered
        size={imageSize}
        style={{ marginTop: 50 }}
      />
      <Header
        as="h1"
        content={content}
        subheader={subheader}
        style={{ marginTop: 50 }}
      />
      <Button
        content="Contact Us to Upgrade"
        onClick={onClick}
        primary
        size="large"
        style={{ marginTop: 20 }}
      />
    </Container>
  );
};

export default UpgradePromo;
