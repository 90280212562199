import { useContext, useState, useEffect, useMemo } from 'react'

import { CacheContext } from '../../../../utils/CacheContext'
import { getLegacyPaths } from '../../../api/attributionCache'
import { addMetrics, detectPlatformTier, generateCompanyRegex } from './helpers'
import { PLATFORM_NAMES, EXCLUDED_TIERS } from './constants'

export const usePathOverlaps = (platform, startDate, endDate, segId) => {
  const [state, setState, Context] = useContext(CacheContext)
  const { account } = state

  const [pathsRaw, setPathsRaw] = useState(undefined)

  // fetch paths dataset
  const PATHS_DATASET = 3 // path_t3
  useEffect(() => {
    if (!segId || segId == 0) return
    setPathsRaw(undefined)
    getLegacyPaths(segId, PATHS_DATASET, startDate, endDate)
      .then(rawData => {
        setPathsRaw(rawData)
        window.pathsRaw = rawData
      })
      .catch(err => console.log(err))
  }, [segId, startDate, endDate, platform])

  // calculate overlaps
  const { pathsProcessed, overlapChannels } = useMemo(() => {
    if (!pathsRaw || !platform) return {}

    const platformRegex = new RegExp(PLATFORM_NAMES[platform].join('|'))
    const organicRegex = new RegExp('organic')
    const exclusionRegex = new RegExp(EXCLUDED_TIERS.join('|'))
    const companyNameRegex = generateCompanyRegex(account)

    // figure out which tier contains platform
    const platformTier = detectPlatformTier(pathsRaw, platformRegex, organicRegex)

    const processed = {}
    const processedPlatform = {}
    let overlappingKeys = []

    // classify paths
    pathsRaw.forEach(path => {
      // generate a list of platforms in each path
      const pathPlatforms = path.path.map(tp => {
        const tierCombined = tp.join('___').toLowerCase()
        const tierPlatform = (tp[platformTier-1] || '').toLowerCase()

        if (tierCombined.match(organicRegex)) return null
        if (tierCombined.match(exclusionRegex)) return null
        if (tierPlatform.match(companyNameRegex)) return null
        if (tierCombined.match(platformRegex)) return platform
        return tierPlatform
      })
      const pathPlatformsSet = [...new Set(pathPlatforms)].filter(p => !!p)

      if (!pathPlatformsSet.length) return

      const hasAnchorPlatform = pathPlatformsSet.includes(platform)
      const hasOtherPlatforms = !pathPlatformsSet.every(p => p == platform)

      if (hasAnchorPlatform) {
        addMetrics(processedPlatform, path) // Add to total platform counts
        if (hasOtherPlatforms) {
          addMetrics(processedPlatform, path, 'overlap') // If registered touchpoint exists add also to overlap platform counts
        }
      }

      pathPlatformsSet.filter(p => p != platform).forEach(p => {
        if (!processed[p]) processed[p] = {}
        addMetrics(processed[p], path)
        if (hasAnchorPlatform) {
          addMetrics(processed[p], path, 'overlap')
          overlappingKeys.push(p)
        }
      })
    })

    processed["platform"] = processedPlatform
    overlappingKeys = [...new Set(overlappingKeys)]

    return {
      pathsProcessed: processed,
      overlapChannels: overlappingKeys,
    }

  }, [pathsRaw])

  return { pathsProcessed, overlapChannels }
}
