import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import { Dropdown, Button } from 'semantic-ui-react';

const Settings = ({ title, key_name, get, getOptions, save}) => {

  const [settings, setSettings] = React.useState([])
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    get().then(resp => setSettings(resp.response.map(row => row[key_name] )) )
  }, [])

  React.useEffect(() => {
    getOptions().then(resp => setOptions(resp.response))
  }, [])

  const prepare = () => settings.map(item => { return { [key_name]: item }})
  const update = (_, {value}) => setSettings(value)
  const submit= () => save(prepare())

  return <ContentCard title={title}>
    <div style={{display:"flex",alignItems: "center"}}>
      <Dropdown multiple selection placeholder options={options} onChange={update} value={settings} />
      <Button size='small' as='a' color='blue' onClick={submit}>Save</Button>
    </div>
  </ContentCard>
}

export default Settings;
