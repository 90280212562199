import React from 'react';
import styled from "styled-components";
import { Button, Menu, Dropdown, Image, Container, Header, Icon, Modal, Loader, Dimmer } from 'semantic-ui-react';
import { Announcements } from '../Announcements';
import { spendPreviewImage } from '@rockerbox/styleguide';

const dateOptions = [
  {value: 30, text: 'Past 30 days'},
  {value: 60, text: 'Past 60 days'},
  {value: 90, text: 'Past 90 days'},
  {value: 120, text: 'Past 120 days'},
  {value: 180, text: 'Past 180 days'}
]

export const upperCase = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1) : str

export const SelectDays = ({handlePastDaysChange, numDays}) => (
  <Dropdown
    onChange={handlePastDaysChange}
    options={dateOptions}
    value={numDays}
    style={{border: 'none'}}
  />
)


export const SpendViewAssignments = ({ assignments, exclusionAssignments, activeAssignmentId, selectAssignment }) => (
  <React.Fragment>
  { assignments.length > 0 ?
    <Menu vertical style={{"width":"100%"}}>
      <Menu.Item active={!activeAssignmentId} onClick={selectAssignment}>
        <Menu.Header>Overall Account Spend </Menu.Header>
      </Menu.Item>
      <Menu.Item >
        <Menu.Header>By Assignment Rule </Menu.Header>

        { assignments.length > 0 &&
          <Menu text vertical style={{marginLeft:"10px"}}>
            {
              assignments.map(({id, name}) => (
                <Menu.Item active={id == activeAssignmentId} onClick={selectAssignment} {...{id, name}}>
                  { name }
                </Menu.Item>
              ))
            }
          </Menu>
        }
        { exclusionAssignments.length > 0 &&
          <React.Fragment>
            <Menu.Header>By Exclusion Rule </Menu.Header>
            <Menu text vertical style={{marginLeft:"10px"}}>
              {
                exclusionAssignments.map(({id, name}) => (
                  <Menu.Item active={id == activeAssignmentId} onClick={selectAssignment} {...{id, name}}>
                    { name }
                  </Menu.Item>
                ))
              }
            </Menu>
          </React.Fragment>
        }
      </Menu.Item>
    </Menu>
    :
    <Menu vertical style={{"width":"100%"}}>
      <Menu.Item style={{"marginBottom": "47px"}}>
        <Menu.Header>No Spend Mappings</Menu.Header>
      </Menu.Item>
    </Menu>
  }
  </React.Fragment>
)

export const SpendViewAccounts = ({ activePlatform, activeAccountId, activeAccountRowId, accounts, selectPlatform, selectAccount, platforms }) => {

  return (
  <React.Fragment>
  { platforms.length > 0 && accounts.length > 0 &&
    <Menu vertical style={{width:"100%"}}>
      { accounts.map(d => (
          <Menu.Item name={d.platform} onClick={d.platform != activePlatform ? selectPlatform : undefined}>
            <Menu.Header>{ d.display_name || d.platform }</Menu.Header>
            { d.platform == activePlatform &&
              <Menu text vertical style={{marginLeft:"10px"}}>
                {
                  d.authorizations.map(d => (
                    <Menu.Item name={d.account_name || '(unauthorized)'} id={d.id}
                      active={activeAccountRowId == d.id}
                      onClick={selectAccount}
                    >
                      <React.Fragment>
                        <span>{ d.account_name || '(unauthorized)'}</span>
                      </React.Fragment>
                    </Menu.Item>
                  ))
                }
              </Menu>
            }
          </Menu.Item>
        )
      )}
    </Menu>
  }
  </React.Fragment>
)
}

export const SpendEmptyView = ({ onButtonClick }) => (
  <Container textAlign='center'>
    <Image
      src={spendPreviewImage}
      size='medium'
      bordered
      circular
      centered
      className='spend-preview-image m-t-20'
    />
    <Header as='h2'>
      Spend Reporting
      <Header.Subheader>Connect your buying platforms to Rockerbox to enable spend reporting</Header.Subheader>
    </Header>
    <Button
      primary
      size='large'
      onClick={onButtonClick}
    >
      Add Accounts
    </Button>
  </Container>
)
