import React, { Component, Fragment } from 'react';
import { ContentCard, ScrollingPopup } from '@rockerbox/styleguide';
import { Container, Divider, Grid, Button, Form, Dropdown, Header, Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes';

const SpendDataSource = ({dataSources, spendDataSourceOptions, handleSpendSourceChange}) => {
  return (
    <Fragment>
      <Header
        as="h4"
        content="Spend Data Source"
        subheader={`Select where you'd like to pull spend data from.`}
      />
      <Form.Group widths="equal">
       <Form.Dropdown
         selection
         fluid
         placeholder="Select a Data Source"
         value={dataSources.spendSource}
         options={spendDataSourceOptions}
         onChange={(e,data) => handleSpendSourceChange(data, "dataSources", "spendSource")}
       />
     </Form.Group>
    </Fragment>
  )
}

const SpendDataFilters = ({dataSources, addSpendFilter, spendFieldOptions, handleSpendDataChange, spendPlatformTierOptions, handleTextChange}) => {
  return (
    <Fragment>
      <Header
      as="h4"
      content="Spend Data Filters (Optional)"
      subheader={`You can set filters to select a subset of a Spend Data Source.`}
      />
      <Table className="m-b-10">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7} textAlign="center">
              Spend Data Fields
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="center"
              content="="
            />
            <Table.HeaderCell width={7} textAlign="center">
              Spend Data Values
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataSources.spendFilters.map((c, i) => {
            return (
              <Table.Row>
                <Table.Cell textAlign="center">
                  <Dropdown
                    selection
                    fluid
                    placeholder="Select a Spend Data Field"
                    options={spendFieldOptions}
                    value={c.field}
                    disabled={!dataSources.spendSource}
                  >
                    <Dropdown.Menu >
                      {/* <Dropdown.Header content="Facebook Ads Values" /> */}
                      {spendFieldOptions.map((current,j) => (
                        <Dropdown.Item
                          key={current.value}
                          value={current.value}
                          onClick={(e,data) => handleSpendDataChange(data, i, "field", "dataSources")}
                        >
                          {current.text}
                        </Dropdown.Item>))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
                <Table.HeaderCell
                  width={1}
                  textAlign="center"
                  content="="
                />
                <Table.Cell textAlign="center">
                  <ScrollingPopup
                    trigger={
                      <Form.Input
                        fluid
                        placeholder="Bucket value"
                        type="text"
                        value={c.value}
                        onChange={(e,data) => handleSpendDataChange(data, i, "value", "dataSources")}
                      />
                    }
                    header="Matching Values"
                    position="bottom left"
                    content={<OptionsPopupContent listItems={spendPlatformTierOptions[c.field]} inputVal={c.value}/>}
                    on="click"
                    flowing
                  />
                  {/* <Form.Dropdown
                    placeholder='Select a value to filter by'
                    fluid
                    search
                    selection
                    options={spendPlatformTierOptions[c.field]}
                    value={c.value}
                    disabled={!c.field}
                    onChange={(e,data) => handleSpendDataChange(data, i, "value", "dataSources")}
                  /> */}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Button
        type="button"
        content="Add Spend Data Filter"
        icon="plus"
        labelPosition="left"
        onClick={(event) => addSpendFilter(event)}
      />
    </Fragment>
  )
}

class OptionsPopupContent extends Component {
  state = {
    originalList: [],
    filteredData: [],
    value: ''
  }

  componentDidMount() {
    this.setState({filteredData: this.filterData(this.props.listItems, this.props.inputVal)})
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.listItems !== this.props.listItems) {
      this.setState({filteredData: this.filterData(this.props.listItems, this.props.inputVal)})
    }
    if(prevProps.inputVal !== this.props.inputVal) {
      this.setState({filteredData: this.filterData(this.props.listItems, this.props.inputVal), value: this.props.inputVal})
    }
  }

  filterData = (data, value) => {
    try {
      const re = new RegExp(value.toLowerCase());
      const filteredData = data.filter(row => row.text.toString().toLowerCase().match(re));

      return filteredData
    } catch(e) {
      return []
    }
  }


  render() {
    const {filteredData} = this.state;

    if(filteredData && filteredData.length > 0) {
      return <React.Fragment>
        {filteredData.map(c => <div>{c.text}</div>)}
      </React.Fragment>
    } else {
      return <p>No matches found</p>
    }
  }
}


class DataSource extends Component {

  backToIndex = () => {
    this.props.history.push(routes.spendIndex);
  }

  render() {
    return (
      <Form>
        <Container fluid>
          <SpendDataSource
            dataSources={this.props.dataSources} spendDataSourceOptions={this.props.spendDataSourceOptions}
            handleSpendSourceChange={this.props.handleSpendSourceChange}
          />
          <SpendDataFilters
            dataSources={this.props.dataSources}
            addSpendFilter={this.props.addSpendFilter}
            spendFieldOptions={this.props.spendFieldOptions}
            handleSpendDataChange={this.props.handleSpendDataChange}
            spendPlatformTierOptions={this.props.spendPlatformTierOptions}
            handleTextChange={this.props.handleTextChange}
          />
        </Container>
        <Divider section />
        <div className="m-t-20">
          <Grid fluid columns={2}>
            <Grid.Column>
              <Button secondary type="button" onClick={this.backToIndex}>Cancel</Button>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button as='a' primary textAlign="right" onClick={() => this.props.updateActiveState("marketing")}>
                Next
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      </Form>
    );
  }
}

export default withRouter(DataSource);
