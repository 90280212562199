import React, { Component } from 'react';
import moment from 'moment';
import { Checkbox, Label, Table, Popup, Menu, Icon, Container } from 'semantic-ui-react';
import { ContentCard, ExpandableIndexGrid, IndexGrid } from '@rockerbox/styleguide';
import { getAddressDataTimeseries, getConversionSegments } from '../../utils/api';
import TimeRange, { yesterday, week } from '../timerange';

class AddressDataSource extends TimeRange {
  state = {
    startDate: week,
    endDate: yesterday,
    featuredFilterId: false,
    customerData: [],
    loading: true
  }

  componentDidMount() {
    getConversionSegments()
      .then(data => {
        const featuredFilterId = data
          .filter(segment => segment.featured == 1)
          .map(seg => {
            return { id: seg.action_id }
          })[0]['id']
        this.setState({ featuredFilterId })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, featuredFilterId } = this.state;
    if ((startDate != prevState.startDate) || (prevState.featuredFilterId != featuredFilterId)) { 
      getAddressDataTimeseries(featuredFilterId, startDate, endDate)
        .then(customerData => {
          this.setState({ customerData, loading: false })
        })
    }
  }

  render() {
    const { customerData, startDate, endDate, loading } = this.state;
    const cols = [
      { display: "Date", key: "date"},
      { display: "Total", key: "count"},
    ];

    console.log(customerData);

    return (
      <ContentCard 
        hasTable title="Address Data" explainer="" 
        topRight={this.renderDateSelector()}
      >
        { loading && <ContentCard.CardLoader /> }
        {
          !loading && <IndexGrid 
            data={customerData} 
            cols={cols} 
            as={ContentCard.Table} 
            idKey="date"
            expandedComponent={this.expandedSection}
            fallbackMsg="No customer data"
          />
        }
        { this.renderDateModal() }
      </ContentCard>
    )
  }
}

export default AddressDataSource;

