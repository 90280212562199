import React from 'react';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import '../../validations/DateParser/react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import { ArrowSmRightIcon } from '@heroicons/react/outline';

const ANCHOR_LEFT = 'left';
const HORIZONTAL_ORIENTATION = 'horizontal';
const START_DATE = "startDate"
const END_DATE = "endDate"

//TODO: convert to hooks
class DateRangePickerWrapper extends React.Component {

  state = {
    focusedInput: null
  }

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.setState({ startDate, endDate })
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.state;
    if (prevState.focusedInput != this.state.focusedInput && this.state.focusedInput == null) return this.props.onChange({ startDate, endDate })

    if ((this.props.startDate != prevProps.startDate) || (this.props.endDate != prevProps.endDate)) {
      this.setState({ startDate: this.props.startDate, endDate: this.props.endDate })
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    const obj = {
      startDate: startDate || this.state.startDate,
      endDate: endDate || this.state.endDate
    }
    this.setState(obj);
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  }

  handleOutsideRange = (day) => {
    const { firstReportingDate } = this.props;
    const yesterday = moment.utc().utcOffset(-5).subtract(1,'days').endOf('day');
    const days150 = moment.utc().utcOffset(-5).subtract(150,"days");
    const minDate = firstReportingDate ? day.isBefore(moment(firstReportingDate)) : day.isBefore(days150);
    const maxDate = day.isAfter(yesterday);

    return minDate || maxDate
  }


  render() {
    const { focusedInput, startDate, endDate } = this.state;
    return (
      <div>
        <DateRangePicker
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          noBorder={true}
          isOutsideRange={(day) => this.handleOutsideRange(day)}
          hideKeyboardShortcutsPanel
          startDateId="uniqueStartDate"
          endDateId="uniqueEndDate"
          minimumNights={0}
          customArrowIcon={<ArrowSmRightIcon style={{ width: 20, height: 20 }} />}
        />
      </div>
    );
  }
}

export default DateRangePickerWrapper;