import React, { useContext, useMemo, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations } from '../../api/account'
import { submitAndWaitForJob } from '../../utils/workqueueJob'
import { track } from '../../../utils/tracking'


const SyncAds = ({ platform, platformDisplayName }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const authorizations = Context.getCache(`authorizations`, getAuthorizations)

  const [submitting, setSubmitting] = useState(false)

  const fbAccountIds = useMemo(() => {
    if (!authorizations?.length) return
    const fbAuths = authorizations.find(x => x.platform === 'facebook')?.authorizations
    if (!fbAuths) return
    return fbAuths.map(x => x.account_id)
  }, [authorizations])

  const buttonIcon = useMemo(() => {
    if (submitting) return <RefreshIcon className='spinning' style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
    return <CheckCircleIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
  }, [submitting])

  const buttonContent = useMemo(() => {
    if (submitting) return ` Syncing ${platformDisplayName} Ads`
    return ` Done Updating Ads? Verify Changes`
  }, [submitting])

  const syncFbAds = async () => {
    if (!fbAccountIds?.length) return
    setSubmitting(true)
    const fbSyncPromises = fbAccountIds.map(account_id => submitAndWaitForJob('facebook_ads/sync', { account_id }))

    toast.success(`We're syncing ads from your ${platformDisplayName} account. This may take a few minutes.`, { duration: 10000 })
    track('data.marketing.advertising_platforms.tracking.sync_ads', { platform })
    
    await Promise.all(fbSyncPromises)
    window.location.reload()
  }

  return (
    <Button
      inverted
      size='large'
      color='purple'
      content={<>
        {buttonIcon}
        {buttonContent}
      </>}
      onClick={syncFbAds}
      disabled={submitting}
      style={{ margin: 0 }}
      className='remove-box-shadow'
    />
  )
}

export default SyncAds
