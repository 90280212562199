import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import { ContentCard, SplitLayout } from "@rockerbox/styleguide";
import OffsetAreaChart from "./AreaChart";
import ErrorPage from "./ErrorPage";
import SettingForm from "./OttOffsetSetting";
import OttTypeSelection, { ottTypesMap } from "./TypeSelection";
import SetupSummaryTable from "./SetupSummaryTable";
import CacheContext from "../../utils/CacheContext.js";
import {
  aggregateSummary,
  prepareChartData,
  getOffsetOptions,
  prepareTatariChartData,
} from "./helper";
import {
  getSegments,
  getConversionSegments,
  fetchServedSummary,
  getOttOffset,
  getOttTypes,
} from "../../utils/api";

const OttAnalysis = (props) => {
  const { startDate, endDate } = props;
  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const ottTypes = Context.getCache("ottTypes", getOttTypes) || null;
  const [offset, setOffset] = useState({});
  const [apiOffset, setApiOffset] = useState({});
  const [ottType, setOttType] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeArea, setActiveArea] = useState("web");

  const areaKeys =
    ottType === "tatari" ? ["tatari"] : ["device", "web", "mobile"];
  const allPageSegmentId = segments
    .filter((item) => item.action_name === "All Pages")
    .map((item) => item.filter_id)[0];

  const apiAnalyticAsync = async (dataType) => {
    try {
      setLoading(true);
      setError(false);
      const newStartDate =
        startDate || moment.utc().utcOffset(-5).subtract(8, "days").format("YYYY-MM-DD");
      const newEndDate =
        endDate || moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD");
      const [summary, offsets, config] = await Promise.all([
        fetchServedSummary(
          `${dataType}_event_summary`,
          newStartDate,
          newEndDate,
          allPageSegmentId
        ),
        fetchServedSummary(
          `ott_${dataType}_offset_analysis`,
          newStartDate,
          newEndDate,
          allPageSegmentId
        ),
        getOttOffset().catch((e) => {
          console.log(e);
          return {};
        }),
      ]);

      const { tiers, counts, detailDict } = aggregateSummary(summary, dataType);
      const loadedChartData =
        ottType === "tatari"
          ? prepareTatariChartData(offsets, counts, dataType)
          : prepareChartData(offsets, counts, dataType);
      setChartData(loadedChartData);
      setOffset(config);
      setApiOffset(config);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ottTypes == null) {
      return;
    }
    if (ottTypes["ott_types"] && ottTypes["ott_types"].length > 0) {
      const firstType = ottTypesMap[ottTypes["ott_types"][0]];
      setOttType(firstType);
      if (firstType === 'tatari') {
        setActiveArea('tatari');
      }
    } else {
      setError(true);
      setLoading(false);
    }
  }, [ottTypes && ottTypes["ott_types"]]);

  useEffect(() => {
    if (allPageSegmentId && ottType) {
      apiAnalyticAsync(ottType);
    }
  }, [allPageSegmentId, ottType]);

  if (error) {
    return <ErrorPage />;
  }

  // const AnalysisCard = areaKeys.map((areaKey) => {
  //   const type = areaKey.charAt(0).toUpperCase() + areaKey.slice(1);
  //   const defaultOffset =
  //     ottType &&
  //     apiOffset[`${ottType}_offset`] &&
  //     apiOffset[`${ottType}_offset`][areaKey];
  //   const currentOffset =
  //     ottType &&
  //     offset[`${ottType}_offset`] &&
  //     offset[`${ottType}_offset`][areaKey];
  //   const offsetOptions = getOffsetOptions(chartData, areaKey).map((item) =>
  //     Object.assign(item, { text: `${item.text} (${item.value} mins)` })
  //   );
  //   const offsetItem =
  //     currentOffset && chartData.find((item) => item.offset == currentOffset);
  //   const total =
  //     chartData.length > 0 ? chartData[chartData.length - 1][areaKey][1] : 0;
  //   const percentage =
  //     Math.floor(offsetItem && (offsetItem[areaKey][1] * 100) / total) || 0;
  //
  //   if (defaultOffset) {
  //     const currentItem = {
  //       pcg: 1,
  //       key: 0,
  //       text: `Current (${defaultOffset} mins)`,
  //       value: defaultOffset,
  //     };
  //     offsetOptions.unshift(currentItem);
  //   }
  //
  //   const props = {
  //     options: offsetOptions,
  //     offsetItem,
  //     offset,
  //     offsetType: ottType,
  //     currentOffset,
  //     total,
  //     percentage,
  //   };
  //
  //   return (
  //     <ContentCard
  //       title={`Setup ${type} Eligibility Time window`}
  //       explainer=""
  //       loading={loading}
  //       key={areaKey}>
  //       <SplitLayout
  //         leftWidth={8}
  //         leftContent={
  //           <OffsetAreaChart
  //             offset={currentOffset}
  //             data={chartData}
  //             offsetItem={offsetItem}
  //             areaKey={areaKey}
  //           />
  //         }
  //         rightWidth={8}
  //         rightContent={
  //           <SettingForm
  //             options={offsetOptions}
  //             setOffset={setOffset}
  //             offsetItem={offsetItem}
  //             offset={offset}
  //             offsetType={ottType}
  //             currentOffset={currentOffset}
  //             areaKey={areaKey}
  //             chartData={chartData}
  //             total={total}
  //             percentage={percentage}
  //           />
  //         }
  //       />
  //     </ContentCard>
  //   );
  // });

  const allTypeProps = areaKeys.reduce((prev, areaKey) => {
    const type = areaKey.charAt(0).toUpperCase() + areaKey.slice(1);
    const defaultOffset =
      ottType &&
      apiOffset[`${ottType}_offset`] &&
      apiOffset[`${ottType}_offset`][areaKey];
    const currentOffset =
      ottType &&
      offset[`${ottType}_offset`] &&
      offset[`${ottType}_offset`][areaKey];
    const offsetOptions = getOffsetOptions(chartData, areaKey).map((item) =>
      Object.assign(item, { text: `${item.text} (${item.value} mins)` })
    );
    const offsetItem = (currentOffset &&
      chartData.find((item) => item.offset == currentOffset)) || {
      [areaKey]: 0,
    };
    const total =
      chartData.length > 0
        ? chartData[chartData.length - 1][areaKey] &&
          chartData[chartData.length - 1][areaKey][1]
        : 0;
    const percentage =
      Math.floor(
        offsetItem &&
          offsetItem[areaKey] &&
          (offsetItem[areaKey][1] * 100) / total
      ) || 0;

    if (defaultOffset) {
      const currentItem = {
        pcg: 1,
        key: 0,
        text: `Current (${defaultOffset} mins)`,
        value: defaultOffset,
      };
      offsetOptions.unshift(currentItem);
    }

    const props = {
      options: offsetOptions,
      offsetItem,
      offset,
      offsetType: ottType,
      currentOffset,
      total,
      percentage,
      areaKey: activeArea,
      setOffset,
    };

    prev[areaKey] = props;

    return prev;
  }, {});

  return (
    <React.Fragment>
      <SplitLayout
        leftWidth={8}
        rightWidth={8}
        leftContent={
          <SetupSummaryTable
            allTypeProps={allTypeProps}
            setActiveArea={setActiveArea}
            header={
              ottTypes && (
                <OttTypeSelection
                  ottTypes={ottTypes}
                  setOttType={setOttType}
                  ottType={ottType}
                  setActiveArea={setActiveArea}
                />
              )
            }
          />
        }
        rightContent={
          <ContentCard
            title={`Setup ${`${
              activeArea.charAt(0).toUpperCase() + activeArea.slice(1)
            }`} Eligibility Time window`}
            explainer=""
            loading={loading}>
            <OffsetAreaChart data={chartData} {...allTypeProps[activeArea]} />
            <SettingForm {...allTypeProps[activeArea]} chartData={chartData} />
          </ContentCard>
        }
      />
    </React.Fragment>
  );
};

export default OttAnalysis;
