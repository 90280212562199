import React, { Component } from 'react';
import { Grid, Feed, Icon, Popup, Accordion, Breadcrumb, Table } from 'semantic-ui-react';

const CustomerTimeline = props => (
  <Grid.Column width={props.width}>
    <Feed>
      {props.children}
    </Feed>
  </Grid.Column>
)

export const MarketingItem = props => {
  const eventTiers = props.eventTiers ? props.eventTiers.filter((tier) => tier.name) : [];
  const numTiers = eventTiers.length;

  return(
    <Item icon={
            <Icon circular
                  name={numTiers ? iconNameMap(eventTiers) : 'question'}
                  color={numTiers ? 'blue' : 'orange'} />
          }
          title={numTiers ?
              <ItemTiers tiers={eventTiers} />
            :
              'Unmapped Event'
          }
          date={props.date}>
      <Feed.Meta className='m-0'
                 title={props.url}>
        {props.url}
      </Feed.Meta>
    </Item>
  )
}

export const BrowsingItems = props => (
  <Item icon={
          <Icon circular
                name='mouse pointer'
                color='grey' />
        }
        title='On-Site Browsing'>
    {props.urls && props.urls.length &&
      <Feed.Meta className='m-0'>
        <Accordion panels={[{
                     key: props.urls[0].date+props.urls[0].url,
                     title: {
                       content: 'Viewed '+props.urls.length+' Pages'
                     },
                     content: {
                       content: (
                         props.urls.map((url) =>
                           <div key={url.date+url.url}
                                title={url.url}
                                className='ellipsed'>
                             <span className='date'>{url.date}</span>: {url.url}
                           </div>
                         )
                       )
                     }
                   }]}
                   className='ellipsed' />
      </Feed.Meta>
    }
  </Item>
)

export const ConversionItem = props => (
  <Item icon={
          <Icon circular inverted
                name='check'
                size='massive'
                color={props.color ? props.color : 'green'} />
        }
        title={
          <span>
            Conversion:&nbsp;
            <a href={props.eventLink}>
              {props.eventName}
            </a>
          </span>
        }
        date={props.date}>
    {props.conversionDetails &&
      <Feed.Meta className='conversion-details m-0'>
        <Accordion panels={[{
                     key: props.eventName+props.eventLink+props.date,
                     title: {
                       content: 'Conversion Details'
                     },
                     content: {
                       content: (
                         <Table celled className='conversion-details-table'>
                           <Table.Body>
                             {props.conversionDetails.map((detail) =>
                               <Table.Row key={detail.label+detail.value}>
                                 <Table.Cell>
                                   {detail.label}
                                 </Table.Cell>
                                 <Table.Cell>
                                   {detail.value}
                                 </Table.Cell>
                               </Table.Row>
                             )}
                           </Table.Body>
                         </Table>
                       )
                     }
                   }]} />
      </Feed.Meta>
    }
  </Item>
)

export const SmallItem = props => (
  <Feed.Event className='customer-timeline-item'>
    <Feed.Label>
    </Feed.Label>
    <Feed.Content className='ellipsed' style={{"padding":"0px"}} >
      <b>{props.title}</b>
      <span style={{color:"rgba(0,0,0,.4)",paddingLeft:"1em"}}>{props.children}</span>
    </Feed.Content>
  </Feed.Event>
)

export const Item = props => (
  <Feed.Event className='customer-timeline-item'>
    <Feed.Label>
      {props.icon}
    </Feed.Label>
    <Feed.Content className='ellipsed' style={{paddingBottom: props.children ? undefined : "0px"}}>
      <Feed.Summary>
        {props.title}
        <br />
        {props.date &&
          <Feed.Date content={props.date} />
        }
      </Feed.Summary>
      <div style={{padding:0, paddingLeft:"1em"}}>{props.children}</div>
    </Feed.Content>
  </Feed.Event>
)

class ItemTiers extends Component {

  render(){
    const { tiers } = this.props

    return(
      <span className='tiers'>
        <b>Marketing: </b>
        {
          tiers.map((tier, i) => (
            <span>
              { i > 0 && ` ≫ `} 
              <a href="#">{ tier.name }</a>
            </span>
          ))
        }
      </span>
    )
  }
}

function iconNameMap(tiers){
  if (!tiers)
    return 'question'

  const tier1Name = tiers[0] ? tiers[0].name.toLowerCase() : '';
  const tier2Name = tiers[1] ? tiers[1].name.toLowerCase() : '';

  if (tier1Name.includes('search') || tier1Name.includes('sem'))
    return 'search'
  else if (tier1Name.includes('video'))
    return 'play'
  else if (tier1Name.includes('tv'))
    return 'television'
  else if (tier1Name.includes('refer'))
    return 'arrow right'
  else if (tier1Name.includes('remarket') || tier1Name.includes('retarget'))
    return 'redo alternate'
  else if (tier1Name.includes('podcast'))
    return 'podcast'
  else if (tier1Name.includes('event'))
    return 'calendar alternate outline'
  else if (tier1Name.includes('email'))
    return 'envelope open outline'
  else if (tier1Name.includes('partner') || tier1Name.includes('affiliate'))
    return 'building outline'
  else if (tier1Name.includes('blog'))
    return 'rss'
  else if (tier1Name.includes('ad'))
    return 'star outline'
  else if (tier1Name.includes('print') || tier1Name.includes('mail'))
    return 'file alternate outline'
  else if (tier1Name.includes('influencer'))
    return 'share'
  else if (tier1Name.includes('social'))
    if (tier2Name.includes('facebook'))
      return 'facebook f'
    else if (tier2Name.includes('twitter'))
      return 'twitter'
    else if (tier2Name.includes('instagram'))
      return 'instagram'
    else if (tier2Name.includes('linkedin'))
      return 'linkedin in'
    else if (tier2Name.includes('pinterest'))
      return 'pinterest p'
    else
      return 'share'
  else
    return 'mouse pointer'
}

export {CustomerTimeline, ItemTiers}
