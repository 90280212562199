import { yesterday, monthAgo } from '../utils/time';
import { getBatchReports } from './attributionETL';

export function getDatasetSchemaStartEnd(
  datasetName,
  id,
  udf = 'attribution_events',
  startDate = yesterday,
  endDate = yesterday,
) {
  const url = `/data/v3/stats/${udf}?keys=${datasetName}&start_date=${startDate}&end_date=${endDate}&identifier=${id}`;
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(response => response.json());
}

export function getMetaData(filter_id, dataset = 'fb_synthetic_summary') {
  const endDate = yesterday;
  const startDate = monthAgo;

  const url = `/data/v3/stats/attribution_events?start_date=${startDate}&end_date=${endDate}&identifier=${filter_id}&keys=${dataset}`;

  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(data => data.response);
}

export function getACETLMetaData(
  filter_id,
  dataset = 'facebook_conversions_device',
) {
  return new Promise(resolutionFunc => {
    getBatchReports().then(resp => {
      const reportType = resp.find(
        ({ report_type_name }) => report_type_name == dataset,
      );

      const { id } = reportType;
      const url = `/data/internal/processed/list?id=${id}`;

      fetch(url, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
          data.response.forEach(row => {
            row.date = row.upload_id;
          });
          resolutionFunc({ current: data.response });
        });
    });
  });
}
