export const METRICS = ['cpa', 'roas', 'cpm', 'cpc', 'ctr']
export const COLORS = ['red', 'blue', 'green', 'purple', 'orange']

export const PLATFORMS = ['facebook', 'google', 'snapchat', 'pinterest', 'tiktok', 'bing']
export const FILTER_TYPES = ['Industry', 'Spend Level']

export const baseCols = [
    { display: 'Date', key: 'date' },
    { display: 'CPC', key: 'cpc' },
    { display: 'CPM', key: 'cpm' },
    { display: 'CTR', key: 'ctr' },
  ];
  
export const facebookCols = [
    { display: 'ROAS', key: 'roas' },
    { display: 'CPA', key: 'cpa' },
  ];

export const baseSummaryCols = [
    { display: 'Date', key: 'date' },
    { display: 'CPC', key: 'cpc', reducer: data => data.reduce((acc, item) => acc + parseFloat(item.cpc.slice(1)), 0) / data.length, formatter: value => `$${Number(value).toFixed(2)}` },
    { display: 'CPM', key: 'cpm', reducer: data => data.reduce((acc, item) => acc + parseFloat(item.cpm.slice(1)), 0) / data.length, formatter: value => `$${Number(value).toFixed(2)}` },
    { display: 'CTR', key: 'ctr', reducer: data => data.reduce((acc, item) => acc + parseFloat(item.ctr.slice(0, -1)), 0) / data.length, formatter: value => `${Number(value).toFixed(2)}%` },
  ];
  
export const facebookSummaryCols = [
    { display: 'ROAS', key: 'roas', reducer: data => data.reduce((acc, item) => acc + parseFloat(item.roas.slice(1)), 0) / data.length, formatter: value => `$${Number(value).toFixed(2)}` },
    { display: 'CPA', key: 'cpa', reducer: data => data.reduce((acc, item) => acc + parseFloat(item.cpa.slice(1)), 0) / data.length, formatter: value => `$${Number(value).toFixed(2)}` },
  ];

export const BUTTON_WIDTH = {'facebook': '255px', 'google': '230px', 'snapchat': '255px', 'pinterest': '250px', 'tiktok': '220px', 'bing': '210px'}
