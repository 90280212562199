export const FREE_PLATFORMS = {
  "facebook": {
    "color": "#479BF5"
  },
  "snapchat": {
    "color": "#F5F33D"
  },
  "bing": {
    "color": "#00B8A1"
  },
  "tiktok": {
    "color": "#42F0EB"
  },
  "linkedin": {
    "color": "#0a95c2"
  },
  "pinterest": {
    "color": "#E61939"
  },
  "adwords": {
    "color": "#12D975"
  }
}
