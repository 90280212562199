import React from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Segment, Image, Header, Icon, Button } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'
import { ClickableCard, evalTemplate } from '../DataMap/parts'


export const ReportTypeCard = ({ icon, color, title, description, link, params }) => (
  <ClickableCard {...{link, params}}>
    <Header
      icon
      as='h4'
      textAlign='center'
    >
      <Icon
        name={icon}
        style={{ color }}
      />
      {title}
      {!!description &&
        <Header.Subheader>
          {description}
        </Header.Subheader>
      }
    </Header>
  </ClickableCard>
)


export const DataSourceCard = ({ title, items, link }) => (
  <ContentCard
    {...{title}}
    hasTable
    topRight={
      <Icon
        name={!!items.length ? 'check circle': 'warning sign'}
        color={!!items.length ? 'green' : 'yellow'}
        style={{height: 17, fontSize: 15}}
      />
    }
    titleRightWidth={13}
    headerTextStyles={{fontWeight: 900, fontSize: 14}}
  >
    <ContentCard.Table>
      {items.map(({ title, platform, ...params }) =>
        <Table.Row>
          <Table.Cell textAlign='left' collapsing>
            <IntegrationIcon {...{platform}} />
          </Table.Cell>
          <Table.Cell textAlign='left' content={title} />
          <Table.Cell textAlign='right'>
            <DataSourceButton {...{ link, params }} />
          </Table.Cell>
        </Table.Row>
      )}
    </ContentCard.Table>
  </ContentCard>
)


const DataSourceButton = ({ link, params }) => {
  console.log('link', link)
  console.log('params', params)
  const history = useHistory()
  const formattedLink = evalTemplate(link, params)

  return (
    <Button
      compact
      size='mini'
      content="View"
      onClick={() => history.push(formattedLink)}
    />
  )
}


const IntegrationIcon = ({ platform }) => (
  <Image
    size='mini'
    src={`https://images.rockerbox.com/integrations/integrations-icon-${platform}.svg`}
    style={{width: 16}}
  />
)
