import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Aux }  from '../stateless';
import { SplitLayout, ContentCard } from '@rockerbox/styleguide';
import { MinLoader } from '../styled';
import { track } from '../../utils/tracking'

import { buildDates, aggregateGroups, buildGroupsFromRaw, urlEncodeTier, urlDecodeTierStr } from './helpers';
import { calcTotals, calcTimeseries, getFilterTiers, convertPctURL } from './helpers';
import { Err } from './parts';

import FilterCard from './FilterCard.js'
import ReportSelections from './ReportSelections';
import ReportTypeCard from './ReportTypeCard';
import SummarySection from './SummarySelector';
import ReportTable from './ReportTable';
import EmailModal from './EmailModal';
import SaveModal from './SaveModal';
import ScheduleModal from './ScheduleModal';

const Table = (props) => {
  const { match, models, data, model } = props;
  return (
    <ContentCard>
      <ReportTable {...props} {...{models, match}} tableData={data}/>
    </ContentCard>
  )
}

const DEFAULTS = {
  reportType: "report" ,
  startDate: moment.utc().utcOffset(-5).subtract(31, 'days').format('YYYY-MM-DD'),
  endDate: moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD'),
  channelGroup: "0",
  ntfFilter: "0"
}

class ReportCard  extends Component {
  filteredData = []
  state = {
    "exportType": "",
    "tiers": {},
    plotVariable: "count",
    showEmailModal: false,
    showSaveModal: false,
    showScheduleModal: false,
    filteredData: [],
    focusedInput: null,
    range: 30,
    hasFiredTracker: false,
  }

  componentDidMount() {
    this.setDefaultPath();
    this.fireAnalyticsTracker()
  }

  setDefaultPath() {
    const { id, group, model, reportType } = this.props.match.params;
    const { groups, models, selected } = this.props
    const defaultModel = (model == "multi") ? models.find(x => x.text == "Multi-Touch") : models[0];

    if (!group && id && selected) {
      const urlValues = Object.assign({},DEFAULTS)
      const frd = moment(selected.first_reporting_date || "2018-01-01");
      const monthAgo = moment.utc().utcOffset(-5).subtract(30, 'days');
      const diff = frd.diff(monthAgo, 'days');

      if (reportType) urlValues.reportType = reportType;
      urlValues.model = (model == "normalized" && !selected.use_model) ? defaultModel.value : (model || defaultModel.value);
      urlValues.group = group || groups[0].value;
      urlValues.startDate = diff >= 0 ? selected.first_reporting_date : monthAgo.format('YYYY-MM-DD');

      this.updatePath(urlValues);
    }
  }

  filterAndProcess = (model, group, raw) => {
    const params = this.props.match.params;
    const tiers = getFilterTiers(params);
    const processed = this.process(model, group.split(","), tiers, raw)
    return processed
  }

  process = (model, group, tiers, _raw) => {

    const raw = _raw || this.props.raw;
    const { startDate, endDate, ntfFilter } = this.props.match.params;

    const modifiers = ntfFilter == "1" ?
      ["ntf_", "", "ntf_revenue_", ""] :
      ["", "", "revenue_", ""];

    const { models } = this.props;
    const filtered = Object.keys(tiers).reduce((p,c) => {
      if (tiers[c].length && tiers[c] != '-') {
        const splitTiers = typeof(tiers[c]) == "string" ? tiers[c].split(",") : tiers[c];
        const decodedSplitTiers = splitTiers.map(urlDecodeTierStr);
        return p.filter(row => decodedSplitTiers.indexOf(row[c]) > -1 || tiers[c].length == 0)
      }
      else return p
    }, raw)

    const dates = buildDates(startDate, endDate)
    const processed = aggregateGroups(dates, models.map(m => m.value), group, ntfFilter, ...modifiers)({ attribution: filtered, raw });

    return processed
  }

  getTiersTimeseries(props, prevProps) {
    const rawData = props.raw;
    const { groupedTiers, groupTimeseries } = this;
    const updates = { shouldUpdate: false, groupedTiers, groupTimeseries };

    const params = this.props.match.params;
    const prevParams = prevProps.match.params;
    const { group, model } = params;

    const propsChanged = Object.keys(params).filter(k => params[k] != prevParams[k]).length > 0;
    const rawChanged = rawData !== prevProps.raw

    const channelGroup = props.channelGroups ? props.channelGroups.find(row => row.id == props.match.params.channelGroup) || {} : {};
    const { prefix } = channelGroup;

    const fields = [
      "even", "first_touch", "last_touch", "normalized",
      "revenue_even", "revenue_first_touch", "revenue_last_touch", "revenue_normalized",
      "ntf_even", "ntf_first_touch", "ntf_last_touch", "ntf_normalized",
      "ntf_revenue_even", "ntf_revenue_first_touch", "ntf_revenue_last_touch", "ntf_revenue_normalized",
    ]

    const modifiedData = prefix ? 
      rawData.map(row => {
        const entries = fields.map(field => [field, row[`${prefix}_${field}`]])
        const updates = Object.fromEntries(entries)
        return Object.assign({}, row, updates)
      }) : 
      rawData

    if (rawChanged) updates['groupedTiers'] = buildGroupsFromRaw(modifiedData);
    if (rawChanged || propsChanged) {
      updates['shouldUpdate'] = true;
      updates['groupTimeseries'] = this.filterAndProcess(model, group, modifiedData)['groupTimeseries'];
    }

    updates['filteredData'] = updates['groupTimeseries'].map(d => d[model])

    return updates;
  }

  componentDidUpdate(prevProps, prevState) {

    const { group, model, channelGroup } = this.props.match.params;
    const { props } = this;
    const { raw, selected, models, channelGroups } = props;
    const didModelChange = selected && !selected.use_model && model == "normalized"

    if (didModelChange) return this.updatePath({model: models[0].value});
    if (!group) return this.setDefaultPath();
    if (raw == undefined) return;

    const params = props.match.params;
    const prevParams = prevProps.match.params;
    const propsChanged = Object.keys(params).filter(k => params[k] != prevParams[k]).length > 0;
    const rawChanged = props.raw != prevProps.raw

    if (!propsChanged && !rawChanged ) return;

    const stateUpdates = {};
    const { shouldUpdate, groupedTiers, groupTimeseries, filteredData } = this.getTiersTimeseries(props, prevProps)

    if (shouldUpdate) {
      this['groupedTiers'] = groupedTiers;
      this['groupTimeseries'] = groupTimeseries;
      this['filteredData'] = filteredData;

      stateUpdates['dataVersion'] = (this.state['dataVersion'] || 0) + 1
    }

    const sameChannelGroup = channelGroup == prevProps.match.params.channelGroup;

    if (shouldUpdate || !sameChannelGroup) {
      this['filteredData'] = filteredData;
    }

    if (Object.keys(stateUpdates).length > 0) this.setState(stateUpdates);

    this.fireAnalyticsTracker(prevProps)
  }

  fireAnalyticsTracker = (prevProps={}) => {
    const currParams = this.props?.match?.params || {}
    const prevParams = prevProps?.match?.params || {}

    const reportType = currParams?.reportType
    const prevReportType = prevParams?.reportType

    const filterId = currParams?.id
    const prevFilterId = prevParams?.id

    const { is_child, is_parent } = this.props
    const isParentChildDefined = is_child !== undefined && is_parent !== undefined

    const { hasFiredTracker } = this.state

    const shouldFire = isParentChildDefined && 
      (hasFiredTracker
        ? reportType != prevReportType || filterId != prevFilterId
        : !!reportType && !!filterId
      )

    if (shouldFire) {
      track(`view.reports.${reportType}`, {
        filter_id: filterId,
        is_child: !!is_child,
        is_parent: !!is_parent,
        segment_type: !!is_child ? 'child' : !!is_parent ? 'parent' : 'standalone',
      })
      if (!hasFiredTracker) this.setState({ hasFiredTracker: true })
    }
  }

  updatePath = (obj) => {
    const objKeys = Object.keys(obj)

    const { history: { push }, location: { search } } = this.props;
    const { path, url } = this.props.match;
    const keys = path.split("/")
    const values = url.split("/").map(val => convertPctURL(val))

    const pathArray = keys.reduce((p,c,i) => {

      const k = c.includes(":") && c.replace(":","").replace("?","")

      if (k && objKeys.indexOf(k) > -1) p.push(obj[k])
      else if (values.length > i && values[i] !== undefined && values[i].length) {
        p.push(values[i])
      }
      return p
    }, [])

    push("/" + pathArray.filter(x => x).join("/") + search)
  }

  selectReport = (_, data) => {
    const reportType = data.value;
    this.updatePath({ reportType, ntfFilter: "0" })
  }

  selectModel = (obj) => {
    const { value } = obj

    const { group } = this.props.match.params
    const { tiers } = this.state
    const processed = this.process(value, group.split(","), tiers)

    this.updatePath({model: value, reportType: "report"})
  }

  selectTier = (tier) => (obj) => {
    const { value } = obj
    const { group, tier_1, tier_2, tier_3, tier_4, tier_5 } = this.props.match.params
    const tiersCopy = { tier_1, tier_2, tier_3, tier_4, tier_5 }
    
    tiersCopy[tier] = urlEncodeTier(value);
    
    Object.keys(tiersCopy).map(t => {
      if (tiersCopy[t] == undefined) delete tiersCopy[t]
    })

    if (value.length == 0) {
      let shouldDelete = false

      Object.keys(tiersCopy).map(t => {
        if (t == tier) shouldDelete = true;
        if (shouldDelete) tiersCopy[t] = false
      })
    }

    const { model } = this.state
    const processed = this.process(model, group.split(","), tiersCopy)

    for (var x in tiersCopy) {
      if (Array.isArray(tiersCopy[x])) {
        tiersCopy[x] = tiersCopy[x].map(tier => convertPctURL(tier))
      }
      else if (tiersCopy[x] != false) {
        tiersCopy[x] = convertPctURL(tiersCopy[x])
      }
    }

    this.updatePath(tiersCopy)
  }

  selectGroup = (obj) => {
    const group = obj.value
    const { model } = this.props.match.params
    const { tiers } = this.state
    const processed = this.process(model, group, tiers)

    this.updatePath({ group })
  }

  selectChannelGroup = ({value}) => {
    // prevent date from resetting by updating path with a string zero instead of an empty string
    const channelGroupParam = value ? value.toString() : "0";
    this.updatePath({ channelGroup: channelGroupParam })
    if (!!value) track('filter.reports.channel_group', {channel_group_id: channelGroupParam})
  }

  onSegment = (id) => {
    this.updatePath({ id });
  }

  onExport = (exportType) => (data) => {
    this.setState({ showEmailModal: true, exportType });
  }

  onSave = (exportType) => (data) => {
    this.setState({ showSaveModal: true, exportType });
  }

  onSchedule = (exportType) => (data) => {
    this.setState({ showScheduleModal: true, exportType });
  }

  onModalClose = () => {
    this.setState({
      showEmailModal: false,
      showSaveModal: false,
      showScheduleModal: false
     });
  }

  handleNtfChange = ({ value }) => {
    //Force ntf filter value to be string to prevent the value from being dropped from the URL, default '0'
    const ntfParam = value ? value.toString() : "0";
    this.updatePath({ntfFilter: ntfParam})
  }

  handlePlotVariable = (plotVariable) => {
    this.setState({plotVariable})
  }

  setDate = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const range = end.diff(start, 'days') + 1;

    this.setState({range: range < 30 ? 30 : range});
    this.updatePath({startDate, endDate});
  }

  render() {
    const {updatePath, handleNtfChange, handlePlotVariable, selectReport, selectModel, onSegment, onExport, onSave, onSchedule, selectGroup, selectChannelGroup, selectTier, onModalClose, setDate } = this;
    const { group, model, reportType, startDate, endDate, channelGroup, ntfFilter } = this.props.match.params;

    const { new_to_file_key } = this.props.selected;
    const show_ntf = !!new_to_file_key;

    const { filteredData, groupTimeseries  } = this;
    const { plotVariable } = this.state;

    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = this.props.match.params;
    const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 }

    const { showEmailModal, showSaveModal, showScheduleModal, exportType, savedReportId, range } = this.state;
    const { match, use_model, models, groups, channelGroups, dataGroups, selected, raw, outboxes, reportTypes, savedReports, permissions, hasSpend, loading } = this.props;
    const headerFuncs = { selectReport, selectModel, onExport, selectGroup, selectTier, onModalClose, setDate, handleNtfChange, selectChannelGroup };
    const headerSelections = { selectedTiers: tiers, model, group, channelGroup, savedReportId, ntfFilter }
    const headerValues = { show_ntf, use_model, tiers: dataGroups, models, groups, channelGroups, reportType, savedReports, startDate, endDate }
    const headerProps = {...headerFuncs, ...headerSelections, ...headerValues}

    const data = filteredData;
    const selectedData = filteredData;
    const hasData = data && data.length > 0

    const { count, revenue, spend } = calcTotals(selectedData);
    const values = calcTimeseries(selectedData, startDate, endDate, plotVariable);

    const types = {
      "report": Table
    }

    const Content = loading ? MinLoader :
      !hasData && raw.length > 0 ? Err :
      types[reportType] || types.report;

    const selectedSegmentId = this.props.match.params.id
    const { history } = this.props
    return (
      <X>
        <FilterCard  {...{selectedSegmentId, onSegment, onSave, onExport, onSchedule, permissions, startDate, endDate, selected, setDate, history, match}}
           key={0} />
        <SplitLayout.StickyScroll
          stickySide='left' stickyOffset={15} leftWidth={3} rightWidth={13}
          leftContent={ <ReportSelections {...headerProps} selected={selected}/> }
          rightContent={
            <Aux>
                <SummarySection {...{handlePlotVariable, plotVariable, count, revenue, spend, hasSpend, values, loading}} />
                {<ReportTypeCard {...{selectReport, reportType, onSave, onExport, onSchedule, show_ntf}}/>}
                <Content {...{groupTimeseries, selected, reportType, model, models, match, hasSpend, updatePath, selectReport, range, loading}} data={filteredData} />
                <EmailModal close={onModalClose} open={showEmailModal} {...{tiers, exportType, startDate, endDate, match}} />
                <SaveModal close={onModalClose} open={showSaveModal} {...{tiers, exportType, reportTypes, startDate, endDate}} />
                <ScheduleModal close={onModalClose} open={showScheduleModal} {...{tiers, exportType, savedReportId, outboxes}}/>
            </Aux>
          }
        />
      </X>
    )
  }
}

const X = styled.div`
  .ellipsed {
    overflow: inherit;
    text-overflow: inherit;
  }
`

ReportCard.propTypes = {
  selected: PropTypes.object
}

export default ReportCard;
