import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Popup } from 'semantic-ui-react';

class TopRightActions extends Component {
  render() {
    return (
      <Menu text size="small">

        {this.props.savedReportId ? null : <Popup
          inverted
          size="small"
          position="top center"
          content="Save the current state of your report"
          trigger={
            <Menu.Item
              onClick={this.props.onSave("compiled_mta_dashboard")}
            >
              <Icon name="save" />
              &nbsp;Save
            </Menu.Item>
          }/>
        }
        <Menu.Item>
          <Dropdown
            pointing="top right"
            className="muted"
            size="mini"
            icon={false}
            text={
              <React.Fragment>
                <Icon name="download" style={{ marginRight: 5 }} />
                Export
              </React.Fragment>
            }
          >
            <Dropdown.Menu>
              <Popup
                inverted
                size="small"
                position="left center"
                content="A detailed log of the marketing touchpoints each converter has interacted with"
                trigger={
                  <Dropdown.Item
                    text="Multi-Touch Report (Standard)"
                    icon="table"
                    onClick={this.props.onExport("compiled_mta_dashboard")}
                  />
                }
              />
              <Popup
                inverted
                size="small"
                position="left center"
                content="A multi-touch attribution report that also includes columns for customer-specified conversion data"
                trigger={
                  <Dropdown.Item
                    text="Multi-Touch Report (with Customer Data)"
                    icon="table"
                    onClick={this.props.onExport("compiled_mta_customer_data")}
                  />
                }
              />
              <Popup
                inverted
                size="small"
                position="left center"
                content="A high-level report that aggregates all of your marketing touchpoints by buckets"
                trigger={
                  <Dropdown.Item
                    text="Buckets Breakdown"
                    icon="chart pie"
                    onClick={this.props.onExport("compiled_mta_tiers")}
                  />
                }
              />
              <Dropdown.Divider />
              <Popup
                inverted
                size="small"
                position="left center"
                content="Get a custom export emailed to you on a scheduled basis"
                trigger={
                  <Dropdown.Item
                    text="Schedule Recurring"
                    icon="clock outline"
                    onClick={this.props.onSchedule("compiled_mta_dashboard")}

                  />
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>

      </Menu>
    )
  }
}

export default TopRightActions;
