import * as routes from '../../baseRoutes'
import { NAV_MENU } from './constants'

export const NAV_ROUTES = NAV_MENU.flatMap(x => 
  x.children.flatMap(y =>
    !!y.children
      ? y.children.map(z => ({ section: x.name, ...z }))
      : ({ section: x.name, ...y })
  )
)

export const ROUTE_MAP = Object.entries(routes).reduce((acc, [key, value]) => {
  acc[value] = key
  return acc
} , {})

export const NAV_MAP = NAV_ROUTES.reduce((acc, curr) => {
  let routeName = ROUTE_MAP[curr.route]
  if (!!routeName) acc[routeName] = curr
  return acc
} , {})
