import React, { Component } from 'react';
import { ContentCard, SplitLayout, PagedIndexGrid, StatCard } from '@rockerbox/styleguide';
import { getWebhooksData, updateWebhook, getWebhookEvents, createWebhookTest, getWebhookDaily, getWebhookTemplates} from '../../utils/api';
import { WebhookNames, Config, TestEvents, Output, EventsTable } from './webhookParts';
import { Menu, Button, Icon, Table, Dimmer, Loader, Grid, Input } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import * as routes from '../../routes';
import moment from 'moment';
import SummaryBar from '../ViewAttribution/SummaryBar';
import DatePicker from 'react-datepicker';

const prettyJSON = (bodyString) => {
  return JSON.stringify(JSON.parse(bodyString), null, 2);
};

const RawMessageIdCell = ({item, col, id, handleRawMessageIdClick}) => {
  return (
    <Table.Cell>
      <a onClick={() => handleRawMessageIdClick(item[col.key])} style={{cursor: 'pointer'}}>{item[col.key]}</a>
    </Table.Cell>
  )
}

const SuccessCell = ({item, col}) => {
  return (
    <Table.Cell>
    <Icon
      color={item[col.key] ? 'green' : 'red'}
      name={item[col.key] ? 'checkmark' : 'close'} size='large' />
  </Table.Cell>
  )
}

export class WebhooksDataSource extends Component {
  state = {
    webhookData: [],
    selected: {},
    configValue: '',
    displayName: '',
    filterOptions: {},
    selectedEvent: '',
    webhookEvents: [],
    eventBody: '',
    output: '',
    searchVal: '',
    filteredData: [],
    testResults: [],
    cols: [],
    loading: true,
    webhooksDaily: [],
    total: '',
    day: moment.utc(),
    templateOptions: [],
    templateData: [],
    selectedTemplate: ''
  }

  componentDidMount() {
    if(this.props.match.path.includes('edit')) {
      this.getData();

    } else {
      // on create
      this.getData();
      getWebhookTemplates()
      .then(templateData => {
        const templateOptions = templateData.map(c => {
          return {text: c.name, value: c.id}
        })
        this.setState({templateOptions, templateData})
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { action, day } = this.state;

    if(prevState.day !== day) {
      const utcHour = `${moment.utc().hours()}:00:00`;
      const formattedDay = moment(day).format('YYYY-MM-DD')
      this.getEvents(action, formattedDay, utcHour);
      getWebhookDaily(action, formattedDay)
      .then(webhooksDaily => {
        const total = webhooksDaily.reduce((p,row) => p + row.count,0);
        this.setState({webhooksDaily, total})
      })

    }
  }

  getData = () => {
    const { id } = this.props.match.params;

    getWebhooksData()
    .then(webhookData => {
      const selectedItem = webhookData.find(e => e.id == id);
      const action = selectedItem.action;
      const pixelSourceName = selectedItem.pixel_source_name;
      const configValue = JSON.stringify(selectedItem.config, null, 2)
      const day = moment.utc().subtract(1, 'hour').format('YYYY-MM-DD');
      const utcHour = `${moment.utc().hours()}:00:00`;
      const { filter_field, filter_list, filter_whitelist } = selectedItem
      const filterOptions = { filter_field, filter_list, filter_whitelist }

      this.setState({webhookData, selected: selectedItem, configValue, action, displayName: selectedItem.display_name, output: '', day, pixelSourceName, filterOptions});
      this.getEvents(action, day, utcHour);
      getWebhookDaily(action, day)
      .then(webhooksDaily => {
        const total = webhooksDaily.reduce((p,row) => p + row.count,0);
        this.setState({webhooksDaily, total})
      })
    });

  }

  getEvents = (endpoint, day, utcHour) => {
    this.setState({loading: true})
    getWebhookEvents(endpoint, day, utcHour)
      .then(data => {
        if(data.length > 0) {
          this.setState({webhookEvents: data, selectedEvent: data[0], eventBody: prettyJSON(data[0].body), filteredData: data, loading: false})
        } else {
          this.setState({filteredData: data, loading: false})
        }
      })
  }

  handleTextChange = (val, key) => {
    this.setState({[key]: val});
  }

  handleSave = () => {
    const {action, configValue, selected, displayName, filterOptions } = this.state;
    const { filter_field, filter_list, filter_whitelist } = filterOptions
    const postObj = {
      action,
      config: JSON.parse(configValue),
      display_name: displayName,
      filter_field,
      filter_list,
      filter_whitelist,
    }

    updateWebhook(selected.id, postObj)
      .then(data => this.getData());
  }

  handleRawMessageIdClick = (messageId) => {
    const { id } = this.props.match.params;
    const { webhookEvents } = this.state;

    const selectedEvent =  webhookEvents.find(c => c.raw_message_id == messageId);
    const eventBody = prettyJSON(selectedEvent.body)

    this.setState({selectedEvent, eventBody }, () => this.props.history.push(`${routes.webhooksEdit}/${id}/${messageId}`))
  }

  handleRowClick = (item) => {
    const { id } = this.props.match.params;

    this.setState({selectedEvent: item, eventBody: prettyJSON(item.body)}, () => this.props.history.push(`${routes.webhooksEdit}/${id}/${item.raw_message_id}`))
  }

  handleTest = () => {
    const { configValue, eventBody } = this.state;
    const { id, messageId } = this.props.match.params;
    const postObj = {
      config: JSON.parse(configValue),
      payload: JSON.parse(eventBody)
    }

    this.setState({output: ''})

    createWebhookTest(postObj)
      .then(data => {
        this.setState({output: JSON.stringify(data.response, null, 2), active: 'view'})
      })
      .catch(error => {
        this.setState({output: 'error'})
      })
    this.props.history.push(`${routes.webhooksEdit}/${id}/${messageId}/results`)
  }

  handleSearchChange = (value) => {
    const { webhookEvents } = this.state;
    const filteredData = webhookEvents.filter(
      event => event.body.indexOf(value) > -1
    );

    this.setState({ searchVal: value, filteredData });
  }

  toggleActive = (state) => {
    const { selectedEvent } = this.state;
    const { id } = this.props.match.params;

    if(state == 'send') {
      this.props.history.push(`${routes.webhooksEdit}/${id}/${selectedEvent.raw_message_id}`)
    }
  }

  handleFindClick = (state) => {
    const { id } = this.props.match.params;
    this.props.history.push(`${routes.webhooksEdit}/${id}`)
  }

  handleTestAll = () => {
    const { id } = this.props.match.params;
    const { filteredData, configValue } = this.state;
    const firstThirty = filteredData.slice(0,30)
    const apiCalls = firstThirty.map(c => {
      const postObj = {
        raw_message_id: c.raw_message_id,
        config: JSON.parse(configValue),
        payload: JSON.parse(c.body)
      }
      return createWebhookTest(postObj)
        .then(data => {
          return {"request":postObj,"success": data.response}
        })
        .catch(error => {
          return {"request":postObj,"failure": true}
        })
    })

    this.props.history.push(`${routes.webhooksEdit}/${id}/test-results`);

    this.setState({loading: true})

    Promise.all(apiCalls)
      .then(data => {
        const { props } = this;
        const testResults = data.map(row => {

          return Object.assign(
            {raw_message_id: row.request.raw_message_id, success: !!row['success']},
            row['success'] || {}
          )
        })

        const objWithAllKeys = testResults.reduce((p,c) => {
          return Object.assign(p, c)
        }, {});
        const allKeys = Object.keys(objWithAllKeys).sort();

        const cols = [
          {display: "success", key: "success", as: SuccessCell },
          {display: "raw_message_id", key: "raw_message_id", as: (props) => <RawMessageIdCell id={id} handleRawMessageIdClick={this.handleRawMessageIdClick} {...props}/>}]
          .concat(
          allKeys.filter(c => (c != "success") && (c != "raw_message_id")).map(c => {
            return {display: c, key: c}
          })
        )

        this.setState({ testResults, cols, loading: false })
      })
  }

  handleHourChange = (row) => {
    const { action } = this.state;
    const { date } = row;
    const utcHour = moment(date).format('HH:00:00');
    const day = moment(date).format('YYYY-MM-DD');

    this.getEvents(action, day, utcHour);
  }

  handleDateChange = (date) => {
    this.setState({day: date})
  }

  handleTemplateChange = (event, data) => {
    const { value } = data;
    const { templateData } = this.state;

    const selected = templateData.find(c => c.id == value);
    const selectedTemplate = selected ? selected.template : '';

    this.setState({selectedTemplate: value, configValue: selectedTemplate})
  }

  render() {
    const { filteredData, webhookData, output, active, testResults, cols, selectedEvent, loading, webhooksDaily, total, day } = this.state;
    const { id } = this.props.match.params;
    const { hideEdit } = this.props
    const leftWidth = hideEdit ? 0 : 8
    const rightWidth = hideEdit ? 16 : 8

    return (
      <React.Fragment>
        {this.props.match.params.messageId !== 'test-results' &&
          <React.Fragment>
            <SplitLayout
              {...{leftWidth, rightWidth}}
            leftContent={
              !hideEdit && <React.Fragment>
                <Config
                  {...this.state}
                  handleTextChange={this.handleTextChange}
                  handleTemplateChange={this.handleTemplateChange}
                />
              </React.Fragment>
            }
            rightContent={
              <React.Fragment>
                <ContentCard
                  disableHeaderEllipse
                  title={
                    <React.Fragment>
                      Events Received &nbsp;
                      <DatePicker
                        customInput={<Input icon='calendar alternate'/>}
                        selected={moment.utc(day)}
                        onChange={(v) => this.handleDateChange(v) }
                        minDate={moment.utc().subtract(7, 'days')}
                        maxDate={moment.utc()}
                      />
                    </React.Fragment>
                  }
                  noContent
                />
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <ContentCard>
                        <SummaryBar data={webhooksDaily} onClick={this.handleHourChange} exclude={[]} tooltipKey="hour" tickType="hour" tickFormat="YYYY-MM-DD HH:mm:ss" hideXAxis={true} height={80} minNumItems={24}/>
                      </ContentCard>
                    </Grid.Column>
                    <Grid.Column width={4} style={{paddingLeft: 0}}>
                      <StatCard style={{height: "100%"}} value={total} label='Total' explainer='Past 30 Days' size='small' />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <ContentCard>
                  <Menu
                    widths={3}
                    attached="top"
                    secondary
                    pointing
                    fluid
                    >
                      <Menu.Item
                        name="Find"
                        active={!this.props.match.params.messageId && !this.props.match.params.results}
                        onClick={() => this.handleFindClick('find')}
                      />
                      <Menu.Item
                        name="Inspect"
                        active={(this.props.match.params.messageId && !this.props.match.params.results)}
                        onClick={() => this.toggleActive("send")}
                      />
                      <Menu.Item
                        name="View Parsed"
                        active={this.props.match.params.results}
                      />
                    </Menu>
                    {(!this.props.match.params.messageId && !this.props.match.params.results) && <EventsTable {...this.state} handleSearchChange={this.handleSearchChange} handleRowClick={this.handleRowClick}/>}
                    {(this.props.match.params.messageId && !this.props.match.params.results) && <TestEvents {...this.state} handleTextChange={this.handleTextChange} handleTest={this.handleTest}/>}
                    {this.props.match.params.results && <Output output={output}/>}
                  </ContentCard>
              </React.Fragment>
              }
            />
            {
              !hideEdit && <div style={{marginTop: 10, textAlign: 'right'}}>
                <Button primary size="small" content="Save" onClick={this.handleSave}/>
                <Button primary size="small" content="Test All" onClick={this.handleTestAll}/>
              </div>
            }
          </React.Fragment>
        }
        {this.props.match.params.messageId == 'test-results' && <ContentCard hasTable title="Test Results"
          topRight={<span style={{cursor: 'pointer', color: '#4183C4'}} onClick={() => this.props.history.push(`${routes.webhooksEdit}/${id}`)}><Icon name='angle left'/> Back</span>}>
          { loading &&
            <div style={{padding: 10}}>
              <Dimmer active inverted>
                <Loader active inline='centered' />
              </Dimmer>
            </div>
          }
          { !loading && <PagedIndexGrid cols={cols} data={testResults} itemsPerPage={50}/>}
        </ContentCard>}
      </React.Fragment>
    )
  }
}

export default withRouter(WebhooksDataSource);
