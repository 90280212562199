import React, { Component, useState, useEffect } from "react";
import { updateScheduledReport } from "./api";
import Container from "../parts/Container";
import { currentTimeZone, hourFromLocalToEST } from "./timezoneConversion";
import { invalidRootFolder } from "./helpers"
import {
  Button,
  Checkbox,
  Modal,
  Form,
  Dropdown,
  Message,
  Popup,
  Icon,
  Accordion,
  Input,
  Table
} from "semantic-ui-react";
import { CloseButtonStyles } from "../styled";

const daysOptions = [
  { key: "Mon", value: "Mon", text: "Mon" },
  { key: "Tues", value: "Tues", text: "Tues" },
  { key: "Wed", value: "Wed", text: "Wed" },
  { key: "Thurs", value: "Thurs", text: "Thurs" },
  { key: "Fri", value: "Fri", text: "Fri" },
  { key: "Sat", value: "Sat", text: "Sat" },
  { key: "Sun", value: "Sun", text: "Sun" }
];
const typeOptions = [
  { key: "csv", value: "csv", text: "csv" },
  { key: "tsv", value: "tsv", text: "tsv" },
  { key: "xlsx", value: "xlsx", text: "xlsx" }
];
const fields = [
  { name: "filetype", label: "File Type" },
  { name: "days", label: "Scheduled Days" },
  { name: "time", label: "Hour" },
  { name: "destination", label: "Destination" }
];

const macroOptions = {
  report_type_name: {
    description: "identifier for selected report format",
    examples:
      "compiled_mta_tiers, compiled_mta_reduced_v4, conversion_data_report"
  },
  report_subtype: {
    description: "subtype of report, used to group together similar formats",
    examples: "MTA, Tiers, Conversions, Paths, Visits, Impressions, Mappings"
  },
  segment_name: {
    description: "name of your conversion segment"
  },
  date: {
    description: "date of the report data",
    examples: "YYYY-MM-DD"
  },
  end_date: {
    description: "end date of the report data",
    examples: "YYYY-MM-DD"
  },
  filetype: {
    description: "spreadsheet file format",
    examples: "tsv, csv, xlsx"
  }
};

const PopupWrapper = props => {
  const { input } = props;
  return (
    <Popup
      on="focus"
      size="large"
      wide="very"
      position="right center"
      trigger={input}
      hoverable>
      <Popup.Header>
        <Icon name="info circle" />
        Available Macros
      </Popup.Header>
      <Popup.Content>
        Format the filename delivered to your destination using the following
        macros
        <br />
        <i>Macros must be wrapped in</i> <code>{"{}"}</code>
        <i>, e.g. </i>
        <code>{"{report_type_name}"}</code>
        <Table celled compact="very" fluid size="small" columns={2}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Macro</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Examples</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(macroOptions).map(macro => (
              <Table.Row key={macro}>
                <Table.Cell>
                  <code>{macro}</code>
                </Table.Cell>
                <Table.Cell>{macroOptions[macro]["description"]}</Table.Cell>
                <Table.Cell>
                  <i>{macroOptions[macro]["examples"]}</i>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Popup.Content>
    </Popup>
  );
};

const EditModal = props => {
  const {
    loading,
    error,
    editReport,
    onLoadingChange,
    onErrorChange,
    onEditReportFieldChange,
    isRBUser,
    showAdvanced,
    onShowAdvancedChange
  } = props;
  
  const onSubmit = () => {
    const destination =
      editReport["outbox_type"] !== "do_spaces"
        ? editReport["destination"]
            .split(".")
            .slice(0, -1)
            .join(".") + `.${editReport["filetype"]}`
        : editReport["destination"];

    const requestBody = Object.assign({}, editReport, {
      time: hourFromLocalToEST(parseInt(editReport["time"])),
      days: Array.isArray(editReport.days)
        ? editReport.days.join(",")
        : editReport.days,
      destination
    });
    onLoadingChange(true);
    updateScheduledReport(requestBody)
      .then(() => props.setScheduledReports())
      .then(() => props.close())
      .catch(err => {
        onLoadingChange(false);
        onErrorChange(true);
      });
  };

  const validate = () => {
    
    if  (invalidRootFolder(editReport.destination)) return false;

    const regMacros = /{([^}]*)}/g;
    const matches = editReport.destination.match(regMacros);
    if (!matches || !matches.length) {
      return true;
    }
    
    const macros = matches.map(match => match.slice(1, -1));
    const isValidDestination = macros.every(val => {
      return Object.keys(macroOptions).find(option => option === val);
    });

    return isValidDestination && fields.map(val => val.name).every(val => val);
  };

  const AdvancedSection = props => {
    const { offset, onEditReportFieldChange } = props;
    const shouldShow = isRBUser();
    const ref = React.createRef();

    useEffect(() => {
      ref.current && ref.current.focus();
    }, []);

    return shouldShow ? (
      <Accordion>
        <Accordion.Title onClick={() => onShowAdvancedChange()}>
          <Icon name="dropdown" /> Advanced Options (Rockerbox only)
        </Accordion.Title>
        <Accordion.Content active={showAdvanced}>
          <Message
            color="orange"
            content="Send reports N-days after the conversion. For example, a 7-day offset means that reports for 12/1 will be sent out on 12/8. This is helpful if it takes a few days for all your data to be finalized (e.g. TV Postlogs). If you are unsure of what to put here, keep the value at 0."
            header="Changing these options may break your reports"></Message>
          <Form.Group key={"date_offset"}>
            <Form.Field>
              <label>{"Date Offset"}</label>
              <Input
                value={offset}
                focus={true}
                ref={ref}
                onChange={onEditReportFieldChange("date_offset")}
              />
            </Form.Field>
          </Form.Group>
        </Accordion.Content>
      </Accordion>
    ) : null;
  };

  const formFields = (
    <React.Fragment>
      <Form.Group key="filetype">
        <Form.Field>
          <label>{"File Type"}</label>
          <Dropdown
            placeholder="csv"
            selection
            value={editReport["filetype"]}
            options={typeOptions}
            onChange={onEditReportFieldChange("filetype")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"days"}>
        <Form.Field>
          <label>{"Scheduled Days"}</label>
          <Dropdown
            placeholder="Mon"
            multiple
            selection
            value={
              Array.isArray(editReport["days"])
                ? editReport["days"]
                : editReport["days"].split(",")
            }
            options={daysOptions}
            onChange={onEditReportFieldChange("days")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"time"}>
        <Form.Field>
          <label>{`Hour (${currentTimeZone.zoneAbbr()})`}</label>
          <Form.Input
            value={editReport["time"]}
            onChange={onEditReportFieldChange("time")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"send_on_pipeline_finish"}>
        <Form.Field>
          <Checkbox
            label="Send when aggregation finish"
            defaultChecked={
              editReport["send_on_pipeline_finish"] === 1 ? true : false
            }
            value={editReport["send_on_pipeline_finish"]}
            onChange={onEditReportFieldChange("send_on_pipeline_finish")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group key={"contactEmail"}>
        <Form.Field>
          <label>{"Contact Email"}</label>
          <Form.Input
            value={editReport["contact"]}
            onChange={onEditReportFieldChange("contact")}
          />
        </Form.Field>
      </Form.Group>
      {editReport.outbox_type !== "do_spaces" && (
        <Form.Group key={"destination"}>
          <Form.Field width={12}>
            <label>{"Destination"}</label>
            <PopupWrapper
              input={
                <Form.Input
                  placeholder='/'
                  value={
                    editReport["destination"]
                      .split(".")
                      .slice(0, -1)
                      .join(".") + `.${editReport["filetype"]}`
                  }
                  onChange={onEditReportFieldChange("destination")}
                  error={invalidRootFolder(editReport?.destination) && {
                    content: 'Please remove the leading slash in Path',
                    pointing: 'below',
                  }}
                />
              }
            />
          </Form.Field>
        </Form.Group>
      )}
      {isRBUser() && (
        <AdvancedSection
          offset={editReport["date_offset"]}
          onEditReportFieldChange={onEditReportFieldChange}></AdvancedSection>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Form onSubmit={() => onSubmit()} loading={loading} error={error}>
        <Message
          error
          header="Failed to edit report"
          content="Please make sure all fields are filled or contact us for help."
        />
        {formFields}
        <Button
          type="submit"
          disabled={!validate()}
          style={{ marginTop: "20px" }}>
          Edit Scheduled Report
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default props => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}>
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>
      {props.title}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <EditModal done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
