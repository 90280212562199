import { metricMapper } from './goalCellHelpers';

export const getRawPercentDiffFromGoal = (target, metricVal) => {
  return  (metricVal - target) / metricVal
}

const round = (val) => {
  const numVal = Number(val)
  return Number(numVal.toFixed(2))
}

const createDataSubsets = (outer, goal) => {
    const {target, metric} = goal

    const insufficientData = []
    const onTarget = []
    const positiveNumbers = []
    const negativeNumbers = []

    outer.forEach(c => {
      const differenceFromGoal = getRawPercentDiffFromGoal(target, c[metric]) * 100 // add differenceFromGoal here to campaign obj so no need to recalc in totalRemovedSpend

      const spendIsLessThan5Percent = ((c.spend/c.totalSpend) * 100) < 5
      const targetIsWithin10PercentOfGoal = Math.abs(differenceFromGoal) <= 10
      const targetIsMoreThan10PercentOfGoal = differenceFromGoal > 10

      if(spendIsLessThan5Percent) return insufficientData.push(c)
      if(targetIsWithin10PercentOfGoal) return onTarget.push(c)
      if(targetIsMoreThan10PercentOfGoal) return positiveNumbers.push(c)

      return negativeNumbers.push(c)
    })

    return { insufficientData, onTarget, positiveNumbers, negativeNumbers}
}

export const addBudget = (outer, goal) => {
  const { metric, budget, target } = goal

  const reverseTrendMetrics = ["cpa"]
  const trendReversed = reverseTrendMetrics.find(c => c == metric)
  const { calculateImpactNumber } = metricMapper[metric]
  const { insufficientData, onTarget, positiveNumbers, negativeNumbers } = createDataSubsets(outer, goal)

  let updatedOnTarget = onTarget
  let updatedInsufficientData = insufficientData
  let aboveGoal = trendReversed ? negativeNumbers : positiveNumbers
  let belowGoal = trendReversed ? positiveNumbers : negativeNumbers

  const totalRemovedSpend = belowGoal.reduce((p,c) => {
    const percentDiff  = Math.abs(getRawPercentDiffFromGoal(target, c[metric]))
    const roundedDiff = Math.round(percentDiff * 100)
    const removedSpend = roundedDiff > 100 ? c.spend : c.spend * percentDiff // never remove more than 100% of spend

    return p + removedSpend
  }, 0)
  

  const { totalSpend } = outer[0] 
  const roundedBudget = round(budget) // round
  const roundedTotalSpend = round(totalSpend) // round
  const roundedTotalRemovedSpend = round(totalRemovedSpend) // round

  const allocatableSpend = round(roundedBudget - roundedTotalSpend + roundedTotalRemovedSpend) // round

  // core cases that cover majority of scenarios
  const underBudget = allocatableSpend > 0
  const overBudget = (allocatableSpend - roundedTotalRemovedSpend) < 0

  const atLeastOneBadlyPerformingCampaign = aboveGoal.length > 0 && belowGoal.length > 0
  const atLeastOneOnTargetAndOneBadCampaign = belowGoal.length > 0 && aboveGoal.length == 0 && updatedOnTarget.length > 0
  const noBadlyPerformingCampaigns = belowGoal.length == 0
  const onlyBadlyPerformingCampaigns = belowGoal.length > 0 && aboveGoal.length == 0 && updatedOnTarget.length == 0

  if(allocatableSpend == 0) return

  if(underBudget) {

    if(atLeastOneBadlyPerformingCampaign || noBadlyPerformingCampaigns ) {
      const hasAboveGoal = aboveGoal.length > 0
      const dataset = hasAboveGoal ? aboveGoal : updatedOnTarget
      const summedImpactNumbers = dataset.reduce((p,c) => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        return p + impactNumber
      }, 0)

      const updatedDataset = dataset.map(c => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        c.recommendedSpend = round((impactNumber/summedImpactNumbers) * allocatableSpend)

        return c
      })

      hasAboveGoal ? aboveGoal = updatedDataset : updatedOnTarget = updatedDataset
      return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
    }

    if(onlyBadlyPerformingCampaigns) {
      const hasOnTarget = updatedOnTarget.length > 0
      const dataset = hasOnTarget ? updatedOnTarget : belowGoal

      const summedBelowGoal = dataset.reduce((p,c) => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        return p + impactNumber
      }, 0)
      
      const recommendation = dataset.map(c => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        const recommendedSpend = round((impactNumber/summedBelowGoal) * (allocatableSpend - roundedTotalRemovedSpend))

        c.recommendedSpend = recommendedSpend
        return c
      })

      hasOnTarget ? updatedOnTarget = recommendation : belowGoal = recommendation;
      return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
    }
  }

  if(overBudget) {
    const hasAboveGoal = aboveGoal.length > 0 

    if(atLeastOneBadlyPerformingCampaign || atLeastOneOnTargetAndOneBadCampaign) {

      const dataset = aboveGoal.length > 0 ? aboveGoal : updatedOnTarget
      const hasSpend = allocatableSpend > 0 ? true : false
      const totalImpactNumbers = dataset.reduce((p,c) => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        return p + impactNumber
      }, 0)

      if(hasSpend) {

        // maybe do total impact numbers calc here? then choose dataset
        const addRecommendedSpend = dataset.map(goal => {
          const impactNumber = calculateImpactNumber(goal[metric], goal.spend)
          const impactNumberPercentOfTotal = (impactNumber/totalImpactNumbers)
          const removalAmount = round(impactNumberPercentOfTotal * allocatableSpend)

          goal.recommendedSpend = removalAmount

          return goal
        })

        hasAboveGoal ? aboveGoal = addRecommendedSpend : updatedOnTarget = addRecommendedSpend

        return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]

      } else {

        const updatedBelowGoal = belowGoal.map(c => {
          const updatedSpend = c.spend - (c.spend * Math.abs(getRawPercentDiffFromGoal(target, c[metric]))) 
          c.updatedSpend = updatedSpend
          c.recommendedSpend = round(updatedSpend * -1)

          return c
        })

        const badlyPerformingRemovalAmount = updatedBelowGoal.reduce((p,c) => {
          return p + c.updatedSpend
        }, 0)

        const updatedAllocatableSpend = totalSpend - badlyPerformingRemovalAmount
        // const stillHasSpend = updatedAllocatableSpend > 0
        
        const aboveGoalAllocatableSpend = roundedBudget - updatedAllocatableSpend

        const subtractRecommendedSpend = aboveGoal.map(goal => {
          const impactNumber = calculateImpactNumber(goal[metric], goal.spend)
          const impactNumberPercentOfTotal = impactNumber/totalImpactNumbers
          const removalAmount = round(impactNumberPercentOfTotal * aboveGoalAllocatableSpend)
          goal.extreme_over_budget = true // what is a case of extreme recommended spend? if removalAmount is greater than...
          goal.recommendedSpend = removalAmount

          return goal
        })

        belowGoal = updatedBelowGoal
        aboveGoal = subtractRecommendedSpend
        return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
      }
    }

    if(noBadlyPerformingCampaigns) {
      const hasAboveGoal = aboveGoal.length > 0
      const dataset = hasAboveGoal ? aboveGoal : updatedOnTarget
      const summedImpactNumbers = dataset.reduce((p,c) => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        return p + impactNumber
      }, 0)

      const updatedDataset = dataset.map(c => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        c.recommendedSpend = round((impactNumber/summedImpactNumbers) * allocatableSpend)

        return c
      })

      hasAboveGoal ? aboveGoal = updatedDataset : updatedOnTarget = updatedDataset
      return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
    }

    if(onlyBadlyPerformingCampaigns) {
      const totalImpactNumbers = belowGoal.reduce((p,c) => {
        const impactNumber = calculateImpactNumber(c[metric], c.spend)
        return p + impactNumber
      }, 0)

      const subtractRecommendedSpend = belowGoal.map(goal => {
        const impactNumber = calculateImpactNumber(goal[metric], goal.spend)
        const impactNumberPercentOfTotal = impactNumber/totalImpactNumbers
        const removalAmount = round(impactNumberPercentOfTotal * (allocatableSpend - roundedTotalRemovedSpend))
        goal.recommendedSpend = removalAmount

        return goal
      })

      belowGoal = subtractRecommendedSpend
      return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
    }
  }

  return [...updatedOnTarget, ...updatedInsufficientData, ...aboveGoal, ...belowGoal]
}
