import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import CacheContext from "../../utils/CacheContext.js";
import FindMarketingEvent from "../Entities/FindMarketingEvent";
import { Form, Button, Icon, Popup, Message } from "semantic-ui-react";
import { ContentCard, DataTable, SplitLayout } from "@rockerbox/styleguide";
import { Link } from "react-router-dom";
import { experimentStore } from "./store";
import { getSegments } from "../../utils/api";
import { customHistory } from "../pushpath";
import {
  dataSheetColumns,
  requiredSetupFields,
  controlTiers
} from "./constants";
import SingleDatePicker from "./SingleDatePicker";
import { parseValueToTiers } from "./helper";
import { track } from "../../utils/tracking";

const experimentsSelector = state => [
  state.getExperiments,
  state.newExperiment,
  state.setNewExperiment,
  state.saveNewExperiment,
  state.setTreament,
  state.platforms,
  state.selectTreeOpen,
  state.setSelectTreeOpen,
  state.resetNewExperiment,
  state.setupError,
  state.setSetupError,
  state.apiRequestLoading
];

const ExperimentSetupForm = props => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const [
    getExperiments,
    newExperiment,
    setNewExperiment,
    saveNewExperiment,
    setTreament,
    platforms,
    selectTreeOpen,
    setSelectTreeOpen,
    resetNewExperiment,
    setupError,
    setSetupError,
    apiRequestLoading
  ] = experimentStore(experimentsSelector);

  const segments = Context.getCache("segments", getSegments) || [];
  const { updatePath } = customHistory(props);
  const segmentOptions = segments
    .filter(segment => segment.action_type === "conversion")
    .map(segment => ({
      key: segment.action_id,
      text: segment.action_name,
      value: segment.action_id
    }));
  const defaultSegmentId = segments.filter(segment => segment.featured).length
    ? segments.filter(segment => segment.featured)[0]["filter_id"]
    : null;
  const segment = React.useMemo(
    () => ({ segment_id: newExperiment["filter_id"] }),
    [newExperiment["filter_id"]]
  );

  useEffect(() => {
    setNewExperiment("filter_id", defaultSegmentId);
  }, [defaultSegmentId]);

  useEffect(() => {
    setSetupError("");
    if (!newExperiment["start_date"]) {
      setNewExperiment("start_date", moment().subtract(1, "days"));
    }
  }, []);

  const nameCallback = useCallback((e, data) =>
    setNewExperiment("name", data.value)
  );
  const startDateCallback = useCallback(date => {
    setNewExperiment("start_date", date);
  });
  const segmentCallback = useCallback((e, data) =>
    setNewExperiment("filter_id", data.value)
  );
  const platformCallback = useCallback((e, data) => {
    setNewExperiment("platform", data.value);
    resetNewExperiment(controlTiers);
    setSelectTreeOpen("pending");
  });
  const onSelectorClick = useCallback((e, data) => {
    setSelectTreeOpen("open");
  });

  const onEventSelection = useCallback((e, data) => {
    const tiers = parseValueToTiers(data);
    Object.entries(tiers).forEach(([key, value]) =>
      setNewExperiment(key, value)
    );
    setSelectTreeOpen("finished");
    e.stopPropagation();
  });

  const onCancel = useCallback(() => {
    setSelectTreeOpen("pending");
    resetNewExperiment();
    updatePath({ view: "view" });
  });

  const onSave = useCallback(() => {
    const requestBody = Object.assign({}, newExperiment);
    requestBody["pixel_source_name"] = segments.filter(
      segment => segment.filter_id === newExperiment["filter_id"]
    )[0]["pixel_source_name"];
    requestBody["test_type"] = "ab"; // Do we need a field for this?
    requestBody["start_date"] = requestBody["start_date"].format("YYYY-MM-DD");
    requestBody["treatments"] = [];
    const fillDefaultNullFields = controlTiers.concat(["end_date"]);
    fillDefaultNullFields.forEach(key => {
      if (!(key in newExperiment)) {
        requestBody[key] = null;
      }
    });

    const isAllFilled = requiredSetupFields.every(
      field => !!newExperiment[field]
    );
    if (isAllFilled) {
      saveNewExperiment(requestBody).then(id => {
        updatePath({ view: "index", id });
        track("experiment.create.submit", requestBody);
        resetNewExperiment();
      });
    } else {
      setSetupError("All Fields must be filled");
    }
  });

  const onRemove = useCallback(() => {
    resetNewExperiment(controlTiers);
    setSelectTreeOpen("pending");
  });

  const enabledSave = React.useMemo(() => {
    return requiredSetupFields.every(field => !!newExperiment[field]);
  }, [
    newExperiment["name"],
    newExperiment["start_date"],
    newExperiment["filter_id"],
    newExperiment["platform"],
    newExperiment["control_tier_1"]
  ]);

  return (
    <ContentCard
      title="Create a New Experiment"
      loading={apiRequestLoading}
      topRight={<Link onClick={onCancel}>{`< back`}</Link>}>
      {setupError && <Message error header={setupError} />}
      <Form>
        <Form.Group>
          <Form.Field width={8}>
            <Form.Input
              value={newExperiment["name"]}
              label="Experiment Name"
              onChange={nameCallback}
              id="experimentName"
            />
          </Form.Field>
          <Form.Field width={8} className="custom-react-dates">
            <label>Start Date</label>
            <SingleDatePicker
              startDate={newExperiment["start_date"]}
              startDateCallback={startDateCallback}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Form.Dropdown
              value={newExperiment["filter_id"]}
              options={segmentOptions}
              label="Conversion Segment"
              onChange={segmentCallback}
              fluid
              selection
              id="experimentSegment"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Form.Dropdown
              value={newExperiment["platform"]}
              options={platforms}
              label="Platform"
              onChange={platformCallback}
              fluid
              selection
              id="experimentPlatform"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <label>
              Set Baseline{" "}
              <Popup
                position="right center"
                content="Pick a baseline marketing campaign/ad that you know performs well. In the next step, you will choose other marketing that will then be compared to this baseline."
                trigger={
                  <Icon name="question circle" style={{ color: "#CACBCD" }} />
                }
              />
            </label>
            {selectTreeOpen === "open" ? (
              <FindMarketingEvent
                segment={segment}
                onChange={onEventSelection}
                controlColumnName={"Select"}
                platformFilter={row =>
                  typeof row.platform === 'string' && row.platform.includes(newExperiment["platform"]) 
                }
                daysAgo={7}
              />
            ) : selectTreeOpen === "finished" ? (
              <SplitLayout
                leftWidth={14}
                rightWidth={2}
                leftContent={
                  <DataTable
                    columns={dataSheetColumns}
                    data={[newExperiment]}
                    onUpdate={() => {}}
                    onRemove={false}
                  />
                }
                rightContent={<Button onClick={onRemove}>Change</Button>}
              />
            ) : (
              <Button
                basic
                fluid
                disabled={!newExperiment["platform"]}
                onClick={onSelectorClick}
                style={{ color: "!#026CAC", backgroundColor: "white" }}>
                <Icon name="search" color="blue" />
                Select Marketing Event
              </Button>
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16}></Form.Field>
        </Form.Group>
      </Form>
      <Button onClick={onCancel}>Cancel</Button>
      <Button
        primary
        disabled={!enabledSave}
        style={{ float: "right" }}
        onClick={onSave}
        id="experimentSave">
        Save
      </Button>
    </ContentCard>
  );
};

export default ExperimentSetupForm;
