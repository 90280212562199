import React from 'react'
import { Grid, Card, Header, Divider } from 'semantic-ui-react'
import ContentLoader from 'react-content-loader'

const SummaryStatLoader = () => (
  <ContentLoader
    viewBox="0 0 120 53"
    foregroundColor="#e5e5e5"
    height="53"
    width="120"
    style={{ float: 'left' }}
  >
    <rect x="0" y="1.39" width="47.1" height="11.23" rx="4" ry="4" />
    <rect x="0" y="20.47" width="120" height="27.29" rx="4" ry="4" />
  </ContentLoader>
)

export const SummaryStatsLoader = ({ featured }) => (
  <Grid columns='equal'>
    <Grid.Column>
      <SummaryStatLoader />
    </Grid.Column>
    <Grid.Column>
      <SummaryStatLoader />
    </Grid.Column>
    {!!featured &&
      <Grid.Column>
        <SummaryStatLoader />
      </Grid.Column>
    }
  </Grid>
)

export const SummaryChartLoader = ({ height }) => (
  <ContentLoader
    viewBox="0 0 1232 200"
    preserveAspectRatio="none" 
    foregroundColor="#e5e5e5"
    height={height}
    width={'100%'}
  >
    <path d="M9.1,134.8h32V195h-32V134.8z" />
    <path d="M49.8,128.5h32V195h-32L49.8,128.5z" />
    <path d="M90.5,150.7h32V195h-32V150.7z" />
    <path d="M131.3,109.5h32V195h-32V109.5z" />
    <path d="M172,115.8h32V195h-32V115.8z" />
    <path d="M212.7,112.7h32V195h-32V112.7z" />
    <path d="M253.5,134.8h32V195h-32V134.8z" />
    <path d="M294.2,115.8h32V195h-32V115.8z" />
    <path d="M334.9,144.3h32V195h-32V144.3z" />
    <path d="M375.7,141.2h32V195h-32V141.2z" />
    <path d="M416.4,122.2h32V195h-32V122.2z" />
    <path d="M457.1,115.8h32V195h-32V115.8z" />
    <path d="M497.9,106.3h32V195h-32L497.9,106.3z" />
    <path d="M538.6,119h32v76h-32V119z" />
    <path d="M579.3,90.5h32V195h-32V90.5z" />
    <path d="M620.1,119h32v76h-32V119z" />
    <path d="M660.8,100h32v95h-32V100z" />
    <path d="M701.5,100h32v95h-32V100z" />
    <path d="M742.3,103.2h32V195h-32V103.2z" />
    <path d="M783,106.3h32V195h-32V106.3z" />
    <path d="M823.7,122.2h32V195h-32V122.2z" />
    <path d="M864.5,93.7h32V195h-32V93.7z" />
    <path d="M905.2,90.5h32V195h-32V90.5z" />
    <path d="M945.9,106.3h32V195h-32V106.3z" />
    <path d="M986.7,77.8h32V195h-32V77.8z" />
    <path d="M1027.4,112.7h32V195h-32V112.7z" />
    <path d="M1068.1,115.8h32V195h-32V115.8z" />
    <path d="M1108.9,84.2h32V195h-32V84.2z" />
    <path d="M1149.6,52.5h32V195h-32V52.5z" />
    <path d="M1190.3,96.8h32V195h-32V96.8z" />
  </ContentLoader>
)

export const ConversionSummaryLoader = ({ featured }) => (
  <Card fluid>
    <Card.Content style={{ padding: 0 }}>
      <div style={{ padding: 10, height: 42 }}>
        <ContentLoader
          viewBox="0 0 120 24"
          preserveAspectRatio="none" 
          foregroundColor="#e5e5e5"
          height="24"
          width="120"
          style={{ display: 'inline-block' }}
        >
          <rect x="0" y="4.64" width="120" height="14.9" rx="4" ry="4" />
        </ContentLoader>
      </div>
      <div style={{ padding: '5px 12px' }}>
        <SummaryStatsLoader {...{ featured }} />
        </div>
        <div style={{ padding: '0 10px 10px' }}>
          <SummaryChartLoader height={!!featured ? 200 : 100} /> 
        </div>
        <Divider style={{ margin: 0 }} />
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}  >
          <div>
            <h3 className='hover-opacity-75'>
              &nbsp;
            </h3>
          </div>
        </div>
      </Card.Content>
    </Card>
)
