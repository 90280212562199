import { openDB } from "idb";

const DATABASE_NAME = "performance_cache"
const VERSION = 1

const upgrade = (upgradeDb) => {
  if (!upgradeDb.objectStoreNames.contains('request_cache_id')) upgradeDb.createObjectStore('request_cache_id',{keyPath:"request"});
  if (!upgradeDb.objectStoreNames.contains('cache_id_data')) upgradeDb.createObjectStore('cache_id_data',{keyPath:"cache_id"});
}

let opened = false

export const getDB = () => {
  if (!opened) opened = openDB(DATABASE_NAME, VERSION, { upgrade })
  return opened
}
