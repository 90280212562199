import moment from 'moment';
import { formatCurrency } from '../../utils/valueFormatter';
import { yesterday } from '../../utils/time';
import { groupChartData, groupDivideChartData } from '../../utils/groupChartData';
import { blankObject } from './constants';

export const generateDateRange = (startDate, endDate) => {
  const dateArray = []
  const stopDate = endDate ? moment(endDate) : moment(yesterday)
  let currentDate = moment(startDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export const filterTiersData = (raw, tier_1, tier_2, tier_3, tier_4, tier_5) => {
  const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 }
  Object.keys(tiers).map(t => {
    if (tiers[t] == undefined || !tiers[t].length) delete tiers[t]
  })
  
  const tierKeysArr = Object.keys(tiers) // [tier_1, tier_2]
  if (tierKeysArr.length === 0) return raw
  
  const filtered = raw.filter((data) => {

    const found = tierKeysArr.flatMap(tier => {
      const filterTier = tiers[tier]
      const rawDataTierValue = data[tier]
      const splitTiers = typeof (filterTier) == "string" ? filterTier.split(",") : filterTier;
      return splitTiers.includes(rawDataTierValue) ? data : []
    })
    return found.length > 0
  })

  return filtered
}                                                                 
                                                                    
export const generateBlankData = (startDate, endDate) => {
  const blankDates = generateDateRange(startDate, endDate)          
    .map(date => Object.assign({ date }, blankObject))
  return blankDates                                                 
}                                                                   

export const getDailyTiersSummary = (tiersData, conversionKey, revenueKey, startDate, endDate) => {
  const blanks = generateBlankData(startDate, endDate)

  const data = tiersData.map(r => {
    const v = Object.keys(r).reduce((p,k) => {
      p[k] = typeof(r[k]) == 'bigint' ? Number(r[k]) : r[k]
      return p
    }, {})
    return v
  })

  const summary = d3.nest()
    .key(g => g.date)
    .rollup((v) => {

      return {
        spend: d3.sum(v, d => d.spend),
        even: d3.sum(v, d => d.even),
        first_touch: d3.sum(v, d => d.first_touch),
        last_touch: d3.sum(v, d => d.last_touch),
        normalized: d3.sum(v, d => d.normalized),
        revenue_even: d3.sum(v, d => d.revenue_even),
        revenue_first_touch: d3.sum(v, d => d.revenue_first_touch),
        revenue_last_touch: d3.sum(v, d => d.revenue_last_touch),
        revenue_normalized: d3.sum(v, d => d.revenue_normalized),
        ntf_even: d3.sum(v, d => d.ntf_even),
        ntf_first_touch: d3.sum(v, d => d.ntf_first_touch),
        ntf_last_touch: d3.sum(v, d => d.ntf_last_touch),
        ntf_normalized: d3.sum(v, d => d.ntf_normalized),
        ntf_revenue_even: d3.sum(v, d => d.ntf_revenue_even),
        ntf_revenue_first_touch: d3.sum(v, d => d.ntf_revenue_first_touch),
        ntf_revenue_last_touch: d3.sum(v, d => d.ntf_revenue_last_touch),
        ntf_revenue_normalized: d3.sum(v, d => d.ntf_revenue_normalized),
        repeat_even: d3.sum(v, d => d.repeat_even),
        repeat_first_touch: d3.sum(v, d => d.repeat_first_touch),
        repeat_last_touch: d3.sum(v, d => d.repeat_last_touch),
        repeat_normalized: d3.sum(v, d => d.repeat_normalized),
        repeat_revenue_even: d3.sum(v, d => d.repeat_revenue_even),
        repeat_revenue_first_touch: d3.sum(v, d => d.repeat_revenue_first_touch),
        repeat_revenue_last_touch: d3.sum(v, d => d.repeat_revenue_last_touch),
        repeat_revenue_normalized: d3.sum(v, d => d.repeat_revenue_normalized),
        roas: d3.sum(v, x => x.spend) ? d3.sum(v, x => x[revenueKey])/d3.sum(v, x => x.spend) : Infinity,
        cpa: d3.sum(v, x => x.spend) ? d3.sum(v, x => x.spend)/d3.sum(v, x => x[conversionKey]) : Infinity,
        rpc: d3.sum(v, x => x.spend) ? d3.sum(v, x => x[revenueKey])/d3.sum(v, x => x[conversionKey]) : Infinity,
      }
    })
    .entries([...data, ...blanks])
    .map(d => {
      const o = {
          'date': d.key
      }
      Object.keys(d.values).map(k => o[k] = d.values[k])
      return o
    });

    return summary
}

export const rollupChartData = (data, metric, startDate, endDate, groupDatesBy = 'daily') => {
  const blanks = generateBlankData(startDate, endDate)
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1 || "")
    .rollup(v => d3.sum(v, x => x[metric]))
    .entries([...blanks, ...data])
    .reduce((p,c) => {
      c.values.map(v => {
        p[c.key] = p[c.key] || {}
        p[c.key]['date'] = c.key
        p[c.key][v.key] = v.values
      })
      return p
    }, {});

  const s = new Set();
  Object.values(d).map(o => Object.keys(o).map(k => s.add(k)));
  Object.values(d).map((o) => {
    [...s].filter(k => !Object.keys(o).includes(k)).map(missing => o[missing] = 0);
  });

  const values = Object.values(d).sort((p, c) => d3.ascending(p.date, c.date))
  if (groupDatesBy !== 'daily') {
    return groupChartData(values, groupDatesBy)
  }
  return values
}

export const rollupDivideChartData = (data, numeratorColumn, denominatorColumn, startDate, endDate, groupDatesBy = 'daily') => {

  if (groupDatesBy !== 'daily') {
    const numeratorData = rollupChartData(data, numeratorColumn, startDate, endDate)
    const denominatorData = rollupChartData(data, denominatorColumn, startDate, endDate)
    const groupedData = groupDivideChartData(numeratorData, denominatorData, groupDatesBy)
    return groupedData
  }

  const blanks = generateBlankData(startDate, endDate)
  const d = d3.nest()
    .key(g => g.date)
    .key(g => g.tier_1 || "")
    .rollup(v => d3.sum(v, z => z[denominatorColumn]) ? d3.sum(v, z => z[numeratorColumn])/d3.sum(v, z => z[denominatorColumn]) : 0)
    .entries([...blanks, ...data])
    .reduce((p,c) => {
      c.values.map(v => {
          p[c.key] = p[c.key] || {}
          p[c.key]['date'] = c.key
          p[c.key][v.key] = v.values
      })
      return p
    }, {});

  const s = new Set();
  Object.values(d).map(o => Object.keys(o).map(k => s.add(k)));
  Object.values(d).map((o) => {
    [...s].filter(k => !Object.keys(o).includes(k)).map(missing => o[missing] = 0);
  });

  const values = Object.values(d).sort((p,c) => d3.ascending(p.date,c.date))
  return values
}

export const buildGroupsFromRaw = (raw) => {
  const groups = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"];

  return groups.reduce((p,c,i,a) => {
    p[c] = d3.nest()
      .key(x => a.slice(0,i+1).map(k => x[k]).join("|||") )
      .rollup(v => v.length)
      .entries(raw)
      .map(x => {
        const value = x.key.split("|||").reverse()[0]
        return {
          key: x.key,
          text: value,
          value: value,
          count: x.values,
          parentTiers: a.slice(0,i),
          parentValues: x.key.split("|||").slice(0,-1)
        }
      }).sort((p,c) => c.count - p.count)

    return p
  },{})
}

export const resetTiers = (val, tierFuncs, index) => {
  if(val == "") {
    const neededFuncs = tierFuncs.slice(index, tierFuncs.length);
    neededFuncs.map(c => {
      return c([]);
    })
  }
}

export const formatTier1Title = (tier_1) => {
  const tiersArr = tier_1
  const numItems = tiersArr.length;
  const remaining = numItems - 3;
  let text = "";

  switch(numItems) {
    case 1:
      text = tiersArr[0]
      break;
    case 2:
      text = `${tiersArr[0]} & ${tiersArr[1]}`
      break;
    case 3:
      text = `${tiersArr[0]}, ${tiersArr[1]}, ${tiersArr[2]}`
      break;
    case 4:
      text = `${tiersArr[0]}, ${tiersArr[1]}, ${tiersArr[2]}, ${tiersArr[3]}`
      break;
    default:
      text = `${tiersArr[0]}, ${tiersArr[1]}, ${tiersArr[2]} & ${remaining} others`
  }

  return text;
}

//TREND CARDS
const sumByPeriod = ({ dailyPerformance, aggObj, start, end }) => {
  dailyPerformance.slice(start, end).forEach(obj => {
    Object.keys(obj).forEach(metric => { if (metric != 'date') aggObj[metric] = obj[metric] + (aggObj[metric] || 0) })
  })
}

export const splitData = ({dates, dailyPerformance}) => {
  const numTestDays = dates.length > 14 ? 7 : 1
  const testDays = dates.slice(-numTestDays)
  const baselineDays = dates.slice(0,-numTestDays)
  
  const baselineObj = dailyPerformance
    .filter(row => baselineDays.includes(row.date))
    .reduce((p,c) => {
      const keys = Object.keys(c)
      keys.forEach(metric => { 
        if (metric == 'date') return;
        p[metric] = Number(p[metric] || 0) + Number(c[metric])
      })
      return p
    }, {})

  const testObj = dailyPerformance
    .filter(row => testDays.includes(row.date))
    .reduce((p,c) => {
      const keys = Object.keys(c)
      keys.forEach(metric => { 
        if (metric == 'date') return;
        p[metric] = (p[metric] || 0) + c[metric]
      })
      return p
    }, {})

  return [testObj, baselineObj, numTestDays, dates.length - numTestDays]
}

export const getChartData = (data, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy) => {
  if (!data) return []
  switch (metricColumn) {
    case 'cpa':
      return rollupDivideChartData(data, 'spend', conversionKey, startDate, endDate, groupDatesBy)
    case 'roas':
      return rollupDivideChartData(data, revenueKey, 'spend', startDate, endDate, groupDatesBy)
    case 'rpc':
      return rollupDivideChartData(data, revenueKey, conversionKey, startDate, endDate, groupDatesBy)
    default:
      return rollupChartData(data, metricColumn, startDate, endDate, groupDatesBy)
  }
}

// duplicated in digital advertising helpers
export const formatNumber = (item, number, currencyCode) => {
  const { format, maxDigits } = item;

  if (format == 'currency') return formatCurrency(number, currencyCode,maxDigits ? maxDigits : 0)

  let numberFormatOptions = {
    style: format ? format : 'decimal',
    maximumFractionDigits: maxDigits ? maxDigits : 0
  }

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formatted = isNaN(number) ? '-' : typeof number !== 'number' ? numberFormatter.format(0) : numberFormatter.format(number);
  return formatted
}
