import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, Divider, Dropdown } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide';
import { DragAndDrop } from '.';
import { DEFAULT_TIERS } from '../constants/tiers';

const PopupCard = ({
  isCustom = false,
  list,
  originalList,
  setOrder,
  customKey,
  title,
  icon,
  callbackFn,
  excludeFirst,
  applyOnDrop = false,
  open
}) => {
  const [items, setItems] = useState([])
  const [allItems, setAllItems] = useState([])
  const [options, setOptions] = useState([])
  const [excluded, setExcluded] = useState([])
  const [dimensionValue, setDimensionValue] = useState('')

  const checkKeyExists = (array, key) => array?.every(obj => key in obj);  
  const uniqueKeyUsed = isCustom ? checkKeyExists(originalList, 'id') ? 'id' : checkKeyExists(originalList, 'key') ? 'key' : customKey : false

  const createCustomObj = (list) => (
    list.reduce((obj, item) => {
      const key = item[uniqueKeyUsed]
      return Object.assign(obj, { [key] : item[customKey] })
    }, {})
  )
  const tiers = isCustom && originalList ? createCustomObj(originalList) : DEFAULT_TIERS

  useEffect(() => {
    if (!open) return
    let allBreakdownItems = Object.keys(tiers)
      .map(item => (
        { id: item, text: tiers[item] }
      ))

    if (excludeFirst) {
      const items = allBreakdownItems.slice(excludeFirst)
      const excluded = allBreakdownItems.slice(0, excludeFirst)
      allBreakdownItems = items

      setExcluded(excluded)
    }
    setAllItems(allBreakdownItems)

    if (list.length > 0) {
      const breakdowns = isCustom ?
        list.map(item => (
          {
            id: item[uniqueKeyUsed],
            text: tiers[item[uniqueKeyUsed]]
          }
        ))
        :
        list.map(item => (
          { id: item, text: tiers[item] }
        ))
      setItems(breakdowns)
    }
    setDimensionValue('')
  }, [open])

  useEffect(() => {
    if (items.length === 0) return
    const excludedList = excluded.map(({ id }) =>  id)
    const filterItems = items.filter(({ id }) => !excludedList.includes(id))
    setItems(filterItems)
  }, [excluded])

  useEffect(() => {
    createOptions(allItems)
  }, [items, allItems])

  useEffect(() => {
    if (applyOnDrop) {
      const order = [...excluded, ...items].map(item => (
        isCustom ?
          originalList.find((x) => (
            x.key ? x.key === item.id : (x[customKey] === item.id)
          ))
          : item.id));
      setOrder(order)
    }
  }, [items, applyOnDrop, setOrder])

  const addDimension = (e, { value }) => {
    const newItems = allItems.find(item => item.id === value)
    setItems([...items, newItems])
    setDimensionValue(value)
  }

  const onApplyClick = () => {
    const order = [...excluded, ...items].map(item => (
      isCustom ?
        originalList.find((x) => (
          x[uniqueKeyUsed] === item.id
        ))
        : item.id));
    setOrder(order)
    callbackFn && callbackFn()
    // setOpen(false)
  }

  const createOptions = (list) => {
    const filteredList = list.filter(item => (
      !items.find(x => x.id === item.id)
    ))
    const newList = filteredList.map(item => (
      { key: item.id, value: item.id, text: item.text }
    ))
    setOptions(newList)
  }

  return (
    <ContentCard title={title}>
      <DragAndDrop {...{items, setItems}} />
      {items.length < allItems.length &&
        <Dropdown
          button
          fluid
          className='icon'
          labeled
          text='Add Dimension'
          icon='plus'
          search
          options={options}
          onChange={addDimension}
          style={{ margin: '15px 0' }}
          value={dimensionValue}
        />}
      {!applyOnDrop && (
        <>
          <Divider />
          <Button fluid primary onClick={onApplyClick}>Apply</Button>
        </>
      )}
    </ContentCard>
  );

}

PopupCard.propTypes = {
  isCustom: PropTypes.bool, // optional: default false
  list:  (props, propName) => {
    if (props['isCustom'] && props[propName] === undefined || typeof (props[propName]) !== 'object') {
      // console.log('Please provide a list prop: an array for draggable items!')
      return new Error('Please provide a list prop: an array for draggable items!');
    }
  },
  originalList: (props, propName) => {
    if (props['isCustom'] && props[propName] === undefined || typeof (props[propName]) !== 'object') {
      // console.log('Please provide a originalList prop: an array of items before modifications')
      return new Error('Please provide a originalList prop: an array of items before modifications');
    }
  },
  customKey:(props, propName) => {
    if (props['isCustom'] && props[propName] === undefined || typeof (props[propName]) !== 'string') {
      // console.log('Please provide a customKey prop: this is the key from the list prop that is used for each draggable item')
      return new Error('Please provide a customKey prop: this is the key from the list prop that is used for each draggable item');
    }
  },
  setOrder: PropTypes.func.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string, // optional: this is the icon if wanted in front of each draggable item. If nothing is passed down, default icon is 'grid layout'
  applyOnDrop: PropTypes.bool, // Hide apply button and run callback on drop
}

export default PopupCard

/*
>>>>>>>>>>> EXAMPLE <<<<<<<<<<<<

>>>> For Default Tiers <<<<<<<
const [tiers, setTiers] = useState(["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"])

<PopupCard title='Customize Breakdowns' list={tiers} setOrder={setTiers} />


>>>> For Customizeable <<<<<<<
export const countCols = [
  { display: "% First Touch", key: 'count_first_all' },
  { display: "% Middle Touch", key: 'count_mid_all' },
  { display: "% Last Touch", key: 'count_last_all' }
]
const [cols, setCols] = useState([countCols])

<PopupCard title='Customize Metrics' isCustom={true} customKey={'display'} list={cols} originalList={countCols} setOrder={setCols} icon='none' />

*/
