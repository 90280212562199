import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2 
})

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1
})

const isCurrency = (value) => value.includes("revenue") ||value.includes("spend") || value.includes("cost") || value.includes("cpa") 
const isRatio = (value) => value.includes("ratio") 

export const DefaultCell = ({item, col}) => <Table.Cell>{ JSON.stringify(item[col.key]) }</Table.Cell>
export const NumberCell = ({item, col, style}) => <Table.Cell style={style}>
  { item[col.key] && !isRatio(col.key.toLowerCase()) && isCurrency(col.key.toLowerCase()) ? "$" : "" }
  { item[col.key] ? numberFormatter.format(item[col.key]) : "-" }
  { item[col.key] && isRatio(col.key.toLowerCase()) ? "X" : "" }
</Table.Cell>
export const PercentCell = ({item, col}) => <Table.Cell>{ item[col.key] ? percentFormatter.format(item[col.key]) : "-" }</Table.Cell>

export const defaultFormatTypeAs = {
  "default": DefaultCell,
  "sum": NumberCell,
  "percent-row": PercentCell,
  "percent-col": PercentCell,
  "percent-all": PercentCell
}
