import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import drawSlopegraph from './drawSlopegraph'

class Slopegraph extends Component {
  renderD3() {
    const { data, width, height, suffix } = this.props
    drawSlopegraph(data, width, height, suffix)
  }

  componentDidMount() {
    this.renderD3()
  }
  componentDidUpdate() {
    this.renderD3()
  }

  render(){
    return(
      <div className='slopegraph-wrap'>
        <nav id='slopegraph-selects' className='slopegraph-selects'></nav>
        <div id='slopegraph' className='slopegraph' />
      </div>
    )
  }
}

export default Slopegraph
