import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { getViaKey, get, currencyFormat, currency, percent, number, defaultNumber } from './helpers';




const Values = ({tag, values, months, accessor, formatter}) => {
  const format = formatter || defaultNumber;

  return (
    <React.Fragment>
      <Row hover>
        <Column padded="5">Existing</Column>
        <Column>{ format(getViaKey(values,`Existing.${"cohort"}.${accessor}`)) }</Column>
        { months.map(date => (<Column></Column>) ) }
        <Column>{ format(getViaKey(values,`Existing.${"cohort"}.${accessor}`)) }</Column>
      </Row>
      <Row hover>
        <Column padded="20">Existing Repeat</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(getViaKey(values,`repeat Existing.${date}.${accessor}`)) }</Column>) ) }
        <Column>{ format(d3.sum(months.map(date => (getViaKey(values,`repeat Existing.${date}.${accessor}`) )))) }</Column>
      </Row>
      <Row hover light grey>
        <Column padded="20">Existing Cumulative Total</Column>
        <Column>{ format(((values['Existing']||{})["cohort"]||{})[accessor]) }</Column>
        { months.map(date => (<Column>{ format(getViaKey(values,`Existing.${date}.cumulative.${accessor}`)) }</Column>) ) }
        { months.map(date => (<Column bold>{ format(getViaKey(values,`Existing.${date}.cumulative.${accessor}`)) }</Column>) ).slice(-1) }
      </Row>
      { tag == "revenue" && 
        <Row hover>
          <Column padded="20">Average Order Value</Column>
          <Column />
          { months.map(date => (<Column>{ format(getViaKey(values,`repeat Existing.${date}.${accessor}`)/getViaKey(values,`repeat Existing.${date}.conversions`)) }</Column>) ) }
          <Column />
        </Row>
      }
      <Row hover>
        <Column padded="5">New Customers</Column>
        <Column>{ format((getViaKey(values,`NTF.${"cohort"}.${accessor}`))) }</Column>
        { months.map(date => (<Column></Column>) ) }
        <Column>{ format((getViaKey(values,`NTF.${"cohort"}.${accessor}`))) }</Column>
      </Row>
      <Row hover>
        <Column padded="20">Repeat Purchase (2x)</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(getViaKey(values,`repeat NTF.${date}.${accessor}`)) }</Column>) ) }
        <Column>{ format(d3.sum(months.map(date => (getViaKey(values,`repeat NTF.${date}.${accessor}`) )))) }</Column>
      </Row>
      <Row hover light>
        <Column padded="20">NTF Cumulative Repeat</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(getViaKey(values,`repeat NTF.${date}.cumulative.${accessor}`)) }</Column>) ) }
        <Column></Column>
      </Row>

      <Row hover light>
        <Column padded="35">NTF Repeat %</Column>
        <Column></Column>
        { 
          months.map(date => (
            <Column>
              { percent((getViaKey(values,`repeat NTF.${date}.${accessor}`)/(getViaKey(values,`NTF.${"cohort"}.${accessor}`)))) }
            </Column>
          )) 
        }
        <Column></Column>
      </Row>
      <Row hover light>
        <Column padded="35">NTF Cumulative Repeat %</Column>
        <Column></Column>
        { 
          months.map(date=> (
            <Column>
              { percent(getViaKey(values,`repeat NTF.${date}.cumulative.${accessor}`)/(getViaKey(values,`NTF.${"cohort"}.${accessor}`))) }
            </Column>
          )) 
        }
        <Column></Column>
      </Row>

      <Row hover light grey>
        <Column padded="20">NTF Cumulative Total</Column>
        <Column>{ format((getViaKey(values,`NTF.${"cohort"}.${accessor}`))) }</Column>

        { months.map(date => (<Column>{ format(getViaKey(values,`NTF.${date}.cumulative.${accessor}`)) }</Column>) ) }
        { months.map(date => (<Column bold>{ format(getViaKey(values,`NTF.${date}.cumulative.${accessor}`)) }</Column>) ).slice(-1) }
      </Row>

      { tag == "revenue" && 
      <React.Fragment>
        <Row hover>
          <Column padded="20">NTF Customer AOV</Column>
          <Column>{ format((getViaKey(values,`NTF.${"cohort"}.${accessor}`)/getViaKey(values,`NTF.${"cohort"}.converters`))) }</Column>

          { months.map(date => (<Column>{ format(getViaKey(values,`NTF.${date}.cumulative.${accessor}`)/getViaKey(values,`NTF.${"cohort"}.converters`)) }</Column>) ) }
          <Column />
        </Row>
        <Row hover>
          <Column padded="20">Average Order Value</Column>
          <Column />
          { months.map(date => (<Column>{ format(getViaKey(values,`repeat NTF.${date}.${accessor}`)/getViaKey(values,`repeat NTF.${date}.conversions`)) }</Column>) ) }
          <Column />
        </Row>
      </React.Fragment>
      }

      <Row hover light>
        <Column padded="5">Cumulative Total</Column>
        <Column></Column>
        { months.map(date => (<Column>{ format(getViaKey(values, `NTF.${date}.cumulative.${accessor}`,0) + getViaKey(values,`Existing.${date}.cumulative.${accessor}`,0)) }</Column>) ) }
        <Column />
      </Row>

      <Row hover>
        <Column padded="20">NTF %</Column>
        <Column></Column>
        { 
          months.map((date,i) => (
            <Column>{
              i ? "" : percent((getViaKey(values,`NTF.${"cohort"}.${accessor}`)) / (
                getViaKey(values, `NTF.${date}.cumulative.${accessor}`,0) + getViaKey(values,`Existing.${date}.cumulative.${accessor}`,0)
              ))
            }
            </Column>
          )) 
        }
        <Column bold>{ }</Column>
      </Row>

      
    </React.Fragment>
  )
}

export default Values;
