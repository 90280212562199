import React, { Component } from 'react';
import { Label, Checkbox, Table, Message, Form, Button, Accordion, Segment, Icon, Grid } from 'semantic-ui-react';
import { FormDropdown } from './SurveyForm';
import styled from 'styled-components';

const POSITION_NUMBERS = [1,2,3,4,5]

const InlineGroup = styled.div`
  display: flex;
  & > .ui.label {
    line-height: 24px;
  }
  & > .ui {
    margin:0;
    border-radius:0;
  }
  & > .ui > .ui.button, 
  & > .ui.buttons,
  & > .ui.input > input {
    border-radius:0 !important;
  }
  & > .ui.basic > .ui.button.icon {
    max-height: 17px
  }
  & .ui.selection.dropdown {
    min-height: 3.2em;
    border-radius:0 !important;
  }
  & .ui.selection.dropdown > i.dropdown.icon {
    margin: -.91666667em;
  }
  & .ui.dropdown > i.dropdown.icon {
    margin: 0;
  }
  & .ui.icon.button>.icon {
    margin-top: -3px !important;
  }
  
`

const codeOptions = [
  {text: "Response", value:"{response}"},
  {text: "Response Followup", value:"{response_followup}"},
]

const ValueField = React.memo(({ value, keyName, updateKey, tierOptions, pos }) => {

  const key = keyName;
  const placeholder = keyName;
  const style = {width: 168}
  const dropdownStyle = Object.assign({}, style, {backgroundColor: "#fcfff5 !important" })

  const defaultType = (!!value && !!value.length && value[0] == "{") ?
    "code" : (tierOptions.find(row => row.value == value)) ?
    "tags" : ( !!value && !!value.length ) ?
    "text" : "tags"

  const [inputType, setInputType] = React.useState(defaultType);
  React.useEffect(() => {
    setInputType(defaultType)
  }, [defaultType])

  const onChange = updateKey(key)

  return <>
    <Button.Group size="mini">
      <Button active={inputType == "tags"} as='a' icon='tags' style={{lineHeight:"18px"}} onClick={() => setInputType("tags")} />
      <Button active={inputType == "text"} as='a' icon='quote left' style={{lineHeight:"18px"}} onClick={() => setInputType("text")} />
      <Button active={inputType == "code"} as='a' icon='code' style={{lineHeight:"18px"}} onClick={() => setInputType("code")} />
    </Button.Group>
    { inputType == "tags" && <Form.Dropdown selection {...{placeholder, style: dropdownStyle, value, onChange, options: tierOptions}} /> }
    { inputType == "text" && <Form.Input {...{placeholder, style, value, onChange}} /> }
    { inputType == "code" && <Form.Dropdown selection {...{placeholder, style: dropdownStyle, value, onChange, options: codeOptions}} /> }
  </>
})

const PriorityField = React.memo(({ event, updateKey }) => {

  const key = 'priority';
  const placeholder = '50';

  const onChange = updateKey(key)
  const value = event['priority']

  return <>
    <Form.Input className="PPS-Priority" {...{placeholder, value, onChange}} /> 
  </>
})

export default class AdvancedCard extends Component {
  state = {
    showCurrent: 1,
    showAs: 1,
    showSkipCreation: false
  }

  componentDidMount() {
    const { event } = this.props;

    const showCurrent = POSITION_NUMBERS.reduce((p,c) => {
      if(event[`mta_tier_${c}`]) {
        p = c
      }
      return p
    }, 1)

    const showAs = POSITION_NUMBERS.reduce((p,c) => {
      if(event[`tier_${c}`]) {
        p = c
      }
      return p
    }, 1)

    this.setState({showCurrent, showAs})
  }

  shouldComponentUpdate(prevProps, prevState) {
    const propsShouldUpdate = JSON.stringify(this.props.event) != JSON.stringify(prevProps.event)
    const stateShouldUpdate = JSON.stringify(this.state) != JSON.stringify(prevState)

    const shouldUpdateTiers = this.props.tierOptions != prevProps.tierOptions
    const shouldUpdateOptions = this.props.options != prevProps.options

    return propsShouldUpdate || stateShouldUpdate || shouldUpdateTiers || shouldUpdateOptions
  }

  handleAddField = (key) => {
    const { showAs, showCurrent} = this.state;
    const val = key == 'showAs' ? showAs : showCurrent;

    this.setState({[key]: val+1});
  }

  toggleShowSkipCreation = () => {
    this.setState({ showSkipCreation: true })
  }

  render() {
    const { tierOptions, options, event, updateStateArrayRowField, pos, remove } = this.props;
    const { showSkipCreation, showCurrent, showAs } = this.state;

    const onResponseChange = updateStateArrayRowField("events", "response")(event);
    const displayAs = showAs == 5 ? 'none' : 'inline-block';
    const displayCurrent = showCurrent == 5 ? 'none' : 'inline-block';

    const updateKey = (key) => updateStateArrayRowField("events", key)(event)
    const tierPositions = POSITION_NUMBERS.slice(0, showAs)
    const skipPositions = POSITION_NUMBERS.slice(0, showCurrent)

    let currentOptions = []
    let nextOptions = tierOptions


    return (
      <Segment secondary>
        <Button as='a' floated='right' icon='remove' onClick={() => remove(pos)} color='google plus' />
        <Form.Group>
          <FormDropdown options={options} label="If the response is" value={event.response} onChange={onResponseChange} />

          <div className="field" style={{margin: 0}}>
            <label>Create an event where</label>
            <>
              { 
                tierPositions.map(pos => {
                  const value = event[`tier_${pos}`]
                  currentOptions = nextOptions || []

                  const next = currentOptions.find(({ text }) => text == value) || { values: [] }
                  nextOptions = next.values

                  return <Form.Group>
                    <Label style={{lineHeight:1.8}}>{ `Tier ${pos}` }</Label>
                    <ValueField keyName={`tier_${pos}`} {...{updateKey, value, tierOptions: currentOptions, pos}} />
                  </Form.Group>
                })
              }
              <Button icon="plus" onClick={() => this.handleAddField("showAs")} style={{display: displayAs}} as="span"/>
            </>
          </div>
        </Form.Group>
        <Form.Group >
          Priority
          <PriorityField {...{event, updateKey}} />
        </Form.Group>
        { !showSkipCreation && <><Checkbox onChange={this.toggleShowSkipCreation} />Skip Creation if Event Exists</>}
        { showSkipCreation && <>
          <div className="field" style={{margin: 0}}>
            <label>Skip creation if existing event matches</label>
          </div>
          <Form.Group>
            { skipPositions.map(pos => <ValueField keyName={`mta_tier_${pos}`} {...{updateKey, value: event[`mta_tier_${pos}`], tierOptions, pos}} />) }
            <Button icon="plus" onClick={() => this.handleAddField("showCurrent")} style={{display: displayCurrent}} as="span"/>
          </Form.Group>
          </>
        }
      </Segment>
    )
  }
}
