import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast'
import 'react-dates/lib/css/_datepicker.css';
import '../../../react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { yesterday, daysAgo } from '../../utils/time';

const MAX_DAYS = 750
const days150 = daysAgo(150)

const DateRangePickerWrapper = ({ startDate, endDate, onChange, firstReportingDate }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [start, setStart] = useState(startDate)
  const [end, setEnd] = useState(endDate)

  useEffect(() => {
    setFocusedInput('startDate')
  }, [])

  useEffect(() => {
    if (focusedInput == null) {
      if (start && end) {
        return onChange({ startDate: start, endDate: end })
      }
      toast(`Invalid Date Input.`, { icon: 'ℹ️' })
    }
  }, [focusedInput])

  useEffect(() => {
    setStart(startDate)
    setEnd(endDate)
  }, [startDate, endDate])

  const onDatesChange = (dates) => {
    const newStartDate = dates.startDate
    const newEndDate = dates.endDate

    // if (focusedInput === 'startDate' && !!newEndDate && !!newStartDate) {
    //   console.log(newStartDate?.clone().add(MAX_DAYS, 'days'))
    //   const isOutsideRange = newEndDate.isAfter(newStartDate?.clone().add(MAX_DAYS, 'days'))
    //   console.log(isOutsideRange)
    //   if (isOutsideRange) {
    //     setStart(newStartDate)
    //     setEnd(null)
    //     toast('Max date range supported is 100 days', { icon: 'ℹ️' })
    //     return
    //   }
    // }

    setStart(newStartDate)
    setEnd(newEndDate)
  }

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput)
    const uniqueStartDate = document.getElementById('uniqueStartDate').value
    const uniqueEndDate = document.getElementById('uniqueEndDate').value

    if (focusedInput === 'endDate' && !start) {
      const beforeFirstReporting = moment(uniqueStartDate).isBefore(moment(firstReportingDate))
      const afterYesterday = moment(uniqueStartDate).isAfter(yesterday)
      const vaildDate = moment(uniqueStartDate).isValid()

      if (beforeFirstReporting) {
        toast(`Please enter a valid start date. Start date should be after this conversions first reporting date. \n\nNote: This conversions first reporting date is ${firstReportingDate}.`, { icon: 'ℹ️', duration: 10000 })
        return
      }
      if (afterYesterday) {
        toast(`Please enter a valid start date, yesterday or before.`, { icon: 'ℹ️' })
        return
      }
      if (!vaildDate) {
        toast(`Please enter a valid start date.`, { icon: 'ℹ️' })
        return
      }
    }
  }

  const handleOutsideRange = (day) => {
    // day from react-dates is every calendar date at different times throughout the date. Need to reformat moment object to consistently show same time for a day.
    const sameDay = moment(day.format("YYYY-MM-DD")).utc()

    const minDate = firstReportingDate ? sameDay.isBefore(moment(firstReportingDate)) : sameDay.isBefore(days150);
    const maxDate = sameDay.isAfter(yesterday);

    if (focusedInput === 'startDate') {
      return minDate || maxDate
    }
    if (focusedInput === 'endDate' && start) {
      return maxDate
      // return (
      //   maxDate ||
      //   sameDay.isAfter(start.clone().add(MAX_DAYS, 'days'))
      // )
    }
    return false
  }

  return (
    <div>
      <DateRangePicker
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={start}
        endDate={end}
        noBorder={true}
        isOutsideRange={(day) => handleOutsideRange(day)}
        hideKeyboardShortcutsPanel
        startDateId="uniqueStartDate"
        endDateId="uniqueEndDate"
        minimumNights={0}
        customArrowIcon={<ArrowSmRightIcon style={{ width: 20, height: 20 }} />}
        verticalSpacing={0}
        anchorDirection='right'
        numberOfMonths='1'
      />
    </div>
  );
}

DateRangePickerWrapper.propTypes = {
  startDate: PropTypes.object.isRequired, // required moment time
  endDate: PropTypes.object.isRequired, // required moment time
  onChange: PropTypes.func.isRequired, // onChange is typically setStartDate
  firstReportingDate: PropTypes.object // is moment time. Default is 150 days ago
}

export default DateRangePickerWrapper;
