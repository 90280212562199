export const validateEmail = (value) => {
  const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = emailReg.test(value)
  return result
}

export  const validateDomain = (value) => {
  const domainReg = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
  const result = domainReg.test(value)
  return result
}

export const validateTextFilled = (formFields) => {
  const formFieldsKeys = Object.keys(formFields)
  const filtered = formFieldsKeys.filter(key => formFields[key] == "")
  return filtered
}

export const validateTier = (tier) => {
  const tierReg = /^(starter|lite|scale)$/
  const result = tierReg.test(tier)
  return result
}

// Alphanumeric only. Spaces allowed.
export const validateNoSpecialCharacters = (value) => {
  const noSpecialChars = /^[\w\s&.-]+$/;
  const result = noSpecialChars.test(value);
  return result;
}
