import React from 'react'
import { FlowMapContext } from './FlowMap'

export const Node = ({ id, className, children }) => {
  const { updateCoordinates } = React.useContext(FlowMapContext)

  const getCoordinates = (element) => {
    if (!element) return null
    const top = element.offsetTop
    const left = element.offsetLeft
    const height = element.offsetHeight
    const width = element.offsetWidth
    const coordinates = { top, left, height, width }
    updateCoordinates(id, coordinates)
  }

  return (
    <div className={`node ${className}`} ref={getCoordinates}>
      {children}
    </div>
  )
}
