import React, { useMemo, useState, useEffect } from 'react';

import { getStats, getTotals, filterTiers, filterSearch } from '../arqueroHelpers';
import { filterTiersData, getDailyTiersSummary } from '../helpers';
import { getChartData } from '../helpers';

const useMemoWithLog = (func, watching) => {
  return useMemo(
    (...args) => {
      // console.log("here", watching, String(func))
      // console.time("func")
      const res = func(...args)
      // console.timeEnd("func")
      return res
    },
    watching
  )
}

const s = (arr) => JSON.stringify(arr)

export const useFilteredDataArq = (tiersDataRaw, search, filters, conversionKey, revenueKey, startDate, endDate, metricColumn, attributionMethod, groupDatesBy) => {

  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;

  const tiersData = useMemoWithLog(
    () =>  !!tiersDataRaw ? filterSearch(tiersDataRaw, search) : false,
    [tiersDataRaw, search]
  );

  const tiersSummaryRaw = useMemoWithLog(
    () => tiersData ? getStats(["tier_1","tier_2","tier_3","tier_4","tier_5"], tiersData, conversionKey, revenueKey, startDate, endDate, true) : false,
    [tiersData, conversionKey, revenueKey, startDate, endDate]
  );

  const tiersSummaryRawTotals = useMemoWithLog(() => (
    tiersSummaryRaw ? getTotals(tiersSummaryRaw) : false
  ), [tiersSummaryRaw])

  const filteredTiers = useMemoWithLog(
    () => !!tiersData ? filterTiers(tiersData, filters) : false,
    [tiersData, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]
  );

  const dailyTiersSummary = useMemoWithLog(
    () => filteredTiers ? getStats(["date", "tier_1"], filteredTiers, conversionKey, revenueKey, startDate, endDate) : false,
    [filteredTiers, conversionKey, revenueKey, startDate, endDate]
  );

  const dailySummary = useMemoWithLog(
    () => filteredTiers ? getStats(["date"], filteredTiers, conversionKey, revenueKey, startDate, endDate) : false,
    [filteredTiers, conversionKey, revenueKey, startDate, endDate]
  );

  const tiersSummary = useMemoWithLog(
    () => !!tiersSummaryRaw ? filterTiers(tiersSummaryRaw, filters).objects() : [],
    [tiersSummaryRaw, s(tier_1), s(tier_2), s(tier_3), s(tier_4), s(tier_5)]
  );

  const chartData = useMemoWithLog(
    () => getChartData(dailyTiersSummary, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy),
    [dailyTiersSummary, metricColumn, revenueKey, conversionKey, startDate, endDate, groupDatesBy]
  );
  const lineChartData = chartData;

  // base case
  if (!tiersData || tiersData.length == 0) return {
    loading: true,
    filteredTiersData: [],
    dailyTiersSummary: getDailyTiersSummary([], conversionKey, revenueKey, startDate, endDate),
    dailySummary: getDailyTiersSummary([], conversionKey, revenueKey, startDate, endDate),
    tiersSummary: [],
    chartData: [],
    lineChartData: [],
    tiersSummaryRaw: [],
    tiersSummaryRawTotals: {},
  }

  return {
    loading: false,
    filteredTiersData: filteredTiers.objects(),
    dailyTiersSummary,
    dailySummary,
    tiersSummary,
    chartData,
    lineChartData,
    tiersSummaryRaw: tiersSummaryRaw.objects(),
    tiersSummaryRawTotals: tiersSummaryRawTotals.objects()[0]
  }
}
