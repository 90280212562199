import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

import ConnectOrders from './ConnectOrders'

const FreeConnectOrders = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [showShopify, setShowShopify] = useState(false)


  const openShopify = () => {
    setShowShopify(true)
    setModalOpen(true)
  }

  const openOthers = () => {
    setShowShopify(false)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setShowShopify(false)
    setModalOpen(false)
  }

  return (
    <>
        <div className="free-connect-orders">
          <div style={{padding: '25px 5px'}}>
            <h2>Connect your orders &amp; conversions</h2>
            <Button
              primary
              onClick={() => openShopify()}
            >
              Get started with Shopify
              <FontAwesomeIcon
                as='i'
                icon={faArrowCircleRight}
                style={{ marginLeft: 10, color: 'white' }}
              />
            </Button>
            <Button
              inverted
              color="purple"
              onClick={() => openOthers()}
              style={{ marginLeft: 10 }}
            >
              Other options
            </Button>
          </div>
        </div>
      <ConnectOrders open={modalOpen} onClose={onModalClose} defaultShowShopify={showShopify}/>
    </>
  )
}

export default FreeConnectOrders
