import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import * as routes from "../../routes";
import { Label, Accordion, Menu, Table, Checkbox, Dropdown, Button, Popup, Icon, Modal, Form, Input } from "semantic-ui-react";
import { StatCard, ColumnLayout, ContentCard, IndexGrid } from "@rockerbox/styleguide";
import * as d3 from "rockerbox_d3_legacy_clone";
import moment from "moment";
import { extendMoment } from 'moment-range';
import { buildDates } from "../ViewAttribution/helpers";
import { checkJob, checkJobsCache, trackJob, getJobId } from "../../utils/job_api";
import { submitPipelineAgg, submitEventsAgg, submitOttAgg, getConversionSegmentReportingData, getOttOffset, getSegments } from "../../utils/api";
import SummaryBar from "../ViewAttribution/SummaryBar";
import ViewStats from "./ViewStats";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";

const dateOptions = [
  { value: 30, text: "Past 30 days" },
  { value: 60, text: "Past 60 days" },
  { value: 90, text: "Past 90 days" },
  { value: 120, text: "Past 120 days" },
  { value: 180, text: "Past 180 days" }
];

export const backfillTypeMap = {
  "Rerun Pipeline": 'pipelineAggStatus',
  "Rerun Compile": 'eventsAggStatus',
  "Rerun Ott": 'ottAggStatus'
}

const BackfillCell = (text, onClick, statusObj, id) => props => {
  const state = statusObj[JSON.stringify(props.item)];
  const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${getJobId(id, backfillTypeMap[text], props.item)}`;

  return (
    <Table.Cell collapsing style={{ minWidth: 70 }}>
      <Button onClick={() => onClick(props.item)}>{text}</Button>

      {state == "error" ? (
        <a href={jobUrl} target="_blank"><Icon name="warning sign" /></a>
      ) : state == "success" ? (
        <a href={jobUrl} target="_blank"><Icon name="check circle" /></a>
      ) : state != undefined ? (
        <a href={jobUrl} target="_blank"><Icon loading name='circle notch' /></a>
      ) : (
        undefined
      )}
    </Table.Cell>
  );
};

class ViewConversionEvent extends Component {
  state = {
    data: [],
    numDays: 30,
    plotVariable: "conversions",
    disabled: false,
    loading: false,
    activeAccount: false,
    eventsAggStatus: {},
    pipelineAggStatus: {},
    ottAggStatus: {},
    modalOpen: false,
    rerunType: "",
    startDate: moment.utc().utcOffset(-5).subtract(1, 'day'),
    endDate: moment.utc().utcOffset(-5).subtract(1, 'day'),
    jobUrl: '',
    rerunTypeError: "",
    ottType: null,
    isChild: false
  };

  componentDidMount() {
    this.getData(this.state.numDays);
    const { id } = this.props.match.params;
    this.getJobs("eventsAggStatus")(id);
    this.getJobs("pipelineAggStatus")(id);
    this.getOttType();
    this.getSegments();
  }

  getSegments = () => {
    const { id } = this.props.match.params;
    getSegments().then(data => {
      const isChildSegment = data.filter(segment => segment.filter_id == id).length > 0 ? data.filter(segment => segment.filter_id == id)[0]['is_child'] : false;
      this.setState({isChild: isChildSegment})
    });
  }

  getJobs = jobKey => id => {
    checkJobsCache(id, jobKey).then(jobStatus => {
      const promises = Object.keys(jobStatus)
        .filter(key => {
          const started = jobStatus[key] == "started";
          const queued = jobStatus[key] == "queued";
          const untracked = jobStatus[key] == "unknown";
          return started || queued || untracked;
        })
        .map(key => checkJob(JSON.parse(key), id, jobKey));

      if (promises.length > 0)
        Promise.all(promises).then(() => {
          setTimeout(() => this.getJobs(jobKey)(id), 5000);
        });

      this.setState({ [jobKey]: jobStatus });
    });
  };

  getOttType = () => {
    getOttOffset()
      .then(({ batch_offset, served_offset, type }) => {
          this.setState({ottType: type})
      })
      .catch(e => {
          console.log("Failed to load ott record, this client doesn't have ott setup")
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { numDays } = this.state;
    if (numDays != prevState.numDays) {
      this.getData(numDays);
    }
  }

  getData = numDays => {
    const startDate = moment.utc().utcOffset(-5)
      .subtract(numDays, "days")
      .format("YYYY-MM-DD");
    const endDate = moment.utc().utcOffset(-5)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    const dateRange = buildDates(startDate, endDate);
    const [conversions, spend, revenue] = [0, 0, 0];
    const defaults = dateRange.map(date =>
      Object.assign({}, { date, conversions, spend, revenue })
    );

    this.setState({ loading: true });
    const { id } = this.props.match.params;
    getConversionSegmentReportingData(id, startDate, endDate)
      .then(resp => {
        const d = d3
          .nest()
          .key(r => r.date)
          .rollup(vs => {
            return {
              conversions: Math.round(d3.sum(vs, x => x.even) * 10000) / 10000,
              spend: Math.round(d3.sum(vs, x => x.spend) * 10000) / 10000,
              revenue:
                Math.round(d3.sum(vs, x => x.revenue_even) * 10000) / 10000
            };
          })
          .entries(resp.flat().concat(defaults))
          .map(row => Object.assign({ date: row.key }, row, row.values))
          .sort((p, c) => d3.ascending(p.key, c.key));

        const data = [{ values: d }];

        this.setState({
          loading: false,
          data: data
        });
      })
      .catch(e => {
        this.setState({
          loading: false,
          data: false
        });
      });
  };

  handlePlotVariable = plotVariable => () => {
      this.setState({ plotVariable });
  };

  handlePastDaysChange = (e, { value }) => this.setState({ numDays: value });

  runPipelineBackfill = item => {
    const { username, match: { params: { id: filter_id }}} = this.props;

    submitPipelineAgg(Object.assign({ filter_id, username }, item)).then(x => {
      trackJob(item, filter_id, "pipelineAggStatus", x.job_id);
      this.getJobs("pipelineAggStatus")(filter_id);
      const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${x.job_id}`;
      this.setState({jobUrl})
    });
  };

  runEventsBackfill = item => {
    const { username, match: { params: { id: filter_id }}} = this.props;
    const { isChild } = this.state;
    const body = Object.assign({ filter_id, username }, item);
    if (isChild) {
      body['child'] = true;
    }

    submitEventsAgg(body).then(x => {
      trackJob(item, filter_id, "eventsAggStatus", x.job_id);
      this.getJobs("eventsAggStatus")(filter_id);
      const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${x.job_id}`;
      this.setState({jobUrl})
    });
  };

  runOttBackfill = item => {
    const { username, match: { params: { id: filter_id }}} = this.props;
    const {ottType} = this.state;
    if (!ottType || !ottType.length) {
      return
    }
    submitOttAgg(Object.assign({ filter_id, username, type:  ottType }, item))
      .then(x => {
        trackJob(item, filter_id, "ottAggStatus", x.job_id);
        this.getJobs("ottAggStatus")(filter_id);
        const jobUrl = `http://wq.internal.getrockerbox.com/v2/workqueue_status/job/${x.job_id}`;
        this.setState({jobUrl})
      })
  }

  toggleModal = () => {
    const { modalOpen } = this.state;
    modalOpen ? this.setState({modalOpen: false}) : this.setState({modalOpen: true});
  }

  handleDropdownChange = (val) => {
    this.setState({rerunType: val})
  }

  handleDateChange = (val, slice) => {
    this.setState({[slice]: val});
  }

  handleSubmit = () => {
    const Moment = extendMoment(moment);
    const { startDate, endDate, rerunType, data, ottType } = this.state;
    const range = Moment.range(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const typeMap = {
      pipeline: this.runPipelineBackfill,
      compile: this.runEventsBackfill,
      ott: this.runOttBackfill
    }
    const send = typeMap[rerunType] || this.runEventsBackfill;

    if(rerunType == "pipeline" || rerunType == "compile" || rerunType == "ott") {
      const selected = data[0].values.filter(c => {
        return range.contains(moment(c.date))
      });

      const promises = selected.map(c => send(c));

      Promise.all(promises).then(response => {
        this.setState({rerunTypeError: "", rerunType: ""})
        this.toggleModal();
      }).catch(e=>console.log(e));
    } else {
      this.setState({rerunTypeError: "Select a range type"})
    }


  }

  render() {
    const { pipelineAggStatus, eventsAggStatus, ottAggStatus, ottType, isChild } = this.state;
    const { plotVariable, showData, activeAccount, data, loading, numDays, modalOpen, rerunType, startDate, endDate, jobUrl, rerunTypeError } = this.state;
    const { id } = this.props.match.params;

    const aggregationButtons = [
      {
        display: "",
        key: "run_events",
        as: BackfillCell("Rerun Pipeline", this.runPipelineBackfill, pipelineAggStatus, id)
      },
      {
        display: "",
        key: "run_spend",
        as: BackfillCell("Rerun Compile", this.runEventsBackfill, eventsAggStatus, id)
      }
    ];
    const dropdownOptions = [{text: "Rerun Pipeline", value: "pipeline"}, {text: "Rerun Compile", value: "compile"}]

    if (ottType && ottType.length) {
      aggregationButtons.push(
        {
          display: "",
          key: "run_ott",
          as: BackfillCell("Rerun Ott", this.runOttBackfill, ottAggStatus, id)
        }
      )
      dropdownOptions.push(
        {text: "Rerun Ott", value: "ott"}
      )
    }

    if (isChild) {
      aggregationButtons.shift()
      dropdownOptions.shift()
    }

    return (
      <React.Fragment>
        <ContentCard
          title={
            <React.Fragment>
              <Link to={routes.conversionEventIndex}>Conversion</Link> ›{" "}
              {id.charAt(0).toUpperCase() + id.slice(1)}
            </React.Fragment>
          }
          topRight={
            <Dropdown
              onChange={this.handlePastDaysChange}
              options={dateOptions}
              value={numDays}
              style={{ border: "none" }}
            />
          }
          noContent
        />
        <ColumnLayout
          leftWidth={3}
          centerWidth={10}
          rightWidth={3}
          leftContent={<React.Fragment></React.Fragment>}
          rightContent={
            data &&
            data.map(d => (
              <ViewStats
                plotVariable={plotVariable}
                data={d}
                handlePlotVariable={this.handlePlotVariable}
              />
            ))
          }
        >
          <React.Fragment>
            {data &&
              data.map(d => (
                <ContentCard title={`Conversions`}>
                  <SummaryBar
                    exclude={[]}
                    data={d.values.map(row => {
                      const rowData = {
                        date: row.date,
                        [plotVariable]: row[plotVariable]
                      };
                      return rowData;
                    })}
                  />
                    <Button content="Rerun Range" floated="right" style={{marginBottom: 10}} onClick={this.toggleModal}/>
                    <IndexGrid
                      data={d.values}
                      cols={["date", "conversions", "revenue", "spend"]
                        .map(col => {
                          return { display: col, key: col };
                        })
                        .concat(aggregationButtons)}
                      as={ContentCard.Table}
                      fallbackMsg="No Data Sources"
                    />
                </ContentCard>
              ))}
            {loading && <ContentCard loading />}
            {data === false && (
              <ContentCard>
                <table style={{ textAlign: "center", width: "100%" }}>
                  <IndexGrid.EmptyMessage fallbackMsg="No spend data available. If you just authorized, please allow 24 hours for data to be synced." />
                </table>
              </ContentCard>
            )}
          </React.Fragment>
        </ColumnLayout>
        <Modal
          open={modalOpen}
          onClose={this.toggleModal}
          size="small"
        >
          <Modal.Header>Rerun Range</Modal.Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Field>
                  <label>Start Date</label>
                  <DatePicker
                    customInput={<Input icon='calendar alternate'/>}
                    selected={startDate}
                    onChange={(e) => this.handleDateChange(e, "startDate")}
                    maxDate={moment.utc().utcOffset(-5).subtract(1, 'day')}
                  />
                </Form.Field>
                <Form.Field>
                  <label>End Date</label>
                  <DatePicker
                    customInput={<Input icon='calendar alternate'/>}
                    selected={endDate}
                    onChange={(e) => this.handleDateChange(e, "endDate")}
                    maxDate={moment.utc().utcOffset(-5).subtract(1, 'day')}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Dropdown value={rerunType} options={dropdownOptions} label="Type:" onChange={(e,d) => this.handleDropdownChange(d.value)} placeholder="Select rerun type" clearable required/>
              <div style={{color: 'red', marginBottom: 10}}>{rerunTypeError}</div>
              <Button primary type="submit" content="Submit"/>
            </Form>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const { username } = state.app;

  return { username };
}

export default connect(mapStateToProps)(withRouter(ViewConversionEvent));
