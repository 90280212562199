export const cleanData = (table, hasNTF, hasModel, hasSpend) => {
  table = table.impute({ "even": () => 0 })
  table = table.impute({ "first_touch": () => 0 })
  table = table.impute({ "last_touch": () => 0 })
  table = table.impute({ "revenue_even": () => 0 })
  table = table.impute({ "revenue_first_touch": () => 0 })
  table = table.impute({ "revenue_last_touch": () => 0 })

  if (hasSpend) {
    table = table.impute({ "spend": () => 0 })
  }

  if (hasNTF) {
    table = table.impute({ "ntf_even": () => 0 })
    table = table.impute({ "ntf_first_touch": () => 0 })
    table = table.impute({ "ntf_last_touch": () => 0 })
    table = table.impute({ "ntf_revenue_even": () => 0 })
    table = table.impute({ "ntf_revenue_first_touch": () => 0 })
    table = table.impute({ "ntf_revenue_last_touch": () => 0 })
  }

  if (hasModel) {
    table = table.impute({ "normalized": () => 0 })
    table = table.impute({ "revenue_normalized": () => 0 })
  }

  if (hasNTF && hasModel) {
    table = table.impute({ "ntf_normalized": () => 0 })
    table = table.impute({ "ntf_revenue_normalized": () => 0 })
  }

  return table
}
