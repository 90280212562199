import React, { useState, useEffect } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Input, Button, Message } from 'semantic-ui-react';
import { getDomains, postDomains } from '../../api/account'

const AllowedDomains = ({ }) => {
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [domains, setDomains] = useState([]);

  const cols = [{ display: 'Domains', key: 'domain' }];

  const updateDomains = () => {
    getDomains().then(data => setDomains(data));
  }
  
  useEffect(() => updateDomains(), [])
  
  const handleChange = (event, { value }) => setDomain(value)
  
  const handleSubmit = () => {
    const postObj = { domain: domain.trim() };
    setLoading(true);
    postDomains(postObj)
      .then(response => {
        setLoading(false);
        setDomain('');
        setError(false);
        updateDomains();
      })
      .catch(error => {
        setError(true);
        setLoading(false)
      })

  }

  return (
    <ContentCard title="Allowed Domains">
      <div style={{marginBottom: 14}}>
        <Input
          placeholder="yourDomain.com"
          value={domain}
          action
          onChange={handleChange}
          style={{width: 300}}
        >
          <input />
          <Button
            primary
            type="submit"
            onClick={handleSubmit}
            disabled={domain === ''}
            loading={loading}
          >Add</Button>
        </Input>
        { error && (
          <Message negative>
            <p>This domain may have already been added</p>
          </Message>
        )}
      </div>
      <PagedIndexGrid data={domains} cols={cols} itemsPerPage={10} collapsed/>
    </ContentCard>
  )
}

export default AllowedDomains;
