import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { Card, Button, Modal } from 'semantic-ui-react';
import moment from 'moment';

import { BasicViewHeader, FilterWrapper, DateRangeHooks } from '../../components';
import { IntegrationCard } from '../AdvertisingPlatforms/PlatformConnectGallery';
import { track } from '../../../utils/tracking';
import { CacheContext } from '../../../utils/CacheContext';
import { getSegments } from '../../api/attribution';
import { getAuthorizations, getAllIntegrations } from '../../api/account';
import { yesterday, monthAgo } from '../../utils/time';
import { ConversionSummaryLoader } from './loaders';

import ConversionSummary from './ConversionSummary';

const ModalContent = ({ integrations, authorizations }) => {
  if (!integrations.length) return null;
  return (
    <>
      <Modal.Header>Select Platform</Modal.Header>
      <Modal.Content>
        <Card.Group itemsPerRow={2} style={{ paddingBottom: 0 }}>
          {
            integrations.map(integration => (
              <IntegrationCard key={integration.platform} {...{ integration, authorizations }} />
            ))
          }
        </Card.Group>
      </Modal.Content>
    </>
  );
};

const ConversionsOverview = () => {
  const [state, setState, Context] = useContext(CacheContext); // eslint-disable-line
  const segments = Context.getCache('segments', getSegments) || null;
  const [authorizations, setAuthorizations] = useState([]);
  const [allIntegrations, setAllIntegrations] = useState([]);

  const [startDate, setStartDate] = useState(monthAgo);
  const [endDate, setEndDate] = useState(yesterday);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    track('data.customers.conversions.main.view');
    getAllIntegrations().then(setAllIntegrations);
    getAuthorizations().then(setAuthorizations);
  }, []);

  const integrations = useMemo(() => {
    if (!allIntegrations) return [];
    return allIntegrations.filter(p => ['shopify', 'invoca', 'stripe'].includes(p.platform));
  }, [allIntegrations]);

  const [featuredSegment, conversionSegments] = useMemo(() => {
    if (!segments) return [null, null];
    return [
      segments.find(s => !!s.featured),
      segments
        .filter(s => s.action_type === 'conversion' && !s.is_child && !s.featured)
        .sort((a, b) => a.display_order - b.display_order),
    ];
  }, [segments]);

  const onDateChange = useCallback(({ startDate: _startDate, endDate: _endDate }) => {
    const formatStartDate = moment(_startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(_endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  });

  // TODO: handle case of no conversion segments

  return (
    <>
      <BasicViewHeader
        header="Orders &amp; Conversions"
        subheader="See Your Customer Data for All Your Conversions"
        topRight={(
          <Modal
            closeIcon
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onOpen={() => setModalOpen(true)}
            trigger={(
              <div style={{ display: 'flex' }}>
                <Button
                  primary
                  style={{ marginLeft: 'auto', marginRight: '0px' }}
                  content="Connect Account"
                  size="large"
                />
              </div>
            )}
          >
            <ModalContent {...{ authorizations, integrations }}/>
          </Modal>
        )}
      />
      <FilterWrapper
        date={<DateRangeHooks {...{ startDate, endDate, onDateChange }} />}
      />
      <Card.Group>
        { !segments && <ConversionSummaryLoader featured /> }
        {
          !!featuredSegment
          && (
          <ConversionSummary
            featured
            {...{ segment: featuredSegment, startDate, endDate }}
          />
          )
        }
      </Card.Group>

      <Card.Group itemsPerRow={3} style={{ marginTop: 0 }}>
        {
          !segments && (
          <>
            <ConversionSummaryLoader />
            <ConversionSummaryLoader />
          </>
          )
        }
        {!!conversionSegments && conversionSegments.map(segment => (
          <ConversionSummary
            key={segment.action_id}
            {...{ segment, startDate, endDate }}
          />
        ))}
      </Card.Group>
    </>
  );
};

export default ConversionsOverview;
