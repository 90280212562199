
import { useURLStateAndSetDefault } from "../../hooks/urlState"
import { monthAgo, yesterday } from "../../utils/time"
import { customerTypeAtom } from "../../atoms"
import { useAtom } from "jotai"
import { checkUrlParamValue } from '../../utils/dataValidation'

export const useUrlStateWithDefaults = () => {
  const [platformName, setPlatform] = useURLStateAndSetDefault("platformName", "0", true)
  const platform = checkUrlParamValue(platformName) ? platformName : "";
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', monthAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);
  const [segId, setSegId] = useURLStateAndSetDefault('id', 0, true);
  const [ntfFilter, setNtfFilter] = useAtom(customerTypeAtom)

  return {
    platform, platformName, setPlatform,
    startDate, setStartDate,
    endDate, setEndDate,
    segId, setSegId,
    ntfFilter, setNtfFilter,
  }
}
