import React from "react";
import { incrementalityImage } from "@rockerbox/styleguide";
import { Button, Container, Header, Image } from "semantic-ui-react";
import { track } from "../../utils/tracking";

const HEADING = "Experiments";
const SUBHEADING =
  "Compare your marketing efforts against a baseline to see each campaign’s incremental impact";

const ExperimentEmptyView = ({ updatePath }) => {
  return (
    <Container textAlign="center" className="gated-view">
      <Image src={incrementalityImage} size="medium" centered />
      <Header as="h1">
        {
          <>
            Introducing <strong>{HEADING}</strong>
          </>
        }
        <Header.Subheader className="">{SUBHEADING}</Header.Subheader>
      </Header>
      <div>
        {
          <Button
            primary
            content="Create New Experiment"
            onClick={() => {
              track("experiment.create.start");
              updatePath({ view: "create" });
            }}
          />
        }
      </div>
    </Container>
  );
};

export default ExperimentEmptyView;
