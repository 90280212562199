import React, { Component } from 'react';
import * as Styles from './FormBodyStyles';
import Container from '../parts/Container';
import RuleSection from './RuleSection';
import RenameSection from './RenameSection';

import UrlButton from '../parts/UrlButton';
import { Header, Form, Button, Popup, Message } from 'semantic-ui-react';

const FieldRowInput = ({displayName, onChange, value}) => (
  <Styles.Field>
    <label>{ displayName }</label>
    <Form.Input required {...{onChange, value}} />
  </Styles.Field>
)

const FormBody = (props) => {
  const { onSubmit, wrapperParams, addGroup, onFieldChange, validateForTrySubmit, validateRegex, deleteEvent, handlePopupClose, handlePopupOpen } = props;
  const { artifacts, segmentFields, name, order, group, type, cancelUrl, submitText, t1, t2, editing, popupOpen } = props;
  const canTrySubmit = validateForTrySubmit(group);
  const isValidRegex = validateRegex(group);

  return (
    <Form {...{onSubmit}}>
      <Styles.Wrapper {...wrapperParams}>
        <Container column fill>
          <Container row fill>
            <FieldRowInput displayName="Rule Name" value={name} onChange={onFieldChange("name")} />
            <FieldRowInput displayName="Priority" value={order} onChange={onFieldChange("order")} />
          </Container>
          { type == "rename" && <RenameSection {...props} />}
          { type != "" && <RuleSection {...props} />}
          <Styles.ControlButton as="a" onClick={addGroup} withMargin={true}>+ And</Styles.ControlButton>
        </Container>
      </Styles.Wrapper>
      <Container row fill style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
        <div>
          { editing && (
            <Popup
              onClose={handlePopupClose}
              open={popupOpen}
              on='click'
              trigger={<Button negative content="Delete Rule" onClick={handlePopupOpen} />}
              content={
                <Button negative content='Confirm' onClick={deleteEvent} onMouseOut={handlePopupClose} />
              }
            />)
          }
        </div>
        <div>
          {! isValidRegex && (
            <Message negative>
              <Message.Header>Invalid Rule</Message.Header>
              <p>One or more of your rules contains an invalid regular expression. Resolve the error to save.</p>
            </Message>
            )
          }
          <UrlButton url={cancelUrl} color="darkgrey" text="cancel" />
          <Button color='green' type='submit' disabled={!canTrySubmit}>{submitText}</Button>
        </div>
      </Container>
      { props.children }
    </Form>
  )
}
export default FormBody;
