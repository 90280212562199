import React from 'react'
import {
  dataOrdersImage,
  dataMarketingImage,
  dataSpendImage,
  processingDeduplicatingImage,
  processingOrganizingImage,
  processingSpendImage,
  analysisJourneysImage,
  analysisModelsImage,
  analysisReturnImage,
  analysisEffectivenessImage,
  funnelTopImage,
  funnelMiddleImage,
  funnelBottomImage,
} from '@rockerbox/styleguide'

export const DATA_TYPES = [
  {
    id: 'orders',
    image: dataOrdersImage,
    title: "Orders & Customers",
    link: '/v2/settings/datasets/view/Order%20Data/${ordersReport}',
  },
  {
    id: 'marketing',
    image: dataMarketingImage,
    title: "Marketing Activity",
    link: '/v2${hasPixelData ? `/channels/overview/view/${allPages}` : `/settings/datasets/view/Event%20Data/${eventsReport}`}',
  },
  {
    id: 'spend',
    image: dataSpendImage,
    title: "Advertising Spend",
    link: '/v2/settings/datasets/view/Spend%20Data',
  },
]

export const DATA_PROCESSORS = [
  {
    image: processingDeduplicatingImage,
    title: "Deduplicating Conversions",
    link: '/v2/customers/converters/view/customers/${purchase}/${startDate}/${endDate}',
  },
  {
    image: processingOrganizingImage,
    title: "Organizing Marketing",
    link: '/v2/channels/mappings',
  },
  {
    image: processingSpendImage,
    title: "Aggregating Spend",
    link: '/v2/channels/transform/tables/view/all',
  },
]

export const DATA_ANALYSIS = [
  {
    id: 'analysis-journey',
    image: analysisJourneysImage,
    title: "Building Customer Marketing Journeys",
    link: '/v2/customers/path/view/${purchase}/${startDate}/${endDate}',
  },
  {
    id: 'analysis-models',
    image: analysisModelsImage,
    title: "Applying Attribution Models",
    link: '/v2/reporting/conversion/view/${purchase}/${startDate}/${endDate}/report_comparison/last_touch/0/0/tier_1',
  },
  {
    id: 'analysis-spend',
    image: analysisReturnImage,
    title: "Calculating CPA & ROAS",
    link: '/v2/reporting/conversion/view/${purchase}/${startDate}/${endDate}/report/even/1/0/tier_1',
  },
  {
    id: 'analysis-effectiveness',
    image: analysisEffectivenessImage,
    title: "Comparing Marketing Effectiveness",
    link: '/v2/reporting/conversion/view/${purchase}/${startDate}/${endDate}/baseline/even/0/0/tier_1,tier_2,tier_3,tier_4,tier_5',
  },
]

export const FUNNEL_STEPS = [
  {
    id: 'acquisition',
    image: funnelTopImage,
    text: <>Which marketing channels are most effective at bringing <strong>new visitors</strong> to my website?</>,
    link: '/v2/reporting/conversion/view/${hasPixelData ? viewProduct : purchase}/${startDate}/${endDate}/report/first_touch/0/0/tier_1,tier_2,tier_3,tier_4,tier_5',
  },
  {
    id: 'purchase',
    image: funnelMiddleImage,
    text: <>Once someone has come to my site, which channel is best at getting them to <strong>purchase</strong>?</>,
    link: '/v2/reporting/conversion/view/${purchase}/${startDate}/${endDate}/report/last_touch/0/1/tier_1,tier_2,tier_3,tier_4,tier_5',
  },
  {
    id: 'retention',
    image: funnelBottomImage,
    text: <>What marketing is bringing my previous customers back for <strong>repeat purchases</strong>?</>,
    link: '/v2/reporting/conversion/view/${purchase}/${startDate}/${endDate}/report/last_touch/0/2/tier_1,tier_2,tier_3,tier_4,tier_5',
  },
]

export const CONNECTIONS = [
  // orders
  {
    from: {
      id: "rockerbox",
      position: "right",
      color: "#66D9AE",
    },
    to: {
      id: "orders",
      position: "left",
      color: "#66D9AE",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "shopify",
      position: "right",
      color: "#66D9AE",
    },
    to: {
      id: "orders",
      position: "left",
      color: "#66D9AE",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  // marketing activity
  {
    from: {
      id: "rockerbox",
      position: "right",
      color: "#FFA278",
    },
    to: {
      id: "marketing",
      position: "left",
      color: "#FFA278",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "adwords",
      position: "right",
      color: "#FFA278",
    },
    to: {
      id: "marketing",
      position: "left",
      color: "#FFA278",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "facebook",
      position: "right",
      color: "#FFA278",
    },
    to: {
      id: "marketing",
      position: "left",
      color: "#FFA278",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  // advertising spend
  {
    from: {
      id: "adwords",
      position: "right",
      color: "#E76D8E",
    },
    to: {
      id: "spend",
      position: "left",
      color: "#E76D8E",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "facebook",
      position: "right",
      color: "#E76D8E",
    },
    to: {
      id: "spend",
      position: "left",
      color: "#E76D8E",
    },
    width: 7,
    opacity: 25,
    animation: {
      speed: 6.5,
      numDots: 2,
      dotWidth: 7,
      dotOpacity: 25,
    },
  },

  // data -> analysis
  {
    from: {
      id: "orders",
      position: "right",
      color: "#66D9AE",
    },
    to: {
      id: "analysis",
      position: "left",
      color: "#51A2CB",
      offset: { y: -30 }
    },
    width: 10,
    opacity: 25,
    animation: {
      speed: 15,
      numDots: 4,
      dotWidth: 10,
      dotOpacity: 35,
    },
  },
  {
    from: {
      id: "marketing",
      position: "right",
      color: "#FFA278",
    },
    to: {
      id: "analysis",
      position: "left",
      color: "#51A2CB",
    },
    width: 10,
    opacity: 25,
    animation: {
      speed: 15,
      numDots: 4,
      dotWidth: 10,
      dotOpacity: 35,
    },
  },
  {
    from: {
      id: "spend",
      position: "right",
      color: "#E76D8E",
    },
    to: {
      id: "analysis",
      position: "left",
      color: "#51A2CB",
      offset: { y: 30 }
    },
    width: 10,
    opacity: 25,
    animation: {
      speed: 15,
      numDots: 4,
      dotWidth: 10,
      dotOpacity: 35,
    },
  },

  // analysis steps
  {
    from: {
      id: "analysis-journey",
      position: "bottom",
      color: "#cce0eb",
    },
    to: {
      id: "analysis-models",
      position: "top",
      color: "#92C3DA",
    },
    width: 30,
    opacity: 35,
    animation: {
      speed: 3,
      numDots: 1,
      dotWidth: 10,
      dotOpacity: 75,
      fade: true,
    },
  },
  {
    from: {
      id: "analysis-models",
      position: "bottom",
      color: "#cce0eb",
    },
    to: {
      id: "analysis-spend",
      position: "top",
      color: "#92C3DA",
    },
    width: 30,
    opacity: 35,
    animation: {
      speed: 3,
      numDots: 1,
      dotWidth: 10,
      dotOpacity: 75,
      fade: true,
    },
  },
  {
    from: {
      id: "analysis-spend",
      position: "bottom",
      color: "#cce0eb",
    },
    to: {
      id: "analysis-effectiveness",
      position: "top",
      color: "#92C3DA",
    },
    width: 30,
    opacity: 35,
    animation: {
      speed: 3,
      numDots: 1,
      dotWidth: 10,
      dotOpacity: 75,
      fade: true,
    },
  },

  // analysis -> funnel
  {
    from: {
      id: "analysis",
      position: "right",
      color: "#d1e2eb",
    },
    to: {
      id: "funnel",
      position: "left",
      color: "#f3f3f5",
      offset: { x: 63 }
    },
    width: 70,
    opacity: 100,
    animation: {
      speed: 6,
      numDots: 2,
      dotWidth: 20,
      dotOpacity: 10,
      dotColor: "#026cac",
    },
  },
  {
    from: {
      id: "analysis",
      position: "right",
      color: "#d1e2eb",
    },
    to: {
      id: "funnel",
      position: "left",
      color: "#f3f3f5",
      offset: { x: 63, y: -105 }
    },
    width: 70,
    opacity: 100,
  },
  {
    from: {
      id: "analysis",
      position: "right",
      color: "#d1e2eb",
    },
    to: {
      id: "funnel",
      position: "left",
      color: "#f3f3f5",
      offset: { x: 63, y: -70 }
    },
    width: 70,
    opacity: 100,
  },
  {
    from: {
      id: "analysis",
      position: "right",
      color: "#d1e2eb",
    },
    to: {
      id: "funnel",
      position: "left",
      color: "#f3f3f5",
      offset: { x: 63, y: 70 }
    },
    width: 70,
    opacity: 100,
  },
  {
    from: {
      id: "analysis",
      position: "right",
      color: "#d1e2eb",
    },
    to: {
      id: "funnel",
      position: "left",
      color: "#f3f3f5",
      offset: { x: 63, y: 105 }
    },
    width: 70,
    opacity: 100,
  },
]
