import { prettyDisplayNames } from '../helpers'

export const arrayToOptions = (arr) => {
  return arr.map(c => ({ text: c, value: c }))
}

export const keyValObjectToNestedOptionsArray = (obj, platformNameMap) => {
  return Object.entries(obj).map(([text, values]) => {
    const options = arrayToOptions(values).map(c => {
      c.text = prettyDisplayNames(c.text)
      return c
    })
    const formattedText = platformNameMap[text] || prettyDisplayNames(text)

    return { text: formattedText, value: text, options }
  })
}

export const invertKeysAndValues = (obj) => {
  const keys = Object.keys(obj)
  const invertedObj = {}

  keys.forEach(key => {
    const values = obj[key]
    values.forEach(val => {
      const currentKeysAssociatedWithValue = invertedObj[val] || []
      invertedObj[val] = [...currentKeysAssociatedWithValue, key]
    })
  })

  return invertedObj
}

const textNotBlank = (row) => row.text !== ""

export const buildConversionMetricsDropdown = (data, platformNameMap) => {
  if (!data || !data.hasOwnProperty("nested_columns")) return []

  const { nested_columns } = data;
  const attributionCol = invertKeysAndValues(nested_columns);
  const conversionsList = keyValObjectToNestedOptionsArray(attributionCol, platformNameMap)

  return conversionsList.filter(textNotBlank)
}
