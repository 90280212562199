import React, { Component } from 'react';
import { EmailSetup, S3Setup, SftpSetup } from './setupParts';

const InboxSetup = ({ inbox_type, mapper, name, toggleUploadId }) => {

  const Component = inbox_type == 'amazon_ses' ? EmailSetup :
    inbox_type == 'amazon_s3' ? S3Setup :
    inbox_type == 'sftp' ? SftpSetup : () => null

  const properties = { inbox_type, mapper, name, toggleUploadId };
  return <Component {...properties} />
}
export default InboxSetup;
