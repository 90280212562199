import React, { Component } from 'react';
import { Dropdown, Button, Modal, Table } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as routes from '../../../routes';

import Form from './form';

class FacebookAds extends Component {

  state = {
    response: []
  }

  render() {
    const { response } = this.state;
    const { type, id } = this.props.match.params

    const current = response && response.find(row => row.id == id) || {}


    return (
      <React.Fragment>
        { type == "edit" && id && <Form data={current} id={current.filter_id} onSuccess={this.refresh}/>}
        { type == "create" && <Form onSuccess={this.refresh}/>}
      </React.Fragment>
    )
  }
}


export default withRouter(FacebookAds);
