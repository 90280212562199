import React, { Component } from 'react'
import _ from 'lodash'
import Sankey from 'paths-js/sankey'
import tinycolor from 'tinycolor2'
import ColorHash from 'color-hash'
import { Message, Icon, Segment, Grid, Label } from 'semantic-ui-react'

// expects data in the following format
// note: path arrays can be flat or nested
// [
//   {
//     count: 23,
//     path: ['Paid Search', 'Organic Social', 'Email']
//   },
//   {
//     count: 103,
//     path: [['Paid Search'], ['Organic Social'], ['Email']]
//   }
// ]
const dataFormatter = (pathsDataOriginal, numPaths=15, minSteps=2) => {
  let nodes = []
  let links = []
  let legend = []
  let addedPaths = 0

  // deep clone data from props so we don't modify the original
  const pathsData = _.cloneDeep(pathsDataOriginal)

  pathsData.map((pathObj, idx) => {
    if (pathObj.count > 0 && pathObj.path.length >= minSteps && addedPaths < numPaths){
      // add conversion event to the end of each path
      if (pathObj.path[pathObj.path.length-1] !== 'Conversion') pathObj.path.push('Conversion')

      // go through each step in a path
      pathObj.path.map((step, stepNum) => {
        if (!nodes[stepNum]) nodes[stepNum] = []
        const stepName = typeof(step) === 'string' ? step : step[0]

        // add to legend
        if (!legend.includes(stepName)){
          legend.push(stepName)
        }

        // create node if it doesn't exist yet
        if (_.findIndex(nodes[stepNum], ['id', stepName+'-'+stepNum]) < 0){
          nodes[stepNum].push(Object({
            id: stepName+'-'+stepNum,
            display: stepName,
          }))
        }

        // create link between nodes
        if (stepNum > 0){
          const startStep = pathObj.path[stepNum-1]
          const startStepName = typeof(startStep) === 'string' ? startStep : startStep[0]
          links.push(Object({
            start: startStepName+'-'+(stepNum-1),
            end: stepName+'-'+stepNum,
            weight: pathObj.count,
          }))
        }
      })
      addedPaths++
    }
  })

  // reduce links
  const reducedLinks = _.reduce(links, (result, link, idx, arr) => {
    const linkIndex = _.findIndex(result, {'start': link.start, 'end': link.end})
    if (linkIndex < 0){
      result.push(link)
    } else {
      result[linkIndex].weight += link.weight
    }
    return result
  }, [])

  // move conversions to the end
  const converstionStepID = 'Conversion-'+(nodes.length-1)
  const conversionAdjustedLinks = reducedLinks.map(link => {
    link.end = (link.end.includes('Conversion') && (link.end !== converstionStepID)) ? converstionStepID : link.end
    return link
  })
  let conversionAdjustedNodes = nodes.map(nodeStep =>
    nodeStep.map(node =>
      node.id.includes('Conversion') && node.id !== converstionStepID ? null : node
    )
  )
  conversionAdjustedNodes = conversionAdjustedNodes.map(nodeStep =>
    _.remove(nodeStep, step => step)
  )
  conversionAdjustedNodes = _.remove(conversionAdjustedNodes, nodeStep => nodeStep.length)

  return Object({
    nodes: conversionAdjustedNodes,
    links: conversionAdjustedLinks,
    legend: legend,
  })
}

const opacity = (i, j) => {
  if (j == null){
    return 0.7
  }
  if (j == i){
    return 1
  }
  return 0.3
}

const opacityRect = (item, start, end) => {
  if (start == null){
    return 0.7
  }
  if ((item.id == start) || (item.id == end)){
    return 1
  }
  return 0.3
}

const colorHash = new ColorHash({
  hue: {min: 50, max: 355},
  saturation: 0.55,
  lightness: [0.4, 0.75, 0.5],
})

class SankeyPath extends Component {
  state = {}

  getInitialState = () => {
    return {
      index: null,
      start: null,
      end: null,
    }
  }

  enter = (r) => {
    this.setState({
      index: r.index,
      start: r.item.start,
      end: r.item.end,
    })
  }

  exit = () => {
    this.setState({
      index: null,
      start: null,
      end: null,
    })
  }

  render = () => {
    const { width, height, numPaths, minSteps, data, colors } = this.props
    const formattedData = dataFormatter(data, numPaths, minSteps)

    if (!formattedData.nodes.length || !formattedData.links.length){
      return(
        <Message
          info
          content={
            <React.Fragment>
              <Icon name='info circle' />
              &nbsp;No matching paths found
            </React.Fragment>
          }
          style={{textAlign: 'center'}}
        />
      )
    }

    const sankey = Sankey({
      data: formattedData,
      width: width,
      height: height,
      gutter: 15,
      rectWidth: 50,
      nodeaccessor: (x) => x.id,
    })

    const curvedRectangles = sankey.curvedRectangles.map((r, i) => (
      <g>
        <path
          d={r.curve.path.print()}
          fill='#acd1e9'
          style={{opacity: opacity(i, this.state.index)}}
          onMouseEnter={this.enter.bind(this, r)}
          onMouseLeave={this.exit}
        />
        {this.state.index === r.index && this.state.start === r.item.start && this.state.end === r.item.end &&
          <text
            x={r.curve.centroid[0]}
            y={r.curve.centroid[1]}
            style={{
              fill: '#000',
              fontWeight: 600,
            }}
            textAnchor='middle'
            alignmentBaseline='middle'
          >
            <tspan
              x={r.curve.centroid[0]}
              y={r.curve.centroid[1]-8}
            >
              {r.item.weight}
            </tspan>
            <tspan
              x={r.curve.centroid[0]}
              y={r.curve.centroid[1]+8}
            >
              {r.item.start.split('-')[0]} → {r.item.end.includes('Conversion') ? '✔' : r.item.end.split('-')[0]}
            </tspan>
          </text>
        }
      </g>
    ))

    const rectangles = sankey.rectangles.map((r) => {
      const opacity = opacityRect(r.item, this.state.start, this.state.end)
      const x = r.curve.centroid[0]
      const y = r.curve.centroid[1]

      const providedColor = !!colors && colors[r.item.display]
      const tierColor = r.item.display === 'Conversion' ? '#00BB5C' : providedColor || colorHash.hex(r.item.display)

      const totalRectValue = _.reduce(formattedData.links, (sum, link) =>
        sum += link[r.group < 1 ? 'start' : 'end'] === r.item.id ? link.weight : 0
      , 0)

      const text = (
        <text
          x={x}
          y={y}
          style={{
            opacity: opacity,
            fill: tinycolor(tierColor).getBrightness() > 185 ? '#000' : '#fff',
          }}
          textAnchor='middle'
          alignmentBaseline='middle'
        >
          {r.item.display === 'Conversion' ?
            '✔'
          :
            totalRectValue
          }
        </text>
      )

      return(
        <g>
          <path
            d={ r.curve.path.print() }
            fill={ tierColor }
          />
          {text}
        </g>
      )
    })

    return(
      <div>
        <div>
          {formattedData.legend.filter(b => b !== 'Conversion').map(bucket =>
            <React.Fragment key={'sankey-legend-'+bucket}>
              <BucketsLegend
                name={bucket}
                color={colors[bucket] || colorHash.hex(bucket)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </React.Fragment>
          )}
        </div>
        <br /><br />
        <svg
          width={ width }
          height={ height }
        >
          { curvedRectangles }
          { rectangles }
        </svg>
      </div>
    )
  }
}

const BucketsLegend = ({ name, color }) => (
  <React.Fragment>
    <Label
      circular
      empty
      style={{ background: color }}
    />
    &nbsp;{name}
  </React.Fragment>
)

export default SankeyPath
