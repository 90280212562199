import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { getLTV, getTiers } from '../../utils/api';
import * as routes from '../../routes';
import styled from 'styled-components';
import { Column, Wrapper, Collapsible } from '../TreeParts';
import _ from 'lodash';

import { get } from './helpers';
import SelectCohort from './SelectCohort';
import CohortHeader from './CohortHeader';
import ReportSettings from './ReportSettings';
import MetricsData, { metricSettings } from './metrics';

const EmptyData = () => (
  <table style={{textAlign:"center",width:"100%"}}>
    <IndexGrid.EmptyMessage fallBackMsg="There is no data for this cohort" />
  </table>
)

const onRouter = ["id","how","startCohort","endCohort","tier_1","bucketBy","numPeriods", "tier_group"]

const formatPath = ({ id, how, startCohort, endCohort, tier_1, bucketBy, numPeriods, tier_group }) => {
  return `/${id}/${how}/${startCohort}/${endCohort}/${tier_1}/${bucketBy}/${numPeriods}/${tier_group}`
}

const defaultParams = {
  startCohort:  moment.utc().utcOffset(-5).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
  endCohort: moment.utc().utcOffset(-5).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
  bucketBy: "month",
  numPeriods: 3,
  how: "even",
  tier_1: "-",
  tier_group: "-",
}

const initialState = {
  loading: false,
  selected: "setup",
  margin: ".65",
  paid: true,
  ntf: false,
  tiers: [],
  data: [],
  metrics: metricSettings.map(row => row.title)
}

class CohortLTV extends Component {

  state = _.clone(initialState)

  getTiers() {
    getTiers().then(tiers => this.setState({ tiers }))
  }

  getData(id) {
    const { selectedSegment } = this.props;
    if (id && selectedSegment) {
      const { new_to_file_key } = selectedSegment;
      const { numPeriods, bucketBy, how, tier_1, tier_group, startCohort, endCohort} = this.props.match.params;

      this.setState({ loading: true })
      if (numPeriods && bucketBy && how && tier_1 && tier_group && startCohort && endCohort) {
        getLTV(id, how, numPeriods, bucketBy, tier_1, tier_group, false, new_to_file_key, startCohort, endCohort)
          .then(data => this.setState({ data, loading: false }) )
          .catch(e => this.setState({ data: [], loading: false }))
      }
    }
  }

  pushPath = (path) => {
    const { push } = this.props.history;
    const base = routes.cohortLTV;
    return push(base + path)
  }

  updatePath = (field, value) => {
    const { id, how, startCohort, endCohort, tier_1, bucketBy, numPeriods, tier_group } = this.props.match.params;
    const params = { id, how, startCohort, endCohort, tier_1, bucketBy, numPeriods, tier_group }
    params[field] = value;

    return this.pushPath(formatPath(JSON.parse(JSON.stringify(params))))
  }

  updateField = (field) => (evt, value) => {
    if (onRouter.includes(field) && value) {
      return this.updatePath(field, value.value)
    }
    this.setState({[field] : value.value})
  }

  reset = () => {
    const { tiers } = this.state;
    const { id } = this.props.match.params;
    const toSet = Object.assign({}, defaultParams, { id })
    this.pushPath(formatPath(toSet))
    this.setState(Object.assign(initialState,{tiers}))
  }

  setDefaultParams = () => {

    const { params } = this.props.match;
    const { id } = params;

    if (!id) return;

    if (onRouter.filter(k => !params[k] && params[k] != "").length > 0) {

      const goodParams = Object.keys(params).reduce((p,c) => {
        if (params[c]) p[c] = params[c]
        return p
      },{})

      const toSet = Object.assign({}, defaultParams, goodParams, { id })
      this.pushPath(formatPath(toSet))
      return true
    }

  }

  componentDidMount() {
    this.getTiers()

    const { id, how } = this.props.match.params;

    if (!id) return ;
    if (this.setDefaultParams()) return ;
    if (id) return this.getData(id);

  }

  fixIfNoPaid = ({data, paid}) => {
    if (!paid) return;

    const hasPaid = data.filter(x => {
        return get(get(x.values,'NTF',{}),"cohort",{}).spend > 0
      }).length > 0

    if ((data.length > 0) && !hasPaid) {
      this.setState({paid: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const prevParams = prevProps.match.params;
    const currentParams = this.props.match.params
    const num = onRouter.filter(field => prevParams[field] != currentParams[field]).length

    if (num > 0 || prevProps.selectedSegment != this.props.selectedSegment) {
      this.getData(currentParams.id)
    }

    this.fixIfNoPaid(this.state)
    this.setDefaultParams()


  }

  render() {
    const cleaned = Object.keys(this.props.match.params).reduce((p,c) => {
      if (this.props.match.params[c]) p[c] = this.props.match.params[c];
      return p
    },{})
    const defaults = Object.assign({},defaultParams,cleaned)

    const { id, how, startCohort, endCohort, tier_1, bucketBy, numPeriods, tier_group } = defaults;
    //if (!how) return null;

    const { metrics, margin, ntf, paid, data, tier_2, tiers } = this.state;
    const { updateField, reset } = this;
    const { selectedSegment } = this.props;


    // Interval calcs

    const startCohortMonth = startCohort.split("-").slice(0,2).join("-")
    const mstart = new Date(moment(startCohortMonth+'-01 00:00:00').format("YYYY-MM-DD"))
    const mend = new Date(moment(startCohortMonth+'-01 00:00:00').add(numPeriods, 'months').format("YYYY-MM-DD"))

    const months = bucketBy == "week" ? d3.range(0,numPeriods).map(v => `Week ${v}`): d3.time.scale()
      .domain([mstart, mend])
      .ticks(d3.time.months, 1)
      .map(x => moment(x).format("YYYY-MM"))

    // Tier calcs

    const tier1Options = tiers.map(r => { return {key: r.key, value: r.key, text: r.key} })
    const selectedOptions = tiers.filter(r => r.key == tier_1);
    const _tier2Options = selectedOptions.length ? selectedOptions[0].tier_2: [];

    const tier2Options = _tier2Options.reduce((p,c) => {
      if (p.filter(x => x.key == c.key).length == 0) p.push(c)
      return p
    }, [])


    // Metrics info

    const grouper = !ntf ? data: data.map(tier => {
      const { key, values } = tier
      const modified = Object.keys(values).reduce((p,c) => {
        p[c] = {}
        if (c.indexOf("NTF") > -1) p[c] = values[c]
        return p
      },{})

      return { key, values: modified }
    })
    const rowsToShow = grouper
      .filter(x => { return paid ? get(get(x.values,'NTF',{}),"cohort",{}).spend  > 0 : true })

    const metricsToDisplay = metricSettings.filter(row => this.state.metrics.indexOf(row.title) > -1)
    const multiplier = 1-parseFloat(margin);

    return ( <div>
      <SelectCohort history={this.props.history} match={this.props.match}
        {...{tier_1, tier1Options, how, startCohort, endCohort, selectedSegment}}
        {...{tier_2, tier2Options, numPeriods, bucketBy, paid, margin, ntf, metrics}}
        {...{updateField, reset}}
      />
      <ContentCard centeredTitle title={`Repeat Purchase Analysis`} borderless >
        {
          this.state.loading ?  <ContentCard.CardLoader /> :
          this.state.data.length == 0 ? <EmptyData /> :
            <Wrapper>
              <CohortHeader {...{months, bucketBy}} />
              {
                rowsToShow.map(({key, values}) => (
                  <Collapsible
                    key={key}
                    style={{marginBottom:"10px"}}
                    title={ <Column padded="5" style={{textAlign:"left"}}>{key}</Column> }
                  >
                    { metricsToDisplay.map(row => (<MetricsData {...row} {...{values, key, multiplier, months}} />))}
                  </Collapsible>
                ))
              }
            </Wrapper>
          }
      </ContentCard>
      </div>
    );
  }
}

function mapStateToProps (state, props) {
  return {
    selectedSegment: state.segment.segments.find(x => x.action_id == props.match.params.id) || {}
  }
}

export default connect(mapStateToProps)(withRouter(CohortLTV));
