import React, { Component } from 'react';
import Proptypes from 'prop-types';
import * as identity from '../../identity';
import Container from '../parts/Container';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { Message, Button, Table, Popup, Label, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import css from '../DailyChart/charts.css';
import ButterToast from 'butter-toast';
import { ToastStyles, CloseButtonStyles } from '../Toast';
import styled from 'styled-components';
import * as routes from '../../routes';
import UrlButton from '../parts/UrlButton';
import { getConversionSegments, updateConversion, deleteSegment } from '../../utils/api';
import _ from 'lodash';


const LinkTo = ({ item: { action_id, action_name } }) => (
  <Table.Cell width={6}>
    <Popup inverted content='View Report'
           position='right center' size='small'
           trigger={
            <Link to={routes.conversionData + "/" + action_id}>{action_name}</Link>
           } />
  </Table.Cell>
);

const ConversionKey = ({item, col}) => {
  return (
    <Table.Cell>
      {item.conversion_key_name === 0 ? <Label>Default</Label> : item.conversion_key_name}
    </Table.Cell>
  )
}

const Featured = ({item, col, setFeaturedSegment}) => {
  if(item.featured == 1) {
    return (
      <Table.Cell style={{width: 25}}>
        <Popup trigger={<Icon name="star" color="yellow"/>} content="featured segment"/>
      </Table.Cell>
    )
  } else {
    return (
      <Table.Cell style={{width: 25, cursor: 'pointer'}}>
        <Icon name="star outline" color="grey" onClick={() => setFeaturedSegment(item)}/>
      </Table.Cell>
    )
  }
}


export const defaultCols = [
  { display: "Name", key: "action_name", as: LinkTo},
  { display: "Conversion Key", key: "conversion_key_name", as: ConversionKey},
];

export default class ConversionEvents extends Component {
  state = {
    segments: []
  }

  componentDidMount(){
    this.getSegments();
  }

  getSegments = () => {
    getConversionSegments()
    .then(data => {
      const conversions = data
        .filter(segment => segment.action_type == "conversion")
        .filter(segment => !segment.is_child)


      this.setState({segments: conversions})
    })
  }

  deleteEvent = (id) => {

    return deleteSegment(id).then(() => {
      this.getSegments();

      ButterToast.raise({
        dismissOnClick: true,
        content: ({toastId, dismiss}) => (
          <ToastStyles>
            <CloseButtonStyles>x</CloseButtonStyles>
            Your conversion event was successfully deleted.  So long!
          </ToastStyles>
        ),
        toastTimeout: 5000
      })
    });
  };

  setFeaturedSegment = (segment) => {
    const featuredSegment = _.cloneDeep(segment);
    featuredSegment.featured = 1;
    featuredSegment.advertiser = this.props.advertiser;

    updateConversion(featuredSegment)
      .then(data => {
        this.getSegments()
      })
  }

    render() {
      const { permissions } = this.props;
      const { segments } = this.state;

      const ManageCell = ({item}) => (
        <Table.Cell collapsing disabled={permissions.readOnly}>
          <IndexGrid.EditButton url={routes.editConversionEvent + '/' + item.action_id} />
          <Popup inverted content='View Conversion Event'
            trigger={
              <UrlButton url={routes.viewConversionEvent + '/' + item.action_id} icon="eye" />
            } />
          <IndexGrid.DeleteButton onDelete={() => {
            this.deleteEvent(item.action_id)
          }}/>
        </Table.Cell>
      )

      const attributableCols = [
        { display: "", as: (props) => (<Featured setFeaturedSegment={this.setFeaturedSegment} {...props} />) },
        ...defaultCols,
        { display: "Manage", key: "manage", as: ManageCell }
      ];


      return (<>
        <SplitLayout
          leftWidth={13}
          rightWidth={3}
          leftContent={
            <ContentCard
              title="Conversion Events"
              hasTable
              explainer="A Conversion Event identifies when your customer takes an important action. You can specify one (e.g. Purchased Product) or many (e.g. Request Demo, Created Account, Signed Contract)."
              topRight={
                <>
                  <Button
                    primary
                    compact
                    size="mini"
                    icon="plus"
                    content={'New Conversion'}
                    as={Link}
                    to={`${routes.createConversionEvent}`}
                    disabled={permissions.readOnly}
                  />
                  <Button
                    primary
                    compact
                    size="mini"
                    icon="database"
                    content={'Manage Data'}
                    as={Link}
                    to={`${routes.conversionEventBackfill}`}
                    disabled={permissions.readOnly}
                  />
                </>
              }>

              { !!segments.length ? <IndexGrid data={segments} cols={attributableCols} as={ContentCard.Table} fallbackMsg="No conversions"/> : <ContentCard.CardLoader /> }
            </ContentCard>
          }
          rightContent={
            <Message
              header="About Conversion Segments"
              content={<>
  <p>A conversion segment is a specific action associated with your users that occurs on your property. It is an action against marketing activity will be evaluated.</p>
  <p>A conversion segment is constructed by explicitly passing a piece of tracking information to Rockerbox via an onsite pixel, server-side webhook or batch file that contains information about conversions.</p>
  <p>Examples of conversion events include a customer adding a product to their cart, making a purchase or completing a post purchase survey.</p>
              </>}
            />
          }
        />
      </>
      )
    }
  }

  // ConversionEvents.propTypes = {
  //   editEvent: Proptypes.func.isRequired,
  //   segments: Proptypes.array.isRequired
  // }
