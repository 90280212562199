import React, { useEffect } from 'react'
import { DateRangeHooks, FilterWrapper, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../../components';
import { NTF_OPTIONS } from '../../../constants/options';
import useAuthedIntegrations from '../../../hooks/useAuthedIntegrations';
import { channelPerformancePlatformsConfig } from '../../../constants/mappings';

const Filter = ({ 
  loading, platform, // data
  startDate, endDate, onDateChange, firstReportingDate, // dates
  ntfFilter, setNtfFilter, hasNtf, // ntf
  segOptions, segId, setSegId // segments
}) => {
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions } = platformSelection;

  useEffect(() => {
    if (hasNtf === '') return // return if segId has not loaded yet
    if (!hasNtf) setNtfFilter('all')
  }, [hasNtf])

  return (
    <FilterWrapper
      {...{ loading }}
      other={
        <>
          <StyledDropdown
            label={"Ad Platform"}
            value={platform}
            options={dropdownOptions}
            onChange={onDropdownChange}
          />
          <StyledDropdown
            label="Conversion"
            options={segOptions}
            value={parseInt(segId)}
            onChange={(e, {value}) => setSegId(value)}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf}
            options={NTF_OPTIONS}
            value={ntfFilter}
            onChange={(e, {value}) => setNtfFilter(value)}
          />
        </>
      }
      date={<DateRangeHooks {...{ startDate, endDate, onDateChange, firstReportingDate }} />}
      stickyRight={
        <>
          <SaveReportButton {...{ loading }} />
          <ResetFilterButton />
        </>
      }
    />
  )
}

export default Filter
