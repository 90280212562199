export const TREND_CARDS = ['conversions', 'revenue', 'spend', 'cpa', 'roas', 'rpc']

export const NTF_BASE_COLS = ['even', 'first_touch', 'last_touch', 'normalized', 'revenue_even', 'revenue_first_touch', 'revenue_last_touch', 'revenue_normalized']

export const blankObject = {
  spend:0,
  even:0,
  first_touch:0,
  last_touch:0,
  normalized:0,
  revenue_even:0,
  revenue_first_touch:0,
  revenue_last_touch:0,
  revenue_normalized:0,
  ntf_even:0,
  ntf_first_touch:0,
  ntf_last_touch:0,
  ntf_normalized:0,
  ntf_revenue_even:0,
  ntf_revenue_first_touch:0,
  ntf_revenue_last_touch:0,
  ntf_revenue_normalized:0,
  repeat_even:0,
  repeat_first_touch:0,
  repeat_last_touch:0,
  repeat_normalized:0,
  repeat_revenue_even:0,
  repeat_revenue_first_touch:0,                                     
  repeat_revenue_last_touch:0,                                      
  repeat_revenue_normalized:0,                                      
  roas:0,                                                           
  cpa:0,                                                            
  rpc:0,                                                            
}