import React, { useContext } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

import { BucketsPath } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getAccountCurrency, getTierColors } from '../../api/account'

import { buildPath } from '../../hooks/urlState'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../utils/valueFormatter'


export const processConversionData = (segId) => (row) => {
  // new vs. repeat
  if (row.new_to_file !== undefined) {
    const isNewUser = row.new_to_file === 1
    row['customer_type'] = isNewUser ? 'New' : 'Repeat'
  }

  // time to conversion
  const { events, timestamp_conv } = row
  const firstTouch = !!events && !!events.length && events[0]
  if (firstTouch?.timestamp) {
    const firstTouchTime = moment(firstTouch.timestamp * 1000)
    const convTime = moment(timestamp_conv * 1000)
    const duration = moment.duration(convTime.diff(firstTouchTime))
    row['time_to_conversion'] = duration
  }

  // link to user profile view
  const params = {
    id: segId,
    date: row.date,
    convHashId: row.conversion_hash_id,
  }
  const path = buildPath('conversionsUserProfile', params)
  row['path'] = path

  return {...row}
}


export const ViewDetailsCell = ({ item, col }) => {
  // this cache obj is used to store the data for a single conversion
  // so it can be used to display conv info in the conversion user profile view
  const [state, setState, Context] = useContext(CacheContext)
  const key = item.conversion_hash_id
  const onClick = () => setState({ conversionDetails: { [key]: item } })

  return (
    <Table.Cell collapsing selectable style={{ color: '#475ddc', textAlign: 'right' }}>
      <Link to={item[col.key]} {...{ onClick }}>
        &nbsp;&nbsp;{'View Details '}
        <Icon name='angle right' />
      </Link>
    </Table.Cell>
  )
}


export const EmptyCell = (textAlign = 'left') => (
  <Table.Cell collapsing style={{ textAlign }}>{'-'}</Table.Cell>
)

export const DefaultCell = ({ item, col }) => {
  const value = item[col.key]
  if (!value || value == '0' || value == '0.0') return EmptyCell()
  return (
    <Table.Cell className={col.key}>
      {value}
    </Table.Cell>
  )
}

export const NumberCell = ({ item, col }) => (
  <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'left' }}>
    {item[col.key]}
  </Table.Cell>
)

export const CurrencyCell = ({ item, col }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const currency = Context.getCache(`currency`, getAccountCurrency) || null
  const { currency_code } = currency || {}

  const formattedValue = formatCurrency(item[col.key], currency_code)
  const symbol = formattedValue.slice(0, 1)
  const value = formattedValue.slice(1)

  return (
    <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
      <span style={{ float: 'left' }}>{symbol}</span>
      {value}
    </Table.Cell>
  )
}

const PercentangeCell = ({ item, col }) => {
  const percentFormatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const value = item[col.key] > 1 ? item[col.key] / 100 : item[col.key]
  const formattedValue = percentFormatter.format(value)

  return (
    <Table.Cell collapsing style={{ fontFeatureSettings: '"kern" 1, "tnum" 1', textAlign: 'right' }}>
      {formattedValue}
    </Table.Cell>
  )
}

export const DiscountCell = ({ item, col }) => {
  const value = item[col.key]
  if (!value || value == '0' || value == '0.0') return EmptyCell('right')
  if (item.discount_type === 'percentage') return PercentangeCell({ item, col })
  if (item.discount_type === 'fixed_amount') return CurrencyCell({ item, col })
  return NumberCell({ item, col })
}

export const DateCell = ({ item, col }) => {
  const time = moment(item[col.key]).format(`MMM D, YYYY`)

  return (
    <Table.Cell>{`${time} (UTC)`}</Table.Cell>
  )
}

export const TimestampCell = ({ item, col }) => {
  const time = moment(item[col.key] * 1000).format(`MMM D, YYYY h:mm a`)
  const timezone = momentTimezone.tz.guess()
  const zoneAbbr = momentTimezone.tz.zone(timezone).abbr(item[col.key]*1000)

  return (
    <Table.Cell>{`${time} (${zoneAbbr})`}</Table.Cell>
  )
}

export const TimerangeCell = ({ item, col }) => {
  const duration = item[col.key].humanize()

  return (
    <Table.Cell>{duration}</Table.Cell>
  )
}

export const PathCell = ({ item, col }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const tierColors = Context.getCache(`tierColors`, getTierColors) || null

  const newArr = []
  item[col.key].map(path => {
    newArr.push({buckets: [path]})
  })

  return (
    <Table.Cell>
      <BucketsPath
        steps={newArr}
        colorMap={tierColors}
        count="200"
        condensed
      />
    </Table.Cell>
  )
}
