import React, { useState, useEffect, useMemo } from 'react'
import { Menu, Segment, Grid, Form, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

import { roundNumber, findClosestPoint, calcPercentDiff } from '../helpers'
import ChannelOptimizationLoader from './loaders'
import ChannelOptimizationRecommendation from './recommendation'
import ChannelOptimizationChart from './chart'


const ChannelOptimization = ({ channels, features, responseCurves, featureStats, modelDate }) => {
  const [selectedChannel, setSelectedChannel] = useState()
  const [selectedTactic, setSelectedTactic] = useState()
  const [targetRoas, setTargetRoas] = useState(1.5)
  const [chartType, setChartType] = useState('revenue')

  // default to first channel
  useEffect(() => {
    if (!channels?.length) return
    setSelectedChannel(channels[0])
  }, [channels])

  // filter tactics by selected channel
  const availableTactics = useMemo(() => {
    if (!features?.length || !selectedChannel) return []
    return features.filter(f => f.channel === selectedChannel)
  }, [features, selectedChannel])

  // default to first tactic
  useEffect(() => {
    if (!availableTactics?.length) return
    setSelectedTactic(availableTactics[0].tactic)
  }, [availableTactics])

  // get model feature from selected channel and tactic
  const selectedFeature = useMemo(() => {
    if (!features?.length || !selectedChannel || !selectedTactic) return
    return features.find(f => f.channel === selectedChannel && f.tactic === selectedTactic)
  }, [features, selectedChannel, selectedTactic])

  // response curve for selected feature
  const responseCurve = useMemo(() => {
    if (!responseCurves || !selectedFeature) return
    return responseCurves[selectedFeature.value]
  }, [responseCurves, selectedFeature])

  // min and max roas for slider
  const {
    minRoas,
    maxRoas,
    currentSpend,
    recommendedSpend,
    recommendedRevenue,
    recommendedRoas,
  } = useMemo(() => {
    if (!featureStats || !selectedFeature) return {}
    return featureStats[selectedFeature.value] || {}
  }, [featureStats, selectedFeature])

  useEffect(() => {
    if (!recommendedRoas) return
    setTargetRoas(recommendedRoas || 1)
  }, [recommendedRoas])

  // calculate target spend based on target roas
  const [targetSpend, predictedRevenue] = useMemo(() => {
    if (!responseCurve?.length) return [0, 0]
    if (targetRoas == recommendedRoas) return [recommendedSpend, recommendedRevenue]
    // find closest point on response curve to target roas
    const closestRoas = findClosestPoint(responseCurve, 'predicted_roas', targetRoas)
    const targetPoint = responseCurve.find(({ predicted_roas }) => predicted_roas === closestRoas)
    return [
      roundNumber(targetPoint?.spend_input, 1) || 0.1,
      roundNumber(targetPoint?.marginal_response, 1) || 0
    ]
  }, [responseCurve, targetRoas])

  // calculate expected revenue for current spend
  const currentRevenue = useMemo(() => {
    if (!currentSpend) return
    return responseCurve.find((x) => x.spend_input === currentSpend)?.marginal_response
  }, [responseCurve, currentSpend])

  // calculate if target spend is more than 50% diff from current spend
  const showWarning = useMemo(() => {
    if (!currentSpend || !targetSpend) return false
    const optimalToCurrentDiff = calcPercentDiff(targetSpend, currentSpend)
    return optimalToCurrentDiff > 0.50
  }, [currentSpend, targetSpend])

  const changeRoas = (value) => {
    if (value < minRoas) value = minRoas
    if (value > maxRoas) value = maxRoas
    setTargetRoas(value)
  }

  if (!channels?.length || !responseCurve?.length) return <ChannelOptimizationLoader />

  return (<>
    <Menu
      tabular
      attached='top'
      className='mmmm-channel-menu-overrides'
    >
      {channels.map(channel => (
        <Menu.Item
          key={channel}
          name={channel}
          content={channel}
          active={selectedChannel === channel}
          onClick={(e, { name }) => setSelectedChannel(name)}
        />
      ))}
    </Menu>
    <Segment attached='bottom' style={{ height: 440, border: '1px solid #e7eafc' }}>
      <Grid style={{ padding: 10 }}>
        <Grid.Row>
          <Grid.Column width={6}>
            {availableTactics?.length > 1 &&
              <Menu pointing secondary>
                {availableTactics.map(x => (
                  <Menu.Item
                    key={x.tactic}
                    name={x.tactic}
                    content={x.tactic}
                    active={selectedTactic === x.tactic}
                    onClick={(e, { name }) => setSelectedTactic(name)}
                  />
                ))}
              </Menu>
            }
            <ChannelOptimizationRecommendation {...{ selectedChannel, selectedTactic, targetRoas, targetSpend, predictedRevenue, currentSpend, currentRevenue, modelDate }} />
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form>
                    <Form.Group inline>
                      <Form.Input
                        type='number'
                        step={0.1}
                        label={<label style={{ marginLeft: 60, fontSize: 13, fontWeight: 400, fontFamily: 'Poppins' }}>Target ROAS</label>}
                        value={targetRoas}
                        onChange={(e, { value }) => changeRoas(value)}
                        style={{ width: 75 }}
                      />
                      <input
                        type='range'
                        step={0.1}
                        min={minRoas}
                        max={maxRoas}
                        value={targetRoas}
                        onChange={(e) => changeRoas(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column width={6} textAlign='right'>
                  <Dropdown
                    compact
                    selection
                    options={[
                      {
                        text: 'Predicted Revenue by Spend Level',
                        value: 'revenue',
                      },
                      {
                        text: 'ROAS by Spend Level',
                        value: 'roas',
                      },
                    ]}
                    value={chartType}
                    onChange={(e, { value }) => setChartType(value)}
                    style={{ width: 245 }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{ position: 'relative' }}>
              <ChannelOptimizationChart {...{ chartType, responseCurve, currentSpend, currentRevenue, targetSpend, predictedRevenue }} />
              {showWarning &&
                <div style={{
                  position: 'absolute',
                  right: chartType === 'revenue' ? 5 : 0,
                  bottom: chartType === 'revenue' && 55,
                  top: chartType === 'roas' && 0,
                }}>
                  <div style={{ backgroundColor: '#212a5e', padding: 10, borderRadius: 5, color: '#fff', fontFamily: 'Poppins' }}>
                    <p>We recommend changing your spend incrementally over time.<br />Stick with conservative adjustments and validate your results.</p>
                  </div>
                </div>
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </>)
}

export default ChannelOptimization
