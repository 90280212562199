export function getRemainingDays () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    return time.getDate() > date.getDate() ? time.getDate() - date.getDate() + 1 : 1;
  }
  
export function getNumDaysInMonth () {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  }
  
export function calculatePortionOfMonthRemaining () {
   var remainingDays = getRemainingDays()
   var totalDays = getNumDaysInMonth()
   return remainingDays / totalDays 
  } 
  
export function calculateProratedCost (monthlyPrice) {
    var portionOfMonth = calculatePortionOfMonthRemaining()
    return (portionOfMonth * monthlyPrice).toFixed(2)
  }

export function getMonth () {
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const d = new Date();
const name = month[d.getMonth()];
return name
}

export function getDay () {
const date = new Date().getDate();
return date
}


export function roundToNext1000 (num) {
    if (num < 10000){
      num = 10000
    }
    return Math.ceil(num/1000)*1000;
  }

// TODO: rename
export function getNumUnits (num) {
    if (num < 1000){
      num = 1
    }
    return Math.ceil(num/1000);
  }

export function roundStripeUnits(num) {
    return getNumUnits(roundToNext1000(num))
}

export function getPriceIndex (num) {
    return num/1000-10
  }

var pow = Math.pow, floor = Math.floor, abs = Math.abs, log = Math.log;
var abbrev = 'kmb';

export function round(n, precision) {
    var prec = Math.pow(10, precision);
    return Math.round(n*prec)/prec;
}

export function formatNumber(n) {
    var base = floor(log(abs(n))/log(1000));
    var suffix = abbrev[Math.min(2, base - 1)];
    base = abbrev.indexOf(suffix) + 1;
    return suffix ? round(n/pow(1000,base),2)+suffix : ''+n;
}
  
const MAX_STRIPE_TIER = 1999000

export function monthlyAdSpendToPrice(monthlyAdSpend, pricingData){
    if (monthlyAdSpend <= MAX_STRIPE_TIER) {
        const index = getPriceIndex(roundToNext1000(monthlyAdSpend))
        return pricingData[index]['price'].toFixed(2)
    } else { //handles case where spend is > than the max tier in stripe
        const index = getPriceIndex(roundToNext1000(MAX_STRIPE_TIER))
        const costUpToMax = pricingData[index]['price']
        const spendUpToMax = pricingData[index]['Monthly Ad Spend']
        const remainingSpend = monthlyAdSpend - spendUpToMax
        const remainingCostRate = pricingData[index]['price'] - pricingData[index-1]['price']
        const remainingUnits = (getNumUnits(remainingSpend))
        const remainingCost = remainingUnits * remainingCostRate
        const totalcost = (costUpToMax + remainingCost).toFixed(2)
        return totalcost
    }
}

export function monthlyAdSpendToCostPer1k(monthlyAdSpend, pricingData){
    if (monthlyAdSpend <= MAX_STRIPE_TIER) {
        const index = getPriceIndex(roundToNext1000(monthlyAdSpend))
        return pricingData[index]['Cost per $1K in Ad Spend']
    } else { //handles case where spend is > than the max tier in stripe
        const index = getPriceIndex(roundToNext1000(MAX_STRIPE_TIER))
        return pricingData[index]['Cost per $1K in Ad Spend']
    }
}

export function setToFixed (number) {
  return Number(number).toFixed(2).toLocaleString()
}

export function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDollarValue(number){
  const fixedDecimals = setToFixed(number)
  return addCommas(fixedDecimals)
}

export function getOriginalPrice (number, magnitude, type) {
  if (type == "fixed_amount"){
    return Number(number) + magnitude
  } else if (type == "percentage") {
    return (number / (1 - (magnitude / 100)))
  }
}

export function applyDiscount(original, promoPercent, promoValue, oneHundredPercentOff) {
  const prices = {"original":0, "discounted": 0}
  if (oneHundredPercentOff){
    prices["original"] = formatDollarValue(original)
    prices["discounted"] = formatDollarValue(0)
  }
  else if (promoPercent){
    prices["original"] = formatDollarValue(getOriginalPrice(original, promoPercent, "percentage"))
    prices["discounted"] = formatDollarValue(original)
  } else if (promoValue) {
    prices["original"] = formatDollarValue(getOriginalPrice(original, promoValue, "fixed_amount"))
    prices["discounted"] = formatDollarValue(original)
  } else {
    prices["original"] = formatDollarValue(original)
    prices["discounted"] = formatDollarValue(original)
  }
  return prices
}

export function handleDiscount(activeSubscription, pricingResponse, invoiceResponse, setEstimated, setProrated, setOneHundredPercentOff) {
  const discountObj = {}
  if (activeSubscription['discount']){
    if (activeSubscription['discount']['coupon']['amount_off']){
      discountObj['estimated'] = monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response']) - (activeSubscription['discount']['coupon']['amount_off'] / 100)
      discountObj['prorated'] = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response'])- (activeSubscription['discount']['coupon']['amount_off']) / 100)
    } else {
      if(activeSubscription['discount']['coupon']['percent_off'] == 100){
        setOneHundredPercentOff(true)
      }
      discountObj['estimated'] = monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response']) * (1 - (activeSubscription['discount']['coupon']['percent_off'] / 100))
      discountObj['prorated'] = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response'])* (1 - (activeSubscription['discount']['coupon']['percent_off'] / 100)))
    }
  } else if (invoiceResponse['discount']) {
    if (invoiceResponse['discount']['coupon']['amount_off']) {
      discountObj['estimated'] =  monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response'])
      discountObj['prorated'] = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response']) - (invoiceResponse['discount']['coupon']['amount_off']) / 100)
    } else { 
      if(invoiceResponse['discount']['coupon']['percent_off'] == 100){
        setOneHundredPercentOff(true)
      }
      discountObj['estimated'] =  monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response'])
      discountObj['prorated'] = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response']) * (1 - invoiceResponse['discount']['coupon']['percent_off'] / 100))
    }
  } else {
    discountObj['estimated'] = monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response'])
    discountObj['prorated'] = calculateProratedCost(monthlyAdSpendToPrice(activeSubscription['quantity']*1000, pricingResponse['response']))
  }
  setEstimated(discountObj['estimated'])
  setProrated(discountObj['prorated'])
}
