import React, { Component } from 'react';
import { Segment, Form, Message, Popup, Icon, Button } from 'semantic-ui-react';
import { ColumnLayout, DataTable } from '@rockerbox/styleguide';
import DateRangeByType from '../DatePicker/DateRangeByType';

const ButtonSmallBlue = (props) => <Button {...props} inline color="blue" size="small" />

const BulkSpendByDate = (props) => {

  const { data, rangeType, columns, issues } = props
  const { addRow, onUpdate, onRemove, updateAllNames, onRemoveAndCopy } = props
  const [startDate, setStartDate] = React.useState(false)
  const [endDate, setEndDate] = React.useState(false)
  const [filterType, setFilterType] = React.useState(false)

  const hasIssues = !(issues && issues.length)

  const onDateUpdate = (_, { startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const onDataUpdate = (data) => {
    if (startDate && endDate) {
      data.map(row => Object.assign(row, {startDate, endDate} ))
      onUpdate(data)
    }
  }

  const dataTableProps = { columns: columns.filter(c => ["state","entity_name","cost"].includes(c.key)), data: data }
  const dataTableFuncs = { onUpdate: onDataUpdate, onRemove , rowProps: { updateAllNames } }

  const filteredData = React.useMemo(() => {
    if (filterType == "not approved") return data.filter(row => row.state != "Approved")
    if (filterType == "approved") return data.filter(row => row.state == "Approved")
    return data
  }, [filterType, data])

  React.useEffect(() => {
    if (filteredData.length == 0) setFilterType(false)
  }, [filteredData])

  return <div>
    <Segment>
      <h5>Choose the {rangeType} against which you want to add spend for multiple sponsorships</h5>
      <DateRangeByType {...{rangeType, onChange: onDateUpdate}} />
    </Segment>
    { startDate && endDate && <Segment>
      <h5>Add the Sponsorship name and the cost you want to assign for the above date</h5>
      <DataTable {...dataTableProps} {...dataTableFuncs} />
      <br />
      <Button size='mini' icon="plus" onClick={addRow}/> 
      <br />
    </Segment>
    }
    { issues.length > 0 && <Segment>
        <Message 
          content={<>
            <Icon name="warning" color="orange" />
            Issues have been detected with the campaigns entered above. You must resolve all issues before uploading.
          </>} 
          negative 
        />

        <div>
          <Button.Group size="small">
            <Button active={filterType == "not approved"} onClick={() => setFilterType("not approved")}>Show Issues ({ issues.length })</Button>
          </Button.Group>

          &nbsp; &nbsp; &nbsp;
          { filterType == "not approved" && 
            <>
              <ButtonSmallBlue disabled={hasIssues} onClick={() => onRemoveAndCopy(false)} > Remove issues </ButtonSmallBlue>
              <ButtonSmallBlue disabled={hasIssues} onClick={() => onRemoveAndCopy(true)} > Remove + copy </ButtonSmallBlue>
              <ButtonSmallBlue disabled={hasIssues} onClick={() => onRemoveAndCopy(true, "Missing Entity")} > Remove missing entities + copy </ButtonSmallBlue>
            </>
          }
          <br />
          <br />
          {filterType == "not approved" && <DataTable {...{ columns: columns.filter(c => ["state","entity_name","cost"].includes(c.key)), data: filteredData, onUpdate, onRemove, rowProps: { updateAllNames } } } /> }
        </div>
      </Segment>
    }
  </div>
}

export default BulkSpendByDate;
