export const PLATFORMS = {
  facebook: {
    color: '#479BF5',
  },
  snapchat: {
    color: '#F5F33D',
  },
  bing: {
    color: '#00B8A1',
  },
  tiktok: {
    color: '#42F0EB',
  },
  linkedin: {
    color: '#0A94C2',
  },
  pinterest: {
    color: '#E61939',
  },
  adwords: {
    color: '#12D975',
  },
  // "amazon_selling_partner_api": {
  //   "color": "#12D505"
  // }
};

export const WARNING_PLATFORMS = ['facebook', 'tiktok'];
