import React, { useMemo } from 'react';
import { incrementalityImage } from '@rockerbox/styleguide';
import { useGlobalState } from '../../hooks/global';
import { BasicViewHeader, UpgradePromo } from '../../components';
import Steps from './Steps';

const Surveys = () => {
  const { account } = useGlobalState();

  const hasAccess = useMemo(() => {
    if (!account?.features) return false;
    return account?.features?.includes('surveys');
  }, [account]);

  if (hasAccess === false) {
    return (
      <UpgradePromo
        trackItem="surveys.upgrade_contact"
        chatMessage="Hi! I'm interested in upgrading to access Surveys."
        imageSrc={incrementalityImage}
        imageSize="medium"
        content="Get Started with Surveys"
        subheader="Use post-purchase surveys to uncover the marketing channels that drive customers to your brand"
      />
    );
  }

  return (
    <>
      <BasicViewHeader
        header="Surveys"
        subheader="Use post-purchase surveys to uncover the marketing channels that drive customers to your brand"
      />
      <Steps />
    </>
  );
};

export default Surveys;
