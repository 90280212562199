import React, { Component } from 'react';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { checkJobSubmissionDate, trackJobV2 } from "../../utils/job_api";
import { getConversionSegments, submitPipelineAgg, submitEventsAgg } from "../../utils/api";
import { Form } from 'semantic-ui-react'

const BackfillSubmission = (props) => {

  const username =  document.cookie.split("user_name=\"")[1].split("\";")[0];
  const { filteredData, datasetType } = props

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [numSubmitted, setNumSubmttted] = React.useState(0)
  const [skipped, setSkipped] = React.useState(0)

  const stateRef = React.useRef();
  stateRef.current= skipped;

  const runPipelineBackfill = item => {
    const { action_id, date } = item;
    const body = Object.assign({ filter_id: action_id, date, username });

    return new Promise((resolve, rej) => {

      checkJobSubmissionDate(date, action_id, "pipelineAggStatus")
        .then(state => {
          
          const jobStates = Object.values(state)
          const running = jobStates.filter(row => row.planned == 0 || (row.planned > row.success && row.error == 0))
          
          if (jobStates.length && running.length) {
            setSkipped(stateRef.current + 1)
            resolve(false)
          } else {

            submitPipelineAgg(body)
              .then(x => {
                trackJobV2(date, action_id, "pipelineAggStatus", x.job_id)
                resolve(true)
              });
          }
        })
    })

  };

  const runEventsBackfill = item => {
    const { action_id, date } = item;
    const body = Object.assign({ filter_id: action_id, date, username });


    return new Promise((resolve, rej) => {

      checkJobSubmissionDate(date, action_id, "eventsAggStatus")
        .then(state => {
          
          const jobStates = Object.values(state)
          console.log(jobStates)
          if (jobStates.includes("submitted") || jobStates.includes(null)) {
            setSkipped(stateRef.current + 1)
            resolve(false)
          } else {

            submitEventsAgg(body)
              .then(x => {
                trackJobV2(date, action_id, "eventsAggStatus", x.job_id)
                resolve(true)
              });
          }
        })
    })
    //submitEventsAgg(body)
    //  .then(x => trackJob(item, action_id, "eventsAggStatus", x.job_id));
  };

  const handleSubmit = () => {
    const typeMap = {
      conversion_data: runPipelineBackfill,
      compiled_mta_tiers: runEventsBackfill,
    }
    const send = typeMap[datasetType] 

    setIsSubmitting(true)

    const promises = filteredData.map(row => {
      return send(row)
    })

    Promise.all(promises)
      .then(response => {
        const submitted = response.reduce((p,c) => p + c, 0)
        setNumSubmttted(submitted)
        setIsSubmitting(false)
      })
      .catch(e=>console.log(e));

  }

  return <ContentCard title="Resubmission">
    <Form>
      Segments Impacted: { filteredData.length } <br />
      Jobs to rerun: { filteredData.length } <br />
      
      <div style={{textAlign:"center"}}>
        <Form.Button onClick={handleSubmit} disabled={isSubmitting} loading={isSubmitting} >
          Submit { datasetType == "conversion_data" ? "Conversion" : "Marketing"} Backfill
        </Form.Button>
      </div>
      { numSubmitted >0 ? <>{numSubmitted} jobs were submitted<br /></> : "" }
      { skipped >0 ? `Skipped Submission of ${skipped} jobs that were already submitted` : "" }
    </Form>
  </ContentCard>
}

export default BackfillSubmission;
