import React from 'react'
import ContentLoader from 'react-content-loader'
import { Segment } from 'semantic-ui-react'

const MiniChartCardLoader = () => (
  <Segment style={{ overflow: 'hidden', padding: 0 }}>
    <ContentLoader
      viewBox="0 0 265 126"
      preserveAspectRatio="none" 
      foregroundColor="#e5e5e5"
      height={126}
      width={'100%'}
    >
      <path d="M0,82.79c0,0,26.37,27.79,78.73-1.23c39.42-21.84,67.46,16.22,115.36-20.8c30.08-23.25,70.91-7.6,70.91-7.6v72.43L0,126 V82.79z"/>
      <path d="M81.91,52.1H17.1c-2.21,0-4-1.79-4-4V35.94c0-2.21,1.79-4,4-4h64.81c2.21,0,4,1.79,4,4V48.1 C85.91,50.31,84.12,52.1,81.91,52.1z"/>
      <path d="M96.48,26.28H17.1c-2.21,0-4-1.79-4-4v-3.76c0-2.21,1.79-4,4-4h79.39c2.21,0,4,1.79,4,4v3.76 C100.48,24.49,98.69,26.28,96.48,26.28z"/>
    </ContentLoader>
  </Segment>
)

export default MiniChartCardLoader
