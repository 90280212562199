import * as aq from "arquero";
import init, { readParquet } from './parquet';

const path = "/v3/static/js/arrow1_bg.wasm";

let arrowIsInit = false;
init(path).then(resp => {
  arrowIsInit = resp
})

export const arrowAddDate = (dt, date) => {
  const dateDt = aq.table({"date": [...Array(dt.size)].map(_ => date)})
  return dt.assign(dateDt)
}


export const formatDataUrl = (date, dataset, identifier) => {
  let url = ""

  if(dataset == "time_to_conversion") {
    url = `/data-reporting/time-to-conversion/cache?date=${date}&identifier=${identifier}`
  } else {
    url = `/report_cache/data/${dataset}?start_date=${date}&end_date=${date}&identifier=${identifier}&format=raw`
  }

  return url
}

// export const formatDataUrl = (date, dataset, identifier) => {
//   const dataBase = `/data-reporting/spend-mta/cache`
//   const url = `${dataBase}?date=${date}&identifier=${identifier}`

//   return url
// }

export const fetchToParquetBuffer = async (promise) => {
  const resp = await promise;

  const raw_buffer = await resp.arrayBuffer()
  return new Uint8Array(raw_buffer)
}

export const getAsBuffer = async (date, dataset, identifier, currentCacheId) => {
  const url = formatDataUrl(date, dataset, identifier)
  const promise = fetch(url, { credentials: "include" })

  const parquetBuffer = await fetchToParquetBuffer(promise);
  const arrowIPC = await readParquet(parquetBuffer);

  return arrowIPC
}
