export const segmentOptions = (segments) => (
  segments
    .filter(s => s.action_type == 'conversion')
    .map(s => ({
      value: s.filter_id,
      key: s.filter_id,
      text: s.action_name,
      filter_id: s.action_id, 
      first_reporting_date: s.first_reporting_date
    }))
)
