import React, { useContext, useMemo, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Icon, Header } from 'semantic-ui-react'

import { BasicViewHeader, Alert } from '../../components'
import { useTrackingParamAlerts } from '../../hooks/trackingParamAlerts'
import { CacheContext } from '../../../utils/CacheContext'
import { getAllIntegrations } from '../../api/account'
import { track } from '../../../utils/tracking'
import * as routes from '../../baseRoutes'
import ParamInstructions from './ParamInstructions'
import AutoParamsIssues from './AutoParamsIssues'
import MissingParams from './MissingParams'
import ImpressionPixelData from './ImpressionPixelData'
import { capitalize } from './helpers'

const AUTO_PARAMS_PLATFORMS = ['facebook', 'adwords']


const AdvertisingTrackingParams = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const allIntegrations = Context.getCache(`allIntegrations`, getAllIntegrations) || null

  const { platformName } = useParams()
  const trackingParamAlerts = useTrackingParamAlerts()

  const [impressionTracking, setImpressionTracking] = useState(false)

  const platformDisplayName = useMemo(() => {
    if (!allIntegrations) return platformName
    const integrationObj = allIntegrations.find(platformObj => platformObj.platform == platformName) || {}
    return integrationObj?.display_name || platformName
  }, [allIntegrations, platformName])

  const trackingParamIssue = useMemo(() => {
    if (!trackingParamAlerts) return null
    return trackingParamAlerts[platformName]
  }, [trackingParamAlerts, platformName])

  const hasTrackingIssues = useMemo(() => (
    !!trackingParamIssue?.hasIssues
  ), [trackingParamIssue])

  const affectedAdTypes = useMemo(() => {
    if (!trackingParamIssue) return
    return trackingParamIssue.subPlatforms
      .filter(x => x !== 'all')
      .map(capitalize)
      .join(', ')
  }, [trackingParamIssue])

  const banner = useMemo(() => {
    if (!platformName || !trackingParamAlerts) return null
    if (hasTrackingIssues && !!trackingParamIssue.numMissing) return (
      <Alert
        type='warning'
        title={`Manual updates required for ${platformDisplayName} ads`}
        description={`${trackingParamIssue.numMissing} ads could not be updated automatically. Review the table below for instructions to add Rockerbox tracking parameters to the affected ads.`}
        style={{ marginTop: 0 }}
      />)
    if (hasTrackingIssues) return (
      <Alert
        type='warning'
        title={`Tracking issues detected with ${platformDisplayName} ads`}
        description={`${trackingParamIssue.percentMissing}% of clicks we saw from ${affectedAdTypes} ads did not have Rockerbox tracking parameters.`}
        style={{ marginTop: 0 }}
      />)
    if (AUTO_PARAMS_PLATFORMS.includes(platformName)) return (
      <Alert
        type='success'
        title={`Good news! We're automatically adding tracking to your ${platformDisplayName} ads`}
        description={`If you see any issues below, check to make sure all your ad accounts are connected to Rockerbox.`}
        style={{ marginTop: 0 }}
      />)
    return (
      <Alert
        type='info'
        title={`Launching new ${platformDisplayName} ads? Don't forget your tracking parameters!`}
        description={`
          Tracking parameters allow us to accurately attribute clicks to your ads and give you granular CPA and ROAS data.
          Follow the instructions below to ensure you're tracking all your ads.
        `}
        style={{ marginTop: 0 }}
      />)
  }, [platformName, trackingParamAlerts, hasTrackingIssues])

  useEffect(() => {
    if (!platformName) return
    track('data.marketing.advertising_platforms.tracking.view', { platform: platformName })
  }, [platformName])

  return (<>
    <BasicViewHeader
      headerOnTop={false}
      header={`Tracking Parameters for ${platformDisplayName}`}
      subheader={<>
        {'Marketing Data '}
        <Icon name='angle right' style={{ margin: 0 }} />{' '}
        <Link to={routes.advertisingPlatforms}>
          Advertising Platforms
        </Link>
      </>}
    />

    {banner}

    {platformName === 'adwords' &&
      <Alert
        type='info'
        title={`YouTube/Google Video Ads and Express Ads require manual tracking`}
        description={`These campaign types can't be updated by Rockerbox. Follow the instructions below to add tracking parameters before you launch new ads.`}
        style={{ marginTop: 0 }}
      />
    }

    <ParamInstructions platform={platformName} {...{ setImpressionTracking }} />

    {platformName === 'facebook' && <>
      <Header
        as='h2'
        content="Review ads that need manual updates"
        subheader={<div className='sub header'>
          Occasionally, we're unable to automatically add tracking parameters to your ads. In these cases, we'll need you to manually make updates in {platformDisplayName} Ads Manager.
          <br />
          Please review the table below and update your ads to include the parameters listed above.
        </div>}
      />
      <AutoParamsIssues {...{ platform: platformName, platformDisplayName }} />
    </>}

    {platformName !== 'facebook' && <>
      <Header
        as='h2'
        content="Review ads with missing tracking parameters"
        subheader={<div className='sub header'>
          We've detected the following links that appear to be from {platformDisplayName} ads but do not have Rockerbox tracking parameters.
          <br />
          Please review the table below and update your ads to include the parameters listed above.
        </div>}
      />
      <MissingParams platform={platformName} />
    </>}

    {!!impressionTracking && <>
      <Header
        as='h2'
        content="Review impression pixel data"
        subheader={<div className='sub header'>
          We've received the following impression pixel fires from {platformDisplayName}.
          <br />
          Please note that only pixel fires from the past 7 days will appear in this table.
        </div>}
      />
      <ImpressionPixelData platform={platformName} {...{ impressionTracking }} />
    </>}
  </>)
}

export default AdvertisingTrackingParams
