import React, { useState, useEffect } from "react";
import {
  ContentCard,
  ColumnLayout,
  IndexGrid,
  ExpandableIndexGrid,
  ScrollingPopup
} from "@rockerbox/styleguide";
import OttDataSource from "./OttDataSource";
import OttAnalysis from "./OttAnalysis";
import CrossDeviceIndex from "../CrossDevice/index";
import CreativeDataSource from "./CreativeDataSource";
import ExternalDataSource from "./ExternalDataSource";
import { FirstMenu, SecondMenu } from "./Menus";
import {
  getConversionSegments,
  fetchServedSummary,
  getOttOffset
} from "../../utils/api";
import { aggregateSummary, prepareChartData, getOffsetOptions } from "./helper";
// Only All pages segment will and should run Visitor task and classification
export const getAllPagesSegment = () => {
  return getConversionSegments().then(list => {
    const allPageSegments = list
      .filter(item => item.action_name === "All Pages")
      .map(item => item.filter_id);
    return allPageSegments[0];
  });
};

const Layout = props => {
  const { updatePath, startDate, endDate, setDate, timePicker } = props;
  const [firstMenu, setFirstMenu] = useState();
  const [secondMenu, setSecondMenu] = useState();
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [segments, setSegments] = useState();
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(false);
  const [offset, setOffset] = useState({});
  const [apiOffset, setApiOffset] = useState({});
  const [creativeData, setCreativeData] = useState({});
  const [tierKeyDict, setTierKeyDict] = useState({});
  const [expandedKey, setExpandedKey] = useState();
  const [activeView, setActiveView] = useState("total");

  const dataType = firstMenu && firstMenu.split("_")[1];

  const componentMap = {
    external: { data: ExternalDataSource },
    creative: { data: CreativeDataSource },
    ott_served: {
      analysis: OttAnalysis,
      data: OttDataSource,
      tiers: CrossDeviceIndex
    },
    ott_batch: {
      analysis: OttAnalysis,
      data: OttDataSource,
      tiers: CrossDeviceIndex
    }
  };

  const apiAnalyticAsync = async type => {
    try {
      setLoading(true);
      setError(false);
      const newStartDate = props.match.params.startDate || startDate;
      const newEndDate = props.match.params.endDate || endDate;
      const segmentIds = segments || (await getAllPagesSegment());
      const [summary, offsets, config] = await Promise.all([
        fetchServedSummary(
          `${type}_event_summary`,
          newStartDate,
          newEndDate,
          segmentIds
        ),
        fetchServedSummary(
          `ott_${type}_offset_analysis`,
          newStartDate,
          newEndDate,
          segmentIds
        ),
        getOttOffset().catch(e => {
          console.log(e);
          return {};
        })
      ]);

      const { tiers: tableData, counts, detailDict } = aggregateSummary(
        summary,
        dataType
      );
      const loadedChartData = prepareChartData(offsets, counts, dataType);
      setSegments(segmentIds);
      setChartData(loadedChartData);
      setSummary(counts);
      setTierKeyDict(detailDict);
      setCreativeData(tableData);
      setOffset(config);
      setApiOffset(config);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  const apiDataAsync = async type => {
    try {
      setLoading(true);
      setError(false);
      const newStartDate = props.match.params.startDate || startDate;
      const newEndDate = props.match.params.endDate || endDate;
      const segmentIds = segments || (await getAllPagesSegment());
      const data = await fetchServedSummary(
        `${type}_event_summary`,
        newStartDate,
        newEndDate,
        segmentIds
      );

      const { tiers: tableData, counts, detailDict } = aggregateSummary(
        data,
        dataType
      );
      setSummary(counts);
      setTierKeyDict(detailDict);
      setCreativeData(tableData);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = props.match.params;
    if (params.firstMenu) {
      setFirstMenu(params.firstMenu);
    } else {
      setFirstMenu("creative");
    }
    if (params.secondMenu) {
      setSecondMenu(params.secondMenu);
    } else {
      setSecondMenu("data");
    }

    if (params.startDate) {
      setDate({
        startDate: params.startDate
      });
    } else {
      updatePath({ startDate, endDate, firstMenu, secondMenu });
    }
  }, []);

  useEffect(() => {
    const newPath = { firstMenu, secondMenu };
    const {
      startDate: pathStartDate,
      endDate: pathEndDate,
      firstMenu: pathFirstMenu,
      secondMenu: pathSecondMenu
    } = props.match.params;
    if (!pathStartDate) {
      newPath["startDate"] = startDate;
    }
    if (!pathEndDate) {
      newPath["endDate"] = endDate;
    }

    if (
      (firstMenu === "ott_served" || firstMenu === "ott_batch") &&
      secondMenu == "analysis" &&
      chartData.length === 0
    ) {
      apiAnalyticAsync(dataType);
    } else if (
      (firstMenu === "ott_served" || firstMenu === "ott_batch") &&
      secondMenu == "data" &&
      Object.getOwnPropertyNames(creativeData).length == 0
    ) {
      apiDataAsync(dataType);
    } else if (
      firstMenu &&
      pathFirstMenu != firstMenu &&
      firstMenu.indexOf("ott_") != -1
    ) {
      apiAnalyticAsync(dataType);
    }
    updatePath(newPath);
  }, [firstMenu, secondMenu]);

  useEffect(() => {
    !loading && apiAnalyticAsync(dataType);
  }, [props.match.params.startDate, props.match.params.endDate]);

  const Content =
    (componentMap[firstMenu] && componentMap[firstMenu][secondMenu]) ||
    ContentCard;

  const MainContent =
    secondMenu === "tiers" ? (
      <Content
        {...props}
        firstMenu={firstMenu}
        secondMenu={secondMenu}
        summary={summary}
        setSummary={setSummary}
        chartData={chartData}
        setChartData={setChartData}
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        offset={offset}
        setOffset={setOffset}
        creativeData={creativeData}
        activeView={activeView}
        setActiveView={setActiveView}
        expandedKey={expandedKey}
        setExpandedKey={setExpandedKey}
        tierKeyDict={tierKeyDict}
        apiOffset={apiOffset}
      />
    ) : (
      <React.Fragment>
        <ContentCard
          hasTable
          title=" "
          explainer=""
          topRight={timePicker}
        ></ContentCard>
        <Content
          {...props}
          firstMenu={firstMenu}
          secondMenu={secondMenu}
          summary={summary}
          setSummary={setSummary}
          chartData={chartData}
          setChartData={setChartData}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
          offset={offset}
          setOffset={setOffset}
          creativeData={creativeData}
          activeView={activeView}
          setActiveView={setActiveView}
          expandedKey={expandedKey}
          setExpandedKey={setExpandedKey}
          tierKeyDict={tierKeyDict}
          apiOffset={apiOffset}
        />
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <ColumnLayout
        leftWidth={2}
        centerWidth={2}
        rightWidth={12}
        leftContent={
          <FirstMenu
            firstMenu={firstMenu}
            setFirstMenu={setFirstMenu}
            setSecondMenu={setSecondMenu}
            updatePath={updatePath}
          />
        }
        rightContent={MainContent}
      >
        {
          <SecondMenu
            firstMenu={firstMenu}
            secondMenu={secondMenu}
            setSecondMenu={setSecondMenu}
            updatePath={updatePath}
          />
        }
      </ColumnLayout>
    </React.Fragment>
  );
};

export default Layout;
