import React from 'react'
import { ArrowCircleUpIcon, ArrowCircleDownIcon, BadgeCheckIcon } from '@heroicons/react/outline'
import { formatCurrency } from './valueFormatter';

const numberToTwoDecimals = (number) => {
  const num = typeof number == "number" ? number : 0
  const formattedNumber = num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})
  return formattedNumber
}

const numberFormatter = new Intl.NumberFormat('en-US', { style : 'percent' })
const formatPercent = (x) => numberFormatter.format(x)

const goalDiffDict = {
  increase: {
    type: "increase",
    text: "Increase spend by ",
    icon: ArrowCircleUpIcon
  },
  decrease: {
    type: "decrease",
    text: "Decrease spend by ",
    icon: ArrowCircleDownIcon
  },
  onTarget: {
    type: "onTarget",
    text: "Right on target!",
    icon: BadgeCheckIcon
  }
}

const cpaPercentFromGoal = (target, item) => {
  const { conversions, spend } = item
  const projectedSpend = target * conversions
  const diff = projectedSpend - spend 

  const percentDiff = (diff/spend)
  const adjustedPercentDiff = percentDiff * 100
  const absoluteAdjustedDiff = Math.abs(adjustedPercentDiff)

  const increaseOrDecrease = (absoluteAdjustedDiff <= 10) ? "onTarget" :
    adjustedPercentDiff > 10 ? "increase" :
    "decrease"
  
  const formattedPercentage = absoluteAdjustedDiff >= 25 ? formatPercent(.25) : formatPercent(Math.abs(percentDiff))

  return {formattedPercentage, increaseOrDecrease: goalDiffDict[increaseOrDecrease], absoluteAdjustedDiff }
}

const roasPercentFromGoal = (target, item) => {
  const { revenue, spend } = item
  const inversetTarget = 1/target
  const projected = inversetTarget * revenue
  const diff = projected - spend

  const percentDiff = (diff/spend)
  const adjustedPercentDiff = percentDiff*100
  const absoluteAdjustedDiff = Math.abs(adjustedPercentDiff)

  const increaseOrDecrease = (absoluteAdjustedDiff <= 10) ? "onTarget" :
    adjustedPercentDiff > 10 ? "increase" :
    "decrease"
  
    const formattedPercentage = absoluteAdjustedDiff >= 25 ? formatPercent(.25) : formatPercent(Math.abs(percentDiff))

    return {formattedPercentage, increaseOrDecrease: goalDiffDict[increaseOrDecrease], absoluteAdjustedDiff }
}

export const metricMapper = {
  cpa: {
    name: "cpa",
    display_name: "CPA",
    format: formatCurrency,
    trendReversed: true,
    percentageFromGoal: cpaPercentFromGoal
  },
  roas: {
    name: "roas",
    display_name: "ROAS",
    format: numberToTwoDecimals,
    trendReversed: false,
    percentageFromGoal: roasPercentFromGoal
  },
}

export const setBackgroundColor = (onTarget, delta, trendReversed) => {
  const red = '#e00000'
  const green = '#00ad39'

  const redOrGreen = onTarget ? 'gray' : delta > 0 ? green : red
  const inverseRedOrGreen = onTarget ? 'gray' : delta < 0 ? green : red

  return trendReversed ? inverseRedOrGreen : redOrGreen
}
