import React, { useState } from 'react';
import { Grid, Header, Message, Button } from 'semantic-ui-react';
import { SplitLayout, IndexGridNew, ContentCard } from '@rockerbox/styleguide';
import { Link } from 'react-router-dom';
import CreateViaGrid from '../Bulk/BulkCreateViaPromoCodes';
import CreateViaEvents from '../Bulk/BulkCreateViaEvents';
import BulkCreateCampaigns from '../Bulk/BulkCreateCampaigns';
import { encode } from '../../../utils/urlFormatter';
import { ManageCell, PromoCodeCell, DisplayCell, HasCell } from '../parts/Cells';
import { objectWithDefault } from '../helpers';

const ViewSponsors = ({
  contentOnly, entityConfigs, getEntityConfigs, entityData, program,
  hasLegacySpend, hasLegacyUTMs,
}) => {
  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = program || {};
  const display = objectWithDefault({ tier_1, tier_2, tier_3, tier_4, tier_5 });
  const jdisplay = JSON.stringify(display);

  const [showCreateMultiple, setShowCreateMultiple] = useState(false);
  const [showCreateEvents, setShowCreateEvents] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);

  const showCreate = view => {
    const show = {
      multiple: setShowCreateMultiple,
      events: setShowCreateEvents,
      campaign: setShowCreateCampaign,
    };
    Object.keys(show).forEach(s => {
      if (s === view) {
        show[s](true);
        return;
      }
      show[s](false);
    });
  };

  const resetView = () => {
    setShowCreateMultiple(false);
    setShowCreateEvents(false);
    setShowCreateCampaign(false);
    getEntityConfigs(true);
  };

  if (!!entityData) {
    entityData.forEach(row => {
      if (row.display.length) {
        const tiers = row.display[0];
        const currentDisplay = objectWithDefault({
          tier_1: tiers.tier_1,
          tier_2: tiers.tier_2,
          tier_3: tiers.tier_3,
          tier_4: tiers.tier_4,
          tier_5: tiers.tier_5,
        });

        row.display_match = JSON.stringify(currentDisplay) === jdisplay;
        row.display_current = JSON.stringify(currentDisplay);
        row.display_plan = jdisplay;
      }
    });
  }

  const cols = (!contentOnly ? [
    { display: 'Name', key: 'name', isFilterable: true, isSearchable: true },
    { display: 'Sponsorship Category', key: 'entity_type', isFilterable: true },
    { display: 'Promo codes', key: 'promo_codes', as: PromoCodeCell, isFilterable: true },
    !!hasLegacySpend && { display: 'Has Cost?', key: 'costs', as: HasCell, isFilterable: true },
    { display: 'Manage', as: ManageCell },
  ] : [
    { display: 'Name', key: 'name', isFilterable: true, isSearchable: true },
    { display: 'Has Tracking?', key: 'promo_codes', as: HasCell, isFilterable: true },
    !!hasLegacySpend && { display: 'Has Cost?', key: 'costs', as: HasCell, isFilterable: true },
    { display: 'Display Matches Program?', key: 'display_match', as: DisplayCell, program, isFilterable: true },
    { display: 'Manage', as: ManageCell },
  ]).filter(Boolean);

  const tableContent = (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h3" content="Promo Codes" />
          </Grid.Column>
          <Grid.Column width={10} textAlign="right">
            <Button primary compact size="mini" icon="plus" content="Promo Codes" onClick={() => showCreate('multiple')} />
            {!!hasLegacyUTMs && (
            <Button primary compact size="mini" icon="plus" content="Click-Based Events (UTMs)" onClick={() => showCreate('events')} />
            )}
            {!!hasLegacySpend && (
            <>
              <Button
                primary
                compact
                size="mini"
                icon="plus"
                content="Campaign Costs"
                onClick={() => showCreate('campaign')}
              />
              <Button
                primary
                compact
                size="mini"
                icon="plus"
                content="Manual Setup"
                as={Link}
                to={`/v3/data/marketing/promo_codes/create/${encode(program.entity_type)}`}
              />
            </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ContentCard hasTable>
        {!showCreateMultiple && !showCreateCampaign && !showCreateEvents
          && <IndexGridNew key={program.entity_type} sortable searchable paginate itemsPerPage={15} data={entityData} cols={cols} />}
      </ContentCard>
      {showCreateMultiple && <CreateViaGrid {...{ entityConfigs, entityData, program }} onReset={() => resetView()} /> }
      {showCreateEvents && <CreateViaEvents {...{ entityData, program }} onReset={() => resetView()} /> }
      {showCreateCampaign && <BulkCreateCampaigns {...{ entityData }} onReset={() => setShowCreateCampaign(false)} /> }
    </>
  );

  return contentOnly ? tableContent
    : (
      <SplitLayout
        leftWidth={13}
        rightWidth={3}
        leftContent={tableContent}
        rightContent={(
          <Message
            header="About Sponsorships"
            content={(
              <>
                <p>
                  Sponsorships are tracking parameters associated with specific marketing channels that fall outside of the standard marketing platforms.
                </p>
                <p>
                  Sponsorships allow you to understand the importance of marketing in these hard to track channels and track the costs associated with running on these channels as well.
                </p>
              </>
            )}
          />
        )}
      />
    );
};

export default ViewSponsors;
