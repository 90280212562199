import React from 'react';
import { Card, Table, Icon, Feed } from 'semantic-ui-react';
import { IndexGrid, CustomerTimeline } from '@rockerbox/styleguide';
import styled from 'styled-components'

const BoldSpan = styled.span`
  font-weight: bold
`;

export const SessionsCard = (actionCols) => ({ item }) => {
  return (
    <div>
      <Table definition>
        <Table.Row>
          <Table.Cell>Session id:</Table.Cell><Table.Cell> {item.sessionId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Start:</Table.Cell><Table.Cell> {item.timestamp}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>End:</Table.Cell><Table.Cell> {item.timestamp_finish}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Events:</Table.Cell><Table.Cell> {item.events}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Initial Action:</Table.Cell><Table.Cell>
            <div style={{maxWidth:"300px"}}> {item.url.split("&action=")[1].split("&")[0] }</div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Url:</Table.Cell><Table.Cell>
            <div style={{maxWidth:"300px",overflow:"ellipsis"}}> {item.url.split("&action=")[0] }</div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Referrer:</Table.Cell><Table.Cell > 
            <div style={{maxWidth:"300px",overflow:"ellipsis"}}>{ item.request_referrer }</div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
        </Table.Row>
      </Table>
      <IndexGrid data={item.action} cols={actionCols}/>
    </div>
  )
}

export const LookupsTable = ({lookupCols, lookup}) => (
  <Table definition>
    <Table.Body>
      {
        lookupCols.map(key => {
          return(
            <Table.Row>
              {lookup.map((lookupObj,i) => {
                return (
                  <React.Fragment>
                    { i == 0 && <Table.Cell width={2}>{key}</Table.Cell> }
                    <Table.Cell width={4}>{lookupObj[key]}</Table.Cell>
                  </React.Fragment>
                )
              })}
            </Table.Row>
          )
        })
      }
    </Table.Body>
  </Table>
)

export const Tier1Item = props => {
  const eventTiers = props.eventTiers ? props.eventTiers.filter((tier) => tier.name) : [];
  const numTiers = eventTiers.length;

  return (
    <CustomerTimeline.Item
      icon={<Icon circular name={'question'} color={'blue'} />}
      title={numTiers ? <CustomerTimeline.ItemTiers tiers={eventTiers} /> : null}
      date={props.date}
    >
      <p style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: ".5em", paddingLeft:"1em"}}>
        url: &nbsp;{props.baseUrl}
        <br />
        referrer: &nbsp; {props.referrer}
      </p>
    </CustomerTimeline.Item>
  )
}

export const ActionItem = props => (
  <CustomerTimeline.Item
    icon={<Icon circular inverted name='check' size='massive' color={props.color || 'blue'} />}
    title={
      <span>
        Action:&nbsp;
        <a href={props.eventLink}>
          {props.eventName}
        </a>
      </span>
    }
    date={props.date}
  >
    <p style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: 10}}>
      {
        <Table size="small" compact definition className='data-pii'>
          <Table.Row>
            <Table.Cell>url</Table.Cell>
            <Table.Cell>{ props.baseUrl.split("&action=")[0] }</Table.Cell>
          </Table.Row>
          { 
            props.baseUrl.split("&action=")[1].split("&").filter(row => {
              const kvs = row.split("=")
              return kvs.length > 1 && ["uid","url","script_version","hash_ip","source","rb_source","client_source","sessionId"].indexOf(kvs[0]) < 0
            }).map(row => {
              return <Table.Row>
                <Table.Cell>{ row.split("=")[0] }</Table.Cell>
                <Table.Cell>{ row.split("=")[1] }</Table.Cell>
              </Table.Row>
            })
          }
        </Table>
      }
    </p>
  </CustomerTimeline.Item>
)
