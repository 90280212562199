import { map, filter, get, compose, find } from 'lodash/fp';
import { CUSTOMER_TYPE_OPTIONS, ATTRIBUTION_MODEL_OPTIONS } from './constants';

export const selectFeaturedSegment = find(({ featured }) => featured === 1)

export const selectSegmentOptions = compose(
  map(({ action_name, action_id }) => ({
    text: action_name,
    value: action_id,
  })),
  filter(({ touchpoints }) => touchpoints === 0),
);

export const selectCustomerTypeOptions = segment => {
  if (get('has_new_to_file')(segment) === 1) {
    return CUSTOMER_TYPE_OPTIONS;
  }
  return filter(({ value }) => value === 'all')(CUSTOMER_TYPE_OPTIONS)
};

export const selectAttributionModelOptions = segment => {
  if (get('use_model')(segment) === 1) {
    return ATTRIBUTION_MODEL_OPTIONS;
  }
  return filter(({ value }) => value !== 'normalized')(ATTRIBUTION_MODEL_OPTIONS);
};
