import React, { Component } from 'react';
import { ContentCard } from '@rockerbox/styleguide';
import SelectConversion from '../SelectSegment';
import { Dropdown} from 'semantic-ui-react';
import { _timeRange } from '../timerange';
import { _pushPath } from '../pushpath';

const InlineDropdown = (props) => {
  return (
    <Dropdown {...props} inline preventFluid={true}/>
  )
}

class FilterCard extends _pushPath(_timeRange(Component)) {

  shouldComponentUpdate(prevProps, prevState) {
    const check = Object.keys(this.props)
      .filter(key => {
        return this.props[key] != prevProps[key]
      })

    const shouldToggle = this.state.focusedInput !== prevState.focusedInput

    return shouldToggle || check.length > 0
  }

  render() {
    const { onSegment, startDate, endDate, selected, setDate } = this.props;

    return (
      <ContentCard
        key="header"
        title={
          <React.Fragment>
            Conversion: &nbsp;
            <SelectConversion
              onChange={onSegment}
              as={InlineDropdown}
              {...this.props}
            />
          </React.Fragment>
        }
        topRight={<React.Fragment>
          <span style={{fontWeight: 'bold'}}>Select Date Range: &nbsp;</span>
          {this.renderAirbnbDatepicker()}
        </React.Fragment>}
        disableHeaderEllipse
        noContent
      />
    )
  }
}

export default FilterCard;
