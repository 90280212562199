export const ROCKERBOX_REPORT_TYPE = 'attribution'
export const PLATFORM_REPORT_TYPE = 'platform_data'

export const PLATFORM_NAME_MAP = {
  'snowflake': 'snowflake',
  'redshift': 'aws',
  'bigquery': 'gcp',
}

export const PLATFORM_NAME_DISPLAY_MAP = {
  'snowflake': 'Snowflake',
  'redshift': 'Amazon Redshift',
  'gcp': 'Google BigQuery',
}

export const GCP_STORAGE_MULTI_REGIONS = [
  'us',
  'eu',
  'asia',
];

export const BIGQUERY_REGIONS = [
  ...GCP_STORAGE_MULTI_REGIONS,

  'us-central1',
  'us-east1',
  'us-east4',
  'us-west4',
  'us-west1',
  'us-west2',
  'us-west3',
  'southamerica-east1',
  'southamerica-west1',
  'northamerica-northeast1',
  'northamerica-northeast2',

  'asia-east1',
  'asia-east2',
  'asia-northeast1',
  'asia-northeast2',
  'asia-northeast3',
  'asia-south1',
  'asia-south2',
  'asia-southeast1',
  'asia-southeast2',
  'australia-southeast2',
  'australia-southeast1',

  'europe-central2',
  'europe-north1',
  'europe-west1',
  'europe-west2',
  'europe-west3',
  'europe-west4',
  'europe-west6',
];

export const REDSHIFT_REGIONS = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-southeast-3',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-south-1',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
  'us-gov-east-1',
  'us-gov-west-1',
]

export const REPORT_SCHEMA_HELP_DOC_MAPPINGS = {
  'compiled_mta': 'mta',
  'compiled_mta_tiers': 'buckets-breakdown',
  'conversion_data': 'conversions',
  'platform_performance_adwords_clean': 'platform-performance-google',
  'platform_performance_facebook_clean': 'platform-performance-facebook',
  'platform_performance_bing_clean': 'platform-performance-bing',
  'platform_performance_tiktok_clean': 'platform-performance-tiktok',
  'platform_performance_pinterest_clean': 'platform-performance-pinterest',
  'platform_performance_linkedin_clean': 'platform-performance-linkedin'
}
