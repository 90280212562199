import { submitAttributionRunJob, checkJobStatus } from '../api/attributionRun'

// utility function to wrap a wq job in a promise
// submits an attribution run job and returns a promise that resolves when the job is complete
export function submitAndWaitForJob(path, data) {
  return new Promise((resolve, reject) => {
    function check(job_id) {
      // make a request to check the job's status
      checkJobStatus(job_id).then(({ finished_at, finish_type }) => {
        if (!!finished_at) {
          if (finish_type !== 'success') {
            reject(new Error('Job failed'))
          }
          // if the job completed successfully, resolve the promise
          resolve({ status: finish_type })
        } else {
          // if the job is not complete, schedule another check in 5 seconds
          setTimeout(() => check(job_id), 5000)
        }
      }).catch(error => {
        reject(error)
      })
    }

    // submit the job and kick off the polling loop
    submitAttributionRunJob(path, data)
      .then(({ job_id }) => {
        if (!job_id) reject(new Error('No job ID returned'))
        check(job_id)
      })
  })
}
