import React from 'react';

import SetupShare from './SetupShare';
import SetupServiceAccount from './SetupServiceAccount';
import SetupPermissions from './SetupPermissions';
import TableCreationDocs from './TableCreationDocs'

export default {
  name: 'BigQuery',
  logo: 'bigquery-logo.png',
  steps: [
    {
      order: 1,
      description: 'Provide the following details of your BigQuery dataset',
      component: SetupShare,
    },
    {
      order: 2,
      description: 'Add Rockerbox service account to your dataset',
      component: SetupServiceAccount,
      requires: [1]
    },
    {
      order: 3,
      description: 'Grant your accounts access to Rockerbox data warehousing',
      component: SetupPermissions,
      requires: [1]
    },
  ],
  customSection: {
    component: TableCreationDocs,
  },
}


