import React from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import {
  postBigQueryTable,
  postRedshiftTable,
  postSnowflakeTable,
} from "../../../api/datalake";

import { Button } from 'semantic-ui-react';
import { Drawer, BasicViewHeader } from '../../../components'
import { useDatalake } from '../DatalakeContext/hooks'

import ReportSyncPlatformTable from "./ReportSyncPlatformTable";
import ReportSyncRockerboxTable from "./ReportSyncRockerboxTable";
import PlatformDataSync from "../components/PlatformDataSync"
import RockerboxDataSync from '../components/RockerboxDataSync';

import { REPORT_SCHEMA_HELP_DOC_MAPPINGS, PLATFORM_NAME_DISPLAY_MAP } from '../constants';

const ReportSync = () => {
  const history = useHistory();
  const [openSyncPlatformData, setOpenSyncPlatformData] = React.useState(false)
  const [openSyncRockerboxData, setOpenSyncRockerboxData] = React.useState(false)
  const [loadingRockerboxData, setLoadingRockerboxData] = React.useState(false)
  const [loadingPlatformData, setLoadingPlatformData] = React.useState(false)

  const {
    datalakeLoaded,
    datalakeConnection,
    datalakeReports,
    refreshDatalakeTables,
  } = useDatalake();

  /*** CLICK / INPUT HANDLERS ***/
  const handlePostToTable = (selectedReport, tableName, identifier) => {
    // hotfix (SN): Remove out-of-scope usage: datalakeReports
    const platformSchemas = datalakeReports?.filter((schema) => schema.report_type === 'platform_data' )
    if (platformSchemas.find((itmInner) => itmInner.report === selectedReport)) {
      setLoadingPlatformData(true)
    } else {
      setLoadingRockerboxData(true)
    }

    /**
     * TODO: this needs to be optimized.
     */
    switch (datalakeConnection['platform']) {
      case 'snowflake':
        return postSnowflakeTable(selectedReport, tableName, identifier)
          .then((data) => {
            refreshDatalakeTables()
            setOpenSyncPlatformData(false)
            setOpenSyncRockerboxData(false)
          })
      case 'redshift':
        return postRedshiftTable(selectedReport, tableName, identifier)
          .then((data) => {
            refreshDatalakeTables()
            setOpenSyncPlatformData(false)
            setOpenSyncRockerboxData(false)
          })
      case 'gcp':
        return postBigQueryTable(selectedReport, tableName, identifier)
          .then((data) => {
            refreshDatalakeTables()
            setOpenSyncPlatformData(false)
            setOpenSyncRockerboxData(false)
          })
    }
  }

  // pass in options
  const handleOpenSyncPlatformData = () => setOpenSyncPlatformData(true)
  const handleOpenSyncRockerboxData = () => setOpenSyncRockerboxData(true)

  const currentPlatform = get(datalakeConnection, 'platform');
  const displayName = get(PLATFORM_NAME_DISPLAY_MAP, currentPlatform)

  const ToSetupView = () => {
    const platform = currentPlatform === 'gcp' ? 'bigquery' : currentPlatform;
    const handleToSetup = () => history.push(`${history.location.pathname}/setup/${platform}`);
    return <Button onClick={handleToSetup} primary>View Setup</Button>
  }

  return (
    <>
      <BasicViewHeader
        header={`${displayName} Data Sync`}
        subheader='Manage syncs of Rockerbox data to your data warehouse'
        topRight={currentPlatform === 'gcp' && ToSetupView()}
      />
      <ReportSyncPlatformTable
        loading={!datalakeLoaded}
        callbackFn={handleOpenSyncPlatformData}
      />
      <ReportSyncRockerboxTable
        loading={!datalakeLoaded}
        callbackFn={handleOpenSyncRockerboxData}
      />
      <Drawer openDrawer={openSyncPlatformData} onDrawerClose={() => setOpenSyncPlatformData(false)}>
        <PlatformDataSync
          {...{
            submitDatasetToSync: handlePostToTable,
            isSidebar: true,
            disableInputs: false
          }}
          open={openSyncPlatformData}
        />
      </Drawer>
      <Drawer openDrawer={openSyncRockerboxData} onDrawerClose={() => setOpenSyncRockerboxData(false)}>
        <RockerboxDataSync
          {...{
            submitDatasetToSync: handlePostToTable,
            isSidebar: true,
            disableInputs: false
          }}
          open={openSyncRockerboxData}
        />
      </Drawer>
    </>
  )
}
export default ReportSync;
