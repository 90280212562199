import React, {useEffect, useState, useCallback, useRef} from 'react'
import { Input, Icon, Popup } from 'semantic-ui-react'
import _ from 'lodash'
import styled from 'styled-components'

const Label = styled.div`
  font-family: 'Poppins' !important;
  color: #333333;
`

const DebouncedSearch = ({onChange, value, disabled, resetFilters}) => {
  const initialRender = useRef(true);
  const [searchVal, setSearchVal] = useState(value)
  const hasSearch = searchVal.length > 0

  const handleSearch = (value) => {
    // skip first render so saved views will work
    if(initialRender.current) {
      initialRender.current = false
      return
    }

    onChange(false, {value})
  }

  useEffect(() => {
    setSearchVal(value)
  }, [value])

  const debouncedResults = useCallback(_.debounce((value) =>handleSearch(value), 300), [])

  useEffect(() => {
    debouncedResults(searchVal)
  }, [searchVal]);  


  return (
    <div> 
      <Label>
        Search
      </Label>
      <div style={{position: 'relative'}}>
        <Input
          className="debounce-search"
          icon={<Icon name="search" style={{color: `${disabled ? '#f0f0f0' : !hasSearch ? '#475ddc' : '#fff'}`}}/>}
          onChange={(evt, { value }) => setSearchVal(value.toLowerCase())} 
          value={searchVal}
          disabled={disabled}
        />
        { (hasSearch && !disabled) && <Icon
            onClick={() => setSearchVal("")} 
            name="close"  
            color="purple" 
            size="small" 
            circular
            style={{
              backgroundColor: "#fff",
              cursor: 'pointer',
              position: 'absolute',
              top: 9,
              right: 5,
            }}
          />
        }
        {(hasSearch && disabled) && <Popup 
            content='Clearing your search term will remove all tier filters.' 
            trigger={<Icon
              onClick={() => setSearchVal("")} 
              name="close"  
              color="purple" 
              size="small" 
              circular
              style={{
                backgroundColor: "#fff",
                cursor: 'pointer',
                position: 'absolute',
                top: 9,
                right: 5,
              }}
            />}
          />
        }
        {(!hasSearch && disabled) && <Popup 
            hoverable
            content={<p>Please <span onClick={() => resetFilters()} style={{color: '#475DDC', cursor: 'pointer', textDecoration: 'underline'}}>clear your tier filters</span> to begin a new search.</p>} 
            trigger={<Icon
              name="info"  
              color="purple" 
              size="small" 
              circular
              style={{
                backgroundColor: "#fff",
                cursor: 'pointer',
                position: 'absolute',
                top: 9,
                right: 5,
              }}
            />}
          />
        }

      </div>

    </div>
  )
}

export default DebouncedSearch
