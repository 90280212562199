import React from 'react'
import { Button, Icon, Input } from 'semantic-ui-react'

const PromoCode = ({promoCode, isEditPromoCode, codePending, promoText, setPromoText, onSubmitPromoCode, handleClick, promoTagline, handleReset, clientSecretOptions, isEdit}) => {

    const ClearPromoCodeButton = props => (
        <Button
          onClick={() => {
            handleReset()
            }}
          {...props}
        >
          <Icon name='times circle' />
        </Button>
      );
    
    if (codePending || isEdit){
        return null
    } else if (isEditPromoCode && !promoCode){
        return (
        <div className='promo-code-entry-div'>
        <p className='enter-a-promo-code'>Enter referral code</p>
        <form className='promo-code-input-form'>
          <Input 
            onChange={e => setPromoText(e.target.value)}
            value={promoText}
            type="text" 
            className='promo-code-input'
            labelPosition='left'
          >
            <input />
          </Input>
            <Button
              className="promo-code-submit-button"
              type="submit"
              size='large'
              color='purple'
              content='Apply'
              disabled={promoText === null}
              onClick={onSubmitPromoCode}
            />
        </form>
        </div>
        )
    } else if (!isEditPromoCode && !promoCode) {
        if (clientSecretOptions) {
            return (
                <div>
                    <button className='have-a-promo-code'onClick={handleClick}
                            style={{
                                paddingTop: '30px',
                                color: '#475ddc',
                                backgroundColor: 'transparent',
                                border: 'none',
                                marginLeft: '-5px',
                                margiTop: '2px',
                                marginBottom: '15px',}}
                    >Have a referral code?</button>
                </div>
                );
        } else {
            return null
        }
            
    } else if (promoCode && promoTagline){
        return (
            <span className='promo-code-tagline' style={{float: "left", width: "100%", marginTop: '-10px', marginBottom: '10px'}}> <text style={{color: "#0db791"}}>{promoTagline} with</text> <text style={{color: "#475ddc"}}>{promoText}</text> 
                <ClearPromoCodeButton className='reset-promo-code-button' style={{color: '#475ddc',background: 'none', marginLeft: '-10px'}}/> 
            </span>
        );
    } else {
        return null
    }
  };
  export default PromoCode;
  