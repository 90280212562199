import React, {useMemo} from 'react'
import { CacheContext } from '../../utils/CacheContext'
import { getAccountCurrency } from '../api/account'

export const useCurrency = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const currency = Context.getCache(`currency`, getAccountCurrency) || null

  const currencyCode = useMemo(() =>{
    return (!!currency?.length && currency[0]?.currency_code) || 'USD'
  }, [currency])
  
  return { currencyCode }
}


