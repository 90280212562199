import React, { Component } from 'react';
import { Icon, Dropdown, Button } from 'semantic-ui-react';
import { Modal, Form } from 'semantic-ui-react';
import { getProjects, createProject } from '../../utils/api';
import EmptyView from './empty';
import ModelInteractive from './Interactive';
import { changed } from './helpers.js';

const projectToOption = (resp) => {
  return { text: resp.name, value: resp.id, key: resp.id }
}

class Project extends Component {

  state = {
    project: {},
    projects: [],
    createProjectModal: false
  }

  openProjectModal = () => this.setState({ createProjectModal: true })
  handleTextChange = (value, key) => this.setState({ [key]: value })

  createProject = () => {
    const { projectName } = this.state;
    createProject(projectName)
      .then(resp => this.setState({ createProjectModal: false }) )
  }
  getProjects() {
    getProjects()
      .then( resp => {
        const projects = resp.response.map(projectToOption)
        const project = (projects.length == 0) ? {} : projects[0]
        this.setState({ projects, project })
      })
  }

  componentDidMount() {
    this.getProjects()
  }
  componentDidUpdate(prevProps, prevState) {
    if (changed(prevState, this.state, "createProjectModal")) this.getProjects()
  }
  setBusy = (isBusy) => {
    this.setState({ isBusy })
  }

  render() {
    const { setBusy } = this;
    const { project, isBusy } = this.state;
    const { key } = project;
    return (
      <div style={{margin:"-14px","marginTop":"-1.4em"}}>
        <h3 style={{padding:".9em",background:"white"}}>
          <Icon name='file code outline' />
          <Dropdown value={this.state.project.value} placeholder="Project" options={ this.state.projects } />
          <div style={{float:"right"}}>{ isBusy && "Running..." }</div>
        </h3>
        { !key && <EmptyView onButtonClick={this.openProjectModal}/> }
        { key && <ModelInteractive {...{project_id: key, setBusy}} />}

        <Modal size="small" open={this.state.createProjectModal} onClose={this.onModalClose}>
          <Modal.Header>Set Project Name</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form onSubmit={this.createProject}>
                <Form.Input label="Project Name" value={this.state.projectName} onChange={(e,d) => this.handleTextChange(d.value, 'projectName')}/>
                <Form.Field>
                  <Button type="submit">Create Project</Button>
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
     </div>
    )
  }
}



export default Project;
