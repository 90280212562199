import React, { useContext, useMemo } from 'react';
import shallow from "zustand/shallow";
import { CostCard, EventCard, PromoCodeCard } from './cards';
import { Checkbox, Message, Form, Accordion, Button, Segment } from 'semantic-ui-react';
import { MultiStepLayout, DataTable, ColumnLayout, SplitLayout, ContentCard } from '@rockerbox/styleguide';
import { _pushPath } from '../pushpath';
import { getSegments, getEntityConfigs, postEntityData, updateEntityData } from '../../utils/api';
import _ from 'lodash';
import moment from 'moment';
import GeneralSetup from './SetupGeneral';
import TrackingSetup from './SetupTracking';
import SpendSetup, { COST_COLUMNS } from './SetupSpend';
import DisplaySetup from './SetupDisplay';
import * as routes from '../../routes';
import * as Router from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { entityStore } from './entityStore';
import { decode } from '../ViewAttribution/helpers';
import CacheContext from '../../utils/CacheContext.js'

const rand = () => `000000000${parseInt(Math.random()*10**10)}`.split("").reverse().join("").slice(0,10)

export const DEFAULTS = {
  "events": { name:"", tier_1:"", tier_2:"", tier_3:"", tier_4:"", tier_5:"" },
  "promo_codes": { name:"", conversion_field_name:"", conversion_field_value:"", fixed_unit_cost:"", rev_share_percent:"", fixed_discount:"", percent_discount: "" },
  "costs": { name:"", start_date:"", end_date:"", distribution:"", cost:"" },
  "display": { name:"", start_date:"", end_date:"", distribution:"", cost:"" }
}

const typeOptions = ["Podcast", "Influencer", "Site Sponsorship"]
  .map(value => { return {text: value, value}})

const defaultState = {
  name: "",
  entity_type: "",
  costs: [],
  events: [],
  promo_codes: [],
  display: [],
  segments: [{segment_id: "", promo_field: "coupon"}],
  hasAffiliateLinks: false,
  hasPromoCodes: false,
  hasUTMParams: false,
  costViaSponsorship: true,
  costViaPurchase: false,
  costViaRevenue: false,
}

const EntitiesNew = (props) => {
  const [contextState, setContextState, Context] = useContext(CacheContext)
  const features = contextState?.account?.features || []
  const hasLegacySpend = features.includes('sponsorships_legacy_spend')
  const hasLegacyUTMs = features.includes('sponsorships_legacy_utms')

  const steps = useMemo(() => {
    return [
      {text: "General Setup", value: "general", as: GeneralSetup},
      {text: "Tracking", value: "tracking", as: TrackingSetup},
      !!hasLegacySpend && {text: "Spend", value: "spend", as: SpendSetup},
      {text: "Display", value: "display", as: DisplaySetup},
    ].filter(Boolean)
  }, [hasLegacySpend])

  const trackingOptions = useMemo(() => {
    return [
      { text: "Promo codes", field: "hasPromoCodes"},
      !!hasLegacyUTMs && { text: "UTM Params / Affiliate Links", field: "hasUTMParams"},
    ].filter(Boolean)
  }, [hasLegacyUTMs])

  const history = Router.useHistory()
  const { entityType } = Router.useParams()
  const decodeEntityType = entityType && decode(entityType)
  const [ set, getEntityConfigs, entityConfigs, entityProgramConfigs ] = entityStore(
    ({ set, getEntityConfigs, entityConfigs, entityProgramConfigs }) => [ 
       set, getEntityConfigs, entityConfigs, entityProgramConfigs
    ], 
    shallow
  )
  const [ state, _setState ] = React.useState(defaultState)
  const [programDefaults, setProgramDefaults] = React.useState({})
  const [ isDisabled, setIsDisabled ] = React.useState(false);
  const [ tier1Empty, setTier1Empty ] = React.useState(false);
  const { id } = Router.useParams()

  const setState = (newState) => _setState(state => ({...state, ...newState}))

  React.useEffect(() => { getEntityConfigs() }, [])
  React.useEffect(() => {
    if (entityProgramConfigs && !id) {
      const entityProgramConfig = entityProgramConfigs.find(({ entity_type }) => entity_type == decodeEntityType)

      const { tier_1, tier_2, tier_3, tier_4, tier_5 } = entityProgramConfig
      const display = [{ tier_1, tier_2, tier_3, tier_4, tier_5 }]

      const programDefaults = {}
      const { fixed_unit_cost, rev_share_percent } = entityProgramConfig
      if (fixed_unit_cost) {
        programDefaults['fixed_unit_cost'] = fixed_unit_cost
        programDefaults['costViaPurchase'] = true
      }
      if (rev_share_percent) {
        programDefaults['rev_share_percent'] = rev_share_percent
        programDefaults['costViaRevenue'] = true
      }

      setProgramDefaults(programDefaults)
      const newState = { display, ...programDefaults }
      setState(newState)

    }
  }, [entityProgramConfigs, id])

  const addItemToList = (key, values = {}) => {
    const stateCopy = _.cloneDeep(state);
    const new_id = `new_${rand()}`;
    const newObj = DEFAULTS[key]
    const obj = Object.assign({ new_id }, newObj, values, programDefaults)
    if (key == "promo_codes") {
      obj.conversion_field_name = state.segments[0]["promo_field"]
    }
    stateCopy[key].push(obj)
    setState(stateCopy);
  }

  const removeItemFromList = (key) => (row) => {
    const stateCopy = _.cloneDeep(state);
    const index = state[key].indexOf(row)
    stateCopy[key].splice(index, 1)
    setState(stateCopy);
  }

  const handleSubmit = () => {
    const postObj = state;
    state['events'] = state.events.filter(row => row.tier_1)

    setTier1Empty(false);

    const send = id ? updateEntityData : postEntityData;

    Object.keys(DEFAULTS).map(key => {
      postObj[key] = postObj[key].map(row => Object.assign({}, DEFAULTS[key], row))
    })

    // prevent submission without tier_1 filled in
    if(state.display[0].tier_1 == "") {
      setTier1Empty(true);
    } else {
      send(postObj, id)
      .then(data => {
        set({ entityConfigs: data})
        history.push('/v3/data/marketing/promo_codes/main')
      })
    }
  }
  const updateStateField = (key) => (evt, { value }) => setState({[key]: value})
  const updateStateArrayRowField = (stateSlice, field, idKey = "id", newIdKey = "new_id") => (item) => (e, {value}) => {
    const stateCopy = _.cloneDeep(state);
    const selectedObj = item.new_id ?
        stateCopy[stateSlice].find(c => c[newIdKey] == item[newIdKey]) :
        stateCopy[stateSlice].find(c => c[idKey] == item[idKey]);
    selectedObj[field] = value;

    setState(stateCopy);
  }

  React.useEffect(() => { if (!state.entity_type && decodeEntityType) setState({ entity_type: decodeEntityType }) }, [state.entity_type, decodeEntityType])

  React.useEffect(() => {
    const obj = (entityConfigs||[]).find(row => row.id == id)
    if (obj) {
      const { segments, promo_codes, events, costs } = obj

      costs.map(cost => {
        cost.start_date = moment.utc(cost.start_date).format("YYYY-MM-DD")
        cost.end_date = moment.utc(cost.end_date).format("YYYY-MM-DD")
        cost.cost = cost.cost || "0"
      }) 

      obj['segments'] = segments.length ? segments : [{}]
      obj['hasPromoCodes'] = promo_codes.length > 0
      obj['hasUTMParams'] = events.length > 0
      obj['costViaSponsorship'] = costs.length > 0
      obj['costViaPurchase'] = (promo_codes.filter(row => row.fixed_unit_cost > 0).length > 0) ||
        (events.filter(row => row.fixed_unit_cost > 0).length > 0)
      obj['costViaRevenue'] = (promo_codes.filter(row => row.rev_share_percent > 0).length > 0) ||
        (events.filter(row => row.rev_share_percent > 0).length > 0)

      setState(obj)
    }
    if (entityConfigs && !obj) {
      getSegments()
        .then(segments => {
          const { action_id } = segments.find(x => x.featured)
          const stateCopy = Object.assign({}, { segments: [{segment_id: action_id, promo_field: "coupon"}] })
          setState(stateCopy)
        })
    }
  }, [entityConfigs, id])

  const { name, entity_type, costs, events, promo_codes } = state;
  const { hasAffiliateLinks, hasPromoCodes, hasUTMParams } = state;
  const { costViaSponsorship, costViaPurchase, costViaRevenue } = state;

  const showName = !!entity_type
  const showTracking = showName && !!name
  const showCost = showTracking && (hasAffiliateLinks || hasPromoCodes || hasUTMParams)

  return (
    <Form onSubmit={handleSubmit}>
      <MultiStepLayout
        {...{ steps }}
        {...{ state, setState }}
        {...{ updateStateField, updateStateArrayRowField, addItemToList, removeItemFromList }}
        {...{ showName, showTracking, showCost }}
        {...{ hasAffiliateLinks, hasPromoCodes, hasUTMParams }}
        {...{ decodeEntityType, entity_type, name, costs }}
        {...{ typeOptions, trackingOptions }}
        {...{ isDisabled, setIsDisabled }}
        {...{ tier1Empty}}
        {...{ hasLegacySpend, hasLegacyUTMs }}
      />
    </Form>
    )
}


export default EntitiesNew;
