import React, { useState, useEffect } from 'react';
import { useDatalake } from '../DatalakeContext/hooks';
import { CreateTableDoneButton } from '../components';
import PlatformDataTable from "./PlatformDataTable";
import RockerboxDataTable from "./RockerboxDataTable";
import PlatformDataSync from "./PlatformDataSync";
import RockerboxDataSync from "./RockerboxDataSync";
import { PLATFORM_REPORT_TYPE } from '../constants';
import { Drawer } from '../../../components';

const CreateTable = ({active, postTable}) => {
  const {
    datalakeReports,
    refreshDatalakeTables
  } = useDatalake()

  const [loadingRockerboxData, setLoadingRockerboxData] = useState(false)
  const [loadingPlatformData, setLoadingPlatformData] = useState(false)
  const [openSyncPlatformData, setOpenSyncPlatformData] = React.useState(false)
  const [openSyncRockerboxData, setOpenSyncRockerboxData] = React.useState(false)

  const handlePostToTable = (selectedReport, tableName, identifier) => {
    const platformSchemas = datalakeReports.filter((schema) => schema.report_type === PLATFORM_REPORT_TYPE )
    if (platformSchemas.find((itmInner) => itmInner.report === selectedReport)) {
      setLoadingPlatformData(true)
    } else {
      setLoadingRockerboxData(true)
    }

    return postTable(selectedReport, tableName, identifier)
      .then(() => refreshDatalakeTables())
      .finally(() => {
        setLoadingPlatformData(false)
        setLoadingRockerboxData(false)
        setOpenSyncPlatformData(false)
        setOpenSyncRockerboxData(false)
      })
  }

  const handleOpenSyncPlatformData = () => setOpenSyncPlatformData(true)
  const handleOpenSyncRockerboxData = () => setOpenSyncRockerboxData(true)

  return (
    <>
      <div>
        <Drawer openDrawer={openSyncPlatformData} onDrawerClose={() => setOpenSyncPlatformData(false)}>
          <PlatformDataSync
            submitDatasetToSync={handlePostToTable}
            disableInputs={!active}
            isSidebar={true}
            open={openSyncPlatformData}
          />
        </Drawer>
        <PlatformDataTable
          emptyGridMessage={"No Synced Platform Datasets"}
          loading={loadingPlatformData}
          callbackFn={handleOpenSyncPlatformData}
        />
      </div>
      <div style={{marginTop: 25}}>
        <Drawer openDrawer={openSyncRockerboxData} onDrawerClose={() => setOpenSyncRockerboxData(false)}>
          <RockerboxDataSync
            submitDatasetToSync={handlePostToTable}
            disableInputs={!active}
            isSidebar={true}
            open={openSyncRockerboxData}
          />
        </Drawer>
        <RockerboxDataTable
          emptyGridMessage={"No Synced Rockerbox Datasets"}
          loading={loadingRockerboxData}
          callbackFn={handleOpenSyncRockerboxData}
        />
      </div>
      <CreateTableDoneButton />
    </>
  )
}

export default CreateTable
