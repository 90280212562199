import React from 'react'
import { Grid } from 'semantic-ui-react';

import logoWhite from '../../../images/logo-white.svg'

const GatewayWrap = props => (
  <div className="gateway-background">
    <Grid columns={2} centered>
      <Grid.Column
        mobile={15}
        computer={7}
        verticalAlign="middle"
        centered="true">
        <div className="m-t-80 m-b-20">
          <a href="https://www.rockerbox.com" target="_blank">
            <img src={logoWhite} width="250" />
          </a>
        </div>
        {props.children}
      </Grid.Column>
    </Grid>
  </div>
)

export default GatewayWrap
