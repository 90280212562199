import React, { Component } from 'react';
import CacheContext from '../../utils/CacheContext.js'
import { Message, Segment, Modal, Button, Form } from 'semantic-ui-react';
import { postEntityProgram, getEntityProgramConfigs } from '../../utils/api';
import { SetupDisplaySelector } from '../Entities/SetupDisplay';
import CreateEntityProgram from './CreateProgram';
import { SelectCheckbox, SplitLayout, IndexGrid, ContentCard } from '@rockerbox/styleguide';

const COLUMNS = [
  {display: "Sponsorship Program", key: "entity_type"},
  {display: "Default Payment - Fixed Cost", key: "fixed_unit_cost"},
  {display: "Default Payment - Revenue Share Percent", key: "rev_share_percent"},
  {display: "Has Campaign Costs?", key: "has_campaign_costs"},
]

const EntityProgram = (props) => {

  const [entityProgramData, setEntityProgramData] = React.useState(false)
  const [showCreate, setShowCreate] = React.useState(false)
  const [refresh, setRefresh] = React.useState(0)

  React.useEffect(() => {
    getEntityProgramConfigs()
      .then(setEntityProgramData)
  }, [refresh])

  return <ContentCard 
    hasTable title="Sponsorships Programs"
    topRight={<Button primary compact size="mini" icon="plus" content="Create Program" onClick={() => setShowCreate(true)} />}
  >
    <CreateEntityProgram 
      open={showCreate} 
      onSubmit={() => {
        setShowCreate(false) 
        setRefresh(refresh + 1)
      }}
    />
    <IndexGrid data={entityProgramData} cols={COLUMNS} />
  </ContentCard>
}

export default EntityProgram;
