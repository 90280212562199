import { formatPercent } from '../../utils/format_helpers';

export const get = (object, key, defaultValue) => object[key] || defaultValue

export const percent = (x) => formatPercent(x);

export const number = (x) => x ? (new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})).format(x) : "-";

export const defaultNumber = (x) => number(x);

export const getViaKey = (obj, dotKey, defaultValue) => {
  const result = dotKey.split(".").reduce((p,c) => {
    return p[c] || {}
  },obj)

  if (typeof(result) == "object") return defaultValue
  return result
}
