import React, { Component } from "react";
import moment from "moment";
import { PagedIndexGrid, ContentCard, IndexGrid } from "@rockerbox/styleguide";
import { Table, Button, Menu } from "semantic-ui-react";

class DeliveredReportIndex extends Component {
  render() {
    const {
      outboxes,
      updateReportId,
      outbox_name,
      loadingOutbox,
      delieveredReports,
      match: { params: { category, id } = {} } = {}
    } = this.props;

    const filterEmailReportFromRockerbox = reports => {
      if (reports["Email Delivery"] && reports["Email Delivery"].length) {
        reports["Email Delivery"] = reports["Email Delivery"].filter(
          report =>
            report.destination &&
            report.destination.indexOf("@rockerbox.com") === -1
            && report.scheduled_report_id != null
        );
      }
    };
    filterEmailReportFromRockerbox(delieveredReports);

    const DownloadCell = ({ item, col }) => {
      if (item.download_report && item.download_report.enable === false) {
        return <Table.Cell textAlign="center"></Table.Cell>;
      }
      return (
        <Table.Cell textAlign="center">
          <IndexGrid.DownloadButton itemId={item.id} />
        </Table.Cell>
      );
    };

    const m = new moment.tz(moment.tz.guess());
    const tz = m.zoneAbbr();
    const cols = [
      { display: "Name", key: "scheduled_report_name" },
      { display: "Report Type", key: "report_type" },
      { display: `Delivered Time (${tz})`, key: "delivered_at" },
      {
        display: "Destination",
        key: "full_path",
        as: IndexGrid.EllipsedCell
      },
      {
        display: "Download",
        key: "download_report",
        as: DownloadCell,
        headerStyles: { textAlign: "center" }
      }
    ];

    const selectedRow =
      (delieveredReports[outbox_name] &&
        delieveredReports[outbox_name].filter(
          message => message.id == id
        )[0]) ||
      {};

    const deliveredData = delieveredReports[outbox_name]
      ? delieveredReports[outbox_name].filter(report => report.regression == 0)
      : [];

    return (
      <React.Fragment>
        <ContentCard title="Delivered Reports" hasTable loading={loadingOutbox}>
          <PagedIndexGrid
            data={deliveredData}
            cols={outbox_name === "Email Delivery" ? cols : cols.slice(0, -1)}
            as={ContentCard.Table}
            itemsPerPage={10}
            fallBackMsg="No reports currently delivered"
            collapsed
            onRowClick={updateReportId}
            selectedRow={id ? selectedRow : {}}
            idKey="id"
          />
        </ContentCard>
      </React.Fragment>
    );
  }
}

export default DeliveredReportIndex;
