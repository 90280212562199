import React, { useEffect } from 'react';
import { FilterWrapper, DateRangeHooks, StyledDropdown, SaveReportButton, ResetFilterButton } from '../../components';
import { NTF_OPTIONS, REPORT_TYPE_OPTIONS_FUNNEL_POSITION } from '../../constants/options'
import { segmentOptions } from '../../utils/options'
import FilterTouchPoint from './FilterTouchPoint';

const Filter = ({
  pathIdsByTiers, loading, setLoading, // data
  segsInfo, segId, setSegId, // conversion segment
  startDate, endDate, onDateChange,  // dates
  hasNtf, customerType, setCustomerType, // customer type
  reportType, setReportType, // report type
  filterTouchPoints, filterTouchpointsMethods // filters
}) => {

  const { minPathLen, any, first, mid, last, filterObj, applyFilters } = filterTouchPoints
  const { setMinPathLen, setAny, setFirst, setMid, setLast, setApplyFilters } = filterTouchpointsMethods 

  const SEGMENT_OPTIONS = segmentOptions(segsInfo)

  useEffect(() => {
    if (!hasNtf) setCustomerType('all')
  }, [hasNtf])

  return (
    <FilterWrapper 
      {...{ loading }}
      other={
        <>
          <StyledDropdown
            label="Conversion Type"
            options={SEGMENT_OPTIONS}
            value={parseInt(segId)}
            onChange={(e, {value}) => setSegId(value)}
          />
          <StyledDropdown
            label="Customer Type"
            disabled={!hasNtf}
            options={NTF_OPTIONS}
            value={customerType}
            onChange={(e, { value }) => setCustomerType(value)}
          />
          <StyledDropdown
            label="Report Type"
            options={REPORT_TYPE_OPTIONS_FUNNEL_POSITION}
            value={reportType}
            onChange={(e, { value }) => setReportType(value)}
          />
        </>
      }
      date={
        <DateRangeHooks {...{startDate, endDate, onDateChange}}/>
      }
      stickyRight={
        <>
          <SaveReportButton {...{ loading }} />
          <ResetFilterButton />
        </>
      }
      advancedFilters={<FilterTouchPoint
        {...{ filterObj, pathIdsByTiers, minPathLen, setMinPathLen, any, setAny, first, setFirst, mid, setMid, last, setLast, applyFilters, setApplyFilters, setLoading }}
      />}
    />
  )
}

export default Filter;
