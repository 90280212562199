import React, { useCallback, useMemo } from "react";
import { Form, Button, Message, Input } from "semantic-ui-react";
import {
  snowflakeFormStore,
  statusStore,
  warehouseStore
} from "./zustandStore";
import { ContentCard } from "@rockerbox/styleguide";
import BackfillButton from "./BackfillButton";
import { accountTypeOptions, snowflakeAccountNameMap } from "./constants";

const SnowflakeAccountConditionals = ({
  setClientUrl,
  setAccountType,
  accountType
}) => {
  const onSnowflakeAccount = useCallback((e, data) => {
    setAccountType(accountTypeOptions[0]["value"]);
  });
  const onReaderAccount = useCallback((e, data) => {
    setClientUrl("");
    setAccountType(accountTypeOptions[1]["value"]);
  });

  return (
    <Button.Group>
      <Button
        primary
        color="blue"
        size="large"
        onClick={onSnowflakeAccount}
        positive={accountType === accountTypeOptions[0]["value"]}>
        Use existing Snowflake instance
      </Button>
      <Button.Or />
      <Button
        primary
        color="blue"
        size="large"
        onClick={onReaderAccount}
        positive={accountType === accountTypeOptions[1]["value"]}>
        Use Rockerbox hosted Snowflake Account
      </Button>
    </Button.Group>
  );
};

const NotAvailableMessage = () => (
  <Message
    info
    icon="lightbulb outlines"
    content="We are excited to know you are interested. This feature is coming soon and we will let you know when it is available"
  />
);

const snowflakeSelector = state => [
  state.accountType,
  state.clientUrl,
  state.setAccountType,
  state.setClientUrl
];
const statusSelector = state => [
  state.setStatus,
  state.setupFormLoading,
  state.changeSetupFormLoading
];
const warehouseSelector = state => [state.fetchWarehouse];

const SnowflakeForm = props => {
  const [setStatus, setupFormLoading, changeSetupFormLoading] = statusStore(
    statusSelector
  );
  const [fetchWarehouse] = warehouseStore(warehouseSelector);
  const [
    accountType,
    clientUrl,
    setAccountType,
    setClientUrl
  ] = snowflakeFormStore(snowflakeSelector);
  const { username } = props;
  const onClientUrlChange = useCallback((e, data) => setClientUrl(data.value));
  const validateAccountUrl = useMemo(() => {
    //TODO: need to handle the edge case better

    // if (!clientUrl) {
    //   return { error: false };
    // }
    //
    // if (clientUrl.split(".").length < 5) {
    //   return {
    //     error: true,
    //     reason:
    //       "Incorrect format, should be {user}.{region}.{platform}.snowflakecomputing.com"
    //   };
    // }
    //
    // const region = clientUrl.split(".").slice(-4, -3)[0];
    // if (!Object.keys(snowflakeAccountNameMap).some(key => key === region)) {
    //   return { error: true, reason: `Invalid region: ${region}` };
    // }

    return { error: false, reason: null };
  }, [clientUrl]);

  return (
    <ContentCard
      title="Continue your snowflake setup"
      loading={setupFormLoading}>
      <p>
        Get started using the Rockerbox Snowflake connector by choosing how you
        want to integrate with Rockerbox
      </p>
      <Form>
        <Form.Group>
          <Form.Field>
            <SnowflakeAccountConditionals
              setClientUrl={setClientUrl}
              setAccountType={setAccountType}
              accountType={accountType}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          {accountType ? (
            accountType === "reader_account" ? (
              <NotAvailableMessage />
            ) : (
              <React.Fragment>
                <Form.Field width={7}>
                  <Form.Input
                    error={
                      validateAccountUrl["error"] && {
                        content: validateAccountUrl["reason"],
                        pointing: "below"
                      }
                    }
                    value={clientUrl}
                    onChange={onClientUrlChange}
                    disabled={accountType === "reader_account"}
                    placeholder={
                      "Enter the URL associatd with your Snowflake instance"
                    }
                  />
                </Form.Field>
                <BackfillButton
                  style={
                    validateAccountUrl["error"] ? { marginTop: "40px" } : {}
                  }
                  disabled={!clientUrl || validateAccountUrl["error"]}
                  text="Submit"
                  filter_id={1234}
                  accountType={accountType}
                  clientUrl={clientUrl}
                  username={username}
                />
              </React.Fragment>
            )
          ) : null}
        </Form.Group>
      </Form>
    </ContentCard>
  );
};

export default SnowflakeForm;
