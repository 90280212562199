import React from "react";
import { ContentCard, SplitLayout } from "@rockerbox/styleguide";
import { Grid, Segment, Icon, Popup, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { IntegrationLogo } from "../Integrations/IntegrationGallery";

export const ExperimentStateIcon = ({ state }) => {
  return state === "running" ? (
    <Icon color="orange" name="play circle" />
  ) : state === "archived" ? (
    <Icon name="archive" color="grey" />
  ) : (
    <Icon name="check circle" color="green" />
  );
};

const ExperimentCard = props => {
  const {
    id,
    title,
    segment_name,
    start_date,
    end_date,
    name,
    treamentsCount,
    num_days,
    state = "running",
    platform,
    baselineCampaignName
  } = props;

  return (
    <ContentCard
      title={
        <Popup
          trigger={
            <div className='ellipsed'>{name}</div>
          }
          content={name}
          inverted
          hoverable
        />
      }
      disableTitleOverflow
      titleLeftWidth={13}
      titleRightWidth={3}
      topRight={
        <>
          <ExperimentStateIcon state={state} />
          <p style={{ marginTop: "3px" }}>{state}</p>
        </>
      }>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column textAlign="left" width={3}>{`Conversion:`}</Grid.Column>
          <Grid.Column textAlign="left" width={5}>
            <Popup
              trigger={<b>{segment_name}</b>}
              content={segment_name}
              inverted
              hoverable
            />
          </Grid.Column>
          <Grid.Column textAlign="right">{`Start Date:`}</Grid.Column>
          <Grid.Column textAlign="right">
            <b>{start_date}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left" width={3}>{`Baseline:`}</Grid.Column>
          <Grid.Column textAlign="left" className="ellipsed" width={5}>
            <Popup
              trigger={<b>{baselineCampaignName}</b>}
              content={baselineCampaignName}
              inverted
              hoverable
            />
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            style={{
              paddingRight: "18px",
              paddingLeft: "5px"
            }}>{`End Date:`}</Grid.Column>
          <Grid.Column textAlign="right">
            <b>{end_date}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="left" width={3}>{`Tests:`}</Grid.Column>
          <Grid.Column textAlign="left" width={5}>
            <b>{treamentsCount}</b>
          </Grid.Column>
          <Grid.Column textAlign="right">{`Num Days:`}</Grid.Column>
          <Grid.Column textAlign="right">
            <b>{num_days}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns='equal'>
          <Grid.Column textAlign='left'>
            <IntegrationLogo
              platform={platform ? platform.split("_")[0] : platform}
              wrapClassName="flex-center-left"
              style={{ maxHeight: 42.5, marginBottom: -5 }}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="bottom" textAlign="right" floated="right">
            <Link to={`/v2/reporting/experiments/index/${id}`}>
              View Results
              <Icon name="angle right" style={{marginRight: -4}} />
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ContentCard>
  );
};

export default ExperimentCard;
