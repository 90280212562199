import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

class SingleDayPicker extends Component {
  state = {
    focused: null
  }

  render() {
    const { focused } = this.state;
    const { date, id, onChange } = this.props;
    return (
      <SingleDatePicker
        date={date}
        onDateChange={onChange}
        id={id}
        focused={focused}
        isOutsideRange={() => false}
        onFocusChange={({ focused }) => this.setState({ focused })}
        hideKeyboardShortcutsPanel={true}
        block={true}
      />
    )
  }

}


export default SingleDayPicker;
