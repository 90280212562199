import moment from 'moment';

export default {
  "datetime": {
    "monthly": (value) => moment(value).format("YYYY-MM-01"),
    "weekly": (value) => moment(value).startOf("week").format("YYYY-MM-DD"),
    "daily": (value) => moment(value).format("YYYY-MM-DD")
  },
  "number": {
    "sum": (values) => d3.sum(values),
    "percent-row": (values, { rowKey, colKey, rowSummary, colSummary }) => rowKey ? d3.sum(values)/rowSummary[rowKey] : d3.sum(values),
    "percent-col": (values, { rowKey, colKey, rowSummary, colSummary }) => colKey ? d3.sum(values)/colSummary[colKey] : d3.sum(values),
    "percent-all": (values, { rowKey, colKey, rowSummary, colSummary }) => colKey ? d3.sum(values)/d3.sum(Object.values(colSummary)) : d3.sum(values)
  },
  "string": {
    "string": (value) => value
  }
}
