import React, { useContext } from 'react';
import { CacheContext } from '../../../../utils/CacheContext';
import { getTierColors, getAccount, getAccountCurrency } from '../../../api/account';
import { getSegments } from '../../../api/attribution';

export const useGlobalState = () => {

  const [state, setState, Context] = useContext(CacheContext);
  const account = (Context.getCache(`account`, getAccount) || {})
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;
  const segments = Context.getCache(`segments`, getSegments) || [];
  const tierColors = Context.getCache(`tierColors`, getTierColors) || undefined;

  return {
    account,
    currencyCode,
    segments,
    tierColors,
  }
}
