import React from 'react'
import { Header, Table, Popup, Icon, Accordion } from 'semantic-ui-react'

const AmazonMWSAccount = ({ seller_id, marketplaces }) => {
  const sections = marketplaces.map(m =>
    <React.Fragment>
      <Header as='h3'>{m["Name"]}</Header>
      <Table celled compact="very" fluid size="small" columns={2}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Field</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(m).map(k =>
            <Table.Row key={k}>
              <Table.Cell>{k}</Table.Cell>
              <Table.Cell>{m[k]}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  )

  return (
    <Popup
      hoverable
      wide
      position="right center"
      trigger={
        <Icon
          circular
          size='small'
          name='info'
          className='explainer'
        />
      }
      content={sections}
    />
  )
}

export default AmazonMWSAccount
