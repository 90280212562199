import React, { useReducer, useMemo, useEffect } from 'react';
import { Input, Table, Dropdown } from 'semantic-ui-react';

import { forecastData } from './forecastData';
import { setInitialFieldValues, buildUpdateFieldValues } from './forecastHelpers';
import { buildForecastRows } from './forecastTableRows';

const options = [
  {text: "Recommended", value: "recommended"},
  {text: "Current", value: "current"},
  {text: "Custom", value: "custom"},
]

const Forecast = ({ responseCurves, selectedData, featureRecommendations }) => {

  const [fieldValues, setFieldValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {},
  );

  const updateFieldValues = buildUpdateFieldValues(fieldValues, setFieldValues)

  useEffect(
    () => setInitialFieldValues(featureRecommendations, setFieldValues),
    [responseCurves]
  )

  const forecastedData = useMemo(
    () => forecastData(responseCurves, fieldValues),
    [responseCurves, fieldValues]
  )

  const currentSpendValues = useMemo(
    () => Object.entries(fieldValues).reduce((p, [k, v]) => {
      return Object.assign(p, {[k]: { value: v.current}})
    }, {}),
    [responseCurves, fieldValues]
  )

  const currentForecastedData = useMemo(
    () => forecastData(responseCurves, currentSpendValues),
    [responseCurves, currentSpendValues]
  )

  const forecastRows = useMemo(
    () => buildForecastRows(fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData),
    [fieldValues, responseCurves, currentSpendValues, currentForecastedData, forecastedData]
  )

  return <>
    <h4>Forecast Return on Ad Spend</h4>
    <br />
    Adjust the budget for each strategy / channel to use the model to predict revenue 
    <Table>
      <Table.Row>
        <Table.Cell><Table.Header>Channel</Table.Header> </Table.Cell>
        <Table.Cell><Table.Header>Target Type</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Daily Budget</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Monthly Spend</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Predicted Revenue</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Current Revenue</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Percent Revenue Change</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Predicted ROI</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Current ROI</Table.Header></Table.Cell>
        <Table.Cell><Table.Header>Percent ROI Change</Table.Header></Table.Cell>
      </Table.Row>
    { 
      forecastRows.map(obj => {
        return <Table.Row>
          <Table.Cell>
            { obj.key }
          </Table.Cell>
          <Table.Cell>
            <Dropdown 
              selection {...{options}} value={obj.currentSelection}  
              onChange={(evt, { value }) => updateFieldValues(obj['key'], "selection", value ) }
            />
          </Table.Cell>
          <Table.Cell>
            <Input 
              disabled={obj.allowCustomInput}
              label="$"
              value={obj.targetValue} 
              onChange={(evt, { value }) => updateFieldValues(obj['key'], "value" , value ) }
            />
          </Table.Cell>
          <Table.Cell> { obj.estimatedMonthlyValue } </Table.Cell>
          <Table.Cell> { obj.revenueAtTargetValue } </Table.Cell>
          <Table.Cell> { obj.revenueAtCurrentValue } </Table.Cell>
          <Table.Cell> { obj.revenuePercentDiff } </Table.Cell>
          <Table.Cell> { obj.ROIAtTargetValue } </Table.Cell>
          <Table.Cell> { obj.ROIAtCurrentValue } </Table.Cell>
          <Table.Cell> { obj.ROIPercentDiff } </Table.Cell>
        </Table.Row>
      }) 
    }
    </Table>
  </>
}

export default Forecast;
