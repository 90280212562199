import React, { useCallback, useEffect, useState } from 'react';
import InviteUsersInput from '../../components/InviteUsers/inviteUsersInput';
import { getAccountUsers } from '../../api/account';

const FreeInviteUsers = ({advertiser, showTitle}) => {

  const title = showTitle == undefined ? true : showTitle
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAccountUsers().then((data) => {
      setUsers(data);
      setLoadingUsers(false);
    });
  }, []);

  const handleManageUpdate = useCallback(() => getAccountUsers().then((data) => setUsers(data)));
  return(
    <div className="free-invite-users">
      <div style={{padding: '25px 5px'}}>
        <h2>Invite your teammates to Rockerbox</h2>
        <InviteUsersInput {...{ handleManageUpdate, advertiser}} />
      </div>
    </div>
  );
}

export default FreeInviteUsers;
