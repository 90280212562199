import React, { useState } from 'react';
import { Button, Step } from 'semantic-ui-react';

const MultiStepLayout = (props) => {

  const [steps, setSteps] = React.useState(props.steps || [])
  const [activeStep, setActiveStep] = React.useState(steps && steps.length && steps[0].value)

  React.useEffect(() => setSteps(steps), [props.steps])

  const active = React.useMemo(() => steps.find(row => row.value == activeStep), [steps, activeStep])
  const ActiveComponent = active.as

  const activeIndex = React.useMemo(() => steps.indexOf(active), [active])
  const showSubmit = (activeIndex + 1) == steps.length
  const isDisabled = props.isDisabled || false

  const setNextStep = () => setActiveStep(steps[activeIndex+1].value)
  const onSubmit = props.onSubmit ?  props.onSubmit : () => {};

  return <>
    <Step.Group widths={steps.length} size="mini">
      { steps.map(({ value, text, icon}) => {
          return <Step active={activeStep == value} onClick={() => setActiveStep(value)}>
            <Step.Content>
              <Step.Title>{ text }</Step.Title>
            </Step.Content>
          </Step>
        })
      }
    </Step.Group>
    <ActiveComponent {...props} {...{activeStep, setActiveStep}}/>
    <div style={{position: "fixed", bottom: "20px", right: "20px" }}>
      { showSubmit ?
          <Button size='big' color='green' disabled={isDisabled} type="submit" content="Submit" icon='right check' labelPosition='right' onClick={onSubmit}/> :
          <Button size='big' disabled={isDisabled} as='a' primary content="Next" onClick={setNextStep} icon='right arrow' labelPosition='right' />
      }
    </div>

  </>
}

export default MultiStepLayout
