import React, { useContext, useState, useMemo } from 'react'
import { Header, Menu, Popup, Button } from 'semantic-ui-react'
import { BellIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations, getAllIntegrations } from '../../api/account'
import { getAnnouncements } from '../../api/attribution'

import { useTrackingParamAlerts } from '../../hooks/trackingParamAlerts'
import { buildPath } from '../../hooks/urlState'

const NotificationsMenu = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const announcements = Context.getCache(`announcements`, getAnnouncements) || []
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null
  const allIntegrations = Context.getCache(`allIntegrations`, getAllIntegrations) || []

  const [opened, setOpened] = useState(false)

  const trackingParamAlerts = useTrackingParamAlerts()
  const trackingParamNotifications = useMemo(() => {
    if (!trackingParamAlerts) return []
    return Object.entries(trackingParamAlerts).map(([k, v]) => ({
      ...v,
      platform: k,
      link: buildPath('advertisingTrackingParameters', { platformName: k }),
      displayName: allIntegrations.find(x => x.platform == k)?.display_name || k,
    }))
  }, [trackingParamAlerts, allIntegrations])

  const lapsedAuths = useMemo(() => {
    if (!authorizations) return []
    const auths = authorizations.flatMap(x => x.authorizations)
    return auths
      .filter(auth => !!auth.account_id && !auth.batch_platform && !auth.status)
      .map(auth => ({
        ...auth,
        link: `/auth/${auth.platform}?account_id=${auth.account_id}`,
        displayName: allIntegrations.find(x => x.platform == auth.platform)?.display_name || auth.platform,
      }))
  }, [authorizations, allIntegrations])

  const numNotifications = useMemo(() =>
    (announcements?.length || 0) + (lapsedAuths?.length || 0) + (trackingParamNotifications?.length || 0)
  , [announcements, lapsedAuths, trackingParamNotifications])

  return (
    <Popup
      transition={{ animation: 'fade down', duration: 500 }}
      trigger={
        <Menu.Item
          className='icon-button'
          content={<>
            <BellIcon />
            {!!numNotifications &&
              <span className='notification-count'>
                {numNotifications}
              </span>
            }
          </>}
          active={!!opened}
        />
      }
      basic
      on='click'
      position='bottom right'
      open={!!opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className='nav-dropdown-menu notifications-menu'
      content={<>
        <Header
          as='h3'
          content='Notifications'
        />
        <div className={`content ${!numNotifications && 'empty'}`}>
          {!numNotifications && <>
            <CheckCircleIcon className='icon' />
            <p>You have no notifications</p>
          </>}
          {!!announcements.length &&
            announcements.map((x, i) => (
              <div key={i} className='notification'>
                <div className='notification-content'>
                  <Header
                    as='h5'
                    content={<>
                      <ExclamationCircleIcon className='icon' />
                      {x.title}
                    </>}
                    subheader={x.message}
                  />
                </div>
              </div>
            ))
          }
          {!!lapsedAuths?.length &&
            <div className='section-heading action-required'>
              <ExclamationIcon className='icon' />
              <div className='text'>Action Required</div>
            </div>
          }
          {lapsedAuths.map((x, i) => (
            <div key={`auth-${i}`} className='notification'>
              <div className='notification-content'>
                <Header
                  as='h5'
                  content={`${x.displayName} Re-Authorization Required`}
                  subheader={`Your connection to ${x.account_name} (Account ${x.account_id}) needs to be renewed.`}
                  className='capitalize'
                />
              </div>
              <div className='notification-action'>
                <Button
                  fluid
                  primary
                  size='small'
                  content={`Reconnect ${x.displayName} Account`}
                  onClick={() => window.location = x.link}
                />
              </div>
            </div>
          ))}
          {!!trackingParamNotifications?.length &&
            <div className='section-heading needs-review'>
              <InformationCircleIcon className='icon' />
              <div className='text'>Needs Review</div>
            </div>
          }
          {trackingParamNotifications.map((x, i) => (
            <div key={`params-${i}`} className='notification'>
              <div className='notification-content'>
                <Header
                  as='h5'
                  content={`${x.displayName} Ads Untracked`}
                  subheader={!!x.numMissing ? `We've detected ${x.numMissing} ${x.displayName} ads that are missing Rockerbox parameters.`
                    : `We've detected clicks from ${x.displayName} ads that are missing Rockerbox parameters.`}
                  className='capitalize'
                />
              </div>
              <div className='notification-action'>
                <Button
                  as={Link}
                  to={x.link}
                  fluid
                  primary
                  size='small'
                  content={`Fix ${x.displayName} Tracking Parameters`}
                />
              </div>
            </div>
          ))}
        </div>
      </>}
    />
  )
}

export default NotificationsMenu
