import React from 'react';
import { Input, Label, Form, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/tomorrow';

const ACE_DEFAULTS = {
  style:{paddingTop: 5, border: "1px solid #ddd", borderRadius: "3px"},
  mode:"python",
  theme:"tomorrow",
  setOptions:{
    highlightGutterLine: false,
    showPrintMargin: false,
    showGutter: false,
    highlightActiveLine: false,
    fontSize: 10,
    indentedSoftWrap: false,
    wrap: true
  },
  width:'100%',
  height:'90px'
}

const INLINE_ACE_OPTIONS = {
  highlightGutterLine: false,
  showPrintMargin: false,
  showGutter: false,
  highlightActiveLine: false,
  fontSize: 10
}

const Inline = (props) => <AceEditor 
    {...Object.assign({},ACE_DEFAULTS,{setOptions: INLINE_ACE_OPTIONS})} 
    height="38px" 
    onLoad={function(editor){ editor.renderer.setPadding(8); editor.renderer.setScrollMargin(10); }}
    {...props}
  />

const input = (props) =>
  <AceEditor
    {...ACE_DEFAULTS}
    onLoad={function(editor){ editor.renderer.setPadding(8); editor.renderer.setScrollMargin(8); }}
    {...props}
  />
export default Object.assign({},{ Inline, Input: input });
