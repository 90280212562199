export const dataToRatio = (data) => {
  const totals = [];

  data.forEach(obj => {
    const sum = Object.values(obj).reduce((a, b) => a + ((typeof(b)=='number') && b), 0 )
    totals.push(sum)
  })

  data.forEach((obj, i) => {
    for (let key in obj) {
      if (typeof (obj[key]) === 'number') obj[key] = obj[key] / totals[i] * 100
    }
  })
  return data
}