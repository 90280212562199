import React, { Component } from "react";
import { IndexGrid, ContentCard } from "@rockerbox/styleguide";
import { Table } from "semantic-ui-react";
import RunButton from "../parts/RunButton";

export const optionConfig = {
  amazon_s3: {
    fields: [
      {
        name: "bucket_name",
        label: "Bucket Name"
      },
      {
        name: "root_directory",
        label: "Root Directory"
      }
    ],
    name: "Amazon S3",
    title: "Add S3 Delivery Option"
  },
  sftp: {
    fields: [
      { name: "username", label: "User Name" },
      { name: "hostname", label: "Host Name" },
      { name: "password", label: "Password" },
      { name: "root_directory", label: "Root Directory", default: "/" },
      { name: "port", label: "Port", default: "22" }
    ],
    name: "SFTP",
    title: "Add SFTP Delivery Option"
  },
  ftp: {
    fields: [
      { name: "username", label: "User Name" },
      { name: "hostname", label: "Host Name" },
      { name: "password", label: "Password" },
      { name: "root_directory", label: "Root Directory", default: "/" },
      { name: "port", label: "Port", default: "22" }
    ],
    name: "FTP",
    title: "Add FTP Delivery Option"
  },
  googlecloud_storage: {
    fields: [
      {
        name: "bucket_name",
        label: "Bucket Name"
      },
      {
        name: "root_directory",
        label: "Root Directory"
      }
    ],
    name: "Google Cloud Storage",
    title: "Add Google Cloud Delivery Option"
  },
  email: {
    name: "Email"
  },
  do_spaces: {
    name: "Email"
  }
};

class DeliveryOptionIndex extends Component {
  render() {
    const {
      setOutboxes,
      optionType,
      switchCurrentOutbox,
      updateReportId,
      loading,
      currentOutbox
    } = this.props;
    const outboxes = this.props.outboxes.filter(
      outbox => outbox.outbox_type === optionType
    );

    const ManageCell = ({ item, col }) => {
      return item.outbox_type === "do_spaces" ? (
        <Table.Cell> </Table.Cell>
      ) : (
        <Table.Cell textAlign="center" collapsing>
          <IndexGrid.SetupButton
            onClick={e => e.stopPropagation()}
            outboxId={item.id}
          />
        </Table.Cell>
      );
    };

    const cols = [
      { display: "Name", key: "name" },
      { display: "Outbox Type", key: "outbox_type" },
      {
        display: "Outbox Credential",
        key: "credential"
      },
      {
        display: "Target",
        key: "identifier"
      },
      {
        display: "Manage",
        key: "id",
        as: ManageCell,
        headerStyles: { textAlign: "center" }
      }
    ];

    return (
      <React.Fragment>
        <ContentCard
          hasTable
          title={`${optionConfig[optionType]["name"]} Outbox`}
          loading={loading}>
          <IndexGrid
            data={outboxes}
            cols={cols}
            collapsed
            as={ContentCard.Table}
            fallBackMsg="No delivery option currently available"
            onRowClick={e => {
              switchCurrentOutbox(e);
              updateReportId(e);
            }}
            selectedRow={currentOutbox}
          />
        </ContentCard>
      </React.Fragment>
    );
  }
}

export default DeliveryOptionIndex;
