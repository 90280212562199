import React from 'react'

const AppWrap = props => {

  return (
    <div className='App rockerbox' id='AppWrap'>
      {props.children}
    </div>
  )
}

export default AppWrap
