import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CustomLegend } from './parts';
import { LineChart } from '@rockerbox/styleguide';
import { COLORS, METRICS } from './constants';
import { calculateMinMax, groupAndCalculateMean } from './helpers';

// format x-axis ticks to show 3-digit month abbreviations, skipping repeats
let prevMonth = null;
const formatDate = (tickItem) => {
  const currentMonth = moment(tickItem).format('MMM');
  if (prevMonth === currentMonth) {
    return '';
  } else {
    prevMonth = currentMonth;
    return currentMonth;
  }
}

const LineChartWithLegend = ({ data, loading, platform }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeMetrics = platform === 'facebook' ? METRICS : METRICS.slice(2, 5);
    const metric = activeMetrics[activeIndex];

    useEffect(() => {
      prevMonth = null;
    }, [])

    useEffect(() => {
      setActiveIndex(0)
    }, [platform])

    return (
      <>
      {!loading && 
      <div className="chart-container">
        <div className="legend-container">
            <CustomLegend activeIndex={activeIndex} onClick={setActiveIndex} data={data} activeMetrics={activeMetrics} />
        </div>
        <div className="chart-wrapper" style={{ width: '100%', height: 400 }}>
          <LineChart
            height={400}
            data={groupAndCalculateMean(data, platform)}
            YAxisStyle={[
              {
                keyName: metric,
                color: COLORS[activeIndex],
              }
            ]}
            showTooltip
            showXAxis
            domainDefault={calculateMinMax(metric, groupAndCalculateMean(data, platform)).max}
            formatter={metric == 'roas' ? (v) => parseFloat(v.toFixed(2)) : metric == 'ctr' ? (v) => `${(v * 100).toFixed(2)}%` : (v) => `$${v.toFixed(2)}`}
            XAxisFormatter={formatDate}
            XAxisTickHeight={14}
            allCaps
            yAnchor={130}
          />
        </div>
      </div>
      
    }
    </>
    );
}
export default LineChartWithLegend;
