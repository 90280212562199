import React, { useContext, useMemo, useEffect, useState } from 'react'
import { Checkbox, Table, Button } from 'semantic-ui-react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'
import moment from 'moment'
import hash from 'object-hash'

import { IndexGridNew } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getSegments } from '../../api/attribution'
import { getOnsiteDataset } from '../../api/attributionReporting'
import { getPixelTrackingResolutions, postPixelTrackingResolution } from '../../api/attributionPixels'
import { TreeTableLoader } from '../../components/loaders'
import { track } from '../../../utils/tracking'
import { PagesCell, FeedbackCell, getUTMDisplayName } from './helpers'


// the tracking qa dataset is generated at 12:00 PM UTC every day
// so our definition of "yesterday" needs to be shifted by 13 hours
const YESTERDAY = moment.utc().utcOffset(-13).subtract(1, 'days').format('YYYY-MM-DD');
const STANDARD_UTMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]


const MissingParams = ({ platform }) => {
  const [state, setState, Context] = useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || null

  const [missingParamsData, setMissingParamsData] = useState(null)
  const [showResolved, setShowResolved] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const allPagesSegment = useMemo(() => {
    if (!segments) return null
    return segments.find(s => s.action_type === 'segment')
  }, [segments])

  useEffect(() => {
    if (!platform || !allPagesSegment) return

    Promise.all([
      getPixelTrackingResolutions(platform),
      getOnsiteDataset(allPagesSegment.action_id, 'tracking_qa_results', YESTERDAY, YESTERDAY),
    ]).then(([resolutions, data]) => {
      const trackingQAData = data?.tracking_qa_results?.[0] || {}
      const platformQAData = trackingQAData[platform] || {}

      const missingParams = platformQAData?.all?.['dataset:has_none']?.truncated_url || {}
      const missingParamsUrls = Object.values(missingParams)

      const missingParamsDataRaw = missingParamsUrls
        .map(url => {
          const urlObj = new URL(url)
          const params = {}
          urlObj.searchParams.forEach((v,k) => {
            if (k.startsWith('utm_')) {
              params[k] = v
            }
          })
          const rowHash = hash(params)
          return {
            ...params,
            rowHash,
            params,
            pages: [{
              url: `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`,
              path: urlObj.pathname == '/' ? 'home page' : urlObj.pathname,
            }],
            resolution: resolutions.find(r => r.hash == rowHash)?.resolution,
          }
        })
        .reduce((acc, curr) => {
          if (acc[curr.rowHash]) {
            acc[curr.rowHash].count++
            if (!acc[curr.rowHash].pages.find(x => x.path == curr.pages[0].path)) acc[curr.rowHash].pages.push(...curr.pages)
          } else {
            acc[curr.rowHash] = curr
            acc[curr.rowHash].count = 1
          }
          return acc
        }, {})

      const missingParamsData = Object.values(missingParamsDataRaw)
      setMissingParamsData(missingParamsData)
    })
    .catch(err => {
      console.error(err)
      setMissingParamsData([])
    })
  }, [platform, allPagesSegment])

  const filteredParamsData = useMemo(() => {
    if (!missingParamsData) return null
    if (showResolved) return missingParamsData
    return missingParamsData.filter(p => !p.resolution)
  }, [missingParamsData, showResolved])

  const hasIssues = useMemo(() => {
    if (!missingParamsData) return false
    return missingParamsData.some(p => !p.resolution)
  }, [missingParamsData])

  const PlatformFeedbackCell = useMemo(() => {
    return FeedbackCell(platform)
  }, [platform])

  const columns = useMemo(() => {
    if (!missingParamsData) return
    const utmsInData = missingParamsData.reduce((acc, curr) => {
      Object.keys(curr).forEach(k => {
        if (k.startsWith('utm_') && !acc.includes(k)) acc.push(k)
      })
      return acc
    }, [])

    const utmCols = STANDARD_UTMS.reduce((acc, utm) => {
      if (utmsInData.includes(utm)) {
        const utmRow = {
          display: getUTMDisplayName(utm),
          key: utm,
          isSearchable: true,
        }
        acc.push(utmRow)
      }
      return acc
    }, [])

    utmsInData.filter(utm => !STANDARD_UTMS.includes(utm)).forEach(utm => {
      const utmRow = {
        display: getUTMDisplayName(utm),
        key: utm,
        isSearchable: true,
      }
      utmCols.push(utmRow)
    })

    return [
      ...utmCols,
      { display: 'Pages', key: 'pages', isSearchable: true, as: PagesCell },
      { display: 'Clicks', key: 'count', textAlign: 'right', tooltip: 'Clicks tracked yesterday', as: IndexGridNew.NumberCell },
      { display: 'Resolution', key: 'resolution', textAlign: 'center', sortable: false, tooltip: 'Mark whether you\'ve resolved each issue or you want to silence the alert', as: PlatformFeedbackCell },
    ]
  }, [missingParamsData])

  const resolveAllIssues = () => {
    const unresolvedRows = missingParamsData.filter(p => !p.resolution)
    if (!unresolvedRows.length) return

    setSubmitting(true)
    Promise.all(unresolvedRows.map(x => postPixelTrackingResolution({
      platform,
      resolution: 'fixed',
      hash: x.rowHash,
      params: x.params,
      url: x.url,
    })))
    .then((res) => {
      const updatedData = missingParamsData.map(x => {
        if (!!x.resolution) return x
        return {
          ...x,
          resolution: 'fixed',
        }
      })
      setMissingParamsData(updatedData)

      const successCount = res.filter(x => !!x?.success)?.length
      toast.success(`${successCount} issues marked resolved`)
      setSubmitting(false)
      track('data.marketing.advertising_platforms.tracking.resolve', { platform, all: true, resolution: 'fixed' })
    })
    .catch(err => {
      console.error(err)
      toast.error('Error submitting resolution')
      setSubmitting(false)
    })
  }

  if (!missingParamsData) return <TreeTableLoader />

  return (
    <IndexGridNew
      celled
      data={filteredParamsData}
      cols={columns}
      sortable
      defaultSortCol='count'
      searchable
      alignSearchLeft
      placeholder='Search'
      emptyIcon='check'
      fallBackMsg='No Issues Found'
      topRight={!!missingParamsData?.length &&
        <Checkbox
          inverted
          color='purple'
          label='Show Resolved'
          className='header-checkbox'
          checked={showResolved}
          onChange={() => setShowResolved(!showResolved)}
        />
      }
      footer={hasIssues &&
        <Table.Footer fullWidth>
          <Table.Row>
          <Table.HeaderCell colSpan={columns.length - 1} />
            <Table.HeaderCell textAlign='center'>
              <Button
                inverted
                size='small'
                color='purple'
                content={<>
                  <CheckCircleIcon style={{ display: 'inline', height: 19, margin: '-5px 3px -5px -3px' }} />
                  {` Mark All Fixed`}
                </>}
                onClick={resolveAllIssues}
                loading={submitting}
                disabled={submitting}
                className='remove-box-shadow'
                style={{ margin: 0 }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      }
    />
  )
}

export default MissingParams
