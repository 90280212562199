/* eslint-disable max-len */
export const ALLOWED_PLATFORMS_MAPPING = {
  lite: ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'bing', 'linkedin'],
  starter: ['facebook', 'adwords', 'pinterest', 'snapchat', 'tiktok', 'bing', 'linkedin', 'criteo'],
};

export const colorNames = {
  green: '#66D9AE',
  orange: '#FFA278',
  purple: '#7C78DC',
  red: '#E76D8E',
  blue: '#0489c1',
  yellow: '#FFDF78',
  'dark-green': '#42C493',
  'dark-orange': '#DE794C',
  'dark-purple': '#5B57C8',
  'dark-red': '#C94568',
  'dark-blue': '#03638D',
  'dark-yellow': '#DEBC4C',
  'light-green': '#92EBC9',
  'light-orange': '#FFBC9E',
  'light-purple': '#A3A0EC',
  'light-red': '#F095AD',
  'light-blue': '#1298D2',
  'light-yellow': '#FFE89E',
};

export const donutColors = {
  filtered: '#6F7FE0',
  missing: '#BFC7F9',
};

export const channelPerformancePlatformsConfig = {
  adwords: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaignName' }, { text: 'Ad Group name', value: 'adGroupName' }],
    // "hierarchy": {"account_name": "Account Name", "campaignName": "Campaign Name", "adGroupName": "Ad Group Name"},
    object_columns: ['identifier', 'campaignName', 'campaignId', 'adGroupName', 'adGroupId'], // netwrok?
    base_metrics_columns: ['spend', 'impressions', 'clicks'], // blue cards - seems like they have specnd by origin
    default_metric: 'spend',
    default_conv: '',
    revenue_suffix: 'Value',
    revenue_formatter: attributionWindow => `${attributionWindow.replace('allConversionsValue', 'allConversions').replace('allConversions', 'allConversionsValue')}`, // NEEDS THE DOUBLE REPLACE TO REMOVE THE OPTION FROM LIST
    exclude_windows: ['clickThroughLookbackWindowDays', 'viewThroughConversions', 'viewThroughLookbackWindowDays'],
    default_attribution_window: 'allConversionsByConversionDate',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Adwords',
  },
  bing: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'CampaignName' }, { text: 'Ad Group name', value: 'AdGroupName' }, { text: 'Ad title', value: 'AdTitle' }],
    // "hierarchy": {"account_name": "Account Name", "CampaignName": "Campaign Name", "AdGroupName": "Ad Group Name", "AdTitle": "Ad Title"},
    object_columns: ['identifier', 'CampaignName', 'CampaignId', 'AdGroupName', 'AdGroupId', 'AdTitle', 'AdId'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'AllConversionsQualified',
    default_attribution_window: 'conversion_analytics',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'Bing',
  },
  facebook: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Adset name', value: 'adset_name' }, { text: 'Ad name', value: 'ad_name' }],
    object_columns: ['identifier', 'campaign_id', 'campaign_name', 'adset_id', 'adset_name', 'ad_id', 'ad_name'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'offsite_conversion.fb_pixel_purchase',
    revenue_suffix: '_value',
    revenue_formatter: attributionWindow => `${`${attributionWindow.replace('_value', '')}_value`}`, // NEEDS THE DOUBLE REPLACE TO REMOVE THE OPTION FROM LIST
    exclude_windows: ['1d_click_original_currency_value', '1d_view_original_currency_value', '7d_click_original_currency_value', '28d_view', '28d_click', '7d_view'],
    default_attribution_window: '7d_click_1d_view',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Facebook',
  },
  linkedin: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign Group name', value: 'campaignGroupName' }, { text: 'Campaign name', value: 'campaignName' }, { text: 'Campaign type', value: 'campaignType' }],
    // "hierarchy": {"account_name":"Account Name", "campaignGroupName": "Campaign Group Name", "campaignName": "Campaign Name", "campaignType": "Campaign Type"}, //creativeType / creativeId
    object_columns: ['identifier', 'campaignGroupName', 'campaignGroupId', 'campaignName', 'campaignId', 'campaignType', 'creativeType', 'creativeId'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: '', // unknown
    default_attribution_window: 'externalWebsiteConversions',
    exclude_windows: ['conversionValue', 'postClickAttributionWindowSize', 'viewThroughAttributionWindowSize'],
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'LinkedIn',
  },
  pinterest: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'CAMPAIGN_NAME' }, { text: 'Ad Group name', value: 'AD_GROUP_NAME' }, { text: 'Ad name', value: 'PIN_PROMOTION_NAME' }],
    // "hierarchy": {"account_name":"Account Name", "CAMPAIGN_NAME": "Campaign Name", "AD_GROUP_NAME": "Ad Group Name", "AD_ID": "Ad ID"},
    object_columns: ['identifier', 'CAMPAIGN_NAME', 'CAMPAIGN_ID', 'AD_GROUP_NAME', 'AD_GROUP_ID', 'PIN_PROMOTION_NAME', 'AD_ID'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'TOTAL_CHECKOUT',
    default_attribution_window: '1D_VIEW',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'Pinterest',
  },
  snapchat: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Ad Squad name', value: 'ad_squad_name' }, { text: 'Ad name', value: 'ad_name' }],
    object_columns: ['identifier', 'campaign_name', 'campaign_id', 'ad_squad_name', 'ad_squad_id', 'ad_name', 'ad_id'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'purchases',
    revenue_suffix: '_value',
    revenue_formatter: attributionWindow => `${`${attributionWindow.replace('_value', '')}_value`}`,
    default_attribution_window: 'swipe_28_day',
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa', 'revenue', 'roas'],
    display_platform_name: 'Snapchat',
  },
  tiktok: {
    hierarchy: [{ text: 'Account name', value: 'account_name' }, { text: 'Campaign name', value: 'campaign_name' }, { text: 'Ad Group name', value: 'adgroup_name' }, { text: 'Ad name', value: 'ad_name' }],
    // "hierarchy": {"account_name": "Account Name", "campaign_name": "Campaign Name", "adgroup_name": "Ad Group Name", "ad_name": "Ad Name"},
    object_columns: ['identifier', 'campaign_id', 'campaign_name', 'adgroup_id', 'adgroup_name', 'ad_id', 'ad_name'],
    base_metrics_columns: ['spend', 'impressions', 'clicks'],
    default_metric: 'spend',
    default_conv: 'conversion',
    default_attribution_window: 'conversion_analytics', // conversion_analytics
    default_metrics: ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'],
    optional_metrics: ['conversions', 'cpa'],
    display_platform_name: 'TikTok',
  },
};
