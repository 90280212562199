import { trackingMap, trackingSummOrder } from './constants'
import aggEventsSummary from './aggEventsSummary'
import * as d3 from 'rockerbox_d3_legacy_clone'

const aggData = (isDataMissing, data, currency, tooltips) => {
  if (isDataMissing) {
    return [null, null]
  }
  const totalConvs = data.order_tracking_summary.reduce((total, obj) => obj.total_orders + total, 0)
  const totalRevs = data.order_tracking_summary.reduce((total, obj) => obj.total_revenue + total, 0)
  const eventTypeSummary = aggEventsSummary(data.event_type_summary, totalConvs, totalRevs, currency, tooltips)
  const orderedTracking = trackingSummOrder.map(key => data.order_tracking_summary.find(({ category }) => category === key))
  const trackingSummary = (d3.nest()
    .key(row => (trackingMap[row['category']]))
    .entries(orderedTracking)
    .flatMap(({ key, values }) => {
      const label = key
      const value = values.reduce((p, row) => p + row.total_orders, 0)
      const conversions = value
      if (totalRevs != 0) {
        const revenue = values.reduce((p, row) => p + row.total_revenue, 0)
        return (value === 0 ? [] : { label, value, conversions, revenue })
      }
      return (value === 0 ? [] : { label, value, conversions })
    }))
  return ([eventTypeSummary, trackingSummary])
}

export default aggData