import React from 'react'
import { Image } from 'semantic-ui-react'
import { generateTrendCards } from './helpers'
import { updateCols } from '../helpers'
import { formatNumber } from '../Comparison/helpers'

import TrendCards from "../../../components/TrendCards"

const Statistic = ({option, integrations, platform, activeMetric, currencyCode}) => {
  const active = activeMetric == option.value
  return (
    <>
      {(integrations && platform && activeMetric) && <Image size='tiny' src={integrations[platform][`${'icon'}`]} style={{ margin: '-4px 5px 0 0', width: 20, height: 20 }} />}
      {formatNumber(option, option.mainNumber, currencyCode)}
    </>
  )
}

export const TrendCardsFromPerformanceData = (props) => {
  const { splitPerformanceData, metrics_group, integrations, platform, activeMetric, currencyCode } = props
  const [testObj, baselineObj, testDays, baselineDays] = splitPerformanceData || [{}, {}, {}, {}, {}, {}];
  const cardParams = { testObj, baselineObj, testDays, baselineDays, currencyCode }

  const cards = React.useMemo(() => {
    return generateTrendCards({ ...cardParams, metrics_group })
      .map(option => {
        // pre-format values to display in card, this map can be done elsewhere
        option.heading = option.text,
        option.statistic = <Statistic {...{option, integrations, platform, activeMetric, currencyCode}}/>
        return option
      })

  }, [cardParams])

  return <TrendCards
    {...props}
    defaultCard={TrendCards.TrendingCard}
    options={cards}
    loading={!splitPerformanceData}
  />
}

export default TrendCardsFromPerformanceData;
