import React from 'react'
import ContentLoader from 'react-content-loader'

export const TreeTableLoader = () => (<>
  <ContentLoader viewBox="0 0 1232 292" width={window.innerWidth - 60} height={292} preserveAspectRatio="none" foregroundColor="#e5e5e5">
    <path d="M1228,39.84H4c-2.21,0-4-1.79-4-4V4c0-2.21,1.79-4,4-4l1224,0c2.21,0,4,1.79,4,4v31.84 C1232,38.05,1230.21,39.84,1228,39.84z"/>
    <path d="M1228,45H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V49C1232,46.79,1230.21,45,1228,45z M1230.09,76.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4V50.53c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V76.42z"/>
    <circle cx="19.48" cy="63.48" r="9.74"/>
    <path d="M113.91,70.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,68.69,115.57,70.04,113.91,70.04z"/>
    <path d="M507.56,69.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,67.88,509.21,69.23,507.56,69.23z"/>
    <path d="M872.56,69.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,67.88,874.21,69.23,872.56,69.23z"/>
    <path d="M1109.56,69.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,67.88,1111.21,69.23,1109.56,69.23z"/>
    <path d="M1228,87H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V91C1232,88.79,1230.21,87,1228,87z M1230.09,118.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4V92.53c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V118.42z"/>
    <circle cx="19.48" cy="105.48" r="9.74"/>
    <path d="M113.91,112.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,110.69,115.57,112.04,113.91,112.04z"/>
    <path d="M507.56,111.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,109.88,509.21,111.23,507.56,111.23z"/>
    <path d="M872.56,111.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,109.88,874.21,111.23,872.56,111.23z"/>
    <path d="M1109.56,111.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,109.88,1111.21,111.23,1109.56,111.23z"/>
    <path d="M1228,129H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V133C1232,130.79,1230.21,129,1228,129z M1230.09,160.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V160.42z"/>
    <circle cx="19.48" cy="147.48" r="9.74"/>
    <path d="M113.91,154.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,152.69,115.57,154.04,113.91,154.04z"/>
    <path d="M507.56,153.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,151.88,509.21,153.23,507.56,153.23z"/>
    <path d="M872.56,153.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,151.88,874.21,153.23,872.56,153.23z"/>
    <path d="M1109.56,153.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,151.88,1111.21,153.23,1109.56,153.23z"/>
    <path d="M1228,171H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V175C1232,172.79,1230.21,171,1228,171z M1230.09,202.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V202.42z"/>
    <circle cx="19.48" cy="189.48" r="9.74"/>
    <path d="M113.91,196.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,194.69,115.57,196.04,113.91,196.04z"/>
    <path d="M507.56,195.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,193.88,509.21,195.23,507.56,195.23z"/>
    <path d="M872.56,195.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,193.88,874.21,195.23,872.56,195.23z"/>
    <path d="M1109.56,195.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,193.88,1111.21,195.23,1109.56,195.23z"/>
    <path d="M1228,213H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V217C1232,214.79,1230.21,213,1228,213z M1230.09,244.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V244.42z"/>
    <circle cx="19.48" cy="231.48" r="9.74"/>
    <path d="M113.91,238.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,236.69,115.57,238.04,113.91,238.04z"/>
    <path d="M507.56,237.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,235.88,509.21,237.23,507.56,237.23z"/>
    <path d="M872.56,237.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,235.88,874.21,237.23,872.56,237.23z"/>
    <path d="M1109.56,237.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,235.88,1111.21,237.23,1109.56,237.23z"/>
    <path d="M1228,255H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V259C1232,256.79,1230.21,255,1228,255z M1230.09,286.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V286.42z"/>
    <circle cx="19.48" cy="273.48" r="9.74"/>
    <path d="M113.91,280.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,278.69,115.57,280.04,113.91,280.04z"/>
    <path d="M507.56,279.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,277.88,509.21,279.23,507.56,279.23z"/>
    <path d="M872.56,279.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,277.88,874.21,279.23,872.56,279.23z"/>
    <path d="M1109.56,279.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,277.88,1111.21,279.23,1109.56,279.23z"/>
  </ContentLoader>
</>)