import React from 'react'
import { connect } from 'react-redux'
import { ContentCard, Checklist, AlertCard } from '@rockerbox/styleguide'
import { Button, Grid, Embed, Header, List } from 'semantic-ui-react'

const HelpResources = ({ helpLinks }) => (
  <ContentCard>
    <Grid divided>
      <Grid.Row>
        <Grid.Column width={5}>
          <Embed
            id='368414923'
            placeholder='https://images.rockerbox.com/shopify-video-thumbnail.jpg'
            source='vimeo'
          />
        </Grid.Column>
        <Grid.Column width={11}>
          <Header
            as='h2'
            content='Rockerbox Resources'
          />
          <List relaxed>
            {helpLinks.map(x =>
              <HelpDocLink {...x} key={x.title} />
            )}
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </ContentCard>
)

const HelpDocLink = ({ icon, link, title, description }) => (
  <List.Item>
    <List.Icon name={icon} />
    <List.Content>
      <List.Header
        as='a'
        href={link}
        target='_blank'
        rel='noopener noreferrer'
      >
        {title}
      </List.Header>
      <List.Description>
        {description}
      </List.Description>
    </List.Content>
  </List.Item>
)

function mapStateToProps(state, props) {
  return {
    helpLinks: JSON.parse(state.app.artifacts.shopify_help_links)
  }
}

const ConnectedHelpResources = connect(mapStateToProps, null)(HelpResources)

export default ConnectedHelpResources
