import React, { useEffect, useState } from "react"
import { Header, Segment, Popup, Icon } from "semantic-ui-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { HeartIcon, FireIcon, SwitchVerticalIcon, ChevronDoubleRightIcon, CashIcon, CurrencyDollarIcon } from '@heroicons/react/solid'
import Graphic from './Graphic'
import { Subheading, Bold, iconStyles } from './stateless'
import { highRevenueLowVolume, highVolumeLowRevenue } from './keyInsightsFormulas'
import { NoDataCat } from "../../../components";

const KeyInsights = ({ filtersAndDates, customerPaths, summaryStats, newCustomers, tierColors }) => {
  const { customerType, hasNtf, hasRevenue, tier } = filtersAndDates
  const { conversions, totalPaths, averageRevenue } = summaryStats.filteredSummary
  const avgConv = conversions / totalPaths
  const [filteredCustomerPaths, setFilteredCustomerPaths] = useState([])
  const hasPlural = tier >= 3 ? null : 's';

  const filterOutDirect = (paths) => {
    return paths.filter((c => {
      const tier_1 = c.path.map(channel => channel[0])
      return !tier_1.includes('Direct')
    }))
  }

  useEffect(() => {
    const pathsWithoutDirect = filterOutDirect(customerPaths)
    const filtered = pathsWithoutDirect
      .filter((c => c.count > conversions * 0.05))

    const checkFiltered = tier >= 3 ?
      (filtered.length < 1 ? pathsWithoutDirect.slice(0, 1) : filtered) :
      (filtered.length < 3 ? pathsWithoutDirect.slice(0, 3) : filtered)
    
    const filteredWithDelta = checkFiltered.map(c => {
      const deltaRevenue = c.avg_revenue - averageRevenue
      const deltaConversions = c.count - avgConv

      c.deltaRevenue = deltaRevenue
      c.deltaConversions = deltaConversions
      
      return c
    })
    setFilteredCustomerPaths(filteredWithDelta)
  }, [customerPaths, summaryStats])

  const keyInsights = [
    {
      icon: <CashIcon style={iconStyles}/>,
      heading: 'Highest Earners',
      subheading: <Subheading>The following path{hasPlural} generated <Bold>highest average revenue</Bold> across all channels.</Subheading>,
      graphic: <Graphic {...{ filteredCustomerPaths, insightType: 'highestEarners', tierColors, tier }} />,
      hide: !hasRevenue
    },
    {
      icon: <ChevronDoubleRightIcon style={iconStyles}/>,
      heading: 'Fastest Movers',
      subheading: <Subheading>The following path{hasPlural} converted customers in the <Bold>lowest average conversion time</Bold> across all channels.</Subheading>,
      graphic: <Graphic {...{filteredCustomerPaths, insightType: 'fastestMovers', tierColors, tier}}/> 
    },
    {
      icon: <FireIcon style={iconStyles}/>,
      heading: 'Most Common Touchpoints',
      subheading: <Subheading>The following touchpoint{hasPlural} <Bold>appeared the most</Bold> across your selected conversions.</Subheading>,
      graphic: <Graphic {...{filteredCustomerPaths, insightType: 'mostCommon', tierColors, tier}}/>
    },
    {
      icon: <HeartIcon style={iconStyles}/>,
      heading: 'Most Popular',
      subheading: <Subheading>The following path{hasPlural} drove <Bold>the most new customers</Bold>.</Subheading>,
      graphic: <Graphic {...{ filteredCustomerPaths: newCustomers, insightType: 'mostPopular', tierColors, tier }} />,
      hide: !(hasNtf && customerType == 'all')
    },
    {
      icon: <SwitchVerticalIcon style={iconStyles}/>,
      heading: 'High Volume, Low Revenue',
      subheading: <Subheading>The following path{hasPlural} led to a <Bold>high number of conversions</Bold>, but generated relatively <Bold>low average revenue</Bold>.</Subheading>,
      graphic: <Graphic {...{ filteredCustomerPaths, insightType: 'highVolumeLowRevenue', tierColors, tier }} />,
      hide: !hasRevenue || highVolumeLowRevenue(filteredCustomerPaths).length === 0
    },
    {
      icon: <CurrencyDollarIcon style={iconStyles}/>,
      heading: 'High Revenue, Low Volume',
      subheading: <Subheading>The following path{hasPlural} had <Bold>high average revenue</Bold>, but relatively <Bold>low number of conversions</Bold>.</Subheading>,
      graphic: <Graphic {...{ filteredCustomerPaths, insightType: 'highRevenueLowVolume', tierColors, tier }} />,
      hide: !hasRevenue || highRevenueLowVolume(filteredCustomerPaths).length === 0
    }
  ]

  return (
    <Segment style={{padding: 30}}>
       <Popup 
        inverted 
        content={"Key Insights show paths that make up at least 5% your conversions, or the paths that make up the most conversions closest to 5%. Paths that make up less than 5% of your conversions can still be seen in the table view at the bottom of the page."} 
        trigger={<div style={{ position: 'absolute', top: 40, right: 30, zIndex: 2 }}>
          <Icon name='info circle' style={{color: '#94A1EF', fontSize: 20, height: 'auto', marginRight: 0}} />
          </div>
        } 
      />
      <Header as="h1" style={{ margin: 0, fontWeight: 'lighter' }}>
        Key Insights 
      </Header>
      {filteredCustomerPaths.length === 0 ?
        <NoDataCat />
        :
        <Carousel
          className="marketing-paths-carousel"
          showArrows={false}
          showIndicators={true}
          style={{ height: 300 }}
          renderIndicator={(onClickHandler, isSelected, index, label) => (
            <li className={`dot ${isSelected ? 'selected' : ''}`} onClick={onClickHandler}>
              {index + 1}
            </li>
          )}
        >
          {keyInsights
            .filter((insight) => !insight.hide)
            .map((insight) => (
              <div style={{ fontSize: '16px', textAlign: 'left' }}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.25em', marginTop: '1em' }}>
                  {insight.icon}
                  <h2 style={{ marginTop: 0 }}>{insight.heading}</h2>
                </div>
                {insight.subheading}
                {insight.graphic}
              </div>
            ))}
        </Carousel>}
    </Segment>
  );
}

export default KeyInsights
