import React, { Component } from "react";
import { Icon, Popup, Button, Table, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import d3 from "rockerbox_d3_legacy_clone";
import HorizontalStackedBar from "../../charts/HorizontalStackedBar";
import { metricMapper, setStyles } from "./goalCellHelpers";

// NumberCell and BounceRate cell variable
const localeOptions = {'minimumFractionDigits': 0, 'maximumFractionDigits': 2}

export const RecommendationCell = ({item }) => {
  if(!item.jotai_goal || !Object.keys(item.jotai_goal).length > 0) return null
  const { target, metric } = item.jotai_goal
  const { extreme_over_budget } = item

  if (item.depth > 0 || isNaN(target)) {
    return <Table.Cell> </Table.Cell>
  }

  const metricConfig = metricMapper[metric]
  const {formattedPercentage, increaseOrDecrease } = metricConfig.percentageFromGoal(target, item)
  const hidePercentage = increaseOrDecrease.type == "onTarget" || increaseOrDecrease.type == "insufficientData"
  const CustomIcon = increaseOrDecrease.icon

  return (
    <Table.Cell style={{textAlign: 'left', fontFeatureSettings: '"kern" 1, "tnum" 1', color: "#475ddc", fontWeight: 690, whiteSpace: 'nowrap'}}>
      <Popup hoverable={true} inverted content={increaseOrDecrease.tooltip(metricConfig.display_name, extreme_over_budget)} position='top center' trigger={<div>
        <CustomIcon style={{ display: 'inline-block', height: 20, margin: '0px 5px -7px 0px'}}/>
        {`${increaseOrDecrease.text} ${hidePercentage ? '' : formattedPercentage}`}
      </div>} />
      
    </Table.Cell>
  )
}

export const MetricVsGoalCell = ({item}) => {
  if(!item.jotai_goal || !Object.keys(item.jotai_goal).length > 0) return null
  if(isNaN(item.jotai_goal.target)) {
    return <Table.Cell></Table.Cell>
  }

  const { target, metric } = item.jotai_goal
  const metricConfig = metricMapper[metric]
  const { trendReversed, percentageFromGoal, format } = metricConfig
  const delta = item[metric] - target
  const formattedValue = format(delta)

  const { increaseOrDecrease } = percentageFromGoal(target, item)
  const onTarget = increaseOrDecrease.type == "onTarget" 
  const colorStyles = setStyles(onTarget, delta, trendReversed, increaseOrDecrease)

  return (
    <Table.Cell style={{textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <span style={Object.assign({padding: 5, borderRadius: 4}, colorStyles)}>
        { onTarget && delta > 0 ? `+${formattedValue}` : 
          onTarget && delta < 0 ? formattedValue:
          delta > 0 ? `+${formattedValue}` : formattedValue}
      </span>
    </Table.Cell>
  )
}

export const VisitorCpaCell = (spendFormatter) => ({ item, col, label }) => {
  return (
    <Table.Cell style={{textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {item['spend'] ? spendFormatter.format(item['spend'] / item['num_new_visitors']) : '-'}
    </Table.Cell>
  )
}

export const CpaCell = (conversionKey, spendFormatter) => ({ item, col, label }) => {
  return (
    <Table.Cell style={{textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {item['spend'] ? spendFormatter.format(item['spend'] / item[conversionKey]) : '-'}
    </Table.Cell>
  )
}

export const RoasCell = (revenueKey) => ({ item, col, label }) => {
  return (
    <Table.Cell style={{textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {item['spend'] ? d3.format(",.2f")(item[revenueKey] / item['spend']) : '-'}
    </Table.Cell>
  )
}

export const BounceRateCell = ({ item, col, label }) => {
  return (
    <Table.Cell style={{textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {((item['num_bounces']) * 100).toLocaleString("en-US", localeOptions)}%
    </Table.Cell>
  )
}

export const SpendCell = (spendFormatter) => ({ item, col, label }) => {
  const value = item[col.key]
  return (
    <Table.Cell style={{textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {value == Infinity ? "-" : spendFormatter.format(item[col.key])}
    </Table.Cell>
  )
}

export const NumberCell = ({ item, col, label }) => {
  const value = item[col.key]
  const number = typeof value == "number" ? value : 0
  return (
    <Table.Cell style={{textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {value == Infinity ? "-" : number.toLocaleString("en-US", localeOptions)}
    </Table.Cell>
  )
}

export const NumberCellTwoDecimals = ({ item, col, label }) => {
  const value = item[col.key]
  const number = typeof value == "number" ? value : 0

  return (
    <Table.Cell style={{textAlign: 'right', fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      <DataLabel {...{label, rightAlign: true}} />
      {number.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}
    </Table.Cell>
  )
}

export const PercentageCellOfTotal = (total) => ({ item, col, data }) => {
  const value = item[col.key]

  return (
    <Table.Cell style={{ textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      {`${((value / total * 100).toFixed(2))}%`}
    </Table.Cell>
  )
}

export const PercentageCellAvg = ({ item, col, data, label, showPercent = true }) => {
  const avg = item[col.key] ? (item[col.key] / col.reducer(data) * 100) : 0;
  return (
    <Table.Cell style={{ textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1'}}>
      {showPercent && `${(avg.toFixed(2))}%`}
    </Table.Cell>
  )
}

export const PercentageCellAvgAcrossForFunnelPosition = (colsForAvg) => ({ item, col, cols, label, showPercent = true }) => {
  const avg = item[col.key] ? item[col.key] / colsForAvg.reduce((acc, col) => acc + item[col], 0) * 100 : 0;
  return (
    <Table.Cell style={{ textAlign: 'right',  fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
      {`${parseInt(avg)}%`}
    </Table.Cell>
  )
}

// Two Numbers in 1 cell Starts here
export const NumberCellWithPercentage = ({ item, col, data, label, showPercent = true }) => {
  const avg = (item[col.key] / col.reducer(data) * 100).toLocaleString("en-US", localeOptions);
  return (
    <Table.Cell>
      {item[col.key].toLocaleString("en-US", localeOptions)} 
      { showPercent && <DataLabel label={`${avg}%`} />}
      <DataLabel {...{label}} />
    </Table.Cell>
  )
}

export const PercentageCellWithNumber = ({ item, col, data, label, showPercent = true }) => {
  const avg = item[col.key] ? (item[col.key] / col.reducer(data) * 100) : 0;
  const number = item[col.key] ? item[col.key] : 0;
  return (
    <Table.Cell>
      {showPercent && `${(avg.toFixed())}%`}
      { <DataLabel label={`${number.toLocaleString("en-US", localeOptions)}`} />}
      <DataLabel {...{label}} />
    </Table.Cell>
  )
}

export const PercentageCellWithNumberAcrossRow = ({ item, col, cols, label, showPercent = true }) => {
  const number = item[col.key] ? item[col.key] : 0;
  const avg = item[col.key] ? item[col.key] / cols.slice(1, 4).reduce((acc, col) => acc + item[col.key], 0) * 100 : 0;
  return (
    <Table.Cell>
      {`${parseInt(avg)}%`}
      { showPercent && <DataLabel label={number.toLocaleString("en-US", localeOptions)} />}
      <DataLabel {...{label}} />
    </Table.Cell>
  )
}


export const ProgressBar = (colors, items) => ({ item, cols }) => (
  <Table.Cell>
    <HorizontalStackedBar
      items={items}
      data={[item]}
      height={20}
      margin={"auto"}
      colors={colors}
    />
  </Table.Cell>
)

/* NameCell parts start (SummaryDot can be used as its own cell) */
export const SummaryDotCell = (dotColors) => ({ item, col }) =>  (
  <Table.Cell>
    <LabelSwatch
      style={{background: dotColors[item[col['key']]] || '#697AE5'}}
      size={16}
    />
  </Table.Cell>
)

const LabelSwatch = styled.div`
  display: inline-block;
  width: ${x => (x.size || 6) + "px"};
  height: ${x => (x.size || 6) + "px"};
  border-radius: ${x => ((x.size || 6)/2) + "px"};
  vertical-align: top;
  margin-left: 8px;
  background: ${props => props.color ? props.color : '#697AE5'};
  position: relative;
`

const ChevronDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${x => tinycolor(x.color).getBrightness() > 200 ? '#000' : '#fff'};

  i.icon.chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`

const Chevron = ({hasOpenChildren, hasChildren, color}) => (
  <ChevronDiv {...{ hasOpenChildren, color }}>
    {(!!hasChildren || !!hasOpenChildren) &&
      <Icon
        name={`chevron ${hasOpenChildren ? 'down' : 'right'}`}
        style={{opacity: hasOpenChildren ? 0.5 : 1}}
      />
    }
  </ChevronDiv>
)

const TierDot = ({ color, hasChildren, hasOpenChildren }) => (
  <div>
    <LabelSwatch color={color} size='19'>
      <Chevron {...{ hasOpenChildren, hasChildren, color }} />
    </LabelSwatch>
  </div>
)

const TierCell = ({ item, col, color, depth, hasChildren, hasOpenChildren }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    // position: 'relative'
  }}>
    {!!depth &&
      <div className='nest-bar' style={{background: color || '#697AE5'}} />
    }
    <TierDot {...{ color, hasChildren, hasOpenChildren }} />
    <div style={{
      verticalAlign:"top",
      display:"inline-block",
      padding:".75em", 
      fontSize: 14 - depth
    }}>
      {item[col.key]}
    </div>
  </div>
)

export const NameCell = (dotColors) => (props) => {
  const { item, col, data } = props;
  const { depth } = item;
  const color = dotColors[item["tier_1"]] || '#697AE5';

  const hasChildren = item.childKeys && item.childKeys.length > 0;
  const position = data.indexOf(item);
  const next = data[position+1] || {key:""};
  const hasOpenChildren = (next.key.includes(item.key) && next.depth > item.depth);

  return (
    <Table.Cell style={{padding:0}}>
      <div
        className='name-cell'
        style={{
          minHeight: "2.9em",
          border: 0,
          display: 'flex',
          marginLeft: depth * 16
        }}
      >
        <TierCell {...{item, col, color, depth, hasChildren, hasOpenChildren}} />
      </div>
    </Table.Cell>
  )
}
/* NameCell parts end */

// IndexGrid utility cells
export const Schedule = ({ item, col }) => {
  const little_time = new moment()
    .hours(item.time)
    .minutes(0)
    .format("LT");

  return (
    <Table.Cell>
      {item.days.split(",").length > 6
        ? "Every day"
        : item.days.replace(/,/g, ", ")}{" "}
      {little_time}
    </Table.Cell>
  );
};

export const CelledCheckmark = ({ item, col }) => (
  <Table.Cell>
    <Icon
      color={item[col.key] ? "green" : "yellow"}
      name={item[col.key] ? "checkmark" : "exclamation triangle"}
      size="large"
    />
  </Table.Cell>
);

export const TextOnly = ({ item, col }) => (
  <Table.Cell>
    {typeof item[col.key] == "object"
      ? item[col.key].map(item => item[col.displayValue]).join(", ")
      : item[col.key]}
  </Table.Cell>
);

export const Time = ({ item, col }) => {
  const time = new moment(item[col.key]).format("LLL");
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const LocalFromUTCTime = ({ item, col, UTCOffset }) => {
  const utc = new moment.tz(item[col.key], "UTC");
  const time = new moment(utc + UTCOffset).format("LLL");
  return <Table.Cell width="75">{time}</Table.Cell>;
};

export const SendButton = ({ item, script, send, popup }) => (
  <ButtonWithHover
    popupContent={popup || "Send"}
    positive
    icon="paper plane"
    onClick={() => send(item, script)}
  />
);

export const HistoryButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="View Status"
    icon="history"
    as={Link}
    to={`/v2/settings/reports/view/inspect/id/${itemId}`}
    onClick={onClick}
  />
);

export const SetupButton = ({ outboxId, onClick }) => (
  <ButtonWithHover
    popupContent="Setup Instruction"
    icon="file alternate outline"
    as={Link}
    to={`/v2/settings/destination/view/outbox/id/${outboxId}`}
    onClick={onClick}
  />
);

export const ViewReportButton = ({ itemId }) => (
  <ButtonWithHover
    popupContent="View Report"
    icon="eye"
    as={Link}
    to={`/v2/settings/conversion/view/${itemId}`}
  />
);

export const DocsButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="View Schema"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/schema/id/${itemId}`}
  />
);

export const CustomizeButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    primary
    popupContent="Customize Columns"
    icon="file alternate"
    onClick={onClick}
    as={Link}
    to={`/v2/settings/reports/view/customize/id/${itemId}`}
  />
);

export const DownloadButton = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="Download"
    icon="download"
    as={Link}
    to={`/data/delivered/space/download?id=${itemId}`}
    target="_blank"
  />
);

export const ButtonWithHover = props => (
  <Popup
    inverted
    basic={props.noArrow}
    content={props.popupContent}
    trigger={<Button {...props} />}
  />
);

export const Spacer = () => (
  <Button icon="paper plane" inverted disabled className="visibility-hidden" />
);

export class DeleteButton extends Component {
  state = {
    popupOpen: false
  };

  onDeleteClick = (e, data) => {
    this.props.onDelete(e, data);
    this.setState({ popupOpen: false });
  };

  handleClose = e => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { disabled, popupContent } = this.props;

    if (disabled) {
      return (
        <ButtonWithHover
          negative
          noArrow={true}
          popupContent={popupContent}
          icon="trash alternate"
        />
      );
    }
    return (
      <Popup
        onClose={this.handleClose}
        open={this.state.popupOpen}
        on="click"
        trigger={
          <Button
            {...this.props}
            negative
            icon="trash alternate"
            onClick={e => {
              e.stopPropagation();
              this.setState({ popupOpen: true });
            }}
          />
        }
        content={
          <Popup
            trigger={
              <Button
                {...this.props}
                negative
                content={this.props.popup || "Delete"}
                onClick={this.onDeleteClick}
                onMouseOut={this.handleClose}
              />
            }
            on="hover"
            content={this.props.popupContent || "Delete"}
          />
        }
      />
    );
  }
}

export class DeactivateButton extends Component {
  state = {
    popupOpen: false
  };

  onActionClick = e => {
    this.props.onChange(e);
    this.setState({ popupOpen: false });
  };

  handleClose = e => {
    this.setState({ popupOpen: false });
  };

  handleClick = e => {
    e.stopPropagation();
    this.setState({ popupOpen: true });
  };

  render() {
    return (
      <Popup
        onClose={this.handleClose}
        open={this.state.popupOpen}
        on="click"
        trigger={
          this.props.active == 1 ? (
            <Button icon="pause" onClick={this.handleClick} />
          ) : (
            <Button icon="play" onClick={this.handleClick} />
          )
        }
        content={
          <Button
            content={
              this.props.popup || this.props.active == 1
                ? "Deactivate"
                : "Activate"
            }
            onClick={this.onActionClick}
            onMouseOut={this.handleClose}
          />
        }
      />
    );
  }
}

export const ViewButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="View"
    onClick={onClick}
    icon="eye"
    as={Link}
    to={url}
  />
);

export const IconButton = ({
  col: { popupContent = "edit", icon = "pencil", onClick }
}) => {
  return (
    <ButtonWithHover
      popupContent={popupContent}
      onClick={onClick}
      icon={icon}
    />
  );
};

export const EditButton = props => (
  <ButtonWithHover
    onClick={props.onClick}
    icon="pencil"
    as={Link}
    to={props.url}
    {...props}
    popupContent={props.popupContent || "Edit"}
  />
);

export const TransformButton = props => (
  <ButtonWithHover
    onClick={props.onClick}
    icon="exchange"
    as={Link}
    to={props.url}
    {...props}
    popupContent={props.popupContent || "Transform"}
  />
);

export const CopyButton = ({ url, onClick }) => (
  <ButtonWithHover
    popupContent="Copy"
    onClick={onClick}
    icon="copy"
    as={Link}
    to={url}
  />
);

export const MatchingUrlsButton = ({ url }) => (
  <ButtonWithHover
    popupContent="View Matching URLs"
    icon="eye"
    as={Link}
    to={url}
    color="black"
  />
);

export const EllipsedCell = ({ item, col, style={} }) => (
  <Table.Cell className="ellipsed" style={style}>
    <Popup
      trigger={<span>{item[col.key]}</span>}
      content={item[col.key]}
      inverted
      hoverable
    />
  </Table.Cell>
);

export const UrlCell = ({ item, col }) => {
  const urlArray = item[col.key].split(/\?|&/);
  const urlDomain = urlArray[0];
  const urlParams = urlArray
    .filter(p => p !== urlDomain)
    .map(p => (
      <Table.Row key={p}>
        <Table.Cell>{p.split("=")[0]}</Table.Cell>
        <Table.Cell>{p.split("=")[1]}</Table.Cell>
      </Table.Row>
    ));

  return (
    <Table.Cell className="ellipsed">
      <Popup
        hoverable
        wide
        position="bottom left"
        trigger={<span>{item[col.key]}</span>}
        content={
          <React.Fragment>
            <Header as="h6" content={urlDomain} className="m-0" disabled />
            <Table celled compact="very" fluid size="small" columns={2}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Parameter</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{urlParams}</Table.Body>
            </Table>
          </React.Fragment>
        }
      />
    </Table.Cell>
  );
};

export const CollapsingCell = ({ item, col }) => (
  <Table.Cell collapsing style={{ minWidth: 70 }}>
    {item[col.key]}
  </Table.Cell>
);

export const ExpandContractCell = ({
  item,
  col,
  idKey,
  handleExpand,
  handleContract,
  expand,
  ownComponent
}) => {
  if (ownComponent) {
    return (
      <Button
        as="a"
        size="mini"
        icon={expand == item[idKey] ? "minus" : "plus"}
        onClick={() => {
          return expand == item[idKey]
            ? handleContract(item[idKey])
            : handleExpand(item[idKey]);
        }}
      />
    );
  } else {
    return (
      <Table.Cell>
        <Button
          as="a"
          size="mini"
          icon={expand == item[idKey] ? "minus" : "plus"}
          onClick={() => {
            return expand == item[idKey]
              ? handleContract(item[idKey])
              : handleExpand(item[idKey]);
          }}
        />
      </Table.Cell>
    );
  }
};

export const DownloadCell = ({ itemId, onClick }) => (
  <ButtonWithHover
    popupContent="Download All Reports"
    icon="download"
    size="mini"
    onClick={onClick}
  />
);

const DataLabel = ({ label, rightAlign }) => <>
  {!!label && <span className={rightAlign ? 'data-label-left' : 'data-label'}>{label}</span>}
</>
