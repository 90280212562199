import React, { useContext, useMemo } from 'react'
import { Header } from 'semantic-ui-react'
import { ArrowCircleRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

import { dataSpendImage } from '@rockerbox/styleguide'
import { CacheContext } from '../../../utils/CacheContext'
import { getAuthorizations } from '../../api/account'
import * as routes from '../../baseRoutes'


const ConnectPromo =() => {
  const [state, setState, Context] = useContext(CacheContext)
  const authorizations = Context.getCache(`authorizations`, getAuthorizations) || null

  const hasSpendIntegrations = useMemo(() =>
    !!authorizations && authorizations.filter(x => x.platform !== 'shopify').length
  , [authorizations])

  if (hasSpendIntegrations) return null

  return (
    <Link to={routes.connectAdvertisingPlatform} className='connect-platforms-promo'>
      <div className='image-wrap'>
        <img src={dataSpendImage} />
      </div>
      <div className='text-wrap'>
        <Header as='h2' inverted>
          <Header.Content>
            How does your marketing stack up against the rest of the industry?
            <Header.Subheader>
              Connect your advertising platforms to view detailed performance reports
            </Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div className='icon-wrap'>
        <ArrowCircleRightIcon />
      </div>
    </Link>
  )
}

export default ConnectPromo
