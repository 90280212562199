import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { ContentCard, IndexGrid, PagedIndexGrid, SortableIndexGrid} from '@rockerbox/styleguide'
import { getSegments, getEventIssues } from '../../utils/api';

export default class EventsIssues extends Component {

  state = {
    loading: false,
    issues: [],
    data: []
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const check = ["id", "date"]

    if(check.filter(k => prevProps[k] != this.props[k]).length > 0) {
      this.getData();
    }
  }

  getData = () => {
    const { date } = this.props;
    this.setState({ loading: true })

    getEventIssues(this.props.id, date)
      .then( issues => {
        this.setState({ issues, loading: false });
      });

  }

  render() {

    const { date } = this.props;
    const { issues, loading } = this.state;
    const forceLoading = loading;

    const dataCols = issues.length ? [
      { display: "Platform", key: "platform", as: IndexGrid.EllipsedCell, headerWidth: 2},
      { display: "Issue", key: "error", as: IndexGrid.EllipsedCell, headerWidth: 7},
      { display: "Events Impacted", key: "count", headerWidth: 2},

    ] : []

    return (
      <React.Fragment>
        <ContentCard title={`Event Issues (${date})`} hasTable {...{forceLoading}}>
          <IndexGrid as={ContentCard.Table} fallBackMsg="No event issues detects" cols={dataCols} data={issues} singleLine />
        </ContentCard>
      </React.Fragment>
    )
  }
}
