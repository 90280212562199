import React, { useState, useEffect } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { SearchField, IndexGridNew } from '@rockerbox/styleguide';
import { useConversionEntityNameMemo, useMissingAssignedCodesMemo } from '../hooks/memo';

const AddCell = ({ item, onAdd }) => {
  const onClick = event => onAdd(event, { value: item });
  return (
    <Table.Cell>
      <Button size="mini" icon="plus" compact {...{ onClick }}>
        Add
      </Button>
    </Table.Cell>
  );
};

const columns = [
  { display: 'Promo Code', key: 'promoCode' },
  { display: 'Usage', key: 'count' },
  { display: 'Discount Type', key: 'discount_type' },
  { display: 'Discount Amount', key: 'discount_amount' },
  { display: '', key: 'discount_amount', as: AddCell },
];

const FindPromoCode = ({ onAdd, conversionData, entityConfigs, promo_field }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);

  const conversionsWithEntityName = useConversionEntityNameMemo(conversionData, entityConfigs, promo_field);
  const { missingPromoCodes } = useMissingAssignedCodesMemo(conversionsWithEntityName);

  (missingPromoCodes || []).forEach(row => {
    delete row.values;
  });

  useEffect(() => {
    const filterLower = (filter || '').toLowerCase();
    const filteredPromo = missingPromoCodes ? missingPromoCodes.filter(row => !filter || row.promoCode.toLowerCase().includes(filterLower)) : [];

    setFilteredData(filteredPromo);
  }, [missingPromoCodes, filter]);

  return (
    <>
      <SearchField onChange={(evt, { value }) => setFilter(value)} />
      {filteredData.length === 0 && (
        <div style={{ margin: '10px', textAlign: 'center' }}>
          No Matching Unassigned Promocodes Found
        </div>
      )}
      <IndexGridNew
        onAdd={onAdd}
        sortable
        paginate={!!missingPromoCodes}
        itemsPerPage={20}
        data={filteredData}
        cols={columns}
      />
    </>
  );
};

export default FindPromoCode;
