import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IndexGrid, ContentCard, SplitLayout } from '@rockerbox/styleguide';
import { Table, Button, Modal, Form, Menu, Icon, Header } from 'semantic-ui-react';
import { Checkbox } from 'semantic-ui-react'
import { updateBatchConfig } from '../../../utils/api';
import * as routes from '../../../routes';

const ManageCell = ({item, col}) => {
  return (
    <Table.Cell collapsing>
      <IndexGrid.EditButton url={`${routes.batchConfigEdit}/${item.id}`}/>
      <IndexGrid.ViewButton url={`${routes.batchConfigView}/${item.id}`}/>
    </Table.Cell>
  )
}

const ToggleCheckbox = ({ item }) => (
    <Table.Cell collapsing>
      <Checkbox toggle
        defaultChecked={item.active}
        onClick={() => manageActive(item)} />
    </Table.Cell>
)

const cols = [
  {display: 'Active', as: ToggleCheckbox, key: 'active', headerWidth: 1},
  {display: 'Proc ID', key: 'id', headerWidth: 1},
  {display: 'Name', key: 'name', headerWidth: 4},
  {display: 'Inbox Type', key: 'inbox_type', headerWidth: 4},
  {display: 'Report Type', key: 'report_type_name', headerWidth: 4},
  {display: 'Report ID', key: 'report_id', headerWidth: 2},
  {display: 'Manage', as: ManageCell, headerWidth: 4},
];

const NoConfig = () => (
  <div style={{textAlign: 'center'}}>
    <br />
    <Icon circular disabled name='info' size='big' className='m-b-10' />
    <Header as='h3' disabled content='No data found, add new report processor' className='m-0' />
    <br />
  </div>
)

const ManageConfig = ({ inbox_id }) => (
  <div>
    <MiniButton icon="file alternate" to={`${routes.batchConfigIndex}/inbox/view/${inbox_id}`} />
    <MiniButton icon="plus" content='Report Processor' to={`${routes.batchConfigIndex}/create/${inbox_id}`} />
  </div>
)

const MiniButton = (props) => {
  return <Button as={Link} primary compact size="mini" {...props} />
}

const manageActive = async (item) => {
  const reqBody = item;
  const id = item.id;
  reqBody['active'] = !reqBody['active']
  
  try {
    await updateBatchConfig(reqBody, id);
  } catch(err) {
    console.log(err)
  }
}

const ConfigSetup = ({ hasConfig, selected, selectedInbox, inbox_id, configs}) => {
  return <ContentCard 
    hasTable={selected} 
    title={selectedInbox.name} 
    topRight={<ManageConfig {...{inbox_id}} />}
  >
    { hasConfig ?  <IndexGrid {...{data: configs, cols}} /> : <NoConfig /> }
  </ContentCard>
}

export default ConfigSetup;
