import { positionIndex, tierKeys } from "./constants"

const updateSummary = (tier_1, position, countAll, countNTF, countRET, tier1Summary) => {
  const tier1obj = tier1Summary[positionIndex[position]][tier_1]
  if (!tier1obj) {
    tier1Summary[positionIndex[position]][tier_1] = [countAll, countNTF, countRET]
  }
  else {
    const aggCountAll = tier1obj[0] + countAll
    const aggCountNTF = tier1obj[1] + countNTF
    const aggCountRET = tier1obj[2] + countRET
    tier1Summary[positionIndex[position]][tier_1] = [aggCountAll, aggCountNTF, aggCountRET]
  }
  const total = tier1Summary[positionIndex[position]]["total"]
  tier1Summary[positionIndex[position]]["total"] = [total[0] + countAll, total[1] + countNTF, total[2] + countRET]
}

const updateFilteredItems = (touchpoint, position, countAll, countNTF, countRET, filteredItems) => {

  const touchpointObj = {
    tier_1: touchpoint[0],
    tier_2: touchpoint[1],
    tier_3: touchpoint[2],
    tier_4: touchpoint[3],
    tier_5: touchpoint[4],
    count_first_all: 0,
    count_ntf_first: 0,
    count_rpt_first: 0,
    count_mid_all: 0,
    count_mid_ntf: 0,
    count_mid_rpt: 0,
    count_last_all: 0,
    count_last_ntf: 0,
    count_last_rpt: 0,
    count_first_all_percent_col: 0,
    count_ntf_first_percent_col: 0,
    count_rpt_first_percent_col: 0,
    count_mid_all_percent_col: 0,
    count_mid_ntf_percent_col: 0,
    count_mid_rpt_percent_col: 0,
    count_last_all_percent_col: 0,
    count_last_ntf_percent_col: 0,
    count_last_rpt_percent_col: 0,
  }

  Object.assign(touchpointObj,
    {
      [`count_${position}_all`]: countAll,
      [`count_${position}_ntf`]: countNTF,
      [`count_${position}_rpt`]: countRET,
      [`count_${position}_all_percent_col`]: countAll,
      [`count_${position}_ntf_percent_col`]: countNTF,
      [`count_${position}_rpt_percent_col`]: countRET,
    })
  filteredItems.push(touchpointObj)
}

const constructPathData = (obj, filtered, tier1Summary) => {
  const { path, count_all: countAll, count_ntf: countNTF, count_ret: countRET } = obj
  const pathLen = path.length
  const filteredItems = []
  let index = 0

  while (index < pathLen) {
    const position = (index == 0) ? 'first' :
      (index == pathLen - 1) ? 'last' : 'mid'
    const touchpoint = path[index]

    updateFilteredItems(touchpoint, position, countAll, countNTF, countRET, filteredItems)
    updateSummary(touchpoint[0], position, countAll, countNTF, countRET, tier1Summary)
    index += 1
  }
  for (const id of filteredItems) {
    filtered.push(id)
  }
  return { filtered, tier1Summary }
}

export const processTableData = (finalPathIds, pathLookup, filtered, tier1Summary) => {
  finalPathIds.forEach(id => {
    constructPathData(pathLookup[id], filtered, tier1Summary)
  })
}

export const filterIdsByDate = (pathIdsByDate, dates) => {
  const idsByDate = []
  dates.forEach(date => {
    if (pathIdsByDate[date]) {
      for (const id of pathIdsByDate[date]) {
        idsByDate.push(id)
      }
    }
  })

  return idsByDate
}

export const filterIdsByLen = (pathIdsByLen, minPathLen) => {
  const lenOptions = [2, 3, 4, 'more']
  const idsByLen = []
  lenOptions.slice(lenOptions.indexOf(Number(minPathLen))).forEach(len => {
    if (pathIdsByLen[len]) {
      for (const id of pathIdsByLen[len]) {
        idsByLen.push(id)
      }
    }
  })

  return idsByLen
}

export const filterIdsByTouchPoint = (pathIdsByTiers, filterObj, pathIdsByDate) => {
  const allAvailPaths = Object.values(pathIdsByDate).flat()
  if (!filterObj.any && !filterObj.first && !filterObj.mid && !filterObj.last) return allAvailPaths

  const wordCombos = {}
  const idsPerFilter = {}

  Object.keys(filterObj).forEach(position => {
    if (filterObj[position]) {
      wordCombos[position] = createWords(filterObj[position])
      const allPathIds = []

      wordCombos[position].forEach(combo => {
        const pathIds = searchTrie(pathIdsByTiers, combo, position)
        if (pathIds) {
          for (const id of pathIds) allPathIds.push(id)
        }
      })
      idsPerFilter[position] = allPathIds
    }
  })
  const intersectedIds = intersection(...Object.values(idsPerFilter))
  return intersectedIds
}

export const createWords = (selectedTiers) => {
  const selectedTiersArr = tierKeys.map(tier => {
    if (selectedTiers[tier]) return selectedTiers[tier]
  }).filter(tier => tier && tier.length > 0)
  const words = []
  const max = selectedTiersArr.length - 1
  const helper = (combo, i) => {
    for (let j = 0, l = selectedTiersArr[i].length; j < l; j++) {
      const a = combo.slice(0)
      a.push(selectedTiersArr[i][j]);
      if (i == max) {
        words.push(a)
      } else {
        helper(a, i + 1)
      }
    }
  }
  helper([], 0)
  return words
}

const searchTrie = (pathIdsByTiers, combo, position) => {
  const foundNode = pathIdsByTiers.search(combo)
  if (foundNode) {
    const allIds = foundNode.getAllPathIds(position)
    return allIds;
  }
}

export const intersection = (...arrs) => {
  let res = arrs[0].slice();
  for (let i = 1; i < arrs.length; i++) {
    let set = new Set(arrs[i])
    res = res.filter(id => set.has(id));
  };
  return res;
};