import React, { useCallback, useState } from "react";
import { Message, Grid, Icon, Button, Loader } from "semantic-ui-react";
import {
  removeNotification,
  snoozeIntegrationNotification,
  getAccountNotifications,
  getVendorNofications,
  deleteVendorNofications
} from "../../utils/api";
import CacheContext from "../../utils/CacheContext";
import _ from "lodash";

export const VendorNotificationWarning = ({
  days = 1,
  notification_id,
  platformDisplayName,
  advertiser_integration_feature_id
}) => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const [loading, setLoading] = useState(false);
  const notifications =
    Context.getCache(`notifications`, getAccountNotifications) || [];

  const snoozeHandler = useCallback(
    (e, data) => {
      setLoading(true);
      return Promise.all([
        removeNotification(notification_id),
        snoozeIntegrationNotification(advertiser_integration_feature_id),
        deleteVendorNofications(notification_id)
      ])
        .then(() => {
          const remainingNotifications = _.cloneDeep(
            notifications.filter(
              notification => notification.id !== notification_id
            )
          );
          setState({ notifications: remainingNotifications });
          return getVendorNofications();
        })
        .then(vendorNotifications => {
          setState({
            vendorNotifications
          });
        })
        .finally(() => setLoading(false));
    },
    [notification_id, advertiser_integration_feature_id, notifications]
  );

  return (
    <Message warning>
      <Grid fluid>
        <Grid.Row>
          <Grid.Column width={11}>
            <Message.Header>
              <Icon name="warning sign" />
              {`We haven't received any files from ${platformDisplayName} in ${days} days.`}
              <Loader
                active={loading}
                inline
                size="small"
                style={{ marginLeft: "10px" }}
              />
            </Message.Header>
            <p>
              {`Please reach out to your ${platformDisplayName} representative to resolve.`}
            </p>
          </Grid.Column>
          <Grid.Column width={5} textAlign="right">
            <p style={{ textAlign: "right" }}>
              <span style={{ fontStyle: "italic" }}>
                {`No longer running on ${platformDisplayName}?`}
              </span>
              <br />
              <a
                style={{ color: "orange" }}
                onClick={snoozeHandler}
                style={{ cursor: "pointer" }}>
                <Icon name="bell slash" />
                Snooze this alert
              </a>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Message>
  );
};
