import React from 'react'
import { Grid } from 'semantic-ui-react';

const ContentWrap = props => (
  <div className="content-wrap">
    <div className="grid-wrap">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {props.children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
)

export default ContentWrap
