import React, { useMemo, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
import moment from 'moment'


const DateDropdown = ({ dateRange, setDateRange }) => {
  const options = useMemo(() => {
    const today = moment().add(1, 'days')
    const tomorrow = moment().add(1, 'days')
    const thirtyDaysFromNow =  moment().add(30, 'days')
    const startOfNextWeek = moment().add(1, 'week').startOf('week')
    const endOfNextWeek = moment().add(1, 'week').endOf('week')
    const startOfNextMonth = moment().add(1, 'month').startOf('month')
    const endOfNextMonth = moment().add(1, 'month').endOf('month')

    return [
      {
        text: `Next 30 Days (${tomorrow.format('MMM D')} - ${thirtyDaysFromNow.format('MMM D')})`,
        value: 'next_30_days',
        start: tomorrow.format('YYYY-MM-DD'),
        end: thirtyDaysFromNow.format('YYYY-MM-DD'),
        startDaysFromNow: 1,
        endDaysFromNow: 30,
        numDays: 30,
      },
      {
        text: `Next Month (${startOfNextMonth.format('MMM D')} - ${endOfNextMonth.format('MMM D')})`,
        value: 'next_month',
        start: startOfNextMonth.format('YYYY-MM-DD'),
        end: endOfNextMonth.format('YYYY-MM-DD'),
        startDaysFromNow: startOfNextMonth.diff(today, 'days') + 2,
        endDaysFromNow: endOfNextMonth.diff(today, 'days') + 1,
        numDays: endOfNextMonth.diff(startOfNextMonth, 'days') + 1,
      },
      {
        text: `Next Week (${startOfNextWeek.format('MMM D')} - ${endOfNextWeek.format('MMM D')})`,
        value: 'next_week',
        start: startOfNextWeek.format('YYYY-MM-DD'),
        end: endOfNextWeek.format('YYYY-MM-DD'),
        startDaysFromNow: startOfNextWeek.diff(today, 'days') + 2,
        endDaysFromNow: endOfNextWeek.diff(today, 'days') + 1,
        numDays: 7,
      },
    ]
  }, [])

  useEffect(() => {
    const defaultOption = options[0]
    setDateRange(defaultOption)
  }, [options])

  const onChange = (e, { value }) => {
    const selectedOption = options.find((x) => x.value === value)
    setDateRange(selectedOption)
  }

  return (
    <Dropdown
      compact
      selection
      value={dateRange?.value}
      {...{ options, onChange }}
      className='date-dropdown'
    />
  )
}

export default DateDropdown
