import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SaveReportForm from './component';

function mapStateToProps (state, props) {
  return {
    filter_id: props.match.params.id,
    pixel_source_name: state.app.user.pixel_source_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    save: (data) => {
      return axios.post("/reporting/saved",data)
    }
  }
}

const SaveReportFormWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveReportForm))

export default SaveReportFormWrapper;
