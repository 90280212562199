import React from 'react'
import PropTypes from 'prop-types'
import { Header, Grid, Icon, Segment, Popup } from 'semantic-ui-react'
import ProgressBar from './ProgressBar'


const EmptyMessage = props => (
  <>
    <Segment textAlign='center'>
      <br />
      <Icon
        circular
        disabled
        name='info'
        size='big'
      />
      <Header
        as='h3'
        disabled
        content={'No data found'}
        className='m-0'
      />
      <br />
    </Segment>
  </>
)

export const Tooltip = ({ tooltip }) => {
  return <Popup inverted
    content={tooltip}
    trigger={<Icon name="question circle" style={{ color: "#CACBCD", marginLeft: '0.5rem', marginBottom: '0.5rem', fontSize: '1em', cursor: 'help' }} />}
    // basic
  />
}

const HorizontalBar = ({metricLabel, color, data, currency, tooltips}) => {

  const total = data.reduce((total, obj) => obj.value + total, 0)

  return ((!data || data.length === 0) ? <EmptyMessage /> :
    <>
      <br />
      <Grid fluid columns={3} verticalAlign="right" >
        <Grid.Row style={{ padding: 8 }}>
          <Grid.Column width={8} />
          <Grid.Column width={4} >
            <Header as='h5' textAlign='center' size='small' style={{ color: '#0F1256', fontWeight: 'bold' }}>
              {metricLabel}
              { !tooltips?.metric? [] : <Tooltip tooltip={tooltips.metric}/>}
            </Header>
          </Grid.Column>
          <Grid.Column width={4} >
            <Header as='h5' textAlign='center' size='small' style={{ color: '#0F1256', fontWeight: 'bold' }}>
              {'% of Total ' + metricLabel}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {data.map((obj, index) => { return <ProgressBar key={"PB-" + index} {...{ obj, total, color, currency }} /> })}
    </>
  )
}

HorizontalBar.propTypes = {
  /** metric name */
  metricLabel: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    /** numeric value of specific label */
    value: PropTypes.number
  })),
  currency: PropTypes.string
}

export default HorizontalBar

