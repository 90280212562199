import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import * as routes from '../../routes'
import SaveReportForm from './SaveReportForm'
import Drawer from '../Drawer'


const SaveReportButton = ({ loading, options }) => {
  const match = useRouteMatch()
  const { path, params } = match
  const [routeName, _routePath] = Object.entries(routes).find(x => x[1] === path)
  const [saveReportDrawerOpen, setSaveReportDrawerOpen] = useState(false)

  return (
    <>
      <Button
        primary
        content='Save as...'
        disabled={loading}
        onClick={() => { if (!loading) setSaveReportDrawerOpen(true) }}
      />
      <Drawer openDrawer={saveReportDrawerOpen} onDrawerClose={() => setSaveReportDrawerOpen(false)}>
        <SaveReportForm
          {...{ routeName, params, options }}
          onClose={() => setSaveReportDrawerOpen(false)}
          open={saveReportDrawerOpen}
        />
      </Drawer>
    </>
  )
}

export default SaveReportButton
