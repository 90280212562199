import React, { useMemo } from 'react';
import { sponsorshipsChannelsImage } from '@rockerbox/styleguide';
import useOptions from './hooks/useOptions';
import { BasicViewHeader, UpgradePromo } from '../../components';
import Steps from './Steps/index';

const PromoCodes = () => {
  const { account } = useOptions();

  const hasAccess = useMemo(() => {
    if (!account?.features) return;
    return account?.features?.includes('promo_codes');
  }, [account]);

  if (hasAccess === false) {
    return (
      <UpgradePromo
        trackItem="promo_codes.upgrade_contact"
        chatMessage="Hi! I'm interested in upgrading to access Promo Codes."
        imageSrc={sponsorshipsChannelsImage}
        imageSize="large"
        content="Get Started with Promo Codes"
        subheader="Associate your promo codes with marketing efforts to understand their impact on your business"
      />
    );
  }

  return (
    <>
      <BasicViewHeader
        header="Promo Codes"
        subheader="Associate your promo codes with marketing efforts to understand their impact on your business"
      />
      <br />
      <Steps />
    </>
  );
};

export default PromoCodes;
