import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import * as routes from '../../baseRoutes';
import { Link } from 'react-router-dom'
import { StackedBarChart, LineChart } from '@rockerbox/styleguide';
import { splitData, formatNumber } from './helpers';
import { generateTrendCards } from '../DigitalAdvertising/PlatformPerformance/helpers'
import { TrendCard, BasicViewHeader } from "../../components"

export const MainHeader = ({ }) => (
  <BasicViewHeader
    header='Cross-Channel Attribution'
    subheader="View your overall marketing performance using various attribution methodologies"
    topRight={<Button
      primary
      icon="external"
      labelPosition="left"
      content="Ad Hoc Exports"
      as={Link}
      to={routes.adHocExportsHistory}
      target="_blank"
    />}
  />
)

export const TrendCardsFromTiersData = (props) => {
  const { loading, dailySummary, metrics_group, dates, metrics, currencyCode } = props;
  
  const [testObj, baselineObj, testDays, baselineDays] = splitData({ dates, dailyPerformance: dailySummary });

  const cardParams = { testObj, baselineObj, testDays, baselineDays };

  const cards = React.useMemo(() => {
    return generateTrendCards({ ...cardParams, metrics_group, metricsObj: metrics })
      .map(option => {
        // pre-format values to display in card, this map can be done elsewhere
        option.heading = option.text
        option.statistic = formatNumber(option, option.mainNumber, currencyCode)
        return option
        })
  }, [cardParams]);
  return <TrendCard {...props} options={cards} defaultCard={TrendCard.TrendingCard} />
}

export const ChartComponent = ({ chartData, lineChartData, tierColorMap, selectedMetric, spendFormatter, groupDatesBy, setGroupDatesBy }) => {
  const d = new Intl.NumberFormat('en')
  const formatter = ['conversions', 'roas'].includes(selectedMetric) ? d : spendFormatter;
  const height = 300
  const useStackedBarChart = ['conversions', 'revenue', 'spend'].includes(selectedMetric)
  const data = useStackedBarChart ? chartData : lineChartData

  if (useStackedBarChart) {
    return <StackedBarChart
      {...{ data, height, formatter, tierColorMap, groupDatesBy, setGroupDatesBy }}
      showTooltip
      hideKeys={['Unmapped Events']}
      showXAxis
      showTotalInToolTip
      groupDatesToggle
    />
  }
  return <LineChart
    {...{ data, height, formatter, tierColorMap, groupDatesBy, setGroupDatesBy }}
    showTooltip
    hideKeys={['Unmapped Events']}
    showXAxis
    groupDatesToggle
  />
}
