import React, { useState, useMemo } from 'react';
import { IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Grid, Button, Form, Label } from 'semantic-ui-react';
import * as d3 from 'rockerbox_d3_legacy_clone';

import useOptions from '../hooks';
import CreateEvents from './CreateEvents';

import { EventMessage } from './Messages';
import { remapResponses } from './CurrentResponses';
import { sumRecursive } from '../helpers';
import { TreeRow } from './parts';

const buildOptions = data => (data ? data.map(({ key }) => ({ key, text: key, value: key })) : []);

const ResponseEvents = React.memo(({ events, surveyData, responses, surveyResponseKey, defaultResponse }) => {
  const [fields, setFields] = useState(['tier_1']);

  const eventsHash = events.map(row => fields.map(k => row[k]).join(',')).join(',');

  const data = useMemo(() => {
    const { summary, conversion_data } = surveyData;
    const responseRules = responses;

    if (summary && Object.keys(summary).length > 0 && responseRules.length > 0) {
      const remapped = remapResponses(summary, surveyResponseKey, responseRules, defaultResponse).reduce((obj, entry) => {
        obj[entry.originalValue] = entry.newValue;
        return obj;
      }, {});

      const valuesByKey = d3
        .nest()
        .key(row => remapped[row[surveyResponseKey]])
        .rollup(values => {
          const response = [...new Set(values.map(row => row[surveyResponseKey]))];
          const response_followup = [...new Set(values.map(row => row[`${surveyResponseKey}_followup`]))];

          return { response, response_followup };
        })
        .map(conversion_data);

      return events.reduce((p, row) => {
        const { response, tier_1, tier_2, tier_3, tier_4, tier_5 } = row;
        const TIERS = { response, tier_1, tier_2, tier_3, tier_4, tier_5 };

        const tiers = Object.keys(TIERS).filter(key => !!TIERS[key] && TIERS[key].includes('}'));

        const items = tiers.reduce((eventsTiers, tier) => {
          const values = valuesByKey[response] || {};

          return eventsTiers.reduce((prev, rowCurr) => {
            const macro = rowCurr[tier].slice(1, -1);
            const itemsResp = macro === 'response' ? [response] : values[macro] || [];
            const newEvents = itemsResp.map(item => ({ ...rowCurr, [tier]: item }));
            return [...prev, ...newEvents];
          }, []);
        }, [TIERS]);

        return [...p, ...items];
      }, []);
    }
    return [];
  }, [eventsHash]);

  const values = fields
    .reduce((p, field) => p.key(row => row[field]), d3.nest())
    .rollup(vals => vals.map(obj => ({ row: obj, count: 1 })))
    .entries(data);

  values.map(sumRecursive);

  const defaultOpen = fields.length - 1;
  const cols = ['response', 'tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'];
  const columns = IndexGrid.arrayToColumns(cols);
  const options = cols.map(value => ({ value, text: value }));

  return (
    <>
      <Form.Group>
        <Label style={{ lineHeight: 1.8 }}>Preview By</Label>
        <Form.Dropdown
          value={fields}
          selection
          multiple
          options={options}
          onChange={(_, { value }) => {
            setFields(value);
          }}
        />
      </Form.Group>
      <ContentCard hasTable>
        {fields.length && values
          .sort((p, c) => c.count - p.count)
          .map(row => <TreeRow {...{ columns, defaultOpen, row }} />)}
      </ContentCard>
    </>
  );
});

const EventSetup = ({ updateStateArrayRowField, addAdvanced, removeAdvanced, survey, events, surveyResponseKey, responses, defaultResponse }) => {
  const { surveyData, tierOptions } = useOptions(survey);

  const responseOptions = useMemo(() => (
    buildOptions(responses.map(row => ({ key: row.response_display })))
  ), [responses.map(({ response_display }) => response_display).join()]);

  const remove = removeAdvanced('events');

  return (
    <Grid columns="equal">
      <Grid.Column>
        <ContentCard maxHeight={events.length > 0 ? 400 : 0} title="Create Events from Response Value">
          {responseOptions && (
            <>
              <EventMessage />
              {events && events.length > 0 && events.map((event, pos) => (
                <CreateEvents
                  tierOptions={tierOptions}
                  options={responseOptions}
                  {...{ event, remove, updateStateArrayRowField, pos }}
                />
              ))}
              <Button
                primary
                onClick={() => addAdvanced('events')}
                content="event"
                icon="plus"
                size="mini"
                as="span"
              />
            </>
          )}
          {!responseOptions && 'You must map event responses before you can create events'}
        </ContentCard>
      </Grid.Column>
      <Grid.Column>
        <ContentCard title="Preview of Events Added from Survey Responses" noContent />
        <br />
        <ResponseEvents {...{ events, surveyData, responses, surveyResponseKey, defaultResponse }} />
      </Grid.Column>
    </Grid>
  );
};

export default EventSetup;
