const TABLE = "cache_meta"

export const datasetSchemas = {
  compiled_mta: {
    index: ["conversion_date", "tier_1", "tier_2", "tier_3", "tier_4", "tier_5", "type", "matches"],
    primary_key: ["event_id"],
    text: [
      "tier_1", "tier_2", "tier_3", "tier_4", "tier_5", 
      "request_referrer", "original_url", 
      "matches", 
      "external_id", "email", "order_id", "uid", "base_id",
      "type", "coupoon", "rb_address_1", "rb_address_2", "rb_city", "rb_country", "rb_state", "rb_zipcode",
    ],
    datetime: [
      "conversion_date", "event_date",
      "timestamp_events", "timestamp_conversion"
    ],
    number: [ 
      "even", "revenue_even", 
      "first_touch", "last_touch", "normalized",
      "revenue_first_touch", "revenue_last_touch", "revenue_normalized",
      "revenue", 
      "total_events", "total_weight", "new_to_file",
    ]
  },
  compiled_mta_tier: ""
}

const metaSchema = {
  primary_key: ["dataset_filter_id_date"],
  text: ["dataset","filter_id", "date", "cache_id"]
}

export const getCacheState = async function(database, dataset, filterId, meta) {
  const { create, select } = database;

  const ok = await create(TABLE, metaSchema)
  const metaEntries = Object.entries(meta)

  const state = {
    valid: {},
    expired: {},
    missing: {}
  }
  for (let entry of metaEntries) {
    const [date, cache_id] = entry;
    const dataset_filter_id_date = `${dataset}_${filterId}_${date}`
    
    const _resp = await select(`SELECT cache_id from ${TABLE} where dataset_filter_id_date ='${dataset_filter_id_date}'`)

    const response = _resp || [];
    const { values=[] } = response[0] || {};
    const missing = !values.length;
    const currentCacheId = values.length ? values[0][0] : false
    const expired = currentCacheId && (currentCacheId != cache_id)

    if (missing) state.missing[date] = true
    if (expired) state.expired[date] = currentCacheId
    if (!missing && !expired) state.valid[date] = currentCacheId
  }

  return state
}

export const clearExpired = async function(database, dataset, filterId, expired) {

  if (!Object.values(expired).length) return true;
  const { exec } = database;

  const table = `${dataset}_${filterId})`
  const toDelete = Object.values(expired).join('","')
  const query = `DELETE FROM ${table} WHERE cache_id in ("${toDelete}")`

  const ok = await exec(query)

  return ok
}

export const createCacheTable  = async function(database, dataset, filterId) {
  const { create, index } = database;

  const table = `${dataset}_${filterId}`
  const schema = datasetSchemas[dataset]

  const isNew = await create(table, schema, ["cache_id"])
  if (isNew) {
    for (let i of (schema.index||[])) {
      const madeIndex = await index(table, i)
    }
  }
  return isNew
}

const loadCacheEntry = async function(database, dataset, filterId, date, cacheId, data) {
  const { exec } = database;

  const dataset_filter_id_date = `${dataset}_${filterId}_${date}`
  const columns = metaSchema.text.join(", ")
  const values = [dataset, filterId, date, cacheId].join("','")
  const _resp = await exec(`INSERT INTO ${TABLE} 
    (dataset_filter_id_date, ${columns})  VALUES 
    ('${dataset_filter_id_date}','${values}')
  `)

  return _resp
}

export const loadCache = async function(database, dataset, filterId, date, cacheId, data) {
  const { load } = database;
  const table = `${dataset}_${filterId}`
  const schema = datasetSchemas[dataset]
  const loaded = await load(table, data, schema, ["cache_id"], [cacheId])
  const cached = await loadCacheEntry(database, dataset, filterId, date, cacheId, data)


  return cached
}
