import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { SelectButtons, IndexGridNew, StatCard, SplitLayout, ContentCard, IndexGrid, PivotGrid, SelectList } from '@rockerbox/styleguide';
import { Radio, Label, Form, Button, Input, Grid, Icon, Segment, Dropdown, Table } from 'semantic-ui-react';
import moment from "moment";
import styled from "styled-components";

import FocusedDatePicker from "./DatePicker";
import OptionButtons from './OptionButtons'
import DateRangePicker from '../ViewAttribution/DatePicker'

export const daysAgo = (num) => moment.utc().utcOffset(-5).subtract(num, "days").format("YYYY-MM-DD")
const isDaysAgo = (value, num) => value == daysAgo(num)

export const DateRangeWrap = styled.div`
  margin-top:3px;
  border-radius:5px;
  border:1px solid #ddd;
  padding-right:27px;
  width:100%;
  height:30px;

  & input.DateInput_input {
    font-size: 1.2em;
    height: 1.9em;
    width:95px;
  }
  & svg.DateRangePickerInput_arrow_svg {
    height: 20px
  }
`

const RadioWrap = styled.div`
  padding:8px;
  ${({ checked }) => checked ? "border:1.5px solid rgb(2 108 172 / 75%); border-radius:.5em;" : ""}
`

const RadioOption = ({ label, checked, onClick }) => {
  return <RadioWrap {...{checked, onClick}}>
    <Radio {...{label, checked, onClick}} />
  </RadioWrap>
}

const SelectionRow = styled.div`
  //border-bottom:1px solid #ccc;
  padding-bottom:12px;
  //margin-bottom:12px;
  margin-top:-12px;

  & > .grid > .column:first-of-type {
    margin-left: -13px;
    margin-top: -13px;
  }

  & > .ui.celled.grid>.column:not(.row) {
    box-shadow: none
  }
`

const PRESET_DATE_RANGES = [
  {
    text: "7D",
    numDays: 8
  },
  {
    text: "30D",
    numDays: 31
  },
  {
    text: "60D",
    numDays: 61
  },
  {
    text: "90D",
    numDays: 90
  }
]

const DateContentCard = ({ title, defaultFilters = null, startDate, endDate, onDateChange, onSearch, onDefaultChange, children, loading, presetOptions = [], defaultPreset = false }) => {

  const [filters, setFilters] = React.useState(defaultFilters);
  const [savedFilter, setSavedFilter] = React.useState(defaultFilters);
  const [start, setStart] = React.useState(startDate);
  const [end, setEnd] = React.useState(endDate);
  const [isPreset, setIsPreset] = React.useState(true);
  const [preset, setPreset] = React.useState(false);
  const [showCustom, setShowCustom] = React.useState(false);
  const [forceSubmit, setForceSubmit] = React.useState(0);


  React.useEffect(() => {
    selectPreset(false, { value: defaultPreset })
  },[defaultPreset])

  React.useEffect(() => {
    onDateChange({ startDate: start, endDate: end })
  },[start, end])

  const onSubmit = () => {
    setSavedFilter(filters)
    onSearch(filters)
  }

  React.useEffect(() => {
    forceSubmit > 0 ? onSubmit() : false
  }, [forceSubmit])

  const clearFilters = () => {
    setFilters("")
    setForceSubmit(forceSubmit + 1)
  }

  const setDates = (start, end) => () => {
    setShowCustom(false)
    setStart(start)
    setEnd(end)
  }

  const setStartEnd = ({ startDate, endDate }) => {
    setStart(startDate.format("YYYY-MM-DD"))
    setEnd(endDate.format("YYYY-MM-DD"))
  }

  const options = [
    {value:"filter", text:"Advanced Filter"},
    {value:"advanced", text:"Build Filter"}
  ]


  const selectPreset = (_, { value }) => {
    setPreset(value)
    const option = presetOptions.find(row => row.value == value)
    const { description } = option
    onDefaultChange(Object.assign({ description }, option.defaults))
    if (option.search || option.search === "") {
      setFilters(option.search)
      setForceSubmit(forceSubmit + 1)
    }
  }

  const updatePreset = (isPreset) => {
    setIsPreset(isPreset)
    const option = presetOptions.find(row => row.value == preset) || {defaults: {}}
    onDefaultChange(Object.assign({}, option.defaults, {isPreset}))
  }

  return <>
  <ContentCard
    noContent
    title="Sponsorships Attribution"
    subtitle={<>
      Need help?
      <a href="https://bit.ly/3qP72sK" target="_blank" style={{color: '#4f7691'}}>
        <Icon name='play circle' style={{fontSize:'1em', margin:'0 2px 0 4px'}} />
        Watch a short video explaining how sponsorships works
      </a>
    </>}
    topRight={
      <div style={{ marginTop:10, marginBottom:10 }}>
        <div style={{verticalAlign:"top",display:"inline-block"}}>
          <Button.Group basic={isPreset} size="small" style={{marginRight: 12}}>
            <Button
              size='small'
              content={<>&nbsp;&nbsp;Advanced Mode</>}
              icon={`toggle ${isPreset ? 'off' : 'on'}`}

              basic={isPreset}
              color={!isPreset && 'black'}
              onClick={() => updatePreset(!isPreset)}
            />
          </Button.Group>
        </div>
        <div style={{display:"inline-block"}}>
          <Button.Group size="small" basic>
            <Button icon="calendar" content="Custom" onClick={() => setShowCustom(true)} active={showCustom} />
            {PRESET_DATE_RANGES.map(({ text, numDays }) =>
              <Button active={!showCustom && isDaysAgo(start,numDays) && isDaysAgo(end,1)} onClick={setDates(daysAgo(numDays),daysAgo(1))} content={text} />
            )}
          </Button.Group>
          { showCustom && <DateRangeWrap>
              <DateRangePicker startDate={moment(start)} endDate={moment(end)} onChange={setStartEnd} />
            </DateRangeWrap>
          }
        </div>
      </div>
    }
  />
  {isPreset &&
    <OptionButtons defaultValue={preset} onChange={selectPreset} options={presetOptions} />
  }
  {!isPreset &&
    <ContentCard>
      <Form {...{onSubmit}} style={{marginBottom:"4px"}}>
        <Input
          label={<Dropdown defaultValue='filter' options={options} />}
          fluid icon="Search" action={<Button type="submit"> Search </Button>}
          onChange={(x, {value}) => setFilters(value.replace(/: /g,":"))}
        />
      </Form>
      {(filters && filters == savedFilter) ?
         <>Current Filters: <Label>{filters}<Icon name="delete" onClick={clearFilters} /></Label></>:
         <div style={{opacity:.5,textAlign:"center"}}>hit enter to apply filter</div>
      }
    </ContentCard>
  }
  <ContentCard hasTable={isPreset}>
    { children }
  </ContentCard>
  </>
}

export default DateContentCard;
