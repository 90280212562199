import React from 'react';
import toast from 'react-hot-toast';
import { Table } from 'semantic-ui-react';
import { IndexGridNew } from '@rockerbox/styleguide';
import { forgotPassword, deleteUser } from '../../api/account';

const InviteUsersRows = ({ item, col }) => {
  const currentuser = col.key.current_username;
  const shouldShow = item.email !== currentuser;

  const handleDelete = () => {
    const { username }  = item
    const deleteUserPromise = deleteUser({ username })
      .then(() => {
        col.key.handleManageUpdate()
      })
    toast.promise(deleteUserPromise, {
      loading: 'Removing',
      success: `${username} removed from account`,
      error: `Error removing ${username} from account`,
    })
  }

  const handleResend = () => {
    const { email } = item
    const resendInvitePromise = forgotPassword({ email })
    toast.promise(resendInvitePromise, {
      loading: 'Sending',
      success: `Resent invite to ${email}`,
      error: `Error resending invite to ${email}`,
    })
  }

  return (
    <Table.Cell textAlign={"right"} style={{ position: 'relative', height: 47 }}> 
      { shouldShow ? <IndexGridNew.SendButton item={item} send={handleResend} popup={"Resend Invite"}/> : ""}
      { shouldShow ? <IndexGridNew.OverlayDeleteBtn onClick={handleDelete} popup={"Remove User"}/> : ""}
    </Table.Cell>
  )
}

export default InviteUsersRows
