import React, { Component } from 'react'
import { Segment, Grid, Header, Icon } from 'semantic-ui-react'

class AlertCard extends Component {
  state = { visible: true }

  handleDismiss = () => this.setState({ visible: false })

  render(){
    const props = this.props
    const { visible } = this.state

    const cardColor = props.success ? 'green' : props.error ? 'red' : 'blue'
    const iconName = props.success ? 'check circle' : props.error ? 'times circle' : 'info circle'

    if (visible) {
      return(
        <Segment
          inverted
          color={cardColor}
        >
          <Grid>
            <Grid.Column floated='left' width={props.dismissable ? 15 : 16} className='ellipsed'>
              <Header
                inverted
                as={props.as || 'h5'}
                icon={iconName}
                content={props.message}
                subheader={props.description}
              />
            </Grid.Column>
            {props.dismissable &&
              <Grid.Column floated='right' width={1} textAlign='right'>
                <Icon
                  name='times'
                  link
                  onClick={this.handleDismiss}
                />
              </Grid.Column>
            }
          </Grid>
        </Segment>
      )
    } else {
      return null
    }
  }
}

export default AlertCard
