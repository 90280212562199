import React, { Component } from 'react';
import moment from 'moment';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { SplitLayout, ContentCard } from '@rockerbox/styleguide';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import ReportSettings from './ReportSettings';
import SelectConversion from '../SelectSegment';
import { Aux, FormWrap, FieldWrap} from '../stateless';
import { Accordion, Checkbox, Button, Segment, Input, Grid, Select, Form, Header, Menu, Icon, Dropdown } from 'semantic-ui-react';

const intervalOptions = [
  {text:"Month",key:"month",value:"month"},
  {text:"Weeks since conversion",key:"week",value:"week"}
]

const intervalSelector = {
  "month": "Analyze Until",
  "week": "Number of weeks",
}

export const numOptions = (startMonth) => d3.range(3,13).map(value => { return {key: value, value, text: value}})

export const numMonthOptions = (startMonth) => [3,4,5,6,7,8].map(month => {
  const start = moment(startMonth+"-01","YYYY-MM-DD")
  const forward = start.add(month,'months').subtract(1,'days').format("MMM YYYY")
  return {key: month, value: month, text: forward}
}).filter(row => moment(row.text + " 01","MMM YYYY DD") < moment.utc().utcOffset(-5))

const intervalSelectorOptions = {
  "month": numMonthOptions,
  "week": numOptions,
}

const GROUPS = [{key:"tier_2", text:"Tier 2", value:"tier_2"},{key:"tier_3", text:"Tier 3", value:"tier_3"},]
const MODELS = ["first_touch","last_touch","even","normalized"]
const MODEL_NAMES = ["First Touch","Last Touch","Even Weight", "Modeled Multi-touch"]
const modelOptions = MODELS.map((x,i) => { return {key: x, value:x, text:MODEL_NAMES[i]} })

export const GRow = styled(Grid.Row)`
  display: flex;
  font-size: .9em;
  & > label {
    flex: 1;
    font-size: 1.111em;
    line-height: 1.21428571em;
    padding-top: 0.57857143em;
  }
  & > .ui.selection.dropdown, & > .ui.input {
    min-height: .5em;
    line-height: 1.21428571em;
    flex: 2;
  }
`

export const ColumnWrap = ({width, children}) => (
  <Grid.Column width={width}>
    <ContentCard borderless noBackground>
      <FormWrap>
        <FieldWrap>{ children }</FieldWrap>
      </FormWrap>
    </ContentCard>
  </Grid.Column>
)

const SelectCohort = ({metrics, ntf, margin, match, history, tier_1,tier1Options, tier_group, numPeriods, paid, how, updateField, submit, reset, startCohort, endCohort, bucketBy, selectedSegment}) => (

  <Grid fluid="true">
    <Grid.Row style={{paddingBottom:"0px"}}>
      <ColumnWrap width={5}>
        <h5>Construct User Cohort</h5>
        <GRow>
          <label>Users that</label>
          <SelectConversion
            filter={(seg) => seg.has_new_to_file}
            endpoint={id => routes.cohortLTV + '/' + id}
            history={history}
            match={match}
            as={Select}
            size="mini"
          />
        </GRow>
        <GRow>
          <label>During</label>

          <DatePicker
            customInput={<Input icon='calendar alternate'/>}
            selected={moment(startCohort)}
            onChange={(v) => updateField("startCohort")(false, {value: v.format("YYYY-MM-DD")}) }
            minDate={selectedSegment.first_reporting_date != "" ? moment(selectedSegment.first_reporting_date) : moment.utc().utcOffset(-5).subtract(200, 'days')}
            maxDate={moment.utc().utcOffset(-5).subtract(1, 'days')}
          />
          <DatePicker
            customInput={<Input icon='calendar alternate'/>}
            selected={moment(endCohort)}
            onChange={(v) => updateField("endCohort")(false, {value: v.format("YYYY-MM-DD")}) }
            minDate={selectedSegment.first_reporting_date != "" ? moment(selectedSegment.first_reporting_date) : moment.utc().utcOffset(-5).subtract(200, 'days')}
            maxDate={moment.utc().utcOffset(-5).subtract(1, 'days')}
          />
        </GRow>
        <GRow>
          <label>Using</label>
          <Select options={modelOptions} value={how} onChange={updateField("how")} size="mini"/>
        </GRow>
        <GRow>
          <label>That engaged with</label>
          <Select placeholder="All Tiers" value={tier_1 != "-" && tier_1} options={tier1Options} onChange={updateField("tier_1")} size="mini" />
        </GRow>

      </ColumnWrap>
      <ColumnWrap width={4}>
        <h5>Build Analysis</h5>
        <GRow>
          <label>Interval type</label>
          <Select options={intervalOptions} value={bucketBy} onChange={updateField("bucketBy")} size="mini"/>
        </GRow>
        <GRow>
          <label>{intervalSelector[bucketBy]}</label>
          <Select options={intervalSelectorOptions[bucketBy](startCohort)} value={parseInt(numPeriods)} onChange={updateField("numPeriods")} size="mini"/>
        </GRow>
        { tier_1 && tier_1 != "-" &&
          <GRow>
            <label>Group By</label>
            <Select value={tier_group} options={GROUPS} onChange={updateField("tier_group")} size="mini"/>
          </GRow>
        }
      </ColumnWrap>

      <ColumnWrap width={2}>
        <h5>&nbsp;</h5>
        <Button onClick={reset}>Reset</Button>
      </ColumnWrap>

      <ReportSettings {...{margin, paid, ntf, updateField, metrics}} />
    </Grid.Row>
  </Grid>
)

export default SelectCohort;
