import React, { useState, useMemo } from 'react';
import { IndexGridNew } from '@rockerbox/styleguide';
import { useSavedReports } from './_hooks/savedReports';
import { MainHeader, TitleCell, BasedOnCell, TimeCell } from './stateless';
import { Drawer, EditSavedReport } from '../../components';
import ManageRow from './ManageRows';
import { TreeTableLoader } from '../../components/loaders';

const SavedReports = () => {
  const { savedReports, handleGetSavedReports, deleteSavedReport, loading } = useSavedReports()
  const [editSavedReportDrawerOpen, setEditSavedReportDrawerOpen] = useState(false)
  const [editSavedReport, setEditSavedReport] = useState({})

  const mySavedReports = useMemo(() => (savedReports.filter(x => x.shared === 0)), [savedReports])
  const sharedSavedReports = useMemo(() => (savedReports.filter(x => x.shared === 1)), [savedReports])
      
  const manage_params = {
    deleteSavedReport,
    handleGetSavedReports,
    openEditDrawer: () => setEditSavedReportDrawerOpen(true),
    onEditButtonClick: (item) => setEditSavedReport(item)
  };

  const cols = [
    { display: 'Title', key: 'name', as: TitleCell },
    { display: 'Description', key: 'description' },
    { display: 'Based On', key: 'route', as: BasedOnCell },
    { display: 'Created', key: 'created_at', as: TimeCell },
    { headerStyles: { width: '250px' }, key: manage_params, as: ManageRow },
  ]

  return (
    <>
      <MainHeader />
      {loading ? <TreeTableLoader /> :
        <IndexGridNew
          title="My Reports"
          data={mySavedReports}
          cols={cols}
          sortable
          defaultSortCol='created_at'
          itemsPerPage={10}
          paginate
        />
      }
      <br /><br />
      {loading ? <TreeTableLoader /> :
        <IndexGridNew
          title="Team Reports"
          data={sharedSavedReports}
          cols={cols}
          sortable
          defaultSortCol='created_at'
          itemsPerPage={10}
          paginate
        />
      }
      <Drawer openDrawer={editSavedReportDrawerOpen} onDrawerClose={() => setEditSavedReportDrawerOpen(false)}>
        <EditSavedReport
          savedReport={editSavedReport}
          onClose={() => setEditSavedReportDrawerOpen(false)}
          open={editSavedReportDrawerOpen}
          refresh={handleGetSavedReports}
        />
      </Drawer>
    </>
  )
}

export default SavedReports;
