import React, { useContext, useEffect } from 'react'

import { CacheContext } from '../../utils/CacheContext'
import { SignupForm } from '../../V3/components'
import { useQuery } from '../../V3/hooks/urlState'
import { ExistingAccountMessage } from './stateless'
import { extractAttribution } from './helpers'


const SignupFormEmbed = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const { account } = state
  const { pixel_source_name } = account || {}
  const hasAccount = !!pixel_source_name

  const query = useQuery()
  const buttonColor = query.get('buttonColor') || query.get('color') || '475ddc'
  const parentPage = extractAttribution(query)
  const source = parentPage?.source || query.get('source')
  const params = {
    tier: query.get('tier'),
    signupSource: !!source ? `form.${source}` : 'form',
    color: query.get('color') || '475ddc',
    header: query.get('header'),
    subheader: query.get('subheader'),
    buttonText: query.get('buttonText') || 'Sign Up',
    buttonTextColor: query.get('buttonTextColor') || 'ffffff',
    buttonColor: buttonColor,
    email: parentPage?.email || query.get('email'),
    firstName: parentPage?.fname || query.get('fname'),
    lastName: parentPage?.lname || query.get('lname'),
    company: parentPage?.company || query.get('company'),
    website: parentPage?.website || query.get('website'),
    attribution: parentPage,
  }

  useEffect(() => {
    document.getElementById('AppWrap').style.minWidth = '320px'
  }, [])

  if (hasAccount) return <ExistingAccountMessage {...{ buttonColor }} />

  return <SignupForm {...params} showValidationMessages={false} />
}

export default SignupFormEmbed
