import React from 'react'
import  { Loader, Grid, Header, Button } from 'semantic-ui-react'
import { CacheContext } from '../../utils/CacheContext'
import { getAccount, getSegments, getIntegrationFeatures, getAllIntegrations } from '../../utils/api'
import AttributionIndex from '../ViewAttributionIndex'
import ReportMap from '../ReportMap'
import DataMap from '../DataMap'


const DashboardView = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const account = Context.getCache(`account`, getAccount) || undefined

  // caching, not used here
  const _segments = Context.getCache(`segments`, getSegments)
  const _integrationsFeatures = Context.getCache(`integrationFeatures`, getIntegrationFeatures)
  const integrations = Context.getCache(`integrations`, getAllIntegrations)

  if (!account) return <Loader active inline='centered' style={{ marginTop: 100 }} />
  if (!!account && account.signup_source !== 'shopify') return <AttributionIndex />
  return <DashboardWrap />
}

export default DashboardView


const DashboardWrap = () => {
  const [activePaneId, setActivePane] = React.useState('data-map')

  const panes = [
    {
      id: "data-map",
      name: "Data Map",
      icon: 'tachometer alternate',
      header: "Welcome to Rockerbox!",
      subheader: "Here's how we're building your single source of truth for marketing analytics",
      component: <DataMap {...{setActivePane}} />
    },
    {
      id: "conversions",
      name: "Conversions",
      icon: 'chart bar',
      header: "Conversions Overview",
      subheader: "View your high-level metrics for each stage of the customer journey",
      component: <AttributionIndex />
    },
    {
      id: "report-map",
      name: "Reports",
      icon: 'database',
      header: "Your Marketing Data Warehouse",
      subheader: "Explore all the available reports to help you make the decisions you need",
      component: <ReportMap />
    },
  ]

  const activePane = React.useMemo(() =>
    panes.find(({ id }) => id == activePaneId) || {}
  , [activePaneId])
  const { id, name, icon, header, subheader, component } = activePane

  return (<>
    <Grid fluid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Header
            as='h2'
            content={header}
            subheader={subheader}
          />
        </Grid.Column>
        <Grid.Column width={6} textAlign='right'>
          {panes.map(p =>
            <Button
              color={activePaneId == p.id && 'grey'}
              active={activePaneId == p.id}
              content={p.name}
              icon={p.icon}
              onClick={() => setActivePane(p.id)}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <br />
    {component}
  </>)
}
