import React, { useState, useEffect} from 'react';
import { Segment, Progress, Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { ContentCard, PivotGrid } from '@rockerbox/styleguide';
import { getDataset, getConversionSegments } from '../../utils/api';
import { getDatasetViaCache } from '../../utils/api_cache';
import { datasetSchemas } from '../../utils/sqlite/cache_meta';
import { useSQLite, useSQLiteCache } from '../../utils/sqlite/useSQLite';
import moment from 'moment';
import json2csv from 'json2csv';
import AdvancedSearch from '../helpers/AdvancedSearch';
import DateRangeHooksUrlState from '../helpers/DateRangeHooksUrlState';

const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD")

const datasets = [
  { text: "Compiled MTA", value: "compiled_mta"},
  { text: "Buckets", value: "compiled_mta_tiers"}
]

const columnNames = {
  "conversion_date": "Conversion Date",
  "event_date": "Event Date",
  "tier_1": "Tier 1",
  "tier_2": "Tier 2",
  "tier_3": "Tier 3",
  "tier_4": "Tier 4",
  "tier_5": "Tier 5",
  "count:sum": "number"
}

const Diagnostics = () => {

  const onCacheChange = (ready, meta, toFetch, fetching, resolved, cacheState) => {

    setLoading(!ready)
    if (!meta) setLoaderPercent(0)
    if (meta && toFetch) {
      const numActive = (Object.values(toFetch).length + Object.values(fetching).length)
      const loaderPercent = parseInt((1 - numActive/Object.values(meta).length)*100)
      setLoaderPercent(loaderPercent)
    }
  }

  const [database, setCacheSettings] = useSQLiteCache(onCacheChange);
  const [sqlQuery, setSQLQuery] = useState("select conversion_date, tier_1, sum(even) conversions from CURRENT_DATASET group by 1,2");
  const [sqlResult, setSQLResult] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  

  const [filterId, setfilterId] = useState(undefined);
  const [dataset, setDataset] = useState("compiled_mta");
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const [loading, setLoading] = React.useState(true);
  const [loaderPercent, setLoaderPercent] = React.useState(0);
  const [queryLoading, setQueryLoading] = React.useState(false);

  const [defaultRows, setDefaultRows] = useState([]);
  const [defaultCols, setDefaultCols] = useState([]);
  const [defaultSummary, setDefaultSummary] = useState([]);

  useEffect(() => {
    getConversionSegments()
    .then(data => {
      const featured = data.find(c => c.featured === 1);
      const options = data.map(c => {
        return {text: c.action_name, value: c.filter_id}
      })

      setfilterId(featured.filter_id);
      setOptionsList(options);
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    if (!filterId) return;
    setSQLResult([])
    setCacheSettings({ dataset, filterId, startDate, endDate })
  }, [filterId, dataset, startDate, endDate])



  const onDateChange = ({ startDate, endDate }) => {
    setLoading(true)
    setLoaderPercent(0)
    const formatStartDate = moment(startDate).format("YYYY-MM-DD");
    const formatEndDate = moment(endDate).format("YYYY-MM-DD");

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  }

  const prepareQuery = (sqlQuery) => {

    const dateRange = `where conversion_date >= '${startDate}' and conversion_date <= '${endDate}'`
    const connector = sqlQuery.includes("CURRENT_DATASET where") ? "and" : ""

    return sqlQuery
      .replace("CURRENT_DATASET where", "CURRENT_DATASET")
      .replace("CURRENT_DATASET", `${dataset}_${filterId} ${dateRange} ${connector} `)
  }

  const onSubmit = () => {
 
    setQueryLoading(true)
    database.select(prepareQuery(sqlQuery))
      .then(res => {
        const { columns, values } = res[0]
        const data = values.map(row => columns.reduce((o,k,i) => Object.assign(o, {[k]: row[i]}), {}))

        const schema = datasetSchemas[dataset]
        const textColumns = [...schema.text, ...schema.datetime]
        const text = columns.filter(col => textColumns.includes(col)).slice(0,5)
        const numbers = columns
          .filter(col => !textColumns.includes(col))
          .filter(col => typeof(data[0][col]) == "number")
          .sort((p,c) => schema.number.indexOf(p) - schema.number.indexOf(c))
          .slice(0,5).map(num => num+":sum")


        const shouldAllRows = (text.length == 1) || (numbers.length > 1)
        setDefaultRows(shouldAllRows ? text : text.slice(1))
        setDefaultCols(shouldAllRows ? [] : text.slice(0,1))
        setDefaultSummary(numbers)
        setQueryLoading(false)

        setSQLResult(data)
      })
  }


  const exportCSV = () => {
    
    const parser = new json2csv.Parser()
    const csv = parser.parse(sqlResult)

    const csvContent = "data:text/csv;charset=utf-8," + csv;
    const fileName = `${filterId}-${startDate}-${endDate}.csv`
    const link = document.createElement("a");

    link.setAttribute("href", csvContent);
    link.setAttribute("download", fileName);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  return (
    <ContentCard 
      title="Diagnostics"
      topRight={<DateRangeHooksUrlState {...{startDate, endDate, onDateChange}}/>}
    >

      <Segment secondary>
        <h3>
          Conversion: &nbsp; 
          <Dropdown options={optionsList} value={filterId} onChange={(e,d) => setfilterId(d.value)} inline/>
        </h3>
        <h3>
          Dataset: &nbsp; 
          <Dropdown options={datasets} value={dataset} onChange={(e,d) => setDataset(d.value)} inline/>
        </h3>

        { !loading && <>
            <Form {...{onSubmit}}>
              <Input
                defaultValue={sqlQuery || ""}
                fluid action={<Button type="submit"> Search </Button>}
                onChange={(x, {value}) => setSQLQuery(value)}
              />
            </Form>
          </>
        }
        { loading && <>
            <div style={{"textAlign":"center"}}>Preparing data for { startDate } until { endDate }</div>
            <Progress size='tiny' success percent={loaderPercent} indicating />
          </>
        }
      </Segment>
      <PivotGrid 
        key={(sqlResult.length > 0 ? Object.keys(sqlResult[0]).join() : "") + startDate + endDate + queryLoading}
        title={sqlResult.length ? 
          <Button onClick={exportCSV} size='mini' icon='save' labelPosition='left' content="Export" style={{marginTop:"5px"}}/> : " "}
        selectColumnWidth={3}
        columnNames={columnNames}
        showOptions={true} 
        defaultRows={defaultRows} 
        defaultCols={defaultCols}
        defaultSummary={defaultSummary} 
        hasRawData={!loading && !queryLoading}
        data={sqlResult}  
        defaultMetricDirection={"column"} 
        hasFooter={false}
        
      />
    </ContentCard>
  )
}

export default Diagnostics;
