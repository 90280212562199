import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const V3UIButton = ({ url }) => (
  <div className='flex-center-center flex-column' style={{height: '100%'}}>
    <h2 style={{ fontWeight: 500, marginTop: 10, marginBottom: 15, textAlign: 'center' }}>
      See this view in our New Rockerbox UI!
    </h2>
    <Button
      color='blue'
      as={Link}
      to={url}
    >
      Redirect
    </Button>
  </div>
)

export default V3UIButton