import React, { Component } from 'react';
import { ContentCard, SplitLayout } from '@rockerbox/styleguide';
import { Table, Checkbox, Loader, Dropdown, Popup, Icon, Button, Menu } from 'semantic-ui-react';
import PixelCnameDomain from './PixelCnameDomain';
import EventTables from './EventTables';
import AccountValidations from './AccountValidations';
import DataFeatures from './DataFeatures';
import InviteUsers from './InviteUsers';
import TierColors from './TierColors';
import AllowedDomains from './AllowedDomains';
import ExcludedDomains from './ExcludedDomains';
import ExcludeUsers from './ExcludeUsers';
import Aggregations from './Aggregations';
import Notifications from './Notifications';
import AllowTransformTableTemplates from './TransformTableTemplates';
import { getUserNotifications, setUserNotifications, getUser, getNotificationTypes, deleteNotifications, getTransformEnableStatus, updateEnableTransformStatus, postEnableTransformStatus, getAccountCurrency, updateAccountCurrency, postAccountCurrency, createPortalSession } from '../../utils/api';
import { currencyOptionsFull, CURRENCY_CODES } from '../constants';



class AccountDetails extends Component {
  state = {
    notificationTypes: [],
    id: '',
    subscribed: {},
    enableTransform: 0,
    transformItem: {},
    curency: '',
    popupOpen: false,
    originalCurrency: false,
    currencyChanged: false,
  }
  
  componentDidMount() {
    const { menuItem } = this.props.params;
    if(!menuItem) {
      this.props.history.push(`/v2/settings/account/index/general`)
    }

    this.getData();

  }

  getData = () => {
    getUser()
      .then(response => {
        const { id } = response;

        getUserNotifications(id)
        .then(userNotifications => {
          const userSubscribedNotifications = userNotifications.reduce((obj,item) => {
            obj[item.notification_id] = item
            return obj;
          }, {});

          this.setState({id, subscribed: userSubscribedNotifications})
        });
      })

      getNotificationTypes()
        .then(notificationTypes => {
          this.setState({notificationTypes})
        })

      getTransformEnableStatus()
        .then(data => {
          const hasLength = data.length > 0;
          const transformItem = hasLength ? data[0] : {};
          const enableTransform = hasLength ? transformItem.use_transform_table : 0;

          this.setState({transformItem, enableTransform});
        })

      getAccountCurrency()
        .then(data => {
          const hasData = data.length > 0;
          const currencyData = hasData ? data[0] : {};
          hasData ? this.setState({currency: currencyData.currency_code, originalCurrency: currencyData.currency_code }) : this.setState({currency: '', originalCurrency: ''})
        })
  }

  componentDidUpdate(prevProps) {
    const { segments } = this.props;
    if(segments !== prevProps.segments) {
      const featured = segments.find(c => c.featured == 1)

      this.setState({segments, featured: featured ? featured : 1});
    }
  }

  handleEmailToggle = ({checked}, notification) => {
    const { id, featured } = this.state;

    if(checked) {
      const postObj = {
        notification_id: notification.notification_id,
        options: {filter_id: featured.filter_id}
      }

      setUserNotifications(id, postObj)
        .then(data => this.getData())
    } else {
      deleteNotifications(id)
        .then(data => this.getData())
    }
  }

  handleEnableTransform = ({checked}) => {
    const { enableTransform, transformItem } = this.state;
    const send = transformItem.id ? updateEnableTransformStatus : postEnableTransformStatus;
    const postObj = {
      use_transform_table: checked ? 1 : 0
    }

    send(postObj).then(data => this.getData());
  }

  handleCurrencyChange = (e, {value}) => {
    this.setState({currency: value, popupOpen: true})
  }

  handleCurrencyConfirmation = ( confirm ) => {
    const { originalCurrency, currency } = this.state;

    if( confirm == "yes") {
      const send = originalCurrency ? updateAccountCurrency : postAccountCurrency ;
      const postObj = { currency_code: currency };

      send(postObj).then(data => {
        this.getData()
        this.setState({popupOpen: false, currencyChanged: true})
      });
    } else {
      this.setState({popupOpen: false, currency: originalCurrency || ''})
    }
  }

  handleBillingClick = () => {
    createPortalSession()
      .then(({ url }) => {
        window.location.href = url
      })
  }

  handleMenuClick = (e, {name}) => {
    this.props.history.push(`/v2/settings/account/index/${name}`)
  }

  render() {
    const { account, segments, signup_source, username, params } = this.props;
    const { notificationTypes, subscribed, featured, enableTransform, currency, popupOpen, currencyChanged } = this.state;
    const touchpoint = segments.filter(c => c.touchpoints === 1)[0];

    return(
      <SplitLayout
        leftWidth={2}
        leftContent={<>
          <Menu vertical pointing>
            <Menu.Item active={params.menuItem == "general"} name="general" onClick={this.handleMenuClick}>
              General
            </Menu.Item>
            <Menu.Item active={params.menuItem == "domains"} name="domains" onClick={this.handleMenuClick}>
              Domains
            </Menu.Item>
            <Menu.Item active={params.menuItem == "users"} name="users" onClick={this.handleMenuClick}>
              Users
            </Menu.Item>
            <Menu.Item active={params.menuItem == "colors"} name="colors" onClick={this.handleMenuClick}>
              Colors
            </Menu.Item>
            {signup_source !== 'shopify' && <>
              <Menu.Item active={params.menuItem == "eventSources"} name="eventSources" onClick={this.handleMenuClick}>
                Event Sources
              </Menu.Item>
              <Menu.Item active={params.menuItem == "dataFeatures"} name="dataFeatures" onClick={this.handleMenuClick}>
                Data Features
              </Menu.Item>
              <Menu.Item active={params.menuItem == "dataValidations"} name="dataValidations" onClick={this.handleMenuClick}>
                Data Validations
              </Menu.Item>
              <Menu.Item active={params.menuItem == "dataExclusions"} name="dataExclusions" onClick={this.handleMenuClick}>
                Data Exclusions
              </Menu.Item>
              <Menu.Item active={params.menuItem == "aggregations"} name="aggregations" onClick={this.handleMenuClick}>
                Aggregations
              </Menu.Item>
            </>}
          </Menu>
          {signup_source === 'shopify' &&
            <Menu vertical pointing>
              <Menu.Item onClick={this.handleBillingClick}>
                Billing
              </Menu.Item >
            </Menu>
          }
        <Menu vertical pointing>
              <Menu.Item style = {{color: "black", fontWeight: "bold"}}>
                Personal Settings
              </Menu.Item>
              <Menu.Item active={params.menuItem == "notifications"} name="notifications" onClick={this.handleMenuClick}>
                Notifications
              </Menu.Item>
            </Menu>
        </>
          }
        rightWidth={14}
        rightContent={
          <React.Fragment>
            { params.menuItem == "general" && (
              <React.Fragment>
                <ContentCard title="Account Details" hasTable>
                  <Table definition columns={2}>
                    <Table.Row>
                      <Table.Cell>Account Name</Table.Cell>
                      <Table.Cell>{account['advertiser_name']}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Account Type</Table.Cell>
                      <Table.Cell>{signup_source == "shopify" ? "Starter (Shopify)" : "Professional (All Access)"}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Account ID</Table.Cell>
                      <Table.Cell>{account['pixel_source_name']}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Timezone</Table.Cell>
                      <Table.Cell>{account['timezone'] || "UTC"}</Table.Cell>
                    </Table.Row>
                    {touchpoint &&
                      <Table.Row>
                        <Table.Cell>{touchpoint.action_name} ID</Table.Cell>
                        <Table.Cell>{touchpoint.action_id}</Table.Cell>
                      </Table.Row>
                    }
                  </Table>
                </ContentCard>
                <ContentCard title="Your Settings">
                 {!notificationTypes.length &&
                   <Loader active inline='centered' size='small' />
                 }
                 {notificationTypes.map(notification => {
                   const notificationId = notification.notification_id;
                   return(
                     <Checkbox
                       toggle
                       label={<label>{notification.name}</label>}
                       checked={subscribed[notificationId] && subscribed[notificationId].deleted == 0 && subscribed[notificationId].active == 1}
                       onChange={(e,d) => this.handleEmailToggle(d,notification)}
                       disabled={featured == 1}
                     />
                   )
                 })}
                 {/* <div style={{marginTop: 5, marginBottom: 5}}>
                   <Checkbox
                     toggle
                     label={<label>Enable Transforms</label>}
                     checked={enableTransform}
                     onChange={(e,d) => this.handleEnableTransform(d)}
                   />
                 </div>
                 <AllowTransformTableTemplates /> */}
                 <br /><br />
                 <div>
                   <Popup
                    header={<h3><Icon name="warning sign" color="yellow"/> Are you sure?</h3>}
                    content={<div>
                      <p> This will change your currency to {currency}.</p>

                      <Button primary onClick={() => this.handleCurrencyConfirmation("no")} size="tiny">No</Button>
                      <Button onClick={() => this.handleCurrencyConfirmation("yes")} size="tiny">Yes</Button>
                    </div>}
                    open={popupOpen}
                    trigger={<div>
                      <Dropdown search selection placeholder="Select currency" options={currencyOptionsFull} value={currency} onChange={this.handleCurrencyChange}/>
                      { currencyChanged && <Icon name="checkmark" color="green" style={{marginLeft: 10}}/>}
                    </div>}
                  />
                 </div>
               </ContentCard>
              </React.Fragment>
            )}

            { params.menuItem == "domains" && (
              <React.Fragment>
                <PixelCnameDomain />
                <SplitLayout
                  rightWidth={8}
                  leftWidth={8}
                  rightContent={<ExcludedDomains advertiser={account.advertiser_name}/>}
                  leftContent={<AllowedDomains advertiser={account.advertiser_name}/>}
                />
              </React.Fragment>
            )}
            { params.menuItem == "eventSources" && <EventTables />}
            { params.menuItem == "dataValidations" && <AccountValidations />}
            { params.menuItem == "dataFeatures" && <DataFeatures />}
            { params.menuItem == "users" && <InviteUsers advertiser={account.advertiser_name} username={username} />}
            { params.menuItem == "colors" && <TierColors advertiser={account.advertiser_name} />}
            { params.menuItem == "dataExclusions" && <ExcludeUsers advertiser={account.advertiser_name}/>}
            { params.menuItem == "aggregations" && <Aggregations />}
            { params.menuItem == "notifications" && <Notifications />}
          </React.Fragment>
        }
      />
    )
  }

}

export default AccountDetails;
