import React, { useState, useEffect, useMemo } from 'react';
import { SortableIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Grid, Dropdown, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { getEntityConfigs } from '../api/attribution';
import { getDataset } from '../api/attributionCache';
import { rollupImpact } from '../views/Surveys/helpers';
import {
  TIERS as tiers,
} from '../constants/tiers';

const Mini = styled.div`
  display: inline-block;
  font-size: .9em
`;

const ChangeCell = ({ item, col }) => {
  const value = item[col.key];
  return (
    <Table.Cell>
      <ContentCard.PercentChangeLabel value={value} />
    </Table.Cell>
  );
};

const RoundCell = ({ item, col }) => {
  const value = Math.round(item[col.key] * 100, 2) / 100;
  return (
    <Table.Cell>
      { value }
    </Table.Cell>
  );
};

const _getSegmentId = () => getEntityConfigs()
  .then(impactData => ((impactData.length > 0) ? impactData[0].segments[0].segment_id : false));

const Impact = ({
  title = 'Entity', numDays = 30,
  datasets = ['entity_difference', 'entity_summary'],
  getSegmentId = _getSegmentId,
  segmentId = false,
}) => {
  const dataCols = [
    { key: 'even_without', display: `Before ${title} Tracking`, as: RoundCell, headerWidth: 1 },
    { key: 'even', display: `Including ${title} Tracking`, as: RoundCell, headerWidth: 1 },
    { key: 'change', display: '% Change', as: ChangeCell, headerWidth: 1 },
  ];
  const [impactDifference, setImpactDifference] = useState([]);
  const [impactDifferenceSummary, setImpactDifferenceSummary] = useState([]);

  const [segment_id, setSegmentId] = useState(segmentId);
  const [tier, setTier] = useState('tier_1');

  const chooseTier = (evt, { value }) => setTier(value);

  useEffect(() => {
    if (!segment_id) {
      getSegmentId()
        .then(setSegmentId);
    }
  }, []);

  useEffect(() => {
    if (segment_id) {
      const datasetString = datasets.join(',');
      getDataset(datasetString, segment_id, numDays)
        .then(({ response }) => {
          const data = response[datasets[0]];
          setImpactDifference(data);
        });
    }
  }, [segment_id]);

  useEffect(() => {
    const rollupImpactDifferenceSummary = rollupImpact(tier, impactDifference);
    setImpactDifferenceSummary(rollupImpactDifferenceSummary);
  }, [tier, impactDifference]);

  const cols = useMemo(() => {
    const allowedTiers = tiers.slice(0, tiers.indexOf(tier) + 1);
    if (impactDifferenceSummary.length) {
      const columns = IndexGrid.arrayToColumns(allowedTiers)
        .map(row => {
          row.headerWidth = 2;
          return row;
        });
      return columns.concat(dataCols);
    }
    return [];
  }, [impactDifferenceSummary]);

  return (
    <ContentCard title="Impact">
      <Grid columns="equal">
        <Grid.Row
          style={{
            fontWeight: 'bold',
            backgroundColor: '#F9FAFB',
            paddingLeft: '15px',
            lineHeight: '30px',
          }}
        >
          View Impact by: &nbsp;&nbsp;
          {' '}
          <Mini>
            <Dropdown
              value={tier}
              selection
              options={tiers.map(value => ({ value, text: value }))}
              onChange={chooseTier}
            />
          </Mini>
        </Grid.Row>
      </Grid>
      <SortableIndexGrid cols={cols} data={impactDifferenceSummary} />
    </ContentCard>
  );
};

export default Impact;
