import React, { useState, useEffect, useCallback } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Table } from 'semantic-ui-react';
import { getAccountUsers } from '../../api/account';
import InviteUsersRows from './InviteUsersRows';
import InviteUsersInput from './inviteUsersInput';


const InviteStatus = ({ item, col }) => {
  return <Table.Cell>{item[col.key] === 0 ? 'Active' : 'Invite Sent'}</Table.Cell>;
};

const InviteUsers = ({ advertiser, hideTitle, username, showFooter = false }) => {

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoadingUsers(true);
    getAccountUsers().then((data) => {
      setUsers(data);
      setLoadingUsers(false);
    });
  }, []);

  const handleManageUpdate = useCallback(() => getAccountUsers().then((data) => setUsers(data)));

  const manage_params = {
    current_username: username,
    handleManageUpdate,
  };

  const cols = [
    { display: 'Email', key: 'email' },
    { display: '', key: 'has_invite', as: InviteStatus },
    { display: '', key: manage_params, as: InviteUsersRows },
  ];

  return (
    <ContentCard title={!hideTitle && 'Invite Users'} loading={loadingUsers}>
      <InviteUsersInput {...{ handleManageUpdate, advertiser}} />
      {
        !loadingUsers &&
        <PagedIndexGrid
          className='invite-users-table'
          data={users}
          cols={cols}
          itemsPerPage={10}
          collapsed
          showFooter={showFooter}
        />
      }
    </ContentCard>
  );
};

export default InviteUsers;
