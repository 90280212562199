import React, { useMemo, useEffect, useState, useContext } from 'react';
import { SplitLayout } from '@rockerbox/styleguide';
import { Segment, Tab, Table, Dropdown } from 'semantic-ui-react';

import { ComposedChart, AreaChart, LineChart } from 'recharts';
import { ReferenceLine, Area, Line, Scatter } from 'recharts';
import { Label, Tooltip, Legend, YAxis, XAxis } from 'recharts';

const Optimize = ({ responseCurves, selectedData }) => {

  const [goal, setGoal] = useState(.8)
  const changeGoal = ({ currentTarget: { value }}) => {
    setGoal(value)
  }

  const targetSpend = useMemo(
    () => {
      if (!responseCurves) return {}

      return responseCurves
        .map(({key, values}) => {

          const index = values.findIndex(row => row.predicted_roas > goal)
          const lower = values.slice(index-1)[0]
          const actual = values.slice(index)[0]


          const spend_lower = lower.spend_input
          const spend_upper = actual.spend_input
          const roas_lower = lower.predicted_roas
          const roas_upper = actual.predicted_roas
          const feature_name = key
           
          return { feature_name, spend_lower, spend_upper, roas_lower, roas_upper }
        }, {})
    },
    [goal]
  )

  return <>
    <h3><b> Setup Budget Optimization </b></h3>
    <Segment>
      Choose Target ROAS: <input type="range" value={goal} min={0.1} max={2} step={0.1} onChange={changeGoal} />
    </Segment>
    <h3><b> Results </b>(Optimized for ROAS { goal }x)</h3>
    <h4> Proposed Budget Allocation </h4>
    <Table>
      <tr>
        <td><th>Channel / Strategy</th></td>
        <td><th>Daily Budget</th></td>
        <td><th>Expected ROAS</th></td>
        <td><th>Monthly Budget</th></td>
        <td><th>Predicted Revenue</th></td>
      </tr>
      { 
        targetSpend
          .map(row => (
            <tr>
              <td>{ row.feature_name }</td>
              <td>{ row.spend_upper } - { row.spend_lower }</td>
              <td>{ row.roas_upper } - { row.roas_lower }</td>
              <td>{ (row.spend_upper + row.spend_lower)/2*30 }</td>
              <td>{ (row.roas_upper + row.roas_lower)/2*(row.spend_upper + row.spend_lower)/2*30 }</td>
            </tr>
          )) 
       }
    </Table>
    <h4> Total Budget and Predicted Revenue </h4>
    <Table>
      <tr>
        <td><th>Monthly Budget</th></td>
        <td>{ targetSpend.reduce((total, row) => total + (row.spend_upper + row.spend_lower)/2*30, 0) }</td>
      </tr>
      <tr>
        <td><th>Predicted Revenue</th></td>
        <td>{ targetSpend.reduce((total, row) => total + (row.roas_upper + row.roas_lower)/2*(row.spend_upper + row.spend_lower)/2*30, 0) }</td>
      </tr>
    </Table>
  </>
}

export default Optimize;
