import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'
import { BasicViewHeader } from '../../components';
import { get } from 'lodash';

import { getAllIntegrations } from '../../api/account';

import StepsSection from './StepsSection'
import StepsConfig from './stepsConfig';

import { PLATFORM_NAME_MAP } from './constants';
import { selectWarehouseIntegrationLogos } from './utils';
import { DatalakeProvider } from './DatalakeContext/context'

const SetupConnection = () => {
  const { platformName } = useParams();
  const [integrations, setIntegrations] = useState([]);

  const platform = get(StepsConfig, platformName);

  useEffect(() => {
    getAllIntegrations().then(setIntegrations)
  }, [])

  const logos = selectWarehouseIntegrationLogos(integrations)

  const platformImage = get(logos, `${PLATFORM_NAME_MAP[platformName]}.logo_img`)

  const customSection = get(platform, 'customSection');

  return (
    <DatalakeProvider>
      <BasicViewHeader
        header={`${platform.name} Data Sync Setup`}
        subheader='Configure your connection from Rockerbox to your data warehouse'
      />
      {!integrations.length ? <ContentCard.CardLoader /> :
        <ContentCard style={{ marginTop: -9 }} hasTable>
          <Grid
            columns={2}
            divided={true}
            style={{ border: '1px solid rgba(34,36,38,.15)' }}
          >
            <Grid.Column width={3}>
              <Image size='medium' centered src={`https://images.rockerbox.com/integrations/${platformImage}`} />
            </Grid.Column>
            <Grid.Column width={13}>
              <StepsSection
                steps={platform.steps}
                customSection={customSection}
              />
            </Grid.Column>
          </Grid>
        </ContentCard>
      }
    </DatalakeProvider>
  )
}
export default SetupConnection;
