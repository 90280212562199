import React, { useMemo } from 'react'
import { Header } from 'semantic-ui-react'
import { ArrowCircleUpIcon, ArrowCircleDownIcon, CheckCircleIcon } from '@heroicons/react/outline'

import { CacheContext } from '../../../../utils/CacheContext'
import { getArtifacts } from '../../../api/attribution'
import { formatCurrency } from '../../../utils/valueFormatter'
import { parseArtifacts, calcPercentChange, roundNumber } from '../helpers'


const thinHeaderStyle = { fontWeight: 400, fontFamily: 'Poppins' }
const boldHeaderStyle = { fontWeight: 600, fontFamily: 'Poppins' }

const ChannelOptimizationRecommendation = ({ selectedChannel, selectedTactic, targetRoas, targetSpend, predictedRevenue, currentSpend, currentRevenue, modelDate }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const artifacts = Context.getCache('artifacts', getArtifacts) || null
  const parsedArtifacts = useMemo(() => parseArtifacts(artifacts), [artifacts])

  const helpDoc = useMemo(() => {
    if (!parsedArtifacts) return
    const { mmm_help_docs } = parsedArtifacts || {}
    return mmm_help_docs?.about
  }, [parsedArtifacts])

  const openHelpDoc = (e) => {
    if (!helpDoc || !window.Lighthouse) return
    window.Lighthouse.navigate(helpDoc.type, helpDoc.id)
    e.preventDefault()
    return false
  }

  const percentChange = useMemo(() => {
    if (!currentSpend || !targetSpend) return 0
    return calcPercentChange(currentSpend, targetSpend)
  }, [currentSpend, targetSpend])

  const direction = useMemo(() => {
    if (currentSpend === 0) return 'up'
    if (percentChange > 0) return 'up'
    if (percentChange < 0) return 'down'
    return null
  }, [percentChange])

  const directionWord = useMemo(() => {
    if (direction === 'up') return 'increase'
    if (direction === 'down') return 'decrease'
    return 'no change'
  }, [direction])

  const DirectionIcon = useMemo(() => {
    if (direction === 'up') return ArrowCircleUpIcon
    if (direction === 'down') return ArrowCircleDownIcon
    return CheckCircleIcon
  }, [direction])

  return (<>
    <Header
      as='h2'
      content={<>
        <DirectionIcon style={{ display: 'inline', margin: '0 5px -4px 0px', width: 23, height: 23 }} />
        {direction === null && `Maintain your current ${selectedChannel} ${selectedTactic} spend at ${formatCurrency(targetSpend, 'USD', 0)}`}
        {!!direction && `${directionWord.slice(0,1).toUpperCase()}${directionWord.slice(1)} ${selectedChannel} ${selectedTactic} spend to ${formatCurrency(targetSpend, 'USD', 0)}`}
      </>}
      style={{ marginTop: 24, marginLeft: 28, textIndent: -28, fontSize: 21, fontWeight: 600 }}
    />
    <Header as='h3' style={{ lineHeight: '1.5em' }}>
      <span style={thinHeaderStyle}>Based on your current model, we predict the</span>{' '}
      <span style={boldHeaderStyle}>optimal spend for {selectedChannel} {selectedTactic}</span>{' '}
      <span style={thinHeaderStyle}>to achieve a target ROAS of {roundNumber(targetRoas, 2)} would be</span>{' '}
      <span style={boldHeaderStyle}>{formatCurrency(targetSpend, 'USD', 0)} per day</span>
      {direction === null &&
        <span style={thinHeaderStyle}>, which matches your current daily spend.</span>
      }
      {currentSpend === 0 && direction === 'up' &&
        <span style={thinHeaderStyle}>.</span>
      }
      {currentSpend !== 0 && !!direction && <>
        <span style={thinHeaderStyle}>, a{direction == 'up' && 'n'}</span>{' '}
        <span style={boldHeaderStyle}>{directionWord} of {roundNumber(Math.abs(percentChange))}%</span>{' '}
        <span style={thinHeaderStyle}>over your current daily spend.</span>
      </>}
    </Header>
      <Header as='h3' style={{ lineHeight: '1.5em' }}>
        {direction === null && <>
          <span style={thinHeaderStyle}>If you maintain this spend rate, we predict</span>{' '}
          <span style={boldHeaderStyle}>{formatCurrency(predictedRevenue, 'USD', 0)} in daily revenue</span>
        </>}
        {!!direction && <>
          <span style={thinHeaderStyle}>If you make this change, we predict</span>{' '}
          <span style={boldHeaderStyle}>
            your daily revenue will {directionWord}{' '}
            {currentSpend !== 0 && `from ${formatCurrency(currentRevenue, 'USD', 0)} `}
            to {formatCurrency(predictedRevenue, 'USD', 0)}
          </span>
        </>}
        <span style={thinHeaderStyle}>.</span>
      </Header>
    <Header as='h4' style={{ lineHeight: '1.5em' }}>
      <Header.Subheader style={{ fontSize: 12, fontWeight: 500 }}>
        This analysis is based on your model built on {modelDate}.<br />
        <a
          href="https://help.rockerbox.com/article/q83uv32p3g"
          target='_blank'
          onClick={openHelpDoc}
          style={{ textDecoration: 'underline' }}
        >
          Learn more about Marketing Mix Modeling
        </a>{' '}
        and how these predictions work.
      </Header.Subheader>
    </Header>
  </>)
}

export default ChannelOptimizationRecommendation
