import React, { Component } from 'react';
import { SplitLayout } from '@rockerbox/styleguide';
import EntityProgram from './EntityProgram';

const ViewPrograms = (props) => {
  return <SplitLayout
      leftWidth={13}
      rightWidth={3}
      leftContent={<EntityProgram />}
      rightContent={<></>}
    />
}

export default ViewPrograms;
