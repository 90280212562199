import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllIntegrations, getPlatformAccounts, getAuthorizations, postPlatformAccount, getAccount } from '../../api/account';
import * as routes from '../../baseRoutes';
import { track } from '../../../utils/tracking';
import { DataWarning } from '../../components';
import NONOAUTH_PLATFORMS from '../../constants/platforms';

import { OAuthForm, NonOAuthForm } from './FinalStepForms';

export const PLATFORM_SKIP_LAST_STEP_WHITELIST = [
  'amazon_selling_partner_api',
];

const AdvertisingPlatformLastStep = ({ history, redirectLocation }) => {
  const [integration, setIntegration] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [platformAccounts, setPlatformAccounts] = useState(undefined);
  const [selectionValue, setselectionValue] = useState('');
  const [authId, setAuthId] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [account, setAccount] = useState({});
  const { platformName } = useParams();

  const navigate = route => history.push(route);
  const isPopup = !!window.opener;

  useEffect(() => {
    if (!!platformName && PLATFORM_SKIP_LAST_STEP_WHITELIST.indexOf(platformName) > -1) navigate(routes.advertisingPlatforms);

    getAllIntegrations()
      .then(data => {
        const i = data.find(c => c.platform === platformName);
        i.src = `https://images.rockerbox.com/integrations/${i.logo_img}`;
        setIntegration(i);
      });

    getAccount()
      .then(setAccount);

    if (Object.keys(NONOAUTH_PLATFORMS).includes(platformName)) return;

    getAuthorizations()
      .then(data => {
        const platform = data.find(c => c.platform === platformName);
        const { authorizations } = platform;
        const { id } = authorizations.sort((a, b) => b.updated - a.updated).find(a => a.account_id === '');
        setAuthId(id);

        getPlatformAccounts(platformName, id)
          .then(resp => {
            const accounts = resp.map(c => ({
              text: c.account_name,
              value: platform.platform === 'adwords' ? c.customer_id : c.account_id,
              ...c,
            }));
            setPlatformAccounts(accounts);
          });
      });
  }, []);

  useEffect(() => {
    if (!selectionValue) return;
    const acc = platformAccounts.find(c => c.value === selectionValue);
    setIsManager(acc.is_manager);
  }, [selectionValue]);

  const handleConnect = () => {
    setLoading(true);
    const acc = platformAccounts.find(c => c.value === selectionValue);

    track('integration.authentication.complete', {
      platform: platformName,
      id: authId,
      account: acc,
    });

    postPlatformAccount(platformName, authId, acc)
      .then(response => {
        if (response.error) {
          setErrorMsg(response.error);
          return;
        }

        if (isPopup) {
          window.close();
          return;
        }

        navigate(redirectLocation || routes.advertisingPlatforms);
      });
  };

  const { tier, requires_setup } = account;
  const isOnboarding = tier !== 'lite' && !!requires_setup;

  return (
    errorMsg
      ? (
        <DataWarning
          header="Account setup failure"
          details={errorMsg}
          intro="If you believe this is an error or would like more information"
        />
      )
      : Object.keys(NONOAUTH_PLATFORMS).includes(platformName)
        ? <NonOAuthForm {...{ platform: platformName, integration }} />
        : <OAuthForm {...{ integration, isManager, platformAccounts, platformName, handleConnect, selectionValue, setselectionValue, loading, isOnboarding }} /> // eslint-disable-line
  );
};

export default AdvertisingPlatformLastStep;
