import * as d3 from 'rockerbox_d3_legacy_clone';
import _ from 'lodash';

export default function drawSlopegraph(data, width, height, suffix) {
  d3.reports = {};

  d3.reports.slopegraph_v2 = function module() {
    // input vars for getter setters
    let w = width,
        h = height,
        margin = {top: 40, bottom: 40, left: 80, right: 80},
        gutter = 50,
        strokeColor = '#000',
        strokeIncrease = '#73d0a1',
        strokeDecrease = '#f29173',
        keyValues = [], // key data values (in order)
        keyName = '', // key value (used for ref/titles)
        format = d3.format(''),
        sets;

    let dispatch = d3.dispatch('_hover');

    let svg, yScale;

    function exports(_selection) {
      _selection.each(function(data) {

      let allValues = [],
          maxValue;

      // format/clean data
      data.forEach(function(d) {
        _.times(keyValues.length, function (n) {
          d[keyValues[n]] = +d[keyValues[n]];
          allValues.push(d[keyValues[n]]);
        });
      });

      // create max value so scale is consistent
      maxValue = _.max(allValues);
      // adapt the size against number of sets
      w = w * keyValues.length;
      // have reference for number of sets
      sets = keyValues.length -1;
      // use same scale for both sides
      yScale = d3.scale
                 .linear()
                 .domain([0, maxValue])
                 .range([h - margin.top, margin.bottom]);

        // clean start
        d3.select(this).select('svg').remove();

        svg = d3.select(this)
                .append('svg')
                .attr({
                  width: w,
                  height: h
                });

        render(data, 0);
      });
    }

    // recursive function to apply each set
    // then the start and end labels (as only needed once)
    function render (data, n) {
      if (n < keyValues.length-1 ) {
        lines(data, n);
        return render(data, n+1);
      } else {
        startLabels(data);
        endLabels(data);
        return n;
      }
    }

    // render connecting lines
    function lines(data, n) {
      let lines = svg.selectAll('.s-line-' + n).data(data);

      lines.enter().append('line');

      lines.attr({
        x1: function () {
              if (n === 0) {
                return margin.left;
              } else {
                return ((w / sets) * n) + margin.left/2;
              }
            },
        y1: function(d) { return yScale(d[keyValues[n]]); },
        x2: function () {
              if (n === sets-1) {
                return w - margin.right;
              } else {
                return ((w / sets) * (n+1)) - gutter;
              }
            },
        y2: function(d) { return yScale(d[keyValues[n+1]]); },
        stroke: (d) => yScale(d[keyValues[n+1]]) < yScale(d[keyValues[n]]) ? strokeIncrease : strokeDecrease,
        'stroke-width': 3,
        class: function (d, i) { return 'elm s-line-' + n + ' sel-' + i; }
      })
      .on('mouseover', dispatch._hover);
    }

    // start labels applied left of chart sets
    function startLabels(data) {
      let startLabels = svg.selectAll('.l-labels').data(data);

      startLabels.enter().append('text')
                 .attr({
                   class: function (d, i) { return 'labels l-labels elm ' + 'sel-' + i; },
                   x: margin.left - 3,
                   y: function(d) { return yScale(d[keyValues[0]]) + 4; }
                 })
                 .text(function (d) {
                   return d[keyName] + ' ' + format(d[keyValues[0]]);
                 })
                 .style('text-anchor','end')
                 .on('mouseover', dispatch._hover);

      // title
      svg.append('text')
         .attr({
           class: 's-title',
           x: margin.left - 3,
           y: margin.top/2
         })
         .text(keyValues[0] + ' ↓')
         .style('text-anchor','end');
    }

    // end labels applied right of chart sets
    function endLabels(data) {
      let i = keyValues.length-1;

      let endLabels = svg.selectAll('r.labels').data(data);

      endLabels.enter().append('text')
               .attr({
                 class: function (d, i) { return 'labels r-labels elm ' + 'sel-' + i; },
                 x: w - margin.right + 3,
                 y: function(d) { return yScale(d[keyValues[i]]) + 4; },
               })
               .text(function (d) {
                 return d[keyName] + ' ' + format(d[keyValues[i]]);
               })
               .style('text-anchor','start')
               .on('mouseover', dispatch._hover);

      // title
      svg.append('text')
         .attr({
           class: 's-title',
           x: w - margin.right + 3,
           y: margin.top/2
         })
         .text('↓ ' + keyValues[i])
         .style('text-anchor','start');
    }

    // getter/setters for overrides
    exports.w = function(value) {
      if (!arguments.length) return w;
      w = value;
      return this;
    }

    exports.h = function(value) {
      if (!arguments.length) return h;
      h = value;
      return this;
    }

    exports.margin = function(value) {
      if (!arguments.length) return margin;
      margin = value;
      return this;
    }

    exports.gutter = function(value) {
      if (!arguments.length) return gutter;
      gutter = value;
      return this;
    }

    exports.format = function(value) {
      if (!arguments.length) return format;
      format = value;
      return this;
    }

    exports.strokeColor = function(value) {
      if (!arguments.length) return strokeColor;
      strokeColor = value;
      return this;
    }

    exports.keyValues = function(value) {
      if (!arguments.length) return keyValues;
      keyValues = value;
      return this;
    }

    exports.keyName = function(value) {
      if (!arguments.length) return keyName;
      keyName = value;
      return this;
    }

    d3.rebind(exports, dispatch, 'on');
    return exports;
  }

  // keys values from data to be applied
  // TODO: make this parameterizable
  let keyValues = [
    'Last Touch',
    'Modeled Multi-Touch',
    // 'Even Weight',
    // 'First Touch',
  ];

  // store chart
  let slopegraph;
  // track any user interactions
  let state = {
     // have an array to mutate
     keys: keyValues,
     // track filtered sets
     filter: [],
     // toggle highlights
     navToggle: [],
     // track line selection
     highlight: null
  };

   // initial render chart
   render(data, keyValues);
   // select line nav
   navAlt(data);

  // navigation to highlight lines
  function navAlt(data) {
    // create array values
    _.times(data.length, function(n) {
      state.navToggle.push(true);
    });

    d3.select('#slopegraph-selects').append('ul')
      .selectAll('li')
      .data(data)
      .enter().append('li')
      .attr('class', function (d, i) { return 'navAlt li-' + i; })
      .on('click', function (d, i) {
        if (!state.navToggle[i]) {
          // update toggle state
          state.navToggle[i] = true;
          resetSelection();
          state.highlight = null;
        } else if (state.navToggle[i]) {
          state.navToggle[i] = false;
          // hover to highlight line
          highlightLine(i);
          // highlight nav in relation to line
          highlightNav(i);
          // update state
          state.highlight = i;
        }
      })
      .text(function (d) { return d['key']; });
  }

  // render slopegraph chart
  function render(data, keys) {
    resetSelection();

    // create chart
    slopegraph = d3.reports.slopegraph_v2()
                   .margin({top: 20, bottom: 20, left: 100, right: 100})
                   .gutter(25)
                   .keyName('key')
                   .keyValues(keys)
                   .on('_hover', function (d, i) {
                      // hover to highlight line
                      highlightLine(i);
                      // highlight nav in relation to line
                      highlightNav(i);
                      // update state of selected highlight line
                      state.highlight = i;
                   });

    // apply chart
    d3.select('#slopegraph')
      .datum(data)
      .call(slopegraph);

    // ensure highlight is maintained on update
    if (!_.isNull(state.highlight)) {
      d3.selectAll('.elm').style('opacity', 0.2);
      d3.selectAll('.sel-' + state.highlight).style('opacity', 1);
      highlightNav(state.highlight);
    }
  }

  function highlightLine(i) {
    d3.selectAll('.elm').transition().style('opacity', 0.2);
    d3.selectAll('.sel-' + i).transition().style('opacity', 1);
  }

  function highlightNav(i) {
    d3.selectAll('.navAlt').transition().style('opacity', 0.6);
    d3.select('.li-' + i).transition().style('opacity', 1);
  }

  function resetSelection() {
    d3.selectAll('.elm').transition().style('opacity', 1);
    d3.selectAll('.navAlt').transition().style('opacity', 1);
  }
}
