import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Message, Grid, Form, Icon, Table, Button } from 'semantic-ui-react'
import { ContentCard, IndexGrid, FullscreenModal } from '@rockerbox/styleguide'
import { getSegments, getSurveyConfigs } from '../../../utils/api.js'
import { getPixelCode } from '../../../utils/api.js'
import { addPlatformFeature } from '../../../utils/api.js'
import EmptyFeature from '../../Empty';
import * as routes from '../../../routes.js'

import WebhookSetup from './Webhook';
import PixelSetup from './Pixel';
import BatchSetup from './Batch';
import ConversionSetup from './Conversion';
import SurveySetup from './Survey';
import DataWarehouseSetup from '../../DataWarehouse';


const DefaultSetup = ({ platform }) => {

  return <ContentCard>
    <ContentCard.EmptyView
      icon='check'
      fallBackMsg='Setup complete'
    />
  </ContentCard>

}

const SetupsIndex = ({ showPlatform, details, feature, activeFeature, activeIntegration, refresh }) => {

  const [createFeature, setCreateFeature] = React.useState(false)
  const hasActiveIntegration = !!Object.keys(activeIntegration).length

  if (!hasActiveIntegration && createFeature) {
    addPlatformFeature(activeFeature['platform'], activeFeature['id'])
      .then(() => {
        refresh()
      })
    return <ContentCard.CardLoader />
  }

  if (!hasActiveIntegration) {
    return <EmptyFeature
      message={`The integration with ${showPlatform} is currently not enabled for your account`}
      callToAction={ <Button onClick={() => setCreateFeature(true)}>Enable Integration</Button>}
    />
  }

  //const { feature_type } = activeFeature;
  const { feature_type } = details

  if (hasActiveIntegration) {
    const Component = (feature_type == "survey" || feature_type == "webhook_survey") ?  SurveySetup :
      feature_type == "conversion" ? ConversionSetup :
      feature_type == "webhook_conversion" ? ConversionSetup :
      feature_type == "pixel_setup" ? PixelSetup :
      feature_type == "inbox_setup" ? BatchSetup :
      feature_type == "webhook_setup" ? WebhookSetup :
      feature_type == "warehouse" ? DataWarehouseSetup :
      DefaultSetup ;
    return <Component {...{platform: showPlatform}} />
  }
}

export default SetupsIndex;
