import React, { Component } from 'react'
import { TransitionablePortal, Segment, Header, Icon } from 'semantic-ui-react'

const FullscreenModal = props => (
  <TransitionablePortal
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    trigger={props.trigger}
    openOnTriggerClick
    closeOnTriggerClick
    transition={{
      animation: 'fade up',
      duration: 500
    }}
  >
    <Segment className='fullscreen-modal'>
      <div className='fullscreen-modal-inner'>
        <div className='fullscreen-modal-header'>
          <Header
            icon={props.headerIcon}
            content={props.headerContent}
          />
          <Icon
            name='close'
            color='grey'
            size='large'
            link
            onClick={() => props.onClose()}
          />
        </div>
        <div className='fullscreen-modal-content'>
          {props.children}
        </div>
      </div>
    </Segment>
  </TransitionablePortal>
)

export default FullscreenModal
