import React from 'react'
import { Grid, Container, Image, Header, Label, Button, Modal } from 'semantic-ui-react'
import { ContentCard } from '@rockerbox/styleguide'

const GatedView = ({ title, image, imageHeight, heading, subheading, label, primaryButton, secondaryButton, features, showNumberedFeatures, marginTop}) => {
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)

  return (<>
    <Container textAlign='center' className='gated-view' style={{ marginTop }}>
      <Image
        src={image}
        size='large'
        centered
        style={{height: imageHeight}}
      />
      <Header as='h1'>
        {heading}
        {!!label && <Label content={label} />}
        <Header.Subheader className=''>{subheading}</Header.Subheader>
      </Header>
      <div>
        {!!secondaryButton ? secondaryButton :
          <Button
            className={!!primaryButton && 'm-r-15'}
            content="How does it work?"
            onClick={() => setDescriptionOpen(!descriptionOpen)}
          />
        }
        {primaryButton}
      </div>

    </Container>
    <FeaturesModal
      {...{descriptionOpen, title, features, showNumberedFeatures, primaryButton}}
      onClose={() => setDescriptionOpen(false)}
    />
  </>)
}

const FeaturesModal = ({ descriptionOpen, title, features, showNumberedFeatures, onClose, primaryButton }) => (
  <Modal
    closeIcon
    open={descriptionOpen}
    {...{onClose}}
  >
    <Modal.Header style={{fontWeight: 400}}>
      How does <strong>{title}</strong> work?
    </Modal.Header>
    <Modal.Content>
      <Grid
        centered
        verticalAlign='middle'
        padded='vertically'
        className='features-explainer'
      >
          {!!features &&
            features.map((item, idx) => (
              <Grid.Row>
                <Grid.Column width={6}>
                  <Image
                    ui={false}
                    src={item.image}
                    className='feature-image'
                  />
                </Grid.Column>
                <Grid.Column width={10}>
                  <Header
                    as='h3'
                    content={<>
                      {showNumberedFeatures && <Label circular content={idx+1} />}
                      {item.title}
                    </>}
                    subheader={item.description}
                    className='feature-text'
                  />
                </Grid.Column>
              </Grid.Row>
            ))
          }

      </Grid>
    </Modal.Content>
    <Modal.Actions style={{textAlign: 'center'}}>
      <Button
        onClick={onClose}
        content="Close"
      />
      {primaryButton}
    </Modal.Actions>
  </Modal>
)

export default GatedView
