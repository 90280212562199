import React from 'react';
import { Icon, Header, Button} from 'semantic-ui-react';

const HandlerBoundary = () => {
  const handleReset = () => {
    location.reload();
    return false;
  }

  return(
    <div style={{textAlign: 'center', paddingTop: 30, paddingBottom: 30}}>
      <Icon name="warning sign" size="massive" color="yellow"/>
      <Header as='h2'>
        Oops! Something went wrong.
      </Header>
      <Button
        primary
        size='large'
        onClick={handleReset}
      >
        Try Again
      </Button>
    </div>
  )
}

export default HandlerBoundary;