import React, { useEffect, useState } from "react";
import moment from "moment";
import ProcessingTable from "./ProcessingTable";
import FinishedTable from "./FinishedReport";
import SendSavedReportModal from "./SendSavedReportModal";
import SendNewReportModal from "./SendNewReportModal";
import StatusCard from "./StatusCard";
import {
  getRunningReportJobs,
  getOutboxes,
  getSavedreports,
  getReportTypes,
  getSegments,
} from "../../utils/api";
import {
  parsedOutboxes,
  formatRunningJobs,
  formatDeliveredJobs,
} from "./helper";
import { Input, Button, Dropdown, Message } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DELIVERED, MYDELIVERED, FAILED } from "./constants";
import * as routes from '../../V3/baseRoutes';
import { DeprecationBanner } from "../stateless";

const ReportDownload = (props) => {
  const { username, pixel_source_name } = props;
  const [loading, setLoading] = useState(true);
  const [reloadProcessing, setReloadProcessing] = useState(false);
  const [reloadFinished, setReloadFinished] = useState(false);
  const [runningJobs, setRunningJobs] = useState([]);
  const [exportedReports, setExportedReports] = useState([]);
  const [displayType, setDisplayType] = useState(DELIVERED);
  const [myReport, setMyReport] = useState(false);
  const [openSavedReportModal, setSavedReportModal] = useState(false);
  const [openSegmentReportModal, setSegmentReportModal] = useState(false);
  const [savedReports, setSavedReports] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [outboxes, setOutboxes] = useState([]);
  const [segments, setSegments] = useState([]);
  const [reloadNums, setReloadNums] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const triggerReload = () => {
    setReloadProcessing(true);
    getRunningReportJobs(moment.utc().utcOffset(-5).format("YYYY-MM-DD")).then((data) => {
      setRunningJobs(formatRunningJobs(data));
      setReloadProcessing(false);
      setReloadNums((prevState, currentProps) => {
        setReloadNums(prevState + 1)
      })
  })}

  // pull initial data and set interval to pull new running status every 30000
  useEffect(() => {
    const now = moment.utc().utcOffset(-5).format("YYYY-MM-DD");
    Promise.all([
      getRunningReportJobs(now).then((data) =>
        setRunningJobs(formatRunningJobs(data))
      ),
      getOutboxes().then((data) => {
        const outboxes = parsedOutboxes(data);
        const spaceOutbox = outboxes.filter(
          (o) => o.outbox_type == "do_spaces"
        )[0];
        const exportedReports = spaceOutbox
          ? spaceOutbox["messages"].map((item) => Object.assign({}, item))
          : [];

        return setExportedReports(formatDeliveredJobs(exportedReports));
      }),
      getSegments().then((data) => setSegments(data)),
    ])
      .then(() => {
        setLoading(false);
        getSavedreports().then((data) => setSavedReports(data));
        getOutboxes().then((data) => setOutboxes(data));
        getReportTypes().then((data) => setReportTypes(data));
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });

    const id = setInterval(triggerReload, 5000);
    setIntervalId(id)

    return () => setIntervalId((prevState, currentProps)=> {
      clearInterval(prevState);
      return null;
    })
  }, []);

  useEffect(() => {
    if (reloadNums === 10) {
      clearInterval(intervalId);
      const newId = setInterval(triggerReload, 15000)
        setIntervalId(newId)
    } else if (reloadNums === 100) {
      clearInterval(intervalId);
      const newId = setInterval(triggerReload, 60000)
      setIntervalId(newId)
    }
  }, [reloadNums])

  // when number of running job changes, pull finished job again.
  useEffect(() => {
    setReloadFinished(true);
    getOutboxes().then((data) => {
      const outboxes = parsedOutboxes(data);
      const spaceOutbox = outboxes.filter(
        (o) => o.outbox_type == "do_spaces"
      )[0];
      const exportedReports = spaceOutbox
        ? spaceOutbox["messages"].map((item) => Object.assign({}, item))
        : [];

      setExportedReports(formatDeliveredJobs(exportedReports));
      setReloadFinished(false);
    });
  }, [runningJobs.length]);

  const isRBUser = username && username.trim().slice(-14) === "@rockerbox.com";

  const DropdownButtons = () => {
    const [openDropdown, setOpenDropdown] = useState(false);

    const buttonOptions = [
      {
        key: "segment",
        icon: "send",
        text: "By Conversion",
        value: "segment",
        onClick: (e, data) => setSegmentReportModal(true),
      },
      {
        key: "saved",
        icon: "save",
        text: "From Saved Report",
        value: "saved",
        onClick: (e, data) => setSavedReportModal(true),
      },
    ];
    return (
      <Button.Group primary style={{ marginBottom: "10px" }}>
        <Button onClick={(e) => setOpenDropdown(true)}>Send Report</Button>
        <Dropdown
          className="button icon"
          floating
          open={openDropdown}
          onClose={(e) => setOpenDropdown(false)}
          onClick={(e) => setOpenDropdown(true)}
          options={buttonOptions}
          trigger={<React.Fragment />}
        />
      </Button.Group>
    );
  };

  const segmentIdNameMap = segments.reduce((prev, cur) => {
    prev[cur["action_id"]] = cur["action_name"];
    return prev;
  }, {});

  const resetTimerCount = () => setReloadNums(1)


  return (
    <React.Fragment>
      <DeprecationBanner viewName='Downloads' newViewName='Ad Hoc Exports' newViewPath='/v3/data/exports/ad_hoc_exports' />
      <Message info>
        <Message.Header>Switch to the new & improved Ad Hoc Exports ✨</Message.Header>
        <ul style={{ fontSize: 14, paddingLeft: 17, marginTop: 5, lineHeight: '1.5em' }}>
          <li>Export data for <b>any</b> date range, up to multiple years at once</li>
          <li>Group data by daily, weekly, monthly, quarterly, etc.</li>
          <li>Group data by custom tier dimensions</li>
          <li>Exports generate <b>10x faster</b> and can be shared with anyone (Rockerbox login not required)</li>
        </ul>
        <Button
          fluid
          color="blue"
          icon="right arrow"
          labelPosition="right"
          content="Go to Ad Hoc Exports"
          as={Link}
          to={routes.adHocExportsHistory}
        />
      </Message>
      <DropdownButtons />
      <ProcessingTable
        runningJobs={runningJobs}
        segmentIdNameMap={segmentIdNameMap}
        loading={loading}
        reloading={reloadProcessing}
        username={username}
      />

      <StatusCard
        exportedReports={exportedReports}
        setDisplayType={setDisplayType}
        displayType={displayType}
        username={username}
      />
      <FinishedTable
        exportedReports={exportedReports}
        segmentIdNameMap={segmentIdNameMap}
        loading={loading}
        displayType={displayType}
        reloading={reloadFinished}
        isRBUser={isRBUser}
        username={username}
      />
      <SendSavedReportModal
        open={openSavedReportModal}
        savedReports={savedReports}
        triggerReload={triggerReload}
        resetTimerCount={resetTimerCount}
        segmentIdNameMap={segmentIdNameMap}
        reportTypes={reportTypes}
        outboxes={outboxes}
        close={() => setSavedReportModal(false)}
        username={username}
        title="Send Saved Report"
      />
      <SendNewReportModal
        segments={segments}
        triggerReload={triggerReload}
        resetTimerCount={resetTimerCount}
        open={openSegmentReportModal}
        pixel_source_name={pixel_source_name}
        reportTypes={reportTypes}
        close={() => setSegmentReportModal(false)}
        username={username}
        isRBUser={isRBUser}
        title="Send Report"
      />
    </React.Fragment>
  );
};

function mapStateToProps(state, props) {
  const {
    username,
    user: { pixel_source_name },
  } = state.app;

  return { username, pixel_source_name };
}

export default connect(mapStateToProps)(withRouter(ReportDownload));
