import React from 'react'
import { SketchPicker } from 'react-color'
import { colorNames } from '../constants/mappings'

const ColorPicker = ({ color, onChange }) => {
  const [displayColorPicker, setSisplayColorPicker] = React.useState(false)

  const presetColors = Object.keys(colorNames).map(key => colorNames[key])

  const handleClick = () => setSisplayColorPicker(!displayColorPicker)

  const handleClose = () => setSisplayColorPicker(false)

  return (
    <div className='color-picker'>
      <div className='swatch' onClick={handleClick}>
        <div className='color-preview' style={{background: color}} />
      </div>
      {displayColorPicker &&
        <div className='color-picker-popup'>
          <div className='cover' onClick={handleClose}/>
          <SketchPicker
            disableAlpha
            color={color}
            onChange={onChange}
            presetColors={presetColors}
          />
        </div>
      }
    </div>
  )
}

export default ColorPicker
