import React, { Component, useMemo } from 'react';
import { MultiInput, ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { getSpendFields, getTransformTables, updateTransformTables } from '../../utils/api';
import CacheContext from '../../utils/CacheContext';
import { Input, Icon, Form, Button } from 'semantic-ui-react';
import TransformTableResults from './TransformTableResults';

const PlatformMultiInput = React.memo((props) => {
  //console.log(props)

  const { platform } = props;
  const [state, setState, Context] = React.useContext(CacheContext);
  const selectOptions = Context.getCache(`${platform}Options`, getSpendFields, platform);
  //const selectOptions = []

  return <MultiInput {...{selectOptions}} {...props} />
})

export default class TransformTableDetails extends Component {

  state = {
    edit: false,
    details: {},
    transformTables: []
  }

  findTableByDetails = (details, transformTables) => {
    const matchingTables = transformTables.filter((d) => d.platform_integration_id == details.id)
    matchingTables.map((t) => {
      t.submitted = false;
      return t
    });
    return matchingTables
  }

  componentDidMount() {
    const { details } = this.props;
    getTransformTables()
      .then((data) => {
        const transformTables = data;
        details.transform_tables = this.findTableByDetails(details, transformTables)
        this.setState({ details, transformTables });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { details, transformTables } = this.state;
    if (Object.keys(details) < 1) return;

    const propDetails = this.props.details;
    const detailFields = ["platform", "feature"]
    const detailsChanged = detailFields.filter(key => details[key] != propDetails[key]).length > 0

    if (details && detailsChanged){
      const newDetails = this.props.details;
      newDetails.transform_tables = this.findTableByDetails(newDetails, transformTables)
      this.setState({ details: newDetails });
    }
  }

  handleFormSubmit = () => {
    const postObj = this.state.details.transform_tables[0];
    const transformTableId = postObj.id;

    updateTransformTables(postObj, transformTableId)
    .then(data => {
      this.handleFormUpdate("submitted")(null, {'value': true});
    })
  }

  handleFormUpdate = _.memoize((key) => (e,d) => {
    const { details } = this.state;
    const stateCopy = _.cloneDeep(this.state);
    const { transform_tables } = stateCopy.details;
    if (transform_tables[0][key] != d.value) {
      transform_tables[0][key] = d.value;
      transform_tables[0]["modified"] = true;
      //console.log(transform_tables[0])
      this.setState(stateCopy);
    }
  })
  toggleEdit = () => {
    const { edit } = this.state
    this.setState({edit: !edit})
  }

  render() {
    const { toggleEdit, handleFormUpdate, handleFormTest, handleFormSubmit } = this;
    const { edit, details } = this.state;
    const { transform_tables } = details
    if (!transform_tables || transform_tables.length == 0) return null;

    const transform_table = transform_tables[0]
    const { id, name, spend_key } = transform_table;
    const transformTableId = transform_table.id
    const platform = transform_table.platform

    const title = (
      <React.Fragment>
        Transform Table
        <Button floated='right' as='a' content={edit ? "Close" : "Edit"} onClick={toggleEdit} size='mini' />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ContentCard title={title} noContent={!edit} >
          {edit && Object.keys(details).length > 0 && <Form onSubmit={handleFormSubmit}>
            <Form.Input label="Name" value={name} onChange={handleFormUpdate("name")}/>
            <PlatformMultiInput {...{id, platform}} label="Spend Key" value={spend_key} onChange={handleFormUpdate("spend_key")} />
            <Form.Group widths="equal" style={{flexWrap:"wrap"}}>
              { [1,2,3,4,5].map(num => {
                  const label = `Tier ${num}`;
                  const key = `transform_tier_${num}`;
                  const value = transform_table[key];
                  return <PlatformMultiInput key={transformTableId + ":" + key} {...{id, platform, label, value}} onChange={handleFormUpdate(key)}/>
                })
              }
            </Form.Group>
            <Button primary content="Save" type="submit"/> 
            {transform_tables[0].submitted && <Icon name="checkmark" color="green" size="large"/>}
          </Form>}
        </ContentCard>
        <TransformTableResults {...{ transformTableId, transform_table, edit}} />
      </React.Fragment>
    )
  }
}
