import React, { createContext, useState} from 'react'
import { Sidebar } from 'semantic-ui-react'

export const DimmerContext = createContext();

export const DimmerContextProvider = (props) => {
  const [dimmed, setDimmed] = useState(false)

  return (
    <DimmerContext.Provider value={{ dimmed, setDimmed }}>
      <Sidebar.Pushable>
        <Sidebar.Pusher dimmed={dimmed}>
          {props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      <div id="v3-custom-sidebar"></div>
    </DimmerContext.Provider>
  )
}