import React, { useMemo, useState, useEffect, useContext } from 'react';
import * as aq from "arquero";

import { getViaCache } from '../../../../utils/arrow/performanceDataCache'
import { buildConversionMetricsDropdown } from '../helpers'
import { useParamsWithDefaults } from '../urlParamHooks';

import { usePlatformNames } from './platformConfig/platformNames'
import { isMetaDataCurrent } from './platformConfig/helpers'

import { TIERS } from '../../../constants/tiers'
import { getAccountCurrency } from "../../../api/account"
import { getCurrencyRate } from "../../../api/currencyRate"
import CacheContext from "../../../../utils/CacheContext"

import { goalAtom } from '../../../atoms'
import { useAtom } from 'jotai'


export const usePerformanceData = () => {
  // Get data from the the IndexedDB cache or the API
  const [state, setState, Context] = useContext(CacheContext);
  const { startDate, endDate, platform } = useParamsWithDefaults();
  const [data, setData] = useState(undefined);
  const [current, setCurrent] = useState({});
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;

  const hasMissingParameters = (!platform || !startDate || !endDate)
  
  useEffect(() => {
    setCurrent({})
    setData(undefined)
    if (hasMissingParameters) return; // Don't attempt to run anything if we dont have everything
    if (!currencyCode) return; //Don't proceed until we have the currency code

    getViaCache(startDate, endDate, `platform_performance_${platform}`)
      .then(arrow => {
        if (arrow.size === 0) {
          setData(arrow)
          setCurrent({ platform, startDate, endDate })
        } else {
          getCurrencyRate(currencyCode, startDate, endDate)
            .then(response => {
              const rates = response['data']
              const rateTable = aq.table({'date': rates.map(d => d['date']), 'exchange_rate': rates.map(d => d['exchange_rate'])});
              const joined = arrow.join_left(rateTable, ['date'])
              const derived = joined.derive({ currencyConvertedSpend: d => d['spend'] / d['exchange_rate'] })
              const updated = derived.rename({ spend: 'unconvertedSpend', currencyConvertedSpend: 'spend' })
              setData(updated)
              setCurrent({ platform, startDate, endDate })
            })
        }
      })
  }, [platform, startDate, endDate, currencyCode])

  // if current != params, dont return meta and derived objects
  if (!isMetaDataCurrent(current, { startDate, endDate, platform})) {
    return {
      current,
      loading: !hasMissingParameters,
      platformPerformanceData: false
    }
  }

  return {
    current,
    loading: false,
    platformPerformanceData: data
  }
}

export const usePerformanceDataWithSelection = (selection) => {
  const [goal, setGoal] = useAtom(goalAtom)
  const { startDate, endDate, platform } = useParamsWithDefaults();
  const {
    groupBy, attributionWindow, platformConversionEvent,
    revenue_formatter, tierFilterMap
  } = selection

  const data = usePerformanceData();

  const revenueColumn = revenue_formatter ? revenue_formatter(attributionWindow) : false

  const rollup = {
    "impressions": `(d) => op.sum(d.impressions || 0)`,
    "clicks": `(d) => op.sum(d.clicks || 0)`,
    "spend": `(d) => op.sum(d.spend || 0)`,
    "conversions": `(d) => op.sum(d['${attributionWindow}']['${platformConversionEvent}'] || 0)`,
    "cpa": `(d) => op.sum(d.spend || 0)/op.sum(d['${attributionWindow}']['${platformConversionEvent}'] || 0)`,
  }

  const simpleRollup = {
    "impressions": `(d) => op.sum(d.impressions || 0)`,
    "clicks": `(d) => op.sum(d.clicks || 0)`,
    "spend": `(d) => op.sum(d.spend || 0)`,
    "conversions": `(d) => op.sum(d.conversions)`,
    "cpa": `(d) => op.sum(d.spend || 0)/op.sum(d.conversions)`,
    "ctr": `(d) => op.sum(d.clicks || 0)/op.sum(d.impressions)`,
    "cpc": `(d) => op.sum(d.spend || 0)/op.sum(d.clicks)`,
    "cpm": `(d) => op.sum(d.spend || 0)/op.sum(d.impressions)*1000`,
  }

  const hasResponse = data.platformPerformanceData
  const hasData = hasResponse && data.platformPerformanceData.size
  const hasSelection = attributionWindow && platformConversionEvent
  const isCurrent = isMetaDataCurrent(data.current || {}, { startDate, endDate, platform})
  const shouldAgg = isCurrent && hasSelection && hasData

  const hasRevenue = data.platformPerformanceData &&
    data.platformPerformanceData
      .columnNames()
      .includes(revenueColumn)

  if (hasRevenue) {
    if(platform === "facebook"){
      rollup["revenue"] = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']/d['exchange_rate']) || 0)`
      rollup["roas"] = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']/d['exchange_rate']) || 0)/op.sum(d.spend || 0)`
      simpleRollup["revenue"] = `(d) => op.sum(d.revenue)`
      simpleRollup["roas"] = `(d) => op.sum(d.revenue || 0)/op.sum(d.spend || 0)`
    } else {
      rollup["revenue"] = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']) || 0)`
      rollup["roas"] = `(d) => op.sum((d['${revenueColumn}']['${platformConversionEvent}']) || 0)/op.sum(d.spend || 0)`
      simpleRollup["revenue"] = `(d) => op.sum(d.revenue)`
      simpleRollup["roas"] = `(d) => op.sum(d.revenue || 0)/op.sum(d.spend || 0)`
    }
  }

  const groupByDimensions = {
    "date": ["date"],
    "groupBy": groupBy,
    "tiers": TIERS
  }

  const hasTiers = data.platformPerformanceData &&
    data.platformPerformanceData
      .columnNames()
      .includes(TIERS[0])

  if (!hasTiers) groupByDimensions['tiers'] = []
  
  const platformPerformanceData = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time("arrow-agg-raw")

      const agg = data.platformPerformanceData
        .groupby([...groupByDimensions["groupBy"], ...groupByDimensions["date"], ...groupByDimensions["tiers"]])
        .rollup(rollup)
      console.timeEnd("arrow-agg-raw")
      return agg
    },
    [groupBy, attributionWindow, platformConversionEvent, shouldAgg]
  )

  const filteredPlatformPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time("arrow-filter")
      const toFilter = platformPerformanceData
        .params(tierFilterMap)

      const filtered = Object.entries(tierFilterMap)
        .reduce((data, [key, values]) => {
          if (values.length == 0) return data
          return data
            .filter(`(d, $) => op.includes($['${key}'], d['${key}'])`)
        }, toFilter)


      console.timeEnd("arrow-filter")
      return filtered
    },
    [platformPerformanceData, tierFilterMap]
  )

  const filteredPlatformPerformanceTiers = React.useMemo(
    () => {
      if (!shouldAgg || !hasTiers) return false;
      console.time("arrow-agg-tiers")

      const agg = filteredPlatformPerformance
        .groupby([...TIERS, "date"])
        .rollup(simpleRollup)

      console.timeEnd("arrow-agg-tiers")
      return agg
    },
    [platformPerformanceData, tierFilterMap]
  )

  const dailyPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time("arrow-agg-daily")
      const agg = filteredPlatformPerformance
        .groupby(["date"])
        .rollup(simpleRollup)

      console.timeEnd("arrow-agg-daily")
      return agg
    },
    [filteredPlatformPerformance]
  )

  const totalPerformance = React.useMemo(
    () => {
      if (!shouldAgg) return false;
      console.time("arrow-agg-total")

      const agg = filteredPlatformPerformance
        .groupby([...groupBy])
        .rollup(simpleRollup)

      console.timeEnd("arrow-agg-total")
      return agg
    },
    [filteredPlatformPerformance]
  )


  if (!shouldAgg && hasResponse) return { 
    platformPerformanceData: [],
    dailyPerformance: [],
    totalPerformance: []
  }
  if (!shouldAgg) return { platformPerformanceData: undefined, }

  const { loading } = data;
  return { 
    loading,
    _platformPerformanceData: platformPerformanceData,
    platformPerformanceData: platformPerformanceData.objects(),
    filteredPlatformPerformanceTiers,
    filteredPlatformPerformance: filteredPlatformPerformance,
    filteredPlatformPerformanceObjects: filteredPlatformPerformance.objects(),
    dailyPerformance: dailyPerformance.objects(),
    totalPerformance: totalPerformance.objects()
  }

}
