import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { ContentCard, IndexGridTree, StackedBarChart, LineChart } from '@rockerbox/styleguide';
import { Loader } from 'semantic-ui-react';
import { getAccountCurrency, getAccount } from '../../api/account';
import { getSegments, getTierColors, getArtifacts } from '../../api/attribution';
import { getFirstVisitsTiers } from '../../api/attributionCache';
import { useURLStateAndSetDefault } from '../../hooks/urlState';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { MainHeader } from './stateless';
import { filterFirstVisits, rollupDonutChartDataColors, rollupCPAChartData, rollupEngagementChartData, rollupChartData } from './helpers';
import ProgressCard from './ProgressCard';
import { CacheContext } from '../../../utils/CacheContext';
import { colorHash } from '../../../components/ViewAttribution/AttributionPercent'; 
import Filter from './Filter';
import { track, time } from '../../../utils/tracking'
import { DonutChartLoader, BarChartLoader, TreeTableLoader } from '../../components/loaders'
import { DonutChart, PopupCard, Drawer, CustomizeColumnsButton } from '../../components';
import { spendFormatter } from '../../utils/valueFormatter';
import { yesterday, monthAgo } from '../../utils/time';
import { useTreeColumns } from './hooks/treeColumns';

import { useAtom } from 'jotai';
import { tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom, reportTypeAtom } from '../../atoms';

const orderDict = {
  visitors: { key: 'num_new_visitors', direction: 'descending' },
  cpa: { key: 'cpa', direction: 'ascending'},
  engagement: { key: 'num_pageviews_mean', direction: 'descending'}
}

const NewVisitors = () => {
  const [state, setState, Context] = useContext(CacheContext);
  const account = (Context.getCache(`account`, getAccount) || {})
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;
  const segments = Context.getCache(`segments`, getSegments) || [];
  const tierColors = Context.getCache(`tierColors`, getTierColors) || undefined;
  const { new_visitors_tooltips, new_visitors_help_docs, intercom_product_tours } = Context.getCache(`artifacts`, getArtifacts) || {};
  const tooltips = !!new_visitors_tooltips ? JSON.parse(new_visitors_tooltips) : {};
  const helpDocs = !!new_visitors_help_docs ? JSON.parse(new_visitors_help_docs) : {}
  const helpDocLink = helpDocs?.new_visitors_help_docs_link
  const intercomTours = !!intercom_product_tours ? JSON.parse(intercom_product_tours) : {}
  const intercomTourId = intercomTours?.new_visitors

  const [firstVisitsRaw, setFirstVisitsRaw] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [donutChartData, setDonutChartData] = useState({});
  const [CPAChartData, setCPAChartData] = useState({});
  const [engagementChartData, setEngagementChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [tierColorMap, setTierColorMap] = useState({});
  const [totalNumVisitors, setTotalNumVisitors] = useState(undefined);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false)

  // router params
  const [startDate, setStartDate] = useURLStateAndSetDefault('startDate', monthAgo, true);
  const [endDate, setEndDate] = useURLStateAndSetDefault('endDate', yesterday, true);

  //filters
  const [reportType, setReportType] = useAtom(reportTypeAtom)
  const [tier_1, set_tier_1] = useAtom(tier1Atom)
  const [tier_2, set_tier_2] = useAtom(tier2Atom)
  const [tier_3, set_tier_3] = useAtom(tier3Atom)
  const [tier_4, set_tier_4] = useAtom(tier4Atom)
  const [tier_5, set_tier_5] = useAtom(tier5Atom)
  
  useEffect(() => {
    if (reportType === '') {
      setReportType('visitors')
    }
  }, [reportType])

  // columns
  const { allColumns, selectedColumns, setSelectedColumns } = useTreeColumns(tierColorMap, currencyCode, tooltips)

  // pull new data
  useEffect(() => {
    const { filter_id } = segments.length > 0 ? segments.find(x => x.action_name == "All Pages") : {};
    if (filter_id && startDate && endDate && tierColors !== undefined) {
      setLoading(true);
      getFirstVisitsTiers(filter_id, startDate, endDate)
        .then(data => {
          const visits = data.first_visits_tiers.map((visit) => {
            visit.num_new_visitors_percent_col = visit.num_new_visitors
            return visit
          }) || [];
          const colors = d3.nest()
            .key(g => g.tier_1)
            .entries(visits)
            .reduce((p,c) => {
              p[c['key']] = tierColors[c['key']] || colorHash(c['key']);
              return p;
            },{});

          const totalVisitors = visits.length > 0 ? visits.reduce((p,c) => p + c['num_new_visitors'], 0) : 0
          setTotalNumVisitors(totalVisitors);
          setFirstVisitsRaw(visits);
          setTierColorMap(colors);
        })
    }
  }, [startDate, endDate, segments, tierColors])

  useEffect(() => {
    const visits = filterFirstVisits(firstVisitsRaw, tier_1, tier_2, tier_3, tier_4, tier_5);
    setFilteredVisits(visits);
  }, [tier_1, tier_2, tier_3, tier_4, tier_5, firstVisitsRaw]);


  useEffect(() => {
    if (filteredVisits.length == 0) return

    setDonutChartData(rollupDonutChartDataColors(filteredVisits, tierColorMap));
    setChartData(rollupChartData(filteredVisits, startDate, endDate));
    setCPAChartData(rollupCPAChartData(filteredVisits));
    setEngagementChartData(rollupEngagementChartData(filteredVisits, startDate, endDate));
    setLoading(false);
  }, [filteredVisits])

  // analytics track on reportType change
  useEffect(() => {
    if (!reportType || !startDate || !endDate) return
    if (!!loading) {
      time(`funnel.new_visitors.view`)
      return
    }
    track(`funnel.new_visitors.view`, {
      view: reportType,
      start_date: startDate,
      end_date: endDate,
    })
  }, [reportType, startDate, endDate, loading])

  const chartHeight = 350;

  const RightLoader = () => (
    reportType == 'visitors'
      ? <BarChartLoader />
      : <Loader active />
  )

  const rightContent = (
    <ContentCard borderless={true} hasTable>
      <div style={{minHeight: chartHeight}}>
      {loading ? <RightLoader /> :
        reportType == 'visitors' ?
          <StackedBarChart
            height={chartHeight}
            data={chartData}
            tierColorMap={tierColorMap}
            formatter={(v) => v.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            showTooltip
            hideKeys={['Unmapped Events']}
            showTotalInToolTip
            showXAxis
          />  
          :
          <LineChart
            data={reportType == 'cpa' ? CPAChartData : engagementChartData}
            height={chartHeight}
            tierColorMap={tierColorMap}
            formatter={reportType == 'cpa' ? spendFormatter(currencyCode) : (v) => v.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            showTooltip
            hideKeys={['Unmapped Events']}
            showXAxis
          />
      }
      </div>
    </ContentCard>
  );

  const leftContent = <div style={{minHeight: chartHeight}}>
    {loading ? <DonutChartLoader /> :
      <DonutChart 
        height={chartHeight}
        data={donutChartData}
        colors={tierColorMap}
        showTooltip
        formatter={(v) => v.toLocaleString("en-US")}
        showTotal
        hideKeys={['Unmapped Events']}
        labelForTotal='Total New Visitors'
      />
    }
  </div>;
  
  const onDateChange = ({ startDate, endDate }) => {
    const formatStartDate = moment(startDate).format("YYYY-MM-DD");
    const formatEndDate = moment(endDate).format("YYYY-MM-DD");

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  }

  return (
    <React.Fragment>
      <MainHeader />
      <Filter {...{
          loading, firstVisitsRaw, // data
          startDate, endDate, onDateChange, // dates
          reportType, setReportType // report type
        }}
      />
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <div style={{minWidth: 408, marginRight: 12}}>
          {reportType == 'visitors'
            ? <ContentCard hasTable title="Overall Breakdown" borderless={true}>{leftContent}</ContentCard>
            : <ProgressCard {...{data: filteredVisits, reportType, spendFormatter: spendFormatter(currencyCode), loading}} />
          }
        </div>
        <div style={{flexGrow: 1, position: 'relative', width: '100%', height: '100%'}}>
          <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
            {rightContent}
          </div>
        </div>
      </div>
      <ContentCard hasTable>
        {loading
          ? <TreeTableLoader showSearch />
          : <IndexGridTree
              cols={selectedColumns}
              allCols={allColumns}
              data={filteredVisits}
              orderBy={orderDict[reportType]?.key}
              orderDirection={orderDict[reportType]?.direction}
              summaryRow
              showSearch
              searchPlaceholder='Search Channels'
              rightContent={
                <div>
                  <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                </div>
              }
              hideKeys={['Unmapped Events']}
            />
        }
      </ContentCard>
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setSelectedColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </React.Fragment>
  )
}

export default NewVisitors;
