import React, { useState, useMemo, useEffect } from 'react'
import { Form, Dropdown, Button, Loader, Message } from 'semantic-ui-react'
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'

import { useGlobalState } from '../../hooks/global'
import { useSavedReports } from '../../views/SavedReports/_hooks/savedReports'
import { track } from '../../../utils/tracking'
import { useAtom } from 'jotai'
import { saveIDAtom } from '../../atoms'

import { sharedOptions } from '../../constants/options'
import { FormSummaryTable } from '../index'
import { useMappings } from './hooks/useMappings'
import { existingFilterMatchFound, formattedHash, formattedParams } from './helpers'

const WarningMessageFiltersExists = () => (
  <Message negative>
    <p>A Saved Report of the Current Filter Set already exists. Are you sure you want to save duplicate report?</p>
  </Message>
)

const SaveReportForm = ({ open, onClose, routeName, params, options = {} }) => {
  const { account } = useGlobalState()
  const { savedReports, postSavedReport, handleGetSavedReports } = useSavedReports()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [appliedFiltersExists, setAppliedFiltersExists] = useState(false)
  const [saveID, setSaveID] = useAtom(saveIDAtom)
  const { appliedFilters, defaultTitle, defaultDescription } = useMappings(routeName, params, options)

  const postParams = {
    route: formattedParams(params),
    hash: formattedHash(window.location.hash)
  }

  const goalsParams = useMemo(() => {
    const isGoalReport = routeName === 'digitalAdvertising' && params.view === 'platform' && appliedFilters.map(x => x.key).includes('Goal Metric') &&!!appliedFilters.find((x) => x.key === 'Goal Metric')?.value

    if (!isGoalReport) return false

    const { startDate, endDate, platformName } = params
    const mappedAppliedFilters = appliedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value
      return acc
    }, {})

    const goalsTrackingMetaData = {
      pixel_source_name: account.pixel_source_name,
      start_date: startDate,
      end_date: endDate,
      platform: platformName,
      goal_metric: mappedAppliedFilters['Goal Target'],
      goal_type: mappedAppliedFilters['Goal Metric'],
      goal_budget: mappedAppliedFilters['Goal Budget'],
      budget_compared_to_spend: mappedAppliedFilters['Budget Compared to Spend'],
      platform_conversion: mappedAppliedFilters['Platform Conversion'],
      platform_attribution: mappedAppliedFilters['Platform Attribution'],
      metrics: mappedAppliedFilters['Metrics'],
      tier_1: mappedAppliedFilters['Tier Filters']?.tier_1,
      tier_2: mappedAppliedFilters['Tier Filters']?.tier_2,
      tier_3: mappedAppliedFilters['Tier Filters']?.tier_3,
      tier_4: mappedAppliedFilters['Tier Filters']?.tier_4,
      tier_5: mappedAppliedFilters['Tier Filters']?.tier_5,
    }

    return goalsTrackingMetaData
  }, [postParams])

  useEffect(() => {
    const filteredSavedReportsParams = savedReports
      .filter(view => view.route === routeName)
      .map(x => x.params)
    const matchFound = existingFilterMatchFound(postParams, filteredSavedReportsParams)
    setAppliedFiltersExists(matchFound)
  }, [params, appliedFilters, savedReports])

  // form values
  const formDefaultValues = {
    name: defaultTitle,
    description: defaultDescription,
    shared: 0,
  }
  const { control, handleSubmit, formState: {errors}, setValue, trigger, reset } = useForm({ defaultValues: formDefaultValues })

  useEffect(() => {
    if (open) {
      reset(formDefaultValues)
      handleGetSavedReports()
    }
  }, [open])
  
  const onSave = ({ name, description, shared }) => {
    setIsSubmitting(true)
    const data = {
      name,
      description,
      route: routeName,
      params: postParams,
      shared,
    }

    postSavedReport(data)
      .then((x) => {
        setSaveID(x.id)
        toast.success('Saved Report')
        handleGetSavedReports()
      })
      .catch(err => {
        console.log('Save Report error:', err)
        toast.error('Error saving report')
      })
      .finally(() => {
        setIsSubmitting(false)
        onClose()
        track('saved_report.create', data)
        if (goalsParams) {
          track(`channel.digitalAdvertising.goals.create`, goalsParams)
        }
      })
  }

  return (
    <>
      {appliedFiltersExists && <WarningMessageFiltersExists />}
      <Form onSubmit={handleSubmit(onSave)}>
        <Loader active={isSubmitting} />
        <h2>Saved Report Summary</h2>
        <p>Saved reports include all customizations within the current view, including search terms, goal metrics, advanced filters and table columns.</p>
        <FormSummaryTable
          summary={appliedFilters}
        />
        <Form.Field disabled={isSubmitting}>
          <label>Saved Report title</label>
          <Controller
            fluid
            name='name'
            control={control}
            render={({ field }) => <Form.Input {...field} placeholder="Title" fluid error={errors.name?.message}/>}
            rules={{
              required: "Missing Title",
              validate: {
                titleExists: (v) => {
                  return !savedReports.map(({ name }) => name).includes(v) || 'A Saved Report with the same title currently exists, please use a different title.'
                } 
              }
            }}
          />
        </Form.Field>
        <Form.Field disabled={isSubmitting}>
          <label>Description</label>
          <Controller
            fluid
            name='description'
            control={control}
            render={({ field }) => <Form.Input {...field} placeholder="Description" fluid error={errors.description?.message}/>}
            rules={{required: "Missing Description"}}
          />
        </Form.Field>
        <Form.Field disabled={isSubmitting}>
          <label>Who can see this report?</label>
          <Controller
            name="shared"
            control={control}
            render={({ field }) => <Dropdown 
              {...field}
              selection
              options={sharedOptions} 
              onChange={(e, { name, value }) => {
                setValue(name, value)
                trigger(name)
              }}
              placeholder="Select"
              fluid
              error={errors.shared?.message}
            />}
            rules={{ required: "Missing sharing privileges" }}
          />
          {errors.shared?.message && <div className="ui pointing above prompt label">{errors.shared.message}</div>}
        </Form.Field>
        <p>After saving this report, you can access it anywhere within Rockerbox via Saved Reports in the main navigation.</p>
        <Button type="submit" primary style={{marginRight: 10}} disabled={isSubmitting}>Save report</Button>
        <Button onClick={() => onClose()} color="purple" inverted type="button" disabled={isSubmitting}>Cancel</Button>
      </Form>
    </>

  )
}

export default SaveReportForm
