export const buildGroupsFromRaw = (raw, hierarchyArr) => {
  
  const groups = hierarchyArr.map(c => c.value)
  return groups.reduce((p,c,i,a) => {
    p[c] = d3.nest()
      .key(x => a.slice(0,i+1).map(k => x[k]).join("|||") )
      .rollup(v => v.length)
      .entries(raw)
      .map(x => {
        const value = x.key.split("|||").reverse()[0]
        return {
          key: x.key,
          text: value,
          value: value,
          count: x.values,
          parentTiers: a.slice(0,i),
          parentValues: x.key.split("|||").slice(0,-1)
        }
      }).sort((p,c) => c.count - p.count)
      .filter(c => c.key)
    return p
  },{})
}

export const resetTiers = (val, tierFuncs, index) => {
  if(val == "") {
    const neededFuncs = tierFuncs.slice(index, tierFuncs.length);
    neededFuncs.map(c => {
      return c([]);
    })
  }
}

export const buildHierarchy = (obj) => {
  const hierarchy = obj.reduce((p,c,i) => {
    p[c.value] = `tier_${i+1}`
    return p
  }, {})
  return hierarchy
}
