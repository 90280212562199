import React from 'react'
import { Card } from 'semantic-ui-react'

const wrapStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
}

const iconStyles = {
  position: 'absolute',
  top: 95,
  left: 0,
  right: 0,
  margin: 'auto',
  height: 40,
  width: 40,
  padding: 10,
  borderRadius: '100%',
  background: 'rgba(0, 0, 0, 0.2)',
  opacity: 0.5,
}

const textStyles = {
  position: 'absolute',
  top: 147,
  margin: 0,
  width: '100%',
  color: 'rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 300,
  whiteSpace: 'pre',
}

const EmptyChartCard = ({ icon, text }) => (
  <Card fluid>
    <svg
      viewBox="0 0 397 200"
      preserveAspectRatio="none" 
      fill="#e5e5e5"
      height={200}
      width={'100%'}
    >
      <path d="M0,113.1c0,0,49.81,16.26,117.95-13.69c46.07-20.25,101.11-20.17,101.11-20.17s62.01,1.14,91.51-27.22
        C339.24,24.44,397,34.34,397,34.34v165.98L0,201.25V113.1z" />
    </svg>
    <div style={wrapStyles}>
      <div style={iconStyles}>
        {icon}
      </div>
      <h3 style={textStyles}>
        {text}
      </h3>
    </div>
  </Card>
)

export default EmptyChartCard
