import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  IndexGrid,
  ContentCard,
  PagedIndexGrid,
  SortablePagedIndexGrid
} from "@rockerbox/styleguide";
import ScheduleNewReportModal from "./ScheduleNewReportModal";
import { NewReportDropdownGroup } from "./DropdownMenu";
import EditModal from "./EditModal";
import Container from "../parts/Container";
import { deleteScheduledReport, toggleActiveScheduledReport } from "./api";
import { Table, Button, Checkbox } from "semantic-ui-react";
import moment from "moment";
import "moment-timezone";
import { currentTimeZone } from "./timezoneConversion";

class ScheduledReportIndex extends Component {
  componentDidMount() {
    this.toggleActiveReport = this.toggleActiveReport.bind(this);
    this.onLoadingChange = this.onLoadingChange.bind(this);
  }

  deleteReport = report => {
    this.onLoadingChange(true);
    deleteScheduledReport(report)
      .then(d => this.props.setScheduledReports())
      .then(() => this.onLoadingChange(false));
  };

  toggleActiveReport = id => {
    this.onLoadingChange(true);

    toggleActiveScheduledReport(id)
      .then(d => this.props.setScheduledReports())
      .then(() => this.onLoadingChange(false));
  };

  onOutboxIdChange = () => (evt, data) => {
    const id = data.value;
    if (!id) {
      return this.setState({ outboxId: id });
    }
    const { outboxes } = this.props;
    const target = outboxes.filter(outbox => outbox["id"] === id)[0];
    if (!target) {
      return this.setState({ outboxId: id });
    }
    const root_directory =
      (target["config"] && target["config"]["root_directory"]) ||
      "from-rockerbox";

    this.setState({
      outboxId: id,
      rootFolder: root_directory
    });
  };

  onFieldChange = fieldName => (evt, data) => {
    this.setState({ [fieldName]: data.value });
  };

  onEditReportFieldChange = fieldName => (evt, data) => {
    const value =
      fieldName === "send_on_pipeline_finish"
        ? data.checked
          ? 1
          : 0
        : data.value;
    this.setState({
      editReport: Object.assign({}, this.state.editReport, {
        [fieldName]: value
      })
    });
  };

  state = {
    showCreateModal: false,
    outbox_type: "",
    showEditModal: false,
    outboxId: undefined,
    savedReportId: undefined,
    rootFolder: "",
    contact: "",
    loading: false,
    error: false,
    editReport: {},
    showAdvanced: false,
    filterActive: true
  };

  onModalClose = () =>
    this.setState({
      showCreateModal: false,
      loading: false,
      error: false,
      outboxId: undefined,
      savedReportId: undefined,
      rootFolder: "",
      contact: ""
    });

  showModal = () =>
    this.setState({
      showCreateModal: true,
      loading: false,
      error: false,
      outboxId: undefined,
      savedReportId: undefined,
      rootFolder: "",
      contact: ""
    });

  onEditModalClose = () =>
    this.setState({
      editReport: {},
      loading: false,
      error: false,
      showAdvanced: false
    });
  showEditModal = id => {
    const { scheduledReports } = this.props;
    const editReport = scheduledReports.filter(report => report.id === id)[0];
    this.setState({
      editReport,
      loading: false,
      error: false,
      showAdvanced: false
    });
  };

  onDropdownItemClick(outbox_type) {
    return () => {
      this.setState({ outbox_type }, () => this.showModal());
    };
  }

  onShowAdvancedChange() {
    this.setState({ showAdvanced: !this.state.showAdvanced });
  }

  onLoadingChange(flag) {
    this.setState({ loading: flag });
  }

  onErrorChange(flag) {
    this.setState({ error: flag });
  }

  onToggleFilter = () =>
    this.setState({ filterActive: !this.state.filterActive });

  render() {
    const {
      scheduledReports,
      savedReports,
      outboxes,
      setScheduledReports,
      loading: dataLoading,
      isRBUser
    } = this.props;

    const {
      showCreateModal,
      outbox_type,
      showEditModal,
      editReport,
      outboxId,
      loading,
      error,
      showAdvanced,
      filterActive
    } = this.state;

    const outbox_types = outboxes.reduce((sum, current) => {
      if (sum.indexOf(current.outbox_type) === -1) {
        sum.push(current.outbox_type);
      }
      return sum;
    }, []);

    const ManageCell = ({ item, col }) => {
      const { id } = item;
      const stopPropagation = e => e.stopPropagation();

      return (
        <Table.Cell textAlign="center" collapsing>
          <IndexGrid.EditButton
            itemId={id}
            onClick={() => this.showEditModal(id)}
          />
          <IndexGrid.HistoryButton onClick={stopPropagation} itemId={id} />
          <IndexGrid.DocsButton
            itemId={item.saved_report_id}
            onClick={stopPropagation}
          />
          <IndexGrid.DeleteButton
            onDelete={e => {
              this.deleteReport(id);
            }}
          />
        </Table.Cell>
      );
    };

    const ActiveCell = ({ item, col }) => {
      const { id, active } = item;
      const stopPropagation = e => e.stopPropagation();

      return (
        <Table.Cell textAlign="center" collapsing>
          <IndexGrid.DeactivateButton
            onChange={e => {
              this.toggleActiveReport({ id, active: active == 1 ? 0 : 1 });
            }}
            active={active}
          />
        </Table.Cell>
      );
    };

    const reportCols = [
      { display: "Name", key: "name" },
      { display: "Report Type", key: "display_name", sortable: false },
      { display: "Outbox", key: "outbox_name", sortable: false },
      // { display: "Destination", key: "destination" },
      {
        display: "Conversion ID",
        key: "filter_id",
        displayValue: "action_name",
        as: IndexGrid.TextOnly,
        sortable: false
      },
      {
        display: "Conversion Name",
        key: "conversion_name",
        as: IndexGrid.TextOnly,
        sortable: false
      },
      {
        display: "Date Offset",
        key: "date_offset",
        as: IndexGrid.TextOnly,
        sortable: false
      },
      {
        display: `Schedule (${currentTimeZone.zoneAbbr()})`,
        key: "days",
        as: IndexGrid.Schedule,
        sortable: false
      },
      {
        display: `Last Delivery (${currentTimeZone.zoneAbbr()})`,
        key: "finished_at",
        as: IndexGrid.LocalFromUTCTime,
        sortable: false
      },
      {
        display: "Delivered?",
        key: "finish_type",
        as: IndexGrid.CelledCheckmark,
        sortable: false
      },
      { display: "Contact Email", key: "contact", sortable: false },
      {
        display: "Active",
        key: "finished_at",
        as: ActiveCell,
        headerStyles: { textAlign: "center" },
        sortable: false
      },
      {
        display: "Manage",
        key: "manage_actions",
        as: ManageCell,
        headerStyles: { textAlign: "center" },
        sortable: false
      }
    ];

    const filteredReports = filterActive
      ? scheduledReports.filter(({ active }) => !!active)
      : scheduledReports;

    return (
      <React.Fragment>
        <NewReportDropdownGroup
          outbox_types={outbox_types}
          onItemClick={this.onDropdownItemClick.bind(this)}
          loadingOutbox={this.props.loadingOutbox}
        />
        <Checkbox
          toggle
          onClick={this.onToggleFilter}
          label="Show Paused Reports"
          style={{ float: "right", top: 10 }}
        />
        <ContentCard hasTable loading={dataLoading}>
          <SortablePagedIndexGrid
            onRowClick={this.props.updateReportId}
            as={ContentCard.Table}
            fallBackMsg="No exports currently scheduled"
            cols={reportCols}
            data={filteredReports}
            UTCOffset={currentTimeZone._offset}
            itemsPerPage={10}
          />
          <ScheduleNewReportModal
            close={this.onModalClose}
            open={showCreateModal}
            error={error}
            onErrorChange={this.onErrorChange.bind(this)}
            onFieldChange={this.onFieldChange.bind(this)}
            onLoadingChange={this.onLoadingChange.bind(this)}
            onOutboxIdChange={this.onOutboxIdChange.bind(this)}
            outboxes={outboxes.filter(
              outbox => outbox.outbox_type === outbox_type
            )}
            {...{ savedReports, setScheduledReports }}
            {...this.state}
          />
          <EditModal
            close={this.onEditModalClose}
            open={editReport.id ? true : false}
            error={error}
            onErrorChange={this.onErrorChange.bind(this)}
            onLoadingChange={this.onLoadingChange.bind(this)}
            onEditReportFieldChange={this.onEditReportFieldChange.bind(this)}
            onShowAdvancedChange={this.onShowAdvancedChange.bind(this)}
            loading={loading}
            title={"Edit Scheduled Report"}
            editReport={editReport}
            setScheduledReports={setScheduledReports}
            isRBUser={isRBUser}
            showAdvanced={showAdvanced}
          />
        </ContentCard>
      </React.Fragment>
    );
  }
}

export default ScheduledReportIndex;
