import React from 'react'
import { Image, Loader, Container, Button, Segment, Header, Popup, Icon } from 'semantic-ui-react'
import { ContentCard, pieChartIcon, noDataCat, ViewHeader } from '@rockerbox/styleguide'
import { openIntercomMessenger } from '../Navigation/helpSection'
import { Link } from 'react-router-dom'

export const Tooltip = ({ tooltip }) => {
  return <Popup inverted
    content={tooltip}
    trigger={<Icon name="question circle" size="small" style={{ color: "#CACBCD", marginLeft: '0.333rem', marginBottom: '0.333rem', fontSize: '1em', cursor: 'help' }} />}
    position='top center'
  />
}

export const EventTypeExplainer = ({ header, text, intercomTourId, helpDocLink }) => {
  const HeaderText = () => {
    return <Header.Content > 
      {header}
      <Header.Subheader>{text}</Header.Subheader>
    </Header.Content>    
  }
  return (
    < ViewHeader
      image={pieChartIcon}
      headerText={<HeaderText />}
      intercomTourId={intercomTourId}
      helpDocLink={helpDocLink}
      intercomMessage="Hi! I have a question about Marketing Sources Analytics."
    />
  )
};

export const LoadingSpinner = ({ loading }) => (
  <Segment style={{ padding: '10% 10%' }}>
    <Loader active={loading} size='medium' inline='centered' >Loading</Loader>
  </Segment>
)

export const NoData = ({ header, details }) => (
  <ContentCard secondary>
    <Container textAlign="center" style={{ padding: '10% 10%' }}>
      <Image src={noDataCat} size='tiny' centered />
      <h3 style={{ fontWeight: 450, marginTop: 5 }}>{header}</h3>
      <p style={{ color: '#8E8D91', fontSize: '1.15em' }}>{details}<br />
        If you believe this is an error or would like more information, please <Link as={Button} onClick={openIntercomMessenger}>contact us for help</Link>.</p>
    </Container>
  </ContentCard>
)