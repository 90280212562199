import React, { useState, useEffect } from 'react';
import { Segment, Loader, Grid, Header} from 'semantic-ui-react';

import StarterPricing from '../../../V3/components/StarterPricing';
import { getArtifacts } from '../../../V3/api/attribution';
import { getOnboardingSurvey } from '../../../V3/api/account';

const Payment = ({ completeStep, completedOnboardingSteps }) => {
  const [loading, setLoading] = useState(true)
  const [pricing, setPricing] = useState(null)
  const [monthlyAdSpend, setMonthlyAdSpend] = useState(0)
  const [onboardingResponse, setOnboardingResponse] = useState(null)

  useEffect(() => {
    getArtifacts()
    .then(artifacts => {
      const pricing = artifacts['starter_pricing_1'] + artifacts['starter_pricing_2'] + artifacts['starter_pricing_3'] + artifacts['starter_pricing_4']
      const pricingObj = JSON.parse(pricing)
      setPricing(pricingObj);
      getOnboardingSurvey()
      .then(onboardingResponse => {
        if (Object.keys(onboardingResponse).length > 1) {
          const survey_spend = onboardingResponse['annual_marketing_spend'] > 0 ? onboardingResponse['annual_marketing_spend'] / 12 : 1
          setMonthlyAdSpend(survey_spend)
          setOnboardingResponse(onboardingResponse)
        }
        setLoading(false)
      })
    })
  }, [])

  return (
    <div className='onboarding-payment-view'>
      <Grid
        columns={1}
      >
        <Grid.Row>
          <Grid.Column>
            <Segment
              basic
              textAlign='center'
            >
              <Header as='h1' className='main-header'>
                Enter Billing Details
                <Header.Subheader className='sub-header'>
                  Rockerbox Starter is billed monthly, based on your advertising spend.<br/>You can cancel anytime, there is no long-term contract or onboarding fees.
                </Header.Subheader>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
          {loading && <div style={{ marginTop: '23px' }}>
              <Loader active />
            </div>
          }
          {!loading &&
            <StarterPricing {...{completeStep, completedOnboardingSteps, pricing, monthlyAdSpend, setMonthlyAdSpend, onboardingResponse}}/>
          }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Payment;
