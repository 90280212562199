import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon, Header, Segment, Dropdown, Input, Button, Grid, Pagination, Popup } from 'semantic-ui-react'
import { CardLoader } from '../ContentCard/ContentCard'
import { columnsToOptions, pagerLength, isFalsy } from './helpers';
import { filterData, sortData, selectData } from './dataHelpers';
import { recordsFrom, recordsTo } from '../PagedIndexGrid/helpers'
import { ContentCardTableFooter, EmptyMessage } from './parts'

const Wrap = props => props.children

const defaultFormatter = (x) => x

export const DefaultCell = ({ item, col }) => {
  const formatter = col.formatter || defaultFormatter
  return(
    <Table.Cell width={item[col.cellWidth]} className={`data-${col?.key}`}>
      <span>{formatter(item[col.key])}</span>
    </Table.Cell>
  )
}

const writeCell = (item, col, otherProps) => {
  const CellComponent = col.as || DefaultCell

  return(
    <CellComponent {...{ col, item, ...otherProps }} />
  )
}

const ExpandedWrapper = (props) => {
  return (
    <Table.Row className="expandable-row">
      <Table.Cell colSpan={props.colSpan} style={{borderTop: 'none'}}>
        {props.children}
      </Table.Cell>
    </Table.Row>
  )
}

const GridRow = props => {
  const { item, cols, isTotal, positive, negative, selectedRow, onRowClick, idKey, expandable, expand, expandCell, expandedComponent, setExpand, warning } = props;
  const ExpandedSection = expandedComponent;
  const style = (isTotal || item.isTotal) ? {fontWeight: "bold"} : {}

  const rowHasClickHandler = !expandCell && expandable
  const handleRowClick = (e) => onRowClick(item)
  const handleExpand = () => setExpand(item[idKey])
  const handleContract = () => setExpand(false)

  const onClick = onRowClick ? handleRowClick :
    rowHasClickHandler ? handleExpand : undefined

  return (
    <React.Fragment>
      <Table.Row
        onClick={onClick}
        positive={positive || item.positive}
        negative={negative || item.negative}
        style={{backgroundColor: (selectedRow == item) ? "#f2f2f2": undefined, cursor: onRowClick ? 'pointer' : undefined}}
        warning={warning || item.warning}
        {...{style}}
      >
        { cols.map((col) => writeCell(item, col, {handleExpand, handleContract, ...props})) }
      </Table.Row>
      { expandable && expand && expand === item[idKey] && <ExpandedWrapper colSpan={Object.keys(cols).length}><ExpandedSection item={item}/></ExpandedWrapper>}
    </React.Fragment>

  )
}

const TableGrid = props => {
  const {
    sortedHeaders: headers,
    customHeader=false,
    paginate,
    selectedPageNumber,
    itemsPerPage,
    pageNumbers,
    setSelectedPageNumber,
  } = props;

  const WrappedPagination = (props) => {
    const onPageChange = (e, {activePage}) => props.setSelectedPageNumber(activePage);
    return <Pagination {...props} {...{onPageChange}} />
  }

  const TableHeader = (
    <Table.Header>
      {/* { props.headerRows } */}
      <Table.Row>
        { headers && headers.length > 0 ? headers : props.cols.map(col => (
          col.headerAs ?
            col.headerAs(col) :
            <Table.HeaderCell width={col.headerWidth} style={col.headerStyles}>{col.display}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  )

  return (
    <React.Fragment>
      { customHeader ? customHeader : TableHeader }
      <Table.Body>
        {props.data.map(item => (
          <GridRow {...props} item={item} />
        ))}
      </Table.Body>
      <Wrap>
        {props.footer || null}
        {paginate ?
          <ContentCardTableFooter>
            <div>
              Showing{' '}
              {recordsFrom(selectedPageNumber, itemsPerPage)}-{recordsTo(selectedPageNumber, itemsPerPage, props.itemsLength)}{' '}
              out of {new Intl.NumberFormat().format(props.itemsLength)} records
            </div>
            {itemsPerPage &&
              <WrappedPagination
                activePage={selectedPageNumber}
                setSelectedPageNumber={setSelectedPageNumber}
                totalPages={pageNumbers}
                firstItem={false}
                lastItem={false}
                ellipsisItem={pageNumbers > 7 ? undefined : null}
              />
            }
          </ContentCardTableFooter> : null}

      </Wrap>
    </React.Fragment>
  )
}

export const IndexGrid = (props) => {
  const { cols, data, fallBackMsg, emptyIcon, sticky, sortable, filterable, searchable, topRight, placeholder, title, defaultSortCol, footer, defaultSortOrder='descending', ...tableProps} = props;
  const [expand, setExpand] = useState(props.defaultExpand || '');

  /* sortable consts */
  const [column, setColumn] = useState(defaultSortCol || null);
  const [init, setInit] = useState(true);
  const [colDirection, setColDirection] = useState(defaultSortOrder);
  const [sortKey, setSortKey] = useState(props.sortKey);
  const [subKey, setSubKey] = useState(false);

  /* searchable consts */
  const filterKeys = cols.filter(row =>  ('isSearchable' in row) );
  const keys = filterKeys.map(({ key }) => key)

  const filterableColumns = cols.filter(row => !!row.isFilterable)
  const [ searchValue, setSearchValue ] = useState("")
  const [ filters, setFilters ] = useState([])
  const [ filterOptions, setFilterOptions ] = useState(false)

  /* paginate consts */
  const { itemsPerPage, paginate } = props;
  const [selectedRow, setSelectedRow] = useState(props.selectedRow);
  const [pageNumbers, setPageNumbers] = useState(pagerLength(data, itemsPerPage));
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  const filtered = useMemo(
    () => filterData(init ? [] : data, searchValue, filters),
    [init, data, searchValue, filters]
  )

  const sortedData = useMemo(() => {
    return (column) ?
      sortData(filtered, column, sortKey, subKey, colDirection) :
      filtered
  }, [filtered, column, sortKey, subKey, colDirection])

  const selectedData = useMemo( () => {
    return paginate ?
       selectData(sortedData, itemsPerPage, selectedPageNumber) :
       sortedData
  }, [sortedData, paginate, itemsPerPage, selectedPageNumber])

  /* Prepare pager */
  useEffect(() => setPageNumbers(pagerLength(filtered, itemsPerPage)) , [filtered]);
  useEffect(() => {
    if (selectedRow !== props.selectedRow && !isFalsy(props.selectedRow)) {
      const index = sortedData.findIndex(item => item == props.selectedRow);
      setSelectedPageNumber(Math.ceil(index / itemsPerPage) || 1)
    }

    if (selectedRow !== props.selectedRow && !isFalsy(selectedRow) && isFalsy(props.selectedRow)) {
      setPageNumbers(pagerLength(sortedData, itemsPerPage))
      setData(filtered)
      setSelectedPageNumber(1)
    }
  }, [props.selectedRow]);

  /* Prepare filter / search */
  React.useEffect(() => {
    !!data && data.map(row => row.searchField = keys.map(key => String(row[key])).join("_").toLowerCase())

    const filterOptions = filterableColumns.reduce((p,c) => {
      p[c.key] = Object.keys((data||[]).reduce((p,row) => {
          p[row[c.key]] = 1
          return p
        }, {}))
        .map(text => new Object({ text, value: text, key: text}))
      return p
    }, {})

    setFilterOptions(filterOptions)
    setInit(false) // initialization is necessary to get the searchField on the data
  }, [init, data, cols])

  const handleSort = (clickedColumn, col) => () => {
    setColumn(clickedColumn);
    setSortKey(col.sortKey || clickedColumn);
    setSubKey(col.subKey || false);

    const shouldReverseDirection = (column == clickedColumn) && (colDirection == "descending");
    setColDirection(shouldReverseDirection ?  "ascending" : "descending");
  }

  const Headers = cols.map(col => {
    const { headerWidth, display, key, headerStyles, textAlign } = col;
    const shouldSort = col.sortable !== false && props.sortable;
    const sorted = column === key ? colDirection : null;
    const onClick = handleSort(key, col);
    const headerProps = shouldSort ? {sorted, onClick } : {};
    const cellStyle = sticky ? {position:"sticky", top:0 } : undefined;
    const cursorStyles = shouldSort ? { cursor: 'pointer' } : { cursor: 'default' }

    return (
      <Table.HeaderCell style={{...cursorStyles, ...cellStyle, ...headerStyles}} width={headerWidth} {...headerProps} {...{ textAlign }}>
        {display}
        {col.tooltip && <Popup basic inverted content={col.tooltip} trigger={<Icon name='question circle' className='tooltip-icon' />} />}
      </Table.HeaderCell>
    )
  })

  const search = (evt, { value }) => setSearchValue(value)

  const addFilter = () => setFilters([...filters, {}])

  const removeFilter = (i) => () => {
    const subset = [...filters]
    subset.splice(i,1)
    setFilters(subset)
  }

  const updateFilter = (pos, field) => (evt, { value }) => {
    const filterCopy = _.cloneDeep(filters);
    filterCopy[pos][field] = value;
    setFilters(filterCopy)
  }

  const headerRows = (searchable || filterable || title) ?
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f2f4fd', padding: '1em', marginBottom: '1em'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {title && <h2 style={{margin: 0, paddingRight: '1em', fontWeight: 300}}>{title}</h2>}
        { searchable &&
          <div className="table-filter-search">
            <Input
              value={searchValue}
              iconPosition='left'
              icon={{
                name: 'search',
                color: 'purple',
              }}
              onChange={search} 
              style={{width: 396}} 
              placeholder={placeholder || ''}
            />
          </div>
        }
        { filterable && <React.Fragment>
            { filters.map((filter,i) => {
                return <Segment secondary style={{fontSize:".8em"}}>
                  <span style={{fontSize:"1.25em"}} >{ i == 0 ? "Where" : "and where"}&nbsp;</span>
                  <Dropdown size='mini' selection options={columnsToOptions(filterableColumns)} onChange={updateFilter(i, "field")} />
                  <span style={{fontSize:"1.25em"}} >&nbsp; contains &nbsp;</span>
                  <Dropdown size='mini' selection multi options={filterOptions[filter['field']]} onChange={updateFilter(i, "value")} />
                  <Button icon="remove" size="mini" color="red" onClick={removeFilter(i)} />
                </Segment>
              })
            }
            <Button size='mini' icon='filter' onClick={addFilter} />
          </React.Fragment>
        }
      </div>
      {topRight && topRight}
    </div>
     : props.headerRows;

  const SummaryRow = ({cols, data}) => {
    const item = cols.reduce((p, col) => {
      if (col.reducer) p[col.key] = col.reducer(data);
      return p
    }, {})
    
    return (
      <Table.Footer>
        <Table.Row style={{fontWeight: 'bold', backgroundColor: '#f3f4f5', marginTop: '2px'}}>
          {cols.map((col, i) => {
            if (i == 0) return <Table.Cell>Summary</Table.Cell>
            if (!col.reducer) return <Table.Cell>-</Table.Cell>
            const Component = col.as || DefaultCell;          
            return <Component {...{col, item, data: [item], showPercent: false, label: col.summaryLabel}} />
          })}
        </Table.Row>
      </Table.Footer>
    )
  }

  return(
    <div>
      {headerRows}
      <Table {...tableProps} sortable={props.sortable} className="index-grid-new">
        { !data && <CardLoader /> }
        { !init && data && data.length > 0 && <TableGrid
            {...props}
            {...{setExpand, headerRows, selectedPageNumber, itemsPerPage, pageNumbers, setSelectedPageNumber, expand}}
            sortedHeaders={Headers} cols={cols}
            data={selectedData} itemsLength={sortedData.length}
          />
        }
        { data && data.length == 0 && <EmptyMessage {...{ emptyIcon, fallBackMsg }} />}
        { footer && !!props.summaryCols && <SummaryRow cols={props.summaryCols} data={data}/>}
      </Table>
    </div>

  )
}


IndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    tooltip: PropTypes.string,
    as: PropTypes.element
   })).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  emptyIcon: PropTypes.string,
  UTCOffset: PropTypes.number,
  onRowClick: PropTypes.func
}

export default IndexGrid
