import React, { useEffect, useMemo, useState } from 'react'
import { Header } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, Legend, Area, Line, Tooltip, YAxis, XAxis } from 'recharts'
import tinycolor from 'tinycolor2'
import _ from 'lodash'

import { AreaChartLoader } from '@rockerbox/styleguide'
import { Tooltip as InfoTooltip } from '../../../components'
import { formatCurrency } from '../../../utils/valueFormatter'
import { axisLabelStyles, CustomAxisTick, TooltipRow, tooltipStyles, PLATFORM_COLORS } from './chartHelpers'


const CustomTooltip = ({ active, payload, features }) => {
  if (!active || !payload?.length) return null

  const date = payload?.[0]?.payload?.date
  const sum = payload.reduce((acc, p) => acc + p.value, 0)
  const revenue = payload.find(p => p.dataKey === 'revenue')?.value || 0

  return (
    <div style={tooltipStyles}>
      <TooltipRow label={date} />
      {payload.filter(p => p.dataKey !== 'revenue').map((p, i) => (
        <TooltipRow
          key={i}
          color={p.fill}
          label={features.find(x => x.value === p.name)?.name || p.name}
          value={formatCurrency(p.value, 'USD', 0)}
        />
      ))}
      <TooltipRow
        final
        label='Total Daily Spend'
        value={formatCurrency(sum, 'USD', 0)}
        style={{ marginBottom: 10 }}
      />
      <TooltipRow
        color='#dc47cb'
        label='Daily Revenue'
        value={formatCurrency(revenue, 'USD', 0)}
        style={{ marginBottom: 0 }}
      />
    </div>
  )
}

const TrainingDataChart = ({ trainingData, features, predVsActual }) => {

  const [visibility, setVisibility] = useState({})

  useEffect(() => {
    const visible = features.reduce((acc, f) => {
      return { ...acc, [f.value]: true }
    }, {})
    setVisibility(visible)
  }, [features])



  const updateVisibility = (evt) => {
    const { dataKey } = evt;

    const isVisible = !visibility[dataKey]

    setVisibility({...visibility, [dataKey]: isVisible})
  }

  const data = useMemo(() => {
    if (!trainingData) return
    return _.zipWith(trainingData, predVsActual, (a, b) => ({ ...a, revenue: b?.actual || 0 }))
  }, [trainingData])

  // generate varying colors for each feature
  const featureColors = useMemo(() => {
    return features.reduce((acc, f, i) => {
      let platformColor = (PLATFORM_COLORS?.[f.platform] || '#7C78DC').toLowerCase()
      while (Object.values(acc).includes(platformColor)) {
        platformColor = tinycolor(platformColor).lighten(5).toHexString()
      }
      return { ...acc, [f.value]: platformColor }
    }, {})
  }, [features])

  return (<>
    <Header
      as='h4'
      content={<>
        Training Data
        <InfoTooltip
          position='right center'
          content='Review the advertising spend and revenue data that was used to train your model.'
          style={{ fontSize: 14, marginLeft: 5, marginBottom: 5 }}
        />
      </>}
    />
    {!data?.length ? <AreaChartLoader style={{ marginBottom: 50 }} /> :
      <ResponsiveContainer width='100%' height={350}>
        <ComposedChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 50 }}>
          <Legend
            verticalAlign="top"
            align="left"
            layout="vertical"
            wrapperStyle={{ paddingRight: "10px" }}
            onClick={updateVisibility}
          />
          <XAxis
            dataKey='date'
            tickLine={false}
            minTickGap={180}
            tick={CustomAxisTick('x', 'date')}
            stroke='#e6e6e6'
          />
          <YAxis
            yAxisId='spend'
            type='number'
            tickLine={false}
            minTickGap={70}
            tick={CustomAxisTick('y', 'currency')}
            label={{ value: 'Spend', angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
            stroke='#e6e6e6'
            orientation='left'
          />
          <YAxis
            hide
            yAxisId='revenue'
            type='number'
          />

          <Tooltip content={<CustomTooltip {...{ features }} />} />

          {features.map((feature, i) => (
            <Area
              key={i}
              dataKey={feature.value}
              yAxisId='spend'
              type='monotone'
              dot={false}
              fill={featureColors?.[feature.value]}
              stroke={featureColors?.[feature.value]}
              strokeWidth={1}
              strokeOpacity={0.6}
              isAnimationActive={false}
              stackId='features'
              hide={!visibility[feature.value]}
            />
          ))}
          <Line
            dataKey='revenue'
            yAxisId='revenue'
            stroke='#dc47cb'
            type='monotone'
            dot={false}
            strokeWidth={2}
            strokeOpacity={0.5}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    }
  </>)
}

export default TrainingDataChart
