import React, { Component } from 'react';
import moment from 'moment';
import { Checkbox, Label, Table, Popup, Menu, Icon, Container } from 'semantic-ui-react';
import { ContentCard, IndexGrid, ColumnLayout } from '@rockerbox/styleguide';
import { getPatternUids, getUIDAction, getPixelDataTimeseries, getConversionSegments, hasAction, getEventData, getParsedEventData } from '../../utils/api';
import TimeRange, { yesterday, week } from '../timerange';
import DailyChart from '../DailyChart';
import { OnsitePixelEditor } from './OnsitePixelEditor';


const rowKey = 'name';
const idList = ['140bd598c683df1d887e57b8acfc57ef55f'];

const Sparkline = ({item, col}) => {
  return(
    <Table.Cell>
      <DailyChart data={item.values} width={100}/>
    </Table.Cell>
  )
}

const Count = ({item, col}) => {
  const nf = new Intl.NumberFormat();
  return (
    <Table.Cell>
      {nf.format(item.total)}
    </Table.Cell>
  )
}

class PixelDataSource extends TimeRange {
  state = {
    startDate: week,
    endDate: yesterday,
    featuredFilterId: false,
    customerData: [],
    loading: true,
    detailObj: {},
    selectedRow: '',
    idList: [],
    parsedJson: '',
    unparsedJson: '',
    activeTab: 0
  }

  componentDidMount() {
    getConversionSegments()
      .then(data => {
        const featuredFilterId = data
          .filter(segment => segment.featured == 1)
          .map(seg => {
            return { id: seg.action_id }
          })[0]['id']

        this.setState({ featuredFilterId })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, featuredFilterId } = this.state;
    if ((startDate != prevState.startDate) || (prevState.featuredFilterId != featuredFilterId)) {
      this.initializeData(featuredFilterId);
    }
  }

  initializeData = (featuredFilterId) => {
    const { startDate, endDate } = this.state;

    hasAction(startDate, endDate)
      .then(customerData => {
        const firstItem = customerData[0];
        const namesArray = Object.keys(firstItem).filter(x => x !== 'date');

        const reducedData = customerData.reduce((p,c) => {
              namesArray.map(name => {
                p[name] = p[name] || {}; // initialize an empty object for the name
                p[name].values = p[name].values || [];
                p[name].values.push(c[name]);
                p[name].name = name;
                p[name].total = (p[name].total||0) + c[name]; // increment the counter by the new information
                // p[name].sparkline = (p[name].sparkline || []).push(c[name]);
              })
              return p // return the object and do it again
            }, {})

        const reformattedCustomerData = Object.values(reducedData)
        const defaultDetailObj = reformattedCustomerData[0];

        // getIdList(name)
        // .then(data => this.setState({idList: data.response}))

        // faking call to populate JSON
        this.getJsonForTabs(idList[0])

        this.setState({ customerData: reformattedCustomerData, loading: false, detailObj: defaultDetailObj, selectedRow: defaultDetailObj[rowKey], idList, col2Active: idList[0] })
    })
  }

  handleCol1Click = (item) => {
    this.setState({selectedRowName: item[rowKey], selectedRow: item});

    // getIdList(item[rowKey])
    //   .then(data => {
    //     const { response } = data;
    //     const firstItem = response[0];
    //     this.setState({idList: response, col2Active: firstItem})
    //     getJsonForTabs(firstItem);
    //   })

    getPatternUids(item[rowKey])
      .then(data => {
        const { response } = data;
        const firstItem = response[0];
        this.setState({idList: response, col2Active: firstItem})
        this.getJsonForTabs(firstItem, item[rowKey]);
      })

    //this.getJsonForTabs(idList[0]);

  }

  handleCol2Click = (action, id) => {
    this.setState({ col2Active: id});
    this.getJsonForTabs(id, action);
  }

  getJsonForTabs = (id, action) => {

    if (!action) {
      getEventData(id)
        .then(data => {
          this.setState({unparsedJson: JSON.stringify(data, null, 2)})
        })

      getParsedEventData(id)
        .then(data => {
          this.setState({parsedJson: JSON.stringify(data, null, 2)})
        })
    } else {
      getUIDAction(id, action)
        .then(data => {
          this.setState({unparsedJson: JSON.stringify(data['response'], null, 2)})
        })
    }
  }

  toggleActive = (active) => {
    this.setState({activeTab: active})
  }

  render() {
    const { customerData, startDate, endDate, loading, idList, parsedJson, unparsedJson, col2Active, activeTab } = this.state;
    const { selectedRow, selectedRowName } = this.state;

    if (customerData.length == 0) return null;

    const cols = [
      {display: 'Name', key: 'name'},
      {display: 'Count', key: 'total', as: Count},
      {display: 'Sparklines', key: 'total', as: Sparkline}
    ]

    return (
      <React.Fragment>
        <ContentCard
          title="Pixel Data"
          topRight={this.renderDateSelector()}
          disableHeaderEllipse
          noContent
        />
        <ColumnLayout
          leftWidth={5}
          centerWidth={4}
          rightWidth={7}
          leftContent={
            <ContentCard.Table>
              { loading && <ContentCard.CardLoader /> }
              {
                !loading && <IndexGrid
                  data={customerData}
                  cols={cols}
                  fallbackMsg="No customer data"
                  style={{border: 'none'}}
                  onRowClick={this.handleCol1Click}
                  rowKey={rowKey}
                  selectedRow={selectedRow}
                />
              }
            </ContentCard.Table>
          }
          rightContent={
            <ContentCard>
              <OnsitePixelEditor
                unparsedJson={unparsedJson}
                parsedJson={parsedJson}
                activeTab={activeTab}
                toggleActive={this.toggleActive}
              />
            </ContentCard>
          }
        >
          <Menu vertical fluid>
            { selectedRowName &&
              <Menu.Item>
                <b>Events for: {selectedRowName}</b>
              </Menu.Item>
            }
            {
              idList.map(id => {
                return (
                  <Menu.Item active={id == col2Active} onClick={() => this.handleCol2Click(selectedRowName, id)}>
                    { id }
                  </Menu.Item>
                )
              })
            }
          </Menu>
        </ColumnLayout>
        { this.renderDateModal() }
      </React.Fragment>
    )
  }
}

export default PixelDataSource;
