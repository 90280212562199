import React from 'react'
import { Button } from 'semantic-ui-react'
import { GatedView, sponsorshipsImage, sponsorshipsTrackingImage, sponsorshipsChannelsImage, sponsorshipsAnalysisImage } from '@rockerbox/styleguide'
import { CacheContext } from '../../utils/CacheContext'
import { getArtifacts } from '../../utils/api'


const HEADING = "Sponsorships Attribution"
const SUBHEADING = "Measure the true ROI of your marketing on podcasts, influencers, site sponsorships, and more!"
const EXPLAINER_STEPS = [
  {
    image: sponsorshipsTrackingImage,
    title: "Enter your sponsorships and payout structure",
    description: "Tell us the sponsorships you're running, how much you're spending on each, and how you'd like to track them. Are they paid a flat fee or a specific percentage/amount per conversion? Did they include in a vanity URL or promo code in their content?",
  },
  {
    image: sponsorshipsChannelsImage,
    title: "Rockerbox attributes customers to sponsorships",
    description: "Rockerbox determines if the customer engaged with a sponsorship and includes it in their path to conversion. Configure how you want each sponsorship categorized within your overall marketing activity. Group togethers sponsorships based on audience, offers, payout structure, or however you want.",
  },
  {
    image: sponsorshipsAnalysisImage,
    title: "View your holistic sponsorships performance",
    description: "View your true CPA and ROAS that combines sponsorships with your other online & offline marketing channels. Leverage Rockerbox to determine the overlap with other channels and see which sponsorships are efficiently bringing in new customers.",
  },
]


const EmptyView = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { sponsorships_help_docs } = Context.getCache(`artifacts`, getArtifacts) || {}
  const { sponsorships_help_docs_link } = !!sponsorships_help_docs ? JSON.parse(sponsorships_help_docs) : {}

  return (
    <GatedView
      title={HEADING}
      image={sponsorshipsImage}
      heading={<>
        Introducing <strong>{HEADING}</strong>
      </>}
      subheading={SUBHEADING}
      showNumberedFeatures
      features={EXPLAINER_STEPS}
      primaryButton={
        <Button
          content="Get Started"
          color="blue"
          onClick={() => window.open(sponsorships_help_docs_link, '_blank')}
          disabled={!sponsorships_help_docs_link}
        />
      }
    />
  )
}

export default EmptyView
