import React from 'react'

const SectionHeader = ({ content, children, style={} }) => (
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f2f4fd', padding: '1em', marginBottom: '1em', ...style}}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexGrow: 1}}>
        {!!content
          ? <h2 style={{margin: 0, paddingRight: '1em', fontWeight: 300}}>{content}</h2>
          : children
        }
    </div>
  </div>
)

export default SectionHeader
