import React, { useEffect } from 'react'
import _ from 'lodash'

import { track } from '../../../utils/tracking'
import { useModelData } from './hooks'
import ChannelOptimization from './ChannelOptimization'
import BudgetForecast from './BudgetForecast'
import ModelInfo from './ModelInfo'


const MMMView = () => {
  const {
    modelData,
    features,
    channels,
    responseCurves,
    featureStats,
    modelDate,
  } = useModelData()

  const { seasonality } = modelData || {}

  useEffect(() => {
    track('modeling.mmm.view')
  }, [])

  return (<>
    <ChannelOptimization {...{ channels, features, responseCurves, featureStats, modelDate }} />
    <BudgetForecast {...{ features, responseCurves, featureStats, seasonality }} />
    <ModelInfo {...{ modelData, features, modelDate }} />
  </>)
}

export default MMMView
