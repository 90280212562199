import React, { Component } from 'react';
import { Grid, Segment, Header, Icon } from 'semantic-ui-react';
import PieChart from 'react-minimal-pie-chart';

class FilterBox extends Component {
  state = {}

  toggleTiers = () => this.setState({ expanded: this.state.expanded === 'tiers' ? false : 'tiers' })

  toggleDates = () => this.setState({ expanded: this.state.expanded === 'dates' ? false : 'dates' })

  render(){
    const { expanded } = this.state
    const props = this.props

    return(
      <React.Fragment>
        <Grid fluid className='filter-wrap'>
          <Grid.Column width={props.tierWidth || 12}>
            <TiersFiltersTrigger
              label={props.filterLabel}
              selectedText={props.filterSelectedText}
              selectedPercent={props.filterSelectedPercent}
              selectedType={props.filterSelectedType}
              expanded={expanded === 'tiers'}
              onToggle={this.toggleTiers}
            />
          </Grid.Column>
          <Grid.Column width={props.datesWidth || 4}>
            <DatesFiltersTrigger
              label={props.datesLabel}
              selectedText={props.datesSelectedText}
              rangeStart={props.datesRangeStart}
              rangeEnd={props.datesRangeEnd}
              expanded={expanded === 'dates'}
              onToggle={this.toggleDates}
            />
          </Grid.Column>
        </Grid>
        {expanded &&
          <Segment className={`simple filter-box ${expanded}`}>
            {expanded === 'tiers' && props.filterContent}
            {expanded === 'dates' && props.datesContent}
          </Segment>
        }
      </React.Fragment>
    )
  }
}

export const TiersFiltersTrigger = ({ expanded, onToggle, selectedPercent, selectedText, selectedType, label }) => (
  <Segment
    onClick={!expanded && onToggle}
    className={`simple filter-box-trigger ${expanded ? 'expanded' : 'collapsed'}`}>
    <Header as='h4'>
      <SelectedChart selectedPercent={selectedPercent} />
      <Header.Content>
        {label || 'Filtering by'}: {selectedText}
        <Header.Subheader>
          {selectedPercent}% of {selectedType}
        </Header.Subheader>
      </Header.Content>
      <Icon
        name={expanded ? 'times circle' : 'pencil'}
        color='blue'
        onClick={expanded && onToggle}
      />
    </Header>
  </Segment>
)

export const DatesFiltersTrigger = ({ expanded, onToggle, selectedText, rangeStart, rangeEnd, label }) => {
  const dateFormatter = (date) => date.toLocaleDateString('us-US', { year: 'numeric', month: 'short', day: 'numeric' })

  return(
    <Segment
      onClick={!expanded && onToggle}
      className={`simple filter-box-trigger ${expanded ? 'expanded' : 'collapsed'}`}>
      <Header as='h4'>
        <Icon
          name={expanded ? 'times circle' : 'calendar alternate outline'}
          color='blue'
          onClick={expanded && onToggle}
        />
        <Header.Content>
          {label || 'Date Range'}: {selectedText}
          <Header.Subheader>
            {dateFormatter(rangeStart)} - {dateFormatter(rangeEnd)}
          </Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  )
}

const SelectedChart = props => (
  <PieChart
    rounded
    animate
    lineWidth={25}
    data={[
      { title: 'Unselected', value: 100-props.selectedPercent, color: '#BDD8E8' },
      { title: 'Selected', value: props.selectedPercent, color: '#026CAC' },
    ]}
    className='percent-selected-pie-chart'
  />
)

export default FilterBox
