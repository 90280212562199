import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'rockerbox_d3_legacy_clone';
import groupedChart from './grouped';

import css from '../charts.css';

class GroupedBarChart extends Component {
  state = {
    width: false
  }
  renderD3() {
    const { title } = this.props;
    const { width } = this.state;
    const data = this.props.data;
    const target = ReactDOM.findDOMNode(this.refs.grouped);
    const chart = groupedChart(width).title(title);

    d3
      .select(target)
      .selectAll('svg')
      .remove();

    d3
      .select(target)
      .datum(data)
      .call(chart);
  }

  getDimensions() {
    const target = ReactDOM.findDOMNode(this.refs.grouped);
    if (target) {
      const parent = target.parentNode
      const x = (this.state.width && (this.state.width === parent.offsetWidth)) ? false : this.setState({"width": parent.offsetWidth});
    }
  }
  componentDidMount() {
    this.getDimensions();
    this.renderD3();
  }
  componentDidUpdate() {
    this.renderD3();
  }

  render() {
    return (
      <div ref="grouped" />);
  }
}

export default GroupedBarChart;
