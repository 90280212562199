import { useContext, useMemo, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { loadIntercom } from './intercom'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { Integrations as SentryIntegrations } from '@sentry/tracing'
import SentryFullStory from '@sentry/fullstory'
import { loadProfitwell } from './profitwell'
import { loadHelpDocs } from './helpdocs'
import { loadVitally } from './vitally'
import { EXTERNAL_APP_KEYS as APPS } from './constants'
import { CacheContext } from '../CacheContext'
import { identify } from './identify'


const Tracking = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const { user, account } = state

  const env = useMemo(() => {
    const isProductionApp = window.location.hostname === 'app.rockerbox.com'
    const hasInternalCookie = document.cookie.includes('is_rockerboxer=true')
    const isLoggedIn = !!user
    const isRockerboxUser = user?.username?.includes('@rockerbox.com')
    const isSignupRoute = window.location.pathname.startsWith('/signup')

    if (!isProductionApp) return 'dev'
    if (hasInternalCookie) return 'internal'
    if (isLoggedIn && isRockerboxUser) return 'internal'
    if (isLoggedIn && !isRockerboxUser) return 'prod'
    if (isSignupRoute) return 'prod'
  }, [user, state])
  window.env = env

  const appKeys = useMemo(() => {
    if (env === 'dev') {
      return {
        mixpanel: APPS.mixpanel.stagingToken,
        intercom: APPS.intercom.testAppId,
        helpdocs: APPS.helpdocs.widgetKey,
      }
    }

    if (env === 'internal') {
      return {
        mixpanel: APPS.mixpanel.stagingToken,
        intercom: APPS.intercom.testAppId,
        fullstory: APPS.fullstory.internalOrgId,
        sentry: APPS.sentry.dsn,
        helpdocs: APPS.helpdocs.widgetKey,
      }
    }

    if (env === 'prod') {
      return {
        mixpanel: APPS.mixpanel.prodToken,
        intercom: APPS.intercom.prodAppId,
        fullstory: APPS.fullstory.prodOrgId,
        sentry: APPS.sentry.dsn,
        profitwell: APPS.profitwell.token,
        helpdocs: APPS.helpdocs.widgetKey,
        vitally: APPS.vitally.token,
      }
    }

    return {}
  }, [env])

  useEffect(() => {
    if (!!appKeys.mixpanel) {
      mixpanel.init(appKeys.mixpanel)
    }

    if (!!appKeys.intercom) {
      loadIntercom(appKeys.intercom)
    }

    if (!!appKeys.fullstory) {
      FullStory.init({
        orgId: appKeys.fullstory,
        recordOnlyThisIFrame: window !== window.top,
      })
    }

    if (!!appKeys.sentry) {
      Sentry.init({
        dsn: appKeys.sentry,
        integrations: [
          new SentryIntegrations.BrowserTracing(),
          new SentryFullStory(appKeys.fullstory),
        ],
        tracesSampleRate: 1,
      })
    }

    if (!!appKeys.profitwell) {
      loadProfitwell(appKeys.profitwell)
    }

    if (!!appKeys.vitally) {
      loadVitally(appKeys.vitally)
    }
  }, [appKeys])

  useEffect(() => {
    if (!user || !account || Object.keys(appKeys).length === 0) return
    identify(user, account, appKeys)

    // only load helpdocs for logged in, non-onboarding users
    const { tier, requires_setup } = account
    const inStarterOnboarding = tier === 'starter' && !!requires_setup
    if (!inStarterOnboarding && !!appKeys.helpdocs) {
      loadHelpDocs(appKeys.helpdocs) 
    }
  }, [user, account, appKeys])

  return null
}

export default Tracking
