import React, { Component } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { IndexGridNew, SplitLayout } from '@rockerbox/styleguide';
import EntityProgram from './EntityProgram';

export const NoPrograms = (props) => {

  const { setOpen } = props

  return <Table>
    <IndexGridNew.EmptyMessage 
      fallbackMsg={"Get started by creating a sponsorship program"} 
      callToAction={<Button color='blue' content="Create Program" onClick={() => setOpen(true)}/>}
    />
  </Table>
}

export const NoSelection = (props) => {

  return <Table>
    <IndexGridNew.EmptyMessage 
      fallbackMsg={"Select a program on the left to view your promo codes"} 
    />
  </Table>
}

export default NoPrograms;

