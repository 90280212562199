import React from 'react'
import moment from 'moment'
import { FlowMap } from '@rockerbox/styleguide'
import { CacheContext } from '../../utils/CacheContext'
import { getIntegrationFeatures, getSegments, getBatchConfigData, getTransformTables } from '../../utils/api'
import { ReportTypeCard, DataSourceCard } from './parts'
import { REPORTS, AGGREGATIONS, DATASETS, CONNECTIONS } from './reportMap'
import { track } from '../../utils/tracking'

const DEFAULT_INTEGRATIONS = { integrations: [] }
const DEFAULT_EVENT_SOURCES = [{ platform: "rockerbox", title: "On-Site Pixels", transformTable: null }]


const ReportMap = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || []
  const { integrations } = Context.getCache(`integrationFeatures`, getIntegrationFeatures) || DEFAULT_INTEGRATIONS
  const batchConfigs = Context.getCache(`batchConfigs`, getBatchConfigData) || []
  const transformTables = Context.getCache(`transformTables`, getTransformTables) || []

  const conversionSources = React.useMemo(() => (
    !!segments && segments
      .filter(({ touchpoints }) => !touchpoints)
      .map(({ action_id, action_name, url_pattern }) => {
        const isShopify = url_pattern.find(x => x.includes("shopify.api"))
        return {
          segment: action_id,
          title: action_name,
          platform: isShopify? "shopify" : "rockerbox",
        }
      })
      .sort((a, b) => a.display_order < b.display_order ? -1 : 1)
  ), [segments])

  const spendSources = React.useMemo(() => (
    integrations
      .filter(({ platform_type }) => platform_type == "advertising")
      .map(({ key, display_name }) =>
        Object({
          platform: key,
          title: display_name,
          spendReport: (batchConfigs.find(({ name, report_type_name }) => name.includes(key) && report_type_name == "platform_spend") || {}).report_id,
          transformTable: (transformTables.find(({ platform }) => platform == key) || {}).id,
        })
      )
  ), [integrations, batchConfigs, transformTables])

  const eventSources = React.useMemo(() => [...DEFAULT_EVENT_SOURCES, ...spendSources], [spendSources])

  // generate params for url macros
  const params = React.useMemo(() => Object({
    startDate: moment.utc().utcOffset(-5).subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD'),
    segment: (segments.find(({ featured }) => !!featured) || {}).action_id,
  }), [segments])

  React.useEffect(() => track('view.dashboard.report_map'), [])

  return (<>
    <FlowMap connections={CONNECTIONS} className='report-map'>
        <div className='row reports'>
          {REPORTS.map(({ id, icon, color, title, description, link }) =>
            <div className='column'>
              <FlowMap.Node {...{id}}>
                <ReportTypeCard {...{ icon, color, title, description, link, params }} />
              </FlowMap.Node>
            </div>
          )}
        </div>

        <div className='row aggregations'>
          {AGGREGATIONS.map(({ id, icon, color, title, description, link, className }) =>
            <div className='column'>
              <FlowMap.Node {...{id, className}}>
                <ReportTypeCard {...{ icon, color, title, description, link, params }} />
              </FlowMap.Node>
            </div>
          )}
        </div>

        <div className='row datasets'>
          {DATASETS.map(({ id, icon, color, title, description, link }) =>
            <div className='column'>
              <FlowMap.Node {...{id}}>
                <ReportTypeCard {...{ icon, color, title, description, link, params }} />
              </FlowMap.Node>
            </div>
          )}
        </div>

        <div className='row sources'>
          <div className='column'>
            <FlowMap.Node id='source-orders'>
              <DataSourceCard
                title="Customers & Orders"
                items={conversionSources}
                link='/v2/settings/conversions/view/${segment}'
              />
            </FlowMap.Node>
          </div>
          <div className='column'>
            <FlowMap.Node id='source-marketing'>
              <DataSourceCard
                title="Marketing Activity"
                items={eventSources}
                link='/v2/channels${!!transformTable ? `/transform/tables/view/${transformTable}` : `/overview/view`}'
              />
            </FlowMap.Node>
          </div>
          <div className='column'>
            <FlowMap.Node id='source-spend'>
              <DataSourceCard
                title="Advertising Spend"
                items={spendSources}
                link='/v2/settings/datasets/view/Spend%20Data/${spendReport}'
              />
            </FlowMap.Node>
          </div>
        </div>
    </FlowMap>
  </>)
}

export default ReportMap
