import React, { Component } from 'react';
import { StatCard, SplitLayout, PagedIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Checkbox, Segment, Grid, Dropdown, Message, Table, Search, Input, Statistic, Button, Form, Icon, Divider } from 'semantic-ui-react';

import { getConversionDataSummary, getSegments, getSurveyConfigs } from './../../utils/api';
import CacheContext from '../../utils/CacheContext.js'
import SelectConversion from '../SelectSegment';
import CreateEvents  from './CreateEvents';

import { SetupJoinMessage, JoinMessage, EventMessage } from './Messages';
import { FormDropdown } from './SurveyForm';
import { remapResponses } from './CurrentResponses';

const buildOptions = (data) => data ? data.map(({ key }) => { return { key, text: key, value: key } }) : []

const EventSetup = (props) => {

  const { handleChange, updateStateArrayRowField, addAdvanced } = props
  const { setupOnly, statsOnly } = props
  const { survey, surveyKey, conversion, conversionKey, events, surveyResponseKey, responses, defaultResponse} = props;
  const { id } = props

  const [showDetails, setShowDetails] = React.useState(false)
  const [keyJoin, setKeyJoin] = React.useState([])
  const [joinSummary, setJoinSummary] = React.useState({})
  const [state, setState, Context] = React.useContext(CacheContext);
  const surveyData = survey ? 
    (Context.getCache(`conversionDataSummary${survey}`, getConversionDataSummary, survey) || false) : false

  const conversionData = conversion ? 
    (Context.getCache(`conversionDataSummary${conversion}`, getConversionDataSummary, conversion) || false) : false

  const summaryOptions = buildOptions(surveyData.summary).filter(({ text }) => !text.includes("_followup"))
  const conversionOptions = buildOptions(conversionData.summary).filter(({ text }) => !text.includes("_followup"))
  const responseOptions = (!!responses && !!responses.length) ? buildOptions(responses.map(row => { return {key: row.response_display }})) : []
  const [segments, setSegments] = React.useState([])

  React.useEffect(() => {

    const promises = [getSegments(), getSurveyConfigs()]
    // remove segments already associated with a survey from conversion segment dropdown
    Promise.all(promises)
    .then(([segments, surveyConfigs]) => {
      const conversionIds = surveyConfigs.flatMap( (survey) => (survey.id == id) ? [] : [survey.conversion_id] )
      const filteredSegments = segments.filter(segment => segment.action_type == "conversion" && !conversionIds.includes(segment.action_id))
      setSegments(filteredSegments)
    })

  }, [id])

  React.useEffect(() => {
    if (surveyData.conversion_data && conversionData.conversion_data) {
      const right = surveyData.conversion_data.reduce((p,c) => {
        p[c[surveyKey]] = c
        return p
      }, {})

      const surveySet = new Set(Object.keys(right))
      const conversionSet = new Set()

      const keyJoin = conversionData.conversion_data.map(row => {
        const conversion_key = row[conversionKey]
        conversionSet.add(conversion_key)
        const survey_key = (right[conversion_key] || {})[surveyKey]
        return { conversion_key, survey_key }
      })

      const overlap = keyJoin.filter(({ conversion_key, survey_key}) => conversion_key == survey_key)

      const joinSummary = {
        surveys: surveySet.size,
        conversions: conversionSet.size,
        overlap: overlap.length
      }
      
      setKeyJoin(keyJoin)
      setJoinSummary(joinSummary)
    }
  }, [surveyData, conversionData, conversionKey, surveyKey])


  const mappedEvents = React.useMemo(() => {
    const { summary } = surveyData;
    const responseRules = responses;

    if (summary && Object.keys(summary).length > 0 && responseRules.length > 0) {
      const remapped = remapResponses(summary, surveyResponseKey, responseRules, defaultResponse)
        .reduce((p,c) => {
          p[c.newValue] = c.count
          return p
        }, {})

      return events.map(row => {
        const { response, tier_1, tier_2, tier_3, tier_4, tier_5 } = row;
        const count = remapped[response]
        return { tier_1, tier_2, tier_3, tier_4, tier_5, count }
      })
    }

    return []

  }, [surveyData.summary, responses])

  const mappedEventSummary = React.useMemo(() => {
    const addedEvents = mappedEvents.reduce((p,c) => p + (c.count || 0), 0)
    return { 
      totalResponses: joinSummary['overlap'],
      skippedResponses: joinSummary['overlap'] - addedEvents,
      addedEvents 
    }
  }, [joinSummary, mappedEvents])

  if (setupOnly) {
    return <>
      <SetupJoinMessage />
      <SelectConversion
        onChange={(id) => handleChange(id,"conversion")}
        as={FormDropdown}
        skipDefaultSelection={true}
        selectedSegmentId={conversion}
        label="Conversion Segment"
        segments={segments}
      />
      <SplitLayout
        leftWidth={8}
        rightWidth={8}
        leftContent={
          <FormDropdown loading={!conversionOptions.length} options={conversionOptions} label="Conversion Key:" value={conversionKey} onChange={(e,d) => handleChange(d.value, "conversionKey")}/>
        }
        rightContent={
          <FormDropdown loading={!summaryOptions.length} options={summaryOptions} label="Survey Key:" value={surveyKey} onChange={(e,d) => handleChange(d.value, "surveyKey")}/>
        }
      />

      { !!conversionOptions.length && !!summaryOptions.length && conversionKey && surveyKey && 
        <Statistic.Group size={"mini"} style={{"justify-content":"space-between", margin: 1}}>
          <StatCard style={{flex:1,margin:5}} value={joinSummary['conversions']} label="Conversions" />
          <StatCard style={{flex:1,margin:5}} value={joinSummary['surveys']} label="Responses" />
          <StatCard style={{flex:1,margin:5}} value={joinSummary['overlap']} label="Overlap" />
          <StatCard style={{flex:1,margin:5}} value={joinSummary['overlap']/joinSummary['conversions']} label="Response Rate" type="percent" />
        </Statistic.Group>
      }
    </>
  }


  return (
    <>
      <Grid columns="equal">
        <Grid.Column>
          <ContentCard title={"Associate Survey Responses to Conversion"}>
            <JoinMessage />
            <SelectConversion
              onChange={(id) => handleChange(id,"conversion")}
              as={FormDropdown}
              skipDefaultSelection={true}
              selectedSegmentId={conversion}
              label="Conversion"
            />
            <SplitLayout
              leftWidth={8}
              rightWidth={8}
              leftContent={
                <FormDropdown options={conversionOptions} label="Conversion Key:" value={conversionKey} onChange={(e,d) => handleChange(d.value, "conversionKey")}/>
              }
              rightContent={
                <FormDropdown options={summaryOptions} label="Survey Key:" value={surveyKey} onChange={(e,d) => handleChange(d.value, "surveyKey")}/>
              }
            />
          </ContentCard>
            
        </Grid.Column>
        <Grid.Column>
          <ContentCard title="Results of Conversion / Survey Association" noContent />
          <Statistic.Group size={"mini"} style={{"justify-content":"space-between", margin: 1}}>
            <StatCard style={{flex:1,margin:5}} value={joinSummary['conversions']} label="Conversions" />
            <StatCard style={{flex:1,margin:5}} value={joinSummary['surveys']} label="Surveys" />
            <StatCard style={{flex:1,margin:5}} value={joinSummary['overlap']} label="Overlap" />
            <StatCard style={{flex:1,margin:5}} value={joinSummary['overlap']/joinSummary['conversions']} label="Response Rate" type="percent" />
          </Statistic.Group>
          <br/>
          <ContentCard hasTable>
            <PagedIndexGrid itemsPerPage={10} cols={IndexGrid.arrayToColumns(["survey_key","conversion_key"])} data={keyJoin} />
          </ContentCard>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default EventSetup;
