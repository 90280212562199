import React from 'react'
import { Header } from 'semantic-ui-react'
import { ArrowCircleRightIcon } from '@heroicons/react/outline'

import { dataSpendImage } from '@rockerbox/styleguide'


const RoadmPortalPromo = () => {
  const onClick = () => window.open('https://roadmap.rockerbox.com', '_blank')

  return (
    <div className='roadmap-portal-promo' {...{ onClick }}>
      <div className='image-wrap'>
        <img src={dataSpendImage} />
      </div>
      <div className='text-wrap'>
        <Header as='h2'>
          <Header.Content>
            Don't see one of your advertising platforms here?
            <Header.Subheader>
              Submit a request to add an integration to our product roadmap!
            </Header.Subheader>
          </Header.Content>
        </Header>
      </div>
      <div className='icon-wrap'>
        <ArrowCircleRightIcon />
      </div>
    </div>
  )
}

export default RoadmPortalPromo
