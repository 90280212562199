import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Header, Menu, Icon } from 'semantic-ui-react'

import { CacheContext } from '../../../utils/CacheContext'
import * as routes from '../../baseRoutes'
import { SETTINGS_MENU } from './constants'
import { track } from '../../../utils/tracking'

const isLocked = (tier, lockedTiers) => {
  if (!lockedTiers) return false
  if (lockedTiers === 'all') return true
  return lockedTiers.includes(tier)
}

const Settings = ({ history, location }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { account } = state
  const { tier } = account || {}

  const { pathname } = location
  const navigate = (route) => history.push(route)

  // only display the billing menu for starter clients
  const filteredSettingsMenu = tier == "starter" ? SETTINGS_MENU : SETTINGS_MENU.filter(item => item.name !== "Billing")

  useEffect(() => {
    if (pathname === '/v3/settings') return
    const view = pathname.replace('/v3/settings/', '').replaceAll('/', '.')
    track(`settings.${view}.view`)
  },[pathname])

  return (
    <div className='layout-sidebar'>
      <div className='sidebar'>
        <Header
          as='h2'
          content='Settings'
        />
        <Menu secondary vertical>
          {filteredSettingsMenu.map(({ name, route, icon, lockedTiers }) => (
            <Menu.Item
              disabled={isLocked(tier, lockedTiers)}
              active={pathname === route}
              onClick={() => navigate(route)}
            >
              <div className='icon'>
                {icon}
              </div>
              {name}
              {!!isLocked(tier, lockedTiers) &&
                <Icon name='lock' style={{ marginLeft: 'auto', marginRight: 0 }} />
              }
            </Menu.Item> 
          ))}
        </Menu>
      </div>
      <div className='content'>
        <Switch>
          {filteredSettingsMenu.map(({ route, component }) =>
            <Route exact path={route} component={component} key={route} />
          )}
          <Route>
            <Redirect to={routes.myAccount} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default Settings
