import React from 'react';
import { Grid, Container, Form } from 'semantic-ui-react';

const FormWrap = props => (
  <Grid fluid="true" columns="equal">
    <Grid.Row>
      <Grid.Column>
        <Form>
          <Container fluid>{props.children}</Container>
        </Form>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default FormWrap;
