import React, { useState, useCallback } from "react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import { firstAvailableDate } from "./constants";

const DatePicker = props => {
  const { startDate, startDateCallback } = props;
  const [focus, setFocus] = useState(false);

  const outRange = useCallback(
    date => {
      const firstDate = moment(firstAvailableDate, "YYYY-MM-DD");
      const yesterday = moment().subtract(1, "days");

      return date.isAfter(yesterday) || date.isBefore(firstDate);
    },
    [firstAvailableDate]
  );
  return (
    <SingleDatePicker
      date={startDate}
      onDateChange={startDateCallback}
      focused={focus}
      onFocusChange={({ focused }) => setFocus(focused)}
      id="experiment_start_date"
      isOutsideRange={outRange}
      noBorder
      hideKeyboardShortcutsPanel
    />
  );
};

export default DatePicker;
