import React, { Component, useMemo } from 'react';
import { ContentCard, PagedIndexGrid } from '@rockerbox/styleguide';
import { Input, Button } from 'semantic-ui-react';
import * as Tree from '../TreeParts';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { filterTree } from '../AttributableOverview/tree';
import { getRawTransformTableData, getTransformTableData } from '../../utils/api';


const TransformTableRaw = ({ transformTable, edit }) => {

  const [rawData, setRawData] = React.useState(null);
  const [rawDataLoading, setRawDataLoading] = React.useState(true);

  React.useEffect(() => {
    setRawData(null);
    getRawTransformTableData(transformTable.acetl_report_id)
      .then(setRawData)
  }, [transformTable.acetl_report_id])

  React.useEffect(() => { setRawDataLoading(rawData == null) }, [rawData])

  const headers = React.useMemo(() => { 
    if (!rawData || rawData.length ==0 ) return []
    const cols = Object.keys(rawData[0]).map(key=> {
      return {display: key, key }
    })
    return cols
  }, [rawData])

  return (
    <React.Fragment>
      <div style={{display:"flex",alignContent:"stretch"}}>
        { rawData && <PagedIndexGrid itemsPerPage={50} data={rawData} cols={headers} /> }
        { rawDataLoading && <div style={{width:"100%",textAlign:"center"}}><ContentCard.CardLoader /></div> }
      </div>
    </React.Fragment>
  )
}

export default TransformTableRaw;
