import React, { useState } from "react";
import _ from "lodash";

export const useRefDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 800, height: 250 });

  React.useEffect(() => {
    if (ref.current) {
      const { current } = ref;

      const outputsize = _.debounce(() => {
        const w = current.clientWidth;

        if (Math.round(w) !== Math.round(dimensions["width"])) {
          // chart height will adjust with width
          setDimensions({ width: Math.round(w), height: dimensions["height"] });
        }
      }, 500);

      if (ref.current.observe) {
        return;
      } else {
        const observer = new ResizeObserver(outputsize).observe(current);
        ref.current.observe = observer;
      }
    }
  }, [ref]);
  return dimensions;
};
