import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { rootAction } from '../../rootAction';
import Fetch from '../parts/GenericFetch';
import { buildGroupsFromRaw } from './helpers';
import MainView from './component';
import ReportTypeData from '../ReportTypeData';
import SavedReportData from '../SavedReportData';
import OutboxData from '../OutboxData';
import { getConversionSegmentReportingData } from '../../utils/api';
import './index.css';
import { DeprecationBanner } from '../stateless';

export const SET_SUMMARY_DATA_BY_ID = 'SET_SUMMARY_DATA_BY_ID';
export const SET_ATTRIBUTION_ERROR = 'SET_ATTRIBUTION_ERROR';
export const SET_ATTRIBUTION_DATA = 'SET_ATTRIBUTION_DATA';
export const SET_ATTRIBUTION_DATA_GROUPS = 'SET_ATTRIBUTION_DATA_GROUPS';
export const SET_CHANNEL_GROUPS = 'SET_CHANNEL_GROUPS';
export const SET_HAS_SPEND = 'SET_HAS_SPEND'
export const ATTRIBUTION_ENDPOINT = '/cache/v3/attribution_events';

export const ATTRIBUTABLE_MODELS = [
  { key: 1, text: 'Modeled Multi-Touch', value: "normalized" },
  { key: 2, text: 'Even Weight', value: "even" },
  { key: 3, text: 'Last Touch', value: "last_touch" },
  { key: 4, text: 'First Touch', value: "first_touch" },
];

export const ATTRIBUTABLE_GROUPS = [
  { key: 1, text: 'Bucket 1', value: "tier_1" },
  { key: 2, text: 'Buckets 1 - 2', value: ["tier_1", "tier_2"] },
  { key: 3, text: 'Buckets 1 - 3', value: ["tier_1","tier_2","tier_3"] },
  { key: 4, text: 'Buckets 1 - 4', value: ["tier_1","tier_2","tier_3","tier_4"] },
  { key: 5, text: 'Buckets 1 - 5', value: ["tier_1","tier_2","tier_3","tier_4","tier_5"] }
];

const buildEndpoint = (dqs, qs, regression) => regression ?
  ATTRIBUTION_ENDPOINT.replace("cache","data") + dqs + qs + "&regression=true" :
  ATTRIBUTION_ENDPOINT + dqs + qs

function mapStateToProps (state, props) {

  const regression = props.location.search == "?regression=true"
  const { startDate, endDate } = props.match.params;
  const segments = state.segment.segments;
  const selected = segments.find(x => x.action_id == props.match.params.id) || {}
  const { use_model, is_child, is_parent } = selected;

  const models = ATTRIBUTABLE_MODELS.filter(x => x.value == "normalized" ? use_model : true);
  const groups = ATTRIBUTABLE_GROUPS;

  const dataQueryString = `?keys=compiled_mta_tiers&start_date=${startDate}&end_date=${endDate}`
  const queryString = `&identifier=${selected.filter_id}`
  const dataEndpoint = buildEndpoint(dataQueryString, queryString, regression)

  const raw = state.attribution.data
  if (regression) raw.map(row => row.date = row.date ? row.date : moment.utc(row.cache_from*1000).format("YYYY-MM-DD"))

  const reportTypes = state.reports.reportTypes
  const outboxes = state.reports.outboxes
  const savedReports = state.reports.savedReports

  const hasSpend = state.attribution.hasSpend
  const channelGroups = state.attribution.channelGroups

  return {
    dataGroups: state.attribution.dataGroups,
    segments,
    selected,
    dataEndpoint,
    raw,
    use_model,
    models,
    groups,
    reportTypes,
    savedReports,
    outboxes,
    hasSpend,
    channelGroups,
    is_child,
    is_parent,
    advertiser: state.app.user.pixel_source_name,
  }
}

function mapDispatchToProps(dispatch, props, ...other) {
  return {
    setError: (data) => {
      console.log(`error: ${data}`)
      dispatch(rootAction(SET_ATTRIBUTION_ERROR, data))
    },
    setAttributionDataAPI: (data) => {
      data.raw = data.response

      const hasSpend = data.raw.filter(x => x.spend > 0).length > 0;
      const groupedTiers = buildGroupsFromRaw(data.raw)
      dispatch(rootAction(SET_HAS_SPEND, hasSpend))
      dispatch(rootAction(SET_ATTRIBUTION_DATA_GROUPS, groupedTiers))
      dispatch(rootAction(SET_ATTRIBUTION_DATA, data.raw))
    },
    setChannelGroups: (data) => {
      const channelGroups = data.response.map((c) => {
        c.text = c.name;
        c.value = c.id;
        return c
      })
      dispatch(rootAction(SET_CHANNEL_GROUPS, channelGroups))
    }
  }
}

class Component extends React.Component {
  state = {
    loading: true
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.match.params.startDate != this.props.match.params.startDate) || 
      (prevProps.match.params.endDate != this.props.match.params.endDate) || 
      (this.props.selected && (prevProps.selected.filter_id != this.props.selected.filter_id))
    )
    {
      this.getData()
    }
  }

  onSuccess = (data) => {
    const { startDate, endDate } = this.props.match.params;
    const { setAttributionDataAPI } = this.props;

    data = {"response": data};

    data.startDate = startDate;
    data.endDate = endDate;
    if (startDate == this.props.match.params.startDate && endDate == this.props.match.params.endDate) {
      setAttributionDataAPI(data);
    } else {
      console.log(`date has changed ${this.props.match.params.startDate} ${startDate} ${this.props.match.params.endDate} ${endDate}`)
    }
    this.setState({loading: false});
  }

  onStartFetch = () => {
    this.setState({loading: true})
  }

  getData = () => {

    const { startDate, endDate } = this.props.match.params;
    const { selected = {} } = this.props;
    const { filter_id } = selected;

    if (!filter_id || !startDate || !endDate) return 

    this.onStartFetch()

    getConversionSegmentReportingData(filter_id, startDate, endDate)
      .then(resp => {
        console.log(new Date())
        const res = [].concat.apply([], resp)
        console.log(new Date())

        // HACK FOR STOCKX
        if (this.props.advertiser === 'stockx') {
          console.log('removing tier_5 data')
          const filtered = res.map(row => {
            const colsToInclude = Object.keys(row).filter(x => !x.includes('tier_5'))
            return colsToInclude.reduce((acc, key) => {
              acc[key] = row[key]
              return acc
            }, {})
          })
          this.onSuccess(filtered)
          return
        }

        this.onSuccess(res)
      })
    
  }

  render(){
    const { dataEndpoint, selected } = this.props;
    const { setAttributionDataAPI, setError, setChannelGroups } = this.props;
    const { startDate, endDate } = this.props.match.params;
    const { loading } = this.state;

    const dontFetch = !selected.id || !startDate || !endDate

    return (
      <React.Fragment>
        <DeprecationBanner viewName='Reports' newViewName='Attribution Report' newViewPath='/v3/attribution/attribution_report' />
        {/* <Fetch preventFetch={dontFetch} endpoint={dataEndpoint} error={setError} success={onSuccess} onStart={onStartFetch} /> */}
        <Fetch endpoint="/channel_group" success={setChannelGroups} error={() => setChannelGroups({response: []})} />
        <OutboxData />
        <ReportTypeData />
        <SavedReportData />
        <MainView {...this.props} loading={loading} />
      </React.Fragment>
    )
  }
}

const AttributionContentWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))

export default AttributionContentWrapper;
