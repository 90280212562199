import React from 'react'
import { TrendingDownIcon, TrendingUpIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import { StatisticCard } from '../parts'
import RadioOptions from '@rockerbox/styleguide/components/layouts/SelectCheckbox'

const formatTrendNumber = (trendNumber) => {
  let numberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 0
  }

  const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
  const formatted = numberFormatter.format(Math.abs(trendNumber));

  return formatted
}

const TrendSpan = styled.span`
  color: ${props => props.redOrGreen} !important;
  font-family: 'Poppins' !important;
`

const AltSpan = styled.span`
  font-weight: bold;
  color: black;
  font-family: 'Poppins' !important;
`

const Trending = (props) => {
  const { option, isActive, altBaseline } = props
  const redOrGreen = (option.trendReversed && option.trendNumber < 0) || (!option.trendReversed && option.trendNumber > 0) ? '#75BE42' : '#E76D8E'

  return (
    !isNaN(option.trendNumber) && <>
      {!altBaseline && ((formatTrendNumber(option.trendNumber) == '0%') ?
        <span style={{ marginTop: 2 }}>&nbsp;{`No change ${option.baseline}`}</span>
        : <>
          <TrendSpan {...{isActive, redOrGreen}}>
            {option.trendNumber > 0 ? <TrendingUpIcon style={{height: 20, marginRight: 2}}/> : <TrendingDownIcon style={{height: 20, marginRight: 2}}/>}
          </TrendSpan>
          <TrendSpan {...{isActive, redOrGreen}}>                        
            {formatTrendNumber(option.trendNumber)}
            &nbsp;{option.baseline}
          </TrendSpan>
        </>)
      }
      {altBaseline && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <AltSpan {...{isActive}}style={{fontWeight: 'bold'}}>{option.trendNumber > 0 ? <ChevronUpIcon style={{height: 20, marginRight: 2}}/> : <ChevronDownIcon style={{height: 20, marginRight: 2}}/>}</AltSpan>
          <AltSpan {...{isActive}}style={{fontWeight: 'bold'}}>{formatTrendNumber(option.trendNumber)}</AltSpan>
          <span style={{fontFamily: 'Poppins'}}>&nbsp;{option.baseline}</span>
        </div>
      )}
    </>
  )
}

const TrendingCard = (props) => {
  const { item, isActive, altBaseline } = props
  const subheading = <Trending {...{option: item, isActive, altBaseline}} /> // add custom subheading
  return <StatisticCard {...props} {...{subheading}}/>
}

export default TrendingCard
