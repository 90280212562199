import React, { useMemo } from 'react'
import { ResponsiveContainer, ComposedChart, ReferenceLine, Line, Customized, Cross, Tooltip, YAxis, XAxis, ReferenceDot } from 'recharts'
import _ from 'lodash'

import { formatCurrency } from '../../../utils/valueFormatter'
import { roundNumber } from '../helpers'


const axisLabelStyles = {
  fontSize: 12,
  textAnchor: 'middle',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fill: '#919191',
  textTransform: 'uppercase',
}

const CustomAxisTick = (axisType, format) => ({ x, y, payload }) => {
  const value = payload?.value
  if (!value) return null

  const formatted = format === 'currency' ? formatCurrency(value, 'USD', 0) : roundNumber(value, 2)
  const rotation = axisType === 'x' ? 0 : -90
  const dy = axisType === 'x' ? 8 : 0
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={dy} textAnchor='end' fill='#919191' transform={`rotate(${rotation})`} >
        {formatted}
      </text>
    </g>
  )
}

const CrossHairs = (chartType) => ({ xAxisMap, yAxisMap, activePayload, isTooltipActive, width, height }) => {
  const xAxisMapper = xAxisMap?.[0]?.scale
  const yAxisMapper = yAxisMap?.[0]?.scale
  
  const activePoint = activePayload?.[0]?.payload
  const yKey = chartType === 'revenue' ? 'marginal_response' : 'predicted_roas'
  const crossX = xAxisMapper?.(activePoint?.spend_input)
  const crossY = yAxisMapper?.(activePoint?.[yKey])

  if (!isTooltipActive || !crossY) return null

  return (
    <Cross
      x={crossX}
      y={crossY}
      top={-45}
      left={60}
      height={height}
      width={width}
      stroke='#919191'
      strokeOpacity={0.5}
    />
  )
}

const TooltipRow = ({ label, value, final }) => (
  <p style={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: !final && 5,
    paddingTop: !!final && 5,
    borderTop: !!final && '1px solid #e6e6e6',
    color: '#4d4d4d',
    fontSize: 12,
  }}>
    <span style={{ marginRight: 20, fontWeight: 600 }}>{label}</span>
    <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{value}</span>
  </p>
)

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload?.length) return null

  const revenue = payload?.[0]?.payload?.marginal_response
  const roas = payload?.[0]?.payload?.predicted_roas

  return (
    <div style={{
      padding: 10,
      borderRadius: 4,
      background: '#fff',
      color: '#000',
      boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
    }}>
      <TooltipRow
        label='Spend'
        value={formatCurrency(label, 'USD', 0)}
      />
      <TooltipRow
        label='Projected Revenue'
        value={formatCurrency(revenue, 'USD', 0)}
      />
      <TooltipRow
        final
        label='Projected ROAS'
        value={roundNumber(roas, 2)}
      />
    </div>
  )
}

const ChannelOptimizationChart = ({ chartType, responseCurve, currentSpend, currentRevenue, targetSpend, predictedRevenue }) => {
  if (!responseCurve?.length) return null

  const maxSpend = useMemo(() => {
    if (!responseCurve?.length) return 0
    return responseCurve.reduce((p,c) => !!c.marginal_response ? Math.max(p, c.spend_input) : p, 0)
  }, [responseCurve])

  const yAxisLabel = chartType === 'revenue' ? 'Projected Revenue' : 'Projected ROAS'
  const yAxisFormat = chartType === 'revenue' ? 'currency' : 'decimal'

  return (
    <ResponsiveContainer width='100%' height={350}>
      <ComposedChart data={responseCurve} margin={{ top: 5, right: 5, bottom: 15, left: 0 }}>
        <XAxis
          dataKey='spend_input'
          type='number'
          domain={[0, () => maxSpend]}
          tickLine={false}
          tick={CustomAxisTick('x', 'currency')}
          label={{ value: 'Spend (Daily)', position: 'bottom', offset: 0, style: axisLabelStyles }}
          stroke='#e6e6e6'
        />
        <YAxis
          type='number'
          tickLine={false}
          tick={CustomAxisTick('y', yAxisFormat)}
          label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
          stroke='#e6e6e6'
        />

        {chartType === 'roas' &&
          <ReferenceLine
            y={1}
            stroke='#e6e6e6'
            strokeDasharray="4 4"
          />
        }
        {!!currentSpend &&
          <ReferenceLine
            x={currentSpend}
            stroke='#1C8ED1'
            opacity={0.25}
          />
        }
        {!!targetSpend &&
          <ReferenceLine
            x={targetSpend}
            stroke='#FF9615'
            opacity={0.25}
          />
        }

        <Customized component={CrossHairs(chartType)} />
        <Tooltip content={<CustomTooltip />} cursor={false} />

        <Line
          dataKey={chartType === 'revenue' ? 'marginal_response' : 'predicted_roas'}
          stroke='#12d975'
          type='monotone'
          dot={false}
          strokeWidth={3}
          isAnimationActive={false}
        />

        {!!currentSpend &&
          <ReferenceDot
            x={currentSpend}
            y={chartType === 'revenue' ? currentRevenue : currentRevenue / currentSpend}
            r={4}
            fill='#fff'
            stroke='#1C8ED1'
            strokeWidth={2}
            isFront={true}
            label={{
              value: currentSpend != targetSpend ? 'Historical' : '',
              position: 'bottom',
              offset: 15,
              fill: '#1C8ED1',
              fontSize: 12,
              fontWeight: 500,
              fontFamily: 'Inter',
            }}
          />
        }
        {!!targetSpend &&
          <ReferenceDot
            x={targetSpend}
            y={chartType === 'revenue' ? predictedRevenue : predictedRevenue / targetSpend}
            r={4}
            fill='#fff'
            stroke='#FF9615'
            strokeWidth={2}
            isFront={true}
            label={{
              value: 'Target',
              position: 'bottom',
              offset: 15,
              fill: '#FF9615',
              fontSize: 12,
              fontWeight: 500,
              fontFamily: 'Inter',
            }}
          />
        }
      </ComposedChart> 
    </ResponsiveContainer>
  )
}

export default ChannelOptimizationChart
