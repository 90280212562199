import React from 'react'
import { Header } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, Line, Tooltip, YAxis, XAxis } from 'recharts'

import { AreaChartLoader } from '@rockerbox/styleguide'
import { Tooltip as InfoTooltip } from '../../../components'
import { formatCurrency } from '../../../utils/valueFormatter'
import { roundNumber } from '../helpers'
import { axisLabelStyles, CustomAxisTick, TooltipRow, tooltipStyles } from './chartHelpers'


const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload?.length) return null

  const data = payload?.[0]?.payload
  const { date, pred, actual, delta } = data

  return (
    <div style={tooltipStyles}>
      <TooltipRow label={date} />
      <TooltipRow
        color='#FF9615'
        label='Predicted Revenue'
        value={formatCurrency(pred, 'USD', 0)}
      />
      <TooltipRow
        color='#1C8ED1'
        label='Actual Revenue'
        value={formatCurrency(actual, 'USD', 0)}
      />
      <TooltipRow
        final
        label='Delta'
        value={formatCurrency(delta, 'USD', 0)}
      />
    </div>
  )
}

const PredVsActualChart = ({ predVsActual, metrics }) => {
  const { rsq_train, mape } = metrics || {}

  const rSquared = roundNumber(rsq_train * 100)
  const mapeRounded = roundNumber(mape * 100)

  return (<>
    <Header as='h4'>
      Predicted vs. Actual Revenue
      <span style={{ float: 'right', color: '#FF9615', fontFamily: 'Poppins' }}>
        {(rSquared < 100 || mapeRounded < 100) && 
        <InfoTooltip
          position='left center'
          content={<>
            {rSquared < 100 && <p>
              <strong>R<sup>2</sup></strong>&nbsp;
              is the coefficient of determination. It is a measure of how well the model fits the data. The higher the value, the better.
            </p>}
            {mapeRounded < 100 && <p>
              <strong>MAPE</strong>&nbsp;
              is the mean average percentage error. It is a measure of the model's prediction accuracy. The lower the value, the better.
            </p>}
          </>}
          style={{ fontSize: 14, marginRight: 10, marginBottom: 5 }}
        />
        }
        {rSquared < 100 && <>
          R<sup>2</sup> = {rSquared}%
        </>}
        {mapeRounded < 100 && <>
          &nbsp;&nbsp;&nbsp;
          MAPE = {mapeRounded}%
        </>}
      </span>
    </Header>
    {!predVsActual?.length ? <AreaChartLoader style={{ marginBottom: 50 }} /> :
      <ResponsiveContainer width='100%' height={250}>
        <ComposedChart data={predVsActual} margin={{ top: 5, right: 5, bottom: 35, left: 0 }}>
          <XAxis
            dataKey='date'
            tickLine={false}
            minTickGap={180}
            tick={CustomAxisTick('x', 'date')}
            stroke='#e6e6e6'
          />
          <YAxis
            type='number'
            tickLine={false}
            minTickGap={70}
            tick={CustomAxisTick('y', 'currency')}
            label={{ value: 'Revenue', angle: -90, position: 'insideLeft', offset: 20, style: axisLabelStyles }}
            stroke='#e6e6e6'
          />

          <Tooltip content={<CustomTooltip />} />

          <Line
            dataKey='actual'
            stroke='#1C8ED1'
            type='monotone'
            dot={false}
            strokeWidth={2}
            isAnimationActive={false}
          />
          <Line
            dataKey='pred'
            stroke='#FF9615'
            type='monotone'
            dot={false}
            strokeWidth={2}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    }
  </>)
}

export default PredVsActualChart
