import React, { useState, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { Button } from 'semantic-ui-react';

import { SelectMenu, SplitLayout, ContentCard } from '@rockerbox/styleguide';
import { useGlobalState } from '../../../hooks/global';
import { useURLState } from '../../../hooks/urlState';
import { getEntityConfigs } from '../../../api/attribution';

import { entityStore } from '../hooks/entityStore';
import { NoPrograms, NoSelection } from '../parts/NoPrograms';
import { StatusTable } from '../../../components';
import ViewProgram from './ViewProgram';
import ViewSponsors from './ViewSponsors';
import CreateEntityProgram from './CreateProgram';

const STEPS = ['Status'].map(text => ({ text, value: text }));

const getSegmentIdFromEntity = () => getEntityConfigs()
  .then(entityData => ((entityData.length > 0) ? entityData[0].segments[0].segment_id : false));

const Steps = () => {
  const { state } = useGlobalState();
  const features = state?.account?.features || [];
  const hasLegacySpend = features.includes('sponsorships_legacy_spend');
  const hasLegacyUTMs = features.includes('sponsorships_legacy_utms');

  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useURLState('activeStep', 'No Programs');

  const [entityConfigs, entityLoading, programOptions, getEntityConfigs, entitySegmentId] = entityStore((
    { entityConfigs, entityLoading, programOptions, getEntityConfigs, entitySegmentId },
  ) => (
    [entityConfigs, entityLoading, programOptions || [], getEntityConfigs, entitySegmentId]
  ), shallow);

  useEffect(() => {
    getEntityConfigs();
  }, []);

  useEffect(() => {
    if (!!activeStep) return;
    const _activeStep = programOptions && programOptions.length ? programOptions[0].value : 'No Programs';
    setActiveStep(_activeStep);
  }, [programOptions]);

  const { programs, entityData } = useMemo(() => (
    programOptions.find(row => Number(row.value) === Number(activeStep)) || {}
  ), [programOptions, activeStep]);

  const onClose = () => setOpen(false);

  const selectedView = entityData || programs ? (
    <SplitLayout
      leftWidth={4}
      rightWidth={12}
      leftContent={<ViewProgram {...{ programs, entityConfigs, programOptions, hasLegacySpend }} />}
      rightContent={(
        <ViewSponsors
          {...{ getEntityConfigs, entityConfigs, entityData: entityData || [], program: programs[0], hasLegacySpend, hasLegacyUTMs }}
          contentOnly
        />
  )}
    />
  ) : activeStep === 'Status' ? (
    <StatusTable
      getSegmentId={getSegmentIdFromEntity}
      datasets={[
        { dataset: 'entity_difference', display: 'Has Sponsorships Data?' },
      ]}
      funcs={[
        { text: 'Backfill', display: '', url: '/run/agg/events' },
      ]}
    />
  ) : entityConfigs ? (
    <NoSelection />
  ) : (
    <NoPrograms {...{ setOpen }} />
  );

  return entityLoading ? (
    <ContentCard.LoadingView />
  ) : (
    <SplitLayout
      leftWidth={2}
      rightWidth={14}
      leftContent={(
        <>
          <SelectMenu
            value={activeStep}
            title="Programs"
            options={programOptions}
            onChange={(_, { value }) => setActiveStep(value)}
            style={{ boxShadow: 'none' }}
          />
          <CreateEntityProgram {...{ open, onClose, programOptions }} />
          <Button fluid primary content="Create Program" onClick={() => setOpen(true)} />
          <SelectMenu
            value={activeStep}
            title="Diagnostics"
            options={STEPS}
            onChange={(_, { value }) => setActiveStep(value)}
            style={{ background: 'transparent', boxShadow: 'none', marginTop: 20 }}
          />
        </>
      )}
      rightContent={selectedView}
    />
  );
};

export default Steps;
