import moment from 'moment'
import { REPORT_TYPE_OPTIONS_NEW_VISITORS, REPORT_TYPE_OPTIONS_FUNNEL_POSITION } from '../../constants/options'

export const capitalizeText = (text) => {
  if (!text) return 'All'
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const compareObjects = (obj1, obj2, skipKey) => {
  const keys1 = Object.keys(obj1).filter(x => x !== skipKey);
  const keys2 = Object.keys(obj2).filter(x => x !== skipKey);
  
  if (keys1.length !== keys2.length) {
    return false;
  }
  
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
    
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export const existingFilterMatchFound = (postParams, paramsArray) => {
  for (let i = 0; i < paramsArray.length; i++) {
    const currentParams = paramsArray[i]
    if (compareObjects(currentParams.hash, postParams.hash, 'saveID') && compareObjects(currentParams.route, postParams.route, 'saveID')) {
      return true
    }
  }
  return false
}

export const findOptionText = (optionsList, value) => (optionsList?.find(c => c.value === value)?.text)

export const reportOptions = (routeName) => {
  if (routeName === 'newVisitors') return REPORT_TYPE_OPTIONS_NEW_VISITORS
  if (routeName === 'funnelPosition') return REPORT_TYPE_OPTIONS_FUNNEL_POSITION
  return []
}

export const convertToMacro = (x) => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
  const isDate = dateRegex.test(x)
  if (isDate) {
    const today = moment.utc().utcOffset(-5)
    const date = moment.utc(x, 'YYYY-MM-DD')
    const numDaysAgo = today.diff(date, 'days')
    return `{{ ${numDaysAgo}d }}`
  }
  return x
}

export const formattedHash = (hash) => {
  if (hash[0] !== '#') return {}
  const hashSplit = hash.substr(1).split('&')
  const saveHash = hashSplit.reduce((acc, v) => {
    const [key, val] = v.split('=')
    if (key === 'saveID') {
      return acc
    }
    return {...acc, [key]: val}
  }, {})
  return saveHash
}

export const formattedParams = (params) => {
  if (!params) return {}
  return Object.keys(params)
    .reduce((acc, key) => {
      const value = convertToMacro(params[key])
      acc[key] = value
      return acc
    }, {})
}