import * as d3 from 'rockerbox_d3_legacy_clone';
import { TIERS } from '../../constants/tiers'
import { isJsonString } from '../../utils/valueFormatter';

const initializeTiers = (data) => {
  const tiers = data.reduce((p,c) => {
    TIERS.map((t,i) => {
      if (c.path[0][i]) {
        p[t] = (p[t]||{})
        p[t][c.path[0][i]] = (p[t][c.path[0][i]]||[])
        const parentPath = i ? c.path[0][i-1] : []
        p[t][c.path[0][i]].push(parentPath)
      }
    })
    return p
  },{})

  const t = Object.keys(tiers).reduce((p,k) => {
    p[k] = Object.keys(tiers[k]).map(key => { return {key, value: key, text: key, parentValues: new Set(tiers[k][key])} })
    return p
  },{})

  return t
}

export const buildTiers = (selectedTiers, nestedObj, resetUrl) => {
  selectedTiers = createSelectedTiers(selectedTiers)
  const { originalTiers } = nestedObj

  const build = TIERS.reduce((p, k, i) => {
    if (originalTiers[k] == undefined) return p
    if (i == 0) {
      p[k] = originalTiers[k];
      return p
    }

    p[k] = originalTiers[k].filter(tier => selectedTiers[TIERS[i - 1]]
      .filter(x => tier.parentValues.has(x)).length)

    return p
  }, {})
  
  const tierValues = Object.keys(build).reduce((p, c) => {
    p[c] = build[c].map(v => v.value)
    return p
  }, {})

  const updateSelected = Object.keys(selectedTiers)
    .reduce((p, tier) => {
      const selected = selectedTiers[tier].filter(v => tierValues[tier].includes(v))
      p[tier] = selected
      return p
    }, {})
  
  return [build, updateSelectedTiersforURL(updateSelected, resetUrl)]
}

const filterTiers = (originalTiers, selectedTiers) => {
  return TIERS.reduce((p,k,i) => {
    if (originalTiers[k] == undefined) return p
    if (i == 0) {
      p[k] = originalTiers[k];
      return p
    }
    p[k] = originalTiers[k].filter(tier => {

      return (selectedTiers[TIERS[i-1]] || []).filter(x => tier.parentValues.has(x)).length
    })
    return p
  },{})
}

export const buildNestedData = (selectedTiers, data, keys) => {
  const { count, revenue, avg_seconds_til_conversion } = keys
  selectedTiers = createSelectedTiers(selectedTiers)

  const nestedData = d3.nest()
    .key(x => JSON.stringify(x.path))
    .rollup(values => {

      return {
          path: values[0].path,
          count: d3.sum(values, x => x[count]),
          revenue: d3.sum(values, x => x[revenue]),
          avg_revenue: d3.sum(values, x => x[revenue])/d3.sum(values, x => x[count]),
          avg_seconds: d3.sum(values, x => x[avg_seconds_til_conversion])/values.length,
          total_seconds: d3.sum(values, x => x[avg_seconds_til_conversion]*x[count]),
      }
    })
    .map(data);

  const originalCustomerPath = Object.values(nestedData);
  const originalTiers = initializeTiers(originalCustomerPath);

  const subsetSelectedTiers = Object.keys(originalTiers).reduce((p,c) => {
    p[c] = selectedTiers[c];
    return p
  }, {})

  const tiers = filterTiers(originalTiers, subsetSelectedTiers)
  return { tiers, originalCustomerPath, originalTiers, subsetSelectedTiers }
}

// might want to break out into separate functions, otherwise all filters run when either any, first, or last is changed
export const filterCustomerPath = (any, first, last, minPathLen, nestedObj) => {
  const { originalCustomerPath } = nestedObj || []
  any = createSelectedTiers(any)
  first = createSelectedTiers(first)
  last = createSelectedTiers(last)
  minPathLen = minPathLen ? minPathLen : 1

  const paths = originalCustomerPath
    .map(row => {
      row.total_revenue = row.avg_revenue * row.count
      return row
    })
    .filter(row => {

    const containsAnyTouch = row.path.filter(visit => {
      return TIERS.reduce((p,c,i) => {
          const tierList = any[c] || []
          const inTierList = tierList.length == 0 || tierList.indexOf(visit[i]) > -1
          return p && inTierList
        }, true)
    }).length > 0

    const containsFirstTouch = row.path.slice(0,1).filter(visit => {
      return TIERS.reduce((p,c,i) => {
          const tierList = first[c] || []
          const inTierList = tierList.length == 0 || tierList.indexOf(visit[i]) > -1
          return p && inTierList
        }, true)
    }).length > 0

    const containsLastTouch = row.path.slice(-1).filter(visit => {
      return TIERS.reduce((p,c,i) => {
          const tierList = last[c] || []
          const inTierList = tierList.length == 0 || tierList.indexOf(visit[i]) > -1
          return p && inTierList
        }, true)
    }).length > 0

    const isMinPathLen = row.path.length >= minPathLen

    return containsAnyTouch && containsFirstTouch && containsLastTouch && isMinPathLen && row.count > 0
  })

  return paths.sort((p,c) => {
    return c.count - p.count
  })
}

export const createSelectedTiers = (selected) => {
  const noneSelected = TIERS.reduce((p, c) => { p[c] = []; return p }, {})
  const initialFilter = ['any', 'first', 'last']
  if (!selected || initialFilter.includes(selected)) {
    return noneSelected
  }
  const parseSelectedTiers = isJsonString(selected) ? isJsonString(selected) : {}
  return Object.assign(noneSelected, parseSelectedTiers)
}

export const updateSelectedTiersforURL = (tiers, resetUrl) => {
  const updateSelected = Object.keys(tiers).reduce((p, c) => {    
    if (tiers[c].length > 0) p[c] = tiers[c]
    return p;
  }, {});
  return Object.keys(updateSelected).length > 0 ? JSON.stringify(updateSelected) : resetUrl
}

// Filters
export const summarizePaths = (paths) => {
/*  {
    avg_revenue: 86.6129411764706
    avg_seconds: 1787.5
    count: 204
    path: [Array(3)]
    revenue: 17669.04
    searchField: ""
    total_seconds: 357947
  } 
  
  avg touchpoints -- need delta?
  avg revenue

  */

  const conversions = paths.reduce((p,c) => p + c.count, 0)
  const totalPaths = paths.length
  const totalLengths = paths.reduce((p,c) => p + c.path.length*c.count, 0)
  const totalTimes = paths.reduce((p,c) => p + c.total_seconds, 0)
  const totalRevenue = paths.reduce((p,c) => p + c.revenue, 0)
  const averageRevenue = totalRevenue/conversions

  const averageLength = totalLengths/conversions
  const averageTime = totalTimes/conversions

  return { conversions, totalPaths, averageLength, averageTime, averageRevenue }
}