import React, { useState, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { Message, Segment, Button, Form } from 'semantic-ui-react';
import { SelectCheckbox } from '@rockerbox/styleguide';
import { updateEntityData } from '../../../api/attribution';
import { SetupDisplaySelector } from '../ManualSetup/SetupDisplay';
import { entityStore } from '../hooks/entityStore';
import CreateEntityProgram from './CreateProgram';
import { getDisplayString } from '../helpers';

const ViewProgram = ({ programs, entityConfigs, programOptions, hasLegacySpend }) => {
  const program = programs[0];
  const { id, tier_1, tier_2, tier_3, tier_4, tier_5 } = program;
  const [open, setOpen] = useState(false);
  const [entityType, setEntityType] = useState(program.entity_type);
  const [description, setDescription] = useState(program.description);
  const [fixedCost, setFixedCost] = useState(program.fixed_unit_cost);
  const [percentCost, setPercentCost] = useState(program.rev_share_percent);
  const [shouldHaveSponsorshipCost, setShouldHaveSponsorshipCost] = useState(program.has_campaign_costs);
  const [display, setDisplay] = useState({ tier_1, tier_2, tier_3, tier_4, tier_5 });
  const [loading, setLoading] = useState(false);

  const [getEntityConfigs] = entityStore(({ getEntityConfigs }) => [getEntityConfigs], shallow);

  useEffect(() => {
    setEntityType(program.entity_type);
    setDescription(program.description);
    setFixedCost(program.fixed_unit_cost);
    setPercentCost(program.rev_share_percent);
    setShouldHaveSponsorshipCost(program.has_campaign_costs);
    setDisplay({ tier_1, tier_2, tier_3, tier_4, tier_5 });
  }, [program]);

  const paymentValues = [];
  if (fixedCost) paymentValues.push('fixed_payout');
  if (percentCost) paymentValues.push('percent_payout');
  if (shouldHaveSponsorshipCost) paymentValues.push('sponsorship');

  const PAYMENT_TERMS = [
    { text: `A fixed amount per purchase${fixedCost ? `: $${fixedCost}` : ''}`, value: 'fixed_payout' },
    { text: `A percent of revenue driven${percentCost ? `: ${percentCost}%` : ''}`, value: 'percent_payout' },
    { text: 'A fee for a sponsorship / campaign run', value: 'sponsorship' },
  ];

  const onClose = () => setOpen(false);

  const onSubmit = ({ value }) => {
    const { entity_type } = value;
    const entitiesToUpdate = entityConfigs.filter(row => row.entity_type === program.entity_type);

    const promises = entitiesToUpdate
      .map(row => ({ ...row, entity_type }))
      .map(entity => updateEntityData(entity, entity, id));

    Promise.all(promises)
      .then(() => {
        getEntityConfigs(true)
          .then(() => setLoading(false));
      });

    onClose();
  };

  const programDisplayString = useMemo(() => getDisplayString(program), [program]);

  const mismatchedEntities = useMemo(() => entityConfigs.filter(row => {
    const isEntity = row.entity_type === program.entity_type;
    const rowDisplay = [...row.display, {}][0];
    const displayString = getDisplayString(rowDisplay);
    const matchDisplay = displayString === programDisplayString;

    return isEntity && !matchDisplay;
  }), [entityConfigs, programDisplayString]);

  const conformSponsorships = () => {
    setLoading(true);
    const promises = mismatchedEntities
      .map(row => ({ ...row, display: [JSON.parse(programDisplayString)] }))
      .map(entity => updateEntityData(entity, entity.id));

    Promise.all(promises)
      .then(() => {
        getEntityConfigs(true)
          .then(() => setLoading(false));
      });
  };

  return (
    <>
      <Segment style={{ border: '1px solid #f2f4fd' }}>
        <h3>
          {entityType}
          {' '}
          Program
        </h3>
        <Form>
          <Message content={description} color="white" />
          {!!hasLegacySpend
          && (
          <Segment secondary>
            <SelectCheckbox
              disabled
              description="Participants in this program are paid:"
              value={paymentValues}
              showBorder={false}
              multiple
              options={PAYMENT_TERMS}
              onChange={() => {}}
            />
          </Segment>
          )}
          <Segment secondary>
            <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
              Setup the standard structure underwhich you want marketing associated with this program to appear:
            </div>
            <div style={{ padding: '10px' }}>
              <SetupDisplaySelector
                showExampleOnly
                value={display}
                onChange={(evt, { value }) => setDisplay(value)}
              />
            </div>
          </Segment>
          { open && (
          <CreateEntityProgram
            {...{ programOptions }}
            {...{ open, onClose, onSubmit }}
            {...{ id, entityType, description, fixedCost, percentCost, shouldHaveSponsorshipCost, display, hasLegacySpend }}
          />
          )}
          <Button icon="pencil" onClick={() => setOpen(true)}>Edit</Button>
        </Form>
      </Segment>
      { mismatchedEntities.length > 0 && (
      <Segment {...{ loading }} style={{ border: '1px solid #f2f4fd' }}>
        <Message
          content="Sponsorships have been detected that do not conform to the program.
          Would you like to have all sponsorships conform to the display rules you specified as part of program setup?"
          icon="warning sign"
          color="white"
        />
        <div style={{ textAlign: 'center' }}>
          <Button icon="magic" onClick={conformSponsorships}>
            Yes, Update All Sponsorships to Conform
          </Button>
        </div>
      </Segment>
      )}
    </>
  );
};

export default ViewProgram;
