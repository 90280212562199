import React from 'react';
import { Grid, Table, Icon } from 'semantic-ui-react';

const CustomerProfile = props => (
  <Grid.Column width={props.width} className='p-0'>
    <Table structured className='b-none'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center' colSpan={2}>
            <Icon name='user' />
            Profile
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.children}
      </Table.Body>
    </Table>
  </Grid.Column>
)

export const Detail = props => (
  <Table.Row>
    <Table.Cell collapsing className='bold'>
      <Icon name={props.icon} />
      {props.label}
    </Table.Cell>
    <Table.Cell className='customer-profile-detail-value'>
      <div className='w-100 ellipsed'>
        {props.value}
      </div>
    </Table.Cell>
  </Table.Row>
)

export default CustomerProfile
