import React, { useState, useEffect } from 'react'
import { Grid, Button, Message } from 'semantic-ui-react'

import { homePageTeaser, playButton, homePagePlayTeaser } from '@rockerbox/styleguide'

const HELP_DOCS = [
  {'text': 'Getting Started', 'url': 'https://help.rockerbox.com/article/xp9f6cgi3r-1-what-rockerbox-is-solving-for'},
  {'text': 'Rockerbox UI Cheat Sheet', 'url': 'https://help.rockerbox.com/article/z07829fw2t-your-cheat-sheet-for-the-new-rockerbox-ui'},
  {'text': 'What to Expect with Rockerbox?', 'url': 'https://help.rockerbox.com/article/fupjusdnw5-what-to-expect-post-onboarding'},
]

const TRAININGS = [
  {'text': 'Rockerbox vs. In-Platform Performance: Understanding the Differences', 'url': 'https://my.demio.com/ref/dReQ7gghiDmQ6qLt'},
  {'text': 'Tactical Optimizations in Rockerbox', 'url': 'https://my.demio.com/ref/YRLzfC2crMQn0MYx'},
  {'text': 'More...', 'url': 'https://help.rockerbox.com/article/5751lsqksz-rockerbox-training-resources'},
]

const MessageContent = ({ setModalOpen }) => {
  return (
    <Grid>
      <Grid.Column width={8} className='video-demo'>
        <div>
          {/* <img src={playButton} /> */}
          {/* <img id="homepage-teaser" src={homePageTeaser} role="button" alt="tutorial" onClick={() => setModalOpen()} /> */}
          <img id="homepage-teaser" src={homePagePlayTeaser} role="button" alt="tutorial" onClick={() => setModalOpen(true)} />
        </div>
        <div>
          <h2>5-Minute Demo</h2>
          <p>In just a few minutes, our product<br/>experts show you how to get the<br/>most out of Rockerbox right now.</p>
          <Button fluid size="small" primary onClick={() => setModalOpen(true)} >Watch the demo<i className="play circle icon"></i></Button>
        </div>
      </Grid.Column>
      <Grid.Column width={4}>
        <div>
          <h2>Help Docs & Tutorials</h2>
          <ul>
            {HELP_DOCS.map(d => {
              return <li><a target="_blank" href={d['url']} title={d['text']}>{d['text']}</a></li>
            })}
          </ul>
        </div>
      </Grid.Column>
      <Grid.Column width={4}>
        <div>
          <h2>Rockerbox Training Series</h2>
          <ul>
            {TRAININGS  .map(d => {
              return <li><a target="_blank" href={d['url']} title={d['text']}>{d['text']}</a></li>
            })}
          </ul>
        </div>
      </Grid.Column>
    </Grid>
  )
}

const HelpMessage = ({ setModalOpen }) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const dontShow = localStorage.getItem("rbxHelpMessageDismissed");
    setOpen(!dontShow);
  }, []);

  const onClose = () => {
    localStorage.setItem("rbxHelpMessageDismissed", true);
    setOpen(false);
  }


  if (open) {
    return (
      <Message
        className='help-message'
        onDismiss={() => onClose()}
        content={<MessageContent {...{ setModalOpen }} />}
      />
    )
  }
  return null;
}

export default HelpMessage
