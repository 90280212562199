import React, { Component } from 'react';
import moment from "moment";
import * as d3 from 'rockerbox_d3_legacy_clone';
import _ from 'lodash'

import { DateRangeWrap } from './DateContentCard'
import DateRangePicker from '../ViewAttribution/DatePicker'
import { DataTable } from '@rockerbox/styleguide';
import { Popup, Icon, Message, Menu, Segment, Modal } from 'semantic-ui-react';

export const SITE_COLUMNS = [
  { 
    style: {width:"1.9em"},
    display: "", key: "state", type: "text", 
    asEdit: ({ value }) => <>{ value}</>, 
    asView: ({ cell, row, rowProps }) => <>
      { cell.value == "Approved" ? <Popup 
            content="The campaign setup details are setup correctly for this date range." 
            trigger={<Icon name="check circle" color="green" />} 
          /> :
        cell.value == "Partial" ? <Popup 
            content="This campaign applies to part of the current range. Only a portion of the spend will be visible with the current reporting view." 
            trigger={<Icon name="warning circle" color="green" />} 
          /> : 
        cell.value == "Outside" ? <Popup 
            content="The campaign start and end dates fall outside of the range. They will not have an impact on the current reporting view." 
            trigger={<Icon name="warning sign" color="red" />} 
          /> : null
      }
    </> 
  },
  { display: "Campaign Name", key: "name", type: "text" },
  { display: "Start Date", key: "start_date", type: "date" },
  { display: "End Date", key: "end_date", type: "date" },
  { display: "Cost", key: "cost", type: "currency" },
  { display: "Cost / Day", key: "dailyCost", type: "currency", readOnly: true },
  { display: "Days (In Range)", key: "days", type: "number", readOnly: true },
  { display: "Cost (In Range)", key: "currentCost", type: "currency", readOnly: true }
]

export const PROMO_COST_COLUMNS = [
  { display: "Description", key: "name", type: "text", readOnly: true },
  { display: "Promo code", key: "conversion_field_value", type: "text", readOnly: true },
  { display: "Discount ($)", key: "fixed_discount", type: "currency", readOnly: true },
  { display: "Discount (%)", key: "percent_discount", type: "percent", readOnly: true },
  { display: "Fixed Payout ($)", key: "fixed_unit_cost", type: "currency" },
  { display: "Revenue Share (%)", key: "rev_share_percent", type: "percent" },
  { display: "Calculated Cost", key: "calculatedCost", type: "currency", readOnly: true }
]

const SpendModal = (props) => {

  const { onClose, open, selectedItem, selectedData, endDate } = props;
  if (!selectedItem) return null

  const { costs, name } = props.selectedEntity;
  const promoCodes = props.selectedEntity.promo_codes;

  const [startDate, setStartDate] = React.useState(props.startDate)
  const [showCurrent, setShowCurrent] = React.useState(true)

  const currentCosts = React.useMemo(() => showCurrent ? costs.filter(row => (moment(row.end_date) >= moment(startDate))) : costs, [showCurrent, costs, startDate])

  const currentCampaignCosts = currentCosts
    .reduce((p,c) => {
      const range = c.cost_range.filter(day => (day.date >= startDate) && (day.date <= endDate))
      return [...p, ...range]
    }, [])
    .reduce((p,c) => {
      p['total'][c.name] = (p['total'][c.name] || 0) + c.cost
      p['daily'][c.name] = (p['daily'][c.name] || c.cost)
      return p
    }, {total: {}, daily: {}})

  currentCosts.map(row => {
    row.start_date = moment(row.start_date).format("YYYY-MM-DD")
    row.end_date = moment(row.end_date).format("YYYY-MM-DD")
    const _start = moment(startDate).format("YYYY-MM-DD")
    const _end = moment(endDate).format("YYYY-MM-DD")

    row.state = (row.start_date >= _start) && (row.end_date <= _end) ? "Approved" :
      (row.start_date < _start) && (row.end_date > _start) ? "Partial" :
      (row.start_date <= _start) && (row.end_date > _end) ? "Partial" : "Outside";

    row.currentCost = currentCampaignCosts['total'][row.name]
    row.dailyCost = currentCampaignCosts['daily'][row.name]
    row.days = row.currentCost/row.dailyCost
  })

  const currentPromoCodes = React.useMemo(
    () => promoCodes.map(code => {
      const assisted = d3.sum(selectedData, x => x.assisted || 0)
      const revenue = d3.sum(selectedData, x => x.revenue || 0)
      let calculatedCost = 0
      if (code.rev_share_percent) calculatedCost += revenue*code.rev_share_percent/100
      if (code.fixed_unit_cost) calculatedCost += assisted*code.fixed_unit_cost
      code.calculatedCost = calculatedCost
      return code
    }), 
    [promoCodes]
  )

  const setStartEnd = ({ startDate, endDate }) => {
    setStartDate(startDate.format("YYYY-MM-DD 00:00:00"))
  }

  return <Modal {...{onClose, open}}>
    <Segment>
      <h3 style={{display:"inline-block"}}>
        View Costs for {name}
      </h3>
      <div style={{fontSize:".9em",float:"right"}}>
        <DateRangeWrap>
          <DateRangePicker startDate={moment(startDate)} endDate={moment(endDate)} onChange={setStartEnd} />
        </DateRangeWrap>
      </div>

      <h5>Sponsorships</h5>
      <Menu tabular>
        <Menu.Item name='Current' active={showCurrent} onClick={() => setShowCurrent(true)} />
        <Menu.Item name='All' active={!showCurrent} onClick={() => setShowCurrent(false)} />
      </Menu>
      <Message header='Navigate to Settings > Sponsorships' list={['To adjust the costs associated with each sponsorship', 'To add new campaigns']} />
      <DataTable 
        {...{
            columns: SITE_COLUMNS, 
            data: currentCosts, 
            readOnly: true
          }
        } 
      />

      <h5>Promo/Affiliates</h5>
      <DataTable 
        {...{
            columns: PROMO_COST_COLUMNS, 
            data: currentPromoCodes, 
            readOnly: true
          }
        } 
      />
    </Segment>
  </Modal>
}

export default SpendModal;
