import React, { Component } from "react";
import moment from "moment";
import Container from "../parts/Container";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import { sendScheduledReporting, getConversionSegments } from "../../utils/api";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";
import { CloseButtonStyles } from "../styled";

const ErrorContent = (props) =>
  props.errorMessage ? (
    <Message color="yellow">
      <Message.Content>{props.errorMessage}</Message.Content>
    </Message>
  ) : null;

const errorType = {
  outOfRange: "You have selected a date range more than 60 days.",
  beforeSetup:
    "You have selected a date before this report has been setup. This report setup date is: ",
};

const maxDays = 59;

const getErrorMsgIfInvalidRange = (startDate, endDate, firstReportingDate, type) => {

  // necessary for the state values not to get all messed up
  const endDateCopy = moment(endDate.format());
  const startDateCopy = moment(startDate.format());
  const beforeSetup = firstReportingDate ? startDateCopy.isBefore(firstReportingDate) : false;
  const outOfRange = type !== "daily" ? endDateCopy.isAfter(startDateCopy.add(maxDays, "days")) : false;

  if (beforeSetup) {
    return errorType["beforeSetup"] + firstReportingDate.format("MM/DD/YYYY");
  }

  if (outOfRange && type !== "daily") {
    return errorType["outOfRange"];
  }

  return null;
}

const SuccessContent = () => (
  <Message
    positive
    header="Reports have been redelivered"
    content="You will see reports delivered to scheduled report destination soon."
  ></Message>
);

const RedeliverReportForm = ({ id, firstReportingDate }) => {
  const [dates, setDates] = React.useState({
    startDate: moment.utc().utcOffset(-5).subtract(1, "days"),
    endDate: moment.utc().utcOffset(-5).subtract(1, "days")
  })
  const [type, setType] = React.useState("daily");
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [status, setStatus] = React.useState({
    success: false,
    loading: false
  });
  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSubmit = React.useCallback(() => {
    setStatus({
      success: false,
      loading: true
    })

    const obj = {
      scheduled_report_id: parseInt(id) || null,
      start_date: dates.startDate.format("YYYY-MM-DD"),
      end_date: dates.endDate.format("YYYY-MM-DD"),
      type,
    };

    sendScheduledReporting(obj).then((d) => {
      setStatus({
        success: true,
        loading: false
      })
    });
  }, [dates, type]);

  const onDatesChange = ({ startDate, endDate }) => {
    // necessary for the state values not to get all messed up
    const endDateCopy = moment(endDate ? endDate.format() : dates.endDate);
    const startDateCopy = moment(startDate ? startDate.format() : dates.startDate);

    const isEndDateOutOfBound = type !== "daily" ? endDateCopy.isAfter(
      startDateCopy.add(maxDays, "days")
    ) : false;

    setDates({
      startDate: startDate || dates.startDate,
      endDate: isEndDateOutOfBound ? startDateCopy : endDate || dates.endDate
    })

  };

  const onTypeChange = React.useCallback((e, { value }) => setType(value), []);

  const onFocusChange = React.useCallback((focusedInput) => setFocusedInput(focusedInput), [focusedInput]);
  
  const handleOutsideRange = React.useCallback((day) => {
    const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").endOf("day");

    // necessary for the state values not to get all messed up
    const endDateCopy = moment(dates.endDate.format());
    const startDateCopy = moment(dates.startDate.format());

    const beforeMin = day.isBefore(endDateCopy.subtract(maxDays, "days"));
    const afterMax = day.isAfter(yesterday);
    const afterSixty = day.isAfter(startDateCopy.add(maxDays, "days"));

    return afterMax || afterSixty;
  }, []);

  const validate = React.useCallback(() => (true), []);

  React.useEffect(() => {
    const message = getErrorMsgIfInvalidRange(dates.startDate, dates.endDate, firstReportingDate, type);
    setErrorMessage(message);
  }, [dates, type, firstReportingDate]);
  
  return (
    <Container>
      {status.success ? (
        <SuccessContent />
      ) : (
        <Form onSubmit={onSubmit} loading={status.loading}>
            {type === 'aggregated' && <Message
              info
              content="Max 60 days per submission. For redeliveries of longer time periods, please break up into smaller sections of 60 days each."
            />}
          <Form.Group>
            <Form.Field>
              <label>You cannot select a date range earlier than the First Reporting Date for this Conversion: {firstReportingDate.format("MM/DD/YYYY")}.</label>
              <label>Date Range</label>
              <DateRangePicker
                onDatesChange={onDatesChange}
                onFocusChange={onFocusChange}
                focusedInput={focusedInput}
                startDate={dates.startDate}
                endDate={dates.endDate}
                noBorder={true}
                isOutsideRange={handleOutsideRange}
                hideKeyboardShortcutsPanel
                startDateId="deliveryStartDate"
                endDateId="deliveryEndDate"
                minimumNights={0}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Radio
              label="Single day"
              value="daily"
              checked={type === "daily"}
              onChange={onTypeChange}
            />
            <Form.Radio
              label="Aggregated"
              value="aggregated"
              checked={type === "aggregated"}
              onChange={onTypeChange}
            />
          </Form.Group>
          <Button
            type="submit"
            disabled={!validate() || errorMessage || status.loading}
          >
            Deliver Report
          </Button>
          <ErrorContent errorMessage={errorMessage} />
        </Form>
      )}
    </Container>
  );
}

const RedeliverReportModal = (props) => (
  <Modal
    size="small"
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>Report
      Delivery
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <RedeliverReportForm done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);

export default RedeliverReportModal;
