import React, { Component } from 'react';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';

import { buildCurrency } from '../../utils/format_helpers';

import Spend from './Spend';
import Profit, { ProfitTotal } from './Profit';
import COGS from './COGS';
import Values from './Values';
import Total from './Total';

const NAMES = [ "Existing", "repeat Existing", "NTF", "repeat NTF"]

export const metricSettings = [
  {
    "title": "Customers",
    "accessor": "converters",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "finalType": "first",
    "useMultiplier": false,
  },
  {
    "title": "Conversions",
    "accessor": "conversions",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "useMultiplier": false,
  },
  {
    "title": "Revenue",
    "accessor": "revenue",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "formatter": buildCurrency,
    "useMultiplier": false,
  },
  {
    "title": "Marketing Spend",
    "accessor": "spend",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "formatter": buildCurrency,
    "useMultiplier": false,
    "Component": Spend
  },
  {
    "title": "COGS",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "accessor": "revenue",
    "formatter": buildCurrency,
    "Component": COGS
  },
  {
    "title": "Profit",
    "cohortKeys": ["NTF", "Existing"],
    "keys": ["repeat NTF", "repeat Existing"],
    "formatter": buildCurrency,
    "Component": Profit,
    "TotalComponent": ProfitTotal
  },
]

const MetricsData = ({finalType, key, title, accessor, cohortKeys, keys, months, values, formatter, Component, TotalComponent, multiplier, useMultiplier}) => {
  const TotalC = TotalComponent || Total;
  const ValuesC = Component || Values;

  return (
    <Row {...{key}}>
      <Collapsible
        {...{key}}
        open={false}
        title={ <Column padded="5" style={{textAlign:"left"}}>{title}</Column> }
        totalRow={<TotalC tag={key} {...{ cohortKeys, keys, accessor, values, months, multiplier, formatter, finalType, useMultiplier}} /> }
      >
        <ValuesC tag={key} keys={NAMES} {...{ accessor, values, months, multiplier, formatter}} />
      </Collapsible>
    </Row>

  )
}

export default MetricsData;
