import React, { useState, useCallback } from "react";
import {
  Table,
  Button,
  Icon,
  Checkbox,
  Header,
  Dropdown,
  Popup
} from "semantic-ui-react";
import { ContentCard, IndexGridNew } from "@rockerbox/styleguide";
import { experimentStore } from "./store";
import { getTiers, combineRow, hashCode } from "./helper";
import { searchSplitKey } from "./constants";
import { colorHash } from "./ExperimentChart";
import { buildCurrency } from "../../utils/format_helpers";
import { DeleteButton } from "./DeleteButton";

const formatter = new Intl.NumberFormat("en-US");

const treamentSelector = state => [
  state.activeTreatments,
  state.activeExperimentId,
  state.saveTreatment,
  state.getExperiments
];

const DeleteComponent = state => ({ item: { searchKey, treatment_id } }) => {
  const [
    activeTreatments,
    activeExperimentId,
    saveTreatment,
    getExperiments
  ] = experimentStore(treamentSelector);

  const onDelete = useCallback(
    (e, data) => {
      const newTreatments = activeTreatments.filter(
        treatment => treatment.id !== treatment_id
      );
      saveTreatment(activeExperimentId)({
        treatments: newTreatments
      }).then(() => getExperiments());
    },
    [activeTreatments]
  );

  return (
    <Table.Cell collapsing>
      {treatment_id === "baseline" || state !== "running" ? (
        <div style={{ width: "27px" }}></div>
      ) : (
        <DeleteButton onDelete={onDelete} />
      )}
    </Table.Cell>
  );
};

const NameComponent = state => ({ item: { searchKey, treatment_id } }) => {
  if (!searchKey) {
    return null;
  }
  const splitItem = searchKey.split(searchSplitKey);

  const name = splitItem[splitItem.length - 1];
  const subHeader = splitItem
    .slice(0, splitItem.length - 1)
    .join(` ${searchSplitKey} `);

  const iconColor = React.useMemo(() => {
    return colorHash(hashCode(`${searchKey}${treatment_id}`));
  }, [searchKey, treatment_id]);

  return (
    <Table.Cell collapsing>
      <Popup
        trigger={
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Icon
              name="circle"
              style={{
                width: "30px",
                marginBottom: "4.5px",
                textAlign: "left",
                alignSelf: "center",
                color: iconColor
              }}
            />
            {name}
          </div>
        }
        content={subHeader}
        hoverable
        inverted
      />
    </Table.Cell>
  );
};

const CheckComponent = (selectedArray, onClick) => props => (
  <Table.Cell collapsing>
    {props.item.is_child ? (
      <Icon name="chevron right" />
    ) : (
      <span>
        <Checkbox
          value={props.item.treatment_id}
          checked={selectedArray.indexOf(props.item.treatment_id) > -1}
          onChange={onClick}
        />
      </span>
    )}
  </Table.Cell>
);

const SelectAll = props => {
  return (
    <Checkbox
      checked={props.selectAll}
      indeterminate={!props.selectAll}
      onChange={props.handleSelectAll}
    />
  );
};

const ConversionHeader = ({ content, title }) => {
  const style =
    title === "Statistical Significance"
      ? { minWidth: "130px", display: "flex" }
      : { display: "flex" };
  return (
    <Popup
      content={content}
      trigger={
        <div style={style}>
          <span
            style={{
              textAlign: "left",
              alignSelf: "center"
            }}>
            {title}
          </span>
          <Icon
            name="question circle"
            style={{ color: "#CACBCD", marginLeft: 5, marginTop: 1 }}
          />
        </div>
      }
      inverted
    />
  );
};

const NumberComponent = (key, format = formatter.format) => props => (
  <Table.Cell collapsing>
    <span> {props.item[key] ? format(props.item[key]) : "-"} </span>
  </Table.Cell>
);

const CurrencyComponent = key => props => (
  <Table.Cell collapsing>
    <span>
      {" "}
      {props.item[key] ? buildCurrency(props.item[key].toFixed(2)) : "-"}{" "}
    </span>
  </Table.Cell>
);

const PercentageComponent = (key, format = formatter.format) => props => (
  <Table.Cell collapsing>
    <span>
      {" "}
      {props.item[key] || props.item[key] === 0
        ? `${format(props.item[key])}%`
        : "-"}{" "}
    </span>
  </Table.Cell>
);

const RecommendIconComponent = ({
  item: { treatment_id, recommendation = "Insufficient Data" }
}) => {
  const iconNames = {
    Outperforms: "chevron circle up",
    Neutral: "minus circle",
    Underperforms: "chevron circle down",
    "Insufficient Data": "hourglass half"
  };
  const colorMap = {
    Outperforms: "green",
    Neutral: "yellow",
    Underperforms: "red",
    "Insufficient Data": "grey"
  };
  const textColor = {
    Neutral: "#FFCE16",
    Outperforms: "#00BB5C",
    Underperforms: "#FF3D00"
  };

  if (treatment_id === "baseline") {
    return <Table.Cell collapsing></Table.Cell>;
  }

  return (
    <Table.Cell collapsing>
      <Icon name={iconNames[recommendation]} color={colorMap[recommendation]} />
    </Table.Cell>
  );
};
const RecommendComponent = ({
  item: { treatment_id, recommendation = "Insufficient Data" }
}) => {
  if (treatment_id === "baseline") {
    return (
      <Table.Cell collapsing>
        <b>Baseline</b>
      </Table.Cell>
    );
  }

  const colorMap = {
    Outperforms: "green",
    Neutral: "yellow",
    Underperforms: "red",
    "Insufficient Data": "grey"
  };

  return (
    <Table.Cell collapsing style={{ color: colorMap[recommendation] }}>
      <b>{recommendation}</b>
    </Table.Cell>
  );
};

const saveTreatmentSelector = state => [
  state.saveTreatment,
  state.getExperiments,
  state.activeTreatments
];

const AddTreatmentButton = props => {
  const { data, testId } = props;
  const [saveTreatment, getExperiments, activeTreatments] = experimentStore(
    saveTreatmentSelector
  );
  const [status, setStatus] = useState("pending");
  const [value, setValue] = useState("");
  if (status === "pending")
    return (
      <Button
        primary
        onClick={() => setStatus("selection")}
        id="experimentAddTest">
        <Icon name="plus" />
        Add Test
      </Button>
    );

  const options = data.map(searchKey => {
    const reverseTiers = searchKey.split(searchSplitKey).reverse();
    return {
      key: searchKey,
      text: reverseTiers[0],
      value: searchKey,
      content: (
        <Header
          as="h5"
          content={reverseTiers[0]}
          subheader={searchKey
            .split(searchSplitKey)
            .slice(0, -1)
            .join(` ${searchSplitKey} `)}
        />
      )
    };
  });

  const onSave = (e, data) => {
    // TODO: loading
    if (value) {
      const tiers = value.split(searchSplitKey).reduce(
        (prev, cur, index) => {
          prev[`treatment_tier_${index + 1}`] = cur;
          return prev;
        },
        {
          treatment_tier_5: null,
          treatment_tier_4: null,
          treatment_tier_3: null,
          treatment_tier_2: null
        }
      );
      saveTreatment(testId)({
        treatments: [...activeTreatments, tiers]
      }).then(() => getExperiments());
    }

    setStatus("pending");
  };

  return (
    <>
      <Dropdown
        selection
        options={options}
        search
        style={{ width: "35%", marginRight: "15px" }}
        placeholder="Choose a test campaign"
        onChange={(e, data) => {
          setValue(data.value);
        }}
      />
      <Button color="green" onClick={onSave}>
        Add Test{" "}
      </Button>
      <Button onClick={() => setStatus("pending")}>Cancel </Button>
    </>
  );
};

const selector = state => [
  state.activeTreatments,
  state.selectedTreatments,
  state.setSelectedTreatments,
  state.apiRequestLoading,
  state.computingLoader,
  state.artifacts
];

const TreatmentCampaignTable = props => {
  const { tableData, searchKeys, id, data_tier, state } = props;
  const [
    activeTreatments,
    selectedTreatments,
    setSelectedTreatments,
    apiRequestLoading,
    computingLoader,
    artifacts
  ] = experimentStore(selector);

  const onCheck = useCallback((_, data) => {
    if (data.checked) {
      selectedTreatments.push(data.value);
      setSelectedTreatments([...selectedTreatments]);
    } else {
      const removed = selectedTreatments.filter(id => id !== data.value);
      setSelectedTreatments(removed);
    }
  });

  const onSelectAll = useCallback((e, data) => {
    if (data.checked) {
      setSelectedTreatments([
        "baseline",
        ...activeTreatments.map(treat => treat.id)
      ]);
    } else {
      setSelectedTreatments([]);
    }
  });

  const fixTwoDecimal = n => f => f.toFixed(n);

  const columns = [
    {
      display: (
        <SelectAll
          selectAll={selectedTreatments.length === activeTreatments.length + 1}
          handleSelectAll={onSelectAll}
        />
      ),
      key: "check",
      as: CheckComponent(selectedTreatments, onCheck)
    },
    { display: "Line Items", key: data_tier, as: NameComponent(state) },
    { display: "", key: "delete", as: DeleteComponent(state) },
    {
      display: "Recommendation",
      key: "recommendation",
      as: RecommendComponent
    },
    {
      display: "",
      key: "recommendationIcon",
      as: RecommendIconComponent
    },
    {
      display: "Spend",
      key: "spend",
      as: CurrencyComponent("spend") // support other currency in the future
    },
    {
      display: (
        <ConversionHeader
          content={artifacts["conversions"]}
          title="Conversions"
        />
      ),
      key: `${data_tier}_assisted`,
      as: NumberComponent(`${data_tier}_assisted`)
    },
    {
      display: (
        <ConversionHeader content={artifacts["revenue"]} title="Revenue" />
      ),
      key: `${data_tier}_assisted_revenue`,
      as: CurrencyComponent(`${data_tier}_assisted_revenue`) // support other currency in the future
    },
    {
      display: <ConversionHeader content={artifacts["cpa"]} title="CPA" />,
      key: "cpa",
      as: CurrencyComponent("cpa") // support other currency in the future
    },
    {
      display: <ConversionHeader content={artifacts["roas"]} title="ROAS" />,
      key: "roas",
      as: NumberComponent("roas", fixTwoDecimal(2))
    },
    {
      display: <ConversionHeader content={artifacts["lift"]} title="Lift" />,
      key: "lift",
      as: NumberComponent("lift", fixTwoDecimal(3))
    },
    {
      display: (
        <ConversionHeader
          content={artifacts["percentile"]}
          title="Statistical Significance"
        />
      ),
      key: "percentile",
      as: PercentageComponent("percentile")
    }
  ];

  return (
    <ContentCard
      title="Experiment Line Items"
      hasTable
      loading={apiRequestLoading || computingLoader}>
      <IndexGridNew
        cols={columns}
        data={tableData}
        className="experiments-table"
        fallBackMsg="Processing Data..."
        footer={
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={12}>
                {state === "running" ? (
                  <AddTreatmentButton data={searchKeys} testId={id} />
                ) : null}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      />
    </ContentCard>
  );
};

export default TreatmentCampaignTable;
