import React, { useMemo } from 'react'
import { Container, Image, Header, Button } from 'semantic-ui-react'

import { CacheContext } from '../../../utils/CacheContext'
import { getArtifacts } from '../../api/attribution'
import { marketingMixModelingHero } from '@rockerbox/styleguide'
import { track } from '../../../utils/tracking'
import { parseArtifacts } from './helpers'


const UpgradePromo = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const artifacts = Context.getCache('artifacts', getArtifacts) || null
  const parsedArtifacts = useMemo(() => parseArtifacts(artifacts), [artifacts])

  const mmmInfo = useMemo(() => parsedArtifacts?.mmm_info || [], [parsedArtifacts])

  const intercomSurveyId = useMemo(() => {
    if (!parsedArtifacts) return null
    const { intercom_surveys } = parsedArtifacts || {}
    const survey = intercom_surveys?.mmm_waitlist || {}
    const intercomEnv = window.env === 'prod' ? 'prod' : 'dev'
    const surveyId = survey[intercomEnv]
    return surveyId
  }, [parsedArtifacts])

  const openWaitlistForm = () => {
    if (!window.Intercom || !intercomSurveyId) return
    window.Intercom('startSurvey', intercomSurveyId)
    track('upgrade_contact.mmm')
  }

  const UpgradeButton = (<>
    <Button
      content='Sign Up For the Waitlist'
      onClick={openWaitlistForm}
      primary
      size='large'
      style={{ marginTop: 20 }}
    />
    <p style={{ marginTop: 6, opacity: 0.5 }}>Starting at $1,000/mo</p>
  </>)

  return (<>
    <Container textAlign='center'>
      <Image
        src={marketingMixModelingHero}
        centered
        size='big'
        style={{ marginTop: 50, height: 256 }}
        />
      <Header
        as='h1'
        content='Get Started with Marketing Mix Modeling'
        subheader='Identify the optimal budget per channel and build plans with predictive revenue forecasts'
        style={{ marginTop: 50 }}
      />
      {UpgradeButton}
    </Container>
    <div style={{ maxWidth: 650, marginLeft: 'auto', marginRight: 'auto', marginBottom: 30 }}>
      {mmmInfo.map(({ header, icon, description }) => (
        <div key={header} style={{ marginTop: 50 }}>
          <Header
            as='h3'
            content={header}
            icon={{
              name: icon,
              color: 'purple',
              circular: true,
              inverted: true,
              style: { opacity: 0.5, fontSize: '1.25em' },
            }}
          />
          {description.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </div>
      ))}
    </div>
    {!!mmmInfo?.length && 
      <Container textAlign='center'>
        {UpgradeButton}
      </Container>
    }
  </>)
}

export default UpgradePromo
