import React from 'react';
import { Form, Grid, Icon, Input, Dropdown, Button, Message } from 'semantic-ui-react';
import {postSnowflakeInit, postSnowflakeParseURL} from "../../../api/datalake";
import {useStepsState, useStepsDisplay} from '../StepsSectionContext';
import ErrorMessage from "../components/ErrorMessage";

const IDatalakeConnection = {
  id: '',
  pixel_source_name: '',
  platform: '',
  storage_bucket: '',
  storage_region: '',
  active: '',
  deleted: '',
  created_at: '',
  updated_at: ''
}
const SetupShare = () => {
  const [clientURL, setClientURL] = React.useState('')
  const [account, setAccount] = React.useState('')
  const [cloud, setCloud] = React.useState('')
  const [region, setRegion] = React.useState('')
  const [urlCallStatus, setUrlCallStatus] = React.useState('')
  const [rockerboxAccountId, setRockerboxAccountId] = React.useState('')
  const [datalakeConnection, setDatalakeConnection] = React.useState({})
  const [datalakeConnectionDetails, setDatalakeConnectionDetails] = React.useState({})
  const [stepsDisplay, setStepsDisplay] = useStepsDisplay();
  const [stateData, setStateData] = useStepsState();
  const [apiError, setAPIError] = React.useState('')
  const [apiResponse, setAPIResponse] = React.useState({})
  const [disableSubmit, setDisableSubmit] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const onCreateDBClick = () => {
    setLoading(true)
    setDisableSubmit(true)
    postSnowflakeInit(account, cloud, region, clientURL)
      .then((data) => {
        data = data['data']
        setAPIResponse(data)
        setAPIError(data['message'])
        setDatalakeConnection(Object.keys(IDatalakeConnection).map((item) => { return {[item]: data[item]} }))
        setDatalakeConnectionDetails(data['details'])
        setRockerboxAccountId(data['details']['rockerbox_account_id'])
        setStateData(data)
        if (data['status'] === 'success') {
          setStepsDisplay(1, true);
        }
        setDisableSubmit(false)
        setLoading(false)
      })
  }

  const onURLInput = (value) => {
    setClientURL(value)
    if (value.length > 3) {
      postSnowflakeParseURL(value)
        .then((data) => {
          setAPIResponse(data)
          setAPIError(data['message'])
          setAccount(data['account'])
          setCloud(data['cloud'])
          setRegion(data['region'])
          setUrlCallStatus(data['status'])
        })
    }
  }

  const returnURLIconStyle = (status) => {
    if (status === "error") {
      return {name: "remove circle", color: "red"}
    }

    if (status === "success") {
      return {name: "check circle", color: "green"}
    }
  }

  return (
    <div>
      <div>
        <Grid columns={2}>
          <Grid.Column width={6}>
            <Form>
              <Form.Field width={12}>
                <label>Snowflake Instance URL</label>
                <div className="ui icon input">
                  <Input
                    style={{width: 400}}
                    placeholder="123xyz.snowflakecomputing.com"
                    value={clientURL}
                    iconPosition='right'
                    onChange={({ target: { value } }) => onURLInput(value)}>
                    <Icon {...returnURLIconStyle(urlCallStatus)} />
                    <input />
                  </Input>
                </div>
                <p/>
                {!!loading ?
                  <Button loading disabled={true}>Loading</Button> :
                  <Button primary content="Setup Share" onClick={onCreateDBClick} disabled={urlCallStatus !== 'success' || disableSubmit} />
                }
              </Form.Field>
              {apiError && <ErrorMessage message={apiError} />}
            </Form>
          </Grid.Column>
          <Grid.Column
            width={6}
          >
            <Message>
              <div>
                <p>Account: {account}</p>
                <p>Cloud: {cloud}</p>
                <p>Region: {region}</p>
              </div>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

export default SetupShare;
