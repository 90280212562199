import React, { Component } from 'react';
import { Step, Dropdown, Button, Modal, Table, Loader } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import * as routes from '../../../routes';

import { getFacebookSyntheticConfigs, getMetaData } from '../../../utils/api';

import View from './view';
import Form from './form';
import SummaryData from './data';
import SetupStatus from './../../DataStatus';
import PixelSetting from './pixelSetting';

const Steps = ({ current, refresh }) => {

  const { filter_id } = current

  const steps = ["Setup", "Status", "Impact"]
  const [activeStep, setActiveStep] = React.useState(steps[0])

  return <>
    <Step.Group widths={steps.length} size="mini">
    { 
      steps.map(value => {
        return <Step active={activeStep == value} onClick={() => setActiveStep(value)}>
          <Step.Content><Step.Title>{ value }</Step.Title></Step.Content>
        </Step>
      })
    }
    </Step.Group>

    { activeStep == "Setup" && <>
      <Form data={current} id={current.filter_id} onSuccess={refresh} />
      <PixelSetting />
    </>}
    { activeStep == "Status" && <SetupStatus filter_id={filter_id} /> }
    { activeStep == "Impact" && <SummaryData filter_id={filter_id} /> }
  </>

}

class FacebookComparison extends Component {

  state = {
    response: undefined
  }

  componentWillMount() {
    this.getData()
  }

  async getData() {
    const response = await getFacebookSyntheticConfigs()
    this.setState({ response })
  }

  render() {
    const { response } = this.state;
    const { type, id } = this.props.match.params

    if (response == undefined) return <Loader active inline='centered' />

    const current = response && id ? 
      response.find(row => row.id == id) : response ?
      response[0] : {}

    if (current && (type == undefined)) {
      return <>
        <Steps {...{current, refresh: () => {}}} />
      </>
    }

    return (
      <React.Fragment>
        { type == "view" && id && <View data={current} id={current.filter_id} />}
        { type == "edit" && id && <Form data={current} id={current.filter_id} onSuccess={this.refresh}/>}
        { type == "create" && <Form onSuccess={this.refresh}/>}
      </React.Fragment>
    )
  }
}


export default withRouter(FacebookComparison);
