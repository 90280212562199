import React from 'react';
import { Button, Input, Form, TextArea } from 'semantic-ui-react';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';
import TiersInput from './TiersInput';

const ExternalMarketingInput = ({ marketType, handleUrlChange, handleSubmit }) => {
  const [externalObj, setExternalObj] = React.useState({});
  const [fakeUrl, setFakeUrl] = React.useState('');

  React.useEffect(() => handleUrlChange(fakeUrl));

  const onExternalTierChange = (e, data) => {
    let newTierObj = externalObj;
    newTierObj[`tier_${data.name}`] = e.target.value;
    setExternalObj(newTierObj);
    updateFakeUrl(externalObj)
  };

  const updateFakeUrl = (obj) => {
    let newFakeUrl = `fakeexternal.com?tier_one=${obj.tier_one}&tier_two=${obj.tier_two}&tier_three=${obj.tier_three}&tier_four=${obj.tier_four}&tier_five=${obj.tier_five}`
    setFakeUrl(newFakeUrl);
  }

  return (
    <Form>
      {marketType === 'external' ? (
        <Form.Field>
          <label>Tiers:</label>
          <TiersInput onExternalTierChange={onExternalTierChange} />
        </Form.Field>
      ) : (
          <Form.Field>
            <label>Enter JSON Object Below (Do Not Include Array [ ])</label>
            <AceEditor
              mode="json"
              theme="tomorrow"
              setOptions={{
                highlightGutterLine: false,
                showGutter: true,
                fontSize: 12,
              }}
              width='100%'
              height='300px'
              placeholder='Enter JSON Object'
              onChange={(value) => updateFakeUrl(JSON.parse(value))}
              style={{marginTop: 15}}
            />
          </Form.Field>
      )}
      <Button primary style={{ marginTop: 10 }} onClick={handleSubmit} disabled={fakeUrl===''}>
        Check External Marketing
      </Button>
    </Form>
  );
};

export default ExternalMarketingInput;
