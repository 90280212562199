import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

class FunnelChart extends Component {
  /**
   * creates the instance
   * @param  {Object} props                     props passed to the component
   * @param  {Array}  props.data                Array of stages to be drawn from left to right
   * @param  {String} props.innerValueKey       property to populate the value in the center of each funnel stage
   * @param  {String} props.outerValueKey       property to compare the inner value with for drawing the inner funnel (defaults to bottomRightValueKey)
   * @param  {String} props.bottomRightValueKey property to populate the value at the bottom-right of each funnel stage
   * @param  {String} props.bottomLeftValueKey  property to populate the value at the bottom-left of each funnel stage
   * @param  {String} props.topLeftLabelKey     property to populate the label in the top-left of each funnel stage
   * @param  {String} props.bottomRightLabelKey property to populate the label in the bottom-right of each funnel stage
   * @param  {Object} context                   context received from the parent
   */
  constructor(props, context){
    super(props, context)
  }

  /**
   * creates a new SVG element with provided tag and attributes
   * @static
   * @param  {String} tag    SVG tag to create an element of
   * @param  {Object} attrs  Attributes to attach to the created element
   * @return {Element}       SVG element creates
   */
  static createSVGElement(tag, attrs){
    let el = document.createElementNS('http://www.w3.org/2000/svg', tag)
    if (attrs) Object.keys(attrs).forEach(key => el.setAttribute(key, attrs[key]))
    return el
  }

  /**
   * computes the dimensions of the element to render things appropriately
   * @return {Object} Object {width, height} to render into
   */
  computeDimensions() {
    let svgElement = ReactDOM.findDOMNode(this.refs.funnelElement)
    return {
      width: svgElement.clientWidth || svgElement.parentNode.clientWidth,
      height: svgElement.clientHeight || svgElement.parentNode.clientHeight,
    }
  }

  /**
   * constructs a polygon with specified points and attributes
   * @param  {Array}  points      corner co-ordinates of the polygon
   * @param  {Object} attributes  attributes to assign to the polygon
   * @return {SVGPolygonElement}  SVG polygon element
   */
  constructPolygon(points, attributes){
    let computedAttributes = _.assign(attributes, {
      points: _.reduce(points, (pointString, point) =>
        pointString + (point.x + ',' + point.y + ' ')
      , '')
    })
    return FunnelChart.createSVGElement('polygon', computedAttributes)
  }

  /**
   * constructs the outer band based on the dimensions of the container and
   * the padding to be left on the top-bottom and extra padding on the top-bottom
   * for the right end.
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the top and bottom
   * @param  {Number} rightOuterBandPadding       % padding to be left at the top and bottom on the right end
   * @return {SVGPolygonElement}                  Construcuted SVG Polygon Element as the outer band
   */
  constructOuterPolygon(svgDimensions, bandPercentageHeightPadding, rightOuterBandPadding){
    let outerPolygonPoints = []

    outerPolygonPoints.push({
      x: 0,
      y: svgDimensions.height * bandPercentageHeightPadding,
    })
    outerPolygonPoints.push({
      x: 0,
      y: svgDimensions.height * (1 - bandPercentageHeightPadding),
    })

    outerPolygonPoints.push({
      x: svgDimensions.width,
      y: svgDimensions.height * (1 - bandPercentageHeightPadding - rightOuterBandPadding),
    })
    outerPolygonPoints.push({
      x: svgDimensions.width,
      y: svgDimensions.height * (bandPercentageHeightPadding + rightOuterBandPadding),
    })

    let animationStartPoints = _.cloneDeep(outerPolygonPoints)

    _.forEach(animationStartPoints, point => point.y = svgDimensions.height / 2)

    let animationEl = FunnelChart.createSVGElement('animate', {
      attributeName: 'points',
      dur: '1800ms',
      begin: '200ms',
      fill: 'freeze',
      keySplines: '0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1',
      keyTimes: '0;0.22;0.33;0.55;0.66;0.88;1',
      calcMode: 'spline',
      to: _.reduce(outerPolygonPoints, (pointString, point) =>
        pointString + (point.x + ',' + point.y + ' ')
      , '')
    })

    let outerPolygon = this.constructPolygon(animationStartPoints, {
      fill: '#F9F9F9',
      stroke: '#EEEEEE',
      strokeWidth: 1,
    })

    outerPolygon.appendChild(animationEl)

    return outerPolygon
  }

  /**
   * constructs the inner band based on the dimensions of the container and
   * the padding to be left on the top-bottom and extra padding on the top-bottom
   * for the right end.
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the top and bottom
   * @param  {Number} rightOuterBandPadding       % padding to be left at the top and bottom on the right end
   * @return {SVGPolygonElement}                  Construcuted SVG Polygon Element as the inner band
   */
  constructInnerPolygon(svgDimensions, bandPercentageHeightPadding, rightOuterBandPadding){
    let self = this

    let bandWidth, bandCount, maxBandHeight, center, innerPolygonPoints

    maxBandHeight = svgDimensions.height * (1 - 2 * bandPercentageHeightPadding)
    bandCount = this.props.data.length
    bandWidth = svgDimensions.width / bandCount
    center = {
      x: 0,
      y: svgDimensions.height / 2,
    }

    innerPolygonPoints = []

    // Insert points for the last edge.
    innerPolygonPoints.splice(innerPolygonPoints.length / 2, 0, {
      x: 0,
      y: svgDimensions.height * (bandPercentageHeightPadding * 1.5),
    })

    innerPolygonPoints.splice(innerPolygonPoints.length / 2 + 1, 0, {
      x: 0,
      y: svgDimensions.height * (1 - (bandPercentageHeightPadding * 1.5)),
    })

    _.forEach(this.props.data, (dataPoint, index) => {
      let currentBandWidth = bandWidth * (index + 1)
      let outerBandHeight = maxBandHeight - ((2 * rightOuterBandPadding * currentBandWidth / svgDimensions.width))

      let innerBandHeight = outerBandHeight * dataPoint[self.props.innerValueKey] / dataPoint[self.props.outerValueKey || self.props.bottomRightValueKey]

      innerPolygonPoints.splice(innerPolygonPoints.length / 2, 0, {
        x: center.x + currentBandWidth,
        y: center.y - innerBandHeight / 2,
      })

      innerPolygonPoints.splice(innerPolygonPoints.length / 2 + 1, 0, {
        x: center.x + currentBandWidth,
        y: center.y + innerBandHeight / 2,
      })
    })

    let animationStartPoints = _.cloneDeep(innerPolygonPoints)

    _.forEach(animationStartPoints, point => point.y = svgDimensions.height / 2)

    let animationEl = FunnelChart.createSVGElement('animate', {
      attributeName: 'points',
      dur: '1500ms',
      begin: '100ms',
      fill: 'freeze',
      keySplines: '0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1; 0.1 0.8 0.2 1',
      keyTimes: '0;0.22;0.33;0.55;0.66;0.88;1',
      calcMode: 'spline',
      to: _.reduce(innerPolygonPoints, (pointString, point) => pointString + (point.x + ',' + point.y + ' '), ''),
    })

    let innerPolygon = this.constructPolygon(animationStartPoints, {
      fill: '#026cac',
      stroke: '#EEEEEE',
      strokeWidth: 1,
    })

    innerPolygon.appendChild(animationEl)

    return innerPolygon
  }

  /**
   * constructs the segment-boundaries based on the dimensions and number of segments needed.
   * @param  {Object}                 svgDimensions dimensions of the outer container
   * @return {Array<SVGPathElement>}  array of the segment vertical boundaries as SVG path elements
   */
  constructPartitions(svgDimensions){
    let partitions = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){
      let xOffset = index * bandWidth
      partitions.push(FunnelChart.createSVGElement('path', {
        d: 'M' + xOffset + ' 0 L' + xOffset + ' ' + svgDimensions.height,
        stroke: '#ECECEC',
        strokeWidth: 1,
      }))
    }

    return partitions
  }

  /**
   * constructes the array of svg elements for the numbers at the center of each segment
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @return {Array<SVGTextElement>}              Array of SVG text elements to render for the numbers
   */
  constructInnerBandValues(svgDimensions){
    if (!this.props.innerValueKey) return []

    let innerBandValues = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){
      let xOffset = (index + 0.5) * bandWidth
      let textEl = FunnelChart.createSVGElement('text', {
        x: xOffset,
        y: svgDimensions.height * 0.5,
        'dominant-baseline': 'central',
        'text-anchor': 'middle',
        'font-size': '24px',
        'fill': '#FFFFFF',
      })

      textEl.textContent = this.props.data[index][this.props.innerValueKey]
      innerBandValues.push(textEl)
    }

    return innerBandValues
  }

  /**
   * constructes the array of svg elements for the labels at the bottom-right of each segment
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the bottom below the text element
   * @return {Array<SVGTextElement>}              Array of SVG text elements to render for the labels
   */
  constructOuterBandLabels(svgDimensions, bandPercentageHeightPadding){
    if (!this.props.bottomRightLabelKey) return []

    let outerBandLabels = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){
      let xOffset = (index + 1) * bandWidth
      let textEl = FunnelChart.createSVGElement('text', {
        x: xOffset - 10,
        y: svgDimensions.height * (1 - bandPercentageHeightPadding * 0.3),
        'dominant-baseline': 'central',
        'text-anchor': 'end',
        'font-size': '11px',
        fill: '#B9BCC8',
      })

      textEl.textContent = this.props.data[index][
        this.props.bottomRightLabelKey
      ]
      outerBandLabels.push(textEl)
    }

    return outerBandLabels
  }

  /**
   * constructes the array of svg elements for the numbers at the bottom-right of each segment
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the bottom below the text element
   * @return {Array<SVGTextElement>}              Array of SVG text elements to render for the numbers
   */
  constructOuterBandValues(svgDimensions, bandPercentageHeightPadding){
    if (!this.props.bottomRightValueKey) return []

    let outerBandLabels = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){
      let xOffset = (index + 1) * bandWidth
      let textEl = FunnelChart.createSVGElement('text', {
        x: xOffset - 10,
        y: svgDimensions.height * (1 - bandPercentageHeightPadding * 0.7),
        'dominant-baseline': 'central',
        'text-anchor': 'end',
        'font-size': '18px',
        fill: '#B9BCC8',
      })

      textEl.textContent = this.props.data[index][
        this.props.bottomRightValueKey
      ]

      outerBandLabels.push(textEl)
    }

    return outerBandLabels
  }

  /**
   * constructes the array of svg elements for the numbers at the bottom-left of each segment
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the bottom below the text element
   * @return {Array<SVGTextElement>}              Array of SVG text elements to render for the numbers
   */
  constructOuterBandSecondaryValues(svgDimensions, bandPercentageHeightPadding){
    if (!this.props.bottomLeftValueKey) return []

    let outerBandLabels = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){

      let xOffset = (index) * bandWidth
      let textEl = FunnelChart.createSVGElement('text', {
        x: xOffset + 5,
        y: svgDimensions.height * (1 - bandPercentageHeightPadding * 0.7),
        'dominant-baseline': 'central',
        'text-anchor': 'start',
        'font-size': '16px',
        fill: '#B9BCC8',
      })

      textEl.textContent = this.props.data[index][
        this.props.bottomLeftValueKey
      ]

      outerBandLabels.push(textEl)
    }

    return outerBandLabels
  }

  /**
   * constructes the array of svg elements for the titles of all the segnments
   * @param  {Object} svgDimensions               dimensions of the outer container
   * @param  {Number} bandPercentageHeightPadding % padding to be left at the top above the text element
   * @return {Array<SVGTextElement>}              Array of SVG text elements to render for the titles
   */
  constructPartitionTitles(svgDimensions, bandPercentageHeightPadding){
    let partitionTitles = []
    let bandCount = this.props.data.length
    let bandWidth = svgDimensions.width / bandCount

    for (let index = 0; index < bandCount; index++){

      let xOffset = index * bandWidth
      let textEl = FunnelChart.createSVGElement('text', {
        x: xOffset + 10,
        y: svgDimensions.height * bandPercentageHeightPadding * 0.65,
        'dominant-baseline': 'central',
        'font-size': '18px',
        'fill': '#878B97',
      })

      textEl.textContent = this.props.data[index][this.props.topLeftLabelKey]
      partitionTitles.push(textEl)
    }

    return partitionTitles
  }

  /**
   * computes the dimensions of the container and creates the
   * SVG elements and glues them together
   */
  renderFunnelChart(){
    const rightOuterBandPadding = 0.1
    const bandPercentageHeightPadding = 0.1

    if (!this.props.data || this.props.data.length === 0) return

    const svgElement = ReactDOM.findDOMNode(this.refs.funnelElement)

    // empty the SVG element for fresh rendering
    while (svgElement.firstChild){
      svgElement.removeChild(svgElement.firstChild)
    }

    // compute dimensions of svg element
    const svgDimensions = this.computeDimensions()

    // construct outer polygon
    svgElement.appendChild(
      this.constructOuterPolygon(
        svgDimensions,
        bandPercentageHeightPadding,
        rightOuterBandPadding
      )
    )

    // construct inner polygon
    svgElement.appendChild(
      this.constructInnerPolygon(
        svgDimensions,
        bandPercentageHeightPadding,
        rightOuterBandPadding
      )
    )

    // render partitions (funnel stages)
    _.forEach(
      this.constructPartitions(svgDimensions),
      (partition) => svgElement.appendChild(partition)
    )

    // render partition (funnel stage) titles
    _.forEach(
      this.constructPartitionTitles(svgDimensions, bandPercentageHeightPadding),
      (partitionTitle) => svgElement.appendChild(partitionTitle)
    )

    // render inner band values
    _.forEach(
      this.constructInnerBandValues(svgDimensions),
      (innerBandValue) => svgElement.appendChild(innerBandValue)
    )

    // render outer band labels
    _.forEach(
      this.constructOuterBandLabels(svgDimensions, bandPercentageHeightPadding),
      (outerBandLabel) => svgElement.appendChild(outerBandLabel)
    )

    // render outer band values
    _.forEach(
      this.constructOuterBandValues(svgDimensions, bandPercentageHeightPadding),
      (outerBandValue) => svgElement.appendChild(outerBandValue)
    )

    // render outer band secondary values
    _.forEach(
      this.constructOuterBandSecondaryValues(svgDimensions, bandPercentageHeightPadding),
      (outerBandValue) => svgElement.appendChild(outerBandValue)
    )
  }

  /**
   * called when the component is mounted.
   * calculates dimensions and renders the component here
   */
  componentDidMount(){
    this.renderFunnelChart()
  }

  /**
   * called when the component is re-rendered after receiving new props
   */
  componentDidUpdate(){
    this.renderFunnelChart()
  }

  render(){
    return(
      <div className='funnel-chart'>
        <svg ref='funnelElement'></svg>
      </div>
    )
  }
}

export default FunnelChart
