/**
 * Given an array of response curves and a function to update form field values, sets the initial field values for a form to 100 for each field in the response curves.
 * @param {Array} featureRecommendations - An array of objects representing recommendations for different features, each with a `key` and a `values` array of objects representing the curve, each with `spend_input` and `marginal_response` properties.
 * @param {Function} setFieldValues - A function that takes an object of field values and updates the state of the form.
 */
export const setInitialFieldValues = (featureRecommendations, setFieldValues) => {
  if (!featureRecommendations) {
    return;
  }

  const fieldValues = featureRecommendations.reduce(
    (obj, curr) => Object.assign(obj, { 
      [curr.key]: { "value": curr['recommended'], "selection":  "recommended", ...curr}
    }),
    {}
  );

  setFieldValues(fieldValues);
};

/**
 * Returns a function that updates the field values with the given key and value.
 * 
 * @param {Object} fieldValues - An object that holds the current field values.
 * @param {function} setFieldValues - A function that sets the field values to a new value.
 * 
 * @returns {function} A function that takes a field name, a key, and a value, and updates the corresponding
 * value in the fieldValues object with the given key and value. If the key is "selection" and the value is
 * not "custom", the function also sets the "value" key in the field to the current value for the selected key.
 */
export const buildUpdateFieldValues = (fieldValues, setFieldValues) => (field, key, value) => {
  const current = fieldValues[field]
  const update = { [key]: value }

  if (key == "value" && current.selection != "custom") {
    // don't allow value to be set unless is custom
    delete update['value'] 
  }
  if (key == "selection" && value != "custom") {
    // set to selections default value
    update['value'] = current[value]
  }

  const future = { [field] : { ...current, ...update }}
    
  setFieldValues(future)
}

