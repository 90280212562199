import React, { Component, useMemo } from 'react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide';

import * as Tree from '../TreeParts';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { filterTree } from '../AttributableOverview/tree';

const cols = [
  "spend_key",
  "transform_tier_1",
  "transform_tier_2",
  "transform_tier_3",
  "transform_tier_4",
  "transform_tier_5",
].map(key => { return { key, display: key} })



export const TreeRow = ({row, defaultOpen = 2, formatter={}, fields=[], pos=0, columns=cols, controlColumn=false, parents=[]}) => {

  const rowWithParents = Object.assign({ parents }, row)
  const RowComponent = (
    <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd"}}>
      <Tree.Column flex={1}>{ row.key }</Tree.Column>
      <Tree.Column flex={1} style={{textAlign:"right"}}>{ row.count }</Tree.Column>
      { fields.map((field) =>
          <Tree.Column flex={1} style={{textAlign:"right"}}>
            { formatter[field] ? formatter[field](row[field]) : row[field] }
          </Tree.Column>
        )
      }
      { fields.length == 0 && <Tree.Column flex={1} style={{marginRight:15*(1/2)*pos}} /> }
      { controlColumn && <Tree.Column flex={1} style={{marginRight:15*(1/2)*pos}}>{ controlColumn(rowWithParents) }</Tree.Column> }
    </Tree.Row>
  )

  return (
    <Tree.Collapsible open={pos < defaultOpen} title={RowComponent} style={{}} useBorder alignOnOpen>
      { row.values && row.values.length > 0 && row.values[0].values ? 
        row.values.map(innerRow => <TreeRow {...{parents: parents.concat([row.key]), columns, defaultOpen, formatter, fields, row: innerRow, pos: pos+1, controlColumn}} />) : 
        row.values[0].row != false ? <IndexGrid {...{cols: columns, data: row.values.map(({row}) => row) }} /> : null
      }
    </Tree.Collapsible>
  )
}

export const sumRecursive = (node) => {
  if (!node.count) node.count = node.values.reduce((p,c) => p + sumRecursive(c), 0 )
  return node.count
}

const sumSet = (field) => (node) => {
  if (!node[field] && node.values && node.values.length > 0) node[field] = [...(new Set(node.values.reduce((p,c) => p.concat(...sumSet(field)(c)),[])))]
  return [node[field]]
}

const TableData = ({ title, rawData, searchValue, children, loading, defaultOpen = 2, headers = [], setColumn = false, formatter={} }) => {

  const nestedData = React.useMemo(() => {

    if (!rawData || rawData.length == 0) return rawData;

    const values = d3.nest()
      .key(row => row.transform_tier_1)
      .key(row => row.transform_tier_2)
      .key(row => row.transform_tier_3)
      .key(row => row.transform_tier_4)
      .key(row => row.transform_tier_5)
      .rollup(values => {
        return values.map(obj => { return {row: obj, count: 1, [setColumn]: obj[setColumn] }} )
      })
      .entries(rawData)

    if (setColumn) values.map(sumSet(setColumn))
    values.map(sumRecursive)

    return values

  }, [rawData])


  const data = React.useMemo(() => {
    return filterTree({key: "", "values":nestedData || []}, searchValue, (text, node) => {
      return node.key && node.key.includes(text)
    })["values"]
  }, [nestedData, searchValue])

  if (nestedData && data && nestedData.length > 0 && data.length == 0) debugger

  return (
  <div style={{marginRight:10,flex:1,paddingTop:10}}>
    { children }
    <div>{ loading && <ContentCard.CardLoader /> }</div>
    {
      !loading && <ContentCard hasTable >
        { 
          headers.length > 0 && <div style={{background:"#eee",fontWeight:"bold"}}>
            <Tree.Row style={{padding:"12px",borderBottom:"1px solid #ddd"}}>
              { 
                headers.map((header,i) => {
                  return <Tree.Column style={{textAlign:i ? "right" : undefined}} flex={1}>{ header }</Tree.Column>
                }) 
              }
            </Tree.Row>
          </div>
        }
        { data.map(row => (<TreeRow {...{defaultOpen, formatter, fields: [setColumn], row}} />)) }
      </ContentCard>
    }
  </div>
  )
}

export default TableData;
