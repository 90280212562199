import React from 'react'
import { Node } from './Node'
import { LineWrap, Line } from './Line'


export const FlowMapContext = React.createContext()

const FlowMapProvider = ({ children }) => {
  const [coordinates, setCoordinates] = React.useState({})
  const updateCoordinates = (key, value) => {
    coordinates[key] = value
    setCoordinates(coordinates)
  }

  // force rerender on window resize
  const [size, setSize] = React.useState([0, 0])
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <FlowMapContext.Provider value={{ coordinates, updateCoordinates, size }}>
      {children}
    </FlowMapContext.Provider>
  )
}


const FlowMapComponent = ({ connections, className, children }) => {
  const { coordinates, size } = React.useContext(FlowMapContext)

  const getEdge = (coord, position) => {
    const { top, left, height, width } = coord
    if (position == 'right')  return { x: left+width,     y: top+(height/2) }
    if (position == 'left')   return { x: left,           y: top+(height/2) }
    if (position == 'top')    return { x: left+(width/2), y: top }
    if (position == 'bottom') return { x: left+(width/2), y: top+height }
  }

  const verticalPositions = ["top", "bottom"]
  const horizontalPositions = ["left", "right"]

  const getLines = () => {
    if (!coordinates || !connections) return []
    return connections.map(section => {
      const { from, to, ...styles } = section
      if (!coordinates[from.id] || !coordinates[to.id]) return {}

      const positions = [from.position, to.position]
      const type = positions.every(x => verticalPositions.includes(x)) ? 'vertical' :
        positions.every(x => horizontalPositions.includes(x)) ? 'horizontal' : 'curve'
      const start = getEdge(coordinates[from.id], from.position)
      const end = getEdge(coordinates[to.id], to.position)
      const startOffset = from.offset || {}
      const endOffset = to.offset || {}
      return {
        x1: start.x + (startOffset.x || 0),
        y1: start.y + (startOffset.y || 0),
        x2: end.x + (endOffset.x || 0),
        y2: end.y + (endOffset.y || 0),
        startColor: from.color,
        endColor: to.color,
        type: type,
        ...styles
      }
    })
  }

  const lines = React.useMemo(() => (
    getLines()
  ), [coordinates, size, children])

  return (
    <div className={`flow-map ${className}`}>
      {children}
      <LineWrap>
        {lines.reverse().map((props, idx) =>
          <Line {...props} />
        )}
      </LineWrap>
    </div>
  )
}


const FlowMap = (props) => (
  <FlowMapProvider>
    <FlowMapComponent {...props} />
  </FlowMapProvider>
)

export default FlowMap
