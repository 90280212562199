import moment from "moment";

export const QUEUED = "queued";
export const SUBMITTED = "submitted";
export const PROCESSING = "processing";

export const runningPeriod = started_at => {
  const start = moment.utc(started_at);
  const now = moment.utc().utcOffset(-5);
  const hour = now.diff(start, "hours");
  const minute = now.diff(start, "minutes") - hour * 60;
  const seconds = now.diff(start, "seconds") - minute * 60 - hour * 60 * 60;
  return [hour, minute, seconds];
};

export const formatLastingTime = runningTime => {
  let timeContent = ` ${runningTime[2]} seconds)`;
  if (runningTime[1] > 0) {
    timeContent = `${runningTime[1]} minutes` + timeContent;
  }
  if (runningTime[0] > 0) {
    timeContent = `${runningTime[0]} hours` + timeContent;
  }

  timeContent = " (" + timeContent;

  return timeContent;
};
