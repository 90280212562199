import { getMetaDataReporting } from "../../../../utils/arrow/reportingMetaData"
import { getDataValidInCache, clearInvalidCache, getDataFromCache, setCacheData } from "../../../../utils/arrow/dbOperations"
import { fetchToParquetBuffer } from "../../../../utils/arrow/performanceData"
import { readParquet } from "../../../../utils/arrow/parquet"
import * as aq from "arquero";
import { getDB } from "../../../../utils/arrow/reportingDBCache";
import { parseArch } from "../../../../utils/arrow/parquetToJson";
import { tableFromIPC, tableToIPC } from "apache-arrow"
import * as reporting from '../../../../utils/arrow/reportingData'

/*
  NOTES:
  getViaCache is also used in DigitalAdvertising/_hooks/reportingData.js and Home/MetricsDashboard/index.js
  !! if migrating getViaCache function in utils/arrow/reportingDataCache make sure the above views are still working !! 
  
  Steps to remove data_reporting_api feature flag check and migrate all users to the /data-reporting endpoint
    1. Remove if statement in datasets.js that uses getViaCacheDataReporting and only use getViaCache (else statement)
    
    2. In utils/arrow/reportingMetaData.js, uncomment getMetaData function that uses /data-reporting endpoint 
       and delete (or comment) getMetaData function that uses /report_cache endpoint
    
       3. In utils/arrow/performanceData.js, uncomment formatDataUrl function that uses /data-reporting endpoint
       and delete (or comment) formatDataUrl function that uses /report_cache endpoint

    3. In utils/arrow/reportingDataCache.js, uncomment .rename code in getDataFromRemote function

    4. If everything is still working in attribution report, digital advertising and metrics dashboard
       this entire file can be safely deleted, and reference to getViaCacheDataReporting in 
       AttributionReport/hooks/datasets.js can be removed
*/

const getAllUniqueColumns = (tables) => {
  const allColumns = tables
    .map((table) => {
      const columnNames = aq.table(table).columnNames()
      return columnNames
    })
    .flat()
  return [...new Set(allColumns)]
}

const getMetaData = (identifier, startDate, endDate, dataset) => {
  const reportName = "spend-mta" 
  const statsBase = `/data-reporting/${reportName}/cache/hash`
  const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${identifier}`

  return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())
    .then(data => data)
}

const formatDataUrl = (date, dataset, identifier) => {
  const dataBase = `/data-reporting/spend-mta/cache`
  const url = `${dataBase}?date=${date}&identifier=${identifier}`

  return url
}

const performance = {
  getAsBuffer: async (date, dataset, identifier, currentCacheId) => {
    const url = formatDataUrl(date, dataset, identifier)
    const promise = fetch(url, { credentials: "include" })
  
    const parquetBuffer = await fetchToParquetBuffer(promise);
    const arrowIPC = await readParquet(parquetBuffer);
  
    return arrowIPC
  }
}

const getDataFromRemote = async ({ date, dataset, identifier, id }) => {
  const dataAPI = (dataset == "compiled_mta_tiers") ? performance : reporting;
  const { getAsBuffer } = dataAPI;

  const _buffer = await getAsBuffer(date, dataset, identifier, id)
  const _data = tableFromIPC(_buffer)

  const arch = aq.fromArrow(_data)
    .derive({
      cache_from: `d => "${date}"`,
      date: `d => "${date}"`
    })
    .rename({ 
      conversions_even: 'even', 
      conversions_first_touch: 'first_touch',
      conversions_last_touch: 'last_touch',
      conversions_normalized: 'normalized',
      ntf_conversions_even: 'ntf_even',
      ntf_conversions_first_touch: 'ntf_first_touch',
      ntf_conversions_last_touch: 'ntf_last_touch',
      ntf_conversions_normalized: 'ntf_normalized',
    })

  const data = arch.toArrow()

  const buffer = tableToIPC(data)

  return { buffer, data, date, dataset, identifier, id }
}

export const getViaCacheDataReporting = async (startDate, endDate, dataset, identifier) => {
  console.time("open-db-"+dataset)
  const db = await getDB()
  console.timeEnd("open-db-"+dataset)
  
  const metaDataAPI = (dataset == "compiled_mta_tiers") ? getMetaData : getMetaDataReporting;

  const requiredDatasets = await metaDataAPI(identifier, startDate, endDate, dataset)

  // check if we have appropriate cached datasets
  const cacheCheckPromises = requiredDatasets.map(requirement => {
    const {date, id} = requirement
    return getDataValidInCache(db)(date, dataset, identifier, id)
  })

  const cacheState = await Promise.all(cacheCheckPromises)

  const hasCache = cacheState.filter(row => row.hasCache)
  const missedCache = cacheState.filter(row => !row.hasCache)
  const invalidCache = missedCache.filter(row => row.cache_id)

  console.time(dataset+"-read-from-cache")
  const cleared = await Promise.all(invalidCache.map(clearInvalidCache(db)))
  const dataFromCache = await Promise.all(hasCache.map(getDataFromCache(db)))
  const dataFromRemote = await Promise.all(missedCache.map(getDataFromRemote))
  const cached = await Promise.all(dataFromRemote.map(setCacheData(db)))
  console.timeEnd(dataset + "-read-from-cache")


  const data = [...dataFromCache, ...dataFromRemote]
    .map(row => aq.fromArrow(row['data']))
  const columns = getAllUniqueColumns(data).reduce((acc, col) => ({...acc, [col]: []}), {})
  const emptyTable = aq.table(columns)

  try {
    const _arch = emptyTable.concat(...data)
    const _data = [...dataFromCache, ...dataFromRemote].length ? [...dataFromCache, ...dataFromRemote][0]['data'] : {};
    return parseArch(_arch, _data)

  } catch(e) {
    console.log("error pulling data from cache")
    return aq.from([])
  }

}
