import React, { Component } from 'react';
import { Label, Checkbox, Table, Form, Button, Accordion, Icon, Grid, Dropdown, Message, Segment } from 'semantic-ui-react';
import CacheContext from '../../utils/CacheContext.js'
import { getSegments, getDataset, getTiersAsOptions } from './../../utils/api';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { IndexGrid } from '@rockerbox/styleguide';
import { CostCard, EventCard, PromoCodeCard } from './cards';
import { FieldPreview, NestedFieldPreview } from '../Survey/SetupGeneral';
import styled from 'styled-components';

const DEFAULT_FUNC = () => () => {}
const DEFAULT_CODE_OPTIONS = []
const DEFAULT_OPTION = {values: []}
const TIERS = ["tier_1", "tier_2", "tier_3", "tier_4","tier_5"]

const StyledSegment = styled(Segment)`
  font-size:.95rem
`
const StyledButton = styled(Button)`
  line-height:18px !important
`

const ValueField = React.memo(({ row, value, keyName, updateRowKey, updateKey, tierOptions, macroOptions, pos }) => {

  const key = keyName;
  const placeholder = keyName;
  const style = {width: 168}
  const dropdownStyle = Object.assign({}, style, {backgroundColor: "#fcfff5 !important" })

  const defaultType = (value && value[0] == "{") ? 
    "code" : (tierOptions.find(row => row.value == value)) ? 
    "tags" : (!value && tierOptions.length) ? 
    "tags" : "text"

  const [inputType, setInputType] = React.useState(defaultType);
  React.useEffect(() => {
    setInputType(defaultType)
  }, [defaultType])

  const onChange = updateRowKey(row, key)

  return <>
    <Button.Group size="mini">
      <StyledButton active={inputType == "tags"} as='a' icon='tags' onClick={() => setInputType("tags")} />
      <StyledButton active={inputType == "text"} as='a' icon='quote left' onClick={() => setInputType("text")} />
      <StyledButton active={inputType == "code"} as='a' icon='code' onClick={() => setInputType("code")} />
    </Button.Group>
    { inputType == "tags" && <Form.Dropdown selection {...{placeholder, style: dropdownStyle, value, onChange, options: tierOptions}} /> }
    { inputType == "text" && <Form.Input {...{placeholder, style, value, onChange}} /> }
    { inputType == "code" && <Form.Dropdown selection {...{placeholder, style: dropdownStyle, value, onChange, options: macroOptions}} /> }
  </>
})

const EditRow = React.memo(({ row, i, pos, updateRowKey, value, tierOptions, macroOptions = DEFAULT_CODE_OPTIONS }) => {
  return <Form.Group key={i}>
    <Label style={{lineHeight:1.8}}>{ `Tier ${pos}` }</Label>
    <ValueField keyName={`tier_${pos}`} {...{row, updateRowKey, value, tierOptions, macroOptions, pos}} />
  </Form.Group>
})

const TierCreation = React.memo(({ tiersOptions, row, onUpdate, WrapperComponent = false, macroOptions: DEFAULT_CODE_OPTIONS, tier1Empty }) => {

  const tiersVisible = React.useMemo(() => {
    const filledTier = TIERS.reduce((p,c) => (row[c]||"").length ? c : p, "")
    const index = TIERS.indexOf(filledTier) + 1
    return (index < TIERS.length) ? TIERS[index] : "tier_5"

  }, [JSON.stringify(row)])

  const selectedTiers = React.useMemo(() => {
    const index = TIERS.indexOf(tiersVisible)
    return TIERS.slice(0, index+1)
  }, [tiersVisible])

  const Wrapper = WrapperComponent || StyledSegment

  const optionsByTier = {
    tier_1: tiersOptions
  }

  const updateRowKey = (row, tier) => (evt, { value }) => {
    const index = TIERS.indexOf(tier)
    const clearUpdates = TIERS.slice(index, TIERS.length)
      .reduce((p, c) => {
        p[c] = ""
        return p
      }, {})

    onUpdate(Object.assign({}, row, clearUpdates, {[tier]: value}))
  }

  return <Wrapper secondary key={row.id || row.new_id || 1}>
    { 
      selectedTiers.map((tier, i) => {
        const pos = i +1
        const nextTier = selectedTiers[i+1]
        const value = row[tier]
        const options = optionsByTier[tier] || []
        if (value) {
          const selectedOption = options.find(row => row.value == value) || DEFAULT_OPTION
          const nextOptions = selectedOption.values || []
          optionsByTier[nextTier] = nextOptions.filter(({ value }) => value.trim().length > 0)
        }
        return <EditRow key={pos} {...{ row, i, pos, updateRowKey, value, tierOptions: options, macroOptions}} />
      })
    }
    { tier1Empty && <Message negative compact><p>Please add a tier_1 value before submitting</p></Message>}
  </Wrapper>
})

const macroOptions = [
  {text: "Promo Code", value: "{conversion_field_value}"},
  {text: "Sponsorship Name", value: "{name}" }
]

const ExampleEvents = ({ showExampleOnly, promo_codes, displayRows, segment_id, promo_field, name }) => {

  const TIERS = ["tier_1", "tier_2", "tier_3", "tier_4", "tier_5"]
  const [data, setData] = React.useState([])

  const uniquePromoCodes = React.useMemo(() => {
    const promoValues = data.map(row => row[promo_field])
    return new Set(promoValues)
  }, [])

  const firstDisplay = displayRows[0]
  const macroFields = TIERS.filter(tier => ["{conversion_field_value}", "{name}"].includes(firstDisplay[tier]))
  const hasMacros = macroFields.length > 0

  const exampleDisplay = React.useMemo(() => {
    const row = Object.assign({}, firstDisplay)
    macroFields.map(field => {
      if (row[field] === "{name}") row[field] = "{ Sponsorship Name }"
      if (row[field] === "{conversion_field_value}") row[field] = "{ Promo Code }"
    })
    return row
  }, [firstDisplay])

  const items = (!hasMacros || promo_codes.length == 0) ? [exampleDisplay] :
    promo_codes
      .map(code => {
        const row = Object.assign({}, firstDisplay)
        macroFields.map(field => {
          const macroName = row[field].slice(1,-1)
          row[field] = macroName === "name" ? name : code[macroName]
        })
        return row
      })

  if (!showExampleOnly) {
    const columns = IndexGrid.arrayToColumns(TIERS)
    return <IndexGrid cols={columns} data={items} />
  }

  const columns = IndexGrid.arrayToColumns(["tier", ...items.map((item,i) => `Example ${i+1}`)])
  
  const tierData = TIERS.map(tier => {
    const row = { tier }
    items.map((item, i) => {
      row["Example " + (i+1)] = item[tier]
    })
    return row
  })

  return <IndexGrid cols={columns} data={tierData} />
}

const SetupDisplay = ({ state, setState, WrapperComponent = false, skipLayout = false, showExampleOnly = false, tier1Empty }) => {

  const { name, display, promo_field } = state
  const { promo_codes, segments } = state;
  const [ firstSegment, ...otherSegments] = segments;
  const { segment_id } = firstSegment || {};

  const [showExamples, setShowExamples] = React.useState(false)
  const [contextState, setContextState, Context] = React.useContext(CacheContext);
  const tiersOptions = segment_id ? (Context.getCache(`tiers${segment_id}`, getTiersAsOptions, segment_id) || false) : false

  const displayRows = display.filter(row => row).length > 0 ? display : [{}];

  const onUpdate = React.useMemo(() => (row) => {
    const index = display.map(evt => evt.id || evt.new_id).indexOf(row.id || row.new_id)
    const newDisplay = _.cloneDeep(display)
    newDisplay.splice(index, 1, row)
    setState({ display: newDisplay })
  },[])

  const Content = <>
      { !showExampleOnly && <>
          { displayRows.map((row, pos) => <TierCreation key={pos} {...{ WrapperComponent, state, row, tiersOptions, onUpdate, macroOptions, tier1Empty }} /> ) }
          <Checkbox label="Show Example Events" onClick={() => setShowExamples(!showExamples)} checked={showExamples} />
          <br />
          <br />
        </>
      }
      { (showExampleOnly || showExamples) && <>
          <ExampleEvents {...{showExampleOnly, displayRows, segment_id, promo_codes, promo_field, name }} />
          { !showExampleOnly && <><br /><br /></> }
        </>
      }
  </>

  if (skipLayout) return Content

  return <ColumnLayout leftWidth={4} centerWidth={8} rightWidth={4} >
    <ContentCard>
      <Message
        header={`How do you want to display ${name} as a marketing channel?`}
        content={`Now that we have the marketing and spend associated with ${name}, we can define how we want to organize this data`}
      />
      { Content }
    </ContentCard>
  </ColumnLayout>
}

export const SetupDisplaySelector = (props) => {

  const { showExampleOnly, segment_id, onChange } = props
  const [ display, setDisplay ] = React.useState(props.value)
  const [contextState, setContextState, Context] = React.useContext(CacheContext);

  React.useEffect(() => setDisplay(props.value), [props.value])

  const segments = segment_id ? [{ segment_id }] : (Context.getCache(`segments`, getSegments) || []) 
    .filter(row => row.featured)
    .map(row => Object.assign({ segment_id: row.action_id }))

  const setState = (state) => setDisplay(state.display[0])
  

  React.useEffect(() => {
    onChange(false, { value: display })
  }, [display])

  return <SetupDisplay state={{segments, promo_codes: [] , display: [display] }} skipLayout {...{setState, showExampleOnly}} WrapperComponent={React.Fragment} />
}

export default SetupDisplay;
