import React, { Component, useEffect } from 'react';
import { mock, getAllowTransformTableTemplates, saveAllowTransformTableTemplates } from '../../utils/api';
import GenericSetting from './GenericSetting';
import EventDataSources from '../DataSources/EventDataSources';
import GenericToggle from './GenericToggle';

const options = [
  {text:"Enable Transform Table Templates", value: 1},
]

const getOptions = mock(options)

const AllowTransformTableTemplates = (props) => {
  const title = "Enable Transform Table Templates";
  const key_name = "enabled";
  const wrapper = React.Fragment
  const d = {
    wrapper, title, key_name, getOptions, 
    get: getAllowTransformTableTemplates, 
    save: saveAllowTransformTableTemplates
  }

  return (
      <GenericToggle {...d}/>
  )
}

export default AllowTransformTableTemplates;
