import React, { useEffect, useState } from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { tier1Atom, anyAtom, firstAtom, midAtom, lastAtom, saveIDAtom } from '../../atoms'
import { useAtom } from 'jotai'
import { isJsonString } from '../../utils/valueFormatter'

const FilterWrapper = ({
  loading, collapseKey, advancedFilters,
  other, date, stickyRight, stickyStyles,
}) => {
  const [tier_1] = useAtom(tier1Atom)
  const [any] = useAtom(anyAtom)
  const [first] = useAtom(firstAtom)
  const [mid] = useAtom(midAtom)
  const [last] = useAtom(lastAtom)
  const [saveID] = useAtom(saveIDAtom)
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (!collapseKey) return
    setShowFilters(false)
  }, [collapseKey])

  const openCloseFilters = () => {
    showFilters ? setShowFilters(false) : setShowFilters(true);
  }
  
  useEffect(() => {
    if (tier_1.length > 0 || isJsonString(any) || isJsonString(first) || isJsonString(mid) || isJsonString(last)) {
      setShowFilters(true)
    }
  }, [saveID])
  
  return (
    <Segment className='filter-wrap-v3'>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {other && other}
          {date && date}
          {advancedFilters && <div
            style={{ paddingLeft: '20px', color: '#475ddc', marginTop: '15px' }}
            onClick={() => {
              if (!loading) {
                openCloseFilters();
              }
            }}
          >
            <span style={{ cursor: 'pointer' }}>Advanced Filters</span>
            <Icon
              link
              name={showFilters ? 'angle up' : 'angle down'}
              style={{ float: 'right', color: '#475ddc' }}
            />
          </div>}
        </div>
        {stickyRight && <div style={stickyStyles || { display: 'flex', alignItems: 'flex-end' }}>
          {stickyRight}
        </div>}
      </div>
      {showFilters && advancedFilters && (
        <div style={{ paddingTop: '10px' }}>
          {advancedFilters}
        </div>
      )}
    </Segment>
  );
}

export default FilterWrapper
