import React from 'react'
import moment from 'moment'
import { Loader } from 'semantic-ui-react'
import { FlowMap, Funnel } from '@rockerbox/styleguide'
import { getIntegrationFeatures, getSegments, getShopifyInfo, getBatchConfigData, getAuths, hasAction } from '../../utils/api'
import { CacheContext } from '../../utils/CacheContext'
import { DataSourceCard, DataTypeCard, DataProcessingCard, MarketingAnalysisCard, MarketingFunnelStep, ViewReportsButton } from './parts'
import { DATA_TYPES, DATA_PROCESSORS, DATA_ANALYSIS, FUNNEL_STEPS, CONNECTIONS } from './dataMap'
import { IntegrationDisplayName } from '../Integrations/parts'
import { track } from '../../utils/tracking'

const DEFAULT_INTEGRATIONS = { integrations: [] }
const SUPPORTED_INTEGRATIONS = ['shopify', 'adwords', 'facebook']
const DEFAULT_DATA_SOURCES = [
  { key: 'rockerbox' },
]


const DataMap = ({ setActivePane }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const segments = Context.getCache(`segments`, getSegments) || []
  const { integrations } = Context.getCache(`integrationFeatures`, getIntegrationFeatures) || DEFAULT_INTEGRATIONS
  const shopifyInfo = Context.getCache(`shopifyInfo`, getShopifyInfo) || {}
  const batchConfigs = Context.getCache(`batchConfigs`, getBatchConfigData) || []
  const authorizations = Context.getCache(`authorizations`, getAuths) || [] // caching, not used here
  const [hasPixelData, setHasPixelData] = React.useState(false)

  // check if just installed or has pixel data
  React.useEffect(() => {
    const yesterday = moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD')
    hasAction(yesterday, yesterday)
      .then(data => {
        const val = !!data.filter(({ view }) => !!view).length
        setHasPixelData(val)
      })
    track('view.dashboard.data_map')
  }, [])

  // generate params for url macros
  const params = React.useMemo(() => Object({
    purchase: shopifyInfo.purchase,
    addToCart: shopifyInfo.add_to_cart,
    viewProduct: shopifyInfo.view_product,
    allPages: (segments.find(({ touchpoints }) => !!touchpoints) || {}).action_id,
    startDate: moment.utc().utcOffset(-5).subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment.utc().utcOffset(-5).subtract(1, 'days').format('YYYY-MM-DD'),
    hasPixelData: hasPixelData,
    ordersReport: (batchConfigs.find(({ name }) => name == "Shopify Orders") || {}).report_id,
    eventsReport: (batchConfigs.find(({ name }) => name == "Shopify Customer Journey") || {}).report_id,
  }), [segments, shopifyInfo, batchConfigs, hasPixelData])

  // generate data sources list for left-most column
  const dataSources = React.useMemo(() => {
    const filteredIntegrations = integrations.filter(({ key }) => SUPPORTED_INTEGRATIONS.includes(key))
    return [...DEFAULT_DATA_SOURCES, ...filteredIntegrations]
  }, [integrations])


  const loading = !integrations.length
  if (loading) return <Loader />

  return (<>
    <div className='data-map-headings'>
      <div className='column-header'>Data Sources</div>
      <div className='column-header'>Processing</div>
      <div className='column-header'>Analysis</div>
      <div className='column-header'>Marketing Insights</div>
    </div>
    <FlowMap connections={CONNECTIONS} className='data-map'>
      <div className='column data-source'>
        {dataSources.map(({ key }) =>
          <FlowMap.Node id={key} className='node data-source'>
            <DataSourceCard platform={key} />
          </FlowMap.Node>
        )}
      </div>

      <div className='column data-type'>
        {DATA_TYPES.map(({ id, image, title, link }) =>
          <FlowMap.Node id={id} className='node data-type'>
            <DataTypeCard {...{ image, title, link, params }} />
          </FlowMap.Node>
        )}
      </div>

      <div className='column data-processing'>
        {DATA_PROCESSORS.map(({ image, title, link }) =>
          <DataProcessingCard {...{ image, title, link, params }} />
        )}
      </div>

      <div className='column data-analysis'>
        <FlowMap.Node className='node data-analysis' id='analysis'>
          <div className='analysis-container'>
            {DATA_ANALYSIS.map(({ id, image, title, link }) =>
              <FlowMap.Node id={id} className='analysis-box'>
                <MarketingAnalysisCard {...{ image, title, link, params }} />
              </FlowMap.Node>
            )}
          </div>
        </FlowMap.Node>
      </div>

      <div className='column data-funnel'>
        <FlowMap.Node className='node data-funnel' id='funnel'>
          <Funnel>
            {FUNNEL_STEPS.map(({ id, image, text, link }) =>
              <MarketingFunnelStep {...{ id, image, text, link, params }} />
            )}
          </Funnel>
          <ViewReportsButton {...{setActivePane}} />
        </FlowMap.Node>
      </div>
    </FlowMap>
  </>)
}

export default DataMap
