export const REPORTS = [
  {
    id: 'report-paths',
    icon: 'road',
    color: '#026CAC',
    title: "Paths to Conversion",
    description: "What are the most common series of marketing touchpoints driving purchases?",
    link: '/v2/customers/path/view/${segment}/${startDate}/${endDate}',
  },
  {
    id: 'report-time',
    icon: 'clock outline',
    color: '#026CAC',
    title: "Time to Conversion",
    description: "How long does it take for people to purchase after engaging with my marketing?",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/time_to_conversion/even/0/0/tier_1',
  },
  {
    id: 'report-device',
    icon: 'mobile alternate',
    color: '#026CAC',
    title: "Cross-Device",
    description: "Which device types are strongly correlated with a marketing channel?",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/cross_device/even/0/0/tier_1',
  },
  {
    id: 'report-baseline',
    icon: 'chart line',
    color: '#7C78DC',
    title: "Baseline Comparison",
    description: "Which of my campaigns are outperforming or struggling, compared to the average?",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/baseline/even/0/0/tier_1',
  },
  {
    id: 'report-ntf',
    icon: 'users',
    color: '#7C78DC',
    title: "New vs. Returning",
    description: "How does marketing engagement differ for new users vs. repeat customers?",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/ntf/even/0/0/tier_1',
  },
  {
    id: 'report-model',
    icon: 'balance scale',
    color: '#7C78DC',
    title: "Model Comparison",
    description: "What does my marketing credit allocation look like with different attribution models?",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/report_comparison/even/0/0/tier_1',
  },
]

export const AGGREGATIONS = [
  {
    id: 'agg-mta',
    icon: 'table',
    color: '#FFA278',
    title: "Log-Level MTA",
    description: "A detailed log of every marketing event that each user interacted with before converting",
    link: '/v2/customers/converters/view/customers/${segment}/${startDate}/${endDate}',
    className: 'agg-mta',
  },
  {
    id: 'agg-tiers',
    icon: 'chart pie',
    color: '#E76D8E',
    title: "Buckets Breakdown",
    description: "A high-level report showing you each marketing channels' attributed conversions by day",
    link: '/v2/reporting/conversion/view/${segment}/${startDate}/${endDate}/report/even/0/0/tier_1',
    className: 'agg-tiers',
  },
]

export const DATASETS = [
  {
    id: 'data-conversions',
    icon: 'cart',
    color: '#66D9AE',
    title: "Conversion Data",
    description: "Deduplicated conversions and customer data",
    link: '/v2/settings/datasets/view/Order%20Data',
  },
  {
    id: 'data-events',
    icon: 'mouse pointer',
    color: '#66D9AE',
    title: "Marketing Events",
    description: "All touchpoints organized into your marketing hierarchy",
    link: '/v2/channels/overview/view',
  },
  {
    id: 'data-spend',
    icon: 'money bill alternate',
    color: '#66D9AE',
    title: "Spend Data",
    description: "Aggregated data from each of your advertising accounts",
    link: '/v2/settings/datasets/view/Spend%20Data',
  },
]

export const CONNECTIONS = [
  // source -> datasets
  {
    from: {
      id: "source-orders",
      position: "top",
      color: "#66D9AE",
    },
    to: {
      id: "data-conversions",
      position: "bottom",
      color: "#66D9AE",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 5,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "source-marketing",
      position: "top",
      color: "#66D9AE",
    },
    to: {
      id: "data-events",
      position: "bottom",
      color: "#66D9AE",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 5,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "source-spend",
      position: "top",
      color: "#66D9AE",
    },
    to: {
      id: "data-spend",
      position: "bottom",
      color: "#66D9AE",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 5,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },

  // datasets -> aggs
  {
    from: {
      id: "data-conversions",
      position: "top",
      color: "#FFA278",
    },
    to: {
      id: "agg-mta",
      position: "bottom",
      color: "#FFA278",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "data-events",
      position: "top",
      color: "#FFA278",
    },
    to: {
      id: "agg-mta",
      position: "bottom",
      color: "#FFA278",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-mta",
      position: "right",
      color: "#E76D8E",
    },
    to: {
      id: "agg-tiers",
      position: "bottom",
      color: "#E76D8E",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "data-spend",
      position: "top",
      color: "#E76D8E",
    },
    to: {
      id: "agg-tiers",
      position: "bottom",
      color: "#E76D8E",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },

  // aggs -> reports
  {
    from: {
      id: "agg-mta",
      position: "top",
      color: "#026CAC",
    },
    to: {
      id: "report-paths",
      position: "bottom",
      color: "#026CAC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-mta",
      position: "top",
      color: "#026CAC",
    },
    to: {
      id: "report-time",
      position: "bottom",
      color: "#026CAC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-mta",
      position: "top",
      color: "#026CAC",
    },
    to: {
      id: "report-device",
      position: "bottom",
      color: "#026CAC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-tiers",
      position: "top",
      color: "#7C78DC",
    },
    to: {
      id: "report-baseline",
      position: "bottom",
      color: "#7C78DC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-tiers",
      position: "top",
      color: "#7C78DC",
    },
    to: {
      id: "report-ntf",
      position: "bottom",
      color: "#7C78DC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
  {
    from: {
      id: "agg-tiers",
      position: "top",
      color: "#7C78DC",
    },
    to: {
      id: "report-model",
      position: "bottom",
      color: "#7C78DC",
    },
    width: 15,
    opacity: 25,
    animation: {
      speed: 10,
      numDots: 2,
      dotWidth: 10,
      dotOpacity: 25,
    },
  },
]
