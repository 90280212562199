import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { get, currencyFormat, currency, percent, number, defaultNumber } from './helpers';

const Total = ({cohortKeys, keys, values, months, accessor, formatter, useMultiplier=true, multiplier=1, finalType='sum'}) => {
  const format = formatter || defaultNumber;

  const cohortValues = cohortKeys.reduce((p,k) => {
    return p + (((values[k]||{})["cohort"]||{})[accessor]||0)
  },0)
  const monthlyValues = months.map(date => 
    keys.reduce((p,k) => {
      return p + ((values[k][date]||{})[accessor]||0)
    },0)
  )
  
  const mult = useMultiplier ? multiplier : 1;
  

  return (
    <React.Fragment>
      <Column>{ format(cohortValues*mult) }</Column>
      { monthlyValues.map(v => ( <Column>{ format(v*mult) }</Column>) ) }
      { finalType == "sum" ? 
          <Column bold>{ format(mult*(d3.sum(monthlyValues) + cohortValues)) }</Column> :
          <Column bold>{ format(mult*(cohortValues)) }</Column>
      }
    </React.Fragment>
  )
}

export default Total;
