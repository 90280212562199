import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import styled from "styled-components";

const DatePickerWrapper = styled.div`
  & .DateRangePicker {
      font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  
  & .DateInput.DateInput_1 { }
  
  & .DateRangePicker_picker_1 {
      z-index: 100;
  }
  
  & .DateInput_input {
    font-size:inherit;
    padding: 5px;
    line-height:inherit;
  }
  & .DateInput_input__focused {
      border-bottom: 2px solid #026CAC;
  }
  
  & .CalendarDay__selected_span {
      background: #138FC9;
      border: 1px double #138FC9;
      color: #fff;
  }
  
  & .CalendarDay__selected_span:hover {
      background: #6CB9DD;
      border: 1px double #6CB9DD;
      color: #fff;
  }
  
  & .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: #0C597E;
      border: 1px double #0C597E;
      color: #fff;
  }
  
  & .CalendarDay__selected_start {
      background: #0C597E;
      border: 1px double #0C597E;
      color: #fff;
  }
  
  & .CalendarMonth_table {
      margin-top: 5px;
  }
`

const FocusedDatePicker = (props) => {

  const [focused, setFocused] = React.useState(props.defaultFocus)
  const onFocusChange = ({ focused }) => setFocused(focused)

  return <DatePickerWrapper>
    <SingleDatePicker isOutsideRange={() => false} {...props} {...{focused, onFocusChange}} />
  </DatePickerWrapper>
}

export default FocusedDatePicker;
