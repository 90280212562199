import axios from 'axios';

/**
 *
 * @param clientUrl
 * @returns {Promise<any>}
 */
export function postSnowflakeParseURL(clientUrl) {
  const url = `/datalake/snowflake/parse_url`;
  const obj = {
    "snowflake_url": clientUrl
  }
  return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
    .then(response => response.json())
}

/**
 *
 * @param account_id
 * @param cloud
 * @param region
 * @param client_url
 * @returns {PromiseLike<any> | Promise<any>}
 */
export function postSnowflakeInit(account_id, cloud, region, client_url) {
  const url = `/datalake/snowflake/setup/initialize`;
  const obj = {
    "client_account_id": account_id,
    "client_cloud": cloud,
    "client_snowflake_region": region,
    "client_url": client_url
  }
  return axios.post(url, obj)
    .then(resp => resp);
}

/**
 *
 * @param selectedReport
 * @param tableName
 * @param identifier [OPT]
 * @returns {PromiseLike<any> | Promise<any>}
 */
export function postSnowflakeTable(selectedReport, tableName, identifier = '') {
  const url = `/datalake/snowflake/table`;
  const obj = {
    "report": selectedReport,
    "identifier": identifier,
    "table_name": tableName
  }
  return axios.post(url, obj)
    .then(resp => resp);
}

/**
 *
 * @param region
 * @returns {PromiseLike<any> | Promise<any>}
 */
 export function postRedshiftInit(region) {
  const url = `/datalake/redshift/setup/initialize`;
  const obj = {
    "client_redshift_region": region,
  }
  return axios.post(url, obj)
    .then(resp => resp);
}

/**
 *
 * @param iamRoleArn
 * @returns {PromiseLike<any> | Promise<any>}
 */
 export function postRedshiftGrantPermission(iamRoleArn) {
  const url = `/datalake/redshift/setup/grant_permission`;
  const obj = {
    "client_iam_role": iamRoleArn,
  }
  return axios.post(url, obj)
    .then(resp => resp);
}

export function postRedshiftTable(selectedReport, tableName, identifier = '') {
  const url = `/datalake/redshift/table`;
  const obj = {
    "report": selectedReport,
    "identifier": identifier,
    "table_name": tableName
  }
  return axios.post(url, obj)
    .then(resp => resp);
}

/**
 *
 * @returns {PromiseLike<any> | Promise<any>}
 */
export function getDatalakeReports() {
  const url = `/datalake/schemas/`;
  return axios.get(url)
    .then(resp => resp);
}

/**
 *
 * @returns {PromiseLike<any> | Promise<any>}
 */
export function getDatalakeTables() {
  const url = `/datalake/table`;
  return axios.get(url)
    .then(resp => resp);
}

export function getDatalakeConnectionByAdvertiser() {
  const url = `/datalake/connection`;
  return axios.get(url)
    .then(resp => resp);
}

// BigQuery
export const postGCPSetup = ({ region, projectId, datasetId }) => {
  return axios.post('/datalake/bigquery/setup/initialize', {
    region,
    project_id: projectId,
    dataset_id: datasetId,
  });
}

export const postEmailToPermissions = (email, entity_type) => {
  return axios.post('/datalake/bigquery/setup/permissions', {
    email,
    entity_type
  });
}

export const getPermissionsList = (role) => {
  return axios.get(`/datalake/bigquery/setup/permissions?role=${role}`);
}

/**
 * TODO: Since the platform has been renamed in the API to: gcp, we should rename the route
 * */
export const postBigQueryTable = (report_name, table_name, identifier = '') => {
  return axios.post('/datalake/bigquery/table', {
    table_name,
    report_name,
    identifier,
  });
}
