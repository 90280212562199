import React, { Component } from 'react';
import { Statistic, Dropdown, Button, Modal, Table } from 'semantic-ui-react';
import { ContentCard } from '@rockerbox/styleguide'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import SummaryBar from '../../ViewAttribution/SummaryBar';
import * as d3 from 'rockerbox_d3_legacy_clone';

import TimeRange, { yesterday, month } from '../../timerange';
import { getFacebookData } from '../../../utils/api';

const InlineDropdown = (props) => {
  return (
    <Dropdown {...props} inline preventFluid={true}/>
  )
}
const percentFormatter = new Intl.NumberFormat(
  'en-US', 
  {style: 'percent',minimumFractionDigits: 2}
);

const aggregations = {
  "device": [
    {"header":"Device", "field":"index"}
  ],
  "region": [
    {"header":"Region", "field":"index"}
  ],
  "adset": [
    {"header":"Campaign", "field":"campaign_name"},
    {"header":"Adset", "field":"adset_name"},
    {"header":"Id", "field":"ad_id"}
  ]
}


class FacebookComparison extends TimeRange {

  state = {
    startDate: month,
    endDate: yesterday,
    agg: "adset"
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { agg } = this.props.match.params;
    if (!agg) {
      return this.setRouterField("agg")(false, {"value": "adset"})
    }
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props;
    const { agg } = this.props.match.params;

    if (id != prevProps.id) this.getData()
    if (agg != prevProps.match.params.agg) {
      this.setState({ response: [] })
      this.getData()
    }
  }

  async getData() {
    const { startDate, endDate } = this.state;
    const { id } = this.props;
    const { agg } = this.props.match.params;
    if (id && agg) {
      const dateRange = d3.time.scale()
        .domain([moment(startDate), moment(endDate)])
        .ticks(d3.time.days, 1)
        .map(c => moment(c).format('YYYY-MM-DD'))

      const resp = await getFacebookData( agg, startDate, endDate, id )
      const columns = aggregations[agg].map(row => row.field)
      const filtered = resp.filter(row => row[columns.slice(-1)[0]] )

      const response = d3.nest()
        .key(row => columns.map(k => row[k]).join("||") )
        .rollup(values => {
          const mappedValues = d3.nest()
            .key(row => row.date)
            .map(values)

          const filledValues = dateRange.map(date => {
            return (mappedValues[date] && mappedValues[date][0]) || { date }
          })
          
          return filledValues
        })
        .entries(filtered)
        .map(row => {
          const splitted = row.key.split("||")
            .map((k,i) => {
              row[columns[i]] = k
            })
          
          row['fb_total'] = row.values.reduce((p,c) => p + (c.fb||0), 0)
          row['rb_total'] = row.values.reduce((p,c) => p + (c.rb||0), 0)
          row['rb_percent'] = row['rb_total']/row['fb_total']
          return row
        })
        .sort((p,c) => d3.descending(p.fb_total,c.fb_total))

      this.setState({ response })
    }
  }

  setDropdownField = (name) => (event, { value }) => {
    this.setState({ [name] : value })
  }

  setRouterField = (name) => (event, { value }) => {
    const { match } = this.props;
    const { path } = match;
    const _fields = path.split("/").filter(row => row[0] == ":");
    const params = _fields
      .reduce((p, row) => {
        const c = row.slice(1, row.slice(-1) == "?" ? -1 : row.length) 
        p[row] = c == name ? value : match.params[c]
        return p
      },{})

    const newPath = Object.keys(params)
      .reduce((p,c) => p.replace(c, params[c]), path)
    
    this.props.history.push(newPath)
  }

  render() {

    const { response } = this.state;
    const { agg } = this.props.match.params;

    if (!agg) return null;

    return (
      <React.Fragment>
        <ContentCard
          title={"Facebook Synthetic Matches"}
          topRight={ this.renderDateSelector() }
          borderless
          hasTable
          disableHeaderEllipse
        >
        <p style={{marginLeft:"15px"}}>
          Aggregated By: <Dropdown 
            onChange={this.setRouterField("agg")}
            value={agg}
            options={Object.keys(aggregations).map(value=> { return { value, text: value} })}
            selection
	  />
        </p>
        <Table striped>
          <Table.Header>
            <Table.Row>
              { aggregations[agg].map(row => {
                  return (<Table.HeaderCell width='1'>{ row.header }</Table.HeaderCell>)
                })
              }
              <Table.HeaderCell colspan='2'width='2'>Post View Conversions</Table.HeaderCell>
              <Table.HeaderCell width='2'></Table.HeaderCell>
              <Table.HeaderCell width='1'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { response && response.map(ComparsionRow(agg)) }
          </Table.Body>
        </Table>
        </ContentCard>
      </React.Fragment>
    )
  }
}



const ComparsionRow = (agg) => (c) => (
  <React.Fragment>
    <Table.Row>
      { aggregations[agg].map(row => {
          return (<Table.Cell rowSpan='2'>{ c[row.field] }</Table.Cell>)
        })
      }
      <Table.Cell>Rockerbox</Table.Cell>
      <Table.Cell>{ c.rb_total }</Table.Cell>
      <Table.Cell rowSpan='2'>
        <div style={{width:"500px",height:"100px"}}>
        { <SummaryBar height="100" data={c.values} exclude={["percent_diff","adset_id","adset_name","campaign_name","index"]} /> }
        </div>
      </Table.Cell>
      <Table.Cell rowSpan='2'>
        <Statistic size='small'>
          <Statistic.Value>{ percentFormatter.format(c.rb_percent) }</Statistic.Value>
          <Statistic.Label>Allocated</Statistic.Label>
        </Statistic>
      </Table.Cell>

    </Table.Row>
    <Table.Row >
      <Table.Cell>Facebook</Table.Cell>
      <Table.Cell>{ c.fb_total }</Table.Cell>
    </Table.Row>
  </React.Fragment>
)

export default withRouter(FacebookComparison);
