import React, { useState } from 'react'
import {
  Sidebar,
  Segment,
  Icon,
  Header,
  Loader,
} from 'semantic-ui-react'

import logoColor from '../../../images/logo-color.svg'


const SidebarLayout = props => {
  const { header, middle, footer, onFooterClick, loading, children } = props

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar
        visible
        className='justified'
      >
        <div className='header'>
          {header || (
            <a href="https://www.rockerbox.com" target="_blank">
              <img src={logoColor} width="165" />
            </a>
          )}
        </div>

        <div className='middle'>
          {middle}
        </div>
        {footer || (
          <div
            className='footer'
            onClick={onFooterClick ? onFooterClick : () => {}}
          >
            <>
              Need help? Chat with Support
              <Icon name='conversation' />
            </>
         </div>
         )}
      </Sidebar>

      <Sidebar.Pusher>
        {loading
          ? <Loader active />
          : children
        }
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default SidebarLayout


export const StepNav = ({ title, icon, active, complete, onClick }) => (
    <div className={`step ${!!onClick && 'clickable'}`} onClick={onClick}>
      <Header as='h5' style={{ color: active ? 'black' : '#475ddc'}}>
        {complete
          ? <Icon circular inverted color='green' name='check' size='mini' />
          : active
            ? <Icon circular className='active' color='black' name={icon} />
            : <Icon circular name={icon} />
        }
        <Header.Content>{title}</Header.Content>
      </Header>
    </div>
)
