import React, { useEffect, useState, useMemo } from "react"

import { getPlatformPerformanceMeta } from '../../../../api/platformPerformanceApi'
import { useParamsWithDefaults } from '../../urlParamHooks';
import { generateDateRange } from '../../helpers'

export const usePerformanceMetaData = () => {
  const { startDate, endDate, platform } = useParamsWithDefaults();

  const [meta, setMeta] = useState(undefined);
  const [current, setCurrent] = useState({});

  // establish date range
  const dates = useMemo(
    () => generateDateRange(startDate, endDate), 
    [startDate, endDate]
  )

  // if current is updated, use the start, end, platform to get the meta data
  useEffect(() => {
    setCurrent({})
    setMeta(undefined)
    getPlatformPerformanceMeta(platform)
      .then((meta) => {
        // HACK for Facebook 7D Click + 1D View
        if (platform === 'facebook') {
          meta['columns'].push('7d_click_1d_view', '7d_click_1d_view_value')
          meta['conversion_columns'].push('7d_click_1d_view', '7d_click_1d_view_value')
          meta['nested_columns']['7d_click_1d_view'] = meta['nested_columns']['7d_click']
          meta['nested_columns']['7d_click_1d_view_value'] = meta['nested_columns']['7d_click_value']
        }
        setMeta(meta)
        setCurrent({ platform })
      })
    
  }, [platform])

  // if current != params, dont return meta and derived objects
  if (platform != current.platform) {
    return {
      current,
      dates,
      platformPerformanceMeta: [],
    }
  }

  return {
    current,
    dates,
    platformPerformanceMeta: meta, 
  }
}
