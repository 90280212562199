import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { sortData } from '../hooks/sortData'
import { defaultChartDimensions as defaults } from '../constants';
import { formatXAxisTickLabel, toolTipLabelFormatter } from '../helpers';
import { GroupByDropdown } from '../parts';
import ChartTooltip from '../ChartTooltip';

const Chart = ({
  data, tierColorMap,
  groupDatesToggle = false, groupDatesBy, setGroupDatesBy,
  defaultGroupBy, // add this prop to sum up data rollups
  height, showTooltip, hideKeys=[], formatter, dot = false, showXAxis, YAxisStyle = [], domainDefault = 'dataMax',
  nameMap = {}, currencyOverride, tooltipComponent, XAxisFormatter = false, XAxisTickHeight = false,
  allCaps = false, yAnchor = 0
}) => {
  const { renderData } = sortData(data, groupDatesBy, defaultGroupBy)
  const toolTipValueFormatter = formatter?.format ? formatter.format :
    formatter ? formatter : (v) => v
  const CTooltip = tooltipComponent || ChartTooltip;
  const keysToRender = YAxisStyle.length ? YAxisStyle.map(k => k.keyName) : Object.keys(renderData[0] || {}).filter(k => k != 'date')

  return (
    <>
      {groupDatesToggle && <GroupByDropdown {...{ groupDatesBy, setGroupDatesBy }} />}
      <ResponsiveContainer width ='100%' height={height || defaults['height']}>
        <LineChart data={renderData}>
          <XAxis
            xAxisId='0'
            hide={!showXAxis}
            dataKey="date"
            tickFormatter={XAxisFormatter ? XAxisFormatter : (date, i) => formatXAxisTickLabel(date, i, renderData)}
            tickLine={false}
            interval={0}
            height={XAxisTickHeight ? XAxisTickHeight : 12}
            padding={{left: 20, right: 20}}
          />
          <YAxis
            hide
            domain={[0, domainDefault]}
            yAxisId='Left'
          />
          <YAxis
            hide
            domain={[0, domainDefault]}
            yAxisId='Right'
          />
          {keysToRender
            .map((k, i) => {
              const color = YAxisStyle.length ? (YAxisStyle[i].color || '#026CAC') : tierColorMap[k]
              const yAxisSide = YAxisStyle.length ? (YAxisStyle[i].side || 'Left') : 'Left'
              return (
                <Line
                  key={k}
                  dataKey={k}
                  dot={dot}
                  fill={color}
                  stroke={color}
                  type="monotone"
                  yAxisId={yAxisSide}
                  strokeWidth={2}
                  connectNulls
                />
              )
            })}
          {showTooltip &&
            <Tooltip
              cursor={{
                stroke: '#efefef',
                strokeWidth: 2,
                strokeDasharray: '5 5',
              }}
              position={{ y: yAnchor }}
              labelFormatter={(v) => toolTipLabelFormatter(v, renderData)}
              formatter={toolTipValueFormatter}
              content={<CTooltip {...{ nameMap, currencyOverride, formatter, hideKeys, allCaps }} />}
              wrapperStyle={{ zIndex: 3 }}
            />
          }
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default Chart;
