import React, { Component } from 'react'
import { Modal, Icon, Form, Message, Input, Button } from 'semantic-ui-react'
import { postAmazonMWSCredentials } from '../../utils/api';

const AMAZON_MWS_HELP_LINK = "https://help.rockerbox.com/docs/amazon-seller-central"

class AmazonMWSAuthModal extends Component {
  state = {
    sellerId: "",
    authToken: "",
    error: false,
  }

  handleTextChange = (k, v) => {
    this.setState({[k]: v})
  }

  handleSubmit = (e) => {
    const { sellerId, authToken } = this.state
    const data = {
      seller_id: sellerId,
      auth_token: authToken
    }

    postAmazonMWSCredentials(data)
      .then(this.handlePostResponse)

    e.preventDefault()
  }

  handlePostResponse = (data) => {
    const { onSuccess, onClose } = this.props

    if (data.success){
      onSuccess()
      onClose()
    }

    this.setState({error: true})
  }

  render(){
    const { open, onClose } = this.props
    const { sellerId, authToken, error } = this.state

    return(
      <Modal
        size="small"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          Follow&nbsp;
          <a className="cursor-pointer" onClick={() => window.open(AMAZON_MWS_HELP_LINK, '_blank')}>
            these instructions&nbsp;
            <Icon
              fitted
              name='external'
            />
          </a>
          &nbsp;to get your Amazon Seller Central token for Rockerbox and enter it below
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              onSubmit={this.handleSubmit}
              error={error}
            >
              <Message
                error
                header='Invalid Credentials'
                content='Unable to connect to that Amazon Seller Central account with provided Auth Token.'
              />
              <Form.Field>
                <label>Seller ID</label>
                <input
                  placeholder='Your Amazon Seller ID'
                  value={sellerId}
                  onChange={(e) => this.handleTextChange('sellerId', e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>MWS Auth Token</label>
                <input
                  placeholder='MWS Auth Token for Rockerbox'
                  value={authToken}
                  onChange={(e) => this.handleTextChange('authToken', e.target.value)}
                />
              </Form.Field>
              <Button
                primary fluid
                content="Connect Amazon Seller Central"
                type="submit"
                disabled={!sellerId || !authToken}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default AmazonMWSAuthModal
