import React from 'react'
import { Segment, Grid, Statistic } from 'semantic-ui-react'

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#3b579d" style={{ width: 50, height: 50 }}>
    <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
    <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clipRule="evenodd" />
  </svg>
)

const StatCard = ({ label, value }) => (
  <Statistic
    size='tiny'
    color='purple'
    style={{ margin: 0 }}
  >
    <Statistic.Label
      content={label || <>&nbsp;</>}
      style={{ color: '#666', textAlign: 'left', fontSize: 13.5, fontWeight: 600, letterSpacing: '.02em' }}
    />
    <Statistic.Value
      content={value}
      style={{ marginTop: 2, color: '#3b579d', textAlign: 'left' }}
    />
  </Statistic>
)

const SummaryBox = ({icon, headline, text, statCards}) => {

  return (
    <Segment style={{ border: '1px solid #e7eafc' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
              {icon && <div style={{ padding: '0px 20px 0 10px' }}>{icon}</div>}
              <div style={{ flexGrow: 1 }}>
                {headline && <h4 style={{ marginBottom: 8 }}>
                  {headline}
                </h4>}
                {text && <h5 style={{ marginTop: 0, fontWeight: 400 }}>
                  {text}
                </h5>}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '100%', maxWidth: 650, margin: 'auto' }}>
              {statCards.map(stat => <StatCard label={stat.label} value={stat.value}/>)}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default SummaryBox
