class TreeNode {
  constructor(val) {
    this.key = val
    this.isPath = false
    this.children = {}
    this.pathIds = { "first": [], "mid": [], "last": [] }
  }

  getAllPathIds(position) {
    const allIds = []
    const collectIds = (pathIds) => {
      if (position === 'any') {
        return [...pathIds['first'], ...pathIds['mid'], ...pathIds['last']]
      } else {
        return pathIds[position]
      }
    }
    const dfs = (node) => {
      allIds.push(...collectIds(node.pathIds))
      for (let child in node.children) {
        dfs(node.children[child])
      }
    }
    dfs(this)
    return allIds
  }
}

export const Trie = function () {
  this.root = new TreeNode()
}

Trie.prototype.insert = function (touchpoint, pathId, position) {
  let curNode = this.root
  for (let i = 0; i < touchpoint.length; i++) {
    let hasChildNode = curNode.children[touchpoint[i]]
    
    if (!hasChildNode) {
      curNode.children[touchpoint[i]] = new TreeNode(touchpoint[i])
    }
    curNode = curNode.children[touchpoint[i]]
    if (i === touchpoint.length - 1) {
      curNode.isPath = true
      curNode.pathIds[position].push(pathId)
    }
    if (touchpoint[i + 1] == "") {
      curNode.isPath = true
      curNode.pathIds[position].push(pathId)
      return
    }
  }
}

Trie.prototype.search = function (touchpoint) {
  let current = this.root
  for (let tier of touchpoint) {
    if (current.children[tier] === undefined) {
      return false
    }
    current = current.children[tier]
  }
  return current
}