import React from 'react'
import ContentLoader from 'react-content-loader'

const PieChartLoader = ({ hideCenterText, height=340 }) => (
  <ContentLoader
    viewBox="0 0 347 340"
    foregroundColor="#e5e5e5"
    {...{ height }}
    width={'100%'}
  >
    {!hideCenterText && <>
      <path d="M215.28,163h-83.55c-2.21,0-4-1.79-4-4v-3.91c0-2.21,1.79-4,4-4h83.55c2.21,0,4,1.79,4,4V159 C219.28,161.21,217.49,163,215.28,163z"/>
      <path d="M217.88,193.81h-88.76c-2.21,0-4-1.79-4-4v-12.22c0-2.21,1.79-4,4-4h88.76c2.21,0,4,1.79,4,4v12.22 C221.88,192.02,220.09,193.81,217.88,193.81z"/>
    </>}
    <path d="M173.5,13.39C87.01,13.39,16.89,83.51,16.89,170c0,86.49,70.12,156.61,156.61,156.61c86.49,0,156.61-70.12,156.61-156.61 C330.11,83.51,259.99,13.39,173.5,13.39z M173.5,302.99c-73.45,0-132.99-59.54-132.99-132.99c0-73.45,59.54-132.99,132.99-132.99 c73.45,0,132.99,59.54,132.99,132.99C306.49,243.45,246.95,302.99,173.5,302.99z"/>
  </ContentLoader>
)

export default PieChartLoader
