import mixpanel from 'mixpanel-browser'
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'

export const identify = async (user, account, appKeys) => {
  const { username, first_name, last_name } = user
  const { advertiser_name, pixel_source_name, signup_source, tier, requires_setup, stripe_id, features } = account
  const intercomOrgId = appKeys?.intercom
  mixpanelIdentify(username, advertiser_name, pixel_source_name, signup_source, tier, requires_setup, features)
  intercomIdentify(username, first_name, last_name, advertiser_name, pixel_source_name, signup_source, tier, requires_setup, features, intercomOrgId)
  fullStoryIdentify(username, first_name, last_name, advertiser_name, pixel_source_name, tier)
  vitallyIdentify(username, first_name, last_name, advertiser_name, pixel_source_name, signup_source, tier, requires_setup, features)
  sentryIdentify(username, advertiser_name, pixel_source_name)
  profitwellIdentify(username, stripe_id)
}

// Mixpanel
export const mixpanelIdentify = async (userEmail, advertiserName, pixelSourceName, signupSource, tier, requiresSetup, features) => {
  mixpanel.identify(userEmail)
  mixpanel.people.set({
    '$email': userEmail,
    company: advertiserName,
    company_id: pixelSourceName,
    signup_source: signupSource,
    window_height: window.innerHeight,
    window_width: window.innerWidth,
  })
  // group analytics
  mixpanel.set_group('account', pixelSourceName)
  const group = mixpanel.get_group('account', pixelSourceName)
  group.set({
    '$name': advertiserName,
    account_name: advertiserName,
    signup_source : signupSource,
    tier: tier || 'scale',
    in_onboarding: !!requiresSetup,
    feature_flags: features || [],
  })
}

// Intercom
export const intercomIdentify = async (userEmail, firstName, lastName, advertiserName, pixelSourceName, signupSource, tier, requiresSetup, features, intercomOrgId) => {
  if (!window.Intercom) return
  const intercomSettings = {
    app_id: intercomOrgId,
    hide_default_launcher: (tier === 'starter' && !!requiresSetup) ? false : true, // show intercom launcher only in starter onboarding
    email: userEmail,
    user_id: userEmail,
    name: firstName && lastName && `${firstName} ${lastName}`,
    company: {
      id: pixelSourceName,
      name: advertiserName,
      signup_source: signupSource,
      plan: tier || 'scale',
      in_onboarding: !!requiresSetup,
      feature_flags: features || [],
    },
  }
  window.Intercom('boot', intercomSettings)
}

// FullStory
export const fullStoryIdentify = async (userEmail, firstName, lastName, advertiserName, pixelSourceName, tier) => {
  // FullStory.clearUserCookie(true)
  const { isInitialized, identify } = FullStory
  if (!isInitialized()) return
  identify(userEmail + ":" + advertiserName, {
    email: userEmail,
    displayName: !!firstName && !!lastName && `${firstName} ${lastName}`,
    account: advertiserName,
    accountId: pixelSourceName,
    tier: tier || 'scale',
  })
}

// Vitally
export const vitallyIdentify = async (userEmail, firstName, lastName, advertiserName, pixelSourceName, signupSource, tier, requiresSetup, features) => {
  if (!window.Vitally) return
  window.Vitally.account({
    accountId: pixelSourceName,
    traits: {
      name: advertiserName,
      signup_source: signupSource,
      plan: tier || 'scale',
      in_onboarding: !!requiresSetup,
      feature_flags: features || [],
    }
  });
  window.Vitally.user({
    userId: userEmail,
    accountId: pixelSourceName,
    traits: {
      name: firstName && lastName && `${firstName} ${lastName}`,
      email: userEmail,
    }
  });
}

// Sentry
export const sentryIdentify = async (userEmail, advertiserName, pixelSourceName) => {
  Sentry.setUser({
    email: userEmail,
    company: advertiserName,
    pixel_source_name: pixelSourceName,
  })
}

// ProfitWell
export const profitwellIdentify = async (username, stripeId) => {
  if (!window.profitwell || !stripeId || username?.includes('@rockerbox.com')) return
  window.profitwell('start', {
    user_id: stripeId
  })
}
