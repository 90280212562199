import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as d3 from 'rockerbox_d3_legacy_clone';
import ColorHash from 'color-hash';

import { colors, buildDrawableData } from './helpers';
import { HoverContext, HoverDisplay } from '../../HoverContext';


const HASH = new ColorHash({
  hue: {min: 50, max: 355},
  saturation: 0.55,
  lightness: [0.4, 0.75, 0.5]
})
export const colorHash = (text) => HASH.hex((text || "").split("").reverse().join(""))

const MaxWidth = styled.div`
  max-width:${x => x.maxWidth ? x.maxWidth: "900px"};
  margin-bottom:20px;
  margin-left:auto;
  margin-right:auto;
`

const ProgressWrapper = styled.div`
  ${x => x.minHeight ? `min-height:${x.minHeight};`: ""}
  position: relative;
  text-align: ${x => x.justify ? x.justify : "inherit"};
  padding-top: ${x => x.paddingTop ? x.paddingTop: "0px" };
  padding-bottom: ${x => x.paddingBottom ? x.paddingBottom: "0px" };

`

const ProgressBarWrapper = styled.div`
  border-radius:3px;
  overflow:hidden;
  height: 20px;
  background: black;
`

const ProgressBar = styled(HoverContext)`
  display:inline-block;
  height:100%;
  width: ${x => x.width};
  background: ${x => (x.percent && x.percent < .5) ? "grey" : colors[x.position] || "grey" };
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
`

export const LabelSwatch = styled.div`
  display:inline-block;
  background: ${x => (x.percent && x.percent < .5) ? "grey" : colors[x.position] || "grey" };
  width: ${x => (x.size || 6) + "px"};
  height: ${x => (x.size || 6) + "px"};
  /*margin-top: ${x => ((32-(x.size || 6))/2) + "px"};
  margin-left: ${x => ((30-(x.size || 6))/2) + "px"};*/
  /*margin: ${x => (x.size || 6) + "px"};*/
  border-radius: ${x => ((x.size || 6)/2) + "px"};
  /*margin-bottom: ${x => ((x.size || 6)/2) + "px"};*/
`

const LabelWrap = styled.div`
  display:inline-block;
  padding-left:10px; padding-right:10px;
  min-width:50px;
  font-size:.9em;
  font-weight:bold;
  font-color: 'grey'
`

const ProgressLabel = (props) => (
  <LabelWrap>
    <LabelSwatch size={6} position={props.position} />
    {props.name}: {props.percent}%
  </LabelWrap>
)

const HoverLabel = styled.div`
  font-size:.9em;
  font-weight:bold;
  width:40%;
  text-align:center;
  /*margin-left:${x => `${x.midPoint-10}%`}; bad performance... */
`

class AttributionPercent extends Component {

  render() {
    const { height, width, data, exclude, skipLabels } = this.props;

    if (!data) return null

    const drawableData = buildDrawableData(data, exclude)

    return (
      <MaxWidth style={{width:"900px"}}>
        <ProgressWrapper minHeight="25px" paddingBottom="5px">
           <HoverDisplay render={({hoverState}) => (
             <HoverLabel style={{marginLeft:`${hoverState.running+hoverState.percent/2-20}%`}}>
               {hoverState.vendor} {hoverState.percent && d3.format(".0%")(hoverState.percent/100)}
             </HoverLabel>
           )} />
        </ProgressWrapper>
        <ProgressWrapper>
          <ProgressBarWrapper>
            {
              drawableData.map((x,i) => {
                return <ProgressBar vendor={x.name} percent={100*x.percent} running={100*x.running} position={i} width={(100*x.percent)+"%"} />
              })
            }
          </ProgressBarWrapper>
        </ProgressWrapper>
        { !skipLabels &&
        <ProgressWrapper justify="center" paddingTop="10px">
          {
            drawableData.map((x,i) => {
              return <ProgressLabel key={x.key} position={i} {...x} />
            })
          }
        </ProgressWrapper>
        }
      </MaxWidth>
    )
  }
}

AttributionPercent.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.object,
  exclude: PropTypes.array
}

export default AttributionPercent;
