import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Icon, Loader, Popup, Header } from 'semantic-ui-react'
import { CardLoader } from '../ContentCard/ContentCard'

const ExpandedWrapper = (props) => {
  return (
    <Table.Row className="expandable-row">
      <Table.Cell colSpan={props.colSpan} style={{borderTop: 'none'}}>
        {props.children}
      </Table.Cell>
    </Table.Row>
  )
}

const Wrap = props => props.children

const writeCell = (item, col, otherProps) => {
  const DefaultCell = ({ item, col }) => {
    const content = typeof(item[col.key]) == 'object' ? new String(item[col.key]) : item[col.key]
    return(
      <Table.Cell width={item[col.cellWidth]}>
        <span>{item[col.key]}</span>
      </Table.Cell>
    )
  }
  const CellComponent = col.as || DefaultCell

  return(
    <CellComponent {...{ col, item, ...otherProps }} />
  )
}

const GridRow = props => {
  const ExpandedSection = props.expandedComponent;
  const {item, idKey, onRowClick} = props;
  const handleClick = props.expandCell ?
      false :
      (e) => {
        props.handleExpand(item[idKey]);
        if (onRowClick) {
          onRowClick({id: item[idKey]});
        }
      }

  return (
    <React.Fragment>
      <Table.Row positive={props.positive || props.item.positive} negative={props.negative} onClick={handleClick} style={props.expandCell ? null : {cursor: 'pointer'}}>
        { props.cols.map((col, index) => writeCell(props.item, col, props)) }
      </Table.Row>
      { props.expand === item[idKey] && <ExpandedWrapper colSpan={Object.keys(props.cols).length}><ExpandedSection item={item}/></ExpandedWrapper>}
    </React.Fragment>
  )
}

const Grid = props => {
  const headers = props.sortedHeaders;

  return (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          { headers && headers.length > 0 ? headers : props.cols.map(col => (
            <Table.HeaderCell width={col.headerWidth}>{col.display}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.data.map(item => (
          <GridRow {...props} item={item} />
        ))}
      </Table.Body>
      <Wrap>
        {props.footer || null}
      </Wrap>
    </React.Fragment>
  )
}


const EmptyMessage = props => (
  <Table.Body>
    <Table.Row>
      <Table.Cell
        textAlign='center'
        selectable={false}
        className='empty'
      >
        <br />
        <Icon
          circular
          disabled
          name='info'
          size='big'
          className='m-b-10' />
        <Header
          as='h3'
          disabled
          content={props.fallBackMsg || 'No data found'}
          className='m-0' />
        <br />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)

class ExpandableIndexGrid extends Component {
  state = {
    expand: ''
  }

  componentDidMount() {
    const expand = this.props.defaultExpand
    if (expand) this.setState({ expand })
  }

  componentDidUpdate(prevProps, prevState) {
    const expand = this.props.forceExpand
    if (expand && prevProps.forceExpand != expand) this.setState({ expand })
  }

  handleExpand = (item) => {
    this.setState({expand: item});
  }
  handleContract = (item) => {
    this.setState({expand: ''});
  }

  render() {
    const { expandedComponent, idKey, cols, data, fallBackMsg, ...tableProps } = this.props;
    const { handleExpand, handleContract } = this

    return(
      <Table {...tableProps}>
        { !data && <CardLoader /> }
        { data && data.length > 0 && <Grid cols={cols} {...this.props} {...{handleExpand, handleContract}} expand={this.state.expand}/> }
        { data && data.length == 0 && <EmptyMessage fallBackMsg={fallBackMsg} />}
      </Table>
    )
  }
};

ExpandableIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element
   })).isRequired,
  /** Key from a `data` object used to determine which row has been clicked and should be expanded.  The selected key must be unique across all objects (ideally some sort of id) */
  idKey: PropTypes.string.isRequired,
  /** Component that will display when table row is clicked */
  expandedComponent: PropTypes.element.isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number
}

export default ExpandableIndexGrid;
