import React from 'react'
import PropTypes from 'prop-types'
import { Ref, Grid, Header, Segment, Icon, Image, Popup, Dropdown, Button, Input } from 'semantic-ui-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ContentCard from '../ContentCard';
import styled from 'styled-components';

const numberFormat = (number) => number.includes("%") ? `calc(${number} - 15px)` :
  number.includes("px") ? number : `${number}px`;

const Flex = styled.div`
  margin: 10px;
  display: flex;
  width: 100%;
  ${props => props.wrap ? "flex-wrap: wrap;" : ""}
  flex-direction: ${props => props.direction || "row"};

  & > div {
    ${props => props.basis ? "flex-basis: " + numberFormat(props.basis) + ";" : ""}
    ${props => props.basis ? "min-width: " + numberFormat(props.basis) + ";" : ""}
    ${props => props.basis ? "max-width: " + numberFormat(props.basis) + ";" : ""}
  }
`
const FlexElement = styled.div`
  margin-left: 10px;
  flex: ${props => props.width || props.columnWidth};
  flex-direction: ${props => props.direction || "inherit"};
`

import KanbanColumn from './Column';
import KanbanCard from './Card';
import KanbanFilterRow from './Filter';

const noop = () => {}
const CLASSKEY = "classification"
const DETAILS = "view details"

const Placeholder = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    max-width: initial;
    animation: none;
    overflow: visible;
    padding: 1em 1em;
    min-height: 18rem;
    background: #f9fafb !important;
    border-color: rgba(34,36,38,.15) !important;
    box-shadow: 0 2px 25px 0 rgba(34,36,38,.05) inset;
}
`

const DefaultHeader = (props) => {
  const { col, color, text, icon } = props;
  const { rule: { response_display, response_values } } = col;
  return <Header as='h4' content={text} {...{color, icon}} style={{marginTop: "10px"}}/>
}


const Kanban = ({ 
  onChange, onHeaderChange, onRemoveOption, onViewDetails=noop,
  CardComponent=KanbanCard, 
  HeaderComponent=DefaultHeader,
  options=[], items=[], 
  orientation="columns", 
  classificationKey=CLASSKEY, viewDetailsText=DETAILS, 
  asRows=false, allowMaximize = true, wrap = false, 
  rightColumnsHeader = false, leftColumnsHeader = false
}) => {
  const [maximizedColumn, setMaximizedColumn] = React.useState(false)

  const leftSideColumns = options.filter(({ fullHeight }) => !!fullHeight)
  const rightSideColumns = options.filter(({ fullHeight }) => !fullHeight)
  const maximizedColumns = rightSideColumns.filter(({ text }) => text == maximizedColumn)

  const onDragEnd = (evt) => {
    const { draggableId, destination } = evt
    const { droppableId } = destination 
    const newItems = _.cloneDeep(items)
    const selectedItem = Object.assign(newItems.find(row => (row.id) == draggableId), { [classificationKey]: droppableId })
    
    onChange(newItems, selectedItem)
  }

  const filteredItems = items

  const leftSideColumnWidth = leftSideColumns.reduce((p,c) => p + (c.columnWidth || 1), 0)

  return (
    <DragDropContext {...{onDragEnd}}>
      <div>

        <Flex direction="row">
          <FlexElement width={leftSideColumnWidth} >
            { leftColumnsHeader && <Header as='h3'>{ leftColumnsHeader }</Header> }
          </FlexElement>
          <FlexElement width={16 - leftSideColumnWidth} >
            { rightColumnsHeader && <Header as='h3'>{ rightColumnsHeader }</Header> }
          </FlexElement>
        </Flex> 

        <Flex direction="row">
          {
            leftSideColumns.map((col) => {
              const { text, value, icon, description, color, columnWidth } = col
              const items = filteredItems.filter((row) => (row[classificationKey] == value))

              const params = { col, text, value, icon, color, description, columnWidth, viewDetailsText, items} 
              const on = { onHeaderChange, onRemoveOption, onViewDetails }
              const components = { Component: FlexElement, CardComponent, HeaderComponent }

              return <KanbanColumn {...params} {...components} {...on} />
            })
          }

          <FlexElement width={16 - leftSideColumns.reduce((p,c) => p + (c.columnWidth || 1), 0)} >
            <Flex direction={maximizedColumn || asRows ? "column" : "row"} {...{wrap}} basis="25%">
              {maximizedColumn && maximizedColumns.map((col) => {
                const { text, value, icon, description, color, columnWidth } = col
                const items = filteredItems.filter((row) => (row[classificationKey] == value))
                const onMinimize = () => setMaximizedColumn(false)

                const params = { col, text, value, icon, color, description, viewDetailsText, items }
                const on = { onHeaderChange, onRemoveOption, onViewDetails, onMinimize }
                const components = { Component: FlexElement, CardComponent, HeaderComponent }

                return <KanbanColumn {...params} {...components} {...on} />
              })}
              {!maximizedColumn && rightSideColumns.map((col) => {
                const { text, value, icon, description, color, columnWidth } = col
                const items = filteredItems.filter((row) => (row[classificationKey] == value))
                const onMaximize = () => setMaximizedColumn(text)

                const params = { col, columnWidth, text, value, icon, color, description, viewDetailsText, items }
                const on = { onHeaderChange, onRemoveOption, onViewDetails, onMaximize, }
                const components = { Component: FlexElement, CardComponent, HeaderComponent }

                return <KanbanColumn {...params} {...components} {...on} />
              })}
            </Flex>
          </FlexElement>
        </Flex>
      </div>
    </DragDropContext>
  )
}

Kanban.propTypes = {
  onChange: PropTypes.function,
  /** Array of grid row objects */
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  /** Array of column objects */
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    display: PropTypes.string,
    as: PropTypes.element
   })).isRequired
}

export default Kanban
