import React, { Component, CreateContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { Button, Loader, Popup } from 'semantic-ui-react';
import { SplitLayout, StatCard, ColumnLayout, ContentCard, IndexGrid, FullscreenModal } from '@rockerbox/styleguide';

import * as routes from '../../routes';
import { getAuthorizations, getSpendByPlatform, submitSpendBackfill, submitBatchSpendPurge, getSpendAssignments, getSpendPlatforms, deletePlatformAccount } from '../../utils/api';

import { Announcements } from '../Announcements';

import PlatformModal from './PlatformModal';
import { PlatformForm, OAUTH_PLATFORMS } from './PlatformModal/Forms';

import SpendViewStats from './SpendViewStats';
import SpendViewPlot, { RunBackfill } from './SpendViewPlot';
import { SpendViewAssignments, SpendViewAccounts, SpendEmptyView, SelectDays, upperCase } from './SpendViewParts';
import CacheContext from '../../utils/CacheContext';

const BACKFILL_KEY = "RB.spend_backfill" // TODO: remove this and use job runner framework


class SpendDataAccount extends Component {
  static contextType = CacheContext;

  state = {
    disabled: false,
    loading: false,
    dataLoading: false,
    showData: false,
    numDays: 30,
    allAssignments: false,
    allExclusionAssignments: [],
    accounts: [],
    activeAccountId: null,
    data: [],
    activeData: [],
    backfilledItems: [],
    showModal: false,
    modalLoading: false,
    platforms: [],
    platformOptions: [],
    selectedPlatform: '',
    authorizedPlatforms: []
  }

  runBackfill = (item) => {
    const { backfilledItems } = this.state;
    const submissions = backfilledItems.reduce((p,c) => { p[c] = true; return p}, {})
    submissions[JSON.stringify(item)] = true;
    submissions['date'] = moment().format("YYYY-MM-DD");
    localStorage.setItem(BACKFILL_KEY, JSON.stringify(submissions))

    if (item['platform'].indexOf('_') > 0) {
      const parts = item['platform'].split('_')
      item['platform'] = parts[0]
      item['version'] = parts[1]
    } else item['version'] = ''

    submitSpendBackfill(item)
      .then(x => {
        this.setBackfillRuns(submissions)
      })
  }

  purgeBatch = (item) => {
    console.log('hello');
    submitBatchSpendPurge(item)
  }

  getBackfillRuns = () => {
    const backfillData = localStorage.getItem(BACKFILL_KEY)
    if (backfillData) {
      const data = JSON.parse(backfillData)
      if (data['date'] == moment().format("YYYY-MM-DD")) this.setBackfillRuns(data)
    }
  }

  setBackfillRuns = (backfillData) => this.setState({ backfilledItems: Object.keys(backfillData) })

  setDataLoading = () => this.setState({ dataLoading: true, assignments: false, data: [], activeData: [] })

  getPlatforms = () => {
    getSpendPlatforms()
      .then(platforms => {
        const platformOptions = platforms.map((p,i) => {
          if (p.type != 'spend') return null;
          return {
            key: i,
            value: p.platform,
            text: p.display_name,
            logo_url: p.logo_url,
            deprecated: p.deprecated,
            version: p.default_version
          }
        }).filter(x => x != null);
        this.setState({ platforms, platformOptions })
      })
  }

  getAuthorizations = () => {
    const { activePlatform, activeAccountRowId, activeAssignmentId } = this.props.match.params;
    return getAuthorizations()
      .then(accounts => {
        if (!accounts.length) {
          this.setState({loading: false});
          return []
        }

        const platform = accounts.find(row => row.platform === activePlatform);
        const platformName = platform ? platform.platform : activePlatform;
        const account = (platform && activeAccountRowId) ? platform.authorizations.find(row => row.id == activeAccountRowId) : {};

        this.setState({ accounts, activeAccount: account, loading: false });
        this.updatePath(platformName, account.id, activeAssignmentId);
        return accounts
      })
  }

  updatePath = (platform, accountId, assignmentId) => {

    const { push } = this.props.history;
    const { activePlatform, activeAccountRowId, activeAssignmentId } = this.props.match.params;
    const base = routes.viewSpend;

    if (assignmentId) {
      return push(base + `/${activePlatform}/${activeAccountRowId}/${assignmentId}`)
    }
    if (platform && accountId) {
      return push(base + `/${platform}/${accountId}`)
    }
    if (accountId) {
      return push(base + `/${activePlatform}/${accountId}`)
    }
    if (platform) {
      return push(base + `/${platform}`)
    }
    if (platform === false && accountId === false) {
      return push(base + `/${activePlatform}/${activeAccountRowId}`)
    }
  }

  getAssignments = () => {
    const { activeAssignmentId } = this.props.match.params;

    getSpendAssignments()
      .then(allAssignments => {
        const activeAssignment = (allAssignments.filter(row => row.id == activeAssignmentId)[0]||{}).name;
        const assignmentMap = allAssignments.reduce((p,c) => { p[c.id] = c.name; return p }, {});
        const allExclusionAssignments = allAssignments.filter(row => row.is_exclusion == 1);
        allAssignments = allAssignments.filter(row => row.is_exclusion != 1);
        this.setState({ allAssignments, activeAssignment, assignmentMap, allExclusionAssignments });
      })
  }

  getData = (numDays, platform, accountId, version, assignmentId) => {
    this.setDataLoading()
    const startDate = moment.utc().utcOffset(-5).subtract(numDays, 'days').format('YYYY-MM-DD');
    const endDate = moment.utc().utcOffset(-5).subtract(1,'days').format('YYYY-MM-DD');

    if (platform !== undefined){
      if (version && version != '') platform = platform + '_' + version;
      getSpendByPlatform(platform, accountId, assignmentId, startDate, endDate)
        .then(data => {
          const { activeAccountRowId, activePlatform } = this.props.match.params;
          const activePlatformAccounts = this.state.accounts.find(row => row.platform === activePlatform).authorizations;
          const activeAccountId = activePlatformAccounts.find(auth => auth.id === Number(activeAccountRowId)).account_id;
          if (accountId == activeAccountId) {
            data.map(x => { x.has_missing = d3.sum(x.values, x => x.missing) })

            const selector = assignmentId || "all"
            const activeData = data.filter(row => row.assignment_id == selector)

            this.setState({ data, activeData, dataLoading: false, loading: false });
          }
        })
        .catch(e => this.setState({ dataLoading: false, loading: false, data: false, activeData: false }))
    }
  }

  componentDidMount() {
    const { activePlatform, activeAccountRowId, activeAssignmentId } = this.props.match.params;
    this.setState({ loading: true, assignments: false, data: [], activeData: [] })
    this.getPlatforms();
    this.getBackfillRuns();
    this.getAssignments();
    this.getAuthorizations()
      .then((accounts) => {
        if (accounts.length > 0 && activePlatform && activeAccountRowId) {
          const activePlatformAccounts = accounts.find(row => row.platform === activePlatform).authorizations;
          const defaultAccount = activePlatformAccounts[0]
          const activeAccount = activePlatformAccounts.find(auth => auth.id === Number(activeAccountRowId)) || defaultAccount;
          const activeAccountId = activeAccount.account_id;
          const activeAccountVersion = activeAccount.version;
          const authorizedPlatforms = accounts.map(a => a.platform);
          this.setState({ activeAccountId, authorizedPlatforms, activeAccountVersion, activeAccount, loading: false });
          if (!activeAccountId) {
            this.openModal()
          } else {
            this.getData(this.state.numDays, activePlatform, activeAccountId, activeAccountVersion, activeAssignmentId);
          }
        } else if (activePlatform && !activeAccountRowId) {
          this.setState({ selectedPlatform: activePlatform });
          this.openModal();
        } else if (accounts.length > 0 && !activePlatform) {
          const platform = accounts[0];
          const account = platform.authorizations[0];
          const accountId = account.id;
          this.updatePath(platform.platform, accountId, null);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { activePlatform, activeAccountRowId } = this.props.match.params;
    const { numDays, accounts } = this.state;

    const paramsIds = ["activePlatform", "activeAccountRowId"];

    const changedProps = paramsIds.filter(key => this.props.match.params[key] != prevProps.match.params[key]).length

    if (!(activePlatform && activeAccountRowId && !!accounts.length)) return

    if ((numDays != prevState.numDays) || (changedProps)) {
      const activePlatformAccounts = accounts.find(row => row.platform === activePlatform).authorizations;
      const activeAccount = activePlatformAccounts.find(auth => auth.id === Number(activeAccountRowId))
      const activeAccountId = activeAccount.account_id;
      const activeAccountVersion = activeAccount.version;
      this.setState({ activeAccountId, activeAccountVersion, activeAccount });
      if (!activeAccountId) {
        this.openModal()
      } else {
        this.getData(numDays, activePlatform, activeAccountId, activeAccountVersion);
      }
    }
  }

  selectAccount = (e, { name, id }) => {
    const { activePlatform, activeAccountRowId } = this.props.match.params;
    if (id == activeAccountRowId) return;

    this.updatePath(false, id);
    const { accounts } = this.state;
    const account = accounts.find(d => d.platform == activePlatform).authorizations.find(a => a.id == id);
    this.setState({ activeAccount: account })
  }

  deleteAccount = (platform, rowId) => {
    this.setState({modalLoading: true});
    deletePlatformAccount(platform, rowId).then(success => {
      window.location = `/v2/settings/spend/view`;
    })
  }

  selectPlatform = (e, { name }) => {
    const { accounts } = this.state
    const { id } = accounts.filter(row => row.platform == name)[0].authorizations[0]
    this.setDataLoading()
    this.updatePath(name, id)
  }

  selectAssignment = (e, { name, id }) => {
    this.updatePath(false, false, id)
    const {data} = this.state
    const selector = id || "all"
    const activeData = data.filter(row => row.assignment_id == selector)
    this.setState({ activeData, activeAssignment: name })
  }

  openModal = (forceAccount) => {
    if (forceAccount && !this.state.activeAccountId) {
      this.setState({ showModal: true, activeAccountId: '0' })
    } else {
      this.setState({ showModal: true });
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, selectedPlatform: '' })
  }

  handleModalSubmit = () => {
    const { activePlatform, activeAccountRowId } = this.props.match.params;

    this.getAuthorizations()
      .then((accounts) => {
        if (activePlatform) {
          const activePlatformAccounts = accounts.find(row => row.platform === activePlatform).authorizations;
          const activeAccount = activePlatformAccounts.find(auth => auth.id === Number(activeAccountRowId));
          const activeAccountId = activeAccount.account_id;
          const activeAccountVersion = activeAccount.version;
          const authorizedPlatforms = accounts.map(a => a.platform);
          this.setState({ activeAccountId, activeAccountVersion, authorizedPlatforms });
        }
        else {
          const platform = accounts[0];
          const account = platform.authorizations[0];
          const accountId = account.id;
          this.updatePath(platform.platform, accountId, null);
        }
      });
      this.handleCloseModal();
      this.setState({loading: true});
  }

  handlePlatformSelect = (platform) => {
    this.setState({ selectedPlatform: platform });
  }

  handlePastDaysChange = (e, { value }) => this.setState({ numDays: value })
  toggleData = (e, { name }) => this.setState({ showData: !this.state.showData })

  render() {
    const { activePlatform, activeAccountRowId, activeAssignmentId } = this.props.match.params;
    const { loading, numDays, showData, activeAccount, activeAccountId, activeAccountVersion, activeAssignment, allAssignments, allExclusionAssignments, authorizedPlatforms } = this.state;
    const { dataLoading, data, activeData, backfilledItems, assignmentMap, selectedPlatform, platforms, platformOptions, showModal, modalLoading, accounts  } = this.state;
    const { toggleData, runBackfill, purgeBatch, handlePastDaysChange, selectPlatform, selectAccount, deleteAccount, selectAssignment } = this;
    const { openModal, handlePlatformSelect, handleCloseModal, handleModalSubmit } = this;

    const versionedPlatformName = activeAccountVersion != '' ? (activePlatform + '_' + activeAccountVersion) : activePlatform;

    const assignments = (allAssignments && activeAccountId) ?
          allAssignments.filter(row => (row.spend_selection.tier_one.toUpperCase() == activeAccountId.toUpperCase()) && (row.spend_selection.platform == versionedPlatformName))
          : [];

    const exclusionAssignments = (allExclusionAssignments.length > 0 && activeAccountId) ?
          allExclusionAssignments.filter(row => row.spend_selection.tier_one.toUpperCase() == activeAccountId.toUpperCase())
          : [];


    const ALLOCATION_COLS = ["name","percent_of_spend","spend"]

    const minTime = moment.utc().utcOffset(-5).subtract(1, 'days');
    const [{currency_code}] = this.context

    return (
      <React.Fragment>
      { (loading || platforms.length == 0) ? <Loader active inline='centered' />
        :
        !accounts.length ? <SpendEmptyView onButtonClick={openModal} />
        :
        <React.Fragment>
          <ContentCard
            title="Authorized Accounts" noContent borderless noBackground
            topRight={<SelectDays {...{handlePastDaysChange, numDays}} />}
          />
          <ColumnLayout leftWidth={3} centerWidth={3} rightWidth={10}
            leftContent={
              <React.Fragment>
                <SpendViewAccounts {...{activePlatform, activeAccountId, activeAccountRowId, accounts, selectPlatform, selectAccount, platforms}} />
                {/* <Button primary onClick={() => openModal(true)}> Add Another Account + </Button> */}
              </React.Fragment>
            }
            rightContent={
              <React.Fragment>
                { (accounts.length > 0) && (assignments.length == 0) &&
                  <Announcements
                    announcements={[{"title":"Spend for this account is not mapped to your attribution report.","major":1}]}
                  />
                }

                <SplitLayout
                  leftWidth={12}
                  rightWidth={4}
                  leftContent={
                    <React.Fragment>
                      <div style={{justifyContent:"space-between",display:"flex"}}>
                        <div style={{lineHeight:"36px",fontWeight:"bold"}}>
                          { upperCase(activePlatform || " ") } › { activeAccount.name ? activeAccount : "" } › { activeAssignment ? activeAssignment : "All" }
                        </div>
                        { activeAssignmentId !== undefined && (
                          <div>
                            <Button primary as={Link} to={`${routes.editSpendMapping}/${activeAssignmentId}`}>
                              Edit Spend Assignment
                            </Button>
                            <Popup
                              inverted
                              content="Copy Spend Assignment"
                              trigger={
                                <Button icon="copy" as={Link} to={`${routes.copySpendMapping}/${activeAssignmentId}`}/>
                              }
                            />
                          </div>
                        )}
                      </div>
                      <SpendViewPlot {...{dataLoading, showData, toggleData, backfilledItems, activeData, runBackfill, purgeBatch, activeAccount}} />
                    </React.Fragment>
                  }
                  rightContent={ <SpendViewStats {...{activeData, numDays}} /> }
                />
                <SplitLayout
                  leftWidth={12}
                  rightWidth={4}
                  leftContent={
                    <React.Fragment>
                    { activeData && !!activeData.length && activeData[0].assignment_id == "all" &&
                      (
                        <ContentCard title={"Allocation per Assignment Rule"} hasTable>
                          <IndexGrid
                            as={ContentCard.Table}
                            fallBackMsg="No allocation rules created"
                            data={data
                              .filter(row => row.assignment_id != "all")
                              .map(row => {
                                row.name = assignmentMap && assignmentMap[row.assignment_id]
                                return row
                              })
                            }
                            cols={ALLOCATION_COLS.map(item => { return {display: item, key: item}})}
                          />
                        </ContentCard>
                      )
                    }
                    { activeData && !!activeData.length && activeData[0].assignment_id == "all" && !activeAccount.batch_platform &&
                      activeData.map(d => (
                        d.has_missing ? <ContentCard hasTable title={`Missing Data`}>
                          <IndexGrid
                            data={d.values.filter(row => row.missing)}
                            cols={[
                              { display: "Date", key: "date" },
                              { display:"", key:"run", as: RunBackfill("Resync Data",backfilledItems,this.runBackfill)}
                            ]}
                            as={ContentCard.Table}
                            fallbackMsg="No Data Sources"
                          />
                        </ContentCard> : undefined
                        )
                      )
                    }
                    </React.Fragment>
                  }
                  rightContent={
                    activeData && !!activeData.length && activeData[0].assignment_id === "all" &&
                    <React.Fragment>
                      <StatCard
                        color={activeData[0].missing_spend > 0 ? 'red' : false}
                        value={activeData[0].missing_spend}
                        label="Unallocated Spend" size='small' type='currency'
                        currencyCode={currency_code}
                      />
                      <StatCard
                        color={activeData[0].missing_spend > 0 ? 'red' : false}
                        value={activeData[0].percent_missing_spend}
                        label="Percent Unallocated" size='small' type='percent'
                      />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            }
          >
            <SpendViewAssignments {...{assignments, exclusionAssignments, activeAssignmentId, selectAssignment}} />
            { accounts.length > 0 && <Button primary as={Link} to={routes.createSpendMapping}>{` Add ${assignments.length > 0 ? 'Another' : 'a'} Mapping + `}</Button> }
          </ColumnLayout>
        </React.Fragment>
      }

      <FullscreenModal
        onClose={handleCloseModal}
        open={showModal}
        headerContent={
          (platforms.length && activePlatform && !activeAccountId) ?
            `Finish connecting ${platforms.find(p => p.platform == activePlatform).display_name} with Rockerbox`
            : "Choose a platform to integrate spend reporting"
          }
      >
        <PlatformForm
          onChange={handlePlatformSelect}
          onSubmit={handleModalSubmit}
          options={platformOptions}
          {...{ platforms, selectedPlatform, authorizedPlatforms, activeAccountId, activePlatform, activeAccountRowId }}
        />
      </FullscreenModal>

    </React.Fragment>
    )
  }
}


const Wrapped = withRouter(SpendDataAccount)
export default Wrapped;
