const V3Base = '/v3';
export const home = `${V3Base}/home`;

/* DATA */
const dataBase = `${V3Base}/data`;
const marketingBase = `${dataBase}/marketing`;

// advertising platforms
const advertisingPlatformsBase = `${marketingBase}/advertising_platforms`;
export const advertisingPlatforms = `${advertisingPlatformsBase}/main`;
export const connectAdvertisingPlatform = `${advertisingPlatformsBase}/connect`;
export const advertisingPlatformLastStep = `${advertisingPlatformsBase}/auth`;
export const advertisingTrackingParameters = `${advertisingPlatformsBase}/tracking`;

// promo codes
const promoCodesBase = `${marketingBase}/promo_codes`;
export const promoCodes = `${promoCodesBase}/main`;
export const promoCodesCreate = `${promoCodesBase}/create`;
export const promoCodesEdit = `${promoCodesBase}/edit`;

// surveys
const surveysBase = `${marketingBase}/surveys`;
export const surveys = `${surveysBase}/main`;
export const surveyCreate = `${surveysBase}/create`;
export const surveyEdit = `${surveysBase}/edit`;

// customers
const customersBase = `${dataBase}/customers`;
const conversionsBase = `${customersBase}/conversions`;
export const conversionsOverview = `${conversionsBase}/main`;
export const conversionsDetail = `${conversionsBase}/detail`;
export const conversionsUserProfile = `${conversionsBase}/user`;
export const conversionsConnect = `${conversionsBase}/connect`;

// exports
const exportsBase = `${dataBase}/exports`;
export const adHocExportsHistory = `${exportsBase}/ad_hoc_exports`;
export const datawarehouse = `${exportsBase}/data_warehouse`;
export const datawarehouseSetup = `${datawarehouse}/setup`;

/* SAVED REPORTS */
const savedReportsBase = `${V3Base}/savedreports`;
export const savedReports = `${savedReportsBase}/all`;

/* CHANNELS */
const channelsBase = `${V3Base}/channels`;
export const digitalAdvertising = `${channelsBase}/digital_advertising`;
export const channelOverlap = `${channelsBase}/channel_overlap`;

/* ATTRIBUTION */
const attributionBase = `${V3Base}/attribution`;
export const attributionReport = `${attributionBase}/attribution_report`;

/* FUNNEL */
const funnelBase = `${V3Base}/funnel`;
export const funnelPosition = `${funnelBase}/position`;
export const newVisitors = `${funnelBase}/new_visitors`;
export const marketingPaths = `${funnelBase}/marketing_paths`;

/* MODELING */
const modelingBase = `${V3Base}/modeling`;
export const marketingMixModeling = `${modelingBase}/mmm`;

/* EXPERIMENTS */

/* BENCHMARKS */
const benchmarksBase = `${V3Base}/benchmarks`;
export const spendTrends = `${benchmarksBase}/spend_trends`;
export const platformBenchmarks = `${benchmarksBase}/platform_benchmarks`;

/* SETTINGS */
export const settingsIndex = '/v3/settings';
export const myAccount = `${settingsIndex}/account`;
export const manageUsers = `${settingsIndex}/users`;
export const domains = `${settingsIndex}/domains`;
export const colors = `${settingsIndex}/colors`;
export const billing = `${settingsIndex}/billing`;
