import React, { Component } from 'react';
import moment from 'moment';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';

const CohortHeader = ({months, bucketBy}) => (
  <Column style={{paddingLeft:"30px",textAlign:"right"}}>
    <Row style={{paddingBottom:"5px",fontWeight:"bold"}}>
      <Column flex={2} style={{textAlign:"center", borderRight:"1px solid #ccc"}}>First Purchase</Column>
      <Column flex={months.length} style={{textAlign:"center", marginLeft:"5px", marginRight:`${months.length}px`}} >
        Repeat Purchases
      </Column>
      <Column>Total</Column>
    </Row>
    <Row style={{borderBottom:"1px solid #ccc",paddingBottom:"5px",marginBottom:"5px"}}>
      <Column flex={2} />
      { 
        months.map(date => (
          <Column style={{marginLeft:"5px",borderLeft:"1px solid #ccc"}}>
            { bucketBy == "month" ? moment(date+"-01","YYYY-MM-DD").format("MMM YYYY") : date }
          </Column>
        ) ) 
      }
      <Column style={{marginLeft:"5px",borderLeft:"1px solid #ccc"}}>Total</Column>
    </Row>
  </Column>
)
export default CohortHeader;
