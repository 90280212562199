import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';

import { ReportRow } from './row';
import { ReportHeader } from './header';
import { Pagination } from 'semantic-ui-react';

const pageSize = 100;

const footerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#f9fafb",
  padding: 15,
  fontStyle: "italic",
  fontSize: 16,
  color: "#555"
}

class ReportTable extends Component {

  state = {
    sortBy: "percent",
    direction: "descending",
    pageNumbers: [],
    selectedPageNumber: 1,
    rows: []
  }

  sortTableData(tableData) {
    const data = this.sortData(tableData||[]);
    const rows = data.filter(x => x && x.percent || (!x.percent && x.spend));
    this.setState({rows, pageNumbers: this.pagerLength(rows)});
  }

  componentDidUpdate(prevProps, prevState) {
    const { tableData } = this.props;

    const tableDataChange = prevProps.tableData !== tableData;
    const sortByChange = prevState.sortBy !== this.state.sortBy;
    const directionChange = prevState.direction !== this.state.direction;

    if (tableDataChange || sortByChange || directionChange) this.sortTableData(tableData);
  }

  componentDidMount() {
    const { tableData } = this.props;
    this.sortTableData(tableData);
  }

  onSort = (sortBy) => {
    const originalSort = this.state.sortBy;
    let direction = "descending";
    if (originalSort == sortBy) {
      direction = direction == this.state.direction ? "ascending" : "descending"
    }
    this.setState({sortBy, direction})
  }

  sortData = (data) => {
    const { sortBy, direction } = this.state
    const sorted = data.sort((p,c) => {
      const _p = parseFloat(p[sortBy]) || p[sortBy];
      const _c = parseFloat(c[sortBy]) || c[sortBy];

      return d3[direction](_p, _c)
    })
    return sorted
  }

  pagerLength = rows => {
    const numberOfPagers = Math.ceil(rows.length / pageSize);

    return numberOfPagers;
  };

  paginateData = data => {
    const { selectedPageNumber } = this.state;

    return data.slice(
      (selectedPageNumber - 1) * pageSize,
      selectedPageNumber * pageSize
    );
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ selectedPageNumber: activePage });
  };

  render() {

    const { models, model, tableData, match, hasSpend, range } = this.props;
    const { sortBy, direction, rows, selectedPageNumber, pageNumbers } = this.state;
    const paginatedRows = this.paginateData(rows);

    const H = this.props.Header || ReportHeader;
    const C = this.props.Row || ReportRow;

    return (
      <div style={{margin:"-14px"}}>
        <H onClick={this.onSort} {...{sortBy, direction, models, hasSpend}} />
        {
          paginatedRows.map((row,index) =>
            <C {...{models, model, row, index, match, hasSpend, range}} />
          )
        }
        {rows.length > 0 &&
          <div style={footerStyles}>
            <Pagination
              activePage={selectedPageNumber}
              totalPages={pageNumbers}
              onPageChange={this.handlePaginationChange}
              siblingRange={1}
              ellipsisItem={undefined}
              firstItem={null}
              lastItem={null}
              prevItem={undefined}
              nextItem={undefined}
              boundaryRange={1}
            />
            <div>
              Viewing Page {selectedPageNumber} of {pageNumbers}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default ReportTable;
