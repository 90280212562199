import React from 'react'
import { Segment } from 'semantic-ui-react'
import ContentLoader from 'react-content-loader'

export const KeyInsightsLoader = () => (
  <Segment style={{ padding: 30 }}>
    <ContentLoader
      viewBox="0 0 716 347"
      width="716"
      height="347"
      foregroundColor="#e5e5e5"
      style={{
        marginLeft: 0,
        marginRight: 'auto',
      }}
    >
      <rect x="30.15" y="54.74" width="148.38" height="14.48" rx="4" ry="4" />
      <rect x="0" y="126.01" width="108.73" height="11.34" rx="4" ry="4" />
      <rect x="0" y="192.8" width="108.73" height="11.34" rx="4" ry="4" />
      <rect x="0" y="260.08" width="108.73" height="11.34" rx="4" ry="4" />
      <rect x="0" y="87.92" width="620.71" height="14.48" rx="4" ry="4" />
      <polygon points="179.56 169.95 0 169.95 0 144.04 179.56 144.04 189.77 156.99 179.56 169.95" />
      <polygon
          points="363.77 236.98 184.21 236.98 194.42 224.03 184.21 211.07 363.77 211.07 373.98 224.03 363.77 236.98" />
      <polygon points="363.77 304.02 184.21 304.02 194.42 291.06 184.21 278.1 363.77 278.1 373.98 291.06 363.77 304.02" />
      <polygon points="547.44 304.02 367.88 304.02 378.09 291.06 367.88 278.1 547.44 278.1 557.65 291.06 547.44 304.02" />
      <polygon points="179.56 236.98 0 236.98 0 211.07 179.56 211.07 189.77 224.03 179.56 236.98" />
      <polygon points="179.56 304.02 0 304.02 0 278.1 179.56 278.1 189.77 291.06 179.56 304.02" />
      <rect x="0" y="5.75" width="148.38" height="17.46" rx="4" ry="4" />
      <circle cx="12.38" cy="62.99" r="8.25" />
    </ContentLoader>
  </Segment>
)

export const SummaryStatsLoader = () => (
  <Segment style={{ padding: 30 }}>
    <ContentLoader
      viewBox="0 0 408 347"
      width="100%"
      height="347"
      preserveAspectRatio='none'
      foregroundColor="#e5e5e5"
    >
      <rect x="0" y="5.75" width="180.59" height="17.46" rx="4" ry="4" />
      <rect x="0" y="46.9" width="379.82" height="11.13" rx="4" ry="4" />
      <rect x="0" y="67.24" width="324.32" height="11.13" rx="4" ry="4" />
      <rect x="0" y="151.54" width="90.3" height="11.13" rx="4" ry="4" />
      <rect x="180.97" y="108.94" width="23.69" height="11.13" rx="4" ry="4" />
      <rect x="178.94" y="151.54" width="27.74" height="11.13" rx="4" ry="4" />
      <rect x="180.97" y="192.76" width="23.69" height="11.13" rx="4" ry="4" />
      <rect x="260.71" y="108.94" width="46.55" height="11.13" rx="4" ry="4" />
      <rect x="363.3" y="108.94" width="23.69" height="11.13" rx="4" ry="4" />
      <rect x="0" y="135.31" width="408" height="1" />
      <rect x="0" y="176.31" width="408" height="1" />
      <rect x="0" y="217.31" width="408" height="1" />
      <rect x="0" y="258.31" width="408" height="1" />
      <rect x="0" y="192.76" width="90.3" height="11.13" rx="4" ry="4" />
      <rect x="0" y="233.61" width="82.02" height="11.13" rx="4" ry="4" />
      <rect x="0" y="274.46" width="94.02" height="11.13" rx="4" ry="4" />
      <rect x="174.09" y="233.61" width="37.45" height="11.13" rx="4" ry="4" />
      <rect x="165.44" y="274.46" width="54.75" height="11.13" rx="4" ry="4" />
      <rect x="270.11" y="151.54" width="27.74" height="11.13" rx="4" ry="4" />
      <rect x="272.14" y="192.76" width="23.69" height="11.13" rx="4" ry="4" />
      <rect x="265.26" y="233.61" width="37.45" height="11.13" rx="4" ry="4" />
      <rect x="256.61" y="274.46" width="54.75" height="11.13" rx="4" ry="4" />
      <rect x="361.28" y="151.54" width="27.74" height="11.13" rx="4" ry="4" />
      <rect x="363.31" y="192.76" width="23.69" height="11.13" rx="4" ry="4" />
      <rect x="356.42" y="233.61" width="37.45" height="11.13" rx="4" ry="4" />
      <rect x="347.78" y="274.46" width="54.75" height="11.13" rx="4" ry="4" />
    </ContentLoader>
  </Segment>
)
