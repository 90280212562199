import React from 'react'
import ContentLoader from 'react-content-loader'
import { Grid, Divider } from 'semantic-ui-react'
import { InformationCircleIcon, UserIcon } from '@heroicons/react/outline'

import { ContentCard } from '@rockerbox/styleguide'

const DetailItemLoader = () => {
  return (
    <ContentLoader
      viewBox="0 0 96 28"
      foregroundColor="#e5e5e5"
      height="28"
      width="96"
      style={{ float: 'left' }}
    >
      <rect x="0" y="1.06" width="96.49" height="10.14" rx="4" ry="4"/>
      <rect x="0" y="15.71" width="47.86" height="9.59" rx="4" ry="4"/>
    </ContentLoader>
  )
}

const SectionLoader = ({ Icon }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: 10 }}>
        <Icon style={{ height: 20, width: 20, opacity: 0.5 }} />
      </div>
      <div style={{ flexGrow: 1, marginTop: '-1em' }}>
        <Grid style={{ marginTop: 0 }}>
          <Grid.Row columns={4}>
            {[...Array(6)].map((_, i) => (
              <Grid.Column key={i} style={{ marginTop: i>=4 ? '1em' : 0 }}>
                <DetailItemLoader />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

const ConversionDetailsLoader = () => {
  return (
    <ContentCard
      hasTable
      title='Conversion Details'
      style={{ marginTop: 0 }}
    >
      <SectionLoader
        Icon={InformationCircleIcon}
      />
      <Divider />
      <SectionLoader
        Icon={UserIcon}
      />
    </ContentCard>
  )
}

export default ConversionDetailsLoader
