import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Icon, Segment } from 'semantic-ui-react';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle, items) => ({
  userSelect: "none",
  padding: `${items.length}px 0`,
  ...draggableStyle
});

const getListStyle = (isDraggingOver, items) => ({
  background: isDraggingOver ? "lightgray" : "white",
  padding: items.length,
  width: 'auto',
});

const DragAndDrop = ({ items, setItems, icon }) => {

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return
    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    setItems(reorderedItems)
  }

  const onClose = (id) => {
    const updatedItems = items.filter(item => item.id !== id)
    setItems(updatedItems)
  }

  if (items.length === 0) return <></>;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, items)}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      provided.draggableProps.style.position = 'static'
                    }                  
                    return (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, items)}>
                        <Segment raised secondary>
                          <Icon name={icon ? icon : 'grid layout'} />
                          {item.text}
                          {items.length > 1 && <Icon name='close' onClick={() => onClose(item.id)} style={{ float: 'right', cursor: 'pointer', color: 'gra' }} />}
                        </Segment>
                      </div>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  );
}

DragAndDrop.propTypes = {
  items: PropTypes.array.isRequired, // required: array of draggable items
  setItems: PropTypes.func.isRequired, // required: function to reset items after reordering
  icon: PropTypes.string, // optional: this is the icon if wanted in front of each draggable item. If nothing is passed down, default icon is 'grid l ayout'
}

export default DragAndDrop
