import React from 'react'
import { metrics } from './helpers'
import { Icon } from 'semantic-ui-react'


const GoalsTooltip = ({ active, payload, label, nameMap, currencyOverride, labelFormatter = x => x, formatter = x => x, goal }) => {
  const target = payload.find(c => c.dataKey == 'target') || {}

  if (active && payload && payload.length) {
    return (
      <div style={{
        padding: 10,
        borderRadius: 4,
        background: '#fff',
        color: '#000',
        boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
      }}>
          <p
            style={{
              fontSize: '14px',
              color: '#4d4d4d'
            }}
          >
            {labelFormatter(label)}
          </p>
          {payload.map(({ name, value, color, dataKey }, i) => {
            const formatMetric  = metrics[goal.metric]?.formatter || formatter
            return (
              <p
                key={name}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: i < payload.length-1 ? 10 : 0,
                  textTransform: 'capitalize',
                }}
              >
                <span style={{ marginRight: 20 }}>
                  <Icon name='square' style={{ color }} />
                  {nameMap[dataKey] || dataKey}
                </span>
                <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
                  {`${formatMetric(Number(value), currencyOverride)}`}
                </span>
              </p>
            )
          })}
          {target && (
            <p
              key="total"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingTop: '10px',
                borderTop: '1px solid grey',
                alignItems: 'center'
              }}
            >
              <span style={{ marginRight: 20 }}>{`${goal?.metric.toUpperCase()} Vs Goal`}</span>
              {Object.keys(target).length > 0 && <span style={Object.assign({padding: 5,borderRadius: 4}, target.payload.colorStyles)}>
                { target.payload.onTarget && target.payload.delta > 0 ? `+${target.payload.formattedValue}` : 
                  target.payload.onTarget && target.payload.delta < 0 ? target.payload.formattedValue:
                  target.payload.delta > 0 ? `+${target.payload.formattedValue}` : target.payload.formattedValue}
              </span>}
            </p>
          )}

      </div>
    )
  }
  return null
}

export default GoalsTooltip
