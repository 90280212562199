import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Menu, Icon, Loader, Popup, Header } from "semantic-ui-react";
import _ from "lodash";
import PagedIndexGrid from "../PagedIndexGrid";

class SortablePagedIndexGrid extends Component {
  state = {
    column: null,
    direction: null,
    data: [],
    sortKeys: []
  };

  componentDidMount() {
    const { data, cols } = this.props;
    const sortKeys = cols
      .filter(col => col.sortable != false)
      .map(col => col.key);
    this.setState({ data, sortKeys });
  }

  componentDidUpdate(prevProps) {
    const { data, cols } = this.props;
    if (data !== prevProps.data) {
      const sortKeys = cols
        .filter(col => col.sortable != false)
        .map(col => col.key);

      if (data.length !== prevProps.data.length) {
        return this.setState({ sortKeys, data });
      }

      for (let i = 0; i < data.length; i++) {
        let changed = false;
        for (let key of Object.keys(data)) {
          if (data[i] && prevProps[i] && data[i][key] !== prevProps[i][key]) {
            changed = true;
          }
        }
        return this.setState({ sortKeys, data });
      }

      this.setState({ sortKeys });
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction, sortKeys } = this.state;
    if (sortKeys.indexOf(clickedColumn) !== -1) {
      if (column !== clickedColumn) {
        const type = typeof data[0][clickedColumn];

        const sortedArray =
          type == "string"
            ? data.sort((a, b) => {
                return a[clickedColumn]
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]+/g, "-") <
                  b[clickedColumn].toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
                  ? -1
                  : 1;
              })
            : data.sort();

        this.setState({
          column: clickedColumn,
          data: sortedArray,
          direction: "ascending"
        });
      } else {
        this.setState({
          data: data.reverse(),
          direction: direction === "ascending" ? "descending" : "ascending"
        });
      }
    }
  };

  render() {
    const { cols, footer, pagination } = this.props;
    const { column, data, direction } = this.state;

    const Headers = cols.map(col => {
      if (col.sortable === false) {
        return (
          <Table.HeaderCell width={col.headerWidth}>
            {col.display}
          </Table.HeaderCell>
        );
      } else {
        return (
          <Table.HeaderCell
            width={col.headerWidth}
            sorted={column === col.key ? direction : null}
            onClick={this.handleSort(col.key)}
          >
            {col.display}
          </Table.HeaderCell>
        );
      }
    });

    return (
      <PagedIndexGrid
        sortedHeaders={Headers}
        {...this.props}
        data={data}
        sortable 
        pagination = {pagination}
      />
    );
  }
}

SortablePagedIndexGrid.propTypes = {
  /** @deprecated instead, wrap `IndexGrid` component with [ContentCard](#contentcard) and include the `hasTable` prop
   */
  as: PropTypes.element,
  /** Array of grid row objects */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Array of column objects */
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display: PropTypes.string,
      as: PropTypes.element,
      sortable: PropTypes.bool
    })
  ).isRequired,
  /** Optional fallback message if no data is available */
  fallBackMsg: PropTypes.string,
  UTCOffset: PropTypes.number,
  pagination: PropTypes.string
  /** Controls the location of the page selector on the footer--"left" or "right". Defaults to "right". */
};

export default SortablePagedIndexGrid;
