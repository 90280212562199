
import { generateBlankData } from './helpers';
import * as aq from 'arquero';

const DEFAULT_COLUMNS = ["even", "first_touch", "last_touch"]
const DEFAULT_NTF_COLUMNS = DEFAULT_COLUMNS.map(col => `ntf_${col}`)
const DEFAULT_REPEAT_COLUMNS = DEFAULT_COLUMNS.map(col => `repeat_${col}`)
const MODEL_COLUMNS = ["normalized"]
const MODEL_NTF_COLUMNS = MODEL_COLUMNS.map(col => `ntf_${col}`)
const MODEL_REPEAT_COLUMNS = MODEL_COLUMNS.map(col => `repeat_${col}`)

const DEFAULT_WITH_NTF = [...DEFAULT_COLUMNS, ...DEFAULT_NTF_COLUMNS, ...DEFAULT_REPEAT_COLUMNS]
const MODEL_WITH_NTF = [...MODEL_COLUMNS, ...MODEL_NTF_COLUMNS, ...MODEL_REPEAT_COLUMNS]

const getColumnsViaDatasetProperties = (hasModel, hasNTF) => {
  return hasModel && hasNTF ? [...DEFAULT_WITH_NTF, ...MODEL_WITH_NTF] :
         hasModel           ? [...DEFAULT_COLUMNS, ...MODEL_COLUMNS] :
         hasNTF             ? [...DEFAULT_WITH_NTF] :
                              [...DEFAULT_COLUMNS];
}

const hasColumn = (table, column) => table
  .columnNames()
  .includes(column)

const constructSumRollups = (columns) => columns.reduce(
  (p, col) => {
    p[`${col}`] = `d => op.sum(op.number(d['${col}']))`

    const isNTF = col.includes("ntf");
    const isRepeat = col.includes("repeat");
    const revenueColumn = isNTF ? col.replace("ntf", "ntf_revenue") :
    isRepeat ? col.replace("repeat", "repeat_revenue") : `revenue_${col}`

    p[`${revenueColumn}`] = `d => op.sum(op.number(d['${revenueColumn}']))`
    return p
  }, {}
)

export const getStats = (groupby, tiersDataRaw, conversionKey, revenueKey, startDate, endDate, raw=false) => {

  aq.addFunction('number', Number)

  if (!tiersDataRaw) return []
  console.time("new")
  const blanks = generateBlankData(startDate, endDate)

  const hasModel = hasColumn(tiersDataRaw, "normalized");
  const hasNTF = hasColumn(tiersDataRaw, "ntf_even");
  const columns = getColumnsViaDatasetProperties(hasModel, hasNTF);
  const hasSpend = hasColumn(tiersDataRaw, "spend");
  
  const spend = {}

  if(hasSpend) {
    spend['spend'] = `d => op.sum(op.number(d.spend))`
  }

  const specific = constructSumRollups(columns)
  const rollup = Object.assign({}, spend, specific)

  const summary = tiersDataRaw
    .groupby(groupby)
    .rollup(rollup)

  if (raw) return summary

  console.timeEnd("new")

  console.time("new objects")
  const summaryObjects = summary.objects()
  console.timeEnd("new objects")

  return [...summaryObjects, ...blanks]
}

export const filterTiers = (raw, filters) => {

  const { tier_1, tier_2, tier_3, tier_4, tier_5 } = filters;
  const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 };

  Object.keys(tiers).map(t => {
    if (tiers[t] == undefined || tiers[t].length == 0) delete tiers[t]
  })

  const toFilter = raw
    .params(tiers)

  const filtered = Object.entries(tiers)
    .reduce((data, [key, values], i) => {
      if (values.length == 0) return data
      // const filteredTable = toFilter
      //   .filter(`(d, $) => op.includes($['${key}'], d['${key}'])`)
      
      // return i === 0 ? filteredTable : data.union(filteredTable)

      return  data.filter(`(d, $) => op.includes($['${key}'], d['${key}'])`) // enable if using regular rollup in advanced
      
    }, toFilter)

  return filtered
}

export const getTotals = (table) => {
  const columns = table.columns()
  const numericColumns = Object.keys(columns)
    .filter((col) => typeof columns[col].data[0] === 'number')
    .reduce((acc, col) => {
      acc[col] = `d => op.sum(op.number(d['${col}']))`
      return acc
    }, {})

  return table.rollup(numericColumns)
}

export const filterSearch = (raw, search) => {
  const toFilter = raw
  .params({search})

  const filtered = raw
    // made new column "searchable" -- lower case and add all tiers together
    .derive({
      searchable: `d => op.lower(d.tier_1 + d.tier_2 + d.tier_3 + d.tier_4 + d.tier_5)`
    })
    .filter(`(d, $) => op.match(d['searchable'], $['search'])`)

  return filtered
}
