import React from 'react'
import { metrics } from './helpers'
import { Icon } from 'semantic-ui-react'

const metricsList = Object.keys(metrics)

const ChartTooltip = ({ active, payload, label, nameMap, currencyOverride, labelFormatter = x => x, formatter = x => x }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        padding: 10,
        borderRadius: 4,
        background: '#fff',
        color: '#000',
        boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
      }}>
          <p
            style={{
              fontSize: '14px',
              color: '#4d4d4d'
            }}
          >
            {labelFormatter(label)}
          </p>
          {payload.map(({ name, value, color, dataKey }, i) => {
            const metricName = metricsList.find(metric => name.includes(metric))
            const formatMetric  = metrics[metricName]?.formatter || formatter

            return (
              <p
                key={name}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: i < payload.length-1 ? 10 : 0,
                  textTransform: 'capitalize',
                }}
              >
                <span style={{ marginRight: 20 }}>
                  <Icon name='square' style={{ color }} />
                  {nameMap[dataKey] || dataKey}
                </span>
                <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>
                  {`${formatMetric(value, currencyOverride)}`}
                </span>
              </p>
            )
          })}

      </div>
    )
  }
  return null
}

export default ChartTooltip
