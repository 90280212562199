import React, { useEffect, useState, useContext } from "react"
import moment from "moment"
import CacheContext from "../../../utils/CacheContext"

// helpers
import { getHeaderText } from './helpers'
import { initializeViewChangeWatcher } from './watcher'
import { arrowColumnUnique } from './_hooks/reportingData'
import { getAccountCurrency, getAccount } from "../../api/account"

// state
import { useTiers } from './_hooks/tierFilters'
import { usePlatformComparisonConfig } from './_hooks/comparisonConfig'
import { usePlatformPerformanceConfig } from './_hooks/platformConfig'
import { usePerformanceDataWithSelection } from './_hooks/performanceData'
import { useSegmentValues } from './_hooks/segment'
import { useTreeColumns } from "./_hooks/treeColumns"

// views
import Comparison from './Comparison'
import PlatformPerformance from "./PlatformPerformance"

import { platformPerformanceFirstReportingDate } from "../../utils/time"

const DigitalAdvertising = (props) => {

  const [state, setState, Context] = useContext(CacheContext);
  const account = Context.getCache(`account`, getAccount) || {};
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;
  const { platformSelection, settings, urlState } = props;
  const { integrations } = platformSelection;
  const { tierColors } = settings;
  const { tier } = account; 

  const {
    view, // what page is shown
    platform, // what platform
    startDate, setStartDate, endDate, setEndDate, // what date range
    segId, setSegId, // what RB segment?
    model, setModel, ntfFilter, setNtfFilter, // what model, what user grouping
  } = urlState

  const performanceConfig = usePlatformPerformanceConfig();
  const { platformConfig } = performanceConfig;

  // read / reset tiers on url
  const { tier_1, resetTiers, tierFilterMap } = useTiers(view, platformConfig);
  const [ firstReportingDate, setFirstReportingDate] = useState(platformPerformanceFirstReportingDate)

  initializeViewChangeWatcher(view, resetTiers, setFirstReportingDate)

  // comparison selection
  const comparisonConfig = usePlatformComparisonConfig() 
  const { segsInfo, comparisonConfigsList, createConfigList, updateConfigList } = comparisonConfig

  const setSegOptions = () => {}
  const setModelOptions = () => {}

  // performance data configuration / selection
  const { 
    defaultMetric, setDefaultMetric, // card selection metric
    optionalMetric, setOptionalMetric, // card selection metric
    conversionOptions,
    convValue, platformConversionEvent, setPlatformConversionEvent, updatePlatformConversionEvent,
    attributionWindowOptions, 
    windowValue, attributionWindow, setAttributionWindow, updateAttributionWindow,
    hasOptionSelected,
    current, dates
  } = performanceConfig;

  const { revenue_formatter } = platformConfig;
  const { optional_metrics, default_metrics } = platformConfig;

  const { segOptions, modelOptions, hasRevenue, hasNtf } = useSegmentValues(view, segId, comparisonConfigsList, segsInfo, model, setModel)

  const { allTiers, tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, groupBy, modelDisplayName } = useTreeColumns(
    view, platform, model, integrations,
    modelOptions, hasRevenue, optional_metrics,
    currencyCode, tier
  );

  // get performance data using configuration
  const { 
    loading,
    _platformPerformanceData,
    platformPerformanceData,
    filteredPlatformPerformanceObjects,
    filteredPlatformPerformanceTiers,
    dailyPerformance,
    totalPerformance
   } = usePerformanceDataWithSelection(
    {groupBy, attributionWindow, platformConversionEvent, revenue_formatter, tierFilterMap}
  );

  const platformDates = React.useMemo(
    () => _platformPerformanceData?.size && arrowColumnUnique(_platformPerformanceData, 'date') , 
    [_platformPerformanceData]
  )
  
  useEffect(() => {
    if(segOptions.length < 1 || segId == 0 || view == "platform") return
    const firstReportingDate = segOptions.find(option => option.filter_id == segId)?.first_reporting_date
    const reportingDateBeforePpDate = moment(firstReportingDate).isBefore(platformPerformanceFirstReportingDate)
    const checkedDate = reportingDateBeforePpDate ? platformPerformanceFirstReportingDate : firstReportingDate

    setFirstReportingDate(checkedDate)
  }, [segOptions, segId])


  const updateSegmentAndModel = (value) => {
    const { platform_conversion } = comparisonConfigsList.find(config => config.filter_id == value)

    setSegId(value)
    updatePlatformConversionEvent(platform_conversion)
  }

  // State update handlers
  const onDefaultMetricChange = (e, { value }) => setDefaultMetric(value)
  const onOptionalMetricChange = (e, { value }) => setOptionalMetric(value)

  const onDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const { display_platform_name } = platformConfig;
  const headerText = getHeaderText(view, display_platform_name)
    
  return (
    <React.Fragment>
      { (view == 'platform') && 
        <PlatformPerformance 
          {...{ 
            convValue, windowValue, comparisonConfigsList,
            conversionOptions, attributionWindowOptions, 
            updateSegmentAndModel, updatePlatformConversionEvent, updateAttributionWindow
          }} // values/methods for conversions metrics dropdown
          {...{ headerText, tier_1, platform, platformConfig, startDate, endDate, onDateChange }}  //Basic params and filters
          {...{ platformPerformanceData,
            integrations, dates,
            dailyPerformance, totalPlatformPerformance: totalPerformance 
          }} //Data
          {...{ optionalMetric, defaultMetric }} // initial trend card values
          {...{ onOptionalMetricChange, onDefaultMetricChange }} // methods for trend cards
          {...{ allTiers, tiers, setTiers, allColumns, selectedColumns, setSelectedColumns }} // for customize cols and edit breakdowns
          {...{ optional_metrics, hasOptionSelected, hasAvailConvMetrics: true, default_metrics, account, hasRevenue }} // other
        />
      }
      { (view == 'comparison') && 
        <Comparison
          {...{ convValue, windowValue, conversionOptions, attributionWindowOptions, updatePlatformConversionEvent, updateAttributionWindow, view}} // values/methods for conversions metrics dropdown
          {...{ headerText, tier_1, platform, platformConfig, startDate, endDate, onDateChange }}  //Basic params and filters
          {...{ platformDates, platformData: filteredPlatformPerformanceTiers, platformPerformanceData, integrations, segsInfo }} //Data
          {...{ optionalMetric, segId, model }} // values
          {...{ onOptionalMetricChange }} //trendCard
          {...{ segOptions, modelOptions }}  // options
          {...{ updateSegmentAndModel, setModel, setSegId, setSegOptions, setModelOptions, createConfigList, updateConfigList }} // methods for RB data
          {...{ tiers, setTiers, allColumns, selectedColumns, setSelectedColumns, modelDisplayName }} // for customize cols and edit breakdowns
          {...{ optional_metrics, hasRevenue, comparisonConfigsList, account }} // other
        />
      }
    </React.Fragment>
  )
}

export default DigitalAdvertising;
