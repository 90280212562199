import React from 'react';
import { connect } from 'react-redux';
import { push, withRouter } from 'react-router-dom';

import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';

import { SET_SAVED_REPORTS } from '../ReportIndex/constants';

const GET_SAVED_REPORTS_ENDPOINT = '/reporting/saved';

const err = () => console.log("error")

function mapStateToProps (state, props) {
  return {
    savedReports: state.reports.savedReports
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    setSavedReports: (reports) => dispatch(rootAction(SET_SAVED_REPORTS, reports))
  }
}

// TODO: fix this
//function setSavedReportPath(savedReportId) {
//
//  const { history: { push } } = this.props;
//
//  const { groups, models, savedReports } = this.props;
//  if (savedReports.length < 1) return;
//
//
//  const report = savedReports.find(r => r.id === savedReportId);
//
//  const model = models[0].value;
//  const group = groups[0].value;
//  const reportType =  "report";
//
//  if (report) {
//    const { tier_1, tier_2, tier_3, tier_4, tier_5 } = report.filters[0];
//
//    const tiers = { tier_1, tier_2, tier_3, tier_4, tier_5 };
//
//    Object.keys(tiers).map(t => {
//      if (tiers[t] == undefined) delete tiers[t]
//    });
//
//    const filter_id = report.filter_id;
//    const filters = Object.keys(tiers).sort()
//      .reduce((a,c) => {
//        if (typeof(tiers[c]) == "string") return a += '/' + tiers[c]
//        if (tiers[c]) return a += '/' + tiers[c].join(',')
//        return a
//      }, '')
//      .substring(1);
//    push(`${routes.conversionData}/${filter_id}/${reportType}/${model}/${group}/${filters}`);
//    return {tiers, model, group};
//  }
//}

const Component = (props) => [
  <Fetch error={err} endpoint={GET_SAVED_REPORTS_ENDPOINT} success={props.setSavedReports} />
]

const SavedReportData = connect(mapStateToProps, mapDispatchToProps)(withRouter(Component))

export default SavedReportData;
