import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom } from '../../../atoms';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { buildGroupsFromRaw, resetTiers, buildHierarchy } from './helpers'; 
import { DEFAULT_TIERS_ARR } from '../../../constants/tiers';

const RollupFilter = ({ rawData, hierarchy = DEFAULT_TIERS_ARR, rollupHeadingLabel }) => {

  const [tier_1, set_tier_1] = useAtom(tier1Atom)
  const [tier_2, set_tier_2] = useAtom(tier2Atom)
  const [tier_3, set_tier_3] = useAtom(tier3Atom)
  const [tier_4, set_tier_4] = useAtom(tier4Atom)
  const [tier_5, set_tier_5] = useAtom(tier5Atom)

  const resetTier1 = useResetAtom(tier1Atom)
  const resetTier2 = useResetAtom(tier2Atom)
  const resetTier3 = useResetAtom(tier3Atom)
  const resetTier4 = useResetAtom(tier4Atom)
  const resetTier5 = useResetAtom(tier5Atom)

  const [tierOptions, setTierOptions] = useState({})

  const clearFilters = () => {
    resetTier1()
    resetTier2()
    resetTier3()
    resetTier4()
    resetTier5()
  }

  const selectedTiers = { tier_1, tier_2, tier_3, tier_4, tier_5 };
  const hierarchyObj = buildHierarchy(hierarchy)
  const tiers = useMemo(() => (
    rawData ? buildGroupsFromRaw(rawData, hierarchy) : []
  ), [rawData, hierarchy])
  const tierKeys =  Object.keys(tiers);

  const tierLength = tierKeys.reduce((p,k) => {
    p[k] = (selectedTiers[hierarchyObj[k]] || []).length
    return p
  }, {});

  const selectTier = (tier) => (obj) => {
    const { value } = obj;
    const param_vals = value; 
    const chosenTier = hierarchyObj[tier]
    const tierFuncs = [set_tier_2, set_tier_3, set_tier_4, set_tier_5];

    switch(chosenTier) {
      case 'tier_1':
        set_tier_1(param_vals)
        resetTiers(param_vals, tierFuncs, 0)
        break;
      case 'tier_2':
        set_tier_2(param_vals)
        resetTiers(param_vals, tierFuncs, 1)
        break;
      case 'tier_3':
        set_tier_3(param_vals)
        resetTiers(param_vals, tierFuncs, 2)
        break;
      case 'tier_4':
        set_tier_4(param_vals)
        resetTiers(param_vals, tierFuncs, 3)
        break;
      case 'tier_5':
        set_tier_5(param_vals)
        break;
    }
  }

  useEffect(() => {
    const optionsList = tierKeys.reduce((acc, key) => {
      acc[key] = createTierOptions(key)
      return acc
    }, {})
    if (!optionsList.tier_1.length) return
    tierKeys.forEach((key) => {
      const chosenTier = hierarchyObj[key]
      checkOptionsToSelected(optionsList[key], selectedTiers[chosenTier], key)
    })
    setTierOptions(optionsList)
  }, [tiers, tier_1, tier_2, tier_3, tier_4, tier_5])

  const createTierOptions = (key) => {
    const options = tiers[key].filter(x =>
      x.parentValues.reduce((p, v, i) => {
        const t = x.parentTiers[i]
        return (selectedTiers[hierarchyObj[t]].indexOf(v) > -1) ? p + 1 : p
      }, 0) == x.parentValues.length
    )
    return options
  }

  const checkOptionsToSelected = (list, selected, tier) => {
    const verifySelected = selected.filter((x) => list.find(item => item.value === x))
    if (verifySelected.length === selected.length) return
    const chosenTier = hierarchyObj[tier] 
    switch(chosenTier) {
      case 'tier_1':
        set_tier_1(verifySelected)
        break;
      case 'tier_2':
        set_tier_2(verifySelected)
        break;
      case 'tier_3':
        set_tier_3(verifySelected)
        break;
      case 'tier_4':
        set_tier_4(verifySelected)
        break;
      case 'tier_5':
        set_tier_5(verifySelected)
        break;
    }
  }

  return (
    <React.Fragment>
      <Form>
          <Form.Field>
            <label>{rollupHeadingLabel}</label>
            {
              tierKeys.map((key,i) => {
                if (i != 0 && tierLength[tierKeys[i-1]] == 0) return null;
                return (
                  <Form.Select
                    search
                    multiple
                    placeholder={`${(hierarchy.find(c => c.value == key)||{}).text} contains`}
                    value={selectedTiers[hierarchyObj[key]] || []}
                    onChange={(_, x) => selectTier(key)(x)}
                    options={tierOptions[key]}
                  />
                )
              })
            }
          </Form.Field>
          {tier_1 &&
            <Form.Field style={{minHeight: "30px", display: 'flex', justifyContent: 'flex-end'}}>
              <Button style={{cursor: "pointer"}} onClick={() => clearFilters()}>Clear All Filters</Button>
            </Form.Field>
          }
        </Form>
      </React.Fragment>
    )
}

export default RollupFilter
