import React from "react"
import { Menu } from 'semantic-ui-react'

// parts
import DigitalAdvertising from "./DigitalAdvertising"
import { MainHeader } from "./parts/stateless"
import { FilterWrapper, StyledDropdown, SelectAPlatform, NoConnections } from '../../components';

// state
import useAuthedIntegrations from "../../hooks/useAuthedIntegrations";
import { usePlatformPerformanceConfig } from './_hooks/platformConfig'

import { channelPerformancePlatformsConfig } from "../../constants/mappings";

const ViewSelector = ({ isLite, platformConfig, activeMenuItem, setActiveMenuItem }) => {

  return <Menu tabular className="digital-advertising-menu-overrides">
    <Menu.Item
      name='platform'
      active={activeMenuItem === 'platform'}
      content={`${platformConfig.display_platform_name || 'Platform'}-Reported Performance`}
      onClick={(e, {name}) => setActiveMenuItem(name)}
    />
    <Menu.Item
      name='comparison'
      active={activeMenuItem === 'comparison'}
      content='First-Party Attributed Performance'
      icon={!!isLite && 'lock'}
      disabled={!!isLite}
      onClick={(e, {name}) => setActiveMenuItem(name)}
    />
  </Menu>
}

const PlatformSelector = ({ platformConfig, platformSelection, settings, urlState }) => {

  const { isLite } = settings;
  const { activeMenuItem, setActiveMenuItem } = urlState;

  return <div>
    <MainHeader {...platformSelection} />
    <ViewSelector {...{isLite, activeMenuItem, setActiveMenuItem, platformConfig}} />
  </div>
}

const GatedByPlatformSelection = (props) => {
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const platformPerformanceConfig = usePlatformPerformanceConfig();

  const { onDropdownChange, dropdownOptions, dropdownOptionsLoading, integrations } = platformSelection;
  const { settings, urlState } = props;
  const { view, platform, startDate, endDate } = urlState;
  const { platformConfig } = platformPerformanceConfig;
  const { isLite } = settings;

  // Force a integration before viewing page
  if (!!dropdownOptions && !dropdownOptions.length)
    return <NoConnections />
  
  // Force the selection of a platform
  if (!platform || platform == 0 || platform == '')
    return (<>
      <div>
        <MainHeader {...{ ...platform }} />
        <Menu tabular className="digital-advertising-menu-overrides">
          <Menu.Item disabled content='Platform-Reported Performance' />
          <Menu.Item disabled icon={!!isLite && 'lock'} content='First-Party Attributed Performance' />
        </Menu>
      </div>
      {dropdownOptions?.length == 0 ? null :
        <FilterWrapper 
          {...{ loading: dropdownOptionsLoading }}
          other={
            <StyledDropdown
              label={"Ad Platform"}
              defaultOpen={true}
              value={platform}
              options={dropdownOptions}
              onChange={onDropdownChange}
            />
          }
        />
      }
        <SelectAPlatform {...platformSelection} />
    </>)

  return <>
    <PlatformSelector {...{platformSelection, settings, urlState, platformConfig}} />
    <DigitalAdvertising 
      {...props}
      {...{platformSelection}}
    />
  </>
}

export default GatedByPlatformSelection;
