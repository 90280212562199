import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Card, Image, Button, Loader, Icon, Header, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { CacheContext } from '../../../utils/CacheContext';
import { getAllIntegrations, getAuthorizations } from '../../api/account';
import { getArtifacts } from '../../api/attribution';
import { track } from '../../../utils/tracking';
import { BasicViewHeader, FilterWrapper } from '../../components';
import * as routes from '../../baseRoutes';
import RoadmapPortalPromo from './RoadmapPortalPromo';

import { ALLOWED_PLATFORMS_MAPPING } from '../../constants/mappings';

export const IntegrationCard = ({ integration, authorizations }) => {
  const [state, setState, Context] = useContext(CacheContext);
  const { account } = state;
  const { tier } = account || {};

  const { display_name, logo_img, platform, path } = integration;
  const image = logo_img || 'integrations-placeholder.png';
  const imageSrc = `https://images.rockerbox.com/integrations/${image}`;

  const isSupported = useMemo(() => {
    const supportedPlatforms = ALLOWED_PLATFORMS_MAPPING[tier] || 'all';
    return supportedPlatforms === 'all' || supportedPlatforms.includes(platform);
  }, [platform]);

  const existingAuths = useMemo(() => {
    if (!authorizations) return;
    const platformAuthorizations = authorizations.find(x => x.platform === platform)?.authorizations || [];
    return platformAuthorizations.filter(y => !!y.account_id).length;
  }, [authorizations]);

  const onClick = () => {
    if (!isSupported) {
      track('integration.upgrade_contact', { platform });
      const chatMessage = `Hi! I'm interested in upgrading to access the ${display_name} integration.`;
      if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
      return;
    }

    const hasAuth = !!path;
    const eventType = hasAuth ? 'authentication' : 'install';
    track(`integration.${eventType}.start`, { platform });

    if (!!path) {
      window.location = path;
      return;
    }

    track('integration.enable_contact', { platform });
    const chatMessage = `Hi! I'm interested in enabling the ${display_name} integration.`;
    if (window.Intercom) window.Intercom('showNewMessage', chatMessage);
  };

  const buttonText = useMemo(() => {
    if (!isSupported) {
      return (
        <>
          <Icon name="lock" style={{ marginRight: 10 }} />
          Contact Us to Upgrade
        </>
      );
    }
    if (!!path) return 'Connect';
    return (
      <>
        <Icon name="chat" style={{ marginRight: 10 }} />
        Contact Us to Enable
      </>
    );
  }, [path]);

  return (
    <Card
      className="integration-card"
      style={{ textAlign: 'center' }}
      key={platform}
    >
      <Card.Content style={{ margin: 0 }}>
        <Image
          centered
          size="medium"
          src={imageSrc}
          style={{ height: 76, width: 'auto', marginBottom: 10 }}
          key={`${platform}-image`}
        />
        <Card.Header style={{ color: 'rgba(0, 0, 0, 0.5)' }}>{display_name}</Card.Header>
        {!!existingAuths
        && (
          <div style={{ alignSelf: 'center', background: '#00BB5C', color: '#fff', padding: '5px 10px', borderRadius: 15, marginTop: 10 }}>
            <Icon name="checkmark" />
            <span style={{ marginLeft: 5 }}>
              {`${existingAuths} Account${existingAuths > 1 ? 's' : ''} Connected`}
            </span>
          </div>
        )}
        <Card.Description textAlign="center" style={{ paddingTop: !!existingAuths ? 10 : 25 }}>
          <Button
            fluid
            inverted={!!isSupported}
            color={!!isSupported && 'purple'}
            content={buttonText}
            {...{ onClick }}
          />
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

const Section = ({ title, integrations, authorizations }) => (
  <>
    <Header
      as="h2"
      content={title}
      dividing
      style={{ marginTop: 12 }}
    />
    <Card.Group itemsPerRow={4} style={{ paddingBottom: 0 }}>
      {integrations.map((integration, i) => (
        <IntegrationCard key={i} {...{ integration, authorizations }} />
      ))}
    </Card.Group>
  </>
);

const PlatformConnectGallery = () => {
  const [state, setState, Context] = useContext(CacheContext);
  const allIntegrations = Context.getCache('allIntegrations', getAllIntegrations) || [];
  const { integrations_categories, featured_integrations } = Context.getCache('artifacts', getArtifacts) || {};
  const integrationsCategories = JSON.parse(integrations_categories || '[]');
  const featuredIntegrationsList = JSON.parse(featured_integrations || '[]');
  const [authorizations, setAuthorizations] = useState(undefined);
  const [search, setSearch] = useState('');

  const onSearch = (e, { value }) => setSearch(value);

  const integrations = useMemo(() => {
    if (!allIntegrations?.length || !integrationsCategories?.length) return [];

    const featuredIntegrations = featuredIntegrationsList.map(x => allIntegrations.find(y => y.platform === x));

    const groupedIntegrations = integrationsCategories
      .filter(({ category }) => category.includes('advertising'))
      .map(({ category, title }) => {
        const categoryIntegrations = allIntegrations
          .filter(x => x.category === category && !x.deprecated && !featuredIntegrationsList.includes(x.platform))
          .sort((a, b) => a.display_name.localeCompare(b.display_name));
        return {
          category,
          title,
          integrations: categoryIntegrations,
        };
      });

    return [
      {
        category: 'featured',
        title: 'Featured',
        integrations: featuredIntegrations,
      },
      ...groupedIntegrations,
    ];
  }, [allIntegrations, integrationsCategories, featuredIntegrationsList, search]);

  const filteredIntegrations = useMemo(() => {
    if (!search) return integrations;
    const searchLower = search.toLowerCase();
    return integrations
      .map(({ category, title, integrations: _integrations }) => {
        const filtered = _integrations.filter(({ display_name }) => display_name.toLowerCase().includes(searchLower));
        return {
          category,
          title,
          integrations: filtered,
        };
      })
      .filter(({ integrations: _integrations }) => !!_integrations.length);
  }, [integrations, search]);

  // scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
    getAuthorizations()
      .then(setAuthorizations);
  }, []);

  return (
    <>
      <BasicViewHeader
        headerOnTop={false}
        header="Connect your Advertising Account"
        subheader={(
          <>
            Marketing Data
            &nbsp;
            <Icon name="angle right" style={{ margin: 0 }} />
            &nbsp;
            <Link to={routes.advertisingPlatforms}>
              Advertising Platforms
            </Link>
          </>
        )}
      />
      <FilterWrapper
        other={(
          <Input
            value={search}
            onChange={onSearch}
            style={{ width: 400 }}
            placeholder="Search"
            icon={{
              name: 'search',
              color: 'purple',
            }}
            iconPosition="left"
            primary
          />
        )}
      />
      {(!integrations.length || !authorizations) && (
        <Loader
          active
          inline="centered"
          style={{ marginTop: 'calc((100vh - 200px)/2)' }}
        />
      )}
      {authorizations && (
        <div>
          {filteredIntegrations.map(({ category, title, integrations: _integrations }) => (
            <Section
              key={category}
              title={title}
              integrations={_integrations}
              authorizations={authorizations}
            />
          ))}
        </div>
      )}
      {
        authorizations
        && <RoadmapPortalPromo />
      }
    </>
  );
};

export default PlatformConnectGallery;
