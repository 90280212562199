import React, { useState, useEffect } from 'react';
import { useStepsDisplay } from '../StepsSectionContext';
import { postRedshiftTable } from '../../../api/datalake';

import CreateTable from '../components/CreateTable';

const RedshiftCreateTable = () => {
  const [active, setActive] = useState(false)
  const [stepsState] = useStepsDisplay();

  useEffect(() => {
    setActive(stepsState[2])
  }, [stepsState])

  return (
    <CreateTable
      active={active}
      createTable={postRedshiftTable}
    />
  )
}

export default RedshiftCreateTable
