/**
 * Given an array of response curves and a function to update form field values, sets the initial field values for a form to 100 for each field in the response curves.
 * @param {Array} featureRecommendations - An array of objects representing recommendations for different features, each with a `key` and a `values` array of objects representing the curve, each with `spend_input` and `marginal_response` properties.
 * @param {Function} setFieldValues - A function that takes an object of field values and updates the state of the form.
 */
export const setInitialFieldValues = (featureRecommendations, setFieldValues) => {
  if (!featureRecommendations) {
    return;
  }

  const fieldValues = featureRecommendations.reduce(
    (obj, curr) => Object.assign(obj, {
      [curr.key]: { "value": curr['recommended'], "selection":  "recommended", ...curr}
    }),
    {}
  );

  setFieldValues(fieldValues);
};

/**
 * Returns a function that updates the field values with the given key and value.
 *
 * @param {Object} fieldValues - An object that holds the current field values.
 * @param {function} setFieldValues - A function that sets the field values to a new value.
 *
 * @returns {function} A function that takes a field name, a key, and a value, and updates the corresponding
 * value in the fieldValues object with the given key and value. If the key is "selection" and the value is
 * not "custom", the function also sets the "value" key in the field to the current value for the selected key.
 */
export const buildUpdateFieldValues = (fieldValues, setFieldValues) => (field, key, value) => {
  const current = fieldValues[field]
  const update = { [key]: value }

  if (key == "value" && current.selection != "custom") {
    // don't allow value to be set unless is custom
    delete update['value']
  }
  if (key == "selection" && value != "custom") {
    // set to selections default value
    update['value'] = current[value]
  }

  const future = { [field] : { ...current, ...update }}

  setFieldValues(future)
}

/**
 * Returns the date that is a specified number of days in the future, in YYYY-MM-DD format.
 *
 * @param {number} days - The number of days to add to the current date.
 *
 * @returns {string} The future date in YYYY-MM-DD format.
 */
export const getFutureDate = (days) => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + (days * 24 * 60 * 60 * 1000));
  const formattedDate = futureDate.toISOString().slice(0, 10);
  return formattedDate;
}

/**
 * Summarizes an array of forecast rows into a single object containing summary statistics.
 *
 * @param {Array} forecastRows - An array of objects representing individual forecasted fields.
 * @param {Object} featureStats - An object that contains the currentSpend for each feature.
 * @param {Number} baseline - The sum of the baseline effect for date range
 * @param {Number} numDays - The number of days in the forecasted range
 *
 * @returns {Object} An object containing the summarized forecast data.
 * The object has the following properties:
 * - dailySpend: The sum of current spend values for all forecasted fields.
 * - targetDailySpend: The sum of target values for all forecasted fields.
 * - totalSpend: The total projected spend for all forecasted fields over the period of numDays.
 * - predictedRevenue: The total projected revenue for all forecasted fields over the period of numDays.
 * - currentRevenue: The total current revenue for all forecasted fields over the period of numDays.
 * - revenueDiff: The difference between the projected and current revenue.
 * - revenuePercentDiff: The percentage difference between the projected and current revenue.
 * - revenuePercentDiffWithBaseline: The percentage difference between the projected and current revenue.
 * - predictedRoas: The projected return on ad spend (ROAS) based on the projected revenue and total spend.
 * - roasDiff: The difference between the projected ROAS and the current ROAS.
 */
export const summarizeForecastRows = (forecastRows, featureStats, baseline, numDays=30) => {
  if (!forecastRows) return
  const obj = forecastRows.reduce((acc, curr) => ({
    dailySpend: (acc.dailySpend || 0) + featureStats?.[curr.key].currentSpend || 0,
    targetDailySpend: (acc.targetDailySpend || 0) + parseInt(curr.targetValue),
    totalSpend: (acc.totalSpend || 0) + (parseInt(curr.targetValue) * numDays),
    predictedRevenue: (acc.predictedRevenue || 0) + (curr.revenueAtTargetValue * numDays),
    currentRevenue: (acc.currentRevenue || 0) + (curr.revenueAtCurrentValue * numDays),
  }), {})

  obj.revenueDiff = obj.predictedRevenue - obj.currentRevenue
  obj.revenuePercentDiff = obj.revenueDiff / ((obj.predictedRevenue + obj.currentRevenue) / 2)
  obj.revenuePercentDiffWithBaseline = obj.revenueDiff / ((obj.predictedRevenue + obj.currentRevenue + (2*baseline)) / 2)

  obj.predictedRoas = (obj.predictedRevenue + baseline) / obj.totalSpend
  obj.currentRoas = (obj.currentRevenue + baseline) / (obj.dailySpend * numDays)

  obj.roasDiff = obj.predictedRoas - obj.currentRoas
  return obj
}

/**
 * Calculates the baseline for a given seasonality.
 *
 * @param {Object[]} seasonality - An array of objects, each with a date and sum property representing the seasonality.
 * @param {number} startDaysFromNow - The number of days from now to start calculating the baseline.
 * @param {number} endDaysFromNow - The number of days from now to end calculating the baseline.
 * @returns {number} The baseline for the given seasonality.
 */
export const calculateBaselineFromSeasonality = (seasonality, startDaysFromNow=0, endDaysFromNow=30) => {
  if (!seasonality) return

  const startDate = getFutureDate(startDaysFromNow);
  const endDate  = getFutureDate(endDaysFromNow);

  const filtered = seasonality.filter(row => {
    const greaterThanStart = row.date >= startDate
    const lessThanEnd = row.date <= endDate
    return greaterThanStart && lessThanEnd
  })

  const baseline = filtered.reduce((p, row) => row.sum + p, 0)

  return baseline
}
