import React, { useContext, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Gateway from './components/SceneGateway';
import V2 from './components/SceneApp';
import V3 from './V3/views/AppV3';
import Documentation from './components/SceneDocumentation';
import Loading from './components/SceneLoading';
import Signup from './scenes/Signup';
import SignupFormEmbed from './scenes/SignupFormEmbed'
import Onboarding from './scenes/Onboarding';
import AdvertisingPlatformLastStep from './V3/views/AdvertisingPlatforms/FinalStep';
import { AppWrap } from '@rockerbox/styleguide';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Fetch from './components/parts/GenericFetch';
import { CacheContextProvider, CacheContext } from "./utils/CacheContext";
import Tracking from './utils/tracking';
import { StripeProvider } from './utils/stripe';
import { switchAccount } from "./utils/api";
import { Provider } from 'jotai';
import { DimmerContextProvider } from './V3/context/DimmerContext';
import { checkGoogleLogin } from './components/SceneApp/data.js'

const GATEWAY_PATHS = [
  "/set_password", "/login", "/set_password", "/password-forgot", "/password-success", "/test_login"
]

window.switchAccount = switchAccount

const App = () => {
  const [state, setState, Context] = useContext(CacheContext)
  const { user, account } = state

  const setUserResp = ({ response }) => setState({ user: response })
  const setAccountResp = ({ response }) => setState({ account: response })

  const showRoutes = user != undefined && account != undefined
  const isLoggedIn = !!user

  const { username } = user || {}
  const isAdminUser = (username || '').includes('@rockerbox.com')

  const { tier, requires_setup } = account || {}
  const isOnboarding = tier != "lite" && !!requires_setup;
  const redirectPath = !isLoggedIn ? "/login" : isOnboarding ? "/onboarding" : "/v3"

  const allowV2 = isAdminUser

  const handleCookieChange = (event) => {
    const changed = event?.changed[0]?.name;
    if (changed == "advertiser_name") {
      document.location.reload();
    }
  }

  useEffect(() => {
    try {
      cookieStore
      cookieStore.addEventListener('change', handleCookieChange);
      return () => {
        cookieStore.removeEventListener();
      };
    } catch {}
  }, [])

  useEffect(() => {
    if(account && username) {
      const cookieName = "google_login";
      const googleLoginCookieMatch = document.cookie.match(RegExp('(?:^|;\\s*)' + cookieName + '=([^;]*)'));
      const cookieValue = googleLoginCookieMatch ? googleLoginCookieMatch[1] : null;

      if(!cookieValue || cookieValue !== username) {
        try {
          checkGoogleLogin(account, username, isAdminUser);
        } catch {}
      }
    }
  }, [account, username])

  return (<>
    <Fetch endpoint="/user" success={setUserResp} error={() => setUserResp({ response: false })} />
    <Fetch endpoint="/account" success={setAccountResp} error={() => setAccountResp({ response: false })} />
    {showRoutes && <Switch>
      {/* public pages */}
      <Route path="/documentation" component={Documentation} />
      <Route path="/signup_form" component={SignupFormEmbed} />
      <Route path="/loading" component={Loading} />

      {/* login and signup pages */}
      {!isLoggedIn && [
        <Route path={GATEWAY_PATHS} component={Gateway} />,
        <Route path="/signup/:tier?" component={Signup} />
      ]}

      {/* onboarding */}
      {isLoggedIn && isOnboarding && [
        <Route path="/v3/data/marketing/advertising_platforms/auth/:platformName?"
          render={({ match }) => {
            const { platformName } = match.params
            return <Redirect to={`/onboarding/platforms/${platformName}`} />
          }}
        />,
        <Route path="/v3/data/customers/conversions/main">
          <Redirect to="/onboarding/store" />
        </Route>,
        <Route path="/onboarding/platforms/:platformName" component={AdvertisingPlatformLastStep} />,
        <Route path="/onboarding/:step?/:platformName?" component={Onboarding} />
      ]}

      {/* app */}
      {isLoggedIn && !isOnboarding && 
        <Route path="/v3" component={V3} />
      }

      {/* scale and admin users */}
      {isLoggedIn && allowV2 &&
        <Route path="/v2" component={V2} />
      }

      {/* default redirect */}
      <Route>
        <Redirect to={isLoggedIn ? redirectPath : `${redirectPath}?next=${window.location.pathname}`} />
      </Route>
    </Switch>}
  </>)
}

const AppContext = () => (
  <AppWrap>
    <Provider>
      <Suspense fallback="Loading...">
        <CacheContextProvider>
          <DimmerContextProvider>
            <Tracking />
            <StripeProvider>
              <App />
            </StripeProvider>
          </DimmerContextProvider>
        </CacheContextProvider>
      </Suspense>
    </Provider>
  </AppWrap>
)

export default AppContext
