import React from "react";
import { Dropdown, Button } from 'semantic-ui-react';
import { getBatchReports, updateBatchTrigger } from '../../utils/api';


const Cadence = props => {

  const [showSaveButton, setShowSaveButton] = React.useState(false)

  React.useEffect(()=> {
    setShowSaveButton(false);
  }, [props.groupId])

  const handleDropdown = (e, data) => {
    props.setTriggerCadence(data.value);
    setShowSaveButton(true);
  };

  const handleClick = () => {
  props.updateCadence();
  setShowSaveButton(false);
  };

  return (
    <React.Fragment>
      <Dropdown
        placeholder="cadence"
        search
        fluid selection options={props.optionsABC}
        value ={props.triggerValue}
        allowAdditions
        additionLabel='Custom cadence: '
        onChange={handleDropdown}
         />
        {showSaveButton && <Button onClick={handleClick}>Save</Button>}
    </React.Fragment>
  );
};

export default Cadence;
