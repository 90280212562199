import React, { useMemo } from 'react'
import { Segment } from 'semantic-ui-react'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip } from 'recharts'

const MiniChartCard = ({ data, title, formatter, dataKey, summaryType = 'sum', color = '#026cac', customTotal }) => {
  const total = useMemo(() => {
    if (!data) return 0
    if (summaryType === 'custom' && !!customTotal) return customTotal
    const total = data.reduce((acc, x) => acc + x[dataKey], 0)
    return summaryType === 'average' ? total / data.length : total
  }, [data])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          padding: 10,
          borderRadius: 4,
          background: '#fff',
          color: '#000',
          boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
        }}>
          <p>
            <b>{`${label}: `}</b>
            {formatter(payload[0].value)}
          </p>
        </div>
      )
    }
    return null
  }

  return (
    <Segment style={{ overflow: 'hidden' }}>
      <h3
        style={{
          marginBottom: 5,
          opacity: 0.6,
          fontSize: 14,
          fontWeight: 500,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {title}
      </h3>
      <div
        style={{
          color,
          fontSize: 22,
          fontWeight: 500,
        }}
      >
        {formatter(total)}
      </div>
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: 80,
          width: '100%',
        }}
      >
        <ResponsiveContainer
          width='100%'
          height='100%'
        >
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <XAxis hide dataKey='date' />
            <YAxis hide domain={[dataMin => (dataMin / 1.5), 'auto']} />
            <Area
              dataKey={dataKey}
              type='monotone'
              fill={color}
              fillOpacity={0.5}
              stroke={color}
              strokeWidth={0}
              dot={false}
            />
            <Tooltip
              cursor={false}
              content={<CustomTooltip />}
              position={{ y: 0 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Segment>
  )
}

export default MiniChartCard
