import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedal, faArrowTrendUp, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons'
import { faCompass } from '@fortawesome/free-regular-svg-icons'

import { HighlightsLoader } from './loaders'
import { CHANNEL_MAP } from './constants'
import { formatPercent } from './helpers'


const Section = ({ icon, color, children }) => (
  <div
    style={{
      display: 'flex',
      width: '30%',
      minWidth: 370,
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        as='i'
        icon={icon}
        style={{ height: 30, color }}
      />
    </div>
    <div style={{ paddingLeft: 15 }}>
      {children}
    </div>
  </div>
)


const Divider = () => (
  <div
    style={{
      margin: '0 20px',
      width: 1,
      height: 45,
      background: '#efefef',
    }}
  />
)


const Highlights = ({ loading, data }) => {
  const { largest, biggestChange, mostStable } = data || {}

  if (!!loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'center',
          padding: 5,
        }}
      >
        <HighlightsLoader />
        <div>
          <Divider vertical />
        </div>
        <HighlightsLoader />
        <div>
          <Divider vertical />
        </div>
        <HighlightsLoader />
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center',
        padding: 5,
      }}
    >
      <Section
        icon={faMedal}
        color={CHANNEL_MAP[largest.channel].color}
      >
        Marketers are spending more on <strong>{CHANNEL_MAP[largest.channel].name}</strong> than any other channel.&nbsp;
        {`${largest.yearlyChange < 0 ? 'However, over' : 'Over'}`} the past year, its average share of budget has <strong>{`${largest.yearlyChange > 0 ? 'in' : 'de'}creased`} from {formatPercent(largest.prevYearValue)} to {formatPercent(largest.prevMonthValue)}</strong>.
      </Section>
      <div>
        <Divider vertical />
      </div>
      <Section
        icon={biggestChange.yearlyChange > 0 ? faArrowTrendUp : faArrowTrendDown}
        color={CHANNEL_MAP[biggestChange.channel].color}
      >
        On average, <strong>{CHANNEL_MAP[biggestChange.channel].name}</strong> saw the biggest change over the past year, with a <strong>{`${biggestChange.yearlyChange > 0 ? 'in' : 'de'}crease`}</strong> in its share of overall marketing budget from <strong>{formatPercent(biggestChange.prevYearValue)} to {formatPercent(biggestChange.prevMonthValue)}</strong>.
      </Section>
      <div>
        <Divider vertical />
      </div>
      <Section
        icon={faCompass}
        color={CHANNEL_MAP[mostStable.channel].color}
      >
        <strong>{CHANNEL_MAP[mostStable.channel].name}</strong> has remained the most consistent, with an average <strong>{formatPercent(Math.abs(mostStable.yearlyPctChange))} change</strong> in share of budget compared to last year.
      </Section>
    </div>
  )
}

export default Highlights
