import React, { Component } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';


export const CostCard = (props) => {
  const { item, updateStateArrayRowField } = props;

  return (
    <Segment secondary>
      <Form.Input label="Name:" value={item.name} onChange={updateStateArrayRowField("costs", "name")(item)}/>
      <Form.Group widths="equal">
        <Form.Input label="Start date:" value={item.start_date} onChange={updateStateArrayRowField("costs", "start_date")(item)}/>
        <Form.Input label="End date:" value={item.end_date} onChange={updateStateArrayRowField("costs", "end_date")(item)}/>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input label="Distribution:" value={item.distribution} onChange={updateStateArrayRowField("costs", "distribution")(item)}/>
        <Form.Input label="Cost" value={item.cost} onChange={updateStateArrayRowField("costs", "cost")(item)}/>
      </Form.Group>
    </Segment>
  )
}

export const PromoCodeCard = (props) => {
  const { item, updateStateArrayRowField } = props;
  return (
    <Segment secondary>
      <Form.Input label="Name:" value={item.name} onChange={updateStateArrayRowField("promo_codes", "name")(item)}/>
      <Form.Group widths="equal">
        <Form.Input label="Conversion field name:" value={item.conversion_field_name} onChange={updateStateArrayRowField("promo_codes", "conversion_field_name")(item)}/>
        <Form.Input label="Conversion value:" value={item.conversion_field_value} onChange={updateStateArrayRowField("promo_codes", "conversion_field_value")(item)}/>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input label="Fixed unit cost:" value={item.fixed_unit_cost} onChange={updateStateArrayRowField("promo_codes", "fixed_unit_cost")(item)}/>
        <Form.Input label="Revenue share:" value={item.rev_share_percent} onChange={updateStateArrayRowField("promo_codes", "rev_share_percent")(item)}/>
      </Form.Group>
    </Segment>
  )

}

const inputPositionNumbers = [1,2,3,4,5]

export class EventCard extends Component {
  state = {
    showAs: 1
  }

  componentDidMount() {
    const { item } = this.props;

    const showAs = inputPositionNumbers.reduce((p,c) => {
      if(item[`tier_${c}`]) {
        p = c
      }
      return p
    }, 1)

    this.setState({showAs})
  }

  handleAddField = (key) => {
    const { showAs } = this.state;

    this.setState({[key]: showAs+1});
  }

  render() {
    const { item, updateStateArrayRowField } = this.props;
    const { showAs } = this.state

    return (
      <Segment secondary>
        <Form.Input label="Response:" value={item.name} onChange={updateStateArrayRowField("events", "name")(item)}/>
        <div className="field" style={{margin: 0}}>
          <label>Current:</label>
        </div>
        <Form.Group>
          { inputPositionNumbers.slice(0, showAs).map(value => {
            const key = "tier_" + value;

            return <Form.Input placeholder={key} value={item[key]} onChange={updateStateArrayRowField("events", key)(item)}/>
          })}
          <Button icon="plus" onClick={() => this.handleAddField("showAs")} style={{display: showAs == 5 ? 'none' : 'inline-block'}} as="span"/>
        </Form.Group>
      </Segment>
    )
  }
}
