import * as aq from "arquero";
import { tableToIPC } from "apache-arrow";

const formatDataUrl = (date, dataset, identifier) => {
  const isCachedDataset = "cache"
  const dataBase = `/${isCachedDataset}/v3/attribution_events`;

  return `${dataBase}?start_date=${date}&end_date=${date}&identifier=${identifier}&keys=${dataset}`
}

const getCacheIdFromDetails = (details, key) => {
  // deal with the difference between cache and data
  const { cache_id } = details
  if (typeof(cache_id[key]) == "number") return cache_id[key]
  return Object.values(cache_id[key])[0]
}

const fetchToBuffer = async (promise) => {
  const resp = await promise;
  const { details, response } = resp;

  const [key, ...other] = details.keys
  const cache_id = getCacheIdFromDetails(details, key)
  const data = response[key]
  
  const arrow = aq.from(data).toArrow()
  return tableToIPC(arrow)
  
}

export const getAsBuffer = async (date, dataset, identifier, currentCacheId) => {
  const url = formatDataUrl(date, dataset, identifier)
  const promise = fetch(url, { credentials: "include" })
    .then(resp => resp.json())
  const ipcBuffer = await fetchToBuffer(promise);
  
  return ipcBuffer
}
