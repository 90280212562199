import React, { useCallback } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import {
  googleCloudBucketStore,
  statusStore,
  warehouseStore
} from "./zustandStore";
import { ContentCard } from "@rockerbox/styleguide";
import { STATUS_FINISHED, GCP_REGION } from "./constants";

const gcpSelector = state => [
  state.region,
  state.account,
  state.accountType,
  state.onFieldChange,
  state.submitSetup
];
const statusSelector = state => [
  state.setStatus,
  state.setupFormLoading,
  state.changeSetupFormLoading
];
const warehouseSelector = state => [state.fetchWarehouse];

const GCBucketForm = props => {
  const [setStatus, setupFormLoading, changeSetupFormLoading] = statusStore(statusSelector);
  const [fetchWarehouse] = warehouseStore(warehouseSelector);
  const [
    region,
    account,
    accountType,
    onFieldChange,
    submitSetup
  ] = googleCloudBucketStore(gcpSelector);

  const { username, segment } = props;

  const onSubmit = () => {
    changeSetupFormLoading(true);

    const body = {
      region,
      gc_account: account,
      gc_account_type: accountType,
      username: username,
      filter_id: segment["filter_id"],
      first_reporting_date: segment["first_reporting_date"]
    };

    submitSetup(body)
      .then(() => fetchWarehouse())
      .then(() => setStatus(STATUS_FINISHED))
      .finally(() => changeSetupFormLoading(false));
  };

  const validateBeforeSubmit = () => {
    return bucket_name && region;
  };

  const accountTypeOptions = [
    {
      text: "User",
      key: "user",
      value: "user"
    },
    {
      text: "Service Account",
      key: "serviceAccount",
      value: "serviceAccount"
    }
  ];

  const onRegionChange = useCallback(onFieldChange("region"));
  const onAccountChange = useCallback(onFieldChange("account"));
  const onAccountTypeChange = useCallback(onFieldChange("accountType"));
  return (
    <ContentCard title="Continue your setup" loading={setupFormLoading}>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Field>
            <Form.Dropdown
              label="Storage bucket Region"
              onChange={onRegionChange}
              options={GCP_REGION}
              value={region}
              selection
              fluid
            />
            <Message
              floating
              content="Bucket's region should match with the Big Query region."
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <Form.Dropdown
              label="Account Type"
              onChange={onAccountTypeChange}
              options={accountTypeOptions}
              value={accountType}
              selection
              fluid
            />
            <Message
              floating
              content="The account type, it can be either a user or a service account."
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Form.Input
              label="Account"
              onChange={onAccountChange}
              value={account}
            />
            <Message
              floating
              content="The account that will consume the newly created bucket."
            />
          </Form.Field>
        </Form.Group>
        <Form.Button primary>{"Setup"}</Form.Button>
      </Form>
    </ContentCard>
  );
};

export default GCBucketForm;
