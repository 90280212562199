import React, { useContext, useMemo } from 'react'
import {
  SparklesIcon,
  CashIcon,
  FilterIcon,
  UserAddIcon,
  MapIcon,
} from '@heroicons/react/outline'

import { ContentCard, Checklist } from '@rockerbox/styleguide'
import * as routes from '../../baseRoutes'

import CacheContext from '../../../utils/CacheContext'
import { getArtifacts } from '../../api/attribution'


const ProductUpdates = (props) => {
  const [state, setState, Context] = useContext(CacheContext)
  const { intercom_surveys } = Context.getCache(`artifacts`, getArtifacts) || {}

  const intercomSurveyId = useMemo(() => {
    if (!intercom_surveys) return null
    const intercomSurveys = JSON.parse(intercom_surveys || '{}')
    const survey = intercomSurveys['general_feedback'] || {}
    const intercomEnv = window.env === 'prod' ? 'prod' : 'dev'
    const surveyId = survey[intercomEnv]
    return surveyId
  }, [intercom_surveys])

  const showTitle = props.showTitle == undefined ? true : props.showTitle;
  // TODO: pull these updates from an API or external service
  return (
    <ContentCard hasTable title={showTitle ? 'Announcements & Updates' : null} >
      <Checklist
        items={[
          {
            icon: <SparklesIcon />,
            title: "Welcome to the newly redesigned Rockerbox platform!",
            description: "Let us know what you think! You can switch back to the Classic UI at anytime using the menu in the top right corner",
            onClick: () => {
              !!window.Intercom && window.Intercom('startSurvey', intercomSurveyId)
            },
          },
          {
            icon: <CashIcon />,
            title: "Platform-reported metrics and spend data",
            description: "View performance metrics reported directly by your advertising platforms, with powerful filtering in an easy-to-use dashboard",
            link: routes.advertisingPlatforms,
          },
          {
            icon: <MapIcon />,
            title: "Marketing Paths To Conversion",
            description: "See and measure the effectiveness of the most common touchpoints that your customers interact with before they convert.",
            link: routes.marketingPaths,
          },
          {
            icon: <FilterIcon />,
            title: "Marketing funnel position analysis",
            description: "Understand the role of each your channels and what your channel mix looks like at each step in the funnel",
            link: routes.funnelPosition,
          },
          {
            icon: <UserAddIcon />,
            title: "New website visitors analytics",
            description: "Identify the top-of-funnel marketing that is driving net-new visitors to your website",
            link: routes.newVisitors,
          },
        ]}
      />
    </ContentCard>
  )
}

export default ProductUpdates
