import React from 'react'
import { Loader } from 'semantic-ui-react'

const InputFormPricing = ({estimatedPrices, proratedPrices, promoCode, codePending, isEdit, pricePending}) => {

    if (isEdit) {
        return null
    } else if (promoCode){
        if(codePending){
            return(
                <>
                    <p className='monthly-price'> Based on your monthly advertising spend, your price would be: </p>
                    <div className='monthly-price-value'>
                        $ <Loader active inline size='small'></Loader> per month
                    </div>
                    <hr/>
                    <p className='due-today'> Amount due today: </p>
                    <div><div className='due-today-value-number'>$ <Loader active inline size='small'></Loader></div> <div className='due-today-value-text'> (prorated for remainder of month)</div></div>
                </>
            )
        } else{
            return (
                <>
                    <p className='monthly-price'> Based on your monthly advertising spend, your price would be: </p>
                    <p className='monthly-price-value'> <span style={{textDecoration: 'line-through'}}>${estimatedPrices["original"]}</span><span> ${estimatedPrices["discounted"]} per month</span></p>
                    <hr/>
                    <p className='due-today'> Amount due today: </p>
                    <p className='due-today-value-number'> <span style={{textDecoration: 'line-through'}}>${proratedPrices["original"]}</span><span> ${proratedPrices["discounted"]}</span></p>
                    <div className='due-today-value-text-with-promo'>(prorated for remainder of month)</div></>
                );
        }
    } else {
        if(pricePending){
            return(
                <>
                    <p className='monthly-price'> Based on your monthly advertising spend, your price would be: </p>
                    <div className='monthly-price-value'>
                        $ <Loader active inline size='small'></Loader> per month
                    </div>
                    <hr/>
                    <p className='due-today'> Amount due today: </p>
                    <div><div className='due-today-value-number'>$ <Loader active inline size='small'></Loader></div> <div className='due-today-value-text'> (prorated for remainder of month)</div></div>
                </>
            )
        } else {
                return (
                    <>
                        <p className='monthly-price'> Based on your monthly advertising spend, your price would be: </p>
                        <p className='monthly-price-value'>${estimatedPrices["original"]} per month</p>
                        <hr/>
                        <p className='due-today'> Amount due today: </p>
                        <div><div className='due-today-value-number'>${proratedPrices["original"]}</div> <div className='due-today-value-text'> (prorated for remainder of month)</div></div>
                    </>
                );
        }
    }
  };
  export default InputFormPricing;
  