import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'semantic-ui-react';
import Split from 'react-split';
import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/tomorrow';
import 'brace/theme/monokai';

import { changed, rand, buildMsg } from './helpers.js';

const OPTIONS = ["Results","stderr","stdout"].map(text => {
  return { text, key: text, value: text }
})

const ResultsBuffer = ({outputBuffer, rightBuffer, stderr, stdout}) => {
  if (outputBuffer == "stderr") return (<pre>{ stderr }</pre>)
  if (outputBuffer == "stdout") return (<pre>{ stdout }</pre>)
  
  const isHTML = rightBuffer && rightBuffer.length > 0 && rightBuffer[0] == "<"

  if (isHTML) {
    let content = rightBuffer
    content = rightBuffer.replace(/<table border="1"/g,'<table class="ui celled compact padded striped table"')
    content = content.replace(/<tr style="text-align: right;">/g,'<tr>')
    if (content.indexOf('<div style="max-height:1000') > -1) {
      const table = content
        .replace("max-height:1000","max-height:700")
        .split("</table>")[0] + "</table></div>"
      const summary = content.split("</table>")[1]
        .replace("</div>","")
        .replace("<p>","<h5 style='margin:0;padding:1em;padding-left:.5em;font-weight:bold'>")
        .replace("</p>","</h5>")
      content = `${summary}${table}`
    }
    return <div dangerouslySetInnerHTML={{__html: content}} />
  }
  return <AceEditor
            mode="bash"
            theme="monokai"
            setOptions={{
              highlightGutterLine: false,
              showPrintMargin: false,
              showGutter: false,
              readOnly: true,
              highlightActiveLine: false,
              fontSize: 12,
              wrapEnabled: true
            }}
            width={'100%'}
            height={'600px'}
            value={rightBuffer}
          />
}

class SplitEditor extends Component {

  state = {
    input: ""
  }

  updateResultsBuffer = (_, { value }) => {
    this.props.setOutputBuffer(value)
  }

  updateBuffer = (input) => {
    this.setState({ input })
  }
  getBuffer = () => {
    this.props.getBuffer()
      .then(resp => {
        const buffers = resp.response
        if (buffers.length > 0) {
          this.setState({ input: buffers[0].body })
          this.props.autoRun && this.props.runBuffer(buffers[0].body)
        }
      })
  }
  saveBuffer = () => {
    const { runBuffer, saveBuffer } = this.props;
    saveBuffer(this.state.input)
    runBuffer(this.state.input)
  }

  componentDidMount() {
    setTimeout(() => { 
      try{ this.refs.reactAceComponent.editor.resize() } catch(e) {}
    }, 10)
    const { defaultInput } = this.props;
    this.setState({ input: defaultInput })
    this.getBuffer()
  }

  componentDidUpdate(prevProps, prevState) {
    if (changed(prevProps, this.props, "defaultInput", (input) => this.setState({ input }))) {
      this.getBuffer()
    }
  }

  render() {

    const { saveBuffer } = this;
    const { input } = this.state;
    const { isFixedInput, beforeAce, leftTitle, rightBuffer, outputBuffer  } = this.props;
    const { runBuffer, stderr, stdout } = this.props;

    return (
      <Split 
        sizes={[50, 50]}
        minSize={200}
        expandToMin={false}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        style={{"display":"flex"}}
      >
        <div>
          <h3 style={{background:"#FCFCFC",margin:0,padding:".8em"}}>
            { leftTitle }
            <Button style={{float:"right"}} size='mini' onClick={saveBuffer}>Run</Button>
          </h3>
          { beforeAce }
          <AceEditor
            ref="reactAceComponent"
            mode="python"
            theme="tomorrow"
            setOptions={{
              highlightGutterLine: false,
              showPrintMargin: false,
              showGutter: true,
              showLineNumbers: !isFixedInput,
              readOnly: isFixedInput,
              highlightActiveLine: false,
              fontSize: 12,
              wrapEnabled: true
            }}
            width={'100%'}
            height={isFixedInput ? '450px' : '600px'}
            value={input}
            onChange={this.updateBuffer}
            commands={[
              {
                name: "showKeyboardShortcuts",
                bindKey: {win: "Ctrl-Enter", mac: "Shift-Enter"},
                exec: function(editor) { saveBuffer() }
              }
            ]}
          />
          <h3>
          </h3>
        </div>
        <div style={{marginLeft:"5px"}}>
          <h3 style={{background:"#27363F",margin:0,padding:".8em",color:"#E0E0E0"}}>
            <Dropdown 
              value={outputBuffer} 
              options={OPTIONS}
              onChange={this.updateResultsBuffer}
            />
          </h3>
          <ResultsBuffer {...{outputBuffer, rightBuffer, stderr, stdout}} />
          
        </div>
      </Split>
    )
  }
}

SplitEditor.propTypes = {
  setOutputBuffer: PropTypes.func
}

export default SplitEditor;
