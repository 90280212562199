import { useContext } from 'react';
import { CacheContext } from '../../utils/CacheContext';
import { getTierColors, getAccount, getAccountCurrency, getUser, getAuthorizations, getAllIntegrations } from '../api/account';
import { getSegments } from '../api/attribution';

export const useGlobalState = () => {
  const [state, setState, Context] = useContext(CacheContext);
  const account = (Context.getCache('account', getAccount) || {});
  const authorizations = Context.getCache('authorizations', getAuthorizations);
  const currencyCode = Context.getCache('currency_code', getAccountCurrency) || undefined;
  const integrations = Context.getCache('allIntegrations', getAllIntegrations);
  const segments = Context.getCache('segments', getSegments) || [];
  const tierColors = Context.getCache('tierColors', getTierColors) || undefined;
  const user = Context.getCache('user', getUser) || {};

  return {
    state, setState, Context,
    account,
    authorizations,
    currencyCode,
    integrations,
    segments,
    tierColors,
    user,
  };
};
