import React, { useEffect, useRef } from "react"

import { platformPerformanceFirstReportingDate } from "../../utils/time"

// NOTE: this is pretty big anti-pattern. lets not copy this unless there is no other choice...
export const initializeViewChangeWatcher = (view, resetTiers, setFirstReportingDate) => {

  const notInitialRender = useRef(false)
  useEffect(() => {
   //if(notInitialRender.current) {
   //  resetTiers()
   //} else {
   //  notInitialRender.current = true
   //}

   if(view == "platform") {
     setFirstReportingDate(platformPerformanceFirstReportingDate)
   }
  }, [view])
}
