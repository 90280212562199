import React, { useState, useEffect, useContext } from 'react'
import { SidebarLayout } from '@rockerbox/styleguide'
import CacheData from './helpers/cache'
import CacheContext from '../../utils/CacheContext'
import { getUser, getOnboardingSteps, saveOnboardingStep, postRequiresSetup } from '../../V3/api/account'
import { track } from '../../utils/tracking'
import Start from './parts/Start'
import Survey from './Survey'
import Platforms from './Platforms'
import Tracking from './Tracking'
import Store from './Store'
import PixelCNameView from './parts/CName'
import InviteUsers from './parts/Invite'
import Payment from './Payment'
import Finish from './parts/Finished'
import CancelOnboardingModal from './parts/CancelOnboardingModal'
import AdvertisingPlatformLastStep from '../../V3/views/AdvertisingPlatforms/FinalStep'
import { Toaster } from 'react-hot-toast'
import { Icon, Button } from 'semantic-ui-react'
import { getSteps } from './helpers/Step'

const Footer = ({ openIntercomMessenger, modalOpen, setModalOpen, jobRunning, setJobRunning, tier }) => (
  <div className='onboarding-footer'>
    {tier === 'starter' && 
    <div onClick={() => setModalOpen(true)}>
      <Icon name="times circle outline"/>
      Exit Onboarding
    </div>}
    <Button primary onClick={openIntercomMessenger}>
      <Icon name="question circle outline"/>
      Need Help? Chat with Support
    </Button>
    <CancelOnboardingModal
      open={!!modalOpen}
      onClose={() =>{if (!jobRunning) {setModalOpen(false)} else {/*pass*/}}}
      {...{ jobRunning, setJobRunning }}
    />
  </div>
)

const OnboardingPage = ({ history, location, match }) => {
  const { step, platformName } = match.params

  const [state, setState, Context] = useContext(CacheContext)
  const user = (Context.getCache(`user`, getUser) || {})
  const { username } = user
  const { account } = state
  const { advertiser_name } = account || {}

  const [completedOnboardingSteps, setCompletedOnboardingSteps] = useState([]);
  const [billingComplete, setBillingComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [jobRunning, setJobRunning] = useState(false);
  const [error, setError] = useState(false);
  const { tier } = account || {}

  const STEPS = getSteps(tier)

  useEffect(() => {
    getOnboardingSteps().then(res => {
      const completedSteps = res.map(r => r.step);
      if (completedSteps.includes('payment')) setBillingComplete(true);
      setCompletedOnboardingSteps(completedSteps);
      const allStepsComplete = STEPS.every(s => completedSteps.includes(s.key))
      if (!step) setActiveStep(allStepsComplete ? "finished" : "start");
    })
  }, [])

  const openIntercomMessenger = event => {
    event.preventDefault()
    if (window.Intercom) window.Intercom("show")
    return false
  }

  const checkComplete = stepKey => {
    return completedOnboardingSteps.includes(stepKey);
  }

  const setActiveStep = step => history.push(`/onboarding/${step}`)

  const completeStep = () => {
    saveOnboardingStep({ step }).then(() => {
      if (step == 'payment') setBillingComplete(true);
      const nextStep = getNextStep(step, 1);
      setCompletedOnboardingSteps([...completedOnboardingSteps, step])
      setActiveStep(nextStep)
    });
  }

  const getNextStep = (step, increment) => {
    const nextStepPosition = (step && step !== "start") ? STEPS.map(({ key }) => key).indexOf(step) + increment : 0
    const nextStep = nextStepPosition < STEPS.length ? STEPS[nextStepPosition].key : "finished"
    return nextStep
  }

  const finishOnboarding = () => {
    setLoading(true)
    track("onboarding.finished")
    postRequiresSetup({requires_setup: false}).then(() =>
      window.location.href = "/v3"
    )
  }

  const [stepTracked, setStepTracked] = React.useState(undefined)
  const trackStepProgress = step => {
    if (step && step != stepTracked){
      track("onboarding.step", { step })
      setStepTracked(step)
    }
  }

  const getActiveComponent = activeStep => {
    if (!!activeStep && activeStep != 'start' && activeStep != "finish") {
      trackStepProgress(activeStep)
    }

    const methods = { completeStep, setActiveStep, finishOnboarding }

    if (platformName) return <div className='onboarding-select-account'><AdvertisingPlatformLastStep history={history} redirectLocation="/onboarding/platforms" /></div>
    if (activeStep === 'survey') return <Survey {...methods} />;
    if (activeStep === 'store') return <Store {...{...methods, advertiser: advertiser_name, tier}} />;
    if (activeStep === 'platforms') return <Platforms {...{ ...methods, advertiser: advertiser_name, tier }} />;
    if (activeStep === 'tracking') return <Tracking {...{...methods, advertiser: advertiser_name, completedOnboardingSteps, tier }} />;
    if (activeStep === 'cname') return <PixelCNameView {...{ ...methods, advertiser: advertiser_name }} />;
    if (activeStep === 'invite') return <InviteUsers {...{ ...methods, username, advertiser: advertiser_name }} />;
    if (activeStep === 'payment') return <Payment {...{...methods, completedOnboardingSteps}} />;
    return null;
  }

  const activeComponent = getActiveComponent(step)

  if (!step) return null;

  return (
    <div className="onboarding-view">
      <CacheData />
      {step === 'start' ?
        <Start {...{completeStep, completedOnboardingSteps, setActiveStep, tier}} />
        :
        step === 'finished' ?
        <Finish {...{completeStep, completedOnboardingSteps, setActiveStep, finishOnboarding, loading, tier}} />
        :
        <SidebarLayout
          loading={!step}
          middle={
            STEPS.map(stepObj => (
              <SidebarLayout.StepNav
                {...stepObj}
                complete={checkComplete(stepObj.key)}
                active={step === stepObj.key}
                onClick={() => setActiveStep(stepObj.key)}
              />
            ))
          }
          footer={<Footer {...{ openIntercomMessenger, modalOpen, setModalOpen, jobRunning, setJobRunning, error, setError, tier }} />}
        >
          {activeComponent}
        </SidebarLayout>
      }
      <Toaster position='top-center' reverseOrder={false} />
    </div>
  )
}

export default OnboardingPage
