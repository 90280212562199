import moment from 'moment'


// >>>>>> CONSTANTS <<<<<<<<
export const PRESET_DATE_RANGES = [
  { text: "7D", numDays: 7 },
  { text: "30D", numDays: 30 },
  { text: "60D", numDays: 60 },
  { text: "90D", numDays: 90 }
]

export const PRESET_DATE_RANGES_V3 = [
  { text: "Yesterday", numDays: 1 },
  { text: "Last 7 days", numDays: 7 },
  { text: "Last 14 days", numDays: 14 },
  { text: "Last 21 days", numDays: 21 },
  { text: "Last 30 days", numDays: 30 },
  { text: "This month-to-date", numDays: "current_month" },
  { text: "Previous calendar month", numDays: "prev_month" },
]

export const PRESET_ADHOC_EXPORTS_DATE_RANGES = [
  ...PRESET_DATE_RANGES_V3,
  { text: "Previous Quarter", numDays: 'previous_quarter' },
  { text: "Quarter-to-date", numDays: 'quarter_to_date' },
  { text: "Year-to-date", numDays: 'year_to_date' },
]

// >>>>>> DATES <<<<<<<<<
export const firstReportingDate = moment.utc("2021 08 1").utcOffset(-5);
export const yesterday = moment.utc().utcOffset(-5).subtract(1, "days").format("YYYY-MM-DD");
export const weekAgo = moment.utc().utcOffset(-5).subtract(7, "days").format("YYYY-MM-DD");
export const monthAgo = moment.utc().utcOffset(-5).subtract(30, "days").format("YYYY-MM-DD");
export const twoMonthsAgo = moment.utc().utcOffset(-5).subtract(60, "days").format("YYYY-MM-DD");
export const startOfMonth = moment.utc().utcOffset(-5).startOf("month").format("YYYY-MM-DD");
export const startOfPreviousMonth = moment.utc().utcOffset(-5).subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
export const endOfPreviousMonth =  moment.utc().utcOffset(-5).subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
export const platformPerformanceFirstReportingDate = moment.utc("2022 01 01").utcOffset(-5);
export const startOfPreviousQuarter = moment.utc().utcOffset(-5).subtract(1, 'quarter').startOf("quarter").format("YYYY-MM-DD");
export const endOfPreviousQuarter = moment.utc().utcOffset(-5).subtract(1, 'quarter').endOf("quarter").format("YYYY-MM-DD");
export const startOfQuarter = moment.utc().utcOffset(-5).startOf("quarter").format("YYYY-MM-DD");
export const startOfYear = moment.utc().utcOffset(-5).startOf("year").format("YYYY-MM-DD");

// >>>>>>> HELPERS <<<<<<<<<
export const daysAgo = (num) => moment.utc().utcOffset(-5).subtract(num, "days").format("YYYY-MM-DD")
export const isDaysAgo = (value, num) => value == daysAgo(num)

export const numDaysAgo = (startDate = monthAgo, endDate = yesterday, preset = false, adhoc = false) => {
  const start = moment(startDate)
  const end = moment(endDate)
  const diff = end.diff(start, 'days') + 1

  if (preset) {
    if (endDate === yesterday && (diff === 1 || diff === 7 || diff === 14 || diff === 21 || diff === 30)) return diff
    if (startDate === startOfPreviousMonth && endDate === endOfPreviousMonth) return 'prev_month'
    if (startDate === startOfMonth && endDate === yesterday) return 'current_month'

    if (adhoc) {
      if (startDate === startOfPreviousQuarter && endDate === endOfPreviousQuarter) return 'previous_quarter'
      if (startDate === startOfQuarter && endDate === yesterday) return 'quarter_to_date'
      if (startDate === startOfYear && endDate === yesterday) return 'year_to_date'
    }
    if (endDate !== yesterday || (diff !== 1 && diff !== 7 && diff !== 14 && diff !== 21 && diff !== 30)) return 'custom'
  }
  return diff
}

// >>>>>>> REFORMAT <<<<<<<<<<
export const reformatDateMMDDYYYY = (date) => moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
export const reformatDateYYYYMMDD = (date) => moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')

export const readableDateFromPreset = (startDate, endDate) => {
  const checkDaysAgo = numDaysAgo(startDate, endDate, true)
  if (checkDaysAgo !== 'custom') {
    return PRESET_DATE_RANGES_V3.find(({ numDays }) => numDays === checkDaysAgo).text
  } else {
    return `${reformatDateMMDDYYYY(startDate)} - ${reformatDateMMDDYYYY(endDate)}`
  }
}
