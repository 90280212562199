import { openDB } from "idb";


const getDB = () => openDB("api_cache", 3, {upgrade(upgradeDb) {
  if (!upgradeDb.objectStoreNames.contains('url_cache_id')) upgradeDb.createObjectStore('url_cache_id', {keyPath: "url"});
  if (!upgradeDb.objectStoreNames.contains('cache_id_data')) upgradeDb.createObjectStore('cache_id_data', {keyPath: "cache_id"});
}})

export const getMetaData = (id, startDate, endDate, dataset) => {
  const statsBase = "/data/v3/stats/attribution_events"
  const statsUrl = `${statsBase}?start_date=${startDate}&end_date=${endDate}&identifier=${id}&keys=${dataset}`;

  return fetch(statsUrl, { credentials: "include"})
    .then(response => response.json())
    .then(({ response: { current }}) => current.reduce((p, { date, id }) => Object.assign(p, {[date]: id}), {}) )
}

const formatDataUrl = (id, date, dataset) => {
  const isCachedDataset = dataset == "compiled_mta_tiers" ? "cache" : "data";
  const dataBase = `/${isCachedDataset}/v3/attribution_events`;

  return `${dataBase}?start_date=${date}&end_date=${date}&identifier=${id}&keys=${dataset}`
}

export const get = (id, date, dataset) => {

  const url = formatDataUrl(id, date, dataset)

  return fetch(url, { credentials: "include" })
    .then(response => response.json())

}

const getAndCache = (id, date, dataset) => {

  const url = formatDataUrl(id, date, dataset)

  return get(id, date, dataset)
    .then((raw, request) => {
      const { details: { keys, cache_id } = {}, response } = raw;
  
      if (keys.length === 1) {
        const key = keys[0];
        const _cache_id = (typeof(cache_id[key]) == "number") ? cache_id[key] : Object.values(cache_id[key])[0];
        const data = response[key];
  
        getDB().then(function(db) {
          const tx = db.transaction(['url_cache_id', 'cache_id_data'], 'readwrite');
          const url_cache = tx.objectStore('url_cache_id');
          const cache_data = tx.objectStore('cache_id_data');
          url_cache.put({ url, cache_id: _cache_id })
          cache_data.put({ cache_id: _cache_id, data })
          return tx.complete;
        })
  
        return data;
      }
  
      return raw["response"];
    })
}

const getFromCacheOrRemote = (id, date, dataset, currentCacheId) => {

  return new Promise((resolve, reject) => {

    getDB()
      .then(function(db) {
        const tx = db.transaction(['url_cache_id','cache_id_data'], 'readwrite');
        const url_cache = tx.objectStore('url_cache_id');
        const cache_data = tx.objectStore('cache_id_data');
        const url = formatDataUrl(id, date, dataset);

        url_cache.get(url)
          .then(response => {
            const { cache_id = false } = response || {}
            
            if (cache_id == currentCacheId) {
              cache_data.get(cache_id)
                .then(({ data }) => resolve(data))
              return true
            }
            if (cache_id && cache_id != currentCacheId) {
              cache_data.delete(cache_id)
              url_cache.delete(url)
            }
            getAndCache(id, date, dataset)
              .then(resolve)
          })
      })
  })
}

export function getDatasetViaCache(id, startDate, endDate, dataset="compiled_mta_tier") {

  return getDB()
    .then(db => {
      return new Promise((resolve, reject) => {

        getMetaData(id, startDate, endDate, dataset)
          .then(dateObj => {
            return Object.entries(dateObj).map(([date, currentCacheId]) => {
              return getFromCacheOrRemote(id, date, dataset, currentCacheId)
            })
          })
          .then(promises => {
            Promise.all(promises)
              .then(dates => {
                resolve(Object.values(dates).flat())
              })
          })
      })
  })
}
