import React, { Component } from 'react';
import shallow from 'zustand/shallow'
import { Segment, Form, Message, Popup, Icon, Button } from 'semantic-ui-react';
import { ColumnLayout, ContentCard, DataTable } from '@rockerbox/styleguide';
import { getConversionDataset } from '../../utils/api';
import { postEntityData, updateEntityData } from '../../utils/api';
import { entityStore } from './entityStore';
import FindPromoCode from './FindPromoCode';
import SelectConversion from '../SelectSegment';

export const FormDropdown = (props) => <Form.Dropdown {...props} select label={props.label} selection/>

const cleanNumberField = (key, row) => {
  return !["fixed_discount", "percent_discount", "fixed_unit_cost", "rev_share_percent"].includes(key) || !row[key] ? 
    row[key] : String(row[key]).replace("$","").replace("%","").replace(",","")

}

const DEFAULT_CREATE = {
  display: [{}],
  segments: [{segment_id: "", promo_field: ""}],
  promo_codes: [],
}

const PROMO_FIELDS = [
  "conversion_field_value",
  "entity_type",
  "fixed_discount",
  "percent_discount",
  "fixed_unit_cost", 
  "rev_share_percent"
]

export const tierMacroColumn = ({ cell, row }) => {
  const { value } = cell

  if (value === "{conversion_field_value}") {
    if (!!row.conversion_field_value) return row.conversion_field_value
    return "{ Promo Code }"
  }

  if (value === "{name}") {
    if (!!row.name) return row.name
    return "{ Name }"
  }

  return value
}

const states = [
  {
    key: "Approved",
    content: "This will create a new entity and promo-code",
    icon: "check circle",
    color: "green"
  },
  {
    key: "Same Name",
    content: "This will add a new promo code to the existing entity",
    icon: "warning circle",
    color: "green"
  },
  {
    key: "Same Code",
    content: "The promo code is already associated with another entity",
    icon: "warning sign",
    color: "orange"
  },
  {
    key: "Missing Code",
    content: "You must enter a promo code to create a new entry",
    icon: "warning sign",
    color: "red"
  },
  {
    key: "Missing Name",
    content: "You must enter a name to create a new entry",
    icon: "warning sign",
    color: "red"
  },
]

export const COLUMNS = [
  { 
    style: {width:"1.9em"},
    display: "", key: "state", type: "text", 
    asEdit: ({ value }) => <>{ value}</>, 
    asView: DataTable.StatusCell({ states })
  },
  { display: "Name", key: "name", type: "text" },
  { display: "Promo Code", key: "conversion_field_value", type: "text" },
  { display: "Type", key: "entity_type", type: "text" },
  { display: "Tier 1", key: "tier_1", type: "text", asView: tierMacroColumn },
  { display: "Tier 2", key: "tier_2", type: "text", asView: tierMacroColumn },
  { display: "Tier 3", key: "tier_3", type: "text", asView: tierMacroColumn },
  { display: "Tier 4", key: "tier_4", type: "text", asView: tierMacroColumn },
  { display: "Tier 5", key: "tier_5", type: "text", asView: tierMacroColumn },
  { display: "Discount ($)", key: "fixed_discount", type: "currency" },
  { display: "Discount (%)", key: "percent_discount", type: "percent" },
  { display: "Fixed Payout ($)", key: "fixed_unit_cost", type: "currency" },
  { display: "Rev Share (%)", key: "rev_share_percent", type: "percent" },
]

const READ_ONLY_DISPLAY = [
  "tier_1",
  "tier_2",
  "tier_3",
  "tier_4",
  "tier_5",
]
const READ_ONLY_COST = [
  "fixed_unit_cost",
  "rev_share_percent",
]
const SKIP_PROMO_COST = [
  "fixed_discount",
  "percent_discount",
  "fixed_unit_cost",
  "rev_share_percent",
]

const makeReadOnly = (readOnlyColumns) => (row) => {
  const readOnly = readOnlyColumns.includes(row.key)
  return Object.assign({ readOnly }, row)
}

const CreateViaPromo = ({ program, entityConfigs, entityData, onReset}) => {

  const { entity_type } = program;
  const { tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent } = program||{}
  const [data, setData] = React.useState([])
  const [segment_id, setSegment] = React.useState(false)
  const [conversionData, setConversionData] = React.useState(false)
  const [totalCount, setTotalCount] = React.useState(false)
  const [currentCount, setCurrentCount] = React.useState(false)
  const [showSearch, setShowSearch] = React.useState(false)
  const [promo_field, setPromoField] = React.useState("coupon")
  const [loading, setLoading] = React.useState(undefined)
  const [fieldOptions, setFieldOptions] = React.useState([])

  const [ featuredSegmentId, segments, segmentOptions, getSegments ] = entityStore(
    ({ featuredSegmentId, segments, segmentOptions, getSegments, }) => [ 
      featuredSegmentId, 
      segments, 
      segmentOptions, 
      getSegments
    ], 
    shallow
  )

  const hasDisplay =  !!(tier_1 || tier_2 || tier_3 || tier_4 || tier_5)
  const hasCosts = !!(fixed_unit_cost || rev_share_percent)
  const display = { tier_1, tier_2, tier_3, tier_4, tier_5 }
  const costs = { fixed_unit_cost, rev_share_percent }

  const _columns = React.useMemo(() => {
    const options = entityData.map(({ name }) => Object.assign({ name, text: name, value: name }))
    const columns = COLUMNS
    const entityColumn = columns.find(row => row.key == "name")
    entityColumn.asEdit = DataTable.SearchCell(options)

    return columns
  }, [entityData])

  React.useEffect(() => { getSegments() }, [])
  React.useEffect(() => { 
    const hasEntityData = (!!entityData && entityData.length > 0) 
    const _segmentId = hasEntityData ? entityData[0].segments[0].segment_id : 
      featuredSegmentId ? featuredSegmentId : 
      null;

    !segment_id && _segmentId ? setSegment(_segmentId) : null 
  }, [featuredSegmentId])
  React.useEffect(() => { (loading === false) ? onReset() : false }, [loading])

  React.useEffect(() => {

    if (segment_id) getConversionDataset(segment_id)
      .then(response => response['response']['conversion_data'])
      .then(data => {

        setConversionData(data)

        const fields = Object.keys(data.slice(0,10)
          .reduce((p,c) => Object.assign(p,c), {}))
          .filter(key => !["1","source_name","ip","request_referrer","rb_source","event_id","hash_ip","raw_message_id","timestamp","date","landing_url","user_agent","conversion_hash_id","origin","url"].includes(key))
          .map(value => { return {text: value, value}})

        setFieldOptions(fields)
      })
  }, [segment_id])

  const { existingCode, existingName } = React.useMemo(() => {
    const existingCode = entityData.reduce((p,c) => {
      c.promo_codes.map(code => {
        p[code.conversion_field_value] = c
      })
      return p
    }, {})
    const existingName = d3.nest()
      .key(row => row.name)
      .map(entityData)
    return { existingCode, existingName }
  }, [entityData])


  React.useEffect(() => {
    const byName = d3.nest()
      .key(row => row.name)
      .key(row => row.conversion_field_value)
      .map(data)
  }, [data])

  const onUpdate = (d) => {
    d.map(row => {
      const sameName = !!existingName[row.name]
      const sameCode = !!existingCode[row.conversion_field_value]
      row.state = sameCode ? "Same Code" :
        sameName ? "Same Name" :  
        !row.conversion_field_value ? "Missing Code" :
        !row.name ? "Missing Name" : "Approved"
      Object.assign(row, {tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent, entity_type})
    })
    setData(d)
  }
  const onRemove = (row) => setData(data.filter(r => r != row))
  const addRow = (row={}) => setData(data.concat([row]))

  const onAdd = (evt, { value }) => {
    const { promoCode } = value;
    const conversion_field_value = promoCode;

    setShowSearch(false)

    const newData = data.concat([{conversion_field_value, tier_1, tier_2, tier_3, tier_4, tier_5, fixed_unit_cost, rev_share_percent, entity_type}])


    setData(newData)
    
  }
  const onSave = () => {
    setLoading(true)

    const newEntities = data.filter(row => row.state == "Approved")

    const newToPost = newEntities.reduce((p,c) => {
      p[c.name] = p[c.name] || _.cloneDeep(DEFAULT_CREATE)

      const asCode = PROMO_FIELDS.reduce((p,key) => {
        p[key] = cleanNumberField(key, c)
        return p
      }, {conversion_field_name: promo_field, name: c.conversion_field_value})

      p[c.name].promo_codes.push(asCode)
      p[c.name].name = c.name
      p[c.name].entity_type = c.entity_type

      const display = p[c.name].display[0];
      const segments = p[c.name].segments[0];

      segments.segment_id = segment_id;
      segments.promo_field = promo_field;

      ["tier_1","tier_2","tier_3","tier_4","tier_5"].map(tier => {
        display[tier] = c[tier] == c.name ? "{name}" : 
          c[tier] == c.conversion_field_value ? "{conversion_field_value}" : c[tier]
      })
      
      return p
    },{})

    const existingEntites = data.filter(row => row.state == "Same Name")
    const toUpdate = existingEntites.reduce((p,c) => {
      const { name } = c;
      p[name] = p[name] || _.cloneDeep(existingName[name])[0];

      const asCode = PROMO_FIELDS.reduce((p,key) => {
        p[key] = cleanNumberField(key, c)
        return p
      }, {conversion_field_name: promo_field, name: c.conversion_field_value})

      p[c.name].promo_codes.push(asCode)

      return p
    },{})

    // TO HANDLE QUEUEING OF REQUESTS
    const requestsQueue = [];

    Object.values(newToPost).map(obj => {
      const { id } = obj
      const send = id ? updateEntityData : postEntityData;
      requestsQueue.push({ send, obj, id })
    })
    Object.values(toUpdate).map(obj => {
      const { id } = obj
      const send = id ? updateEntityData : postEntityData;
      requestsQueue.push({ send, obj, id })
    })

    setTotalCount(requestsQueue.length)

    const runFromQueue = () => {
      const item = requestsQueue.pop()
      if (requestsQueue.length % 10) setCurrentCount(requestsQueue.length);

      if (!item) {
        setLoading(false)
        onReset()
        return
      }

      const { send, obj, id } = item
      const promise = send(obj, id)
        .then(runFromQueue)
    }

    requestsQueue.reverse()
    runFromQueue()
    
    //runBatchQueue(100)

  }

  const removeErrors = () => {
    const valid = data.filter(row => (row.state == "Approved") || (row.state == "Same Name"))
    setData(valid)
  }
  
  const toRemove = data.filter(row => (row.state != "Approved") && (row.state != "Same Name"))

  const readOnlyColumns = program && hasDisplay && hasCosts ? [...READ_ONLY_DISPLAY, ...READ_ONLY_DISPLAY] : 
    program && hasDisplay ? READ_ONLY_DISPLAY : 
    program && hasCosts ? READ_ONLY_COST: []

  const skipColumns = program && !hasCosts ? ["entity_type", ...SKIP_PROMO_COST] : []

  const columns = program ? _columns
    .filter(row => !skipColumns.includes(row.key))
    .map(makeReadOnly(readOnlyColumns)) : _columns
  
  return <ContentCard title="Create Multiple Sponsorships via Promo Codes">
    <ColumnLayout
      leftWidth={12}
      rightWidth={4}
      leftContent={<Form>
        <FormDropdown
          required
          onChange={(x, {value}) => setSegment(value) }
          as={FormDropdown}
          options={segmentOptions}
          value={segment_id}
          label="Segment"
        />
        <FormDropdown
          value={promo_field}
          onChange={(x, {value}) => setPromoField(value)}
          options={fieldOptions}
          search
          label="Promo-code Field"
          loading={segment_id && fieldOptions.length == 0}
        />
        <br />
      </Form>
      }
      rightContent={ <Message 
        header="Associate a segment" 
        content="Since you are using 'promo-codes' or information collected at the time of conversion to attribute marketing, it is necessary to choose a conversion segment from which to capture the marketing information. To do this, we need to know the conversion segment as well as the field which contains the promo-code information." 
      />}
    />
    <Segment {...{loading}} >
      <DataTable
        {...{
            columns,
            data,
            onUpdate,
            onRemove
          }
        }
      />
      <Button size='mini' icon="plus" onClick={addRow}/>
      { (!showSearch) && 
          <div style={{textAlign:"center",marginTop:"15px"}}>
            <Button basic size='huge' content="Find Promo Code to associate to create a Sponsorship" icon="search" onClick={() => setShowSearch(true)}/>
          </div>
      }
      { showSearch && <FindPromoCode {...{conversionData, promo_field, entityConfigs, entityData, onAdd}} /> }
      { totalCount && <>Saving {totalCount-currentCount}/{totalCount}...</> }
      <br />
      { toRemove.length > 0 && <Message content={<>
          The records below either have insufficent data or are already associated with other sponsorships. Either remove or correct the errors before submitting.
          <Button style={{marginLeft:"5px"}} color="orange" compact size="tiny" onClick={removeErrors}>Remove Errors</Button>
        </>} /> 
      }
      <br />
      { data.length > 0 && toRemove.length == 0 && <Button disabled={loading || data.length == 0 || !segment_id}  loading={loading} floated="right" color="green" onClick={onSave} submit>
          Add Entities & Promo Codes
        </Button>
      }
      <br />
      <br />
    </Segment>
  </ContentCard>
}

export default CreateViaPromo;
