import React from 'react'
import { Segment, Header, Icon } from 'semantic-ui-react'
import styled from 'styled-components'


const Funnel = ({ children }) => (
  <div className='funnel-vertical'>
    {children}
  </div>
)

export default Funnel

export const Step = ({ children, onClick }) => {
  const [stepHeight, setStepHeight] = React.useState(0)
  const getHeight = (element) => {
    if (!element) return null
    setStepHeight(element.offsetHeight)
  }

  return (
    <div ref={getHeight} className='funnel-wrap'>
      <Segment
        as={FunnelStepStyle}
        className='funnel-section'
        {...{stepHeight, onClick}}
      >
        {children}
      </Segment>
    </div>
  )
}

const FunnelStepStyle = styled.div`
  &:before {
    border-width: 0 20px ${props => props.stepHeight}px 0 !important;
  }
  &:after {
    border-width: ${props => props.stepHeight}px 20px 0 0 !important;
  }
  &:hover {
    &:before {
      filter: drop-shadow(-4px 0px 2px rgba(34, 36, 38, 0.075)) !important;
    }
    &:after {
      filter: drop-shadow(4px 0px 2px rgba(34, 36, 38, 0.075)) !important;
    }
  }
`
