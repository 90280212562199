import React from 'react'
import ContentLoader from 'react-content-loader'
import { Menu, Segment, Grid } from 'semantic-ui-react'

const TabLoader = ({ active }) => (
  <Menu.Item
    {...{ active }}
    content={
      <ContentLoader
        viewBox='0 0 60 15'
        foregroundColor='#e5e5e5'
        height='15'
        width='60'
        style={{ float: 'left' }}
      >
        <rect x='0' y='1' width='60' height='13' rx='4' ry='4' />
      </ContentLoader>
    }
  />
)

const RecommendationLoader = () => (
  <ContentLoader
    viewBox='0 0 445 400'
    foregroundColor='#e5e5e5'
    height={400}
    width='100%'
    preserveAspectRatio='none'
  >
    <rect x='13.99' y='9.46' width='62.16' height='12.75' rx='4' ry='4' />
    <rect x='103.99' y='9.46' width='62.16' height='12.75' rx='4' ry='4' />
    <rect x='193.99' y='9.46' width='62.16' height='12.75' rx='4' ry='4' />
    <rect y='32.02' width='445' height='2' />
    <rect x='1.47' y='61.6' width='370.66' height='21.06' rx='4' ry='4' />
    <rect x='1.47' y='111.64' width='395.61' height='12.47' rx='4' ry='4' />
    <rect x='1.47' y='132.64' width='395.61' height='12.47' rx='4' ry='4' />
    <rect x='1.47' y='153.64' width='275.29' height='12.47' rx='4' ry='4' />
    <rect x='1.47' y='219.64' width='395.61' height='12.47' rx='4' ry='4' />
    <rect x='1.47' y='240.64' width='275.29' height='12.47' rx='4' ry='4' />
    <rect x='1.47' y='174.64' width='150.13' height='12.47' rx='4' ry='4' />
  </ContentLoader>
)

const ChartLoader = () => (
  <ContentLoader
    viewBox='0 0 760 400'
    foregroundColor='#e5e5e5'
    height={400}
    width='100%'
    preserveAspectRatio='none'
  >
    <path d="m760,95.46v257.95s-713.1,0-698.75,0C135.95,141.67,471.33,107.4,760,95.46Z" />
  </ContentLoader>
)

const ChannelOptimizationLoader = () => (<>
  <Menu
    tabular
    attached='top'
    className='mmmm-channel-menu-overrides'
    >
    <TabLoader active />
    <TabLoader />
    <TabLoader />
  </Menu>
  <Segment attached='bottom' style={{ height: 440, border: '1px solid #e7eafc' }}>
      <Grid style={{ padding: 10 }}>
        <Grid.Row>
          <Grid.Column width={6}>
            <RecommendationLoader />
          </Grid.Column>
          <Grid.Column width={10}>
            <ChartLoader />
          </Grid.Column>
        </Grid.Row>
      </Grid>
  </Segment>
</>)

export default ChannelOptimizationLoader
