import { CHANNELS_BREAKDOWNS_LIST } from './constants'

const numberFormatter = new Intl.NumberFormat('en-US', { style : 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
export const formatPercent = (x) => numberFormatter.format(x)

// UNITTEST ME!
export const generateAverages = (data, channels, threshold=0.01) => {
  const summary = data.reduce((acc, curr) => {
    channels.map(x => {
      if (!acc[x]) acc[x] = 0
      acc[x] += (curr[x] || 0)
    })
    return acc
  }, {})
  const total = Object.values(summary).reduce((a, b) => a + b, 0)

  return Object.keys(summary)
    .map(x => ({
      channel: x,
      value: summary[x] / total
    }))
    .filter(x => x.value > threshold)
    .sort((a, b) => b.value - a.value)
}

export const getChannelBreakdownSummary = (channelBreakdown, channels, threshold) => {
  if (!channelBreakdown) return null
  return generateAverages(channelBreakdown, channels, threshold);
}

export const getYearlyChanges = (channelBreakdown, channels) => {
  if (!channelBreakdown) return null

    const prevYearData = channelBreakdown.slice(0,4)
    const prevMonthData = channelBreakdown.slice(-5, -1)

    const prevYear = generateAverages(prevYearData, channels, 0.001)
    const prevMonth = generateAverages(prevMonthData, channels, 0.001)

    const changes = prevYear.map(x => {
      const prevMonthValue = prevMonth.find(y => y.channel === x.channel)?.value || 0
      const yearlyChange = prevMonthValue - x.value
      const yearlyPctChange = yearlyChange / x.value
      return {
        channel: x.channel,
        prevYearValue: x.value,
        prevMonthValue,
        yearlyChange,
        yearlyAbsChange: Math.abs(yearlyChange),
        yearlyPctChange,
        yearlyAbsPctChange: Math.abs(yearlyPctChange),
      }
    })
    return changes.filter(x => !!x.prevMonthValue && !!x.prevYearValue)
}

export const getChannelsAndBreakdown = (trendData) => {
  if (!trendData) return [null, null]
    const total = trendData.reduce((acc, curr) => {
      const { week, channel, pct_of_spend } = curr
      if (!acc[week]) {
        acc[week] = {}
      }
      if (!acc[week][channel]) {
        acc[week][channel] = 0
      }
      acc[week][channel] += pct_of_spend
      return acc
    }, {})

    const channelsList = []
    const breakdown = Object.keys(total).map((week) => {
      const channels = Object.keys(total[week]).filter((channel) => CHANNELS_BREAKDOWNS_LIST.includes(channel))
      channelsList.push(...channels)
      const totalSpend = channels.reduce((acc, curr) => {
        return acc + total[week][curr] ** 2
      }, 0)
      const obj = { week }
      channels.map((channel) => {
        obj[channel] = total[week][channel] ** 2 / totalSpend
      })
      return obj
    })
    const channels = [...new Set(channelsList)]

    return [channels, breakdown]
}

export const getChannelMixHighlights = (yearlyChanges, channelBreakdownSummary) => {
  if (!channelBreakdownSummary || !yearlyChanges) return null

  const largestPlatform = channelBreakdownSummary[0]
  const largest = yearlyChanges.find(x => x.channel === largestPlatform.channel)

  const biggestChange = yearlyChanges.sort((a, b) => b.yearlyChange - a.yearlyChange)[0]
  const mostStable = yearlyChanges.sort((a, b) => b.yearlyAbsPctChange - a.yearlyAbsPctChange)[yearlyChanges.length-1]

  return {
    largest,
    biggestChange,
    mostStable,
  }
}
