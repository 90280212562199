export function getBenchmarkData(platform) {
    const url = `/data-reporting/platform-performance-benchmarks/${platform}`;
    return fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }})
      .then(response =>  response.json() )
      .catch((error) => console.error(error.message))
  }
  