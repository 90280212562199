import React, { createContext, useContext, useState } from 'react';
import { compose, reduce, defaultTo } from 'lodash/fp';

const StepsContext = createContext({});
const StepsProvider = ({ children, steps }) => {
  const initialDisplayState = compose(
    defaultTo({}),
    reduce((t, c) => ({
      ...t,
      [c.order]: false,
    }), {})
  )(steps)

  const [stepsDisplay, setStepsDisplayState] = useState(initialDisplayState)
  const setStepDisplay = (step, value) => setStepsDisplayState({
    ...stepsDisplay,
    [step]: value,
  });

  // platform agnostic bucket
  // TODO: validate / organize the value?
  const [stateData, setStateDataState] = useState({})
  const setStateData = (value) => {
    return setStateDataState({
      ...stateData,
      ...value // ensure this is an object
    });
  }

  const state = {
    stepsDisplay,
    setStepDisplay,
    stateData,
    setStateData
  };

  return (
    <StepsContext.Provider value={state}>
      {children}
    </StepsContext.Provider>
  )
};

export { StepsContext, StepsProvider }

export const useStepsDisplay = () => {
  const { stepsDisplay, setStepDisplay } = useContext(StepsContext);
  return [stepsDisplay, setStepDisplay];
}

export const useStepsState = () => {
  const { stateData, setStateData } = useContext(StepsContext);
  return [stateData, setStateData];
}
