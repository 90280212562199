import { tableToIPC } from "apache-arrow";
import { tableFromIPC } from "apache-arrow";

import { getMetaData, getMetaDataReporting } from './reportingMetaData';
import * as performance from './performanceData';
import * as reporting from './reportingData';
import { getDB } from './reportingDBCache';
import { parseArch } from "./parquetToJson";
import { ipcBufferToArrow, getDataValidInCache, getDataFromCache, clearInvalidCache, setCacheData } from './dbOperations';

import * as aq from "arquero";

const getDataFromRemote = async ({ date, dataset, identifier, id }) => {
  const dataAPI = (dataset == "compiled_mta_tiers" || dataset == "time_to_conversion") ? performance : reporting;
  const { getAsBuffer } = dataAPI;

  const _buffer = await getAsBuffer(date, dataset, identifier, id)
  const _data = tableFromIPC(_buffer)

  const arch = aq.fromArrow(_data)
    .derive({
      cache_from: `d => "${date}"`,
      date: `d => "${date}"`
    })
    // .rename({ 
    //   conversions_even: 'even', 
    //   conversions_first_touch: 'first_touch',
    //   conversions_last_touch: 'last_touch',
    //   conversions_normalized: 'normalized',
    //   ntf_conversions_even: 'ntf_even',
    //   ntf_conversions_first_touch: 'ntf_first_touch',
    //   ntf_conversions_last_touch: 'ntf_last_touch',
    //   ntf_conversions_normalized: 'ntf_normalized',
    // })

  const data = arch.toArrow()

  const buffer = tableToIPC(data)

  return { buffer, data, date, dataset, identifier, id }
}

const getAllUniqueColumns = (tables) => {
  const allColumns = tables
    .map((table) => {
      const columnNames = aq.table(table).columnNames()
      return columnNames
    })
    .flat()
  return [...new Set(allColumns)]
}

const findBigIntColumns = (table) => {
  const { _data } = table
  const entries = Object.entries(_data)
  return entries
    .reduce(
      (p, [k,v]) => typeof(v.data[0]) == "bigint" ? [...p, k]: p, 
      []
    )
}

const bigIntsToNumber = (bigIntColumns) => {

  return bigIntColumns.reduce(
    (p, column) => Object.assign(p, {[column]: aq.escape(d => Number(d[column])) }),
    {}
  )

}

export const getViaCache = async (startDate, endDate, dataset, identifier) => {
  console.time("open-db-"+dataset)
  const db = await getDB()
  console.timeEnd("open-db-"+dataset)
  
  const metaDataAPI = (dataset == "compiled_mta_tiers") ? getMetaData : getMetaDataReporting;

  const requiredDatasets = await metaDataAPI(identifier, startDate, endDate, dataset)

  // check if we have appropriate cached datasets
  const cacheCheckPromises = requiredDatasets.map(requirement => {
    const {date, id} = requirement
    return getDataValidInCache(db)(date, dataset, identifier, id)
  })

  const cacheState = await Promise.all(cacheCheckPromises)

  const hasCache = cacheState.filter(row => row.hasCache)
  const missedCache = cacheState.filter(row => !row.hasCache)
  const invalidCache = missedCache.filter(row => row.cache_id)

  console.time(dataset+"-read-from-cache")
  const cleared = await Promise.all(invalidCache.map(clearInvalidCache(db)))
  const dataFromCache = await Promise.all(hasCache.map(getDataFromCache(db)))
  const dataFromRemote = await Promise.all(missedCache.map(getDataFromRemote))
  const cached = await Promise.all(dataFromRemote.map(setCacheData(db)))
  console.timeEnd(dataset + "-read-from-cache")


  const data = [...dataFromCache, ...dataFromRemote]
    .map(row => aq.fromArrow(row['data']))
  const columns = getAllUniqueColumns(data).reduce((acc, col) => ({...acc, [col]: []}), {})
  const emptyTable = aq.table(columns)

  try {
    const _arch = emptyTable.concat(...data)
    const _data = [...dataFromCache, ...dataFromRemote].length ? [...dataFromCache, ...dataFromRemote][0]['data'] : {};
    return parseArch(_arch, _data)
    
  } catch(e) {
    console.log("error pulling data from cache")
    return aq.from([])
  }

}

