import React, { Component } from "react";
import { Popup, Button, Icon } from "semantic-ui-react";

export class DeleteButton extends Component {
  state = {
    popupOpen: false,
    color: "grey"
  };

  onDeleteClick = (e, data) => {
    this.props.onDelete(e, data);
    this.setState({ popupOpen: false });
  };

  handleClose = e => {
    this.setState({ popupOpen: false });
  };

  render() {
    const { disabled, popupContent } = this.props;

    return (
      <Popup
        onClose={this.handleClose}
        open={this.state.popupOpen}
        on="click"
        trigger={
          <Icon
            {...this.props}
            name="trash alternate outline"
            bordered={false}
            color={this.state.color}
            onMouseEnter={() => {
              this.setState({ color: "red" });
            }}
            onMouseLeave={() => {
              this.setState({ color: "grey" });
            }}
            onClick={e => {
              e.stopPropagation();
              this.setState({ popupOpen: true });
            }}
          />
        }
        content={
          <Popup
            trigger={
              <Button
                {...this.props}
                negative
                content={this.props.popup || "Delete"}
                onClick={this.onDeleteClick}
                onMouseOut={this.handleClose}
              />
            }
            on="hover"
            content={this.props.popupContent || "Delete"}
          />
        }
      />
    );
  }
}
