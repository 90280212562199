import React, { Component } from 'react';
import { SplitLayout, ContentCard, StatCard } from '@rockerbox/styleguide';
import SummaryBar from '../ViewAttribution/SummaryBar';
import { Grid } from 'semantic-ui-react';
import CacheContext from '../../utils/CacheContext';

const SummarySelector = ({handlePlotVariable, plotVariable, count, revenue, spend, hasSpend}) => {
  const [{currency_code}] = React.useContext(CacheContext);

  return(
  <Grid fluid='true'>
    <Grid.Row stretched>
      <Grid.Column>
        <StatCard
          segmentColor={plotVariable == "count" && "blue"}
          onClick={() => handlePlotVariable("count")} value={count}
          label='Conversions'
          size='small'
        />
        <StatCard
          segmentColor={plotVariable == "revenue" && "blue"}
          onClick={() => handlePlotVariable("revenue")}
          value={revenue}
          label='REVENUE'
          type='currency'
          currencyCode={currency_code}
          size='small' />
        {
        hasSpend && <StatCard
          segmentColor={plotVariable == "spend" && "blue"}
          onClick={() => handlePlotVariable("spend")}
          value={spend}
          label='SPEND'
          type='currency'
          currencyCode={currency_code}
          size='small' />
        }
      </Grid.Column>
    </Grid.Row>
  </Grid>
)}

const TITLE = {
  "revenue": "Revenue",
  "spend": "Spend",
  "count": "Conversions"
}

const SummaryBarCard = ({ plotVariable, values, loading }) => (
  <ContentCard title={TITLE[plotVariable]} borderless fullHeight>
    {loading
      ? <ContentCard.CardLoader />
      : values.length
        ? <SummaryBar data={values} exclude={[]} />
        : <ContentCard.EmptyView
            fallBackMsg="No data available yet"
            description="Please allow 24 hours after setup for data to appear in your dashboard"
          />
    }
  </ContentCard>

)

class SummarySection extends Component {

  render() {
    const { handlePlotVariable, plotVariable, count, revenue, spend, hasSpend, values, loading } = this.props;

    return (
      <SplitLayout stretched leftWidth={12} rightWidth={4}
        leftContent={ <SummaryBarCard {...{ plotVariable, values, loading}} /> }
        rightContent={ <SummarySelector {...{ handlePlotVariable, plotVariable, count, revenue, spend, hasSpend}} /> }
      />
    )
  }
}

export default SummarySection;
