import React, { Component } from 'react';

import { ContentCard, IndexGrid } from '@rockerbox/styleguide';
import { Label, Accordion, Menu, Table, Checkbox, Dropdown, Button, Popup, Icon } from 'semantic-ui-react';
import SummaryBar from '../ViewAttribution/SummaryBar';

export const RunBackfill = (text, backfilledItems, onClick) => (props) => {
  return (
    <Table.Cell collapsing style={{minWidth: 70}}>
      <Button disabled={backfilledItems.includes(JSON.stringify(props.item))} onClick={() => onClick(props.item)}>{ text }</Button>
    </Table.Cell>
  )
}

export const PurgeBatch = (text, backfilledItems, onClick) => (props) => {
  return (
    <Table.Cell collapsing style={{minWidth: 70}}>
      <Button disabled={backfilledItems.includes(JSON.stringify(props.item))} onClick={() => onClick(props.item)}>{ text }</Button>
    </Table.Cell>
  )
}
const SpendViewPlot = (props) => {

  const { dataLoading, activeData, toggleData, showData, backfilledItems, runBackfill, purgeBatch, activeAccount } = props;
  const data = activeData;

  if (data === false) return (
    <ContentCard>
      <table style={{textAlign:"center",width:"100%"}}>
        <IndexGrid.EmptyMessage
          fallBackMsg="No spend data available. If you just authorized, please allow 24 hours for data to be synced." />
      </table>
    </ContentCard>
  )

  if (data && data.length === 0 && !dataLoading) return (
    <ContentCard>
      <table style={{textAlign:"center",width:"100%"}}>
        <IndexGrid.EmptyMessage
          fallBackMsg="No spend data matches this mapping rule" />
      </table>
    </ContentCard>
  )

  if (!data) return null;

  return (
    <React.Fragment>
      { dataLoading && <ContentCard loading /> }
      { data.map(d => (
          <ContentCard hasTable >
            <div style={{position:"relative",width:"inherit",display:"block"}}>
              { d.assignment_id === "all" ?
                <SummaryBar data={d.values} exclude={["assignment_id","tier_one","platform","missing","imps","clicks","spend","version"]} stacked={true} barColors={{'unallocated':'#d9534d','allocated':'#0489c1'}} />
                :
                <SummaryBar data={d.values} exclude={["assignment_id","tier_one","platform","missing","imps","clicks","version"]} />
              }
            </div>
            <Accordion >
              <Accordion.Title active={showData} onClick={toggleData}>
                <Icon name='dropdown' />
                <Label>{ showData ? "Hide": "Show" } tabular data</Label>
              </Accordion.Title>
              <Accordion.Content active={showData} >
                <IndexGrid
                  data={d.values}
                  cols={["date","imps","clicks","spend"].reduce(
                    (p,col) => {
                      p.push({ display: col, key: col });
                      return p
                    },
                    activeAccount.batch_platform ?  [{display:"", key:"purge", as: PurgeBatch("Purge Batch", backfilledItems, purgeBatch)}] : [{display:"", key:"run", as: RunBackfill("Resync Data", backfilledItems, runBackfill)}],
                  )}
                  as={ContentCard.Table}
                  fallbackMsg="No Data Sources" />
              </Accordion.Content>
            </Accordion>
          </ContentCard>
          )
        )
      }

    </React.Fragment>
  )
}

export default SpendViewPlot;
