import React, { useState, useEffect, useMemo } from 'react'
import { Label, Icon, Dropdown, Grid, Divider, Statistic } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Bar, Line, ReferenceLine, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ContentCard, ChartTooltip, PieChartLoader } from '@rockerbox/styleguide'
import { getTrendCategories, getChannelTrends } from '../../api/attribution'
import { SaveReportButton, ResetFilterButton, BasicViewHeader, FilterWrapper, StyledDropdown } from '../../components'
import { CHANNELS, CHANNEL_NAME_MAP, CHANNEL_MAP, NAME_MAP } from './constants'
import { formatPercent, getChannelBreakdownSummary, getYearlyChanges, getChannelsAndBreakdown, getChannelMixHighlights } from './helpers'
import { TrendChartLoader, ChannelTrendsLoader } from './loaders'
import Highlights from './Highlights'
import ChannelRecommendations from './ChannelRecommendations'
import ConnectPromo from './ConnectPromo'
import { track, time } from '../../../utils/tracking'
import { useAtom } from 'jotai'
import { categoryAtom, categoryValueAtom, selectedChannelAtom } from '../../atoms'

const SpendTrends = () => {
  const [loading, setLoading] = useState(true)
  const [trendCategories, setTrendCategories] = useState(null)
  const [trendData, setTrendData] = React.useState(null)
  const [selectedChannel, setSelectedChannel] = useAtom(selectedChannelAtom)
  const [category, setCategory] = useAtom(categoryAtom)
  const [categoryValue, setCategoryValue] = useAtom(categoryValueAtom)

  const setCategoryParams = (category, categoryValue) => {
    setCategory(category)
    setCategoryValue(categoryValue)
  }

  const categoryNameMap = useMemo(() => {
    if (!trendCategories) return {}
    const allCategories = [...trendCategories.industries, ...trendCategories.spend_levels]
    return allCategories.reduce((acc, curr) => {
      acc[curr.value] = curr.name
      return acc
    }, {})
  }, [trendCategories])

  useEffect(() => {
    getTrendCategories()
      .then((data) => {
        setTrendCategories(data)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    time(`benchmarks.spend_trends.view`)
    getChannelTrends(category, categoryValue)
      .then((data) => {
        setTrendData(data)
        setLoading(false)
        track(`benchmarks.spend_trends.view`, {
          category,
          category_value: categoryValue,
        })
      })
  }, [categoryValue])

  const [channels, channelBreakdown] = useMemo(() => {
    return getChannelsAndBreakdown(trendData);
  }, [trendData])

  const channelBreakdownSummary = useMemo(() => {
    return getChannelBreakdownSummary(channelBreakdown, channels, 0.005);
  }, [channelBreakdown, channels])

  const yearlyChanges = useMemo(() => {
    return getYearlyChanges(channelBreakdown, channels);
  }, [channelBreakdown, channels])

  const channelMixHighlights = useMemo(() => {
    return getChannelMixHighlights(yearlyChanges, channelBreakdownSummary);
  }, [yearlyChanges])

  const channelData = useMemo(() => {
    if (!selectedChannel || !trendData) return null
    return trendData.filter((item) => item.channel === selectedChannel)
  }, [trendData, selectedChannel])

  const selectedChannelStats = useMemo(() => {
    if (!channelData) return null

    const prevYear  = channelData.slice( 0,  4).reduce((acc, curr) => acc + curr.pct_of_spend, 0) / 4
    const prevMonth = channelData.slice(-9, -5).reduce((acc, curr) => acc + curr.pct_of_spend, 0) / 4
    const currMonth = channelData.slice(-5, -1).reduce((acc, curr) => acc + curr.pct_of_spend, 0) / 4

    const yearlyChange = currMonth - prevYear
    const yearlyPctChange = yearlyChange / prevYear

    const monthlyChange = currMonth - prevMonth
    const monthlyPctChange = monthlyChange / prevMonth

    return {
      prevYear,
      prevMonth,
      currMonth,
      yearlyChange,
      yearlyPctChange,
      monthlyChange,
      monthlyPctChange,
    }
  }, [channelData])

  return (<>
    <BasicViewHeader
      header='Advertising Spend Trends'
      subheader='See how other companies are spending their advertising budgets and adjusting their allocation per channel'
    />
    <FilterWrapper 
      {...{ loading }}
      other={
        <>
          <StyledDropdown
            label="Industries and Advertising Spend Levels"
            value={categoryValue}
            placeholder='Loading...'
            text={
              !category
                ? 'All industries and advertising spend levels'
                : `${category == 'annual_spend' ? 'Annual Spend' : 'Industry'}: ${categoryNameMap[categoryValue] || ''}`
            }
            advancedDropdown={
              <Dropdown.Menu>
                <Dropdown.Item
                    key='all'
                    text='All industries and advertising spend levels'
                    value={null}
                    onClick={() => setCategoryParams(null, null)}
                    active={categoryValue == null}
                  />
                  <Dropdown.Header icon='dollar' content='Annual Spend' />
                  {!!trendCategories?.spend_levels && trendCategories.spend_levels.map(x => (
                    <Dropdown.Item
                      key={x.value}
                      text={x.name}
                      value={x.value}
                      onClick={() => setCategoryParams('annual_spend', x.value)}
                      active={categoryValue == x.value}
                    />
                  ))}
                  <Dropdown.Header icon='building' content='Industry' />
                  {!!trendCategories?.industries && trendCategories.industries.map(x => (
                    <Dropdown.Item
                      key={x.value}
                      text={x.name}
                      value={x.value}
                      onClick={() => setCategoryParams('industry', x.value)}
                      active={categoryValue == x.value}
                    />
                  ))}
                </Dropdown.Menu>
            }
          />
        </>
      }
      stickyRight={<>
        <SaveReportButton {...{ loading }} />
        <ResetFilterButton />
      </>}
    />

    <ContentCard
      title='Cross-Channel Spend Mix'
      style={{ marginTop: 0 }}
    >
      <Highlights {...{ loading }} data={channelMixHighlights} />

      <Divider style={{ margin: '10px 5px', opacity: 0.5 }} />

      <Grid style={{ paddingBottom: 10 }}>
        <Grid.Row>
          <Grid.Column stretched style={{ width: 370 }}>
            {!!loading && <PieChartLoader hideCenterText />}
            {!loading && <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  position: 'absolute',
                  width: '100%',
                  height: 340,
                  top: 0,
                  left: 0,
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: 16,
                    opacity: 0.6,
                    fontWeight: 500,
                    textAlign: 'center',
                    lineHeight: '20px',
                  }}
                >
                  Average Mix of<br />
                  Advertising Spend
                </div>
              </div>
              <ResponsiveContainer
                id="channelMixPieChart"
                width='100%'
                height={340}
              >
                <PieChart>
                  <Pie
                    data={channelBreakdownSummary}
                    dataKey="value"
                    nameKey="channel"
                    startAngle={90}
                    endAngle={-270}
                    innerRadius='80%'
                    outerRadius='95%'
                    paddingAngle={1}
                  >
                    {channelBreakdownSummary.map(({ channel }, i) =>
                      <Cell
                        key={channel}
                        fill={CHANNELS.find(x => x.key === channel)?.color}
                      />
                    )}
                  </Pie>
                  <Tooltip
                    content={<ChartTooltip nameMap={CHANNEL_NAME_MAP} />}
                    isAnimationActive={false}
                    formatter={formatPercent}
                  />
                </PieChart>
              </ResponsiveContainer>
            </>}
          </Grid.Column>
          <Grid.Column stretched width={11} style={{ paddingTop: 15, flexGrow: 1 }}>
            {!!loading && <TrendChartLoader />}
            {!loading && !!channels && !!channelBreakdown &&
              <ResponsiveContainer width='100%' height={350} id="channelMixTrends">
                <ComposedChart data={channelBreakdown}>
                  <XAxis hide dataKey='week' />
                  <YAxis hide yAxisId='bar' domain={[0, 1]} />
                  
                  {channels.map((k, i) =>
                    <Bar
                      key={k}
                      dataKey={k}
                      yAxisId='bar'
                      stackId='spend'
                      fill={CHANNELS.find(x => x.key === k)?.color}
                    />
                  )}

                  <Tooltip
                    content={
                      <ChartTooltip
                        labelFormatter={x => `Week of ${x}`}
                        nameMap={CHANNEL_NAME_MAP}
                      />
                    }
                    formatter={formatPercent}
                    position={{ y: 5 }}
                    cursor={{
                      stroke: '#efefef',
                      strokeWidth: 2,
                      strokeDasharray: '5 5',
                    }}
                  />
                  <Legend
                    formatter={(name) => CHANNEL_NAME_MAP[name] || name}
                    wrapperStyle={{ paddingTop: 20 }}
                  />

                </ComposedChart>
              </ResponsiveContainer>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ContentCard>

    <ConnectPromo />

    <ContentCard
      title={<>
        Spend Trends by Channel:&nbsp;
        {!!channelBreakdownSummary && channelBreakdownSummary.map(({ channel }) => 
          <Label
            as={!!trendData && 'a'}
            disabled
            key={channel}
            active={!!trendData && channel === selectedChannel}
            onClick={() => setSelectedChannel(channel)}
            style={!!trendData && channel === selectedChannel ? {
              backgroundColor: CHANNEL_MAP[channel].color,
              color: '#fff',
              boxShadow: '0px 0px 4px 1px rgba(34, 36, 38, 0.15)',
            } : {}}
          >
            {!!CHANNEL_MAP[channel].icon && 
              <Icon
                name={CHANNEL_MAP[channel].icon}
                style={{ fontSize: '1em' }}
              />
            }
            {!!CHANNEL_MAP[channel].customIcon && 
              <FontAwesomeIcon
                as='i'
                className='icon'
                icon={CHANNEL_MAP[channel].customIcon}
                style={{ height: 11 }}
              />
            }
            {CHANNEL_MAP[channel].name}
          </Label>
        )}
      </>}
    >
      {loading ? <ChannelTrendsLoader /> : <>
        <Grid>
          <Grid.Row>
            <Grid.Column stretched width={11} style={{ paddingTop: 15, flexGrow: 1 }}>
              <ResponsiveContainer width='100%' height={350} id="channelAllocationTrends">
                <ComposedChart data={channelData}>
                  <XAxis hide dataKey='week' />
                  <YAxis hide yAxisId='bar' domain={[0, 'dataMax']} />
                  <Bar
                    yAxisId='bar'
                    dataKey='pct_of_spend'
                    fill={CHANNELS.find(x => x.key === selectedChannel)?.color}
                  />
                  <Tooltip
                    content={
                      <ChartTooltip
                        labelFormatter={x => `Week of ${x}`}
                        nameMap={NAME_MAP}
                      />
                    }
                    formatter={formatPercent}
                    position={{ y: 25 }}
                    cursor={{
                      stroke: '#efefef',
                      strokeWidth: 2,
                      strokeDasharray: '5 5',
                    }}
                  />
                  <Legend
                    formatter={(name) => NAME_MAP[name] || name}
                    wrapperStyle={{ paddingTop: 20 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Grid.Column>
            <Grid.Column
              stretched
              verticalAlign='middle'
              style={{ width: 245, height: '75%', paddingLeft: 0 }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Statistic size='small'>
                  <Statistic.Value style={{ color: CHANNELS.find(x => x.key === selectedChannel)?.color }}>
                    <Icon
                      name={`caret ${selectedChannelStats?.yearlyPctChange < 0 ? 'down' : 'up'}`}
                      color={selectedChannelStats?.yearlyPctChange < 0 ? 'red' : 'green'}
                      style={{ opacity: 0.5 }}
                    />
                    &nbsp;
                    {formatPercent(Math.abs(selectedChannelStats?.yearlyPctChange))}
                  </Statistic.Value>
                  <Statistic.Label>
                    {`${selectedChannelStats?.yearlyPctChange < 0 ? 'de' : 'in'}crease over the past year`}
                  </Statistic.Label>
                </Statistic>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Statistic size='small'>
                  <Statistic.Value style={{ color: CHANNELS.find(x => x.key === selectedChannel)?.color }}>
                    <Icon
                      name={`caret ${selectedChannelStats?.monthlyPctChange < 0 ? 'down' : 'up'}`}
                      color={selectedChannelStats?.monthlyPctChange < 0 ? 'red' : 'green'}
                      style={{ opacity: 0.5 }}
                    />
                    &nbsp;
                    {formatPercent(Math.abs(selectedChannelStats?.monthlyPctChange))}
                  </Statistic.Value>
                  <Statistic.Label>
                    {`${selectedChannelStats?.monthlyPctChange < 0 ? 'de' : 'in'}crease over the past month`}
                  </Statistic.Label>
                </Statistic>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br />

        <ResponsiveContainer width='100%' height={150} id="channelSpendTrends">
          <ComposedChart data={channelData}>
            <XAxis hide dataKey='week' />
            <YAxis hide yAxisId='line'  domain={[-0.5, 0.5]} />
            <Line
              yAxisId='line'
              dataKey='pct_change'
              type='monotone'
              stroke={CHANNELS.find(x => x.key === selectedChannel)?.color}
              strokeWidth={3}
              dot={false}
            />
            <ReferenceLine
              yAxisId='line'
              y={0}
              stroke="rgba(0, 0, 0, 0.1)"
              strokeWidth={2}
            />
            <Tooltip
              content={
                <ChartTooltip
                  labelFormatter={x => `Week of ${x}`}
                  nameMap={NAME_MAP}
                />
              }
              formatter={formatPercent}
              position={{ y: 15 }}
              cursor={{
                stroke: '#efefef',
                strokeWidth: 2,
                strokeDasharray: '5 5',
              }}
            />
            <Legend
              formatter={(name) => NAME_MAP[name] || name}
              wrapperStyle={{ paddingTop: 20 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </>}
    </ContentCard>

    <ChannelRecommendations />
  </>)
}
 
export default SpendTrends
