import React from 'react'
import { Button } from 'semantic-ui-react'
import { ViewBoardsIcon } from '@heroicons/react/solid'

const CustomizeColumnsButton = ({ onClick }) => (
  <Button
    inverted
    color="purple"
    onClick={onClick}
    style={{ padding: '11px 13px', marginRight: 0, fontSize: 14 }}
    className='remove-box-shadow'
  >
    <ViewBoardsIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
    Customize Columns
  </Button>
)

export default CustomizeColumnsButton
