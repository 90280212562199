import React, { Component } from 'react';
import { ContentCard, SplitLayout, IndexGrid } from '@rockerbox/styleguide';
import { getBatchInbox, getBatchReportType, getBatchConfigData, updateBatchConfig, postBatchConfig, getInboxMessages, postBatchReportTest, getBatchTemplates } from '../../utils/api';
import { Config, TestPane } from './parts';
import { Button, Icon, Table, Dimmer, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import * as routes from '../../routes';;

const prettyJSON = (bodyString) => {
  return JSON.stringify(JSON.parse(bodyString), null, 2);
};

const fileTypeOptions = [
  {key: 1, value: 'csv', text: 'csv'},
  {key: 2, value: 'tsv', text: 'tsv'},
  {key: 3, value: 'xlsx', text: 'xlsx'},
  {key: 4, value: 'xls', text: 'xls'},
  {key: 5, value: 'gz', text: 'gz'},
  {key: 6, value: 'zip', text: 'zip'}
]

const SuccessCell = ({item, col}) => {
  return (
    <Table.Cell>
    <Icon
      color={item[col.key] ? 'green' : 'red'}
      name={item[col.key] ? 'checkmark' : 'close'} size='large' />
  </Table.Cell>
  )
}


class BatchEditIndex extends Component {
  state = {
    inboxOptions: [],
    reportTypeOptions: [],
    messagesOptions: [],
    configTab: 'extractSpec',
    testTab: 'select',
    inboxId: '',
    reportTypeId: '',
    reportTypes: [],
    editItem: {},
    name: '',
    extractSpec: '',
    config: '',
    fileType: '',
    fileName: '.',
    reportId: '',
    loadSwagger: '',
    messages: [],
    fileVal: '',
    previewList: [],
    test: '',
    result: '',
    swaggerError: [],
    cols: [],
    testResults: [],
    templateOptions: [],
    templateData: [],
    selectedTemplate: ''
  }

  componentDidMount() {
    if(this.props.match.path.includes('edit')){
      this.initializeData();
    } else {
      //on create
      this.initializeData();
      getBatchTemplates()
        .then(templateData => {
          const templateOptions = templateData.map(c => {
            return {text: c.name, value: c.id}
          })
        this.setState({templateOptions, templateData})
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { reportTypeId, reportTypes } = this.state;

    if(prevState.reportTypeId !== reportTypeId && reportTypeId) {
      const item = reportTypes.find(c => c.id == reportTypeId)
      this.setState({loadSwagger: item.swagger})
    }
  }

  initializeData = () => {
    const { id, inboxId } = this.props.match.params;
    getBatchInbox()
      .then(data => {
        const inboxOptions = data.map(c => {return {text: c.name, value: c.id}})
        this.setState({inboxOptions})
      })

    getBatchReportType()
      .then(data => {
        const reportTypeOptions = data.map(c => {
          return {text: c.display_name, value: c.id}
        })

        this.setState({reportTypeOptions, reportTypes: data});
      })

    if(id) {
      getBatchConfigData()
      .then(data => {
        const editItem = data.find(c => c.id == id);
        const inboxId = editItem.inbox_id;
        const name = editItem.name;
        const extractSpec = JSON.stringify(editItem.inbox_swagger,false,2);
        const reportTypeId = editItem.report_type_id;
        const config = editItem.config ? JSON.stringify(editItem.config,false,2) : '';
        const fileType = editItem.file_type;
        const fileName = editItem.filename;
        const reportId = editItem.report_id

        this.setState({editItem, inboxId, name, extractSpec, reportTypeId, config, fileType, fileName, reportId})

        getInboxMessages(inboxId)
        .then(data => {
          // set default values
          let messagesOptions = [];
          let messages = [];
          let fileVal = '';
          let previewList = []
          let test = ""

          const hasData = data.length > 0;

          if(hasData) {
            const firstItem = data[0];
            messagesOptions = firstItem.messages.map(c => {return {text: c.filename, value: c.filename}});
            messages = firstItem.messages;
            fileVal = messages.length > 0 ? messages[0].filename : fileVal;

            const selectedMessage = messages.length > 0 ? messages[0] : {};
            previewList = Object.keys(selectedMessage).length > 0 ? selectedMessage.preview_json : previewList;
            test = previewList.length > 0 ? JSON.stringify(previewList[0], 0, 2) : test;
          }

          this.setState({ messages, messagesOptions, fileVal, previewList, test})
        })
      })
    }

    if(inboxId) {
      this.setState({inboxId: parseInt(inboxId)})
    }
  }

  handleTextChange = (val, key) => {
    this.setState({[key]: val});
  }

  handleSubmit = () => {
    const { name, inboxId, config, extractSpec, reportTypeId, fileType, fileName, reportId } = this.state;
    const { id } = this.props.match.params;

    const postObj = {
      name,
      inbox_id: inboxId,
      config,
      file_type: fileType,
      filename_regex: fileName,
      inbox_swagger: extractSpec,
      report_type_id: reportTypeId,
      report_id: reportId
    }

    if(id) {
      updateBatchConfig(postObj, id)
      .then(data => this.initializeData)
    }
    else {
      postBatchConfig(postObj)
        .then(data => this.props.history.push(`/v2/settings/batch`))
    }

  }

  updatePreviewList = (event, data) => {
    const filename = data.value;
    const { messages } = this.state;
    const previewList = messages.find(c => c.filename == filename).preview_json;

    this.setState({previewList, fileVal: filename})
  }

  handleUse = (json) => {
    this.setState({testTab: 'test', test: JSON.stringify(json, 0, 2)})
  }

  handleTest = () => {
    const { test, config, reportTypes, reportTypeId, loadSwagger } = this.state;
    const report_type_name = reportTypes.find(c => c.id == reportTypeId).name;

    const postObj = {
      payload: test,
      config,
      report_type_name,
      swagger: loadSwagger
    }

    postBatchReportTest(postObj)
    .then(data => {
      const swaggerError = data.errors ? data.errors : [];
      this.setState({result: JSON.stringify(data.data, 0,2), testTab: 'result', swaggerError})
    })

  }

  handleSearchChange = (value) => {
    const { webhookEvents } = this.state;
    const filteredData = webhookEvents.filter(
      event => event.body.indexOf(value) > -1
    );

    this.setState({ searchVal: value, filteredData });
  }

  handleTemplateChange = (event, data) => {
    const { value } = data;
    const { templateData } = this.state;

    const selected = templateData.find(c => c.id == value);
    const selectedConfigTemplate = selected ? selected.config_template : '';
    const selectedSwaggerTemplate = selected ? selected.swagger_template : '';
    const selectedReportTypeId = selected ? selected.report_type_id : '';
    this.setState({selectedTemplate: value, config: selectedConfigTemplate, extractSpec: selectedSwaggerTemplate, reportTypeId: selectedReportTypeId})
  }

  toggleActive = (key, tab) => {
    this.setState({[key]: tab})
  }

  handleTestAll = () => {
    const { id } = this.props.match.params;
    const { config, previewList, reportTypes, reportTypeId } = this.state;
    const report_type_name = reportTypes.find(c => c.id == reportTypeId).name;

    const apiCalls = previewList.map(c => {
      const postObj = {
        report_type_name,
        config,
        payload: JSON.stringify(c)
      }

      return postBatchReportTest(postObj)
        .then(data => {
          return {"request":postObj,"success": data}
        })
        .catch(error => {
          return {"request":postObj,"failure": true}
        })
    })

    this.props.history.push(`${routes.batchConfigEdit}/${id}/test-results`);

    this.setState({loading: true})

    Promise.all(apiCalls)
      .then(data => {
        const responseResults = data.map(row => {
          return row.success.data.map( d => Object.assign(
              {
                report_type_name: row.request.report_type_name,
                success: !!row.success.data
              },
              d || {}
            )
          )
        })

        const testResults = responseResults.flat()

        const objWithAllKeys = testResults.reduce((p,c) => {
          return Object.assign(p, c)
        }, {});

        const allKeys = Object.keys(objWithAllKeys).sort();

        const cols = [{display: "success", key: "success", as: SuccessCell }].concat(
          allKeys.filter(c => c != "success").map(c => {
            return {display: c, key: c}
          })
        )

        this.setState({ testResults, cols, loading: false })
      })
  }
  render() {
    const { id } = this.props.match.params;

    return (
      <React.Fragment>
        {!this.props.match.params.results && <SplitLayout
          leftWidth={8}
          rightWidth={8}
          leftContent={
            <React.Fragment>
              <Config
                {...this.state}
                handleTextChange={this.handleTextChange}
                handleCopy={this.handleCopy}
                handleTemplateChange={this.handleTemplateChange}
                toggleActive={this.toggleActive}
                fileTypeOptions={fileTypeOptions}
                id={this.props.match.params.id}
                handleSubmit={this.handleSubmit}
              />
            </React.Fragment>
          }
          rightContent={
            <React.Fragment>
              <TestPane
                {...this.state}
                toggleActive={this.toggleActive}
                updatePreviewList={this.updatePreviewList}
                handleUse={this.handleUse}
                handleTest={this.handleTest}
              />
              <Button primary size="small" content="Test All" onClick={this.handleTestAll}/>
            </React.Fragment>
          }
        />}
        {this.props.match.params.results &&
          (
            <ContentCard hasTable title="Test Results"
              topRight={<span style={{color: '#4183C4', cursor: 'pointer !important'}} onClick={() => this.props.history.push(`${routes.batchConfigEdit}/${id}`)}><Icon name='angle left'/> Back</span>}>
              { this.state.loading &&
                <div style={{padding: 10}}>
                  <Dimmer active inverted>
                    <Loader active inline='centered' />
                  </Dimmer>
                </div>
              }
              { !this.state.loading && <IndexGrid cols={this.state.cols} data={this.state.testResults} />}
            </ContentCard>
          )
        }
      </React.Fragment>
    )
  }
}

export default withRouter(BatchEditIndex);
