import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import View from './component';
import * as routes from '../../routes';
import Fetch from '../parts/GenericFetch';
import { rootAction } from '../../rootAction';
import { SET_ALL_SEGMENTS } from '../../constants';
import SelectConversion from '../SelectSegment';


const ATTRIBUTABLE_ENDPOINT = "/attributable_events";
const JOURNEY_ENDPOINT = '/has/onsite/pixel/';
const SET_CUSTOMER_PATH = 'SET_CUSTOMER_PATH';
const CUSTOMER_PATH_ERROR = 'CUSTOMER_PATH_ERROR';
const SET_ATTRIBUTABLE_EVENTS = "SET_ATTRIBUTABLE_EVENTS";

const getUrlPart = (props, index) => {
  const parts = props.history.location.pathname.split('/');
  return parts[parts.length - index];
}


const getConversionEvent = (state, props) => {
  const { segments } = state.segment;
  if (!segments.length) return;

  const actionId = parseInt(props.match.params.id);
  const detail = segments.find((segment) => segment.action_id === actionId);
  return detail;
}

const getUrlPattern = (state, props) => {
  const detail = getConversionEvent(state, props);
  return detail && detail.url_pattern[0];
}

const JourneyWrapper = (props) => {

  const { uid } = props.match.params

  return (
    [
      <Fetch
        endpoint={ATTRIBUTABLE_ENDPOINT}
        error={props.setJourneyError}
        success={props.setAttributableEvents} />,
      <Fetch
        endpoint={JOURNEY_ENDPOINT + props.match.params.uid}
        error={props.setJourneyError}
        success={props.setPath} />,
      <View {...props} /> ,
      <div style={{"display":"none"}}><SelectConversion endpoint={id => routes.customerData + '/' + id + "/" + uid} {...props} /></div>,
    ]
  )
}


function mapStateToProps(state, props){
  return {
    error: state.journey.error,
    path: state.journey.path,
    segments: state.segment.segments,
    conversionEvent: getConversionEvent(state, props),
    conversionEventId: props.match.params.id,
    conversionPattern: getUrlPattern(state, props),
    events: state.attributableEvents.attributable_events
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setJourneyError: () => dispatch(rootAction(CUSTOMER_PATH_ERROR)),
    setPath: (data) => dispatch(rootAction(SET_CUSTOMER_PATH, data)),
    setAttributableEvents: (data) => dispatch(rootAction(SET_ATTRIBUTABLE_EVENTS, data))
  }
}

const defaultJourneyState = {
  path: [],
  error: undefined
}

export const CustomerJourney = withRouter(connect(mapStateToProps, mapDispatchToProps)(JourneyWrapper));

export function reducer(state = defaultJourneyState, action){
  switch(action.type) {
    case SET_CUSTOMER_PATH:
      return {
        ...state,
        path: action.data.response,
        error: undefined
      }
    case CUSTOMER_PATH_ERROR:
      return {
        ...state,
        error: "Sorry, there's no data associated with this customer profile"
      }
    default:
      return {
        ...state
      }
  }
}
