import React, { useState, useEffect } from 'react';
import { getProcessedReports, getBatchReports, getBatchConfigData } from '../../../utils/api.js'
import { Dropdown } from 'semantic-ui-react'
import * as d3 from 'rockerbox_d3_legacy_clone';
import { ContentCard, StatCard, IndexGrid, PagedIndexGrid } from '@rockerbox/styleguide'

const StatCardSelector = ({ value, options, onClick }) => {
  return <div style={{display: "flex", flexDirection: "row"}}>
    <div />
    { options.map((row) => {
        const { text, count } = row
        return <StatCard 
          size="small"
          label={text} value={count} 
          style={{flex: 1, marginLeft:"10px", marginRight:"10px"}} 
          segmentColor={row.value == value ? "blue" : ""} 
          onClick={(evt, value) => onClick(evt, { value: row.value}) }
        />
      })
    }
    <div />
  </div>
}

const GenericAutoParams = (props) => {
  const { platform } = props
  const reportTypeName = `${platform}_auto_params`

  const [reportTypes, setReportTypes] = React.useState(false)
  const [inboxData, setInboxData] = React.useState(false)
  const [data, setData] = React.useState(false)
  const [selected, setSelected] = React.useState("total")
  const [account, setAccount] = React.useState("all")

  const onClick = (evt, { value }) => setSelected(value)

  React.useEffect(() => {
    getBatchReports().then(setReportTypes)
    getBatchConfigData().then(setInboxData)
  }, [])

  const reportType = React.useMemo(
    () => reportTypes ? reportTypes.find(row => row.report_type_name == reportTypeName) : false,
    [reportTypes, reportTypeName]
  )

  const { report_id } = React.useMemo(() => {
    if (!inboxData || !reportType) return {}
    const { id } = reportType
    return inboxData.find(row => row.report_id == id)
  }, [reportType, inboxData]) || {}

  React.useEffect(() => {
    if (report_id) getProcessedReports(report_id, "processed", "data")
      .then(setData)
  },[report_id])

  const accounts = Object.values((data || []).reduce((p,c) => {
    p[c.account_id] = {text: c.account_id, value: c.account_id} 
    return p
  }, {"all": {text: "All", value: "all"}}))

  const accountData = React.useMemo(() => account != "all" ? data.filter(row => row.account_id == account) : data || [],[data])

  const formatted = d3.nest()
    .key(row => row.status)
    .entries(data || [])
    .map(row => {
      const count = row.values.length
      const text = row.key
      return Object.assign({ count, text, value: text }, row)
    })
    .concat([{ text: "Total", value: "total", count: data.length, values: data }])

  const columns = data.length > 0 ? IndexGrid.arrayToColumns(Object.keys(data[0])) : []
  const selectedData = data.length && formatted.length ? formatted.find(row => row.value == selected).values : []

  return <>
    <ContentCard>
      <label style={{fontWeight:"bold"}}>Select Account </label>
      <Dropdown selection options={accounts} value={account} onChange={(e, { value }) => setAccount(value)}/>
    </ContentCard>
    <StatCardSelector {...{options: formatted, value: selected, onClick}} />
    <ContentCard hasTable style={{overflowX: "scroll"}}>
      <PagedIndexGrid itemsPerPage={20} cols={columns} data={selectedData} />
    </ContentCard>
  </>
}

export default GenericAutoParams;
