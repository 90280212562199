import React, { Component } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
const noop = () => {}

const DateRangeByType = (props) => {

  const { rangeType = "week", onChange = noop } = props;

  const {
    startDateOffset = (day) => day.startOf(rangeType),
    endDateOffset = (day) => day.endOf(rangeType)
  } = props;

  const [startDate, setStartDate] = React.useState(false)
  const [endDate, setEndDate] = React.useState(false)
  const [focusedInput, setFocusedInput] = React.useState(true)

  const onFocusChange = (focusedInput) => {
    setFocusedInput(!focusedInput ? startDate: focusedInput)
  } 

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  React.useEffect(() => {
    onChange(false, { startDate, endDate })
  }, [startDate, endDate])

  return <DayPickerRangeController 
      {...{ 
        focusedInput,
        autoFocusEndDate: false,
        startDate,
        endDate,
        onFocusChange,
        onDatesChange,
        startDateOffset,
        endDateOffset,
        hideKeyboardShortcutsPanel: true
      }} 
    />

}

export default DateRangeByType
