import React, { useEffect, useState } from 'react';

const CheckboxKeyboardAccessible = ({ label, setValue, resetField, name, ...field }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setValue(name, isChecked);
      }, [isChecked]);

    useEffect(() => {
      setValue(name, error);
    }, [error]);
    
    return (
      <div className="checkbox-wrapper">
        <label>
          <input type="checkbox"
          style={{ height: 20, width: 20, verticalAlign: 'middle' }}
            {...field}
            onChange={() => {
                setIsChecked((prev) => !prev)
                setError((prev) => !prev)
                resetField(name)
                }}
            />
          <span>{label}</span>
        </label>
      </div>
    );
  };
  export default CheckboxKeyboardAccessible;
