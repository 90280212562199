import React, { useEffect } from "react";
import ExperimentList from "./ExperimentList";
import ExperimentResultView from "./ExperimentResultView";
import ExperimentSetupForm from "./ExperimentSetupForm";
import ExperimentEditForm from "./ExperimentEditForm";
import ExperimentEmptyView from "./ExperimentEmptyView";
import InfoCard from "./InfoCard";
import { experimentStore } from "./store";
import { SplitLayout } from "@rockerbox/styleguide";
import { customHistory } from "../pushpath";
import { Loader, Dimmer, Segment } from "semantic-ui-react";
import { findCampaign, parseExperimentState } from "./helper";
import CacheContext from "../../utils/CacheContext.js";
import { getSegments } from "../../utils/api";
import "./experimentCustomizeStyle.css";

const experimentsSelector = state => [
  state.getExperiments,
  state.experimentList,
  state.activeExperimentId,
  state.setActiveExperimentId,
  state.getPlatforms,
  state.initialLoading,
  state.setInitialLoading,
  state.activeTreatments,
  state.setActiveTreatments,
  state.getExperimentArtifacts
];

const IncrementalityAnalysisIndex = props => {
  const {
    match: { params }
  } = props;

  const [
    getExperiments,
    experimentList,
    activeExperimentId,
    setActiveExperimentId,
    getPlatforms,
    initialLoading,
    setInitialLoading,
    activeTreatments,
    setActiveTreatments,
    getExperimentArtifacts
  ] = experimentStore(experimentsSelector);

  const [state, setState, Context] = React.useContext(CacheContext);
  const segments = Context.getCache("segments", getSegments) || [];
  const { updatePath } = customHistory(props);
  const viewState = params["view"];

  const contentMap = {
    live: ExperimentEmptyView,
    view: ExperimentList,
    create: ExperimentSetupForm,
    edit: ExperimentEditForm,
    index: ExperimentResultView
  };
  const ContentComponenet = contentMap[viewState] || ExperimentList;
  const activeExperiment = React.useMemo(() => {
    const experiment =
      (activeExperimentId &&
        experimentList.filter(item => item.id === activeExperimentId)[0]) ||
      {};
    experiment["baselineCampaignName"] = findCampaign(experiment);
    experiment["state"] = parseExperimentState(experiment);
    const segment = segments.filter(
      segment => segment.filter_id === experiment.filter_id
    )[0];
    experiment["segment_name"] = segment ? segment.action_name : null;
    return experiment;
  }, [activeExperimentId, experimentList, segments]);

  useEffect(() => {
    getExperiments()
      .then(data => {
        if (!viewState) {
          if (data.length) {
            updatePath({ view: "view" });
          } else {
            updatePath({ view: "live" });
          }
        }
      })
      .finally(() => setInitialLoading(false));
    getPlatforms();
    getExperimentArtifacts();
  }, []);

  useEffect(() => {
    if (!viewState) {
      if (experimentList.length) {
        updatePath({ view: "view" });
      } else {
        updatePath({ view: "live" });
      }
    }
  }, [experimentList, viewState]);

  useEffect(() => {
    if (params["id"]) {
      setActiveExperimentId(parseInt(params.id));
    }
  }, [params["id"]]);

  useEffect(() => {
    const { treatments } = activeExperiment;
    if (treatments) {
      setActiveTreatments(treatments);
    }
  }, [activeExperiment, activeExperiment.treatments?.length]);

  const showInfoCard = ["view", "create", "edit"].indexOf(viewState) !== -1;

  return initialLoading ? (
    <div className="flex-centered p-100">
      <Loader active />
    </div>
  ) : (
    <SplitLayout
      leftWidth={showInfoCard ? 13 : 16}
      rightWidth={showInfoCard ? 3 : 0}
      leftContent={
        <ContentComponenet
          {...props}
          updatePath={updatePath}
          {...activeExperiment}
          activeExperiment={activeExperiment}
        />
      }
      rightContent={showInfoCard ? <InfoCard /> : null}
    />
  );
};

export default IncrementalityAnalysisIndex;
