// Code moved from /V3/utils/parquetToJson.js
import { trimEnd } from 'lodash';
import * as aq from 'arquero';

export const isValidJSONArray = value => {
  try {
    const parsedValue = JSON.parse(value);
    return Array.isArray(parsedValue);
  } catch (error) {
    return false;
  }
};

export const isDecimal = value => ArrayBuffer.isView(value);

export const isBigInt = value => Object.prototype.toString.call(value).slice(8, -1) === 'BigInt';

export const isDate = value => {
  if (typeof value !== 'object' || ArrayBuffer.isView(value)) {
    return false;
  }
  const timestamp = Date.parse(value);
  return !Number.isNaN(timestamp);
};

const MAX_INT32 = 2 ** 31 - 1;
function isNegative(value) {
  return value[value.length - 1] > MAX_INT32;
}

function negate(value) {
  let carry = 1;
  for (let i = 0; i < value.length; i++) { // eslint-disable-line no-plusplus
    const elem = value[i];
    const updated = ~elem + carry;
    value[i] = updated; // eslint-disable-line no-param-reassign
    carry &= elem === 0 ? 1 : 0;
  }
}

function uint32ArrayToDecimal(value, scale = 10) {
  const negative = isNegative(value);
  const sign = negative ? '-' : '';
  if (negative) {
    negate(value);
  }

  const str = value.toString().padStart(scale, '0');
  if (scale === 0) {
    return `${sign}${str}`;
  }

  const wholePart = str.slice(0, -scale) || '0';
  const decimalPart = trimEnd(str.slice(-scale), '0') || '0';
  return `${sign}${wholePart}.${decimalPart}`;
}

export const parseArch = (_arch, _data) => {
  const columnNames = _arch.columnNames();
  const utcDateColumns = columnNames
    .filter(col => isDate(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = `d => op.format_utcdate(d['${col}'])`;
      return accu;
    }, {});

  const validJSONColumnsArrays = columnNames
    .filter(col => isValidJSONArray(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => JSON.parse(d[col]));
      return accu;
    }, {});

  // const _data = [...dataFromCache, ...dataFromRemote][0]['data'] // use first dataset's schema
  const decimalCols = _data.schema ? _data.schema.fields.filter(f => f.type.scale) : [];
  const scaleMap = decimalCols.reduce((accu, c) => {
    accu[c.name] = c.type.scale;
    return accu;
  }, {});

  const decimalColumns = columnNames
    .filter(col => isDecimal(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => uint32ArrayToDecimal(d[col], scaleMap[col]));
      return accu;
    }, {});

  const bigIntColumns = columnNames
    .filter(col => isBigInt(_arch.get(col)))
    .reduce((accu, col) => {
      accu[col] = aq.escape(d => Number(d[col]));
      return accu;
    }, {});

  const arch = _arch
    .derive(utcDateColumns)
    .derive(validJSONColumnsArrays)
    .derive(decimalColumns)
    .derive(bigIntColumns);

  return arch;
};
