import React from 'react'
import { Header, Button } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  background-color: #${props => props.buttonColor} !important;

  &:hover {
    background-color: #${props => props.buttonColor} !important;
    opacity: 0.8;
  }
`

export const ExistingAccountMessage = ({ buttonColor }) => {
  return (
    <div className='signup-form-existing-account'>
      <Header
        as='h2'
        content='You already have an existing Rockerbox account 🙂'
      />
      <StyledButton
        {...{ buttonColor }}
        primary
        size='large'
        content='Open your Dashboard'
        icon='external square'
        labelPosition='right'
        onClick={() => window.open('https://app.rockerbox.com', '_blank')}
      />
    </div>
  )
}
