import React, { useMemo } from 'react'

import { CacheContext } from '../../../utils/CacheContext'
import { switchAccount, getUser, getUserPermissions } from '../../api/account'
import { getAnnouncements, getArtifacts } from '../../api/attribution'
import NavbarV3 from './component'
import { LOGOUT, LOGIN } from './constants'

const Navbar = ({ history }) => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const { account } = state
  const features = account?.features || []

  const artifacts = Context.getCache('artifacts', getArtifacts) || null
  const user = (Context.getCache(`user`, getUser) || {})
  const permissions = (Context.getCache(`permissions`, getUserPermissions) || {})
  const allowedAccounts = permissions.accounts || []
  const accounts = React.useMemo(() =>
    allowedAccounts.map(({ advertiser_name, pixel_source_name }) => 
      Object({
        text: advertiser_name,
        key: pixel_source_name,
        value: pixel_source_name,
      })
    )
  , [allowedAccounts])

  const announcements = (Context.getCache(`announcements`, getAnnouncements) || [])
  const notifications = React.useMemo(() => announcements.filter(a => !a.major), [announcements])

  const parsedArtifacts = useMemo(() => {
    if (!artifacts) return {}
    const parsed = {}
    Object.entries(artifacts).map(x => {
      try {
        parsed[x[0]] = JSON.parse(x[1])
      } catch (e) {
        parsed[x[0]] = x[1]
      }
    })
    return parsed
  }, [artifacts])

  const handleAccountChange = (value) => {
    switchAccount(value)
      .then(() => document.location.reload(true))
  }

  const handleLogout = () => {
    fetch(LOGOUT,
      {'headers': {'accept': 'application/json'}}
    ).then(() => {
      if (!!window.gapi) {
        try {
          var auth2 = window.gapi.auth2.getAuthInstance()
          auth2.signOut().then(() => 
            console.info('Google user signed out')
          )
        }
        catch (e) {
          console.error(e)
        }
      }
      window.location = LOGIN
    })
  }

  const navigate = (route) => history.push(route)

  return (
    <NavbarV3
      {...{ navigate, account, accounts, user, handleAccountChange, features, notifications, handleLogout, artifacts: parsedArtifacts }}
    />
  )
}

export default Navbar
