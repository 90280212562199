import React, { useState, useEffect } from 'react'
import { Header, Form, Button, Input } from 'semantic-ui-react'
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { getUser, putUser } from '../../api/account'

const ChangeDisplayName = () => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(undefined)
  const [firstName, setFirstName] = useState('-')
  const [lastName, setLastName] = useState('-')

  useEffect(() => {
    getUser().then((user) => { setUser(user) })
  }, [])

  useEffect(() => {
    if (!user) return
    setFirstName(user.first_name)
    setLastName(user.last_name)
  }, [user])

  const { control, handleSubmit, formState: { errors }, setValue, trigger, reset, watch } = useForm({
    defaultValues: {
      first_name: "",
      last_name: ""
    }
  })

  const watchFields = watch(["first_name", "last_name"])

  const onChange = (e, { name, value }) => {
    setValue(name, value);
    trigger(name);
  }

  const onSubmit = ({ first_name, last_name }) => {
    if (!first_name && !last_name) return
    const postObj = { first_name: first_name || firstName, last_name: last_name || lastName }
    setLoading(true)
    putUser(postObj)
      .then(res => {
        setLoading(false)
        setFirstName(res.first_name)
        setLastName(res.last_name)
        reset({ first_name: '', last_name: '' })
      })
      .catch(err => {
        console.log('error updating name', err)
        toast.error('Error update first and last name')
        setLoading(false);
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 25 }}>
      <Form.Group>
      <Form.Field>
        <Header as="h3" content="First Name" />
        <label>{firstName}</label>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              style={{ width: 275 }}
              placeholder="new first name"
              error={errors.first_name?.message}
              onChange={onChange}
              disabled={loading}
            />
          )}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h3" content="Last Name" />
        <label>{lastName}</label>
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              style={{ width: 275 }}
              placeholder="new last name"
              error={errors.last_name?.message}
              onChange={onChange}
              disabled={loading}
            />
          )}
        />
      </Form.Field>
      <Button
        type="submit"
        primary
        style={{ alignSelf: 'end', marginBottom: 1, marginRight: 6 }}
        disabled={watchFields.join('').length === 0}
        onClick={onSubmit}
        loading={loading}
      >
        Submit
      </Button>
      </Form.Group>
    </Form>
  );
}

export default ChangeDisplayName
