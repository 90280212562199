import React, { Component } from 'react';
import { getDataset, getEntityConfigs } from '../../utils/api';
import { SplitLayout, SortableIndexGrid, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Dropdown, Step, Message, Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import * as d3 from 'rockerbox_d3_legacy_clone';
import moment from 'moment';
import SetupStatus, { defaultFunc } from '../DataStatus';

const Status = (props) => {
  const [entityData, setEntityData] = React.useState([])
  const [segmentId, setSegmentId] = React.useState(false)

  React.useEffect(() => {
    getEntityConfigs()
      .then(entityData => {
        if (entityData.length > 0) setSegmentId(entityData[0].segments[0].segment_id)
        setEntityData(entityData)
      })
  },[])

  const datasets = [
    { dataset: "entity_difference", display: "Has Sponsorships Data?"}
  ]

  const funcs = [
    { func: defaultFunc, text: "Backfill", display: "", url: "/run/agg/events" }
  ]


  if (segmentId) return <SetupStatus filter_id={segmentId} {...{datasets, funcs}} />
  return null
}


export default Status;
