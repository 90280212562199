import moment from "moment";
import { searchSplitKey } from "./constants";
import _ from "lodash";
import { proportionalZTest } from "./statistic";

export const findCampaign = (experiment, type = "control") => {
  if (!experiment) {
    return null;
  }
  const baseline_tiers = ["tier_5", "tier_4", "tier_3", "tier_2", "tier_1"];
  const testTier = baseline_tiers.find(tier => !!experiment[`${type}_${tier}`]);

  return experiment[testTier];
};

export const findCampaigns = (experiment, type = "control") => {
  if (experiment) {
    const baseline_tiers = ["tier_5", "tier_4", "tier_3", "tier_2", "tier_1"];
    const testTierIndex = baseline_tiers.findIndex(
      tier => !!experiment[`${type}_${tier}`]
    );

    return baseline_tiers.slice(testTierIndex);
  }
  return null;
};

export const findNumDays = ({ start_date, end_date }) => {
  if (!end_date) {
    return moment().diff(moment(start_date), "days");
  } else {
    return moment(end_date).diff(moment(start_date), "days");
  }
};

export const parseExperimentState = ({ end_date, active }) => {
  if (!end_date) {
    return "running";
  } else if (active === 0) {
    return "archived";
  } else {
    return "completed";
  }
};

export const getTiers = data_tier => {
  if (!data_tier) {
    return [];
  }
  const maxIndex = parseInt(data_tier.split("_")[1]);
  const tiers = [];
  for (let i = 1; i <= maxIndex; i++) {
    tiers.push(`tier_${i}`);
  }

  return tiers;
};

export const combineRow = (a, b) => {
  const sumCols = [
    "spend",
    "tier_1_assisted",
    "tier_2_assisted",
    "tier_3_assisted",
    "tier_4_assisted",
    "tier_5_assisted",
    "tier_1_assisted_revenue",
    "tier_2_assisted_revenue",
    "tier_3_assisted_revenue",
    "tier_4_assisted_revenue",
    "tier_5_assisted_revenue"
  ];
  sumCols.forEach(col => {
    a[col] = b[col] !== undefined && !isNaN(b[col]) ? a[col] + b[col] : a[col];
  });

  return a;
};

export const withinDataRange = (min, max) => data => {
  if (!max) {
    max = max = moment().subtract(1, "days");
  }
  const target = moment(data.date);

  return target.diff(max) <= 0 && target.diff(min) >= 0;
};

export const parseValueToTiers = data => {
  const {
    value: { key, parents }
  } = data;

  parents.push(key);

  return parents.reduce((sum, current, index) => {
    sum[`control_tier_${index + 1}`] = current;
    return sum;
  }, {});
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const groupBySearchKeyAndDate = dataWithSearchKey => {
  if (!Array.isArray(dataWithSearchKey)) {
    return {};
  }
  return dataWithSearchKey.reduce((prev, cur) => {
    const { searchKey, date } = cur;
    if (searchKey in prev) {
      if (date in prev[searchKey]) {
        prev[searchKey][date] = combineRow(prev[searchKey][date], cur);
      } else {
        prev[searchKey][date] = cur;
      }
    } else {
      prev[searchKey] = {
        [date]: cur
      };
    }

    return prev;
  }, {});
};

export const createTableData = (
  type,
  testTiers,
  groupBySearchKey
) => treatment => {
  const key = testTiers
    .map(tier => treatment[`${type}_${tier}`])
    .join(searchSplitKey);

  const data = groupBySearchKey[key]
    ? Object.values(groupBySearchKey[key]).reduce((prev, cur) => {
        if (!prev) {
          prev = _.cloneDeep(cur);
        } else {
          prev = combineRow(prev, cur);
        }

        prev["treatment_id"] =
          type === "treatment" ? treatment["id"] : "baseline";
        return prev;
      }, null)
    : {};

  return data;
};

export const zTestCalculation = (controlExperiment, data_tier) => data => {
  if (!controlExperiment) {
    return data;
  }

  return Object.assign(
    {},
    data,
    proportionalZTest({
      controlNumeratorSum: controlExperiment[`${data_tier}_assisted`],
      controlDenominatorSum: controlExperiment["spend"],
      treatmentNumeratorSum: data[`${data_tier}_assisted`],
      treatmentDenominatorSum: data["spend"]
    })
  );
};

export const calcLiftAndRecommendation = data_tier => (data, i, array) => {
  const { spend, percentile } = data;
  const cpa =
    data[`${data_tier}_assisted`] === 0
      ? NaN
      : spend / data[`${data_tier}_assisted`];
  const roas = data[`${data_tier}_assisted_revenue`] / spend;
  const baselineCpa = array[0]["spend"] / array[0][`${data_tier}_assisted`];

  return Object.assign({}, data, {
    cpa,
    roas,
    recommendation:
      percentile < 95 || isNaN(percentile)
        ? "Insufficient Data"
        : cpa < baselineCpa
        ? "Outperforms"
        : cpa == baselineCpa
        ? "Neutral"
        : "Underperforms",
    lift: isNaN(cpa) ? 0 : (baselineCpa - cpa) / baselineCpa
  });
};

export const getAllDates = data => {
  const dates = data.reduce((prev, cur) => {
    return prev.concat(Object.keys(cur));
  }, []);

  const dateSet = new Set(dates);
  return [...dateSet].sort(function(a, b) {
    return new Date(a) - new Date(b);
  });
};

export const constructChartData = (selectedData, data_tier) => date => {
  const lists = selectedData.map(item => {
    if (!item[date]) {
      if (Object.values(item)[0]) {
        return { searchKey: Object.values(item)[0]["searchKey"] };
      } else {
        return null;
      }
    }
    return item[date];
  });
  const mapByCampaignName = (prev, cur) => {
    if (!cur) {
      return prev;
    }
    const campaign = cur["searchKey"];
    const conversions = cur[`${data_tier}_assisted`] || 0;
    const spend = cur["spend"] || 0;
    const revenue = cur[`${data_tier}_assisted_revenue`] || 0;

    prev[campaign] = {
      conversions: conversions,
      spend: spend,
      revenue: revenue
    };
    return prev;
  };
  return lists.reduce(mapByCampaignName, { name: date });
};

export const calculateCumulativeValue = chartData => {
  if (!Array.isArray(chartData)) {
    return;
  }

  chartData.forEach((dateData, i, array) => {
    if (i === 0) {
      return;
    }

    for (let key in dateData) {
      if (key !== "name" && array[i - 1][key]) {
        dateData[key]["spend"] =
          array[i - 1][key]["spend"] + dateData[key]["spend"];
        dateData[key]["revenue"] =
          array[i - 1][key]["revenue"] + dateData[key]["revenue"];
        dateData[key]["conversions"] =
          array[i - 1][key]["conversions"] + dateData[key]["conversions"];
      }
    }
  });
};

export const getCumulativeResult = (chartData, chartType) => {
  const chartTypeList = ["cpa", "roas"];
  if (chartTypeList.indexOf(chartType) === -1) {
    throw new Error(`Invalid type: ${chartType}`);
  }
  if (!Array.isArray(chartData)) {
    return [];
  }
  return chartData.map(data => {
    return Object.keys(data).reduce(
      (prev, key) => {
        if (key === "name") {
          return prev;
        }

        const calculatedValue =
          chartType === "cpa"
            ? data[key]["spend"] / data[key]["conversions"]
            : data[key]["revenue"] / data[key]["spend"];
        // edge case when 0
        try {
          prev[key] =
            calculatedValue === Infinity
              ? NaN
              : Number(calculatedValue.toFixed(3));
        } catch (e) {
          prev[key] = calculatedValue;
        }

        return prev;
      },
      { name: data["name"] }
    );
  });
};

export const hashCode = str => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
