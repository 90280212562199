import React from 'react'
import { Menu, Popup, Input } from 'semantic-ui-react'
import { UserIcon, LogoutIcon, RefreshIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, SwitchHorizontalIcon } from '@heroicons/react/solid'
import { track } from '../../../utils/tracking'

const AccountSwitcher = ({ account, accounts, handleAccountChange }) => {
  const [search, setSearch] = React.useState('')
  const [focusedOption, setFocusedOption] = React.useState(null)

  const { pixel_source_name } = account || {}

  const filteredAccounts = React.useMemo(() => {
    const searchLower = search.toLowerCase()
    return accounts.filter(({ text }) => text.toLowerCase().includes(searchLower))
  }, [accounts, search])

  const handleEnter = (event) => {
    const ENTER_KEY = 13
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault()
      handleAccountChange(focusedOption)
    }
  }

  return (
    <div className='account-switcher'>
      <div className='item no-click'>
        <div className='icon'>
          <SwitchHorizontalIcon />
        </div>
        <div className='text'>
          <div className='name'>
            Switch Account
          </div>
        </div>
      </div>
      <Input
        autoFocus
        fluid
        size='small'
        icon='search'
        iconPosition='left'
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
      />
      <div className='accounts-list'>
        {filteredAccounts.map(({ text, key, value }) => (
          <div
            {...{key}}
            tabIndex={0}
            className={`item account-option ${value === pixel_source_name && 'active'}`}
            onClick={() => handleAccountChange(value)}
            onFocus={() => setFocusedOption(value)}
            onKeyDown={handleEnter}
          >
            {text}
          </div>
        ))}
      </div>
      <div className="divider"></div>
    </div>
  )
}

const AccountMenu = ({ account, accounts, user, handleAccountChange, handleLogout }) => {
  const { advertiser_name, tier } = account || {}
  const { username, first_name, last_name } = user || {}
  const isAdminUser = (username || '').includes('@rockerbox.com')
  const displayName = !!first_name ? `${first_name} ${!!last_name ? last_name : ''}` : username
  const hasV2UIAccess = isAdminUser

  const [opened, setOpened] = React.useState(false)
  const [mouseOver, setMouseOver] = React.useState(false)

  return (
    <Popup
      transition={{ animation: 'fade down', duration: 500 }}
      trigger={
        <Menu.Item
          className='account-button'
          content={<>{advertiser_name}<ChevronDownIcon className='caret' /></>}
          active={!!opened}
          tabIndex={0}
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          onFocus={({ target }) => !opened && !mouseOver && target.click()}
        />
      }
      basic
      on='click'
      position='bottom right'
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className='nav-dropdown-menu nav-settings-menu'
      content={<>
        {accounts.length > 1 &&
          <AccountSwitcher
            {...{ account, accounts, handleAccountChange }}
          />
        }
        {hasV2UIAccess && <>
          <div
            className='item'
            onClick={() => {
              track(`nav.switch_to_classic`)
              window.location = '/v2'
            }}
          >
            <div className='icon'>
              <RefreshIcon />
            </div>
            <div className='text'>
              <div className='name'>Switch to Classic UI</div>
            </div>
          </div>
          <div className="divider"></div>
        </>}
        <div className='item no-click'>
          <div className='icon'>
            <UserIcon />
          </div>
          <div className='text'>
            <div className='name'>{displayName}</div>
          </div>
        </div>
        <div className='item logout' onClick={handleLogout}>
          <div className='icon'>
            <LogoutIcon />
          </div>
          <div className='text'>
            <div className='name'>Sign Out</div>
          </div>
        </div>
      </>}
    />
  )
}

export default AccountMenu
