import React, { useEffect, useState, useMemo } from 'react'
import { BasicViewHeader, SelectAPlatform,FilterWrapper, StyledDropdown, NoConnections } from '../../components'
import { useUrlStateWithDefaults } from './urlParamsHooks';
import useAuthedIntegrations from '../../hooks/useAuthedIntegrations';
import { channelPerformancePlatformsConfig } from '../../constants/mappings';

import Overlap from './Overlap';

import moment from 'moment'
import { platformPerformanceFirstReportingDate } from '../../utils/time';
import { segmentOptions } from '../../utils/options';
import useAppSettings from '../../hooks/useAppSettings';
import { useGlobalState } from '../../hooks/global';

const ChannelOverlap = () => {
  const [firstReportingDate, setFirstReportingDate] = useState(platformPerformanceFirstReportingDate)
  const { segments } = useGlobalState()
  const segOptions = useMemo(() => {
    return segmentOptions(segments)
  }, [segments])

  const settings = useAppSettings();
  const { tierColors } = settings;
  const urlState = useUrlStateWithDefaults()
  const { platform, startDate, endDate, setStartDate, setEndDate, segId, setSegId, ntfFilter, setNtfFilter } = urlState;
  const platformSelection = useAuthedIntegrations(channelPerformancePlatformsConfig);
  const { onDropdownChange, dropdownOptions, dropdownOptionsLoading, integrations } = platformSelection;

  const noPlatform = !platform || platform == 0 || platform == ''
  const { display_platform_name }  = channelPerformancePlatformsConfig[platform] || {}

  const { hasNtf, hasRevenue } = useMemo(() => {
    if(segments.length == 0 || segId == 0) return false
    const selectedSegment = segments.find(c => c.filter_id == segId)
    return {
      hasNtf: selectedSegment.has_new_to_file,
      hasRevenue: selectedSegment.include_revenue,
    }
  }, [segments, segId])

  const onDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    if(segOptions.length < 1 || segId == 0) return
    const firstReportingDate = segOptions.find(option => option.filter_id == segId)?.first_reporting_date
    const reportingDateBeforePpDate = moment(firstReportingDate).isBefore(platformPerformanceFirstReportingDate)
    const checkedDate = reportingDateBeforePpDate ? platformPerformanceFirstReportingDate : firstReportingDate

    setFirstReportingDate(checkedDate)
  }, [segOptions, segId])

  useEffect(() => {
    if(!noPlatform && segments.length > 0 && segId == 0) {
      const { filter_id } = segments.find(c => c.featured == 1)
      setSegId(filter_id) // setSegId on initial platform selection
    }
  }, [platform, segments])

  if (!!dropdownOptions && !dropdownOptions.length) {
    return <NoConnections/>
  }

  if (noPlatform) {
    return (
      <>
        <BasicViewHeader
          header='Channel Overlap'
          subheader="View advertising performance within a specific channel by looking at platform-reported and first-party metrics"
        />
        { dropdownOptions?.length == 0 ? null :
          <FilterWrapper 
            {...{ loading: dropdownOptionsLoading }}
            other={
              <StyledDropdown
                label={"Ad Platform"}
                defaultOpen={noPlatform ? true : false}
                value={platform}
                options={dropdownOptions}
                onChange={onDropdownChange}
              />
            }
          />
        }
        <SelectAPlatform {...platformSelection} />
      </>
    )
  }
  
  return (
    <>
      <BasicViewHeader
        header='Channel Overlap'
        subheader="View advertising performance within a specific channel by looking at platform-reported and first-party metrics"
      />
      <Overlap
        {...{ headerText: `Which other marketing channels are my ${display_platform_name} users interacting with?`, platform }} //Basic params
        {...{ startDate, endDate, onDateChange, firstReportingDate }} // Dates
        {...{ segOptions, segId, setSegId }} // Conversion params
        {...{ ntfFilter, setNtfFilter, hasNtf }} // NTF
        {...{ integrations, tierColors, hasRevenue }} // Other
      />
    </>
  )

}

export default ChannelOverlap

