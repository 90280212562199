import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Message, Grid, Form, Icon, Table, Button } from 'semantic-ui-react'
import { getWebhooksData } from '../../../utils/api.js'
import * as routes from '../../../routes.js'
import { WebhooksDataSource } from '../../Webhooks/WebhooksConfig'
import { WebhookUrl } from '../../Webhooks/webhookParts'
import { ContentCard } from '@rockerbox/styleguide'

const WebhookSetup = ({ platform }) => {

  const [activeWebhook, setActiveWebhook] = React.useState(false)
  const [match, setMatch] = React.useState({})

  React.useEffect(() => {
    getWebhooksData()
      .then(resp => {
        const activeWebhook = resp.find(({ action }) => action && action.toLowerCase().includes(platform))
        setActiveWebhook(activeWebhook)
      })
  }, [])

  React.useEffect(() => {
    if (activeWebhook) setMatch({ params: { id: activeWebhook.id}, path: "edit" })
  }, [activeWebhook])

  const push = (data) => {
    const splitData = data.split("/")
    const [id, messageId, results] = splitData.slice(splitData.indexOf("edit"),-1)//.concat([false]).slice(0,3)

    const params = (results) ? { id, messageId, results} :
      (messageId) ? { id, messageId } : { id }

    const match = { params, path: "edit"}
    setMatch(match)
  }

  return <>
    { !!activeWebhook &&
      <ContentCard>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2} style={{fontWeight: 'bold', maxWidth: 150}}>
              <span>Webhook URL:&nbsp;</span>
            </Grid.Column>
            <Grid.Column width={6} style={{paddingLeft: 0, display: 'flex', justifyContent: 'left'}}>
              <WebhookUrl action={activeWebhook.action} pixelSourceName={activeWebhook.pixel_source_name} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentCard>
    }
    { !!activeWebhook && match.params && <WebhooksDataSource {...{ history: { push }, hideEdit: true, match }} /> }
  </>

}
export default WebhookSetup;
