export function getCurrencyRate(currency_code, start_date, end_date='') {
    const url = `/currency_rate_range?currency_code=${currency_code}&start_date=${start_date}&end_date=${end_date}`;
    return fetch(url, {
      credentials: "include",
      method: "GET"
    })
      .then(function(response) {
        return response.json();
      })
  }
