import React, { Component } from 'react';
import { Step, Icon } from 'semantic-ui-react';


const Steps = (props) => {

  return (
    <Step.Group widths={3} size="mini">
      <Step active={props.active.data} onClick={() => props.updateActiveState("data")}>
        <Icon name="database" />
        <Step.Content>
          <Step.Title>Data Source</Step.Title>
        </Step.Content>
      </Step>

      <Step active={props.active.marketing} onClick={() => props.updateActiveState("marketing")}>
        <Icon name="tags" />
        <Step.Content>
          <Step.Title>Marketing Channels</Step.Title>
        </Step.Content>
      </Step>

      <Step active={props.active.mappings} onClick={() => props.updateActiveState("mappings")}>
        <Icon name="exchange" />
        <Step.Content>
          <Step.Title>Define Mappings</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}

export default Steps;
