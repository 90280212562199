import React, { Component } from 'react';
import { SplitLayout, ContentCard, IndexGrid } from '@rockerbox/styleguide'
import { trackJobV2, checkJobStatus, checkAggSubmission } from "../../utils/job_api";
import { Progress, Form, Accordion } from 'semantic-ui-react'

const BackfillStatus = (props) => {

  const { filteredData, segments, jobType } = props

  const [showDetails, setShowDetails] = React.useState(false)
  const [submissions, setSubmissions] = React.useState([])
  const [submitted, setSubmitted] = React.useState([])
  const [checkSubmitted, setCheckSubmitted] = React.useState(0)
  const [checkState, setCheckState] = React.useState(0)
  const [isDirty, setIsDirty] = React.useState(false)

  const filteredSet = React.useMemo(() => {
    return filteredData.reduce((p,c) => p.add(`${c.action_id}${c.date}`), new Set())
  }, [filteredData])

  React.useEffect(() => {

    setSubmissions([])
    setSubmitted([])

    const action_ids = Object.keys(segments)

    const promises = action_ids
      .map(action_id => checkAggSubmission(action_id, jobType))

    Promise.all(promises)
      .then(actionSubmissions => {
        const submissions = [];
        actionSubmissions.map((actionSubmission, i) => {
          const action_id = action_ids[i]
          Object.entries(actionSubmission['days'] || {})
            .map(([day, values]) => {
              Object.entries(values)
                .map(([job_id, state]) => {
                  const actionDay = `${action_id}${day}`
                  if (filteredSet.has(actionDay)) {
                    submissions.push({ action_id, day, job_id, state })
                  }
                })

            })
        })
        const submitted = submissions.filter(row => {
          return true //row.state == "submitted" || row.state === null || row.state == "running" || row.state.planned > 0 || row.state.running || (row.state.success + row.state.error) == 0
        })

        setSubmissions(submissions)
        setSubmitted(submitted)
        setIsDirty(false)
      })
  }, [filteredSet, segments, checkState])

  React.useEffect(() => {
    setIsDirty(true)
    const promises = submitted.map(row => {
      const { day, action_id, job_id } = row
      return checkJobStatus(job_id)
        .then(resp => {
          trackJobV2(day, action_id, jobType, job_id, resp.status_summary)
        })
    })

    Promise.all(promises)
      .then(all => {
        setCheckState(checkState + 1)
      })
  }, [checkSubmitted])
  
  const summaryState = d3.nest()
    .key(row => row)
    .rollup(values => values.length)
    .entries(submissions
      .map(row => {
        const { state } = row

        return state.error ? "error":
          state.planned > state.success ? "running" : 
          state.success > 0 && state.success == state.planned ? "success": "submitted"
      })
    )

  return <ContentCard title="Backfill Status">
    <IndexGrid data={summaryState} cols={[{display:"State",key:"key"},{display:"Count",key:"values"}]} />
    <div style={{textAlign:"center"}}>
      <Form.Button onClick={() => setCheckSubmitted(checkSubmitted + 1)} loading={isDirty}>
        Check State
      </Form.Button>
    </div>
    <Accordion>
      <Accordion.Title active={showDetails} onClick={() => setShowDetails(!showDetails)}>
        Details
      </Accordion.Title>
      <Accordion.Content active={showDetails}>

        { submissions.map(row => {
            return <div style={{display:"flex"}}>
              <div>
                { row.action_id } - { row.day } — 
                {row.state.running + row.state.success}/
                {row.state.planned}
                {row.finish_type}
              </div>

              { row.state && <div style={{display:"inline-block",width:"100px"}}><Progress 
                  progress style={{margin:0,marginLeft:"2em"}}
                  color={ (row.state.running + row.state.success)/row.state.planned == 1 ? "green" : row.state.error == 1 ? "red" : "yellow" } 
                  percent={parseInt((row.state.running + row.state.success)/row.state.planned*100)} 
                /></div>
              }
              { row.state && !!row.state.error && <><br/><a target="_blank" 
                  href={"http://wq.internal.getrockerbox.com/v2/workqueue_status/job/" + row.state.failures[0].job_id}
                >
                  {row.state.failures[0].job}
                </a> 
                </> 
              }
            </div>
          }) 
        }
      </Accordion.Content>
    </Accordion>
  </ContentCard>
}

export default BackfillStatus;
