import React, { useState, useMemo, useEffect } from 'react';
import { ContentCard, IndexGridTree } from '@rockerbox/styleguide';
import { useAtom } from 'jotai';
import moment from 'moment';

// local
import Filter from './Filter';
import { TrendCardsFromTiersData, ChartComponent, MainHeader } from './parts';
import { buildCardMetricArray } from './cardHelpers';
import { getColorsFromHash } from './colorHelpers';
import { TREND_CARDS } from './constants';
import ChannelDistributionModal from './ChannelRedistributionModal';

// state
import { useGlobalState } from './hooks/global';
import { usePageParams } from './hooks/pageParams';
import { useDerivedKeys } from './hooks/derivedKeys';
import { useDatasets } from './hooks/datasets';
import { useFilters } from './hooks/filters';
import { useFilteredDataArq } from './hooks/filteredData';
import { useTreeColumns } from './hooks/treeColumns';

// shared v3
import { ChartPickerLoader, BarChartLoader, TreeTableLoader } from '../../components/loaders';
import { Drawer, PopupCard, BreakdownsButton, CustomizeColumnsButton, NoDataCat } from '../../components';
import { customerTypeAtom } from '../../atoms';
import { spendFormatter } from '../../utils/valueFormatter';
import { track, time } from '../../../utils/tracking';


const AttributionReportIndex = () => {
  // GLOBAL / ACCOUNT
  const { account, currencyCode, segments, tierColors } = useGlobalState();

  // API INPUT AND CONTROL
  const { id, setId, startDate, setStartDate, endDate, setEndDate } = usePageParams(segments);

  // GET DATA: from global state and url params
  const datasets = useDatasets(segments, startDate, endDate, id, account);
  const {
    tiersData, tiersDataRaw,
    loading, noData,
    hasNtf, hasModel,
    attributionMethod, setAttributionMethod,
    dates, firstReportingDate,
  } = datasets;

  // DISPLAY settings
  const [ntfFilter, setNtfFilter] = useAtom(customerTypeAtom);
  const [selectedMetric, setSelectedMetric] = useState('conversions');
  const [groupDatesBy, setGroupDatesBy] = useState('daily');

  const derivedKeys = useDerivedKeys(ntfFilter, attributionMethod, selectedMetric);
  const { conversionKey, revenueKey, metricColumn, orderBy, orderDirection } = derivedKeys;

  const metrics = useMemo(
    () => buildCardMetricArray(revenueKey, conversionKey, currencyCode),
    [revenueKey, conversionKey, currencyCode],
  );

  // FILTER
  const { filters, filterSetters, search, setSearch, resetFilters } = useFilters();
  const filteredDataArq = useFilteredDataArq(tiersDataRaw, search, filters, conversionKey, revenueKey, startDate, endDate, metricColumn, attributionMethod, groupDatesBy);
  const { dailySummary, tiersSummaryRaw, tiersSummaryRawTotals, tiersSummary, dailyTiersSummary, chartData, lineChartData } = filteredDataArq;

  // TREE GRID settings based on display settings
  const tierColorMap = useMemo(
    () => getColorsFromHash(tiersDataRaw, tierColors),
    [tiersDataRaw, tierColors],
  );
  const treeColumns = useTreeColumns(tiersData, tierColorMap, conversionKey, currencyCode, revenueKey, tiersSummaryRawTotals);
  const { tiers, setTiers, allColumns, selectedColumns, setColumns } = treeColumns;

  // DRAWER
  const [breakdownsDrawerOpen, setBreakdownsDrawerOpen] = useState(false);
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false);

  const onMetricChange = (e, { value }) => {
    setSelectedMetric(value);
  };

  const onDateChange = ({ startDate, endDate }) => {
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    setStartDate(formatStartDate);
    setEndDate(formatEndDate);
  };

  useEffect(() => {
    if (!id || !parseInt(id) || !startDate || !endDate) return;
    if (!!loading) {
      time('attribution.attribution_report.view');
      return;
    }
    track('attribution.attribution_report.view', {
      segment_id: id,
      start_date: startDate,
      end_date: endDate,
    });
  }, [id, startDate, endDate, loading]);

  if (!id) return null;

  return (
    <>
      {/* <ChannelDistributionModal {...{hasModel, attributionMethod, data: tiersSummary}}/> */}
      <div>
        <MainHeader />
        <Filter {...{
          loading, tiersData: tiersSummaryRaw, // data
          segments, id, setId, // conversion segment
          startDate, endDate, onDateChange, firstReportingDate, // dates
          hasNtf, ntfFilter, setNtfFilter, // customer type
          attributionMethod, setAttributionMethod, hasModel, noData, // model
          filters, filterSetters, resetFilters, // jotai
          search, setSearch,
        }}
        />
      </div>
      {noData && (
      <NoDataCat
        message={`There is no data available in the date range. Please make sure your reporting date is set to on or after ${firstReportingDate}`}
        imgSize="small"
      />
      )}
      {!noData && (
      <>
        <div style={{ justifyContent: 'space-between', width: '100%' }}>
          <TrendCardsFromTiersData
            {...{ loading, dates, dailySummary, metrics_group: TREND_CARDS, metrics, currencyCode }}
            onChange={onMetricChange}
            value={selectedMetric}
            cardGroupStyle={{ paddingTop: '1em', marginBottom: '-2.05rem', zIndex: 10 }}
            point="down"
            {...{ conversionKey, revenueKey }}
          />
        </div>
        <ContentCard style={{ borderRadius: 0, border: '1px solid #e6e6e6', zIndex: 0, position: 'static', boxShadow: 'none' }} hasTable>
          {loading
            ? (
              <>
                <ChartPickerLoader />
                <BarChartLoader />
              </>
            )
            : (
              <div style={{ marginBottom: 3 }}>
                <ChartComponent
                  {...{
                    chartData, lineChartData, tierColorMap, selectedMetric,
                    spendFormatter: spendFormatter(currencyCode), groupDatesBy, setGroupDatesBy,
                  }}
                />
              </div>
            )}
        </ContentCard>
        <ContentCard style={{ marginTop: 20 }} hasTable>
          {loading
            ? <TreeTableLoader showSearch />
            : (
              <IndexGridTree
                cols={selectedColumns}
                allCols={allColumns}
                data={tiersSummary}
                orderBy={orderBy}
                orderDirection={orderDirection}
                summaryRow
                title="Channel Breakdown"
                rightContent={(
                  <div>
                    <BreakdownsButton onClick={() => setBreakdownsDrawerOpen(true)} />
                    <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                  </div>
)}
                tiersOrder={tiers}
                hideKeys={['Unmapped Events']}
                sticky
              />
            )}

        </ContentCard>
      </>
      )}

      <Drawer openDrawer={breakdownsDrawerOpen} onDrawerClose={() => setBreakdownsDrawerOpen(false)}>
        <PopupCard
          title="Breakdowns"
          list={tiers}
          setOrder={setTiers}
          callbackFn={() => setBreakdownsDrawerOpen(false)}
          open={breakdownsDrawerOpen}
        />
      </Drawer>
      <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
        <PopupCard
          title="Customize Columns"
          isCustom={true}
          customKey="display"
          list={selectedColumns}
          originalList={allColumns}
          setOrder={setColumns}
          icon="none"
          callbackFn={() => setColumnsDrawerOpen(false)}
          excludeFirst={1}
          open={columnsDrawerOpen}
        />
      </Drawer>
    </>
  );
};

export default AttributionReportIndex;
