import React, { useEffect, useState, useContext, useMemo } from 'react'
import { ContentCard, AreaChartLoader, LineChart, IndexGridTree, GoalLineChart } from '@rockerbox/styleguide'
import { Header, Button } from 'semantic-ui-react'
import { splitData } from './helpers'

import { fillDailyPerformanceMissingDates, getChartData } from '../helpers'
import { metrics } from "../platformMetrics"
import { TreeTableLoader } from '../../../components/loaders.js'
import { PopupCard, Drawer, ChartTooltip, NoDataCat, BreakdownsButton, CustomizeColumnsButton, SetAGoalButton, GoalsTooltip, SummaryBox } from "../../../components"
import FilterHeader from '../parts/FilterHeader'
import { TrendCardsFromPerformanceData } from './PerformanceTrendCards'
import Filter from './Filter'
import { track, time } from '../../../../utils/tracking'
import { getAccountCurrency } from "../../../api/account"
import CacheContext from "../../../../utils/CacheContext"

import GoalsForm from './GoalsForm'
import { metricMapper } from '../../../utils/metrics'
import { CalloutCards, NoGoal, RemoveGoal } from './parts'

import { goalAtom } from '../../../atoms'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils';
import { XIcon } from '@heroicons/react/solid'
import RocketIcon from '../../../assets/RocketIcon'
import { formatCurrency } from '../../../utils/valueFormatter'

const GatedByConvEventAndWindow = (props) => {
  const { platform, startDate, endDate, platformConfig } = props  // dates and filters
  const { 
    convValue, conversionOptions, comparisonConfigsList, updatePlatformConversionEvent, updateSegmentAndModel
  } = props // conversion events and attribution window

  useEffect(() => { 
    if (!comparisonConfigsList || !conversionOptions.length || convValue.length) return
    // if comparison exists, use comparison list to select default or else default to platformconfig
    const missingConvValue = (convValue == "" || convValue.length === 0) && !!conversionOptions?.length
    if (comparisonConfigsList.length) {
      updateSegmentAndModel(comparisonConfigsList[0].filter_id)
      return
    }
    if (missingConvValue) {
      const defaultConv = platformConfig.default_conv && platformConfig.default_conv !== '' ? platformConfig.default_conv : conversionOptions[0].value
      updatePlatformConversionEvent(defaultConv)
      return
    }
  }, [conversionOptions, platform, convValue])

  // start mixpanel timer for load speed (set here as fallback in case child component has no loading state)
  useEffect(() => {
    if (!platform || !startDate || !endDate) return
    time(`channels.digital_advertising.platform.view`)
  }, [platform, startDate, endDate])

  if (conversionOptions === false) {
    return <NoDataCat message={'No data available for ' + platformConfig.display_platform_name} height='200px' />
  }

  return (
    <div>
      {convValue ? <PlatformPerformance {...props} /> : <></>}
    </div>
  );

}

const PlatformPerformance = (props) => {
  const { platform, dates, platformConfig, loading, startDate, endDate } = props // basic params
  const { onDateChange, firstReportingDate, convValue, conversionOptions, attributionWindowOptions, windowValue, updatePlatformConversionEvent, updateAttributionWindow, headerText } = props // filters
  const { platformPerformanceData, integrations, dailyPerformance, totalPlatformPerformance } = props // data
  const { optionalMetric, defaultMetric } = props // values
  const { onOptionalMetricChange, onDefaultMetricChange } = props // methods for trend cards
  const { allTiers, tiers, setTiers, allColumns, selectedColumns, setSelectedColumns } = props
  const { optional_metrics, hasOptionSelected, hasAvailConvMetrics, default_metrics, setAttributionWindow, setOptionalMetric, account, hasRevenue } = props // other
  const [tiersOrder, setTiersOrder] = useState(undefined)
  const [goalsDrawerOpen, setGoalsDrawerOpen] = useState(false)
  const [breakdownsDrawerOpen, setBreakdownsDrawerOpen] = useState(false)
  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState(false)
  const [groupDatesBy, setGroupDatesBy] = useState('daily')
  const [state, setState, Context] = useContext(CacheContext);
  const currencyCode = Context.getCache(`currency_code`, getAccountCurrency) || undefined;

  const [goal, setGoal] = useAtom(goalAtom)
  const resetGoal = useResetAtom(goalAtom)

  const hasGoal = Object.keys(goal).length > 0
  const badTarget = goal?.target == null || isNaN(goal?.target)
  
  useEffect(() => {
    if(!tiers || tiers.length < 1 ) return
    setTiersOrder(tiers.map(c => c.value))
  }, [tiers])

  if (dailyPerformance && dailyPerformance.length == 0) return <NoDataCat message={'We could not find data for the conversion event you requested.'} />

  const filledPerformance = dailyPerformance && fillDailyPerformanceMissingDates(dates, dailyPerformance)
  const splitPerformanceData = dailyPerformance && splitData(dates, filledPerformance)
  const localLoading = !dailyPerformance || loading
  const chartData = getChartData(filledPerformance, groupDatesBy)

  useEffect(() => {
    if (!platform || !startDate || !endDate) return
    if (!!localLoading) {
      time(`channels.digital_advertising.platform.view`)
      return
    }
    track(`channels.digital_advertising.platform.view`, {
      platform,
      start_date: startDate,
      end_date: endDate,
    })
  }, [platform, startDate, endDate, localLoading])

  const totalSpend = useMemo(() => {
    if(!dailyPerformance || dailyPerformance.length == 0) return 0
    return dailyPerformance.reduce((p,c) => {
      return p + c.spend
    }, 0)
  }, [hasGoal, dailyPerformance])

  const averageMetric = useMemo(() => {
    if(!hasGoal || !dailyPerformance || dailyPerformance.length == 0) return 0
    const { metric } = goal
    const { reducer } = metrics[metric]
    return reducer(dailyPerformance)
  }, [hasGoal, dailyPerformance])

  const statsList = useMemo(() => {
    if(!hasGoal || !dailyPerformance || dailyPerformance.length == 0) return []
    const { target, metric, budget } = goal
    const formatter = metricMapper[metric].format
    const noGoal = hasGoal && badTarget
    return [
      {label: "Budget", value: budget ? formatCurrency(Number(budget)) : "N/A"}, 
      {label: "Actual Spend", value: formatCurrency(totalSpend)}, 
      {label: `${metric.toUpperCase()} Goal`, value: noGoal ? <NoGoal {...{goal, resetGoal}}/> : formatter(Number(target))}, 
      {label: `Average ${metric.toUpperCase()}`, value: formatter(averageMetric)}
    ]
  }, [hasGoal, dailyPerformance])

  useEffect(() => {
    if(!hasGoal || !dailyPerformance || dailyPerformance.length == 0) return
    const goalTotalSpendAtom = { ...goal }
    goalTotalSpendAtom['total_spend'] = totalSpend
    setGoal(goalTotalSpendAtom)
  }, [totalSpend])

  return <>
    <Filter {...{ 
      platform, loading: localLoading, platformPerformanceData, platformConfig, // data
      startDate, endDate, onDateChange, firstReportingDate, // dates
      conversionOptions, convValue, updatePlatformConversionEvent, // conversion metrics
      attributionWindowOptions, windowValue, updateAttributionWindow // attribution window
    }} />
    <FilterHeader {...{ headerText }} />
    <TrendCardsFromPerformanceData
      {...{splitPerformanceData, metrics_group: optional_metrics, integrations, platform, activeMetric: optionalMetric, currencyCode }}
      onChange={onOptionalMetricChange}
      value={optionalMetric}
      cardGroupStyle={{ paddingTop: '1em', marginBottom: '-2.05rem', zIndex: 10 }}
      activeColor="#FFA278"
      point="down"
      showPlaceholder = {!(hasOptionSelected && hasAvailConvMetrics)}
    />

    <ContentCard style={{borderRadius: 0, border: '1px solid #e6e6e6', zIndex: 0, position: 'static', boxShadow: 'none'}} >
      <Header as='h2'> 
        {!!metrics && metrics[defaultMetric]?.display_name} 
        { (hasAvailConvMetrics && optionalMetric!="") && ` & ${metrics[optionalMetric].display_name}` }
      </Header>
      {localLoading
        ? <AreaChartLoader />
        : (!!platformPerformanceData && !platformPerformanceData.length)
            ? <NoDataCat message={'No Data'} height='200px' />
            : (optionalMetric == goal.metric)
            ? <GoalLineChart
                height={200}
                showTooltip
                domainDefault='auto'
                tooltipComponent={GoalsTooltip}
                data={chartData}
                XAxisKey="date"
                YAxisStyle={[
                  {
                    keyName: defaultMetric
                  },
                  {
                    keyName: hasAvailConvMetrics && optionalMetric,
                    color: '#FFA278',
                    side: 'Right'
                  }
                ]}
                goalAxisStyle={[
                  {
                    keyName: 'target',
                    color: '#47d0dc',
                    side: 'Right'
                  }
                ]}
                currencyOverride="USD"
                showXAxis
                nameMap={{ cpa: 'CPA', roas: 'ROAS', ctr: 'CTR', cpc: 'CPC', cpm: 'CPM', target: 'Goal' }}
                groupDatesToggle
                {...{ groupDatesBy, setGroupDatesBy, goal, setGoal }}
              />
            : <LineChart
                height={200}
                showTooltip
                domainDefault='auto'
                tooltipComponent={ChartTooltip}
                data={chartData}
                XAxisKey="date"
                YAxisStyle={[
                  {
                    keyName: defaultMetric
                  },
                  {
                    keyName: hasAvailConvMetrics && optionalMetric,
                    color: '#FFA278',
                    side: 'Right'
                  }
                ]}
                currencyOverride="USD"
                showXAxis
                nameMap={{ cpa: 'CPA', roas: 'ROAS', ctr: 'CTR', cpc: 'CPC', cpm: 'CPM' }}
                groupDatesToggle
                {...{ groupDatesBy, setGroupDatesBy }}
              />
      }
    </ContentCard>

    <TrendCardsFromPerformanceData
      {...{splitPerformanceData, metrics_group: default_metrics, currencyCode }}
      onChange={onDefaultMetricChange}
      value={defaultMetric}
      cardGroupStyle={{ paddingBottom: '1em', marginTop: '-1.95rem', zIndex: 10 }}
    />

   {!localLoading && <CalloutCards {...{setGoalsDrawerOpen, hasGoal}}/>}

    { (hasGoal && !localLoading) && <SummaryBox 
      icon={<RocketIcon style={{ width: 50, height: 50 }} fill="#3b579d"/>}
      headline="Budget and Goal"
      text="Be sure to update your budget as your spend changes to ensure you get the most accurate recommendations."
      statCards={statsList}
    /> }

    <ContentCard hasTable>
      { localLoading 
        ? <TreeTableLoader showSearch />
        : (!!totalPlatformPerformance && !totalPlatformPerformance.length)
          ? <NoDataCat message={'No Data'} height='200px' />
          : <IndexGridTree
              cols={selectedColumns}
              allCols={allColumns}
              data={totalPlatformPerformance}
              title={`${platformConfig.display_platform_name} Performance Metrics`}
              summaryRow
              showSearch
              searchPlaceholder='Search'
              rightContent={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {
                  (hasGoal && badTarget) ? <RemoveGoal {...{resetGoal}}/> : 
                  (hasGoal && !badTarget) ? <div style={{display: 'flex', alignItems: 'center'}}> 
                    <Button 
                      className='remove-box-shadow'
                      style={{marginLeft: 5, fontSize: 14}} 
                      color="purple" 
                      inverted 
                      onClick={() => resetGoal()}>
                        <XIcon style={{ display: 'inline-block', height: 15, margin: '-4px 10px -3px -2px' }} />
                        Remove Goal
                    </Button>
                  </div>
                     : <SetAGoalButton onClick={() => {
                      setGoalsDrawerOpen(true)
                      track(`channels.digitalAdvertising.goals.set.click`)
                      }}/>
                    }
                  {/* <BreakdownsButton onClick={() => setBreakdownsDrawerOpen(true)} /> */}
                  <CustomizeColumnsButton onClick={() => setColumnsDrawerOpen(true)} />
                </div>
              }
              tiersOrder={tiersOrder}
              orderBy={'conversions'}
              orderDirection={'descending'}
              sticky
              additionalDataObj={{goal}}
            />
      }
    </ContentCard>
    
    <Drawer openDrawer={columnsDrawerOpen} onDrawerClose={() => setColumnsDrawerOpen(false)}>
      <PopupCard
        title="Customize Columns"
        isCustom={true}
        customKey="display"
        list={selectedColumns}
        originalList={allColumns}
        setOrder={setSelectedColumns}
        icon="none"
        callbackFn={() => setColumnsDrawerOpen(false)}
        excludeFirst={hasGoal ? 3 : 1}
        open={columnsDrawerOpen}
      />
    </Drawer>
    <Drawer openDrawer={goalsDrawerOpen} onDrawerClose={() => setGoalsDrawerOpen(false)}>
      <GoalsForm {...{
          conversionOptions, convValue, 
          attributionWindowOptions, windowValue, 
          startDate, endDate,
          platformDisplayName: platformConfig.display_platform_name,
          selectedColumns, setSelectedColumns, setGoal,
          onOptionalMetricChange, filledPerformance, optional_metrics,
          totalSpend
      }}
        open={goalsDrawerOpen}
        onClose={() => setGoalsDrawerOpen(false)}
      />
    </Drawer>
  </>
}

export default GatedByConvEventAndWindow
