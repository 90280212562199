import React, { useState, useEffect } from "react";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { ContentCard, MultiStepLayout } from "@rockerbox/styleguide";

import { Link } from "react-router-dom";
import * as routes from "../../routes";
import TestTransformView from "./TestTransformView";
import PreviewView from "./PreviewView";
import ReviewView from "./ReviewView";
import {
  getReportType,
  getSavedreports,
  updateSavedReport,
} from "../ReportIndex/api";
import {
  getDataset,
  sendReportTransform,
  getSavedReportSchema,
} from "../../utils/api";
import { generateDefaultInboundSchema, prettyInitialValue } from "./helper";

const DEFAULT_FUNC = `def run(df, settings):
    return df`;
const DEFAULT_INBOUND_SCHEMA = "{}";

const ReportInteractive = (props) => {
  const { match: { params: { id: savedReportId } = {} } = {} } = props;

  const [inboundSchema, setInboundSchema] = useState(DEFAULT_INBOUND_SCHEMA);
  const [transformFunction, setTranformFunction] = useState(DEFAULT_FUNC);
  const [customSchema, setCustomSchema] = useState("");
  const [savedReport, setSavedReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorContent, setErrorContent] = useState("");
  const [displayData, setDisplayData] = useState({});
  const [displayResult, setDisplayResult] = useState({});
  const [isShowData, setIsShowData] = useState(false);
  const [editorError, setEditorError] = useState([]);
  const [defaultSchema, setDefaultSchema] = useState("");

  useEffect(() => {
    Promise.all([
      getReportType(),
      getSavedreports(),
      getSavedReportSchema(savedReportId).then((data) =>
        setDefaultSchema(data["info"]["default_schema"])
      ),
    ])
      .then((resp) => {
        const [reportTypes, savedReports] = resp;
        const savedReport = savedReports.filter(
          (item) => item.id === parseInt(savedReportId)
        )[0];
        const newSavedReport = prettyInitialValue(savedReport, [
          "custom_schema",
          "inbound_transform_schema",
          "customSchema",
        ]);
        const dataset = reportTypes.filter(
          (item) => item.id === savedReport.report_type_id
        )[0]["keys"];
        const filter_id = savedReport["filter_id"];

        setSavedReport(newSavedReport);
        if (newSavedReport["transform_func"]) {
          setTranformFunction(newSavedReport["transform_func"]);
        }
        if (newSavedReport["inbound_transform_schema"]) {
          setInboundSchema(newSavedReport["inbound_transform_schema"]);
        }
        if (newSavedReport["custom_schema"]) {
          setCustomSchema(newSavedReport["custom_schema"]);
        }

        return getDataset(dataset, filter_id, 1, 'data');
      })
      .then((data) => {
        setLoading(false);
        setDisplayData(data["response"]);
        // if (inboundSchema === DEFAULT_INBOUND_SCHEMA) {
        //   setInboundSchema(generateDefaultInboundSchema(data["response"]));
        // }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrorContent(e.message);
      });
  }, []);

  const sendReportTransformRequest = () => {
    setLoading(true);
    const yesterday = moment.utc()
      .utcOffset(-5)
      .subtract(1, "days")
      .endOf("day")
      .format("YYYY-MM-DD");

    const body = {
      email: "support@rockerbox.com",
      file_type: "csv",
      outbox_id: null,
      saved_report_id: savedReport["id"],
      start_date: yesterday,
      end_date: yesterday,
      inbound_transform_schema: inboundSchema || null,
      transform_func: transformFunction || DEFAULT_FUNC,
      custom_schema: customSchema || null,
    };

    return sendReportTransform(body)
      .then((data) => {
        setDisplayResult(data);
        setIsShowData(true);
        setLoading(false);
        return "review";
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrorContent(e.message);
        return "transform";
      });
  };

  const sendUpdateSavedReport = () => {
    setLoading(true);

    const body = {
      custom_schema: customSchema,
      inbound_transform_schema: inboundSchema,
      transform_func: transformFunction,
      name: savedReport["name"],
      report_type_id: savedReport["report_type_id"],
      num_days: savedReport["num_days"],
    };

    body["filters"] = savedReport["filters"]
      ? JSON.stringify(savedReport["filters"])
      : "";

    updateSavedReport(savedReportId, body)
      .then((resp) => setLoading(false))
      .catch((e) => console.log(e.message));
  };

  const steps = [
    { text: "Preview Data", value: "preview", as: PreviewView },
    { text: "Transform Testing", value: "transform", as: TestTransformView },
    { text: "Review", value: "review", as: ReviewView },
  ];

  const disabledNextStep = editorError && editorError.length > 0;

  const childProps = {
    inboundSchema,
    setInboundSchema,
    savedReport,
    setSavedReport,
    errorContent,
    loading,
    displayData,
    transformFunction,
    setTranformFunction,
    sendReportTransformRequest,
    displayResult,
    setDisplayResult,
    editorError,
    setEditorError,
    defaultSchema,
    customSchema,
    setCustomSchema,
  };

  return (
    <ContentCard
      title="Report Interactive"
      loading={loading}
      topRight={
        <Link to={routes.reports}>
          <Icon name="angle left" />
          Back to Exports
        </Link>
      }>
      <MultiStepLayout
        {...{ steps }}
        {...childProps}
        isDisabled={disabledNextStep}
        onSubmit={sendUpdateSavedReport}
      />
    </ContentCard>
  );
};

export default ReportInteractive;
