import { useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  customerTypeAtom,
  // attributionMethodAtom,
  modelAtom, segIdAtom, convValueAtom, attributionWindowAtom,
  anyAtom, firstAtom, midAtom, lastAtom,
  tier1Atom, tier2Atom, tier3Atom, tier4Atom, tier5Atom,
  goalAtom, searchAtom, tiersAtom, columnsAtom, categoryAtom, categoryValueAtom, selectedChannelAtom,
  topTabAtom, reportTypeAtom, minPathLenAtom,
} from '../../../atoms';

import { useGlobalState } from '../../../hooks/global';
import { readableDateFromPreset } from '../../../utils/time';
import { segmentOptions } from '../../../utils/options';
import { capitalizeText, findOptionText, reportOptions } from '../helpers';
import { methodOptions, NTF_OPTIONS, ATTRIBUTABLE_MODELS } from '../../../constants/options';
import { isJsonString } from '../../../utils/valueFormatter';

// columns
import { getTreeGridColumns as attributionReportColumns } from '../../../views/AttributionReport/hooks/treeColumns';
import { getTreeGridColumns as newVisitorsColumns } from '../../../views/NewVisitors/hooks/treeColumns';
import { getTreeGridColumns as funnelPositionColumns } from '../../../views/FunnelPosition/hooks/treeColumns';
import { generateCols as platformColumns } from '../../../views/DigitalAdvertising/_hooks/platformConfig/displayHelpers';
import { usePlatformPerformanceConfig } from '../../../views/DigitalAdvertising/_hooks/platformConfig';
import {
  generateTier1DisplayNames,
  generateFirstColumn,
  generateColsComparison,
  generateSegmentMetrics,
} from '../../../views/DigitalAdvertising/Comparison/helpers';
import { findSelectedSegment, derivedParameters } from '../../../views/DigitalAdvertising/_hooks/segment';
import { CONVERSION_COLUMNS } from '../../../views/ConversionsDetail/constants';

const reportMapping = {
  attributionReport: {
    routeDisplay: 'Attribution Report',
    filters: ['Conversion Type', 'Customer Type', 'Attribution Model', 'Search', 'Date Range', 'Tier Filters', 'Metrics'],
    title: ['Conversion Type', 'Customer Type'],
    description: ['Customer Type', 'Attribution Model', 'Date Range'],
  },
  conversionsDetail: {
    routeDisplay: 'Conversions Detail',
    filters: ['Conversion Type', 'Customer Type', 'Date Range', 'Metrics'],
    title: ['Conversion Type'],
    description: ['Conversion Type', 'Customer Type', 'Date Range'],
  },
  funnelPosition: {
    routeDisplay: 'Funnel Position',
    filters: [
      'Conversion Type', 'Customer Type', 'Report Type', 'Date Range',
      'Anywhere In Path', 'First Touchpoint', 'Middle Touchpoint', 'Last Touchpoint',
      'Min Path Length', 'Metrics',
    ],
    title: ['Conversion Type', 'Report Type'],
    description: ['Customer Type', 'Report Type', 'Date Range'],
  },
  marketingPaths: {
    routeDisplay: 'Marketing Paths',
    filters: [
      'Conversion Type', 'Group By', 'Customer Type', 'Date Range',
      'Anywhere In Path', 'First Touchpoint', 'Last Touchpoint', 'Min Path Length',
    ],
    title: ['Conversion Type', 'Group By'],
    description: ['Group By', 'Customer Type', 'Date Range'],
  },
  newVisitors: {
    routeDisplay: 'New Visitors',
    filters: ['Report Type', 'Date Range', 'Tier Filters', 'Metrics'],
    title: ['Report Type'],
    description: ['Report Type', 'Date Range'],
  },
  spendTrends: {
    routeDisplay: 'Spend Trends',
    filters: ['Category', 'Category Value', 'Selected Channel'],
    title: ['Category'],
    description: ['Category', 'Category Value', 'Selected Channel'],
  },
  channelOverlap: {
    routeDisplay: 'Channel Overlap',
    filters: ['Ad Platform', 'Conversion Type', 'Customer Type', 'Date Range'],
    title: ['Ad Platform', 'Conversion Type'],
    description: ['Conversion Type', 'Customer Type', 'Date Range'],
  },
  digitalAdvertising: {
    comparison: {
      routeDisplay: 'Platform Comparison',
      filters: [
        'Ad Platform', 'Conversion', 'RB Attribution', 'Platform Attribution', 'Date Range',
        'Tier Filters', 'Chart Tab', 'Metrics', 'Breakdown',
      ],
      title: ['Ad Platform', 'Conversion'],
      description: ['Conversion', 'RB Attribution', 'Platform Attribution', 'Date Range'],
    },
    platform: {
      routeDisplay: 'Platform Performance',
      filters: [
        'Ad Platform', 'Platform Conversion', 'Platform Attribution', 'Date Range',
        'Tier Filters', 'Chart Tab', 'Metrics',
        'Goal Metric', 'Goal Target', 'Goal Budget', 'Budget Compared to Spend',
      ],
      title: ['Ad Platform', 'Platform Conversion'],
      description: ['Platform Conversion', 'Platform Attribution', 'Date Range'],
    },
  },
};

export const useMappings = (routeName, params, options) => {
  // params
  const { id, startDate, endDate, tier, platformName, view } = params;
  const readableDate = readableDateFromPreset(startDate, endDate);

  // mappings by route
  const { routeDisplay, filters, title, description } = routeName === 'digitalAdvertising'
    ? reportMapping[routeName][view] || []
    : reportMapping[routeName] || [];

  // GLOBAL / ACCOUNT
  const { segments } = useGlobalState();
  const segmentOptionsFormatted = segmentOptions(segments);

  // jotai
  const [anyTouch] = useAtom(anyAtom);
  const [firstTouch] = useAtom(firstAtom);
  const [midTouch] = useAtom(midAtom);
  const [lastTouch] = useAtom(lastAtom);
  const [customerType] = useAtom(customerTypeAtom);
  // const [attributionMethod] = useAtom(attributionMethodAtom);
  const [categorySpendTrends] = useAtom(categoryAtom);
  const [categoryValueSpendTrends] = useAtom(categoryValueAtom);
  const [selectedChannelSpendTrends] = useAtom(selectedChannelAtom);
  const [minPathLen] = useAtom(minPathLenAtom);
  const [model] = useAtom(modelAtom);
  const [segId] = useAtom(segIdAtom);
  const [attributionWindow] = useAtom(attributionWindowAtom);
  const [convValue] = useAtom(convValueAtom);
  const [reportType] = useAtom(reportTypeAtom);
  const [search] = useAtom(searchAtom);
  const [tier_1] = useAtom(tier1Atom);
  const [tier_2] = useAtom(tier2Atom);
  const [tier_3] = useAtom(tier3Atom);
  const [tier_4] = useAtom(tier4Atom);
  const [tier_5] = useAtom(tier5Atom);
  const [selectedColumns] = useAtom(columnsAtom);
  const [editBreakdowns] = useAtom(tiersAtom);
  const [topTab] = useAtom(topTabAtom);
  const [goal] = useAtom(goalAtom);

  // columns
  // Digital Advertising: platform performance config
  const generatePlatformColumns = () => {
    if (routeName === 'digitalAdvertising' && view === 'platform') {
      const performanceConfig = usePlatformPerformanceConfig();
      const { platformConfig } = performanceConfig;
      return platformColumns({
        hasOptionSelected: true,
        platformConfig,
        currencyCode: 'USD',
        goal,
        tier: {},
      });
    }
    return [];
  };

  // Digital Advertising: Comparison col
  const generateComparisonColumns = () => {
    if (routeName === 'digitalAdvertising' && view === 'comparison') {
      const performanceConfig = usePlatformPerformanceConfig();
      const { platformConfig } = performanceConfig;
      const { display_platform_name, optional_metrics } = platformConfig;
      const selectedSegment = findSelectedSegment(segments, segId);
      const { modelOptions, hasRevenue } = derivedParameters(selectedSegment);

      const segmentMetrics = generateSegmentMetrics(modelOptions, hasRevenue, optional_metrics);

      const allTiers = ['tier_3', 'tier_4', 'tier_5'];
      const tierNames = generateTier1DisplayNames(allTiers);
      const firstColumn = generateFirstColumn(allTiers, tierNames);
      const attributionText = findOptionText(ATTRIBUTABLE_MODELS, model);
      const comparisonCols = generateColsComparison(segmentMetrics, model, attributionText, '', firstColumn, 'USD', display_platform_name);
      return comparisonCols;
    }
    return [];
  };

  const columnMapping = {
    attributionReport: attributionReportColumns({}, 'even', 'USD', 'revenue_even'),
    newVisitors: newVisitorsColumns({}, 'USD', {}),
    funnelPosition: reportType === 'mix' ? funnelPositionColumns({}, reportType, customerType, {}) : [],
    conversionsDetail: CONVERSION_COLUMNS,
    digitalAdvertising: {
      platform: generatePlatformColumns(),
      comparison: generateComparisonColumns(),
    },
  };

  const getColumnDisplayNames = columns => {
    const isApplicable = Object.keys(columnMapping);
    if (!isApplicable.includes(routeName)) return [];

    const allColumns = routeName === 'digitalAdvertising' ? columnMapping[routeName][view] : columnMapping[routeName];
    // todo: see if can refactor these route specific things
    const key = routeName === 'attributionReport' ? 'id' : 'key';

    if (columns.length === 0) {
      return allColumns?.map(c => c.display);
    }
    return columns?.map(col => allColumns.find(c => c[key] === col)?.display);
  };

  const budgetComparedToSpend = (budget, spend) => {
    const budgetNum = Number(budget).toFixed(2);
    const roundTotalSpend = spend.toFixed(2);
    return budgetNum > roundTotalSpend ? 'Above' : budgetNum < roundTotalSpend ? 'Below' : 'Even';
  };

  const allFilters = {
    'Ad Platform': capitalizeText(platformName),
    'Anywhere In Path': isJsonString(anyTouch),
    'First Touchpoint': isJsonString(firstTouch),
    'Middle Touchpoint': isJsonString(midTouch),
    'Last Touchpoint': isJsonString(lastTouch),
    'Attribution Model': findOptionText(methodOptions, options['Attribution Model']),
    Breakdown: editBreakdowns,
    Category: capitalizeText(categorySpendTrends),
    'Category Value': capitalizeText(categoryValueSpendTrends),
    'Chart Tab': capitalizeText(topTab),
    'Conversion Value': convValue,
    Conversion: findOptionText(segmentOptionsFormatted, Number(segId)),
    'Conversion Type': findOptionText(segmentOptionsFormatted, Number(id)),
    'Customer Type': findOptionText(NTF_OPTIONS, customerType),
    'Group By': `Tier 1${tier > 1 ? `-${tier}` : ''}`,
    Metrics: getColumnDisplayNames(selectedColumns)?.map(c => c).join(', '),
    'Min Path Length': minPathLen,
    'Report Type': findOptionText(reportOptions(routeName), reportType),
    Search: search,
    'Date Range': readableDate,
    'Tier Filters': { tier_1, tier_2, tier_3, tier_4, tier_5 },
    'Platform Attribution': findOptionText(options['Platform Attribution'], attributionWindow) || attributionWindow,
    'Platform Conversion': findOptionText(options['Platform Conversion'], convValue) || convValue,
    'RB Attribution': findOptionText(ATTRIBUTABLE_MODELS, model),
    'Selected Channel': capitalizeText(selectedChannelSpendTrends),
    'Goal Metric': goal.metric ? goal.metric.toUpperCase() : false,
    'Goal Target': goal.target ? `$${Number(goal.target).toFixed(2)}` : false,
    'Goal Budget': goal.budget ? `$${Number(goal.budget).toFixed(2)}` : false,
    'Budget Compared to Spend': goal.total_spend ? budgetComparedToSpend(goal.budget, goal.total_spend) : false,
  };

  const appliedFilters = useMemo(() => filters.map(filter => {
    if (filter === 'Anywhere In Path' || filter === 'First Touchpoint' || filter === 'Middle Touchpoint' || filter === 'Last Touchpoint') {
      return ({ key: 'Tier Filters', text: filter, value: allFilters[filter] });
    }
    return ({ key: filter, text: filter, value: allFilters[filter] });
  }), [allFilters]);

  const defaultTitle = useMemo(() => {
    const titleDisplay = title.map(c => allFilters[c]);
    return `${routeDisplay} - ${titleDisplay.join(' - ')}`;
  }, [allFilters]);

  const defaultDescription = useMemo(() => {
    const descriptionDisplay = description.map(c => allFilters[c]);
    return `${descriptionDisplay.join(' - ')}`;
  }, [allFilters]);

  return {
    appliedFilters,
    defaultTitle,
    defaultDescription,
  };
};
