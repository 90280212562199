import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { SelectButtons, IndexGridNew, StatCard, SplitLayout, ContentCard, IndexGrid, PivotGrid, SelectList } from '@rockerbox/styleguide';
import { Popup, Modal, Radio, Label, Form, Button, Input, Grid, Icon, Segment, Dropdown, Table, Loader } from 'semantic-ui-react';
import { mock, getEntityConfigs, getEntityResults } from '../../utils/api';
import { _timeRange, yesterday, week, month } from '../timerange';
import { _pushPath } from '../pushpath';
import { SparklineCell } from '../DailyChart';
import * as routes from '../../routes';
import { ATTRIBUTABLE_MODELS } from '../ViewAttribution';
import _ from 'lodash'
import SummaryBar from '../ViewAttribution/SummaryBar';
import { InlineDropdown } from '../stateless';
import SelectConversion from '../SelectSegment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { buildCurrency } from '../../utils/format_helpers';
import moment from "moment";
import styled from "styled-components";

import EmptyView from './Empty';
import SpendModal from './SpendModal';
import parseLucene from "./luceneFilter";
import presetOptions from "./presetOptions";
import DateContentCard, { daysAgo } from "./DateContentCard";

const InfluencerResults = (props) => {
  const [startDate, setStartDate] = React.useState(daysAgo(60));
  const [description, setDescription] = React.useState(false);
  const [endDate, setEndDate] = React.useState(daysAgo(1));
  const [data, setData] = React.useState(false);
  const [costChanges, setCostChanges] = React.useState({});
  const [selectedItem, setSelectedItem] = React.useState(false);
  const [groupedData, setGroupedData] = React.useState(false);
  const [entityData, setEntityData] = React.useState(false);
  const [defaults, setDefaults] = React.useState({});
  const [searchValue, setSearchValue] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [refreshEntity, setRefreshEntity] = React.useState(0);
  
  React.useEffect(() => {
    setLoading(true)
    getEntityConfigs()
      .then(setEntityData)
  }, [refreshEntity])

  React.useEffect(() => {
    if (entityData == false) return ;

    setLoading(true)

    const entitySegment = entityData.reduce((p,c) => {
      if (c.segments.length) p[c.segments[0].segment_id] = 1
      return p
    }, {});

    // NOTE: this is incorrect bcs it should be at an individual code level
    // Currently, just taking last
    const entityToAffiliateCost = d3.nest()
      .key(row => row.name)
      .rollup(values => {
        return values[0]['promo_codes'].reduce((p,code) => {
          return code
        }, {})
      })
      .map(entityData);

    const entityMap = d3.nest()
      .key(row => row.name)
      .rollup(values => {
        return values[0]['costs'].reduce((p,cost) => {
          cost.cost_range.map(c => {
            p[c.date] = (p[c.date] || 0) + c.cost
          })
        
          return p
        }, {})
      })
      .map(entityData);

    (Object.keys(entitySegment) || []).slice(0,1).map(segmentId => {
      const promise = getEntityResults(segmentId, startDate, endDate)
          .then(data => data.length ? 
            data.map(row => Object.assign(row, {date: row.cache_from, original_incremental_cost: row.incremental_cost})) :
            []
          )

      promise
        .then(data => {
          const sponsorshipCostChanges = {}

          const nested = d3.nest()
            .key(row => row.entity_name)
            .rollup(values => {
              values.map(value => {
                const aggValue = value.sponsorship_cost;
                value.incremental_cost = value.original_incremental_cost;

                const aggIncrValue = value.incremental_cost;
                const objIncrValue = (entityToAffiliateCost[value.entity_name]||false)
                if (objIncrValue !== false) {
                  // NOTE: this isnt exactly correct -- if changing from N to 0, this will look incorrect
                  const { rev_share_percent, fixed_unit_cost } = objIncrValue
                    value.incremental_cost = (rev_share_percent) ? value.revenue*rev_share_percent/100 : 
                      fixed_unit_cost ? value.assisted*fixed_unit_cost : 0
                    
                  if (rev_share_percent || fixed_unit_cost) {
                    sponsorshipCostChanges[value.entity_name] = true
                  }
                }

                const objValue = (entityMap[value.entity_name][value.date + " 00:00:00"]||0)
                if (aggValue != objValue) {
                  value.sponsorship_cost = objValue
                  sponsorshipCostChanges[value.entity_name] = true
                }

                value.spend = (value.sponsorship_cost || 0) + (value.incremental_cost||0)

              })

              return {
                total_spend: d3.sum(values, row => row.spend || 0),
                total_incremental_cost: d3.sum(values, row => row.incremental_cost || 0),
                total_sponsorship_cost: d3.sum(values, row => row.sponsorship_cost || 0),
                total_assisted: d3.sum(values, row => row.assisted || 0),
                total_assisted_revenue: d3.sum(values, row => row.revenue || 0),
                total_attributed: d3.sum(values, row => row.even || 0),
                total_attributed_revenue: d3.sum(values, row => row.revenue_even || 0),
              }
            })
            .map(data)

          return [
            data.map(row => Object.assign( row, nested[row.entity_name])),
            sponsorshipCostChanges
          ]
        })
        .then(([data, costChanges]) => {
          setCostChanges(costChanges)
          setLoading(false)
          setData(data)
        })
    })

  }, [entityData, startDate, endDate])

  const onEdit = (item, col) => {
    setSelectedItem(item)
  }

  const columnCells = {
    "entity_name": ({ item, col }) => {
      return <Table.Cell style={{cursor:"pointer"}} onClick={() => onEdit(item, col) }>
        <a>{ item[col.key] }</a> 
        { costChanges[item[col.key]] ? 
          <Popup 
            content="Spend has been recently updated and aggregations are still running." 
            trigger={<Icon name="warning circle" color="orange" />} /> : "" 
        } 
      </Table.Cell>
    }
  }

  const columnNames = {
    "date":"Date",
    "entity_name":"Name",
    "entity_type":"Entity Type",
    "revenue_even:sum":"Revenue (Multi-touch)",
    "even:sum":"Conversions (Multi-touch)",
    "spend:sum":"Spend",
    "incremental_cost:sum":"Spend (Incremental)",
    "sponsorship_cost:sum":"Spend (Sponsorship)",
    "revenue:sum":"Revenue (Siloed)",
    "assisted:sum":"Conversions (Siloed)",
    "attributedAssistedRatio":"Multi-touch ÷ Siloed Ratio",
    "attributedAssistedCPARatio":"Multi-touch ÷ Siloed CPA",
    "cpaAssisted":"CPA (Siloed)",
    "cpaAttributed":"CPA (Multi-touch)",
    "roasAssisted":"ROAS (Siloed)",
    "roasAttributed":"ROAS (Multi-touch)",
    "rpaAssisted":"RPA (Siloed)",
    "rpaAttributed":"RPA (Multi-touch)"
  }

  const calculatedColumns = {
    "attributedAssistedRatio": ( values, props ) => {
      const assisted = d3.sum(values, x => x.assisted)
      const attributed = d3.sum(values, x => x.even)
      return attributed/assisted
    },
    "attributedAssistedCPARatio": ( values, props ) => {
      const assisted = d3.sum(values, x => x.assisted)
      const attributed = d3.sum(values, x => x.even)
      const spend = d3.sum(values, x => x.spend)
      return (spend/attributed)/(spend/assisted)
    },
    "rpaAssisted": ( values, props ) => {
      const assisted = d3.sum(values, x => x.assisted)
      const revenue = d3.sum(values, x => x.revenue)
      return revenue/assisted
    },
    "rpaAttributed": ( values, props ) => {
      const attributed = d3.sum(values, x => x.even)
      const revenue = d3.sum(values, x => x.revenue_even)
      return revenue/attributed
    },
    "cpaAssisted": ( values, props ) => {
      const assisted = d3.sum(values, x => x.assisted)
      const spend = d3.sum(values, x => x.spend)
      return spend/assisted
    },
    "cpaAttributed": ( values, props ) => {
      const attributed = d3.sum(values, x => x.even)
      const spend = d3.sum(values, x => x.spend)
      return spend/attributed
    },
    "roasAssisted": ( values, props ) => {
      const revenue = d3.sum(values, x => x.revenue)
      const spend = d3.sum(values, x => x.spend)
      return revenue/spend
    },
    "roasAttributed": ( values, props ) => {
      const revenue = d3.sum(values, x => x.revenue_even)
      const spend = d3.sum(values, x => x.spend)
      return revenue/spend
    }
  }

  const onDateChange = ({ startDate, endDate }) => {
    setStartDate(moment(startDate).format("YYYY-MM-DD"))
    setEndDate(moment(endDate).format("YYYY-MM-DD"))
  }

  const onSearch = (searchValue) => {
    setSearchValue(searchValue)
  }

  const onDefaultChange = (defaults) => {
    setDescription(defaults.description)
    setDefaults(defaults)
  }

  const filteredData = React.useMemo(() => parseLucene(
      data, searchValue, 
      (term, field) => {
        return (!term && ["spend"].includes(field)) ? 
          0 : (term && typeof(term) == "string") ? 
          term.toLowerCase() : term 
      },
      [
        "total_spend","total_incremental_cost","total_sponsorship_cost",
        "total_assisted","total_assisted_revenue","total_attributed", "total_attributed_revenue",
        "entity_type", "entity_name"
      ], 
      {
        "spend":"total_spend",
        "incremental_cost":"total_incremental_cost",
        "sponsorship_cost":"total_sponsorship_cost",
        "assisted":"total_assisted", 
        "assisted_revenue":"total_assisted_revenue", 
        "attributed":"total_attributed", 
        "attributed_revenue":"total_attributed_revenue", 
        "type":"entity_type", 
        "name":"entity_name"
      }
    ), 
    [searchValue, data, costChanges]
  )

  if (!entityData) return (
    <div className='flex-centered p-100'>
      <Loader active />
    </div>
  )

  if (!entityData.length) return <EmptyView />

  return <DateContentCard
    title="Marketing Entity Analysis"
    defaultPreset="performance_fractional"
    {...{startDate, endDate, onDateChange, onSearch, onDefaultChange, loading, presetOptions}}
  >
    <SpendModal
      onClose={({ update }) => {
        if (update) {
          const original = entityData.filter(row => row.id != update.id)
          const newData = [...original, update].sort((p,c) => c.id - p.id)
          setEntityData(newData)
        }
        setSelectedItem(false)
      }}
      open={!!selectedItem}
      startDate={startDate}
      endDate={endDate}
      selectedItem={selectedItem}
      selectedEntity={entityData.find(row => row.name == selectedItem.entity_name)}
      selectedData={(data||[]).filter(row => row.entity_name == selectedItem.entity_name)}
    />
     
    <PivotGrid 
      description={description}
      skipColumns={["cache_from","normalized","first_touch","last_touch","revenue_normalized","revenue_first_touch","revenue_last_touch","related_tiers", "total_spend", "total_incremental_cost", "total_sponsorship_cost", "total_assisted", "total_attributed", "total_attributed_revenue", "total_assisted_revenue"]}
      skipFormatter={["percent-row","percent-col","percent-all"]}
      showOptions={!defaults.isPreset}
      columnNames={columnNames}
      columnCells={columnCells}
      calculatedColumns={calculatedColumns}
      hasRawData={loading ? false : (data.length >= 0)}
      data={filteredData} 
      defaultCols={defaults.defaultCols || ["date"]} 
      defaultMetricDirection={defaults.defaultMetricDirection || "row"} 
      defaultRows={defaults.defaultRows || ["entity_type"]} 
      defaultSummary={defaults.defaultSummary || ["assisted:sum", "cpaAssisted", "spend:sum"]} 
      defaultFormats={defaults.defaultFormats || {date: "daily"}} 
    />
  </DateContentCard>
}

export default InfluencerResults;