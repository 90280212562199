import React from 'react';
import {Icon, Table} from "semantic-ui-react";
import { ContentCard } from '@rockerbox/styleguide'
import PlatformDataTable from '../components/PlatformDataTable';

export const DatasetDocumentationCell = (platformDatasets) =>({item, col}) => {
  const helpDocUrl = `https://help.rockerbox.com/docs/schema-data-warehouse-${item['helpDoc']}`

  return (
    <Table.Cell>
      <span><Icon name='file alternate' /><a href={helpDocUrl} target="_blank">Docs</a></span>
    </Table.Cell>
  )
}

export const DatasetLastSyncedCell = (platformDatasets) =>({item, col}) => {
  return (
    <Table.Cell>
      <span><Icon name='check circle' color='green' />{item.last_sync}</span>
    </Table.Cell>
  )
}

const ReportSyncPlatformTable = ({loading, callbackFn}) => {
  return (
    <ContentCard hasTable>
      <PlatformDataTable
        callbackFn={callbackFn}
        loading={loading}
        showLastSyncd={true}
        emptyGridMessage={"No Datasets Synced"}
      />
    </ContentCard>
  )
}

export default ReportSyncPlatformTable;
