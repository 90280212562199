import React from 'react';

const ErrorMessage = ({header, message}) => {
  return (
    <div className="ui negative message">
      {header && <div className="header">{header}</div>}
      <p>{message}</p>
    </div>
  )
}

export default ErrorMessage;
