import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Message } from 'semantic-ui-react';
import SendReportForm from '../../SendReportForm';
import { CloseButtonStyles } from '../../styled';
import * as routes from '../../../V3/baseRoutes';

export default (props) => (
  <Modal size="small" open={props.open} onClose={props.close} closeOnDimmerClick={false}>
    <Modal.Header><CloseButtonStyles onClick={props.close}>x</CloseButtonStyles>Send Attribution Report</Modal.Header>
    <Modal.Content>
      <Message info>
        <Message.Header>Switch to the new & improved Ad Hoc Exports ✨</Message.Header>
        <ul style={{ fontSize: 14, paddingLeft: 17, marginTop: 5, lineHeight: '1.5em' }}>
          <li>Export data for <b>any</b> date range, up to multiple years at once</li>
          <li>Group data by daily, weekly, monthly, quarterly, etc.</li>
          <li>Group data by custom tier dimensions</li>
          <li>Exports generate <b>10x faster</b> and can be shared with anyone (Rockerbox login not required)</li>
        </ul>
        <Button
          fluid
          color="blue"
          icon="right arrow"
          labelPosition="right"
          content="Go to Ad Hoc Exports"
          as={Link}
          to={routes.adHocExportsHistory}
          onClick={props.close}
        />
      </Message>
      <Modal.Description>
         <SendReportForm done={props.close} {...props} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
)
