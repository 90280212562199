import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'

const Tooltip = ({ icon, color, position, content, style }) => {
  return (
    <Popup
      trigger={<Icon
        name={icon || 'question circle'}
        color={color || 'purple'}
        {...{ style }}
      />}
      content={content}
      position={position || 'top center'}
      inverted
    />
  )
}

export default Tooltip
