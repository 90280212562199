import React from 'react'
import { highestEarners, fastestMovers, mostCommon, mostPopular, highRevenueLowVolume, highVolumeLowRevenue } from './keyInsightsFormulas'
import Path from './Path'
import { formatCurrency, secondsToDhms } from '../../../utils/valueFormatter'

const Graphic = ({ filteredCustomerPaths, insightType, tierColors, tier }) => {
  
  const keyInsightsFormulas = {
    highestEarners: {
      formula: highestEarners,
      formatter: formatCurrency,
      labelKey: 'avg_revenue',
      labelKey2: 'count',
      labelName: 'avg rev',
      labelName2: 'conversions'
    },
    fastestMovers: {
      formula: fastestMovers,
      formatter: secondsToDhms,
      labelKey: 'avg_seconds',
      labelKey2: 'count',
      labelName2: 'conversions'
    },
    mostCommon: {
      formula: mostCommon,
      labelKey: 'count',
      labelName: 'conversions'
    },
    mostPopular: {
      formula: mostPopular,
      labelKey: 'count',
      labelName: 'conversions'
    },
    highRevenueLowVolume: {
      formula: highRevenueLowVolume,
      formatter: formatCurrency,
      labelKey: 'avg_revenue',
      labelKey2: 'count',
      labelName: 'avg rev',
      labelName2: 'conversions'
    },
    highVolumeLowRevenue: {
      formula: highVolumeLowRevenue,
      formatter2: formatCurrency,
      labelKey: 'count',
      labelName: 'conversions',
      labelKey2: 'avg_revenue',
      labelName2: 'avg rev'
    },
  }
  const keyInsight = keyInsightsFormulas[insightType]
  const sliceIndex = tier >= 3 ? 1 : 3

  return(
    <div>
      {keyInsight
        .formula(filteredCustomerPaths)
        .slice(0, sliceIndex)
        .map((c,i,a )=> <Path {...{ item: c, keyInsight, tierColors }} />)
      }
    </div>
  )
}

export default Graphic
