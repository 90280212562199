import React, { useState } from "react";
import AceEditor from "react-ace";

import brace from "brace";
import "brace/mode/json";
import "brace/mode/python";
import "brace/mode/text";
import "brace/theme/tomorrow";
import "brace/theme/monokai";

const EditorWindow = (props) => {
  const {
    mode = "json",
    width = "100%",
    height = "400px",
    style = { marginTop: 15 },
    readOnly = false,
    theme = "tomorrow",
  } = props;

  const setOptions = {
    highlightGutterLine: false,
    showPrintMargin: false,
    showGutter: true,
    highlightActiveLine: false,
    fontSize: 14,
    wrapEnabled: true,
  };
  const editorProps = {
    mode,
    setOptions,
    width,
    height,
    style,
    readOnly,
  };

  return (
    <React.Fragment>
      <AceEditor {...props} {...editorProps} />
    </React.Fragment>
  );
};

export default EditorWindow;
