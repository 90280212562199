import React from 'react'
import ContentLoader from 'react-content-loader'
import { Input } from 'semantic-ui-react'


export const DonutChartLoader = () => (
  <ContentLoader viewBox="0 0 384 350" foregroundColor="#e5e5e5">
    <rect x="146.22" y="156.09" width="91.55" height="11.91" rx="4" ry="4" />
    <rect x="143.62" y="178.59" width="96.76" height="20.22" rx="4" ry="4" />
    <path d="M192,13.52C102.81,13.52,30.52,85.81,30.52,175s72.3,161.48,161.48,161.48,161.48-72.3,161.48-161.48S281.19,13.52,192,13.52Zm0,298.61c-75.73,0-137.13-61.39-137.13-137.13S116.27,37.87,192,37.87s137.13,61.39,137.13,137.13-61.39,137.13-137.13,137.13Z" />
  </ContentLoader>
)


export const HorizontalBarLoader = () => (
  <ContentLoader viewBox="0 0 384 349" foregroundColor="#e5e5e5">
    <path d="M377.5,93.93H6.5c-3.04,0-5.5-2.46-5.5-5.5v0c0-3.04,2.46-5.5,5.5-5.5h371c3.04,0,5.5,2.46,5.5,5.5v0 C383,91.47,380.53,93.93,377.5,93.93z"/>
    <path d="M377.5,154.93l-371,0c-3.04,0-5.5-2.46-5.5-5.5v0c0-3.04,2.46-5.5,5.5-5.5h371c3.04,0,5.5,2.46,5.5,5.5v0 C383,152.47,380.53,154.93,377.5,154.93z"/>
    <path d="M377.5,214.93l-371,0c-3.04,0-5.5-2.46-5.5-5.5v0c0-3.04,2.46-5.5,5.5-5.5h371c3.04,0,5.5,2.46,5.5,5.5v0 C383,212.47,380.53,214.93,377.5,214.93z"/>
    <path d="M377.5,275.93H6.5c-3.04,0-5.5-2.46-5.5-5.5l0,0c0-3.04,2.46-5.5,5.5-5.5h371c3.04,0,5.5,2.46,5.5,5.5l0,0 C383,273.47,380.53,275.93,377.5,275.93z"/>
    <path d="M377.5,335.93H6.5c-3.04,0-5.5-2.46-5.5-5.5l0,0c0-3.04,2.46-5.5,5.5-5.5h371c3.04,0,5.5,2.46,5.5,5.5l0,0 C383,333.47,380.53,335.93,377.5,335.93z"/>
    <path d="M52.11,69.07H6c-2.76,0-5-2.24-5-5v-4c0-2.76,2.24-5,5-5h46.11c2.76,0,5,2.24,5,5v4C57.11,66.83,54.88,69.07,52.11,69.07z"/>
    <path d="M61.97,130.07H6c-2.76,0-5-2.24-5-5v-4c0-2.76,2.24-5,5-5h55.97c2.76,0,5,2.24,5,5v4 C66.97,127.83,64.73,130.07,61.97,130.07z"/>
    <path d="M77.48,190.07H6c-2.76,0-5-2.24-5-5v-4c0-2.76,2.24-5,5-5h71.48c2.76,0,5,2.24,5,5v4 C82.48,187.83,80.24,190.07,77.48,190.07z"/>
    <path d="M35.81,251.07H6c-2.76,0-5-2.24-5-5v-4c0-2.76,2.24-5,5-5h29.81c2.76,0,5,2.24,5,5v4 C40.81,248.83,38.57,251.07,35.81,251.07z"/>
    <path d="M66.58,311.07H6c-2.76,0-5-2.24-5-5v-4c0-2.76,2.24-5,5-5h60.58c2.76,0,5,2.24,5,5v4 C71.58,308.83,69.34,311.07,66.58,311.07z"/>
    <path d="M279.17,26.04h-70.49c-2.76,0-5-2.24-5-5V12c0-2.76,2.24-5,5-5h70.49c2.76,0,5,2.24,5,5v9.04 C284.17,23.8,281.94,26.04,279.17,26.04z"/>
    <path d="M365.6,26.04h-43.68c-2.76,0-5-2.24-5-5V12c0-2.76,2.24-5,5-5h43.68c2.76,0,5,2.24,5,5v9.04 C370.6,23.8,368.36,26.04,365.6,26.04z"/>
  </ContentLoader>
)


export const BarChartLoader = ({ animate=true, height=300 }) => (
  <ContentLoader viewBox="0 0 1232 300" width={'100%'} {...{ height }} preserveAspectRatio="none" foregroundColor="#e5e5e5" {...{ animate }}>
    <path d="M9.07,203.24h32V295h-32V203.24z"/>
    <path d="M49.81,172.27h32V295h-32L49.81,172.27z"/>
    <path d="M90.54,164.9h32V295h-32V164.9z"/>
    <path d="M131.27,170.62h32V295h-32V170.62z"/>
    <path d="M172.01,178.35h32V295h-32V178.35z"/>
    <path d="M212.74,211.46h32V295h-32V211.46z"/>
    <path d="M253.47,176.65h32V295h-32V176.65z"/>
    <path d="M294.21,216.44h32V295h-32V216.44z"/>
    <path d="M334.94,176.32h32V295h-32V176.32z"/>
    <path d="M375.67,175.22h32V295h-32V175.22z"/>
    <path d="M416.41,116.73h32V295h-32V116.73z"/>
    <path d="M457.14,76.21h32V295h-32V76.21z"/>
    <path d="M497.87,139.52h32V295h-32L497.87,139.52z"/>
    <path d="M538.61,201.32h32V295h-32V201.32z"/>
    <path d="M579.34,156.34h32V295h-32V156.34z"/>
    <path d="M620.07,178.33h32V295h-32V178.33z"/>
    <path d="M660.81,159.15h32V295h-32V159.15z"/>
    <path d="M701.54,173.04h32V295h-32V173.04z"/>
    <path d="M742.27,197.68h32V295h-32V197.68z"/>
    <path d="M783.01,172.41h32V295h-32V172.41z"/>
    <path d="M823.74,166.95h32V295h-32V166.95z"/>
    <path d="M864.47,139.52h32V295h-32V139.52z"/>
    <path d="M905.21,119.25h32V295h-32V119.25z"/>
    <path d="M945.94,75.77h32V295h-32V75.77z"/>
    <path d="M986.67,94.13h32V295h-32V94.13z"/>
    <path d="M1027.41,118.9h32V295h-32V118.9z"/>
    <path d="M1068.14,43.24h32V295h-32V43.24z"/>
    <path d="M1108.87,78.49h32V295h-32V78.49z"/>
    <path d="M1149.61,94.13h32V295h-32V94.13z"/>
    <path d="M1190.34,69.45h32V295h-32V69.45z"/>
  </ContentLoader>
)


export const TreeTableLoader = ({ showSearch }) => (<>
  {showSearch &&
    <Input
      size='small'
      className='table-filter-search loading'
    />
  }
  <ContentLoader viewBox="0 0 1232 292" width="100%" height={292} preserveAspectRatio="none" foregroundColor="#e5e5e5">
    <path d="M1228,39.84H4c-2.21,0-4-1.79-4-4V4c0-2.21,1.79-4,4-4l1224,0c2.21,0,4,1.79,4,4v31.84 C1232,38.05,1230.21,39.84,1228,39.84z"/>
    <path d="M1228,45H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V49C1232,46.79,1230.21,45,1228,45z M1230.09,76.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4V50.53c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V76.42z"/>
    <circle cx="19.48" cy="63.48" r="9.74"/>
    <path d="M113.91,70.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,68.69,115.57,70.04,113.91,70.04z"/>
    <path d="M344.56,69.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,67.88,346.21,69.23,344.56,69.23z"/>
    <path d="M507.56,69.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,67.88,509.21,69.23,507.56,69.23z"/>
    <path d="M641.56,69.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,67.88,643.21,69.23,641.56,69.23z"/>
    <path d="M872.56,69.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,67.88,874.21,69.23,872.56,69.23z"/>
    <path d="M1109.56,69.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,67.88,1111.21,69.23,1109.56,69.23z"/>
    <path d="M1228,87H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V91C1232,88.79,1230.21,87,1228,87z M1230.09,118.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4V92.53c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V118.42z"/>
    <circle cx="19.48" cy="105.48" r="9.74"/>
    <path d="M113.91,112.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,110.69,115.57,112.04,113.91,112.04z"/>
    <path d="M344.56,111.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,109.88,346.21,111.23,344.56,111.23z"/>
    <path d="M507.56,111.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,109.88,509.21,111.23,507.56,111.23z"/>
    <path d="M641.56,111.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,109.88,643.21,111.23,641.56,111.23z"/>
    <path d="M872.56,111.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,109.88,874.21,111.23,872.56,111.23z"/>
    <path d="M1109.56,111.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,109.88,1111.21,111.23,1109.56,111.23z"/>
    <path d="M1228,129H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V133C1232,130.79,1230.21,129,1228,129z M1230.09,160.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V160.42z"/>
    <circle cx="19.48" cy="147.48" r="9.74"/>
    <path d="M113.91,154.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,152.69,115.57,154.04,113.91,154.04z"/>
    <path d="M344.56,153.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,151.88,346.21,153.23,344.56,153.23z"/>
    <path d="M507.56,153.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,151.88,509.21,153.23,507.56,153.23z"/>
    <path d="M641.56,153.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,151.88,643.21,153.23,641.56,153.23z"/>
    <path d="M872.56,153.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,151.88,874.21,153.23,872.56,153.23z"/>
    <path d="M1109.56,153.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,151.88,1111.21,153.23,1109.56,153.23z"/>
    <path d="M1228,171H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V175C1232,172.79,1230.21,171,1228,171z M1230.09,202.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V202.42z"/>
    <circle cx="19.48" cy="189.48" r="9.74"/>
    <path d="M113.91,196.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,194.69,115.57,196.04,113.91,196.04z"/>
    <path d="M344.56,195.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,193.88,346.21,195.23,344.56,195.23z"/>
    <path d="M507.56,195.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,193.88,509.21,195.23,507.56,195.23z"/>
    <path d="M641.56,195.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,193.88,643.21,195.23,641.56,195.23z"/>
    <path d="M872.56,195.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,193.88,874.21,195.23,872.56,195.23z"/>
    <path d="M1109.56,195.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,193.88,1111.21,195.23,1109.56,195.23z"/>
    <path d="M1228,213H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V217C1232,214.79,1230.21,213,1228,213z M1230.09,244.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V244.42z"/>
    <circle cx="19.48" cy="231.48" r="9.74"/>
    <path d="M113.91,238.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,236.69,115.57,238.04,113.91,238.04z"/>
    <path d="M344.56,237.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,235.88,346.21,237.23,344.56,237.23z"/>
    <path d="M507.56,237.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,235.88,509.21,237.23,507.56,237.23z"/>
    <path d="M641.56,237.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,235.88,643.21,237.23,641.56,237.23z"/>
    <path d="M872.56,237.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,235.88,874.21,237.23,872.56,237.23z"/>
    <path d="M1109.56,237.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,235.88,1111.21,237.23,1109.56,237.23z"/>
    <path d="M1228,255H4c-2.21,0-4,1.79-4,4v28.95c0,2.21,1.79,4,4,4h1224c2.21,0,4-1.79,4-4V259C1232,256.79,1230.21,255,1228,255z M1230.09,286.42c0,2.21-1.79,4-4,4H5.91c-2.21,0-4-1.79-4-4v-25.89c0-2.21,1.79-4,4-4h1220.18c2.21,0,4,1.79,4,4V286.42z"/>
    <circle cx="19.48" cy="273.48" r="9.74"/>
    <path d="M113.91,280.04H43.2c-1.66,0-3-1.34-3-3v-7.12c0-1.66,1.34-3,3-3h70.71c1.66,0,3,1.34,3,3v7.12 C116.91,278.69,115.57,280.04,113.91,280.04z"/>
    <path d="M344.56,279.23H312.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C347.56,277.88,346.21,279.23,344.56,279.23z"/>
    <path d="M507.56,279.23H475.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C510.56,277.88,509.21,279.23,507.56,279.23z"/>
    <path d="M641.56,279.23H609.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C644.56,277.88,643.21,279.23,641.56,279.23z"/>
    <path d="M872.56,279.23H840.2c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C875.56,277.88,874.21,279.23,872.56,279.23z"/>
    <path d="M1109.56,279.23h-32.35c-1.66,0-3-1.34-3-3v-5.5c0-1.66,1.34-3,3-3h32.35c1.66,0,3,1.34,3,3v5.5 C1112.56,277.88,1111.21,279.23,1109.56,279.23z"/>
  </ContentLoader>
</>)


export const ChartPickerLoader = () => (
  <ContentLoader viewBox="0 0 208 46" width={208} height={46} foregroundColor="#e5e5e5" style={{ float: 'left' }}>
    <circle cx="14.35" cy="16.03" r="12.09"/>
    <path d="M194.61,22.21H44.32c-2.21,0-4-1.79-4-4v-4.36c0-2.21,1.79-4,4-4h150.29c2.21,0,4,1.79,4,4v4.36 C198.61,20.42,196.82,22.21,194.61,22.21z"/>
  </ContentLoader>
)

export const KeyInsightsLoader = () => (
  <ContentLoader width="100%" height="345" viewBox="0 0 393 345" preserveAspectRatio="none" foregroundColor="#e5e5e5">
    <path id="z3uboh8pga_00000143592979506078235020000012739741166337108357_" d="M370,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S364.5,345,370,345z"/>
    <path id="fevfc059qb_00000174589827282701556030000003735489017515068333_" d="M330,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S324.5,345,330,345z"/>
    <path id="oueoer0tpc_00000117663125491109273130000016648890318802881204_" d="M290,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S284.5,345,290,345z"/>
    <path id="y5j7hc2abd_00000119106239842047367790000016624567994197532050_" d="M250,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S244.5,345,250,345z"/>
    <path id="bvjzsc15je_00000041282018610805075350000008772256576024091551_" d="M210,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S204.5,345,210,345z"/>
    <path id="iocbepd6rf_00000028292937782391862250000005466562662243658394_" d="M170,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S164.5,345,170,345z"/>
    <path id="ir9qq96wyg_00000098920754199506252690000009324384866157493945_" d="M130,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S124.5,345,130,345z"/>
    <path id="q3kwcsnfeh_00000074422616171621245820000001290773976081982643_" d="M90,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S84.5,345,90,345z"/>
    <path id="smu6srw3ji_00000100342769018321673300000004288068796560000133_" d="M50,345c5.5,0,10-4.5,10-10s-4.5-10-10-10
      s-10,4.5-10,10S44.5,345,50,345z"/>
    <path class="st0" d="M10,345c5.5,0,10-4.5,10-10s-4.5-10-10-10s-10,4.5-10,10S4.5,345,10,345z" />
    <path class="st0" d="M2,59h389c1.1,0,2,1.3,2,2.8l0,0c0,1.5-0.9,2.8-2,2.8H2c-1.1,0-2-1.3-2-2.8l0,0C0,60.3,0.9,59,2,59z"/>
    <path class="st0" d="M2,82.2h389c1.1,0,2,1.3,2,2.8l0,0c0,1.5-0.9,2.8-2,2.8H2c-1.1,0-2-1.3-2-2.8l0,0C0,83.5,0.9,82.2,2,82.2z"/>
    <path class="st0" d="M2,106.2h389c1.1,0,2,1.3,2,2.8l0,0c0,1.5-0.9,2.8-2,2.8H2c-1.1,0-2-1.3-2-2.8l0,0C0,107.5,0.9,106.2,2,106.2z"
      />
    <path class="st0" d="M2,130.2h389c1.1,0,2,1.3,2,2.8l0,0c0,1.5-0.9,2.8-2,2.8H2c-1.1,0-2-1.3-2-2.8l0,0C0,131.5,0.9,130.2,2,130.2z"
      />
    <path class="st0" d="M186,198H4c-2.2,0-4-1.8-4-4v-22c0-2.2,1.8-4,4-4h182c2.2,0,4,1.8,4,4v22C190,196.2,188.2,198,186,198z"/>
    <path class="st0" d="M120,15H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h116c2.2,0,4,1.8,4,4v7C124,13.2,122.2,15,120,15z"/>
    <path class="st0" d="M166,230H4c-2.2,0-4-1.8-4-4v-22c0-2.2,1.8-4,4-4h162c2.2,0,4,1.8,4,4v22C170,228.2,168.2,230,166,230z"/>
    <path class="st0" d="M236,262H4c-2.2,0-4-1.8-4-4v-22c0-2.2,1.8-4,4-4h232c2.2,0,4,1.8,4,4v22C240,260.2,238.2,262,236,262z"/>
  </ContentLoader>
)

export const VennDiagramLoader = () => (
  <ContentLoader width="370" height="280" viewBox="0 0 370 280" foregroundColor="#e5e5e5">
    <path d="M140 280c77.32 0 140-62.68 140-140S217.32 0 140 0 0 62.68 0 140s62.68 140 140 140z"/>
    <path d="M295.5 149c41.145 0 74.5-33.355 74.5-74.5S336.645 0 295.5 0 221 33.355 221 74.5s33.355 74.5 74.5 74.5zM288.5 273c26.234 0 47.5-21.266 47.5-47.5S314.734 178 288.5 178 241 199.266 241 225.5s21.266 47.5 47.5 47.5z"/>
  </ContentLoader>
)

export const ChannelOverlapBaseline = () => (
  <ContentLoader width="100%" height="136" viewBox="0 0 1232 136" preserveAspectRatio="none" foregroundColor="#e5e5e5">
   <rect x="97.03" y="32.65" width="328.35" height="10.97" rx="4" ry="4"/>
   <rect x="484.71" y="66.48" width="63.81" height="19.38" rx="4" ry="4"/>
   <rect x="463.75" y="38.14" width="105.71" height="9.69" rx="4" ry="4"/>
   <rect x="652.49" y="38.14" width="105.71" height="9.69" rx="4" ry="4"/>
   <rect x="817.1" y="38.14" width="105.71" height="9.69" rx="4" ry="4"/>
   <rect x="986.99" y="38.14" width="167.95" height="9.69" rx="4" ry="4"/>
   <rect x="644.15" y="66.48" width="122.39" height="19.38" rx="4" ry="4"/>
   <rect x="833.7" y="66.48" width="72.51" height="19.38" rx="4" ry="4"/>
   <rect x="974.08" y="66.48" width="193.78" height="19.38" rx="4" ry="4"/>
   <rect x="97.03" y="51.6" width="328.35" height="10.97" rx="4" ry="4"/>
   <rect x="97.03" y="72.18" width="283.21" height="10.97" rx="4" ry="4"/>
   <rect x="97.03" y="92.76" width="53.56" height="10.97" rx="4" ry="4"/>
   <circle cx="49.54" cy="58.61" r="31.77"/>
  </ContentLoader>
)