import React, { Component } from 'react';
import { getSurveyConfigs } from '../../utils/api';
import { SplitLayout, IndexGrid, ContentCard } from '@rockerbox/styleguide';
import { Button, Table } from 'semantic-ui-react';
import SelectConversion from '../SelectSegment';
import { Link } from 'react-router-dom';
import * as routes from '../../routes';
import GenericMessage from '../GenericMessage';


const ManageCell = ({item,col}) => {
  return (<Table.Cell>
    <IndexGrid.EditButton url={`/v3/data/marketing/surveys/edit/${item.id}`}/>
  </Table.Cell>)
}

class SurveyIndex extends Component {
  state = {
    surveyData: [],
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getSurveyConfigs()
    .then(surveyData => {
      this.setState({surveyData})
    })
  }

  render() {
    const { surveyData } = this.state;

    const cols = [
      {display: 'Display name', key: 'display_name'},
      {display: 'Conversion Id', key: 'conversion_id'},
      {display: 'Conversion Key', key: 'conversion_key'},
      {display: 'Survey Id', key: 'survey_id'},
      {display: 'Survey Key', key: 'survey_key'},
      {display: 'Survey Response Key', key: 'survey_response_key'},
      {display: 'Manage', as: ManageCell}
    ]

    return (

      <SplitLayout
        leftWidth={13}
        rightWidth={3}
        leftContent={
          <ContentCard hasTable title="Survey"
            topRight={<Button primary compact size="mini" icon="plus" content="Survey" as={Link} to={'/v3/data/marketing/surveys/create'} />}
          >
            <IndexGrid data={surveyData} cols={cols}/>
          </ContentCard>
        }
        rightContent={
          <GenericMessage message={"survey_index"} />
        }
      />
    )
  }
}

export default SurveyIndex;
