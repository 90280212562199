import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';
import { Grid, GridColumn } from 'semantic-ui-react'
import { getActiveStripeCoupons, getActiveStripePromoCodes } from '../../api/account';
import InputFormMonthlySpend from './inputFormMonthlySpend';
import InputFormPricing from './inputFormPricing';
import PromoCode from './promoCode';
import { formatDollarValue, applyDiscount } from './helpers';

const PricingInputForm = ({estimated, prorated, isEdit, setIsEdit, isEditPromoCode, setIsEditPromoCode, monthlyAdSpend, setMonthlyAdSpend, promoCode, setPromoCode, codePending, setCodePending, promoValue, setPromoValue, promoTagline, promoPercent, setPromoPercent, setDiscountType, clientSecretOptions, oneHundredPercentOff, setOneHundredPercentOff, pricePending, setPricePending}) => {
  
  const [data, setData] = useState(null);
  const [promoText, setPromoText] = useState(null);
  const [activeCoupons, setActiveCoupons] = useState(null)
  const [promoCodesToIds, setPromoCodesToIds] = useState(null)
  const [oneHundredPercentOffCodes, setOneHundredPercentOffCodes] = useState(null)

  useEffect(() => {
    getActiveStripeCoupons()
    .then(couponResponse => {
      getActiveStripePromoCodes()
      .then(promoCodeResponse => {
        const couponCodes = couponResponse['response'].map(({id})=>id)
        const combinedCodes = couponCodes.concat(promoCodeResponse['response'].map(({code})=>code))
        setActiveCoupons(combinedCodes);
        const idArray = promoCodeResponse['response'].map(({code, id})=>({[code]:id}))
        setPromoCodesToIds(Object.assign.apply(Object, idArray));
        const oneHundredPercentOffObjects = promoCodeResponse['response'].filter(function (el) {
          return el.coupon.percent_off == 100
        });
        const oneHundredPercentOffObjectsPromoCodes = oneHundredPercentOffObjects.map(a => a.code)
        const oneHundredPercentOffCombinedCodes = oneHundredPercentOffObjectsPromoCodes.concat(oneHundredPercentOffObjects.map(a => a.coupon.id))
        setOneHundredPercentOffCodes(oneHundredPercentOffCombinedCodes)
      })
      
    })
}, [])

  const monthlyAdSpendFormatted = formatDollarValue(monthlyAdSpend)

  const estimatedPrices = applyDiscount(estimated, promoPercent, promoValue, oneHundredPercentOff)
  const proratedPrices = applyDiscount(prorated, promoPercent, promoValue, oneHundredPercentOff)

  function handleClick() {
    setIsEditPromoCode(true)
  }

  function handleReset() {
    setPricePending(true)
    setPromoCode(null)
    setIsEditPromoCode(false)
    setPromoValue(null)
    setPromoPercent(null)
    setPromoText(null)
    setOneHundredPercentOff(false)
  }

  const onSubmit = () => { 
    setPricePending(true)
    setIsEdit(false);
    setMonthlyAdSpend(data);
  }

  const onSubmitPromoCode = () => {
    const isValidCode = activeCoupons.includes(promoText.trim()) ? true : false
    setIsEditPromoCode(false);
    if (isValidCode){
      if (oneHundredPercentOffCodes.includes(promoText.trim())){
        setOneHundredPercentOff(true)
      }
      if (promoText in promoCodesToIds){
        setPromoCode(promoCodesToIds[promoText])
        setDiscountType("promo_code")
      } else{
        setPromoCode(promoText.trim())
        setDiscountType("coupon_code")
      }
      setCodePending(true)
    }
    else {
      toast.error('Invalid Code Entered')
      handleReset()
      setPricePending(false)
    }
  }
    
  return (
    <>
      <Grid>
      <GridColumn width={14}>
      <div>
        <InputFormMonthlySpend {...{isEdit, promoCode, codePending, monthlyAdSpendFormatted, setIsEdit, data, setData, onSubmit}}/>
        <InputFormPricing  {...{estimatedPrices, proratedPrices, promoCode, codePending, isEdit, pricePending}}/>
      </div>
      <div>
      <PromoCode {...{promoCode, isEditPromoCode, codePending, promoText, setPromoText, onSubmitPromoCode, handleClick, promoTagline, handleReset, clientSecretOptions, isEdit}}/>
      {!codePending && !isEdit && clientSecretOptions && <p className='due-today-explainer'>Your initial charge of ${proratedPrices["discounted"]} is prorated from today through the end of the month. Every month thereafter, you will be billed on the 1st day of each month based on your ad spend that Rockerbox has tracked for the previous month.</p>}
      </div>
      </GridColumn>
      <GridColumn width={2}></GridColumn>
      </Grid>
      </>
  );
}

export default PricingInputForm;
