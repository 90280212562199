import React, { useState } from "react";
import SummarySelector from "../CreativeDataSource/SummarySelector";
import { DELIVERED, MYDELIVERED, FAILED } from "./constants";

const StatusCard = (props) => {
  const { exportedReports, username, displayType, setDisplayType } = props;

  const deliveredNum = exportedReports.filter(
    (report) => report.finish_type === "success"
  ).length;
  const myDeliveredNum = exportedReports.filter(
    (report) =>
      report.finish_type === "success" && report.destination === username
  ).length;
  const failedNum = exportedReports.filter(
    (report) => report.finish_type === "failed"
  ).length;

  const ClickHandler = (type) => {
    setDisplayType(type);
  };

  const datasetList = [
    {
      value: deliveredNum,
      label: `Delivered Reports`,
      type: DELIVERED,
    },
    {
      value: myDeliveredNum,
      label: "My Delivered Reports",
      type: MYDELIVERED,
    },
    {
      value: failedNum,
      label: "Failed Reports",
      type: FAILED,
    },
  ];

  return (
    <SummarySelector
      datasetList={datasetList}
      onClick={ClickHandler}
      activeView={displayType}
    />
  );
};

export default StatusCard;
