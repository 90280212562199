import React, { useMemo, useEffect } from 'react';

import { buildModelOptions } from '../helpers'
import { segmentOptions } from '../../../utils/options';

export const getSegOptions = (isComparison, comparisonConfigsList, segsInfo) => {
  if (isComparison) return comparisonConfigsList || [];
  if (!segsInfo) return [];

  return segmentOptions(segsInfo)
}

export const findSelectedSegment = (segments, id) => {
  return segments?.find(({ filter_id }) => filter_id == id) || {}
}

export const derivedParameters = (selectedSegment) => {
  if (!selectedSegment || !selectedSegment.filter_id) {
    return {
      modelOptions: [],
      hasRevenue: false,
      hasNtf: ''
    }
  }

  const { include_revenue, has_new_to_file, use_model } = selectedSegment
  const modelOptions = buildModelOptions(use_model)
  return {
    modelOptions,
    hasRevenue: include_revenue,
    hasNtf: !!has_new_to_file
  }
}

export const useSegmentValues = (view, segId, comparisonConfigsList, segsInfo, model, setModel) => {
  const isComparison = view == 'comparison';
  const segOptions = useMemo(() => getSegOptions(isComparison, comparisonConfigsList, segsInfo), [isComparison, comparisonConfigsList, segsInfo]);
  const { modelOptions, hasRevenue, hasNtf } = useMemo(() => {
    const selectedSegment = findSelectedSegment(segsInfo, segId);
    return derivedParameters(selectedSegment);
  }, [segId, segsInfo]);

  useEffect(() => {
    if (!modelOptions || modelOptions.length === 0) return

    const modelExists = modelOptions.find(({ value }) => value === model)
    if (modelExists) return
    setModel('even')
  }, [modelOptions])

  return {
    segOptions,
    modelOptions,
    hasRevenue,
    hasNtf,
  }
}
