import React, { Component } from 'react';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { StatCard } from '@rockerbox/styleguide';
import CacheContext from '../../utils/CacheContext';

const SpendViewStats = (props) => {
  const [{currency_code}] = React.useContext(CacheContext);
  const data = props.activeData;
  const { numDays } = props

  if (!data) return null;

  return (
    <React.Fragment>
      { data
          .map(d => (
            <React.Fragment>
              <StatCard
                value={ d3.sum(d.values, x => x.imps) }
                label="Imps" explainer={`Past ${numDays} Days`} size='small'
              />
              <StatCard
                value={ d3.sum(d.values, x => x.clicks) }
                label="Clicks" explainer={`Past ${numDays} Days`} size='small'
              />
              <StatCard
                value={ d3.sum(d.values, x => x.spend) }
                label='Spend' type='currency' currencyCode={currency_code} explainer={`Past ${numDays} Days`} size='small'
              />
            </React.Fragment>
          )
        )
      }
    </React.Fragment>
  )
}
export default SpendViewStats;
