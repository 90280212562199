import React, { Component } from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';

import CacheContext from '../../utils/CacheContext';
import { getBatchTriggers, getBatchConfigData, updateBatchTrigger, getBatchTriggerLinks } from '../../utils/api';
import { Table, Form, Icon, Menu, Button, Modal, Checkbox, Dropdown, Portal, Segment, Header } from 'semantic-ui-react';
import { IndexGrid, ContentCard, SplitLayout, ColumnLayout } from '@rockerbox/styleguide';
import Rules from './Reports'
import Datasets from './Datasets'
import Jobs from './Jobs'
import CreateTrigger from './Create'
import Reporting from './Reporting'
import Sankey from './Sankey'
import Links from './Links'

const FLOAT_RIGHT = {float: 'right', margin: 0}

const TriggerIndex = (props) => {

  const history = Router.useHistory();
  const { params: { triggerId } = {} } = Router.useRouteMatch(`${routes.triggerIndex}/view/:triggerId`) || {};
  const [state, setState, Context] = React.useContext(CacheContext);
  const [showCreate, setShowCreate] = React.useState(false);
  const [triggerGraph, setTriggerGraph] = React.useState(null);
  const triggers = Context.getCache('triggers', getBatchTriggers) || [];
  const { traverse: triggerTraverse = [], edges = [] } = Context.getCache('triggerLinks', getBatchTriggerLinks) || {};
  const selectedTrigger = React.useMemo(() => {
    try {
      return triggers.filter(row => row.id == triggerId)[0]
    } catch(e) {
      return {}
    }
  }, [triggerId, triggers]) || false;

  const onClick = React.useCallback((id) => () => history.push(`${routes.triggerIndex}/view/${id}`), [])
  const isTriggerLoading = Context.isLoading('triggers')
  const isSelectedTrigger = (element) => element.id == triggerId
  const toggleActive = React.useCallback((e, data) => {
    updateBatchTrigger(triggerId, {active: data.checked})
      .then( (resp) => {
        const selectedIndex = triggers.findIndex(isSelectedTrigger);
        const new_triggers = triggers.slice(0, selectedIndex).concat(resp).concat(triggers.slice(selectedIndex+1, triggers.length));
        setState({ triggers: new_triggers })
      })
  }, [triggerId, triggers])

  // Split triggers into group/non-group.
  const groupedTriggerIds = React.useMemo(() => triggerTraverse.reduce((prev, sum) => sum.concat(prev), []), [triggerTraverse]);
  const normalTriggers = React.useMemo(() => triggers.filter(trigger => groupedTriggerIds.indexOf(trigger.id) === -1), [triggers, triggerTraverse]);
  const groupedTriggers = React.useMemo(() => triggerTraverse
    .map(group => {
      const triggersInGroup = triggers && triggers.length ? group.map(id => triggers.filter(trigger => trigger.id === id)[0]) : [];
      const name = triggersInGroup.length ? triggersInGroup[0]['name'] : null;
      const links = edges.filter(edge => group.indexOf(edge.from_id) !== -1).map(edge => {
        const { from_id, to_id, dataset } = edge;
        const from_index = triggersInGroup.findIndex(trigger => trigger.id === from_id);
        const to_index = triggersInGroup.findIndex(trigger => trigger.id === to_id);
        return { source: from_index, target:to_index, value: 5, label: dataset }
      });
      const nodes = triggersInGroup.map((trigger, i) => {
        const work = trigger['jobs'][0]['backfill_config']['work'];
        const name = work['pipeline_name'] || work['func_name'] || work['task_name'];
        return Object.assign({}, trigger, {id: i, name})
      });
      return { triggers: triggersInGroup, name, links, nodes  }
    }), [triggerTraverse, triggers])

  const cadence = triggers.filter(trigger => trigger.id === triggerId)[0] && triggers.filter(trigger => trigger.id === triggerId)[0]['cadence'] || 1 // error handling if no match

  return <React.Fragment>
    <SplitLayout leftWidth="2" rightWidth="14"
      leftContent={
        <React.Fragment>
        <Menu vertical fluid>
          <Menu.Item header>Triggers</Menu.Item>
          {
            normalTriggers.map(trigger => {
              return <Menu.Item onClick={onClick(trigger.id)} key={trigger.id} >
                {trigger.name}
                {triggerId == trigger.id && <Icon name="chevron right" style={FLOAT_RIGHT}/>}
              </Menu.Item>
            })
          }
        </Menu>
        <Menu vertical fluid>
          <Menu.Item header>Grouped Triggers</Menu.Item>
          {
            groupedTriggers.map(group =>
              <Dropdown item text={group.name}>
                <Dropdown.Menu>
                  {group.triggers.map(trigger => <Dropdown.Item onClick={onClick(trigger.id)} key={trigger.id} >{trigger.name} {triggerId == trigger.id && <Icon name="chevron right" style={FLOAT_RIGHT}/>}</Dropdown.Item>)}
                  <Dropdown.Item onClick={()=>setTriggerGraph(group)}>Show Dependency</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
        </Menu>
        <Button icon="plus" onClick={() => setShowCreate(true) } />
        { showCreate && <CreateTrigger onClose={() => setShowCreate(false)} />}
        </React.Fragment>
      }
      rightContent={
        <React.Fragment>
          <Portal onClose={()=>setTriggerGraph(null)} open={!!triggerGraph}>
            <Segment
              style={{
                left: '40%',
                position: 'fixed',
                top: '40%',
                zIndex: 1000,
              }}
            >
              <Sankey data={triggerGraph}/>
              <Button
                style={{display: "block"}}
                content='Close Portal'
                negative
                onClick={()=>setTriggerGraph(null)}
              />
            </Segment>
          </Portal>
          { selectedTrigger &&
            <React.Fragment>
              <ContentCard title={
                  <SplitLayout leftWidth="15" rightWidth="1"
                    leftContent={`Processing Trigger: ${selectedTrigger.name}`}
                    rightContent={<Checkbox toggle checked={selectedTrigger.active} onChange={ toggleActive }/>}
                  />
                }
              >
                  <ul>
                    <li>An aggregation processing trigger will automatically reprocess data after a new piece of information is dropped off or pulled into the platform.</li>
                    <li>Triggers need to be setup for data processing that is predictable asynchronously or might be delayed because of unavailability in another platform.</li>
                  </ul>
              </ContentCard>
              <ColumnLayout leftWidth="5" centerWidth="5" rightWidth="6"
                leftContent={<Rules groupId={triggerId} rules={selectedTrigger['rules'] || []} cadence={cadence}/>}
                rightContent={
                  <React.Fragment>
                    <Jobs groupId={triggerId} jobs={selectedTrigger['jobs'] || []} />
                    <Links groupId={triggerId} triggers={groupedTriggers} />
                  </React.Fragment>
                }
               >
                {<Datasets groupId={triggerId} datasets={selectedTrigger['datasets'] || []} multiday={selectedTrigger['multiday']} />}
              </ColumnLayout>
            </React.Fragment>
          }
          { isTriggerLoading && <ContentCard forceLoading /> }
          { !isTriggerLoading && !selectedTrigger && <Table><IndexGrid.EmptyMessage fallBackMsg={"Choose an existing trigger or create a new trigger to get started" } /></Table> }
          <Reporting groupId={triggerId} />
        </React.Fragment>
      }
    />
  </React.Fragment>

}

export default TriggerIndex
