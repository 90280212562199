import React from 'react'
import moment from 'moment'

import { formatCurrency } from '../../../utils/valueFormatter'


export const axisLabelStyles = {
  fontSize: 12,
  textAnchor: 'middle',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fill: '#919191',
  textTransform: 'uppercase',
}

const formatValue = (value, dataType) => {
  if (dataType === 'date') {
    return moment(value).format('MMM YYYY')
  }
  if (dataType === 'currency') {
    return formatCurrency(value, 'USD', 0)
  }
  return value
}

export const CustomAxisTick = (axisType, dataType) => ({ x, y, payload }) => {
  const value = payload?.value
  if (!value) return null

  const formatted = formatValue(value, dataType)
  const rotation = axisType === 'x' ? 0 : -90
  const dy = axisType === 'x' ? 8 : 0
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={dy} textAnchor="end" fill="#919191" transform={`rotate(${rotation})`} >
        {formatted}
      </text>
    </g>
  )
}

export const TooltipRow = ({ label, value, color, final, style }) => (
  <p style={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: !!final ? 0 : 5,
    paddingTop: !!final && 5,
    borderTop: !!final && '1px solid #e6e6e6',
    color: '#4d4d4d',
    fontSize: 12,
    ...style,
  }}>
    {color && <span style={{ width: 10, height: 10, backgroundColor: color, marginRight: 5, marginTop: 3 }} />}
    <span style={{ flexGrow: 1, marginLeft: 0, marginRight: 20, fontWeight: !!value && 600 }}>{label}</span>
    <span style={{ fontFeatureSettings: '"kern" 1, "tnum" 1' }}>{value}</span>
  </p>
)

export const tooltipStyles = {
  padding: 10,
  borderRadius: 4,
  background: '#fff',
  color: '#000',
  boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
}

export const PLATFORM_COLORS = {
  'adwords': '#12D975',
  'bing': '#00B8A1',
  'facebook': '#479BF5',
  'linkedin': '#0A94C2',
  'pinterest': '#E61939',
  'snapchat': '#F5F33D',
  'tiktok': '#42F0EB',
}
