import React, { useMemo } from 'react'

import { CacheContext } from '../../../utils/CacheContext'
import { getAnnouncements } from '../../api/attribution'
import { Alert } from '../../components'


const Announcements = () => {
  const [state, setState, Context] = React.useContext(CacheContext)
  const announcements = Context.getCache(`announcements`, getAnnouncements) || []

  const majorAnnouncements = useMemo(() =>
    announcements.filter(a => !!a.active && !!a.major)
  , [announcements])

  if (!majorAnnouncements?.length) return null

  return (
    <div className='announcements'>
      {majorAnnouncements.map((x, i) => (
        <Alert
          key={i}
          type='warning'
          title={x.title}
          description={x.message}
        />
      ))}
    </div>
  )
}

export default Announcements
