import React, { useState, useEffect } from "react"
import { Card, Statistic, Icon } from "semantic-ui-react"
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/solid'

const TrendCard = styled(Card)`
  background: ${props => props.isActive ? props.activeColor : '#FFFFFF'} !important;
`

const StatValue = styled(Statistic.Value)`
  color: ${props => props.isActive ? '#FFFFFF' : props.activeColor ? props.activeColor : '#026CAC'} !important;
`

const Heading = styled.h3`
  color: ${props => props.isActive ? 'rgba(255, 255, 255, 0.75)' : 'black'} !important;
`

const TrendSpan = styled.span`
  font-weight: bold;
  color: ${props => (props.isActive && props.redOrGreen == '#75BE42') ? '#B7EC92' : props.redOrGreen} !important;
`

const Placeholder = ({ option, activeColor }) => (
 <TrendCard className={`trend-card placeholder`} >
  <Card.Content>
    <Heading style={{ margin: 0 }}>{option.text}</Heading>
    <svg viewBox="0 0 167 54" style={{ opacity: 0.1 }}>
      <circle cx="6.93" cy="45.87" r="4.99" fill="#999" />
      <path d="M61.04,50.24H18.4c-1.57,0-2.84-1.27-2.84-2.84v-3.12c0-1.57,1.27-2.84,2.84-2.84h42.63c1.57,0,2.84,1.27,2.84,2.84v3.12
        C63.88,48.97,62.6,50.24,61.04,50.24z" fill="#999" />
      <path d="M121.56,29.99H9.57c-4.12,0-7.46-3.34-7.46-7.46v-8.19c0-4.12,3.34-7.46,7.46-7.46h111.97c4.12,0,7.46,3.34,7.46,7.46v8.19
        C129.02,26.66,125.68,29.99,121.56,29.99z" fill={activeColor} />
    </svg>
  </Card.Content>
 </TrendCard>)

const TrendCards = (props) => {
  const { options, onChange, value, cardGroupStyle, itemsPerRow, activeColor="#026CAC", point="up" ,showPlaceholder=false} = props;
  const [active, setActive] = useState(value || "")
  
  useEffect(() => {
    onChange(false, { value: active })
  }, [active])

  const generateStyle = (current) => {
    return { backgroundColor: current == active ? "green" : "" }
  }

  const formatNumber = (item) => {
    const { format } = item;
    let numberFormatOptions = {
      style: format ? format : 'decimal',
      maximumFractionDigits: item.maxDigits ? item.maxDigits : 0
    }
    
    if (format == 'currency') numberFormatOptions['currency'] = 'USD'; // in the future, add currency code -> item.currencyCode || 'USD'

    const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
    const formatted = numberFormatter.format(item.mainNumber);

    return formatted
  }

  const formatTrendNumber = (item) => {
    let numberFormatOptions = {
      style: 'percent',
      maximumFractionDigits: 0
    }

    const numberFormatter = new Intl.NumberFormat('en-US', numberFormatOptions);
    const formatted = numberFormatter.format(Math.abs(item.trendNumber));

    return formatted
  }

  return (
    <Card.Group itemsPerRow={itemsPerRow} style={cardGroupStyle}>
      {options.map(option => {
        const isActive = option.value == active;
        const redOrGreen = (option.trendReversed && option.trendNumber < 0) || (!option.trendReversed && option.trendNumber > 0) ? '#75BE42' : '#E76D8E';
        
        if (showPlaceholder) return <Placeholder {...{option, activeColor}} />;

        return (
          <TrendCard 
            link 
            {...{isActive, activeColor}}
            // className={`trend-card ${isActive ? `active ${point}` : ""}`} 
            onClick={() => setActive(option.value)}
          >
            <Card.Content>
              <Heading {...{isActive}}>{option.text}</Heading>
              
              <Statistic style={{ margin: '0 0 1em 0'}}>
                <StatValue {...{isActive, activeColor}}>
                  {formatNumber(option)}
                </StatValue>
              </Statistic>

              <div style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                {!isNaN(option.trendNumber) && <>
                  {(formatTrendNumber(option) == '0%') ?
                    <span style={{ color: `${isActive ? 'rgba(255, 255, 255, 0.65)' : 'rgba(191, 191, 191, 0.87)'}`, marginTop: 2 }}>&nbsp;{`No change ${option.baseline}`}</span>
                    : <>
                      <TrendSpan {...{isActive, redOrGreen}}>
                        {option.trendNumber > 0 ? <TrendingUpIcon style={{height: 20, marginRight: 2}}/> : <TrendingDownIcon style={{height: 20, marginRight: 2}}/>}
                      </TrendSpan>
                      <TrendSpan {...{isActive, redOrGreen}}>                        
                        {formatTrendNumber(option)}
                      </TrendSpan>
                      <span style={{ color: `${isActive ? 'rgba(255, 255, 255, 0.65)' : 'rgba(191, 191, 191, 0.87)'}` }}>&nbsp;{option.baseline}</span>
                    </>
                  }
                </>}
              </div>
            </Card.Content>
          </TrendCard>
        )
      })}
    </Card.Group>
  )
}

export default TrendCards

TrendCards.propTypes = {
  as: PropTypes.element,
  // data: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string,
  color: PropTypes.string,
  baselineMetric: PropTypes.number,
  baselineName: PropTypes.string,
  positivTrendUp: PropTypes.bool,
  showPlaceholder: PropTypes.bool
  //tooltip: element?
}



