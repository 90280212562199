import React, {} from 'react';
import { IndexGridNew } from '@rockerbox/styleguide';
import { baseCols, facebookCols, baseSummaryCols, facebookSummaryCols } from './constants';
import { groupByMonth, calculateAverages, groupAndCalculateMean } from './helpers';

const Table = ({data, platform}) => {

    const metrics = data ? groupByMonth(groupAndCalculateMean(data, platform), platform) : data
    const means = data ? calculateAverages(metrics) : data
    const cols = platform === 'facebook' ? [...baseCols, ...facebookCols] : baseCols;
    const summaryCols = platform === 'facebook' ? [...baseSummaryCols, ...facebookSummaryCols] : baseSummaryCols;
    const displayName = platform === "google" ? "Adwords" : platform[0].toUpperCase() + platform.slice(1)
    const titleText = `${displayName} Monthly Performance Metrics`

    return (
        <>
            {data.length > 0 && 
                <div>
                    <IndexGridNew title={titleText} data={metrics} cols={cols} summaryData={means} summaryCols={summaryCols} footer fallbackMsg="No data found"/>
                </div>
            }
        </>
    );
}
export default Table;
