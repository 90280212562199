import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { openIntercomMessenger } from '../../../components/Navigation/helpSection'

const DataWarning = ({ header, details, intro }) => (
  <div style={{ textAlign: 'center', paddingTop: 30, paddingBottom: 30 }}>
    <Icon name="warning sign" size="massive" color="yellow" />
    <h3 style={{ fontWeight: 450, marginTop: 5 }}>{header}</h3>
    <p style={{ color: '#8E8D91', fontSize: '1.15em' }}>{details}<br />
      {intro ? intro : `For more information`}, reach out to your Customer Success Manager or <Link as={Button} onClick={openIntercomMessenger}>contact us for help</Link>.
    </p>
  </div>
)

export default DataWarning;
