import React, { Component } from 'react';
import * as Router from "react-router-dom";
import * as routes from '../../routes';
import CacheContext from '../../utils/CacheContext';
import { exportCSV } from '../../utils/export';
import { Message, Button, Dropdown, Input, Label } from 'semantic-ui-react'

import { getTransformTables, getTransformTableData } from '../../utils/api';
import TreeTableData from './TreeTableData';
const colors = [
  'orange',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'yellow',
  'red',
  'brown',
  'grey',
  'black',
]

const PreviewAll = (props) => {
  const history = Router.useHistory();
  const [state, setState, Context] = React.useContext(CacheContext);
  const transformTables = Context.getCache(`transformTables`, getTransformTables) || [];
  const [rawData, setRawData] = React.useState([]);
  const [defaultOpen, setDefaultOpen] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const formatter = {
    "table_name": (values) => {
      return (
        <div>
          { values.map(value => {
              const activeTable = transformTables.find(row => row.name == value);
              const indexOf = transformTables.indexOf(activeTable);
              return <Label 
                to={`${routes.transformTablesIndex}/view/${activeTable.id}`} 
                as={Router.Link} 
                color={colors[indexOf]}
                size='mini'
              >{value}
              </Label>
            }) 
          }
        </div>
      )
    }
  }

  React.useEffect(() => {

    setLoading(true)
    const allTableData = transformTables.map(table => {
      return getTransformTableData(table.id, "spend")
    })
    Promise.all(allTableData)
      .then((dataList) => {
        const allData = dataList.reduce((p,c,i) => {
          if (typeof(c) == "string") return p
          c.map(v => {
            const table = transformTables[i];
            const { name } = table;
            v['table_name'] = name;
          })
          return p.concat(c)
        }, [])
        setRawData(allData)
        setLoading(false)
      })
  }, [transformTables.length])

  const TierLevel = () => <Dropdown inline
    options={[0,1,2,3,4].map(text => { return {text, value: text} }) } 
    defaultValue={defaultOpen} 
    onChange={(_,{value}) => setDefaultOpen(value)} 
  />

  const headers = [<div style={{display:"block"}}><span>Marketing Tiers – </span><TierLevel /></div>, "Num Items", "Transform Table"]

  return <>
    <Message
      header="Explore Marketing Structure"
      content="Transform tables allows you to use data from an advertising platform to structure your marketing data for interpretation. Each platform gets its own configuration so that you can reformat the data so that structure across platforms matches. Below shows the results for all platform configurations. Each row in the table below specifies which transform table was used to generate that record as well as the number of unique records below the level shown."
    />
    <div>
      <Input label="Find Value" onChange={(_, {value}) => setSearchValue(value)} value={searchValue} />
      <Button floated="right" onClick={() => exportCSV(rawData, "transform_table.csv") } >Export</Button>
    </div>
    <TreeTableData 
      key={defaultOpen} 
      {...{headers, title: "", searchValue, rawData, loading, defaultOpen, setColumn: "table_name", formatter}} 
    />
  </>
}

export default PreviewAll;
