import React, { Component } from 'react';
import * as routes from '../../routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ContentCard } from '@rockerbox/styleguide';
import { Button, Table, Checkbox, Dropdown, Modal, Form, Input } from 'semantic-ui-react';
import { getAuthorizations, getGoogleAnalyticsProfiles, getGoogleAnalyticsDataSource, getGoogleProperties, postGooglePropertySelection, getAmazonAdsAccount, getAmazonMWSAccount } from '../../utils/api';
import AmazonMWSAuthModal from './AmazonMWSAuthModal';
import AmazonMWSAccount from './AmazonMWSAccount';
import _ from 'lodash';

class OtherDataSources extends Component {
  state = {
    googleAnalytics: {
      propertyId: false,
      accountId: false,
      viewId: false
    },
    options: [],
    accounts: [],
    properties: [],
    profiles: [],
    loading: true,
    disabled: false,
    amazon_ads: false,
    amazon_mws: false,
    amazonMWSModalOpen: false,
  }

  componentDidMount() {
    getGoogleAnalyticsDataSource()
      .then(data => {
        this.getOptions(data)
      });

    getAuthorizations()
      .then(data => {
        const facebook = !!data.find(row => row.platform == "facebook")
        this.setState({ facebook })
      })

    getAmazonAdsAccount()
      .then(data => {
        if (data.length) this.setState({amazon_ads: data[0]})
      });

    getAmazonMWSAccount()
      .then(data => {
        this.setState({amazon_mws: data})
      });
  }

  getOptions = (data) => {
    getGoogleProperties()
      .then( options => {
        const accounts = _.cloneDeep(options).reduce((p,c) => {
          p[c.account_id] = p[c.account_id] || [];
          p[c.account_id].push(c)
          return p
        },{})
        const accountOptions = Object.keys(accounts).map(id => {
          const account = accounts[id][0]
          const properties = _.cloneDeep(accounts[id]).map(row => {
            row['text'] = row['name']
            row['value'] = row['id']
            row['key'] = row['id']
            return row
          })
          account['properties'] = properties;
          account['text'] = account['account_name']
          account['value'] = account['account_id']
          account['key'] = account['account_id']
          return account
        })

        const filterdAccounts = data.account_id ? accountOptions.filter(row => row.key == data.account_id) : []
        const gaAccount = filterdAccounts.length > 0 ? filterdAccounts[0] : []
        const properties = gaAccount['properties'] ? gaAccount['properties'] : []

        this.setState({googleAnalytics: data, options, accounts: accountOptions, properties, loading: false, disabled: false})
      });

  }

  componentDidUpdate(prevProps, prevState) {
    const { account_id, property_id, view_id } = this.state.googleAnalytics;
    if (prevState.googleAnalytics.property_id != property_id) {
      this.getProfiles(account_id, property_id)
    }

    if (view_id && prevState.googleAnalytics.view_id != view_id && view_id != "") {
      this.setState({loading: true, disabled: true})
      postGooglePropertySelection(this.state.googleAnalytics)
        .then(resp => {
          this.setState({loading: false, disabled: false})
        })
    }
  }

  getProfiles = (account_id, property_id) => {
    getGoogleAnalyticsProfiles(account_id, property_id)
      .then( options => {
        options.map(row => {
          row.value = row.id
          row.text = row.name
          row.key = row.id
        })
        this.setState({ profiles: options })
      })
  }

  handleSelection = (field) => (event, data) => {
    const GA = _.clone(this.state.googleAnalytics);
    GA[field] = data.value;
    GA[field.replace("id","name")] = data.options.filter(row => row.value == data.value)[0].text;

    const toUpdate = { googleAnalytics: GA }
    if (field == "account_id") {
      toUpdate['properties'] = this.state.accounts.filter(row => row.value == data.value)[0]['properties']
    }
    this.setState(toUpdate)
  }

  toggleModal = (type, v) => {
    if (type === "amazon_mws") this.setState({amazonMWSModalOpen: v})
  }

  handleChange = (event, data) => {
    const {checked, name} = data;

    if(checked && name === 'googleAnalytics') {
      window.location = "/auth/ga";
    }
    if(checked && name === 'amazon_ads') {
      window.open('/auth/amazon_ads', '_self');
    }
    if(checked && name === 'amazon_mws') {
      this.toggleModal('amazon_mws', true);
    }
  }

  refreshIntegration = (integration) => {
    if (integration === 'amazon_mws') {
      getAmazonMWSAccount()
        .then(data =>
          this.setState({amazon_mws: data})
        )
    }
  }


  render() {
    const { googleAnalytics, amazon_ads, amazon_mws, loading, disabled, options, accounts, properties, profiles, amazonMWSModalOpen} = this.state;

    return (
      <React.Fragment>
        <ContentCard
          disableHeaderEllipse
          hasTable
          title="Integrations"
        >
          <ContentCard.Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing/>
                <Table.HeaderCell>
                  Source Name
                </Table.HeaderCell>
                <Table.HeaderCell collapsing/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Checkbox toggle
                    checked={googleAnalytics.active == 1}
                    disabled={googleAnalytics.active == 1}
                    onChange={this.handleChange}
                    name="googleAnalytics"
                  />
                </Table.Cell>
                <Table.Cell>
                  Google Analytics

                  <Dropdown
                    placeholder='Select Account'
                    selection
                    options={accounts}
                    value={googleAnalytics.account_id}
                    disabled={loading || disabled}
                    onChange={this.handleSelection("account_id")}
                    style={{marginLeft: 15, marginRight: 15}}
                    disabled={googleAnalytics.account_id}
                  />
                  <Dropdown
                    placeholder='Select Property'
                    selection
                    options={properties}
                    value={googleAnalytics.property_id}
                    disabled={loading || disabled}
                    onChange={this.handleSelection("property_id")}
                    style={{marginLeft: 15, marginRight: 15}}
                  />
                  { profiles.length > 0 &&
                    <Dropdown
                      placeholder='Select View Profile'
                      selection
                      options={profiles}
                      value={googleAnalytics.view_id}
                      disabled={loading || disabled}
                      onChange={this.handleSelection("view_id")}
                      style={{marginLeft: 15, marginRight: 15}}
                    />
                  }
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button as={Link} to={routes.dataSources + "/view/ga_data"}>View</Button>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Checkbox toggle
                    checked={!!amazon_ads}
                    disabled={!!amazon_ads}
                    onChange={this.handleChange}
                    name="amazon_ads"
                  />
                </Table.Cell>
                <Table.Cell>
                  Amazon Ads
                </Table.Cell>
                <Table.Cell width={2} textAlign="right" />
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Checkbox toggle
                    checked={!!amazon_mws.seller_id}
                    disabled={!!amazon_mws.seller_id}
                    onChange={this.handleChange}
                    name="amazon_mws"
                  />
                </Table.Cell>
                <Table.Cell>
                  Amazon Seller Central
                  {amazon_mws.marketplaces &&
                    <span className="muted m-l-10">
                      {amazon_mws.seller_id}
                      &nbsp;
                      <AmazonMWSAccount marketplaces={amazon_mws.marketplaces} />
                    </span>
                  }
                </Table.Cell>
                <Table.Cell width={2} textAlign="right" />
              </Table.Row>
            </Table.Body>
          </ContentCard.Table>
        </ContentCard>
        {!amazon_mws.seller_id &&
          <AmazonMWSAuthModal
            open={amazonMWSModalOpen}
            onClose={() => this.toggleModal('amazon_mws', false)}
            onSuccess={() => this.refreshIntegration('amazon_mws')}
          />
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    artifacts: state.app.artifacts,
  };
}

export default connect(mapStateToProps)(OtherDataSources);
