import React from 'react'

import { ModelSectionHeader } from '../stateless'
import PredVsActualChart from './predVsActual'
import SeasonalityChart from './seasonality'
import CorrelationChart from './correlationData'
import TrainingDataChart from './trainingData'


const ModelInfo = ({ modelData, features, modelDate }) => {
  const { predVsActual, metrics, trainingDates, trainingData, seasonality, correlationData } = modelData || {}

  return (<>
    <ModelSectionHeader
      text='Model Results'
      right={!!modelDate && `Build Date: ${modelDate}`}
    />
    <PredVsActualChart {...{ predVsActual, metrics }} />

    <ModelSectionHeader text='Model Features'/>
    {/* <SeasonalityChart {...{ seasonality, trainingDates }} /> */}
    <TrainingDataChart {...{ trainingData, features, predVsActual }} />
    <CorrelationChart {...{correlationData, features }} />
  </>)
}

export default ModelInfo
