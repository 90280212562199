import React, { Component } from 'react';
import { Column, Row, Wrapper, Collapsible } from '../TreeParts';
import { getViaKey, get, currencyFormat, currency, percent, number, defaultNumber } from './helpers';

export const ProfitTotal = ({multiplier, tag, values, months, accessor, formatter}) => {
  const format = formatter || defaultNumber;

  const all_gross_spend_cum = [
    ...months.map(date => (getViaKey(values,`NTF.${"cohort"}.spend`)) ),
    ...["cohort"].map(date => (getViaKey(values,`NTF.${"cohort"}.spend`)) ).slice(0,1)
  ]

  const all_cogs_cum = [
    ...months.map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.cumulative.revenue`,0) + getViaKey(values,`Existing.${date}.cumulative.revenue`,0))) )
  ]

  const lastDate = months.slice(-1)[0]
  const lastCogs = all_cogs_cum.slice(-1)[0]
  const lastSpend = all_gross_spend_cum.slice(-1)[0]
  const cohortCogs = ((multiplier)*(getViaKey(values,`NTF.cohort.cumulative.revenue`,0) + getViaKey(values,`Existing.cohort.cumulative.revenue`,0)))
  const cohortSpend = all_gross_spend_cum.slice(-1)[0] // CONST

  return (
    <React.Fragment>
      { ["cohort"].map((date,i) => (
          <Column>
            { format((getViaKey(values,`Existing.${date}.revenue`,0) + getViaKey(values,`NTF.${date}.revenue`,0) - cohortSpend - cohortCogs)) }
          </Column>
        )).slice(0,1) 
      }
      { months.map((date,i) => (
          <Column>
            { format(all_cogs_cum[i] ? (getViaKey(values,`Existing.${date}.cumulative.revenue`,0) + getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i]) : 0) }
          </Column>
        ))
      }
      <Column bold>
        { format((getViaKey(values,`Existing.${lastDate}.cumulative.revenue`,0) + getViaKey(values,`NTF.${lastDate}.cumulative.revenue`,0) - lastSpend - lastCogs)) }
      </Column>
    </React.Fragment>
  )
}

const Profit = ({multiplier, tag, values, months, accessor, formatter}) => {
  const format = formatter || defaultNumber;

  const ntf_gross_spend_cum = [
    ...months.map(date => (getViaKey(values,`NTF.${"cohort"}.spend`)) ),
    ...["cohort"].map(date => (getViaKey(values,`NTF.${"cohort"}.spend`)) ).slice(0,1)
  ]

  const ntf_cogs = [
    ...["cohort"].map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.revenue`,0))) ).slice(0,1),
    ...months.map(date => ((multiplier)*(getViaKey(values,`repeat NTF.${date}.revenue`,0))) ) 
  ]
  const ntf_cogs_cum = [
    ...["cohort"].map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.revenue`,0))) ).slice(0,1),
    ...months.map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.cumulative.revenue`,0))) )
  ]


  const all_gross_spend_cum = [
    ...months.map(date => (getViaKey(values,`NTF.${"cohort"}.spend`) ) ),
    ...["cohort"].map(date => (getViaKey(values,`NTF.${"cohort"}.spend`) ) ).slice(0,1)
  ]

  const all_cogs = [
    ...["cohort"].map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.revenue`,0) + getViaKey(values,`Existing.${date}.revenue`,0))) ).slice(0,1),
    ...months.map(date => ((multiplier)*(getViaKey(values,`repeat NTF.${date}.revenue`,0) + getViaKey(values,`repeat Existing.${date}.revenue`,0))) ) 
  ]
  const all_cogs_cum = [
    ...["cohort"].map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.revenue`,0) + getViaKey(values,`Existing.${date}.revenue`,0))) ).slice(0,1),
    ...months.map(date => ((multiplier)*(getViaKey(values,`NTF.${date}.cumulative.revenue`,0) + getViaKey(values,`Existing.${date}.cumulative.revenue`,0))) )
  ]

  const numCustomers = getViaKey(values,`NTF.cohort.converters`,0)
  

  return (
    <React.Fragment>


      <Row hover grey>
        <Column padded="5">NTF Customers</Column>
        { months.map(date => (<Column></Column>) ) }
        <Column />
        <Column />
      </Row>

      <Row hover>
        <Column padded="20">Cumulative Revenue</Column>
        { ["cohort"].map(date => (<Column>{ format((getViaKey(values,`NTF.${date}.revenue`,0))) }</Column>) ).slice(0,1) }
        { months.map(date => (<Column>{ format((getViaKey(values,`NTF.${date}.cumulative.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>


      <Row hover light>
        <Column padded="35">Incremental Revenue</Column>
        <Column />
        { months.map(date => (<Column>{ format((getViaKey(values,`repeat NTF.${date}.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>

      <Collapsible hideTotalOnOpen={true} open={false} title={
          <Column padded="5" style={{textAlign:"left",marginRight:"-17px"}}>Total Costs</Column>
        }
        totalRow={
          <React.Fragment>
            { ntf_cogs_cum.map((v,i) => (<Column>{ format(v + ntf_gross_spend_cum[i]) }</Column>)) }
            <Column />
          </React.Fragment>
        }
      > 

        <Row hover light>
          <Column padded="20" style={{marginRight:"-35px"}}>COGS</Column>
          { ntf_cogs.map((v,i) => (<Column>{ format(v) }</Column>)) }
          <Column />
        </Row>
        <Row hover light>
          <Column padded="20" style={{marginRight:"-35px"}}>Marketing</Column>
          { ntf_gross_spend_cum.map((v,i) => (<Column>{ format(i == 0 ? v : 0) }</Column>)) }
          <Column />
        </Row>
      </Collapsible>

      <Row hover>
        <Column padded="20">Profit (Loss)</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { format((getViaKey(values,`NTF.${date}.revenue`,0) - ntf_gross_spend_cum[i] - ntf_cogs_cum[i])) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { format((getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - ntf_gross_spend_cum[i] - ntf_cogs_cum[i+1])) }
            </Column>
          ))
        }
         <Column />
      </Row>
      <Row hover light>
        <Column padded="35">Profit per Customer</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { format((getViaKey(values,`NTF.${date}.revenue`,0) - ntf_gross_spend_cum[i] - ntf_cogs_cum[i])/numCustomers) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { format((getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - ntf_gross_spend_cum[i] - ntf_cogs_cum[i+1])/numCustomers) }
            </Column>
          ))
        }
        { months.map((date,i) => (
            <Column bold>
              { format((getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - ntf_gross_spend_cum[i] - ntf_cogs_cum[i+1])/numCustomers) }
            </Column>
          )).slice(-1)
        }
      </Row>
      <Row hover light>
        <Column padded="35">ROI</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { defaultNumber((getViaKey(values,`NTF.${date}.revenue`,0) / (ntf_gross_spend_cum[i] + ntf_cogs_cum[i]))) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { defaultNumber((getViaKey(values,`NTF.${date}.cumulative.revenue`,0) / (ntf_gross_spend_cum[i] + ntf_cogs_cum[i+1]))) }
            </Column>
          ))
        }
         <Column />
      </Row>



      <Row hover grey>
        <Column padded="5">All Customers</Column>
        { months.map(date => (<Column></Column>) ) }
        <Column />
        <Column />
      </Row>

      <Row hover>
        <Column padded="20">Cumulative Revenue</Column>
        { ["cohort"].map(date => (<Column>{ format((getViaKey(values,`Existing.${date}.revenue`,0)) + (getViaKey(values,`NTF.${date}.revenue`,0))) }</Column>) ).slice(0,1) }
        { months.map(date => (<Column>{ format((getViaKey(values,`Existing.${date}.cumulative.revenue`,0)) + (getViaKey(values,`NTF.${date}.cumulative.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>


      <Row hover light>
        <Column padded="35">Incremental Revenue</Column>
        <Column />
        { months.map(date => (<Column>{ format((getViaKey(values,`repeat Existing.${date}.revenue`,0)) + (getViaKey(values,`repeat NTF.${date}.revenue`,0))) }</Column>) ) }
        <Column />
      </Row>


      <Collapsible hideTotalOnOpen={true} open={false} title={
          <Column padded="5" style={{textAlign:"left",marginRight:"-17px"}}>Total Costs</Column>
        }
        totalRow={
          <React.Fragment>
            { all_cogs_cum.map((v,i) => (<Column>{ format(v + all_gross_spend_cum[i]) }</Column>)) }
            <Column />
          </React.Fragment>
        }
      > 

        <Row hover light>
          <Column padded="20"style={{marginRight:"-35px"}} >COGS</Column>
          { all_cogs.map((v,i) => (<Column>{ format(v) }</Column>)) }
          <Column />
        </Row>
        <Row hover light>
          <Column padded="20" style={{marginRight:"-35px"}}>Marketing</Column>
          { all_gross_spend_cum.map((v,i) => (<Column>{ format(i == 0 ? v : 0) }</Column>)) }
          <Column />
        </Row>
      </Collapsible>

      <Row hover>
        <Column padded="20">Profit (Loss)</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { format((getViaKey(values,`Existing.${date}.revenue`,0) + getViaKey(values,`NTF.${date}.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i])) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { format(getViaKey(values,`Existing.${date}.cumulative.revenue`,0) + (getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i+1])) }
            </Column>
          ))
        }

        { months.map((date,i) => (
            <Column bold>
              { format(getViaKey(values,`Existing.${date}.cumulative.revenue`,0) + (getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i+1])) }
            </Column>
          )).slice(-1)
        }
      </Row>
      <Row hover light>
        <Column padded="35">Profit per Customer</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { format((getViaKey(values,`Existing.${date}.revenue`,0) + getViaKey(values,`NTF.${date}.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i])/numCustomers) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { format((getViaKey(values,`Existing.${date}.cumulative.revenue`,0) + (getViaKey(values,`NTF.${date}.cumulative.revenue`,0) - all_gross_spend_cum[i] - all_cogs_cum[i+1]))/numCustomers) }
            </Column>
          ))
        }
        <Column></Column>
      </Row>



      <Row hover light>
        <Column padded="35">ROI</Column>
        { ["cohort"].map((date,i) => (
            <Column>
              { defaultNumber(((getViaKey(values,`NTF.${date}.revenue`,0) + getViaKey(values,`Existing.${date}.revenue`,0)) / (all_gross_spend_cum[i] + all_cogs_cum[i]))) }
            </Column>
          )).slice(0,1) 
        }
        { months.map((date,i) => (
            <Column>
              { defaultNumber(((getViaKey(values,`NTF.${date}.cumulative.revenue`,0) + getViaKey(values,`Existing.${date}.cumulative.revenue`,0)) / (all_gross_spend_cum[i] + all_cogs_cum[i+1]))) }
            </Column>
          ))
        }
         <Column />
      </Row>

    </React.Fragment>
  )
}

export default Profit;
