import React from 'react'
import { Link } from 'react-router-dom'
import { CurrencyDollarIcon } from '@heroicons/react/outline'

import { StackedBarChart, ListLoader } from '@rockerbox/styleguide'
import { Grid, Image } from 'semantic-ui-react'
import CacheContext from '../../../utils/CacheContext'
import { getAllIntegrations } from '../../api/account'
import { NoDataCat, DividingHeader } from '../../components'
import { BarChartLoader } from '../../components/loaders'
import { CHANNELS } from '../SpendTrends/constants'
import { buildPath } from '../../hooks/urlState'
import { BackfillingMessage } from './parts'

import { yesterday, monthAgo, twoMonthsAgo } from '../../utils/time'
import { getPlatformSyncStatusByPlatform, PLATFORM_PERFORMANCE_PLATFORMS } from './helpers'

const CHART_HEIGHT = 380;

const LegendItem = ({ platform, legendData, platforms, colorMap, currencyFormatter }) => {
  if (!currencyFormatter) return null;
  if (platform == "total") {
    const formattedTotalSpend = currencyFormatter.format(legendData["totalSpend"] || 0)
    return <li className="item total">
      <div className='text'>
        <span className='label'>Total</span>
        <span className='value'>{formattedTotalSpend}</span>
      </div>
    </li>
  }

  const integration = platforms.find(x => x.platform == platform)
  const spendAmount = legendData[platform] || 0
  const spend = currencyFormatter.format(spendAmount)
  const { defaultIcon, title, icon } = integration;

  return (
    <Link
      as='li'
      className='item'
      to={
        PLATFORM_PERFORMANCE_PLATFORMS.includes(platform)
        ? buildPath('digitalAdvertising', { view: 'platform', platformName: platform })
        : buildPath('attributionReport')
      }
    >
      {!!icon ?
        <Image
          className='icon'
          src={icon}
          style={{ backgroundColor: colorMap[platform] }}
        />
        :
        <Image
          avatar
          as={defaultIcon}
          className='icon'
          style={{ backgroundColor: colorMap[platform], color: 'white' }}
        />
      }
      <div className='text'>
        <span className='label'>{title}</span>
        <span className='value'>{spend}</span>
      </div>
    </Link>
  )
}

const PlatformSpendChart = ({ colorMap, currencyFormatter, loading, setLoading, stillBackfilling, setStillBackfilling, hasSpendData, platforms, startDate, endDate, data }) => {

  const [state, setState, Context] = React.useContext(CacheContext)

  React.useEffect(() => {
    if (!hasSpendData) return
    if (!platforms) return
    setLoading(true);

    // only check for platform performance platforms here. don't have reliable status API for legacy spend
    getPlatformSyncStatusByPlatform(platforms.filter(x => PLATFORM_PERFORMANCE_PLATFORMS.includes(x.platform)).map(x => x.platform), twoMonthsAgo, yesterday)
      .then(data => {
        let isBackfilling = false;
        data.map(obj => {
          if (obj.value.length < 30) {
            isBackfilling = true;
            console.log('backfilling ', obj);
          }
        })
        setStillBackfilling(isBackfilling);
      })
  }, [hasSpendData, platforms, startDate, endDate])

  const legendData = data?.length ? data.reduce((p, c) => {
    Object.keys(c).filter(x => x!="date").map(k => {
        if (p.hasOwnProperty(k)) p[k] += c[k];
        else p[k] = c[k];
    })
    return p;
  }, {}) : {};

  const chartPlatforms = platforms ? platforms.filter(x => x.platform != 'shopify').map(x => x.platform) : [];
  const spendAmounts = Object.values(legendData)

  const totalSpend = spendAmounts.reduce((partialSum, a) => partialSum + a, 0)
  legendData["totalSpend"] = totalSpend

  return (<>
    <DividingHeader
      text='Advertising Spend'
      icon={<CurrencyDollarIcon />}
    />
    <div className="platform-spend-wrap">
      <div className='chart-wrap'>
        <Grid stretched style={{ marginTop: -2, marginBottom: 0 }}>
          <Grid.Column width={4}>
            <ul className='platform-spend-legend' style={{ height: CHART_HEIGHT, paddingBottom: '0px' }}>
              {(!!loading || !!stillBackfilling) ?
                <ListLoader numItems={3} showBorder={false} animate={!!loading} />
                : <>
                  {chartPlatforms.sort((a, b) => (legendData[b] || 0) - (legendData[a] || 0)).map(i => {
                    return <LegendItem platform={i} {...{ legendData, platforms, colorMap, currencyFormatter }} />
                  })}
                  <hr className='divider' />
                  <LegendItem platform={"total"} {...{ legendData, platforms, currencyFormatter }}  />
                </>
              }
            </ul>
          </Grid.Column>
          <Grid.Column width={12}>
            {(!!loading || !!stillBackfilling)
              ? <BarChartLoader animate={!!loading} />
              : !data?.length
                ? <NoDataCat message={'No Spend Data'} />
                : <StackedBarChart
                    height={CHART_HEIGHT}
                    data={data}
                    tierColorMap={colorMap}
                    formatter={currencyFormatter}
                    showTooltip
                    showTotalInToolTip
                    showXAxis
                  />
              }
          </Grid.Column>
        </Grid>
      </div>
      {!!stillBackfilling && <BackfillingMessage />}
    </div>
  </>)
}

export default PlatformSpendChart
