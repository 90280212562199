import React, { useState, useEffect, useCallback } from 'react';
import { Image } from 'semantic-ui-react';
import { useURLStateAndSetDefault } from '../hooks/urlState';
import { checkUrlParamValue } from '../utils/dataValidation';
import { useGlobalState } from './global';

import { goalAtom } from "../atoms"
import { useResetAtom } from 'jotai/utils';

const platformSettings = {
  adwords: {
    color: '#34a853',
  },
  bing: {
    color: '#60a79b',
  },
  facebook: {
    color: '#1877f2',
  },
  linkedin: {
    color: '#00a0dc',
  },
  pinterest: {
    color: '#e60023',
  },
  snapchat: {
    color: '#fffc00',
  },
  tiktok: {
    color: '#25ece8',
  },
};
const platforms = Object.keys(platformSettings)

// Auth / Options Setup
const integrationsToMap = (integrations) => {
  const integrationsMap = {};

  platforms.forEach(platform => {
    const selectedObj = integrations.find(obj => obj.platform == platform && obj.type == 'spend')
    const src = `https://images.rockerbox.com/integrations/${selectedObj.icon_img}`
    const alt = `https://images.rockerbox.com/integrations/${selectedObj.icon_white_img}`
    integrationsMap[platform] = { icon: src, color: platformSettings[platform].color, display_name: selectedObj.display_name, alt }
  })

  return integrationsMap
}

const authorizationsToAuthedPlatforms = (authorizations) => {
  return authorizations
    .filter(c => platforms.includes(c.platform) && c.authorizations.filter(a => !!a.account_id).length > 0)
    .map(c => c.platform)
}

const integrationsAndplatformsToOptions = (integrations, authedPlatforms, filterIntegrations) => {
  return integrations
    .filter(integration => authedPlatforms.includes(integration.platform)  && filterIntegrations[integration.platform] ) //Enable only platforms with config
    .map(integration => {
      integration.src = `https://images.rockerbox.com/integrations/${integration.logo_img}`
      integration.icon = `https://images.rockerbox.com/integrations/${integration.icon_img}`
      return integration
    })
    .map(c => ({
      key: c.platform,
      text: <span><Image size='tiny' src={c.icon} style={{ marginRight: 5, width: 15, display: 'inline' }} />{c.display_name}</span>,
      value: c.platform,
      // image: { src: c.icon },
    }))
}

const authorizationsAndPlatformsToAccounts = (authorizations, authedPlatforms) => {
  const accounts = {};
  authorizations.forEach(obj => {
    if (authedPlatforms.includes(obj.platform))
      obj.authorizations.forEach(authz => accounts[authz.account_id] = authz.account_name);
  })
  return accounts
}

const useAuthedIntegrations = (filterIntegrations) => {
  const [platformName, setPlatform] = useURLStateAndSetDefault("platformName", "0", true)
  const platform = checkUrlParamValue(platformName) ? platformName : "";
  const { authorizations, integrations } = useGlobalState()
  const resetGoal = useResetAtom(goalAtom)
  const [accountMap, setAccountMap] = useState(undefined)
  const [dropdownOptions, setDropdownOptions] = useState(undefined)
  const [dropdownOptionsLoading, setDropdownOptionsLoading] = useState(true)
  const [integrationsMap, setIntegrationsMap] = useState(undefined)

  // This doesnt seem like it needs to be a use effect.
  // likely creating a rerender when it is not necessary
  useEffect(() => {
    if (!integrations || !authorizations) return

    const integrationsMapObj = integrationsToMap(integrations)
    const authedPlatforms = authorizationsToAuthedPlatforms(authorizations)
    const options = integrationsAndplatformsToOptions(integrations, authedPlatforms, filterIntegrations)
    const accounts = authorizationsAndPlatformsToAccounts(authorizations, authedPlatforms)

    setAccountMap(accounts)
    setDropdownOptions(options)
    setIntegrationsMap(integrationsMapObj)
    setDropdownOptionsLoading(false)
  }, [integrations, authorizations])

  const onDropdownChange = ((e, { value }) => { 
    setPlatform(value) 
    resetGoal()
  })

  return {
    platform,
    accountMap,
    onDropdownChange,
    dropdownOptions,
    dropdownOptionsLoading,
    integrations: integrationsMap,
    authorizations
  }
}

export default useAuthedIntegrations