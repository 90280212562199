import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';


const CONTEXT = '__hover__'

function withContext(Component) {
  function Wrapper(
    {innerRef, ...props},
    context,
  ) {
    const thisContext = context[CONTEXT]
    return (
      <Component
        {...props}
        ref={innerRef}
        hover={thisContext}
      />
    )
  }
  Wrapper.componentWillMount = (x) => {
  }
  Wrapper.contextTypes = {
    [CONTEXT]: PropTypes.object.isRequired,
  }
  Wrapper.displayName = `withContext(${CONTEXT},${Component.displayName ||
    Component.name})`
  return Wrapper
}

class Hoverable extends Component {

  state = {}
  onhover = (hoverState) => {
    this.setState(hoverState)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.update ? this.props.update(this.state, nextState) : true
  }

  componentWillMount() {
    this.props.hover.subscribe(this.onhover)
  }
  componentWillUnmount() {
    this.props.hover.unsubscribe(this.onhover)
  }

  render() {
    return this.props.render({
      hoverState: this.state,
      ...this.props
    })
  }
}

export const HoverDisplay = withContext(Hoverable);

class Hover extends Component {

  handleHover = (e) => {
    this.props.hover.setCurrent(this.props)
  }
  render() {
    return (<div onMouseOver={this.handleHover} {...this.props} />)
  }
}

export const HoverContext = withContext(Hover);


export default class HoverableWrapper extends Component {

  static childContextTypes = {
    [CONTEXT]: PropTypes.object.isRequired
  }

  subscriptions = []
  subscribe = handler => {
    this.subscriptions = this.subscriptions.concat(handler);
  }
  unsubscribe = handler => {
    this.subscriptions = this.subscriptions.filter(current => current !== handler);
  }

  hoverState = {}
  setCurrent = (value) => {
    this.hoverState = value
    this.subscriptions.forEach(fn => fn(value) )
  }

  getChildContext() {
    return {
      [CONTEXT]: {
        current: this.hoverState,
        setCurrent: this.setCurrent,
        subscriptions: this.subscriptions,
        subscribe: this.subscribe,
        unsubscribe: this.unsubscribe
      }
    }
  }

  render() {
    return [...this.props.children]
  }

}
