import React, { Component } from 'react';
import { Icon, Dropdown, Button, Modal, Table, Loader } from 'semantic-ui-react';
import { ContentCard, IndexGrid } from '@rockerbox/styleguide'
import moment from 'moment';
import * as d3 from 'rockerbox_d3_legacy_clone';
import { getDataset, getMetaData, getACETLMetaData } from '../../../utils/api';
import { getLocalJSON, setLocalJSON, checkJobStatus } from "../../../utils/job_api";

const DivideCell = ({ item, col }) => (
  <Table.Cell>
    { !!item[item.divisor] ? 
        parseFloat(parseFloat(item[col.key])/ item[item.divisor]*100).toFixed(2) + "%" :
        "-" 
    }
  </Table.Cell>
);

const RoundCell = ({ item, col }) => (
  <Table.Cell>
    { parseFloat(item[col.key]).toFixed(0) }
  </Table.Cell>
);

const PercentCell = ({ item, col }) => (
  <Table.Cell>
    { parseFloat(item[col.key]*100).toFixed(2)+"%" }
  </Table.Cell>
);

const cols = [
  {key: "display", display: "", headerWidth: 3},
  {key: "original", display: "#", as: RoundCell, headerWidth: 1},
  {key: "original", display: "% of total", as: DivideCell, headerWidth: 1},
  {key: "all", display: "#", as: RoundCell, headerWidth: 1},
  {key: "all", display: "% of total", as: DivideCell, headerWidth: 1},
  {key: "percentIncrease", display: "% increase", as: PercentCell, headerWidth: 1},
]

const SummaryData = ({ filter_id, }) => {

  const [data, setData] = React.useState(false)

  React.useEffect(() => {
    getDataset("fb_synthetic_summary,summary", filter_id, 30)
      .then(({ response: { fb_synthetic_summary, summary }}) => {

        const totalConversions = d3.sum(summary, row => row.count.attributed + row.count.unattributed)
        const totalEvents = d3.sum(summary, row => d3.sum(Object.values(row.events || {})))
        const metrics = d3.nest()
          .key(row => row.eval_metric)
          .rollup(values => {
            const d = {
              original: d3.sum(values, row => row.RB_Original),
              all: d3.sum(values, row => row.RB_w_Synthetic)
            }
            d['percentIncrease'] = (d.all - d.original)/d.original
            return d
          })
          .map(fb_synthetic_summary)

        const data = [
          {display: "Conversions", divisor: "totalConversions", totalConversions, ...metrics['total_conv_even']},
          {display: "Events", divisor:"totalEvents", totalEvents, ...metrics['total_events']},
        ]

        setData(data)

      })
  } , [])

  const headerRows = <>
    <Table.Row>
      <Table.HeaderCell width={3}></Table.HeaderCell>
      <Table.HeaderCell width={1}>Without Synthetic</Table.HeaderCell>
      <Table.HeaderCell width={1}></Table.HeaderCell>
      <Table.HeaderCell width={1}>With Synthetic</Table.HeaderCell>
      <Table.HeaderCell width={1}></Table.HeaderCell>
      <Table.HeaderCell width={1}>Impact of Synthetic</Table.HeaderCell>
    </Table.Row>
  </>
  

  return <ContentCard title="Synthetic Impact" hasTable>
    <IndexGrid {...{headerRows, filter_id, data, cols}} />
  </ContentCard>
}

export default SummaryData;

