import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CacheContext from "../../utils/CacheContext";
import { getAccountNotifications } from "../../utils/api";
import { Grid, Header, Icon, Button, Label, Segment } from "semantic-ui-react";
import { ContentCard } from "@rockerbox/styleguide";
import _ from "lodash";

export const Notifications = props => {
  const [state, setState, Context] = React.useContext(CacheContext);
  const notifications =
    Context.getCache(`notifications`, getAccountNotifications) || [];

  return notifications.map(notification => (
    <NotificationBanner
      {...notification}
      notifications={notifications}
      setState={setState}
      key={notification["id"]}
    />
  ));
};

const NotificationBanner = ({
  title,
  description,
  additional_desc,
  cta_link,
  cta_text,
  setState,
  notifications = []
}) => {
  const history = useHistory();

  const [expanded, setExpanded] = React.useState(false);
  const toggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  const [active, setDismissed] = React.useState(true);
  const toggleDismissed = useCallback(() => setDismissed(!active), ["active"]);

  const followLink = cta_link => {
    const newNotifications = _.cloneDeep(notifications);
    newNotifications.forEach(
      item => (item["clicked"] = item.cta_link === cta_link ? true : false)
    );
    setState({ notifications: newNotifications });

    if (
      cta_link.startsWith("https://app.rockerbox.com") ||
      cta_link.startsWith("/v2")
    ) {
      history.push(cta_link);
    } else {
      window.open(cta_link, "_blank");
    }
  };

  return (
    <>
      {active && (
        <ContentCard className="account-notification communications">
          <Grid fluid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">
                  <Icon name={"exclamation triangle"} color={"yellow"} />
                  <Header.Content>
                    {title}
                    <Header.Subheader style={{ marginTop: 3 }}>
                      {description}
                      <Label
                        as="a"
                        size="mini"
                        icon={`chevron ${expanded ? "up" : "down"}`}
                        content={expanded ? "Hide Info" : "More Info"}
                        onClick={toggleExpand}
                      />
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                {cta_link && cta_text && (
                  <Button
                    primary
                    content={cta_text}
                    onClick={() => followLink(cta_link)}
                  />
                )}
                <Icon
                  link
                  name="close"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={toggleDismissed}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {expanded && (
            <Segment color="yellow">
              <p>
                <Icon name="info circle" />
                <strong>{additional_desc}</strong>
              </p>
            </Segment>
          )}
        </ContentCard>
      )}
    </>
  );
};

export default Notifications;
